import styled from 'styled-components'
import { KeyValue } from 'views/components/KeyValue/KeyValue'
import { Company } from 'modules/domain/company/types'
import { Card } from 'views/components/Card/Card'
import { useTranslation } from 'react-i18next'
import { Deal } from 'modules/domain/deal/types'
import { defaultTheme } from '@agro-club/agroclub-shared'

const InfoWrapper = styled.div`
  display: grid;
  grid-template-columns: 120px 120px auto;
  column-gap: 24px;
  @media (max-width: ${defaultTheme.queries.mobile}) {
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
    & > :nth-child(3) {
      grid-column: 1 / -1;
    }
  }
`

type Props = {
  deal: Deal
}

export const InfoBlock = ({ deal }: Props) => {
  const { t } = useTranslation('deal')

  return (
    <Card.Container bordered>
      <InfoWrapper>
        <KeyValue label={t('requestDocsModal.deal')}>ID {deal.id}</KeyValue>
        <KeyValue label={t('requestDocsModal.product')}>{deal.product.title}</KeyValue>
        <KeyValue label={t('requestDocsModal.seller')}>
          <div>{deal.sale_bid.company.name}</div>
          <div>
            {t('common:inn')} {(deal.sale_bid.company as Company).inn}
          </div>
        </KeyValue>
      </InfoWrapper>
    </Card.Container>
  )
}
