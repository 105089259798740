import AMobileButton from 'views/components/Analytics/AMobileButton'
import { Deal, DealStatusTypes } from 'modules/domain/deal/types'
import { Icons, defaultTheme } from '@agro-club/agroclub-shared'
import { AButton } from 'views/components/Analytics'
import { BidCloningModal } from './BidCloningModal'
import { useMediaQuery } from 'react-responsive'
import { isLegacyBidCloningEnabled } from 'env'
import { useTranslation } from 'react-i18next'
import { FC, useState } from 'react'

interface Props {
  deal: Deal
}

export const BidCloningButton: FC<Props> = ({ deal }) => {
  const [cloningModal, setCloningModal] = useState<boolean>(false)
  const { t } = useTranslation('deal')
  const handlerModal = () => {
    setCloningModal(!cloningModal)
  }
  const isCloningEnabled = isLegacyBidCloningEnabled && deal.status !== DealStatusTypes.DRAFT

  const isMobile = useMediaQuery({
    query: `(max-width: ${defaultTheme.queries.mobile})`,
  })

  if (!isCloningEnabled) return null
  return (
    <>
      {!isMobile ? (
        <AButton id="bidCloning" intent="secondary" size="medium" onClick={handlerModal}>
          {t('bidCloning')}
        </AButton>
      ) : (
        <AMobileButton id="bidCloning" onClick={handlerModal} intent="secondary" Icon={Icons.IconReplace}>
          {t('bidCloning')}
        </AMobileButton>
      )}
      {cloningModal && <BidCloningModal deal={deal} onClose={handlerModal} />}
    </>
  )
}
