import styled from 'styled-components'

export const TabsWrapper = styled.div`
  margin-top: 10px;

  .ant-tabs-extra-content {
    div {
      margin-bottom: 0;
    }
  }
`

export const AddressWrapper = styled.div`
  width: inherit;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const FiltersWrapper = styled.div`
  display: flex;
  column-gap: 8px;
`
