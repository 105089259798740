const main = 'dashboard'

const relativeRoutes = {
  relativePage: `${main}/*`,
  relativeRoot: `${main}`,
  relativeListWithTab: 'tab-:activeTab',
}

export const DashboardRoutes = {
  Root: `/${main}`,
  ListWithTabs: `/${main}/${relativeRoutes.relativeListWithTab}`,
  ...relativeRoutes,
}

export default DashboardRoutes
