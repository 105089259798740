import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ExternalLink, Icons, Progress } from '@agro-club/agroclub-shared'
import { DocumentFile, RespFile } from 'modules/domain/types'
import { FileItemStyled } from 'views/components/FilesByTypes/styled'
import { SharedModalContent, SharedModalTitle } from 'views/components/SharedModal'
import { TruncatedValue } from 'views/components/TruncatedValue/TruncatedValue'
import { AButton } from '../Analytics'
import ADefaultModalFooter from '../DefaultModalFooter/ADefaultModalFooter'
import ASharedModal from '../SharedModal/ASharedModal'

interface Props {
  file: DocumentFile | RespFile
  progress?: Progress
  onDelete?: (document_id: string) => void
}

const FileItem: FC<Props> = ({ file, progress, onDelete }) => {
  const { t } = useTranslation('common')
  const [isConfirmVisible, setConfirmVisible] = useState<boolean>(false)

  return (
    <>
      <FileItemStyled>
        <ExternalLink href={file.uploaded_file} target="_blank">
          <TruncatedValue hint={file.original_filename}>{file.original_filename}</TruncatedValue>
        </ExternalLink>
        <AButton id="delete" Icon={Icons.IconDelete} intent="white" onClick={() => setConfirmVisible(true)} />
      </FileItemStyled>

      {isConfirmVisible && (
        <ASharedModal id="RemoveDocumentModal" size="small" onClose={() => setConfirmVisible(false)}>
          <SharedModalTitle>{t('removeDocumentHeader')}</SharedModalTitle>
          <SharedModalContent>{t('removeDocument')}</SharedModalContent>
          <ADefaultModalFooter
            progress={progress}
            onClose={() => setConfirmVisible(false)}
            onConfirm={() => onDelete?.(file.id)}
          />
        </ASharedModal>
      )}
    </>
  )
}

export default FileItem
