import { MobileModalFooter } from 'views/components/MobileComponents/Modal/MobileModalFooter'
import { DispatchingItem, DispatchingStatuses, DispatchingListType } from '../../types'
import { AMobileModal } from 'views/components/MobileComponents/Modal/AMobileModal'
import { FormGroup, Input, Text } from '@agro-club/agroclub-shared'
import { SharedModalInputs } from 'views/components/SharedModal'
import FormSelectShared from 'views/components/FormSelectShared'
import { labelPrice } from 'modules/utils/numbers/formatPrice'
import { apiClient } from 'modules/utils/httpClient'
import { useAFormHandler } from 'analytics/hooks'
import { useTranslation } from 'react-i18next'
import { endpoints } from 'modules/endpoints'
import { useFormik } from 'formik'
import { isBrazil } from 'env'
import { FC } from 'react'

type Props = {
  data: DispatchingListType
  refetch: () => void
  onClose: () => void
}

export const EditDispatch: FC<Props> = ({ onClose, data, refetch }) => {
  const { t } = useTranslation('dispatching')
  const logisticianIds = data.logisticians.filter(Boolean).map((el) => el.id)

  const { formProgress, formHandler } = useAFormHandler('addCompanyContract')

  const formik = useFormik<{
    status: DispatchingStatuses
    logisticians: string[]
    trips_expected: number
    price_exw: number
  }>({
    initialValues: {
      price_exw: data.price_exw,
      trips_expected: data.trips_expected,
      status: data.status,
      logisticians: logisticianIds,
    },
    enableReinitialize: true,
    onSubmit: formHandler(
      async () => {
        const payload = {
          price_exw: formik.values.price_exw,
          trips_expected: formik.values.trips_expected,
          status: formik.values.status,
          payments: Object.entries(data.payments).map(([payment_date, details]) => ({
            ...details,
            payment_date,
          })),
        }

        return await Promise.all([
          apiClient.put<DispatchingItem>(endpoints.dispatchingItem(data.id), { ...payload }),
          apiClient.put(endpoints.executorsEdit(data.deal_id), { logisticians: formik.values.logisticians }),
        ])
      },
      {
        onSuccess: async () => {
          await refetch()
          onClose()
        },
        onError: (error) => {
          const { errors } = error
          formik.setErrors(errors)
        },
      },
    ),
  })

  return (
    <AMobileModal header={<Text typography="titleH4">{t('editData')}</Text>} id="editDispatch" onClose={onClose}>
      <SharedModalInputs>
        <FormGroup label={labelPrice(t('price'))} error={formik.errors?.price_exw}>
          <Input {...formik.getFieldProps('price_exw')} invalid={!!formik.errors?.price_exw} type="number" />
        </FormGroup>
        <FormGroup label={t('table.trips_expected')} error={formik.errors?.trips_expected}>
          <Input {...formik.getFieldProps('trips_expected')} invalid={!!formik.errors?.trips_expected} type="number" />
        </FormGroup>
        <FormSelectShared
          placeholder={t('modal.inputs.status_placeholder')}
          endpoint={endpoints.dispatchingStatuses()}
          label={t('modal.inputs.status')}
          getLabel={(dto) => dto.label}
          allowClear={false}
          showSearch={false}
          fieldName="status"
          formik={formik}
          simpleApiFormat
        />
        <FormSelectShared
          endpoint={endpoints.dispatchingLogisticians()}
          getLabel={(dto) => dto.full_name}
          label={t('responsible')}
          fieldName="logisticians"
          multiple={!isBrazil}
          searchField="search"
          formik={formik}
        />
      </SharedModalInputs>
      <MobileModalFooter onConfirm={() => formik.submitForm()} progress={formProgress} />
    </AMobileModal>
  )
}
