import _ from 'lodash'
import { ClickableTag, defaultTheme, Typography } from '@agro-club/agroclub-shared'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { FC } from 'react'
import { PaymentStatus } from '../../types'
import { FormikProps } from 'formik'
import { getColors } from '../../helpers'

type Props = {
  formik: FormikProps<any>
  fieldName: string
}
const Tags = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  margin: auto 0;
`

const TagText = styled.div<{ color: string }>`
  ${Typography.buttonSmall}
  color: ${({ color }) => color};
`

export const PaymentStatuses: FC<Props> = ({ formik, fieldName }) => {
  const { t } = useTranslation('dispatching')

  return (
    <Tags>
      {Object.values(PaymentStatus).map((status) => {
        const isActive = _.get(formik.values, fieldName) === status
        return (
          <ClickableTag
            key={status}
            onClick={() => formik.setFieldValue(fieldName, status)}
            active={isActive}
            activeBackground={getColors(status).background}
          >
            <TagText color={isActive ? getColors(status).text : defaultTheme.color.primary600}>
              {t(`paymentStatuses.${status}`)}
            </TagText>
          </ClickableTag>
        )
      })}
    </Tags>
  )
}
