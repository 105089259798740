import { useTranslation } from 'react-i18next'
import { getPriceTypeLabel } from 'modules/domain/bid/utils'
import { FC } from 'react'
import { Card } from 'views/components/Card/Card'
import { KeyValue } from 'views/components/KeyValue/KeyValue'
import { formatTarif } from 'modules/utils/numbers/formatTarif'
import { VerticalFormatQuantity } from 'modules/utils/numbers/formatQuantity'
import { Bid, BidContract } from 'modules/domain/bid/types'
import { useBrazilBidDetails } from 'views/pages/Bid/components/Brazil/utils'
import { BidDetailGrid } from '../BidDetailGrid'
import { TextWithSubText } from 'views/components/TextWithSubText/TextWithSubText'

export const BRBidDetailProduct: FC<{ bid: Bid | BidContract }> = ({ bid }) => {
  const { t } = useTranslation(['deal', 'bid', 'address'])

  const isVisibleTax = bid.tax_from_exw !== undefined

  const brazilDetails = useBrazilBidDetails(bid)

  return (
    <>
      <Card.Container bordered disableShadow>
        <Card.Grid cols={3} maxColumnWidth="122px" columnGap={32}>
          <KeyValue label={getPriceTypeLabel(bid.price_type, t)}>
            <TextWithSubText
              text={formatTarif(bid.price)}
              subText={isVisibleTax && `${t('common:tax')} ${formatTarif(bid.tax_from_exw)}`}
              subColor="accent"
            />
          </KeyValue>
          <KeyValue label={t('fields.quantity')}>
            <VerticalFormatQuantity valueTons={bid.quantity} />
          </KeyValue>
          <KeyValue label={t('deal:rematch.remaining')}>
            <VerticalFormatQuantity valueTons={bid.remaining_quantity} />
          </KeyValue>
          <BidDetailGrid bid={bid} uniqueItems={brazilDetails} />
        </Card.Grid>
      </Card.Container>
    </>
  )
}
