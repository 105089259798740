import { format, isValid, parseISO } from 'date-fns'

/**
 * @deprecated in favor of the  useFormatDate hook
 */
export default function formatDateWithoutLocale(value: string | null | undefined, pattern: string) {
  if (!value) {
    return ''
  }
  const parsed = parseISO(value)

  return isValid(parsed) ? format(parsed, pattern) : ''
}
