import { FC } from 'react'
import { generatePath, useParams } from 'react-router'
import { useTranslation } from 'react-i18next'

import { Progress } from '@agro-club/agroclub-shared'
import * as Layout from 'views/layouts/NewLayout'
import * as Header from 'views/ui/Header/Header'
import ItemLoadingLayout from 'views/layouts/ItemLoadingLayout/ItemLoadingLayout'
import { HeaderTitleWrapper } from '../../components/styled'
import { CarsAddForm } from './CarsAddForm'

import CarriersKRRoutes from '../routes'

import { useSingleEntity } from 'modules/domain/common/hooks'
import { endpoints } from 'modules/endpoints'
import { LogisticsKRCarrier } from '../../types'

export const CarriersKRAddCar: FC = () => {
  const { t } = useTranslation('logisticsKR')
  const { id } = useParams()
  const [fetchProgress, carrier] = useSingleEntity<LogisticsKRCarrier>(endpoints.LogisticsKR.carriers(id))

  if (fetchProgress === Progress.WORK || !carrier) return <ItemLoadingLayout id={id as string} />

  return (
    <Layout.WrapperContent>
      <Layout.Header>
        <Layout.TopHeader>
          <Layout.Breadcrumbs
            routes={[
              { path: CarriersKRRoutes.List, breadcrumbName: t('carriers.heading') },
              {
                path: generatePath(CarriersKRRoutes.Details, { id: id }),
                breadcrumbName: `${carrier?.name}`,
              },
              {
                breadcrumbName: t('carriers.carsAdd.heading'),
              },
            ]}
          />
        </Layout.TopHeader>
        <HeaderTitleWrapper>
          <Header.Title title={t('carriers.carsAdd.heading')} compact />
        </HeaderTitleWrapper>
      </Layout.Header>

      <CarsAddForm carrierId={id} />
    </Layout.WrapperContent>
  )
}
