import { useContext, useEffect, useRef, useState } from 'react'
import { AnalyticsContext } from 'analytics/analyticsContext'
import { RequestError } from 'modules/errors'
import { AnalyticsPayload } from './types'
import { Progress } from '@agro-club/agroclub-shared'
import { useIsMounted } from 'hooks/useIsMounted'
import { useAnalytics } from 'use-analytics'
import { useNoPermissionNotification } from 'hooks/useNoPermissionNotification'

export const useAnalyticsContext = () => {
  return useContext(AnalyticsContext)
}

export const useAnalyticsIdentify = () => {
  const { identify } = useAnalytics()

  const userIdentification = (userId: string) => {
    identify(userId, {
      backup_user_id: userId,
    })
  }

  return { userIdentification }
}

export const useModalPlace = (place: string) => {
  const prevPlace = useRef('')
  const { setOptions, options } = useAnalyticsContext()

  useEffect(() => {
    prevPlace.current = options.place || ''
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setOptions({ place })

    return () => {
      setOptions({ place: prevPlace.current })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export const useAFormHandler = (eventName = 'save', analyticsPayload?: AnalyticsPayload) => {
  const { track } = useAnalyticsContext()
  const [formProgress, setFormProgress] = useState<Progress>(Progress.IDLE)
  const isMounted = useIsMounted()
  const notify = useNoPermissionNotification()

  const formHandler = (
    sendRequest: (...args: any) => Promise<any>,
    optParams?: {
      onSuccess?: (requestResult: any) => Promise<void> | void
      onError?: (error: RequestError) => void
    },
  ) => {
    const { onSuccess, onError } = optParams ?? {}
    return async (...args: any): Promise<void> => {
      try {
        setFormProgress(Progress.WORK)
        const res = await sendRequest(...args)
        track(`submit|${eventName}`, analyticsPayload)
        await onSuccess?.(res)
        if (isMounted.current) {
          setFormProgress(Progress.SUCCESS)
        }
      } catch (err) {
        setFormProgress(Progress.ERROR)
        const error = err as RequestError

        const { errors } = error

        const errorsKeys = Object.keys(errors)

        if (errorsKeys.length) {
          track(`submit_failed|${eventName}`, {
            ...analyticsPayload,
            field: errorsKeys.join(', '),
          })
        }
        if (error?.code === 403) {
          notify()
        }
        onError?.(error)
      }
    }
  }

  return { formProgress, formHandler }
}

export const useAnalyticsClickHandler = (id: string) => {
  const { track } = useAnalyticsContext()

  return (callback?: (...args: any) => void, payload?: AnalyticsPayload) => {
    return (...args: any) => {
      callback?.(...args)

      track(`click|${id}`, payload)
    }
  }
}

export const useAnalyticsChangeHandler = (id: string) => {
  const { track } = useAnalyticsContext()

  return (callback?: (value: any, ...otherArgs: any) => void, payload?: AnalyticsPayload) => {
    return (value: any, ...otherArgs: any) => {
      callback?.(value, ...otherArgs)

      track(`change|${id}`, {
        ...payload,
        value: {
          from: payload?.value?.from,
          to: value ?? '',
        },
      })
    }
  }
}
