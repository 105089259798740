import { FormItemWrapper } from 'views/styled/common'
import { FormGroup, Radio, RadioItem } from '@agro-club/agroclub-shared'
import { useTranslation } from 'react-i18next'
import { FormikProps } from 'formik'
import { FC, useState } from 'react'
import { CloningFormFields, CloningTypes } from '../../../types'
import { ProfileType } from 'modules/domain/user/types'
import { ProfileTypeRadio } from './ProfileTypeRadio'

interface Props {
  formik: FormikProps<CloningFormFields>
  setCurrentCloningType: (value: CloningTypes) => void
  currentProfileType: ProfileType
  changeProfileTypeHandler: (value: ProfileType) => void
}

export const BRCloningRadio: FC<Props> = ({
  formik,
  setCurrentCloningType,
  currentProfileType,
  changeProfileTypeHandler,
}) => {
  const { t } = useTranslation('deal')
  const [currentCloning, setCurrentCloning] = useState<CloningTypes>(CloningTypes.customer_change)

  const currentCloningChangeHandler = (value: CloningTypes) => {
    formik.setFieldValue('user', '')
    formik.setFieldValue('new_bid', '')
    formik.setFieldValue('distance', 0)
    formik.setFieldValue('logistics_rate', 0)
    formik.setFieldValue('price_logistics', 0)
    setCurrentCloning(value)
    setCurrentCloningType(value)
  }

  return (
    <>
      <FormGroup label={t('bidCloningModal.labels.cloning_type_br')}>
        <FormItemWrapper>
          <Radio onChange={currentCloningChangeHandler} value={currentCloning}>
            <RadioItem value={CloningTypes.customer_change} label={t('cloningTypesBr.cloning')} title="" />
            <RadioItem value={CloningTypes.quantity_split} label={t('cloningTypesBr.quantity_split')} title="" />
          </Radio>
        </FormItemWrapper>
      </FormGroup>

      <ProfileTypeRadio
        currentCloning={currentCloning}
        setCurrentCloningType={setCurrentCloningType}
        formik={formik}
        currentProfileType={currentProfileType}
        changeProfileTypeHandler={changeProfileTypeHandler}
      />
    </>
  )
}
