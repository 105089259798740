import { PopoverContainer } from 'views/styled/common'
import { APopoverButton } from 'views/components/Analytics/APopoverButton'
import { useTranslation } from 'react-i18next'
import { Icons } from '@agro-club/agroclub-shared'
import { FC } from 'react'
import PermissionCheck from 'views/components/PermissionCheck/PermissionCheck'

type Props = {
  setPopoverVisible: (arg: boolean) => void
  setEditModal: (arg: boolean) => void
  setRemoveModal: (arg: boolean) => void
}

export const LoadingActionButtons: FC<Props> = ({ setPopoverVisible, setRemoveModal, setEditModal }) => {
  const { t } = useTranslation()

  return (
    <PopoverContainer onClick={() => setPopoverVisible(false)}>
      <PermissionCheck scope={['deals.change_regionalactivity']}>
        <APopoverButton
          id="edit"
          onClick={() => setEditModal(true)}
          Icon={<Icons.IconEdit />}
          text={t('common:edit')}
        />
      </PermissionCheck>

      <PermissionCheck scope={['deals.delete_regionalactivity']}>
        <APopoverButton
          id="remove"
          onClick={() => setRemoveModal(true)}
          Icon={<Icons.IconRemove />}
          text={t('common:delete')}
        />
      </PermissionCheck>
    </PopoverContainer>
  )
}
