import { FC, useCallback, useMemo } from 'react'
import styled from 'styled-components'
import { AntTooltip, Icons } from '@agro-club/agroclub-shared'
import { AButton } from 'views/components/Analytics'
import { Comment } from 'views/components/Comments/hooks'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 16px;
`

const TextContainer = styled.div`
  display: -webkit-box;
  width: 220px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
`

type Props = {
  comments?: Comment[]
  dealId: string
  setDrawerIsOpen: (arg: boolean) => void
  setSelectedDealId: (value: string) => void
}

export const CommentsCell: FC<Props> = ({ comments, setDrawerIsOpen, setSelectedDealId, dealId }) => {
  const unpinnedComments = useMemo(() => comments?.filter((comment) => !comment.is_pinned), [comments])
  const firstComment = unpinnedComments?.[0]?.comment
  const commentsList = useMemo(
    () => unpinnedComments?.reverse()?.map((comment) => <div key={comment.id}>&bull; {comment.comment}</div>),
    [unpinnedComments],
  )

  const clickHandler = useCallback(() => {
    setSelectedDealId(dealId)
    setDrawerIsOpen(true)
  }, [dealId, setDrawerIsOpen, setSelectedDealId])

  return (
    <Wrapper>
      <AntTooltip
        tooltipContent={commentsList}
        variant="secondary"
        placement="topLeft"
        overlayStyle={{ maxWidth: '550px' }}
      >
        <TextContainer>{firstComment}</TextContainer>
      </AntTooltip>
      <AButton
        id="dispatchingComments"
        size="small"
        intent="secondary"
        Icon={Icons.IconNegotiations}
        onClick={clickHandler}
      />
    </Wrapper>
  )
}
