import { FC } from 'react'
import { TeamsTableCard } from './TeamsTableCard/TeamsTableCard'
import { PageContainer } from 'views/pages/Teams/styled'
import { PurchaseTableCard } from 'views/pages/Teams/PurchaseTableCard/PurchaseTableCard'
import { SellerTableCard } from 'views/pages/Teams/SellerTableCard/SellerTableCard'

export const TeamsList: FC = () => {
  return (
    <PageContainer>
      <TeamsTableCard />
      <PurchaseTableCard />
      <SellerTableCard />
    </PageContainer>
  )
}
