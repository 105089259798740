import { Progress } from '@agro-club/agroclub-shared'
import capitalizeFirstLetter from 'helpers/capitalizeFirstLetter'
import { useSingleEntity } from 'modules/domain/common/hooks'
import { BasicStatus } from 'modules/domain/types'
import { endpoints } from 'modules/endpoints'

export const useDocumentRequestStatuses = (): [Progress, any[], Record<string, string> | null] => {
  const [progress, statuses] = useSingleEntity<BasicStatus[]>(endpoints.dealDocumentRequestStatuses(), false)
  const statusDict =
    statuses?.reduce<Record<string, string>>((acc, status) => {
      acc[status.slug] = capitalizeFirstLetter(status.label)
      return acc
    }, {}) ?? null
  const statusOptions =
    statuses?.map((status) => ({
      label: capitalizeFirstLetter(status.label),
      value: status.slug,
    })) ?? []

  return [progress, statusOptions, statusDict]
}
