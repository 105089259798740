import { FC, useEffect } from 'react'

import { AntSwitch, defaultTheme, Progress, RouterLink, Typography } from '@agro-club/agroclub-shared'
import { useAProgress } from 'hooks/useAProgress'
import useFormatDate from 'hooks/useFormatDate'
import { ACCORDION_ITEM_PAGE_SIZE } from 'modules/constants'
import { refetchFunc, usePagination, useSingleEntity } from 'modules/domain/common/hooks'
import * as managers from 'modules/domain/deal/managers'
import { Deal, SpecificationsForPurchase } from 'modules/domain/deal/types'
import { SpecificationStatus } from 'modules/domain/specification/types'
import { endpoints } from 'modules/endpoints'
import formatNumber from 'modules/utils/numbers/formatNumber'
import formatPrice from 'modules/utils/numbers/formatPrice'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router-dom'
import styled from 'styled-components'
import { BorderedItem } from 'views/components/BorderedItem/BorderedItem'
import { Pagination } from 'views/components/Pagination/Pagination'
import { ItemContentInfo, ItemList } from 'views/pages/Deal/DealDetail/DealAccordion/styled'
import SpecificationsRoutes from 'views/pages/Specifications/routes'
import { ListFooterWrapper } from 'views/styled/common'
import { ButtonsWrapper } from 'views/styled/detailedPage'
import { AddSpecification } from './AddSpecification'
import { WithoutSpecWarning } from './WithoutSpecWarning'
import { WithoutSpecification } from './WithoutSpecification'

const MainInfo = styled.div<{ disabled?: boolean }>`
  ${Typography.bodyLarge};
  color: ${({ disabled }) => (disabled ? defaultTheme.color.textSecondary60a : defaultTheme.color.textPrimary900)};
`

const SecondaryInfo = styled.div<{ disabled?: boolean }>`
  ${Typography.bodyLarge};
  color: ${({ disabled }) => (disabled ? defaultTheme.color.textTertiary30a : defaultTheme.color.textSecondary60a)};
`

type ItemProps = {
  specification: SpecificationsForPurchase
  dealId: string
  purchaseSpecificationId: string
  progress: Progress
  onBind: (dealId: string, specificationId: string) => void
}
const SpecificationItem: FC<ItemProps> = ({ specification, dealId, purchaseSpecificationId, progress, onBind }) => {
  const { t } = useTranslation('common')
  const isPurchaseSpecification = purchaseSpecificationId === specification.id
  const isClosedSpecification = specification.status === SpecificationStatus.closed
  const formatDate = useFormatDate()
  const secondaryInfo = [
    specification.product?.title,
    specification.quantity ? `${formatNumber(specification.quantity)} ${t('t')}` : null,
    specification.price ? formatPrice(specification.price, true) : null,
    formatDate(specification.deadline),
  ].join(', ')

  return (
    <BorderedItem>
      <ItemContentInfo>
        <MainInfo disabled={isClosedSpecification}>
          <RouterLink to={generatePath(SpecificationsRoutes.Details, { id: specification.id })}>
            {t('specification:specificationNumber', {
              number: specification.number,
              date: formatDate(specification.signed_date),
            })}
          </RouterLink>
          {t('specification:toContract', { contract: specification.contract.number })}
          {isClosedSpecification && t('close_specification')}
        </MainInfo>
        <SecondaryInfo disabled={isClosedSpecification}>{secondaryInfo}</SecondaryInfo>
      </ItemContentInfo>
      {!isClosedSpecification && (
        <AntSwitch
          checked={isPurchaseSpecification}
          disabled={isPurchaseSpecification || progress === Progress.WORK}
          onChange={!isPurchaseSpecification ? () => onBind(dealId, specification.id) : undefined}
        />
      )}
    </BorderedItem>
  )
}

type Props = {
  deal: Deal
  dealRefetch: refetchFunc
  setSummary: (arg: boolean) => void
}
export const BuyerSpecifications: FC<Props> = ({ deal, dealRefetch, setSummary }) => {
  const [progress, specifications = [], specificationsRefetch] = useSingleEntity<SpecificationsForPurchase[]>(
    endpoints.specificationForPurchase(deal.purchase_bid.owner.id, deal?.product?.slug),
  )

  useEffect(() => {
    if (progress === Progress.SUCCESS) {
      setSummary(!!specifications.length)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [specifications.length])

  const [specificationsCurrentPage, setSpecificationsCurrentPage, specificationsCurrentItems] = usePagination(
    specifications,
    ACCORDION_ITEM_PAGE_SIZE,
  )

  const changeBindingHandler = async (dealId: string, specificationId: string) => {
    await managers.bindSpecificationForDeal(dealId, specificationId)
    await dealRefetch()
    await specificationsRefetch()
  }

  const [bindingSpecificationProgress, changeBinding] = useAProgress(changeBindingHandler, {
    eventName: 'changeBinding',
  })

  return (
    <>
      {deal.without_purchase_specification && <WithoutSpecWarning />}
      {!!specifications?.length && (
        <ItemList>
          {specificationsCurrentItems.map((item) => (
            <SpecificationItem
              key={item.id}
              specification={item}
              dealId={deal.id}
              purchaseSpecificationId={deal.purchase_specification?.id}
              onBind={changeBinding}
              progress={bindingSpecificationProgress}
            />
          ))}
        </ItemList>
      )}
      <ListFooterWrapper>
        <ButtonsWrapper>
          <AddSpecification deal={deal} bindingSpecToDeal={changeBindingHandler} />
          <WithoutSpecification deal={deal} dealRefetch={dealRefetch} specRefetch={specificationsRefetch} />
        </ButtonsWrapper>
        <Pagination
          disableMargin
          total={specifications.length}
          currPage={specificationsCurrentPage}
          setCurrPage={setSpecificationsCurrentPage}
        />
      </ListFooterWrapper>
    </>
  )
}
