import { Input, Text, defaultTheme } from '@agro-club/agroclub-shared'
import { labelPrice } from 'modules/utils/numbers/formatPrice'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { CalculatorRow } from './Row'
import { FC } from 'react'
import { FormikFieldsType } from 'modules/domain/types'

type Props = {
  formik: FormikFieldsType
}
export const CalculatorInput = styled(Input).attrs({
  inputSize: 'small',
  disabled: true,
})`
  input:disabled {
    color: ${defaultTheme.color.secondary500};
    cursor: pointer;
  }
`

export const CalculatorRightBlock: FC<Props> = ({ formik }) => {
  const { t } = useTranslation('calculator')
  return (
    <div>
      <CalculatorRow
        title={
          <Text typography="bodyMedium" color="secondary">
            {t('title')}
          </Text>
        }
      >
        <Text typography="bodyMedium">{t('autoFreight')}</Text>
        <Text typography="bodyMedium">{t('simulation')}</Text>
      </CalculatorRow>
      <CalculatorRow title={<Text typography="accentMedium">{labelPrice(t('netMargin'))}</Text>}>
        <CalculatorInput formik={formik} {...formik.getFieldProps('auto.net_margin_percent')} />
        <CalculatorInput {...formik.getFieldProps('simulation.net_margin_percent')} />
      </CalculatorRow>
      <CalculatorRow title={<Text typography="bodyMedium">{t('comercialGM')}</Text>}>
        <CalculatorInput {...formik.getFieldProps('auto.net_gm')} />
        <CalculatorInput {...formik.getFieldProps('simulation.net_gm')} />
      </CalculatorRow>
      <CalculatorRow title={<Text typography="bodyMedium">{t('marginPer10d')}</Text>}>
        <CalculatorInput {...formik.getFieldProps('auto.margin_per_10d_percent')} />
        <CalculatorInput {...formik.getFieldProps('simulation.margin_per_10d_percent')} />
      </CalculatorRow>
      <CalculatorRow title={<Text typography="bodyMedium">{t('marginPc')}</Text>}>
        <CalculatorInput {...formik.getFieldProps('auto.margin_percent')} />
        <CalculatorInput {...formik.getFieldProps('simulation.margin_percent')} />
      </CalculatorRow>
      <CalculatorRow title={<Text typography="bodyMedium">{labelPrice(t('gmPC'))}</Text>}>
        <CalculatorInput {...formik.getFieldProps('auto.gm')} />
        <CalculatorInput {...formik.getFieldProps('simulation.gm')} />
      </CalculatorRow>
    </div>
  )
}
