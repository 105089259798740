import { useTranslation } from 'react-i18next'

import { AntdTable, Progress } from '@agro-club/agroclub-shared'
import type { ColumnsType } from 'antd/lib/table'
import { format } from 'date-fns'

import env from 'env'
import useDateFnsLocale from 'hooks/useDateFnsLocale'
import { TeamsOverall } from 'modules/domain/teams/types'
import { usePlansTableData } from 'views/pages/Teams/TeamsTableCard/hooks'
import { TableContainer } from 'views/pages/Teams/TeamsTableCard/styled'
import { FC } from 'react'

const TABLE_SCROLL_HEIGHT = 532
const currency = env.REACT_APP_CURRENCY_SYMBOL

interface Prop {
  progress: Progress
  data: TeamsOverall
  currentYear: number
}

export const TeamsTable: FC<Prop> = ({ progress, data, currentYear }) => {
  const { t } = useTranslation('teams')
  const locale = useDateFnsLocale()
  const currMonth = +format(new Date(), 'M')

  const tableData = usePlansTableData(currentYear, data, locale)

  const columns: ColumnsType<TeamsOverall> = [
    {
      title: t('tableHeader.month'),
      dataIndex: 'month',
      key: 'month',
      width: '148px',
    },
    {
      title: t('tableHeader.volume', { currency }),
      dataIndex: 'volume',
      key: 'volume',
      align: 'right',
      width: '164px',
    },
    {
      title: t('tableHeader.completed', { currency }),
      dataIndex: 'completed',
      key: 'completed',
      align: 'right',
      width: '164px',
    },
    {
      title: t('tableHeader.progress'),
      dataIndex: 'progress',
      key: 'progress',
      align: 'right',
      width: '64px',
    },
  ]

  return (
    <TableContainer>
      <AntdTable
        columns={columns}
        data={tableData}
        rowClassName={(record) => (record.monthIndex === currMonth ? 'current-month-row' : 'month-row')}
        rowKey="monthIndex"
        progress={progress}
        disablePagination={true}
        tableLayout="auto"
        scroll={{ y: TABLE_SCROLL_HEIGHT }}
        bordered
      />
    </TableContainer>
  )
}
