import { FC } from 'react'
import { CarrierStatuses } from '../../../types'
import { AntTag } from '@agro-club/agroclub-shared'
import { useTranslation } from 'react-i18next'

type Props = {
  status: string
}

export const CarrierStatusCell: FC<Props> = ({ status }) => {
  const { t } = useTranslation('logisticsKR')

  switch (status) {
    case CarrierStatuses.active:
      return <AntTag color="green">{t('carriers.statuses.active')}</AntTag>
    case CarrierStatuses.archived:
      return <AntTag color="grey">{t('carriers.statuses.archived')}</AntTag>
    case CarrierStatuses.draft:
      return <AntTag color="red">{t('carriers.statuses.draft')}</AntTag>
    default:
      return <AntTag color="blue">{status}</AntTag>
  }
}
