import { FC, useState } from 'react'
import { useFormik } from 'formik'
import styled from 'styled-components'

import { SharedModalContent, SharedModalTitle } from 'views/components/SharedModal'
import { AddLoadingModalContent } from './AddLoadingModalContent'
import { useTranslation } from 'react-i18next'
import { FormGroup, Radio, RadioItem } from '@agro-club/agroclub-shared'
import { AddUnloadingModalContent } from './AddUnloadingModal'
import { apiClient } from 'modules/utils/httpClient'
import { endpoints } from 'modules/endpoints'
import { addPrefixInErrors, removePropertyNamePrefix } from 'modules/utils/helpers'
import { FormItemWrapper } from 'views/styled/common'
import { useAFormHandler } from 'analytics/hooks'
import ASharedModal from 'views/components/SharedModal/ASharedModal'
import { AnalyticsPlaces } from 'views/pages/LogisticsKR/analyticsPlaces'
import ADefaultModalFooter from 'views/components/DefaultModalFooter/ADefaultModalFooter'

const ModalSwitcher = styled.div`
  margin-bottom: 24px;
`

interface Props {
  setIsOpen: (val: boolean) => void
  onSuccess: () => void
}

export const WarehousesModal: FC<Props> = ({ setIsOpen, onSuccess }) => {
  const { formProgress, formHandler } = useAFormHandler('addWarehouse')
  const { t } = useTranslation('logisticsKR')
  const [isUnloading, setIsUnloading] = useState<boolean>(false)
  const formik = useFormik({
    initialValues: {
      loading_warehouse_address: null,
      loading_warehouse_latitude: null,
      loading_warehouse_longitude: null,
      loading_warehouse_geo_object: null,
      loading_warehouse_gates_height: null,
      loading_warehouse_loader_type: [],
      loading_warehouse_scales_capacity: null,
      loading_warehouse_scales_remoteness: null,
      loading_warehouse_scales_length: null,
      loading_warehouse_volume_per_day: null,
      loading_warehouse_vehicle_type: [],
      loading_warehouse_schedule_from: null,
      loading_warehouse_schedule_to: null,
      loading_warehouse_works_on_weekend: false,
      loading_warehouse_car_delivery: false,
      loading_warehouse_railway_delivery: false,
    },
    onSubmit: formHandler(
      async () =>
        await apiClient.post(
          endpoints.LogisticsKR.loadingWarehouse(),
          removePropertyNamePrefix(formik.values, /loading_warehouse_/g),
        ),
      {
        onSuccess: () => {
          onSuccess()
          setIsOpen(false)
        },
        onError: (error) => {
          const { errors } = error
          formik.setErrors(addPrefixInErrors(errors, 'loading_warehouse'))
        },
      },
    ),
  })
  const secondFormik = useFormik({
    initialValues: {
      unloading_warehouse_address: null,
      unloading_warehouse_latitude: null,
      unloading_warehouse_longitude: null,
      unloading_warehouse_geo_object: null,
      unloading_warehouse_unloading_type: null,
      unloading_warehouse_vehicle_type: [],
      unloading_warehouse_schedule_from: null,
      unloading_warehouse_volume_per_day: null,
      unloading_warehouse_schedule_to: null,
      unloading_warehouse_works_on_weekend: false,
      unloading_warehouse_car_delivery: false,
      unloading_warehouse_railway_delivery: false,
      unloading_warehouse_is_dump_truck: true,
    },
    onSubmit: formHandler(
      async () =>
        await apiClient.post(
          endpoints.LogisticsKR.unloadingWarehouse(),
          removePropertyNamePrefix(secondFormik.values, /unloading_warehouse_/g),
        ),
      {
        onSuccess: () => {
          onSuccess()
          setIsOpen(false)
        },
        onError: (error) => {
          const { errors } = error
          secondFormik.setErrors(addPrefixInErrors(errors, 'unloading_warehouse'))
        },
      },
    ),
  })

  return (
    <ASharedModal id={AnalyticsPlaces.WAREHOUSES.ADD_MODAL} size="large" onClose={() => setIsOpen(false)}>
      <SharedModalTitle>{t('warehouses.forms.heading')}</SharedModalTitle>

      <SharedModalContent>
        <ModalSwitcher>
          <FormGroup label={t('warehouses.forms.warehouseType.label')}>
            <FormItemWrapper>
              <Radio onChange={(value) => setIsUnloading(!!value)} value={isUnloading ? 1 : 0}>
                <RadioItem value={0} label={t('warehouses.forms.warehouseType.loading')} />
                <RadioItem value={1} label={t('warehouses.forms.warehouseType.unloading')} />
              </Radio>
            </FormItemWrapper>
          </FormGroup>
        </ModalSwitcher>

        {isUnloading ? <AddUnloadingModalContent formik={secondFormik} /> : <AddLoadingModalContent formik={formik} />}
      </SharedModalContent>

      <ADefaultModalFooter
        progress={formProgress}
        confirmButtonText={t('common:save')}
        onConfirm={() => (isUnloading ? secondFormik.handleSubmit() : formik.handleSubmit())}
        onClose={() => setIsOpen(false)}
        cancelButtonText={t('common:cancel')}
      />
    </ASharedModal>
  )
}
