import { FC, useState } from 'react'
import styled from 'styled-components'
import { Icons } from '@agro-club/agroclub-shared'
import { RemoveTripModal } from '../../../../../CarriersKR/RemoveTripModal/RemoveTripModal'
import { ListRequestParams } from 'modules/domain/types'
import { AButton } from 'views/components/Analytics'

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`

type Props = {
  tripId: string
  listRequestParams: ListRequestParams
  setListRequestParams: (params: ListRequestParams) => void
}
export const OnApprovalTripCell: FC<Props> = ({ tripId, listRequestParams, setListRequestParams }) => {
  const [removeModal, setRemoveModal] = useState(false)

  return (
    <ButtonWrapper>
      <AButton
        id="removeTrip"
        size="small"
        intent="destruct"
        Icon={Icons.IconRemove}
        onClick={() => setRemoveModal(true)}
      />
      {removeModal && (
        <RemoveTripModal
          onClose={setRemoveModal}
          tripId={tripId}
          onSuccess={() => setListRequestParams({ ...listRequestParams })}
        />
      )}
    </ButtonWrapper>
  )
}
