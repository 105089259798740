import { t } from 'i18next'
import { Deal, DealStatusTypes, Executor } from 'modules/domain/deal/types'
import { formatDistance } from 'modules/utils/numbers/formatDistance'
import { formatQuantity } from 'modules/utils/numbers/formatQuantity'
import { formatPriceLogistics } from 'modules/utils/numbers/formatPriceLogistics'
import { ApprovalStatus, RegionalWorkType, SignStatus } from './types'

export const getContractSignStatuses = (deal: Deal | undefined) => {
  const isUsersVerified = deal?.sale_bid.owner.profile?.is_verified && deal?.purchase_bid.owner.profile?.is_verified
  const approvalStatus = deal?.approval_status
  const approvedByLogistic =
    approvalStatus === ApprovalStatus.APPROVED_BY_LOGISTICIANS || approvalStatus === ApprovalStatus.APPROVED_BY_DIRECTOR

  if (!approvedByLogistic) {
    return {
      logistic: SignStatus.ACTIVE,
      users: SignStatus.INACTIVE,
      sign: SignStatus.INACTIVE,
    }
  }

  if (!isUsersVerified) {
    return {
      logistic: SignStatus.DONE,
      users: SignStatus.ACTIVE,
      sign: SignStatus.INACTIVE,
    }
  }

  if (approvalStatus !== ApprovalStatus.APPROVED_BY_DIRECTOR) {
    return {
      users: SignStatus.DONE,
      logistic: SignStatus.DONE,
      sign: SignStatus.ACTIVE,
    }
  }

  return {
    users: SignStatus.DONE,
    logistic: SignStatus.DONE,
    sign: SignStatus.DONE,
  }
}

const DealStatuses = [
  DealStatusTypes.DRAFT,
  DealStatusTypes.NEW,
  DealStatusTypes.IN_PROGRESS,
  DealStatusTypes.CUSTOMER_NEGOTIATIONS,
  DealStatusTypes.AGREED,
  DealStatusTypes.CONTRACT_SIGNED,
  DealStatusTypes.PAYMENT_SHIPMENT,
  DealStatusTypes.PICK_UP_COMPLETE,
  DealStatusTypes.SHIPMENT_COMPLETE,
  DealStatusTypes.DOCUMENTS_ACCEPTED,
  DealStatusTypes.PAYMENT_COMPLETE,
]

export const isRatingAvailable = (currentStep: DealStatusTypes, closed_at: string | null, permissions: string[]) => {
  const currentStepIndex = DealStatuses.indexOf(currentStep)
  const isRatingVisible = permissions.includes('deals.view_rating')

  return !closed_at && isRatingVisible && currentStepIndex > DealStatuses.indexOf(DealStatusTypes.AGREED)
}

export const isStatusAfter = (currStep: string, targetStep: DealStatusTypes): boolean => {
  const currIndex = DealStatuses.indexOf(currStep as DealStatusTypes)
  const targetIndex = DealStatuses.indexOf(targetStep)

  return currIndex >= targetIndex
}

const getCommonWarehouseParams = (deal) => {
  const commonParams = deal.sale_bid.address.vehicle_type?.filter((vehicle_type) =>
    deal.purchase_bid.address.vehicle_type?.includes(vehicle_type),
  )

  return commonParams?.map((item) => t(`deal:vehicle_type.${item}`))
}

const getVehicleType = (deal) => {
  return deal.sale_bid.address.loader_type?.map((item) => t(`deal:vehicle_type.${item}`))
}

export const formatCoordinatorText = (deal) => {
  const cultureData = `${deal.product.title} ${formatQuantity(deal.quantity)}`
  const loadingData = t('deal:loadingFeatures', { loader_type: getVehicleType(deal) })
  const loadingSchedule =
    deal.sale_bid.address?.schedule_from && deal.sale_bid.address?.schedule_to
      ? t('deal:loadingSchedule', {
          from: deal.sale_bid.address?.schedule_from,
          to: deal.sale_bid.address?.schedule_to,
        })
      : ''
  const scalesParameters = `${t('deal:farm_scales')} ${
    deal.sale_bid.address?.scales_remoteness
      ? t('deal:scales_remoteness', { scales_remoteness: deal.sale_bid.address?.scales_remoteness })
      : t('common:notSpecified')
  } ${
    deal.sale_bid.address?.scales_capacity
      ? formatQuantity(deal.sale_bid.address?.scales_capacity)
      : t('common:notSpecified')
  } ${
    deal.sale_bid.address?.gates_height
      ? t('deal:gate_height', { gates_height: deal.sale_bid.address?.gates_height })
      : t('common:notSpecified')
  }`
  const logisticians =
    deal.executors.logisticians[0] &&
    `${t('deal:logisticianExecutor')} ${deal.executors.logisticians[0]?.full_name} ${
      deal.executors.logisticians[0]?.phone
    }`
  const regional =
    deal.executors.regionals[0] &&
    `${t('deal:regionalExecutor')} ${deal.executors.regionals[0]?.full_name} ${deal.executors.regionals[0]?.phone}`

  const data = [
    deal.sale_bid.company.name,
    cultureData,
    deal.sale_bid.address?.address,
    deal.purchase_bid.company.name,
    deal.purchase_bid.address?.address,
    t('deal:copyButtonCommentText'),
    getCommonWarehouseParams(deal),
    `${loadingData} ${loadingSchedule}`,
    scalesParameters,
    formatDistance(deal.distance),
    formatPriceLogistics(deal.price_logistics),
    t('deal:from'),
    t('deal:to'),
    `ID ${deal.id}`,
    logisticians,
    regional,
  ]
    .filter(Boolean)
    .join('\r\n')

  return navigator.clipboard.writeText(data)
}

export const formatLogisticianText = (deal) => {
  const cultureData = `🌾 ${deal.product.title} ${formatQuantity(deal.quantity)}`
  const loadingWarehouseParameters = `${t('deal:loading')} ${getVehicleType(deal)}`
  const logisticians =
    deal.executors.logisticians[0] &&
    `${deal.executors.logisticians[0]?.full_name} ${deal.executors.logisticians[0]?.phone}`

  const data = [
    cultureData,
    `✅ ${deal.sale_bid.address?.address}`,
    `➡️ ${deal.purchase_bid.address?.address}`,
    getCommonWarehouseParams(deal),
    loadingWarehouseParameters,
    `${t('deal:deal_comment')}`,
    `🏁 ${formatDistance(deal.distance)}`,
    `${t('deal:logistic_price', { price: formatPriceLogistics(deal.price_carrier) })} 🔥`,
    logisticians,
  ]
    .filter(Boolean)
    .join('\r\n')

  return navigator.clipboard.writeText(data)
}

export const executorsFormFormatErrors = (err) => {
  if (!err.regionals_with_type) return err
  return {
    ...err,
    sample: err.regionals_with_type[0]?.regional,
    documents: err.regionals_with_type[1]?.regional,
    loading: err.regionals_with_type[2]?.regional,
  }
}

export const workTypes = Object.keys(RegionalWorkType)

export const isOneRegionalForAllJobs = (regionals: Executor[] | undefined) => {
  if (!regionals?.length) return false
  const regionalIds = regionals.map((regional) => regional.id)

  if (regionalIds.length !== workTypes.length) {
    return false
  }

  return [...new Set(regionalIds)].length === 1
}

export const getRegionalListSortedByWorkStage = (regionals: Executor[] | undefined) => {
  if (!regionals?.length || !regionals?.[0]?.work_type) {
    return regionals
  }
  return workTypes
    .map((type) => regionals.find((regional) => regional.work_type === type))
    .filter(Boolean) as Executor[]
}

export const getOtherRegionalsCount = (regionals: Executor[]) => {
  if (regionals.length < 1) return 0

  const regionalIds = regionals.map((regional) => regional.id)
  const uniqueIdsArr = [...new Set(regionalIds)]
  const otherRegionalCount = uniqueIdsArr.length - 1
  return otherRegionalCount
}
