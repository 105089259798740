import { Route, Routes } from 'react-router-dom'
import { FC } from 'react'
import RatesList from './RatesList'
import RatesRoutes from './routes'

const RatesPage: FC = () => (
  <Routes>
    <Route index element={<RatesList />} />
    <Route path={RatesRoutes.relativeListWithTab} element={<RatesList />} />
  </Routes>
)
export default RatesPage
