import { FC } from 'react'

import { Select, SelectProps } from '@agro-club/agroclub-shared'
import { useAnalyticsContext } from 'analytics/hooks'
import { AnalyticsFilterVariant } from 'analytics/types'

interface Props extends Omit<SelectProps, 'id'> {
  id: AnalyticsFilterVariant
  place?: string
}

const ASelect: FC<Props> = ({ id, value, onChange, place, ...otherProps }) => {
  const { track } = useAnalyticsContext()

  const handleChange = (newValue: any, option: any) => {
    onChange?.(newValue, option)

    const eventName = `change|${id}`

    track(eventName, {
      filter: id,
      place,
      value: {
        from: value,
        to: newValue,
      },
    })
  }

  return <Select value={value} onChange={handleChange} data-testid={id} {...otherProps} />
}

export default ASelect
