import { FilterWorksWith, Team, TeamPlan } from 'modules/domain/teams/types'
import { endpoints } from 'modules/endpoints'
import { apiClient } from 'modules/utils/httpClient'
import { ListResponse } from 'types/api.d'

export const getMembers = (worksWith: FilterWorksWith) =>
  apiClient.get<ListResponse<Team>>(endpoints.teamsMembers(), { works_with: worksWith })

export const getPlans = (worksWith: FilterWorksWith, schedule_date?: string, schedule_year?: number) =>
  apiClient.get<ListResponse<TeamPlan>>(endpoints.teamsPlans(), {
    works_with: worksWith,
    schedule_date,
    schedule_year,
  })

export const setPlans = (volume: number, team: number, schedule_date: string) =>
  apiClient.post<TeamPlan>(endpoints.teamsPlans(), {
    volume,
    team,
    schedule_date,
  })

export const editPlan = (volume: number, id: number) =>
  apiClient.put<{ volume: number }>(endpoints.teamsPlans(id), {
    volume,
  })

export const getPlansById = (id: string) => apiClient.get<TeamPlan[]>(endpoints.teamsPlansById(id))

export const getMembersById = (id: string) => apiClient.get<Team>(endpoints.teamsMembersById(id))
