import { CloseDealModal } from 'views/components/CloseDealModal/CloseDealModal'
import AMobileButton from 'views/components/Analytics/AMobileButton'
import { Deal, DealStatusTypes } from 'modules/domain/deal/types'
import { Icons, defaultTheme } from '@agro-club/agroclub-shared'
import { AButton } from 'views/components/Analytics'
import { useMediaQuery } from 'react-responsive'
import { useTranslation } from 'react-i18next'
import { FC, useState } from 'react'

export const CloseDeal: FC<{ deal: Deal }> = ({ deal }) => {
  const { t } = useTranslation('deal')
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const isMobile = useMediaQuery({
    query: `(max-width: ${defaultTheme.queries.mobile})`,
  })
  return (
    <>
      {isMobile ? (
        <AMobileButton
          disabled={deal.status === DealStatusTypes.DRAFT}
          onClick={() => setIsOpen(true)}
          Icon={Icons.IconClose}
          intent="destruct"
          id="close"
        >
          {t('closeDeal')}
        </AMobileButton>
      ) : (
        <AButton
          disabled={deal.status === DealStatusTypes.DRAFT}
          onClick={() => setIsOpen(true)}
          intent="destruct"
          size="medium"
          id="close"
        >
          {t('closeDeal')}
        </AButton>
      )}

      {isOpen && <CloseDealModal onClose={() => setIsOpen(false)} />}
    </>
  )
}
