import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Progress, useHelmet } from '@agro-club/agroclub-shared'
import * as Layout from 'views/layouts/NewLayout'
import * as Header from 'views/ui/Header/Header'
import { HeaderButtons } from '../../components/styled'
import { useSingleEntity } from 'modules/domain/common/hooks'
import { LogisticsKRLoadingWarehouse, LogisticsKRUnloadingWarehouse } from '../../types'
import { endpoints } from 'modules/endpoints'
import { useParams } from 'react-router'
import ItemLoadingLayout from 'views/layouts/ItemLoadingLayout/ItemLoadingLayout'
import WarehousesKRRoutes from '../routes'
import { ContentWrapper } from '../../CarsKR/CarsDetails/styled'
import { LeftBlock } from './LeftBlock'
import { EditLoadingModal } from '../WarehousesList/components/Modals/EditModals/EditLoadingModal'
import { EditUnloadingModal } from '../WarehousesList/components/Modals/EditModals/EditUnloadingModal'
import APageWrapper from 'views/components/PageWrapper/APageWrapper'
import { AnalyticsPages, AnalyticsPlaces } from '../../analyticsPlaces'
import { AButton } from 'views/components/Analytics'

export const WarehousesDetails: FC = () => {
  const { t } = useTranslation('logisticsKR')
  const { id, type } = useParams()
  useHelmet({ title: t('warehouses.list.heading') })
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [fetchProgress, warehouse, refetch] = useSingleEntity<
    LogisticsKRLoadingWarehouse | LogisticsKRUnloadingWarehouse
  >(type === 'loading' ? endpoints.LogisticsKR.loadingWarehouse(id) : endpoints.LogisticsKR.unloadingWarehouse(id))

  if (fetchProgress === Progress.WORK || !warehouse) return <ItemLoadingLayout id={id as string} />

  return (
    <APageWrapper page={AnalyticsPages.WAREHOUSES.DETAIL} place={AnalyticsPlaces.WAREHOUSES.DETAIL} id={id}>
      <Layout.WrapperContent>
        <Layout.Header>
          <Layout.TopHeader>
            <Header.Breadcrumbs
              routes={[
                {
                  path: WarehousesKRRoutes.List,
                  breadcrumbName: t('warehouses.list.heading'),
                },
                {
                  breadcrumbName: warehouse.id,
                },
              ]}
            />

            <HeaderButtons>
              <AButton id="edit" intent="secondary" onClick={() => setIsModalOpen(true)}>
                {t('common:edit')}
              </AButton>
            </HeaderButtons>
          </Layout.TopHeader>
          <Header.Title title={t('warehouses.details.heading')} compact />
        </Layout.Header>
        <ContentWrapper>
          <LeftBlock warehouse={warehouse} />
        </ContentWrapper>
        {isModalOpen && type === 'loading' && (
          <EditLoadingModal setIsOpen={setIsModalOpen} onSuccess={() => refetch()} warehouse={warehouse} />
        )}
        {isModalOpen && type === 'unloading' && (
          <EditUnloadingModal setIsOpen={setIsModalOpen} onSuccess={() => refetch()} warehouse={warehouse} />
        )}
      </Layout.WrapperContent>
    </APageWrapper>
  )
}
