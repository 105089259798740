import { Progress, Text } from '@agro-club/agroclub-shared'
import { ACCORDION_ITEM_PAGE_SIZE } from 'modules/constants'
import { usePagination, useTableData } from 'modules/domain/common/hooks'
import { Employee } from 'modules/domain/logisticsKR/types'
import { ListRequestParams } from 'modules/domain/types'
import { endpoints } from 'modules/endpoints'
import { useEffect } from 'react'
import { BorderedItem } from 'views/components/BorderedItem/BorderedItem'
import { Pagination } from 'views/components/Pagination/Pagination'
import APhone from 'views/ui/Phone/APhone'

type EmployeeItemProps = {
  employee: Employee
}

export const EmployeeItem = ({ employee }: EmployeeItemProps) => {
  return (
    <BorderedItem>
      <div>
        <Text>{employee.profile?.full_name}</Text>
        <APhone phone={employee.phone} />
      </div>
    </BorderedItem>
  )
}

type EmployeesProps = {
  listRequestParams: ListRequestParams
  carrierId: string
  setSummary: (arg: number) => void
}

export const Employees = ({ listRequestParams, carrierId, setSummary }: EmployeesProps) => {
  const { data: employees = [], progress } = useTableData(
    endpoints.LogisticsKR.carrierEmployees(carrierId),
    listRequestParams,
    !!listRequestParams.filter.carrier,
  )
  const [employeesCurrentPage, setEmployeesCurrentPage, employeesCurrentItems] = usePagination(
    employees,
    ACCORDION_ITEM_PAGE_SIZE,
  )

  useEffect(() => {
    if (progress === Progress.SUCCESS) setSummary(employees.length)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employees.length])

  return (
    <div>
      {!!employeesCurrentItems?.length && (
        <div>
          {employeesCurrentItems.map((employee) => (
            <EmployeeItem key={employee.id} employee={employee} />
          ))}
        </div>
      )}
      <Pagination total={employees?.length} currPage={employeesCurrentPage} setCurrPage={setEmployeesCurrentPage} />
    </div>
  )
}
