import { SpecificationForm } from 'modules/domain/specification/types'
import { endpoints } from 'modules/endpoints'
import { apiClient } from 'modules/utils/httpClient'

export const blockUser = (id: string) => apiClient.post(endpoints.blockUser(id))

export const deleteDocument = (userId: string, fileId: string) => apiClient.delete(endpoints.userFiles(userId, fileId))

export const deleteDocumentFile = (id: string, document_id: string) =>
  apiClient.delete(endpoints.userDeleteDocumentFile(id, document_id))

export const editUserSpecification = (userId: string, itemId: string, data: SpecificationForm) => {
  return apiClient.put<SpecificationForm>(endpoints.userSpecification(userId, itemId), data)
}
