import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router'

import useFormatDate from 'hooks/useFormatDate'
import { DashboardTask } from 'modules/domain/dashboard/types'

import { isBrazil } from 'env'
import { refetchFunc } from 'modules/domain/common/hooks'
import { formatDistance } from 'modules/utils/numbers/formatDistance'
import { formatPriceLogistics } from 'modules/utils/numbers/formatPriceLogistics'
import { formatQuantity } from 'modules/utils/numbers/formatQuantity'
import { DealCardPrice } from 'views/components/DealCard/DealCardPrice'
import { DeliveryPeriodData } from 'views/components/DealCard/DeliveryPeriod'
import { DefaultTooltip } from 'views/components/DefaultTooltip/DefaultTooltip'
import DealRoutes from 'views/pages/Deal/routes'
import UserRoutes from 'views/pages/User/routes'
import { TextCard } from 'views/styled/ItemCardStyles'
import { CardHeader } from '../CardHeader/CardHeader'
import { AItemLink, Item, ItemBody } from '../styled'
import { CommonInfo, CompanyLink } from './styled'

type Props = {
  task: DashboardTask
  refetch: refetchFunc
}

export const TaskDealItem: FC<Props> = ({ task, ...props }) => {
  const deal = task.linked_deal
  const { t } = useTranslation('dashboard')
  const { pathname, search } = window.location
  const saleBidOwnerId = deal?.sale_bid.owner
  const purchaseBidOwnerId = deal?.purchase_bid.owner
  const formatDate = useFormatDate()

  if (!deal) return null

  return (
    <Item>
      {deal.id && (
        <AItemLink
          eventName="deal"
          itemId={deal.id}
          state={{ pathname, search }}
          to={generatePath(DealRoutes.Details, { id: deal.id })}
        />
      )}

      <CardHeader task={task} {...props} />

      <ItemBody>
        <CommonInfo>
          <TextCard>
            {t('dealId')} {deal.id}
          </TextCard>
          <TextCard>
            {t('created')} {formatDate(deal.created, true)}
          </TextCard>
        </CommonInfo>

        <DealCardPrice deal={deal} />

        <DefaultTooltip tooltipContent={deal.sale_bid.address.address}>
          <CompanyLink
            eventName="saleBidCompany"
            itemId={saleBidOwnerId}
            to={generatePath(UserRoutes.Details, { id: saleBidOwnerId })}
          >
            {deal.sale_bid.company.name || t('company:list.emptyCompanyName')}
          </CompanyLink>
        </DefaultTooltip>

        <DefaultTooltip tooltipContent={deal.purchase_bid.address.address}>
          <CompanyLink
            eventName="purchaseBidCompany"
            itemId={purchaseBidOwnerId}
            to={generatePath(UserRoutes.Details, { id: purchaseBidOwnerId })}
          >
            {deal.purchase_bid.company.name || t('company:list.emptyCompanyName')}
          </CompanyLink>
        </DefaultTooltip>

        <CommonInfo>
          <TextCard>
            {deal.product.title}, {deal.season && deal.season.name}
          </TextCard>
          <TextCard>
            {formatQuantity(deal.quantity)}, {formatDistance(deal.distance)},{' '}
            {formatPriceLogistics(deal.price_logistics)}
          </TextCard>
          {!isBrazil && <DeliveryPeriodData data={deal} />}
        </CommonInfo>
        <TextCard thumbnail>{t('margin', { value: deal.margin_percent })}</TextCard>
      </ItemBody>
    </Item>
  )
}
