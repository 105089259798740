import { isRussia } from 'env'
import { Deal, DealStatusTypes } from 'modules/domain/deal/types'
import { formatCoordinatorText, formatLogisticianText, isStatusAfter } from './helpers'
import { AButton } from 'views/components/Analytics'
import { AntDropdown, Icons, Progress } from '@agro-club/agroclub-shared'
import { TooltipIcon } from 'views/components/TooltipIcons/TooltipIcons'
import { useTranslation } from 'react-i18next'
import { useNotification } from 'hooks/useNotification'

interface Props {
  deal: Deal
}

export const DealCopyText = ({ deal }: Props) => {
  const { t } = useTranslation('deal')
  const notify = useNotification()
  const handleFormatText = async (formatTextFunc, deal) => {
    await formatTextFunc(deal)
    notify(Progress.SUCCESS, { title: t('logistician_text_notification') })
  }

  const items = [
    {
      key: t('bid_for_coordinator'),
      label: <div onClick={() => handleFormatText(formatCoordinatorText, deal)}>{t('bid_for_coordinator')}</div>,
    },
    {
      key: t('bid_for_carrier'),
      label: <div onClick={() => handleFormatText(formatLogisticianText, deal)}>{t('bid_for_carrier')}</div>,
    },
  ]

  if (!isRussia || !isStatusAfter(deal.status, DealStatusTypes.CONTRACT_SIGNED)) return null

  return (
    <>
      <TooltipIcon isVisible text={t('copy_text_icon')}>
        <AntDropdown
          triggerElement={<AButton id="edit" size="medium" intent="white" Icon={Icons.IconCopy} />}
          items={items}
        />
      </TooltipIcon>
    </>
  )
}
