import { Text, VerifiedByAll, WrapperIcon } from '../../styled'
import { Icons } from '@agro-club/agroclub-shared'
import { useTranslation } from 'react-i18next'

export const VerifiedUser = () => {
  const { t } = useTranslation('user')

  return (
    <VerifiedByAll>
      <WrapperIcon>
        <Icons.IconCheckMark />
      </WrapperIcon>
      <Text>{t('verify_user')}</Text>
    </VerifiedByAll>
  )
}
