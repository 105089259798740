import { FC } from 'react'
import DealsTable from './DealsTable'
import GeneralInformation from './GeneralInformation'
import { Specification } from 'modules/domain/specification/types'

interface Props {
  specification: Specification
}

export const SpecificationLeftBlock: FC<Props> = ({ specification }) => {
  return (
    <div>
      <GeneralInformation specification={specification} />
      <DealsTable id={specification.id} />
    </div>
  )
}
