import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import { CellRenders } from 'views/components/Table/renderers'
import { USBid } from 'modules/domain/bid/types'
import { ColumnsType } from '@agro-club/agroclub-shared'
import { DatePeriodFormatted } from 'views/components/DatePeriodFormatted'
import BidRoutes from 'views/pages/Bid/routes'
import { generatePath } from 'react-router'
import { TableTextLink } from 'views/ui/TableTextLink/TableTextLink'
import formatNumber from 'modules/utils/numbers/formatNumber'

export const useBestColumns = (): ColumnsType<USBid> => {
  const { t } = useTranslation('bid')

  return useMemo(
    () => [
      {
        title: t('usShortBid.crop'),
        dataIndex: 'product',
        render: (product, bid) => {
          return (
            <div>
              <TableTextLink
                bold
                target="_blank"
                to={generatePath(BidRoutes.Details, { id: bid.id })}
                tableItemId={bid.id}
                id="bid"
              >
                {product.title}, <span style={{ whiteSpace: 'nowrap' }}>{bid.symbol}</span>
              </TableTextLink>
              <DatePeriodFormatted period={{ start_date: bid.start_date, end_date: bid.end_date }} />
            </div>
          )
        },
      },
      {
        title: t('usShortBid.futures'),
        dataIndex: 'futures_price',
        width: 72,
        align: 'right',
        render: CellRenders.price,
      },
      {
        title: t('common:fob'),
        align: 'center',
        children: [
          {
            title: t('usShortBid.basis'),
            dataIndex: 'price_exw_basis',
            width: 64,
            align: 'right',
            render: CellRenders.price,
          },
          {
            title: t('usShortBid.cash'),
            dataIndex: 'price_exw',
            width: 64,
            align: 'right',
            render: CellRenders.price,
          },
        ],
      },
      {
        title: t('usShortBid.delivery'),
        align: 'center',
        children: [
          {
            title: t('common:remoteness'),
            width: 70,
            dataIndex: 'distance',
            align: 'right',
            render: (d) => formatNumber(d),
          },
          {
            title: t('usShortBid.basis'),
            dataIndex: 'sale_price_delivered_basis',
            width: 64,
            align: 'right',
            render: CellRenders.price,
          },
          {
            title: t('usShortBid.cash'),
            dataIndex: 'sale_price_delivered',
            width: 64,
            align: 'right',
            render: CellRenders.price,
          },
        ],
      },
    ],
    [t],
  )
}
