import { formatTarif } from 'modules/utils/numbers/formatTarif'
import { useTranslation } from 'react-i18next'
import { Card } from 'views/components/Card/Card'
import { KeyValue, Value } from 'views/components/KeyValue/KeyValue'
import { useDealContext } from '../DealContext'
import { UserLink } from 'views/components/UserLink/UserLink'
import { isRussia } from 'env'

export const BrokerInfo = () => {
  const { deal } = useDealContext()
  const { t } = useTranslation('deal')
  const sellerBroker = deal.seller_broker ?? null
  const customerBroker = deal.customer_broker ?? null
  if (!customerBroker && !sellerBroker) {
    return null
  }
  const hasBothBlocks = sellerBroker && customerBroker
  return (
    <Card.Container intent="tertiary" padding="8px">
      <Card.Grid cols={hasBothBlocks ? 2 : 1} maxColumnWidth={hasBothBlocks ? '288px' : undefined} columnGap={24}>
        {sellerBroker && (
          <KeyValue label={isRussia ? t('broker.seller_agent') : t('broker.seller_broker')}>
            <Value typography="bodyLarge">
              <span>
                {t('broker.broker_commission')} {formatTarif(sellerBroker.commission)}
                {', '}
                <UserLink user={sellerBroker.broker} />
              </span>
            </Value>
          </KeyValue>
        )}
        {customerBroker && (
          <KeyValue label={t('broker.customer_broker')}>
            <Value typography="bodyLarge">
              <span>
                {t('broker.broker_commission')} {formatTarif(customerBroker.commission)}
                {', '}
                <UserLink user={customerBroker.broker} />
              </span>
            </Value>
          </KeyValue>
        )}
      </Card.Grid>
    </Card.Container>
  )
}
