import { useHelmet } from '@agro-club/agroclub-shared'

import { useTranslation } from 'react-i18next'

import * as Layout from 'views/layouts/NewLayout'
import { TeamsList } from 'views/pages/Teams/TeamsList'
import * as Header from 'views/ui/Header/Header'
import { FC } from 'react'
import { AnalyticsPages, AnalyticsPlaces } from 'views/pages/Teams/analyticsPlaces'
import APageWrapper from 'views/components/PageWrapper/APageWrapper'

export const TeamsView: FC = () => {
  const { t } = useTranslation('teams')
  useHelmet({ title: t('teams') })

  return (
    <APageWrapper page={AnalyticsPages.TEAMS} place={AnalyticsPlaces.LIST}>
      <Layout.WrapperContent>
        <Layout.Header>
          <Layout.TopHeader>
            <Header.Breadcrumbs
              routes={[
                {
                  breadcrumbName: t('teams'),
                },
              ]}
            />
          </Layout.TopHeader>

          <Layout.PageName>{t('teams')}</Layout.PageName>
        </Layout.Header>
        <TeamsList />
      </Layout.WrapperContent>
    </APageWrapper>
  )
}
