import { useNotification } from 'hooks/useNotification'
import { Progress } from '@agro-club/agroclub-shared'
import { apiClient } from 'modules/utils/httpClient'
import { useProgress } from 'hooks/useProgress'
import { RequestError } from 'modules/errors'

export const useEntityTaskStat = <T,>(setEntityStat: (stat: T) => void, endpoint: () => string) => {
  const notify = useNotification()

  return useProgress(async (filter) => {
    try {
      const resp = await apiClient.get<T>(endpoint(), filter)
      setEntityStat(resp)
    } catch (error) {
      const parsedError = RequestError.parseError(error)
      notify(Progress.ERROR, { title: parsedError?.detail })
    }
  })
}
