import { Icons } from '@agro-club/agroclub-shared'
import { LogisticRequest } from 'modules/domain/logisticsKR/types'
import { FC, useState } from 'react'
import { LogisticsKRTrip } from 'views/pages/LogisticsKR/types'
import styled from 'styled-components'
import { AButton } from 'views/components/Analytics'
import { AddDraftTripModal } from 'views/pages/LogisticsKR/components/AddDraftTripModal/AddDraftTripModal'

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`

export interface Props {
  request: LogisticRequest
  refetchTripsReconciliation: () => Promise<void> | void
  trip?: LogisticsKRTrip
}

export const CarToTripCell: FC<Props> = ({ request, refetchTripsReconciliation, trip }) => {
  const [addCarModal, setAddCarModal] = useState(false)

  return (
    <ButtonWrapper>
      <AButton
        id="carToTrip"
        intent="secondary"
        Icon={Icons.IconAddPlusCircle}
        size="small"
        onClick={() => setAddCarModal(true)}
      />
      {addCarModal && (
        <AddDraftTripModal
          carrier={trip?.carrier}
          agent={trip?.agent}
          carrierHasVat={trip?.has_vat}
          agentHasVat={trip?.has_agent_vat}
          carId={trip?.car?.id}
          trailerId={trip?.trailer?.id}
          driverId={trip?.driver?.id}
          onClose={() => setAddCarModal(false)}
          onSuccess={refetchTripsReconciliation}
          activeRequest={request}
        />
      )}
    </ButtonWrapper>
  )
}
