import { ASharedModal, SharedModalContent, SharedModalTitle } from 'views/components/SharedModal'
import ADefaultModalFooter from 'views/components/DefaultModalFooter/ADefaultModalFooter'
import { refetchFunc } from 'modules/domain/common/hooks'
import { apiClient } from 'modules/utils/httpClient'
import { Text } from '@agro-club/agroclub-shared'
import { useAProgress } from 'hooks/useAProgress'
import { Deal } from 'modules/domain/deal/types'
import { useTranslation } from 'react-i18next'
import { endpoints } from 'modules/endpoints'
import { FC } from 'react'

type Props = {
  refetch: refetchFunc
  onClose: () => void
  contractId: string
  deal: Deal
}

export const ConfirmModal: FC<Props> = ({ deal, onClose, contractId, refetch }) => {
  const { t } = useTranslation('export')
  const [attachProgress, attachHandler] = useAProgress(
    () => apiClient.put(endpoints.attachContract(deal.id), { export_contract: contractId, confirmation: true }),
    {
      eventName: 'attachContract',
      onSuccess: async () => {
        await refetch()
        onClose()
      },
    },
  )

  return (
    <ASharedModal id="attachContract" onClose={onClose} size="small">
      <SharedModalTitle>{t('attachContract')}</SharedModalTitle>
      <SharedModalContent>
        {!deal.export_contract_id ? (
          t('attachContractDescriptionFirst')
        ) : (
          <div>
            <Text typography="accentLarge">{t('attention')}</Text>
            <Text>{t('attentionDescription')}</Text>
          </div>
        )}
      </SharedModalContent>
      <ADefaultModalFooter progress={attachProgress} onConfirm={attachHandler} onClose={onClose} />
    </ASharedModal>
  )
}
