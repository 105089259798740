import { FC } from 'react'
import { SharedModalInputs } from 'views/components/SharedModal'
import { InfoBlock } from './InfoBlock'
import { ControlsBlock } from './ControlsBlock'
import { SharedModalSecondContent, SharedModalSubTitle } from 'views/components/SharedModal/styled'
import { ProductParams } from './ProductParams/ProductParams'
import { Deal } from 'modules/domain/deal/types'
import { FormikProps } from 'formik'
import { DealContractForm } from '../../../types'
import { useTranslation } from 'react-i18next'
type Props = {
  deal: Deal
  formik: FormikProps<DealContractForm>
}
export const CommonFields: FC<Props> = ({ deal, formik }) => {
  const { t } = useTranslation('deal')
  return (
    <>
      <SharedModalInputs>
        <InfoBlock deal={deal} />
        <ControlsBlock formik={formik} />
      </SharedModalInputs>

      <SharedModalSecondContent>
        <SharedModalSubTitle>{t('requestDocsModal.product_params')}</SharedModalSubTitle>
        <ProductParams formik={formik} productId={deal.product.id} />
      </SharedModalSecondContent>
    </>
  )
}
