import { Icons } from '@agro-club/agroclub-shared'
import { FC, useState } from 'react'
import { AButton } from 'views/components/Analytics'
import { EditRatesModal } from './EditRatesModal'
import { RateType, RatesProductTypes } from '../types'
import { Logistician } from '../../types'

interface Props {
  regionIds?: string[]
  onSuccess: () => void
  ratesData: RateType[]
  responsibleLogisticians?: Logistician[]
  currentProductType?: RatesProductTypes
}

export const EditRatesCell: FC<Props> = ({
  regionIds,
  onSuccess,
  ratesData,
  responsibleLogisticians,
  currentProductType,
}) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  return (
    <>
      <AButton id="edit" intent="secondary" Icon={Icons.IconEdit} size="small" onClick={() => setIsModalOpen(true)} />

      {isModalOpen && (
        <EditRatesModal
          onClose={() => setIsModalOpen(false)}
          regionIds={regionIds}
          refetch={onSuccess}
          ratesData={ratesData}
          responsibleLogisticians={responsibleLogisticians}
          currentProductType={currentProductType}
        />
      )}
    </>
  )
}
