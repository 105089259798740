import { formatTarif } from 'modules/utils/numbers/formatTarif'
import { Text } from '@agro-club/agroclub-shared'
import styled from 'styled-components'
import { FC } from 'react'

const PriceWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const PriceWithBroker: FC<{ price: number; commision?: number; sign: string }> = ({
  price,
  commision,
  sign,
}) => {
  return (
    <PriceWrapper>
      {formatTarif(price)}&nbsp;
      {commision && (
        <Text typography="bodySmall">
          {' '}
          {sign} {formatTarif(commision)}{' '}
        </Text>
      )}
    </PriceWrapper>
  )
}
