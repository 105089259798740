import { SharedModalInputs, SharedModalTitle } from 'views/components/SharedModal'
import { FormPhoneInput } from 'views/components/form/FormPhoneInput'
import { FormGroup, Input, Progress } from '@agro-club/agroclub-shared'
import { useTranslation } from 'react-i18next'
import { FC } from 'react'
import ASharedModal from 'views/components/SharedModal/ASharedModal'
import ADefaultModalFooter from 'views/components/DefaultModalFooter/ADefaultModalFooter'
import { FormikFieldsType } from 'modules/domain/types'

type Props = {
  id: string
  formik: FormikFieldsType
  title?: string
  progress?: Progress
  setShowModal: (payload: boolean) => void
}

export const CommonFields: FC<Props> = ({ setShowModal, formik, title, id, progress = Progress.IDLE }) => {
  const { t } = useTranslation('userContact')

  return (
    <ASharedModal size="medium" onClose={() => setShowModal(false)} id={id}>
      <SharedModalTitle>{title || t('new_contact')}</SharedModalTitle>
      <SharedModalInputs>
        <FormGroup label={t('full_name')} error={formik.errors.full_name} required>
          <Input {...formik.getFieldProps('full_name')} invalid={!!formik.errors.full_name} />
        </FormGroup>
        <FormGroup label={t('position')} error={formik.errors.position} required>
          <Input {...formik.getFieldProps('position')} invalid={!!formik.errors.position} />
        </FormGroup>
        <FormPhoneInput formik={formik} />
      </SharedModalInputs>
      <ADefaultModalFooter
        onConfirm={() => formik.handleSubmit()}
        confirmButtonText={t('common:save')}
        onClose={() => setShowModal(false)}
        disabled={!formik.values?.phone}
        progress={progress}
      />
    </ASharedModal>
  )
}
