import ASelectFilter from 'views/components/Analytics/ASelectFilter'
import { CompanyCommercial } from 'modules/domain/company/types'
import { ListRequestParams } from 'modules/domain/types'
import { useTranslation } from 'react-i18next'
import { FC } from 'react'
import { isUS } from 'env'

interface Props {
  listRequestParams: ListRequestParams
  updateFilterState: (filterObj: Record<string, any>, commaShieldingParam?: string) => void
}

export const CommercialFilter: FC<Props> = ({ listRequestParams, updateFilterState }) => {
  const { t } = useTranslation('company')

  if (!isUS) return null

  const options = [
    {
      value: CompanyCommercial.yes,
      label: t('common:yes'),
    },
    {
      value: CompanyCommercial.no,
      label: t('common:no'),
    },
    {
      value: CompanyCommercial.unknown,
      label: t('common:unknown'),
    },
  ]

  return (
    <ASelectFilter
      onChange={(commercial) => updateFilterState({ commercial })}
      value={listRequestParams.filter.commercial}
      placeholder={t('commercial')}
      id="commercialFilter"
      showSearch={false}
      options={options}
    />
  )
}
