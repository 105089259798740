import { BorderedItem } from 'views/components/BorderedItem/BorderedItem'
import { SamplesForBid } from 'views/pages/Bid/BidDetail/Bid/types'
import { ExternalLink, Progress } from '@agro-club/agroclub-shared'
import { ARouterLink } from 'views/components/Analytics'
import useFormatDate from 'hooks/useFormatDate'
import { useTranslation } from 'react-i18next'
import BidRoutes from 'views/pages/Bid/routes'
import { generatePath } from 'react-router'
import styled from 'styled-components'
import { FC, useEffect } from 'react'
import { useSingleEntity } from 'modules/domain/common/hooks'
import { endpoints } from 'modules/endpoints'

type Props = {
  setSummary: (arg: number) => void
  userId: string
}
const InfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px 0;
`

export const Samples: FC<Props> = ({ setSummary, userId }) => {
  const { t } = useTranslation()
  const formatDate = useFormatDate()

  const [progress, samples = []] = useSingleEntity<SamplesForBid[]>(endpoints.samplesForUser(userId))

  useEffect(() => {
    if (progress === Progress.SUCCESS) setSummary(samples.length)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [samples.length])

  return (
    <div>
      {!!samples?.length &&
        samples.map((sample) => (
          <BorderedItem key={sample.id}>
            <InfoBlock>
              <ARouterLink
                eventName="bid"
                to={generatePath(BidRoutes.Details, {
                  id: sample.bid_id,
                })}
              >
                {`ID ${sample.bid_id}`}
              </ARouterLink>
              <div>{`${sample.title}, ${t('common:from')} ${formatDate(sample.created)}`}</div>
              {sample.files?.length > 0 &&
                sample.files.map((file) => (
                  <ExternalLink href={file.uploaded_file} target="_blank" key={file.id}>
                    {file.original_filename}
                  </ExternalLink>
                ))}
            </InfoBlock>
          </BorderedItem>
        ))}
    </div>
  )
}
