import { ABaseTableFilter } from 'views/components/TableFilters/ABaseTableFilter'
import { t } from 'i18next'
import { endpoints } from 'modules/endpoints'
import { AnalyticsFilterVariant } from 'analytics/types'
import { ListRequestParams } from 'modules/domain/types'
import { FC } from 'react'

interface Props {
  listRequestParams: ListRequestParams
  updateFilterState: (filterObj: Record<string, string>) => void
  placeholder?: string
}

export const ExecutorFilter: FC<Props> = ({
  updateFilterState,
  listRequestParams,
  placeholder = t('bid:filterByExecutor'),
}) => {
  return (
    <ABaseTableFilter
      onChange={(created_by) => updateFilterState({ created_by })}
      value={listRequestParams.filter.created_by}
      placeholder={placeholder}
      getLabel={(label) => label.full_name}
      endpoint={endpoints.coordinatorsShort()}
      searchField="search"
      id={AnalyticsFilterVariant.AUTHOR}
    />
  )
}
