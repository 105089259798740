import { labelQuantity } from 'modules/utils/numbers/formatQuantity'

import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import { AntTag, ColumnsType } from '@agro-club/agroclub-shared'
import { DeliveryBasis, Export, ExportStatus, CurrencySign } from 'modules/domain/export/types'
import { TableTextLink } from 'views/ui/TableTextLink/TableTextLink'
import { generatePath } from 'react-router'
import ExportRoutes from '../routes'
import formatPrice from 'modules/utils/numbers/formatPrice'
import { DASH } from 'modules/constants'
import formatNumber from 'modules/utils/numbers/formatNumber'

const useColumns = (): ColumnsType<Export> => {
  const { t } = useTranslation('export')

  return useMemo(
    () => [
      {
        title: t('crop'),
        dataIndex: 'product',
        width: 168,
        render: (product, row) => (
          <TableTextLink id="company" tableItemId={row.id} to={generatePath(ExportRoutes.Details, { id: row.id })}>
            {product.title}
          </TableTextLink>
        ),
      },
      {
        title: t('numberContract'),
        dataIndex: 'number',
        width: 130,
      },
      {
        title: t('status'),
        dataIndex: 'status',
        width: 108,
        align: 'center',
        render: (status) => {
          const isClosed = status === ExportStatus.closed
          const isActive = status === ExportStatus.active
          const text = (isClosed && t('closed')) || (isActive && t('active')) || t('fulfilled')
          const color = (isClosed && 'grey') || (isActive && 'green') || 'blue'
          return <AntTag color={color}>{text}</AntTag>
        },
      },
      {
        title: t('deliveryBasis'),
        dataIndex: 'delivery_basis',
        width: 130,
        render: (delivery_basis) => (delivery_basis === DeliveryBasis.CIF ? t('cif') : t('fob')),
      },
      {
        title: labelQuantity(t('exportVolume')),
        dataIndex: 'quantity',
        sorter: true,
        width: 200,
        render: (quantity) => formatNumber(quantity),
      },
      {
        title: t('contractValue'),
        sorter: true,
        dataIndex: 'price',
        width: 200,
        render: (price, row) => (price ? `${formatPrice(price)} ${CurrencySign[row.price_currency]}` : DASH),
      },
      {
        title: t('buyer'),
        dataIndex: 'purchaser',
        width: 417,
        render: (purchaser) => purchaser?.name,
      },
      {
        title: t('vesselName'),
        dataIndex: 'ship',
        width: 417,
      },
    ],
    [t],
  )
}

export default useColumns
