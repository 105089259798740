import { CompanyCommercial } from 'modules/domain/company/types'
import { AntTag } from '@agro-club/agroclub-shared'
import { useTranslation } from 'react-i18next'
import { FC } from 'react'
import { isUS } from 'env'

export const CompanyCommercialTag: FC<{ commercial }> = ({ commercial }) => {
  const { t } = useTranslation('company')
  if (commercial !== CompanyCommercial.yes || !isUS) return

  return <AntTag color="magenta">{t('commercial')}</AntTag>
}
