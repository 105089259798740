const main = 'tasks'
const relativeRoutes = {
  relativePage: `${main}/*`,
  relativeList: `${main}`,
  relativeDetail: ':id',
}

const routes = {
  List: `/${main}`,
  Details: `/${main}/${relativeRoutes.relativeDetail}`,
}

const TasksRoutes = { ...relativeRoutes, ...routes }

export default TasksRoutes
