import { MobileMenu, defaultTheme } from '@agro-club/agroclub-shared'
import { useMobileOptions } from '../Options/useMobileOptions'
import { AuthContext } from 'modules/context/AuthContext'
import { getActiveRoute, useNavigation } from '../utils'
import { useAnalyticsContext } from 'analytics/hooks'
import styled from 'styled-components'
import { FC, useContext } from 'react'

const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${defaultTheme.color.backgroundPrimary};
  * {
    user-select: none;
    -webkit-tap-highlight-color: transparent;
  }
`

export const MobileMainMenu: FC = () => {
  const { isCurrent } = useNavigation()

  const { menuOptions } = useMobileOptions()
  const activeRoutes = getActiveRoute(menuOptions, isCurrent)
  const { track } = useAnalyticsContext()

  const { logOut } = useContext(AuthContext)

  const handleLogout = () => {
    track('logout', {
      place: 'MainMenu',
    })
    logOut()
  }

  return (
    <Wrapper>
      <MobileMenu options={menuOptions} activeRoutes={activeRoutes} onExit={handleLogout} />
    </Wrapper>
  )
}
