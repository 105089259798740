import { FormGroup, Input, Progress, defaultTheme } from '@agro-club/agroclub-shared'
import { isBrazil } from 'env'
import { FormikProps } from 'formik'
import { Deal } from 'modules/domain/deal/types'
import { ProfileType, User } from 'modules/domain/user/types'
import { labelDistance } from 'modules/utils/numbers/formatDistance'
import { labelPriceLogistics } from 'modules/utils/numbers/formatPriceLogistics'
import { labelTarif } from 'modules/utils/numbers/formatTarif'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import styled from 'styled-components'
import { ResponsiveSelectAddButton } from 'views/components/FormSelectButtons/FormSelectButtons'
import { FormSelectActiveUser } from 'views/components/FormSelectUser'
import { QuantityInput } from 'views/components/QuantityInput/QuantityInput'
import { SharedModalGroupContent, SharedModalSubTitle } from 'views/components/SharedModal/styled'
import { ShortFormControls } from 'views/styled/common'
import { CloningFormFields, CloningTypes } from '../../types'
import { FormSelectBid } from './FormSelectBid'
import { FormSkeleton } from './FormSkeleton'
import { RadioButtons } from './RadioButtons'
import { SelectWithButton, WarningContainer } from './styled'

type Props = {
  changeCurrentProfileTypeHandler: (value: ProfileType) => void
  setCurrentCloningType: (value: CloningTypes) => void
  formik: FormikProps<CloningFormFields>
  setCurrentUser: (dto: User) => void
  handleBidOptionLoaded: (any) => void
  currentProfileType: ProfileType
  fieldResetHandler: () => void
  bidControlAction: () => void
  getParamsProgress: Progress
  warningText?: string
  deal: Deal
}
const MobileContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;

  div:nth-child(5) {
    grid-column: span 2;
  }
`

export const BidCloningFields: FC<Props> = ({
  changeCurrentProfileTypeHandler,
  handleBidOptionLoaded,
  setCurrentCloningType,
  currentProfileType,
  getParamsProgress,
  fieldResetHandler,
  bidControlAction,
  setCurrentUser,
  warningText,
  formik,
  deal,
}) => {
  const { t } = useTranslation('deal')

  const isMobile = useMediaQuery({
    query: `(max-width: ${defaultTheme.queries.mobile})`,
  })

  const [usersFilter] = useState({ with_company: true, with_contract: isBrazil ? true : undefined })

  const Container = isMobile ? MobileContainer : ShortFormControls

  return (
    <>
      <SharedModalGroupContent>
        {warningText && <WarningContainer>{warningText}</WarningContainer>}
        <RadioButtons
          changeProfileTypeHandler={changeCurrentProfileTypeHandler}
          setCurrentCloningType={setCurrentCloningType}
          currentProfileType={currentProfileType}
          formik={formik}
        />
      </SharedModalGroupContent>
      <SharedModalGroupContent>
        {!isMobile && <SharedModalSubTitle>{t('bidCloningModal.bid_details')}</SharedModalSubTitle>}
        <FormSelectActiveUser
          onSelectedOptionLoad={(option) => setCurrentUser(option.dto)}
          currentProfileType={currentProfileType}
          onChange={fieldResetHandler}
          formik={formik}
          filter={usersFilter}
        />

        <SelectWithButton>
          <FormSelectBid
            onSelectedOptionLoad={handleBidOptionLoaded}
            onChange={(bid) => {
              fieldResetHandler()
              formik.setFieldValue('new_bid', bid)
            }}
            formik={formik}
            deal={deal}
            required
          >
            <ResponsiveSelectAddButton
              disabled={!formik.values.user}
              action={bidControlAction}
              eventId="openCreateBidModal"
            >
              {t('bid:form.add')}
            </ResponsiveSelectAddButton>
          </FormSelectBid>
        </SelectWithButton>
      </SharedModalGroupContent>
      <SharedModalGroupContent>
        <SharedModalSubTitle>{t('bidCloningModal.deal_details')}</SharedModalSubTitle>
        {getParamsProgress === Progress.WORK ? (
          <FormSkeleton />
        ) : (
          <Container>
            <QuantityInput fieldName="quantity" formik={formik} label={t('common:quantity')} />

            <FormGroup label={labelDistance(t('bidCloningModal.distance'))} error={formik.errors.distance}>
              <Input {...formik.getFieldProps('distance')} type="number" invalid={!!formik.errors.distance} />
            </FormGroup>

            <FormGroup label={labelTarif(t('bidCloningModal.price_exw'))} error={formik.errors.price_exw}>
              <Input {...formik.getFieldProps('price_exw')} type="number" invalid={!!formik.errors.price_exw} />
            </FormGroup>

            <FormGroup label={labelTarif(t('bidCloningModal.price_cpt'))} error={formik.errors.price_cpt}>
              <Input {...formik.getFieldProps('price_cpt')} type="number" invalid={!!formik.errors.price_cpt} />
            </FormGroup>

            <FormGroup
              label={labelPriceLogistics(t('bidCloningModal.price_logistics'))}
              error={formik.errors.price_logistics}
            >
              <Input
                {...formik.getFieldProps('price_logistics')}
                type="number"
                invalid={!!formik.errors.price_logistics}
              />
            </FormGroup>
          </Container>
        )}
      </SharedModalGroupContent>
    </>
  )
}
