import { GeoAddress, Icons, Text, defaultTheme } from '@agro-club/agroclub-shared'
import APageWrapper from 'views/components/PageWrapper/APageWrapper'
import { AddressControl } from 'views/components/Maps/AddressControl'
import { CalculatorRightBlock } from './Blocks/CalculatorRightBlock'
import { CalculatorLeftBlock } from './Blocks/CalculatorLeftBlock'
import { AButton } from 'views/components/Analytics'
import { apiClient } from 'modules/utils/httpClient'
import { useAFormHandler } from 'analytics/hooks'
import { Card } from 'views/components/Card/Card'
import * as Layout from 'views/layouts/NewLayout'
import * as Header from 'views/ui/Header/Header'
import { CalculatorDTO } from './types'
import { useTranslation } from 'react-i18next'
import { endpoints } from 'modules/endpoints'
import { RequestError } from 'modules/errors'
import CalculatorRoutes from './routes'
import styled from 'styled-components'
import { MapModal } from './MapModal'
import { FC, useState } from 'react'
import { useFormik } from 'formik'
import { nonEmptyFields } from 'helpers/object'

const Wrapper = styled.div`
  margin-top: 16px;
  width: 1390px;
`

const ControlsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0 1px;
  margin-bottom: 24px;
`

const AddressBlock = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  width: 100%;
  padding: 16px;
  background: ${defaultTheme.color.backgroundSecondary};
  gap: 0 8px;
`

const LeftAddressBlock = styled(AddressBlock)`
  border-radius: 8px 0 0 8px;
  display: flex;
  white-space: nowrap;
`

const RightAddressBlock = styled(AddressBlock)`
  border-radius: 0 8px 8px 0;
  display: flex;
  white-space: nowrap;
`

const CalculatorWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0 24px;
`
const initialValues = {
  product_id: '',
  distance: undefined,
  quantity: undefined,
  selling_price: undefined,
  purchase_price: undefined,
  selling_payment_date: undefined,
  purchase_payment_date: undefined,
  freight_cost: undefined,
  freight_cost_per_ton: undefined,
  freight_cost_per_bag: undefined,
  freight_piscofins_per_ton: undefined,
  freight_net_cost_per_ton: undefined,
  freight_net_cost_per_bag: undefined,
  days_cashflow: undefined,
  net_gm: undefined,
  gm: undefined,
  net_margin_percent: undefined,
  margin_percent: undefined,
  margin_per_10d_percent: undefined,
}

export const Calculator: FC = () => {
  const { t } = useTranslation('calculator')
  const [loadingModal, setLoadingModal] = useState(false)
  const [unloadingModal, setUnloadingModal] = useState(false)
  const [dataLoadingAddress, setDataLoadingAddress] = useState<GeoAddress>()
  const [dataUnloadingAddress, setDataUnloadingAddress] = useState<GeoAddress>()

  const { formProgress, formHandler } = useAFormHandler('calculate')
  const formik = useFormik<CalculatorDTO>({
    initialValues: {
      auto: {
        ...initialValues,
      },
      simulation: {
        ...initialValues,
      },
    },
    enableReinitialize: false,

    onSubmit: formHandler(async () => {
      const data = {
        auto: nonEmptyFields({
          product_id: formik.values.auto.product_id,
          origin_point: {
            lat: dataLoadingAddress?.coords?.[0],
            lng: dataLoadingAddress?.coords?.[1],
          },
          destination_point: {
            lat: dataUnloadingAddress?.coords?.[0],
            lng: dataUnloadingAddress?.coords?.[1],
          },
          quantity: formik.values.auto.quantity,
          selling_price: formik.values.auto.selling_price,
          purchase_price: formik.values.auto.purchase_price,
          selling_payment_date: formik.values.auto.selling_payment_date,
          purchase_payment_date: formik.values.auto.purchase_payment_date,
        }),
        simulation: nonEmptyFields({
          selling_price: formik.values.simulation.selling_price,
          purchase_price: formik.values.simulation.purchase_price,
          freight_cost_per_ton: formik.values.simulation.freight_cost_per_ton,
        }),
      }
      try {
        const response = await apiClient.post<CalculatorDTO>(endpoints.priceCalculator(), data)

        const newFormValues = { ...response }
        formik.setValues(newFormValues)
      } catch (err) {
        const error = err as RequestError
        const { errors } = error
        formik.setErrors(errors)
      }
    }),
  })
  return (
    <APageWrapper page="calculatorPage" place="calculatorPage">
      <Layout.WrapperContent>
        <Layout.Header>
          <Layout.TopHeader>
            <Header.Breadcrumbs routes={[{ path: CalculatorRoutes.List, breadcrumbName: t('calculator') }]} />
          </Layout.TopHeader>
        </Layout.Header>
        <Header.Title compact noMargin title={t('calculator')} />
        <Layout.ContentOneColumn>
          <Wrapper>
            <ControlsWrapper>
              <LeftAddressBlock>
                <Text typography="accentMedium">{t('cityOfOrigin')}</Text>
                <AddressControl
                  handlerMap={(address) => setDataLoadingAddress(address)}
                  address={dataLoadingAddress}
                  showMap={false}
                  size="small"
                />
                <AButton id="openMap" Icon={Icons.IconLocation} intent="white" onClick={() => setLoadingModal(true)} />
              </LeftAddressBlock>
              <RightAddressBlock>
                <Text typography="accentMedium">{t('cityDestination')}</Text>
                <AddressControl
                  handlerMap={(address) => setDataUnloadingAddress(address)}
                  address={dataUnloadingAddress}
                  showMap={false}
                  size="small"
                />
                <AButton
                  id="openMap"
                  Icon={Icons.IconLocation}
                  intent="white"
                  onClick={() => setUnloadingModal(true)}
                />
              </RightAddressBlock>
            </ControlsWrapper>
            <CalculatorWrapper>
              <Card.Container>
                <CalculatorLeftBlock formik={formik} formProgress={formProgress} />
              </Card.Container>
              <Card.Container>
                <CalculatorRightBlock formik={formik} />
              </Card.Container>
            </CalculatorWrapper>
          </Wrapper>
        </Layout.ContentOneColumn>
      </Layout.WrapperContent>
      {loadingModal && (
        <MapModal
          onClose={() => setLoadingModal(false)}
          setAddress={setDataLoadingAddress}
          address={dataLoadingAddress}
        />
      )}
      {unloadingModal && (
        <MapModal
          onClose={() => setUnloadingModal(false)}
          setAddress={setDataUnloadingAddress}
          address={dataUnloadingAddress}
        />
      )}
    </APageWrapper>
  )
}
