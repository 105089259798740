import { Location, Price, TextCard } from 'views/styled/ItemCardStyles'
import { Bid, BidStatus } from 'modules/domain/bid/types'
import { formatQuantity } from 'modules/utils/numbers/formatQuantity'
import { getPriceWithType, isBidWithPricePeriods } from 'modules/domain/bid/utils'
import { getMainParameter } from 'modules/utils/helpers'
import { ChildrenSpoiler, SpoilerLink } from 'views/components/ChildrenSpoiler/ChildrenSpoiler'
import { DatePeriodFormatted } from 'views/components/DatePeriodFormatted'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const BlockTextCard = styled(TextCard)`
  display: block;
`

type Props = {
  bid: Bid
}

export const BidCardInfo = ({ bid }: Props) => {
  const { t } = useTranslation()

  const mainParam = getMainParameter(bid.parameters)

  const isPricePeriodsBid = isBidWithPricePeriods(bid)

  const pricePeriods = isPricePeriodsBid ? (
    <ChildrenSpoiler linkComponent={SpoilerLink}>
      {bid.price_periods.map((period) => (
        <div key={period.id}>
          <BlockTextCard>
            <DatePeriodFormatted period={period} wrap={false} />
          </BlockTextCard>
          <Price>{getPriceWithType(bid, t, period.price)}</Price>
        </div>
      ))}
    </ChildrenSpoiler>
  ) : null

  return (
    <>
      <div>
        <BlockTextCard thumbnail>
          {bid.product.title}, {bid.season && `${bid.season.name}`}
        </BlockTextCard>
        <TextCard thumbnail={bid.status === BidStatus.potential}>{formatQuantity(bid.quantity)}</TextCard>
        {bid.status != BidStatus.potential && (
          <>
            {mainParam && <TextCard>, {mainParam}</TextCard>}
            {!isPricePeriodsBid && (
              <>
                {', '}
                <Price>{getPriceWithType(bid, t)}</Price>
              </>
            )}
          </>
        )}
        {bid.has_samples && <div>{t('samples:thereAreSapmples')}</div>}
      </div>
      {pricePeriods}
      <Location>{bid.address.address}</Location>
    </>
  )
}
