import { ABaseTableFilter } from 'views/components/TableFilters/ABaseTableFilter'
import { endpoints } from 'modules/endpoints'
import { FC } from 'react'

type Props = {
  onChange: (value: any) => void
  placeholder: string
  value: string[]
}

export const RegionFilter: FC<Props> = ({ onChange, value, placeholder }) => {
  return (
    <ABaseTableFilter
      endpoint={endpoints.provinces()}
      placeholder={placeholder}
      onChange={onChange}
      searchField="name"
      id="regionFilter"
      simpleApiFormat
      value={value}
      multiple
    />
  )
}
