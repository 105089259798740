import { AntTag, Icons } from '@agro-club/agroclub-shared'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const Tag = styled(AntTag)`
  svg {
    width: 16px;
    height: 16px;
  }
`

export const MoratoriumTag: FC<{ isVisible?: boolean }> = ({ isVisible }) => {
  const { t } = useTranslation('company')
  if (!isVisible) return null

  return (
    <Tag color="red" icon={<Icons.IconStopIcons />}>
      {t('moratorium')}
    </Tag>
  )
}
