import { createSearchParams, generatePath } from 'react-router-dom'
import BidRoutes from 'views/pages/Bid/routes'
import { UserAddress } from '../userAddress/types'
import { BidStatus, UIBidTypes } from './types'
import { addMonths, format, lastDayOfMonth } from 'date-fns'
import { SERVER_DATE_FORMAT } from 'modules/constants'
import { isRussia } from 'env'

const generateBidLink = (isSeller: boolean, address: UserAddress) => {
  const activeTab = isSeller ? UIBidTypes.purchase : UIBidTypes.sale
  const endDate: { end_date: string } | {} = !isRussia
    ? { end_date: format(lastDayOfMonth(addMonths(new Date(), 1)), SERVER_DATE_FORMAT) }
    : {}
  const filters = isSeller
    ? {
        status: BidStatus.published,
        sort: '-price_exw',
        address: address?.address || '',
        latitude: String(address?.latitude) || '',
        longitude: String(address?.longitude) || '',
        ...endDate,
      }
    : {
        status: BidStatus.published,
        sort: 'price_cpt',
        address: address?.address || '',
        latitude: String(address?.latitude) || '',
        longitude: String(address?.longitude) || '',
        ...endDate,
      }

  const search = createSearchParams(filters).toString()
  return `${generatePath(BidRoutes.ListWithTab, { activeTab })}?${search}`
}

export default generateBidLink
