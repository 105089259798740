import { formatPriceLogistics } from 'modules/utils/numbers/formatPriceLogistics'
import { Typography } from '@agro-club/agroclub-shared'

import { formatDistance } from 'modules/utils/numbers/formatDistance'
import { formatTarif } from 'modules/utils/numbers/formatTarif'
import { apiClient } from 'modules/utils/httpClient'
import { useAFormHandler } from 'analytics/hooks'
import DealRoutes from 'views/pages/Deal/routes'
import { Deal } from 'modules/domain/deal/types'
import { endpoints } from 'modules/endpoints'
import { generatePath } from 'react-router'
import styled from 'styled-components'
import { useFormik } from 'formik'
import { FC } from 'react'
import { RematchForm } from '../../RematchForm'
import { CommonWrapper } from 'views/components/Card/Wrapper'

const AccentBlock = styled.div`
  ${Typography.accentLarge}
`

type Props = {
  deal: Deal
  reset: () => void
}
type Fields = {
  distance: number
  price_logistics: string | number
  quantity: string | number
  purchase_bid_id: string
  sale_bid_id: string
  user_coordinator: string | number
  deal_coordinator: string | number
  logisticians: string[]
  trader: string | number
}

export const DealCard: FC<Props> = ({ deal, reset }) => {
  const { formProgress, formHandler } = useAFormHandler('newDeal')

  const formik = useFormik<Fields>({
    initialValues: {
      distance: deal.distance,
      price_logistics: deal.price_logistics,
      purchase_bid_id: deal.purchase_contract?.id ?? '',
      sale_bid_id: deal.sale_contract?.id ?? '',
      quantity: deal.quantity,
      user_coordinator: deal.executors?.user_coordinator?.id || '',
      deal_coordinator: deal.executors?.deal_coordinator?.id || '',
      logisticians: deal.executors?.logisticians?.map((logist) => logist.id) || [],
      trader: deal.executors?.trader?.id || '',
    },

    enableReinitialize: false,
    validateOnBlur: false,

    onSubmit: formHandler(
      async () => {
        const data = {
          price_logistics: formik.values.price_logistics,
          purchase_bid_id: formik.values.purchase_bid_id,
          sale_bid_id: formik.values.sale_bid_id,
          quantity: formik.values.quantity,
          distance: formik.values.distance,
          executors: {
            user_coordinator: formik.values.user_coordinator,
            deal_coordinator: formik.values.deal_coordinator,
            logisticians: formik.values.logisticians,
            trader: formik.values.trader,
          },
        }
        const response = await apiClient.post<Deal>(endpoints.dealsManual(), data)
        if (response?.id) {
          window.open(generatePath(DealRoutes.Details, { id: response.id }), '_blank')
        }
      },
      {
        onSuccess: reset,
        onError: (error) => {
          const { errors } = error
          formik.setErrors(errors)
        },
      },
    ),
  })
  return (
    <CommonWrapper>
      <AccentBlock>
        {`${deal.sale_bid.price_type}
        ${formatTarif(deal.sale_bid.price)},
        ${deal.purchase_bid.price_type}
        ${formatTarif(deal.purchase_bid.price)}`}
      </AccentBlock>

      <div>
        {`${deal.product.title}, ${formatDistance(deal.distance)}, ${formatPriceLogistics(deal.price_logistics)}`}
      </div>

      <RematchForm formik={formik} progress={formProgress} withExecutors />
    </CommonWrapper>
  )
}
