import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { AntTag, AntTagColor } from '@agro-club/agroclub-shared'

import { TaskStatus } from 'modules/domain/task/types'

const statusColor: Record<string, AntTagColor> = {
  new: 'orange',
  in_progress: 'green',
  complete: 'grey',
}

interface Props {
  status: TaskStatus
}

export const TaskStatuses: FC<Props> = ({ status }) => {
  const { t } = useTranslation('my_tasks')

  return <AntTag color={statusColor[status]}>{t(`statuses.${status}`)}</AntTag>
}
