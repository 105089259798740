import { FC } from 'react'
import { DashboardTaskBid } from 'modules/domain/dashboard/types'
import { AntTag } from '@agro-club/agroclub-shared'
import { BidStatus } from 'modules/domain/bid/types'
import { useTranslation } from 'react-i18next'

interface Props {
  bid: DashboardTaskBid | null
}

export const CardBidStatus: FC<Props> = ({ bid }) => {
  const { t } = useTranslation('bid')
  const tagColor = bid?.status === BidStatus.archived ? 'grey' : 'white'

  return <AntTag color={tagColor}>{t(`bidStatuses.${bid?.status}`)}</AntTag>
}
