import { useCallback, useEffect } from 'react'

import { Icons, Typography, Progress, antNotification, defaultTheme } from '@agro-club/agroclub-shared'

import usePrevious from 'hooks/usePrevious'
import styled, { createGlobalStyle } from 'styled-components'
import { useTranslation } from 'react-i18next'

export const NotificationGlobalStyle = createGlobalStyle`
    .ant-notification {
      opacity: 0.9;
      transition: 0.2s ease-in-out opacity;

       &:hover {
        opacity: 1;
      }
    }

    .ant-notification-notice {
      display: flex;
      flex-direction: column;
      row-gap: 4px;
      width: 448px;
      padding: 12px 12px 12px 16px;
      border-radius: 4px;
      box-shadow: none;
    }

    .ant-notification-notice-message {
      margin-bottom: 0;
    }

    .ant-notification-notice-close {
      top: 12px;
      right: 12px;
      cursor: pointer;

      .ant-notification-notice-close-x {
        width: 22px;
        height: 22px;

        svg {
          width: inherit;
          height: inherit;

          path {
            fill: ${defaultTheme.color.textSecondary60a};
          }
        }
      }

      &:hover {
        svg {
          path {
          fill: ${defaultTheme.color.secondary900};
         }
        }
      }
    }
  `

const NotificationTittle = styled.div`
  ${Typography.accentLarge}
  color: ${defaultTheme.color.textPrimary900};
`

const NotificationDescription = styled.div`
  ${Typography.bodyMedium}
  color: ${defaultTheme.color.textSecondary60a};
`

export interface NotificationInfo {
  title?: string
  description?: string
}
/** @deprecated use -> useNotification */
export const useNotificationProgress = (
  progress?: Progress,
  textSuccess?: NotificationInfo | string,
  textError?: NotificationInfo | string,
  showError = true,
  showSuccess = true,
) => {
  const { t } = useTranslation('common')

  antNotification.config({
    placement: 'top',
    duration: 3,
    maxCount: 5,
    closeIcon: <Icons.IconClose />,
  })

  const notifySuccess = useCallback(() => {
    let description: string | undefined = undefined
    let title: string | undefined = undefined
    if (textSuccess) {
      description = (textSuccess as NotificationInfo).description
      title = (textSuccess as NotificationInfo).title
    }
    if (!title) {
      title = t('notifySuccess')
    }
    antNotification.open({
      message: <NotificationTittle>{title}</NotificationTittle>,
      description: description && <NotificationDescription>{description}</NotificationDescription>,
      duration: 4,
      style: {
        backgroundColor: defaultTheme.color.accentApprove100,
      },
    })
  }, [t, textSuccess])

  const notifyError = useCallback(() => {
    let description: string | undefined = undefined
    let title: string | undefined = undefined
    if (textError) {
      description = (textError as NotificationInfo).description
      title = (textError as NotificationInfo).title
    }
    if (!title) {
      title = t('notifyError')
    }
    antNotification.open({
      message: <NotificationTittle>{title}</NotificationTittle>,
      description: description && <NotificationDescription>{description}</NotificationDescription>,
      duration: 3,
      style: {
        backgroundColor: defaultTheme.color.accentDestruct100,
      },
    })
  }, [t, textError])

  const prevProgress = usePrevious(progress)

  useEffect(() => {
    if (progress === Progress.SUCCESS && prevProgress === Progress.WORK && showSuccess) {
      notifySuccess()
    }
  }, [notifySuccess, prevProgress, progress, showSuccess])

  useEffect(() => {
    if (progress === Progress.ERROR && prevProgress === Progress.WORK && showError) {
      notifyError()
    }
  }, [notifyError, prevProgress, progress, showError])
}
