import { SharedModalContent, SharedModalInputs } from 'views/components/SharedModal/styled'
import ADefaultModalFooter from 'views/components/DefaultModalFooter/ADefaultModalFooter'
import { TaskManagementForm } from './components/TaskManagementForm'
import { ResponseCoordinatorsTaskStats } from './DistributeBlock'
import { FC, ReactNode, useEffect, useState } from 'react'
import { CoordinatorsStatBoard } from './RearrangeBlock'
import { useEntityTaskStat } from './useEntityTaskStat'
import { DateISO } from '@agro-club/agroclub-shared'
import { apiClient } from 'modules/utils/httpClient'
import { useAFormHandler } from 'analytics/hooks'
import { useTranslation } from 'react-i18next'
import { endpoints } from 'modules/endpoints'
import { useFormik } from 'formik'
import { HrWithHeader } from 'views/components/Hr/Hr'

type Fields = {
  assignees: string[]
  tasks_per_day: string
  other_task_per_day: string | null
  start_deadline_date: DateISO | undefined
}

type Props = {
  onClose: () => void
  children: ReactNode
  refetch: () => void
}

export const BalanceBlock: FC<Props> = ({ onClose, children, refetch }) => {
  const { t } = useTranslation('my_tasks')
  const [coordinatorsStat, setCoordinatorsStat] = useState<ResponseCoordinatorsTaskStats>()

  const { formProgress, formHandler } = useAFormHandler('balanceTasks')

  const [coordinatorsStatProgress, getCoordinatorsStat] = useEntityTaskStat<ResponseCoordinatorsTaskStats>(
    setCoordinatorsStat,
    endpoints.getCoordinatorTaskStats,
  )

  const formik = useFormik<Fields>({
    initialValues: {
      assignees: [],
      tasks_per_day: '50',
      start_deadline_date: undefined,
      other_task_per_day: null,
    },

    onSubmit: formHandler(
      async () => {
        const data = {
          assignees: formik.values.assignees,
          tasks_per_day:
            formik.values.tasks_per_day === 'other' ? formik.values.other_task_per_day : formik.values.tasks_per_day,
          start_deadline_date: formik.values.start_deadline_date,
        }
        await apiClient.post(endpoints.balanceTasks(), data)
      },
      {
        onSuccess: () => {
          refetch()
          onClose()
        },
        onError: (error) => {
          const { errors } = error
          formik.setErrors(errors)
        },
      },
    ),
  })

  useEffect(() => {
    if (formik.values.assignees.length) {
      getCoordinatorsStat({
        assignee: formik.values.assignees,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.assignees])

  return (
    <>
      <SharedModalContent>
        <SharedModalInputs>
          {children}
          <HrWithHeader text={t('task_distribution.for_whom')} />
          <TaskManagementForm formik={formik} teamField="team" assigneeField="assignees">
            {!!formik.values.assignees.length && (
              <CoordinatorsStatBoard
                data={coordinatorsStat}
                progress={coordinatorsStatProgress}
                title={t('task_distribution.total_tasks')}
              />
            )}
          </TaskManagementForm>
        </SharedModalInputs>
      </SharedModalContent>
      <ADefaultModalFooter
        onConfirm={() => formik.submitForm()}
        confirmButtonText={t('common:save')}
        progress={formProgress}
        onClose={onClose}
      />
    </>
  )
}
