import { FC, useState } from 'react'
import { isBidContractsEnabled } from 'env'
import { BidTypes } from 'modules/domain/bid/types'
import { refetchFunc } from 'modules/domain/common/hooks'
import { Deal } from 'modules/domain/deal/types'
import { getDealBidAndContract } from 'modules/domain/deal/utils'
import { useTranslation } from 'react-i18next'
import { AButton } from 'views/components/Analytics'
import { AddContractModal } from 'views/pages/BidContracts/BidContractDetail/Modals/AddContractModal'

type Props = {
  deal: Deal
  bidType: BidTypes
  disabled?: boolean
  refetch: refetchFunc
}

export const AddBidContractAction: FC<Props> = ({ disabled = false, refetch, deal, bidType }) => {
  const { t } = useTranslation()
  const [modalVisible, setModalVisible] = useState(false)

  const { bid, contract } = getDealBidAndContract(deal, bidType)

  const bidHasEnoughQuantity = (bid.remaining_quantity ?? 0) >= deal.quantity
  const isContractSignable = bid && !contract && bidHasEnoughQuantity

  if (!isBidContractsEnabled || !bid || !isContractSignable) {
    return null
  }

  return (
    <>
      <AButton
        onClick={() => setModalVisible(true)}
        disabled={disabled || !isContractSignable}
        id="createContract"
        intent="secondary"
      >
        {t('bid:contracts.addContract')}
      </AButton>
      {modalVisible && (
        <AddContractModal refetch={refetch} onClose={() => setModalVisible(false)} deal={deal} bidType={bidType} />
      )}
    </>
  )
}
