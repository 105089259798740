import { Address } from 'modules/domain/types'
import { FC, useEffect, useRef, useState } from 'react'
import { useClosestColumns } from './useClosestColumns'
import getUsTopOffers, { UserOffer } from 'modules/domain/bid/getUsTopOffers'
import { refetchFunc } from 'modules/domain/common/hooks'
import { AntdTable, OptionType, Progress, Spinner, Text } from '@agro-club/agroclub-shared'
import { useProgress } from 'hooks/useProgress'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Card } from 'views/components/Card/Card'
import { useBestColumns } from './useBestColumns'
import { AnalyticsFilterVariant } from 'analytics/types'
import { ADateRangePicker } from 'views/components/TableFilters/DateRangePicker/ADateRangePicker'
import { addMonths, format, lastDayOfMonth } from 'date-fns'
import { SERVER_DATE_FORMAT } from 'modules/constants'
import ASelect from 'views/components/Analytics/ASelect'
import { HrWithHeader } from 'views/components/Hr/Hr'

type Props = {
  addressesRefetch: refetchFunc
  addresses: Address[]
  progress: Progress
}

const PeriodFilterWrapper = styled.div`
  display: flex;
  column-gap: 12px;
  align-items: center;
`
const WrapperTable = styled.div`
  .ant-table-cell {
    padding: 12px 6px !important;
  }
`

export const Offers: FC<Props> = ({ addresses, progress }) => {
  const { t } = useTranslation('bid')
  const [offers, setOffers] = useState<UserOffer>({ best: [], closest: [] })
  const [currAddress, setCurrAddress] = useState<Address>()
  const defaultRangePickerValue = {
    start_date: '',
    end_date: format(lastDayOfMonth(addMonths(new Date(), 1)), SERVER_DATE_FORMAT),
  }
  const [rangeDate, setRangeDate] = useState<any>(defaultRangePickerValue)
  const [addrOptions, setAddrOptions] = useState<OptionType[]>([])
  const abortControllerRef = useRef<AbortController | null>(null)
  const [offersProgress, fetch] = useProgress(async () => {
    if (!currAddress) return

    /* it is necessary to cancel the previous request because of race condition */
    if (abortControllerRef.current) {
      abortControllerRef.current?.abort()
      abortControllerRef.current = new AbortController()
    } else {
      abortControllerRef.current = new AbortController()
    }

    const response = await getUsTopOffers(currAddress, rangeDate, abortControllerRef.current)
    abortControllerRef.current = null
    setOffers(response)
  })

  useEffect(() => {
    fetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currAddress, rangeDate])

  const closestColumns = useClosestColumns()
  const bestColumns = useBestColumns()

  useEffect(() => {
    setAddrOptions(addresses.map((a) => ({ label: a.address, value: String(a.id), dto: a })))
    setCurrAddress(addresses[0])
  }, [addresses])

  if (progress == Progress.WORK) return <Spinner />
  if (!addresses.length) return null

  return (
    <Card.GapableContent>
      <PeriodFilterWrapper>
        <Text>{t('form.deliveryPeriod')}</Text>
        <ADateRangePicker
          value={[rangeDate.start_date, rangeDate.end_date]}
          onChange={(value) => setRangeDate({ start_date: value.startDate, end_date: value.endDate })}
          id={AnalyticsFilterVariant.DATE}
          allowEmpty={[true, true]}
          place="ShowPrice"
        />
      </PeriodFilterWrapper>
      <ASelect
        onChange={(addr) => {
          setCurrAddress(addresses.find((a) => a.id == addr))
        }}
        value={String(currAddress?.id)}
        options={addrOptions}
        showSearch={false}
        fullWidth
        allowClear={false}
        id={AnalyticsFilterVariant.ADDRESS}
        place="ShowPrice"
      />
      <WrapperTable>
        <HrWithHeader typography="accentMedium" text={t('usShortBid.ourOffers')} marginBottom="4px" forForm={false} />
        <AntdTable
          pagination={false}
          data={offers.best}
          columns={bestColumns}
          rowHoverGray
          bordered
          progress={offersProgress}
        />
      </WrapperTable>
      <WrapperTable>
        <HrWithHeader
          typography="accentMedium"
          text={t('usShortBid.closestBuyer')}
          marginBottom="4px"
          forForm={false}
        />
        <AntdTable
          pagination={false}
          data={offers.closest}
          columns={closestColumns}
          rowHoverGray
          bordered
          progress={offersProgress}
        />
      </WrapperTable>
    </Card.GapableContent>
  )
}
