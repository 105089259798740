import { Progress } from '@agro-club/agroclub-shared'
import { isFuturesEnabled } from 'env'
import { useNotificationProgress } from 'hooks/useNotificationProgress'
import { isBidWithPricePeriods } from 'modules/domain/bid/utils'
import { endpoints } from 'modules/endpoints'
import { apiClient } from 'modules/utils/httpClient'
import { FC, KeyboardEventHandler, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TableInput } from 'views/components/Table/Controls/TableInput'
import { BidPriceTableCellProps } from './types'
import CellProgress from './CellProgress'
import { useAProgress } from 'hooks/useAProgress'

const PriceCell: FC<BidPriceTableCellProps> = ({ currentValue, bid, refetch }) => {
  const { t } = useTranslation()
  const [value, setValue] = useState(currentValue)

  const [progress, execute, error] = useAProgress(
    async (price) => {
      await apiClient.patch(endpoints.bid(bid.id), { price: Number(price) })
      await refetch()
    },
    { eventName: 'price', scope: 'change' },
  )

  const updatePrice = (price) => {
    if (Number(price) === Number(currentValue)) {
      return
    }
    return execute(price)
  }

  useNotificationProgress(progress, t('bid:form.notifyEditSuccess'))

  const onKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (event.key !== 'Enter' || progress === Progress.WORK) {
      return
    }
    updatePrice(value)
  }

  return (
    <>
      <CellProgress enabled={progress === Progress.WORK} />
      <TableInput
        error={!!error}
        onChange={(e) => setValue(e.target.value)}
        onBlur={() => updatePrice(value)}
        onKeyDown={onKeyDown}
        value={value}
        visible={!(isFuturesEnabled && isBidWithPricePeriods(bid))}
        disabled={progress === Progress.WORK}
      />
    </>
  )
}

export default PriceCell
