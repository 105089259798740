import { Icons } from '@agro-club/agroclub-shared'
import useFormatDate from 'hooks/useFormatDate'
import { PHONE_TABLE_CELL_WIDTH } from 'modules/constants'
import { ColumnData } from 'modules/types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router-dom'
import { AButton } from 'views/components/Analytics'
import CompanyRoutes from 'views/pages/Company/routes'
import UserRoutes from 'views/pages/User/routes'
import APhone from 'views/ui/Phone/APhone'
import { TableTextLink } from 'views/ui/TableTextLink/TableTextLink'

export const useColumns = (openModal): ColumnData[] => {
  const { t } = useTranslation('whatsApp')
  const formatDate = useFormatDate()
  return useMemo(
    () => [
      {
        title: t('columns.date'),
        width: 140,
        dataIndex: 'date_sent',
        key: 'date_sent',
        render: (date_sent) => formatDate(date_sent),
      },
      {
        title: t('columns.coordinator'),
        width: 212,
        dataIndex: 'coordinator_name',
        key: 'coordinator_name',
      },
      {
        title: t('columns.user'),
        width: 376,
        dataIndex: 'client_name',
        key: 'client_name',
        render: (client_name, data) => (
          <>
            {data.client_id && (
              <TableTextLink
                id="clientName"
                tableItemId={data.client_id}
                to={generatePath(UserRoutes.Details, { id: data.client_id })}
              >
                {client_name}
              </TableTextLink>
            )}
          </>
        ),
      },
      {
        title: t('columns.phone'),
        width: PHONE_TABLE_CELL_WIDTH,
        dataIndex: 'client_phone',
        key: 'client_phone',
        render: (client_phone) => <APhone phone={client_phone} />,
      },
      {
        title: t('columns.company'),
        width: 400,
        dataIndex: 'client_company',
        key: 'client_company',
        render: (client_company, row) => (
          <>
            {row.client_company_id && (
              <TableTextLink
                id="clientCompany"
                tableItemId={row.client_company_id}
                to={generatePath(CompanyRoutes.Details, { id: row.client_company_id })}
              >
                {client_company}
              </TableTextLink>
            )}
          </>
        ),
      },
      {
        title: t('columns.message'),
        width: 230,
        dataIndex: 'message',
        key: 'message',
        render: (_, data) => (
          <AButton
            id="showCommunicationDetails"
            Icon={Icons.IconWhatsapp}
            onClick={() => openModal(data)}
            intent="secondary"
            size="small"
          >
            {t('columns.viewContent')}
          </AButton>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, formatDate],
  )
}
