import { FC } from 'react'

import { ListRequestParams } from 'modules/domain/types'
import { ColumnData } from 'modules/types'
import ATableFullPage from 'views/components/Table/ATableFullPage'

interface Props {
  listRequestParams: ListRequestParams
  setListRequestParams: (params: ListRequestParams) => void
  isFiltersLoaded: boolean
  dataEndpoint: string
  columnsFunc: (string?) => ColumnData[]
}

const WarehousesTable: FC<Props> = ({
  listRequestParams,
  setListRequestParams,
  isFiltersLoaded,
  dataEndpoint,
  columnsFunc,
}) => {
  const columns = columnsFunc(listRequestParams.sort)

  return (
    <>
      <ATableFullPage
        columns={columns}
        dataEndpoint={dataEndpoint}
        listParamsUpdated={setListRequestParams}
        listRequestParams={listRequestParams}
        isFiltersLoaded={isFiltersLoaded}
        rowHoverGray
      />
    </>
  )
}

export default WarehousesTable
