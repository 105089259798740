import { useDesktopOptions } from '../Options/useDesktopOptions'
import { MenuContainer } from 'views/components/MainMenu/styled'
import { getActiveRoute, useNavigation } from '../utils'
import { AntdMenu, Icons } from '@agro-club/agroclub-shared'
import { useAnalyticsContext } from 'analytics/hooks'
import { MenuInfo } from 'rc-menu/lib/interface'
import styled from 'styled-components'
import { useEffect, useRef, useState } from 'react'

const ScrollBtn = styled.div<{ isVisible: boolean }>`
  position: relative;
  width: 94px;
  height: 28px;
  display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    top: -10px;
    left: 0;
    width: 100%;
    height: 11px;
    background: linear-gradient(180deg, rgba(217, 217, 217, 0) 0%, rgba(39, 45, 51, 0.2) 236.36%);
  }
`

const MenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden auto;
  margin-bottom: 16px;
`

export const DesktopMainMenu = () => {
  const { track } = useAnalyticsContext()
  const { isCurrent } = useNavigation()
  const { menuOptions } = useDesktopOptions()
  const activeRoutes = getActiveRoute(menuOptions, isCurrent)
  const menuRef = useRef<HTMLDivElement>(null)
  const [showScrollButton, setShowScrollButton] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      if (menuRef.current) {
        const menuContainerHeight = menuRef.current.parentElement?.offsetHeight ?? 0
        const menuHeight = menuRef.current.offsetHeight

        setShowScrollButton(menuHeight > menuContainerHeight)
      }
    }

    handleResize()
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [menuRef?.current?.offsetHeight])

  const handleClick = (info: MenuInfo) => {
    track('click|menu', {
      place: 'MainMenu',
      key: info.key,
    })
  }

  const handleScrollClick = () => {
    if (menuRef.current) {
      menuRef.current.parentElement?.scrollBy({ top: 400, behavior: 'smooth' })
    }
  }

  return (
    <MenuWrapper>
      <MenuContainer>
        <div ref={menuRef}>
          <AntdMenu onClick={handleClick} selectedKeys={activeRoutes} items={menuOptions} />
        </div>
      </MenuContainer>
      <ScrollBtn onClick={handleScrollClick} isVisible={showScrollButton}>
        <Icons.IconArrowDownS />
      </ScrollBtn>
    </MenuWrapper>
  )
}
