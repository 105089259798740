import { FormCheckboxGroup } from 'views/components/CheckboxGroup/CheckboxGroup'
import { useTranslation } from 'react-i18next'
import { FormikFieldsType, VehicleType } from 'modules/domain/types'
import { FC } from 'react'

type Props = {
  formik: FormikFieldsType
  fieldName?: string /* for unique fields */
}

export const VehicleTypeCheckboxGroup: FC<Props> = ({ formik, fieldName = 'vehicle_type' }) => {
  const { t } = useTranslation('address')

  return (
    <FormCheckboxGroup
      keys={Object.keys(VehicleType)}
      fieldName={fieldName}
      formik={formik}
      formGroupLabel={t('fields.vehicle_type')}
      checkboxLabel="vehicles:vehicleTypes"
    />
  )
}
