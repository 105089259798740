import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AntdTabsProps, useHelmet } from '@agro-club/agroclub-shared'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { WrapperContent } from 'views/layouts/NewLayout'
import { useTabsData } from 'hooks/useTabsData'
import { AAntdTabs } from 'views/components/Analytics'
import APageWrapper from 'views/components/PageWrapper/APageWrapper'
import { endpoints } from 'modules/endpoints'
import { AnalyticsPages, AnalyticsPlaces } from '../../../analyticsPlaces'
import { prepareMultipleEditing } from '../../../helpers'
import RatesRoutes from '../../routes'
import { EditableRatesDTO, RateDTO, RatesProductTypes } from '../../types'
import { useColumns } from '../../useColumns'
import { AddRatesModal } from '../AddRatesModal'
import { EditRatesModal } from '../EditRatesModal'
import { RatesListHeader } from './RatesListHeader'
import { useAverageColumns } from '../../useAverageColumns'
import { CommonRatesTable } from 'views/pages/LogisticsKR/Rates/components/RatesTables/CommonRatesTable'
import { AverageTable } from 'views/pages/LogisticsKR/Rates/components/RatesTables/AverageTable'

export const RatesRuContent = () => {
  const { t } = useTranslation('rates')
  useHelmet({ title: t('metaTitle') })

  const navigate = useNavigate()
  const { activeTab: activeTabParams } = useParams()
  const [activeTab, setActiveTab] = useState<string>(activeTabParams || RatesProductTypes.cereals)
  const [selectedRows, setSelectedRows] = useState<RateDTO[]>([])
  const [isAddModalOpen, setIsAddModalOpen] = useState<boolean>(false)
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false)
  const rowsPreparedForEditing = prepareMultipleEditing(selectedRows)
  const isEditingAvailable = selectedRows.length > 0
  const initialTabsData = {
    [RatesProductTypes.cereals]: {
      label: t('productTypes.cereals'),
      endpoint: endpoints.LogisticsKR.ratesWithRegion(),
      columnsFunc: useColumns,
      extraParams: { filter: { product_type: RatesProductTypes.cereals } },
    },
    [RatesProductTypes.oilseeds]: {
      label: t('productTypes.oilseeds'),
      endpoint: endpoints.LogisticsKR.ratesWithRegion(),
      columnsFunc: useColumns,
      extraParams: { filter: { product_type: RatesProductTypes.oilseeds } },
    },
    [RatesProductTypes.average]: {
      label: t('productTypes.average'),
      endpoint: endpoints.LogisticsKR.ratesWithoutRegionAndProductType(),
      columnsFunc: useAverageColumns,
    },
  }

  const updateSelectedRowsAfterEdit = useCallback(
    (data: EditableRatesDTO) => {
      if (!data) return
      const selectedRowsDto = selectedRows.map((row) => ({
        ...row,
        logistics_rates: data.rates.map(({ rate }, i) => ({
          ...data.rates[i],
          rate: rate,
        })),
      }))

      setSelectedRows(selectedRowsDto)
    },
    [selectedRows],
  )

  const {
    isFiltersLoaded,
    commonRequestParams,
    updateCommonFilterState,
    tablesParams,
    tablesSetters,
    clearAllTabsState,
  } = useTabsData(activeTab, initialTabsData)

  const onSuccess = useCallback(() => {
    updateCommonFilterState(commonRequestParams.filter)
  }, [commonRequestParams, updateCommonFilterState])

  useEffect(() => {
    navigate(
      {
        pathname: generatePath(RatesRoutes.ListWithTab, { activeTab: activeTab }),
        search: location.search,
      },
      { replace: true },
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab])

  const ratesTabsItems = () => {
    const tabs: AntdTabsProps['items'] = []
    for (const key of Object.keys(initialTabsData)) {
      tabs.push({
        label: initialTabsData[key].label,
        key,
        children:
          key === RatesProductTypes.average ? (
            <AverageTable
              listRequestParams={tablesParams[key]}
              setListRequestParams={tablesSetters[key]}
              dataEndpoint={initialTabsData[key].endpoint}
              columnsFunc={initialTabsData[key].columnsFunc}
            />
          ) : (
            <CommonRatesTable
              listRequestParams={tablesParams[key]}
              isFiltersLoaded={isFiltersLoaded}
              setListRequestParams={tablesSetters[key]}
              dataEndpoint={initialTabsData[key].endpoint}
              columnsFunc={initialTabsData[key].columnsFunc}
              setSelectedRows={setSelectedRows}
              onSuccess={onSuccess}
            />
          ),
      })
    }
    return tabs
  }

  return (
    <APageWrapper page={AnalyticsPages.RATES} place={AnalyticsPlaces.RATES.LIST}>
      <WrapperContent>
        <RatesListHeader
          listRequestParams={commonRequestParams}
          updateFilterState={updateCommonFilterState}
          clearAllTabsState={clearAllTabsState}
          setIsAddModalOpen={setIsAddModalOpen}
          setIsEditModalOpen={setIsEditModalOpen}
          isEditingAvailable={isEditingAvailable}
        />

        <AAntdTabs items={ratesTabsItems()} onChange={setActiveTab} activeKey={activeTab} id="tabs" />

        {isAddModalOpen && (
          <AddRatesModal
            refetch={onSuccess}
            onClose={() => setIsAddModalOpen(false)}
            currentProductType={activeTab as RatesProductTypes}
          />
        )}

        {isEditModalOpen && (
          <EditRatesModal
            refetch={onSuccess}
            onClose={() => setIsEditModalOpen(false)}
            currentProductType={activeTab as RatesProductTypes}
            regionIds={rowsPreparedForEditing?.id}
            ratesData={rowsPreparedForEditing?.logistic_rates}
            responsibleLogisticians={rowsPreparedForEditing?.responsible_logisticians}
            updateSelectedRows={updateSelectedRowsAfterEdit}
          />
        )}
      </WrapperContent>
    </APageWrapper>
  )
}
