import { FC, useState } from 'react'
import { Icons, Spinner } from '@agro-club/agroclub-shared'
import {
  DetailsItemButtons,
  DetailsItemInfo,
  DetailsStyledIcon,
  SeparateDetailsItem,
  SpinnerWrapper,
} from 'views/pages/LogisticsKR/components/styled'
import {
  AddressInfoWrapper,
  InfoRow,
  InfoSection,
  InfoSectionText,
  InfoSectionTitle,
  InfoWrapper,
  Text,
} from './styled'
import {
  LogisticsKRLoadingWarehouse,
  LogisticsKRUnloadingWarehouse,
  LogisticStatuses,
} from 'views/pages/LogisticsKR/types'
import { Trans, useTranslation } from 'react-i18next'
import { LogisticRequest } from 'modules/domain/logisticsKR/types'
import { useSingleEntity } from 'modules/domain/common/hooks'
import { endpoints } from 'modules/endpoints'
import { LoadingAddressModal } from '../LoadingAddressModal'
import { UnloadingAddressModal } from '../UnloadingAddressModal'
import { TableSectionAccentText, TableSectionText, TextWrapper } from '../RequestTripsTables/styled'
import { StyledLink } from '../styled'
import { generatePath } from 'react-router'
import DealRoutes from 'views/pages/Deal/routes'
import CompanyRoutes from 'views/pages/Company/routes'
import { formatTarif } from 'modules/utils/numbers/formatTarif'
import { AButton } from 'views/components/Analytics'
import { WarehouseParams } from '../WarehouseParams'
import { DASH } from 'modules/constants'

interface Props {
  request: LogisticRequest
  handleOpenEditPopUp?: (warehouse: LogisticsKRLoadingWarehouse | LogisticsKRUnloadingWarehouse) => void
}

export const CommonInfo: FC<Props> = ({ request }) => {
  const { t } = useTranslation('logisticsKR')
  const [isLoadingModalOpen, setIsLoadingModalOpen] = useState<boolean>(false)
  const [isUnloadingModalOpen, setIsUnloadingModalOpen] = useState<boolean>(false)
  const [, loading_warehouse, refetch_loading_warehouse] = useSingleEntity<LogisticsKRLoadingWarehouse>(
    endpoints.LogisticsKR.loadingWarehouse(request.loading_warehouse.id),
  )
  const [, unloading_warehouse, refetch_unloading_warehouse] = useSingleEntity<LogisticsKRUnloadingWarehouse>(
    endpoints.LogisticsKR.unloadingWarehouse(request.unloading_warehouse.id),
  )

  if (!loading_warehouse || !unloading_warehouse || !request) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    )
  }

  return (
    <InfoWrapper>
      {request.deal_id && (
        <StyledLink
          eventName="deal"
          itemId={request.deal_id}
          to={generatePath(DealRoutes.Details, { id: request.deal_id })}
        >
          {t('details.accordion.deal', { deal_id: request.deal_id })}
        </StyledLink>
      )}

      <InfoSection>
        <InfoSectionTitle>{t('details.accordion.parameters')}</InfoSectionTitle>
        <InfoRow>
          {request.logistics_status === LogisticStatuses.in_progress && (
            <>
              <TextWrapper>
                <Trans
                  i18nKey="logisticsKR:details.accordion.remaining_quantity"
                  values={{ remaining_quantity: request.trip_remained_quantity }}
                  components={[<TableSectionText key={0} />, <TableSectionAccentText key={1} />]}
                />
              </TextWrapper>
              <TextWrapper>
                <Trans
                  i18nKey="logisticsKR:details.accordion.cars_required"
                  values={{ cars_required: request.cars_needed_count || DASH }}
                  components={[<TableSectionText key={0} />, <TableSectionAccentText key={1} />]}
                />
              </TextWrapper>
            </>
          )}
          {request.logistics_status === LogisticStatuses.new && (
            <>
              <TextWrapper>
                <Trans
                  i18nKey="logisticsKR:details.accordion.price_logistics"
                  values={{ price_logistics: formatTarif(request.price_logistics) }}
                  components={[<TableSectionText key={0} />, <TableSectionAccentText key={1} />]}
                />
              </TextWrapper>
              <TextWrapper>
                <Trans
                  i18nKey="logisticsKR:details.accordion.days_for_export"
                  values={{ days_for_export: request.days_for_export }}
                  components={[<TableSectionText key={0} />, <TableSectionAccentText key={1} />]}
                />
              </TextWrapper>
            </>
          )}
        </InfoRow>
      </InfoSection>
      <InfoSection>
        <InfoSectionTitle>{t('details.accordion.loading_warehouse')}</InfoSectionTitle>
        {request.loading_company && (
          <StyledLink
            eventName="loadingCompany"
            itemId={request.loading_company.id}
            to={generatePath(CompanyRoutes.Details, { id: request.loading_company?.id })}
          >
            {request.loading_company?.name}
          </StyledLink>
        )}
        <InfoSectionText>{request.loading_warehouse.address}</InfoSectionText>

        <SeparateDetailsItem>
          <AddressInfoWrapper>
            <DetailsItemInfo>
              <Text>
                <WarehouseParams warehouse={loading_warehouse} />
              </Text>
            </DetailsItemInfo>
          </AddressInfoWrapper>
          <DetailsItemButtons>
            <DetailsStyledIcon>
              <AButton
                id="editLoading"
                Icon={Icons.IconEdit}
                intent="white"
                onClick={() => setIsLoadingModalOpen(true)}
              />
            </DetailsStyledIcon>
          </DetailsItemButtons>
        </SeparateDetailsItem>
      </InfoSection>

      <InfoSection>
        <InfoSectionTitle>{t('details.accordion.unloading_warehouse')}</InfoSectionTitle>
        {request.unloading_company && (
          <StyledLink
            eventName="unloadingCompany"
            itemId={request.unloading_company.id}
            to={generatePath(CompanyRoutes.Details, { id: request.unloading_company?.id })}
          >
            {request.unloading_company?.name}
          </StyledLink>
        )}
        <InfoSectionText>{request.unloading_warehouse.address}</InfoSectionText>

        <SeparateDetailsItem>
          <AddressInfoWrapper>
            <DetailsItemInfo>
              <Text>
                <WarehouseParams warehouse={unloading_warehouse} />
              </Text>
            </DetailsItemInfo>
          </AddressInfoWrapper>
          <DetailsItemButtons>
            <DetailsStyledIcon>
              <AButton id="edit" Icon={Icons.IconEdit} intent="white" onClick={() => setIsUnloadingModalOpen(true)} />
            </DetailsStyledIcon>
          </DetailsItemButtons>
        </SeparateDetailsItem>
      </InfoSection>

      {isLoadingModalOpen && (
        <LoadingAddressModal
          setIsOpen={setIsLoadingModalOpen}
          loading_warehouse={loading_warehouse}
          refetch={refetch_loading_warehouse}
        />
      )}
      {isUnloadingModalOpen && (
        <UnloadingAddressModal
          setIsOpen={setIsUnloadingModalOpen}
          unloading_warehouse={unloading_warehouse}
          refetch={refetch_unloading_warehouse}
        />
      )}
    </InfoWrapper>
  )
}
