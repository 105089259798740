import { useEffect, useRef } from 'react'

function useRecursiveTimeout<T>(callback: () => Promise<T> | void, delay: number | null) {
  const savedCallback = useRef(callback)

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    let id

    function tick() {
      const ret = savedCallback.current()
      const isPromise = ret instanceof Promise

      if (delay !== null) {
        id = setTimeout(() => {
          if (isPromise) {
            ret.then(() => tick())
          } else {
            tick()
          }
        }, delay)
      }
    }

    if (delay !== null) {
      id = setTimeout(tick, delay)
      return () => id && clearTimeout(id)
    }
  }, [delay])
}

export default useRecursiveTimeout
