import { ResponsiveModal } from 'views/components/ResponsiveModal/ResponsiveModal'
import { DutyType, Export } from 'modules/domain/export/types'
import { refetchFunc } from 'modules/domain/common/hooks'
import { apiClient } from 'modules/utils/httpClient'
import { useAFormHandler } from 'analytics/hooks'
import { useTranslation } from 'react-i18next'
import { CommonFields } from './CommonFields'
import { endpoints } from 'modules/endpoints'
import { FormFields } from './AddExport'
import { useFormik } from 'formik'
import { cloneDeep } from 'lodash'
import { FC } from 'react'

type Props = {
  onClose: () => void
  exportData: Export
  refetch: refetchFunc
}

export const EditExport: FC<Props> = ({ onClose, exportData, refetch }) => {
  const { t } = useTranslation('export')

  const { formProgress, formHandler } = useAFormHandler('addExportContract')

  const formik = useFormik<FormFields>({
    initialValues: {
      product: exportData.product?.id.toString(),
      quantity: exportData.quantity,
      delivery_basis: exportData.delivery_basis,
      number: exportData.number,
      unique_contract_number: exportData.unique_contract_number,
      contract_date: exportData.contract_date,
      through_dubai: exportData.through_dubai,
      through_dubai_price: exportData.through_dubai_price,
      through_dubai_price_currency: exportData.through_dubai_price_currency,
      purchaser: exportData.purchaser?.id.toString(),
      ship: exportData.ship?.id.toString(),
      ship_owner: exportData.ship_owner?.id.toString(),
      price: exportData.price,
      price_currency: exportData.price_currency,
      freight_cost: exportData.freight_cost,
      freight_currency: exportData.freight_currency,
      duty_type: exportData.duty_type,
      duty_value:
        exportData.duty_type === DutyType.FIXED ? exportData.fix_duty_value : exportData.percentage_duty_value,
      storage_cost: exportData.storage_cost,
      storage_currency: exportData.storage_currency,
      documents_cost: exportData.documents_cost,
      documents_currency: exportData.documents_currency,
      warehouse: exportData.warehouse?.id.toString(),
      warehouse_cost: exportData.warehouse_cost,
      warehouse_currency: exportData.warehouse_currency,
      freight_broker: exportData.freight_broker?.id.toString(),
      freight_broker_cost: exportData.freight_broker_cost,
      freight_broker_currency: exportData.freight_broker_currency,
      export_broker: exportData.export_broker?.id.toString(),
      export_broker_cost: exportData.export_broker_cost,
      export_broker_currency: exportData.export_broker_currency,
      insurance: exportData.insurance?.id.toString(),
      insurance_cost: exportData.insurance_cost,
      insurance_currency: exportData.insurance_currency,
      surveyor: exportData.surveyor?.id.toString(),
      surveyor_cost: exportData.surveyor_cost,
      surveyor_currency: exportData.surveyor_currency,
    },

    onSubmit: formHandler(
      async () => {
        const valuesToSend = cloneDeep(formik.values)
        delete valuesToSend.duty_value

        if (formik.values.duty_type === DutyType.FIXED) {
          valuesToSend.fix_duty_value = formik.values.duty_value
        }

        if (formik.values.duty_type === DutyType.PERCENT) {
          valuesToSend.percentage_duty_value = formik.values.duty_value
        }

        await apiClient.put(endpoints.export(exportData.id), valuesToSend)
      },
      {
        onSuccess: () => {
          onClose()
          refetch()
        },
        onError: (error) => {
          const { errors } = error
          formik.setErrors(errors)
        },
      },
    ),
  })

  return (
    <ResponsiveModal
      onConfirm={() => formik.submitForm()}
      confirmButtonText={t('common:save')}
      title={t('newExportContract')}
      progress={formProgress}
      onClose={onClose}
      id="addExport"
    >
      <CommonFields formik={formik} />
    </ResponsiveModal>
  )
}
