import { Icons } from '@agro-club/agroclub-shared'
import { BidPrice } from 'modules/domain/bid/types'
import { getPriceTypeLabel } from 'modules/domain/bid/utils'
import { HighlightPrice, WhoResponded } from 'modules/domain/deal/types'
import { formatTarif } from 'modules/utils/numbers/formatTarif'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { ArrowWrapper, PriceRow } from 'views/pages/Dashboard/components/Cards/CardItems/styled'
import { PriceBlock, TextCard } from 'views/styled/ItemCardStyles'

interface Props {
  sale: { price: number; type: BidPrice }
  purchase: { price: number; type: BidPrice }
  who_responded: WhoResponded | null
  highlight_price: HighlightPrice | null
}

export const PriceSection: FC<Props> = ({ sale, purchase, highlight_price, who_responded }) => {
  const { t } = useTranslation('bid')
  // todo fix highlight_price naming
  return (
    <PriceRow>
      <PriceBlock>
        <TextCard thumbnail={highlight_price === HighlightPrice.exw}>
          {getPriceTypeLabel(sale.type, t)}
          <br />
          {formatTarif(sale.price)}
        </TextCard>
        <TextCard thumbnail={highlight_price === HighlightPrice.cpt}>
          {getPriceTypeLabel(purchase.type, t)}
          <br />
          {formatTarif(purchase.price)}
        </TextCard>
      </PriceBlock>
      {!!who_responded && (
        <ArrowWrapper $whoResponded={who_responded}>
          <Icons.IconCurvedArrow />
        </ArrowWrapper>
      )}
    </PriceRow>
  )
}
