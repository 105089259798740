import { AntTag, Progress, Spinner, Text } from '@agro-club/agroclub-shared'
import useFormatDate from 'hooks/useFormatDate'
import { TemplateType, User } from 'modules/domain/user/types'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { BorderedItem } from 'views/components/BorderedItem/BorderedItem'
import { Pagination } from 'views/components/Pagination/Pagination'
import { SpinnerWrapper } from 'views/styled/common'
import { Filters } from './Filters'
import { SmsInfo } from './SmsInfo'
import { useSmsHistoryManager } from './useSmsHistoryManager'

const Tags = styled.div`
  display: flex;
  gap: 0 8px;
  margin-bottom: 8px;
`
const Content = styled.div`
  width: 100%;
`
const SmsText = styled(Text)`
  white-space: pre-line;
  word-break: break-word;
`

type Props = {
  user: User
}

export const SmsHistory: FC<Props> = ({ user }) => {
  const { t } = useTranslation()
  const { data, progress, listRequestParams, updateFilterState, total, setListRequestParams } = useSmsHistoryManager(
    user.id,
  )

  const formatDate = useFormatDate()

  return (
    <>
      <Filters listRequestParams={listRequestParams} updateFilterState={updateFilterState} />
      {progress === Progress.WORK ? (
        <SpinnerWrapper>
          <Spinner />
        </SpinnerWrapper>
      ) : (
        <div>
          {!!data.length ? (
            data.map((item) => {
              const cashBestBidsOnlyType = item.template_type === TemplateType.cash_best_bids_only
              return (
                <BorderedItem hover={false} key={item.id}>
                  <Content>
                    <Tags>
                      {!!item.statuses.length && <AntTag color="white">{item.statuses[0].status_label}</AntTag>}
                      <AntTag color="white">{formatDate(item.created, true)}</AntTag>
                      {cashBestBidsOnlyType && item.product_title && (
                        <AntTag color="white">{item.product_title}</AntTag>
                      )}
                    </Tags>
                    {item.template_type === TemplateType.static && (
                      <SmsText typography="bodyRegular">{item.static_template_text}</SmsText>
                    )}
                    {(item.template_type === TemplateType.cash || cashBestBidsOnlyType) && (
                      <SmsInfo
                        tableData={item.message_parameters}
                        templateType={item.template_type}
                        product={item.product_title}
                      />
                    )}
                  </Content>
                </BorderedItem>
              )
            })
          ) : (
            <BorderedItem hover={false} justifyContent="center">
              <Text color="secondary">{t('common:not_found')}</Text>
            </BorderedItem>
          )}
        </div>
      )}
      <Pagination
        setCurrPage={(page) => setListRequestParams((prev) => ({ ...prev, page }))}
        currPage={listRequestParams.page}
        total={total}
      />
    </>
  )
}
