import { FC, useState } from 'react'
import { Icons } from '@agro-club/agroclub-shared'
import { useTranslation } from 'react-i18next'
import { ContentBlock } from 'views/pages/LogisticsKR/components/styled'
import { defaultListRequestParams } from 'views/components/TableFilters/data'
import { RequestStatus } from 'views/pages/LogisticsKR/types'
import { TableSectionContainer, TableSectionHeader, TableSectionHeaderRightBlock, TableSectionTitle } from '../styled'
import { OnApprovalTripsTable } from './OnApprovalTripsTable'
import { ListRequestParams } from 'modules/domain/types'
import { LogisticRequest } from 'modules/domain/logisticsKR/types'
import { AButton } from 'views/components/Analytics'
import { AddDraftTripModal } from 'views/pages/LogisticsKR/components/AddDraftTripModal/AddDraftTripModal'

interface Props {
  request: LogisticRequest
  listRequestParams: ListRequestParams
  setListRequestParams: (params: ListRequestParams) => void
}

export const OnApprovalTripsTableSection: FC<Props> = ({ request, listRequestParams, setListRequestParams }) => {
  const { t } = useTranslation('logisticsKR')
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  return (
    <ContentBlock>
      <TableSectionContainer>
        <TableSectionHeader>
          <TableSectionTitle>{t('details.tableSections.approvedHeading')}</TableSectionTitle>

          <TableSectionHeaderRightBlock>
            <AButton id="addTrip" intent="approve" Icon={Icons.IconPlus} onClick={() => setIsModalOpen(true)} />
          </TableSectionHeaderRightBlock>
        </TableSectionHeader>

        <OnApprovalTripsTable
          request={request}
          listRequestParams={listRequestParams}
          setListRequestParams={setListRequestParams}
        />
      </TableSectionContainer>

      {isModalOpen && (
        <AddDraftTripModal
          activeRequest={request}
          onClose={setIsModalOpen}
          onSuccess={() =>
            setListRequestParams({
              ...defaultListRequestParams,
              filter: { status: RequestStatus.on_approval },
            })
          }
        />
      )}
    </ContentBlock>
  )
}
