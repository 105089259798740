import { AntSkeleton } from '@agro-club/agroclub-shared'
import { FC } from 'react'
import { BorderedWrapper } from '../Card/Wrapper'
import styled from 'styled-components'

const PaddedBorderedWrapper = styled(BorderedWrapper)`
  padding: 12px;
  min-width: 298px;
`

export const PreloadCard: FC<{ className?: string }> = (props) => {
  return (
    <PaddedBorderedWrapper className={props.className}>
      <AntSkeleton.Button active size="small" style={{ width: 45, borderRadius: 4 }} />
      <AntSkeleton.Button active size="small" style={{ width: 256, borderRadius: 4 }} />
      <AntSkeleton.Button active size="small" style={{ width: 139, borderRadius: 4 }} />
      <AntSkeleton.Button active size="small" style={{ width: 139, borderRadius: 4 }} />
      <AntSkeleton.Button active size="large" style={{ width: 256, borderRadius: 4 }} />
    </PaddedBorderedWrapper>
  )
}

export const PreloadCount: FC = () => {
  return (
    <AntSkeleton.Avatar
      active
      size="small"
      style={{ width: 24, height: 22, borderRadius: 24, margin: '4px 0px 0px 4px' }}
    />
  )
}
