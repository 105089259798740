import { AntTooltip } from '@agro-club/agroclub-shared'
import { FCWithChild } from 'modules/reactTypes'
import styled from 'styled-components'
import { ReactNode } from 'react'

export const TruncatedValueWrapper = styled.div<{ maxWidth?: number }>`
  width: inherit;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : '100%;')};
`

type Props = {
  hint?: ReactNode
}

export const TruncatedValue: FCWithChild<Props> = ({ hint, children }) => {
  return (
    <AntTooltip overlayStyle={{ maxWidth: 'inherit' }} tooltipContent={hint} variant="secondary" placement="topLeft">
      <TruncatedValueWrapper>{children}</TruncatedValueWrapper>
    </AntTooltip>
  )
}
