import { FC } from 'react'
import { useFormik } from 'formik'
import { useAFormHandler } from 'analytics/hooks'
import { Select } from '@agro-club/agroclub-shared'
import { apiClient } from 'modules/utils/httpClient'
import { endpoints } from 'modules/endpoints'
import { useNotificationProgress } from 'hooks/useNotificationProgress'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { markEventAsRead } from 'modules/socket/managers'
import { refetchFunc } from 'modules/domain/common/hooks'
import { TripOfferClosedReasons } from 'views/pages/LogisticsKR/types'
import { ASharedModal, SharedModalTitle } from 'views/components/SharedModal'
import ADefaultModalFooter from 'views/components/DefaultModalFooter/ADefaultModalFooter'

const ModalContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`

interface FieldProps {
  closed_reason: TripOfferClosedReasons
}

interface Props {
  tripOfferId: string
  onSuccess: refetchFunc | (() => void)
  onClose: () => void
}

export const ClosedReasonModal: FC<Props> = ({ onSuccess, tripOfferId = '', onClose }) => {
  const { t } = useTranslation('logisticBids')
  const { formProgress, formHandler } = useAFormHandler('tripOfferClosing', { id: tripOfferId })

  useNotificationProgress(formProgress)

  const formik = useFormik<FieldProps>({
    initialValues: {
      closed_reason: TripOfferClosedReasons.request_closed,
    },

    onSubmit: formHandler(
      async () => {
        await apiClient.post(endpoints.LogisticsKR.tripOfferClose(tripOfferId), formik.values)
        tripOfferId && (await markEventAsRead([{ model: 'tripoffer', object_id: tripOfferId }]))
      },
      {
        onSuccess: async () => {
          await onSuccess(true)
          onClose()
        },
        onError: (error) => {
          formik.setErrors(error.errors)
        },
      },
    ),
  })

  const options = Object.keys(TripOfferClosedReasons).map((val) => ({
    value: val,
    label: t(`closed_reason.${val}`),
  }))

  return (
    <ASharedModal size="small" id="tripOfferClosed" onClose={onClose}>
      <SharedModalTitle>{t('closed_reason.title')}</SharedModalTitle>
      <ModalContentWrapper>
        {t('closed_reason.body')}
        <Select
          onChange={(val) => formik.setFieldValue('closed_reason', val)}
          options={options}
          value={formik.values.closed_reason}
          showSearch={false}
          fullWidth
          allowClear={false}
        />
      </ModalContentWrapper>
      <ADefaultModalFooter onClose={onClose} onConfirm={() => formik.submitForm()} progress={formProgress} />
    </ASharedModal>
  )
}
