import { Route, Routes } from 'react-router-dom'
import { WarehousesList } from './WarehousesList/WarehousesList'

import WarehousesKRRoutes from './routes'
import { WarehousesDetails } from './WarehousesDetails/WarehousesDetails'
import { FC } from 'react'

const WarehousesKRPage: FC = () => (
  <Routes>
    <Route index element={<WarehousesList />} />
    <Route path={WarehousesKRRoutes.relativeListWithTab} element={<WarehousesList />} />
    <Route path={WarehousesKRRoutes.relativeDetails} element={<WarehousesDetails />} />
  </Routes>
)
export default WarehousesKRPage
