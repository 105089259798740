import { StyledCompanyLink } from '../styled'
import { FC } from 'react'

type Props = {
  companyId: number | string
  companyName: string
}

export const UserCompanyLink: FC<Props> = ({ companyId, companyName }) => {
  return (
    <StyledCompanyLink eventName="company" itemId={String(companyId)} to={`/companies/${companyId}`}>
      {companyName}
    </StyledCompanyLink>
  )
}
