import { CSSProperties, FC, useMemo } from 'react'

import { Modal } from '@agro-club/agroclub-shared'
import { Props as ReactModalProps } from 'react-modal'
import {
  SharedModalContent,
  SharedModalFooter,
  SharedModalFooterWrapper,
  SharedModalInputs,
  SharedModalTitle,
} from './styled'

export interface InternalSharedModalProps extends Omit<ReactModalProps, 'isOpen'> {
  contentStyle?: CSSProperties
  size?: 'small' | 'medium' | 'large' | 'extraLarge'
  onClose: () => void
}

const modalContentStyles = (maxWidth: string): CSSProperties => ({
  alignItems: 'stretch',
  maxWidth,
  padding: '24px',
  borderRadius: '8px',
})

export const InternalSharedModal: FC<InternalSharedModalProps> = ({
  size = 'medium',
  children,
  contentStyle,
  ...otherProps
}) => {
  const styles = useMemo(() => {
    switch (size) {
      case 'small':
        return modalContentStyles('448px')
      case 'medium':
        return modalContentStyles('684px')
      case 'large':
        return modalContentStyles('802px')
      case 'extraLarge':
        return modalContentStyles('1460px')
    }
  }, [size])

  return (
    <Modal
      isOpen={true}
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={false}
      contentStyle={{ ...styles, ...contentStyle }}
      {...otherProps}
    >
      {children}
    </Modal>
  )
}

export { SharedModalTitle, SharedModalContent, SharedModalInputs, SharedModalFooterWrapper, SharedModalFooter }
