import { useAFormHandler } from 'analytics/hooks'
import { useFormik } from 'formik'
import { endpoints } from 'modules/endpoints'
import { apiClient } from 'modules/utils/httpClient'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import FormAddress from 'views/components/form/FormAddress/FormAddress'
import { FormInput } from 'views/components/form/FormInput'
import FormSelectShared from 'views/components/FormSelectShared'
import { ResponsiveModal } from 'views/components/ResponsiveModal/ResponsiveModal'
import { SharedModalInputs } from 'views/components/SharedModal'

type Props = {
  onClose: () => void
  onSuccess: (newWarehouseId?: string) => void
}

export const CreateWarehouseModal: FC<Props> = ({ onClose, onSuccess }) => {
  const { t } = useTranslation('warehouses')
  const { formProgress, formHandler } = useAFormHandler('addWarehouse')

  const formik = useFormik({
    initialValues: {
      title: null,
      owner: null,
      address: null,
    },

    onSubmit: formHandler(async (value) => await apiClient.post(endpoints.warehouses(), value), {
      onSuccess: (res) => {
        onSuccess(res.id)
        onClose()
      },
      onError: (error) => {
        const { errors } = error
        formik.setErrors(errors)
      },
    }),
  })

  const userSelectFilter = useMemo(
    () => ({
      with_company: true,
    }),
    [],
  )

  const userChangeHandler = (value) => {
    formik.setFieldValue('owner', value)
    if (formik.values.address) {
      formik.setFieldValue('address', null)
    }
  }

  return (
    <ResponsiveModal
      id="createWarehouse"
      title={t('addModal.title')}
      progress={formProgress}
      confirmButtonText={t('common:save')}
      onConfirm={() => formik.submitForm()}
      onClose={onClose}
    >
      <SharedModalInputs>
        <FormInput label={t('addModal.name')} formik={formik} field="title" required />

        <FormSelectShared
          label={t('addModal.owner')}
          formik={formik}
          onChange={userChangeHandler}
          placeholder={t('bid:form.chooseUser')}
          fieldName="owner"
          endpoint={endpoints.users()}
          filter={userSelectFilter}
          getLabel={(dto) => dto.profile.full_name || t('common:withoutName')}
          searchField="search"
          required
        />

        {formik.values.owner && (
          <FormAddress
            label={t('addModal.address')}
            disabled={!formik.values.owner}
            formik={formik}
            userId={formik.values.owner}
            required
          />
        )}
      </SharedModalInputs>
    </ResponsiveModal>
  )
}
