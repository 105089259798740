import { Typography, AntTag, defaultTheme } from '@agro-club/agroclub-shared'
import styled from 'styled-components'
import { CustomScroll } from 'views/styled/common'

export const WrapperBoard = styled.div<{ dividingBorder?: boolean }>`
  display: flex;
  align-items: flex-start;
  height: calc(100dvh - 210px);
  ${CustomScroll}
  .ant-collapse {
    max-height: calc(100dvh - 160px);
  }

  .ant-collapse-header {
    border-bottom: ${({ dividingBorder }) => (dividingBorder ? `1px solid ${defaultTheme.color.secondary50}` : 'none')};
  }

  .ant-collapse-content-box {
    max-height: calc(100dvh - 350px);
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0 16px 0 16px;
    margin-right: 2px;
    box-shadow: inset 0px 20px 5px -15px rgba(39, 45, 51, 0.08);

    ${CustomScroll}
  }
  .ant-collapse-content {
    border-top: none;
  }
`
export const WrapperCards = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px 0;
  padding: 10px 0 16px 0;
`
export const Count = styled(AntTag).attrs({
  counter: true,
})`
  ${Typography.accentMedium}
  border: none;
  margin-left: 4px;
  padding: 0 8px;
`

export const WrapperCollapse = styled.div`
  width: 330px;
  margin-right: 24px;
  height: 100%;
`
export const ContentWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 16px;
  height: calc(100dvh - 130px);
  overflow: auto;
  ${CustomScroll}
`
