import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import { SharedModalTitle } from 'views/components/SharedModal'
import { RequestError } from 'modules/errors'
import { apiClient } from 'modules/utils/httpClient'
import { endpoints } from 'modules/endpoints'
import { useAFormHandler } from 'analytics/hooks'
import ASharedModal from 'views/components/SharedModal/ASharedModal'
import { AnalyticsPlaces } from '../../analyticsPlaces'
import ADefaultModalFooter from 'views/components/DefaultModalFooter/ADefaultModalFooter'
import { FC } from 'react'
import { EditableAverageRatesDTO, EditableRatesDTO, RatesProductTypes, RateType } from '../types'
import { getLogisticsRanges } from '../../helpers'
import { Logistician } from '../../types'
import { ModalContent } from './ModalContent'
import { useParams } from 'react-router-dom'
import { isBrazil } from 'env'

interface Props {
  regionIds?: string[]
  refetch: () => void
  onClose: () => void
  ratesData?: RateType[]
  responsibleLogisticians?: Logistician[]
  currentProductType?: RatesProductTypes
  updateSelectedRows?: (data: EditableRatesDTO) => void
}

export const EditRatesModal: FC<Props> = ({
  onClose,
  refetch,
  ratesData,
  responsibleLogisticians,
  regionIds,
  currentProductType,
  updateSelectedRows,
}) => {
  const { activeTab: activeTabParams } = useParams()
  const isAverageRates = activeTabParams === RatesProductTypes.average

  const { formProgress, formHandler } = useAFormHandler('editRates')
  const { t } = useTranslation('rates')
  const initialRates: RateType[] = getLogisticsRanges().map((range, index) => ({
    rate: ratesData?.[index]?.rate,
    distance_range: range,
  }))

  const formik = useFormik<EditableRatesDTO>({
    initialValues: {
      sale_regions: regionIds ? regionIds.map((r) => String(r)) : [],
      rates: initialRates,
      product_type: currentProductType ?? '',
      responsible_logisticians: responsibleLogisticians
        ? [...responsibleLogisticians?.map((logistician) => logistician.id)]
        : [],
    },
    enableReinitialize: true,

    onSubmit: formHandler(
      async () => {
        let filteredRates: RateType[]
        const reverseIndexOfLastFilled = [...formik.values.rates]
          .reverse()
          .findIndex((item) => typeof item.rate === 'number' && item.rate > 0)

        if (reverseIndexOfLastFilled !== -1) {
          const indexOfLastFilled = formik.values.rates.length - 1 - reverseIndexOfLastFilled
          filteredRates = formik.values.rates.slice(0, indexOfLastFilled + 1)
        } else {
          filteredRates = formik.values.rates.filter((item) => item.rate)
        }

        let data

        if (isAverageRates) {
          data = { rates: filteredRates }
          await apiClient.put<EditableAverageRatesDTO>(endpoints.LogisticsKR.averageRates(), data)
        } else {
          const emptyResponsibleLogisticians = isBrazil ? [] : null
          data = {
            sale_regions: formik.values.sale_regions.length ? formik.values.sale_regions : null,
            rates: filteredRates,
            product_type: formik.values.product_type,
            responsible_logisticians: formik.values.responsible_logisticians.length
              ? formik.values.responsible_logisticians
              : emptyResponsibleLogisticians,
          }
          const response = await apiClient.post<EditableRatesDTO>(endpoints.LogisticsKR.relatedRates(), data)

          updateSelectedRows?.(response)
        }
      },
      {
        onSuccess: async () => {
          await refetch()
          onClose()
        },

        onError: (err) => {
          const error = err as RequestError
          const { errors } = error
          formik.setErrors(errors)
        },
      },
    ),
  })

  return (
    <ASharedModal id={AnalyticsPlaces.RATES.EDIT_MODAL} size="medium" onClose={onClose}>
      <SharedModalTitle>{t('modal.heading')}</SharedModalTitle>

      <ModalContent formik={formik} isAverageRates={isAverageRates} isEditModal />

      <ADefaultModalFooter
        confirmButtonText={t('common:save')}
        onClose={onClose}
        onConfirm={() => formik.dirty && formik.submitForm()}
        progress={formProgress}
        disabled={!formik.dirty}
      />
    </ASharedModal>
  )
}
