import { Divider } from 'views/layouts/MainLayout/MainLayout'
import { AButton } from 'views/components/Analytics'
import { Progress } from '@agro-club/agroclub-shared'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { FC, ReactNode } from 'react'

const Controls = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px 0;
`
type Props = {
  onConfirm?: () => void
  onClose?: () => void
  disabled?: boolean
  progress?: Progress
  confirmButtonText?: string
  children?: ReactNode
}

export const MobileModalFooter: FC<Props> = ({
  confirmButtonText,
  onConfirm,
  disabled,
  progress,
  onClose,
  children,
}) => {
  const { t } = useTranslation()
  return (
    <>
      {(onConfirm || onClose) && (
        <Controls>
          <Divider />
          {onConfirm && (
            <AButton
              onClick={onConfirm}
              disabled={disabled}
              progress={progress}
              intent="primary"
              size="biggest"
              id="confirm"
            >
              {confirmButtonText || t('common:confirm')}
            </AButton>
          )}

          {onClose && (
            <AButton id="cancel" intent="secondary" size="biggest" onClick={onClose}>
              {t('common:cancel')}
            </AButton>
          )}
          {children}
        </Controls>
      )}
    </>
  )
}
