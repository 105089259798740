import { Route, Routes } from 'react-router-dom'
import { DocumentRequestsDetail } from './DocumentRequestsDetail/DocumentRequestsDetail'
import { DocumentRequestsList } from './DocumentRequestsList/DocumentRequestsList'
import { DocumentRequestsRoutes } from './routes'
import { FC } from 'react'

export const DocumentRequestsPage: FC = () => {
  return (
    <Routes>
      <Route index element={<DocumentRequestsList />} />
      <Route path={DocumentRequestsRoutes.RelativeDetails} element={<DocumentRequestsDetail />} />
    </Routes>
  )
}
