import styled from 'styled-components'
import { tableContainer } from '../styled'
import { useTranslation } from 'react-i18next'
import { useTeamsTableData } from 'views/pages/Teams/hooks'
import { Title } from 'views/pages/Teams/TeamsTableCard/styled'
import { SellerTable } from 'views/pages/Teams/SellerTableCard/SellerTable'

const Container = styled.div`
  ${tableContainer};
  width: 448px;
`

export const SellerTableCard = () => {
  const { t } = useTranslation('teams')

  const [progress, membersList] = useTeamsTableData('customer')

  return (
    <div>
      <Container>
        <Title>{t('selling')}</Title>
        <SellerTable membersList={membersList} progress={progress} />
      </Container>
    </div>
  )
}
