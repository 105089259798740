import { defaultTheme, Typography } from '@agro-club/agroclub-shared'
import { CustomScroll } from 'views/styled/common'
import styled, { css } from 'styled-components'

const flexCommonStyles = css`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`
export const SharedModalTitle = styled.div`
  margin-bottom: 24px;
  ${Typography.titleH3};
  color: ${defaultTheme.color.textPrimary900};
`

export const SharedModalSubTitle = styled.div`
  ${Typography.titleH4};
  color: ${defaultTheme.color.textPrimary900};
  @media (max-width: ${defaultTheme.queries.mobile}) {
    text-align: center;
  }
`

export const SharedModalContent = styled.div`
  display: flex;
  flex-direction: column;
  max-height: calc(100dvh - 300px);
  overflow-y: auto;
  overflow-x: hidden;
  ${CustomScroll}
`

export const SharedModalSecondContent = styled.div`
  ${flexCommonStyles}
  margin-top: 24px;
`
export const SharedModalGroupContent = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  margin-bottom: 24px;
`
export const SharedModalInputs = styled.div`
  ${flexCommonStyles}
`

export const SharedModalFooterWrapper = styled.div<{ withMargin?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: ${({ withMargin }) => (withMargin ? '24px' : 0)};
`

export const SharedModalFooter = styled.div<{ withMargin?: boolean }>`
  display: flex;
  align-items: center;
  column-gap: 16px;
  margin-top: ${({ withMargin = true }) => (withMargin ? '24px' : 0)};
`
