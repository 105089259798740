import { Card } from 'views/components/Card/Card'
import { KeyValue } from 'views/components/KeyValue/KeyValue'
import { useTranslation } from 'react-i18next'
import { DASH } from 'modules/constants'
import { RoutesFileResponse } from 'modules/domain/routes/types'

type Props = {
  file?: RoutesFileResponse
}

export const RightBlock = ({ file }: Props) => {
  const { t } = useTranslation('routes')

  return (
    <Card.Container>
      <Card.GapableContent>
        <Card.Title>{t('load_info')}</Card.Title>
        {/*TODO - file, date, author пока не завезли */}
        {/*<KeyValue label={t('file')}>{DASH}</KeyValue>*/}
        {/*<KeyValue label={t('date')}>{DASH}</KeyValue>*/}
        {/*<KeyValue label={t('author')}>{DASH}</KeyValue>*/}
        <KeyValue label={t('detail')}>
          {!!file ? (
            <>
              <div>{t('entries_count', { count: file.entries })}</div>
              <div>{t('updated_count', { count: file.updated })}</div>
              <div>{t('created_count', { count: file.created })}</div>
              <div>{t('invalid_count', { count: file.invalid })}</div>
              <div>{t('partially_filled_count', { count: file.partially_filled })}</div>
            </>
          ) : (
            DASH
          )}
        </KeyValue>
      </Card.GapableContent>
    </Card.Container>
  )
}
