import { useTranslation } from 'react-i18next'

import * as Layout from 'views/layouts/NewLayout'
import SpinnerLayout from 'views/layouts/SpinnerLayout/SpinnerLayout'
import * as Header from 'views/ui/Header/Header'
import { FC } from 'react'

const ItemLoadingLayout: FC<{
  id: string
}> = ({ id }) => {
  const { t } = useTranslation('common')

  return (
    <Layout.WrapperContent>
      <Layout.Header>
        <Layout.TopHeader>
          <Header.Breadcrumbs routes={[{ breadcrumbName: t('loading') }]} />
        </Layout.TopHeader>
        <Layout.DetailPageSubTitle>{`ID ${id}`}</Layout.DetailPageSubTitle>
      </Layout.Header>
      <Layout.WrapperContent>
        <SpinnerLayout />
      </Layout.WrapperContent>
    </Layout.WrapperContent>
  )
}

export default ItemLoadingLayout
