import { REACT_APP_LOCALE } from 'env'
import _ from 'lodash'

const formatNumber = (
  value?: number | string | null,
  keepDigitsAfterPoint = false,
  options?: { fractionDigits?: number },
) => {
  if (_.isNil(value)) {
    return ''
  }
  if (typeof value === 'string' && Number.isNaN(Number(value))) {
    return value
  }
  const fractionOptions = keepDigitsAfterPoint
    ? { minimumFractionDigits: options?.fractionDigits ?? 2, maximumFractionDigits: options?.fractionDigits ?? 2 }
    : undefined

  return Number(value || 0).toLocaleString(REACT_APP_LOCALE, fractionOptions)
}

export default formatNumber
