import {
  InternalTableFullPageWithoutDataProps,
  InternalTableFullPageWithoutData,
} from './InternalTableFullPageWithoutData'
import { useAnalyticsChangeHandler } from 'analytics/hooks'
import { FC } from 'react'

export const ATableFullPageWithoutData: FC<InternalTableFullPageWithoutDataProps> = ({ ...otherProps }) => {
  const handlePageChange = useAnalyticsChangeHandler('tablePage')

  return <InternalTableFullPageWithoutData onPageChange={handlePageChange()} {...otherProps} />
}
