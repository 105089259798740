import { defaultTheme } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

export const TaskDetail = styled.div`
  flex: 1;
`

export const AdditionalInfo = styled.div`
  color: ${defaultTheme.color.secondaryPlaceholder};
`
