import { FC } from 'react'
import { TableTextLink } from 'views/ui/TableTextLink/TableTextLink'
import { generatePath } from 'react-router-dom'
import formatNumber from 'modules/utils/numbers/formatNumber'
import LogisticRequestsRoutes from 'views/pages/LogisticsKR/LogisticRequests/routes'

interface Props {
  value: number
  logisticsRequestId: string | null
}

export const TripsLinkCell: FC<Props> = ({ value, logisticsRequestId }) => {
  if (value && logisticsRequestId) {
    return (
      <TableTextLink
        id="logisticRequestLink"
        to={generatePath(LogisticRequestsRoutes.Details, { id: logisticsRequestId })}
        target="_blank"
      >
        {formatNumber(value)}
      </TableTextLink>
    )
  }

  return <div>{formatNumber(value)}</div>
}
