import { isBrazil, isUS, REACT_APP_CURRENCY_SYMBOL } from 'env'
import { t } from 'i18next'
import { FC } from 'react'
import { TextWithSubText } from 'views/components/TextWithSubText/TextWithSubText'
import formatNumber from './formatNumber'

const currency = REACT_APP_CURRENCY_SYMBOL

export const quantityInBags = (valueTons: number) => {
  const result = (valueTons * 1000) / 60
  const formattedBags = result % 1 !== 0 ? result.toFixed(1) : result
  return `${formatNumber(formattedBags)} ${t('common:bags')}`
}

export const quantityInTons = (valueTons: number) => {
  return `${formatNumber(valueTons)} ${t('common:t')}`
}

export const formatQuantity = (valueTons: number) => {
  const formattedTons = formatNumber(valueTons)

  if (isUS) {
    return `${formattedTons} ${t('common:bsh')}`
  }

  if (isBrazil) {
    return `${formattedTons} ${t('common:tons')} (${quantityInBags(valueTons)})`
  }

  return quantityInTons(valueTons)
}

const unit = () => {
  if (isUS) {
    return t('common:bsh')
  }
  return t('common:ton')
}

export const labelQuantity = (label?: string) => {
  return `${label || t('common:quantity')}, ${unit()}`
}

export const labelQuantityTarif = (label: string) => `${label}, ${currency}/${unit()}`

export const VerticalFormatQuantity: FC<{ valueTons: number }> = ({ valueTons }) => {
  return <TextWithSubText text={quantityInTons(valueTons)} subText={quantityInBags(valueTons)} />
}
