import { AntSwitch, Progress } from '@agro-club/agroclub-shared'
import { useNotificationProgress } from 'hooks/useNotificationProgress'
import { BidStatus } from 'modules/domain/bid/types'
import { endpoints } from 'modules/endpoints'
import { apiClient } from 'modules/utils/httpClient'
import { FC, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { BidPriceTableCellProps } from './types'
import { useAProgress } from 'hooks/useAProgress'

const SwitchCell: FC<BidPriceTableCellProps> = ({ bid, refetch }) => {
  const { t } = useTranslation()
  const [checked, setChecked] = useState(bid.status === BidStatus.published)

  useEffect(() => {
    setChecked(bid.status === BidStatus.published)
  }, [bid])

  const [progress, submit] = useAProgress(
    async (value) => {
      await apiClient.post(endpoints.bidChangeStatus(bid.id), {
        new_status: value ? BidStatus.published : BidStatus.archived,
      })
      await refetch()
    },
    { eventName: 'publish', scope: 'change' },
  )

  useNotificationProgress(progress, t('bid:form.notifyEditSuccess'))

  return <AntSwitch checked={checked} onChange={submit} loading={progress === Progress.WORK} />
}

export default SwitchCell
