import { FC } from 'react'
import { DealProvider } from './DealContext'
import { DealDetailWithProvider } from './DealDetailWithProvider'

export const DealDetail: FC = () => {
  return (
    <DealProvider>
      <DealDetailWithProvider />
    </DealProvider>
  )
}
