import { CommentContainer, CommentDate, CommentHeader, CommentText } from './styled'
import { Card } from 'views/components/Card/Card'
import { Icons, Text, defaultTheme } from '@agro-club/agroclub-shared'
import styled from 'styled-components'
import useFormatDate from 'hooks/useFormatDate'
import { CommentItemProps } from 'views/components/Comments/CommentsItem'
import { useTranslation } from 'react-i18next'
import { ACommentsTrack } from 'views/components/Comments/AComments'

const HeaderWrapper = styled(CommentHeader)`
  display: flex;
  column-gap: 4px;
`

const AuthorInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
`

const AuthorInfo = styled.div`
  display: flex;
  @media (max-width: ${defaultTheme.queries.mobile}) {
    flex-direction: column;
  }
`

const StarIcon = styled(Icons.IconStar)`
  & > path {
    fill: ${({ theme }) => theme.color.accentNotify600};
  }
`
const Date = styled(CommentDate)`
  @media (max-width: ${defaultTheme.queries.mobile}) {
    margin-left: 0px;
  }
`
export const PinnedCommentItem = ({
  comment,
  setComment,
  setIsEdit,
  isEdit,
  setCommentId,
  onATrack,
}: CommentItemProps & { onATrack: ACommentsTrack }) => {
  const { t } = useTranslation('comments')
  const formatDate = useFormatDate()
  const onClickEdit = () => {
    onATrack('click|edit', comment.comment)
    setIsEdit?.(true)
    setComment?.(comment.comment)
    setCommentId?.(comment.id)
  }

  const editInfo = `${t('edited')}: ${comment.last_modified_author} ${formatDate(comment.modified, true)}`

  return (
    <CommentContainer>
      <Card.Container disableShadow padding="compact" intent="description">
        {!isEdit && <Card.EditButton size="small" onClick={onClickEdit} />}

        <HeaderWrapper>
          <StarIcon />
          <AuthorInfoWrapper>
            <AuthorInfo>
              <Text as="span" typography="accentMedium">
                {comment.author}
              </Text>
              <Date>{formatDate(comment.created, true)}</Date>
            </AuthorInfo>
            {comment.last_modified_author && (
              <Text color="accent" typography="bodySmall">
                {editInfo}
              </Text>
            )}
          </AuthorInfoWrapper>
        </HeaderWrapper>

        <CommentText>{comment.comment}</CommentText>
      </Card.Container>
    </CommentContainer>
  )
}
