import { Typography, defaultTheme } from '@agro-club/agroclub-shared'
import styled from 'styled-components'
import { Card } from 'views/components/Card/Card'
import { SharedModalInputs } from 'views/components/SharedModal'

export const SmallText = styled.div`
  ${Typography.bodyMedium}
  margin-bottom: 12px;
`

export const DealInfoFirstRow = styled.div`
  display: grid;
  grid-template-columns: 120px 120px auto;
  grid-gap: 24px;
  @media (max-width: ${defaultTheme.queries.mobile}) {
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
    & > :nth-child(3) {
      grid-column: 1 / -1;
    }
  }
`

export const DealInfoSecondRow = styled.div`
  display: grid;
  grid-gap: 24px;
  grid-template-columns: 1fr 1fr;
  margin-top: 24px;
  @media (max-width: ${defaultTheme.queries.mobile}) {
    grid-template-columns: 1fr;
    margin-top: 8px;
    gap: 8px 0px;
  }
`

export const ModalFormWrapper = styled(SharedModalInputs)`
  margin-top: 16px;
`

export const InputWithCheckboxWrapper = styled.div`
  margin-left: 211px;
  margin-top: 8px;
  @media (max-width: ${defaultTheme.queries.mobile}) {
    margin-left: 0px;
  }
`

export const DescriptionContainer = styled(Card.Container).attrs({
  intent: 'description',
  disableShadow: true,
})`
  height: 106px;
  @media (max-width: ${defaultTheme.queries.mobile}) {
    height: auto;
    margin-bottom: 4px;
  }
`
