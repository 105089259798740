import styled from 'styled-components'
import { Icons, defaultTheme } from '@agro-club/agroclub-shared'
import { useTranslation } from 'react-i18next'
import { Text } from '@agro-club/agroclub-shared'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`

const Icon = styled(Icons.IconArrowCheck)`
  width: 19px;
  height: 15px;
  margin-bottom: 8px;

  > path {
    fill: ${defaultTheme.color.textSecondary60a};
  }
`

export const NoData = () => {
  const { t } = useTranslation('common')

  return (
    <Wrapper>
      <Icon />
      <Text typography="titleH4" color="secondary">
        {t('noData')}
      </Text>
    </Wrapper>
  )
}
