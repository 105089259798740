import { Text } from '@agro-club/agroclub-shared'
import { useAProgress } from 'hooks/useAProgress'
import { refetchFunc } from 'modules/domain/common/hooks'
import { Company, FactoringStatus } from 'modules/domain/company/types'
import { ProfileType } from 'modules/domain/user/types'
import { endpoints } from 'modules/endpoints'
import { apiClient } from 'modules/utils/httpClient'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import AButton from 'views/components/Analytics/AButton'

interface Props {
  company: Company
  refetchCompany: refetchFunc
}

const Wrapper = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`

export const FactoringHeader = ({ company, refetchCompany }: Props) => {
  const { t } = useTranslation('company')
  const [requestFactoringProgress, requestFactoring] = useAProgress(
    (factoringType) => apiClient.post(endpoints.requestFactoring(company.id), { factoring_type: factoringType }),
    {
      eventName: 'requestFactoring',
      analyticsPayload: {
        id: company.id,
      },
      onSuccess: () => refetchCompany(true),
    },
  )
  let canRequestSellerFactoring = false
  let canRequestCustomerFactoring = false

  if (!company.company_member_types) {
    canRequestSellerFactoring = true
    canRequestCustomerFactoring = true
  } else {
    if (
      company.factoring_seller_status === FactoringStatus.not_verified &&
      (company.company_member_types.includes(ProfileType.seller) || !company.company_member_types.length)
    ) {
      canRequestSellerFactoring = true
    }
    if (
      company.factoring_customer_status === FactoringStatus.not_verified &&
      (company.company_member_types.includes(ProfileType.customer) || !company.company_member_types.length)
    ) {
      canRequestCustomerFactoring = true
    }
  }

  if (!canRequestSellerFactoring && !canRequestCustomerFactoring) return null

  return (
    <Wrapper>
      <Text color="secondary"> {t('requestFactoring')}: </Text>
      {canRequestSellerFactoring && (
        <AButton
          intent="secondary"
          id="submit|requestSellerFactoring"
          progress={requestFactoringProgress}
          onClick={() => requestFactoring(ProfileType.seller)}
        >
          {t('factoringTypes.seller')}
        </AButton>
      )}
      {canRequestCustomerFactoring && (
        <AButton
          intent="secondary"
          id="submit|requestCustomerFactoring"
          progress={requestFactoringProgress}
          onClick={() => requestFactoring(ProfileType.customer)}
        >
          {t('factoringTypes.customer')}
        </AButton>
      )}
    </Wrapper>
  )
}
