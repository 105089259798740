import { ColumnData } from 'modules/types'
import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import { CarrierCell } from 'views/pages/LogisticsKR/LogisticRequests/RequestDetails/components/RequestTripsTables/TripOffersSection/CarrierCell'
import { DriverCell } from 'views/pages/LogisticsKR/LogisticRequests/RequestDetails/components/RequestTripsTables/TripOffersSection/DriverCell'
import { ActionsCell } from 'views/pages/LogisticsKR/LogisticRequests/RequestDetails/components/RequestTripsTables/TripOffersSection/ActionsCell'
import { LogisticRequest } from 'modules/domain/logisticsKR/types'
import { CellRenders } from 'views/components/Table/renderers'

type Props = {
  request: LogisticRequest
  tripOffersRefetch: () => void
  draftTripsRefetch: () => void
}

export const useTripOffersColumns = ({ request, tripOffersRefetch, draftTripsRefetch }: Props): ColumnData[] => {
  const { t } = useTranslation('logisticsKR')
  return useMemo(
    () => [
      {
        title: t('details.tableHeaders.trip_offer'),
        width: 94,
        dataIndex: 'created',
        render: CellRenders.date,
      },
      {
        title: t('details.tableHeaders.load_date'),
        width: 94,
        dataIndex: 'load_date',
        render: CellRenders.date,
      },
      {
        title: t('details.tableHeaders.company'),
        width: 408,
        dataIndex: 'carrier',
        render: (carrier) => <CarrierCell carrier={carrier} />,
      },
      {
        title: t('details.tableHeaders.driver'),
        width: 320,
        dataIndex: 'driver',
        render: (_driver, tripOffer) => <DriverCell tripOffer={tripOffer} />,
      },
      {
        title: '',
        width: 86,
        dataIndex: 'actions',
        render: (_, tripOffer) => (
          <ActionsCell
            request={request}
            tripOffer={tripOffer}
            draftTripsRefetch={draftTripsRefetch}
            tripOffersRefetch={tripOffersRefetch}
          />
        ),
      },
    ],
    [draftTripsRefetch, request, t, tripOffersRefetch],
  )
}
