import { AntTag } from '@agro-club/agroclub-shared'
import { Deal } from 'modules/domain/deal/types'
import { FC } from 'react'
import { IconOneC } from '../../Board/styled'

interface Props {
  deal: Deal
}

export const Tag1C: FC<Props> = ({ deal }) => {
  return <AntTag icon={<IconOneC $approve={deal.margin_confirmed} />} color={deal.margin_confirmed ? 'blue' : 'grey'} />
}
