import { FC } from 'react'
import { ListRequestParams } from 'modules/domain/types'
import { useTranslation } from 'react-i18next'
import { endpoints } from 'modules/endpoints'
import { ABaseTableFilter } from 'views/components/TableFilters/ABaseTableFilter'
import { AnalyticsFilterVariant } from 'analytics/types'

interface Props {
  listRequestParams: ListRequestParams
  updateFilterState: (filterObj: Record<string, any>, commaShieldingParam?: string) => void
  disabled?: boolean
  fieldKey: 'logistician' | 'logisticians'
  multiple?: boolean
}

export const LogisticianFilter: FC<Props> = ({
  listRequestParams,
  updateFilterState,
  disabled,
  fieldKey,
  multiple,
}) => {
  const { t } = useTranslation('logisticsKR')

  return (
    <ABaseTableFilter
      id={AnalyticsFilterVariant.LOGISTICIAN}
      onChange={(value) => updateFilterState({ [fieldKey]: value })}
      endpoint={endpoints.dispatchingLogisticians()}
      value={listRequestParams.filter[fieldKey]}
      placeholder={t(`list.filters.${fieldKey}`)}
      getLabel={(dto) => dto.full_name}
      searchField="search"
      showSearch={true}
      multiple={multiple}
      disabled={disabled}
    />
  )
}
