import { FC, useEffect } from 'react'
import { useSingleEntity } from 'modules/domain/common/hooks'
import { Rating } from 'modules/domain/types'
import { endpoints } from 'modules/endpoints'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router-dom'
import { SpinnerCenter } from 'views/pages/Specifications/styled'
import UserRoutes from 'views/pages/User/routes'
import {
  RatedBy,
  RatedByLink,
  RatingItemContainer,
  RatingItemSubTitle,
  RatingItemTitle,
  RatingItemWrapper,
  SpinnerWrapper,
  StyledIcon,
  StyledRatingItem,
  StyledRatingsList,
  TooltipContent,
} from './styled'
import { AntRate, AntTooltip, Icons, Progress } from '@agro-club/agroclub-shared'
import { changeRating } from 'modules/domain/company/managers'
import { RatingTypes } from './types'
import { useAProgress } from 'hooks/useAProgress'

interface Props {
  rating: Rating
  dealId: string
  onSuccess?: () => void
  setProgress: (
    dealId: string,
    values: {
      value: number
      rating_type: string
    },
  ) => void
}

export const RatingItem: FC<Props> = ({ rating, dealId, onSuccess, setProgress }) => {
  const { t } = useTranslation('common')
  const { editable, label, rated_by, rating_type, tooltip, value } = rating

  const handleRate = async (value) => {
    await setProgress(dealId, { value, rating_type })
    onSuccess?.()
  }

  return (
    <StyledRatingItem>
      <RatingItemWrapper>
        <RatingItemContainer>
          <RatingItemTitle>{label}</RatingItemTitle>
          <AntRate value={value} onChange={handleRate} disabled={!editable} />
        </RatingItemContainer>

        {rating_type !== RatingTypes.seller_quality && (
          <AntTooltip
            tooltipContent={<TooltipContent>{tooltip}</TooltipContent>}
            variant="secondary"
            placement="topRight"
          >
            <StyledIcon>
              <Icons.IconHow />
            </StyledIcon>
          </AntTooltip>
        )}
      </RatingItemWrapper>

      <RatingItemSubTitle>{t('ratedBy')}</RatingItemSubTitle>
      {rated_by?.full_name ? (
        <RatedByLink eventName="userLink" to={generatePath(UserRoutes.Details, { id: rated_by.id })}>
          {rated_by.full_name}
        </RatedByLink>
      ) : (
        <RatedBy>{t('no')}</RatedBy>
      )}
    </StyledRatingItem>
  )
}

interface ListProps {
  dealId: string
  dealStatus: string
}

export const RatingsList: FC<ListProps> = ({ dealId, dealStatus }) => {
  const [fetchProgress, ratings, refetchRatings] = useSingleEntity<Rating[]>(endpoints.dealRating(dealId))
  const [ratingProgress, setRating] = useAProgress(changeRating, {
    eventName: 'changeRating',
  })

  useEffect(() => {
    if (!ratings) return

    refetchRatings()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dealStatus])

  if (fetchProgress === Progress.WORK || ratingProgress === Progress.WORK || !ratings) {
    return (
      <SpinnerWrapper>
        <SpinnerCenter />
      </SpinnerWrapper>
    )
  }

  return (
    <StyledRatingsList>
      {ratings.map((rating) => (
        <RatingItem
          key={rating.label}
          rating={rating}
          dealId={dealId}
          onSuccess={refetchRatings}
          setProgress={setRating}
        />
      ))}
    </StyledRatingsList>
  )
}
