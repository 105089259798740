import { Navigate, useLocation } from 'react-router-dom'
import AuthRoutes from 'views/pages/Auth/routes'
import InternalPageWrapper from 'views/components/PageWrapper/InternalPageWrapper'
import { OPTIONS, defaultRoute } from '../MainMenu/Options/CommonOptions'
import { useFilteredOptionsByPerms } from '../MainMenu/utils'
import { AuthContext } from 'modules/context/AuthContext'
import { useContext } from 'react'

const PrivatePageWrapper = () => {
  const location = useLocation()

  const { profile } = useContext(AuthContext)
  const options = useFilteredOptionsByPerms(Object.values(OPTIONS))

  if (!profile) {
    return <Navigate to={AuthRoutes.Login} replace />
  } else {
    const available = !!options.find((opt) => {
      return opt.key && location.pathname.startsWith(opt.key)
    })
    if (!available) {
      const defaultRouteAvailable = !!options.find((o) => o.key === defaultRoute)
      let redirectTo
      if (defaultRouteAvailable) {
        redirectTo = defaultRoute
      } else if (options.length > 0) {
        // get first available route
        redirectTo = options.find((o) => o.key)?.key
      } else {
        redirectTo = AuthRoutes.NotPermitted
      }
      return <Navigate to={redirectTo} replace />
    }
  }
  return <InternalPageWrapper />
}

export default PrivatePageWrapper
