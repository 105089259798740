import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Checkbox, FormGroup, Input } from '@agro-club/agroclub-shared'
import { InputWithCheckbox } from '../CarsForm/styled'
import { SimpleOptionsSelect } from '../CarsForm/SimpleOptionsSelect'
import { CarStatuses, UnloadingType, VehicleType } from '../../types'
import { NumberInput } from 'views/ui/NumberInput/NumberInput'
import { BoolRadio } from 'views/components/BoolRadio/BoolRadio'
import { ShortFormControl } from 'views/styled/common'
import { TrailerSelect } from '../TrailerSelect/TrailerSelect'
import { FormikFieldsType } from 'modules/domain/types'

type Props = {
  formik: FormikFieldsType
  isEditForm?: boolean
  showTrailer?: boolean
}

export const CarModalContent: FC<Props> = ({ formik, isEditForm, showTrailer = true }) => {
  const { t } = useTranslation('logisticsKR')
  const isRequiredField = formik.values.status === CarStatuses.active

  return (
    <>
      <FormGroup
        error={formik.errors.brand_model}
        label={t('carsEdit.form.labels.brand_model')}
        required={isRequiredField}
      >
        <Input
          placeholder={t('carsEdit.form.placeholders.brand_model')}
          {...formik.getFieldProps('brand_model')}
          invalid={!!formik.errors.brand_model}
        />
      </FormGroup>
      <InputWithCheckbox>
        <ShortFormControl>
          <FormGroup error={formik.errors.licence_number} label={t('carsEdit.form.labels.licence_number')} required>
            <Input
              placeholder={t('carsEdit.form.placeholders.car_licence_number')}
              {...formik.getFieldProps('licence_number')}
              invalid={!!formik.errors.licence_number}
              disabled={isEditForm}
            />
          </FormGroup>
        </ShortFormControl>
        <Checkbox
          isChecked={formik.values.has_trailer}
          onChange={(_value, isChecked) => formik.setFieldValue('has_trailer', isChecked)}
          label={t('carsEdit.form.labels.has_trailer')}
        />
      </InputWithCheckbox>
      {showTrailer && <TrailerSelect carrierId={formik.values.carrier} formik={formik} />}
      <SimpleOptionsSelect
        formik={formik}
        fieldName="car_type"
        EnumType={VehicleType}
        label={t('carsEdit.form.labels.car_type')}
        optionsTranslations="logisticsKR:vehicle_type"
        allowClear={false}
        required={isRequiredField}
      />
      <SimpleOptionsSelect
        formik={formik}
        fieldName="unloading_type"
        EnumType={UnloadingType}
        label={t('carsEdit.form.labels.unloading_type')}
        optionsTranslations="logisticsKR:directions"
        allowClear={false}
        required={isRequiredField}
      />
      <ShortFormControl>
        <FormGroup
          error={formik.errors.side_height}
          label={t('carsEdit.form.labels.side_height')}
          required={isRequiredField}
        >
          <NumberInput
            placeholder={t('carsEdit.form.placeholders.side_height')}
            {...formik.getFieldProps('side_height')}
            onChange={(value) => formik.setFieldValue('side_height', value)}
            invalid={!!formik.errors.side_height}
          />
        </FormGroup>
      </ShortFormControl>
      <BoolRadio formik={formik} label={t('carsEdit.form.labels.is_dump_truck')} fieldName="is_dump_truck" />
    </>
  )
}
