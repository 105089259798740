import { AntTag, ColumnsType, ExternalLink } from '@agro-club/agroclub-shared'
import useFormatDate from 'hooks/useFormatDate'
import { DASH } from 'modules/constants'
import { PaymentOrder, StatusOrder } from 'modules/domain/logisticsKR/types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router'
import { TruncatedValue } from 'views/components/TruncatedValue/TruncatedValue'
import DealRoutes from 'views/pages/Deal/routes'
import { TableTextLink } from 'views/ui/TableTextLink/TableTextLink'
import CarriersKRRoutes from '../CarriersKR/routes'
import DealsKRRoutes from '../LogisticRequests/routes'

const useColumns = (): ColumnsType<PaymentOrder> => {
  const { t } = useTranslation('paymentOrders')
  const formatDate = useFormatDate()

  return useMemo(
    () => [
      {
        title: t('date'),
        dataIndex: 'status_changed_date',
        width: 120,
        render: (status_changed_date) => formatDate(status_changed_date),
      },
      {
        title: t('status'),
        dataIndex: 'status',
        width: 112,
        render: (status) => {
          const isPaid = status === StatusOrder.paid
          const text = isPaid ? t('paid') : t('unpaid')
          const color = isPaid ? 'green' : 'grey'
          return <AntTag color={color}>{text}</AntTag>
        },
      },
      {
        title: t('carrier'),
        dataIndex: 'carrier',
        width: 514,
        render: (carrier) => (
          <TableTextLink id="carrierLink" to={generatePath(CarriersKRRoutes.Details, { id: carrier?.id })}>
            {carrier?.name}
          </TableTextLink>
        ),
      },
      {
        title: t('inn'),
        dataIndex: 'carrier',
        width: 120,
        render: (carrier) => carrier.inn,
      },
      {
        title: t('idDeal'),
        dataIndex: 'deal_id',
        width: 120,
        render: (deal_id) => (
          <TableTextLink target="_blank" id="dealLink" to={generatePath(DealRoutes.Details, { id: deal_id })}>
            {deal_id}
          </TableTextLink>
        ),
      },

      {
        title: t('trips'),
        dataIndex: 'request_id',
        width: 120,
        render: (request_id) => (
          <TableTextLink target="_blank" id="tripLink" to={generatePath(DealsKRRoutes.Details, { id: request_id })}>
            {request_id}
          </TableTextLink>
        ),
      },
      {
        title: t('paymentType'),
        dataIndex: 'payment_type',
        width: 120,
        render: (payment_type) => t(`${payment_type}`),
      },
      {
        title: t('file'),
        dataIndex: 'files',
        width: 664,
        render: (files) =>
          !!files.length ? (
            <ExternalLink href={files[0].uploaded_file} target="_blank">
              <TruncatedValue hint={files[0].original_filename}>{files[0].original_filename}</TruncatedValue>
            </ExternalLink>
          ) : (
            DASH
          ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t],
  )
}

export default useColumns
