import styled from 'styled-components'
import { AExternalLink } from 'views/components/Analytics'
import { Icons, defaultTheme, Typography } from '@agro-club/agroclub-shared'
import { RespFile } from 'modules/domain/types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

const FileWrapper = styled.div`
  width: 100%;
  height: 44px;
  padding: 11px 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color ease-in 0.2s;

  &:hover {
    background: ${defaultTheme.color.secondary25};
  }
`

const FileListWrapper = styled.div<{ shownFiles?: number }>`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ shownFiles }) => (shownFiles ? '4px' : 'auto')};
`

const FileLink = styled(AExternalLink)`
  color: ${defaultTheme.color.primary600};
  ${Typography.bodyLarge}
  margin: 0 auto 0 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:hover {
    text-decoration: underline;
  }
`

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 24px;
`

const ShowMoreButton = styled.div`
  width: fit-content;
  border-bottom: 1px dashed;
  cursor: pointer;
`

type FileItemProps = {
  file: RespFile
}

const FileItem = ({ file }: FileItemProps) => {
  return (
    <FileWrapper>
      <IconWrapper>
        <Icons.IconClip />
      </IconWrapper>
      <FileLink eventName="file" href={file.uploaded_file} target="_blank">
        {file.original_filename}
      </FileLink>
    </FileWrapper>
  )
}

type FileListProps = {
  files: RespFile[]

  /** number of files displayed */
  shownFiles?: number
}

export const FileList = ({ files, shownFiles }: FileListProps) => {
  const { t } = useTranslation('common')
  const [showMore, setShowMore] = useState(false)
  const shownFileList = files.slice(0, shownFiles)
  const filesResult = showMore ? files : shownFileList
  const countOtherFiles = files.length - (shownFiles ?? 0)
  const showMoreText = showMore ? t('showLess') : t('showMoreCount', { count: countOtherFiles })
  const showMoreIsVisible = files.length > shownFileList.length

  return (
    <div>
      <FileListWrapper shownFiles={shownFiles}>
        {filesResult.map((file) => (
          <FileItem key={file.id} file={file} />
        ))}
      </FileListWrapper>

      {showMoreIsVisible && <ShowMoreButton onClick={() => setShowMore(!showMore)}>{showMoreText}</ShowMoreButton>}
    </div>
  )
}
