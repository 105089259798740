import { AntTag } from '@agro-club/agroclub-shared'
import { useTranslation } from 'react-i18next'
import useFormatDate from 'hooks/useFormatDate'
import { useMemo } from 'react'

type Props = {
  start_date: string
  close_date: string
}

export const TermsTag = ({ start_date, close_date }: Props) => {
  const { t } = useTranslation('logisticsKR')
  const formatDate = useFormatDate()

  const getTerms = useMemo(() => {
    if (!start_date && !close_date) return t('details.no_terms')

    const startDate = formatDate(start_date) || t('details.no_date')
    const closeDate = formatDate(close_date) || t('details.no_date')

    return `${t('details.terms')}: ${startDate} — ${closeDate}`
  }, [close_date, formatDate, start_date, t])

  return <AntTag color="white">{getTerms}</AntTag>
}
