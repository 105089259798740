export enum AnalyticsPages {
  USERS = 'users',
  USER_DETAIL = 'userDetail',
  USER_BID_PRICE = 'userBidPrice',
}

export const AnalyticsPlaces = {
  LIST: 'UserList',
  DETAIL: 'UserDetail',
  BID_PRICE: 'UserBidPrice',
  ADD_MODAL: 'AddModal',
  EDIT_MODAL: 'EditModal',
  BLOCK_MODAL: 'BlockModal',
  VERIFY_COORDINATOR_MODAL: 'VerifyCoordinatorModal',
  VERIFY_SECURITY_MODAL: 'VerifySecurityModal',
  SIGNIFICANT_MODAL: 'SignificantModal',
  KYC_MODAL: 'KycModal',
  REMOVE_MATCH_MODAL: 'RemoveMatchModal',
  EDIT_ADDRESS_MODAL: 'EditAddressModal',
}
