import { useParams } from 'react-router-dom'
import * as Layout from 'views/layouts/NewLayout'
import * as Header from 'views/ui/Header/Header'

import { useTranslation } from 'react-i18next'
import { DocumentRequestsRoutes } from '../routes'
import { TwoColumns } from 'views/styled/detailedPage'
import { endpoints } from 'modules/endpoints'
import { useSingleEntity } from 'modules/domain/common/hooks'
import { DocumentRequestFull, DocumentRequestStatuses } from 'modules/domain/documentRequest/types'
import { Progress, useHelmet } from '@agro-club/agroclub-shared'
import ItemLoadingLayout from 'views/layouts/ItemLoadingLayout/ItemLoadingLayout'
import { apiClient } from 'modules/utils/httpClient'
import { useMarkAsRead } from 'modules/socket/hooks'
import { useDocumentRequestStatuses } from '../useDocumentRequestStatuses'
import APageWrapper from 'views/components/PageWrapper/APageWrapper'
import { AnalyticsPages, AnalyticsPlaces } from '../analyticsPlaces'
import { AButton } from 'views/components/Analytics'
import { StatusBlockWrapper } from 'views/styled/common'
import { StatusesRightArrow } from 'views/components/StatusesRightArrow/StatusesRightArrow'
import { useAProgress } from 'hooks/useAProgress'
import { FC } from 'react'
import { CompletedStatus } from 'views/components/CompletedStatus/CompletedStatus'
import { DocumentRequestLeftBlock } from './DocumentRequestLeftBlock/DocumentRequestLeftBlock'
import { DocumentRequestRightBlock } from './DocumentRequestRightBlock/DocumentRequestRightBlock'

export const DocumentRequestsDetail: FC = () => {
  const { id } = useParams()
  const { t } = useTranslation('documentRequests')
  const title = `ID ${id}`
  const [progress, documentRequest, refetch] = useSingleEntity<DocumentRequestFull>(endpoints.dealDocumentRequest(id))
  const [updateStatusProgress, updateStatus] = useAProgress(
    (status: string) =>
      apiClient.post(endpoints.dealDocumentChangeStatuses(id), {
        new_status: status,
      }),
    {
      eventName: 'dealDocumentChangeStatuses',
      onSuccess: () => refetch(true),
    },
  )
  useMarkAsRead(id, 'documentsrequest')
  useHelmet({ title })
  const [, , statusDict] = useDocumentRequestStatuses()

  if (progress === Progress.WORK || !documentRequest) {
    return <ItemLoadingLayout id={id} />
  }

  const nextStatus = documentRequest.available_statuses[0]

  return (
    <APageWrapper page={AnalyticsPages.DOC_DETAIL} place={AnalyticsPlaces.DETAIL} id={id}>
      <Layout.WrapperContentFlex>
        <Layout.Header>
          <Layout.TopHeader>
            <Header.Breadcrumbs
              routes={[
                { path: DocumentRequestsRoutes.List, breadcrumbName: t('metaTitle') },
                { breadcrumbName: title },
              ]}
            />
            {statusDict && (
              <StatusBlockWrapper>
                {nextStatus ? (
                  <>
                    <span>{statusDict[documentRequest.status]}</span>
                    <StatusesRightArrow />
                    <AButton
                      id={nextStatus}
                      intent="secondary"
                      onClick={() => updateStatus(nextStatus)}
                      progress={updateStatusProgress}
                    >
                      {nextStatus !== DocumentRequestStatuses.completed
                        ? statusDict[nextStatus]
                        : t('detail.main.perform')}
                    </AButton>
                  </>
                ) : (
                  <CompletedStatus />
                )}
              </StatusBlockWrapper>
            )}
          </Layout.TopHeader>
          <Layout.PageTitleWrapper>
            <Header.Title title={title} />
          </Layout.PageTitleWrapper>
        </Layout.Header>

        <TwoColumns>
          <DocumentRequestLeftBlock documentRequest={documentRequest} documentRefetch={refetch} />
          <DocumentRequestRightBlock />
        </TwoColumns>
      </Layout.WrapperContentFlex>
    </APageWrapper>
  )
}
