import { FC, useState } from 'react'
import { Icons } from '@agro-club/agroclub-shared'
import { useTranslation } from 'react-i18next'
import { EditShipmentModal } from './EditShipmentModal'
import { DealShipment } from 'modules/domain/deal/types'
import { ArchivedConfirmModal } from './ArchivedConfirmModal'
import { APopoverButton } from 'views/components/Analytics/APopoverButton'
import { PopoverContainer } from 'views/styled/common'

interface Props {
  dealId: string
  shipment: DealShipment
  shipmentRefetch: () => Promise<void>
  setPopoverVisible: (arg: boolean) => void
}

export const PopoverContent: FC<Props> = ({ dealId, shipmentRefetch, shipment, setPopoverVisible }) => {
  const { t } = useTranslation('popover')

  const [editModal, setEditModal] = useState(false)
  const [archivedConfirmModal, setArchivedConfirmModal] = useState(false)

  return (
    <PopoverContainer onClick={() => setPopoverVisible(false)}>
      <APopoverButton
        id="editShipment"
        text={t('buttonTitle.edit')}
        onClick={() => setEditModal(true)}
        Icon={<Icons.IconEdit />}
      />
      <APopoverButton
        id="archivedShipment"
        text={t('buttonTitle.remove')}
        onClick={() => setArchivedConfirmModal(true)}
        Icon={<Icons.IconRemove />}
      />
      {editModal && (
        <EditShipmentModal
          dealId={dealId}
          onClose={() => setEditModal(false)}
          shipment={shipment}
          onSuccess={shipmentRefetch}
        />
      )}
      {archivedConfirmModal && (
        <ArchivedConfirmModal
          dealId={dealId}
          onClose={() => setArchivedConfirmModal(false)}
          shipment={shipment}
          onSuccess={shipmentRefetch}
        />
      )}
    </PopoverContainer>
  )
}
