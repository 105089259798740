import { Address, Product, RespFile } from 'modules/domain/types'
import { Bid } from 'modules/domain/bid/types'
import { Profile, User } from 'modules/domain/user/types'
import { DateISO } from '@agro-club/agroclub-shared'

export enum SampleStatus {
  in_progress = 'in_progress',
  archived = 'archived',
}

export type Owner = Omit<User, 'profile'> & {
  profile: Profile
}

export type Sample = {
  id: string
  address: Address
  bid: Bid
  created: string
  created_by: {
    full_name: string
    id: string
    is_active: boolean
    phone: string
  }
  expire_at: DateISO | undefined
  modified: string
  owner: Owner
  product: Product
  status: SampleStatus
  title: string
  files: RespFile[]
}
