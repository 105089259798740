import { UserBidPrice } from 'views/pages/User/UserBidPrice/UserBidPrice'
import { UserDetail } from 'views/pages/User/UserDetail/UserDetail'
import UserList from 'views/pages/User/UserList'
import UserRoutes from 'views/pages/User/routes'
import { Route, Routes } from 'react-router-dom'

function UserPage() {
  return (
    <Routes>
      <Route index element={<UserList />} />
      <Route path={UserRoutes.relativeDetail} element={<UserDetail />} />
      <Route path={UserRoutes.relativeBidPrice} element={<UserBidPrice />} />
      {/*   This route is required for navigation from the overdue bid card located in the dashboard */}
      <Route path={UserRoutes.relativeBidPriceForNavigation} element={<UserBidPrice />} />
      <Route path={UserRoutes.relativeDetailWithBid} element={<UserDetail />} />
    </Routes>
  )
}

export default UserPage
