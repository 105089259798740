import { FC } from 'react'

import { BaseTableFilterProps } from '@agro-club/agroclub-shared'
import { useAnalyticsContext } from 'analytics/hooks'
import { AnalyticsFilterVariant } from 'analytics/types'
import InternalBaseTableFilter from './InternalBaseTableFilter'

interface Props extends BaseTableFilterProps {
  place?: string
}

export const ABaseTableFilter: FC<Props> = ({ value, id, place, onChange, ...otherProps }) => {
  const { track } = useAnalyticsContext()
  const eventName = `change|${id}`

  const handleChange = (newValue: any) => {
    onChange(newValue)

    track(eventName, {
      filter: id as AnalyticsFilterVariant,
      place,
      value: {
        from: value,
        to: newValue,
      },
    })
  }

  return <InternalBaseTableFilter onChange={handleChange} value={value} data-testid={id} {...otherProps} />
}
