import { DeleteModal } from './DocumentRequestModal/DeleteModal'
import { Icons, defaultTheme } from '@agro-club/agroclub-shared'
import { EditModal } from './DocumentRequestModal/EditModal'
import { refetchFunc } from 'modules/domain/common/hooks'
import { AButton } from 'views/components/Analytics'
import { Declaration } from 'modules/domain/types'
import { Deal } from 'modules/domain/deal/types'
import styled from 'styled-components'
import { FC, useState } from 'react'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0 4px;
  padding-left: 16px;
  @media (max-width: ${defaultTheme.queries.mobile}) {
    flex-direction: column-reverse;
    justify-content: space-between;
  }
`

type Props = {
  refetch: refetchFunc
  deal: Deal
  declaration: Declaration | undefined
}

export const EditDocumentRequest: FC<Props> = ({ deal, refetch, declaration }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)

  return (
    <Wrapper>
      {!deal.without_documents_request && (
        <AButton intent="white" Icon={Icons.IconEdit} id="EditDocumentRequest" onClick={() => setShowEditModal(true)} />
      )}
      <AButton
        onClick={() => setShowDeleteModal(true)}
        Icon={Icons.IconCancelled}
        id="DeleteDocumentRequest"
        intent="white"
      />
      {showDeleteModal && <DeleteModal onClose={() => setShowDeleteModal(false)} deal={deal} refetch={refetch} />}
      {showEditModal && deal.documents_request && (
        <EditModal
          deal={deal}
          documents_request={deal.documents_request}
          onClose={() => setShowEditModal(false)}
          refetch={refetch}
          declaration={declaration}
        />
      )}
    </Wrapper>
  )
}
