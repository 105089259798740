import { ResponsiveFilters } from 'views/components/ResponsiveFilters/ResponsiveFilters'
import { ABaseTableFilter } from 'views/components/TableFilters/ABaseTableFilter'
import { CropsFilter, TeamsFilter } from 'views/components/TableFilters'
import { MonthFilter } from 'views/components/MonthFilter/MonthFilter'
import { useDisplayClearBtn } from 'hooks/useDisplayClearBtn'
import { AnalyticsFilterVariant } from 'analytics/types'
import { defaultListRequestParams } from '../DealList'
import { TableFiltersProps } from 'modules/types'
import { useTranslation } from 'react-i18next'
import { endpoints } from 'modules/endpoints'
import { FC, useMemo } from 'react'

export const DealFilters: FC<TableFiltersProps> = ({
  setListRequestParams,
  updateFilterState,
  listRequestParams,
  defaultFilterValues,
  isFiltersLoaded,
}) => {
  const { t } = useTranslation('deal')
  const displayClearBtn = useDisplayClearBtn({
    listRequestParams,
    defaultFilters: defaultFilterValues,
  })

  const filterForAssignee = useMemo(
    () => (listRequestParams.filter.team ? { team: listRequestParams.filter.team } : null),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [listRequestParams.filter.team, isFiltersLoaded],
  )

  return (
    <ResponsiveFilters
      listRequestParams={listRequestParams}
      displayClearBtn={displayClearBtn}
      onReset={() =>
        setListRequestParams({
          ...defaultListRequestParams,
          filter: defaultFilterValues,
        })
      }
      updateFilterState={updateFilterState}
    >
      <MonthFilter value={listRequestParams.filter.month} updateFilterState={updateFilterState} allowClear={false} />
      <CropsFilter listRequestParams={listRequestParams} updateFilterState={updateFilterState} />
      <TeamsFilter
        listRequestParams={listRequestParams}
        updateFilterState={(data) => updateFilterState({ team: data.team, executor: undefined })}
      />

      <ABaseTableFilter
        onChange={(executor) => updateFilterState({ executor })}
        value={listRequestParams.filter.executor}
        getLabel={(label) => label.full_name}
        endpoint={endpoints.taskAssignees()}
        id={AnalyticsFilterVariant.ASSIGNEE}
        placeholder={t('common:assignee')}
        filter={filterForAssignee}
        searchField="search"
        simpleApiFormat
      />

      <ABaseTableFilter
        onChange={(deal_type) => updateFilterState({ deal_type })}
        value={listRequestParams.filter.deal_type}
        id={AnalyticsFilterVariant.DEAL_TYPE}
        placeholder={t('deal:dealType')}
        endpoint={endpoints.dealTypes()}
        getLabel={(dto) => dto.label}
        filter={filterForAssignee}
        showSearch={false}
        simpleApiFormat
      />
    </ResponsiveFilters>
  )
}
