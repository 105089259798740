import { FC, useState } from 'react'
import { AntCollapse, Panel } from '@agro-club/agroclub-shared'
import { CommentType } from 'modules/domain/comment/types'
import { endpoints } from 'modules/endpoints'

import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Specification } from 'modules/domain/specification/types'
import AntCollapseHeader from 'views/components/AntCollapseHeader/AntCollapseHeader'
import { ContractDocuments } from './ContractDocuments'
import { SpecificationDocuments } from './SpecificationDocuments'
import AComments from 'views/components/Comments/AComments'
import { SummaryThereIsNo } from 'views/components/AntCollapseHeader/SummaryThereIsNo'
import { SummaryComments } from 'views/components/AntCollapseHeader/SummaryComments'

interface Props {
  specification: Specification | undefined
}

export const SpecificationRightBlock: FC<Props> = ({ specification }) => {
  const { t } = useTranslation('specification')
  const { id } = useParams()
  const companyId = specification?.company?.id || ''
  const contractId = specification?.contract?.id || ''

  const [commentsSummary, setCommentsSummary] = useState<string>('')
  const [contractDocsSummary, setContractDocsSummary] = useState<number>()
  const [specDocsSummary, setSpecDocsSummary] = useState<number>()

  const options = [
    { value: 'comments', title: t('common:comments') },
    { value: 'documents', title: t('specification:specification_files') },
    { value: 'contract_documents', title: t('specification:contract_files') },
  ]
  const [activeKey, setActiveKey] = useState<string | string[]>(options[0].value)
  const components = {
    comments: (
      <AComments
        id={id}
        analyticsPayload={{ id: specification?.id }}
        type={CommentType.specification}
        url={endpoints.specificationComments(id)}
        maxHeight={400}
        popSummary={setCommentsSummary}
      />
    ),
    documents: <SpecificationDocuments setSummary={setSpecDocsSummary} />,
    contract_documents: (
      <ContractDocuments companyId={companyId} setSummary={setContractDocsSummary} contractId={contractId} />
    ),
  }

  const summary = {
    comments: <SummaryComments summary={commentsSummary} />,
    documents: <SummaryThereIsNo hasData={specDocsSummary} />,
    contract_documents: <SummaryThereIsNo hasData={contractDocsSummary} />,
  }

  return (
    <div>
      <AntCollapse accordion bordered={false} defaultActiveKey={options[0].value} onChange={(key) => setActiveKey(key)}>
        {options.map((option) => (
          <Panel
            forceRender
            header={
              <AntCollapseHeader title={option.title} summary={activeKey !== option.value && summary[option.value]} />
            }
            key={option.value}
          >
            {components[option.value]}
          </Panel>
        ))}
      </AntCollapse>
    </div>
  )
}
