import { FC } from 'react'
import { ListRequestParams } from 'modules/domain/types'
import { endpoints } from 'modules/endpoints'
import { useOnApprovalColumns } from './useOnApprovalColumns'
import { LogisticRequest } from 'modules/domain/logisticsKR/types'
import ATableFullPage from 'views/components/Table/ATableFullPage'
import { useTranslation } from 'react-i18next'

interface Props {
  request: LogisticRequest
  listRequestParams: ListRequestParams
  setListRequestParams: (params: ListRequestParams) => void
}

export const OnApprovalTripsTable: FC<Props> = ({ request, listRequestParams, setListRequestParams }) => {
  const { t } = useTranslation('tableNoDataText')

  const columns = useOnApprovalColumns(listRequestParams, setListRequestParams)

  return (
    <ATableFullPage
      columns={columns}
      dataEndpoint={endpoints.LogisticsKR.tripsForDeal(request.id)}
      listRequestParams={listRequestParams}
      listParamsUpdated={setListRequestParams}
      rowHoverGray
      isFiltersLoaded={true}
      maxHeight={736}
      bordered
      noDataText={t('draft_trips')}
    />
  )
}
