import { Spinner as SpinnerComponent } from '@agro-club/agroclub-shared'
import { FC } from 'react'
import styled from 'styled-components'

const SpinnerWrapper = styled.div<{ $on: boolean }>`
  position: absolute;
  transition: opacity 0.3s ease-in-out;
  top: 0;
  left: -10px;
  bottom: 0;
  display: flex;
  align-items: center;
  opacity: ${({ $on = false }) => ($on ? '1' : '0')};
`

const Spinner = styled(SpinnerComponent)`
  opacity: 0.8;
`

const CellProgress: FC<{ enabled: boolean }> = ({ enabled }) => {
  return (
    <SpinnerWrapper $on={enabled}>
      <Spinner size="small" />
    </SpinnerWrapper>
  )
}

export default CellProgress
