import { defaultTheme } from '@agro-club/agroclub-shared'
import React, { Children, ReactNode, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

type SpoilerState = 'expanded' | 'collapsed'

type Props = {
  size?: number
  children: ReactNode
  expandLabel?: string
  collapseLabel?: string
  appendCounterOn?: SpoilerState | 'always' | 'never'
  disableCollapseButton?: boolean
  linkComponent?: React.FunctionComponent
}

export const SpoilerLink = styled.a`
  &,
  &:visited {
    color: ${defaultTheme.color.textForLink};
  }
  align-self: flex-start;
  display: inline-block;
  border-bottom: 1px dashed;
  border-color: ${defaultTheme.color.textForLink};
  text-decoration: none;
  cursor: pointer;
`

export const ChildrenSpoiler: React.VFC<Props> = ({
  children,
  size = 3,
  expandLabel,
  collapseLabel,
  appendCounterOn = 'collapsed',
  disableCollapseButton,
  linkComponent: LinkComponent = SpoilerLink,
}) => {
  const [state, setState] = useState<SpoilerState>('collapsed')
  const { t } = useTranslation('common')
  const labels: Record<SpoilerState, string> = {
    collapsed: expandLabel ?? t('showMore'),
    expanded: collapseLabel ?? t('hide'),
  }
  const childrenArray = Children.toArray(children)

  if (!childrenArray.length || !size || size >= childrenArray.length) {
    return <>{children}</>
  }

  const isCollapsed = state === 'collapsed'

  const items = isCollapsed ? childrenArray.slice(0, size) : children
  const counterVisible = appendCounterOn === 'always' || appendCounterOn === state
  const toggleButtonVisible = isCollapsed ? true : !disableCollapseButton

  return (
    <>
      {items}
      {toggleButtonVisible && (
        <LinkComponent
          onClick={() => {
            setState(isCollapsed ? 'expanded' : 'collapsed')
          }}
        >
          {labels[state]} {counterVisible && childrenArray.length - size}
        </LinkComponent>
      )}
    </>
  )
}
