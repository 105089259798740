import { FC } from 'react'
import { Input } from '@agro-club/agroclub-shared'
import { InputProps } from 'views/ui/types'

type AdditionalProps = {
  isInteger?: boolean
  max?: number
}

export type NumberInputProps = {
  onChange: (value?: string | number) => void
  onClearFilter?: () => void
  disabled?: boolean
} & Omit<InputProps, 'onChange'> &
  AdditionalProps

const integerRegExp = /^[0-9]+$/

const handleIntegerValidationOnChange = (v, onChange, max?: number) => {
  if (v === '') {
    onChange(null)
  } else if (integerRegExp.test(v)) {
    const value = parseInt(v, 10)
    onChange(max !== undefined ? Math.min(value, max) : value)
  }
}

const floatRegExp = /^([0-9]+([.][0-9]*)?|[.][0-9]+)$/

const handleFloatValidationOnChange = (v, onChange, max?: number) => {
  let value = v.replace(/,+/g, '.')
  if (value === '') {
    onChange(null)
  } else if (value.endsWith('.') || (value.includes('.') && value.endsWith('0'))) {
    onChange(value)
  } else if (floatRegExp.test(value)) {
    value = parseFloat(value)
    onChange(max !== undefined ? Math.min(value, max) : value)
  }
}

export const NumberInput: FC<NumberInputProps> = ({ onChange, max, isInteger, ...rest }) => {
  const handleChange = (_e, v) => {
    const validationFn = isInteger ? handleIntegerValidationOnChange : handleFloatValidationOnChange

    validationFn(v, onChange, max)
  }

  return <Input onChange={handleChange} {...rest} />
}
