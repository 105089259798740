import { FC } from 'react'
import { LogisticRequest } from 'modules/domain/logisticsKR/types'
import { AddressWrapper, Arrow, Container, TripAddress, TripParameters } from './styled'
import { WarehouseParams } from './WarehouseParams'

const TripsHeader: FC<{ request: LogisticRequest }> = ({ request }) => {
  const { loading_warehouse, unloading_warehouse } = request

  return (
    <Container>
      <AddressWrapper>
        <TripAddress>{loading_warehouse.address}</TripAddress>
        <TripParameters>
          <WarehouseParams warehouse={loading_warehouse} />
        </TripParameters>
      </AddressWrapper>
      <Arrow />
      <AddressWrapper>
        <TripAddress>{unloading_warehouse.address}</TripAddress>
        <TripParameters>
          <WarehouseParams warehouse={unloading_warehouse} />
        </TripParameters>
      </AddressWrapper>
    </Container>
  )
}

export default TripsHeader
