import { FilesLayout } from 'views/components/FilesByTypes/styled'
import FilesGroup from 'views/components/FilesByTypes/FilesGroup'
import { useSingleEntity } from 'modules/domain/common/hooks'
import FileItem from 'views/components/FilesByTypes/FileItem'
import { uploadFile } from 'modules/domain/common/managers'
import { apiClient } from 'modules/utils/httpClient'
import { useAProgress } from 'hooks/useAProgress'
import { RespFile } from 'modules/domain/types'
import { FC, useEffect } from 'react'

type ContractItemProps = {
  deleteFileEndpoint: (id: string, fileId: string) => string
  uploadFileEndpoint: (id: string) => string
  setHasFiles: (hasFiles: boolean) => void
  fileListEndpoint: string
  title: string
  id: string
}

export const ContractFileManager: FC<ContractItemProps> = ({
  id,
  title,
  setHasFiles,
  fileListEndpoint,
  deleteFileEndpoint,
  uploadFileEndpoint,
}) => {
  const [, files, refetchFiles] = useSingleEntity<RespFile[]>(fileListEndpoint)

  useEffect(() => {
    if (!files?.length) {
      setHasFiles(false)
      return
    }
    setHasFiles(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files])

  const [removeProgress, removeFile] = useAProgress((fileId) => apiClient.delete(deleteFileEndpoint(id, fileId)), {
    eventName: 'removeFile',
    analyticsPayload: {
      id,
    },
    onSuccess: () => {
      refetchFiles()
    },
  })

  const [addProgress, addFile] = useAProgress((file) => uploadFile(uploadFileEndpoint(id), file), {
    eventName: 'addFile',
    analyticsPayload: {
      id,
    },
    onSuccess: () => {
      refetchFiles()
    },
  })

  return (
    <FilesLayout>
      <FilesGroup header={title} onUpload={addFile} progress={addProgress}>
        {!!files?.length &&
          files.map((file) => <FileItem key={file.id} file={file} progress={removeProgress} onDelete={removeFile} />)}
      </FilesGroup>
    </FilesLayout>
  )
}
