import { Progress } from '@agro-club/agroclub-shared'
import { useAProgress } from 'hooks/useAProgress'
import { useSingleEntity } from 'modules/domain/common/hooks'
import * as managers from 'modules/domain/common/managers'
import { DocumentFile, DocumentType, DocumentWithTypeFile } from 'modules/domain/types'
import * as userManagers from 'modules/domain/user/managers'
import { endpoints } from 'modules/endpoints'
import { FC, useEffect } from 'react'
import { AFilesByTypes } from 'views/components/FilesByTypes/AFilesByTypes'

type Props = {
  setSummary: (arg: number) => void
  ownerId: string
}

export const DealDocuments: FC<Props> = ({ setSummary, ownerId }) => {
  const [, documentTypes = []] = useSingleEntity<DocumentType[]>(endpoints.userDocumentTypes(ownerId))
  const [progress, documents = [], documentsRefetch] = useSingleEntity<DocumentFile[]>(
    endpoints.userDocumentFiles(ownerId),
  )

  const [uploadProgress, upload] = useAProgress<DocumentWithTypeFile>(
    (documentType: DocumentType['slug'], file: File) =>
      managers.uploadDocuments(endpoints.userDocuments(ownerId), documentType, file),
    {
      eventName: 'uploadDocument',
      onSuccess: () => documentsRefetch(),
    },
  )

  const [removeProgress, removeDoc] = useAProgress(
    (documentId: string) => userManagers.deleteDocumentFile(ownerId, documentId),
    {
      eventName: 'deleteDocument',
      onSuccess: () => documentsRefetch(),
    },
  )

  useEffect(() => {
    if (progress === Progress.SUCCESS) {
      setSummary(documents.length)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documents.length])

  return (
    <AFilesByTypes
      uploadProgress={uploadProgress}
      deleteProgress={removeProgress}
      types={documentTypes}
      files={documents}
      onUpload={upload}
      onDelete={removeDoc}
    />
  )
}
