import { FC, Fragment, useEffect, useMemo, useState } from 'react'
import { MultipleCollapseWrapper, WrapperCollapse } from '../../styled'
import { AntCollapse, Panel } from '@agro-club/agroclub-shared'
import { Matches } from './Matches/Matches'
import { Trips } from './Trips/Trips'
import { DraftTrips } from 'views/pages/LogisticsKR/LogisticRequests/RequestsBoard/OpenRequestsBoardColumns/DraftTrips/DraftTrips'
import { DraftTripsHeader } from 'views/pages/LogisticsKR/LogisticRequests/RequestsBoard/OpenRequestsBoardColumns/DraftTrips/DraftTripsHeader'
import { MatchesHeader } from './Matches/MatchesHeader'
import { TripsHeader } from './Trips/TripsHeader'
import { LogisticStatuses, RequestCondition } from '../../../types'
import { RequestDrawer } from '../RequestDrawer'
import { TripOffers } from '../../../components/TripOffers/TripOffers'
import { ListRequestParams } from 'modules/domain/types'
import { useTableData } from 'modules/domain/common/hooks'
import { LogisticRequest } from 'modules/domain/logisticsKR/types'
import { endpoints } from 'modules/endpoints'
import { RequestColumnHeader } from 'views/pages/LogisticsKR/LogisticRequests/components/RequestColumnHeader/RequestColumnHeader'
import { useTranslation } from 'react-i18next'
import { RequestColumn } from 'views/pages/LogisticsKR/LogisticRequests/components/RequestColumn/RequestColumn'
import { generatePath, useNavigate, useParams } from 'react-router'
import DealsKRRoutes from '../../routes'

const useManagingDisplayedData = ({ status, tripOffers, relevantCars, draftTrips, trips, selectedRequest }) => {
  const [displaybleTripOffers, setDisplaybleTripOffers] = useState([])
  const [displaybleMatches, setDisplaybleMatches] = useState([])
  const [displaybleDraftTrips, setDisplaybleDraftTrips] = useState([])
  const [displaybleTrips, setDisplaybleTrips] = useState([])
  const isInProgress = status === LogisticStatuses.in_progress
  /*
   * (isInProgress) - This check is needed, so that when you switch the active request from "In progress" to other statuses,
   * not to load columns on the request "In progress" (works with a slow Internet or with very fast switching)
   */

  useEffect(() => {
    if (isInProgress && selectedRequest) {
      setDisplaybleTripOffers(tripOffers)
      setDisplaybleMatches(relevantCars)
      setDisplaybleDraftTrips(draftTrips)
      setDisplaybleTrips(trips)
    }
  }, [isInProgress, tripOffers, selectedRequest, relevantCars, draftTrips, trips])

  useEffect(() => {
    if (!isInProgress || !selectedRequest) {
      setDisplaybleTripOffers([])
      setDisplaybleMatches([])
      setDisplaybleDraftTrips([])
      setDisplaybleTrips([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, selectedRequest])

  return {
    displaybleTripOffers,
    displaybleMatches,
    displaybleDraftTrips,
    displaybleTrips,
  }
}

type Props = {
  listRequestParams: ListRequestParams
  setDrawerIsOpen: (boolean) => void
  drawerIsOpen: boolean
  isFiltersLoaded: boolean
  activeRequest: LogisticRequest | undefined
  setActiveRequest: (arg: LogisticRequest | undefined) => void
}

export const OpenRequestsBoardColumns: FC<Props> = ({
  listRequestParams,
  setDrawerIsOpen,
  drawerIsOpen,
  isFiltersLoaded,
  setActiveRequest,
  activeRequest,
}) => {
  const { t } = useTranslation('logisticBids')
  const { activeTab, selectedRequest } = useParams()
  const navigate = useNavigate()
  const {
    progress: newRequestProgress,
    data: newRequestsList,
    refetch: refetchNewRequestList,
  } = useTableData(endpoints.LogisticsKR.requestsWithStatus(LogisticStatuses.new), listRequestParams, isFiltersLoaded)

  const {
    progress: inWorkRequestProgress,
    data: inWorkRequestsList,
    refetch: refetchInWorkRequestList,
  } = useTableData(
    endpoints.LogisticsKR.requestsWithStatus(LogisticStatuses.in_progress),
    listRequestParams,
    isFiltersLoaded,
  )

  const isInProgress = activeRequest?.logistics_status === LogisticStatuses.in_progress

  const {
    progress: relevantCarsProgress,
    data: relevantCars,
    refetch: relevantCarsRefetch,
  } = useTableData(
    endpoints.LogisticsKR.relevantCars(activeRequest?.id),
    listRequestParams,
    !!activeRequest?.id && isInProgress,
  )

  const {
    progress: tripOffersProgress,
    data: tripOffers,
    refetch: refetchTripOffers,
  } = useTableData(
    endpoints.LogisticsKR.activeBidTripOffers(activeRequest?.id),
    listRequestParams,
    !!activeRequest?.id && isInProgress,
  )

  const {
    progress: draftTripsProgress,
    data: draftTrips,
    refetch: refetchDraftTrips,
  } = useTableData(
    endpoints.LogisticsKR.draftTrips(activeRequest?.id),
    listRequestParams,
    !!activeRequest?.id && isInProgress,
  )
  const { progress: tripsProgress, data: trips } = useTableData(
    endpoints.LogisticsKR.approvedTrips(activeRequest?.id),
    listRequestParams,
    !!activeRequest?.id && isInProgress,
  )

  useEffect(() => {
    // wait for server response when changing filters
    if (!newRequestsList || !inWorkRequestsList) return

    const concatRequests = newRequestsList?.concat(inWorkRequestsList)
    const activeRequestInSearchResult = !!concatRequests?.find((el) => String(el?.id) === String(selectedRequest))

    // If there is no active request in the filtered data, then remove this request from the ulr
    if (!activeRequestInSearchResult && selectedRequest) {
      navigate(
        {
          pathname: generatePath(DealsKRRoutes.ListRequestsWithTab, { activeTab: activeTab }),
          search: location.search,
        },
        { replace: true },
      )
      setActiveRequest(undefined)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newRequestsList, inWorkRequestsList])

  const { displaybleTripOffers, displaybleMatches, displaybleDraftTrips, displaybleTrips } = useManagingDisplayedData({
    status: activeRequest?.logistics_status,
    tripOffers,
    relevantCars,
    draftTrips,
    trips,
    selectedRequest,
  })

  const refetchForDrawer =
    activeRequest?.logistics_status === LogisticStatuses.new ? refetchNewRequestList : refetchInWorkRequestList

  const drawerIsOpenMemo = useMemo(() => drawerIsOpen && activeTab === RequestCondition.open, [activeTab, drawerIsOpen])

  const options = [
    {
      value: 'new',
      header: (
        <RequestColumnHeader
          counter={newRequestsList?.length}
          progress={newRequestProgress}
          title={t('board_columns.title.new')}
        />
      ),
    },
    {
      value: 'inProgress',
      header: (
        <RequestColumnHeader
          counter={inWorkRequestsList?.length}
          progress={inWorkRequestProgress}
          title={t('board_columns.title.in_progress')}
        />
      ),
    },
    {
      value: 'tripOffers',
      multiColumn: true,
    },
    {
      value: 'matches',
      header: <MatchesHeader counter={displaybleMatches?.length} progress={relevantCarsProgress} />,
    },
    {
      value: 'tripsReconciliation',
      header: (
        <DraftTripsHeader
          counter={displaybleDraftTrips?.length}
          activeRequest={activeRequest}
          refetchDraftTrips={refetchDraftTrips}
          refetchInWorkRequestList={refetchInWorkRequestList}
          progress={draftTripsProgress}
        />
      ),
    },
    { value: 'trips', header: <TripsHeader counter={displaybleTrips?.length} progress={tripsProgress} /> },
  ]

  const components = {
    new: (
      <RequestColumn
        setDrawerIsOpen={setDrawerIsOpen}
        progress={newRequestProgress}
        setActiveRequest={setActiveRequest}
        requests={newRequestsList}
      />
    ),
    inProgress: (
      <RequestColumn
        progress={inWorkRequestProgress}
        requests={inWorkRequestsList}
        setActiveRequest={setActiveRequest}
      />
    ),
    tripOffers: (
      <TripOffers
        progress={tripOffersProgress}
        tripOffersRefetch={refetchTripOffers}
        activeRequest={activeRequest}
        refetchDraftTrips={refetchDraftTrips}
        tripOffers={displaybleTripOffers}
        inProgressRequestRefetch={refetchInWorkRequestList}
      />
    ),
    matches: (
      <Matches
        progress={relevantCarsProgress}
        relevantCars={displaybleMatches}
        refetchMatches={relevantCarsRefetch}
        refetchInWorkRequestList={refetchInWorkRequestList}
        activeRequest={activeRequest}
        refetchDraftTrips={refetchDraftTrips}
      />
    ),
    tripsReconciliation: (
      <DraftTrips
        progress={draftTripsProgress}
        refetchDraftTrips={refetchDraftTrips}
        draftTrips={displaybleDraftTrips}
        refetchInWorkRequestList={refetchInWorkRequestList}
      />
    ),
    trips: (
      <Trips
        trips={displaybleTrips}
        progress={tripsProgress}
        request={activeRequest}
        refetchTripsReconciliation={refetchDraftTrips}
      />
    ),
  }

  return (
    <>
      {options.map((option) => (
        <Fragment key={option.value}>
          {option.multiColumn ? (
            <MultipleCollapseWrapper key={option.value}>{components[option.value]}</MultipleCollapseWrapper>
          ) : (
            <WrapperCollapse key={option.value}>
              <AntCollapse bordered={false} key={option.value} noMarginTop defaultActiveKey={option.value}>
                <Panel collapsible="disabled" header={option.header} key={option.value} showArrow={false}>
                  {components[option.value]}
                </Panel>
              </AntCollapse>
            </WrapperCollapse>
          )}
        </Fragment>
      ))}
      <RequestDrawer
        activeRequest={activeRequest}
        drawerIsOpen={drawerIsOpenMemo}
        requestsRefetch={refetchForDrawer}
        setDrawerIsOpen={setDrawerIsOpen}
      />
    </>
  )
}
