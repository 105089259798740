import ATableFullPage from 'views/components/Table/ATableFullPage'
import { updateSortOrder } from 'modules/domain/common/tableUtils'
import { ListRequestParams } from 'modules/domain/types'
import { ColumnData } from 'modules/types'
import { FC, useMemo } from 'react'

interface Props {
  setListRequestParams: (params: ListRequestParams) => void
  columnsFunc: (sort?: string) => ColumnData[]
  listRequestParams: ListRequestParams
  isFiltersLoaded: boolean
  dataEndpoint: string
}

export const ContractsTable: FC<Props> = ({
  setListRequestParams,
  listRequestParams,
  isFiltersLoaded,
  dataEndpoint,
  columnsFunc,
}) => {
  const columns = columnsFunc()
  const sorted = useMemo(() => updateSortOrder(columns, listRequestParams.sort), [columns, listRequestParams.sort])

  return (
    <ATableFullPage
      listParamsUpdated={setListRequestParams}
      listRequestParams={listRequestParams}
      isFiltersLoaded={isFiltersLoaded}
      dataEndpoint={dataEndpoint}
      columns={sorted}
      rowHoverGray
    />
  )
}
