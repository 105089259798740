import env from 'env'
import { t } from 'i18next'
import formatNumber from './formatNumber'

const currency = env.REACT_APP_CURRENCY_SYMBOL

export const formatDailyPriceInTon = (price) => {
  const formatPrice = formatNumber(price, true)

  return `${formatPrice} ${currency}/${t('common:ton')}/${t('common:fullDay')}`
}

export const labelDailyPriceInTon = (label) => {
  return `${label}, ${currency}/${t('common:ton')}/${t('common:fullDay')}`
}
