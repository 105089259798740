import { ResponsiveModal } from 'views/components/ResponsiveModal/ResponsiveModal'
import { SharedModalInputs } from 'views/components/SharedModal'
import { Bid } from 'modules/domain/bid/types'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import { FormGroup, Input } from '@agro-club/agroclub-shared'
import * as managers from 'modules/domain/deal/managers'
import { useParams } from 'react-router-dom'
import { QuantityInput } from 'views/components/QuantityInput/QuantityInput'
import { labelTarif } from 'modules/utils/numbers/formatTarif'
import { AnalyticsPlaces } from '../../analyticsPlaces'
import { useAFormHandler } from 'analytics/hooks'
import { FC } from 'react'

type Props = {
  bid: Bid
  isPurchase: boolean
  onClose: () => void
  onSuccess: () => void
}

type EditableFields = {
  price: number
  quantity: number
}

export const DealPriceQuantityEditModal: FC<Props> = ({ onClose, bid, isPurchase, onSuccess }) => {
  const { t } = useTranslation('deal')
  const { id } = useParams()
  const { formProgress, formHandler } = useAFormHandler()

  const formik = useFormik<EditableFields>({
    initialValues: {
      price: bid.price,
      quantity: bid.quantity,
    },

    onSubmit: formHandler(
      async () =>
        isPurchase
          ? await managers.updatePurchaseBid(id, formik.values)
          : await managers.updateSaleBid(id, formik.values),
      {
        onSuccess: async () => {
          await onSuccess()
          onClose()
        },
        onError: (error) => {
          const { errors } = error
          formik.setErrors(errors)
        },
      },
    ),
  })

  return (
    <ResponsiveModal
      onConfirm={() => formik.dirty && formik.submitForm()}
      id={AnalyticsPlaces.EDIT_PRICE_QUANTITY_MODAL}
      confirmButtonText={t('common:save')}
      title={t('detail_edit')}
      disabled={!formik.dirty}
      progress={formProgress}
      onClose={onClose}
      size="small"
    >
      <SharedModalInputs>
        <FormGroup
          label={labelTarif(isPurchase ? t(`common:price.cpt`) : t(`common:price.exw`))}
          error={formik.errors.price}
        >
          <Input {...formik.getFieldProps('price')} invalid={!!formik.errors.price} type="number" min={0} />
        </FormGroup>
        <QuantityInput formik={formik} fieldName="quantity" label={t('common:volume')} />
      </SharedModalInputs>
    </ResponsiveModal>
  )
}
