import { defaultTheme, Typography } from '@agro-club/agroclub-shared'
import styled from 'styled-components'
import { ARouterLink } from 'views/components/Analytics'

export const StyledCompanyLink = styled(ARouterLink)`
  ${Typography.bodyLarge}
  color: ${defaultTheme.color.primary600};

  &:hover {
    color: ${defaultTheme.color.primary800};
    text-decoration: underline;
  }
`
export const WrapperButton = styled.div`
  display: flex;
  gap: 0 16px;
  margin-bottom: 3px;
`
export const Separator = styled.div`
  border-right: 1px solid;
  border-color: ${defaultTheme.color.secondary100};
  height: 32px;
`
