import { DealDocumentRequest } from 'views/pages/Deal/DealDetail/DocumentRequestControl/DealDocumentRequest/DealDocumentRequest'
import { ContractRequest } from 'views/pages/Deal/DealDetail/DocumentRequestControl/ContractRequest/ContractRequest'
import { isStatusAfter } from 'views/pages/Deal/DealDetail/helpers'
import { DealStatusTypes } from 'modules/domain/deal/types'
import { defaultTheme } from '@agro-club/agroclub-shared'
import { Declaration } from 'modules/domain/types'
import { useDealContext } from '../DealContext'
import styled from 'styled-components'
import { isRussia } from 'env'
import { FC } from 'react'

type Props = {
  declaration: Declaration | undefined
}

const Wrapper = styled.div`
  @media (max-width: ${defaultTheme.queries.mobile}) {
    display: flex;
    flex: 1 0 auto;
    overflow-x: auto;
    scrollbar-width: none;
    gap: 0 4px;
  }
`

export const DocumentRequestControl: FC<Props> = ({ declaration }) => {
  const { deal, dealRefetch } = useDealContext()
  const isVisibleDocsBlock = isStatusAfter(deal.status, DealStatusTypes.AGREED)
  const showContractRequest = isVisibleDocsBlock

  if (isRussia && isVisibleDocsBlock) {
    return (
      <Wrapper>
        {showContractRequest && <ContractRequest deal={deal} refetch={dealRefetch} />}
        {!!deal?.can_request_documents && (
          <DealDocumentRequest deal={deal} refetch={dealRefetch} declaration={declaration} />
        )}
      </Wrapper>
    )
  }
  return <></>
}
