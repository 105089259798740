import { Route, Routes } from 'react-router-dom'
import { RoutesList } from 'views/pages/Routes/RoutesList/RoutesList'

export const RoutesPage = () => {
  return (
    <Routes>
      <Route index element={<RoutesList />} />
    </Routes>
  )
}
