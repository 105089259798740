import { FC } from 'react'

import { useAnalyticsContext } from 'analytics/hooks'
import { InternalUploadFiles, InternalUploadFilesProps } from './InternalUploadFiles'
import { AnalyticsPayload } from 'analytics/types'

interface Props extends InternalUploadFilesProps {
  id?: string
  analyticsPayload?: AnalyticsPayload
}

const AUploadFiles: FC<Props> = ({ addFile, removeFile, fileList, ...otherProps }) => {
  const { track } = useAnalyticsContext()

  const handleAddFile = (file: any) => {
    addFile(file)

    track(`add|${otherProps.id}` || 'add|file', {
      ...otherProps.analyticsPayload,
      value: {
        to: file,
      },
    })
  }

  const handleRemoveFile = (id: string) => {
    removeFile(id)

    track(`remove|${otherProps.id}` || 'remove|file', {
      ...otherProps.analyticsPayload,
      id,
      value: {
        to: fileList?.find((file) => file.id === id) ?? {},
      },
    })
  }

  return (
    <InternalUploadFiles addFile={handleAddFile} removeFile={handleRemoveFile} fileList={fileList} {...otherProps} />
  )
}

export default AUploadFiles
