import { FC, useEffect } from 'react'
import { RespFile } from 'modules/domain/types'
import { useParams } from 'react-router-dom'
import AUploadFiles from 'views/components/UploadFiles/AUploadFiles'
import { apiClient } from 'modules/utils/httpClient'
import { endpoints } from 'modules/endpoints'
import { uploadFile } from 'modules/domain/common/managers'
import { useSingleEntity } from 'modules/domain/common/hooks'
import { Progress } from '@agro-club/agroclub-shared'

interface Props {
  setSummary: (arg: number) => void
}

export const SpecificationDocuments: FC<Props> = ({ setSummary }) => {
  const { id } = useParams()
  const [progress, specificationFiles = [], specificationFilesRefetch] = useSingleEntity<RespFile[]>(
    endpoints.specificationFiles(id),
  )

  useEffect(() => {
    if (progress === Progress.SUCCESS) setSummary(specificationFiles.length)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [specificationFiles.length])

  const addHandler = async (specId: string, file: File) => {
    await uploadFile(endpoints.specificationFiles(specId), file)
    specificationFilesRefetch(true)
  }

  const removeHandler = async (specId: string, fileId: string) => {
    await apiClient.delete(endpoints.deleteSpecificationFiles(specId, fileId))
    specificationFilesRefetch(true)
  }

  return (
    <AUploadFiles
      id="addSpecificationFile"
      analyticsPayload={{ id }}
      removeFile={(fileId) => removeHandler(id, fileId)}
      addFile={({ file }) => addHandler(id, file)}
      fileList={specificationFiles}
    />
  )
}
