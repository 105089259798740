import { endpoints } from 'modules/endpoints'
import { apiClient } from 'modules/utils/httpClient'
import { MarkAsReadPayload, EventsCount } from './types'

export const getEventsChannel = () => {
  return apiClient.get<{ channel_name: string }>(endpoints.getEventChannel())
}

export const getEventsCount = () => {
  return apiClient.get<EventsCount[]>(endpoints.eventsCount())
}

export const markEventAsRead = (payload: MarkAsReadPayload[]) => {
  return apiClient.post<MarkAsReadPayload[]>(endpoints.markEventAsRead(), payload)
}
