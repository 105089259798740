import { FC } from 'react'
import { PopoverContainer } from 'views/styled/common'
import { APopoverButton } from 'views/components/Analytics/APopoverButton'
import { Icons } from '@agro-club/agroclub-shared'
import { useTranslation } from 'react-i18next'

type Props = {
  setPopoverVisible: (arg: boolean) => void
  setTripReconciliationModal: (arg: boolean) => void
  setRejectModal: (arg: boolean) => void
}

export const PopoverContent: FC<Props> = ({ setPopoverVisible, setTripReconciliationModal, setRejectModal }) => {
  const { t } = useTranslation('popover')

  return (
    <PopoverContainer width={170} onClick={() => setPopoverVisible(false)}>
      <APopoverButton
        id="toApprovalTrips"
        text={t('buttonTitle.to_approval')}
        Icon={<Icons.IconShippingDone />}
        onClick={() => setTripReconciliationModal(true)}
      />

      <APopoverButton
        id="reject"
        text={t('buttonTitle.reject')}
        Icon={<Icons.IconClear />}
        onClick={() => setRejectModal(true)}
      />
    </PopoverContainer>
  )
}
