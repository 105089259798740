import { makeEventCountHook, makeSocketConnectionHook } from '@agro-club/agroclub-shared'
import { getEventsChannel, getEventsCount, markEventAsRead } from './managers'
import { DEVELOPMENT, PUSHER_APP_CLUSTER, PUSHER_APP_KEY, REACT_APP_API_BASE_URL } from 'env'
import { endpoints } from 'modules/endpoints'
import { getAccessToken } from 'modules/domain/auth/repository'
import { useContext, useEffect } from 'react'
import { EventModel } from './types'
import { AuthContext } from 'modules/context/AuthContext'

const useSocketConnection = makeSocketConnectionHook({
  debug: !!DEVELOPMENT,
  appKey: PUSHER_APP_KEY,
  appCluster: PUSHER_APP_CLUSTER,
  authEndpoint: REACT_APP_API_BASE_URL + endpoints.pusherAuthEndpoint(),
  getAccessToken,
})

const useEventCountInternal = makeEventCountHook({ getEventsCount, getEventsChannel })

export const useEventCount = () => {
  const { profile } = useContext(AuthContext)
  const socket = useSocketConnection(!!profile)
  return useEventCountInternal(socket)
}

export const useMarkAsRead = (id: string, model: EventModel) => {
  useEffect(() => {
    markEventAsRead([{ model, object_id: id }])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
