const main = 'warehouses'
const relativeRoutes = {
  relativePage: `${main}/*`,
  relativeList: `${main}`,
  relativeDetail: ':id',
  relativeListWithTab: 'tab-:activeTab',
}

const routes = {
  List: `/${main}`,
  ListWithTab: `/${main}/${relativeRoutes.relativeListWithTab}`,
  Details: `/${main}/${relativeRoutes.relativeDetail}`,
}

const WarehousesRoutes = { ...relativeRoutes, ...routes }

export default WarehousesRoutes
