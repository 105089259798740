import { Progress } from '@agro-club/agroclub-shared'
import { endpoints } from 'modules/endpoints'
import { apiClient } from 'modules/utils/httpClient'
import { Dispatch, FC, KeyboardEventHandler, SetStateAction, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TableInput } from 'views/components/Table/Controls/TableInput'
import { BidPriceTableCellProps } from './types'
import CellProgress from './CellProgress'
import { useAProgress } from 'hooks/useAProgress'
import { BidKind, USBid, USContractType } from 'modules/domain/bid/types'
import { roundPrice } from 'modules/utils/numbers/formatPrice'
import { useNotification } from 'hooks/useNotification'

const getUsDto = (fieldName: BidKind, bid: USBid, value: string | undefined) => {
  const currValue = Number(value)
  const price = bid.price
  const basis = bid.basis
  const futuresPrice = bid.futures_price
  const datesDto = { start_date: bid.start_date, end_date: bid.end_date }

  switch (fieldName) {
    case BidKind.basis:
      return {
        basis: roundPrice(currValue),
        futures_price: roundPrice(futuresPrice),
        price: roundPrice((price ?? 0) + currValue - (basis ?? 0)),
        ...datesDto,
      }
    case BidKind.futures:
      return {
        basis: roundPrice(basis),
        futures_price: roundPrice(currValue),
        price: roundPrice((price ?? 0) + currValue - (futuresPrice ?? 0)),
        ...datesDto,
      }
    case BidKind.cash_priced:
      return {
        basis: roundPrice(basis),
        futures_price: roundPrice(futuresPrice),
        price: roundPrice(currValue),
        ...datesDto,
      }
  }
}

type USBidPriceTableCellProps = {
  fieldName?: BidKind
  rowsToUpdate?: number[]
  setRowsToUpdate?: Dispatch<SetStateAction<number[]>>
} & BidPriceTableCellProps

export const USPriceCell: FC<USBidPriceTableCellProps> = ({
  currentValue,
  bid,
  refetch,
  fieldName,
  setRowsToUpdate,
  rowsToUpdate,
}) => {
  const { t } = useTranslation()
  const [value, setValue] = useState(currentValue)
  const notify = useNotification()
  const disableVariants = {
    [USContractType.basis]: fieldName === BidKind.cash_priced,
    [USContractType.cash_priced]: fieldName === BidKind.basis || fieldName === BidKind.futures,
  }

  useEffect(() => {
    if (currentValue === value) return
    setValue(currentValue)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentValue])

  const [progress, execute, error] = useAProgress(
    async (price) => {
      setRowsToUpdate?.((prev) => [...prev, +bid.id])

      await apiClient.patch(endpoints.bid(bid.id), getUsDto(fieldName as BidKind, bid as USBid, price))
      await refetch()

      notify(Progress.SUCCESS, { title: t('bid:form.notifyEditSuccess') })
      setRowsToUpdate?.((prev) => prev.filter((id) => id !== +bid.id))
    },
    { eventName: `${fieldName}-price`, scope: 'change' },
  )

  useEffect(() => {
    if (!error) return

    const errorList =
      !!error?.errors &&
      Object.entries(error?.errors)
        .map(([key, value]) => `${key.toUpperCase()}: ${value}`)
        .join('\n\n')

    notify(Progress.ERROR, { title: errorList ?? t('common.notifyError') })
    setRowsToUpdate?.((prev) => prev.filter((id) => id !== +bid.id))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  const updatePrice = (price) => {
    if (Number(price) === Number(currentValue)) {
      return
    }
    return execute(price)
  }

  const onKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (event.key !== 'Enter' || progress === Progress.WORK) {
      return
    }
    updatePrice(value)
  }

  return (
    <>
      <CellProgress enabled={progress === Progress.WORK} />
      <TableInput
        error={!!error}
        onChange={(e) => setValue(e.target.value)}
        onBlur={() => updatePrice(value)}
        onKeyDown={onKeyDown}
        value={value}
        disabled={disableVariants[(bid as USBid).contract_type] || rowsToUpdate?.includes(+bid.id)}
      />
    </>
  )
}
