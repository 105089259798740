import { defaultTheme, Typography } from '@agro-club/agroclub-shared'
import styled from 'styled-components'
import { CustomScroll } from 'views/styled/common'

export const CommentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`

export const CommentsList = styled.div<{ maxHeight?: number }>`
  flex-grow: 1;
  overflow-y: auto;
  margin-bottom: 16px;
  height: 100%;
  max-height: ${(props) =>
    props.maxHeight
      ? `${props.maxHeight}px`
      : 'calc(100dvh - 235px)'}; //TODO will need to reorganize the styles a bit to make the component more flexible
  ${CustomScroll}
`

export const CommentContainer = styled.div`
  &:not(:first-child) {
    margin-top: 16px;
  }
`

export const CommentHeader = styled.div`
  margin-bottom: 8px;
`

export const CommentDate = styled.span`
  ${Typography.bodyMedium};
  margin-left: 8px;
  color: ${defaultTheme.color.textSecondary60a};
`

export const CommentText = styled.div`
  ${Typography.bodyLarge};
  color: ${defaultTheme.color.textPrimary900};
  white-space: pre-line;
  word-break: break-word;
`

export const RightPanelComments = styled.div`
  margin-top: -5px;
  height: 100%;
`

export const ButtonWrapper = styled.div`
  display: flex;
  column-gap: 16px;
  margin-top: 12px;
  @media (max-width: ${defaultTheme.queries.mobile}) {
    flex-direction: column;
    gap: 8px 0;
  }
`
