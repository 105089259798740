import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'

import { apiClient } from 'modules/utils/httpClient'
import { endpoints } from 'modules/endpoints'
import { Driver } from 'modules/domain/types'
import { SharedModalContent, SharedModalTitle } from 'views/components/SharedModal'
import { SharedModalInputs } from 'views/components/SharedModal/styled'
import { useNotificationProgress } from 'hooks/useNotificationProgress'
import { DriverModalContent } from './DriverModalContent'
import ASharedModal from 'views/components/SharedModal/ASharedModal'
import { AnalyticsPlaces } from '../../analyticsPlaces'
import ADefaultModalFooter from 'views/components/DefaultModalFooter/ADefaultModalFooter'
import { useAFormHandler } from 'analytics/hooks'
import { refetchFunc } from 'modules/domain/common/hooks'

interface Props {
  onClose: () => void
  carrierId: string
  onSuccess: refetchFunc
  driver: Driver
}

export const DriverEditModal: FC<Props> = ({ onClose, carrierId, onSuccess, driver }) => {
  const { formProgress, formHandler } = useAFormHandler('editDriver')
  const { t } = useTranslation('logisticsKR')

  const handleEditDriver = formHandler(
    async () => {
      await apiClient.put(endpoints.LogisticsKR.drivers(driver?.id), formik.values)
    },
    {
      onSuccess: async () => {
        await onSuccess()
        onClose()
      },
      onError: (error) => {
        const { errors } = error
        formik.setErrors(errors)
      },
    },
  )

  useNotificationProgress(
    formProgress,
    t('carriers.notifications.driver_edit_success'),
    t('carriers.notifications.driver_edit_error'),
  )

  const formik = useFormik({
    initialValues: {
      carrier: carrierId,
      name: driver.name,
      phone: driver.phone,
    },
    enableReinitialize: true,
    onSubmit: handleEditDriver,
  })

  return (
    <ASharedModal id={AnalyticsPlaces.CARRIERS.EDIT_DRIVER_MODAL} size="medium" onClose={onClose}>
      <SharedModalTitle>{t('carsDetails.addForm.editTitle')}</SharedModalTitle>
      <SharedModalContent>
        <SharedModalInputs>
          <DriverModalContent formik={formik} isEditModal />
        </SharedModalInputs>
      </SharedModalContent>
      <ADefaultModalFooter
        onClose={onClose}
        progress={formProgress}
        confirmButtonText={t('common:save')}
        onConfirm={() => formik.submitForm()}
      />
    </ASharedModal>
  )
}
