import { isBrazil, isUS } from 'env'
import { FC, ReactNode } from 'react'
import { DefaultTooltip } from 'views/components/DefaultTooltip/DefaultTooltip'

type Props = {
  title?: ReactNode
  textFormula?: ReactNode
  numberFormula: ReactNode
  children: ReactNode
}

export const FormulaTooltip: FC<Props> = ({ title, textFormula, numberFormula, children }) => {
  if ((isBrazil && title) || (isUS && title)) return <DefaultTooltip tooltipContent={title}>{children}</DefaultTooltip>
  if (isBrazil || isUS) return children

  const content = (
    <div onClick={(e) => e.stopPropagation()}>
      {title && <div>{title}</div>}
      {numberFormula && <div>{numberFormula}</div>}
      {textFormula && <div>{textFormula}</div>}
    </div>
  )

  return <DefaultTooltip tooltipContent={content}>{children}</DefaultTooltip>
}
