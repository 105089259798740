export const AnalyticsPages = {
  REQUESTS: 'requests',
  DETAILS: 'requestsDetails',
  REQUEST_CARS: 'requestCars',
  CUSTOMERS: {
    LIST: 'customersKR',
    DETAIL: 'customerKRDetail',
  },
  CARRIERS: {
    LIST: 'carriersKR',
    DETAIL: 'carrierKRDetail',
  },
  CARS: 'carsKR',
  WAREHOUSES: {
    LIST: 'warehousesKR',
    DETAIL: 'warehouseKRDetail',
  },
  RATES: 'rates',
}

export const AnalyticsPlaces = {
  REQUESTS_BOARD: {
    PAGE: 'RequestsBoard',
    EDIT_INFO_MODAL: 'EditInfoModal',
    CAR_TO_TRIP_MODAL: 'CarToTripModal',
    CAR_TO_TRIP_RECONCILIATION_MODAL: 'CarToTripReconciliationModal',
    ADD_TRAILER_MODAL: 'AddTrailerModal',
    EDIT_ASSIGNEE_MODAL: 'EditAssigneeModal',
    INFO_DRAWER: 'InfoDrawer',
    ADD_TRIP_RECONCILIATION_MODAL: 'AddTripReconciliationModal',
    DELETE_TRIP_MODAL: 'DeleteTripModal',
  },
  DETAILS: {
    PAGE: 'Details',
  },
  REQUEST_CARS: {
    LIST: 'RequestCarsList',
  },
  CUSTOMERS: {
    LIST: 'CustomersList',
    DETAIL: 'CustomerDetail',
  },
  CARRIERS: {
    LIST: 'CarriersList',
    DETAIL: 'CarrierDetail',
    ADD_MODAL: 'AddModal',
    EDIT_MODAL: 'EditModal',
    CHANGE_STATUS_MODAL: 'ChangeStatusModal',
    ARCHIVE_MODAL: 'ArchiveModal',
    ADD_CAR_MODAL: 'AddCarModal',
    ARCHIVE_CAR_MODAL: 'ArchiveCarModal',
    EDIT_CAR_MODAL: 'EditCarModal',
    CHANGE_CAR_STATUS_MODAL: 'ChangeCarStatusModal',
    DELETE_TRAILER_MODAL: 'DeleteTrailerModal',
    EDIT_TRAILER_MODAL: 'EditTrailerModal',
    ADD_DRIVER_MODAL: 'AddDriverModal',
    DELETE_DRIVER_MODAL: 'DeleteDriverModal',
    EDIT_DRIVER_MODAL: 'EditDriverModal',
  },
  CARS: {
    LIST: 'CarsList',
    EDIT_MODAL: 'EditModal',
    EDIT_BEFORE_SEND: 'EditCarBeforeTrip',
    ARCHIVE_MODAL: 'ArchiveModal',
    CHANGE_STATUS_MODAL: 'ChangeStatusModal',
    ADD_MODAL: 'AddModal',
  },
  WAREHOUSES: {
    LIST: 'WarehousesList',
    DETAIL: 'WarehouseDetail',
    ADD_MODAL: 'AddModal',
    EDIT_MODAL: 'EditModal',
  },
  RATES: {
    LIST: 'RatesList',
    EDIT_MODAL: 'EditModal',
    ADD_MODAL: 'AddModal',
  },
}
