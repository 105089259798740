import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router'

import useFormatDate from 'hooks/useFormatDate'

import { getMainParameter } from 'modules/utils/helpers'
import { DashboardTask } from 'modules/domain/dashboard/types'
import { TaskType } from 'modules/domain/task/types'
import { Item, ItemBody, AItemLink } from '../styled'
import BidRoutes from 'views/pages/Bid/routes'
import { CardHeader } from '../CardHeader/CardHeader'
import { formatQuantity } from 'modules/utils/numbers/formatQuantity'
import { CommonInfo } from './styled'
import { TextCard } from 'views/styled/ItemCardStyles'
import UserRoutes from 'views/pages/User/routes'
import { getPriceWithType } from 'modules/domain/bid/utils'
import { refetchFunc } from 'modules/domain/common/hooks'

type Props = {
  task: DashboardTask
  refetch: refetchFunc
}

export const TaskBidItem: FC<Props> = ({ task, ...props }) => {
  const bid = task.linked_bid
  const isOverdue = task?.task_type === TaskType.OVERDUE_BID_SELL
  const { t } = useTranslation('common')

  const formatDate = useFormatDate()

  if (!bid) return null

  const parameter = getMainParameter(bid.parameters)

  const link = isOverdue
    ? `${generatePath(UserRoutes.BidPriceWithTopBid, { id: bid.owner?.id, topBid: bid.id })}`
    : `${generatePath(BidRoutes.Details, { id: bid.id })}`

  const { pathname, search } = window.location

  return (
    <Item>
      <AItemLink eventName="bid" itemId={bid.id} state={{ pathname, search }} to={link} />
      <CardHeader task={task} {...props} isOverdue={isOverdue} />
      <ItemBody>
        <CommonInfo>
          <TextCard>
            {t('dashboard:bidId')} {bid.id}
          </TextCard>
          {isOverdue ? (
            <TextCard>
              {t('dashboard:modified')} {formatDate(bid.modified, true)}
            </TextCard>
          ) : (
            <TextCard>
              {t('dashboard:created')} {formatDate(bid.created, true)}
            </TextCard>
          )}
        </CommonInfo>

        <TextCard>{bid.company.name}</TextCard>

        <CommonInfo>
          <TextCard>{bid.product.title},</TextCard>
          <TextCard>
            {formatQuantity(bid.quantity)} {parameter ? `, ${parameter}` : ''}
          </TextCard>
          <TextCard thumbnail>{getPriceWithType(bid, t)}</TextCard>
        </CommonInfo>
        {task.assignee && <TextCard>{task.assignee.short_name}</TextCard>}
      </ItemBody>
    </Item>
  )
}
