import { FC, useState } from 'react'
import { CardButtonWrapper, CardContentWrapper, ItemCard, TextCard } from 'views/styled/ItemCardStyles'
import { Icons, AntPopover } from '@agro-club/agroclub-shared'
import { useTranslation } from 'react-i18next'
import { CarStatuses, LogisticsKRCar } from '../../../../types'
import { AButton } from 'views/components/Analytics'
import { useAnalyticsClickHandler } from 'analytics/hooks'
import { PopoverContent } from '../../../../components/PopoverContent/PopoverContent'

type Props = {
  car: LogisticsKRCar
  refetch: () => Promise<void>
  onSelectedChanged: (carId: string) => void
  activeCarId: string
}

export const CarCard: FC<Props> = ({ car, refetch, onSelectedChanged, activeCarId }) => {
  const handleClick = useAnalyticsClickHandler('car')
  const { t } = useTranslation('logisticsKR')
  const [popoverVisible, setPopoverVisible] = useState(false)
  const carParameters = [
    car.car_type && t(`vehicle_type.${car.car_type}`),
    car.is_dump_truck && t('warehouses.details.is_dump_truck'),
    car.unloading_type && t(`directions.${car.unloading_type}`),
    car.side_height && t('warehouses.details.values.scales_length', { scales_length: car.side_height }),
  ]
    .filter(Boolean)
    .join(', ')

  const carInfo = [`${t('vehicles:charter')}: ${car.is_in_charter ? t('common:yes') : t('common:no')}`].join(', ')

  return (
    <ItemCard
      activeCard={car.id === activeCarId}
      status={car.status}
      onClick={handleClick(() => onSelectedChanged(car.id), { id: car.id })}
    >
      <CardContentWrapper>
        <TextCard thumbnail>
          {car.brand_model} {car.licence_number}
        </TextCard>
        {carParameters && <TextCard>{carParameters}</TextCard>}
        {carInfo && <TextCard>{carInfo}</TextCard>}
        <CardButtonWrapper>
          {car.status !== CarStatuses.archived && (
            <AntPopover
              content={<PopoverContent setPopoverVisible={setPopoverVisible} car={car} onSuccess={refetch} />}
              placement="bottomRight"
              trigger="click"
              open={popoverVisible}
              onOpenChange={() => setPopoverVisible(!popoverVisible)}
            >
              <AButton id="carActions" intent="white" Icon={Icons.IconMoreHoriz} onClick={(e) => e.stopPropagation()} />
            </AntPopover>
          )}
        </CardButtonWrapper>
      </CardContentWrapper>
    </ItemCard>
  )
}
