import { DocumentRequestDTO, DocumentRequestFull, ProductPurpose } from 'modules/domain/documentRequest/types'
import { ResponsiveModal } from 'views/components/ResponsiveModal/ResponsiveModal'
import { refetchFunc, useSingleEntity } from 'modules/domain/common/hooks'
import { SharedModalContent } from 'views/components/SharedModal'
import { CommonFormFields } from './CommonFormFields'
import { apiClient } from 'modules/utils/httpClient'
import { Declaration } from 'modules/domain/types'
import { useAFormHandler } from 'analytics/hooks'
import { Deal } from 'modules/domain/deal/types'
import { useTranslation } from 'react-i18next'
import { endpoints } from 'modules/endpoints'
import { useFormik } from 'formik'
import { FC } from 'react'

type Props = {
  deal: Deal
  documents_request: NonNullable<Deal['documents_request']>
  declaration: Declaration | undefined
  refetch: refetchFunc
  onClose: () => void
}

export const EditModal: FC<Props> = ({ onClose, deal, documents_request, declaration, refetch }) => {
  const { t } = useTranslation('deal')
  const { formProgress, formHandler } = useAFormHandler('editDocumentsRequest')
  const [, documentsRequest] = useSingleEntity<DocumentRequestFull>(endpoints.documentsRequest(documents_request.id))

  const formik = useFormik<DocumentRequestDTO>({
    initialValues: {
      deal: deal.id,
      request_ds: documentsRequest?.request_ds || false,
      request_quarantine: documentsRequest?.request_quarantine || false,
      request_vet: documentsRequest?.request_vet || false,
      request_sdiz: documentsRequest?.request_sdiz || false,
      regionals: documentsRequest?.regionals?.map((regional) => regional.id) || null,
      load_address: documentsRequest?.load_address || '',
      unload_address: documentsRequest?.unload_address || '',
      consignee: documentsRequest?.consignee || '',
      pay_to_customer_account: documentsRequest?.pay_to_customer_account || false,
      product_purpose: documentsRequest?.product_purpose || ProductPurpose.feed,
      quantity: documentsRequest?.quantity || 0,
    },
    enableReinitialize: true,
    onSubmit: formHandler(
      async () => {
        if (formik.values.product_purpose === ProductPurpose.alimentary) {
          formik.setFieldValue('request_vet', false)
        }

        await apiClient.put(endpoints.documentsRequest(documents_request.id), formik.values)
      },
      {
        onSuccess: async () => {
          await refetch()
          onClose()
        },
      },
    ),
  })

  return (
    <ResponsiveModal
      title={t('requestDocumentsBlock.editTitle')}
      confirmButtonText={t('common:save')}
      onConfirm={formik.handleSubmit}
      disabled={!formik.dirty}
      progress={formProgress}
      onClose={onClose}
      id="EditDocument"
    >
      <SharedModalContent>
        <CommonFormFields formik={formik} deal={deal} declaration={declaration} />
      </SharedModalContent>
    </ResponsiveModal>
  )
}
