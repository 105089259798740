import { DealContractForm, DealProductDTO } from 'views/pages/Deal/DealDetail/types'
import { FormikErrors, FormikProps } from 'formik'
import { useTranslation } from 'react-i18next'
import { useSingleEntity } from 'modules/domain/common/hooks'
import { endpoints } from 'modules/endpoints'
import { FormGroup, Icons, TextArea, defaultTheme } from '@agro-club/agroclub-shared'
import { Product } from 'modules/domain/collection/types'
import { useEffect } from 'react'
import { AButton } from 'views/components/Analytics'
import { CropParam } from './CropParam'
import { FormParamInput } from 'views/pages/Deal/DealDetail/DocumentRequestControl/ContractRequest/ContractRequestModal/ProductParams/FormParamInput'
import { ShortFormControl } from 'views/styled/common'
import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'

type Props = {
  formik: FormikProps<DealContractForm>
  productId: string
  withoutInitialValue?: boolean
}

const AddParamBtn = styled(AButton)`
  @media (max-width: ${defaultTheme.queries.mobile}) {
    width: 100%;
  }
`

export const ProductParams = ({ formik, productId, withoutInitialValue }: Props) => {
  const { t } = useTranslation('deal')
  const [, product] = useSingleEntity<Product>(endpoints.products(productId))
  const separateMainParam = product?.parameters.slice(1)
  const formikParams = formik.values.parameters
  const usedParams = formikParams.map((param) => param.parameter)
  const availableParams = product?.parameters.filter(
    (param) => param.id !== formik.values.main_param?.parameter && !usedParams.includes(param.id),
  )

  useEffect(() => {
    if (!product || withoutInitialValue) return

    formik.setValues({
      ...formik.values,
      parameters: [],
      main_param: {
        parameter: product.main_parameter?.id,
        parameter_value: undefined,
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product])

  let errorMainParam
  let errorsParams

  if (formik?.errors?.parameters?.length) {
    errorMainParam = formik.errors.parameters[0] as FormikErrors<DealProductDTO>
    errorsParams = formik.errors.parameters.slice(1) as FormikErrors<DealProductDTO>[]
  }

  const addNewParamHandler = () =>
    formik.setFieldValue('parameters', [
      ...formik.values.parameters,
      {
        parameter: undefined,
        parameter_value: undefined,
      },
    ])

  const isMobile = useMediaQuery({
    query: `(max-width: ${defaultTheme.queries.mobile})`,
  })

  return (
    <>
      <FormGroup error={formik.errors.parameters_comment} label={t('common:comment')}>
        <TextArea
          {...formik.getFieldProps('parameters_comment')}
          invalid={!!formik.errors.parameters_comment}
          placeholder={t('comments:enter_text')}
          height={80}
        />
      </FormGroup>
      <ShortFormControl width={325}>
        <FormParamInput
          onChange={(values) => formik.setFieldValue('main_param', values)}
          parameter={formik.values.main_param}
          label={product?.main_parameter?.name}
          formikError={errorMainParam}
          required
        />
      </ShortFormControl>
      {formikParams.map((param, i) => (
        <CropParam
          allParams={separateMainParam || []}
          availableParams={availableParams || []}
          parameter={param}
          onRemove={() =>
            formik.setFieldValue(
              'parameters',
              formik.values.parameters.filter((_, index) => i !== index),
            )
          }
          onChange={(values) => formik.setFieldValue(`parameters[${i}]`, values)}
          key={i}
          formikError={errorsParams && errorsParams[i]}
        />
      ))}
      {formik.values.parameters.length !== separateMainParam?.length ? (
        <div>
          <AddParamBtn
            size={isMobile ? 'biggest' : 'medium'}
            onClick={addNewParamHandler}
            Icon={Icons.IconAdd}
            intent="approve"
            id="addParam"
          >
            {t('requestDocsModal.add_param')}
          </AddParamBtn>
        </div>
      ) : null}
    </>
  )
}
