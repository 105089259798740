import { TripOffer } from 'views/pages/LogisticsKR/types'
import { FlexColumnWrapper } from '../styled'
import { Text } from '@agro-club/agroclub-shared'
import APhone from 'views/ui/Phone/APhone'
import { DASH } from 'modules/constants'

type Props = {
  tripOffer: TripOffer
}

export const DriverCell = ({ tripOffer }: Props) => {
  const { driver, car, trailer } = tripOffer
  const carInfo = `${car.brand_model} ${car.licence_number} / ${trailer?.licence_number ?? DASH}`

  return (
    <FlexColumnWrapper>
      <div>{driver.name}</div>
      <APhone phone={driver.phone} />
      <Text typography="bodyMedium" color="secondary">
        {carInfo}
      </Text>
    </FlexColumnWrapper>
  )
}
