import { useTranslation } from 'react-i18next'

import { CarsStatusFilter } from './CarsStatusFilter'
import { CarFilter } from './CarFilter'
import { UnloadingTypeFilter } from './UnloadingTypeFilter'
import { SideHeightFilter } from './SideHeightFilter'
import { BooleanFilter } from 'views/components/BooleanFilter/BooleanFilter'

import { useDisplayClearBtn } from 'hooks/useDisplayClearBtn'

import { ListRequestParams } from 'modules/domain/types'
import { endpoints } from 'modules/endpoints'
import { FiltersAndSearchWrapper, FiltersWrapper } from 'views/components/CommonTableComponents/CommonTableComponents'
import { SearchFilter } from 'views/components/SearchFilter/SearchFilter'
import { ABaseTableFilter } from 'views/components/TableFilters/ABaseTableFilter'
import { AnalyticsFilterVariant } from 'analytics/types'
import { AClearFiltersButton } from 'views/components/Analytics'
import { FC } from 'react'

type Props = {
  listRequestParams: ListRequestParams
  updateFilterState: (filterObj: Record<string, any>) => void
  handleClearFilters: () => void
}

export const CarsFilters: FC<Props> = ({ listRequestParams, updateFilterState, handleClearFilters }) => {
  const { t } = useTranslation()
  const displayClearBtn = useDisplayClearBtn({
    listRequestParams,
  })

  return (
    <FiltersAndSearchWrapper>
      <FiltersWrapper>
        <CarFilter listRequestParams={listRequestParams} updateFilterState={updateFilterState} />
        <CarsStatusFilter listRequestParams={listRequestParams} updateFilterState={updateFilterState} />
        <UnloadingTypeFilter listRequestParams={listRequestParams} updateFilterState={updateFilterState} />
        <BooleanFilter
          value={listRequestParams.filter.has_trailer}
          onChange={(value) => updateFilterState({ has_trailer: value })}
          placeholder={t('logisticsKR:cars.filters.trailer')}
        />
        <BooleanFilter
          value={listRequestParams.filter.is_dump_truck}
          onChange={(value) => updateFilterState({ is_dump_truck: value })}
          placeholder={t('logisticsKR:cars.filters.is_dump_truck')}
        />
        <BooleanFilter
          value={listRequestParams.filter.is_in_charter}
          onChange={(value) => updateFilterState({ is_in_charter: value })}
          placeholder={t('logisticsKR:cars.filters.is_in_charter')}
        />

        <ABaseTableFilter
          onChange={(working_regions) => updateFilterState({ working_regions })}
          value={listRequestParams.filter.working_regions}
          placeholder={t('logisticsKR:carriers.region')}
          getLabel={(label) => label.name}
          endpoint={endpoints.provinces()}
          simpleApiFormat
          multiple
          id={AnalyticsFilterVariant.REGION}
        />
        <SideHeightFilter listRequestParams={listRequestParams} updateFilterState={updateFilterState} />

        {displayClearBtn && <AClearFiltersButton onClick={handleClearFilters} />}
      </FiltersWrapper>
      <SearchFilter listRequestParams={listRequestParams} updateFilterState={updateFilterState} />
    </FiltersAndSearchWrapper>
  )
}
