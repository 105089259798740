import { ExportDetail } from './ExportDetail/ExportDetail'
import { ExportList } from './ExportList/ExportList'
import { Routes, Route } from 'react-router-dom'
import ExportRoutes from './routes'
import { FC } from 'react'

export const ExportPage: FC = () => {
  return (
    <Routes>
      <Route index element={<ExportList />}></Route>
      <Route path={ExportRoutes.relativeDetail} element={<ExportDetail />}></Route>
    </Routes>
  )
}
