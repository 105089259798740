import { labelQuantity } from 'modules/utils/numbers/formatQuantity'
import { TableTextLink } from 'views/ui/TableTextLink/TableTextLink'
import { BidContract, BidTypes } from 'modules/domain/bid/types'
import * as renderers from 'views/components/Table/renderers'
import { CompanyCell } from 'views/pages/Bid/BidTable/styled'
import { labelTarif } from 'modules/utils/numbers/formatTarif'
import { DateFormatted } from 'views/components/DateFormatted'
import { Text } from '@agro-club/agroclub-shared'
import DealRoutes from 'views/pages/Deal/routes'
import { useTranslation } from 'react-i18next'
import BidContractsRoutes from '../routes'
import { generatePath } from 'react-router'
import { ColumnData } from 'modules/types'
import styled from 'styled-components'
import { useMemo } from 'react'
import { ContractDeliveryPeriod } from './ContractDeliveryPeriod'

const DealLink = styled.div`
  display: flex;
  flex-direction: column;
`

export const useColumns = (contract: BidContract): ColumnData[] => {
  const { t } = useTranslation('bidContracts')
  const isPurchaseContract = contract.bid_type === BidTypes.purchase
  const indexColumn = isPurchaseContract ? 'sale_contract' : 'purchase_contract'
  return useMemo(
    () => [
      {
        title: t('deal'),
        width: 130,
        align: 'left',
        dataIndex: 'id',
        render: (id, data) => (
          <DealLink>
            <TableTextLink id="deal" tableItemId={id} to={generatePath(DealRoutes.Details, { id })} target="_blank">
              {`ID ${id}`}
            </TableTextLink>
            <Text typography="bodySmall" color="secondary">{`${t(`deal:statuses.${data.status}`)}`}</Text>
          </DealLink>
        ),
      },
      {
        title: isPurchaseContract ? t('saleNumberContract') : t('purchaseNumberContract'),
        width: 117,
        align: 'left',
        dataIndex: indexColumn,
        render: (data) => (
          <>
            {data?.id && (
              <TableTextLink
                id="number"
                tableItemId={data.id}
                to={generatePath(BidContractsRoutes.Details, { id: data.id })}
                target="_blank"
              >
                {data.number}
              </TableTextLink>
            )}
          </>
        ),
      },
      {
        title: t('company'),
        width: 168,
        align: 'left',
        dataIndex: indexColumn,
        render: (data) => <>{data?.company && <CompanyCell id={data.company.id} name={data.company.name} />}</>,
      },

      {
        title: labelQuantity(),
        width: 98,
        align: 'right',
        dataIndex: 'quantity',
        render: renderers.number,
      },
      {
        title: labelTarif(t('purchasePrice')),
        width: 164,
        align: 'right',
        dataIndex: 'purchase_price',
        render: renderers.price,
      },
      {
        title: labelTarif(t('salePrice')),
        width: 155,
        align: 'right',
        dataIndex: 'sale_price',
        render: renderers.price,
      },
      {
        title: t('shipping'),
        width: 110,
        align: 'left',
        dataIndex: indexColumn,
        render: (data) => (
          <ContractDeliveryPeriod
            shipment_due_date={data?.shipment_due_date}
            shipment_from_date={data?.shipment_from_date}
          />
        ),
      },
      {
        title: t('payment'),
        width: 98,
        align: 'left',
        dataIndex: indexColumn,
        render: (data) => <DateFormatted date={data?.payment_due_date} />,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t],
  )
}
