import { CountryCodeEntry } from '@agro-club/agroclub-shared'

import { InstanceIDs, REACT_APP_INSTANCE_ID } from 'env'

const BR = {
  id: 'BR',
  name: 'BR',
  phone_prefix: '55',
}

const CA = {
  id: 'CA',
  name: 'CA',
  phone_prefix: '1',
}

const ES = {
  id: 'ES',
  name: 'ES',
  phone_prefix: '34',
}

const US = {
  id: 'US',
  name: 'US',
  phone_prefix: '1',
}

const RU = {
  id: 'RU',
  name: 'RU',
  phone_prefix: '7',
}

const countryCodes = {
  [InstanceIDs.RU]: [RU],
  [InstanceIDs.BR]: [BR, ES, RU, US],
  [InstanceIDs.US]: [US, ES, CA, BR, RU],
}

export const getCountryCodes = (): CountryCodeEntry[] =>
  countryCodes[REACT_APP_INSTANCE_ID as string] || countryCodes[InstanceIDs.RU]

export const getDefaultCountryCode = () => getCountryCodes()[0].id
