import { FC, Fragment } from 'react'

import { AntTooltip, AntTooltipProps, Button, ButtonFullProps } from '@agro-club/agroclub-shared'
import { useAnalyticsClickHandler } from 'analytics/hooks'
import { AnalyticsPayload } from 'analytics/types'

export interface AButtonProps extends Omit<ButtonFullProps, 'id'> {
  id: string
  analyticsPayload?: AnalyticsPayload
  tooltipProps?: AntTooltipProps
}

const AButton: FC<AButtonProps> = ({ id, children, onClick, analyticsPayload, tooltipProps, ...otherProps }) => {
  const handleClick = useAnalyticsClickHandler(id)
  const Wrapper = tooltipProps ? AntTooltip : Fragment
  const wrapperProps = (
    tooltipProps
      ? {
          variant: 'secondary',
          placement: 'topLeft',
          ...tooltipProps,
        }
      : {}
  ) as AntTooltipProps

  return (
    <Wrapper {...wrapperProps}>
      <Button onClick={handleClick(onClick, analyticsPayload)} data-test-id={id} {...otherProps}>
        {children}
      </Button>
    </Wrapper>
  )
}

export default AButton
