import { FC } from 'react'
import { maxColumnWidth } from './styled'
import { useTranslation } from 'react-i18next'
import { Card } from 'views/components/Card/Card'
import formatPrice from 'modules/utils/numbers/formatPrice'
import { KeyValue } from 'views/components/KeyValue/KeyValue'
import { Specification } from 'modules/domain/specification/types'
import { formatQuantity } from 'modules/utils/numbers/formatQuantity'
import useFormatDate from 'hooks/useFormatDate'
import APhone from 'views/ui/Phone/APhone'
import { UserLinkWithIcons } from 'views/components/UserLinkWithIcons/UserLinkWithIcons'
import { DASH } from 'modules/constants'

interface Props {
  specification: Specification
}

export const GeneralInformation: FC<Props> = ({ specification }) => {
  const { t } = useTranslation('specification')
  const formatDate = useFormatDate()
  return (
    <Card.Container>
      <Card.Grid cols={2} maxColumnWidth={'548px'}>
        <Card.GapableContent>
          <Card.Title>{t('specification_information')}</Card.Title>
          <Card.Grid cols={3} maxColumnWidth={maxColumnWidth}>
            <KeyValue label={t('createDate')}>{formatDate(specification.signed_date)}</KeyValue>
            <KeyValue label={t('form.signed_date')}>{formatDate(specification.signed_date)}</KeyValue>
            <KeyValue label={t('table.team')}>{specification?.owner?.profile?.team?.name}</KeyValue>
          </Card.Grid>
          <KeyValue label={t('common:user')}>
            <UserLinkWithIcons
              user={specification?.owner}
              isVerified={specification?.owner?.profile?.is_verified}
              hasShippedDeals={specification?.owner?.profile?.has_shipped_deals}
            />
            <APhone phone={specification.owner.phone || ''} />
            <div>{specification.address.address}</div>
          </KeyValue>
          <Card.Grid cols={3} maxColumnWidth={maxColumnWidth}>
            <KeyValue label={t('table.product')}>{specification.product.title}</KeyValue>
            <KeyValue label={t('priceWithTax')}>{formatPrice(specification?.price, true)}</KeyValue>
            <KeyValue label={t('totalQuantity')}>{formatQuantity(specification.quantity)}</KeyValue>
            <KeyValue label={t('quantityShipping')}>{formatQuantity(specification?.quantity_shipping ?? 0)}</KeyValue>
            <KeyValue label={t('brought')}>{formatQuantity(specification?.quantity_shipped ?? 0)}</KeyValue>
            <KeyValue label={t('remaining')}>{formatQuantity(specification?.quantity_left ?? 0)}</KeyValue>
            <KeyValue label={t('table.term')}>{formatDate(specification.deadline)}</KeyValue>
          </Card.Grid>
        </Card.GapableContent>

        <div>
          <Card.GapableContent>
            <Card.Title>{t('contract_information')}</Card.Title>
            <Card.Grid cols={2} maxColumnWidth={maxColumnWidth}>
              <KeyValue label={t('contractNumber')}>{specification.contract.number || DASH}</KeyValue>
              <KeyValue label={t('form.signed_date')}>
                {formatDate(specification.contract.signed_date) || DASH}
              </KeyValue>
            </Card.Grid>
          </Card.GapableContent>
        </div>
      </Card.Grid>
    </Card.Container>
  )
}

export default GeneralInformation
