import { FC, useState } from 'react'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router'

import { Checkbox, FormGroup, Input } from '@agro-club/agroclub-shared'
import {
  FooterButtons,
  FormInputs,
  FormWrapper,
  InputWithCheckbox,
} from 'views/pages/LogisticsKR/components/CarsForm/styled'
import { SimpleOptionsSelect } from 'views/pages/LogisticsKR/components/CarsForm/SimpleOptionsSelect'
import { NumberInput } from 'views/ui/NumberInput/NumberInput'
import { ShortField } from 'views/pages/LogisticsKR/components/styled'
import { BoolRadio } from 'views/components/BoolRadio/BoolRadio'

import CarsKRRoutes from 'views/pages/LogisticsKR/CarriersKR/routes'

import { apiClient } from 'modules/utils/httpClient'
import { endpoints } from 'modules/endpoints'
import { UnloadingType, VehicleType } from 'views/pages/LogisticsKR/types'
import { AddTrailerModal } from 'views/pages/LogisticsKR/components/TrailerModals/AddTrailerModal'
import { AButton } from 'views/components/Analytics'
import { useAFormHandler } from 'analytics/hooks'
import { TrailerSelect } from '../../components/TrailerSelect/TrailerSelect'

interface Props {
  carrierId: string
}

export const CarsAddForm: FC<Props> = ({ carrierId }) => {
  const { t } = useTranslation('logisticsKR')
  const navigate = useNavigate()
  const pathBack = generatePath(CarsKRRoutes.Details, { id: carrierId })
  const [isAddTrailerOpen, setIsAddTrailerOpen] = useState<boolean>(false)
  const { formProgress, formHandler } = useAFormHandler()

  const formik = useFormik({
    initialValues: {
      carrier: carrierId,
      brand_model: null,
      licence_number: null,
      car_type: null,
      unloading_type: null,
      side_height: null,
      is_dump_truck: false,
      has_trailer: false,
      trailer: null,
    },
    onSubmit: formHandler(
      async () => await apiClient.post(endpoints.LogisticsKR.car(), formik.values),

      {
        onSuccess: () => navigate(pathBack),
        onError: (error) => {
          const { errors } = error
          formik.setErrors(errors)
        },
      },
    ),
  })

  return (
    <FormWrapper>
      <FormInputs>
        <FormGroup error={formik.errors.brand_model} label={t('carsEdit.form.labels.brand_model')}>
          <Input
            placeholder={t('carsEdit.form.placeholders.brand_model')}
            {...formik.getFieldProps('brand_model')}
            invalid={!!formik.errors.brand_model}
          />
        </FormGroup>

        <FormGroup error={formik.errors.licence_number} label={t('carsEdit.form.labels.licence_number')}>
          <InputWithCheckbox>
            <ShortField>
              <Input
                placeholder={t('carsEdit.form.placeholders.licence_number')}
                {...formik.getFieldProps('licence_number')}
                invalid={!!formik.errors.licence_number}
              />
            </ShortField>

            <Checkbox
              isChecked={formik.values.has_trailer}
              onChange={(_value, isChecked) => {
                formik.setFieldValue('has_trailer', isChecked)
              }}
              label={t('carsEdit.form.labels.has_trailer')}
            />
          </InputWithCheckbox>
        </FormGroup>

        {formik.values.has_trailer && <TrailerSelect carrierId={carrierId} formik={formik} />}

        <SimpleOptionsSelect
          formik={formik}
          fieldName="unloading_type"
          EnumType={UnloadingType}
          label={t('carsEdit.form.labels.unloading_type')}
          optionsTranslations="logisticsKR:directions"
        />

        <SimpleOptionsSelect
          formik={formik}
          fieldName="car_type"
          EnumType={VehicleType}
          label={t('carsEdit.form.labels.car_type')}
          optionsTranslations="logisticsKR:vehicle_type"
        />

        <FormGroup error={formik.errors.side_height} label={t('carsEdit.form.labels.side_height')}>
          <ShortField>
            <NumberInput
              placeholder={t('carsEdit.form.placeholders.side_height')}
              {...formik.getFieldProps('side_height')}
              onChange={(value) => formik.setFieldValue('side_height', value)}
              invalid={!!formik.errors.side_height}
            />
          </ShortField>
        </FormGroup>

        <BoolRadio formik={formik} label={t('carsEdit.form.labels.is_dump_truck')} fieldName="is_dump_truck" />
      </FormInputs>

      <FooterButtons>
        <AButton id="save" intent="primary" progress={formProgress} onClick={() => formik.handleSubmit()} size="big">
          {t('common:save')}
        </AButton>
        <AButton id="cancel" intent="secondary" size="big" to={pathBack}>
          {t('common:cancel')}
        </AButton>
      </FooterButtons>
      {isAddTrailerOpen && (
        <AddTrailerModal
          carrierId={carrierId}
          setIsOpen={setIsAddTrailerOpen}
          onSuccess={(id) => formik.setFieldValue('trailer', id)}
        />
      )}
    </FormWrapper>
  )
}
