import FormSelectShared from '../FormSelectShared'
import { useTranslation } from 'react-i18next'
import { endpoints } from 'modules/endpoints'
import { FC } from 'react'
import { FormikFieldsType } from 'modules/domain/types'

interface Props {
  formik: FormikFieldsType
}

export const FormTeam: FC<Props> = ({ formik }) => {
  const { t } = useTranslation()

  return (
    <FormSelectShared
      endpoint={endpoints.allTeams()}
      label={t('common:team')}
      showSearch={false}
      placeholder=""
      fieldName="team"
      formik={formik}
    />
  )
}
