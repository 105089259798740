import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { FormGroup, Input } from '@agro-club/agroclub-shared'
import { ShortFormControl } from 'views/styled/common'
import { FormikFieldsType } from 'modules/domain/types'

type Props = {
  formik: FormikFieldsType
  isEditModal?: boolean
}

export const DriverModalContent: FC<Props> = ({ formik, isEditModal }) => {
  const { t } = useTranslation('logisticsKR')

  return (
    <>
      <FormGroup error={formik.errors.name} label={t('carsDetails.addForm.labels.name')} required>
        <Input
          placeholder={t('carsDetails.addForm.placeholders.name')}
          {...formik.getFieldProps('name')}
          invalid={!!formik.errors.name}
        />
      </FormGroup>
      <ShortFormControl>
        <FormGroup error={formik.errors.phone} label={t('carsDetails.addForm.labels.phone')} required={!isEditModal}>
          <Input
            type="tel"
            placeholder={t('carsDetails.addForm.placeholders.phone')}
            {...formik.getFieldProps('phone')}
            invalid={!!formik.errors.phone}
          />
        </FormGroup>
      </ShortFormControl>
    </>
  )
}
