import { IconInstructions } from '../../Board/styled'
import { AntTag } from '@agro-club/agroclub-shared'
import { Deal } from 'modules/domain/deal/types'
import { useTranslation } from 'react-i18next'
import { FC } from 'react'

interface Props {
  deal: Deal
}

export const InstructionsTag: FC<Props> = ({ deal }) => {
  const { t } = useTranslation()
  return (
    <AntTag
      icon={<IconInstructions $approve={deal.has_instructions} />}
      color={deal.has_instructions ? 'blue' : 'grey'}
    >
      {t('deal:instructionTagText')}
    </AntTag>
  )
}
