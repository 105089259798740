import { t } from 'i18next'
import { ListRequestParams } from 'modules/domain/types'
import { endpoints } from 'modules/endpoints'
import { BaseTableFilterProps } from '@agro-club/agroclub-shared'
import { AnalyticsFilterVariant } from 'analytics/types'
import { FC, useEffect } from 'react'
import { isUS } from 'env'
import { ABaseTableFilter } from 'views/components/TableFilters/ABaseTableFilter'
import _ from 'lodash'

interface Props extends Omit<BaseTableFilterProps, 'value' | 'placeholder' | 'endpoint' | 'onChange'> {
  listRequestParams: ListRequestParams
  updateFilterState: (filterObj: Record<string, any>, commaShieldingParam?: string) => void
}

export const RegionsFilter: FC<Props> = ({ listRequestParams, updateFilterState, ...otherProps }) => {
  const placeholder = isUS ? t('regionFilter:usPlaceholder') : t('regionFilter:placeholder')

  const handleChange = (id) => {
    const newValue = _.toString(id)

    updateFilterState({ id: newValue })
  }

  useEffect(() => {
    const parseIds = () => {
      const parsedIds = _.toString(listRequestParams.filter.id)

      updateFilterState({ id: parsedIds })
    }

    parseIds()
  }, [listRequestParams.filter.id, updateFilterState])

  return (
    <ABaseTableFilter
      onChange={handleChange}
      value={listRequestParams.filter.id ?? []}
      placeholder={placeholder}
      endpoint={endpoints.provinces()}
      searchField="name"
      simpleApiFormat
      id={AnalyticsFilterVariant.REGION}
      multiple
      {...otherProps}
    />
  )
}
