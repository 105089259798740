import { useCallback, useState } from 'react'

import { Progress } from '@agro-club/agroclub-shared'

import { apiClient } from 'modules/utils/httpClient'
import { useSingleEntity } from 'modules/domain/common/hooks'

export type Comment = {
  id: string
  comment: string
  author: string
  created: string
  is_pinned: boolean
  modified: string
  last_modified_author: string
}

export const useComments = (url: string, onSuccess: () => void, commentId?: string) => {
  const [sendProgress, setSendProgress] = useState<Progress>(Progress.IDLE)
  const [getListProgress, comments = [], refetch] = useSingleEntity<Comment[]>(url)

  const addComment = useCallback(
    async (comment: string, isPin?: boolean) => {
      try {
        setSendProgress(Progress.WORK)
        const resp = await apiClient.post<Comment>(url, { comment })

        if (isPin && resp.id) {
          await apiClient.put<Comment>(`${url}${resp.id}/pin/`)
        }
        setSendProgress(Progress.SUCCESS)
        refetch(true)
        onSuccess()
      } catch (e) {
        setSendProgress(Progress.ERROR)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [url],
  )

  const editPinnedComment = async (comment: string) => {
    try {
      setSendProgress(Progress.WORK)
      await apiClient.put<Comment>(`${url}${commentId}/`, { comment })
      setSendProgress(Progress.SUCCESS)
      refetch(true)
      onSuccess()
    } catch (e) {
      setSendProgress(Progress.ERROR)
    }
  }

  return {
    addComment,
    comments,
    sendProgress,
    getListProgress,
    editPinnedComment,
  }
}
