import { Company } from 'modules/domain/company/types'
import { Address, Product } from 'modules/domain/types'
import { User } from 'modules/domain/user/types'
import { DateISO } from '@agro-club/agroclub-shared'
import { Deal } from '../deal/types'

export enum USContractType {
  basis = 'basis',
  cash_priced = 'cash_priced',
}

export enum BidKind {
  potential = 'potential',
  regular = 'regular',
  futures = 'futures',
  basis = 'basis',
  cash_priced = 'cash_priced',
}

export type BidParameter = {
  parameter: {
    id?: string
    name?: string
  }
  condition_label?: string
  parameter_value?: number
  description: string
  id?: string
  parameters_description?: string
  parameter_value_from?: number
  parameter_value_to?: number
}

export type BidPricePeriod = {
  id?: string
  start_date: DateISO | null
  end_date: DateISO | null
  status: BidStatus
  price: number | null
  created?: string
  modified?: string
  tax_from_exw?: number
  quantity?: number
}

export enum BidStatus {
  published = 'published',
  archived = 'archived',
  potential = 'potential',
  completed = 'completed',
}

export enum BidTypes {
  sale = 'sale',
  purchase = 'purchase',
}

export enum BidPrice {
  CPT = 'CPT',
  EXW = 'EXW',
}

export enum USDeliveryOptions {
  Delivered = 'Delivered',
  FOB_Farm = 'FOB Farm',
}

export type Bid = {
  id: string
  bid_type: BidTypes
  start_date: string | null
  end_date: string | null
  product: Product
  parameters: BidParameter[]
  parameters_description: string
  status: BidStatus
  has_samples: boolean
  last_comment?: {
    text: string
    author: string
    created: string
  } | null
  quantity: number
  remaining_quantity: number
  created: string
  created_by?: {
    full_name: string
    id: number
    is_active: boolean
    phone: string
  }
  modified: string
  price: number
  price_type: BidPrice
  tax_from_exw?: number
  address: Address
  distance: number
  company: Company
  contracts?: BidContract[]
  owner: User
  owner_is_significant?: boolean
  owner_is_verified_by_coordinator?: boolean
  owner_is_verified_by_security?: boolean
  owner_is_verified_by_kyc?: boolean
  owner_is_verified?: boolean
  has_files?: boolean
  aground_imputs?: AgroundImputs
  bid_tag?: BidTags
  price_periods?: BidPricePeriod[]
  parent_id?: string
  owner_delivery_address?: Address
  source: string
  season?: Season
  is_for_warehouse?: boolean
}

export type USBid = Bid & {
  symbol: string
  basis: number
  futures_price: number
  basis_expiration: DateISO
  expires_at: DateISO
  owner_delivery_distance: number
  contract_type: USContractType
  hedge_ticket: string | null
  is_firm: boolean
}

export type BidParameterDTO = {
  parameter?: string
  parameter_value_from?: number
  parameter_value_to?: number
}

export enum UIBidTypes {
  purchase = 'purchase',
  sale = 'sale',
  potential = 'potential',
}

export enum AgroundImputs {
  YES = 'yes',
  NO = 'no',
}

export enum BidTags {
  EXPORT = 'export',
  DEFERRED = 'deferred',
  TAXED = 'taxed',
}

export interface Season {
  id: string
  name: string
  product: string
  startDate: string
  end_date: string
}

export type BidAddFields = {
  company?: string
  owner?: string
  product?: string
  price?: number
  price_type?: BidPrice
  quantity?: number
  address?: string
  mainParameter: BidParameterDTO
  parameters: BidParameterDTO[]
  price_periods?: BidPricePeriod[]
  aground_imputs?: AgroundImputs
  bid_tag?: BidTags
  owner_delivery_address?: number | undefined
  season?: string
}

export type BidEditableFields = {
  price?: number
  quantity?: number
  mainParameter: BidParameterDTO
  parameters: BidParameterDTO[]
  price_periods?: BidPricePeriod[]
  season?: string
}

export type PotentialBidEditableFields = {
  price?: number
  quantity?: number
  parameters: BidParameterDTO[]
  price_periods?: BidPricePeriod[]
}

export enum BidSources {
  INTERNAL = 'internal',
  BARCHART = 'barchart',
}

export type BidContract = Omit<Bid, 'contracts'> & {
  bid_id: string
  number: string
  signed_at: DateISO
  payment_due_date: DateISO
  shipment_due_date: DateISO
  shipment_from_date: DateISO
  rematching_quantity: number
  deal_ids: string[]
  deals?: Deal[]
  payment_type?: ContractPaymentType
  payment_hour_period?: string
  payment_volume_period?: string
  payment_type_description: string
}

export type MatchingBidContract = BidContract & {
  deal_preview?: Deal
}

export type BidContractTotalsItem = {
  slug: string
  title: string
  quantity: number
  remaining_quantity: number
  rematching_quantity: number
}

export enum UIContractTypes {
  purchase = 'purchase',
  sale = 'sale',
}

export enum ContractPaymentType {
  at_gate = 'at_gate',
  before_loading = 'before_loading',
  fix_date = 'fix_date',
  periodic_parametrized = 'periodic_parametrized',
}

export enum PositionStatus {
  OPEN = 'open',
  CLOSED = 'closed',
}

export enum PositionType {
  LONG = 'long',
  SHORT = 'short',
}

export type Position = {
  bid: USBid
  id: string
  position_status: PositionStatus
  position_type: PositionType
  quantity: string
  remaining_quantity: string
}
