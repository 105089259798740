import { Progress } from '@agro-club/agroclub-shared'
import { refetchFunc, useSingleEntity } from 'modules/domain/common/hooks'
import { Deal } from 'modules/domain/deal/types'
import { WarehouseMovement } from 'modules/domain/warehouses/types'
import { endpoints } from 'modules/endpoints'
import { createContext, useContext, useMemo } from 'react'
import { useParams } from 'react-router'
import ItemLoadingLayout from 'views/layouts/ItemLoadingLayout/ItemLoadingLayout'
import { useWarehouseMovements } from './useWarehouseMovements'

export type WhMovements = {
  list: WarehouseMovement[]
  last: WarehouseMovement
  listWithoutLast: WarehouseMovement[]
  refetch: refetchFunc
  progress: Progress
}

interface DealContextType {
  deal: Deal
  dealRefetch: refetchFunc
  whMovements: WhMovements
}

export const DealContext = createContext<DealContextType>({
  // we dont allow deal be empty to avoid existings checks
  deal: {} as Deal,
  dealRefetch: async () => {
    return undefined
  },
  whMovements: {} as WhMovements,
})

export const useDealContext = () => {
  const context = useContext(DealContext)
  if (!context.deal.id) {
    throw new Error('DealContext outside of Provider')
  }

  return context
}

export function DealProvider({ children }: any) {
  const { id } = useParams()
  const [, deal, dealRefetch] = useSingleEntity<Deal>(endpoints.deal(id))
  const { progress, list, last, listWithoutLast, refetch } = useWarehouseMovements(
    deal?.has_warehouse_movements ? deal?.id : undefined,
  )

  const loading = () => <ItemLoadingLayout id={id} />

  const whMovements = useMemo(
    () => ({ progress, list, last, listWithoutLast, refetch }),
    [last, list, listWithoutLast, progress, refetch],
  )

  if (!deal) {
    return loading()
  }

  return <DealContext.Provider value={{ deal, dealRefetch, whMovements }}>{children}</DealContext.Provider>
}
