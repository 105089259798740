import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { FormGroup, Select } from '@agro-club/agroclub-shared'

import { BidAddFields, BidTags } from 'modules/domain/bid/types'
import { ShortFormControl } from 'views/styled/common'
import { FormikProps } from 'formik'
import { TaxesFormFields } from '../../BidModals/Bid/BidTaxesModal'

type Props = {
  formik: FormikProps<BidAddFields> | FormikProps<TaxesFormFields>
}

export const BidTagSelect: FC<Props> = ({ formik }) => {
  const { t } = useTranslation('bid')

  return (
    <ShortFormControl>
      <FormGroup label={t('bidTag.label')} error={formik.errors.bid_tag}>
        <Select
          onChange={(val) => formik.setFieldValue('bid_tag', val)}
          options={[
            { value: BidTags.EXPORT, label: t('bidTag.export') },
            { value: BidTags.DEFERRED, label: t('bidTag.deferred') },
            { value: BidTags.TAXED, label: t('bidTag.taxed') },
          ]}
          value={formik.values.bid_tag}
          showSearch={false}
          allowClear={false}
          fullWidth
        />
      </FormGroup>
    </ShortFormControl>
  )
}
