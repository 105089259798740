import { REACT_APP_CURRENCY_SYMBOL } from 'env'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { FlexColumnWrapper } from '../styled'

interface Props {
  name: string
  commission: number
}

export const AgentCell: FC<Props> = ({ name, commission }) => {
  const { t } = useTranslation('logisticsKR')
  const currency = REACT_APP_CURRENCY_SYMBOL

  return (
    <FlexColumnWrapper>
      <div>{name}</div>
      <div>{t('details.agent_commission', { agent_commission: commission, currency })}</div>
    </FlexColumnWrapper>
  )
}
