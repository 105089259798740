import styled from 'styled-components'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { apiClient } from 'modules/utils/httpClient'
import { endpoints } from 'modules/endpoints'
import { CarStatuses } from '../../../types'
import { Icons } from '@agro-club/agroclub-shared'
import { SharedModalContent, SharedModalTitle } from 'views/components/SharedModal'
import { CarEditModal } from '../../CarEditModal/CarEditModal'
import { ListRequestParams } from 'modules/domain/types'
import { useNotificationProgress } from 'hooks/useNotificationProgress'
import { AButton } from 'views/components/Analytics'
import ASharedModal from 'views/components/SharedModal/ASharedModal'
import { AnalyticsPlaces } from 'views/pages/LogisticsKR/analyticsPlaces'
import ADefaultModalFooter from 'views/components/DefaultModalFooter/ADefaultModalFooter'
import { useAProgress } from 'hooks/useAProgress'

const ButtonWrapper = styled.div`
  display: flex;
  column-gap: 8px;
`

type ActionsCellProps = {
  data: any
  setListRequestParams: (ListRequestParams) => void
  listRequestParams: ListRequestParams
}
export const ActionsCell: FC<ActionsCellProps> = ({ data, setListRequestParams, listRequestParams }) => {
  const { t } = useTranslation('logisticsKR')
  const { ready_for_active, status, id } = data

  const [editModal, setEditModal] = useState(false)
  const [archivedConfirmModal, setArchivedConfirmModal] = useState(false)
  const [changeStatusConfirmModal, setChangeStatusConfirmModal] = useState(false)

  const handleChangeStatusToArchived = async (carId: string) => {
    await apiClient.post(endpoints.LogisticsKR.carStatus(carId), { new_status: CarStatuses.archived })
    setArchivedConfirmModal(false)
    setListRequestParams({ ...listRequestParams })
  }

  const handleChangeStatusToActive = async (carId: string) => {
    await apiClient.post(endpoints.LogisticsKR.carStatus(carId), { new_status: CarStatuses.active })
    setChangeStatusConfirmModal(false)
    setListRequestParams({ ...listRequestParams })
  }

  const [changeStatusToArchivedProgress, changeStatusToArchived] = useAProgress(handleChangeStatusToArchived, {
    eventName: 'setStatusToArchived',
  })
  const [changeStatusToActiveProgress, changeStatusToActive] = useAProgress(handleChangeStatusToActive, {
    eventName: 'setStatusToActive',
  })

  useNotificationProgress(
    changeStatusToArchivedProgress,
    t('carsEdit.notifications.car_archived_success'),
    t('carsEdit.notifications.car_archived_error'),
  )

  useNotificationProgress(
    changeStatusToActiveProgress,
    t('carsEdit.notifications.car_active_success'),
    t('carsEdit.notifications.car_active_error'),
  )

  return (
    <ButtonWrapper>
      {status === CarStatuses.draft && (
        <AButton
          id="changeStatus"
          intent="secondary"
          size="small"
          Icon={Icons.IconCheck}
          onClick={() => setChangeStatusConfirmModal(true)}
        />
      )}
      {status !== CarStatuses.archived && (
        <AButton id="edit" intent="secondary" size="small" Icon={Icons.IconEdit} onClick={() => setEditModal(true)} />
      )}
      {status !== CarStatuses.archived && (
        <AButton
          id="archive"
          analyticsPayload={{ id }}
          intent="destruct"
          progress={changeStatusToArchivedProgress}
          size="small"
          Icon={Icons.IconDelete}
          onClick={() => setArchivedConfirmModal(true)}
        />
      )}
      {archivedConfirmModal && (
        <ASharedModal
          id={AnalyticsPlaces.CARS.ARCHIVE_MODAL}
          size="small"
          onClose={() => setArchivedConfirmModal(false)}
        >
          <SharedModalTitle>{t('carsDetails.archiveForm.title')}</SharedModalTitle>
          <SharedModalContent>{t('carsDetails.archiveForm.text')}</SharedModalContent>
          <ADefaultModalFooter
            onClose={() => setArchivedConfirmModal(false)}
            onConfirm={() => changeStatusToArchived(id)}
            confirmButtonText={t('common:archive')}
          />
        </ASharedModal>
      )}
      {editModal && (
        <CarEditModal
          car={data}
          onClose={() => setEditModal(false)}
          setListRequestParams={setListRequestParams}
          listRequestParams={listRequestParams}
        />
      )}
      {changeStatusConfirmModal && (
        <ASharedModal
          id={AnalyticsPlaces.CARS.CHANGE_STATUS_MODAL}
          size="small"
          onClose={() => setChangeStatusConfirmModal(false)}
        >
          <SharedModalTitle>{t('cars.confirmModals.statusChangingTitle')}</SharedModalTitle>
          <SharedModalContent>
            {ready_for_active
              ? t('cars.confirmModals.youReallyWantToChangeStatus')
              : t('cars.confirmModals.allFieldsMustBeFilledIn')}
          </SharedModalContent>
          <ADefaultModalFooter
            progress={changeStatusToActiveProgress}
            disabled={!ready_for_active}
            onConfirm={() => changeStatusToActive(id)}
            onClose={() => setChangeStatusConfirmModal(false)}
          />
        </ASharedModal>
      )}
    </ButtonWrapper>
  )
}
