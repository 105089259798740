import { FC } from 'react'
import { SharedModalInputs, SharedModalTitle } from 'views/components/SharedModal'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import { apiClient } from 'modules/utils/httpClient'
import { endpoints } from 'modules/endpoints'
import ASharedModal from 'views/components/SharedModal/ASharedModal'
import { AnalyticsPlaces } from 'views/pages/LogisticsKR/analyticsPlaces'
import ADefaultModalFooter from 'views/components/DefaultModalFooter/ADefaultModalFooter'
import { useAFormHandler } from 'analytics/hooks'
import { Car, CarStatuses } from 'views/pages/LogisticsKR/types'
import { CarModalContent } from 'views/pages/LogisticsKR/components/CarModalContent/CarModalContent'

type Props = {
  onClose: () => void
  carrierId: string
  setValue: (val: string) => void
}

export const AddNewCarModal: FC<Props> = ({ onClose, carrierId, setValue }) => {
  const { t } = useTranslation('logisticBids')
  const { formProgress, formHandler } = useAFormHandler('addNewCar')

  const formik = useFormik({
    initialValues: {
      carrier: carrierId,
      status: CarStatuses.active,
      has_trailer: false,
      brand_model: '',
      licence_number: '',
      unloading_type: '',
      side_height: '',
      is_dump_truck: false,
    },

    onSubmit: formHandler(
      async () => {
        const response = await apiClient.post<Car>(endpoints.LogisticsKR.car(), formik.values)
        if (response?.id) {
          setValue(response.id)
        }
        onClose()
      },
      {
        onError: (error) => {
          const { errors } = error
          formik.setErrors(errors)
        },
      },
    ),
  })

  return (
    <ASharedModal id={AnalyticsPlaces.CARS.ADD_MODAL} onClose={() => onClose()}>
      <SharedModalTitle>{t('form.titles.add_car')}</SharedModalTitle>
      <SharedModalInputs>
        <CarModalContent formik={formik} showTrailer={false} />
      </SharedModalInputs>
      <ADefaultModalFooter progress={formProgress} onClose={() => onClose()} onConfirm={() => formik.submitForm()} />
    </ASharedModal>
  )
}
