import { ReactComponent as IconNotFound } from '../../../assets/icons/notFound.svg'
import { Copyright, Description, LinkDashboard, Wrapper } from './style'
import { useTranslation } from 'react-i18next'
import { FC } from 'react'
import DashboardRoutes from '../Dashboard/routes'

const NotFound: FC = () => {
  const { t } = useTranslation('errors')
  return (
    <Wrapper>
      <IconNotFound />
      <Description>
        <div style={{ margin: '56px 0 8px 0' }}> {t('404.not_found_page')}</div>
        <div>
          {t('404.return')} <LinkDashboard to={DashboardRoutes.Root}>{t('404.dashboard')}</LinkDashboard>
        </div>
      </Description>
      <Copyright>© Agro.Club, Inc.</Copyright>
    </Wrapper>
  )
}
export default NotFound
