import { TeamsPlansByMembers } from 'modules/domain/teams/types'
import { AntdTable, Progress } from '@agro-club/agroclub-shared'
import { FC } from 'react'
import { useTeamColumns } from 'views/pages/Teams/useTeamColumns'

interface Props {
  membersList: TeamsPlansByMembers[]
  progress: Progress
}

export const SellerTable: FC<Props> = ({ progress, membersList }) => {
  const columns = useTeamColumns()

  return (
    <AntdTable
      columns={columns}
      data={membersList}
      progress={progress}
      disablePagination={true}
      tableLayout="auto"
      bordered
      rowKey="team"
    />
  )
}
