import { FC } from 'react'
import { FormikProps } from 'formik'
import { BidAddFields } from 'modules/domain/bid/types'
import { PotentialBidAddFields } from 'modules/domain/potentialBid/types'
import { useTranslation } from 'react-i18next'
import { ProfileType, User } from 'modules/domain/user/types'
import { FormCompany } from 'views/components/form/FormCompany'
import { endpoints } from 'modules/endpoints'
import FormSelectShared from 'views/components/FormSelectShared'
import { getUserName } from 'modules/domain/user/utils'
import { FormAddress } from 'views/components/form/FormAddress/FormAddress'
import { isUS } from 'env'

type Props = {
  formik: FormikProps<BidAddFields> | FormikProps<PotentialBidAddFields>
  setOwnerProfileType?: (profile?: ProfileType) => void
}

export const CommonBidModalControls: FC<Props> = ({ formik, setOwnerProfileType }) => {
  const { t } = useTranslation('bid')
  const userId = formik.values.owner
  const companyId = formik.values.company

  const getEmployeeLabel = (u: User) => {
    const profileType = u?.profile?.profile_type
    const userName = getUserName(u)
    if (!profileType) {
      return userName
    }
    const profileTypeTranslated = t(`user:profile_type.${profileType}`)
    return `${userName} (${profileTypeTranslated})`
  }

  const onChangeCompany = (company: string) => {
    formik.setFieldValue('company', company)
    setOwnerProfileType?.()
    onChangeUser()
  }

  const onChangeUser = (owner?: string) => {
    formik.setFieldValue('owner', owner)
    formik.setFieldValue('address', null)
    if (isUS) {
      formik.setFieldValue('is_firm', false)
      formik.setFieldValue('hedge_ticket', undefined)
    }
  }

  return (
    <>
      <FormCompany required formik={formik} onChange={onChangeCompany} showInn />
      <FormSelectShared
        label={t('form.user')}
        placeholder={t('form.selectEmployeePlaceholder')}
        formik={formik}
        endpoint={companyId && endpoints.activeEmployees(companyId)}
        simpleApiFormat
        getLabel={(dto) => getEmployeeLabel(dto)}
        onChange={onChangeUser}
        onSelectedOptionLoaded={(e) => setOwnerProfileType?.(e?.dto?.profile?.profile_type)}
        fieldName="owner"
        showSearch={false}
        disabled={!companyId}
        required
      />
      {userId && (
        <FormAddress label={t('bid:form.address')} disabled={!userId} formik={formik} userId={userId} required />
      )}
    </>
  )
}
