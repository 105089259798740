import { FC } from 'react'
import { ItemCard, CardContentWrapper, TextCard } from 'views/styled/ItemCardStyles'
import { useTranslation } from 'react-i18next'
import { formatQuantity } from 'modules/utils/numbers/formatQuantity'
import APhone from 'views/ui/Phone/APhone'
import { LogisticsKRTrip } from 'views/pages/LogisticsKR/types'
import useFormatDate from 'hooks/useFormatDate'
import { DASH } from 'modules/constants'

type Props = {
  trip: LogisticsKRTrip
}

export const TripCard: FC<Props> = ({ trip }) => {
  const { t } = useTranslation('logisticsKR')
  const formatDate = useFormatDate()

  const { car, trailer, driver } = trip
  const carParameters = [
    !!car?.car_type && t(`vehicle_type.${car.car_type}`),
    !!car?.is_dump_truck && t('warehouses.details.is_dump_truck'),
    !!car?.unloading_type && t(`directions.${car.unloading_type}`),
    !!car?.side_height && t('warehouses.details.values.scales_length', { scales_length: car.side_height }),
  ]
    .filter(Boolean)
    .join(', ')

  const quantityInfo = [
    trip.load_quantity && formatQuantity(trip.load_quantity),
    trip.unload_quantity && formatQuantity(trip.unload_quantity),
  ]
    .filter(Boolean)
    .join(' / ')

  return (
    <ItemCard withoutHighlight>
      <CardContentWrapper>
        <TextCard thumbnail>
          {formatDate(trip?.load_date) || DASH} / {formatDate(trip?.unload_date) || DASH}
        </TextCard>
        {quantityInfo && <TextCard thumbnail>{quantityInfo}</TextCard>}
        <TextCard>{driver?.name}</TextCard>
        <APhone phone={driver?.phone || DASH} />
        <TextCard>
          {car?.brand_model || DASH} {car?.licence_number || DASH} / {trailer?.licence_number || DASH}
        </TextCard>
        <TextCard>{carParameters}</TextCard>
        <TextCard>{t('carriers.card.trips_count', { count: car?.trips_count || 0 })}</TextCard>
      </CardContentWrapper>
    </ItemCard>
  )
}
