import { FiltersAndSearchWrapper, FiltersWrapper } from 'views/components/CommonTableComponents/CommonTableComponents'
import { ABaseTableFilter } from 'views/components/TableFilters/ABaseTableFilter'
import { defaultListRequestParams } from 'views/components/TableFilters/data'
import { SearchFilter } from 'views/components/SearchFilter/SearchFilter'
import { CropsFilter, TeamsFilter } from 'views/components/TableFilters'
import { MonthFilter } from 'views/components/MonthFilter/MonthFilter'
import { AClearFiltersButton } from 'views/components/Analytics'
import { useDisplayClearBtn } from 'hooks/useDisplayClearBtn'
import { TableFiltersProps } from 'modules/types'
import { useTranslation } from 'react-i18next'
import { endpoints } from 'modules/endpoints'
import { FC } from 'react'

export const SamplesFilters: FC<TableFiltersProps> = ({
  setListRequestParams,
  updateFilterState,
  listRequestParams,
}) => {
  const { t } = useTranslation()

  const displayClearBtn = useDisplayClearBtn({
    listRequestParams,
  })

  return (
    <FiltersAndSearchWrapper>
      <FiltersWrapper>
        <MonthFilter value={listRequestParams.filter.month} updateFilterState={updateFilterState} allowClear={true} />
        <CropsFilter listRequestParams={listRequestParams} updateFilterState={updateFilterState} />
        <TeamsFilter
          listRequestParams={listRequestParams}
          updateFilterState={(data) => updateFilterState({ team: data.team, executor: undefined })}
        />
        <ABaseTableFilter
          onChange={(status) => updateFilterState({ status })}
          endpoint={endpoints.samplesStatus()}
          value={listRequestParams.filter.status}
          getLabel={(label) => label.label}
          placeholder={t('common:status')}
          id="SamplesStatus"
          showSearch={false}
          simpleApiFormat
        />
        {displayClearBtn && (
          <AClearFiltersButton
            onClick={() =>
              setListRequestParams({
                ...defaultListRequestParams,
              })
            }
          />
        )}
      </FiltersWrapper>
      <SearchFilter listRequestParams={listRequestParams} updateFilterState={updateFilterState} />
    </FiltersAndSearchWrapper>
  )
}
