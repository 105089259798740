import { FC } from 'react'
import { LogisticRequest } from 'modules/domain/logisticsKR/types'
import { ASharedModal, SharedModalContent, SharedModalInputs, SharedModalTitle } from 'views/components/SharedModal'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import FormSelectShared from 'views/components/FormSelectShared'
import { endpoints } from 'modules/endpoints'
import { apiClient } from 'modules/utils/httpClient'
import { useAFormHandler } from 'analytics/hooks'
import ADefaultModalFooter from 'views/components/DefaultModalFooter/ADefaultModalFooter'

interface Props {
  request: LogisticRequest
  onClose: () => void
  onSuccess: () => void
}

export const EditAssigneeModal: FC<Props> = ({ request, onClose, onSuccess }) => {
  const { t } = useTranslation('logisticsKR')
  const { formProgress, formHandler } = useAFormHandler('editLogisticRequestAssignee', {
    id: request.id,
  })

  const formik = useFormik({
    initialValues: {
      ...request,
      assignee: '', //TODO When we move completely to multi-logisticains, we should make this field optional on the backend
      assigned_team: request.assigned_team?.id,
      executors: request?.executors.map((el) => el.id),
    },

    onSubmit: formHandler(
      async () => await apiClient.put<LogisticRequest>(endpoints.LogisticsKR.requests(request.id), formik.values),
      {
        onSuccess: async () => {
          await onSuccess()
          onClose()
        },
        onError: (error) => {
          const { errors } = error
          formik.setErrors(errors)
        },
      },
    ),
  })

  return (
    <ASharedModal onClose={onClose} size="small" id="executors">
      <SharedModalTitle>{t('details.edit_assignees')}</SharedModalTitle>
      <SharedModalContent>
        <SharedModalInputs>
          <FormSelectShared
            hideLabel
            formik={formik}
            endpoint={endpoints.dispatchingLogisticians()}
            fieldName="executors"
            getLabel={(dto) => dto.full_name}
            searchField="search"
            multiple
          />
        </SharedModalInputs>
      </SharedModalContent>
      <ADefaultModalFooter
        onClose={onClose}
        disabled={!formik.dirty}
        confirmButtonText={t('common:save')}
        progress={formProgress}
        onConfirm={() => formik.submitForm()}
      />
    </ASharedModal>
  )
}
