import { DateISO } from '@agro-club/agroclub-shared'

import { Company } from 'modules/domain/company/types'
import { Address, Product } from 'modules/domain/types'
import { User } from 'modules/domain/user/types'

export type Specification = {
  id: string
  owner: User
  company: Company
  contract: Contract
  number: string
  signed_date: DateISO | null
  address: Address
  product: Product
  price: string
  quantity: number
  quantity_left?: number
  quantity_shipping?: number
  quantity_shipped?: number
  status?: string
  deadline: DateISO | null
  files?: string[]
}

export type SpecificationForm = {
  id?: string
  owner?: string
  company?: string
  number: string | undefined
  signed_date: DateISO | null
  address: string | undefined
  product: string | undefined
  price: string | undefined
  quantity: number
  deadline: DateISO | null
  files?: any[]
  contract?: string | number
  contract_number?: string
  contract_signed_date?: DateISO | null
  contract_files?: string[]
}

export type SpecificationsListRequestFilter = {
  month?: string
  product?: string[]
  team?: string[]
  status?: string
}

export type SpecificationsListRequestSorting = {
  sort_field?: any
  sort_reversed?: boolean
}

export enum SpecificationStatus {
  open = 'open',
  closed = 'closed',
  archived = 'archived',
}

export type ListRequestedParams = {
  filter?: SpecificationsListRequestFilter
  sorting?: SpecificationsListRequestSorting
  page: number
}

export type Contract = {
  id?: string
  number: string | number
  signed_date: DateISO | null | undefined
  files?: string[]
  title?: string
}

export type Deals = {
  id?: string
  company: { id?: string; inn?: string; name: string }
  owner: { id?: string; full_name?: string }
  delivered_quantity: string
  loaded_quantity: string
  price_exw: string
  quantity: string
  remaining_quantity: string
}
