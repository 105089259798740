import { AntTag, defaultTheme } from '@agro-club/agroclub-shared'
import { isBidContractsEnabled, isUS } from 'env'
import useFormatDate from 'hooks/useFormatDate'
import { BidTypes, USBid } from 'modules/domain/bid/types'
import { Deal } from 'modules/domain/deal/types'
import { getDealBidAndContract } from 'modules/domain/deal/utils'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { USContractTypeTag, BidTeamTag } from 'views/components/Tags'
import { BidContractTag } from 'views/components/Tags/BidContractTag'
import { BarchartIcon } from 'views/pages/Bid/BidDetail/Bid/BarchartIcon'
import { CompanySizeTag } from 'views/pages/User/UserDetail/CompanySizeTag'

export const TagsWrapper = styled.div`
  display: flex;
  gap: 8px;
  @media (max-width: ${defaultTheme.queries.mobile}) {
    flex-wrap: wrap;
  }
`

export const BidDetailTitleTags: FC<{ deal: Deal; bidType: BidTypes }> = ({ deal, bidType }) => {
  const { bid, contract } = getDealBidAndContract(deal, bidType)
  const formatDate = useFormatDate()
  const { t } = useTranslation('deal')

  return (
    <TagsWrapper>
      <CompanySizeTag companySize={bid.owner?.profile?.company_size} />
      <BarchartIcon source={bid?.source} />
      {isBidContractsEnabled ? <BidContractTag bid={contract ?? bid} /> : <AntTag color="white">ID {bid?.id}</AntTag>}
      <USContractTypeTag bid={bid as USBid} />
      {!isUS && <BidTeamTag bid={bid} />}
      {!isBidContractsEnabled && (
        <AntTag color="white">
          {t('date_tag', {
            created: formatDate(bid?.created),
            updated: formatDate(bid?.modified),
          })}
        </AntTag>
      )}
    </TagsWrapper>
  )
}
