import { FC, ReactNode } from 'react'

import { Badge } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

interface Props {
  showBadge: boolean
  icon: ReactNode
}

const Container = styled.div`
  display: flex;
  align-items: center;

  & > div {
    max-height: 24px;
  }

  .ant-badge {
    color: inherit;

    &-dot {
      top: 6px;
      right: -6px;
    }
  }
`

const MenuIcon: FC<Props> = ({ icon, showBadge }) => {
  return <Container>{showBadge ? <Badge intent="destruct">{icon}</Badge> : icon}</Container>
}

export default MenuIcon
