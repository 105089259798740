import { ColumnData } from 'modules/types'
import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import { CellRenders } from 'views/components/Table/renderers'
import { FarmerLoadActionsCell, ShipLoadActionsCell } from './ActionsCell/LoadingActionsCell'
import { refetchFunc } from 'modules/domain/common/hooks'
import PermissionCheck from 'views/components/PermissionCheck/PermissionCheck'
import { labelQuantity } from 'modules/utils/numbers/formatQuantity'
import { TruncatedValue } from 'views/components/TruncatedValue/TruncatedValue'

const getCommonParams = (t) => [
  {
    title: t('accordion.loading.date'),
    width: 95,
    align: 'left',
    dataIndex: 'load_date',
    render: CellRenders.date,
  },
  {
    title: t('accordion.loading.regional'),
    width: 180,
    align: 'left',
    dataIndex: 'regional',
    render: (regional) => <TruncatedValue hint={regional.full_name}>{regional.full_name}</TruncatedValue>,
  },
  {
    title: labelQuantity(t('accordion.loading.quantity')),
    width: 80,
    align: 'right',
    dataIndex: 'quantity',
  },
  {
    title: t('accordion.loading.operationType'),
    width: 117,
    align: 'left',
    dataIndex: 'work_type',
    render: (work_type) => t(`accordion.loading.${work_type}`),
  },
]

export const useShipLoadColumns = (refetch: refetchFunc): ColumnData[] => {
  const { t } = useTranslation('deal')

  return useMemo(
    () => [
      ...getCommonParams(t),
      {
        title: '',
        width: 59,
        align: 'left',
        dataIndex: 'actions',
        render: (_, shipLoad) => (
          <PermissionCheck scope={['deals.change_regionalactivity', 'deals.delete_regionalactivity']}>
            <ShipLoadActionsCell loadItem={shipLoad} refetch={refetch} />
          </PermissionCheck>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t],
  )
}

export const useFarmerLoadColumns = (refetch: refetchFunc): ColumnData[] => {
  const { t } = useTranslation('deal')

  return useMemo(
    () => [
      ...getCommonParams(t),
      {
        title: '',
        width: 59,
        align: 'left',
        dataIndex: 'actions',
        render: (_, shipLoad) => (
          <PermissionCheck scope={['deals.change_regionalactivity', 'deals.delete_regionalactivity']}>
            <FarmerLoadActionsCell loadItem={shipLoad} refetch={refetch} />
          </PermissionCheck>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t],
  )
}
