import { FC } from 'react'

import { ReactComponent as IconCheck } from 'assets/icons/checkmark.svg'
import { useTranslation } from 'react-i18next'
import { RelatedContent, RelatedItem, RestrictionContainer } from 'views/pages/MyTasks/TaskDetail/styled'
import { generatePath } from 'react-router-dom'
import UserRoutes from 'views/pages/User/routes'
import DealRoutes from 'views/pages/Deal/routes'
import BidRoutes from 'views/pages/Bid/routes'
import CompanyRoutes from 'views/pages/Company/routes'
import APhone from 'views/ui/Phone/APhone'
import { ARouterLink } from 'views/components/Analytics'
import { CompanyFactoringIcon } from 'views/components/TooltipIcons/TooltipIcons'
import { refetchFunc } from 'modules/domain/common/hooks'
import { CarrierVerification } from './CarrierVerification'
import TaskFiles from '../TaskFiles'
import { Task } from '../../types'
import { Card } from 'views/components/Card/Card'
import { TaskType } from 'modules/domain/task/types'
import { TruncatedValue } from 'views/components/TruncatedValue/TruncatedValue'
import { EntityWithIconsWrapper, MultilineText } from 'views/styled/common'
import { OtherLinks } from './OtherLinks'

interface Props {
  task?: Task | undefined
  taskRefetch: refetchFunc
}

export const LeftBlock: FC<Props> = ({ task, taskRefetch }) => {
  const { t } = useTranslation('my_tasks')

  if (task?.task_type === TaskType.CARRIER_VERIFICATION) {
    return <CarrierVerification task={task} taskRefetch={taskRefetch} />
  }

  return (
    <Card.Container>
      <RestrictionContainer>
        {task?.comment && (
          <Card.GapableContent>
            <Card.Title>{t('detail.description')}</Card.Title>
            <MultilineText>{task?.comment}</MultilineText>
          </Card.GapableContent>
        )}
        {task?.linked_user || task?.linked_deal || task?.linked_bid || task?.linked_company || task?.other_links ? (
          <Card.GapableContent>
            <Card.Title>{t('detail.related_object')}</Card.Title>
            <RelatedContent>
              {task.linked_user && (
                <RelatedItem>
                  <ARouterLink
                    eventName="user"
                    itemId={String(task.linked_user.id)}
                    to={generatePath(UserRoutes.Details, { id: task.linked_user.id })}
                  >
                    {task.linked_user.full_name}
                  </ARouterLink>
                  {task.linked_user.is_verified && (
                    <>
                      &nbsp;
                      <IconCheck />
                    </>
                  )}
                  &nbsp;/&nbsp;
                  <APhone phone={task?.linked_user.phone} />
                </RelatedItem>
              )}
              <OtherLinks task={task} />
              {task.linked_company && (
                <EntityWithIconsWrapper>
                  <TruncatedValue hint={task?.linked_company.name}>
                    <ARouterLink
                      eventName="company"
                      itemID={String(task?.linked_company.id)}
                      to={generatePath(CompanyRoutes.Details, { id: task?.linked_company.id })}
                    >
                      {task?.linked_company.name}
                    </ARouterLink>
                  </TruncatedValue>

                  <CompanyFactoringIcon company={task?.linked_company} />
                </EntityWithIconsWrapper>
              )}
              {task?.linked_deal && (
                <ARouterLink
                  eventName="deal"
                  itemId={String(task?.linked_deal.id)}
                  to={generatePath(DealRoutes.Details, { id: task?.linked_deal.id })}
                >
                  {`${t('detail.deal_id')} ${task?.linked_deal.id}`}
                </ARouterLink>
              )}
              {task?.linked_bid && (
                <ARouterLink
                  eventName="bid"
                  itemId={String(task?.linked_bid.id)}
                  to={generatePath(BidRoutes.Details, { id: task?.linked_bid.id })}
                >
                  {`${t('detail.bid_id')} ${task?.linked_bid.id}`}
                </ARouterLink>
              )}
            </RelatedContent>
          </Card.GapableContent>
        ) : null}
        {task && <TaskFiles id={task?.id} />}
      </RestrictionContainer>
    </Card.Container>
  )
}
