import { FC, useState } from 'react'

import { useTranslation } from 'react-i18next'
import * as Layout from 'views/layouts/NewLayout'
import * as Header from 'views/ui/Header/Header'

import { Icons, Progress, useHelmet } from '@agro-club/agroclub-shared'
import { useParams } from 'react-router-dom'
import ItemLoadingLayout from 'views/layouts/ItemLoadingLayout/ItemLoadingLayout'
import { useSingleEntity } from 'modules/domain/common/hooks'
import { endpoints } from 'modules/endpoints'
import { Carrier } from 'modules/domain/logisticsKR/types'
import CarrierAccordion from './CarrierAccordion/CarrierAccordion'
import { TwoColumns } from 'views/styled/detailedPage'
import CustomersKRRoutes from '../routes'
import { CarrierBoard } from './CarrierBoard/CarrierBoard'
import { HeaderButtons } from '../../components/styled'
import { CarrierEditModal } from '../CarrierEditModal/CarrierEditModal'
import { CarriersKRAddCarModal } from '../CarriersKRAddCar/CarriersKRAddCarModal'
import { getPersonalNumberLabel } from 'modules/utils/numbers/formatPersonalNumber'
import APageWrapper from 'views/components/PageWrapper/APageWrapper'
import { AnalyticsPages, AnalyticsPlaces } from '../../analyticsPlaces'
import { AButton } from 'views/components/Analytics'
import APhone from 'views/ui/Phone/APhone'

const CarrierKRDetails: FC = () => {
  const { t } = useTranslation('logisticsKR')
  const { id } = useParams()
  const [fetchProgress, carrier, refetch] = useSingleEntity<Carrier>(endpoints.LogisticsKR.carriers(id))
  const [editCarrierModal, setEditCarrierModal] = useState<boolean>(false)
  const [addCarModal, setAddCarModal] = useState(false)
  useHelmet({ title: carrier?.name || '' })

  if (fetchProgress === Progress.WORK || !carrier) return <ItemLoadingLayout id={id as string} />

  return (
    <APageWrapper page={AnalyticsPages.CARRIERS.DETAIL} place={AnalyticsPlaces.CARRIERS.DETAIL} id={id}>
      <Layout.WrapperContentFlex>
        <Layout.Header>
          <Layout.TopHeader>
            <Header.Breadcrumbs
              routes={[
                { path: CustomersKRRoutes.List, breadcrumbName: t('carriers.heading') },
                { breadcrumbName: carrier.name ?? carrier.id },
              ]}
            />
            <HeaderButtons>
              <AButton id="edit" intent="secondary" size="medium" onClick={() => setEditCarrierModal(true)}>
                {t('common:edit')}
              </AButton>
              <Header.LineSeparator />
              <AButton id="addCar" Icon={Icons.IconAdd} intent="approve" onClick={() => setAddCarModal(true)}>
                {t('carriers.carsAdd.heading')}
              </AButton>
            </HeaderButtons>
          </Layout.TopHeader>
          {editCarrierModal && (
            <CarrierEditModal onClose={() => setEditCarrierModal(false)} carrier={carrier} onSuccess={refetch} />
          )}
          {addCarModal && (
            <CarriersKRAddCarModal carrierId={carrier.id} onSuccess={refetch} onClose={() => setAddCarModal(false)} />
          )}
          <Layout.PageTitleWrapper>
            <Header.Title compact title={carrier.name} />
            <Header.SubTitle>
              {carrier.inn && `${getPersonalNumberLabel()}: ${carrier.inn}`}
              {carrier.contact_name && ` / ${carrier.contact_name}`}
              {carrier.phone && (
                <>
                  {` / `}
                  <APhone phone={carrier.phone} />
                </>
              )}
              {' / '}
              {t(`carriers.${carrier.carrier_type}`)}
            </Header.SubTitle>
          </Layout.PageTitleWrapper>
        </Layout.Header>
        <TwoColumns>
          <CarrierBoard carrier={carrier} />
          <CarrierAccordion carrier={carrier} />
        </TwoColumns>
      </Layout.WrapperContentFlex>
    </APageWrapper>
  )
}

export default CarrierKRDetails
