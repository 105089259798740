import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'

import { apiClient } from 'modules/utils/httpClient'
import { endpoints } from 'modules/endpoints'
import { SharedModalInputs, SharedModalTitle } from 'views/components/SharedModal'
import { TrailerModalContent } from './TrailerModalContent'
import { useAFormHandler } from 'analytics/hooks'
import ASharedModal from 'views/components/SharedModal/ASharedModal'
import { AnalyticsPlaces } from '../../analyticsPlaces'
import ADefaultModalFooter from 'views/components/DefaultModalFooter/ADefaultModalFooter'

interface Props {
  setIsOpen: (val: boolean) => void
  carrierId: string
  onCancel?: () => void
  onSuccess: (val: number) => void
}

export const AddTrailerModal: FC<Props> = ({ setIsOpen, carrierId, onCancel, onSuccess }) => {
  const { t } = useTranslation('logisticsKR')
  const { formProgress, formHandler } = useAFormHandler()

  const formik = useFormik({
    initialValues: {
      carrier: carrierId,
      licence_number: null,
      brand_model: null,
      unloading_type: null,
      side_height: null,
    },
    enableReinitialize: true,
    onSubmit: formHandler(async () => await apiClient.post(endpoints.LogisticsKR.trailers(), formik.values), {
      onSuccess: async (requestResult) => {
        await onSuccess(requestResult.id)
        setIsOpen(false)
      },
      onError: (error) => {
        const { errors } = error
        formik.setErrors(errors)
      },
    }),
  })

  const handleClose = () => {
    setIsOpen(false)
    onCancel?.()
  }

  return (
    <ASharedModal id={AnalyticsPlaces.REQUESTS_BOARD.ADD_TRAILER_MODAL} size="small" onClose={handleClose}>
      <SharedModalTitle>{t('common.newTrailer')}</SharedModalTitle>
      <SharedModalInputs>
        <TrailerModalContent formik={formik} />
      </SharedModalInputs>
      <ADefaultModalFooter
        onClose={handleClose}
        progress={formProgress}
        onConfirm={() => formik.submitForm()}
        confirmButtonText={t('common:save')}
      />
    </ASharedModal>
  )
}
