import { FC } from 'react'

import { Search, SearchInputProps } from '@agro-club/agroclub-shared'
import { useAnalyticsChangeHandler } from 'analytics/hooks'
import { t } from 'i18next'

interface Props extends SearchInputProps {
  id?: string
}

const ASearch: FC<Props> = ({ onChange, id = 'search', value, ...otherProps }) => {
  const handleChange = useAnalyticsChangeHandler(id)

  return (
    <Search
      placeholder={t('common:search')}
      onChange={handleChange(onChange, { value: { from: value } }) as any}
      value={value}
      {...otherProps}
    />
  )
}

export default ASearch
