import { defaultTheme, Icons, Text, Typography } from '@agro-club/agroclub-shared'
import { BidStatus } from 'modules/domain/bid/types'

import styled, { css } from 'styled-components'

interface Props {
  status?: string | null
  activeCard?: boolean
  thumbnail?: boolean
  withoutHighlight?: boolean
}

export const ItemCard = styled.div<Props>`
  position: relative;
  width: 298px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid;
  border-color: ${({ activeCard }) =>
    activeCard ? `${defaultTheme.color.primary50a}` : `${defaultTheme.color.secondary50}`};
  padding: 12px;
  cursor: ${({ withoutHighlight }) => (withoutHighlight ? 'auto' : 'pointer')};
  transition: border-color ease-in 0.2s;
  color: ${({ status }) =>
    status === BidStatus.archived
      ? `${defaultTheme.color.secondaryPlaceholder};`
      : `${defaultTheme.color.secondary900};`};
  background-color: ${({ activeCard }) => (activeCard ? `${defaultTheme.color.backgroundQuarternary}` : 'none')};

  &:hover {
    background: ${({ withoutHighlight }) => (withoutHighlight ? 'transparent' : defaultTheme.color.primary8a)};
  }
`

export const TextCard = styled.span<Props>`
  ${({ thumbnail }) => (thumbnail ? Typography.accentLarge : Typography.bodyLarge)}
  color: inherit;
`

export const GroupInfoWrapper = styled.div`
  span,
  a {
    display: block;
  }
`

export const CardContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 240px;
  row-gap: 8px;
`

export const CompanyName = styled.div<Props>`
  max-width: 250px;
  ${Typography.bodyLarge}
`

const commonTagsStyle = css`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 8px;
  max-width: 240px;

  &:empty {
    display: none;
  }
`

export const BidCardTags = styled.span`
  ${commonTagsStyle}
  margin-bottom: 8px;
`

export const MatchCardTags = styled.span`
  ${commonTagsStyle}
  margin-bottom: 12px;
`

export const CardInfo = styled.div`
  ${Typography.bodyLarge}
  margin-bottom: 8px;
`
export const Price = styled.span`
  ${Typography.accentLarge}
  white-space: nowrap;
`
export const Location = styled(Text)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const PriceWrapper = styled.div<{ $withMargin?: boolean; $withBg?: boolean }>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 12px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ $withMargin }) => ($withMargin ? '8px' : 0)};
  background-color: ${({ $withBg }) => ($withBg ? defaultTheme.color.backgroundPrimary : 'transparent')};
  border-radius: 8px;
`

export const PriceColumn = styled.div<{ isClosed: boolean | undefined }>`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  padding: 4px 0 4px 8px;
  border-radius: 8px;
  background-color: ${({ isClosed }) => (!isClosed ? defaultTheme.color.backgroundPrimary : 'transparent')};
`

export const SecondPriceColumn = styled(PriceColumn)`
  background-color: ${({ isClosed }) =>
    !isClosed ? defaultTheme.color.accentNotify100 : defaultTheme.color.backgroundPrimary};
`

export const PriceTitle = styled.div`
  ${Typography.accentSmall}
  color: inherit;
`

export const PriceBlock = styled.div<{ accent?: boolean }>`
  display: flex;
  flex-direction: column;
  row-gap: 4px;

  &:not(:first-of-type) {
    background-color: ${({ accent }) => (accent ? defaultTheme.color.accentNotify16a : 'transparent')};
  }
`

export const IconCheck = styled(Icons.IconDone)`
  width: 16px;
  height: 16px;
  margin: 0 0 -2px 2px;

  path {
    fill: ${defaultTheme.color.textForLink};
  }
`
export const FooterCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const CardButtonWrapper = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
`
