import { BidContract, BidContractTotalsItem } from 'modules/domain/bid/types'
import { useSingleEntity } from 'modules/domain/common/hooks'
import { Progress } from '@agro-club/agroclub-shared'
import { NoDataCard } from 'views/components/Board/NoDataCard'
import { PreloadCard } from 'views/components/Board/Preload'
import { ContractCard, contractWrapperCSS } from './Cards/ContractCard'
import { FC, useCallback, useMemo } from 'react'
import { HeaderControls } from './HeaderControls'
import { endpoints } from 'modules/endpoints'
import { intersection } from 'lodash'
import { type RematchingData } from 'views/pages/Deal/hooks/useRematching'
import { CommonWrapper } from 'views/components/Card/Wrapper'
import { RematchingColumn } from './styled'
import styled from 'styled-components'
import { CustomScroll, HoverScrollingWithPadding } from 'views/styled/common'

type Props = {
  title: string
  main: RematchingData
  secondary: RematchingData
}

export const ContractPreload = styled(PreloadCard)`
  ${contractWrapperCSS}
`

const ColumnBody = styled(CommonWrapper)`
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  ${CustomScroll}
  ${HoverScrollingWithPadding}
`
const ColumnContent = styled(CommonWrapper)``

export const ContractsColumn: FC<Props> = ({ main, secondary, title }) => {
  const [totalsProgress, totals = []] = useSingleEntity<BidContractTotalsItem[]>(
    endpoints.contractsTotals(main.requestParams.filter),
  )

  const secondaryContract = secondary.bid as BidContract

  const loading = [main.progress, totalsProgress].includes(Progress.WORK)

  const getDealsIntersection = useCallback(
    (mainContract: BidContract) => {
      if (!secondaryContract) {
        return []
      }
      return intersection(secondaryContract?.deal_ids, mainContract.deal_ids)
    },
    [secondaryContract],
  )
  const sortedData = useMemo(() => {
    return main.items?.map((item) => ({ ...item, intersection: getDealsIntersection(item as BidContract) })) ?? []
  }, [main.items, getDealsIntersection])

  return (
    <RematchingColumn>
      <HeaderControls
        title={title}
        totalsProgress={totalsProgress}
        count={main.total}
        countLoading={loading}
        totals={totals}
        matching={main}
      />
      <ColumnBody>
        <ColumnContent>
          {!loading
            ? sortedData.map((contract) => (
                <ContractCard
                  key={contract.id}
                  contract={contract as BidContract}
                  onSelect={main.setBid}
                  isSelected={main.bid?.id === contract.id}
                  isPaired={!!contract.intersection.length}
                />
              ))
            : [1, 2, 3, 4].map((key) => <ContractPreload key={key} />)}
          {!sortedData.length && !loading && <NoDataCard />}
        </ColumnContent>
      </ColumnBody>
    </RematchingColumn>
  )
}
