import { Icons, defaultTheme, Typography } from '@agro-club/agroclub-shared'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { FC } from 'react'

const NoData = styled.div`
  min-width: 298px;
  height: 98px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid ${defaultTheme.color.secondary50};
  ${Typography.titleH4}
  color: ${defaultTheme.color.secondaryPlaceholder};
`

export const NoDataCard: FC = () => {
  const { t } = useTranslation()
  return (
    <NoData>
      <Icons.IconWindmill />
      {t('common:nothing_yet')}
    </NoData>
  )
}
