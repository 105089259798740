import { FC, useState } from 'react'
import { Icons } from '@agro-club/agroclub-shared'
import { useTranslation } from 'react-i18next'
import { useAProgress } from 'hooks/useAProgress'
import { CarStatuses, LogisticsKRCar } from '../../types'
import { apiClient } from 'modules/utils/httpClient'
import { endpoints } from 'modules/endpoints'
import { useNotificationProgress } from 'hooks/useNotificationProgress'
import { CarEditModal } from '../../CarsKR/CarEditModal/CarEditModal'
import { ArchivedConfirmModal } from './ArchivedConfirmModal'
import { ToActiveConfirmModal } from './ToActiveConfirmModal'
import { APopoverButton } from 'views/components/Analytics/APopoverButton'
import { PopoverContainer } from 'views/styled/common'
import { refetchFunc } from 'modules/domain/common/hooks'

type Props = {
  car: LogisticsKRCar
  onSuccess: refetchFunc
  setPopoverVisible: (arg: boolean) => void
}

export const PopoverContent: FC<Props> = ({ car, onSuccess, setPopoverVisible }) => {
  const { t } = useTranslation('logisticsKR')
  const [editModal, setEditModal] = useState(false)
  const [archivedConfirmModal, setArchivedConfirmModal] = useState(false)
  const [toActiveConfirmModal, setToActiveConfirmModal] = useState(false)

  const [changeStatusToArchivedProgress, changeStatusToArchived] = useAProgress(
    (carId: string) => apiClient.post(endpoints.LogisticsKR.carStatus(carId), { new_status: CarStatuses.archived }),
    {
      eventName: 'archiveCar',
      onSuccess: () => onSuccess(true),
    },
  )
  const [changeStatusToActiveProgress, changeStatusToActive] = useAProgress(
    (carId: string) => apiClient.post(endpoints.LogisticsKR.carStatus(carId), { new_status: CarStatuses.active }),
    {
      eventName: 'activeCar',
      onSuccess: () => onSuccess(true),
    },
  )

  useNotificationProgress(
    changeStatusToArchivedProgress,
    t('carsEdit.notifications.car_archived_success'),
    t('carsEdit.notifications.car_archived_error'),
  )

  useNotificationProgress(
    changeStatusToActiveProgress,
    t('carsEdit.notifications.car_active_success'),
    t('carsEdit.notifications.car_active_error'),
  )
  return (
    <PopoverContainer
      width={170}
      onClick={(e) => {
        e.stopPropagation()
        setPopoverVisible(false)
      }}
    >
      <APopoverButton
        id="editCar"
        analyticsPayload={{ id: car.id }}
        text={t('carriers.popover.edit')}
        onClick={() => setEditModal(true)}
        Icon={<Icons.IconEdit />}
      />
      {car.status === CarStatuses.draft && (
        <APopoverButton
          id="changeToActive"
          analyticsPayload={{ id: car.id }}
          text={t('carriers.popover.change_to_active')}
          onClick={() => setToActiveConfirmModal(true)}
          Icon={<Icons.IconCheck />}
        />
      )}
      <APopoverButton
        id="changeToArchive"
        analyticsPayload={{ id: car.id }}
        text={t('carriers.popover.archived')}
        onClick={() => setArchivedConfirmModal(true)}
        Icon={<Icons.IconRemove />}
      />
      {editModal && <CarEditModal car={car} onClose={() => setEditModal(false)} onSuccess={() => onSuccess(true)} />}
      {archivedConfirmModal && (
        <ArchivedConfirmModal
          progress={changeStatusToArchivedProgress}
          car={car}
          onChange={changeStatusToArchived}
          onClose={setArchivedConfirmModal}
        />
      )}
      {toActiveConfirmModal && (
        <ToActiveConfirmModal
          progress={changeStatusToActiveProgress}
          car={car}
          changeHandler={changeStatusToActive}
          onClose={setToActiveConfirmModal}
        />
      )}
    </PopoverContainer>
  )
}
