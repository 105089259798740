import { FC, ReactNode } from 'react'
import styled from 'styled-components'
import { defaultTheme } from '@agro-club/agroclub-shared'
import { Address } from 'modules/domain/types'
import { useTranslation } from 'react-i18next'

type Props = {
  accent?: boolean
  children: ReactNode
  as?: string
}

export const Wrapper = styled.div<{ accent?: boolean }>`
  background-color: ${({ accent }) => (accent ? defaultTheme.color.accentNotify16a : 'transparent')};
  width: fit-content;
`

export const MarketZoneWrapper = styled.div<{ accent?: boolean }>`
  background-color: ${({ accent }) => (accent ? defaultTheme.color.primary24a : 'transparent')};
  width: fit-content;
`

export const AddressInfoWrapper = styled.div`
  display: flex;
  gap: 4px;
`
export const AddressTitle: FC<Props> = ({ accent, as, children }) => {
  return (
    <Wrapper as={as} accent={accent}>
      «{children}»
    </Wrapper>
  )
}

export const MarketZone: FC<Props> = ({ as, children, accent }) => {
  const { t } = useTranslation('address')
  return (
    <MarketZoneWrapper as={as} accent={accent}>
      {t('MZ')}: {children}
    </MarketZoneWrapper>
  )
}

export const AddressExtraInfo: FC<{ address: Address }> = ({ address }) => {
  return (
    <AddressInfoWrapper>
      {address.market_zone && <MarketZone accent>{address.market_zone.name}</MarketZone>}
      {address.title && <AddressTitle accent>{address.title}</AddressTitle>}
    </AddressInfoWrapper>
  )
}
