import { ResponsiveModal } from 'views/components/ResponsiveModal/ResponsiveModal'
import { FormGroup, Input, Radio, RadioItem } from '@agro-club/agroclub-shared'
import { useNotificationProgress } from 'hooks/useNotificationProgress'
import { FormLegalType } from 'views/components/form/FormLegalType'
import { SharedModalInputs } from 'views/components/SharedModal'
import { FormMap } from 'views/components/form/FormMap'
import { FormItemWrapper } from 'views/styled/common'
import { apiClient } from 'modules/utils/httpClient'
import { useAFormHandler } from 'analytics/hooks'
import { useTranslation } from 'react-i18next'
import { endpoints } from 'modules/endpoints'
import { useFormik } from 'formik'
import { FC } from 'react'
import { FormMarketZone } from 'views/components/form/FormMarketZone/FormMarketZone'

type Props = {
  addressesRefetch?: () => void
  onSuccess?: (id: number) => void
  onClose: () => void
  userId: string
}

type Fields = {
  address: {
    geoObject: any
    address: string
    latitude: number
    longitude: number
  } | null
  is_main: boolean
  title: string | null
  legal_type?: string
  market_zone?: string
}

type responseAddressType = {
  id: number
  address: string
  geo_object: object | null
  is_main: boolean
  latitude: number
  longitude: number
  title: string | null
}

export const AddAddressModal: FC<Props> = ({ onClose, userId, addressesRefetch, onSuccess }) => {
  const { t } = useTranslation()
  const { formProgress, formHandler } = useAFormHandler()

  useNotificationProgress(formProgress, t('address:newAddressSuccess'))

  const handlerMap = (data) => {
    if (!data.coords) {
      formik.setFieldValue('address', [])
      return
    }

    formik.setFieldValue('address', {
      geoObject: data.geoObject,
      address: data.address,
      latitude: data.coords && data.coords[0],
      longitude: data.coords && data.coords[1],
    })
  }

  const formik = useFormik<Fields>({
    initialValues: {
      legal_type: undefined,
      is_main: false,
      address: null,
      title: null,
      market_zone: undefined,
    },

    onSubmit: formHandler(
      async () => {
        const data = {
          title: formik.values.title,
          is_main: formik.values.is_main,
          legal_type: formik.values.legal_type,
          market_zone: formik.values.market_zone,
          ...formik.values.address,
        }

        const response = await apiClient.post<responseAddressType>(endpoints.userAddress(userId), data)

        if (response) {
          onSuccess?.(response.id)
        }
      },
      {
        onSuccess: async () => {
          await addressesRefetch?.()
          onClose()
        },
        onError: (error) => {
          const { errors } = error
          formik.setErrors(errors)
        },
      },
    ),
  })

  return (
    <ResponsiveModal
      disabled={!formik.values?.address?.latitude}
      onConfirm={() => formik.handleSubmit()}
      title={t('address:newAddressHeader')}
      confirmButtonText={t('common:save')}
      progress={formProgress}
      id="AddAddressModal"
      onClose={onClose}
    >
      <SharedModalInputs>
        <FormGroup error={formik.errors.is_main} label={t('legalType:label')}>
          <FormItemWrapper>
            <Radio onChange={(value) => formik.setFieldValue('is_main', !!value)} value={formik.values.is_main ? 1 : 0}>
              <RadioItem value={1} label={t('address:main')} />
              <RadioItem value={0} label={t('address:additional')} />
            </Radio>
          </FormItemWrapper>
        </FormGroup>
        <FormMap handlerMap={handlerMap} formik={formik} />

        <FormGroup label={t('address:nameForAddress')} error={formik.errors.title}>
          <Input {...formik.getFieldProps('title')} invalid={!!formik.errors.title} />
        </FormGroup>
        <FormMarketZone formik={formik} />
        <FormLegalType formik={formik} endpoint={endpoints.userLegalTypes(userId)} />
      </SharedModalInputs>
    </ResponsiveModal>
  )
}
