const main = 'contracts'

const relativeRoutes = {
  relativePage: `${main}/*`,
  relativeList: `${main}`,
  relativeDetails: ':id',
}

export const ContractsRoutes = {
  ...relativeRoutes,
  List: `/${main}`,
  Details: `/${main}/${relativeRoutes.relativeDetails}`,
}
