import { AntTag } from '@agro-club/agroclub-shared'
import { Deal } from 'modules/domain/deal/types'
import { FC } from 'react'
import { IconTrack } from '../../Board/styled'
import { useTranslation } from 'react-i18next'

interface Props {
  deal: Deal
}

export const LogisticsTag: FC<Props> = ({ deal }) => {
  const { t } = useTranslation('deal')

  return (
    <AntTag
      icon={<IconTrack $approve={deal.is_approved_by_logistician} />}
      color={deal.is_approved_by_logistician ? 'blue' : 'grey'}
    >
      {t('logistician')}
    </AntTag>
  )
}
