import { FC, useState } from 'react'

import { ButtonsWrapper, EntityTitle } from 'views/pages/Deal/DealDetail/DealAccordion/styled'
import { useTranslation } from 'react-i18next'
import { ExternalLink, Icons } from '@agro-club/agroclub-shared'
import { DeclarationModal } from 'views/pages/Deal/DealDetail/DealAccordion/DealFiles/DeclarationModal'
import { Declaration as DeclarationType } from 'modules/domain/types'
import { SharedModalContent, SharedModalTitle } from 'views/components/SharedModal/styled'
import { apiClient } from 'modules/utils/httpClient'
import { endpoints } from 'modules/endpoints'
import { useParams } from 'react-router-dom'
import useFormatDate from 'hooks/useFormatDate'
import { BorderedItem } from 'views/components/BorderedItem/BorderedItem'
import { useNotificationProgress } from 'hooks/useNotificationProgress'
import { useAProgress } from 'hooks/useAProgress'
import ASharedModal from 'views/components/SharedModal/ASharedModal'
import { AnalyticsPlaces } from 'views/pages/Deal/analyticsPlaces'
import ADefaultModalFooter from 'views/components/DefaultModalFooter/ADefaultModalFooter'
import { AButton } from 'views/components/Analytics'
import { isBidContractsEnabled } from 'env'
import { refetchFunc } from 'modules/domain/common/hooks'

type Props = {
  declaration: DeclarationType | undefined
  declarationRefetch: refetchFunc
}

const Declaration: FC<Props> = ({ declaration, declarationRefetch }) => {
  const { t } = useTranslation('deal')
  const { id } = useParams()
  const [isOpenModalAdd, setIsOpenModalAdd] = useState(false)
  const [isOpenConfirmRemove, setIsOpenConfirmRemove] = useState(false)

  const [removeProgress, removeHandler] = useAProgress(
    (dealId: string) => apiClient.delete(endpoints.dealDeclaration(dealId)),
    {
      eventName: 'removeDeclaration',
      onSuccess: async () => {
        await declarationRefetch()
        setIsOpenConfirmRemove(false)
      },
    },
  )
  const formatDate = useFormatDate()

  useNotificationProgress(removeProgress, t('declarationRemoveSuccess'), t('common:something_went_wrong'))

  const addButton = !isBidContractsEnabled ? (
    <AButton id="add" intent="approve" Icon={Icons.IconAdd} onClick={() => setIsOpenModalAdd(true)}>
      {t('common:add')}
    </AButton>
  ) : (
    t('common:no')
  )

  return (
    <div>
      <EntityTitle>{t('declaration')}</EntityTitle>
      <div>
        {declaration ? (
          <BorderedItem>
            <ExternalLink href={declaration.files[0]?.uploaded_file} target="_blank">
              {t('declarationLinkName', {
                number: declaration.number,
                date: formatDate(declaration.start_date),
              })}
            </ExternalLink>

            <ButtonsWrapper>
              <AButton
                id="remove"
                intent="white"
                Icon={Icons.IconDelete}
                onClick={() => setIsOpenConfirmRemove(true)}
              />
            </ButtonsWrapper>
          </BorderedItem>
        ) : (
          addButton
        )}
      </div>
      {isOpenModalAdd && <DeclarationModal onSuccess={declarationRefetch} onClose={() => setIsOpenModalAdd(false)} />}

      {isOpenConfirmRemove && (
        <ASharedModal
          id={AnalyticsPlaces.REMOVE_DECLARATION_MODAL}
          size="small"
          onClose={() => setIsOpenConfirmRemove(false)}
        >
          <SharedModalTitle>{t('declarationRemoveTitle')}</SharedModalTitle>
          <SharedModalContent>{t('declarationRemoveBody')}</SharedModalContent>
          <ADefaultModalFooter
            progress={removeProgress}
            onClose={() => setIsOpenConfirmRemove(false)}
            onConfirm={() => removeHandler(id)}
          />
        </ASharedModal>
      )}
    </div>
  )
}
export default Declaration
