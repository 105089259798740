import styled from 'styled-components'
import { ASharedModal, SharedModalContent } from 'views/components/SharedModal'

export const RematchingModalContent = styled(SharedModalContent)`
  display: grid;
  grid-template-columns: repeat(3, minmax(452px, 1fr));
  gap: 0 24px;
  flex: 1;
  max-height: unset;
`

export const RematchingModal = styled(ASharedModal)`
  > div {
    min-height: calc(100dvh - 48px);
    max-height: calc(100dvh - 48px);
  }
`
