import { FC } from 'react'
import { FormGroup, Input } from '@agro-club/agroclub-shared'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { SharedModalContent, SharedModalInputs, SharedModalTitle } from 'views/components/SharedModal'
import { Bank, DispatchingItem, DispatchingItemFormik, PaymentStatus } from '../../types'
import { REACT_APP_CURRENCY_SYMBOL } from 'env'
import FormSelectShared from 'views/components/FormSelectShared'
import { endpoints } from 'modules/endpoints'
import { useSingleEntity } from 'modules/domain/common/hooks'
import { apiClient } from 'modules/utils/httpClient'
import { ShortFormControl } from 'views/styled/common'
import ASharedModal from 'views/components/SharedModal/ASharedModal'
import { AnalyticsPlaces } from '../../analyticsPlaces'
import ADefaultModalFooter from 'views/components/DefaultModalFooter/ADefaultModalFooter'
import { useAFormHandler } from 'analytics/hooks'
import { HrWithHeader } from 'views/components/Hr/Hr'
import { Payments } from './Payments'
interface Props {
  onClose: () => void
  onSuccess: () => void
  id: string
  currentDates: string[] | undefined
}

export const DispatchingModal: FC<Props> = ({ onClose, onSuccess, id, currentDates }) => {
  const { t } = useTranslation('dispatching')
  const [, departureItem] = useSingleEntity<DispatchingItem>(endpoints.dispatchingItem(id))
  const [, banks] = useSingleEntity<Bank[]>(endpoints.dispatchingBanks())
  const currency = REACT_APP_CURRENCY_SYMBOL
  const { formProgress, formHandler } = useAFormHandler()

  const datesForRender = {}

  currentDates?.slice().forEach((item) => {
    datesForRender[item] = {
      amount: null,
      status: PaymentStatus.UNDEFINED_STATUS,
      bank: null,
      loan_agreement: null,
      source: null,
    }
  })

  const availableDataByDates =
    departureItem &&
    Object.entries(departureItem.payments).reduce((acc, [key, val]) => {
      if (!currentDates?.includes(key)) {
        return acc
      }
      acc[key] = {
        amount: val.amount,
        status: val.status,
        source: val.source,
        bank: val.bank?.id.toString(),
        loan_agreement: val.loan_agreement?.id.toString(),
      }
      return acc
    }, {})

  const formik = useFormik<DispatchingItemFormik>({
    initialValues: {
      price_exw: departureItem?.price_exw,
      trips_expected: departureItem?.trips_expected,
      status: departureItem?.status,
      payments: {
        ...datesForRender,
        ...availableDataByDates,
      },
    },
    enableReinitialize: true,
    onSubmit: formHandler(
      async () => {
        const preparedPayments = Object.entries(formik.values.payments as DispatchingItemFormik['payments']).map(
          ([key, value]) => ({
            ...value,
            payment_date: key,
          }),
        )

        return await apiClient.put<DispatchingItem>(endpoints.dispatchingItem(id), {
          price_exw: formik.values.price_exw,
          trips_expected: formik.values.trips_expected,
          status: formik.values.status,
          payments: preparedPayments,
        })
      },
      {
        onSuccess: async () => {
          await onSuccess()
          onClose()
        },
        onError: (error) => {
          const { errors } = error
          let paymentErrors
          if (errors.payments) {
            paymentErrors = errors.payments.reduce((acc, err, index) => {
              const date = Object.keys(formik.values.payments)[index]
              acc[date] = err
              return acc
            }, {})
            formik.setErrors({ ...errors, ...{ payments: paymentErrors } })
          } else {
            formik.setErrors(errors)
          }
        },
      },
    ),
  })

  return (
    <ASharedModal size="small" id={AnalyticsPlaces.DISPATCHING_MODAL} onClose={onClose}>
      <SharedModalTitle>{t('modal.title')}</SharedModalTitle>
      <SharedModalContent>
        <SharedModalInputs>
          <ShortFormControl>
            <FormGroup label={t('modal.inputs.price_exw', { currency })} error={formik.errors?.price_exw}>
              <Input {...formik.getFieldProps('price_exw')} invalid={!!formik.errors?.price_exw} type="number" />
            </FormGroup>
          </ShortFormControl>

          <ShortFormControl>
            <FormGroup label={t('table.trips_expected')} error={formik.errors?.trips_expected}>
              <Input
                {...formik.getFieldProps('trips_expected')}
                invalid={!!formik.errors?.trips_expected}
                type="number"
              />
            </FormGroup>
          </ShortFormControl>

          <ShortFormControl>
            <FormSelectShared
              label={t('modal.inputs.status')}
              formik={formik}
              endpoint={endpoints.dispatchingStatuses()}
              placeholder={t('modal.inputs.status_placeholder')}
              fieldName="status"
              simpleApiFormat
              getLabel={(dto) => dto.label}
              allowClear={false}
              showSearch={false}
            />
          </ShortFormControl>
          <HrWithHeader text={t('modal.date&Payments')} />
          {banks && <Payments formik={formik} banks={banks} />}
        </SharedModalInputs>
      </SharedModalContent>
      <ADefaultModalFooter
        onClose={onClose}
        progress={formProgress}
        confirmButtonText={t('common:save')}
        onConfirm={() => formik.submitForm()}
      />
    </ASharedModal>
  )
}
