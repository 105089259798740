import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { AddSpecificationModal } from 'views/components/SpecificationModals/AddSpecificationModal'
import { Icons } from '@agro-club/agroclub-shared'
import { Deal } from 'modules/domain/deal/types'
import { AButton } from 'views/components/Analytics'

interface Props {
  deal: Deal
  bindingSpecToDeal: (dealId: string, specificationId: string) => void
}

export const AddSpecification: FC<Props> = ({ deal, bindingSpecToDeal }) => {
  const { t } = useTranslation('common')
  const [isOpen, setIsOpen] = useState<boolean>(false)

  return (
    <>
      <AButton
        id="addSpecification"
        intent="approve"
        Icon={Icons.IconAdd}
        size="medium"
        onClick={() => setIsOpen(true)}
      >
        {t('add')}
      </AButton>
      {isOpen && (
        <AddSpecificationModal
          onClose={() => setIsOpen(false)}
          disableProduct
          deal={deal}
          bindingSpecToDeal={bindingSpecToDeal}
        />
      )}
    </>
  )
}
