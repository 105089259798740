import { FC } from 'react'
import { Text } from '@agro-club/agroclub-shared'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router'
import { Driver, LogisticsKRTrip } from 'views/pages/LogisticsKR/types'
import { TableTextLink } from 'views/ui/TableTextLink/TableTextLink'
import CarriersKRRoutes from 'views/pages/LogisticsKR/CarriersKR/routes'
import { DASH } from 'modules/constants'
import styled from 'styled-components'
import APhone from 'views/ui/Phone/APhone'

export const FlexColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export interface Props {
  driver: Driver
  logistics_request: LogisticsKRTrip
}

export const DriverCell: FC<Props> = ({ driver, logistics_request }) => {
  const { t } = useTranslation('logisticsKR')
  const { brand_model, licence_number, has_trailer, car_type, is_dump_truck, unloading_type, side_height } =
    logistics_request.car || {}

  const carParameters = [
    !!car_type ? t(`vehicle_type.${car_type}`) : DASH,
    !!is_dump_truck ? t('cars.is_dump_truck') : t('cars.is_not_dump_truck'),
    !!unloading_type ? t(`directions.${unloading_type}`) : DASH,
    !!side_height ? t('warehouses.details.values.scales_length', { scales_length: side_height }) : DASH,
  ].join(', ')
  return (
    <FlexColumnWrapper>
      <div>{driver?.name ? driver.name : DASH}</div>
      {driver?.phone ? <APhone phone={driver?.phone} /> : DASH}
      <Text typography="bodyMedium" color="secondary">
        {brand_model}, {licence_number}, {has_trailer ? t('trips.details.trailer') : DASH}, {carParameters}
      </Text>
      <TableTextLink
        id="carrierLink"
        to={generatePath(CarriersKRRoutes.Details, { id: logistics_request.carrier?.id || '' })}
      >
        {logistics_request.carrier?.name ?? DASH}
      </TableTextLink>
    </FlexColumnWrapper>
  )
}
