import { FC, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { Specification, SpecificationStatus } from 'modules/domain/specification/types'
import EditSpecificationModal from 'views/components/SpecificationModals/EditSpecificationModal'
import * as Layout from 'views/layouts/NewLayout'
import * as Header from 'views/ui/Header/Header'
import { LineSeparator } from 'views/ui/Header/Header'
import { useSingleEntity } from 'modules/domain/common/hooks'
import { endpoints } from 'modules/endpoints'
import SpecificationsRoutes from 'views/pages/Specifications/routes'

import { SharedModalContent, SharedModalTitle } from 'views/components/SharedModal'
import APageWrapper from 'views/components/PageWrapper/APageWrapper'
import { AnalyticsPages, AnalyticsPlaces } from '../analyticsPlaces'
import { AButton } from 'views/components/Analytics'
import ASharedModal from 'views/components/SharedModal/ASharedModal'
import ADefaultModalFooter from 'views/components/DefaultModalFooter/ADefaultModalFooter'
import { apiClient } from 'modules/utils/httpClient'
import { useHelmet } from '@agro-club/agroclub-shared'
import { useAProgress } from 'hooks/useAProgress'
import { SpecificationLeftBlock } from './SpecificationLeftBlock/SpecificationLeftBlock'
import { SpecificationRightBlock } from './SpecificationRightBlock/SpecificationRightBlock'

const SpecificationDetail: FC = () => {
  const { t } = useTranslation('specification')
  const { id } = useParams()
  const [, specification, specificationRefetch] = useSingleEntity<Specification>(endpoints.specifications(id))
  const [closeSpecificationModalIsOpen, setCloseSpecificationModalIsOpen] = useState(false)
  const [archivedSpecificationModalIsOpen, setArchivedSpecificationModalIsOpen] = useState(false)
  const [editModalIsOpen, setEditModalIsOpen] = useState(false)

  const [changeStatusProgress, changeStatus] = useAProgress(
    (status: string) => apiClient.post(endpoints.specificationChangeStatus(id), { new_status: status }),
    {
      eventName: 'changeSpecificationStatus',
      onSuccess: async () => {
        await specificationRefetch(true)
        setCloseSpecificationModalIsOpen(false)
        setArchivedSpecificationModalIsOpen(false)
      },
    },
  )

  useHelmet({ title: `${t('title')}${specification?.number}` })

  return (
    <APageWrapper page={AnalyticsPages.SPECIFICATION_DETAIL} place={AnalyticsPlaces.DETAIL} id={id}>
      {specification && (
        <>
          <Layout.WrapperContent>
            <Layout.Header>
              <Layout.TopHeader>
                <Header.Breadcrumbs
                  routes={[
                    {
                      path: SpecificationsRoutes.List,
                      breadcrumbName: t('specifications'),
                    },
                    {
                      breadcrumbName: `${t('title')}${specification?.number}`,
                    },
                  ]}
                />

                <Header.ButtonWrapper>
                  <AButton id="edit" intent="secondary" onClick={() => setEditModalIsOpen(true)} size="medium">
                    {t('common:edit')}
                  </AButton>
                  <AButton
                    id="close"
                    disabled={specification?.status !== SpecificationStatus.open}
                    onClick={() => setCloseSpecificationModalIsOpen(true)}
                    intent="secondary"
                    size="medium"
                  >
                    {t('close_specification')}
                  </AButton>
                  <LineSeparator />
                  <AButton
                    id="archive"
                    analyticsPayload={{ id }}
                    disabled={specification?.status !== SpecificationStatus.open}
                    onClick={() => setArchivedSpecificationModalIsOpen(true)}
                    intent="destruct"
                    size="medium"
                  >
                    {t('common:archive')}
                  </AButton>
                </Header.ButtonWrapper>
              </Layout.TopHeader>
              <Layout.DetailPageName>{`${t('title')}${specification?.number}`}</Layout.DetailPageName>
            </Layout.Header>
            <Layout.ContentTwoColumns>
              <SpecificationLeftBlock specification={specification} />
              <SpecificationRightBlock specification={specification} />
            </Layout.ContentTwoColumns>
          </Layout.WrapperContent>
          {editModalIsOpen && (
            <EditSpecificationModal
              onClose={() => setEditModalIsOpen(false)}
              specification={specification}
              refetch={specificationRefetch}
            />
          )}
          {closeSpecificationModalIsOpen && (
            <ASharedModal
              id={AnalyticsPlaces.CLOSE_MODAL}
              size="small"
              onClose={() => setCloseSpecificationModalIsOpen(false)}
            >
              <SharedModalTitle>{t('close_specification_title')}</SharedModalTitle>
              <SharedModalContent>{t('areYouSureToClosed')}</SharedModalContent>
              <ADefaultModalFooter
                onClose={() => setCloseSpecificationModalIsOpen(false)}
                onConfirm={() => changeStatus(SpecificationStatus.closed)}
                confirmButtonText={t('close_specification')}
                progress={changeStatusProgress}
              />
            </ASharedModal>
          )}
          {archivedSpecificationModalIsOpen && (
            <ASharedModal
              id={AnalyticsPlaces.ARCHIVE_MODAL}
              size="small"
              onClose={() => setArchivedSpecificationModalIsOpen(false)}
            >
              <SharedModalTitle>{t('archiving')}</SharedModalTitle>
              <SharedModalContent>{t('areYouSureToArchive')}</SharedModalContent>
              <ADefaultModalFooter
                onClose={() => setArchivedSpecificationModalIsOpen(false)}
                onConfirm={() => changeStatus(SpecificationStatus.archived)}
                progress={changeStatusProgress}
              />
            </ASharedModal>
          )}
        </>
      )}
    </APageWrapper>
  )
}

export default SpecificationDetail
