import { Header, PageName, TopHeader, WrapperContent } from 'views/layouts/NewLayout'
import { BidStatus, BidTypes, UIContractTypes } from 'modules/domain/bid/types'
import APageWrapper from 'views/components/PageWrapper/APageWrapper'
import { ContractsFilters } from './Filters/ContractsFilters'
import { ContractsTable } from './ContractTable/ContractsTable'
import { AntdTabsProps, useHelmet } from '@agro-club/agroclub-shared'
import { useNavigate, useParams } from 'react-router-dom'
import { useColumns } from './ContractTable/useColumns'
import { AAntdTabs } from 'views/components/Analytics'
import { useTabsData } from 'hooks/useTabsData'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { endpoints } from 'modules/endpoints'
import { Breadcrumbs } from '../Bid/styled'
import { generatePath } from 'react-router'
import BidContractsRoutes from './routes'

export const BidContractList: FC = () => {
  const { activeTab: activeTabParams } = useParams()
  const [activeTab, setActiveTab] = useState<string>(activeTabParams || UIContractTypes.purchase)
  const { t } = useTranslation('bidContracts')
  const navigate = useNavigate()
  useHelmet({ title: t('heading') })

  const initialTabsData = {
    [UIContractTypes.purchase]: {
      endpoint: endpoints.contractsWithType(BidTypes.purchase),
      label: t('purchase'),
      columnsFunc: useColumns,
    },
    [UIContractTypes.sale]: {
      endpoint: endpoints.contractsWithType(BidTypes.sale),
      label: t('sale'),
      columnsFunc: useColumns,
    },
  }

  const {
    updateCommonFilterState,
    commonRequestParams,
    clearAllTabsState,
    isFiltersLoaded,
    tablesSetters,
    tablesParams,
  } = useTabsData(activeTab, initialTabsData, {
    status: BidStatus.published,
  })

  const tabItems = () => {
    const tabs: AntdTabsProps['items'] = []
    for (const key of Object.keys(initialTabsData)) {
      tabs.push({
        label: initialTabsData[key].label,
        key,
        children: (
          <ContractsTable
            columnsFunc={initialTabsData[key].columnsFunc}
            dataEndpoint={initialTabsData[key].endpoint}
            setListRequestParams={tablesSetters[key]}
            listRequestParams={tablesParams[key]}
            isFiltersLoaded={isFiltersLoaded}
          />
        ),
      })
    }
    return tabs
  }

  useEffect(() => {
    navigate(
      {
        pathname: generatePath(BidContractsRoutes.ListWithTab, { activeTab: activeTab }),
        search: location.search,
      },
      { replace: true },
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab])

  return (
    <APageWrapper page="contractsPage" place="contractsList">
      <WrapperContent>
        <Header>
          <TopHeader>
            <Breadcrumbs routes={[{ breadcrumbName: t('heading') }]} />
          </TopHeader>
          <PageName>{t('heading')}</PageName>
          <ContractsFilters
            listRequestParams={commonRequestParams}
            updateFilterState={updateCommonFilterState}
            clearAllTabsState={clearAllTabsState}
          />
        </Header>

        <AAntdTabs items={tabItems()} onChange={setActiveTab} activeKey={activeTab} id="tabs" />
      </WrapperContent>
    </APageWrapper>
  )
}
