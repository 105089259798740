import { TaskItem } from './TaskItem'
import { NoTasks } from './NoTasks'
import { TaskManagerReturnType } from '../useUserTasksManager'
import { Progress, Spinner } from '@agro-club/agroclub-shared'
import { SpinnerWrapper } from 'views/styled/common'
import { User } from 'modules/domain/user/types'
import { refetchFunc } from 'modules/domain/common/hooks'

type Props = {
  taskManager: TaskManagerReturnType
  user: User
  userRefetch: refetchFunc
}

export const TaskBlockList = ({ taskManager, user, userRefetch }: Props) => {
  const { data, refetch, progress } = taskManager
  const isLoading = progress === Progress.WORK

  if (isLoading) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    )
  }

  return (
    <div>
      {data.length ? (
        data.map((item) => (
          <TaskItem key={item.id} user={user} task={item} tasksRefetch={refetch} userRefetch={userRefetch} />
        ))
      ) : (
        <NoTasks />
      )}
    </div>
  )
}
