import { CompanyRuEdit } from './CompanyGeneral/CompanyRuEdit'
import { EditWorldCompany } from '../WorldModal/EditWorldCompany'
import { Company } from 'modules/domain/company/types'
import { FC } from 'react'
import { isRussia } from 'env'

type Props = {
  setCompanyEditModalOpen: (payload: boolean) => void
  refreshCompany: () => void
  company: Company
}

export const EditCompanyControl: FC<Props> = ({ setCompanyEditModalOpen, company, refreshCompany }) => {
  if (isRussia) {
    return (
      <CompanyRuEdit onClose={() => setCompanyEditModalOpen(false)} refreshCompany={refreshCompany} company={company} />
    )
  }

  return (
    <EditWorldCompany
      refreshCompany={refreshCompany}
      company={company}
      onClose={() => setCompanyEditModalOpen(false)}
    />
  )
}
