import { FC, useEffect, useState } from 'react'
import { AntUpload, Progress } from '@agro-club/agroclub-shared'
import { RespFile } from 'modules/domain/types'

type FileList = {
  name: string
  uid: string
  url: string
}

export type InternalUploadFilesProps = {
  progress?: Progress
  removeFile: (any: any) => void
  addFile: (any: any) => void
  maxCountFiles?: number
  fileList: RespFile[] | undefined
  text?: string
  hint?: string
  maxWidth?: number
  readonly?: boolean
}

export const InternalUploadFiles: FC<InternalUploadFilesProps> = ({
  progress,
  removeFile,
  addFile,
  fileList,
  readonly,
  ...otherProps
}) => {
  // we need to store items being deleted because add/delete requests happen asynchronously
  const [fileUidsToRemove, setFileUidsToRemove] = useState<string[]>([])
  const [newFileList, setNewFileList] = useState<FileList[]>([])

  useEffect(() => {
    const newList = newFileList.filter((f) => !fileList?.find((file) => file.original_filename === f.name))
    setNewFileList(newList)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileList])

  const uploadedFiles: FileList[] = !!fileList?.length
    ? fileList.map((file) => {
        return {
          name: file.original_filename,
          uid: file.id,
          url: file.uploaded_file,
        }
      })
    : []

  const onAddFile = (file) => {
    setNewFileList(file.fileList.filter((f) => !f.url))
    addFile(file)
  }

  const onRemoveFile = (fileUid) => {
    setFileUidsToRemove([...fileUidsToRemove, fileUid])
    removeFile(fileUid)
  }

  // new files in progress of adding and uploaded files without files in progress of deletion
  const localFileList = [...newFileList, ...uploadedFiles.filter((f) => !fileUidsToRemove.find((uid) => uid === f.uid))]

  return (
    <AntUpload
      isLoading={progress === Progress.WORK}
      removeFile={onRemoveFile}
      addFile={onAddFile}
      fileList={localFileList}
      readonly={readonly}
      {...otherProps}
    />
  )
}
