import { Typography, defaultTheme } from '@agro-club/agroclub-shared'
import styled from 'styled-components'
import { CommonWrapper, SelectableWrapper, SelectableWrapperProps } from 'views/components/Card/Wrapper'

export const RematchingColumnTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 0 8px;
  ${Typography.titleH4};
`
export const QuantityBlock = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-flow: row;

  align-items: center;
  gap: 8px;
  .ant-skeleton-element,
  .ant-skeleton-button {
    height: 50px;
    width: 100%;
    border-radius: 4px;
  }
`

type SelectableQuantityProps = SelectableWrapperProps & { active?: Boolean }

export const SelectableQuantity = styled(SelectableWrapper).attrs<SelectableQuantityProps>((props) => ({
  className: props.active ? 'active' : '',
}))`
  gap: 0 4px;
  padding: 4px;
  border-radius: 4px;
  &:hover {
    background-color: ${defaultTheme.color.primary8a};
  }
  &.active {
    border-color: transparent;
    &:hover {
      background-color: transparent;
    }
    cursor: default;
  }
`
export const QuantityLabel = styled.div`
  color: ${defaultTheme.color.textSecondary60a};
`

export const RematchingColumn = styled(CommonWrapper)`
  background: white;
  border-radius: 8px;
  overflow-y: hidden;
  padding: 16px;

  max-height: calc(100dvh - 152px);
  overflow-x: hidden;
`
