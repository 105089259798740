const main = 'bids'
const relativeRoutes = {
  relativePage: `${main}/*`,
  relativeList: `${main}`,
  relativeDetail: ':id',
  relativeBidPrice: 'bid-price',
  relativePotentialBidsDetail: 'potential-bids/:id',
  relativeListWithTab: 'tab-:activeTab',
}

const routes = {
  List: `/${main}`,
  ListWithTab: `/${main}/${relativeRoutes.relativeListWithTab}`,
  Details: `/${main}/${relativeRoutes.relativeDetail}`,
  PotentialBidDetail: `/${main}/${relativeRoutes.relativePotentialBidsDetail}`,
}

const BidRoutes = { ...relativeRoutes, ...routes }

export default BidRoutes
