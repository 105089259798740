import { User } from 'modules/domain/user/types'
import { FC } from 'react'
import { TaskBlockHeader } from './TaskBlockHeader'
import { TaskBlockList } from './TaskBlockList'
import { TaskBlockFooter } from './TaskBlockFooter'
import styled from 'styled-components'
import { TaskManagerReturnType } from '../useUserTasksManager'
import { DontDisturbWarning } from './DontDisturbWarning'
import { refetchFunc } from 'modules/domain/common/hooks'

const TaskBlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
`

type Props = {
  openTaskModal: () => void
  taskManager: TaskManagerReturnType
  user: User
  userRefetch: refetchFunc
}

export const UserTasks: FC<Props> = ({ taskManager, openTaskModal, user, userRefetch }) => {
  return (
    <TaskBlockWrapper>
      {!!user?.profile?.do_not_call_reason && <DontDisturbWarning dontCallReason={user.profile.do_not_call_reason} />}

      <TaskBlockHeader user={user} userRefetch={userRefetch} taskManager={taskManager} />

      <TaskBlockList taskManager={taskManager} user={user} userRefetch={userRefetch} />

      <TaskBlockFooter taskManager={taskManager} openTaskModal={openTaskModal} />
    </TaskBlockWrapper>
  )
}
