import { AntTag } from '@agro-club/agroclub-shared'
import { isBidContractsEnabled } from 'env'
import { Bid, BidContract } from 'modules/domain/bid/types'
import { isBidContract } from 'modules/domain/bid/utils'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

export const BidContractTag: FC<{ bid: Bid | BidContract | null }> = ({ bid }) => {
  const { t } = useTranslation('bid')
  if (!isBidContractsEnabled || !bid) {
    return null
  }

  if (isBidContract(bid)) {
    return (
      <AntTag color="blue">
        {t('contracts.contract')} № {bid.number}
      </AntTag>
    )
  }

  return (
    <AntTag color="green">
      {t('contracts.offer')} ID {bid.id}
    </AntTag>
  )
}
