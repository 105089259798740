import { ASharedModal, SharedModalContent, SharedModalInputs, SharedModalTitle } from 'views/components/SharedModal'
import { ShortFormControl } from 'views/styled/common'
import { FormGroup, Input } from '@agro-club/agroclub-shared'
import { DatePicker } from 'views/components/DatePicker/DatePicker'
import { FormGroupUpload } from 'views/components/FormGroupUpload/FormGroupUpload'
import { useTranslation } from 'react-i18next'
import { FormikProps, useFormik } from 'formik'
import { SpecificationEditableFields } from 'views/components/SpecificationModals/types'
import ADefaultModalFooter from 'views/components/DefaultModalFooter/ADefaultModalFooter'
import { useAFormHandler } from 'analytics/hooks'
import { apiClient } from 'modules/utils/httpClient'
import { endpoints } from 'modules/endpoints'
import { Contract } from 'modules/domain/specification/types'
import { useNotificationProgress } from 'hooks/useNotificationProgress'

type Props = {
  specificationFormik: FormikProps<SpecificationEditableFields>
  companyId: string
  onClose: () => void
}

export const AddContractModal = ({ specificationFormik, onClose, companyId }: Props) => {
  const { t } = useTranslation('specification')
  const { formProgress, formHandler } = useAFormHandler('addCompanyContract')

  useNotificationProgress(formProgress, t('contract_add_success'))

  const contractFormik = useFormik({
    initialValues: {
      number: '',
      signed_date: '',
      files: [],
    },

    onSubmit: formHandler(
      async () => {
        const response = await apiClient.post<Contract>(endpoints.companyContracts(companyId), contractFormik.values)
        specificationFormik.setFieldValue('contract', response.id)
      },
      {
        onSuccess: onClose,
        onError: (error) => {
          const { errors } = error
          contractFormik.setErrors(errors)
        },
      },
    ),
  })
  return (
    <ASharedModal id="addCompanyContract" onClose={onClose}>
      <SharedModalTitle>{t('form.new_contract')}</SharedModalTitle>
      <SharedModalContent>
        <SharedModalInputs>
          <FormGroup label={t('form.contract_number')} error={contractFormik.errors.number} required>
            <Input {...contractFormik.getFieldProps('number')} invalid={!!contractFormik.errors.number} />
          </FormGroup>
          <ShortFormControl>
            <FormGroup label={t('form.signed_date')} error={contractFormik.errors.signed_date} required>
              <DatePicker
                onChange={(data) => contractFormik.setFieldValue('signed_date', data['date'])}
                error={!!contractFormik.errors.signed_date}
                placeholder={t('common:datePlaceholder')}
              />
            </FormGroup>
          </ShortFormControl>
          <FormGroupUpload
            maxWidthFileUpload={411}
            formik={contractFormik}
            fieldName="files"
            label={t('common:files')}
          />
        </SharedModalInputs>
      </SharedModalContent>
      <ADefaultModalFooter
        onClose={onClose}
        progress={formProgress}
        disabled={!contractFormik.dirty}
        confirmButtonText={t('common:save')}
        onConfirm={() => contractFormik.submitForm()}
      />
    </ASharedModal>
  )
}
