import { BorderedItem } from 'views/components/BorderedItem/BorderedItem'
import { Task } from 'views/pages/MyTasks/TaskDetail/types'
import APhone from 'views/ui/Phone/APhone'
import styled from 'styled-components'
import { AButton } from 'views/components/Analytics'
import { AntTag, Icons, Text } from '@agro-club/agroclub-shared'
import { useTranslation } from 'react-i18next'
import { useAProgress } from 'hooks/useAProgress'
import { apiClient } from 'modules/utils/httpClient'
import { endpoints } from 'modules/endpoints'
import { refetchFunc } from 'modules/domain/common/hooks'
import { useParams } from 'react-router-dom'
import { DASH } from 'modules/constants'

const ButtonsWrapper = styled.div`
  display: flex;
  column-gap: 8px;
`

interface Props {
  task: Task
  onSuccess: refetchFunc
}

export const UserForVerification = ({ task, onSuccess }: Props) => {
  const { t } = useTranslation('common')
  const { id: taskId } = useParams()
  const isRejected = !task.is_accepted && task.is_completed

  const [acceptProgress, acceptHandler] = useAProgress(
    async (companyId) =>
      await apiClient.post(endpoints.LogisticsKR.addCompanyEmployee(companyId ?? ''), {
        ...task.linked_user,
      }),
    {
      eventName: 'acceptNewEmployee',
      onSuccess: async () => {
        await apiClient.post(endpoints.completeTask(taskId))
        await onSuccess()
      },
    },
  )

  const [rejectUserProgress, rejectUserHandler] = useAProgress(
    (taskId) => apiClient.post(endpoints.completeTask(taskId)),
    {
      eventName: 'complete',
      analyticsPayload: {
        id: taskId,
      },
      onSuccess,
    },
  )

  const userStatusContent = () => {
    if (task.is_accepted) return <AntTag color="white">{t('approved')}</AntTag>

    if (isRejected) return <AntTag color="red">{t('rejected')}</AntTag>

    return (
      <ButtonsWrapper>
        <AButton
          onClick={() => acceptHandler(task.linked_carrier?.id)}
          progress={acceptProgress}
          id="accept"
          size="small"
          intent="secondary"
          Icon={Icons.IconCheck}
        >
          {t('accept')}
        </AButton>
        <AButton
          onClick={() => rejectUserHandler(taskId)}
          progress={rejectUserProgress}
          id="reject"
          size="small"
          intent="destruct"
          Icon={Icons.IconClear}
        >
          {t('reject')}
        </AButton>
      </ButtonsWrapper>
    )
  }

  return (
    <div>
      <BorderedItem>
        <Text>
          {task.linked_user?.full_name || task.linked_user?.short_name || DASH} /{' '}
          <APhone phone={task.linked_user?.phone || DASH} />
        </Text>
        {userStatusContent()}
      </BorderedItem>
    </div>
  )
}
