import styled from 'styled-components'

export const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 1038px;
  overflow-x: auto;
  column-gap: 24px;
  padding-top: 16px;
`
