import { ABaseTableFilter } from 'views/components/TableFilters/ABaseTableFilter'
import { AAntDatePicker } from 'views/components/Analytics'
import { ListRequestParams } from 'modules/domain/types'
import { useTranslation } from 'react-i18next'
import { endpoints } from 'modules/endpoints'
import styled from 'styled-components'
import { FC } from 'react'

const FiltersWrapper = styled.div`
  display: flex;
  gap: 0 16px;
  margin-bottom: 12px;
`
type Props = {
  updateFilterState: (filterObj: Record<string, any>) => void
  listRequestParams: ListRequestParams
}

export const Filters: FC<Props> = ({ updateFilterState, listRequestParams }) => {
  const { t } = useTranslation('')
  return (
    <FiltersWrapper>
      <AAntDatePicker
        onChange={(v) => updateFilterState({ created: v['date'] })}
        value={listRequestParams.filter.created}
        allowClear={true}
        place="SMSHistory"
        id="dateFilter"
        size="small"
      />
      <ABaseTableFilter
        onChange={(product_id) => updateFilterState({ product_id })}
        endpoint={endpoints.productsChoicesForSms()}
        value={listRequestParams.filter.product_id}
        placeholder={t('common:product')}
        getLabel={(dto) => dto.title}
        place="SMSHistory"
        showSearch={false}
        simpleApiFormat
        id="crop"
        multiple
      />
    </FiltersWrapper>
  )
}
