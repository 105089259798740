import { FC } from 'react'

import { useAnalyticsClickHandler, useModalPlace } from 'analytics/hooks'
import { InternalSharedModal, InternalSharedModalProps } from './InternalSharedModal'

interface Props extends Omit<InternalSharedModalProps, 'id'> {
  id: string
}

const ASharedModal: FC<Props> = ({ id, children, onClose, ...otherProps }) => {
  const handleClose = useAnalyticsClickHandler('close')
  useModalPlace(id)

  return (
    <InternalSharedModal onClose={handleClose(onClose)} {...otherProps}>
      {children}
    </InternalSharedModal>
  )
}

export default ASharedModal
