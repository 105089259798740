import { FC, useState } from 'react'

import { useTranslation } from 'react-i18next'
import * as Layout from 'views/layouts/NewLayout'
import * as Header from 'views/ui/Header/Header'

import { Icons, Progress, useHelmet } from '@agro-club/agroclub-shared'
import { useParams } from 'react-router-dom'
import ItemLoadingLayout from 'views/layouts/ItemLoadingLayout/ItemLoadingLayout'
import { useSingleEntity } from 'modules/domain/common/hooks'
import { endpoints } from 'modules/endpoints'
import { Customer } from 'modules/domain/logisticsKR/types'
import RightBlock from './components/RightBlock'
import { TwoColumns } from 'views/styled/detailedPage'
import CustomersKRRoutes from '../routes'
import { CustomerKRBoard } from './components/CustomerKRBoard'
import { LogisticsKRModal } from '../../LogisticRequests/LogisticsKRList/LogisticsKRListPopUp/LogisticsKRModal'
import { defaultListRequestParams } from 'views/components/TableFilters/data'
// import { CustomerEdit } from '../CustomerEdit/CustomerEdit'
import { HeaderButtons } from '../../components/styled'
import APageWrapper from 'views/components/PageWrapper/APageWrapper'
import { AnalyticsPages, AnalyticsPlaces } from '../../analyticsPlaces'
import { AButton } from 'views/components/Analytics'
import APhone from 'views/ui/Phone/APhone'
import { getPersonalNumberLabel } from 'modules/utils/numbers/formatPersonalNumber'

const CustomerKRDetails: FC = () => {
  const { t } = useTranslation('logisticsKR')
  const { id } = useParams()
  const [fetchProgress, customer] = useSingleEntity<Customer>(endpoints.LogisticsKR.customers(id))
  const [isRequestModalOpen, setIsRequestModalOpen] = useState<boolean>(false)
  // const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false)
  const [listRequestParams, setListRequestParams] = useState({
    ...defaultListRequestParams,
    filter: { customer: id },
    pageSize: 1000,
  })
  useHelmet({ title: customer?.name || '' })
  if (fetchProgress === Progress.WORK || !customer) return <ItemLoadingLayout id={id as string} />

  return (
    <APageWrapper page={AnalyticsPages.CUSTOMERS.DETAIL} place={AnalyticsPlaces.CUSTOMERS.DETAIL} id={id}>
      <Layout.WrapperContentFlex>
        <Layout.Header>
          <Layout.TopHeader>
            <Header.Breadcrumbs
              routes={[
                { path: CustomersKRRoutes.List, breadcrumbName: t('customers.heading') },
                { breadcrumbName: customer.id },
              ]}
            />
            <HeaderButtons>
              {/*               <AButton id="edit" intent="primary" size="medium" onClick={() => setIsEditModalOpen(true)}>
                {t('common:edit')}
              </AButton> */}
              <Header.LineSeparator />
              <AButton
                id="add"
                Icon={Icons.IconAdd}
                intent="approve"
                size="medium"
                // not in use now
                disabled
                onClick={() => setIsRequestModalOpen(true)}
              >
                {t('list.add')}
              </AButton>
            </HeaderButtons>
          </Layout.TopHeader>
          {isRequestModalOpen && (
            <LogisticsKRModal
              setIsOpen={setIsRequestModalOpen}
              onSuccess={() => setListRequestParams({ ...listRequestParams })}
            />
          )}
          {/* {isEditModalOpen && <CustomerEdit setIsOpen={setIsEditModalOpen} customer={customer} onSucess={refetch} />} */}
          <Layout.PageTitleWrapper>
            <Header.Title compact title={customer.name} />
            {`${getPersonalNumberLabel()}: ${customer.inn || t('common:notSpecified')}`} / {customer.contact_name} /{' '}
            <APhone phone={customer.phone} />
          </Layout.PageTitleWrapper>
        </Layout.Header>
        <TwoColumns>
          <CustomerKRBoard listRequestParams={listRequestParams} />
          <RightBlock customer={customer} />
        </TwoColumns>
      </Layout.WrapperContentFlex>
    </APageWrapper>
  )
}

export default CustomerKRDetails
