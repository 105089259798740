import { ResponsiveModal } from 'views/components/ResponsiveModal/ResponsiveModal'
import { DealStatus, DealStatusTypes } from 'modules/domain/deal/types'
import { DealStatusExecutorsModal } from './DealStatusExecutorsModal'
import { SharedModalContent } from 'views/components/SharedModal'
import { Progress } from '@agro-club/agroclub-shared'
import { useTranslation } from 'react-i18next'
import { FC } from 'react'
import { isUS } from 'env'

export type DealStatusModalProps = {
  changeStatusAction: (id?: string | undefined, _status?: string | undefined) => Promise<unknown> | void
  changeStatusProgress: Progress
  currentStatus?: DealStatus
  nextStatus: DealStatus
  onClose: () => void
  dealId: string
}

const needToShowExecutorsModal = (nextStatus) => {
  return isUS && nextStatus.slug === DealStatusTypes.NEW
}

export const DealStatusModal: FC<DealStatusModalProps> = (props) => {
  const { t } = useTranslation('logistics')

  const { currentStatus, dealId, changeStatusProgress, changeStatusAction, nextStatus, onClose } = props

  const handleConfirm = async () => {
    await changeStatusAction(dealId, nextStatus.slug)
    onClose()
  }

  if (needToShowExecutorsModal(nextStatus)) {
    return <DealStatusExecutorsModal onConfirm={handleConfirm} {...props} />
  }

  // \u00A0 - is non-breaking space to make sure that status names will be on one line
  const content = t('areYouSureToChangeStatus', {
    statusFrom: currentStatus ? currentStatus.label.replaceAll(' ', '\u00A0') : '',
    statusNew: nextStatus.label.replaceAll(' ', '\u00A0'),
  })

  return (
    <ResponsiveModal
      progress={changeStatusProgress}
      title={t('statusChanging')}
      onConfirm={handleConfirm}
      id="DealStatusModal"
      onClose={onClose}
      size="small"
    >
      <SharedModalContent>{content}</SharedModalContent>
    </ResponsiveModal>
  )
}
