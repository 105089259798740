import { QuantityInput } from 'views/components/QuantityInput/QuantityInput'
import { DatePicker } from 'views/components/DatePicker/DatePicker'
import { SharedModalInputs } from 'views/components/SharedModal'
import { labelTarif } from 'modules/utils/numbers/formatTarif'
import { FormGroup, Input } from '@agro-club/agroclub-shared'
import { Bid, BidContract, ContractPaymentType } from 'modules/domain/bid/types'
import { isPurchaseBid } from 'modules/domain/bid/utils'
import { ShortFormControl } from 'views/styled/common'
import { useTranslation } from 'react-i18next'
import { FormikProps } from 'formik'
import { FC } from 'react'
import FormSelectShared from 'views/components/FormSelectShared'
import { endpoints } from 'modules/endpoints'

export type ContractFormFields = Partial<BidContract> & { deal_id?: string }

type Props = {
  formik: FormikProps<ContractFormFields>
  bid: Bid
}

export const CommonFields: FC<Props> = ({ formik, bid }) => {
  const isPurchase = isPurchaseBid(bid)
  const { t } = useTranslation('bid')

  const onChangePaymentType = () => {
    formik.setFieldValue('payment_hour_period', undefined)
    formik.setFieldValue('payment_volume_period', undefined)
  }
  return (
    <SharedModalInputs>
      <FormGroup label={t('contracts.contracyNumber')} error={formik.errors.number} required>
        <Input {...formik.getFieldProps('number')} invalid={!!formik.errors.number} />
      </FormGroup>
      <ShortFormControl>
        <FormGroup label={t('contracts.dateSigning')} error={formik.errors.signed_at}>
          <DatePicker
            onChange={(data) => formik.setFieldValue('signed_at', data['date'])}
            error={!!formik.errors.signed_at}
            value={formik.values.signed_at}
            allowClear
          />
        </FormGroup>
        <FormSelectShared
          label={isPurchase ? t('contracts.purchaseFormPayment') : t('contracts.sellerFormPayment')}
          endpoint={endpoints.contractPaymentTypes()}
          getLabel={(dto) => dto.label}
          fieldName="payment_type"
          showSearch={false}
          formik={formik}
          simpleApiFormat
          placeholder=""
          onChange={onChangePaymentType}
        />
      </ShortFormControl>
      {formik.values.payment_type == ContractPaymentType.periodic_parametrized && (
        <>
          <FormGroup label={t('contracts.xHours')} error={formik.errors.payment_hour_period} required>
            <Input {...formik.getFieldProps('payment_hour_period')} invalid={!!formik.errors.payment_hour_period} />
          </FormGroup>
          <FormGroup label={t('contracts.xVolume')} error={formik.errors.payment_volume_period} required>
            <Input {...formik.getFieldProps('payment_volume_period')} invalid={!!formik.errors.payment_volume_period} />
          </FormGroup>
        </>
      )}

      <ShortFormControl>
        <FormGroup
          label={isPurchase ? t('contracts.purchasePaymentDate') : t('contracts.sellerPaymentDate')}
          error={formik.errors.payment_due_date}
          required
        >
          <DatePicker
            onChange={(data) => formik.setFieldValue('payment_due_date', data['date'])}
            error={!!formik.errors.payment_due_date}
            value={formik.values.payment_due_date}
          />
        </FormGroup>
      </ShortFormControl>
      <FormGroup label={labelTarif(t('common:price.default'))} error={formik.errors.price} required>
        <Input {...formik.getFieldProps('price')} invalid={!!formik.errors.price} />
      </FormGroup>
      <QuantityInput formik={formik} fieldName="quantity" label={t('common:volume')} />

      <ShortFormControl>
        <FormGroup label={t('contracts.shipmentFromDate')} error={formik.errors.shipment_from_date} required>
          <DatePicker
            onChange={(data) => formik.setFieldValue('shipment_from_date', data['date'])}
            error={!!formik.errors.shipment_from_date}
            value={formik.values.shipment_from_date}
          />
        </FormGroup>
        <FormGroup label={t('contracts.shipmentDueDate')} error={formik.errors.shipment_due_date} required>
          <DatePicker
            onChange={(data) => formik.setFieldValue('shipment_due_date', data['date'])}
            error={!!formik.errors.shipment_due_date}
            value={formik.values.shipment_due_date}
          />
        </FormGroup>
      </ShortFormControl>
    </SharedModalInputs>
  )
}
