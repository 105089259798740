import { ContractDeliveryPeriod } from 'views/pages/BidContracts/BidContractDetail/ContractDeliveryPeriod'
import { formatQuantity } from 'modules/utils/numbers/formatQuantity'
import { formatTarif } from 'modules/utils/numbers/formatTarif'
import { AntTag, Icons, defaultTheme } from '@agro-club/agroclub-shared'
import { ARouterLink } from 'views/components/Analytics'
import { BidContract, MatchingBidContract } from 'modules/domain/bid/types'
import useFormatDate from 'hooks/useFormatDate'
import BidRoutes from 'views/pages/Bid/routes'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router'
import { FC, ReactNode } from 'react'
import { RemainingQuantity, QuantityAndPrice, AddressAndUser, Address, DealTag, Header, Dates, Tags } from './styled'
import { MarginTag } from 'views/components/Tags/MarginTag'
import { CommonWrapper, SelectableWrapper } from 'views/components/Card/Wrapper'
import styled, { css } from 'styled-components'
import { formatDistance } from 'modules/utils/numbers/formatDistance'
import { isNumber } from 'lodash'

type Props = {
  contract: MatchingBidContract
  onSelect: (contract: BidContract | null) => void
  isSelected: boolean
  isPaired?: boolean
  children?: ReactNode
}

export const contractWrapperCSS = css`
  min-height: 206px;
  padding: 12px;
  min-width: 420px;
`

const ContractWrapper = styled(SelectableWrapper)`
  &:hover {
    background-color: ${defaultTheme.color.primary8a};
  }
  transition: all 0.3s ease;
  ${contractWrapperCSS}
`

const CommaSeparatedValues = styled.div`
  span {
    white-space: pre-wrap;
    &:not(:last-child)::after {
      content: ', ';
    }
    display: inline-flex;
  }
`

export const ContractCard: FC<Props> = ({ contract, onSelect, isPaired = false, isSelected = false, children }) => {
  const { t } = useTranslation('deal')
  const formatDate = useFormatDate()

  const handleClick = () => {
    if (isSelected) {
      onSelect(null)
      return
    }
    onSelect(contract)
  }
  const distance = contract.deal_preview?.distance
  const numbers = [
    formatQuantity(contract.rematching_quantity),
    `${contract.price_type} ${formatTarif(contract.price)}`,
    isNumber(distance) ? formatDistance(distance) : null,
  ].filter(Boolean)

  return (
    <ContractWrapper selected={isSelected} onClick={handleClick}>
      <Header>
        <Tags>
          {isPaired && (
            <DealTag color="blue">
              <Icons.IconDeals />
            </DealTag>
          )}

          <AntTag color="blue">№ {contract.number}</AntTag>
          {contract.signed_at && (
            <AntTag color="white">
              {t('rematch.signed')} {formatDate(contract.signed_at)}
            </AntTag>
          )}

          <MarginTag value={contract.deal_preview?.margin_percent} />
        </Tags>
      </Header>
      <CommonWrapper>
        <QuantityAndPrice>
          <ARouterLink
            eventName="bidLink"
            itemId={contract.bid_id}
            to={generatePath(BidRoutes.Details, {
              id: contract.bid_id,
            })}
          >
            {contract.product.title}
          </ARouterLink>

          <CommaSeparatedValues>
            {numbers.map((n, i) => (
              <span key={i}>{n}</span>
            ))}
          </CommaSeparatedValues>
          <RemainingQuantity>{`${t('rematch.initialVolume')}: ${formatQuantity(contract.quantity)}`}</RemainingQuantity>
        </QuantityAndPrice>
        <AddressAndUser>
          <div>{contract.company.name}</div>
          <Address> {contract.address.address}</Address>
        </AddressAndUser>
        <Dates>
          <div>{`${t('rematch.payment')} ${formatDate(contract.payment_due_date)}`}</div>
          <ContractDeliveryPeriod
            shipment_due_date={contract.shipment_due_date}
            shipment_from_date={contract.shipment_from_date}
            label={t('rematch.shipment')}
          />
        </Dates>
      </CommonWrapper>
      {children}
    </ContractWrapper>
  )
}
