import { ErrorInfo } from 'react'

import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'

import env from 'env'

import { BrowserTracing } from '@sentry/tracing'

class SentryAdapter {
  sentryInstance: typeof import('@sentry/browser') | null

  constructor() {
    this.sentryInstance = null
  }

  async init() {
    if (env.SENTRY_DSN) {
      this.sentryInstance = await import('@sentry/browser')
      this.sentryInstance.init({
        dsn: env.SENTRY_DSN,
        integrations: [new BrowserTracing()],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 0,
        enableTracing: false, // we don't use sentry perfomance right now
        ignoreErrors: [
          'ResizeObserver loop limit exceeded',
          'ResizeObserver loop completed with undelivered notifications',
        ], // https://agroclub.atlassian.net/browse/GM-1311
      })
    }
  }

  handleAxiosRequest(config: AxiosRequestConfig) {
    if (!this.sentryInstance) {
      return
    }
    this.sentryInstance.addBreadcrumb({
      category: 'request',
      type: 'http',
      data: {
        type: 'initiated',
        url: config.url,
        params: config.params,
        data: config.data,
      },
    })
  }

  handleAxiosError(error: AxiosError) {
    if (!this.sentryInstance) {
      return
    }
    this.sentryInstance.addBreadcrumb({
      category: 'request',
      type: 'http',
      data: {
        path: error.config?.url,
        method: error.config?.method,
        type: 'error',
        error: {
          message: error.message,
          name: error.name,
        },
      },
    })

    this.sentryInstance.withScope((scope) => {
      const sentry = this.sentryInstance
      if (!sentry) {
        return
      }

      const httpStatus = error.response?.status?.toString() || 'unknown'
      const apiHandle = error.config?.url as string

      if (error.response?.status && error.response.status < 500) {
        scope.setLevel('warning')
      } else {
        scope.setLevel('error')
      }

      scope.setTag('kind', 'apiRequest')
      scope.setTag('apiHandle', apiHandle)
      scope.setTag('httpStatus', httpStatus)
      scope.setExtras({
        httpStatus,
        apiHandle,
        method: error.config?.method,
        query: error.config?.params,
        data: error.config?.data,
        response: JSON.stringify(error?.response?.data),
      })
      const newError = new Error(`${error.message} ${error.config?.method} ${apiHandle}`, { cause: error })
      sentry.captureException(newError, {
        fingerprint: [apiHandle],
      })
    })
  }

  handleAxiosResponse(response: AxiosResponse) {
    if (!this.sentryInstance) {
      return
    }
    this.sentryInstance.addBreadcrumb({
      category: 'request',
      type: 'http',
      data: {
        path: response.config.url,
        method: response.config.method,
        type: 'response',
      },
    })
  }

  handleReactException(error: Error, errorInfo: ErrorInfo) {
    if (!this.sentryInstance) {
      return
    }
    this.sentryInstance.withScope((scope) => {
      scope.setExtra('ComponentStack', errorInfo.componentStack)
      if (this.sentryInstance) {
        this.sentryInstance.captureException(error)
      }
    })
  }

  logEvent(payload: string) {
    if (!this.sentryInstance) {
      return
    }
    this.sentryInstance.captureMessage(payload)
  }

  captureRawError(error: any) {
    if (!this.sentryInstance) {
      return
    }
    this.sentryInstance.captureException(error)
  }
}

const Singleton = new SentryAdapter()

export const Sentry = Singleton
