import { ShortFormControl } from 'views/styled/common'
import FormSelectShared from '../FormSelectShared'
import { useTranslation } from 'react-i18next'
import { endpoints } from 'modules/endpoints'
import { FC, useMemo } from 'react'
import { FormikFieldsType } from 'modules/domain/types'

interface Props {
  formik: FormikFieldsType
  teamField: string
  assigneeField: string
  required?: boolean
}

export const FormTeamAndAssignee: FC<Props> = ({ formik, teamField, assigneeField, required = true }) => {
  const { t } = useTranslation()

  const filterForAssignee = useMemo(
    () => (formik.values[teamField] ? { team: formik.values[teamField] } : null),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formik.values[teamField]],
  )

  return (
    <>
      <ShortFormControl>
        <FormSelectShared
          onChange={() => formik.setFieldValue(assigneeField, [])}
          endpoint={endpoints.tasksTeamsChoices()}
          placeholder={t('common:team')}
          getLabel={(dto) => dto.label}
          label={t('common:team')}
          fieldName={teamField}
          showSearch={false}
          formik={formik}
          simpleApiFormat
        />
      </ShortFormControl>

      <FormSelectShared
        endpoint={endpoints.taskAssignees()}
        placeholder={t('common:assignees')}
        getLabel={(dto) => dto.full_name}
        label={t('common:assignees')}
        filter={filterForAssignee}
        fieldName={assigneeField}
        searchField="search"
        formik={formik}
        simpleApiFormat
        required={required}
        multiple
      />
    </>
  )
}
