import formatNumber from 'modules/utils/numbers/formatNumber'
import { Text } from '@agro-club/agroclub-shared'
import styled from 'styled-components'
import { FC } from 'react'

const WrapperBoardItem = styled.div`
  display: flex;
  gap: 0 4px;
  padding: 1px 0;
`

export const BoardItem: FC<{ title: string; value: string; disabled?: boolean }> = ({ title, value, disabled }) => {
  const textColor = disabled ? 'secondary' : 'primary'
  return (
    <WrapperBoardItem>
      <Text color={textColor} typography="bodyMedium">
        {title}
      </Text>
      <Text color={textColor} typography="accentMedium">
        {formatNumber(value)}
      </Text>
    </WrapperBoardItem>
  )
}
