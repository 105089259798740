import { REACT_APP_CHATRA_API_KEY } from 'env'
import { generatePath } from 'react-router-dom'
import UserRoutes from 'views/pages/User/routes'
import Chatra from '@chatra/chatra'

const addChatraUserUnfo = (userData) => {
  if (REACT_APP_CHATRA_API_KEY) {
    const path = generatePath(UserRoutes.Details, { id: userData.id })
    const link = `${document.location.origin}${path}`
    const config = {
      ID: REACT_APP_CHATRA_API_KEY,
      integration: {
        name: userData?.profile?.full_name || '',
        phone: userData?.phone || '',
        team: userData?.profile?.team?.name || '',
        role: userData?.profile?.role || '',
        link,
      },
    }
    Chatra('init', config)
  }
}

export default addChatraUserUnfo
