import { useTranslation } from 'react-i18next'

import { FormGroup, Select } from '@agro-club/agroclub-shared'
import { FormikProps } from 'formik'

import { BidAddFields, BidPrice } from 'modules/domain/bid/types'
import { ShortFormControl } from 'views/styled/common'
import { FC } from 'react'
import { isUS } from 'env'

type Props = {
  formik: FormikProps<BidAddFields>
}

export const TypePriceSelect: FC<Props> = ({ formik }) => {
  const { t } = useTranslation(['common', 'bid'])
  return (
    <ShortFormControl>
      <FormGroup label={t('bid:form.delivery')} error={formik.errors.price_type} required>
        <Select
          onChange={(val) => formik.setFieldValue('price_type', val)}
          options={[
            { value: BidPrice.CPT, label: isUS ? t('common:delivered') : t('common:cpt') },
            { value: BidPrice.EXW, label: isUS ? t('common:fob') : t('common:exw') },
          ]}
          value={formik.values.price_type}
          showSearch={false}
          fullWidth
        />
      </FormGroup>
    </ShortFormControl>
  )
}
