import { AntSkeleton, ClickableTag, FormGroup, Progress, Text } from '@agro-club/agroclub-shared'
import { useSingleEntity } from 'modules/domain/common/hooks'
import { FormikFieldsType } from 'modules/domain/types'
import { endpoints } from 'modules/endpoints'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { FC } from 'react'

type Props = {
  formik: FormikFieldsType
}
const Tags = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  margin: auto 0;
`

export const FormTags: FC<Props> = ({ formik }) => {
  const { t } = useTranslation('my_tasks')
  const [progressReasons, reasons] = useSingleEntity<{ id: string; label: string }[]>(endpoints.doNotCallReasons())

  return (
    <FormGroup label={t('reason')}>
      {progressReasons === Progress.WORK && (
        <AntSkeleton.Button active size="large" style={{ width: 582, borderRadius: 4 }} />
      )}
      {reasons && (
        <Tags>
          {reasons.map((item) => (
            <ClickableTag
              key={item.id}
              onClick={() => formik.setFieldValue('unsuccessful_reason', item.id)}
              active={formik.values.unsuccessful_reason === item.id}
            >
              <Text typography="buttonSmall" color="forLink">
                {item.label}
              </Text>
            </ClickableTag>
          ))}
        </Tags>
      )}
    </FormGroup>
  )
}
