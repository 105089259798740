const main = 'new-carsKR'

const relativeRoutes = {
  relativePage: `${main}/*`,
  relativeList: `${main}`,
  relativeDetails: `:id`,
  relativeEdit: `:id/edit`,
}

const routes = {
  List: `/${main}`,
  Details: `/${main}/${relativeRoutes.relativeDetails}`,
  Edit: `/${main}/${relativeRoutes.relativeEdit}`,
}

const CarsKRRoutes = { ...relativeRoutes, ...routes }

export default CarsKRRoutes
