import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import FormSelectShared from '../FormSelectShared'
import { SharedModalContent, SharedModalTitle } from '../SharedModal/styled'
import { ModalContent } from './BlackListUserBtn'

import { useAFormHandler } from 'analytics/hooks'

import { endpoints } from 'modules/endpoints'
import { apiClient } from 'modules/utils/httpClient'
import ASharedModal from '../SharedModal/ASharedModal'
import { AnalyticsPlaces } from 'views/pages/Dashboard/analyticsPlaces'
import ADefaultModalFooter from '../DefaultModalFooter/ADefaultModalFooter'
import { refetchFunc } from 'modules/domain/common/hooks'

interface Props {
  onClose: () => void
  refetch: refetchFunc
  userId: string | undefined
}

export const BlackListUserModal: FC<Props> = ({ onClose, refetch, userId }) => {
  const { t } = useTranslation('task')
  const { formProgress, formHandler } = useAFormHandler('blackListUser', { id: userId })
  const formik = useFormik<{ do_not_call_reason: string }>({
    initialValues: {
      do_not_call_reason: '',
    },
    onSubmit: formHandler(
      async () => {
        await apiClient.post(endpoints.blackListUser(userId), formik.values)
      },
      {
        onSuccess: async () => {
          await refetch(true)
          onClose()
        },

        onError: (error) => {
          const { errors } = error
          formik.setErrors(errors)
        },
      },
    ),
  })

  return (
    <ASharedModal id={AnalyticsPlaces.BLACK_LIST_USER_MODAL} size="small" onClose={onClose}>
      <SharedModalTitle>{t('closeWithoutTaskHeader')}</SharedModalTitle>
      <SharedModalContent>
        <ModalContent>
          {t('closeWithoutTaskContent')}
          <FormSelectShared
            formik={formik}
            endpoint={endpoints.doNotCallReasons()}
            fieldName="do_not_call_reason"
            placeholder={t('closeWithoutTaskPlaceholder')}
            simpleApiFormat
            showSearch={false}
            getLabel={(dto) => dto.label}
            hideLabel
            disableRightPadding
            allowClear={false}
          />
        </ModalContent>
      </SharedModalContent>
      <ADefaultModalFooter
        onClose={onClose}
        onConfirm={() => formik.submitForm()}
        disabled={!formik.dirty}
        confirmButtonText={t('common:close')}
        progress={formProgress}
      />
    </ASharedModal>
  )
}
