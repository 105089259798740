import { FormikProps } from 'formik'
import { FC } from 'react'
import { ShipLoadWorkTypes } from 'modules/domain/deal/types'
import { t } from 'i18next'
import { FormEnumSelect } from 'views/components/form/EnumSelect/FormEnumSelect'

type Props = {
  formik: FormikProps<any>
}

export const ShipWorkTypeSelect: FC<Props> = ({ formik }) => {
  return (
    <FormEnumSelect
      formik={formik}
      fieldName="work_type"
      translationNameSpace={'deal:accordion.loading'}
      required
      allowClear
      label={t('deal:accordion.loading.operationType')}
      enumType={ShipLoadWorkTypes}
      placeholder={t('common:selectWorkType')}
    />
  )
}
