import SpinnerLayout from 'views/layouts/SpinnerLayout/SpinnerLayout'
import APageWrapper from 'views/components/PageWrapper/APageWrapper'
import { BidContract, BidStatus } from 'modules/domain/bid/types'
import { EditContractModal } from './Modals/EditContractModal'
import { useSingleEntity } from 'modules/domain/common/hooks'
import { CompleteContractBtn } from './CompleteContractBtn'
import { ContractStatusTag } from './ContractStatusTag'
import { CloseContractBtn } from './CloseContractBtn'
import { RightBlock } from './RightBlock/RightBlock'
import { AButton } from 'views/components/Analytics'
import { AntTag } from '@agro-club/agroclub-shared'
import { BidTypeTag } from 'views/components/Tags'
import * as Layout from 'views/layouts/NewLayout'
import { Deal } from 'modules/domain/deal/types'
import * as Header from 'views/ui/Header/Header'
import { useTranslation } from 'react-i18next'
import { endpoints } from 'modules/endpoints'
import { useParams } from 'react-router-dom'
import BidContractsRoutes from '../routes'
import { LeftBlock } from './LeftBlock'
import styled from 'styled-components'
import { FC, useState } from 'react'

const Wrapper = styled.div`
  margin-top: 16px;
`
const WrapperTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 0 8px;
`

export const BidContractDetail: FC = () => {
  const { t } = useTranslation('bidContracts')
  const [showModal, setShowModal] = useState(false)
  const { id } = useParams()

  const [, contract, refetch] = useSingleEntity<BidContract>(endpoints.contracts(id))
  const [contractDealsProgress, contractDeals] = useSingleEntity<Deal[]>(endpoints.contractDeals(id))

  if (!contract) {
    return <SpinnerLayout />
  }

  const hasBtn = contract.status == BidStatus.published && contractDeals
  const hasEditBtn = contract.status !== BidStatus.completed
  return (
    <APageWrapper page="contractDetail" place="ContractDetail" id={id}>
      <Layout.WrapperContent>
        <Layout.Header>
          <Layout.TopHeader>
            <Header.Breadcrumbs
              routes={[
                { path: BidContractsRoutes.List, breadcrumbName: t('heading') },
                { breadcrumbName: contract?.id },
              ]}
            />
            <Header.ButtonWrapper>
              {hasEditBtn && (
                <AButton id="edit" intent="secondary" size="medium" onClick={() => setShowModal(true)}>
                  {t('common:edit')}
                </AButton>
              )}
              {hasBtn && <CompleteContractBtn contract={contract} refetch={refetch} />}
              {hasBtn && <CloseContractBtn contract={contract} refetch={refetch} contractDeals={contractDeals} />}
            </Header.ButtonWrapper>
          </Layout.TopHeader>
        </Layout.Header>
        <WrapperTitle>
          <Header.Title compact noMargin title={`${t('contract')} №${contract.number}`} />
          <BidTypeTag bid={contract} />
          {contract.signed_at && <AntTag color="blue">{t('signed')}</AntTag>}
          <ContractStatusTag status={contract.status} />
        </WrapperTitle>

        <Wrapper>
          <Layout.ContentTwoColumns>
            <LeftBlock contractDeals={contractDeals} contract={contract} progress={contractDealsProgress} />
            <RightBlock />
          </Layout.ContentTwoColumns>
        </Wrapper>
      </Layout.WrapperContent>
      {showModal && <EditContractModal contract={contract} onClose={() => setShowModal(false)} refetch={refetch} />}
    </APageWrapper>
  )
}
