import { FC, ReactNode, useState } from 'react'

import { Icons } from '@agro-club/agroclub-shared'
import styled from 'styled-components'
import { BlackListUserModal } from './BlackListUserModal'
import { AButton } from '../Analytics'
import { useTranslation } from 'react-i18next'
import { refetchFunc } from 'modules/domain/common/hooks'

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`

type Props = {
  refetch: refetchFunc
  userId?: string
  forDashboard?: boolean
}

export const BlackListUserBtn: FC<Props> = ({ refetch, userId, forDashboard }) => {
  const { t } = useTranslation('my_tasks')
  const [isModalOpen, setIsModalOpen] = useState(false)

  let button: ReactNode
  if (forDashboard) {
    button = (
      <AButton
        id="openBlackListUserModal"
        analyticsPayload={{ id: userId }}
        intent="destruct"
        size="small"
        Icon={Icons.IconDelete}
        onClick={() => setIsModalOpen(true)}
        disabled={!userId}
      />
    )
  } else {
    button = (
      <AButton
        id="removeTasks"
        intent="whiteDestruct"
        analyticsPayload={{ id: userId }}
        onClick={() => setIsModalOpen(true)}
        disabled={!userId}
      >
        {t('dont_set_tasks')}
      </AButton>
    )
  }

  return (
    <>
      {button}
      {isModalOpen && <BlackListUserModal onClose={() => setIsModalOpen(false)} refetch={refetch} userId={userId} />}
    </>
  )
}
