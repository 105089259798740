import { AntSkeleton, Icons, Progress, Text, defaultTheme } from '@agro-club/agroclub-shared'
import {
  DispatchingItem,
  DispatchingStatuses,
  DispatchingListType,
  Payment,
  EmptyPaymentWithDate,
  PaymentWithDate,
} from '../../types'
import { KeyValue } from 'views/components/KeyValue/KeyValue'
import formatPrice from 'modules/utils/numbers/formatPrice'
import { refetchFunc } from 'modules/domain/common/hooks'
import { apiClient } from 'modules/utils/httpClient'
import { getColors } from '../../helpers'
import { useAProgress } from 'hooks/useAProgress'
import { Card } from 'views/components/Card/Card'
import { FC, useEffect, useState } from 'react'
import useFormatDate from 'hooks/useFormatDate'
import { useTranslation } from 'react-i18next'
import { endpoints } from 'modules/endpoints'
import { EditPayment } from './EditPayment'
import { DASH } from 'modules/constants'
import styled from 'styled-components'

type Props = {
  datesArray: string[]
  data: DispatchingListType
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px 0;
`
const PayBtn = styled.div<{ textColor?: string; bgColor?: string }>`
  padding: 8px;
  border-radius: 8px;
  box-sizing: border-box;
  border: 1px solid;
  border-color: ${({ bgColor }) => (bgColor ? 'transparent' : defaultTheme.color.secondary50)};
  background: ${({ bgColor }) => (bgColor ? bgColor : 'none')};
  color: ${({ textColor }) => textColor};
  display: flex;
  justify-content: space-between;
  align-items: center;
  path {
    fill: ${defaultTheme.color.primary600};
  }
`
const StyledText = styled(Text)`
  text-align: center;
`

const Preload = styled(AntSkeleton.Button)`
  border-radius: 4px;
  width: 100%;
  .ant-skeleton-button {
    width: 100%;
    height: 178px;
  }
`

export const PaymentDetails: FC<Props> = ({ datesArray, data }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [dispatchItem, setDispatchItem] = useState<DispatchingItem>()

  const isClosedDispatch = data.status === DispatchingStatuses.closed

  const { t } = useTranslation('dispatching')
  const formatDate = useFormatDate()

  const [progress, getDispatch] = useAProgress(
    () => apiClient.get<DispatchingItem>(endpoints.dispatchingItem(data.id)),
    {
      eventName: 'getDispatchingItem',
      onSuccess: (response) => setDispatchItem(response),
    },
  )
  useEffect(() => {
    if (!isClosedDispatch) {
      getDispatch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [currentData, setCurrentData] = useState<PaymentWithDate | EmptyPaymentWithDate>()

  const handlerModal = (isOpen: boolean, payload?: PaymentWithDate | EmptyPaymentWithDate) => {
    setIsOpen(isOpen)
    if (!payload) return
    setCurrentData(payload)
  }

  if (progress === Progress.WORK) return <Preload active size="small" />

  const paymentData: Array<PaymentWithDate | EmptyPaymentWithDate> = datesArray.map((date) => {
    // for open dispatch we get the data with a separate call because they can be edited
    // TODO check this fact
    const payment = (isClosedDispatch ? data : dispatchItem)?.payments[date] || ({} as Partial<Payment>)
    return {
      date,
      ...payment,
    }
  })
  return (
    <Wrapper>
      <StyledText typography="titleH4">{t('payments')}</StyledText>
      <Card.Grid cols={3} rowGap={8} columnGap={8}>
        {paymentData.map((item) => {
          const colors = getColors(item.status)
          return (
            <PayBtn
              onClick={() => handlerModal(true, item)}
              textColor={colors.text}
              bgColor={colors.background}
              key={item.date}
            >
              <KeyValue label={formatDate(item.date)}>
                <Text typography="bodySmall" style={{ color: colors.text }}>
                  {item.amount ? formatPrice(item.amount, true) : DASH}
                </Text>
              </KeyValue>
              {!item.amount && !isClosedDispatch && <Icons.IconPlus />}
            </PayBtn>
          )
        })}
      </Card.Grid>
      {currentData && dispatchItem && isOpen && (
        <EditPayment
          onClose={() => handlerModal(false)}
          refetch={getDispatch as refetchFunc}
          dispatchItem={dispatchItem}
          data={currentData}
        />
      )}
    </Wrapper>
  )
}
