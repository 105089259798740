import { Text, TextColor } from '@agro-club/agroclub-shared'
import { FC, ReactNode } from 'react'
import { DefaultTooltip } from '../DefaultTooltip/DefaultTooltip'

type Props = {
  text: ReactNode
  subText: ReactNode
  subColor?: TextColor
  tooltipContent?: ReactNode
}

export const TextWithSubText: FC<Props> = ({ tooltipContent, text, subText, subColor = 'primary' }) => {
  return (
    <DefaultTooltip tooltipContent={tooltipContent}>
      <div>
        <Text>{text}</Text>
        <Text color={subColor} typography="bodySmall">
          {subText}
        </Text>
      </div>
    </DefaultTooltip>
  )
}
