import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { AntTag } from '@agro-club/agroclub-shared'
import { Bid } from 'modules/domain/bid/types'
import useFormatDate from 'hooks/useFormatDate'
import { PotentialBid } from 'modules/domain/potentialBid/types'

type Props = {
  bid: Bid | PotentialBid
}

export const BidCreatedUpdatedTag: FC<Props> = ({ bid }) => {
  const { t } = useTranslation('bid')
  const formatDate = useFormatDate()
  return (
    <AntTag color="white">
      {t(`dateTag`, {
        created: formatDate(bid.created, true),
        updated: formatDate(bid.modified, true),
      })}
    </AntTag>
  )
}
