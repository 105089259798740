import { Company } from '../company/types'
import { Product } from '../types'

export enum ExportStatus {
  active = 'active',
  fulfilled = 'fulfilled',
  closed = 'closed',
}

export enum DutyType {
  FIXED = 'fixed',
  PERCENT = 'percent',
}

export enum DeliveryBasis {
  FOB = 'fob',
  CIF = 'cif',
}

export enum CurrencyCode {
  CNY = '3',
  EUR = '2',
  AED = '4',
  USD = '1',
  RUB = '37',
}

export const CurrencySign = {
  [CurrencyCode.CNY]: '¥',
  [CurrencyCode.EUR]: '€',
  [CurrencyCode.AED]: 'د.إ',
  [CurrencyCode.USD]: '$',
  [CurrencyCode.RUB]: '₽',
}

export type ExportDealType = {
  id: string
  company: Company
  owner: {
    id: string
    full_name: string
  }
  quantity: string
  loaded_quantity: string
  delivered_quantity: string
  remaining_quantity: string
  price_exw: string
  price_with_logistics: string
}

type EntityItem = {
  id: string
  name: string
  title: string
}

type ExportRate = {
  currency_id: CurrencyCode
  activation_date: string
  activation_date_rate: string
  create_date_rate: string
}

export type Export = {
  id: string
  product: Product
  quantity: string
  status: ExportStatus
  delivery_basis: DeliveryBasis
  number: string
  unique_contract_number: string
  contract_date: string
  consignment_date: string
  through_dubai: true
  duty_type: DutyType
  purchaser: EntityItem
  ship: EntityItem
  ship_owner: EntityItem
  warehouse: EntityItem
  export_broker: EntityItem
  freight_broker: EntityItem
  insurance: EntityItem
  surveyor: EntityItem
  price: string
  price_currency: CurrencyCode
  through_dubai_price: string
  through_dubai_price_currency: CurrencyCode
  through_dubai_localized_price: string
  freight_cost: string
  freight_currency: CurrencyCode
  freight_cost_localized_price: string
  warehouse_cost: string
  warehouse_currency: CurrencyCode
  warehouse_cost_localized_price: string
  storage_cost: string
  storage_currency: CurrencyCode
  storage_cost_localized_price: string
  export_broker_cost: string
  export_broker_currency: CurrencyCode
  export_broker_cost_localized_price: string
  freight_broker_cost: string
  freight_broker_currency: CurrencyCode
  freight_broker_cost_localized_price: string
  documents_cost: string
  documents_currency: CurrencyCode
  documents_cost_localized_price: string
  duty_cost: string
  duty_currency: CurrencyCode
  duty_cost_localized_price: string
  insurance_cost: string
  insurance_currency: CurrencyCode
  insurance_cost_localized_price: string
  surveyor_cost: string
  surveyor_currency: CurrencyCode
  surveyor_cost_localized_price: string
  total_margin: string
  exchange_rates: ExportRate[]
  fix_duty_value: string
  percentage_duty_value: string
  deal_weighted_avg_kg_price: string
}

export type HistoryType = {
  changes: Export
  date: string
  user: {
    id: string
    phone: string
    short_name: string
  }
}
