import APageWrapper from 'views/components/PageWrapper/APageWrapper'
import ATableFullPage from 'views/components/Table/ATableFullPage'
import { OrdersCondition } from 'modules/domain/logisticsKR/types'
import { Tab, useTab, useTabsDataV2 } from 'hooks/useTabsDataV2'
import { useNavigate, useParams } from 'react-router-dom'
import { useHelmet } from '@agro-club/agroclub-shared'
import { AAntdTabs } from 'views/components/Analytics'
import * as Layout from 'views/layouts/NewLayout'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { endpoints } from 'modules/endpoints'
import { generatePath } from 'react-router'
import PaymentOrdersRoutes from './routes'
import useColumns from './useColumns'
import { Filters } from './Filters'

export const PaymentOrdersList: FC = () => {
  const { t } = useTranslation('paymentOrders')
  useHelmet({ title: t('title') })

  const { activeTab: activeTabParams } = useParams()
  const [activeTab, setActiveTab] = useState<string>(activeTabParams || OrdersCondition.active)

  const columns = useColumns()

  const tabs: Record<string, Tab> = {}
  tabs[OrdersCondition.active] = useTab({
    endpoint: endpoints.LogisticsKR.activePaymentOrders(),
    columnsFunc: columns,
    label: t('active'),
  })
  tabs[OrdersCondition.archived] = useTab({
    endpoint: endpoints.LogisticsKR.archivedPaymentOrders(),
    label: t('archived'),
    columnsFunc: columns,
  })

  const { isFiltersLoaded, commonRequestParams, updateCommonFilterState, clearAllTabsState } = useTabsDataV2({
    activeTab,
    tabs,
  })

  const navigate = useNavigate()
  useEffect(() => {
    navigate(
      {
        pathname: generatePath(PaymentOrdersRoutes.ListWithTab, { activeTab: activeTab }),
        search: location.search,
      },
      { replace: true },
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab])

  const tabsItems = () => {
    const tabsJsx: any = []
    for (const key of Object.keys(tabs)) {
      tabsJsx.push({
        label: tabs[key].label,
        key,
        children: (
          <ATableFullPage
            dataEndpoint={tabs[key].endpoint || ''}
            listParamsUpdated={tabs[key].setter}
            listRequestParams={tabs[key].params}
            isFiltersLoaded={isFiltersLoaded}
            columns={tabs[key].columnsFunc}
            rowHoverGray
          />
        ),
      })
    }
    return tabsJsx
  }

  return (
    <APageWrapper page="PaymentOrders" place="PaymentOrdersList">
      <Layout.WrapperContent>
        <Layout.Header>
          <Layout.TopHeader>
            <Layout.Breadcrumbs routes={[{ breadcrumbName: t('title') }]} />
          </Layout.TopHeader>

          <Layout.PageName>{t('title')}</Layout.PageName>

          <Filters
            updateFilterState={updateCommonFilterState}
            listRequestParams={commonRequestParams}
            clearAllTabsState={clearAllTabsState}
          />
        </Layout.Header>
        <AAntdTabs items={tabsItems()} onChange={setActiveTab} activeKey={activeTab} id="tabs" />
      </Layout.WrapperContent>
    </APageWrapper>
  )
}
