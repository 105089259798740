import { FC, useEffect } from 'react'

import { DocumentFile, DocumentType } from 'modules/domain/types'
import { useTranslation } from 'react-i18next'
import { apiClient } from 'modules/utils/httpClient'
import { endpoints } from 'modules/endpoints'
import { uploadDocuments } from 'modules/domain/common/managers'
import { SubTitle } from 'views/components/FilesByTypes/styled'
import { AFilesByTypes } from 'views/components/FilesByTypes/AFilesByTypes'
import { useSingleEntity } from 'modules/domain/common/hooks'
import { Progress } from '@agro-club/agroclub-shared'

const mustBeSigned = ['company_documents', 'srts_documents', 'knd_1151162_form', 'copy_report_efs1', 'email_address']
const onTransporters = ['sts_or_declaration', 'patent_with_applications']

const docTypesGrouping = (docTypes: DocumentType[], filterArr: string[]) =>
  docTypes.filter((type) => filterArr.includes(type.slug))

type Props = {
  setSummary: (arg: number) => void
  carrierId: string
}

export const CarrierDocuments: FC<Props> = ({ carrierId, setSummary }) => {
  const { t } = useTranslation('logisticsKR')

  const [, documentTypes = []] = useSingleEntity<DocumentType[]>(endpoints.LogisticsKR.carrierDocumentsTypes(carrierId))
  const [progress, documents = [], documentsRefetch] = useSingleEntity<DocumentFile[]>(
    endpoints.LogisticsKR.carrierDocuments(carrierId),
  )

  useEffect(() => {
    if (progress === Progress.SUCCESS) setSummary(documents.length)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documents.length])

  const descriptions = {
    company_documents: t('carriers.accordion.descriptions.company_documents'),
    knd_1151162_form: t('carriers.accordion.descriptions.knd_1151162_form'),
    copy_report_efs1: t('carriers.accordion.descriptions.copy_report_efs1'),
  }

  const onUploadHandler = async (document_type, file) => {
    try {
      await uploadDocuments(endpoints.LogisticsKR.carrierDocuments(carrierId), document_type, file)
      documentsRefetch()
    } catch (err) {
      console.error(err)
    }
  }

  const onDeleteHandler = async (document_id) => {
    try {
      await apiClient.delete(endpoints.LogisticsKR.carrierDocument(carrierId, document_id))
      documentsRefetch()
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <>
      <SubTitle>{t('carriers.accordion.subtitles.all_documents_must_be_signed')}</SubTitle>
      <AFilesByTypes
        types={docTypesGrouping(documentTypes, mustBeSigned)}
        files={documents}
        onUpload={onUploadHandler}
        onDelete={onDeleteHandler}
        description={descriptions}
      />

      <SubTitle>{t('carriers.accordion.subtitles.all_documents_must_be_signed')}</SubTitle>
      <AFilesByTypes
        types={docTypesGrouping(documentTypes, onTransporters)}
        files={documents}
        onUpload={onUploadHandler}
        onDelete={onDeleteHandler}
      />
    </>
  )
}
