import { Typography, defaultTheme } from '@agro-club/agroclub-shared'
import { CSSProperties } from 'react'
import styled from 'styled-components'
import { TeamsProgressSize } from 'views/components/TeamsProgress/types'

export const ProgressContainer = styled.div<{ align?: CSSProperties['alignItems'] }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: ${({ align }) => (align ? align : 'inherit')};
`

export const ProgressHeader = styled.div<{ isActive: boolean; isTableCell?: boolean }>`
  display: flex;
  margin-bottom: ${({ isTableCell }) => (isTableCell ? '8px' : '4px')};
  color: ${({ isActive }) => (isActive ? defaultTheme.color.secondary900 : defaultTheme.color.textSecondary60a)};
`

export const Goal = styled.div<{ size?: TeamsProgressSize }>`
  ${({ size }) => {
    switch (size) {
      case 'small':
        return Typography.titleH4
      case 'large':
        return Typography.titleH2
      default:
        return ``
    }
  }};
`

export const Progress = styled.div`
  ${Typography.bodyLarge}
`

export const ProgressBarContainer = styled.div<{ isTableCell?: boolean }>`
  width: ${({ isTableCell }) => (isTableCell ? '100px' : '100%')};
  height: 8px;
  border-radius: 16px;
  background-color: ${({ theme }) => theme.color.backgroundTertiary};
`

export const ProgressLine = styled.div`
  height: 8px;
  border-radius: 16px;
`
