import { FC, useCallback } from 'react'
import { WrapperCards } from '../../../styled'
import { LogisticsKRTrip } from '../../../../types'
import { Progress } from '@agro-club/agroclub-shared'
import { PreloadCard } from 'views/components/Board/Preload'
import { refetchFunc } from 'modules/domain/common/hooks'
import { DraftTripCard } from 'views/pages/LogisticsKR/components/DraftTripCard/DraftTripCard'

type Props = {
  draftTrips: LogisticsKRTrip[] | undefined
  refetchDraftTrips: refetchFunc
  refetchInWorkRequestList: refetchFunc
  progress: Progress
}

export const DraftTrips: FC<Props> = ({ draftTrips, refetchDraftTrips, refetchInWorkRequestList, progress }) => {
  const onSuccess = useCallback(
    async (arg?: boolean) => {
      await refetchInWorkRequestList(arg)
      await refetchDraftTrips(arg)
    },
    [refetchInWorkRequestList, refetchDraftTrips],
  )

  if (progress === Progress.WORK) {
    return (
      <WrapperCards>
        <PreloadCard />
      </WrapperCards>
    )
  }

  return (
    <WrapperCards>
      {draftTrips?.map((trip) => (
        <DraftTripCard key={trip?.id} trip={trip} onSuccess={onSuccess} />
      ))}
    </WrapperCards>
  )
}
