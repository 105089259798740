import { SampleDetail } from './SampleDetail/SampleDetail'
import { SamplesList } from './SamplesList/SamplesList'
import { Routes, Route } from 'react-router-dom'
import SamplesRoutes from './routes'

function SamplesPage() {
  return (
    <Routes>
      <Route index element={<SamplesList />} />
      <Route path={SamplesRoutes.relativeDetail} element={<SampleDetail />} />
    </Routes>
  )
}

export default SamplesPage
