import { FC } from 'react'

import { isBrazil } from 'env'
import { useTranslation } from 'react-i18next'

interface Props {
  vat?: boolean
}

const Vat: FC<Props> = ({ vat }) => {
  const { t } = useTranslation('company')

  if (isBrazil) return null

  return (
    <div>
      {t('company:form.fields.vat')} {t(`company:form.prepaymentValues.${vat ? 'yes' : 'no'}`)}
    </div>
  )
}

export default Vat
