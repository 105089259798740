import { ABaseTableFilter } from 'views/components/TableFilters/ABaseTableFilter'
import { endpoints } from 'modules/endpoints'
import { BlackListUserBtn } from 'views/components/TaskActions/BlackListUserBtn'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { TaskManagerReturnType } from '../useUserTasksManager'
import { User } from 'modules/domain/user/types'
import { refetchFunc } from 'modules/domain/common/hooks'

const TaskListHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
`
const FiltersWrapper = styled.div`
  display: flex;
  gap: 0 16px;
`

type Props = {
  user: User
  taskManager: TaskManagerReturnType
  userRefetch: refetchFunc
}

export const TaskBlockHeader = ({ taskManager, user, userRefetch }: Props) => {
  const { refetch, listRequestParams, updateFilterState } = taskManager
  const { t } = useTranslation('task')

  const refetchHandler: refetchFunc = async (silent) => {
    await refetch(silent)
    await userRefetch(silent)
  }

  return (
    <>
      <TaskListHeader>
        <FiltersWrapper>
          <ABaseTableFilter
            onChange={(status) => updateFilterState({ status })}
            endpoint={endpoints.tasksStatuses()}
            value={listRequestParams.filter.status}
            placeholder={t('my_tasks:placeholders.status')}
            getLabel={(dto) => dto.label}
            showSearch={false}
            simpleApiFormat
          />
          <ABaseTableFilter
            onChange={(task_type) => updateFilterState({ task_type })}
            endpoint={endpoints.typesTask('user-list')}
            value={listRequestParams.filter.task_type}
            placeholder={t('my_tasks:placeholders.type_task')}
            getLabel={(dto) => dto.label}
            searchField="search"
            showSearch={false}
            simpleApiFormat
          />
        </FiltersWrapper>
        {!user?.profile?.do_not_call_reason && <BlackListUserBtn refetch={refetchHandler} userId={user.id} />}
      </TaskListHeader>
    </>
  )
}
