import { ReactComponent as IconMedium } from 'assets/icons/priorities/medium.svg'
import { ReactComponent as IconHigh } from 'assets/icons/priorities/high.svg'
import { ReactComponent as IconLow } from 'assets/icons/priorities/low.svg'
import formatNumber from 'modules/utils/numbers/formatNumber'
import formatPrice from 'modules/utils/numbers/formatPrice'
import { t } from 'i18next'
import { formatBool } from 'helpers/formatBool'
import { DateFormatted } from '../DateFormatted'
import { DASH } from 'modules/constants'

export const priority = (priority: number) => {
  switch (priority) {
    case 1:
      return <IconHigh />
    case 2:
      return <IconMedium />
    case 3:
      return <IconLow />
    default:
      return priority
  }
}

export const number = (number: number) => formatNumber(number)

export const price = (price) => {
  return price ? formatPrice(price) : DASH
}

export const haveOrNot = (value?: boolean) => formatBool(value, t('common:thereIs'))
export const YesNo = (value?: boolean) => formatBool(value)

export const date = (date) => (date ? <DateFormatted date={date} /> : DASH)

export const CellRenders = {
  priority,
  number,
  price,
  haveOrNot,
  YesNo,
  date,
}
