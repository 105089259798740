import { FC } from 'react'
import { ASharedModal, SharedModalContent, SharedModalTitle } from 'views/components/SharedModal'
import { useTranslation } from 'react-i18next'
import { DealShipment } from 'modules/domain/deal/types'
import { apiClient } from 'modules/utils/httpClient'
import { endpoints } from 'modules/endpoints'
import { useAProgress } from 'hooks/useAProgress'
import { AnalyticsPlaces } from 'views/pages/Deal/analyticsPlaces'
import ADefaultModalFooter from 'views/components/DefaultModalFooter/ADefaultModalFooter'

interface Props {
  dealId: string
  shipment: DealShipment
  onSuccess: () => Promise<void>
  onClose: () => void
}

export const ArchivedConfirmModal: FC<Props> = ({ dealId, onClose, shipment, onSuccess }) => {
  const { t } = useTranslation('deal')
  const [archivedProgress, archivedHandler] = useAProgress(
    (dealId: string, shipmentId: string) => apiClient.delete<DealShipment>(endpoints.dealShipment(dealId, shipmentId)),
    {
      eventName: 'archivedShipment',
      onSuccess,
    },
  )

  return (
    <ASharedModal id={AnalyticsPlaces.REMOVE_SHIPMENT_MODAL} onClose={onClose} size="small">
      <SharedModalTitle>{t('shipmentRemoveTitle')}</SharedModalTitle>
      <SharedModalContent>{t('shipmentRemoveBody')}</SharedModalContent>
      <ADefaultModalFooter
        progress={archivedProgress}
        onClose={onClose}
        onConfirm={() => archivedHandler(dealId, shipment.id)}
      />
    </ASharedModal>
  )
}
