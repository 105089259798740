import { FormAddress } from 'views/components/form/FormAddress/FormAddress'
import { BidAddFields } from 'modules/domain/bid/types'
import { ProfileType } from 'modules/domain/user/types'
import { TypePriceSelect } from '../TypePriceSelect'
import { useTranslation } from 'react-i18next'
import { isPriceTypeEnabled } from 'env'
import { FC, useEffect } from 'react'
import { FormikProps } from 'formik'
import { isDeliveryAddressRequired } from '../../helpers'

type Props = {
  formik: FormikProps<BidAddFields>
  ownerProfileType: ProfileType
  userId: string
}

export const TypePriceControl: FC<Props> = ({ formik, userId, ownerProfileType }) => {
  const { t } = useTranslation('bid')
  const needDeliveryData = isDeliveryAddressRequired(ownerProfileType, formik.values.price_type)

  useEffect(() => {
    if (needDeliveryData) {
      formik.setFieldValue('owner_delivery_address', undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [needDeliveryData])

  if (!isPriceTypeEnabled) {
    return null
  }

  return (
    <>
      <TypePriceSelect formik={formik} />
      {needDeliveryData && (
        <FormAddress
          fieldName="owner_delivery_address"
          label={t('bid:deliveryAddress')}
          formik={formik}
          userId={userId}
          required
        />
      )}
    </>
  )
}
