import { DashboardTab } from '../types'
import { ListRequestParams } from 'modules/domain/types'
import { useTranslation } from 'react-i18next'
import { RegionFilter } from './RegionFilter'
import styled from 'styled-components'
import { isBrazil, isUS } from 'env'
import { FC } from 'react'
import { USDashboardRegionFilter } from './USDashboardRegionFilter'
import { CommonRegionFilter } from 'views/components/TableFilters/RegionsFilters/CommonRegionFilter'

type Props = {
  updateFilterState: (filterObj: Record<string, any>) => void
  updateCommonFilterState: (filterObj: Record<string, any>) => void
  listRequestParams: ListRequestParams
  activeTab: DashboardTab
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 16px;
`

export const DashboardRegionFilter: FC<Props> = ({
  listRequestParams,
  activeTab,
  updateFilterState,
  updateCommonFilterState,
}) => {
  const { t } = useTranslation('dashboard')

  const matchesIsBrazil = activeTab === DashboardTab.matches && isBrazil
  const matchesIsUS = activeTab === DashboardTab.matches && isUS
  const saleRegionValue = listRequestParams.filter.sale_region
  const purchaseRegionValue = listRequestParams.filter.purchase_region

  if (matchesIsUS) {
    return <USDashboardRegionFilter listRequestParams={listRequestParams} updateFilterState={updateFilterState} />
  }

  return (
    <>
      {matchesIsBrazil ? (
        <Container>
          <RegionFilter
            onChange={(region) => updateFilterState({ sale_region: region })}
            value={saleRegionValue}
            placeholder={t('saleRegion')}
          />
          <RegionFilter
            onChange={(region) => updateFilterState({ purchase_region: region })}
            value={purchaseRegionValue}
            placeholder={t('purchaseRegion')}
          />
        </Container>
      ) : (
        <CommonRegionFilter listRequestParams={listRequestParams} updateFilterState={updateCommonFilterState} />
      )}
    </>
  )
}
