import { AntTag } from '@agro-club/agroclub-shared'
import { useTranslation } from 'react-i18next'
import { BadgesWrapper } from './styled'
import useFormatDate from 'hooks/useFormatDate'
import { DispatchingStatusesTags } from 'views/components/DispatchingStatusesTags/DispatchingStatusesTags'
import { TermsTag } from 'views/components/TermsTag/TermsTag'
import { LogisticRequest } from 'modules/domain/logisticsKR/types'

interface Props {
  request: LogisticRequest
}

export const TitleBadges = ({ request }: Props) => {
  const { deal_dispatching_status, logistics_status, created, start_date, close_date } = request
  const { t } = useTranslation('logisticsKR')
  const formatDate = useFormatDate()
  return (
    <BadgesWrapper>
      <DispatchingStatusesTags status={deal_dispatching_status} />
      <AntTag color="white">{t(`statuses.${logistics_status}`)}</AntTag>
      <AntTag color="white">{t('details.created_at', { date_time: formatDate(created, true) })}</AntTag>
      <TermsTag start_date={start_date} close_date={close_date} />
    </BadgesWrapper>
  )
}
