import { FC } from 'react'

import { useAnalyticsClickHandler } from 'analytics/hooks'
import InternalDefaultModalFooter, { InternalDefaultModalFooterProps } from './InternalDefaultModalFooter'

const ADefaultModalFooter: FC<InternalDefaultModalFooterProps> = ({ onClose, ...otherProps }) => {
  const handleClose = useAnalyticsClickHandler('close')

  return <InternalDefaultModalFooter onClose={handleClose(onClose)} {...otherProps} />
}

export default ADefaultModalFooter
