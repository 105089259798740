import { ResponsiveModal } from 'views/components/ResponsiveModal/ResponsiveModal'
import { DatePicker } from 'views/components/DatePicker/DatePicker'
import { AnalyticsPlaces } from 'views/pages/Deal/analyticsPlaces'
import { SharedModalInputs } from 'views/components/SharedModal'
import { refetchFunc } from 'modules/domain/common/hooks'
import { FormGroup } from '@agro-club/agroclub-shared'
import { apiClient } from 'modules/utils/httpClient'
import { useAFormHandler } from 'analytics/hooks'
import { Deal } from 'modules/domain/deal/types'
import { useTranslation } from 'react-i18next'
import { endpoints } from 'modules/endpoints'
import { useFormik } from 'formik'
import { FC } from 'react'

interface Fields {
  start_date: string
  close_date: string
}

interface Props {
  onClose: () => void
  deal: Deal
  onSuccess: refetchFunc
}

export const WithoutDocsModal: FC<Props> = ({ onClose, onSuccess, deal }) => {
  const { t } = useTranslation('deal')
  const { formProgress, formHandler } = useAFormHandler()

  const formik = useFormik<Fields>({
    initialValues: {
      start_date: '',
      close_date: '',
    },

    onSubmit: formHandler(
      async () => await apiClient.post(endpoints.dealWithoutDocumentRequest(deal.id), formik.values),

      {
        onSuccess: async () => {
          await onSuccess()
          onClose()
        },
        onError: (error) => {
          const { errors } = error
          formik.setErrors(errors)
        },
      },
    ),
  })

  return (
    <ResponsiveModal
      id={AnalyticsPlaces.WITHOUT_DOCUMENT_REQUEST_MODAL}
      title={t('without_docs_modal.title')}
      onConfirm={() => formik.submitForm()}
      confirmButtonText={t('common:save')}
      progress={formProgress}
      onClose={onClose}
    >
      <SharedModalInputs>
        <FormGroup label={t('without_docs_modal.start_date')} error={formik.errors.start_date} required>
          <DatePicker
            onChange={(data) => formik.setFieldValue('start_date', data['date'])}
            error={!!formik.errors.start_date}
            placeholder={t('common:datePlaceholder')}
          />
        </FormGroup>
        <FormGroup label={t('without_docs_modal.close_date')} error={formik.errors.close_date} required>
          <DatePicker
            onChange={(data) => formik.setFieldValue('close_date', data['date'])}
            error={!!formik.errors.close_date}
            placeholder={t('common:datePlaceholder')}
          />
        </FormGroup>
      </SharedModalInputs>
    </ResponsiveModal>
  )
}
