import { Icons, defaultTheme } from '@agro-club/agroclub-shared'
import { LinkProps } from 'react-router-dom'
import { AButton } from '../Analytics'
import styled from 'styled-components'
import { FC, ReactNode } from 'react'

const Wrapper = styled.div<{ border?: boolean }>`
  padding: 8px;
  border: ${({ border }) => (border ? `1px solid ${defaultTheme.color.secondary50}` : 'none')};
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
`

type Props = {
  to?: LinkProps['to']
  children: ReactNode
  border?: boolean
}

export const InfoLinkBox: FC<Props> = ({ to, children, border = true }) => {
  return (
    <Wrapper border={border}>
      <>{children}</>
      {to && <AButton target="_blank" id="link" intent="white" Icon={Icons.IconOpen} to={to} />}
    </Wrapper>
  )
}
