import { defaultTheme, Typography } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

const StatusStyle = `
  display: flex;
  align-items: center;
  cursor: pointer;
  ${Typography.bodySmall};

   svg {
    width: 32px;
    height: 100%;
    margin-right: 6px;
  }

  &:hover {
    color: ${defaultTheme.color.secondary900};

    svg,
     svg > path {
      fill: ${defaultTheme.color.secondary600};
    }

  }

    & > div {
      display: flex;
      align-items: center;
    }
`

type SgvStyleProps = {
  isVerified?: boolean
  isActive: boolean
  withSeparator?: boolean
}
export const WrapperIcon = styled.span`
  height: 32px;
`
export const Text = styled.span``

export const VerifiedByAll = styled.div`
  ${StatusStyle};
  pointer-events: none;

  svg > path {
    margin-right: 10px;
    fill: ${defaultTheme.color.primary600};
  }
`

export const BlockUser = styled.div<SgvStyleProps>`
  ${StatusStyle};
  position: relative;
  pointer-events: ${(props) => (props.isActive ? 'auto' : 'none')};
  color: ${(props) => (props.isActive ? defaultTheme.color.secondary600 : defaultTheme.color.secondary900)};

  svg > path {
    fill: ${(props) => (props.isActive ? defaultTheme.color.secondary300 : defaultTheme.color.accentDestruct800)};
  }

  &::before {
    content: '';
    position: absolute;
    display: ${({ withSeparator }) => (withSeparator ? 'block' : 'none')};
    width: 1px;
    height: 100%;
    left: -24px;
    background: ${defaultTheme.color.textQuarternary18a};
  }
`

export const Verified = styled.div<SgvStyleProps>`
  ${StatusStyle};
  color: ${(props) => (props.isVerified ? defaultTheme.color.secondary900 : defaultTheme.color.secondary600)};
  pointer-events: ${(props) => (props.isVerified ? 'none' : 'auto')};

  svg > path {
    fill: ${(props) => (props.isVerified ? defaultTheme.color.accentNotify600 : defaultTheme.color.secondary300)};
  }
`

export const KeyUser = styled.div<SgvStyleProps>`
  ${StatusStyle};
  color: ${(props) => (props.isVerified ? defaultTheme.color.secondary900 : defaultTheme.color.secondary600)};
  pointer-events: ${(props) => (props.isVerified ? 'none' : 'auto')};

  svg > path {
    fill: ${(props) => (props.isVerified ? defaultTheme.color.accentApprove600 : defaultTheme.color.secondary300)};
  }
`

export const WrapperCollapse = styled.div`
  .ant-collapse-content-box {
    padding-bottom: 15px;
  }
`
