import { TableTextLink } from 'views/ui/TableTextLink/TableTextLink'
import CarriersKRRoutes from 'views/pages/LogisticsKR/CarriersKR/routes'
import { generatePath } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Carrier } from 'modules/domain/logisticsKR/types'
import { FlexColumnWrapper } from '../styled'
import APhone from 'views/ui/Phone/APhone'

type Props = {
  carrier: Carrier
}

export const CarrierCell = ({ carrier }: Props) => {
  const { t } = useTranslation('logisticBids')

  return (
    <FlexColumnWrapper>
      <TableTextLink id={carrier.id} to={generatePath(CarriersKRRoutes.Details, { id: carrier.id })}>
        {carrier.name}
      </TableTextLink>
      <APhone phone={carrier?.phone} />
      <div>{carrier.has_vat ? t('vat.with_vat') : t('vat.not_vat')}</div>
    </FlexColumnWrapper>
  )
}
