import { FC } from 'react'
import { ItemCard, CardButtonWrapper, CardContentWrapper, TextCard } from 'views/styled/ItemCardStyles'
import { useTranslation } from 'react-i18next'
import { Icons } from '@agro-club/agroclub-shared'
import { generatePath } from 'react-router-dom'
import DealsKRRoutes from '../../../../LogisticRequests/routes'
import CompanyRoutes from '../../../../../Company/routes'
import { RequestType } from '../../../../types'
import { LogisticRequest } from 'modules/domain/logisticsKR/types'
import { formatQuantity } from 'modules/utils/numbers/formatQuantity'
import { formatTarif } from 'modules/utils/numbers/formatTarif'
import useFormatDate from '../../../../../../../hooks/useFormatDate'
import { useAnalyticsClickHandler } from 'analytics/hooks'
import { AButton, ARouterLink } from 'views/components/Analytics'

type Props = {
  request: LogisticRequest
  activeRequestId: string | undefined
  onSelectedChanged: (request: LogisticRequest) => void
}

export const RequestCard: FC<Props> = ({ request, activeRequestId, onSelectedChanged }) => {
  const handleClick = useAnalyticsClickHandler('request')
  const { t } = useTranslation('logisticsKR')
  const formatDate = useFormatDate()

  const requestInfo = [
    request.quantity && formatQuantity(request.quantity),
    request.distance && t('carriers.card.num_km', { num: request.distance }),
    request.price_logistics && formatTarif(request.price_logistics),
  ]
    .filter(Boolean)
    .join(', ')

  return (
    <ItemCard
      activeCard={request.id === activeRequestId}
      onClick={handleClick(() => onSelectedChanged(request), { id: request.id })}
    >
      <CardContentWrapper>
        <TextCard>
          {t('carriers.card.id_and_date', {
            id: request.id,
            date: formatDate(request.created),
          })}
        </TextCard>
        <div>
          <TextCard thumbnail>{`${request.product.name},`}</TextCard>
          <br />
          <TextCard thumbnail>{requestInfo}</TextCard>
        </div>
        <div>
          {request.loading_company?.id && (
            <ARouterLink
              eventName="loadingCompany"
              itemId={request.loading_company.id}
              to={generatePath(CompanyRoutes.Details, { id: request.loading_company.id })}
            >
              {request.loading_company?.name}
            </ARouterLink>
          )}
          <div>{request.loading_warehouse?.address}</div>
        </div>
        <div>
          {request.unloading_company?.id && (
            <ARouterLink
              eventName="unloadingCompany"
              itemId={request.unloading_company.id}
              to={generatePath(CompanyRoutes.Details, { id: request.unloading_company.id })}
            >
              {request.unloading_company?.name}
            </ARouterLink>
          )}
          <div>{request.unloading_warehouse?.address}</div>
        </div>
      </CardContentWrapper>
      <CardButtonWrapper>
        {request.source === RequestType.internal && (
          <AButton
            id="logisticsRequestLink"
            to={generatePath(DealsKRRoutes.Details, {
              id: request.id,
            })}
            Icon={Icons.IconOpen}
            intent="white"
          />
        )}
      </CardButtonWrapper>
    </ItemCard>
  )
}
