import { LogisticsKRCar } from '../../types'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import ASharedModal from '../../../../components/SharedModal/ASharedModal'
import { AnalyticsPlaces } from '../../analyticsPlaces'
import { SharedModalContent, SharedModalTitle } from 'views/components/SharedModal'
import ADefaultModalFooter from '../../../../components/DefaultModalFooter/ADefaultModalFooter'
import { Progress } from '@agro-club/agroclub-shared'

interface Props {
  car: LogisticsKRCar
  onClose: (arg: boolean) => void
  changeHandler: (carId: string) => Promise<unknown>
  progress: Progress
}

export const ToActiveConfirmModal: FC<Props> = ({ onClose, car, changeHandler, progress }) => {
  const { t } = useTranslation('logisticsKR')

  return (
    <ASharedModal id={AnalyticsPlaces.CARRIERS.CHANGE_CAR_STATUS_MODAL} size="small" onClose={() => onClose(false)}>
      <SharedModalTitle>{t('cars.confirmModals.statusChangingTitle')}</SharedModalTitle>
      <SharedModalContent>
        {car.ready_for_active
          ? t('cars.confirmModals.youReallyWantToChangeStatus')
          : t('cars.confirmModals.allFieldsMustBeFilledIn')}
      </SharedModalContent>
      <ADefaultModalFooter
        progress={progress}
        disabled={!car.ready_for_active}
        onConfirm={() => changeHandler(car.id)}
        onClose={() => onClose(false)}
      />
    </ASharedModal>
  )
}
