import { DateISO, FormGroup, Input } from '@agro-club/agroclub-shared'
import { FormikProps } from 'formik'
import { Bid, BidAddFields, BidEditableFields, BidPricePeriod } from 'modules/domain/bid/types'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { SharedModalSecondContent, SharedModalSubTitle } from 'views/components/SharedModal/styled'
import { BinButton } from 'views/ui/BinButton/BinButton'
import { ReactComponent as IconAdd } from 'assets/icons/addIcon.svg'
import { DateRangePicker } from 'views/components/DateRangePicker/DateRangePicker'
import { AButton } from 'views/components/Analytics'
import { DangerousHtml } from 'views/ui/DangerousHtml/DangerousHtml'
import { FC } from 'react'

type Props = {
  bid?: Bid
  name?: string
  priceLabel: string
  formik: FormikProps<BidAddFields> | FormikProps<BidEditableFields>
}

const Row = styled.div`
  display: flex;
  column-gap: 4px;
`

const DateRangeGroup = styled(FormGroup)`
  flex-basis: 40%;
`

const PriceGroup = styled(FormGroup)`
  flex-basis: 40%;
  column-gap: 8px;
  /* LabelContainer */
  div:first-child {
    width: auto;
    min-width: 0;
    white-space: nowrap;
  }
  /* ControlContainer */
  div:last-child {
    input {
      width: 168px;
    }
  }
`

export const PricePeriodsForm: FC<Props> = ({ name = 'price_periods', formik, priceLabel }) => {
  const periods: BidPricePeriod[] = formik.values[name]
  const { t } = useTranslation(['bid'])

  const handleChange = (idx, obj: Partial<BidPricePeriod>) => {
    formik.setFieldValue(`${name}[${idx}]`, {
      ...periods[idx],
      ...obj,
    })
  }
  const handleRemove = (idx) => {
    formik.setFieldValue(
      name,
      periods.filter((_, i) => i !== idx),
    )
  }
  const handleAdd = () => {
    formik.setFieldValue(name, [...periods, { start_date: undefined, end_date: undefined, price: undefined }])
  }

  const atLeastTwoItems = periods.length > 1
  // TODO WTF??? possibly need to delete this
  const dangerousPriceLabel = <DangerousHtml html={priceLabel.replace(/,/, ',<br/>')} />

  return (
    <SharedModalSecondContent>
      <SharedModalSubTitle>{t('form.pricePeriods')}</SharedModalSubTitle>
      {periods.map((period, idx) => {
        const errors = formik.errors[name]?.[idx]
        const priceError = errors?.price
        const rangeError = errors?.start_date || errors?.end_date
        const range = [period.start_date as DateISO, period.end_date as DateISO]
        return (
          <Row key={idx}>
            <DateRangeGroup error={rangeError}>
              <DateRangePicker
                error={rangeError}
                size="large"
                value={range}
                disabled={Boolean(period.id)}
                onChange={({ startDate, endDate }) => {
                  handleChange(idx, {
                    start_date: (startDate as DateISO) || undefined,
                    end_date: (endDate as DateISO) || undefined,
                  })
                }}
              />
            </DateRangeGroup>
            <PriceGroup label={dangerousPriceLabel} error={priceError}>
              <Input
                invalid={!!priceError}
                value={period.price || undefined}
                type="number"
                onChange={(e) => {
                  handleChange(idx, { price: e.target.value })
                }}
              />
            </PriceGroup>
            {atLeastTwoItems && <BinButton onClick={() => handleRemove(idx)} />}
          </Row>
        )
      })}

      <div>
        <AButton id="addPricePeriod" onClick={() => handleAdd()} intent="approve" Icon={IconAdd} size="medium">
          {t('form.addPricePeriod')}
        </AButton>
      </div>
    </SharedModalSecondContent>
  )
}
