import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Icons, defaultTheme, Text } from '@agro-club/agroclub-shared'

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  align-items: center;
  justify-content: center;

  svg {
    width: 212px;
    height: 212px;

    path {
      fill: ${defaultTheme.color.primary600};
    }
  }
`

const cursorPointer = { cursor: 'pointer' }

const ErrorLayout = () => {
  const { t } = useTranslation('errors')
  const navigate = useNavigate()

  return (
    <Wrapper>
      <Icons.IconMagicWand />
      <Text typography="titleH2">{t('something_went_wrong')}</Text>
      <div>
        <Text as="span">{t('go_back')}</Text>{' '}
        <Text style={cursorPointer} as="span" color="forLink" onClick={() => navigate(-1)}>
          {t('back_btn')}
        </Text>{' '}
        <Text as="span">{t('or')}</Text>{' '}
        <Text style={cursorPointer} as="span" color="forLink" onClick={() => location.reload()}>
          {t('reload_btn')}
        </Text>
      </div>
    </Wrapper>
  )
}

export default ErrorLayout
