import { endpoints } from 'modules/endpoints'
import { useTranslation } from 'react-i18next'
import { ABaseTableFilter } from '../TableFilters/ABaseTableFilter'
import { AnalyticsFilterVariant } from 'analytics/types'
import { FC } from 'react'

type Props = {
  eventId?: string
  value: string[]
  updateFilterState: (value: any) => void
}

export const NewTeamFilter: FC<Props> = ({ value, updateFilterState, eventId = AnalyticsFilterVariant.TEAMS }) => {
  const { t } = useTranslation()

  return (
    <ABaseTableFilter
      id={eventId}
      onChange={(team) => updateFilterState({ team })}
      value={value}
      placeholder={t('common:team')}
      getLabel={(label) => label.label}
      endpoint={endpoints.tasksTeams()}
      showSearch={false}
      listHeight={500}
      simpleApiFormat
      multiple
    />
  )
}
