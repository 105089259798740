import { ContractTag } from 'views/pages/Deal/DealDetail/DealTags/ContractTag'
import { Deal, DealStatusTypes, ListDeal } from 'modules/domain/deal/types'
import { DocumentsTag } from './DocumentsTag'
import { DirectorTag } from './DirectorTag'
import { isBrazil, isRussia, isUS } from 'env'
import { LogisticsTag } from './LogisticsTag'
import { SpecificationTag } from './SpecificationTag'
import { Tag1C } from './Tag1C'
import { SampleTag } from './SampleTag'
import { InstructionsTag } from './InstructionsTag'
import { isStatusAfter } from '../helpers'
import { PositionTag } from './PositionTag'

interface tagDataItem {
  render: (deal: Deal | ListDeal, key: any) => JSX.Element
  isVisible: (status) => boolean
}

export const useTags = (): tagDataItem[] => {
  const tags = [
    {
      render: (deal) => <PositionTag deal={deal} />,
      isVisible: () => isUS,
    },
    {
      render: (deal, key) => <InstructionsTag deal={deal} key={key} />,
      isVisible: (status) => isBrazil && isStatusAfter(status, DealStatusTypes.CONTRACT_SIGNED),
    },
    {
      render: (deal, key) => <SampleTag deal={deal} key={key} />,
      isVisible: (status) =>
        (!isBrazil && status === DealStatusTypes.IN_PROGRESS) || status === DealStatusTypes.CUSTOMER_NEGOTIATIONS,
    },
    {
      render: (deal, key) => <DirectorTag deal={deal} key={key} />,
      isVisible: (status) => isRussia && status === DealStatusTypes.AGREED,
    },
    {
      render: (deal, key) => <DocumentsTag deal={deal} key={key} />,
      isVisible: (status) => isRussia && status === DealStatusTypes.CONTRACT_SIGNED,
    },
    {
      render: (deal, key) => <LogisticsTag deal={deal} key={key} />,
      isVisible: (status) =>
        (status === DealStatusTypes.AGREED || status === DealStatusTypes.CUSTOMER_NEGOTIATIONS) && isRussia,
    },
    {
      render: (deal, key) => <SpecificationTag deal={deal} key={key} />,
      isVisible: (status) =>
        isRussia && (status === DealStatusTypes.AGREED || status === DealStatusTypes.CONTRACT_SIGNED),
    },
    {
      render: (deal, key) => <Tag1C deal={deal} key={key} />,
      isVisible: (status) => isRussia && status === DealStatusTypes.SHIPMENT_COMPLETE,
    },
    {
      render: (deal, key) => <ContractTag deal={deal} key={key} />,
      isVisible: (status) => isRussia && status === DealStatusTypes.AGREED,
    },
  ]

  return tags
}
