import { BidTypes } from '../bid/types'
import { Deal } from './types'

export const getDealContract = (deal: Deal, bidType: BidTypes) => deal[`${bidType}_contract`]

export const getDealBid = (deal: Deal, bidType: BidTypes) => deal[`${bidType}_bid`]

export const getDealPrice = (deal: Deal, bidType: BidTypes) => deal[`${bidType}_price`]

export const getDealBidAndContract = (deal: Deal, bidType: BidTypes) => ({
  bid: getDealBid(deal, bidType),
  contract: getDealContract(deal, bidType),
})

export const isDealClosed = (deal: Deal) => Boolean(deal.closed_at)
export const isDealOpen = (deal: Deal) => !isDealClosed(deal)
