import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Steps } from '@agro-club/agroclub-shared'
import { StepsWrapper } from './styled'
import { LogisticsKRPopUpFooter } from './LogisticsKRPopUpFooter'
import { Stepper } from './Stepper/Stepper'
import CommonInfoSchema from './Stepper/Steps/CommonInfo/CommonInfo.schema'
import { useFormik } from 'formik'
import { getStepSchema } from './helpers'
import LoadingParametersSchema from './Stepper/Steps/LoadingParameters/LoadingParameters.schema'
import UnloadingParametersSchema from './Stepper/Steps/UnloadingParameters/UnloadingParameters.schema'
import { SharedModalContent, SharedModalTitle } from 'views/components/SharedModal'
import { apiClient } from 'modules/utils/httpClient'
import { endpoints } from 'modules/endpoints'
import { replaceErrorsDotNotation } from 'modules/utils/helpers'
import { useAFormHandler } from 'analytics/hooks'
import ASharedModal from 'views/components/SharedModal/ASharedModal'

interface Props {
  setIsOpen: (val: boolean) => void
  onCancel?: () => void
  onSuccess?: () => void
}

export const LogisticsKRModal: FC<Props> = ({ setIsOpen, onCancel, onSuccess }) => {
  const { t } = useTranslation('logisticsKR')
  const [currentStep, setCurrentStep] = useState(0)
  const [isNewAddress, setIsNewAddress] = useState<boolean>(false)
  const [isNewUnloadingAddress, setIsNewUnloadingAddress] = useState<boolean>(false)
  const requiredFieldMessage = t('common.required_field')
  const stepsSchemaArray = [
    CommonInfoSchema(),
    LoadingParametersSchema(requiredFieldMessage),
    UnloadingParametersSchema(requiredFieldMessage),
  ]
  const { formProgress, formHandler } = useAFormHandler('addLogisticsRequest')

  const getRequestValues = (formik) => {
    const fields = {
      customer: formik.values.customer,
      product: formik.values.product,
      quantity: formik.values.quantity,
      price_logistics: formik.values.price_logistics,
      distance: formik.values.distance,
      loading_warehouse: {
        id: formik.values.loading_warehouse_id,
        address: formik.values.loading_warehouse_address,
        latitude: formik.values.loading_warehouse_latitude,
        longitude: formik.values.loading_warehouse_longitude,
        geo_object: formik.values.loading_warehouse_geo_object,
        gates_height: formik.values.loading_warehouse_gates_height,
        loader_type: formik.values.loading_warehouse_loader_type,
        scales_capacity: formik.values.loading_warehouse_scales_capacity,
        scales_remoteness: formik.values.loading_warehouse_scales_remoteness,
        scales_length: formik.values.loading_warehouse_scales_length,
        volume_per_day: formik.values.loading_warehouse_volume_per_day,
        vehicle_type: formik.values.loading_warehouse_vehicle_type,
        schedule_to: formik.values.loading_warehouse_schedule_from,
        schedule_from: formik.values.loading_warehouse_schedule_from,
        works_on_weekend: formik.values.loading_warehouse_works_on_weekend,
        car_delivery: formik.values.loading_warehouse_car_delivery,
        railway_delivery: formik.values.loading_warehouse_railway_delivery,
      },
      unloading_warehouse: {
        id: formik.values.unloading_warehouse_id,
        address: formik.values.unloading_warehouse_address,
        latitude: formik.values.unloading_warehouse_latitude,
        longitude: formik.values.unloading_warehouse_longitude,
        geo_object: formik.values.unloading_warehouse_geo_object,
        vehicle_type: formik.values.unloading_warehouse_vehicle_type,
        is_dump_truck: formik.values.unloading_warehouse_is_dump_truck,
        volume_per_day: formik.values.unloading_warehouse_volume_per_day,
        schedule_from: formik.values.unloading_warehouse_schedule_from,
        schedule_to: formik.values.unloading_warehouse_schedule_to,
        works_on_weekend: formik.values.unloading_warehouse_works_on_weekend,
        car_delivery: formik.values.unloading_warehouse_car_delivery,
        railway_delivery: formik.values.unloading_warehouse_railway_delivery,
        unloading_type: formik.values.unloading_warehouse_unloading_type,
      },
    }

    if (!formik.values.loading_warehouse_id) delete fields.loading_warehouse.id
    if (!formik.values.unloading_warehouse_id) delete fields.unloading_warehouse.id

    return fields
  }

  const formik = useFormik({
    initialValues: {
      customer: null,
      product: null,
      quantity: null,
      price_logistics: null,
      distance: null,
      loading_warehouse_id: null,
      loading_warehouse_address: null,
      loading_warehouse_latitude: null,
      loading_warehouse_longitude: null,
      loading_warehouse_geo_object: null,
      loading_warehouse_gates_height: null,
      loading_warehouse_loader_type: [],
      loading_warehouse_scales_capacity: null,
      loading_warehouse_scales_remoteness: null,
      loading_warehouse_scales_length: null,
      loading_warehouse_volume_per_day: null,
      loading_warehouse_vehicle_type: [],
      loading_warehouse_schedule_to: null,
      loading_warehouse_schedule_from: null,
      loading_warehouse_works_on_weekend: false,
      loading_warehouse_car_delivery: false,
      loading_warehouse_railway_delivery: false,
      unloading_warehouse_id: null,
      unloading_warehouse_address: null,
      unloading_warehouse_geo_object: null,
      unloading_warehouse_latitude: null,
      unloading_warehouse_longitude: null,
      unloading_warehouse_vehicle_type: [],
      unloading_warehouse_is_dump_truck: true,
      unloading_warehouse_volume_per_day: null,
      unloading_warehouse_schedule_from: null,
      unloading_warehouse_schedule_to: null,
      unloading_warehouse_works_on_weekend: false,
      unloading_warehouse_car_delivery: false,
      unloading_warehouse_railway_delivery: false,
      unloading_warehouse_unloading_type: null,
    },
    validationSchema: getStepSchema(currentStep, stepsSchemaArray),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: formHandler(
      async () => await apiClient.post(endpoints.LogisticsKR.requests(), getRequestValues(formik)),

      {
        onSuccess: async () => {
          await onSuccess?.()
          setIsOpen(false)
        },
        onError: (error) => {
          const { errors } = error
          formik.setErrors(replaceErrorsDotNotation(errors))
        },
      },
    ),
  })

  const handleCancel = () => {
    setIsOpen(false)
    setCurrentStep(0)
    formik.resetForm()
    onCancel?.()
  }

  const handleSubmit = () => {
    formik.submitForm()
  }

  return (
    <ASharedModal id="LogisticsKRModal" size="large" onClose={handleCancel}>
      <SharedModalTitle>{t('list.createForm.heading')}</SharedModalTitle>

      <StepsWrapper>
        <Steps direction="horizontal" current={currentStep}>
          <Steps.Step
            title={t('list.createForm.steps.firstStep.name')}
            description={t('list.createForm.steps.firstStep.description')}
          />
          <Steps.Step
            title={t('list.createForm.steps.secondStep.name')}
            description={t('list.createForm.steps.secondStep.description')}
          />
          <Steps.Step
            title={t('list.createForm.steps.thirdStep.name')}
            description={t('list.createForm.steps.thirdStep.description')}
          />
        </Steps>
      </StepsWrapper>

      <SharedModalContent>
        <Stepper
          currentStep={currentStep}
          formik={formik}
          isLoadingNewAddress={isNewAddress}
          setIsLoadingNewAddress={setIsNewAddress}
          isUnloadingNewAddress={isNewUnloadingAddress}
          setIsUnloadingNewAddress={setIsNewUnloadingAddress}
        />
      </SharedModalContent>

      <LogisticsKRPopUpFooter
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        formik={formik}
        progress={formProgress}
      />
    </ASharedModal>
  )
}
