import { Typography, AntdBreadcrumbs, defaultTheme } from '@agro-club/agroclub-shared'
import styled from 'styled-components'
import { FC, ReactNode } from 'react'

const RootStyled = styled.header`
  min-height: 80px;
  padding: 24px 0 16px;
  display: flex;
  background-color: transparent;
`

type TitleStyledProps = { compact?: boolean; noMargin?: boolean }

const TitleContainerStyled = styled.div<TitleStyledProps>`
  display: flex;
  align-items: center;
  margin-top: ${({ compact }: TitleStyledProps) => (compact ? '10px' : '0px')};
`

const TitleStyled = styled.div<TitleStyledProps>`
  display: flex;
  flex-direction: row;
  margin-right: ${(props) => (props.noMargin ? '0' : '20px')};
  color: ${defaultTheme.color.textPrimary900};
  ${({ compact }: TitleStyledProps) => (compact ? Typography.titleH3 : Typography.titleH2)};
`

export const Title: FC<{
  title: string
  compact?: boolean
  onClick?: () => void
  noMargin?: boolean
  children?: React.ReactNode
}> = ({ children, compact, title, onClick, noMargin }) => (
  <TitleContainerStyled onClick={onClick}>
    <TitleStyled noMargin={noMargin} compact={compact}>
      {title}
    </TitleStyled>
    {children}
  </TitleContainerStyled>
)

export const SubTitle = styled.div`
  ${Typography.bodyLarge}
  color: ${defaultTheme.color.textPrimary900};
  margin-top: 4px;
`
export const InlineContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0 10px;
`

export const ButtonWrapper = styled.div`
  display: grid;
  gap: 16px;
  grid-auto-flow: column;
  grid-auto-rows: max-content;
`

export const AbsoluteButtonWrapper = styled.div`
  position: absolute;
  top: 24px;
  right: 32px;
  display: grid;
  gap: 16px;
  grid-auto-flow: column;
  grid-auto-rows: max-content;
`

const RightBlock = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 0;
`

const LeftBlock = styled.div`
  flex: 1;
`

type HeaderProps = {
  rightBlock?: ReactNode | ReactNode[] | string
  children?: React.ReactNode
}

export const Root: FC<HeaderProps> = ({ rightBlock, children }) => (
  <RootStyled>
    <LeftBlock>{children}</LeftBlock>
    {!!rightBlock && <RightBlock>{rightBlock}</RightBlock>}
  </RootStyled>
)

export const LineSeparator = styled.span`
  width: 1px;
  height: 32px;
  background-color: ${(props) => props.theme.color.secondary100};
`

type BreadcrumbsProps = {
  routes: object[]
  otherProps?: any
}

const BreadcrumbsWrapper = styled.div`
  .ant-breadcrumb {
    ${Typography.bodyMedium}
    margin-bottom: 8px;
  }

  .ant-breadcrumb-link {
    a {
      color: ${defaultTheme.color.textPrimary900} !important;
    }

    span {
      color: ${defaultTheme.color.textSecondary60a};
    }
  }
`

export const Breadcrumbs: FC<BreadcrumbsProps> = (props) => {
  const { routes, ...otherProps } = props
  return (
    <BreadcrumbsWrapper>
      <AntdBreadcrumbs routes={routes} {...otherProps} />
    </BreadcrumbsWrapper>
  )
}
