import { FC, useState } from 'react'

import { Icons, Text } from '@agro-club/agroclub-shared'

import { Driver, DriverStatuses } from 'modules/domain/types'
import { endpoints } from 'modules/endpoints'
import { apiClient } from 'modules/utils/httpClient'
import { BorderedItem } from 'views/components/BorderedItem/BorderedItem'
import { SharedModalContent, SharedModalTitle } from 'views/components/SharedModal'
import { useTranslation } from 'react-i18next'
import { useNotificationProgress } from 'hooks/useNotificationProgress'
import { useAProgress } from 'hooks/useAProgress'
import ASharedModal from 'views/components/SharedModal/ASharedModal'
import { AnalyticsPlaces } from 'views/pages/LogisticsKR/analyticsPlaces'
import { AButton } from 'views/components/Analytics'
import ADefaultModalFooter from 'views/components/DefaultModalFooter/ADefaultModalFooter'
import { DetailsItemButtons, DetailsItemInfo } from 'views/pages/LogisticsKR/components/styled'
import { DriverEditModal } from 'views/pages/LogisticsKR/components/DriverModals/DriverEditModal'
import { refetchFunc } from 'modules/domain/common/hooks'
import APhone from 'views/ui/Phone/APhone'

interface Props {
  driver: Driver
  carrierId: string
  refetch: refetchFunc
}

export const DriverItem: FC<Props> = ({ driver, carrierId, refetch }) => {
  const { t } = useTranslation('logisticsKR')
  const [isModalOpened, setIsModalOpened] = useState(false)
  const [removeConfirmModal, setRemoveConfirmModal] = useState(false)
  const isArchive = driver.status === DriverStatuses.archived

  const [removeProgress, remove] = useAProgress(
    (driverId) => apiClient.delete(endpoints.LogisticsKR.drivers(driverId)),
    {
      eventName: 'removeDriver',
      onSuccess: async () => {
        await refetch()
        setRemoveConfirmModal(false)
      },
    },
  )

  useNotificationProgress(
    removeProgress,
    t('carriers.notifications.driver_archived_success'),
    t('carriers.notifications.driver_archived_error'),
  )

  return (
    <>
      <BorderedItem>
        <DetailsItemInfo>
          <Text color={isArchive ? 'secondary' : 'primary'}>
            {driver.name} {isArchive ? t('carriers.archived') : ''}
          </Text>
          {isArchive ? <Text color="tertiary">{driver.phone}</Text> : <APhone phone={driver.phone} />}
        </DetailsItemInfo>
        {!isArchive && (
          <DetailsItemButtons>
            <AButton id="editDriver" Icon={Icons.IconEdit} onClick={() => setIsModalOpened(true)} intent="white" />
            <AButton
              id="removeDriver"
              Icon={Icons.IconDelete}
              onClick={() => setRemoveConfirmModal(true)}
              intent="white"
            />
          </DetailsItemButtons>
        )}
      </BorderedItem>

      {isModalOpened && (
        <DriverEditModal
          onClose={() => setIsModalOpened(false)}
          carrierId={carrierId}
          onSuccess={refetch}
          driver={driver}
        />
      )}

      {removeConfirmModal && (
        <ASharedModal
          id={AnalyticsPlaces.CARRIERS.DELETE_DRIVER_MODAL}
          size="small"
          onClose={() => setRemoveConfirmModal(false)}
        >
          <SharedModalTitle>{t('carriers.confirmModals.archivedDriver')}</SharedModalTitle>
          <SharedModalContent>{t('carriers.confirmModals.youReallyWantArchivedDriver')}</SharedModalContent>
          <ADefaultModalFooter
            progress={removeProgress}
            onClose={() => setRemoveConfirmModal(false)}
            onConfirm={() => remove(driver.id)}
          />
        </ASharedModal>
      )}
    </>
  )
}
