import { User } from 'modules/domain/user/types'
import { useTranslation } from 'react-i18next'
import { FC, useState } from 'react'
import { AButton } from 'views/components/Analytics'
import { Icons, Text } from '@agro-club/agroclub-shared'
import { ProductTagsModal } from './ProductTagsModal'
import { Card } from 'views/components/Card/Card'
import { refetchFunc } from 'modules/domain/common/hooks'
import { isUserProductTagsEnabled } from 'env'
import styled from 'styled-components'
import { boxBordered } from 'views/styled/common'
import { Column } from '../styled'

const TagsContainer = styled.div`
  ${boxBordered};
  position: relative;
  padding: 9px 46px 9px 11px;
  min-height: 64px;
`

type Props = {
  user: User
  refetch: refetchFunc
}

export const ProductTags: FC<Props> = ({ user, refetch }) => {
  const { t } = useTranslation('user')
  const [showModal, setShowModal] = useState(false)

  if (!isUserProductTagsEnabled) return null

  if (!user.profile || !user.profile.product_tags) {
    return null
  }

  let tagsJsx

  if (user.profile.product_tags.length === 0) {
    tagsJsx = (
      <div style={{ marginTop: '4px' }}>
        <AButton
          id="addProductTag"
          Icon={Icons.IconAdd}
          intent="approve"
          size="medium"
          onClick={() => setShowModal(true)}
        >
          {t('common:add')}
        </AButton>
      </div>
    )
  } else {
    tagsJsx = (
      <TagsContainer>
        {user.profile.product_tags.map((t) => t.title).join(', ')}
        <Card.EditButton onClick={() => setShowModal(true)} />
      </TagsContainer>
    )
  }

  return (
    <>
      <Column>
        <Text color="secondary">{t('productTags.title')}</Text>
        {tagsJsx}
      </Column>
      {showModal && <ProductTagsModal setShowModal={setShowModal} user={user} refetch={refetch} />}
    </>
  )
}
