import { DateFormatted } from 'views/components/DateFormatted'
import styled from 'styled-components'
import { FC } from 'react'

const Dates = styled.div`
  display: flex;
  align-items: center;
  gap: 0 16px;
`
export const Delivery = styled.div`
  display: flex;
  gap: 0 4px;
`

export const ContractDeliveryPeriod: FC<{
  shipment_from_date: string | null
  shipment_due_date: string
  label?: string
}> = ({ shipment_from_date, shipment_due_date, label }) => {
  return (
    <Delivery>
      {label}
      <Dates>
        {shipment_from_date && (
          <>
            <DateFormatted date={shipment_from_date} />-
          </>
        )}
        <DateFormatted date={shipment_due_date} />
      </Dates>
    </Delivery>
  )
}
