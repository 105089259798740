import { CompanyControlsProps } from '../types'
import { FormGroup, Input } from '@agro-club/agroclub-shared'
import { useTranslation } from 'react-i18next'
import { ShortFormControl } from 'views/styled/common'

export const WorldCompanyControls = ({ formik }: CompanyControlsProps) => {
  const { t } = useTranslation('common')

  return (
    <>
      <FormGroup error={formik.errors.name} label={t('company')}>
        <Input
          placeholder={t('company_placeholder')}
          {...formik.getFieldProps('name')}
          invalid={!!formik.errors.name}
        />
      </FormGroup>
      <ShortFormControl>
        <FormGroup error={formik.errors.inn} label={t('inn')}>
          <Input placeholder={t('inn_placeholder')} {...formik.getFieldProps('inn')} invalid={!!formik.errors.inn} />
        </FormGroup>
      </ShortFormControl>
    </>
  )
}
