import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { FormGroup, Input } from '@agro-club/agroclub-shared'
import { useFormik } from 'formik'
import * as userManagers from 'modules/domain/user/managers'
import { EditModalProps, SpecificationEditableFields } from './types'
import { DatePicker } from 'views/components/DatePicker/DatePicker'
import { endpoints } from 'modules/endpoints'
import FormSelectShared from 'views/components/FormSelectShared'
import { SharedModalContent, SharedModalInputs, SharedModalTitle } from 'views/components/SharedModal'
import { ShortFormControl } from 'views/styled/common'
import { QuantityInput } from '../QuantityInput/QuantityInput'
import { labelTarif } from 'modules/utils/numbers/formatTarif'
import { useAFormHandler } from 'analytics/hooks'
import ASharedModal from '../SharedModal/ASharedModal'
import { AnalyticsPlaces } from 'views/pages/Specifications/analyticsPlaces'
import ADefaultModalFooter from '../DefaultModalFooter/ADefaultModalFooter'
import { apiClient } from 'modules/utils/httpClient'
import { SpecificationForm } from 'modules/domain/specification/types'

const EditSpecificationModal: FC<EditModalProps> = ({ specification, itemId, refetch, onClose }) => {
  const { t } = useTranslation('specification')
  const { formProgress, formHandler } = useAFormHandler('edit', { id: specification.id })
  const { id } = useParams()

  const formik = useFormik<SpecificationEditableFields>({
    initialValues: {
      number: specification?.number,
      address: String(specification?.address?.id),
      signed_date: specification?.signed_date,
      product: String(specification?.product?.id),
      price: specification?.price,
      quantity: specification?.quantity,
      deadline: specification?.deadline,
    },

    onSubmit: formHandler(
      async () => {
        const { number, address, signed_date, product, price, quantity, deadline } = formik.values
        const owner = specification?.owner?.id
        const company = specification?.company?.id
        const data = {
          owner,
          company,
          number,
          address,
          signed_date,
          product,
          price,
          quantity,
          deadline,
        }

        itemId
          ? await userManagers.editUserSpecification(id, itemId, data)
          : await apiClient.put<SpecificationForm>(endpoints.specifications(id), data)
      },
      {
        onSuccess: async () => {
          await refetch?.()
          onClose()
        },
        onError: (error) => {
          const { errors } = error
          formik.setErrors(errors)
        },
      },
    ),
  })

  return (
    <ASharedModal id={AnalyticsPlaces.UPDATE_MODAL} size="medium" onClose={onClose}>
      <SharedModalTitle>{t('common:editing')}</SharedModalTitle>
      <SharedModalContent>
        <SharedModalInputs>
          <ShortFormControl>
            <FormGroup label={t('form.number')} error={formik.errors.number} required>
              <Input {...formik.getFieldProps('number')} invalid={!!formik.errors.number} />
            </FormGroup>
            <FormGroup label={t('form.signed_date')} error={formik.errors.signed_date} required>
              <DatePicker
                onChange={(data) => formik.setFieldValue('signed_date', data['date'])}
                error={!!formik.errors.signed_date}
                placeholder={t('common:datePlaceholder')}
                value={formik.values.signed_date || undefined}
                allowClear
              />
            </FormGroup>
          </ShortFormControl>
          <FormSelectShared
            label={t('form.address')}
            formik={formik}
            endpoint={endpoints.userAddress(specification?.owner?.id)}
            fieldName="address"
            simpleApiFormat
            getLabel={(dto) => dto.address}
            required
          />
          <FormSelectShared
            label={t('form.product')}
            formik={formik}
            endpoint={endpoints.products()}
            fieldName="product"
            getLabel={(dto) => dto.title}
            simpleApiFormat
            required
          />
          <ShortFormControl>
            <QuantityInput formik={formik} fieldName="quantity" label={t('common:volume')} required />
            <FormGroup label={labelTarif(t('common:price.default'))} error={formik.errors.price} required>
              <Input {...formik.getFieldProps('price')} invalid={!!formik.errors.price} type="number" />
            </FormGroup>
            <FormGroup label={t('form.deadline')} error={formik.errors.deadline} required>
              <DatePicker
                onChange={(data) => formik.setFieldValue('deadline', data['date'])}
                error={!!formik.errors.deadline}
                placeholder={t('common:datePlaceholder')}
                value={formik.values.deadline || undefined}
                allowClear
              />
            </FormGroup>
          </ShortFormControl>
        </SharedModalInputs>
      </SharedModalContent>
      <ADefaultModalFooter
        onClose={onClose}
        onConfirm={() => formik.submitForm()}
        progress={formProgress}
        confirmButtonText={t('common:save')}
        disabled={!formik.dirty}
      />
    </ASharedModal>
  )
}

export default EditSpecificationModal
