import { labelQuantity } from 'modules/utils/numbers/formatQuantity'
import formatNumber from 'modules/utils/numbers/formatNumber'
import useFormatDate from 'hooks/useFormatDate'
import { useTranslation } from 'react-i18next'
import { ColumnData } from 'modules/types'
import { useMemo } from 'react'
import { labelPriceLogistics } from 'modules/utils/numbers/formatPriceLogistics'
import { labelPrice } from 'modules/utils/numbers/formatPrice'

export const useColumns = (): ColumnData[] => {
  const { t } = useTranslation('deal')
  const formatDate = useFormatDate()

  return useMemo(
    () => [
      {
        title: t('shipments.pick_up_date'),
        width: 136,
        align: 'left',
        dataIndex: 'picked_up_at',
        render: (picked_up_at) => formatDate(picked_up_at),
      },
      {
        title: labelQuantity(t('shipments.picked_up_quantity')),
        width: 136,
        align: 'right',
        dataIndex: 'pick_up_quantity',
        render: (pick_up_quantity) => formatNumber(pick_up_quantity, true),
      },
      {
        title: t('shipments.delivery_date'),
        width: 136,
        align: 'left',
        dataIndex: 'delivered_at',
        render: (delivered_at) => formatDate(delivered_at),
      },
      {
        title: labelQuantity(t('shipments.delivered_quantity')),
        width: 136,
        align: 'right',
        dataIndex: 'quantity',
        render: (quantity) => formatNumber(quantity, true),
      },
      {
        title: labelPriceLogistics(t('fields.freight_rate_short')),
        width: 136,
        align: 'right',
        dataIndex: 'price_logistics',
        render: (price_logistics) => formatNumber(price_logistics, true),
      },
      {
        title: labelPrice(t('fields.marginShort')),
        width: 200,
        align: 'right',
        dataIndex: 'margin',
        render: (margin) => formatNumber(margin, true),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t],
  )
}
