import { useTranslation } from 'react-i18next'

import { Input } from '@agro-club/agroclub-shared'
import { SideHeightFilterWrapper } from '../../styled'

import { ListRequestParams } from 'modules/domain/types'
import { FC } from 'react'

type Props = {
  listRequestParams: ListRequestParams
  updateFilterState: (filterObj: Record<string, any>) => void
}

export const SideHeightFilter: FC<Props> = ({ listRequestParams, updateFilterState }) => {
  const { t } = useTranslation('logisticsKR')

  return (
    <SideHeightFilterWrapper>
      <Input
        placeholder={t('cars.filters.side_height')}
        value={listRequestParams.filter.side_height_range_max}
        onChange={(e) => {
          updateFilterState({ side_height_range_max: e.target.value })
        }}
      />
    </SideHeightFilterWrapper>
  )
}
