import { Icons } from '@agro-club/agroclub-shared'
import { useEventCount } from 'modules/socket/hooks'
import { OptionItem } from '../types'
import { useFilteredOptionsByPerms } from '../utils'
import { OPTIONS } from './CommonOptions'
import useMenuOptions from './useMenuOptions'

const DESKTOP_OPTIONS: OptionItem[] = [
  OPTIONS.DASHBOARD,
  OPTIONS.CALCULATOR,
  OPTIONS.MY_TASKS,
  OPTIONS.TEAMS,
  OPTIONS.COMPANIES,
  OPTIONS.USERS,
  OPTIONS.BIDS,
  OPTIONS.BID_CONTRACTS,
  OPTIONS.DEALS,
  OPTIONS.WAREHOUSES,
  {
    key: 'newLogistics',
    translateKey: 'newLogistics',
    icon: <Icons.IconLocation />,
    eventModel: ['tripoffer', 'tripdocument'],
    access: { extra: true },
    children: [
      OPTIONS.LOGISTICS_REQUESTS,
      OPTIONS.CARRIERS,
      OPTIONS.CARS,
      OPTIONS.PAYMENT_ORDERS,
      OPTIONS.RATES,
      OPTIONS.ROUTES,
      OPTIONS.CUSTOMERS,
      OPTIONS.ADDRESSES,
    ],
  },
  OPTIONS.EXPORT,
  OPTIONS.SHIPMENTS_CONTROL,
  OPTIONS.DISPATCHING,
  {
    translateKey: 'calls',
    icon: <Icons.IconPhone />,
    key: 'calls',
    access: { extra: true },
    children: [OPTIONS.CALLS_LIST, OPTIONS.CALL_STATISTICS, OPTIONS.WHATS_APP],
  },
  OPTIONS.SPECIFICATIONS,
  OPTIONS.SAMPLES,
  {
    key: 'documents',
    icon: <Icons.IconGdprData />,
    translateKey: 'documents',
    eventModel: ['documentsrequest', 'salecontractrequest'],
    access: { extra: true },
    children: [OPTIONS.DOCUMENTS_LIST, OPTIONS.CONTRACTS],
  },
]

export const useDesktopOptions = () => {
  const eventsCount = useEventCount()
  const filteredByPerms = useFilteredOptionsByPerms(DESKTOP_OPTIONS)

  const { menuOptions } = useMenuOptions(eventsCount, filteredByPerms)

  menuOptions.forEach((item) => {
    if (item.children?.length) {
      item.children = item.children.map(({ icon: _, ...rest }) => {
        return { ...rest }
      })
    }
  })

  return { menuOptions }
}
