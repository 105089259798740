import { defaultTheme, Typography } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

export const FooterStepBackButton = styled.button`
  ${Typography.bodyLarge}
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 12px;
  height: 40px;
  border: none;
  outline: none;
  transition: 0.1s color ease-out;
  background-color: transparent;
  cursor: pointer;
  color: ${defaultTheme.color.primary600};

  &:hover {
    color: ${defaultTheme.color.primary800};
  }
`

export const StepsWrapper = styled.div`
  max-width: 670px;
  margin-bottom: 24px;

  .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
    padding-left: 6px;
  }

  .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:first-child {
    padding-left: 0;
  }
`
