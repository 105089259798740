import useFormatNumber from 'hooks/useFormatNumber'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import {
  ContentBlock,
  InfoItem,
  InfoRow,
  InfoTable,
  LongInfoRow,
  SubTitle,
  Text,
} from 'views/pages/LogisticsKR/components/styled'
import { removeSeconds } from '../../helpers'
import { isUnloadingWarehouse, LogisticsKRLoadingWarehouse, LogisticsKRUnloadingWarehouse } from '../../types'
import { DASH } from 'modules/constants'

interface Props {
  warehouse: LogisticsKRLoadingWarehouse | LogisticsKRUnloadingWarehouse
}

export const LeftBlock: FC<Props> = ({ warehouse }) => {
  const { t } = useTranslation('logisticsKR')
  const formatNumber = useFormatNumber()

  if (isUnloadingWarehouse(warehouse)) {
    return (
      <ContentBlock>
        <InfoTable>
          <LongInfoRow>
            <InfoItem>
              <SubTitle>{t('warehouses.details.address')}</SubTitle>
              <Text>{warehouse?.address}</Text>
            </InfoItem>
          </LongInfoRow>

          <LongInfoRow>
            <InfoItem>
              <SubTitle>{t('warehouses.details.vehicle_type')}</SubTitle>
              <Text>{warehouse?.vehicle_type.map((type) => t(`vehicle_type.${type}`)).join(', ')}</Text>
            </InfoItem>
          </LongInfoRow>

          <InfoRow>
            <InfoItem>
              <SubTitle>{t('warehouses.details.unloading_type')}</SubTitle>
              <Text>{warehouse?.unloading_type ? t(`directions.${warehouse?.unloading_type}`) : DASH}</Text>
            </InfoItem>
          </InfoRow>

          <LongInfoRow>
            <InfoItem>
              <SubTitle>{t('warehouses.details.volume_per_day')}</SubTitle>
              <Text>
                {t('warehouses.details.values.volume_per_day', { volume_per_day: warehouse?.volume_per_day })}
              </Text>
            </InfoItem>
          </LongInfoRow>

          <InfoRow>
            <InfoItem>
              <SubTitle>{t('warehouses.details.is_dump_truck')}</SubTitle>
              <Text>{warehouse?.is_dump_truck ? t('common:yes') : t('common:no')}</Text>
            </InfoItem>
          </InfoRow>

          <InfoRow>
            <InfoItem>
              <SubTitle>{t('warehouses.details.schedule')}</SubTitle>
              <Text>{`${removeSeconds(warehouse?.schedule_from)} - ${removeSeconds(warehouse?.schedule_to)}`}</Text>
            </InfoItem>

            <InfoItem>
              <SubTitle>{t('warehouses.details.works_on_weekend')}</SubTitle>
              <Text>{warehouse?.works_on_weekend ? t('common:yes') : t('common:no')}</Text>
            </InfoItem>
          </InfoRow>

          <InfoRow>
            <InfoItem>
              <SubTitle>{t('warehouses.details.car_delivery')}</SubTitle>
              <Text>{warehouse?.car_delivery ? t('common:yes') : t('common:no')}</Text>
            </InfoItem>

            <InfoItem>
              <SubTitle>{t('warehouses.details.railway_delivery')}</SubTitle>
              <Text>{warehouse?.railway_delivery ? t('common:yes') : t('common:no')}</Text>
            </InfoItem>
          </InfoRow>
        </InfoTable>
      </ContentBlock>
    )
  }

  return (
    <ContentBlock>
      <InfoTable>
        <LongInfoRow>
          <InfoItem>
            <SubTitle>{t('warehouses.details.address')}</SubTitle>
            <Text>{warehouse?.address}</Text>
          </InfoItem>
        </LongInfoRow>

        <InfoRow>
          <InfoItem>
            <SubTitle>{t('warehouses.details.gate_height')}</SubTitle>
            <Text>{formatNumber(warehouse?.gates_height)}</Text>
          </InfoItem>
        </InfoRow>

        <LongInfoRow>
          <InfoItem>
            <SubTitle>{t('warehouses.details.loader_type')}</SubTitle>
            <Text>{warehouse?.loader_type.map((type) => t(`vehicle_type.${type}`)).join(', ')}</Text>
          </InfoItem>
        </LongInfoRow>

        <InfoRow>
          <InfoItem>
            <SubTitle>{t('warehouses.details.scales_capacity')}</SubTitle>
            <Text>
              {warehouse?.scales_capacity
                ? t('warehouses.details.values.scales_capacity', { scales_capacity: warehouse?.scales_capacity })
                : t('common:unknown')}
            </Text>
          </InfoItem>

          <InfoItem>
            <SubTitle>{t('warehouses.details.scales_remoteness')}</SubTitle>
            <Text>
              {warehouse?.scales_remoteness
                ? t('warehouses.details.values.scales_remoteness', { scales_remoteness: warehouse?.scales_remoteness })
                : t('common:unknown')}
            </Text>
          </InfoItem>

          <InfoItem>
            <SubTitle>{t('warehouses.details.scales_length')}</SubTitle>
            <Text>
              {warehouse?.scales_length
                ? t('warehouses.details.values.scales_length', { scales_length: warehouse?.scales_length })
                : t('common:unknown')}
            </Text>
          </InfoItem>
        </InfoRow>

        <LongInfoRow>
          <InfoItem>
            <SubTitle>{t('warehouses.details.volume_per_day')}</SubTitle>
            <Text>{t('warehouses.details.values.volume_per_day', { volume_per_day: warehouse?.volume_per_day })}</Text>
          </InfoItem>
        </LongInfoRow>

        <LongInfoRow>
          <InfoItem>
            <SubTitle>{t('warehouses.details.vehicle_type')}</SubTitle>
            <Text>{warehouse?.vehicle_type.map((type) => t(`vehicle_type.${type}`)).join(', ')}</Text>
          </InfoItem>
        </LongInfoRow>

        <InfoRow>
          <InfoItem>
            <SubTitle>{t('warehouses.details.schedule')}</SubTitle>
            <Text>{`${removeSeconds(warehouse?.schedule_from)} - ${removeSeconds(warehouse?.schedule_to)}`}</Text>
          </InfoItem>

          <InfoItem>
            <SubTitle>{t('warehouses.details.works_on_weekend')}</SubTitle>
            <Text>{warehouse?.works_on_weekend ? t('common:yes') : t('common:no')}</Text>
          </InfoItem>
        </InfoRow>

        <InfoRow>
          <InfoItem>
            <SubTitle>{t('warehouses.details.car_delivery')}</SubTitle>
            <Text>{warehouse?.car_delivery ? t('common:yes') : t('common:no')}</Text>
          </InfoItem>

          <InfoItem>
            <SubTitle>{t('warehouses.details.railway_delivery')}</SubTitle>
            <Text>{warehouse?.railway_delivery ? t('common:yes') : t('common:no')}</Text>
          </InfoItem>
        </InfoRow>
      </InfoTable>
    </ContentBlock>
  )
}
