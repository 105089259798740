import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { PriceColumn, PriceTitle, PriceWrapper, SecondPriceColumn } from 'views/styled/ItemCardStyles'

import { DashboardDeal } from 'modules/domain/dashboard/types'
import { UserDeal } from 'modules/domain/userBid/types'
import { DealSource } from 'modules/domain/deal/types'
import { PriceSection } from './PriceSection'

interface Props {
  deal: DashboardDeal | UserDeal
  isClosed?: boolean
}

export const DealCardPrice: FC<Props> = ({ deal, isClosed }) => {
  const { t } = useTranslation('common')
  const isShowEstimatedPrice = deal?.source === DealSource.deal_response || deal?.source === DealSource.matching

  return (
    <PriceWrapper $withMargin $withBg={isShowEstimatedPrice && !isClosed}>
      <PriceColumn isClosed={isClosed}>
        <PriceTitle>{t('user:wants')}</PriceTitle>
        <PriceSection
          sale={{ price: deal.sale_price, type: deal.sale_bid.price_type }}
          purchase={{ price: deal.purchase_price, type: deal.purchase_bid.price_type }}
          highlight_price={deal.highlight_price}
          who_responded={deal.who_responded}
        />
      </PriceColumn>
      {isShowEstimatedPrice && (
        <SecondPriceColumn isClosed={isClosed}>
          <PriceTitle>{t('user:can_offer')}</PriceTitle>
          <PriceSection
            sale={{ price: deal.estimated_sale_price, type: deal.sale_bid.price_type }}
            purchase={{ price: deal.estimated_purchase_price, type: deal.purchase_bid.price_type }}
            highlight_price={deal.highlight_price}
            who_responded={deal.who_responded}
          />
        </SecondPriceColumn>
      )}
    </PriceWrapper>
  )
}
