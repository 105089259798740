import { FC } from 'react'
import { Bid, BidSources, USBid } from 'modules/domain/bid/types'
import { Card } from 'views/components/Card/Card'
import { useTranslation } from 'react-i18next'
import { OptionalDetails } from '../../components/Brazil/OptionalDetails'
import { refetchFunc } from 'modules/domain/common/hooks'
import { EditableDetails } from 'views/pages/Bid/BidDetail/Bid/EditableDetails'
import { isUS } from 'env'
import { USEditableBidInfo } from 'views/components/USEditableBidInfo/USEditableBidInfo'

type Props = {
  bid: Bid
  refreshBid: refetchFunc
}

export const ExtraInfo: FC<Props> = ({ bid, refreshBid }) => {
  const { t } = useTranslation('bid')
  const readOnlyBidInfo = bid.source === BidSources.BARCHART

  const editableInfo = isUS ? (
    <USEditableBidInfo bid={bid as USBid} refreshBid={refreshBid} usedOnPage="bid" readOnly={readOnlyBidInfo} />
  ) : (
    <EditableDetails bid={bid} refreshBid={refreshBid} />
  )

  return (
    <Card.GapableContent>
      <Card.Title>{t('form.bidsParams')}</Card.Title>

      <OptionalDetails bid={bid} refreshBid={refreshBid} />

      {editableInfo}
    </Card.GapableContent>
  )
}
