import styled from 'styled-components'
import { ListRequestParams } from 'modules/domain/types'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { apiClient } from 'modules/utils/httpClient'
import { endpoints } from 'modules/endpoints'
import { CarrierStatuses } from '../../../types'
import { useNotificationProgress } from 'hooks/useNotificationProgress'
import { Icons } from '@agro-club/agroclub-shared'
import { SharedModalContent, SharedModalTitle } from 'views/components/SharedModal'
import { CarrierEditModal } from '../../CarrierEditModal/CarrierEditModal'
import { AButton } from 'views/components/Analytics'
import ASharedModal from 'views/components/SharedModal/ASharedModal'
import { AnalyticsPlaces } from 'views/pages/LogisticsKR/analyticsPlaces'
import ADefaultModalFooter from 'views/components/DefaultModalFooter/ADefaultModalFooter'
import { useAProgress } from 'hooks/useAProgress'

const ButtonWrapper = styled.div`
  display: flex;
  column-gap: 8px;
`

type ActionsCellProps = {
  data: any
  setListRequestParams: (ListRequestParams) => void
  listRequestParams: ListRequestParams
}

export const ActionsCell: FC<ActionsCellProps> = ({ data, setListRequestParams, listRequestParams }) => {
  const { t } = useTranslation('logisticsKR')
  const { ready_for_active, status, id } = data

  const [editModal, setEditModal] = useState(false)
  const [archivedConfirmModal, setArchivedConfirmModal] = useState(false)
  const [changeStatusConfirmModal, setChangeStatusConfirmModal] = useState(false)

  const [changeStatusToArchivedProgress, changeStatusToArchived] = useAProgress(
    (carrierId: string) =>
      apiClient.post(endpoints.LogisticsKR.carrierStatus(carrierId), { new_status: CarrierStatuses.archived }),
    {
      eventName: 'setArchived',
      onSuccess: () => {
        setArchivedConfirmModal(false)
        setListRequestParams({ ...listRequestParams })
      },
    },
  )
  const [changeStatusToActiveProgress, changeStatusToActive] = useAProgress(
    (carrierId: string) =>
      apiClient.post(endpoints.LogisticsKR.carrierStatus(carrierId), { new_status: CarrierStatuses.active }),
    {
      eventName: 'setActive',
      onSuccess: () => {
        setChangeStatusConfirmModal(false)
        setListRequestParams({ ...listRequestParams })
      },
    },
  )

  useNotificationProgress(
    changeStatusToArchivedProgress,
    t('carriers.notifications.carrier_archived_success'),
    t('carriers.notifications.carrier_archived_error'),
  )

  useNotificationProgress(
    changeStatusToActiveProgress,
    t('carriers.notifications.carrier_active_success'),
    t('carriers.notifications.carrier_active_error'),
  )

  return (
    <ButtonWrapper>
      {status === CarrierStatuses.draft && (
        <AButton
          id="setActive"
          intent="secondary"
          size="small"
          Icon={Icons.IconCheck}
          onClick={() => setChangeStatusConfirmModal(true)}
        />
      )}
      {status !== CarrierStatuses.archived && (
        <AButton id="edit" intent="secondary" size="small" Icon={Icons.IconEdit} onClick={() => setEditModal(true)} />
      )}
      {status !== CarrierStatuses.archived && (
        <AButton
          id="archive"
          analyticsPayload={{ id }}
          intent="destruct"
          progress={changeStatusToArchivedProgress}
          size="small"
          Icon={Icons.IconRemove}
          onClick={() => setArchivedConfirmModal(true)}
        />
      )}
      {archivedConfirmModal && (
        <ASharedModal
          id={AnalyticsPlaces.CARRIERS.ARCHIVE_MODAL}
          size="small"
          onClose={() => setArchivedConfirmModal(false)}
        >
          <SharedModalTitle>{t('carriers.archiveForm.title')}</SharedModalTitle>
          <SharedModalContent>{t('carriers.archiveForm.text')}</SharedModalContent>
          <ADefaultModalFooter
            progress={changeStatusToArchivedProgress}
            onClose={() => setArchivedConfirmModal(false)}
            onConfirm={() => changeStatusToArchived(id)}
            confirmButtonText={t('common:archive')}
          />
        </ASharedModal>
      )}
      {editModal && (
        <CarrierEditModal
          carrier={data}
          onClose={() => setEditModal(false)}
          onSuccess={() => setListRequestParams({ ...listRequestParams })}
        />
      )}
      {changeStatusConfirmModal && (
        <ASharedModal
          id={AnalyticsPlaces.CARRIERS.CHANGE_STATUS_MODAL}
          size="small"
          onClose={() => setChangeStatusConfirmModal(false)}
        >
          <SharedModalTitle>{t('carriers.confirmModals.statusChangingTitle')}</SharedModalTitle>
          <SharedModalContent>
            {ready_for_active
              ? t('carriers.confirmModals.youReallyWantToChangeStatus')
              : t('carriers.confirmModals.allFieldsMustBeFilledIn')}
          </SharedModalContent>
          <ADefaultModalFooter
            progress={changeStatusToActiveProgress}
            disabled={!ready_for_active}
            onConfirm={() => changeStatusToActive(id)}
            onClose={() => setChangeStatusConfirmModal(false)}
          />
        </ASharedModal>
      )}
    </ButtonWrapper>
  )
}
