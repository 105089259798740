import { ColumnData } from 'modules/types'
import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import { TableTextLink } from 'views/ui/TableTextLink/TableTextLink'
import { generatePath } from 'react-router-dom'
import CompanyRoutes from 'views/pages/Company/routes'
import { ListRequestParams } from 'modules/domain/types'
import { convertSortingToAntDFormat } from 'modules/domain/common/tableUtils'
import useFormatDate from 'hooks/useFormatDate'
import { getPersonalNumberLabel } from 'modules/utils/numbers/formatPersonalNumber'
import { isBrazil, isRussia, isUS } from 'env'
import { Company, FactoringStatus } from 'modules/domain/company/types'
import { CompanyFactoringIcon } from 'views/components/TooltipIcons/TooltipIcons'
import { TruncatedValue } from 'views/components/TruncatedValue/TruncatedValue'
import { EntityWithIconsWrapper } from 'views/styled/common'

import { MoratoriumTag } from 'views/components/Tags/CompanyTag'
import { CompanySizeTag } from '../User/UserDetail/CompanySizeTag'
import { CompanyCommercialTag } from './CompanyCommercialTag'
import { DASH } from 'modules/constants'
import { getFactoringSummary } from './helpers'

const getVatColumn = (t) => {
  if (isBrazil) return []
  return [
    {
      title: t('list.tableHeaders.vat'),
      width: 64,
      dataIndex: 'vat',
      render: (vat) => t(`form.prepaymentValues.${vat ? 'yes' : 'no'}`),
    },
  ]
}

const getTagColumn = (t) => {
  if (isRussia) {
    return [
      {
        title: t('status'),
        width: 108,
        dataIndex: 'company_size',
        align: 'left',
        render: (company_size) => <CompanySizeTag companySize={company_size} />,
      },
    ]
  }
  if (isUS) {
    return [
      {
        title: t('status'),
        width: 108,
        dataIndex: 'commercial',
        align: 'left',
        render: (commercial) => <CompanyCommercialTag commercial={commercial} />,
      },
    ]
  }
  return []
}

export const useColumns = (sortParam: ListRequestParams['sort']): ColumnData[] => {
  const { t } = useTranslation('company')
  const formatDate = useFormatDate()
  return useMemo(
    () => [
      {
        title: t('list.tableHeaders.created'),
        width: 140,
        dataIndex: 'created',
        sorter: true,
        key: 'created',
        sortOrder: convertSortingToAntDFormat('created', String(sortParam)),
        render: (created) => formatDate(created),
      },
      ...getTagColumn(t),
      {
        title: t('list.tableHeaders.inn', { innName: getPersonalNumberLabel() }),
        width: 160,
        dataIndex: 'inn',
        key: 'inn',
        align: 'left',
      },

      {
        title: t('list.tableHeaders.name'),
        width: 852,
        dataIndex: 'name',
        sorter: true,
        sortOrder: convertSortingToAntDFormat('name', sortParam),
        render: (name, data: Company) => (
          <EntityWithIconsWrapper>
            <TruncatedValue hint={name}>
              <TableTextLink
                id="company"
                tableItemId={data.id}
                to={generatePath(CompanyRoutes.Details, { id: data.id })}
              >
                {name || t('list.emptyCompanyName')}
              </TableTextLink>
            </TruncatedValue>
            <MoratoriumTag isVisible={data.soy_restriction} />
            <CompanyFactoringIcon company={data} />
          </EntityWithIconsWrapper>
        ),
      },
      ...getVatColumn(t),
      {
        title: t('factoring'),
        width: 160,
        dataIndex: 'factoring_seller_status',
        render: (_, company: Company) => {
          const status = getFactoringSummary(company)
          if (status === FactoringStatus.accepted) {
            return t('common:thereIs')
          } else if (status === FactoringStatus.cancelled) {
            return t('common:no')
          } else {
            return DASH
          }
        },
      },
      {
        title: t('list.tableHeaders.prepayment'),
        width: 160,
        dataIndex: 'prepayment',
        render: (prepayment) => t(`form.prepaymentValues.${prepayment}`),
      },
      {
        title: t('list.tableHeaders.payment_delay_days'),
        width: 116,
        align: 'right',
        dataIndex: 'payment_delay_days',
      },
      {
        title: t('list.tableHeaders.agreementType'),
        width: 130,
        dataIndex: 'cooperation_type_agent',
        render: (_, data) => (
          <>
            {data.cooperation_type_agent && <div>{`${t('form.fields.cooperation_type_agent')}`}</div>}
            {data.cooperation_type_trader && <div>{`${t('form.fields.cooperation_type_trader')}`}</div>}
          </>
        ),
      },
    ],
    [sortParam, t, formatDate],
  )
}
