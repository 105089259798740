import { Dispatch, FC, SetStateAction } from 'react'

import { defaultListRequestParams } from 'views/components/TableFilters/data'
import { CarsFilters } from './Filters/CarsFilters'

import { useFilters } from 'hooks/useFilter'

import { ListRequestParams } from 'modules/domain/types'
import { ColumnData } from 'modules/types'
import ATableFullPage from 'views/components/Table/ATableFullPage'

interface Props {
  columns: ColumnData[]
  endpoint: string
  listRequestParams: ListRequestParams
  setListRequestParams: Dispatch<SetStateAction<ListRequestParams>>
}

const CarsTable: FC<Props> = ({ columns, endpoint, listRequestParams, setListRequestParams }) => {
  const { updateFilterState } = useFilters({
    listRequestParams,
    setListRequestParams,
  })

  const handleClearFilters = () => {
    setListRequestParams(defaultListRequestParams)
  }

  return (
    <>
      <CarsFilters
        updateFilterState={updateFilterState}
        listRequestParams={listRequestParams}
        handleClearFilters={handleClearFilters}
      />

      <ATableFullPage
        tableWidth={1628}
        columns={columns}
        dataEndpoint={endpoint}
        listParamsUpdated={setListRequestParams}
        listRequestParams={listRequestParams}
        isFiltersLoaded={true}
        rowHoverGray
      />
    </>
  )
}

export default CarsTable
