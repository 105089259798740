import { AnchorHTMLAttributes, FC } from 'react'

import { ExternalLink } from '@agro-club/agroclub-shared'
import { useAnalyticsClickHandler } from 'analytics/hooks'

interface Props extends Omit<AnchorHTMLAttributes<HTMLAnchorElement>, 'id'> {
  eventName: string
}

const AExternalLink: FC<Props> = ({ children, eventName, itemID, onClick, ...otherProps }) => {
  const handleClick = useAnalyticsClickHandler(eventName)

  return (
    <ExternalLink onClick={handleClick(onClick, { id: itemID })} {...otherProps}>
      {children}
    </ExternalLink>
  )
}

export default AExternalLink
