import { InternalSharedModal, SharedModalTitle } from 'views/components/SharedModal'
import { useTranslation } from 'react-i18next'
import { Progress, Text } from '@agro-club/agroclub-shared'
import InternalDefaultModalFooter from 'views/components/DefaultModalFooter/InternalDefaultModalFooter'

type Props = {
  addComment: () => void
  onClose: () => void
  progress: Progress
}

export const WarningModal = ({ onClose, addComment, progress }: Props) => {
  const { t } = useTranslation('comments')

  return (
    <InternalSharedModal size="small" onClose={onClose}>
      <SharedModalTitle>{t('important_comment')}</SharedModalTitle>
      <Text>{t('want_pin_comment')}</Text>
      <InternalDefaultModalFooter
        onClose={onClose}
        confirmButtonText={t('common:save')}
        onConfirm={addComment}
        progress={progress}
      />
    </InternalSharedModal>
  )
}
