import { FC } from 'react'

import { ExternalLink, Typography } from '@agro-club/agroclub-shared'
import styled from 'styled-components'
import { LinkProps } from 'react-router-dom'
import { ARouterLink } from 'views/components/Analytics'

const Text = styled(ARouterLink)<{ bold?: boolean }>`
  ${({ bold }) => (bold ? Typography.accentMedium : Typography.bodyMedium)}
`

export const TableExternalLink = styled(ExternalLink)`
  ${Typography.bodyMedium}
`

interface Props extends Omit<LinkProps, 'id'> {
  tableItemId?: string
  id: string
  bold?: boolean
}

export const TableTextLink: FC<Props> = ({ id, tableItemId, bold, children, ...otherProps }) => {
  return (
    <Text bold={bold} eventName={id ?? ''} itemId={tableItemId ?? ''} {...otherProps}>
      {children}
    </Text>
  )
}
