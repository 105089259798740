import { FormTeamAndAssignee } from 'views/components/form/FormTeamAndAssignee'
import { FormDatePicker } from 'views/components/form/FormDatePicker'
import { FormikFieldsType } from 'modules/domain/types'
import { FormTasksPerDay } from './FormTasksPerDay'
import { useTranslation } from 'react-i18next'
import { FC, ReactNode } from 'react'

type Props = {
  formik: FormikFieldsType
  assigneeField: string
  children?: ReactNode
  teamField: string
}

export const TaskManagementForm: FC<Props> = ({ formik, teamField, assigneeField, children }) => {
  const { t } = useTranslation('my_tasks')
  return (
    <>
      <FormTeamAndAssignee formik={formik} teamField={teamField} assigneeField={assigneeField} />
      {children}
      <FormTasksPerDay formik={formik} />
      <FormDatePicker
        label={t('task_distribution.start_date')}
        fieldName="start_deadline_date"
        id="StartDeadlineDate"
        formik={formik}
      />
    </>
  )
}
