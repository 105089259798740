import { FC, useState } from 'react'
import { Icons } from '@agro-club/agroclub-shared'
import { useTranslation } from 'react-i18next'
import { APopoverButton } from 'views/components/Analytics/APopoverButton'
import { PopoverContainer } from 'views/styled/common'
import BidRoutes from 'views/pages/Bid/routes'
import { BidStatus, USBid } from 'modules/domain/bid/types'
import { generatePath } from 'react-router-dom'
import { CreateDealFromBidModal } from 'views/pages/Bid/components/US/CreateDealModal/CreateDealFromBidModal'

type Props = {
  bid: USBid
  setPopoverVisible: (arg: boolean) => void
}

export const BidPopoverContent: FC<Props> = ({ bid, setPopoverVisible }) => {
  const { t } = useTranslation('logisticsKR')
  const [createDealModal, setCreateDealModal] = useState(false)

  return (
    <PopoverContainer
      width={170}
      onClick={(e) => {
        e.stopPropagation()
        setPopoverVisible(false)
      }}
    >
      <APopoverButton
        id="bidActionBid"
        text={t('bid:goToBid')}
        to={generatePath(bid.status === BidStatus.potential ? BidRoutes.PotentialBidDetail : BidRoutes.Details, {
          id: bid.id,
        })}
        Icon={<Icons.IconOpen />}
        analyticsPayload={{ id: bid.id }}
      />
      <APopoverButton
        id="bidActionDeal"
        text={t('bid:createDeal')}
        onClick={() => setCreateDealModal(true)}
        Icon={<Icons.IconDeals />}
        analyticsPayload={{ id: bid.id }}
      />
      {createDealModal && <CreateDealFromBidModal bid={bid} onClose={() => setCreateDealModal(false)} />}
    </PopoverContainer>
  )
}
