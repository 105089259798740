import { defaultTheme, Typography } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

export const TablesSectionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
`

export const TableSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
`

export const TableSectionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const TableSectionHeaderRightBlock = styled.div`
  display: flex;
  align-items: center;
  column-gap: 16px;
`

export const TableSectionTitle = styled.div`
  ${Typography.titleH4}
  color: ${defaultTheme.color.textPrimary900};
`

export const TableSectionText = styled.div`
  ${Typography.bodyLarge}
  color: ${defaultTheme.color.textPrimary900};
`

export const TableSectionAccentText = styled.div`
  ${Typography.accentLarge}
  color: ${defaultTheme.color.textPrimary900};
`

export const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 5px;
`

export const FlexColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const SecondaryText = styled.div`
  ${Typography.bodyMedium}
  color: ${defaultTheme.color.textSecondary60a};
`
