import ATableFullPage from 'views/components/Table/ATableFullPage'
import { ListRequestParams } from 'modules/domain/types'
import { Dispatch, FC, SetStateAction } from 'react'
import { endpoints } from 'modules/endpoints'
import { useColumns } from './useColumns'

type Props = {
  listRequestParams: ListRequestParams
  listParamsUpdated: Dispatch<SetStateAction<ListRequestParams>>
  isFiltersLoaded: boolean
}

export const TripsWithoutTtnTable: FC<Props> = ({ listRequestParams, listParamsUpdated, isFiltersLoaded }) => {
  const tripsRefetch = () => listParamsUpdated((prev) => ({ ...prev }))

  const columns = useColumns(tripsRefetch)

  return (
    <ATableFullPage
      columns={columns}
      dataEndpoint={endpoints.LogisticsKR.tripsWithoutTtn()}
      listParamsUpdated={listParamsUpdated}
      listRequestParams={listRequestParams}
      page={listRequestParams.page}
      isFiltersLoaded={isFiltersLoaded}
    />
  )
}
