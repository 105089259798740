import styled from 'styled-components'
import { defaultTheme, Typography } from '@agro-club/agroclub-shared'

export const HeaderWrapper = styled.div`
  display: flex;
`

export const HeaderTitle = styled.div`
  ${Typography.titleH4};
  color: ${defaultTheme.color.textPrimary900};
  margin-right: 8px;
`

export const HeaderSummary = styled.div`
  display: flex;
  ${Typography.bodyMedium};
  align-items: center;
  color: ${defaultTheme.color.textSecondary60a};
  padding-top: 2px;
`
