import { FormItemWrapper } from 'views/styled/common'
import { FormGroup, Radio, RadioItem } from '@agro-club/agroclub-shared'
import { Overload } from 'modules/domain/types'
import { useTranslation } from 'react-i18next'
import { FormikProps } from 'formik'
import { FC } from 'react'

type Props = {
  formik: FormikProps<any>
}

export const OverloadRadio: FC<Props> = ({ formik }) => {
  const { t } = useTranslation('address')

  return (
    <FormGroup label={t('fields.overload')} error={formik.errors.overload}>
      <FormItemWrapper>
        <Radio value={formik.values.overload} onChange={(overload) => formik.setFieldValue('overload', overload)}>
          <RadioItem value={Overload.normal} label={t('overLoadTypes.normal')} />
          <RadioItem value={Overload.full} label={t('overLoadTypes.full')} />
        </Radio>
      </FormItemWrapper>
    </FormGroup>
  )
}
