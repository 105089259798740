import { FormikProps } from 'formik'
import { SharedModalContent, SharedModalInputs } from 'views/components/SharedModal'
import FormSelectShared from 'views/components/FormSelectShared'
import { QuantityInput } from 'views/components/QuantityInput/QuantityInput'
import { DatePicker } from 'views/components/DatePicker/DatePicker'
import { DateISO, FormGroup, Progress } from '@agro-club/agroclub-shared'
import { useTranslation } from 'react-i18next'
import ADefaultModalFooter from 'views/components/DefaultModalFooter/ADefaultModalFooter'
import { ShortFormControl } from 'views/styled/common'
import { endpoints } from 'modules/endpoints'
import { FC, ReactNode } from 'react'
import { FarmerLoadWorkTypes, ShipLoadWorkTypes } from 'modules/domain/deal/types'
import { ShipWorkTypeSelect } from '../ShipWorkTypeSelect'
import { FarmerWorkTypeSelect } from '../FarmerWorkTypeSelect'

export type EditableFields = {
  regional: string
  work_type: ShipLoadWorkTypes | FarmerLoadWorkTypes | null
  load_date: DateISO | null
  quantity: number | null
  record_type?: 'export' | 'not_export'
}

type Props = {
  formik: FormikProps<EditableFields>
  onClose: () => void
  progress: Progress
  children: ReactNode
}

export const ModalCommonContent: FC<Props> = ({ formik, onClose, progress, children }) => {
  const { t } = useTranslation('deal')

  return (
    <SharedModalContent>
      <SharedModalInputs>
        <FormSelectShared
          label={t('accordion.loading.regional')}
          formik={formik}
          fieldName="regional"
          endpoint={endpoints.executorsByType('regionals')}
          getLabel={(item) => item.full_name}
          searchField="search"
          required
        />
        {children}
        <FormGroup label={t('accordion.loading.loading_date')} error={formik.errors.load_date}>
          <DatePicker
            onChange={(data) => formik.setFieldValue('load_date', data['date'])}
            value={formik.values.load_date as DateISO}
            error={!!formik.errors.load_date}
            placeholder={t('common:datePlaceholder')}
            allowClear
          />
        </FormGroup>
        <ShortFormControl>
          <QuantityInput formik={formik} fieldName="quantity" label={t('accordion.loading.quantity')} required />
        </ShortFormControl>
      </SharedModalInputs>
      <ADefaultModalFooter
        onClose={onClose}
        progress={progress}
        confirmButtonText={t('common:save')}
        onConfirm={() => formik.submitForm()}
      />
    </SharedModalContent>
  )
}

type CommonLoadProps = Omit<Props, 'children'>

export const FarmerLoadCommonContent: FC<CommonLoadProps> = ({ formik, onClose, progress }) => {
  return (
    <ModalCommonContent formik={formik} progress={progress} onClose={onClose}>
      <FarmerWorkTypeSelect formik={formik} />
    </ModalCommonContent>
  )
}

export const ShipLoadCommonContent: FC<CommonLoadProps> = ({ formik, onClose, progress }) => {
  return (
    <ModalCommonContent formik={formik} progress={progress} onClose={onClose}>
      <ShipWorkTypeSelect formik={formik} />
    </ModalCommonContent>
  )
}
