import { defaultTheme, Typography } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

export const Column = styled.div``

export const BidPageTitle = styled.div`
  ${Typography.titleH3}
  color: ${defaultTheme.color.secondary900};
`

export const BidLeftBlockTwoColumns = styled.div`
  display: grid;
  grid-template-columns: 550px 432px;
  column-gap: 24px;
`
