import { Card } from 'views/components/Card/Card'
import { DealInfoFirstRow, DealInfoSecondRow, InputWithCheckboxWrapper, ModalFormWrapper } from './styled'
import { KeyValue } from 'views/components/KeyValue/KeyValue'
import { DocumentRequestCompanyInfo } from 'views/pages/DocumentRequests/DocumentRequestsDetail/DocumentRequestCompanyInfo'
import { Checkbox, FormGroup, Input, Radio, RadioItem } from '@agro-club/agroclub-shared'
import { FormItemWrapper, ShortFormControl } from 'views/styled/common'
import { FormikProps } from 'formik'
import { useTranslation } from 'react-i18next'
import { Deal } from 'modules/domain/deal/types'
import { getDeclarationText } from 'views/pages/Deal/helpers/getDeclarationText'
import { DocumentRequestDTO, ProductPurpose } from 'modules/domain/documentRequest/types'
import FormSelectShared from 'views/components/FormSelectShared'
import { endpoints } from 'modules/endpoints'
import { QuantityInput } from 'views/components/QuantityInput/QuantityInput'
import { Declaration } from 'modules/domain/types'

interface Props {
  formik: FormikProps<DocumentRequestDTO>
  deal: Deal
  declaration: Declaration | undefined
}

export const CommonFormFields = ({ formik, deal, declaration }: Props) => {
  const { t } = useTranslation('documentRequests')

  return (
    <>
      <Card.Container bordered>
        <DealInfoFirstRow>
          <KeyValue label={t('common:deal')}>ID {deal.id}</KeyValue>
          <KeyValue label={t('common:product')}>{deal.product.title}</KeyValue>
          <KeyValue label={t('detail.dealInfo.declaration')}>{getDeclarationText(declaration)}</KeyValue>
        </DealInfoFirstRow>
        <DealInfoSecondRow>
          <DocumentRequestCompanyInfo company={deal.sale_bid.company} label={t('detail.sellerInfo.title')} />
          <DocumentRequestCompanyInfo company={deal.purchase_bid.company} label={t('detail.customerInfo.title')} />
        </DealInfoSecondRow>
      </Card.Container>
      <ModalFormWrapper>
        <FormGroup
          label={t('detail.main.title')}
          error={
            formik.errors.request_ds ||
            formik.errors.request_quarantine ||
            formik.errors.request_vet ||
            formik.errors.request_sdiz
          }
        >
          <FormItemWrapper>
            <Checkbox
              isChecked={formik.values.request_ds}
              onChange={(_value, isChecked) => formik.setFieldValue('request_ds', isChecked)}
              label={t('detail.main.ds')}
            />
            <Checkbox
              isChecked={formik.values.request_quarantine}
              onChange={(_value, isChecked) => formik.setFieldValue('request_quarantine', isChecked)}
              label={t('detail.main.quarantine')}
            />

            <Checkbox
              isChecked={formik.values.request_vet}
              onChange={(_value, isChecked) => formik.setFieldValue('request_vet', isChecked)}
              label={t('detail.main.vet')}
              disabled={formik.values.product_purpose === ProductPurpose.alimentary}
            />
            <Checkbox
              isChecked={formik.values.request_sdiz}
              onChange={(_value, isChecked) => formik.setFieldValue('request_sdiz', isChecked)}
              label={t('detail.main.sdiz')}
            />
          </FormItemWrapper>
        </FormGroup>
        <FormSelectShared
          formik={formik}
          endpoint={endpoints.users('regionals')}
          fieldName="regionals"
          searchField="search"
          multiple
          placeholder={t('executors:not_selected')}
          label={t(`executors:regionals`)}
          getLabel={(item) => item.full_name}
        />
        <ShortFormControl>
          <QuantityInput formik={formik} fieldName="quantity" label={t('common:volume')} />
        </ShortFormControl>

        <FormGroup label={t('modal.loadAddress')} error={formik.errors.load_address}>
          <Input
            {...formik.getFieldProps('load_address')}
            placeholder={t('modal.placeholder.address')}
            invalid={!!formik.errors.load_address}
          />
        </FormGroup>
        <FormGroup label={t('modal.unloadAddress')} error={formik.errors.unload_address}>
          <Input
            {...formik.getFieldProps('unload_address')}
            placeholder={t('modal.placeholder.address')}
            invalid={!!formik.errors.unload_address}
          />
        </FormGroup>
        <div>
          <FormGroup label={t('modal.consignee')} error={formik.errors.consignee}>
            <Input
              {...formik.getFieldProps('consignee')}
              placeholder={t('modal.placeholder.consignee')}
              invalid={!!formik.errors.consignee}
            />
          </FormGroup>
          <InputWithCheckboxWrapper>
            <Checkbox
              isChecked={formik.values.pay_to_customer_account}
              onChange={(_value, isChecked) => formik.setFieldValue('pay_to_customer_account', isChecked)}
              label={t('detail.main.payToCustomerAccount', { company: deal.purchase_bid.company.name })}
            />
          </InputWithCheckboxWrapper>
        </div>
        <FormGroup label={t('modal.productPurpose')} error={formik.errors.product_purpose}>
          <FormItemWrapper>
            <Radio
              value={formik.values.product_purpose}
              onChange={(value) => {
                formik.setFieldValue('product_purpose', value)
              }}
            >
              <RadioItem value={ProductPurpose.alimentary} label={t('detail.dealInfo.alimentary')} />
              <RadioItem value={ProductPurpose.feed} label={t('detail.dealInfo.feed')} />
            </Radio>
          </FormItemWrapper>
        </FormGroup>
      </ModalFormWrapper>
    </>
  )
}
