import { useTranslation } from 'react-i18next'
import { AntTag } from '@agro-club/agroclub-shared'
import { DatePeriod, DatePeriodFormatted, containsFilledDatePeriod } from 'views/components/DatePeriodFormatted'
import { isBrazil } from 'env'
import { FC } from 'react'

type Props = {
  value?: DatePeriod | null
  label?: string
}

export const FuturesPeriodTag: FC<Props> = ({ value, ...restProps }) => {
  const { t } = useTranslation('bid')
  const label = restProps.label ?? `${t('kind.futures')}:`

  if (!isBrazil || !containsFilledDatePeriod(value)) {
    return null
  }

  return (
    <AntTag color="purple">
      {label} &nbsp; <DatePeriodFormatted period={value} />
    </AntTag>
  )
}
