import { IconArrowRight, defaultTheme, Typography } from '@agro-club/agroclub-shared'
import styled from 'styled-components'
import { ASimpleLink } from 'views/components/Analytics'

export const TitleWithBadges = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
  margin-bottom: 16px;

  div {
    margin-bottom: 0;
  }
`

export const BadgesWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
`

export const StyledLink = styled(ASimpleLink)`
  ${Typography.bodyLarge};
  display: block;
  color: ${defaultTheme.color.primary600};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`

export const TripAddress = styled.div`
  ${Typography.bodyMedium}
  color: ${defaultTheme.color.textPrimary900}
`

export const TripParameters = styled.div`
  ${Typography.bodyMedium}
  color: ${defaultTheme.color.textSecondary60a}
`

export const Arrow = styled(IconArrowRight)`
  margin: 0 8px;
  align-self: center;
  fill: ${defaultTheme.color.secondary300};
`

export const Container = styled.div`
  display: flex;
  margin: 8px 0 22px;
`

export const AddressWrapper = styled.div`
  width: 550px;
  padding: 12px;
  border-radius: 8px;
  background-color: ${defaultTheme.color.backgroundSecondary};
  box-shadow: ${defaultTheme.boxShadowColor.common};
`
