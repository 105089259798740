import { refetchFunc } from 'modules/domain/common/hooks'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AButton } from 'views/components/Analytics'
import { TripTtnDocType } from 'views/pages/LogisticsKR/types'
import { UploadTtnModal } from '../UploadTtnModal/UploadTtnModal'

type Props = {
  loadType: 'load' | 'unload'
  tripId: string
  onSuccess: (() => void) | refetchFunc
}

export const UploadTtnButton: FC<Props> = ({ loadType, tripId, onSuccess }) => {
  const { t } = useTranslation('logisticsKR')
  const [isOpen, setIsOpen] = useState(false)
  const docType = loadType === 'load' ? TripTtnDocType.load : TripTtnDocType.unload

  return (
    <>
      <AButton id="ttn_files_modal" size="small" intent={'secondary'} onClick={() => setIsOpen(true)}>
        {t('details.loadTtn')}
      </AButton>
      {isOpen && (
        <UploadTtnModal tripId={tripId} docType={docType} onSuccess={onSuccess} onClose={() => setIsOpen(false)} />
      )}
    </>
  )
}
