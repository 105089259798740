import { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { AntTag, Icons } from '@agro-club/agroclub-shared'
import { BoldText, CompanyWrapper, TagsWrapper } from '../styled'
import { LogisticRequest } from 'modules/domain/logisticsKR/types'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import DealsKRRoutes from 'views/pages/LogisticsKR/LogisticRequests/routes'
import { ItemCard, CardButtonWrapper } from 'views/styled/ItemCardStyles'
import { formatTarif } from 'modules/utils/numbers/formatTarif'
import { formatQuantity } from 'modules/utils/numbers/formatQuantity'
import { AButton, ARouterLink, ASimpleLink } from 'views/components/Analytics'
import { TripOffersTags } from 'views/pages/LogisticsKR/LogisticRequests/components/TripOffersTags/TripOffersTags'
import CompanyRoutes from 'views/pages/Company/routes'
import { LogisticStatuses } from 'views/pages/LogisticsKR/types'
import { DispatchingStatusesTags } from 'views/components/DispatchingStatusesTags/DispatchingStatusesTags'

type ItemProps = {
  setDrawerIsOpen?: (arg: boolean) => void
  request: LogisticRequest
  setActiveRequest: (arg: LogisticRequest) => void
}

export const LogisticRequestsCard: FC<ItemProps> = ({ request, setActiveRequest, setDrawerIsOpen }) => {
  const { t } = useTranslation('logisticBids')
  const { activeTab, selectedRequest } = useParams()
  const navigate = useNavigate()
  const isActive = String(request.id) === String(selectedRequest)
  const isInProgress = request.logistics_status === LogisticStatuses.in_progress
  const isClosed = request.is_closed

  useEffect(() => {
    if (isActive) setActiveRequest(request)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRequest, request])

  const onClickHandler = () => {
    navigate(
      {
        pathname: generatePath(DealsKRRoutes.ListRequestsWithTabAndRequest, {
          activeTab,
          selectedRequest: request.id,
        }),
        search: location.search,
      },
      { replace: true },
    )

    if (request.logistics_status === LogisticStatuses.new) {
      setDrawerIsOpen?.(true)
    }
  }
  return (
    <ItemCard
      id={isActive ? `${selectedRequest}active` : ''}
      style={{ rowGap: 8 }}
      activeCard={isActive}
      onClick={onClickHandler}
    >
      <TagsWrapper>
        <AntTag color={isClosed ? 'grey' : 'white'}>{t(`tags.${request?.source}`)}</AntTag>
        {isInProgress && <DispatchingStatusesTags status={request.deal_dispatching_status} />}
      </TagsWrapper>
      <BoldText>
        <div>{`${request.product?.name}, `}</div>
        <div>
          {`${formatQuantity(request.quantity)}, ${request.distance} ${t('common:km')},
               ${formatTarif(request.price_logistics)}`}
        </div>
      </BoldText>
      <CompanyWrapper>
        {request.loading_company?.id && (
          <ARouterLink
            onClick={(e) => e.stopPropagation()}
            eventName="company"
            itemId={request.loading_company.id}
            to={generatePath(CompanyRoutes.Details, { id: request.loading_company.id })}
          >
            {request.loading_company.name}
          </ARouterLink>
        )}
        <div>{request.loading_warehouse?.address}</div>
      </CompanyWrapper>
      <CompanyWrapper>
        {request.unloading_company?.id && (
          <ARouterLink
            onClick={(e) => e.stopPropagation()}
            eventName="company"
            itemId={request.unloading_company.id}
            to={generatePath(CompanyRoutes.Details, { id: request.unloading_company.id })}
          >
            {request.unloading_company.name}
          </ARouterLink>
        )}
        <div>{request.unloading_warehouse?.address}</div>
      </CompanyWrapper>
      <TripOffersTags request={request} />

      <CardButtonWrapper>
        {request?.id && (
          <ASimpleLink
            onClick={(e) => e.stopPropagation()}
            eventName="requestDetail"
            itemId={request.id}
            to={generatePath(DealsKRRoutes.Details, { id: request.id })}
          >
            <AButton id="requestDetail" Icon={Icons.IconOpen} intent="white" />
          </ASimpleLink>
        )}
      </CardButtonWrapper>
    </ItemCard>
  )
}
