import { useState, FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Progress } from '@agro-club/agroclub-shared'
import { useAProgress } from 'hooks/useAProgress'
import { apiClient } from 'modules/utils/httpClient'
import { endpoints } from 'modules/endpoints'
import { useNotificationProgress } from 'hooks/useNotificationProgress'
import { useNavigate } from 'react-router-dom'
import { SharedModalContent, SharedModalFooter, SharedModalTitle } from 'views/components/SharedModal/styled'
import { AButton } from 'views/components/Analytics'
import ASharedModal from 'views/components/SharedModal/ASharedModal'
import { AnalyticsPlaces } from '../../analyticsPlaces'

type Props = {
  id: string
}

export const DeletePotentialButton: FC<Props> = ({ id }) => {
  const { t } = useTranslation('bid')
  const navigate = useNavigate()
  const [isConfirmVisible, setConfirmVisible] = useState<boolean>(false)
  const [deleteBidProgress, deleteBid] = useAProgress(() => apiClient.delete(endpoints.potentialBid(id)), {
    eventName: 'delete',
    onSuccess: () => {
      setConfirmVisible(false)
      navigate(-1)
    },
  })
  useNotificationProgress(deleteBidProgress, t('potentialDeleteSuccess'))

  return (
    <>
      <AButton
        intent="destruct"
        size="medium"
        onClick={() => {
          setConfirmVisible(true)
        }}
        id="delete"
      >
        {t('common:delete')}
      </AButton>

      {isConfirmVisible && (
        <ASharedModal onClose={() => setConfirmVisible(false)} size="small" id={AnalyticsPlaces.DELETE_MODAL}>
          <SharedModalTitle>{t('deletePotential')}</SharedModalTitle>
          <SharedModalContent>{t('areYouSureToDeletePotential')}</SharedModalContent>
          <SharedModalFooter>
            <AButton
              id="delete"
              intent="primary"
              size="big"
              disabled={deleteBidProgress === Progress.WORK}
              onClick={deleteBid}
              progress={deleteBidProgress}
            >
              {t('common:delete')}
            </AButton>

            <AButton intent="secondary" size="big" onClick={() => setConfirmVisible(false)} id="close">
              {t('common:canceling')}
            </AButton>
          </SharedModalFooter>
        </ASharedModal>
      )}
    </>
  )
}
