import { Checkbox, FormGroup } from '@agro-club/agroclub-shared'
import { useFormik } from 'formik'
import { useAProgress } from 'hooks/useAProgress'
import { useNotificationProgress } from 'hooks/useNotificationProgress'
import { DocumentRequestDatesUpdateDTO, DocumentRequestFull } from 'modules/domain/documentRequest/types'
import { endpoints } from 'modules/endpoints'
import { RequestError } from 'modules/errors'
import { apiClient } from 'modules/utils/httpClient'

import { VFC } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { AButton } from 'views/components/Analytics'
import { Card } from 'views/components/Card/Card'
import { DatePicker } from 'views/components/DatePicker/DatePicker'

const CheckboxStyled = styled(Checkbox)`
  margin-top: 8px;
`

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

const Buttons = styled.div`
  display: flex;
  gap: 16px;
`

type Props = {
  documentRequest: DocumentRequestFull
  refetch: (silent: true) => void
}

const documentRequestTODatesDTO = (documentRequest: DocumentRequestFull): DocumentRequestDatesUpdateDTO => ({
  expected_complete_date: documentRequest.expected_complete_date,
  sampling_date: documentRequest.sampling_date,
  received_ds_date: documentRequest.received_ds_date,
  received_quarantine_date: documentRequest.received_quarantine_date,
  received_vet_date: documentRequest.received_vet_date,
  received_sdiz_date: documentRequest.received_sdiz_date,
  received_sdiz: documentRequest.received_sdiz,
  received_vet: documentRequest.received_vet,
  received_quarantine: documentRequest.received_quarantine,
  received_ds: documentRequest.received_ds,
  received_conclusion_date: documentRequest.received_conclusion_date,
  received_conclusion: documentRequest.received_conclusion,
  quantity: documentRequest.quantity,
})

export const DocumentRequestsDates: VFC<Props> = ({ documentRequest, refetch }) => {
  const { t } = useTranslation('documentRequests')

  const [progress, updateDates] = useAProgress<DocumentRequestDatesUpdateDTO>(
    (dto: DocumentRequestDatesUpdateDTO) => apiClient.put(endpoints.dealDocumentRequest(documentRequest.id), dto),
    {
      eventName: 'updateDates',
    },
  )

  useNotificationProgress(progress, t('detail.main.datesUpdated'))

  const formik = useFormik<DocumentRequestDatesUpdateDTO>({
    initialValues: documentRequestTODatesDTO(documentRequest),
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        await updateDates(values)
        formik.initialValues = values
        refetch(true)
      } catch (err) {
        const error = err as RequestError
        const { errors } = error
        formik.setErrors(errors || {})
      }
    },
  })

  return (
    <Card.Container intent="primary" bordered>
      <Card.GapableContent>
        <Card.Title>{t('detail.dates.title')}</Card.Title>
        <FormWrapper>
          <FormGroup
            label={t('detail.dates.expectedCompleteDate')}
            error={formik.errors.expected_complete_date}
            horizontal={false}
            disableRightPadding
          >
            <DatePicker
              value={formik.values.expected_complete_date}
              onChange={(data) => formik.setFieldValue('expected_complete_date', data['date'])}
              placeholder={t('common:datePlaceholder')}
            />
          </FormGroup>
          {documentRequest.request_ds && (
            <FormGroup
              label={t('detail.dates.receivedDsDate')}
              error={formik.errors.received_ds_date}
              horizontal={false}
              disableRightPadding
            >
              <DatePicker
                value={formik.values.received_ds_date}
                onChange={(data) => formik.setFieldValue('received_ds_date', data['date'])}
                placeholder={t('common:datePlaceholder')}
              />
              <CheckboxStyled
                isChecked={formik.values.received_ds}
                onChange={(_, val) => formik.setFieldValue('received_ds', val)}
                label={t('detail.dates.documentsReceived')}
              />
            </FormGroup>
          )}
          {documentRequest.request_quarantine && (
            <>
              <FormGroup
                label={t('detail.dates.samplingDate')}
                error={formik.errors.received_ds_date}
                horizontal={false}
                disableRightPadding
              >
                <DatePicker
                  value={formik.values.sampling_date}
                  onChange={(data) => formik.setFieldValue('sampling_date', data['date'])}
                  placeholder={t('common:datePlaceholder')}
                />
              </FormGroup>
              <FormGroup
                label={t('detail.dates.receivedConclusionDate')}
                error={formik.errors.received_conclusion_date}
                horizontal={false}
                disableRightPadding
              >
                <DatePicker
                  value={formik.values.received_conclusion_date}
                  onChange={(data) => formik.setFieldValue('received_conclusion_date', data['date'])}
                  placeholder={t('common:datePlaceholder')}
                />
                <CheckboxStyled
                  isChecked={formik.values.received_conclusion}
                  onChange={(_, val) => formik.setFieldValue('received_conclusion', val)}
                  label={t('detail.dates.documentsReceived')}
                />
              </FormGroup>

              <FormGroup
                label={t('detail.dates.receivedQuarantineDate')}
                error={formik.errors.received_quarantine_date}
                horizontal={false}
                disableRightPadding
              >
                <DatePicker
                  value={formik.values.received_quarantine_date}
                  onChange={(data) => formik.setFieldValue('received_quarantine_date', data['date'])}
                  placeholder={t('common:datePlaceholder')}
                />
                <CheckboxStyled
                  isChecked={formik.values.received_quarantine}
                  onChange={(_, val) => formik.setFieldValue('received_quarantine', val)}
                  label={t('detail.dates.documentsReceived')}
                />
              </FormGroup>
            </>
          )}

          {documentRequest.request_vet && (
            <FormGroup
              label={t('detail.dates.receivedVetDate')}
              error={formik.errors.received_vet_date}
              horizontal={false}
              disableRightPadding
            >
              <DatePicker
                value={formik.values.received_vet_date}
                onChange={(data) => formik.setFieldValue('received_vet_date', data['date'])}
                placeholder={t('common:datePlaceholder')}
              />
              <CheckboxStyled
                isChecked={formik.values.received_vet}
                onChange={(_, val) => formik.setFieldValue('received_vet', val)}
                label={t('detail.dates.documentsReceived')}
              />
            </FormGroup>
          )}
          {documentRequest.request_sdiz && (
            <FormGroup
              label={t('detail.dates.receivedSdizDate')}
              error={formik.errors.received_sdiz_date}
              horizontal={false}
              disableRightPadding
            >
              <DatePicker
                value={formik.values.received_sdiz_date}
                onChange={(data) => formik.setFieldValue('received_sdiz_date', data['date'])}
                placeholder={t('common:datePlaceholder')}
              />
              <CheckboxStyled
                isChecked={formik.values.received_sdiz}
                onChange={(_, val) => formik.setFieldValue('received_sdiz', val)}
                label={t('detail.dates.documentsReceived')}
              />
            </FormGroup>
          )}
          {formik.dirty && (
            <Buttons>
              <AButton id="saveDateUpdate" intent="primary" progress={progress} onClick={() => formik.handleSubmit()}>
                {t('detail.dates.save')}
              </AButton>
              <AButton id="cancelDateUpdate" intent="secondary" onClick={() => formik.resetForm()}>
                {t('common:cancel')}
              </AButton>
            </Buttons>
          )}
        </FormWrapper>
      </Card.GapableContent>
    </Card.Container>
  )
}
