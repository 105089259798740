import { Route, Routes } from 'react-router-dom'
import { ContractsList } from 'views/pages/Contracts/ContractsList/ContractsList'
import { ContractDetails } from 'views/pages/Contracts/ContractDetails/ContractDetails'
import { ContractsRoutes } from 'views/pages/Contracts/routes'

export const ContractsPage = () => {
  return (
    <Routes>
      <Route index element={<ContractsList />} />
      <Route path={ContractsRoutes.relativeDetails} element={<ContractDetails />} />
    </Routes>
  )
}
