import { FC, useMemo } from 'react'
import { FormikProps } from 'formik'
import { Select } from '@agro-club/agroclub-shared'
import { LoanAgreement } from '../../types'

interface Props {
  formik: FormikProps<any>
  fieldName: string
  value?: string
  placeholder: string
  agreements?: LoanAgreement[]
  disabled?: boolean
}

export const LoanAgreementSelect: FC<Props> = ({ formik, agreements, value, fieldName, placeholder, disabled }) => {
  const options = useMemo(() => {
    if (!agreements) return []
    return agreements.map((a) => ({ value: String(a.id), label: `...${a.name.slice(-4)}` }))
  }, [agreements])

  return (
    <Select
      onChange={(val) => {
        formik.setFieldValue(fieldName, val)
      }}
      value={value}
      options={options}
      showSearch={false}
      fullWidth
      allowClear={false}
      placeholder={placeholder}
      disabled={disabled}
    />
  )
}
