import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { CollapseHeaderWrapper, TitleAndTagWrapper } from '../../../components/styled'
import { Progress } from '@agro-club/agroclub-shared'
import { BoardCounter } from 'views/components/Board/BoardCounter'

type Props = {
  counter: number | undefined
  progress: Progress
}

export const MatchesHeader: FC<Props> = ({ counter, progress }) => {
  const { t } = useTranslation('logisticBids')

  return (
    <CollapseHeaderWrapper>
      <TitleAndTagWrapper>
        <div>{t('board_columns.title.matches')}</div>
        <BoardCounter counter={counter} progress={progress} />
      </TitleAndTagWrapper>
    </CollapseHeaderWrapper>
  )
}
