import { AvailableLanguages, GoogleAddressFilter, YandexAddressSelectFilter } from '@agro-club/agroclub-shared'
import { AnalyticsFilterVariant } from 'analytics/types'
import { InputFilter } from '../InputFilter/InputFilter'
import { useAnalyticsContext } from 'analytics/hooks'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import {
  REACT_APP_YA_MAPS_SUGGEST_API_KEY,
  REACT_APP_GOOGLE_MAPS_API_KEY,
  REACT_APP_YA_MAPS_API_KEY,
  isBrazil,
  isRussia,
  isUS,
} from 'env'

const Wrapper = styled.div`
  display: flex;
  gap: 0 16px;
  .select-address-filter .ant-select-selector {
    min-width: 400px;
  }
`
const googleOptions = {
  searchOptions: {
    componentRestrictions: { country: isBrazil ? 'br' : null },
  },
  language: isBrazil ? AvailableLanguages.pt_BR : AvailableLanguages.en,
}

export type AddressFilterType = {
  address?: string
  longitude?: number
  latitude?: number
  radius?: number
  id?: AnalyticsFilterVariant
}

export type AddressFilterProps = {
  onChange: (addr: AddressFilterType) => void
  filter: AddressFilterType
  disabled?: boolean
}

export const AddressFilter: FC<AddressFilterProps> = ({ onChange, filter, disabled }) => {
  const { t } = useTranslation('bid')
  const { track } = useAnalyticsContext()
  const [addressString, setAddressString] = useState('')

  useEffect(() => {
    setAddressString(decodeURIComponent(filter.address || ''))
  }, [filter.address])

  const handleAddressChange = ({ coords, address }) => {
    const [latitude, longitude] = coords || []
    const encodedAddress = encodeURIComponent(address)
    onChange({
      address: encodedAddress || undefined,
      latitude,
      longitude,
    })
    track(`change|${AnalyticsFilterVariant.ADDRESS}`, {
      value: { to: decodeURIComponent(address ?? '') },
    })
  }

  const commonProps = {
    notFoundContent: t('form.noAddressOptionMessage'),
    placeholder: t('filterByAddress'),
    onChange: handleAddressChange,
    addressString,
    disabled,
  }

  const suffix = t(isUS ? 'common:ml' : 'common:km')

  return (
    <Wrapper>
      {!isRussia ? (
        <GoogleAddressFilter
          className="ant-select-filter select-address-filter"
          apikey={REACT_APP_GOOGLE_MAPS_API_KEY}
          {...googleOptions}
          {...commonProps}
        />
      ) : (
        <YandexAddressSelectFilter
          suggest_apikey={REACT_APP_YA_MAPS_SUGGEST_API_KEY}
          apikey={REACT_APP_YA_MAPS_API_KEY}
          {...commonProps}
        />
      )}
      <InputFilter
        id={AnalyticsFilterVariant.RADIUS}
        placeholder={t('filterByRadius')}
        onChange={(radius) =>
          onChange({
            ...filter,
            radius: radius || undefined,
          })
        }
        value={filter.radius}
        disabled={disabled}
        suffix={suffix}
        type="number"
      />
    </Wrapper>
  )
}
