import { Progress } from '@agro-club/agroclub-shared'

import { Spinner } from 'views/components/CommonTableComponents/CommonTableComponents'
import { ColumnItem, Item } from './ColumnItem'
import { ColumnContentHeader } from './ColumnContentHeader'
import { Column } from 'views/components/ColumnBoard/styled'
import { FC, ReactNode } from 'react'

type Props = {
  items: Array<Item>
  title: string
  count: number
  headerButton?: ReactNode
  progress: Progress
  ItemComponent: FC<{ item: Item }>
  initialOpened?: boolean
}

export const ColumnContent: FC<Props> = ({ items, title, count, progress, ItemComponent, initialOpened = false }) => {
  let content
  if (progress === Progress.WORK) {
    content = <Spinner />
  } else {
    content = items.map((i) => <ColumnItem key={i.id} item={i} ItemComponent={ItemComponent} />)
  }

  return (
    <ColumnContentHeader title={title} count={count} initialOpened={initialOpened}>
      {content}
    </ColumnContentHeader>
  )
}

export { Column }
