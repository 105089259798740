import { useContext } from 'react'
import { AuthContext } from 'modules/context/AuthContext'
import _ from 'lodash'

export const usePermissions = (scope: string[]): boolean => {
  const { profile } = useContext(AuthContext)
  const available = _.intersection(profile?.permissions ?? [], scope).length > 0

  return available
}
