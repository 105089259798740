import { FC } from 'react'
import { Bid, BidContract, USBid } from 'modules/domain/bid/types'
import { refetchFunc } from 'modules/domain/common/hooks'
import { isBrazil, isRussia, isUS } from 'env'
import { RUBidDetailProduct } from './RUBidDetailProduct'
import { USEditableBidInfo } from 'views/components/USEditableBidInfo/USEditableBidInfo'
import { BRBidDetailProduct } from './BRBidDetailProduct'

type Props = {
  contract: BidContract | null
  bid: Bid
  readOnly: boolean
  refetch: refetchFunc
}

export const BidDetailProduct: FC<Props> = ({ bid, refetch, readOnly, contract }) => {
  /* TODO Enable after finalization of the api!!! */
  // const readOnlyUSBidDetail =
  //   deal.status === DealStatusTypes.DRAFT || bid.source === BidSources.BARCHART || !(bid as USBid).contract_type

  /* TODO Turn off after finalizing the api!!! */
  const readOnlyUSBidDetail = true

  const contractOrBid = contract || bid

  if (isRussia) return <RUBidDetailProduct bid={contractOrBid} readOnly={readOnly} refetch={refetch} />

  if (isBrazil) return <BRBidDetailProduct bid={contractOrBid} />

  if (isUS)
    return (
      <USEditableBidInfo bid={bid as USBid} refreshBid={refetch} usedOnPage="deal" readOnly={readOnlyUSBidDetail} />
    )
}
