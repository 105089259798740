import { FormikProps } from 'formik'
import { USAddBidFields, USEditBidFields } from 'views/pages/Bid/BidModals/Bid/USModals/types'

type BidFields = USAddBidFields | USEditBidFields

export const getAndSetCashPrice = (formik: FormikProps<BidFields>) => {
  const isValidBasisForSum = typeof formik.values.basis === 'number'
  const isValidFuturesForSum = typeof formik.values.futures_price === 'number' && formik.values.futures_price > 0
  const price =
    isValidBasisForSum && isValidFuturesForSum ? (formik.values.basis ?? 0) + (formik.values.futures_price ?? 0) : 0

  formik.setFieldValue('price', price ? parseFloat(String(price)).toFixed(2) : 0)
}
