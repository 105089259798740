import { FC } from 'react'
import { useFormik } from 'formik'
import { endpoints } from 'modules/endpoints'
import { apiClient } from 'modules/utils/httpClient'
import { useTranslation } from 'react-i18next'
import FormSelectShared from 'views/components/FormSelectShared'
import { SharedModalContent, SharedModalInputs, SharedModalTitle } from 'views/components/SharedModal'
import ASharedModal from 'views/components/SharedModal/ASharedModal'
import { AnalyticsPlaces } from '../../analyticsPlaces'
import ADefaultModalFooter from 'views/components/DefaultModalFooter/ADefaultModalFooter'
import { useAFormHandler } from 'analytics/hooks'
import { isBrazil } from 'env'

interface Props {
  id: string[]
  deal_id: string
  onClose: () => void
  onSuccess: () => void
}

type Fields = {
  logisticians?: string[]
  logistician?: string
}
export const EditLogisticianModal: FC<Props> = ({ id, deal_id, onClose, onSuccess }) => {
  const { t } = useTranslation('dispatching')
  const { formProgress, formHandler } = useAFormHandler()
  const fieldName = isBrazil ? 'logistician' : 'logisticians'
  const logisticianIds = isBrazil ? id[0] : id

  const formik = useFormik<Fields>({
    initialValues: {
      [fieldName]: logisticianIds,
    },
    onSubmit: formHandler(async () => await apiClient.put<Fields>(endpoints.executorsEdit(deal_id), formik.values), {
      onSuccess: async () => {
        await onSuccess()
        onClose()
      },
      onError: (error) => {
        const { errors } = error
        formik.setErrors(errors)
      },
    }),
  })

  return (
    <ASharedModal id={AnalyticsPlaces.EDIT_LOGISTIAN_MODAL} onClose={onClose} size="small">
      <SharedModalTitle>{t('modal.editLogisticianTitle')}</SharedModalTitle>
      <SharedModalContent>
        <SharedModalInputs>
          <FormSelectShared
            hideLabel
            formik={formik}
            endpoint={endpoints.dispatchingLogisticians()}
            fieldName={fieldName}
            getLabel={(dto) => dto.full_name}
            multiple={!isBrazil}
            searchField="search"
          />
        </SharedModalInputs>
      </SharedModalContent>
      <ADefaultModalFooter
        onClose={onClose}
        disabled={!formik.dirty}
        confirmButtonText={t('common:save')}
        progress={formProgress}
        onConfirm={() => formik.submitForm()}
      />
    </ASharedModal>
  )
}
