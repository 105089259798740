import { MobileModalFooter } from 'views/components/MobileComponents/Modal/MobileModalFooter'
import { FormGroup, Input, OptionType, Select, Text } from '@agro-club/agroclub-shared'
import { AMobileModal } from 'views/components/MobileComponents/Modal/AMobileModal'
import { SharedModalInputs } from 'views/components/SharedModal'
import { labelPrice } from 'modules/utils/numbers/formatPrice'
import {
  Bank,
  DispatchingItem,
  EmptyPaymentWithDate,
  PaymentSources,
  PaymentStatus,
  PaymentWithDate,
} from '../../types'
import { refetchFunc, useSingleEntity } from 'modules/domain/common/hooks'
import { apiClient } from 'modules/utils/httpClient'
import { useAFormHandler } from 'analytics/hooks'
import useFormatDate from 'hooks/useFormatDate'
import { useTranslation } from 'react-i18next'
import { endpoints } from 'modules/endpoints'
import { useFormik } from 'formik'
import { FC, useMemo } from 'react'
import { PaymentStatuses } from '../DispatchingModal/PaymentStatuses'
import { EnumSelect } from 'views/components/form/EnumSelect/EnumSelect'
import { LoanAgreementControls } from '../DispatchingModal/LoanAgreementControls'

type Props = {
  dispatchItem: DispatchingItem
  refetch: refetchFunc
  onClose: () => void
  data: PaymentWithDate | EmptyPaymentWithDate
}

export const EditPayment: FC<Props> = ({ onClose, data, dispatchItem, refetch }) => {
  const { t } = useTranslation('dispatching')
  const formatDate = useFormatDate()

  const { formProgress, formHandler } = useAFormHandler('addCompanyContract')
  const [, banks] = useSingleEntity<Bank[]>(endpoints.dispatchingBanks())
  const banksOptions: OptionType[] = useMemo(() => {
    if (!banks) return []
    return banks.map((bank) => ({
      value: String(bank.id),
      label: bank.name,
    }))
  }, [banks])

  const formik = useFormik<{
    amount: number | undefined
    status: PaymentStatus | undefined
    bank: string | undefined
    loan_agreement: string | undefined
    source: PaymentSources
  }>({
    initialValues: {
      amount: data.amount,
      status: data.status,
      bank: data.bank?.id.toString(),
      loan_agreement: data.loan_agreement?.id.toString(),
      source: data.source ?? PaymentSources.OWN,
    },

    enableReinitialize: true,

    onSubmit: formHandler(
      async () => {
        const paymentsDto = Object.entries(dispatchItem.payments).map(([date, val]) => {
          if (date === data.date) {
            return {
              source: formik.values.source,
              amount: formik.values.amount,
              payment_date: date,
              bank: formik.values.bank,
              status: formik.values.status,
              loan_agreement: formik.values.loan_agreement,
            }
          }
          return {
            ...val,
            payment_date: date,
            bank: val.bank?.id.toString(),
            loan_agreement: val.loan_agreement?.id.toString(),
          }
        })

        const updatedDepartureItem = {
          ...dispatchItem,
          payments: paymentsDto,
        }

        await apiClient.put<DispatchingItem>(endpoints.dispatchingItem(dispatchItem.id), updatedDepartureItem)
      },
      {
        onSuccess: async () => {
          await refetch()
          onClose()
        },
        onError: (error) => {
          const { errors } = error
          formik.setErrors(errors)
        },
      },
    ),
  })

  return (
    <AMobileModal
      header={<Text typography="titleH4">{`${t('payment')} ${formatDate(data.date)}`}</Text>}
      onClose={onClose}
      id="editPayment"
    >
      <SharedModalInputs>
        <FormGroup label={labelPrice(t('amount'))} error={formik.errors.amount}>
          <Input {...formik.getFieldProps('amount')} invalid={!!formik.errors.amount} type="number" />
        </FormGroup>
        <EnumSelect
          formik={formik}
          fieldName="source"
          enumType={PaymentSources}
          translationNameSpace="dispatching:paymentSources"
          placeholder={t('modal.inputs.source_placeholder')}
        />
        <Select
          onChange={(bank) => {
            formik.setFieldValue(`bank`, bank)
            formik.setFieldValue(`loan_agreement`, null)
          }}
          placeholder={t('modal.inputs.bank_placeholder')}
          value={formik.values.bank}
          options={banksOptions}
          showSearch={false}
          fullWidth
          allowClear={false}
        />
        <LoanAgreementControls
          formik={formik}
          bankId={formik.values.bank}
          value={formik.values.loan_agreement}
          fieldName="loan_agreement"
          isMobile
        />
        <PaymentStatuses formik={formik} fieldName="status" />
      </SharedModalInputs>
      <MobileModalFooter
        disabled={!formik.values.amount || !formik.values.status}
        onConfirm={() => formik.submitForm()}
        progress={formProgress}
      />
    </AMobileModal>
  )
}
