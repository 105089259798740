import formatPrice from './formatPrice'
import { t } from 'i18next'
import env, { isUS } from 'env'
import { isBrazil } from 'env'

const currency = env.REACT_APP_CURRENCY_SYMBOL

export const unit = () => {
  if (isBrazil) {
    return t('common:bag')
  }
  if (isUS) {
    return t('common:bsh')
  }
  return t('common:kg')
}

export const formatTarif = (value, hasVat = false) => {
  return `${formatPrice(value, true)}/${unit()}${hasVat ? `, ${t('common:has_vat')}` : ''}`
}

export const labelTarif = (label?: string, comma = true) => {
  return `${label}${comma ? ', ' : ' '}${currency}/${unit()}`
}
