import { Bid, BidStatus } from 'modules/domain/bid/types'
import UserRoutes from 'views/pages/User/routes'
import { generatePath } from 'react-router'
import { PotentialBid } from 'modules/domain/potentialBid/types'

export const getUserLink = (bid: Bid | PotentialBid): string => {
  const link =
    bid.status === BidStatus.published || bid.status === BidStatus.archived
      ? generatePath(UserRoutes.DetailsWithBid, {
          id: String(bid.owner.id),
          bidId: String(bid.id),
          statusBid: bid.status,
        })
      : generatePath(UserRoutes.Details, { id: String(bid.owner.id) })
  return link
}
