import { FC } from 'react'
import styled from 'styled-components'
import logoImg from 'assets/images/logo-img.svg'
import { defaultTheme } from '@agro-club/agroclub-shared'

const Root = styled.div`
  display: flex;
  width: 100vw;
  height: 100dvh;
  padding: 0 32px 0 0;

  @media (max-width: ${defaultTheme.queries.mobile}) {
    flex-direction: column;
    position: relative;
    padding: 0 16px;
  }
`

export const Sidebar = styled.div`
  height: 100dvh;
  display: flex;
  flex-direction: column;
  width: fit-content;
  transition: width 0.2s ease-out;
  position: relative;
  background-color: ${defaultTheme.color.backgroundPrimary};
  padding: 22px 0;
  overflow: hidden;
`

export const Page = styled.div`
  height: 100dvh;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  overflow: hidden;
  background-color: ${defaultTheme.color.backgroundPrimary};
`

export const LogoStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
`

export const LogoImageContainer = styled.div`
  display: flex;
  align-items: center;
`

export const Logo: FC = () => {
  return (
    <LogoStyled>
      <LogoImageContainer>
        <img src={logoImg} height={34} width={34} alt="logo" />
      </LogoImageContainer>
    </LogoStyled>
  )
}

export const Bar = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

export const Divider = styled.div`
  height: 1px;
  background-color: ${defaultTheme.color.secondary16a};
  margin: 0 0 8px;
`

export const Header = styled.div<{ hasBottomBorder?: boolean }>`
  flex-shrink: 0;
  border-bottom: ${({ theme, hasBottomBorder }) => (hasBottomBorder ? `1px solid ${theme.color.secondary50}` : 'none')};
`

export const Content = styled.div<{ locked?: boolean }>`
  background-color: ${({ theme }) => theme.color.backgroundPrimary};
  flex-grow: 1;
  overflow: auto;

  & > div > div:last-child {
    pointer-events: ${({ locked }) => (locked ? 'none' : 'auto')};
  }
`

export const Layout: FC<{ children?: React.ReactNode }> = ({ children }) => <Root>{children}</Root>
