import { FC } from 'react'

import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'

import { CloseDealModalContent } from './CloseDealModalContent'

import { endpoints } from 'modules/endpoints'
import { apiClient } from 'modules/utils/httpClient'

import { useAFormHandler } from 'analytics/hooks'
import { useDealContext } from 'views/pages/Deal/DealDetail/DealContext'
import { ResponsiveModal } from '../ResponsiveModal/ResponsiveModal'

interface Props {
  onClose: () => void
}

export const CloseDealModal: FC<Props> = ({ onClose }) => {
  const { deal, dealRefetch } = useDealContext()
  const { formProgress, formHandler } = useAFormHandler('closeDeal', { id: deal.id })
  const { t } = useTranslation('deal')
  const formik = useFormik<{ closed_reason: string }>({
    initialValues: {
      closed_reason: '',
    },
    enableReinitialize: false,

    onSubmit: formHandler(
      async () => {
        await apiClient.post(endpoints.dealClose(deal.id), formik.values)
      },
      {
        onSuccess: async () => {
          await dealRefetch(true)
          onClose()
        },
        onError: (error) => {
          const { errors } = error
          formik.setErrors(errors)
        },
      },
    ),
  })
  return (
    <ResponsiveModal
      onConfirm={() => formik.submitForm()}
      title={t('closeDealModal.title')}
      disabled={!formik.dirty}
      progress={formProgress}
      id="CloseDealModal"
      onClose={onClose}
      size="small"
    >
      <CloseDealModalContent formik={formik} value="closed_reason" />
    </ResponsiveModal>
  )
}
