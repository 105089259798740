import { addMonths, isBefore } from 'date-fns'

import { Dict } from 'types/generics.d'
import { BidParameter } from 'modules/domain/bid/types'

type StringFields<T extends { id: string }> = keyof {
  [P in 'id']: string extends T[P] ? P : never
}

export function arrToDict<T extends { id: string }>(arr: T[], prop: StringFields<T> = 'id'): Dict<T> {
  const result: Dict<T> = {}
  for (const item of arr) {
    result[item[prop]] = item
  }
  return result
}

export function getIds<T extends { id: string }>(arr: T[]): string[] {
  return arr.map((item) => item.id)
}

export const getMonthList = () => {
  //it is important to have 2021-09-01 for current time zone
  let startDate = new Date(2021, 8, 1)
  const endDate = Date.now()
  const result: Date[] = []

  while (isBefore(startDate, endDate)) {
    result.push(startDate)
    startDate = addMonths(startDate, 1)
  }
  return result.reverse()
}

/** @deprecated */
export function formatNumber(num) {
  const parts = num.toString().split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
  return parts.join(',')
}

export const getMainParameter = (parameters: BidParameter[]) => {
  const parameter = parameters?.length ? parameters[0] : undefined
  return parameter ? parameter.description.trim() : ''
}
/**
 * It takes an object with dot notation properties and returns an object with underscore notation
 * properties. This function is needed when nested objects in errors from the server are reduced to a flat format.
 * In this case, we need to replace the name of the objectName.propertyName format property with objectName_propertyName.
 *
/* @deprecated */
export const replaceErrorsDotNotation = (errors: object) => {
  const dto = {}

  Object.keys(errors).forEach((propertyName) => {
    const value = errors[propertyName]

    dto[propertyName.replace(/\./g, '_')] = value
  })

  return dto
}

export const changeErrorsDotsToPipes = (errors: object) => {
  const dto = {}

  Object.keys(errors).forEach((propertyName) => {
    const value = errors[propertyName]

    dto[propertyName.replace(/\./g, '|')] = value
  })

  return dto
}

export const addPropertyNamePrefix = (values: object, prefix: string) => {
  const dto = {}

  Object.keys(values).forEach((key) => {
    const value = values[key]

    dto[`${prefix}_${key}`] = value
  })

  return dto
}

/**
 * It takes an object and a regular expression and returns a new object with the keys of the original
 * object minus the regular expression
 */
export const removePropertyNamePrefix = (values: object, pattern: RegExp) => {
  const dto = {}

  Object.keys(values).forEach((key) => {
    const value = values[key]

    dto[key.replace(pattern, '')] = value
  })

  return dto
}

/**
 * It takes an object and a string, and returns a new object with the string prefixed to each key
 * of an object
 */
export const addPrefixInErrors = (errors: object, name: string) => {
  const dto = replaceErrorsDotNotation(errors)

  Object.keys(errors).forEach((key) => {
    const value = errors[key]

    dto[`${name}_${key}`] = value
  })

  return dto
}
