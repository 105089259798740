import { Icons } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  svg {
    width: 20px;
    height: 20px;
  }
`

export const StatusesRightArrow = () => {
  return (
    <Wrapper>
      <Icons.IconArrowRightM />
    </Wrapper>
  )
}
