import { FC } from 'react'

import { ADateRangePicker } from 'views/components/TableFilters/DateRangePicker/ADateRangePicker'
import { FiltersAndSearchWrapper, FiltersWrapper } from 'views/components/CommonTableComponents/CommonTableComponents'
import { defaultListRequestParams } from 'views/components/TableFilters/data'
import { useDisplayClearBtn } from 'hooks/useDisplayClearBtn'
import { TableFiltersProps } from 'modules/types'
import { useTranslation } from 'react-i18next'
import { endpoints } from 'modules/endpoints'
import { AClearFiltersButton } from 'views/components/Analytics'
import { AnalyticsFilterVariant } from 'analytics/types'
import { ABaseTableFilter } from 'views/components/TableFilters/ABaseTableFilter'
import { SearchFilter } from 'views/components/SearchFilter/SearchFilter'

export const TasksFilters: FC<TableFiltersProps> = ({ setListRequestParams, updateFilterState, listRequestParams }) => {
  const { t } = useTranslation('my_tasks')

  const displayClearBtn = useDisplayClearBtn({
    listRequestParams,
  })

  return (
    <FiltersAndSearchWrapper>
      <FiltersWrapper>
        <ADateRangePicker
          value={[listRequestParams.filter.created_after, listRequestParams.filter.created_before]}
          onChange={(value) => updateFilterState({ created_after: value.startDate, created_before: value.endDate })}
          id={AnalyticsFilterVariant.DATE}
        />

        <ABaseTableFilter
          onChange={(task_type) => updateFilterState({ task_type })}
          endpoint={endpoints.typesTask('my-tasks')}
          value={listRequestParams.filter.task_type}
          placeholder={t('placeholders.type_task')}
          getLabel={(obj) => obj.label}
          searchField="search"
          showSearch={false}
          simpleApiFormat
          id={AnalyticsFilterVariant.TYPE}
        />

        <ABaseTableFilter
          onChange={(status) => updateFilterState({ status })}
          endpoint={endpoints.tasksStatuses()}
          value={listRequestParams.filter.status}
          placeholder={t('placeholders.status')}
          getLabel={(dto) => dto.label}
          showSearch={false}
          simpleApiFormat
          id={AnalyticsFilterVariant.STATUS}
        />

        <ABaseTableFilter
          onChange={(created_by) => updateFilterState({ created_by })}
          value={listRequestParams.filter.created_by}
          placeholder={t('placeholders.author')}
          getLabel={(label) => label.full_name}
          endpoint={endpoints.taskAssignees()}
          searchField="search"
          simpleApiFormat
          id={AnalyticsFilterVariant.AUTHOR}
        />

        <ABaseTableFilter
          onChange={(assignee) => updateFilterState({ assignee })}
          value={listRequestParams.filter.assignee}
          placeholder={t('placeholders.executor')}
          getLabel={(label) => label.full_name}
          endpoint={endpoints.taskAssignees()}
          searchField="search"
          simpleApiFormat
          id={AnalyticsFilterVariant.ASSIGNEE}
        />

        <ABaseTableFilter
          onChange={(team) => updateFilterState({ team })}
          value={listRequestParams.filter.team}
          placeholder={t('placeholders.team')}
          getLabel={(label) => label.label}
          endpoint={endpoints.tasksTeams()}
          showSearch={false}
          simpleApiFormat
          multiple
          id={AnalyticsFilterVariant.TEAMS}
        />
        <ABaseTableFilter
          onChange={(linked_user_company) => updateFilterState({ linked_user_company })}
          value={listRequestParams.filter.linked_user_company}
          placeholder={t('placeholders.company')}
          id={AnalyticsFilterVariant.COMPANY}
          endpoint={endpoints.company()}
          getLabel={(dto) => dto.name}
          searchField="search"
        />

        <ABaseTableFilter
          onChange={(priority) => updateFilterState({ priority })}
          value={listRequestParams.filter.priority}
          placeholder={t('placeholders.priority')}
          endpoint={endpoints.priorityTask()}
          getLabel={(label) => label.label}
          showSearch={false}
          simpleApiFormat
          id={AnalyticsFilterVariant.PRIORITY}
        />

        {displayClearBtn && (
          <AClearFiltersButton
            onClick={() => setListRequestParams && setListRequestParams({ ...defaultListRequestParams, filter: {} })}
          />
        )}
      </FiltersWrapper>
      <SearchFilter listRequestParams={listRequestParams} updateFilterState={updateFilterState} />
    </FiltersAndSearchWrapper>
  )
}
