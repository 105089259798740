import { Text, defaultTheme } from '@agro-club/agroclub-shared'
import { FC, ReactNode } from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  padding: 16px;
  background: ${defaultTheme.color.backgroundSecondary};
  border-radius: 16px;
  &:first-child {
    border-radius: 0 0 16px 16px;
  }
  &:last-child {
    border-radius: 16px 16px 0 0;
  }
`
const NameMobileFilter = styled(Text).attrs({
  typography: 'accentLarge',
})`
  margin-bottom: 8px;
`
type Props = {
  label?: string | ReactNode
  children: ReactNode
}

export const MobileFilterContainer: FC<Props> = ({ label, children }) => {
  return (
    <Wrapper>
      <NameMobileFilter>{label}</NameMobileFilter>
      {children}
    </Wrapper>
  )
}
