import styled from 'styled-components'
import { AButton } from 'views/components/Analytics'
import { Icons } from '@agro-club/agroclub-shared'
import { useCallback, useState } from 'react'
import { LogisticRequest } from 'modules/domain/logisticsKR/types'
import { TripOffer } from 'views/pages/LogisticsKR/types'
import { AddDraftTripModal } from 'views/pages/LogisticsKR/components/AddDraftTripModal/AddDraftTripModal'
import { ClosedReasonModal } from 'views/pages/LogisticsKR/LogisticRequests/components/ClosedReasonModal'

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  column-gap: 8px;
`

type Props = {
  request: LogisticRequest
  tripOffer: TripOffer
  tripOffersRefetch: () => void
  draftTripsRefetch: () => void
}

export const ActionsCell = ({ request, tripOffer, tripOffersRefetch, draftTripsRefetch }: Props) => {
  const [draftTripModal, setDraftTripModal] = useState(false)
  const [archiveModal, setArchiveModal] = useState(false)

  const onSuccessForDraftTripModal = useCallback(async () => {
    await tripOffersRefetch()
    await draftTripsRefetch()
  }, [draftTripsRefetch, tripOffersRefetch])

  return (
    <>
      <ButtonWrapper>
        <AButton
          id="toDraftTrips"
          size="small"
          intent="secondary"
          Icon={Icons.IconShippingDone}
          onClick={() => setDraftTripModal(true)}
        />
        <AButton
          id="toArchive"
          size="small"
          intent="destruct"
          Icon={Icons.IconClear}
          onClick={() => setArchiveModal(true)}
        />
      </ButtonWrapper>
      {draftTripModal && (
        <AddDraftTripModal
          key={tripOffer.id}
          agent={tripOffer.agent}
          agentCommission={tripOffer.agent_commission}
          carrier={tripOffer.carrier}
          carId={tripOffer.car.id}
          trailerId={tripOffer.trailer?.id}
          driverId={tripOffer.driver.id}
          tripOfferId={tripOffer.id}
          onClose={() => setDraftTripModal(false)}
          activeRequest={request}
          onSuccess={onSuccessForDraftTripModal}
          isCancelControlDisable
        />
      )}
      {archiveModal && (
        <ClosedReasonModal
          key={tripOffer.id}
          onClose={() => setArchiveModal(false)}
          onSuccess={tripOffersRefetch}
          tripOfferId={tripOffer.id}
        />
      )}
    </>
  )
}
