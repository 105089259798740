import { BrazilExecutorsModal } from './BrazilExecutorsModal'
import { ExecutorsModal } from './ExecutorsModal'
import { isBrazil } from 'env'
import { FC } from 'react'

export type Props = {
  onClose: () => void
}

export const ExecutorsModalControl: FC<Props> = (props) => {
  return <>{isBrazil ? <BrazilExecutorsModal {...props} /> : <ExecutorsModal {...props} />}</>
}
