import styled from 'styled-components'
import { defaultTheme, Typography } from '@agro-club/agroclub-shared'
import { CommonTagsWrapper } from 'views/styled/common'

export const CardWrapper = styled.div<{ isArchived?: boolean }>`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  width: 298px;
  position: relative;
  padding: 12px;
  border: 1px solid ${defaultTheme.color.secondary50};
  border-radius: 8px;
  color: ${({ isArchived }) => (isArchived ? defaultTheme.color.textSecondary60a : defaultTheme.color.textPrimary900)};
`

export const TagsWrapper = styled(CommonTagsWrapper)`
  flex-wrap: wrap;
  width: 240px;
`

export const CollapseHeaderWrapper = styled.div`
  width: 298px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const TitleAndTagWrapper = styled.div`
  display: flex;
  column-gap: 4px;
  align-items: center;
`

export const HintText = styled.div`
  margin-left: 211px;
  ${Typography.bodySmall}
  color: ${defaultTheme.color.accentDestruct600};
`

export const BoldText = styled.div`
  ${Typography.accentLarge}
  color: ${defaultTheme.color.textPrimary900};
`

export const CompanyWrapper = styled.div`
  width: 240px;
`

export const CardGroupInfo = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
`
