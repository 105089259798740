import { FC } from 'react'
import useFormatDate from 'hooks/useFormatDate'
import { DashboardTask } from 'modules/domain/dashboard/types'
import { useTranslation } from 'react-i18next'
import { AntTag, Icons } from '@agro-club/agroclub-shared'
import { useNotificationProgress } from 'hooks/useNotificationProgress'
import {
  CardHeaderWrapper,
  CardHeaderInfo,
  CardHeaderRow,
  CardHeaderTitle,
  CardHeaderSubtitle,
  Until,
  CardHeaderTags,
} from './styled'
import { CardBidStatus } from './CardBidStatus'
import { endpoints } from 'modules/endpoints'
import { useAProgress } from 'hooks/useAProgress'
import { apiClient } from 'modules/utils/httpClient'
import { AButton } from 'views/components/Analytics'
import { FuturesPeriodTag } from 'views/components/Tags'
import styled from 'styled-components'
import { BarchartIcon } from 'views/pages/Bid/BidDetail/Bid/BarchartIcon'
import { refetchFunc } from 'modules/domain/common/hooks'

type Props = {
  task: DashboardTask
  isOverdue?: boolean
  refetch: refetchFunc
}

const WrappedCardHeaderTags = styled(CardHeaderTags)`
  flex-wrap: wrap;
  row-gap: 8px;
`

const CompleteButton = styled(AButton)`
  margin-bottom: -10px;
`

export const CardHeader: FC<Props> = ({ task, isOverdue, refetch }) => {
  const { t } = useTranslation()
  const formatDate = useFormatDate()
  const bid = task.linked_bid

  const [progress, complete, error] = useAProgress(() => apiClient.post(endpoints.completeTask(task.id)), {
    eventName: 'completeTask',
    analyticsPayload: {
      id: task.id,
    },
    onSuccess: () => refetch(true),
  })

  useNotificationProgress(progress, { title: t('task:notifyTaskCompleted') }, { title: error?.detail })

  return (
    <CardHeaderWrapper>
      <CardHeaderRow>
        <WrappedCardHeaderTags>
          <BarchartIcon source={bid?.source} />
          <AntTag color="white">{task.assigned_team.name}</AntTag>
          <FuturesPeriodTag value={task.linked_bid || task.linked_deal} />
          {!!bid && <CardBidStatus bid={bid} />}
        </WrappedCardHeaderTags>

        <CompleteButton id="completeTask" intent="white" Icon={Icons.IconDone} progress={progress} onClick={complete} />
      </CardHeaderRow>

      <CardHeaderInfo>
        <CardHeaderTitle>{task.subject}</CardHeaderTitle>
        <CardHeaderSubtitle>
          <Until $red={isOverdue}>
            {t('common:until')} {formatDate(task?.deadline, true)}
          </Until>
          &nbsp;
        </CardHeaderSubtitle>
      </CardHeaderInfo>
    </CardHeaderWrapper>
  )
}
