import { LogisticsParamsResponse } from 'views/pages/Bid/components/US/CreateDealModal/CreateDealFromBidModal'
import { AntSkeleton, defaultTheme, FormGroup, Input, Progress, Text } from '@agro-club/agroclub-shared'
import { formatDistance } from 'modules/utils/numbers/formatDistance'
import { labelQuantity } from 'modules/utils/numbers/formatQuantity'
import { labelTarif } from 'modules/utils/numbers/formatTarif'
import { KeyValue } from 'views/components/KeyValue/KeyValue'
import formatPrice from 'modules/utils/numbers/formatPrice'
import { useNotification } from 'hooks/useNotification'
import { apiClient } from 'modules/utils/httpClient'
import { AButton } from 'views/components/Analytics'
import { useAFormHandler } from 'analytics/hooks'
import { Position } from 'modules/domain/bid/types'
import { useProgress } from 'hooks/useProgress'
import { endpoints } from 'modules/endpoints'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { FC, useEffect } from 'react'
import { useFormik } from 'formik'
import _ from 'lodash'

const Wrapper = styled.div`
  padding: 16px;
  border: 1px solid ${defaultTheme.color.secondaryHover};
  background: ${defaultTheme.color.primary8a};
  border-radius: 8px;
  height: 128px;
`
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const Inputs = styled.div`
  display: grid;
  grid-template-columns: 110px 110px;
  gap: 8px;
`
const Btns = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 8px;
`

type Props = {
  clearPositions: () => void
  longPosition: Position
  shortPosition: Position
  refetch: () => void
}

type FormValues = {
  margin_per_kg: string | null
  price_logistics: string | null
  distance: string | null
  quantity: string | number | null
}

const Skeleton = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
      <AntSkeleton.Button active size="large" style={{ width: 500, borderRadius: 4 }} />
      <AntSkeleton.Button active size="small" style={{ width: 100, borderRadius: 4 }} />
    </div>
  )
}

export const CreateBatchForm: FC<Props> = ({ longPosition, shortPosition, clearPositions, refetch }) => {
  const { t } = useTranslation('deal')
  const notify = useNotification()

  const { formProgress, formHandler } = useAFormHandler('newBatch')

  const formik = useFormik<FormValues>({
    initialValues: {
      margin_per_kg: null,
      price_logistics: null,
      distance: '0',
      quantity:
        longPosition.remaining_quantity <= shortPosition.remaining_quantity
          ? longPosition.remaining_quantity
          : shortPosition.remaining_quantity,
    },
    enableReinitialize: true,

    onSubmit: formHandler(
      async () => {
        const data = {
          sale_bid_id: longPosition.bid.id,
          purchase_bid_id: shortPosition.bid.id,
          quantity: formik.values.quantity,
          price_logistics: formik.values.price_logistics,
          distance: formik.values.distance,
          long_position_id: longPosition.id,
          short_position_id: shortPosition.id,
        }

        await apiClient.post(endpoints.dealsManual(), data)
      },

      {
        onSuccess: () => {
          refetch()
          clearPositions()
        },
        onError: (error) => {
          const { errors } = error
          formik.setErrors(errors)

          if (!_.isEmpty(errors)) {
            Object.values(errors)
              .flat()
              .forEach((message) => notify(Progress.ERROR, { title: message }))
          }
        },
      },
    ),
  })

  const [logisticParamsProgress, fetchLogisticParams] = useProgress(async () => {
    try {
      const response = await apiClient.get<LogisticsParamsResponse>(endpoints.calcLogisticParams(), {
        purchase_bid_id: shortPosition.bid.id,
        sale_bid_id: longPosition.bid.id,
      })
      formik.setFieldValue('price_logistics', response.price_logistics)
      formik.setFieldValue('distance', response.distance)
      formik.setFieldValue('margin_per_kg', response.margin_per_kg)
    } catch (err) {
      notify(Progress.ERROR, { title: t('errorLogisticParams') })
    }
  })

  useEffect(() => {
    fetchLogisticParams()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [longPosition.id, shortPosition.id])

  return (
    <Wrapper>
      {logisticParamsProgress === Progress.WORK ? (
        <Skeleton />
      ) : (
        <>
          <Header>
            <div>
              <Text typography="accentLarge">
                {t('cardPositionMargin')} {formatPrice(formik.values.margin_per_kg, true)}
              </Text>
              <div>
                {longPosition.bid.product.title}, {longPosition.bid.season?.name},{' '}
                {formatDistance(formik.values.distance)}
              </div>
            </div>
            <Inputs>
              <KeyValue label={labelQuantity()}>
                <FormGroup error={formik.errors.quantity}>
                  <Input inputSize="small" {...formik.getFieldProps('quantity')} />
                </FormGroup>
              </KeyValue>
              <KeyValue label={labelTarif(t('freightCost'))}>
                <FormGroup error={formik.errors.price_logistics}>
                  <Input inputSize="small" {...formik.getFieldProps('price_logistics')} />
                </FormGroup>
              </KeyValue>
            </Inputs>
          </Header>
          <Btns>
            <AButton intent="primary" id="createBatch" progress={formProgress} onClick={formik.submitForm}>
              {t('createBatch')}
            </AButton>
            <AButton id="cancel" intent="secondary" onClick={clearPositions}>
              {t('common:cancel')}
            </AButton>
          </Btns>
        </>
      )}
    </Wrapper>
  )
}
