import { FC } from 'react'
import { RangeDateFilter } from '@agro-club/agroclub-shared'
import { useTranslation } from 'react-i18next'
import { RangeDateProps } from '@agro-club/agroclub-shared/dist/cjs/ui/date/AntRangePicker/AntRangePicker'
import useLanguage from 'hooks/useLanguage'

export type InternalDateRangePickerProps = Partial<RangeDateProps> & {
  onChange: (value: object, keys: [string, string]) => void
}

export const InternalDateRangePicker: FC<InternalDateRangePickerProps> = ({ value, onChange, ...props }) => {
  const { t } = useTranslation('common')
  const locale = useLanguage()

  return (
    <RangeDateFilter
      value={value}
      onChange={onChange}
      placeholder={[t('dateFrom'), t('dateTo')]}
      locale={locale}
      {...props}
    />
  )
}
