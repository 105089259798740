import { ASharedModal, SharedModalContent, SharedModalInputs, SharedModalTitle } from 'views/components/SharedModal'
import ADefaultModalFooter from 'views/components/DefaultModalFooter/ADefaultModalFooter'
import { CompanyWorldFields } from './AddWorldCompany'
import { Company } from 'modules/domain/company/types'
import { AnalyticsPlaces } from '../analyticsPlaces'
import { apiClient } from 'modules/utils/httpClient'
import { useAProgress } from 'hooks/useAProgress'
import { useTranslation } from 'react-i18next'
import { endpoints } from 'modules/endpoints'
import { RequestError } from 'modules/errors'
import { CommonFields } from './CommonFields'
import { useFormik } from 'formik'
import { isBrazil, isUS } from 'env'
import { FC } from 'react'
import { FormGroup, Input } from '@agro-club/agroclub-shared'

type Props = {
  refreshCompany: (arg: boolean) => void
  onClose: () => void
  company: Company
}

export const EditWorldCompany: FC<Props> = ({ onClose, refreshCompany, company }) => {
  const { t } = useTranslation('company')

  const [updateProgress, updateCompany] = useAProgress(
    (dto: CompanyWorldFields) => apiClient.put<CompanyWorldFields>(endpoints.company(company.id), dto),
    {
      eventName: 'edit',
      onSuccess: async () => {
        await refreshCompany(true)
        onClose()
      },
      onError: (err) => {
        const error = err as RequestError
        const { errors } = error
        formik.setErrors(errors)
      },
    },
  )

  const formik = useFormik<CompanyWorldFields>({
    initialValues: {
      cooperation_type_trader: company.cooperation_type_trader,
      cooperation_type_agent: company.cooperation_type_agent,
      payment_delay_days: company.payment_delay_days,
      state_reg_number: company.state_reg_number,
      prepayment: company.prepayment,
      name: company.name,
      inn: company.inn,
      soy_restriction: company.soy_restriction,
      commercial: company.commercial,
    },
    onSubmit: updateCompany,
  })

  return (
    <ASharedModal id={AnalyticsPlaces.DETAILS_MODAL} onClose={onClose}>
      <SharedModalTitle>{t('commonInfo')}</SharedModalTitle>
      <SharedModalContent>
        <SharedModalInputs>
          <FormGroup label={t('common:company')} error={formik.errors.name}>
            <Input {...formik.getFieldProps('name')} invalid={!!formik.errors.name} />
          </FormGroup>
          <CommonFields.PersonalNumber formik={formik} />
          {isBrazil && <CommonFields.RegNumber formik={formik} />}

          <CommonFields.PrepaymentState formik={formik} />
          <CommonFields.PaymentDelayDays formik={formik} />
          <CommonFields.AgreementType formik={formik} />
          {isBrazil && <CommonFields.SoyRestriction formik={formik} />}
          {isUS && <CommonFields.CommercialField formik={formik} />}
        </SharedModalInputs>
      </SharedModalContent>

      <ADefaultModalFooter
        confirmButtonText={t('common:save')}
        onConfirm={formik.handleSubmit}
        progress={updateProgress}
        onClose={onClose}
      />
    </ASharedModal>
  )
}
