import {
  DraftTripsInitial,
  TripReconciliationRequest,
} from '../../LogisticRequests/RequestsBoard/OpenRequestsBoardColumns/DraftTrips/types'
import { DealType } from 'modules/domain/logisticsKR/types'

export const getTripsReconciliationRequestValues = (values: DraftTripsInitial) => {
  const fields: TripReconciliationRequest = {
    logistician: values.logistician,
    carrier: values.carrier_id,
    has_vat: values.has_vat,
    need_contract: values.need_contract,
    car: values.car_id,
    trailer: values.trailer,
    without_trailer: values.without_trailer,
    request: values.request,
    driver: values.driver,
    agent: values.agent_id,
    price_logistics: values.price_logistics,
    load_date: values.load_date,
    deal_type: values.deal_type,
    has_agent_vat: values.has_agent_vat,
    agent_commission: +values.agent_commission,
    trip_offer: values.trip_offer,
  }
  if (!values.agent_id && values.deal_type === DealType.direct) {
    delete fields.agent
    delete fields.has_agent_vat
    delete fields.agent_commission
  }
  return fields
}
