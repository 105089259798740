import { FC, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Progress, TextArea } from '@agro-club/agroclub-shared'

import useFormatDate from 'hooks/useFormatDate'
import { CommentType } from 'modules/domain/comment/types'
import { CommentsList } from './CommentsList'
import { useComments } from './hooks'
import * as S from './styled'
import { FooterControls } from 'views/components/Comments/FooterControls'
import { ACommentsTrack } from './AComments'

export type InternalCommentsProps = {
  url: string
  id: string
  type?: CommentType
  isClosed?: boolean
  maxHeight?: number
  onSuccess?: () => void
  onATrack: ACommentsTrack
  popSummary?: (v: string) => void
}

export const InternalComments: FC<InternalCommentsProps> = ({
  url,
  isClosed,
  popSummary,
  onSuccess,
  onATrack,
  ...props
}) => {
  const { t } = useTranslation('comments')
  const [comment, setComment] = useState('')
  const [commentId, setCommentId] = useState('')
  const formatDate = useFormatDate()
  const [isEdit, setIsEdit] = useState(false)

  const { comments, addComment, getListProgress, sendProgress, editPinnedComment } = useComments(
    url,
    () => {
      onSuccess?.()
      setComment('')
    },

    commentId,
  )

  const hasPinned = useMemo(() => comments.some((comment) => comment.is_pinned), [comments])

  useEffect(() => {
    if (popSummary && comments?.length) {
      popSummary(formatDate(comments.slice(-1).pop()?.created, true))
    }
  }, [comments, formatDate, popSummary])

  return (
    <S.CommentWrapper>
      <CommentsList
        onATrack={onATrack}
        setCommentId={setCommentId}
        setComment={setComment}
        setIsEdit={setIsEdit}
        isEdit={isEdit}
        maxHeight={props.maxHeight}
        isLoading={getListProgress === Progress.WORK}
        comments={comments}
      />

      {!isClosed && (
        <div>
          <TextArea
            height={80}
            placeholder={t('enter_text')}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />

          <FooterControls
            onATrack={onATrack}
            addComment={addComment}
            comment={comment}
            isEdit={isEdit}
            sendProgress={sendProgress}
            setIsEdit={setIsEdit}
            setComment={setComment}
            editPinnedComment={editPinnedComment}
            hasPinned={hasPinned}
          />
        </div>
      )}
    </S.CommentWrapper>
  )
}
