import { Team } from 'modules/domain/collection/types'
import { Company } from 'modules/domain/company/types'
import { UserShort } from 'modules/domain/types'

type BidShort = {
  id: string
  company: Company
}

type DealShort = {
  id: string
  product: {
    id: string
    slug: string
    title: string
  }
  sale_bid: BidShort
  purchase_bid: BidShort
}

export type Task = {
  assignee: UserShort
  created_by: UserShort
  assigned_team: Team
  assignee_team: Team
  id: string
  subject: string
  task_type: TaskType
  task_type_label: string
  deadline: string // iso datetime
  comment: string | null
  linked_deal: DealShort | null // deal id
  linked_bid: BidShort | null
  linked_user: UserShort | null
  completed_at: string | null
  is_completed: boolean
  completed_by: UserShort | null
  status: TaskStatus
  assignee_full_name: string
}

export enum TaskType {
  CALL = 'call',
  MEETING = 'meeting',
  TASK = 'task',
  NEW_USER = 'new_user',
  OVERDUE_BID_SELL = 'overdue_bid_sell',
  OVERDUE_BID_PURCHASE = 'overdue_bid_purchase',
  LOGISTICS = 'logistics',
  QUARANTINE_CERTIFICATE = 'quarantine_certificate',
  RATING = 'rating',
  DEAL_DOCUMENTS = 'deal_documents',
  MATCH = 'match',
  NEW_BID = 'new_bid',
  BID_RESPONSE = 'bid_response',
  DEAL_RESPONSE = 'deal_response',
  MARGIN_NOT_AS_PLANNED = 'margin_not_as_planned',
  NOT_FILLED_LOAD = 'not_filled_load',
  NOT_FILLED_UNLOAD = 'not_filled_unload',
  DEAL_SALE_CONTRACT = 'deal_sale_contract',
  CARRIER_VERIFICATION = 'carrier_verification',
}

export enum TaskPriority {
  high = '1',
  medium = '2',
  low = '3',
}

export enum TaskStatus {
  new = 'new',
  in_progress = 'in_progress',
  complete = 'complete',
}
