import { FC, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { AntdTable, Progress, Icons } from '@agro-club/agroclub-shared'

import { endpoints } from 'modules/endpoints'
import { apiClient } from 'modules/utils/httpClient'

import { Error, HeaderButtons, Loading, DescriptionCase, TableWraper, WrapperDatePicker } from './styled'
import useRecursiveTimeout from 'hooks/useRecursiveTimeout'
import { AButton, AExternalLink } from 'views/components/Analytics'
import { useAProgress } from 'hooks/useAProgress'
import { ADateRangePicker } from 'views/components/TableFilters/DateRangePicker/ADateRangePicker'
import { RespFile } from 'modules/domain/types'

type Date = {
  startDate: string
  endDate: string
}
type ActFile = RespFile

interface DataActs {
  count: number
  current: number
  next: number | null
  page_size: number
  previous: number | null
  results: ActFile[]
}
type Props = { companyId: string; setCountActs: (count: number | undefined) => void }

export const Acts: FC<Props> = ({ companyId, setCountActs }) => {
  const { t } = useTranslation('common')
  const [range, setRange] = useState<Date>({ startDate: '', endDate: '' })
  const [error, setError] = useState<string>('')
  const [dataActs, setDataActs] = useState<ActFile[]>([])
  const [loadingDataFrom1C, setLoadingDataFrom1C] = useState<boolean>(false)
  const [disabledButton, setDisabledButton] = useState<boolean>(true)

  const [removeAllProgress, removeAllActs] = useAProgress(() => apiClient.delete(endpoints.removeAllActs(companyId)), {
    eventName: 'removeAllActs',
    onSuccess: () => queryGetActs(companyId),
    onError: () => setError(t('loading_err')),
  })
  const [removeActProgress, removeAct] = useAProgress(
    (fileId: string) => apiClient.delete(endpoints.removeAct(companyId, fileId)),
    {
      eventName: 'removeAct',
      onSuccess: () => queryGetActs(companyId),
      onError: () => setError(t('loading_err')),
    },
  )

  const handlerDatePicker = (value) => {
    setRange(value)
    value.startDate ? setDisabledButton(false) : setDisabledButton(true)
  }

  const getActs = (id: string): Promise<DataActs> | undefined => apiClient.get(endpoints.companyActs(id))

  const queryGetActs = async (id: string) => {
    try {
      const data: DataActs | undefined = await getActs(id)

      if (data?.results !== undefined) {
        setDataActs(data.results)
        setCountActs(data.results.length)
      }
    } catch (error) {
      setError(t('loading_err'))
    }
  }

  useEffect(() => {
    queryGetActs(companyId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getActFrom1C = async () => {
    try {
      const dataQuery = {
        end_date: range.endDate,
        start_date: range.startDate,
      }

      await apiClient.post(endpoints.companyAct1C(companyId), dataQuery)

      setLoadingDataFrom1C(true)
      setDisabledButton(true)
    } catch (error) {
      if (error instanceof Error) {
        setError(t('1C_err'))
      }
    }
  }

  const [numberRequests, setNumberRequests] = useState(0)

  const reAcquisitionActs = useCallback(async () => {
    setNumberRequests((prev) => prev + 1)
    try {
      const data: DataActs | undefined = await getActs(companyId)
      if (data && data.results?.length > dataActs.length) {
        setLoadingDataFrom1C(false)
        setDataActs(data?.results ?? [])
        setDisabledButton(false)
        setNumberRequests(0)
      } else if (numberRequests > 4) {
        setLoadingDataFrom1C(false)
        setError(t('loading_err'))
        setDisabledButton(false)
        setNumberRequests(0)
      }
    } catch (error) {
      setError(t('loading_err'))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataActs, companyId, numberRequests])

  useRecursiveTimeout(reAcquisitionActs, !loadingDataFrom1C ? null : 3000)

  const columns = [
    {
      dataIndex: 'original_filename',
      key: 'id',
      width: '90%',
      render: (original_filename, row: ActFile) => (
        <AExternalLink eventName="act" itemID={row.id} href={row.uploaded_file} target="_blank">
          {original_filename}
        </AExternalLink>
      ),
    },
    {
      dataIndex: 'id',
      key: 'iconRemove',
      render: (id) => (
        <AButton
          id="deleteAct"
          intent="white"
          Icon={Icons.IconDelete}
          onClick={() => removeAct(id)}
          progress={removeActProgress}
        />
      ),
    },
  ]

  return (
    <>
      <DescriptionCase>{t('text_acts')}</DescriptionCase>
      <WrapperDatePicker>
        <ADateRangePicker id="date" onChange={handlerDatePicker} allowEmpty={[false, false]} />
      </WrapperDatePicker>

      <HeaderButtons>
        <AButton id="getActs" intent="primary" size="medium" onClick={getActFrom1C} disabled={disabledButton}>
          {t('upload_acts')}
        </AButton>

        {dataActs?.length ? (
          <AButton
            id="removeActs"
            intent="secondary"
            size="medium"
            style={{ marginLeft: 16 }}
            onClick={removeAllActs}
            progress={removeAllProgress}
          >
            {t('remove_all')}
          </AButton>
        ) : null}
      </HeaderButtons>

      {error && !loadingDataFrom1C && <Error>{error}</Error>}
      {loadingDataFrom1C ? <Loading>{t('loading_data')}</Loading> : ''}

      {dataActs?.length ? (
        <TableWraper>
          <AntdTable
            progress={!dataActs ? Progress.WORK : Progress.SUCCESS}
            showHeader={false}
            columns={columns}
            data={dataActs}
            rowHoverGray
            rowKey="id"
            bordered
          />
        </TableWraper>
      ) : null}
    </>
  )
}
