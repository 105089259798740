import { Progress } from '@agro-club/agroclub-shared'
import { PreloadCard } from 'views/components/Board/Preload'
import { FC, useEffect, useMemo } from 'react'
import { BidContract } from 'modules/domain/bid/types'
import { NoDealCard } from './Cards/NoDealCard'
import { useTranslation } from 'react-i18next'
import { DealCard } from './Cards/DealCard'
import { intersection } from 'lodash'
import { useRematchingPreview } from 'views/pages/Deal/hooks/useRematchingPreview'
import { CommonWrapper } from 'views/components/Card/Wrapper'
import { RematchingColumnTitle, RematchingColumn } from './styled'
import styled from 'styled-components'
import { MarginTag } from 'views/components/Tags/MarginTag'

type Props = {
  purchaseContract: BidContract | null
  saleContract: BidContract | null
  reset: () => void
}

const PreloadWithoutBorder = styled(PreloadCard)`
  border-color: transparent;
  padding: 0;
`

export const DealColumn: FC<Props> = ({ saleContract, purchaseContract, reset }) => {
  const { t } = useTranslation('deal')
  const preview = useRematchingPreview({ saleContract, purchaseContract })

  const dealIDs = useMemo(
    () => intersection(purchaseContract?.deal_ids, saleContract?.deal_ids),
    [purchaseContract?.deal_ids, saleContract?.deal_ids],
  )

  const loading = preview.progress === Progress.WORK
  const hasIntersection = dealIDs.length !== 0

  useEffect(() => {
    if (hasIntersection || saleContract?.product.slug !== purchaseContract?.product.slug) {
      preview.setDeal(null)
      return
    }
    preview.refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saleContract, purchaseContract, hasIntersection])

  return (
    <RematchingColumn>
      <RematchingColumnTitle>
        {t('rematch.newDeal')}
        {!!preview.deal && <MarginTag value={preview.deal.margin_percent} />}
      </RematchingColumnTitle>
      <CommonWrapper>
        {preview.deal ? (
          <DealCard deal={preview.deal} reset={reset} />
        ) : loading ? (
          <PreloadWithoutBorder />
        ) : (
          <NoDealCard dealIDs={dealIDs} />
        )}
      </CommonWrapper>
    </RematchingColumn>
  )
}
