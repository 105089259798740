import { FC } from 'react'
import { FormikProps } from 'formik'
import { SharedModalContent, SharedModalInputs } from '../SharedModal/styled'
import { ShortFormControl } from '../../styled/common'
import { FormGroup, Input, Progress } from '@agro-club/agroclub-shared'
import { QuantityInput } from '../QuantityInput/QuantityInput'
import { VehicleTypeCheckboxGroup } from '../CheckboxGroup/VehicleTypeCheckboxGroup'
import { ScheduleControls } from '../ScheduleControls/ScheduleControls'
import { YesNoUnknownRadio } from './YesNoUnknownRadio'
import { useTranslation } from 'react-i18next'
import { BoolRadio } from '../BoolRadio/BoolRadio'

import { AnalyticsPlaces } from '../../pages/Deal/analyticsPlaces'
import { LoaderTypeSelect } from '../LoaderTypeSelect'
import { OverloadRadio } from 'views/components/OverloadRadio/OverloadRadio'
import { WarehouseDTO } from 'modules/domain/types'
import { ResponsiveModal } from '../ResponsiveModal/ResponsiveModal'

type Props = {
  onClose: () => void
  formik: FormikProps<WarehouseDTO>
  progress: Progress
  boolDeliveryRadio?: boolean //Probably a temporary solution, since the new logistics have switched to BoolRadio
}

export const SellerWarehouseForm: FC<Props> = ({ formik, onClose, progress, boolDeliveryRadio }) => {
  const { t } = useTranslation('address')

  return (
    <ResponsiveModal
      title={t('logisticsKR:details:loadingModal:heading')}
      id={AnalyticsPlaces.SELLER_WAREHOUSE_EDIT_MODAL}
      onConfirm={() => formik.submitForm()}
      disabled={!formik.dirty}
      progress={progress}
      onClose={onClose}
      size="large"
    >
      <SharedModalContent>
        <SharedModalInputs>
          <ShortFormControl>
            <FormGroup label={t('fields.gates_height')} error={formik.errors.gates_height}>
              <Input
                {...formik.getFieldProps('gates_height')}
                invalid={!!formik.errors.gates_height}
                type="number"
                min={0}
              />
            </FormGroup>
          </ShortFormControl>
          <LoaderTypeSelect formik={formik} fieldName="loader_type" />
          <ShortFormControl>
            <QuantityInput formik={formik} fieldName="scales_capacity" label={t('common:scales_capacity')} />
            <FormGroup label={t('fields.scales_remoteness')} error={formik.errors.scales_remoteness}>
              <Input
                {...formik.getFieldProps('scales_remoteness')}
                invalid={!!formik.errors.scales_remoteness}
                type="number"
                min={0}
              />
            </FormGroup>
            <FormGroup label={t('fields.scales_length')} error={formik.errors.scales_length}>
              <Input
                {...formik.getFieldProps('scales_length')}
                invalid={!!formik.errors.scales_length}
                type="number"
                min={0}
              />
            </FormGroup>
            <QuantityInput formik={formik} fieldName="volume_per_day" label={t('fields.volume_per_day.seller')} />
          </ShortFormControl>

          <OverloadRadio formik={formik} />
          <VehicleTypeCheckboxGroup formik={formik} />
          <FormGroup label={t('fields.schedule.seller')}>
            <ScheduleControls formik={formik} />
          </FormGroup>
          {['works_on_weekend', 'car_delivery', 'railway_delivery'].map((fieldName) =>
            boolDeliveryRadio ? (
              //Probably a temporary solution, since the new logistics have switched to BoolRadio
              <BoolRadio
                key={fieldName}
                label={t(`logisticsKR:list:createForm:labels:${fieldName}`)}
                fieldName={fieldName}
                formik={formik}
              />
            ) : (
              <FormGroup key={fieldName} label={t(`logisticsKR:list:createForm:labels:${fieldName}`)}>
                <YesNoUnknownRadio
                  onChange={(value) => formik.setFieldValue(fieldName, value)}
                  value={formik.values[fieldName]}
                />
              </FormGroup>
            ),
          )}
        </SharedModalInputs>
      </SharedModalContent>
    </ResponsiveModal>
  )
}
