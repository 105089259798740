import { defaultTheme, Typography } from '@agro-club/agroclub-shared'
import { CustomScroll } from 'views/styled/common'
import styled from 'styled-components'

type Props = {
  inbound?: boolean
}

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  gap: 16px 0;
  max-height: 600px;
  ${CustomScroll}
  padding-right: 10px;
`
export const WrapperPlayer = styled.div`
  background: ${defaultTheme.color.primary8a};
  padding: 10px;
  border-radius: 8px;
  width: 230px;
`
export const WrapperItem = styled.div<Props>`
  display: flex;
  flex-direction: column;
  gap: 4px 0;
  max-width: 420px;
  margin-left: ${({ inbound }) => (inbound ? 0 : 'auto')};
`
export const Name = styled.div`
  ${Typography.bodySmall}
  color: ${defaultTheme.color.secondaryPlaceholder};
`
export const Text = styled.div<Props>`
  padding: 8px;
  color: ${defaultTheme.color.secondary900};
  ${Typography.bodyMedium}
  background: ${({ inbound }) => (inbound ? defaultTheme.color.primary8a : defaultTheme.color.accentApprove16a)};
  border-radius: 8px;
`
