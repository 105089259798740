import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { useFormik } from 'formik'
import { ModalContent } from './ModalContent'
import { useAFormHandler } from 'analytics/hooks'
import { apiClient } from 'modules/utils/httpClient'
import { endpoints } from 'modules/endpoints'
import ASharedModal from 'views/components/SharedModal/ASharedModal'
import { AnalyticsPlaces } from 'views/pages/Deal/analyticsPlaces'
import { SharedModalTitle } from 'views/components/SharedModal/styled'
import { SellerSpec } from 'modules/domain/deal/types'

type Fields = {
  price: string
  files: string[]
}
type Props = {
  onClose: (arg: boolean) => void
  onSuccess: () => Promise<void>
  specification: SellerSpec[]
}

export const EditSellerSpecification: FC<Props> = ({ onClose, specification, onSuccess }) => {
  const { formProgress, formHandler } = useAFormHandler()
  const { t } = useTranslation('deal')
  const { id } = useParams()

  const formik = useFormik<Fields>({
    initialValues: {
      price: specification[0].price,
      files: [],
    },
    onSubmit: formHandler(
      async (values) => await apiClient.put(endpoints.sellerSpecification(id, specification[0].id), values),
      {
        onSuccess: async () => {
          await onSuccess()
          onClose(false)
        },
        onError: (error) => {
          const { errors } = error
          formik.setErrors(errors)
        },
      },
    ),
  })

  return (
    <ASharedModal id={AnalyticsPlaces.EDIT_SELLER_SPECIFICATION_MODAL} onClose={() => onClose(false)}>
      <SharedModalTitle>{t('accordion.dealFiles.seller_specification')}</SharedModalTitle>
      <ModalContent formik={formik} onClose={onClose} progress={formProgress} files={specification[0].files} />
    </ASharedModal>
  )
}
