import { format, startOfMonth } from 'date-fns'

import useDateFnsLocale from 'hooks/useDateFnsLocale'
import { MONTH_NAME_YEAR, SERVER_DATE_FORMAT } from 'modules/constants'
import { TeamsOverall } from 'modules/domain/teams/types'
import { useSingleEntity } from 'modules/domain/common/hooks'
import { endpoints } from 'modules/endpoints'
import { CurrentMonth } from './styled'
import { TeamsTable } from './TeamsTable'
import { TeamsProgress } from 'views/components/TeamsProgress/TeamsProgress'
import styled from 'styled-components'
import { tableContainer } from '../styled'

const PlansCardContainer = styled.div`
  ${tableContainer};
  width: 566px;
`

export const TeamsTableCard = () => {
  const today = new Date()
  const currYear = today.getFullYear()

  const [overallByMonthProgress, overallByMonth = {}] = useSingleEntity<TeamsOverall>(
    endpoints.teamsOverallByMonth(String(currYear)),
  )
  const locale = useDateFnsLocale()

  const firstDateOfMonth = startOfMonth(today)
  const monthName = format(firstDateOfMonth, MONTH_NAME_YEAR, { locale })
  const monthKey = format(firstDateOfMonth, SERVER_DATE_FORMAT, { locale })

  const { volume, completed } = overallByMonth[monthKey] ?? { volume: 0, completed: 0 }

  return (
    <div>
      <PlansCardContainer>
        <CurrentMonth>{monthName}</CurrentMonth>
        <TeamsProgress size="large" volume={volume} completed={completed} />
        <TeamsTable progress={overallByMonthProgress} data={overallByMonth} currentYear={currYear} />
      </PlansCardContainer>
    </div>
  )
}
