import { FormItemWrapper } from 'views/styled/common'
import { FormGroup, Radio, RadioItem } from '@agro-club/agroclub-shared'
import { useTranslation } from 'react-i18next'
import { FC } from 'react'
import { ProfileType } from 'modules/domain/user/types'
import { CloningFormFields, CloningTypes } from '../../../types'
import { FormikProps } from 'formik'

interface Props {
  currentCloning: CloningTypes
  setCurrentCloningType: (value: CloningTypes) => void
  formik: FormikProps<CloningFormFields>
  changeProfileTypeHandler: (value: ProfileType) => void
  currentProfileType: ProfileType
}

export const ProfileTypeRadio: FC<Props> = ({
  currentCloning,
  setCurrentCloningType,
  formik,
  changeProfileTypeHandler,
  currentProfileType,
}) => {
  const { t } = useTranslation('deal')

  const changeCloningTypeHandler = (value) => {
    formik.setFieldValue('cloning_type', value)
    formik.setFieldValue('user', '')
    formik.setFieldValue('new_bid', '')
    formik.setFieldValue('distance', 0)
    formik.setFieldValue('logistics_rate', 0)
    formik.setFieldValue('price_logistics', 0)
    setCurrentCloningType(value)
  }

  return (
    <>
      <FormGroup label={t('bidCloningModal.labels.user')}>
        <FormItemWrapper>
          {currentCloning === CloningTypes.quantity_split ? (
            <Radio onChange={changeProfileTypeHandler} value={currentProfileType}>
              <RadioItem value={ProfileType.customer} label={t('userTypes.buyer')} title="" />
              <RadioItem value={ProfileType.seller} label={t('userTypes.seller')} title="" />
            </Radio>
          ) : (
            <Radio onChange={changeCloningTypeHandler} value={formik.values.cloning_type}>
              <RadioItem value={CloningTypes.customer_change} label={t('userTypes.buyer')} title="" />
              <RadioItem value={CloningTypes.seller_change} label={t('userTypes.seller')} title="" />
            </Radio>
          )}
        </FormItemWrapper>
      </FormGroup>
    </>
  )
}
