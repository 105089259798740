import { Carrier, LogisticRequest } from 'modules/domain/logisticsKR/types'
import { FC, useState } from 'react'

import { CarsColumn } from './CarsColumn/CarsColumn'
import { RequestsColumn } from './RequestsColumn/RequestsColumn'
import styled from 'styled-components'
import { CollapseWrapperStyles } from 'views/pages/LogisticsKR/styled'
import { TripsAndOffersColumn } from './TripsAndOffersColumn/TripsAndOffersColumn'

const COLLAPSE_HEADER_HEIGHT = 60

const BoardContainer = styled.div`
  display: inline-grid;
  grid-column-gap: 24px;
  grid-auto-flow: column;
  grid-template-columns: 330px 330px 330px;
`

export const MultipleColumnWrapper = styled.div<{ collapseAmount: number }>`
  min-width: 330px;

  .ant-collapse-content-box {
    max-height: ${({ collapseAmount }) => `calc(100dvh - ${160 + collapseAmount * COLLAPSE_HEADER_HEIGHT}px)`};
  }

  ${CollapseWrapperStyles}
`

type Props = {
  carrier: Carrier
}

export const CarrierBoard: FC<Props> = ({ carrier }) => {
  const [carId, setCarId] = useState('')
  const [activeRequest, setActiveRequest] = useState<LogisticRequest | undefined>(undefined)

  return (
    <BoardContainer>
      <CarsColumn activeCarId={carId} carrier={carrier} onSelectedChanged={setCarId} />
      <RequestsColumn
        activeRequestId={activeRequest?.id}
        carrier={carrier.id}
        car={carId}
        onSelectedChanged={setActiveRequest}
      />
      <TripsAndOffersColumn carrier={carrier.id} car={carId} request={activeRequest} />
    </BoardContainer>
  )
}
