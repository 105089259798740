import { useTranslation } from 'react-i18next'
import { useTeamsTableData } from 'views/pages/Teams/hooks'
import { PurchaseTable } from 'views/pages/Teams/PurchaseTableCard/PurchaseTable'
import styled from 'styled-components'
import { Title } from '../TeamsTableCard/styled'
import { tableContainer } from '../styled'

const Container = styled.div`
  ${tableContainer};
  width: 448px;
`

export const PurchaseTableCard = () => {
  const { t } = useTranslation('teams')

  const [progress, membersList] = useTeamsTableData('seller')

  return (
    <div>
      <Container>
        <Title>{t('buying')}</Title>
        <PurchaseTable membersList={membersList} progress={progress} />
      </Container>
    </div>
  )
}
