import { ADateRangePicker } from 'views/components/TableFilters/DateRangePicker/ADateRangePicker'
import { ABaseTableFilter } from 'views/components/TableFilters/ABaseTableFilter'
import { defaultListRequestParams } from 'views/components/TableFilters/data'
import { AClearFiltersButton, ASearch } from 'views/components/Analytics'
import {
  FiltersAndSearchWrapper,
  FiltersWrapper,
  SearchWrapper,
} from 'views/components/CommonTableComponents/CommonTableComponents'
import { useDisplayClearBtn } from 'hooks/useDisplayClearBtn'
import { TableFiltersProps } from 'modules/types'
import { useTranslation } from 'react-i18next'
import { FC } from 'react'
import { AnalyticsFilterVariant } from 'analytics/types'
import { endpoints } from 'modules/endpoints'

export const Filters: FC<TableFiltersProps> = ({ setListRequestParams, listRequestParams, updateFilterState }) => {
  const { t } = useTranslation('calls')

  const displayClearBtn = useDisplayClearBtn({
    listRequestParams,
  })

  return (
    <FiltersAndSearchWrapper>
      <FiltersWrapper>
        <ADateRangePicker
          onChange={(value) => updateFilterState({ date_sent_after: value.startDate, date_sent_before: value.endDate })}
          value={[listRequestParams.filter.date_sent_after, listRequestParams.filter.date_sent_before]}
          id={AnalyticsFilterVariant.DATE}
        />
        <ABaseTableFilter
          onChange={(coordinator) => updateFilterState({ coordinator })}
          value={listRequestParams.filter.coordinator}
          id={AnalyticsFilterVariant.COORDINATOR_NAME}
          endpoint={endpoints.whatsAppTwilio()}
          placeholder={t('coordinator')}
          searchField="search"
        />
        {displayClearBtn && (
          <AClearFiltersButton
            onClick={() => setListRequestParams && setListRequestParams({ ...defaultListRequestParams })}
          />
        )}
      </FiltersWrapper>
      <SearchWrapper>
        <ASearch
          onChange={(search) => updateFilterState({ search })}
          value={listRequestParams?.filter?.search}
          id={AnalyticsFilterVariant.SEARCH}
        />
      </SearchWrapper>
    </FiltersAndSearchWrapper>
  )
}
