import * as Yup from 'yup'

const LoadingParametersSchema = (requiredFieldMessage) => {
  return Yup.object({
    loading_warehouse_address: Yup.string().required(requiredFieldMessage).nullable(),
    loading_warehouse_gates_height: Yup.number().required(requiredFieldMessage).nullable(),
    loading_warehouse_vehicle_type: Yup.array().min(1, requiredFieldMessage),
  })
}

export default LoadingParametersSchema
