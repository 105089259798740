import { defaultTheme, Progress } from '@agro-club/agroclub-shared'
import { CommentType } from 'modules/domain/comment/types'
import { refetchFunc } from 'modules/domain/common/hooks'
import { endpoints } from 'modules/endpoints'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import { Card } from 'views/components/Card/Card'
import AComments from 'views/components/Comments/AComments'
import { Spinner } from 'views/components/CommonTableComponents/CommonTableComponents'
import { SpinnerWrapper } from 'views/styled/common'
import { WhMovements } from '../../DealContext'
import { WarehouseDetailTitle } from '../DetailTitle/WarehouseDetailTitle'
import { EditableWhMovementInfo } from '../EditableWhMovementInfo'
import { WhMovementDetailAddress } from '../WhMovementDetailAddress'
import { WhMovementDetailCompanyUser } from '../WhMovementDetailCompanyUser'

type Props = {
  dealId: string
  dealRefetch: refetchFunc
  whMovements: WhMovements
}

export const BidWarehouseBlock: FC<Props> = ({ dealId, dealRefetch, whMovements }) => {
  const { t } = useTranslation()

  const isMobile = useMediaQuery({
    query: `(max-width: ${defaultTheme.queries.mobile})`,
  })

  const refetchHandler = () => {
    dealRefetch(true)
    whMovements.refetch(true)
  }

  if (whMovements.progress !== Progress.SUCCESS) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    )
  }

  return (
    <Card.Container bordered={!isMobile} borderRadius={isMobile ? 'large' : 'normal'}>
      <Card.GapableContent>
        <WarehouseDetailTitle
          warehouseId={whMovements.last.warehouse?.id}
          isMobile={isMobile}
          dealId={dealId}
          refetch={refetchHandler}
        />
        <EditableWhMovementInfo
          whMovementRefetch={whMovements.refetch}
          whMovement={whMovements.last}
          isMobile={isMobile}
        />
        <WhMovementDetailCompanyUser whMovement={whMovements.last} />
        <WhMovementDetailAddress address={whMovements.last.warehouse.address} />
      </Card.GapableContent>

      <Card.HorizontalDivider />

      <Card.GapableContent>
        <Card.Title>{t('common:comments')}</Card.Title>
        <div>
          <AComments
            maxHeight={300}
            id={whMovements.last.warehouse.owner.id}
            type={CommentType.user}
            url={endpoints.userComments(whMovements.last.warehouse.owner.id)}
            eventPostFix="customer-warehouse"
          />
        </div>
      </Card.GapableContent>
    </Card.Container>
  )
}
