const main = 'routes'
const relativeRoutes = {
  relativePage: `${main}/*`,
  relativeList: `${main}`,
  relativeDetail: ':id',
}

const routes = {
  List: `/${main}`,
}

const RoutesRoutes = { ...relativeRoutes, ...routes }

export default RoutesRoutes
