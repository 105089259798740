import { Progress } from '@agro-club/agroclub-shared'
import { useAnalyticsContext } from 'analytics/hooks'
import { AnalyticsPayload } from 'analytics/types'
import { RequestError } from 'modules/errors'
import { useProgress } from './useProgress'

type ErrorType = ReturnType<typeof RequestError.parseError> | null

type Options<T> = {
  scope?: string
  eventName: string
  analyticsPayload?: AnalyticsPayload
  onSuccess?: (response: T) => void
  onError?: (error: ErrorType) => void
}

export const useAProgress = <T, Args extends any[] = any[]>(
  request: (...args: Args) => Promise<T>,
  { scope = 'sumbit', eventName, analyticsPayload, onSuccess, onError }: Options<T>,
): [Progress, (...args: Args) => Promise<T | undefined>, ErrorType] => {
  const { track } = useAnalyticsContext()

  const trackedRequest = async (...args: Args) => {
    const response = await request(...args)
    await onSuccess?.(response)
    track(`${scope}|${eventName}`, analyticsPayload)
    return response
  }

  const [progress, performRequest, error] = useProgress(trackedRequest)

  const sendRequest = async (...args: Args) => {
    try {
      const response = await performRequest(...args)
      return response
    } catch (error) {
      const parsedError = RequestError.parseError(error)
      if (parsedError.type === 'unknown') throw error
      onError?.(parsedError)
      track(`${scope}_failed|${eventName}`, analyticsPayload)
    }
  }

  return [progress, sendRequest, error]
}
