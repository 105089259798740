import { Spinner } from '@agro-club/agroclub-shared'
import styled from 'styled-components'
import { FC } from 'react'

const SpinnerContainer = styled.div`
  width: 100%;
  height: 100dvh;
  display: flex;
  align-items: center;
  justify-content: center;
`

const SpinnerLayout: FC = () => (
  <SpinnerContainer>
    <Spinner />
  </SpinnerContainer>
)

export default SpinnerLayout
