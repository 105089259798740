import { Icons } from '@agro-club/agroclub-shared'
import { refetchFunc } from 'modules/domain/common/hooks'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AButton } from 'views/components/Analytics'
import AMobileButton from 'views/components/Analytics/AMobileButton'
import { WarehouseMovementsModal } from 'views/components/WarehouseMovementsModal/WarehouseMovementsModal'

type Props = {
  dealId: string
  refetch: refetchFunc | (() => void)
  isMobile?: boolean
}

export const WarehouseMovements: FC<Props> = ({ dealId, refetch, isMobile }) => {
  const { t } = useTranslation('warehouses')
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      {isMobile ? (
        <AMobileButton id="moveToWarehouse" intent="white" Icon={Icons.IconGarageMove} onClick={() => setIsOpen(true)}>
          {t('moveToWarehouse')}
        </AMobileButton>
      ) : (
        <AButton id="moveToWarehouse" intent="white" Icon={Icons.IconGarageMove} onClick={() => setIsOpen(true)} />
      )}
      {isOpen && <WarehouseMovementsModal dealId={dealId} onSuccess={refetch} onClose={() => setIsOpen(false)} />}
    </>
  )
}
