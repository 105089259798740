import { ContractDeliveryPeriod } from 'views/pages/BidContracts/BidContractDetail/ContractDeliveryPeriod'
import { CommentsCell } from 'views/pages/Dispatching/components/CommentsCell/CommentsCell'
import { AntSkeleton, Progress, Text } from '@agro-club/agroclub-shared'
import { DealStatusLabel } from '../../Deal/DealDetail/DealStatusLabel'
import { labelQuantity } from 'modules/utils/numbers/formatQuantity'
import { TableTextLink } from 'views/ui/TableTextLink/TableTextLink'
import { labelTarif } from 'modules/utils/numbers/formatTarif'
import formatPrice, { labelPrice } from 'modules/utils/numbers/formatPrice'
import formatNumber from 'modules/utils/numbers/formatNumber'
import BidContractsRoutes from '../../BidContracts/routes'
import { TypeTotals } from '../ShipmentsControlList'
import DealRoutes from 'views/pages/Deal/routes'
import useFormatDate from 'hooks/useFormatDate'
import { useTranslation } from 'react-i18next'
import { CommonCell, Hr } from './TableCells'
import { generatePath } from 'react-router'
import UserRoutes from '../../User/routes'
import { ColumnData } from 'modules/types'
import { useMemo } from 'react'
import { TruncatedValue } from 'views/components/TruncatedValue/TruncatedValue'

type ColumnDataOptionalDataIndex = Omit<ColumnData, 'dataIndex'> & {
  dataIndex?: string
}

const preload = <AntSkeleton.Button active size="small" style={{ width: 80, height: 20, borderRadius: 4 }} />

const generateTooltipBlock = (user, userRoute) => {
  return (
    <TruncatedValue hint={user.name}>
      <TableTextLink id="userLink" to={generatePath(userRoute, { id: user.id })}>
        {user.name}
      </TableTextLink>
    </TruncatedValue>
  )
}
const withSubTitle = () => ({
  withSubTitle: true,
})

export const useColumns = (
  setSelectedDealId: (value: string) => void,
  setDrawerIsOpen: (arg: boolean) => void,
  progressTotals?: Progress,
  totals?: TypeTotals,
): ColumnDataOptionalDataIndex[] => {
  const { t } = useTranslation('shipmentsControl')
  const formatDate = useFormatDate()

  const renderHeaderCell = (label, value) => {
    return (
      <>
        {label}
        <Text typography="accentMedium" color="secondary">
          {progressTotals === Progress.SUCCESS ? formatNumber(value, true) : preload}
        </Text>
      </>
    )
  }

  return useMemo(
    () => [
      {
        title: t('crop'),
        width: 112,
        dataIndex: 'product_title',
        fixed: 'left',
        render: (product_title, data) => {
          return (
            <>
              <TableTextLink id="dealLink" to={generatePath(DealRoutes.Details, { id: data.id })}>
                {product_title}
              </TableTextLink>
              <Text typography="bodySmall" color="secondary">{`ID ${data.id}`}</Text>
            </>
          )
        },
      },
      {
        title: t('buyerSeller'),
        width: 280,
        onCell: () => ({ style: { paddingRight: '0px' } }),
        fixed: 'left',
        render: (data) => {
          return (
            <CommonCell
              sellerBlock={generateTooltipBlock(data.seller, UserRoutes.Details)}
              buyerBlock={generateTooltipBlock(data.purchaser, UserRoutes.Details)}
              padding="0 14px 0 0"
            />
          )
        },
      },
      {
        title: t('contract'),
        width: 80,
        onCell: () => ({ style: { paddingLeft: '0px' } }),
        fixed: 'left',
        render: (data) => {
          return (
            <CommonCell
              sellerBlock={
                <TableTextLink
                  id="saleContractLink"
                  to={generatePath(BidContractsRoutes.Details, { id: data.sale_contract.id })}
                >
                  {data.sale_contract.number}
                </TableTextLink>
              }
              buyerBlock={
                <TableTextLink
                  id="purchaseContractLink"
                  to={generatePath(BidContractsRoutes.Details, { id: data.purchase_contract.id })}
                >
                  {data.purchase_contract.number}
                </TableTextLink>
              }
              padding="0 0 0 14px"
            />
          )
        },
      },
      {
        title: t('status'),
        width: 156,
        dataIndex: 'status',
        render: (status) => <DealStatusLabel status={status} />,
      },
      {
        title: t('originDestination'),
        width: 180,
        onCell: () => ({ style: { paddingRight: '0px' } }),
        render: (data) => {
          return <CommonCell sellerBlock={data.seller_region} buyerBlock={data.purchaser_region} padding="0 14px 0 0" />
        },
      },
      {
        title: t('contractDates'),
        width: 190,
        onCell: () => ({ style: { paddingRight: '0px', paddingLeft: '0px' } }),
        render: (data) => {
          return (
            <CommonCell
              sellerBlock={
                <ContractDeliveryPeriod
                  shipment_from_date={data.sale_contract.shipment_from_date}
                  shipment_due_date={data.sale_contract.shipment_due_date}
                />
              }
              buyerBlock={
                <ContractDeliveryPeriod
                  shipment_from_date={data.purchase_contract.shipment_from_date}
                  shipment_due_date={data.purchase_contract.shipment_due_date}
                />
              }
              padding="0 14px 0 14px"
            />
          )
        },
      },
      {
        title: t('period'),
        width: 80,
        align: 'center',
        dataIndex: 'period',
      },
      {
        title: labelTarif(t('price')),
        align: 'right',
        width: 114,
        render: (data) => {
          return <CommonCell sellerBlock={formatPrice(data.sale_price)} buyerBlock={formatPrice(data.purchase_price)} />
        },
      },
      {
        title: () => renderHeaderCell(labelQuantity(), totals?.quantity),
        onHeaderCell: withSubTitle,
        align: 'right',
        width: 100,
        dataIndex: 'quantity',
        render: (quantity) => formatNumber(quantity, true),
      },
      {
        title: t('payment'),
        width: 100,
        render: (data) => {
          return (
            <div>
              {formatDate(data.sale_contract.payment_due_date)}
              <Hr />
              {formatDate(data.purchase_contract.payment_due_date)}
            </div>
          )
        },
      },
      {
        title: () => renderHeaderCell(labelQuantity(t('uploads')), totals?.uploads),
        onHeaderCell: withSubTitle,
        align: 'right',
        width: 100,
        dataIndex: 'uploads',
        render: (uploads) => formatNumber(uploads, true),
      },
      {
        title: () => renderHeaderCell(labelQuantity(t('offloads')), totals?.offloads),
        onHeaderCell: withSubTitle,
        align: 'right',
        width: 150,
        dataIndex: 'offloads',
        render: (offloads) => formatNumber(offloads, true),
      },
      {
        title: labelQuantity(t('remaining')),
        align: 'right',
        width: 150,
        onCell: () => ({ style: { paddingRight: '0px' } }),
        render: (data) => {
          return (
            <CommonCell
              sellerBlock={formatNumber(data.remaining_load, true)}
              buyerBlock={formatNumber(data.remaining_delivery, true)}
              padding="0 14px 0 0"
            />
          )
        },
      },
      {
        title: () => renderHeaderCell(labelQuantity(t('transit')), totals?.transit),
        onHeaderCell: withSubTitle,
        align: 'right',
        width: 100,
        dataIndex: 'transit',
        render: (transit) => formatNumber(transit, true),
      },

      {
        title: labelPrice(t('cost')),
        width: 132,
        align: 'right',
        dataIndex: 'cost',
        render: (cost) => formatPrice(cost),
      },
      {
        title: labelPrice(t('revenue')),
        width: 132,
        align: 'right',
        dataIndex: 'revenue',
        render: (revenue) => formatPrice(revenue),
      },
      {
        title: t('obs'),
        width: 280,
        dataIndex: 'comments',
        render: (comments, data) => (
          <CommentsCell
            setSelectedDealId={setSelectedDealId}
            setDrawerIsOpen={setDrawerIsOpen}
            comments={comments}
            dealId={data.id}
          />
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, formatDate, totals, progressTotals],
  )
}
