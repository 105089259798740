import { DateText, EmptyContainer, FullContainer, RouterLinkStyled } from '../styled'
import { DealDocumentRequestStatusTags } from './DealDocumentRequestStatusTags'
import { DocumentRequestsRoutes } from 'views/pages/DocumentRequests/routes'
import { DealDocumentRequestControls } from './DealDocumentRequestControls'
import { EditDocumentRequest } from './EditDocumentRequest'
import { refetchFunc } from 'modules/domain/common/hooks'
import { Declaration } from 'modules/domain/types'
import { Text } from '@agro-club/agroclub-shared'
import { Deal } from 'modules/domain/deal/types'
import { generatePath } from 'react-router-dom'
import useFormatDate from 'hooks/useFormatDate'
import { useTranslation } from 'react-i18next'

import styled from 'styled-components'
import { FC } from 'react'

const ControlsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px 0;
`

type Props = {
  handlerWithoutDocsModal: () => void
  handlerAddDocsModal: () => void
  refetch: refetchFunc
  deal: Deal
  declaration?: Declaration
}

export const MobileDocumentRequest: FC<Props> = ({
  deal,
  refetch,
  declaration,
  handlerAddDocsModal,
  handlerWithoutDocsModal,
}) => {
  const { t } = useTranslation('deal')
  const formatDate = useFormatDate()

  const documentRequest = deal.documents_request
  const withoutDocumentRequest = deal.without_documents_request
  const title = <Text typography="accentLarge">{t('requestDocumentsBlock.title')}</Text>

  return (
    <>
      {!withoutDocumentRequest && !documentRequest ? (
        <EmptyContainer>
          <div>
            {title}
            {deal.documents_request?.expected_complete_date && (
              <DateText>
                {t('requestDocumentsBlock.expectedDate', {
                  date: formatDate(deal.documents_request.expected_complete_date),
                })}
              </DateText>
            )}
          </div>
          <DealDocumentRequestControls
            handlerWithoutDocsModal={handlerWithoutDocsModal}
            handlerAddDocsModal={handlerAddDocsModal}
          />
        </EmptyContainer>
      ) : (
        <FullContainer>
          <ControlsWrapper>
            {title}
            <DealDocumentRequestStatusTags deal={deal} />
            {documentRequest?.id && (
              <RouterLinkStyled
                to={generatePath(DocumentRequestsRoutes.Details, { id: documentRequest.id })}
                eventName="detailedDocuments"
                itemId={documentRequest.id}
              />
            )}
          </ControlsWrapper>
          <EditDocumentRequest declaration={declaration} deal={deal} refetch={refetch} />
        </FullContainer>
      )}
    </>
  )
}
