import { LogisticRequest } from 'modules/domain/logisticsKR/types'
import { FC } from 'react'
import { TagsWrapper } from 'views/pages/LogisticsKR/LogisticRequests/components/styled'
import { AntTag, Icons } from '@agro-club/agroclub-shared'
import { formatQuantity } from 'modules/utils/numbers/formatQuantity'
import { LogisticStatuses } from 'views/pages/LogisticsKR/types'

const getCount = (num: number) => (num > 99 ? '99+' : num)

interface Props {
  request: LogisticRequest
}

export const TripOffersTags: FC<Props> = ({ request }) => {
  const {
    new_trip_offers_count,
    trips_on_approval_count,
    trips_count,
    trips_loaded_quantity,
    trips_unloaded_quantity,
  } = request
  const tripsInfo = `${getCount(trips_count)}: ${formatQuantity(trips_loaded_quantity)} / ${formatQuantity(
    trips_unloaded_quantity,
  )}`
  const closedRequestStatuses = [
    LogisticStatuses.waiting_for_documents,
    LogisticStatuses.shipment_complete,
    LogisticStatuses.cancelled,
  ]
  const isInProgress = request.logistics_status === LogisticStatuses.in_progress
  const isShowTripTag = closedRequestStatuses.includes(request.logistics_status) || isInProgress

  return (
    <TagsWrapper>
      {isInProgress && (
        <>
          <AntTag icon={<Icons.IconNotifications height={16} width={16} />} color="white">
            {String(getCount(new_trip_offers_count))}
          </AntTag>
          <AntTag icon={<Icons.IconProcessing height={16} width={16} />} color="white">
            {String(getCount(trips_on_approval_count))}
          </AntTag>
        </>
      )}
      {isShowTripTag && (
        <AntTag icon={<Icons.IconShippingDone height={16} width={16} />} color="white">
          {tripsInfo}
        </AntTag>
      )}
    </TagsWrapper>
  )
}
