import { Icons } from '@agro-club/agroclub-shared'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router'
import { AButton } from 'views/components/Analytics'
import AMobileButton from 'views/components/Analytics/AMobileButton'
import { Card } from 'views/components/Card/Card'
import WarehousesRoutes from 'views/pages/Warehouses/routes'
import { WarehouseMovements } from '../WarehouseMovements'
import { ActionsWrapper, ActionsWrapperMobile, Header, TitleWrapper } from './styled'

type Props = {
  isMobile: boolean
  dealId: string
  warehouseId: number
  refetch: () => void
}

export const WarehouseDetailTitle: FC<Props> = ({ isMobile, dealId, refetch, warehouseId }) => {
  const { t } = useTranslation('warehouses')

  return (
    <TitleWrapper>
      <Header>
        {!isMobile && <Card.Title>{t('warehouse')}</Card.Title>}
        {isMobile ? (
          <>
            <ActionsWrapperMobile>
              <WarehouseMovements isMobile={isMobile} dealId={dealId} refetch={refetch} />
              <AMobileButton
                id="warehouse"
                Icon={Icons.IconOpen}
                intent="white"
                target="_blank"
                href={generatePath(WarehousesRoutes.Details, { id: warehouseId })}
              >
                {t('goToWarehouse')}
              </AMobileButton>
            </ActionsWrapperMobile>
          </>
        ) : (
          <>
            <ActionsWrapper>
              <WarehouseMovements dealId={dealId} refetch={refetch} />
              <AButton
                id="warehouse"
                Icon={Icons.IconOpen}
                intent="white"
                target="_blank"
                to={generatePath(WarehousesRoutes.Details, { id: warehouseId })}
              />
            </ActionsWrapper>
          </>
        )}
      </Header>
    </TitleWrapper>
  )
}
