import styled from 'styled-components'

import { AntTag, Typography } from '@agro-club/agroclub-shared'
import { FC } from 'react'

export const Container = styled(AntTag)`
  ${Typography.accentMedium};
  margin: 0;
  margin-left: 4px;
  height: 20px;
`

interface Props {
  count?: number
}

const OVERFLOW_COUNT = 100
const OVERFLOW_LABEL = '99+'

const MenuCounter: FC<Props> = ({ count }) => {
  if (!count) return null

  return (
    <Container color="orange" bordered={false}>
      {count >= OVERFLOW_COUNT ? OVERFLOW_LABEL : count}
    </Container>
  )
}

export default MenuCounter
