import { AntTag, Typography, defaultTheme } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`
export const Tags = styled.div`
  display: flex;
  align-items: center;
  .ant-tag:not(:first-child) {
    margin-left: 4px;
  }
`

export const QuantityAndPrice = styled.div`
  ${Typography.accentLarge}
`

export const AddressAndUser = styled.div``
export const Address = styled.div`
  color: ${defaultTheme.color.textSecondary60a};
`
export const Dates = styled.div`
  display: flex;
  gap: 0 16px;
  ${Typography.bodyMedium}
`
export const RemainingQuantity = styled.div`
  ${Typography.bodyMedium}
`

export const DealTag = styled(AntTag)`
  svg {
    width: 16px;
    height: 16px;
    margin: 0;
  }
`
