import { FormGroup, Input } from '@agro-club/agroclub-shared'
import { useTranslation } from 'react-i18next'
import { FC } from 'react'
import { FormikFieldsType } from 'modules/domain/types'

interface Props {
  formik: FormikFieldsType
}

export const FormPatronymicName: FC<Props> = ({ formik }) => {
  const { t } = useTranslation()
  return (
    <FormGroup error={formik.errors.patronymic_name} label={t('common:patronymic_name')}>
      <Input
        placeholder={t('common:patronymic_name_placeholder')}
        {...formik.getFieldProps('patronymic_name')}
        invalid={!!formik.errors.patronymic_name}
      />
    </FormGroup>
  )
}
