import { FC, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Progress, useHelmet } from '@agro-club/agroclub-shared'
import * as Layout from 'views/layouts/NewLayout'
import * as Header from 'views/ui/Header/Header'
import CarsTable from '../../components/CarsTable/CarsTable'

import useColumns from './components/useColumns'

import { endpoints } from 'modules/endpoints'
import { generatePath, useParams } from 'react-router-dom'
import DealsKRRoutes from '../routes'
import ItemLoadingLayout from 'views/layouts/ItemLoadingLayout/ItemLoadingLayout'
import { useSingleEntity } from 'modules/domain/common/hooks'
import { LogisticRequest } from 'modules/domain/logisticsKR/types'
import TripsHeader from '../RequestDetails/components/TripsHeader'
import { ListRequestParams } from 'modules/domain/types'
import { defaultListRequestParams } from 'views/components/TableFilters/data'
import APageWrapper from 'views/components/PageWrapper/APageWrapper'
import { AnalyticsPages, AnalyticsPlaces } from '../../analyticsPlaces'

export const RequestCars: FC = () => {
  const { t } = useTranslation('logisticsKR')
  const { id } = useParams()
  useHelmet({ title: t('detailsCars.heading') })
  const [listRequestParams, setListRequestParams] = useState<ListRequestParams>(defaultListRequestParams)
  const [fetchProgress, request] = useSingleEntity<LogisticRequest>(endpoints.LogisticsKR.requests(id))

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const refetchMemo = useCallback(() => setListRequestParams((prev) => ({ ...prev })), [])
  const columns = useColumns(request, refetchMemo)

  if (fetchProgress === Progress.WORK || !request) return <ItemLoadingLayout id={id as string} />

  return (
    <APageWrapper page={AnalyticsPages.REQUEST_CARS} place={AnalyticsPlaces.REQUEST_CARS.LIST}>
      <Layout.WrapperContent>
        <Layout.Header>
          <Layout.TopHeader>
            <Header.Breadcrumbs
              routes={[
                { breadcrumbName: t('menu:newLogistics') },
                { path: DealsKRRoutes.List, breadcrumbName: t('list.heading') },
                {
                  path: generatePath(DealsKRRoutes.Details, { id: id }),
                  breadcrumbName: `${t('list.requestN')} ${request.id}`,
                },
                { breadcrumbName: t('detailsCars.heading') },
              ]}
            />
          </Layout.TopHeader>
          <Header.Title title={t('detailsCars.heading')} compact />
          <TripsHeader request={request} />
        </Layout.Header>
        <CarsTable
          listRequestParams={listRequestParams}
          setListRequestParams={setListRequestParams}
          columns={columns}
          endpoint={endpoints.LogisticsKR.car()}
        />
      </Layout.WrapperContent>
    </APageWrapper>
  )
}
