export enum AnalyticsPages {
  DEALS = 'deals',
  DEAL_DETAIL = 'dealDetail',
}

export enum AnalyticsPlaces {
  LIST = 'DealsList',
  DETAIL = 'DealDetail',
  EXECUTORS_EDIT_MODAL = 'ExecutorsEditModal',
  EDIT_DETAIL_MODAL = 'EditDetailModal',
  HISTORY_MODAL = 'HistoryModal',
  SELLER_WAREHOUSE_EDIT_MODAL = 'SellerWarehouseEditModal',
  BUYER_WAREHOUSE_EDIT_MODAL = 'BuyerWarehouseEditModal',
  ADD_SELLER_SPECIFICATION_MODAL = 'AddSellerSpecificationModal',
  EDIT_SELLER_SPECIFICATION_MODAL = 'EditSellerSpecificationModal',
  ADD_DECLARATION_MODAL = 'AddDeclarationModal',
  REMOVE_DECLARATION_MODAL = 'RemoveDeclarationModal',
  ADD_SHIPMENT_MODAL = 'AddShipmentModal',
  EDIT_SHIPMENT_MODAL = 'EditShipmentModal',
  REMOVE_SHIPMENT_MODAL = 'RemoveShipmentModal',
  ADD_DOCUMENT_REQUEST_MODAL = 'AddDocumentRequestModal',
  WITHOUT_DOCUMENT_REQUEST_MODAL = 'WithoutDocumentRequestModal',
  EDIT_PRICE_QUANTITY_MODAL = 'EditPriceQuantityModal',
}
