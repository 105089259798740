import { Pagination as AntPagination } from '@agro-club/agroclub-shared'
import { ACCORDION_ITEM_PAGE_SIZE } from 'modules/constants'
import { FC } from 'react'

type Props = {
  total: number
  currPage: number
  setCurrPage: (number) => void
  pageSize?: number
  disableMargin?: boolean
  size?: 'default' | 'small'
  showLessItems?: boolean
}

export const Pagination: FC<Props> = ({
  currPage,
  setCurrPage,
  pageSize = ACCORDION_ITEM_PAGE_SIZE,
  total,
  disableMargin,
  size = 'small',
  showLessItems,
}) => {
  return (
    <>
      {total > pageSize && (
        <AntPagination
          size={size}
          disableMargin={disableMargin}
          total={total}
          current={currPage}
          onChange={setCurrPage}
          pageSize={pageSize}
          showLessItems={showLessItems}
        />
      )}
    </>
  )
}
