import { CurrencySign, CurrencyCode } from 'modules/domain/export/types'
import formatNumber from 'modules/utils/numbers/formatNumber'
import { Text } from '@agro-club/agroclub-shared'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { FC } from 'react'

type Props = {
  currency: CurrencyCode
  price: string
  label: string
  localizedPrice: string
}

const NameCost = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const TwoCost = styled.div`
  display: flex;
  gap: 0 8px;
`

export const Price: FC<Props> = ({ currency, price, label, localizedPrice }) => {
  const { t } = useTranslation('export')

  const formatPrice = (amount: string, currencyType: string) => {
    return `${formatNumber(amount, true)} ${t('formatPrice', { currency: currencyType })}`
  }

  return (
    <NameCost>
      <Text typography="bodyMedium">{label}</Text>
      <TwoCost>
        {currency !== CurrencyCode.RUB && (
          <Text color="secondary" typography="bodyMedium">
            {formatPrice(localizedPrice, CurrencySign[CurrencyCode.RUB])}
          </Text>
        )}
        <Text typography="accentMedium">{formatPrice(price, CurrencySign[currency])}</Text>
      </TwoCost>
    </NameCost>
  )
}
