import { AntPopover, AntTag, Icons } from '@agro-club/agroclub-shared'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router'
import { CardButtonWrapper, TextCard } from 'views/styled/ItemCardStyles'
import { PopoverContent } from 'views/pages/LogisticsKR/components/TripOffers/PopoverContent/PopoverContent'
import { TripOffer, TripOfferStatuses } from 'views/pages/LogisticsKR/types'
import { CardWrapper, TagsWrapper } from 'views/pages/LogisticsKR/LogisticRequests/components/styled'
import { AButton, ARouterLink } from 'views/components/Analytics'
import CarriersKRRoutes from 'views/pages/LogisticsKR/CarriersKR/routes'
import APhone from 'views/ui/Phone/APhone'
import styled from 'styled-components'
import { LogisticRequest } from 'modules/domain/logisticsKR/types'
import { refetchFunc } from 'modules/domain/common/hooks'
import { AddDraftTripModal } from 'views/pages/LogisticsKR/components/AddDraftTripModal/AddDraftTripModal'
import { ClosedReasonModal } from 'views/pages/LogisticsKR/LogisticRequests/components/ClosedReasonModal'
import { REACT_APP_CURRENCY_SYMBOL } from 'env'
import useFormatDate from 'hooks/useFormatDate'
import { DASH } from 'modules/constants'

//temporary solution while tags are missing
const ContentWrapper = styled.div`
  width: 240px;

  a {
    word-break: break-word;
  }
`

interface CardProps {
  tripOffer: TripOffer
  activeRequest: LogisticRequest | undefined
  refetchDraftTrips?: refetchFunc
  tripOffersRefetch?: refetchFunc
  inProgressRequestRefetch?: refetchFunc
}

export const TripOffersCard: FC<CardProps> = ({
  tripOffer,
  tripOffersRefetch,
  refetchDraftTrips,
  inProgressRequestRefetch,
  activeRequest,
}) => {
  const { t } = useTranslation('logisticBids')
  const formatDate = useFormatDate()
  const [popoverVisible, setPopoverVisible] = useState(false)
  const [tripReconciliationModal, setTripReconciliationModal] = useState(false)
  const [rejectModal, setRejectModal] = useState(false)
  const commission = tripOffer.agent_commission ?? 0
  const currency = REACT_APP_CURRENCY_SYMBOL
  const isClosed = tripOffer.status === TripOfferStatuses.closed
  const carrierId = tripOffer.carrier?.id
  const carParams = [
    tripOffer.car.brand_model || '',
    tripOffer.car.licence_number || '',
    !!tripOffer.trailer?.licence_number && `/ ${tripOffer?.trailer.licence_number}`,
  ]
    .filter(Boolean)
    .join(' ')

  const isShowButton = tripOffer.status === TripOfferStatuses.new

  const onSuccess = async () => {
    await refetchDraftTrips?.(true)
    await inProgressRequestRefetch?.(true)
    await tripOffersRefetch?.(true)
  }

  return (
    <>
      <CardWrapper isArchived={isClosed}>
        <TagsWrapper>
          {!!tripOffer?.agent && !isClosed && (
            <AntTag color="white">{t('agent_commission', { commission, currency })}</AntTag>
          )}
          {isClosed && <AntTag color="grey">{t(`closed_reason.${tripOffer.closed_reason}`)}</AntTag>}
          <AntTag color="white">{formatDate(tripOffer.created)}</AntTag>
        </TagsWrapper>
        <ContentWrapper>
          <ARouterLink
            eventName="tripOfferCarrier"
            itemId={carrierId}
            to={generatePath(CarriersKRRoutes.Details, { id: carrierId || '' })}
          >
            {tripOffer.carrier.name || DASH}
          </ARouterLink>
        </ContentWrapper>
        <APhone phone={tripOffer.carrier?.phone || DASH} />
        <TextCard>{tripOffer.carrier?.has_vat ? t('vat.with_vat') : t('vat.not_vat')}</TextCard>
        <TextCard>{tripOffer.driver.name || DASH}</TextCard>
        <TextCard>{carParams}</TextCard>
        <TextCard>
          {t('loading')}: {formatDate(tripOffer.load_date) || DASH}
        </TextCard>
        {isShowButton && (
          <CardButtonWrapper>
            <AntPopover
              content={
                <PopoverContent
                  setPopoverVisible={setPopoverVisible}
                  setTripReconciliationModal={setTripReconciliationModal}
                  setRejectModal={setRejectModal}
                />
              }
              placement="bottomRight"
              trigger="click"
              open={popoverVisible}
              onOpenChange={() => setPopoverVisible(!popoverVisible)}
            >
              <AButton id="tripOfferCardPopover" intent="white" Icon={Icons.IconMoreHoriz} />
            </AntPopover>
          </CardButtonWrapper>
        )}
      </CardWrapper>
      {tripReconciliationModal && (
        <AddDraftTripModal
          key={tripOffer.id}
          agent={tripOffer.agent}
          agentCommission={tripOffer.agent_commission}
          carrier={tripOffer.carrier}
          carId={tripOffer.car.id}
          trailerId={tripOffer.trailer?.id}
          driverId={tripOffer.driver.id}
          tripOfferId={tripOffer.id}
          onClose={() => setTripReconciliationModal(false)}
          activeRequest={activeRequest}
          onSuccess={onSuccess}
          isCancelControlDisable
        />
      )}
      {rejectModal && (
        <ClosedReasonModal
          key={tripOffer.id}
          onClose={() => setRejectModal(false)}
          onSuccess={onSuccess}
          tripOfferId={tripOffer.id}
        />
      )}
    </>
  )
}
