import { IconArrowDown } from '@agro-club/agroclub-shared'

import { ToggleColumnContent } from './ToggleColumnContent'
import * as ColumnBoard from 'views/components/ColumnBoard/styled'
import { FC, useState } from 'react'

type Props = {
  title: string
  count: number
  initialOpened: boolean
  children?: React.ReactNode
}

export const ColumnContentHeader: FC<Props> = ({ children, title, count, initialOpened }) => {
  const [open, setOpen] = useState(initialOpened)

  return (
    <>
      <ToggleColumnContent $open={open} onClick={() => setOpen((oldVal) => !oldVal)}>
        <div>
          {title} <ColumnBoard.Counter>{count}</ColumnBoard.Counter>
        </div>
        <IconArrowDown />
      </ToggleColumnContent>
      {open && children}
    </>
  )
}
