import { useTranslation } from 'react-i18next'
import { KeyValue } from 'views/components/KeyValue/KeyValue'
import { formatPriceLogistics } from 'modules/utils/numbers/formatPriceLogistics'
import { isRussia } from 'env'

interface Props {
  priceCarrier: number
}

export const PriceCarrierBlock = ({ priceCarrier }: Props) => {
  const { t } = useTranslation('deal')

  if (isRussia) {
    return (
      <KeyValue label={t('fields.carrier_price')}>
        {priceCarrier ? formatPriceLogistics(priceCarrier) : t('common:notSpecified')}
      </KeyValue>
    )
  }

  return null
}
