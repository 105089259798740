import { ColumnsType } from '@agro-club/agroclub-shared'
import { DealShipment } from 'modules/domain/deal/types'
import { labelQuantity } from 'modules/utils/numbers/formatQuantity'
import { labelTarif } from 'modules/utils/numbers/formatTarif'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { CellRenders } from 'views/components/Table/renderers'

export const useNestedShipmentsColumns = (): ColumnsType<DealShipment[]> => {
  const { t } = useTranslation('warehouses')

  return useMemo(
    () => [
      {
        title: t('detail.unloadDate'),
        dataIndex: 'delivered_at',
        width: 200,
        render: CellRenders.date,
      },
      {
        title: labelQuantity(t('detail.unloaded')),
        dataIndex: 'quantity',
        width: 200,
        align: 'right',
      },
      {
        title: labelTarif(t('common:logistics')),
        dataIndex: 'price_logistics',
        width: 200,
        align: 'right',
        render: CellRenders.price,
      },
      {
        title: labelTarif(t('common:exw')),
        dataIndex: 'price_exw',
        width: 200,
        align: 'right',
        render: CellRenders.price,
      },
      //This cell is needed to keep the others from stretching to the full available width.
      {
        render: () => ' ',
      },
    ],
    [t],
  )
}
