import { FC } from 'react'

import { AntdTabs, AntdTabsProps } from '@agro-club/agroclub-shared'
import { useAnalyticsChangeHandler } from 'analytics/hooks'

interface Props extends Omit<AntdTabsProps, 'id'> {
  id: string
}

const AAntdTabs: FC<Props> = ({ id, activeKey, onChange, ...otherProps }) => {
  const handleChange = useAnalyticsChangeHandler(id)

  return (
    <AntdTabs
      onChange={handleChange(onChange, { value: { from: activeKey } })}
      id={id}
      activeKey={activeKey}
      {...otherProps}
    />
  )
}

export default AAntdTabs
