import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import { useParams } from 'react-router-dom'
import { ModalContent } from './ModalContent'
import { useAFormHandler } from 'analytics/hooks'
import { endpoints } from 'modules/endpoints'
import { apiClient } from 'modules/utils/httpClient'
import ASharedModal from 'views/components/SharedModal/ASharedModal'
import { SharedModalTitle } from 'views/components/SharedModal/styled'
import { AnalyticsPlaces } from 'views/pages/Deal/analyticsPlaces'
import { refetchFunc } from 'modules/domain/common/hooks'

type Fields = {
  price: string
  files: string[]
}
type Props = {
  onClose: (arg: boolean) => void
  onSuccess: refetchFunc
}

export const AddSellerSpecification: FC<Props> = ({ onClose, onSuccess }) => {
  const { formProgress, formHandler } = useAFormHandler()
  const { t } = useTranslation('deal')
  const { id } = useParams()

  const formik = useFormik<Fields>({
    initialValues: {
      price: '',
      files: [],
    },
    onSubmit: formHandler(async (values) => await apiClient.post(endpoints.sellerSpecification(id), values), {
      onSuccess: async () => {
        await onSuccess()
        onClose(false)
      },
      onError: (error) => {
        const { errors } = error
        formik.setErrors(errors)
      },
    }),
  })

  return (
    <ASharedModal id={AnalyticsPlaces.ADD_SELLER_SPECIFICATION_MODAL} onClose={() => onClose(false)}>
      <SharedModalTitle>{t('accordion.dealFiles.seller_specification')}</SharedModalTitle>
      <ModalContent formik={formik} onClose={onClose} progress={formProgress} />
    </ASharedModal>
  )
}
