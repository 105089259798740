import { defaultTheme } from '@agro-club/agroclub-shared'
import styled from 'styled-components'
import { FC, ReactNode } from 'react'

const TrimmedBlock = styled.div<{ padding?: string }>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: ${({ padding }) => (padding ? `${padding}` : '0px')};
`

export const Hr = styled.div`
  border-top: 1px solid ${defaultTheme.color.secondary50};
  margin: 10px 0;
`

export const CommonCell: FC<{
  buyerBlock: ReactNode
  sellerBlock: ReactNode
  padding?: string
}> = ({ buyerBlock, sellerBlock, padding }) => (
  <div>
    <TrimmedBlock padding={padding}>{sellerBlock}</TrimmedBlock>
    <Hr />
    <TrimmedBlock padding={padding}>{buyerBlock}</TrimmedBlock>
  </div>
)
