import { Progress } from '@agro-club/agroclub-shared'
import { refetchFunc } from 'modules/domain/common/hooks'
import { DashboardTask } from 'modules/domain/dashboard/types'
import { ListRequestParams } from 'modules/domain/types'

export enum DashboardTab {
  responses = 'responses',
  matches = 'matches',
  newBids = 'newBids',
  newUsers = 'newUsers',
  calls = 'calls',
  overdueBids = 'overdueBids',
}

export type DashboardDataType = DashboardTask

export type DashboardTabItemProps<T = DashboardDataType> = {
  key: DashboardTab
  data: T[] | undefined
  listRequestParams: ListRequestParams
  listParamsUpdated: (ListRequestParams: any) => void
  progress: Progress
  total: number
  pageSize: number
  fetchFunc: refetchFunc
}
