import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ColumnData } from 'modules/types'
import { ListRequestParams } from 'modules/domain/types'
import { convertSortingToAntDFormat } from 'modules/domain/common/tableUtils'
import useFormatNumber from 'hooks/useFormatNumber'
import { isBrazil } from 'env'

export const useColumns = (sortParam: ListRequestParams['sort']): ColumnData[] => {
  const { t } = useTranslation('callStatistics')
  const formatNumber = useFormatNumber()
  return useMemo(
    () => [
      {
        title: t('columnsTitle.employee'),
        width: 262,
        dataIndex: 'name',
        key: 'name',
        sorter: true,
        sortOrder: convertSortingToAntDFormat('name', sortParam),
      },
      {
        title: t('columnsTitle.successful'),
        width: 116,
        dataIndex: 'successful_calls_count',
        key: 'successful_calls_count',
        align: 'right',
        sorter: true,
        sortOrder: convertSortingToAntDFormat('successful_calls_count', sortParam),
        render: (successful_calls_count) => formatNumber(successful_calls_count),
      },
      {
        title: t('columnsTitle.incoming'),
        width: 116,
        dataIndex: 'incoming_calls_percent',
        key: 'incoming_calls_percent',
        align: 'right',
        sorter: true,
        sortOrder: convertSortingToAntDFormat('incoming_calls_percent', sortParam),
        render: (incoming_calls_percent) => `${incoming_calls_percent}%`,
      },
      {
        title: t('columnsTitle.outgoing'),
        width: 128,
        dataIndex: 'outgoing_calls_percent',
        key: 'outgoing_calls_percent',
        align: 'right',
        sorter: true,
        sortOrder: convertSortingToAntDFormat('outgoing_calls_percent', sortParam),
        render: (outgoing_calls_percent) => `${outgoing_calls_percent}%`,
      },
      {
        title: t('columnsTitle.per_day'),
        width: isBrazil ? 102 : 92,
        dataIndex: 'per_day_calls_count',
        key: 'per_day_calls_count',
        align: 'right',
        sortOrder: convertSortingToAntDFormat('per_day_calls_count', sortParam),
        sorter: true,
      },
      {
        title: t('columnsTitle.per_hour'),
        width: isBrazil ? 112 : 80,
        align: 'right',
        dataIndex: 'per_hour_calls_count',
        key: 'per_hour_calls_count',
        sorter: true,
        sortOrder: convertSortingToAntDFormat('per_hour_calls_count', sortParam),
      },
      {
        title: t('columnsTitle.average_duration'),
        autoWidth: 203,
        dataIndex: 'average_successful_calls_duration',
        key: 'average_successful_calls_duration',
        align: 'right',
        sorter: true,
        sortOrder: convertSortingToAntDFormat('average_successful_calls_duration', sortParam),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sortParam, t],
  )
}
