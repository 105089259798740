import { FiltersAndSearchWrapper, FiltersWrapper } from 'views/components/CommonTableComponents/CommonTableComponents'
import { ABaseTableFilter } from 'views/components/TableFilters/ABaseTableFilter'
import { defaultListRequestParams } from 'views/components/TableFilters/data'
import { SearchFilter } from 'views/components/SearchFilter/SearchFilter'
import { MonthFilter } from 'views/components/MonthFilter/MonthFilter'
import { AClearFiltersButton } from 'views/components/Analytics'
import { useDisplayClearBtn } from 'hooks/useDisplayClearBtn'
import { CropsFilter } from 'views/components/TableFilters'
import { TableFiltersProps } from 'modules/types'
import { useTranslation } from 'react-i18next'
import { endpoints } from 'modules/endpoints'
import { FC } from 'react'

export const ExportFilters: FC<TableFiltersProps> = ({
  setListRequestParams,
  updateFilterState,
  listRequestParams,
}) => {
  const { t } = useTranslation('export')

  const displayClearBtn = useDisplayClearBtn({
    listRequestParams,
  })

  return (
    <FiltersAndSearchWrapper>
      <FiltersWrapper>
        <MonthFilter value={listRequestParams.filter.month} updateFilterState={updateFilterState} allowClear={true} />
        <ABaseTableFilter
          onChange={(status) => updateFilterState({ status })}
          value={listRequestParams.filter.status}
          endpoint={endpoints.exportStatuses()}
          getLabel={(dto) => dto.label}
          placeholder={t('status')}
          showSearch={false}
          simpleApiFormat
          id="status"
        />
        <ABaseTableFilter
          onChange={(delivery_basis) => updateFilterState({ delivery_basis })}
          value={listRequestParams.filter.delivery_basis}
          endpoint={endpoints.deliveryBasis()}
          placeholder={t('deliveryBasis')}
          getLabel={(dto) => dto.label}
          showSearch={false}
          simpleApiFormat
          id="deliveryBasis"
        />

        <CropsFilter listRequestParams={listRequestParams} updateFilterState={updateFilterState} />

        <ABaseTableFilter
          onChange={(ship_id) => updateFilterState({ ship_id })}
          value={listRequestParams.filter.ship_id}
          endpoint={endpoints.exportShips()}
          getLabel={(dto) => dto.name}
          placeholder={t('ship')}
          showSearch={false}
          simpleApiFormat
          id="ship"
        />

        {displayClearBtn && (
          <AClearFiltersButton
            onClick={() => setListRequestParams && setListRequestParams({ ...defaultListRequestParams, filter: {} })}
          />
        )}
      </FiltersWrapper>
      <SearchFilter listRequestParams={listRequestParams} updateFilterState={updateFilterState} />
    </FiltersAndSearchWrapper>
  )
}
