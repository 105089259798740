import { MultiCardContainerStyled, RouterLinkStyled, Title } from '../styled'
import { refetchFunc } from 'modules/domain/common/hooks'
import { Deal } from 'modules/domain/deal/types'
import { useTranslation } from 'react-i18next'
import { AButton } from 'views/components/Analytics'
import { useState } from 'react'
import { ContractRequestModal } from './ContractRequestModal/ContractRequestModal'
import { Text, defaultTheme } from '@agro-club/agroclub-shared'
import styled from 'styled-components'
import { TagsStatus } from './TagsStatus'
import { RemoveContract } from './RemoveContract'
import { generatePath } from 'react-router-dom'
import { ContractsRoutes } from 'views/pages/Contracts/routes'
import { Icons } from '@agro-club/agroclub-shared'
import { DASH } from 'modules/constants'
import { useMediaQuery } from 'react-responsive'
import { MobileContractRequest } from './MobileContractRequest'
import { EditContractRequestModal } from './ContractRequestModal/EditContractRequestModal'

const ButtonsWrapper = styled.div`
  display: flex;
  column-gap: 4px;
`

const ControlsWrapper = styled.div`
  display: flex;
  column-gap: 16px;
`

type Props = {
  refetch: refetchFunc
  deal: Deal
}

export const ContractRequest = ({ deal, refetch }: Props) => {
  const { t } = useTranslation('deal')
  const [requestDocsModal, setRequestDocsModal] = useState(false)
  const [editDocsModal, setEditDocsModal] = useState(false)

  const isMobile = useMediaQuery({
    query: `(max-width: ${defaultTheme.queries.mobile})`,
  })

  return (
    <>
      {isMobile ? (
        <MobileContractRequest
          openRequestDocsModal={() => setRequestDocsModal(true)}
          openEditDocsModal={() => setEditDocsModal(true)}
          refetch={refetch}
          deal={deal}
        />
      ) : (
        <MultiCardContainerStyled enableHover={!!deal?.sale_contract_request} intent="primary" bordered>
          {deal?.sale_contract_request?.id && (
            <RouterLinkStyled
              eventName="contract"
              to={generatePath(ContractsRoutes.Details, { id: deal.sale_contract_request.id })}
            />
          )}
          <div>
            <Title>{t('requestContractBlock.title')}</Title>
            {deal.sale_contract_request && (
              <Text typography="bodyMedium" color="secondary">
                {t('requestContractBlock.assignee')}: {deal.sale_contract_request.assignee?.full_name ?? DASH}
              </Text>
            )}
          </div>
          {deal.sale_contract_request ? (
            <ControlsWrapper>
              <TagsStatus contract={deal.sale_contract_request} />
              <ButtonsWrapper>
                <AButton
                  id="edit_request_docs"
                  intent="white"
                  Icon={Icons.IconEdit}
                  onClick={() => setEditDocsModal(true)}
                />

                <RemoveContract contractId={deal.sale_contract_request.id} onSuccess={refetch} />
              </ButtonsWrapper>
            </ControlsWrapper>
          ) : (
            <AButton id="request_docs" intent="primary" onClick={() => setRequestDocsModal(true)}>
              {t('requestContractBlock.request_docs')}
            </AButton>
          )}
        </MultiCardContainerStyled>
      )}
      {requestDocsModal && (
        <ContractRequestModal deal={deal} onSuccess={refetch} onClose={() => setRequestDocsModal(false)} />
      )}
      {editDocsModal && (
        <EditContractRequestModal deal={deal} onSuccess={refetch} onClose={() => setEditDocsModal(false)} />
      )}
    </>
  )
}
