import { apiClient } from 'modules/utils/httpClient'
import { User } from 'modules/domain/user/types'
import { useTranslation } from 'react-i18next'
import { endpoints } from 'modules/endpoints'
import { FC } from 'react'
import { useFormik } from 'formik'
import { useAFormHandler } from 'analytics/hooks'
import { ASharedModal, SharedModalTitle } from 'views/components/SharedModal'
import ADefaultModalFooter from 'views/components/DefaultModalFooter/ADefaultModalFooter'
import FormSelectShared from 'views/components/FormSelectShared'
import _ from 'lodash'
import styled from 'styled-components'
import { refetchFunc } from 'modules/domain/common/hooks'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`

type Props = {
  setShowModal: (show: boolean) => void
  refetch: refetchFunc
  user: User
}

type Fields = {
  tags: string[]
}

export const ProductTagsModal: FC<Props> = ({ setShowModal, user, refetch }) => {
  const { t } = useTranslation('user')
  const { formProgress, formHandler } = useAFormHandler()

  const initialTags = user.profile?.product_tags?.map((t) => String(t.id)) || []

  const formik = useFormik<Fields>({
    initialValues: {
      tags: initialTags,
    },
    onSubmit: formHandler(
      async () => {
        const toCreate = _.difference(formik.values.tags, initialTags)
        const toRemove = _.difference(initialTags, formik.values.tags)

        let promises = toCreate.map((p) => apiClient.post(endpoints.userProductTags(user.id), { id: p }))
        promises = [
          ...promises,
          ...toRemove.map((p) => apiClient.delete(endpoints.userProductTags(user.id, p), { id: p })),
        ]
        return await Promise.all(promises)
      },
      {
        onSuccess: async () => {
          await refetch()
          setShowModal(false)
        },
        onError: (error) => {
          const { errors } = error
          formik.setErrors(errors)
        },
      },
    ),
  })

  return (
    <ASharedModal size="small" onClose={() => setShowModal(false)} id="ProductTagsModal">
      <SharedModalTitle>{t('productTags.modalHeader')}</SharedModalTitle>
      <Container>
        {t('productTags.modalText')}
        <FormSelectShared
          formik={formik}
          endpoint={endpoints.productChoices()}
          fieldName="tags"
          placeholder={t('productTags.placeholder')}
          multiple
          simpleApiFormat
          showSearch={false}
          getLabel={(dto) => dto.title}
          hideLabel
          disableRightPadding
        />
      </Container>
      <ADefaultModalFooter
        onConfirm={() => formik.handleSubmit()}
        confirmButtonText={t('common:save')}
        onClose={() => setShowModal(false)}
        disabled={!formik.dirty}
        progress={formProgress}
      />
    </ASharedModal>
  )
}
