import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router'

import { AntRate } from '@agro-club/agroclub-shared'

import { ColumnData } from 'modules/types'

import DealRoutes from 'views/pages/Deal/routes'
import { RatingItem, TableLink, TableRatingText } from '../styled'
import formatNumber from 'modules/utils/numbers/formatNumber'

const useColumns = (): ColumnData[] => {
  const { t } = useTranslation('user')

  return useMemo(
    () => [
      {
        title: t('dropdownTable.deal'),
        dataIndex: 'deal_id',
        width: 267,
        render: (deal_id) => (
          <TableLink id="dealLink" to={generatePath(DealRoutes.Details, { id: deal_id })}>
            ID {deal_id}
          </TableLink>
        ),
      },
      {
        title: t('dropdownTable.rating'),
        dataIndex: 'rating_value',
        width: 267,
        render: (rating_value) => (
          <RatingItem smallRating>
            <TableRatingText>{formatNumber(rating_value, true, { fractionDigits: 1 })}</TableRatingText>
            <AntRate value={rating_value} disabled />
          </RatingItem>
        ),
      },
    ],
    [t],
  )
}

export default useColumns
