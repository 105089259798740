import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useHelmet, Icons } from '@agro-club/agroclub-shared'
import * as Layout from 'views/layouts/NewLayout'
import * as Header from 'views/ui/Header/Header'
import { HeaderButtons } from '../../components/styled'
import { WarehousesModal } from './components/Modals/WarehousesModal'
import { FiltersWrapper, TabsWrapper } from './components/styled'
import { SearchFilter } from 'views/components/SearchFilter/SearchFilter'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { useTabsData } from 'hooks/useTabsData'
import { endpoints } from 'modules/endpoints'
import useColumns from './components/WarehousesTable/useColumns'
import useUnloadingColumns from './components/WarehousesTable/useUnloadingColumns'
import WarehousesKRRoutes from '../routes'
import WarehousesTable from './components/WarehousesTable/WarehousesTable'
import APageWrapper from 'views/components/PageWrapper/APageWrapper'
import { AnalyticsPages, AnalyticsPlaces } from '../../analyticsPlaces'
import { AAntdTabs, AButton } from 'views/components/Analytics'

export const WarehousesList: FC = () => {
  const { t } = useTranslation('logisticsKR')
  useHelmet({ title: t('warehouses.list.heading') })
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const navigate = useNavigate()
  const { activeTab: activeTabParams } = useParams()
  const [activeTab, setActiveTab] = useState<string>(activeTabParams || 'loading')
  const initialTabsData = {
    loading: {
      label: t('warehouses.list.loadTab'),
      endpoint: endpoints.LogisticsKR.loadingWarehouse(),
      columnsFunc: useColumns,
    },
    unloading: {
      label: t('warehouses.list.unloadTab'),
      endpoint: endpoints.LogisticsKR.unloadingWarehouse(),
      columnsFunc: useUnloadingColumns,
    },
  }
  const {
    isFiltersLoaded,
    commonRequestParams: listRequestParams,
    updateCommonFilterState,
    setCommonRequestParams: setListRequestParams,
    tablesParams,
    tablesSetters,
  } = useTabsData(activeTab, initialTabsData)

  useEffect(() => {
    navigate(
      {
        pathname: generatePath(WarehousesKRRoutes.ListWithTabs, { activeTab: activeTab }),
        search: window.location.search,
      },
      { replace: true },
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab])

  const warehousesTabsItems = () => {
    const tabs: any[] = []
    for (const key of Object.keys(initialTabsData)) {
      tabs.push({
        label: initialTabsData[key].label,
        key,
        children: (
          <WarehousesTable
            listRequestParams={tablesParams[key]}
            isFiltersLoaded={isFiltersLoaded}
            setListRequestParams={tablesSetters[key]}
            dataEndpoint={initialTabsData[key].endpoint}
            columnsFunc={initialTabsData[key].columnsFunc}
          />
        ),
      })
    }
    return tabs
  }

  return (
    <APageWrapper page={AnalyticsPages.WAREHOUSES.LIST} place={AnalyticsPlaces.WAREHOUSES.LIST}>
      <Layout.WrapperContent>
        <Layout.Header>
          <Layout.TopHeader>
            <Header.Breadcrumbs
              routes={[
                {
                  breadcrumbName: t('warehouses.list.heading'),
                },
              ]}
            />

            <HeaderButtons>
              <AButton id="add" intent="approve" Icon={Icons.IconAdd} onClick={() => setIsModalOpen(true)}>
                {t('common:add')}
              </AButton>
            </HeaderButtons>
          </Layout.TopHeader>
          <Header.Title title={t('warehouses.list.heading')} />
        </Layout.Header>
        <TabsWrapper>
          <AAntdTabs
            id="tabs"
            items={warehousesTabsItems()}
            extraContent={
              <FiltersWrapper>
                <SearchFilter listRequestParams={listRequestParams} updateFilterState={updateCommonFilterState} />
              </FiltersWrapper>
            }
            onChange={(warehouseType) => setActiveTab(warehouseType)}
            activeKey={activeTab}
          />
        </TabsWrapper>

        {isModalOpen && (
          <WarehousesModal
            setIsOpen={setIsModalOpen}
            onSuccess={() => setListRequestParams({ ...listRequestParams })}
          />
        )}
      </Layout.WrapperContent>
    </APageWrapper>
  )
}
