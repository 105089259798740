import { Select } from '@agro-club/agroclub-shared'
import { useTranslation } from 'react-i18next'
import { FC, useMemo } from 'react'
import { FormikFieldsType } from 'modules/domain/types'
import _ from 'lodash'

export type EnumSelectProps = {
  formik: FormikFieldsType
  translationNameSpace?: string
  allowClear?: boolean
  fieldName: string
  enumType: any
  disabled?: boolean
  multiple?: boolean
  placeholder?: string
}

export const EnumSelect: FC<EnumSelectProps> = ({
  translationNameSpace,
  allowClear = false,
  fieldName,
  enumType,
  disabled,
  formik,
  multiple,
  placeholder = '',
}) => {
  const { t } = useTranslation()

  const options = useMemo(
    () =>
      Object.values(enumType).map((item) => ({ value: item as string, label: t(`${translationNameSpace}.${item}`) })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [enumType],
  )

  return (
    <Select
      onChange={(value) => formik.setFieldValue(fieldName, value)}
      status={!!formik.errors[fieldName] ? 'error' : ''}
      value={_.get(formik.values, fieldName)}
      defaultValue={options[0]}
      allowClear={allowClear}
      disabled={disabled}
      showSearch={false}
      options={options}
      mode={multiple ? 'multiple' : undefined}
      fullWidth
      placeholder={placeholder}
    />
  )
}
