import { ColumnData } from 'modules/types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { User } from 'modules/domain/user/types'
import { HistoryChangesCell } from './HistoryChangesCell'
import { date } from 'views/components/Table/renderers'

type BidChangeHistoryUser = Pick<User, 'id' | 'phone'> & { full_name: string; short_name: string }

export const useHistoryColumns = (): ColumnData[] => {
  const { t } = useTranslation('bid')

  return useMemo(
    () => [
      {
        title: t('history.date'),
        width: 102,
        dataIndex: 'date',
        align: 'left',
        render: date,
      },
      {
        title: t('history.user'),
        width: 220,
        dataIndex: 'user',
        render: (user: BidChangeHistoryUser) => user?.full_name ?? user?.short_name,
      },
      {
        title: t('history.changes'),
        autoWidth: 210,
        dataIndex: 'changes',
        render: (_, record) => <HistoryChangesCell record={record} />,
      },
    ],
    [t],
  )
}
