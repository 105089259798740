import styled from 'styled-components'
import { KeyValue } from 'views/components/KeyValue/KeyValue'
import { Card } from 'views/components/Card/Card'
import { useTranslation } from 'react-i18next'
import { endpoints } from 'modules/endpoints'
import { useSingleEntity } from 'modules/domain/common/hooks'
import formatNumber from 'modules/utils/numbers/formatNumber'
import { AntSkeleton, Progress } from '@agro-club/agroclub-shared'
import { FC } from 'react'

const CardContainer = styled(Card.Container)`
  padding-right: 40px;
`

const HorizontalDivider = styled(Card.HorizontalDivider)`
  margin-right: -40px;
`

const KeyValueBold = styled(KeyValue).attrs({ valueTypo: 'accentLarge' })``

const ItemSkeletonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
`

const ItemSkeleton: FC<{ titleWidth: number }> = ({ titleWidth }) => (
  <ItemSkeletonWrapper>
    <AntSkeleton.Input active style={{ height: 22, minWidth: 'inherit', width: titleWidth, borderRadius: 8 }} />
    <AntSkeleton.Input active style={{ height: 22, minWidth: 20, width: 40, borderRadius: 8 }} />
  </ItemSkeletonWrapper>
)

const GroupItemSkeleton = () => (
  <>
    <ItemSkeleton titleWidth={137} />
    <ItemSkeleton titleWidth={73} />
    <ItemSkeleton titleWidth={83} />
    <ItemSkeleton titleWidth={49} />
    <ItemSkeleton titleWidth={40} />
  </>
)

type GeneralStatistics = {
  successful?: string
  incoming?: string
  outgoing?: string
  per_day?: string
  per_hour?: string
}

type AverageStatistics = {
  avg_successful_calls_count?: string
  avg_incoming_calls_percent?: string
  avg_outgoing_calls_percent?: string
  avg_per_day_calls_count?: string
  avg_per_hour_calls_count?: string
  avg_calls_duration?: string
}

export const EmployeeStatistics = () => {
  const { t } = useTranslation('callStatistics')
  const [generalProgress, general] = useSingleEntity<GeneralStatistics>(endpoints.callStatisticsGeneral())
  const [averageProgress, average] = useSingleEntity<AverageStatistics>(endpoints.callStatisticsAverage())

  const generalJSX =
    generalProgress === Progress.WORK ? (
      <GroupItemSkeleton />
    ) : (
      <>
        <KeyValueBold label={t('columnsTitle.calls_successful')}>{formatNumber(general?.successful)}</KeyValueBold>
        <KeyValueBold label={t('columnsTitle.incoming')}>{formatNumber(general?.incoming)}</KeyValueBold>
        <KeyValueBold label={t('columnsTitle.outgoing')}>{formatNumber(general?.outgoing)}</KeyValueBold>
        <KeyValueBold label={t('columnsTitle.per_day')}>{formatNumber(general?.per_day)}</KeyValueBold>
        <KeyValueBold label={t('columnsTitle.per_hour')}>{general?.per_hour}</KeyValueBold>
      </>
    )

  const averageWithoutLongItemJSX =
    averageProgress === Progress.WORK ? (
      <GroupItemSkeleton />
    ) : (
      <>
        <KeyValueBold label={t('columnsTitle.calls_successful')}>
          {formatNumber(average?.avg_successful_calls_count)}
        </KeyValueBold>
        <KeyValueBold label={t('columnsTitle.incoming')}>{average?.avg_incoming_calls_percent}%</KeyValueBold>
        <KeyValueBold label={t('columnsTitle.outgoing')}>{average?.avg_outgoing_calls_percent}%</KeyValueBold>
        <KeyValueBold label={t('columnsTitle.per_day')}>{average?.avg_per_day_calls_count}</KeyValueBold>
        <KeyValueBold label={t('columnsTitle.per_hour')}>{average?.avg_per_hour_calls_count}</KeyValueBold>
      </>
    )

  const averageLongItemJSX =
    averageProgress === Progress.WORK ? (
      <ItemSkeleton titleWidth={137} />
    ) : (
      <KeyValueBold label={t('columnsTitle.average_duration')}>{average?.avg_calls_duration}</KeyValueBold>
    )

  return (
    <CardContainer>
      <Card.GapableContent>
        <Card.Title>{t('columnsTitle.all_employees')}</Card.Title>
        <Card.Grid cols={5} columnGap={32} maxColumnWidth="auto">
          {generalJSX}
        </Card.Grid>
      </Card.GapableContent>

      <HorizontalDivider />

      <Card.GapableContent>
        <Card.Title>{t('columnsTitle.average_employee')}</Card.Title>
        <Card.Grid cols={5} columnGap={32} maxColumnWidth="auto">
          {averageWithoutLongItemJSX}
        </Card.Grid>
        <Card.Grid>{averageLongItemJSX}</Card.Grid>
      </Card.GapableContent>
    </CardContainer>
  )
}
