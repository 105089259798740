import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const Wrapper = styled.div`
  width: 100vw;
  height: 100dvh;
  background: #f1f3f5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  font-family: TT Agro Club;
`
export const Description = styled.div`
  font-size: 16px;
  line-height: 22px;
  color: #272d33;
  display: flex;
  flex-direction: column;
  align-items: center;
`
export const LinkDashboard = styled(Link)`
  color: #037bff;
  transition: color ease-in 0.2s;
  &:hover {
    color: #1f58d8;
  }
`
export const Copyright = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: rgba(39, 45, 51, 0.6);
  position: absolute;
  bottom: 20px;
`
