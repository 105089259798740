import { isUS } from 'env'
import { FormikFieldsType } from 'modules/domain/types'
import { endpoints } from 'modules/endpoints'
import { useTranslation } from 'react-i18next'
import FormSelectShared from 'views/components/FormSelectShared'
import { ShortFormControl } from 'views/styled/common'

interface Props {
  formik: FormikFieldsType
  disabled?: boolean
  product_id?: string
}

export const SeasonSelect = ({ formik, disabled, product_id }: Props) => {
  const { t } = useTranslation('bid')
  const selectJSX = (
    <FormSelectShared
      formik={formik}
      label={isUS ? t('form.cropYear') : t('form.season')}
      placeholder={t('form.seasonPlaceholder')}
      fieldName="season"
      endpoint={endpoints.seasonsList(formik.values.product || product_id)}
      disabled={disabled}
      showSearch={false}
    />
  )

  return <ShortFormControl>{selectJSX}</ShortFormControl>
}
