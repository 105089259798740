import { KeyValue } from 'views/components/KeyValue/KeyValue'
import { UserLink } from 'views/components/UserLink/UserLink'
import useFormatDate from 'hooks/useFormatDate'
import { useTranslation } from 'react-i18next'
import { Sample } from '../../types'
import { FC } from 'react'
import APhone from 'views/ui/Phone/APhone'
import { Card } from 'views/components/Card/Card'
import { UserLinkWithIcons } from 'views/components/UserLinkWithIcons/UserLinkWithIcons'
import { ARouterLink } from 'views/components/Analytics'
import { generatePath } from 'react-router-dom'
import BidRoutes from 'views/pages/Bid/routes'
import styled from 'styled-components'

const UserInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
`

type Props = {
  sample: Sample
}

export const LeftBlock: FC<Props> = ({ sample }) => {
  const { t } = useTranslation('samples')
  const formatDate = useFormatDate()

  return (
    <Card.Container>
      <Card.GapableContent>
        <Card.Title>{t('detail.selectionInformation')}</Card.Title>

        <Card.Grid cols={2} maxColumnWidth="172px">
          <KeyValue label={t('sample_id')}>{sample.id}</KeyValue>
          <KeyValue label={t('bid_id')}>
            <ARouterLink
              eventName="bid_id_from_sample_detail"
              to={generatePath(BidRoutes.Details, { id: sample.bid.id })}
            >
              {sample.bid.id}
            </ARouterLink>
          </KeyValue>
        </Card.Grid>

        <Card.Grid cols={3} maxColumnWidth="172px">
          <KeyValue label={t('created')}>{formatDate(sample.created)}</KeyValue>
          <KeyValue label={t('validity')}>{formatDate(sample.expire_at)}</KeyValue>
          <KeyValue label={t('team')}>{sample.owner.profile.team?.name}</KeyValue>
        </Card.Grid>

        <KeyValue label={t('user')}>
          <UserInfoWrapper>
            <UserLinkWithIcons
              user={sample.owner}
              isVerified={sample.owner.profile.is_verified}
              hasShippedDeals={sample.owner.profile.has_shipped_deals}
            />
            <APhone phone={sample.owner.phone} />
            <div>{sample.address.address}</div>
          </UserInfoWrapper>
        </KeyValue>

        <KeyValue label={t('createdSample')}>
          <UserLink user={sample.created_by} />
        </KeyValue>

        <KeyValue label={t('common:product')}>{sample.product.title}</KeyValue>
      </Card.GapableContent>
    </Card.Container>
  )
}
