import { ListRequestParams } from 'modules/domain/types'
import { useEffect, useState } from 'react'
import _ from 'lodash'

interface Props {
  listRequestParams: ListRequestParams
  defaultFilters?: object | undefined
}

export const useDisplayClearBtn = ({ listRequestParams, defaultFilters }: Props) => {
  const [displayClearBtn, setDisplayClearBtn] = useState(false)

  useEffect(() => {
    const isFilterEmpty = (filter: any) => {
      return Array.isArray(filter) ? !!filter.length : !!filter
    }

    const selectedFilters = Object.keys(listRequestParams.filter).filter((key) =>
      isFilterEmpty(listRequestParams.filter[key]),
    )

    if (defaultFilters && selectedFilters.length > 0) {
      const defaultFilterNames = Object.keys(defaultFilters)

      if (selectedFilters.length !== Object.keys(defaultFilters).length) {
        setDisplayClearBtn(true)
        return
      }

      const arrOtherFilters = selectedFilters.filter((nameFilter) => !defaultFilterNames?.includes(nameFilter))

      let matchingFilterValues = true

      for (const key in defaultFilters) {
        if (!listRequestParams.filter[key]) continue

        const isArray = Array.isArray(defaultFilters[key])
        if (isArray) {
          matchingFilterValues = _.isEqual(listRequestParams.filter[key], defaultFilters[key])
          if (!matchingFilterValues) break
        } else {
          matchingFilterValues = listRequestParams.filter[key] === defaultFilters[key]
          if (!matchingFilterValues) break
        }
      }

      if (arrOtherFilters.length > 0 || !matchingFilterValues) {
        setDisplayClearBtn(true)
      } else {
        setDisplayClearBtn(false)
      }
      return
    }

    selectedFilters.length > 0 ? setDisplayClearBtn(true) : setDisplayClearBtn(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listRequestParams])

  return displayClearBtn
}
