import { Bar, Layout, Logo, Page, Sidebar } from 'views/layouts/MainLayout/MainLayout'
import { DesktopMainMenu } from 'views/components/MainMenu/Menu/DesktopMainMenu'
import { I18nLangSelect } from 'views/components/I18nLangSelect/I18nLangSelect'
import { CurrentUser } from 'views/components/CurrentUser/CurrentUser'
import { MobileMainMenu } from '../MainMenu/Menu/MobileMainMenu'
import { defaultTheme } from '@agro-club/agroclub-shared'
import { useMediaQuery } from 'react-responsive'
import styled from 'styled-components'
import isLangSelect from 'helpers/isLangSelect'
import { Outlet } from 'react-router'
import { FC } from 'react'

const StyledBarBottom = styled.div`
  margin-top: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  row-gap: 8px;
`

const InternalPageWrapper: FC = () => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${defaultTheme.queries.mobile})`,
  })

  return (
    <Layout>
      {!isMobile && (
        <Sidebar>
          <Logo />

          <Bar>
            <DesktopMainMenu />

            <StyledBarBottom>
              {isLangSelect() && <I18nLangSelect />}
              <CurrentUser />
            </StyledBarBottom>
          </Bar>
        </Sidebar>
      )}

      <Page>
        <Outlet />
      </Page>
      {isMobile && <MobileMainMenu />}
    </Layout>
  )
}

export default InternalPageWrapper
