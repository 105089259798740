import { ResponsiveModal } from 'views/components/ResponsiveModal/ResponsiveModal'
import { FormGroupUpload } from 'views/components/FormGroupUpload/FormGroupUpload'
import { DateISO, FormGroup, Input } from '@agro-club/agroclub-shared'
import { DatePicker } from 'views/components/DatePicker/DatePicker'
import { SharedModalInputs } from 'views/components/SharedModal'
import { refetchFunc } from 'modules/domain/common/hooks'
import { ShortFormControl } from 'views/styled/common'
import { apiClient } from 'modules/utils/httpClient'
import { useAFormHandler } from 'analytics/hooks'
import { useTranslation } from 'react-i18next'
import { endpoints } from 'modules/endpoints'
import { useFormik } from 'formik'
import { FC } from 'react'

type Props = {
  refetch: refetchFunc
  onClose: () => void
  bidId: string
}
type Fields = {
  expire_at: DateISO | undefined
  title: string
  bid: string
  files: []
}

export const AddSample: FC<Props> = ({ onClose, bidId, refetch }) => {
  const { t } = useTranslation('samples')
  const { formProgress, formHandler } = useAFormHandler('addSample', { id: bidId })

  const formik = useFormik<Fields>({
    initialValues: {
      expire_at: undefined,
      title: '',
      files: [],
      bid: bidId,
    },

    onSubmit: formHandler(
      async () => {
        await apiClient.post(endpoints.samples(), formik.values)
      },
      {
        onSuccess: async () => {
          await refetch()
          onClose()
        },

        onError: (error) => {
          formik.setErrors(error.errors)
        },
      },
    ),
  })

  return (
    <ResponsiveModal
      onConfirm={() => formik.handleSubmit()}
      confirmButtonText={t('common:save')}
      progress={formProgress}
      onClose={onClose}
      title={t('add')}
      size="medium"
      id={bidId}
    >
      <SharedModalInputs>
        <FormGroup label={t('sampleName')} error={formik.errors.title} required>
          <Input {...formik.getFieldProps('title')} invalid={!!formik.errors.title} />
        </FormGroup>
        <ShortFormControl>
          <FormGroup label={t('validity')} error={formik.errors.expire_at} required>
            <DatePicker
              onChange={(data) => formik.setFieldValue('expire_at', data['date'])}
              error={!!formik.errors.expire_at}
              value={formik.values.expire_at}
              placeholder={t('validity')}
            />
          </FormGroup>
        </ShortFormControl>

        <FormGroupUpload
          maxWidthFileUpload={405}
          progress={formProgress}
          label={t('file')}
          fieldName="files"
          formik={formik}
        />
      </SharedModalInputs>
    </ResponsiveModal>
  )
}
