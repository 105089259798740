import { FC } from 'react'
import { SharedModalContent, SharedModalTitle } from 'views/components/SharedModal'
import { useTranslation } from 'react-i18next'
import ASharedModal from '../../../../components/SharedModal/ASharedModal'
import { useAProgress } from 'hooks/useAProgress'
import { apiClient } from 'modules/utils/httpClient'
import { endpoints } from 'modules/endpoints'
import ADefaultModalFooter from '../../../../components/DefaultModalFooter/ADefaultModalFooter'

type Props = {
  onClose: (boolean) => void
  tripId: string
  onSuccess: (arg: boolean) => void | Promise<void>
}
export const RemoveTripModal: FC<Props> = ({ onClose, tripId, onSuccess }) => {
  const { t } = useTranslation('logisticsKR')
  const [removeProgress, removeHandler] = useAProgress(
    (tripId: string) => apiClient.delete(endpoints.LogisticsKR.trips(tripId)),
    {
      eventName: 'removeTripReconciliation',
      onSuccess: () => onSuccess(true),
    },
  )

  return (
    <ASharedModal id="tripReconciliation" size="small" onClose={() => onClose(false)}>
      <SharedModalTitle>{t('trips.remove_confirm_title')}</SharedModalTitle>
      <SharedModalContent>{t('trips.remove_confirm_text')}</SharedModalContent>
      <ADefaultModalFooter
        progress={removeProgress}
        confirmButtonText={t('common:yes_remove')}
        onConfirm={() => removeHandler(tripId)}
        onClose={() => onClose(false)}
      />
    </ASharedModal>
  )
}
