import { ListRequestParams } from 'modules/domain/types'
import { ColumnData } from 'modules/types'
import { useMemo } from 'react'
import { convertSortingToAntDFormat } from 'modules/domain/common/tableUtils'
import { useTranslation } from 'react-i18next'
import { labelPriceLogistics } from 'modules/utils/numbers/formatPriceLogistics'
import { CellRenders } from 'views/components/Table/renderers'
import { Text } from '@agro-club/agroclub-shared'

export const useColumns = (sortParam: ListRequestParams['sort']): ColumnData[] => {
  const { t } = useTranslation('routes')

  return useMemo(
    () => [
      {
        title: t('origin'),
        width: 260,
        dataIndex: 'sale_city',
        sorter: true,
        key: 'sale_city',
        sortOrder: convertSortingToAntDFormat('sale_city', String(sortParam)),
      },
      {
        title: () => (
          <div>
            {t('estate')}
            <Text typography="bodySmall" color="secondary">
              {t('origin')}
            </Text>
          </div>
        ),
        width: 80,
        dataIndex: 'sale_region_short',
        key: 'sale_region_short',
      },
      {
        title: t('destination'),
        width: 260,
        dataIndex: 'purchase_city',
        sorter: true,
        key: 'purchase_city',
        sortOrder: convertSortingToAntDFormat('purchase_city', String(sortParam)),
      },
      {
        title: () => (
          <div>
            {t('estate')}
            <Text typography="bodySmall" color="secondary">
              {t('destination')}
            </Text>
          </div>
        ),
        width: 90,
        dataIndex: 'purchase_region_short',
        key: 'purchase_region_short',
      },
      {
        title: labelPriceLogistics(t('tariff')),
        width: 112,
        dataIndex: 'rate',
        key: 'rate',
        align: 'right',
        render: CellRenders.price,
      },
      {
        title: t('distance_km'),
        width: 118,
        dataIndex: 'reference_distance',
        key: 'reference_distance',
        align: 'right',
      },
      {
        title: t('updated'),
        width: 110,
        dataIndex: 'modified',
        key: 'modified',
        render: CellRenders.date,
      },
    ],
    [sortParam, t],
  )
}
