import { Progress } from '@agro-club/agroclub-shared'
import { useFormik } from 'formik'
import { useCombinedProgress } from 'hooks/useCombinedProgress'
import { FC, useContext } from 'react'

import { useTranslation } from 'react-i18next'
import { useExecutorsEdit } from 'views/pages/Deal/DealDetail/useExecutorsEdit'
import { SharedModalContent, SharedModalInputs, SharedModalTitle } from '../../SharedModal/styled'
import ASharedModal from 'views/components/SharedModal/ASharedModal'
import ADefaultModalFooter from 'views/components/DefaultModalFooter/ADefaultModalFooter'
import { useAFormHandler } from 'analytics/hooks'
import { AuthContext } from 'modules/context/AuthContext'
import { ExecutorItem } from 'views/pages/Deal/DealDetail/DealEditModals/ExecutorsModal/ExecutorItem'
import { ExecutorsForm } from 'views/pages/Deal/DealDetail/types'

export type DealStatusExecutorsModalProps = {
  onClose: () => void
  dealId: string
  changeStatusProgress: Progress
  onConfirm: () => void
}

const US_IMPORTANT_EXECUTORS = [
  { type: 'user-coordinators', value: 'user_coordinator' },
  { type: 'deal-coordinators', value: 'deal_coordinator' },
  { type: 'traders', value: 'trader' },
]

const formItems = US_IMPORTANT_EXECUTORS

export const DealStatusExecutorsModal: FC<DealStatusExecutorsModalProps> = (props) => {
  const { onClose, onConfirm, changeStatusProgress, dealId } = props

  const { profile } = useContext(AuthContext)
  const [updateProgress, updateExecutors] = useExecutorsEdit()

  const { t } = useTranslation(['logistics', 'executors'])
  const { formHandler } = useAFormHandler()

  const formik = useFormik<ExecutorsForm>({
    initialValues: formItems.reduce((acc, field) => {
      acc[field.value] = profile?.profile?.coordinator_type === field.value ? profile?.id : null
      return acc
    }, {}),
    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: formHandler(
      async () => {
        await updateExecutors(dealId, formik.values)
      },
      {
        onSuccess: () => onConfirm(),
      },
    ),
  })

  const progress = useCombinedProgress(updateProgress, changeStatusProgress)

  return (
    <ASharedModal id="DealStatusModal" size="medium" onClose={onClose}>
      <SharedModalTitle>{t('logistics:statusChanging')}</SharedModalTitle>
      <SharedModalContent>
        <SharedModalInputs>
          {formItems.map((item) => (
            <ExecutorItem key={item.type} formik={formik} {...item} />
          ))}
        </SharedModalInputs>
      </SharedModalContent>

      <ADefaultModalFooter
        onConfirm={() => formik.submitForm()}
        progress={progress}
        onClose={onClose}
        confirmButtonText={t('common:save')}
      />
    </ASharedModal>
  )
}
