import { ListRequestParams } from 'modules/domain/types'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { AnalyticsFilterVariant } from 'analytics/types'
import { ASelectFilter } from '../../../../../components/Analytics'

type Props = {
  listRequestParams: ListRequestParams
  updateFilterState: any
  disabled?: boolean
}

export const RequestWithTripOfferFilter: FC<Props> = ({ updateFilterState, listRequestParams, disabled }) => {
  const { t } = useTranslation('logisticBids')
  const options = [
    {
      value: 'new',
      label: t('filters.labels.with_new'),
    },
    {
      value: 'approved',
      label: t('filters.labels.with_approval'),
    },
    {
      value: 'closed',
      label: t('filters.labels.with_closed'),
    },
  ]

  return (
    <ASelectFilter
      placeholder={t('filters.placeholders.all_bids')}
      options={options}
      value={listRequestParams.filter.trip_offer_status}
      onChange={(trip_offer_status) => updateFilterState({ trip_offer_status })}
      disabled={disabled}
      showSearch={false}
      id={AnalyticsFilterVariant.REQUEST_WITH_TRIP_OFFER}
    />
  )
}
