import { AntTag, Progress } from '@agro-club/agroclub-shared'
import { FC, ReactNode } from 'react'

import { AntTagColor } from '@agro-club/agroclub-shared/dist/cjs/ui/misc/AntTag/types'
import styled from 'styled-components'
import { PreloadCount } from '../Board/Preload'

export const CollapseHeaderWrapper = styled.div`
  width: 298px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const TitleAndTagWrapper = styled.div`
  display: flex;
  column-gap: 4px;
  align-items: center;
`

type Props = {
  counter: number | undefined
  progress: Progress
  tagColor?: AntTagColor
  title: ReactNode
}

export const CollapseHeaderWithCount: FC<Props> = ({ counter, tagColor = 'orange', title, progress }) => {
  const preloadCount = progress === Progress.WORK && <PreloadCount />

  return (
    <CollapseHeaderWrapper>
      <TitleAndTagWrapper>
        <div>{title}</div>
        {preloadCount || (
          <AntTag counter={true} color={tagColor}>
            {counter}
          </AntTag>
        )}
      </TitleAndTagWrapper>
    </CollapseHeaderWrapper>
  )
}
