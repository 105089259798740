import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import ASharedModal from 'views/components/SharedModal/ASharedModal'
import { AnalyticsPlaces } from 'views/pages/LogisticsKR/analyticsPlaces'
import ADefaultModalFooter from 'views/components/DefaultModalFooter/ADefaultModalFooter'
import { useAFormHandler } from 'analytics/hooks'
import { SharedModalContent, SharedModalInputs, SharedModalTitle } from 'views/components/SharedModal'
import { CarModalContent } from '../CarModalContent/CarModalContent'
import { CarStatuses, LogisticsKRCar } from '../../types'
import { apiClient } from 'modules/utils/httpClient'
import { endpoints } from 'modules/endpoints'
import { ListRequestParams } from 'modules/domain/types'
import { WarningContainer } from 'views/pages/Bid/BidModals/styled'
import { refetchFunc } from 'modules/domain/common/hooks'

type Props = {
  car: LogisticsKRCar
  onClose: () => void
  listRequestParams?: ListRequestParams
  setListRequestParams?: (ListRequestParams) => void
  onSuccess?: refetchFunc
}

export const EditCarBeforeTrip: FC<Props> = ({ car, onClose, setListRequestParams, listRequestParams, onSuccess }) => {
  const { formProgress, formHandler } = useAFormHandler()
  const { t } = useTranslation('logisticsKR')

  const formik = useFormik({
    initialValues: {
      brand_model: car.brand_model,
      licence_number: car.licence_number,
      car_type: car.car_type,
      unloading_type: car.unloading_type,
      side_height: car.side_height,
      is_dump_truck: car.is_dump_truck,
      has_trailer: car.has_trailer || false, //TODO temporary substitution, will need to change later
      status: CarStatuses.active,
    },
    onSubmit: formHandler(async () => await apiClient.put(endpoints.LogisticsKR.car(car.id), formik.values), {
      onSuccess: () => {
        setListRequestParams?.({ ...listRequestParams })
        onSuccess?.()
        onClose()
      },
      onError: (error) => {
        const { errors } = error
        formik.setErrors(errors)
      },
    }),
  })
  return (
    <ASharedModal id={AnalyticsPlaces.CARS.EDIT_BEFORE_SEND} onClose={onClose}>
      <SharedModalTitle>{t('carsEdit.heading')}</SharedModalTitle>
      <SharedModalContent>
        <WarningContainer>
          <div>{t('carsEdit.warningText')}</div>
        </WarningContainer>
        <SharedModalInputs>
          <CarModalContent formik={formik} isEditForm showTrailer={false} />
        </SharedModalInputs>
        <ADefaultModalFooter
          onClose={onClose}
          onConfirm={() => formik.submitForm()}
          confirmButtonText={t('carsEdit.form.save_and_activate')}
          progress={formProgress}
        />
      </SharedModalContent>
    </ASharedModal>
  )
}
