import { Badge, Icons, defaultTheme } from '@agro-club/agroclub-shared'
import styled from 'styled-components'
import { FC } from 'react'

const Btn = styled.div`
  position: absolute;
  top: 18px;
  right: 18px;
  path {
    fill: ${defaultTheme.color.textPrimary900};
  }
`

export const MobileFilterBtn: FC<{ handlerModal: () => void; displayClearBtn: boolean }> = ({
  displayClearBtn,
  handlerModal,
}) => {
  return (
    <Btn onClick={handlerModal}>
      <Badge intent="destruct" style={{ display: displayClearBtn ? 'inline' : 'none' }}>
        <Icons.IconAdjustments />
      </Badge>
    </Btn>
  )
}
