import { Progress, Text, defaultTheme } from '@agro-club/agroclub-shared'
import { useAFormHandler, useAnalyticsClickHandler } from 'analytics/hooks'
import { useFormik } from 'formik'
import { Deal } from 'modules/domain/deal/types'
import { ProfileType, User } from 'modules/domain/user/types'
import { endpoints } from 'modules/endpoints'
import { RequestError } from 'modules/errors'
import { apiClient } from 'modules/utils/httpClient'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import { generatePath, useNavigate } from 'react-router'
import styled from 'styled-components'
import { ResponsiveModal } from 'views/components/ResponsiveModal/ResponsiveModal'
import { SharedModalContent } from 'views/components/SharedModal'
import { BidCreateModal } from 'views/pages/Bid/BidModals/BidCreateModal'
import DealRoutes from 'views/pages/Deal/routes'
import { CloningFormFields, CloningTypes } from '../../types'
import { BidCloningFields } from './BidCloningFields'

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`

interface ParamsResponse {
  price_logistics: string
  logistics_rate: string
  distance: string
}

interface Props {
  deal: Deal
  onClose: () => void
}

export const BidCloningModal: FC<Props> = ({ deal, onClose }) => {
  const { t } = useTranslation('deal')
  const { formProgress, formHandler } = useAFormHandler('cloneBid', { id: deal.id })
  const [getParamsProgress, setParamsProgress] = useState<Progress>(Progress.IDLE)
  const [currentCloningType, setCurrentCloningType] = useState<CloningTypes>(CloningTypes.customer_change)
  const [currentProfileType, setCurrentProfileType] = useState<ProfileType>(ProfileType.customer)
  const [isCreateOpen, setIsCreateOpen] = useState<boolean>(false)
  const [currentUser, setCurrentUser] = useState<User>()
  const navigate = useNavigate()

  const formik = useFormik<CloningFormFields>({
    initialValues: {
      new_bid: '',
      quantity: deal.quantity,
      price_exw: currentCloningType !== CloningTypes.seller_change ? deal.sale_price : 0,
      price_cpt: currentCloningType === CloningTypes.seller_change ? deal.purchase_price : 0,
      distance: 0,
      logistics_rate: 0,
      price_logistics: 0,
      cloning_type: currentCloningType,
      user: '',
    },
    enableReinitialize: true,
    onSubmit: formHandler(
      async () => await apiClient.post<Deal>(endpoints.bidCloning(deal.id), formik.values),

      {
        onSuccess: async (data) => {
          await navigate(generatePath(DealRoutes.Details, { id: data.id }))
          // it is imported to call onClose, because the route is the same, and component will be still mouted
          onClose()
        },
        onError: (error) => {
          formik.setErrors(error.errors)
        },
      },
    ),
  })

  useEffect(() => {
    const getLogisticParams = async () => {
      if (formik.values.new_bid) {
        const bidsIds = {
          purchase_bid_id:
            currentCloningType === CloningTypes.seller_change ? deal.purchase_bid.id : formik.values.new_bid,
          sale_bid_id: currentCloningType === CloningTypes.seller_change ? formik.values.new_bid : deal.sale_bid.id,
        }

        try {
          setParamsProgress(Progress.WORK)
          const response: ParamsResponse = await apiClient.get(endpoints.calcLogisticParams(), bidsIds)
          formik.setFieldValue('price_logistics', response.price_logistics)
          formik.setFieldValue('logistics_rate', response.logistics_rate)
          formik.setFieldValue('distance', response.distance)
          setParamsProgress(Progress.SUCCESS)
        } catch (err) {
          const { errors } = err as RequestError
          formik.setErrors(errors)
          setParamsProgress(Progress.ERROR)
        }
      }
    }

    getLogisticParams()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.new_bid])

  useEffect(() => {
    currentCloningType === CloningTypes.seller_change
      ? setCurrentProfileType(ProfileType.seller)
      : setCurrentProfileType(ProfileType.customer)
  }, [currentCloningType])

  const fieldResetHandler = () => {
    formik.setFieldValue('new_bid', '')
    formik.setFieldValue('distance', 0)
    formik.setFieldValue('logistics_rate', 0)
    formik.setFieldValue('price_logistics', 0)
  }

  const handleBidOptionLoaded = (value) => {
    if (currentCloningType !== CloningTypes.seller_change) {
      return formik.setFieldValue('price_cpt', value.dto.price_cpt)
    }

    formik.setFieldValue('price_exw', value.dto.price_exw)
  }

  const changeCurrentProfileTypeHandler = (value: ProfileType) => {
    formik.setFieldValue('user', '')
    fieldResetHandler()
    setCurrentProfileType(value)
  }

  const onBidCreateClose = () => {
    setIsCreateOpen(false)
  }

  const onBidCreateSuccess = (newBid) => {
    formik.setFieldValue('new_bid', newBid.id)
    onBidCreateClose()
  }

  const handleClick = useAnalyticsClickHandler('openCreateBidModal')

  const isMobile = useMediaQuery({
    query: `(max-width: ${defaultTheme.queries.mobile})`,
  })

  const title = isMobile ? (
    <Header>
      <Text typography="titleH4">{t('bidCloningModal.title')}</Text>
      <Text>{t('bidCloningModal.warning_text')}</Text>
    </Header>
  ) : (
    t('bidCloningModal.title')
  )

  return (
    <>
      <ResponsiveModal
        onConfirm={() => formik.submitForm()}
        confirmButtonText={t('common:save')}
        progress={formProgress}
        headerHeight={149}
        onClose={onClose}
        id="bidCloning"
        title={title}
        size="large"
      >
        <SharedModalContent>
          <BidCloningFields
            changeCurrentProfileTypeHandler={changeCurrentProfileTypeHandler}
            warningText={!isMobile ? t('bidCloningModal.warning_text') : ''}
            bidControlAction={handleClick(() => setIsCreateOpen(true))}
            setCurrentCloningType={setCurrentCloningType}
            handleBidOptionLoaded={handleBidOptionLoaded}
            currentProfileType={currentProfileType}
            fieldResetHandler={fieldResetHandler}
            getParamsProgress={getParamsProgress}
            setCurrentUser={setCurrentUser}
            formik={formik}
            deal={deal}
          />
        </SharedModalContent>
      </ResponsiveModal>

      {isCreateOpen && <BidCreateModal user={currentUser} onClose={onBidCreateClose} onSuccess={onBidCreateSuccess} />}
    </>
  )
}
