import { ConfirmArchive } from 'views/pages/Bid/BidDetail/Bid/Samples/ConfirmArchive'
import { EditSample } from 'views/pages/Bid/BidDetail/Bid/Samples/EditSample'
import APageWrapper from 'views/components/PageWrapper/APageWrapper'
import { WrapperButton } from 'views/pages/User/UserDetail/styled'
import { useSingleEntity } from 'modules/domain/common/hooks'
import { RightBlock } from './RightBlock/RightBlock'
import { AButton } from 'views/components/Analytics'
import { AntTag } from '@agro-club/agroclub-shared'
import { LeftBlock } from './LeftBlock/LeftBlock'
import * as Layout from 'views/layouts/NewLayout'
import * as Header from 'views/ui/Header/Header'
import { Sample, SampleStatus } from '../types'
import { useTranslation } from 'react-i18next'
import { endpoints } from 'modules/endpoints'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import SamplesRoutes from '../routes'
import { FC, useState } from 'react'

const Content = styled.div`
  margin-top: 16px;
`
const WrapperTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 0 8px;
`

export const SampleDetail: FC = () => {
  const { id } = useParams()
  const { t } = useTranslation('samples')
  const [showModal, setShowModal] = useState(false)
  const [isConfirmVisible, setConfirmVisible] = useState(false)
  const [progressSample, sample, refetchSample] = useSingleEntity<Sample>(endpoints.samples(id))

  if (!sample) return <></>

  return (
    <APageWrapper page="sampleDetail" place="SampleDetail" id={id}>
      <Layout.WrapperContent>
        <Layout.Header>
          <Layout.TopHeader>
            <Header.Breadcrumbs
              routes={[
                { path: SamplesRoutes.List, breadcrumbName: t('list.heading') },
                { breadcrumbName: sample.title },
              ]}
            />
            <WrapperButton>
              <AButton id="edit" intent="secondary" size="medium" onClick={() => setShowModal(true)}>
                {t('common:edit')}
              </AButton>
              {sample.status === SampleStatus.in_progress && (
                <>
                  <Header.LineSeparator />
                  <AButton
                    onClick={() => setConfirmVisible(true)}
                    analyticsPayload={{ id }}
                    intent="destruct"
                    size="medium"
                    id="archive"
                  >
                    {t('common:archive')}
                  </AButton>
                </>
              )}
            </WrapperButton>
          </Layout.TopHeader>
        </Layout.Header>

        <WrapperTitle>
          <Header.Title noMargin compact title={sample.title}></Header.Title>
          {sample.status === SampleStatus.archived && <AntTag color="grey">{t('archived')}</AntTag>}
        </WrapperTitle>

        <Content>
          <Layout.ContentTwoColumns>
            <LeftBlock sample={sample} />
            <RightBlock
              sampleProgress={progressSample}
              sampleRefetch={refetchSample}
              files={sample.files}
              sample={sample}
            />
          </Layout.ContentTwoColumns>
        </Content>
      </Layout.WrapperContent>
      {showModal && <EditSample sample={sample} onClose={() => setShowModal(false)} refetch={refetchSample} />}
      {isConfirmVisible && (
        <ConfirmArchive onClose={() => setConfirmVisible(false)} sampleId={sample.id} refetch={refetchSample} />
      )}
    </APageWrapper>
  )
}
