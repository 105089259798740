import { FC } from 'react'
import { FormGroup } from '@agro-club/agroclub-shared'
import { useTranslation } from 'react-i18next'
import { NumberInput } from 'views/ui/NumberInput/NumberInput'
import { BoolRadio } from 'views/components/BoolRadio/BoolRadio'
import { controlContainerStyles, ShortField } from './styled'
import { TimeInput } from './TimeInput'
import { VehicleTypeCheckboxGroup } from 'views/components/CheckboxGroup/VehicleTypeCheckboxGroup'
import { LoaderTypeSelect } from 'views/components/LoaderTypeSelect'
import { FormikFieldsType } from 'modules/domain/types'

interface Props {
  formik: FormikFieldsType
}

export const CommonLoadWHForm: FC<Props> = ({ formik }) => {
  const { t } = useTranslation('logisticsKR')

  return (
    <>
      <FormGroup
        error={formik.errors.loading_warehouse_gates_height}
        label={t('list.createForm.labels.gates_height')}
        controlContainerStyle={controlContainerStyles()}
        required
      >
        <ShortField>
          <NumberInput
            placeholder={t('list.createForm.placeholders.gates_height')}
            {...formik.getFieldProps('loading_warehouse_gates_height')}
            invalid={!!formik.errors.loading_warehouse_gates_height}
            onChange={(value) => formik.setFieldValue('loading_warehouse_gates_height', value)}
          />
        </ShortField>
      </FormGroup>

      <LoaderTypeSelect fieldName="loading_warehouse_loader_type" formik={formik} />

      <FormGroup
        error={formik.errors.loading_warehouse_scales_capacity}
        label={t('list.createForm.labels.scales_capacity')}
        controlContainerStyle={controlContainerStyles()}
      >
        <ShortField>
          <NumberInput
            placeholder={t('list.createForm.placeholders.scales_capacity')}
            {...formik.getFieldProps('loading_warehouse_scales_capacity')}
            invalid={!!formik.errors.loading_warehouse_scales_capacity}
            onChange={(value) => formik.setFieldValue('loading_warehouse_scales_capacity', value)}
          />
        </ShortField>
      </FormGroup>

      <FormGroup
        error={formik.errors.loading_warehouse_scales_remoteness}
        label={t('list.createForm.labels.scales_remoteness')}
        controlContainerStyle={controlContainerStyles()}
      >
        <ShortField>
          <NumberInput
            placeholder={t('list.createForm.placeholders.scales_remoteness')}
            {...formik.getFieldProps('loading_warehouse_scales_remoteness')}
            invalid={!!formik.errors.loading_warehouse_scales_remoteness}
            onChange={(value) => formik.setFieldValue('loading_warehouse_scales_remoteness', value)}
          />
        </ShortField>
      </FormGroup>

      <FormGroup
        error={formik.errors.loading_warehouse_scales_length}
        label={t('list.createForm.labels.scales_length')}
        controlContainerStyle={controlContainerStyles()}
      >
        <ShortField>
          <NumberInput
            placeholder={t('list.createForm.placeholders.scales_length')}
            {...formik.getFieldProps('loading_warehouse_scales_length')}
            invalid={!!formik.errors.loading_warehouse_scales_length}
            onChange={(value) => formik.setFieldValue('loading_warehouse_scales_length', value)}
          />
        </ShortField>
      </FormGroup>

      <FormGroup
        error={formik.errors.loading_warehouse_volume_per_day}
        label={t('list.createForm.labels.volume_per_day', { type: t('list.createForm.load_type') })}
        controlContainerStyle={controlContainerStyles()}
      >
        <ShortField>
          <NumberInput
            placeholder={t('list.createForm.placeholders.volume_per_day')}
            {...formik.getFieldProps('loading_warehouse_volume_per_day')}
            invalid={!!formik.errors.loading_warehouse_volume_per_day}
            onChange={(value) => formik.setFieldValue('loading_warehouse_volume_per_day', value)}
          />
        </ShortField>
      </FormGroup>

      <VehicleTypeCheckboxGroup fieldName="loading_warehouse_vehicle_type" formik={formik} />

      <TimeInput
        formik={formik}
        label={t('list.createForm.labels.loading_schedule', { type: t('list.createForm.second_load_type') })}
        fromFormikValue="loading_warehouse_schedule_from"
        toFormikValue="loading_warehouse_schedule_to"
      />

      <BoolRadio
        formik={formik}
        label={t('list.createForm.labels.works_on_weekend')}
        fieldName="loading_warehouse_works_on_weekend"
      />

      <BoolRadio
        formik={formik}
        label={t('list.createForm.labels.car_delivery')}
        fieldName="loading_warehouse_car_delivery"
      />

      <BoolRadio
        formik={formik}
        label={t('list.createForm.labels.railway_delivery')}
        fieldName="loading_warehouse_railway_delivery"
      />
    </>
  )
}
