import { AFilesByTypes } from 'views/components/FilesByTypes/AFilesByTypes'
import { ContractDocumentType } from 'modules/domain/contracts/types'
import * as contractManagers from 'modules/domain/contracts/managers'
import { DocumentFile, DocumentType } from 'modules/domain/types'
import { useSingleEntity } from 'modules/domain/common/hooks'
import { useAProgress } from 'hooks/useAProgress'
import { endpoints } from 'modules/endpoints'
import { FC, useEffect } from 'react'

type Props = {
  setHasFiles: (hasFiles: boolean) => void
  contractId: string
  dealId: string
}

export const ContractFilesWithTypes: FC<Props> = ({ contractId, dealId, setHasFiles }) => {
  const [, documentTypes = []] = useSingleEntity<DocumentType[]>(endpoints.contractDocumentTypes(contractId))
  const [, documents = [], documentsRefetch] = useSingleEntity<DocumentFile[]>(
    endpoints.contractDocuments(contractId, dealId),
  )

  const [uploadProgress, uploadDoc] = useAProgress(
    (document_type: DocumentType['slug'], file: File) => {
      if (document_type === ContractDocumentType.instruction) {
        return contractManagers.uploadDocuments(contractId, document_type, file, dealId)
      } else {
        return contractManagers.uploadDocuments(contractId, document_type, file)
      }
    },

    {
      eventName: 'uploadDocument',
      onSuccess: () => documentsRefetch(),
    },
  )

  const [deleteProgress, deleteDoc] = useAProgress(
    (document_id: string) => contractManagers.deleteDocumentFile(contractId, document_id),
    {
      eventName: 'deleteDocument',
      onSuccess: () => documentsRefetch(),
    },
  )

  useEffect(() => {
    if (documents.length) {
      setHasFiles(true)
      return
    }
    setHasFiles(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documents])

  return (
    <AFilesByTypes
      deleteProgress={deleteProgress}
      uploadProgress={uploadProgress}
      types={documentTypes}
      onUpload={uploadDoc}
      onDelete={deleteDoc}
      files={documents}
    />
  )
}
