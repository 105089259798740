import { FormikProps } from 'formik'
import { useTranslation } from 'react-i18next'
import { SharedModalSecondContent, SharedModalSubTitle } from 'views/components/SharedModal/styled'
import { isBrazil } from 'env'
import { FC } from 'react'
import { Product } from 'modules/domain/types'
import { ProfileType } from 'modules/domain/user/types'
import { BidAddFields } from 'modules/domain/bid/types'
import { BidTagSelect } from './BidTagSelect'
import { AgroundImputsSelect } from './AgroundImputsSelect'
import { getBrazilFieldRequirements } from './utils'

type Props = {
  formik: FormikProps<BidAddFields>
  profileType?: ProfileType
  product?: Product
}

export const BrazilSubForm: FC<Props> = ({ formik, profileType, product }) => {
  const { t } = useTranslation('bid')
  if (!isBrazil) {
    return null
  }

  const required = getBrazilFieldRequirements({ profileType, product })

  if (!required.bid_tag && !required.aground_imputs) {
    return null
  }

  return (
    <SharedModalSecondContent>
      <SharedModalSubTitle>{t('form.additionalParams')}</SharedModalSubTitle>
      {required.aground_imputs && <AgroundImputsSelect formik={formik} />}
      {required.bid_tag && <BidTagSelect formik={formik} />}
    </SharedModalSecondContent>
  )
}
