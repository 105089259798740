import { SharedModalInputs, SharedModalTitle } from 'views/components/SharedModal'
import { FormGroup, Input } from '@agro-club/agroclub-shared'
import { useNotificationProgress } from 'hooks/useNotificationProgress'
import { UserAddress } from 'modules/domain/userAddress/types'
import { apiClient } from 'modules/utils/httpClient'
import { useTranslation } from 'react-i18next'
import { endpoints } from 'modules/endpoints'
import { FC } from 'react'
import { useFormik } from 'formik'
import ASharedModal from 'views/components/SharedModal/ASharedModal'
import { AnalyticsPlaces } from 'views/pages/User/analyticsPlaces'
import ADefaultModalFooter from 'views/components/DefaultModalFooter/ADefaultModalFooter'
import { useAFormHandler } from 'analytics/hooks'
import { FormMarketZone } from 'views/components/form/FormMarketZone/FormMarketZone'

type Props = {
  setShowModal: (show: boolean) => void
  addressesRefetch: () => void
  userId: string
  address: UserAddress
}

type Fields = {
  title: string
  market_zone?: string | number
}

export const EditAddressModalUs: FC<Props> = ({ setShowModal, userId, address, addressesRefetch }) => {
  const { t } = useTranslation('address')
  const { formProgress, formHandler } = useAFormHandler('editAddress', { id: userId })

  useNotificationProgress(formProgress, t('address:updateAddressSuccess'))

  const formik = useFormik<Fields>({
    initialValues: {
      title: address.title,
      // only for US
      market_zone: address.market_zone?.id.toString(),
    },

    onSubmit: formHandler(
      async () => {
        await apiClient.patch(endpoints.userAddress(userId, address.id), formik.values)
      },
      {
        onSuccess: async () => {
          await addressesRefetch()
          setShowModal(false)
        },
        onError: (error) => {
          const { errors } = error
          formik.setErrors(errors)
        },
      },
    ),
  })

  return (
    <ASharedModal id={AnalyticsPlaces.EDIT_ADDRESS_MODAL} onClose={() => setShowModal(false)}>
      <SharedModalTitle>{t('editAddress')}</SharedModalTitle>
      <SharedModalInputs>
        <FormGroup error={formik.errors.title} label={t('addressNameLabel')}>
          <Input {...formik.getFieldProps('title')} invalid={!!formik.errors.title} />
        </FormGroup>
        <FormMarketZone formik={formik} />
      </SharedModalInputs>
      <ADefaultModalFooter
        confirmButtonText={t('common:save')}
        onClose={() => setShowModal(false)}
        onConfirm={() => formik.handleSubmit()}
      />
    </ASharedModal>
  )
}
