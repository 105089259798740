import { FC } from 'react'
import { Card } from 'views/components/Card/Card'
import { formatTarif } from 'modules/utils/numbers/formatTarif'
import { KeyValue } from 'views/components/KeyValue/KeyValue'
import { useTranslation } from 'react-i18next'
import { Bid, BidPricePeriod, BidStatus } from 'modules/domain/bid/types'
import { ChildrenSpoiler, SpoilerLink } from 'views/components/ChildrenSpoiler/ChildrenSpoiler'
import { getPriceTypeLabel } from 'modules/domain/bid/utils'
import { DatePeriodFormatted } from 'views/components/DatePeriodFormatted'
import styled from 'styled-components'
import { defaultTheme } from '@agro-club/agroclub-shared'

type Props = {
  bid: WithRequired<Bid, 'price_periods'>
}

const Period = styled(DatePeriodFormatted)`
  span: last-child {
    display: block;
  }
`

const StatusColor = styled.div<{ bid: Bid; period: BidPricePeriod }>`
  color: ${({ bid, period }) => {
    if (bid.status === BidStatus.archived || period.status !== BidStatus.archived) {
      return 'inherit'
    }
    return defaultTheme.color.textTertiary30a
  }};
`

export const PricePeriodsCardItems: FC<Props> = ({ bid }) => {
  const { t } = useTranslation(['bid', 'common'])

  const priceLabel = getPriceTypeLabel(bid.price_type, t)

  return (
    <ChildrenSpoiler linkComponent={SpoilerLink}>
      {bid.price_periods.map((pricePeriod) => (
        <Card.Grid key={pricePeriod.id} cols={2} maxColumnWidth="130px">
          <KeyValue label={priceLabel}>
            <StatusColor bid={bid} period={pricePeriod}>
              {formatTarif(pricePeriod.price)}
            </StatusColor>
          </KeyValue>
          <KeyValue label={t('common:period')}>
            <StatusColor bid={bid} period={pricePeriod}>
              <Period period={pricePeriod} />
            </StatusColor>
          </KeyValue>
        </Card.Grid>
      ))}
    </ChildrenSpoiler>
  )
}
