import { FC, useState } from 'react'

import { useTranslation } from 'react-i18next'

import { useHelmet } from '@agro-club/agroclub-shared'

import * as Layout from 'views/layouts/NewLayout'
import * as Header from 'views/ui/Header/Header'
import { ListRequestParams } from 'modules/domain/types'
import { defaultListRequestParams } from 'views/components/TableFilters/data'
import { useFilters } from 'hooks/useFilter'
import { endpoints } from 'modules/endpoints'
import { useColumns } from './useColumns'
import { DocumentRequestsFilters } from './DocumentRequestsFilters'
import { useDocumentRequestStatuses } from '../useDocumentRequestStatuses'
import APageWrapper from 'views/components/PageWrapper/APageWrapper'
import { AnalyticsPages, AnalyticsPlaces } from '../analyticsPlaces'
import ATableFullPage from 'views/components/Table/ATableFullPage'

export const DocumentRequestsList: FC = () => {
  const { t } = useTranslation('documentRequests')
  const [listRequestParams, setListRequestParams] = useState<ListRequestParams>(defaultListRequestParams)
  const { isFiltersLoaded, updateFilterState } = useFilters({
    listRequestParams,
    setListRequestParams,
  })
  const [statusProgress, statuses, statusDict] = useDocumentRequestStatuses()

  const columns = useColumns(listRequestParams.sort, statusDict)
  useHelmet({ title: t('metaTitle') })

  return (
    <APageWrapper page={AnalyticsPages.DOCS} place={AnalyticsPlaces.LIST}>
      <Layout.WrapperContent>
        <Layout.Header>
          <Layout.TopHeader>
            <Header.Breadcrumbs
              routes={[{ breadcrumbName: t('metaTitle') }, { breadcrumbName: t('menu:documentsList') }]}
            />
          </Layout.TopHeader>
          <Layout.PageName>{t('list.heading')}</Layout.PageName>
          <DocumentRequestsFilters
            statusProgress={statusProgress}
            statuses={statuses}
            setListRequestParams={setListRequestParams}
            listRequestParams={listRequestParams}
            isFiltersLoaded={isFiltersLoaded}
            updateFilterState={updateFilterState}
          />
        </Layout.Header>
        <ATableFullPage
          columns={columns}
          dataEndpoint={endpoints.dealDocumentRequest()}
          listParamsUpdated={setListRequestParams}
          listRequestParams={listRequestParams}
          isFiltersLoaded={isFiltersLoaded}
          rowHoverGray
        />
      </Layout.WrapperContent>
    </APageWrapper>
  )
}
