import { ProfileType, User } from 'modules/domain/user/types'
import formatPrice from 'modules/utils/numbers/formatPrice'
import { useTranslation } from 'react-i18next'
import { Column, WrapperData } from './styled'
import { FC } from 'react'
import { ProductTags } from './ProductTags/ProductTags'
import { refetchFunc } from 'modules/domain/common/hooks'
import { VerifyStatusList } from './VerifyStatusList/VerifyStatusList'
import { Text } from '@agro-club/agroclub-shared'
import { UserInfo } from './UserInfo'
import { UserTrader } from './UserTrader/UserTrader'
import { CompanyInfo } from './CompanyInfo'

type Props = {
  userRefetch: refetchFunc
  user: User
}

export const UserData: FC<Props> = ({ user, userRefetch }) => {
  const { t } = useTranslation(['user'])

  const isCarrier = user.profile?.profile_type === ProfileType.carrier

  const percent = user.profile?.recommended_margin_percent
  const creditLimit = user.profile?.credit_limit

  return (
    <WrapperData>
      <Column>
        <Text color="secondary">{t('user:feature')}</Text>
        <VerifyStatusList user={user} userRefetch={userRefetch} />
      </Column>
      <Column>
        <Text color="secondary">{t('company:commonInfo')}</Text>
        <UserInfo user={user} />
      </Column>
      {user.profile?.company && (
        <Column>
          <Text color="secondary">{t('common:company')}</Text>
          <CompanyInfo company={user.profile.company} />
        </Column>
      )}

      <ProductTags user={user} refetch={userRefetch} />
      <UserTrader user={user} refetch={userRefetch} />
      {!isCarrier && (
        <Column>
          <Text color="secondary">{t('recommendations')}</Text>
          <div>
            <div>{t('form.fields.margin', { percent: percent || 0 })}</div>

            <div>
              <span>{t('form.fields.creditLimitShort')}</span>
              <span>{formatPrice(creditLimit || 0, true)}</span>
            </div>
          </div>
        </Column>
      )}
    </WrapperData>
  )
}
