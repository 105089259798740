import { defaultTheme, Text } from '@agro-club/agroclub-shared'
import { DispatchingItem, PaymentStatus } from './types'
import { DASH } from 'modules/constants'
import formatNumber from 'modules/utils/numbers/formatNumber'

export const ColorMap = {
  [PaymentStatus.UNDEFINED_STATUS]: {
    text: defaultTheme.color.textPrimary900,
    background: defaultTheme.color.secondary8a,
  },
  [PaymentStatus.PAYMENT_REQUIRED]: {
    text: defaultTheme.color.accentApprove600,
    background: defaultTheme.color.accentApprove16a,
  },
  [PaymentStatus.PAYMENT_DOCUMENT_SET]: {
    text: defaultTheme.color.yellow800,
    background: defaultTheme.color.yellow16a,
  },
  [PaymentStatus.BANK_FACTORING_PAYMENT_SET]: {
    text: defaultTheme.color.magenta,
    background: defaultTheme.color.magenta16a,
  },
  [PaymentStatus.PAID]: {
    text: defaultTheme.color.accentCyan600,
    background: defaultTheme.color.accentCyan16a,
  },
  default: {
    text: defaultTheme.color.textPrimary900,
    background: defaultTheme.color.secondary8a,
  },
}

export const getColors = (status?: PaymentStatus): { text: string; background: string } => {
  if (!status) return ColorMap.default
  return ColorMap[status] ?? ColorMap.default
}

export const renderPaymentsCell = (payments: DispatchingItem['payments'], date, t) => {
  if (!payments[date]) return DASH
  const colors = getColors(payments[date]?.status)
  return (
    <div>
      <div style={{ color: colors.text }}>{formatNumber(payments[date].amount, true, { fractionDigits: 3 })}</div>
      <Text typography="bodySmall" color="secondary">
        {[
          payments[date].bank?.short_name,
          payments[date].source ? t(`paymentSourcesShort.${payments[date].source}`) : null,
          payments[date].loan_agreement ? `...${payments[date].loan_agreement?.name.slice(-4)}` : null,
        ]
          .filter(Boolean)
          .join(', ')}
      </Text>
    </div>
  )
}
