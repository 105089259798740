import { Button, Progress, defaultTheme } from '@agro-club/agroclub-shared'
import { ButtonWrapper } from './styled'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { WarningModal } from 'views/components/Comments/WarningModal'
import { ACommentsTrack } from './AComments'
import { useMediaQuery } from 'react-responsive'

type Props = {
  comment: string
  addComment: (comment: string, isPin?: boolean) => void
  onATrack: ACommentsTrack
  sendProgress: Progress
  isEdit: boolean
  setIsEdit: (arg: boolean) => void
  setComment: (text: string) => void
  editPinnedComment: (comment: string) => void
  hasPinned: boolean
}

export const FooterControls = ({
  sendProgress,
  addComment,
  comment,
  isEdit,
  setIsEdit,
  setComment,
  editPinnedComment,
  hasPinned,
  onATrack,
}: Props) => {
  const { t } = useTranslation('comments')

  const [warningModal, setWarningModal] = useState(false)

  const onSaveComment = () => {
    addComment(comment)
    onATrack('add|comment', comment)
  }

  const onSendPinned = async () => {
    if (hasPinned) {
      setWarningModal(true)
      onATrack('click|save', comment)
    } else {
      await addComment(comment, true)
      onATrack('add|comment|pin', comment)
    }
  }

  const onCancelEdited = () => {
    onATrack('click|close', comment)
    setIsEdit(false)
    setComment('')
  }

  const onSaveEdited = async () => {
    await editPinnedComment(comment)
    onATrack('change|comment|pin', comment)
    setIsEdit(false)
  }

  const onConfirmModal = async () => {
    await addComment(comment, true)
    onATrack('change|comment|pin', comment)
    setWarningModal(false)
  }

  const onCloseModal = () => {
    onATrack('click|close', comment)
    setWarningModal(false)
  }

  const isMobile = useMediaQuery({
    query: `(max-width: ${defaultTheme.queries.mobile})`,
  })

  const size = isMobile ? 'biggest' : 'medium'
  return (
    <ButtonWrapper>
      {isEdit ? (
        <>
          <Button onClick={onSaveEdited} disabled={!comment} progress={sendProgress} size={size} intent="primary">
            {t('save')}
          </Button>
          <Button onClick={onCancelEdited} size={size} intent="secondary">
            {t('common:cancel')}
          </Button>
        </>
      ) : (
        <>
          <Button onClick={onSaveComment} disabled={!comment} progress={sendProgress} size={size} intent="primary">
            {t('save')}
          </Button>
          <Button onClick={onSendPinned} disabled={!comment} progress={sendProgress} size={size} intent="secondary">
            {t('save_and_pin')}
          </Button>
          {warningModal && <WarningModal addComment={onConfirmModal} onClose={onCloseModal} progress={sendProgress} />}
        </>
      )}
    </ButtonWrapper>
  )
}
