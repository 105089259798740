import { FC, useEffect, useState } from 'react'

import { SharedModalContent, SharedModalInputs } from 'views/components/SharedModal'
import { useTranslation } from 'react-i18next'

import { WarningContainer } from 'views/pages/Bid/BidModals/styled'
import { CommonBidModalControls } from '../CommonBidModalControls'
import { ShortFormControl } from 'views/styled/common'
import { QuantityInput } from 'views/components/QuantityInput/QuantityInput'
import ADefaultModalFooter from 'views/components/DefaultModalFooter/ADefaultModalFooter'
import { PotentialBidsParams } from './PotentialBidsParams'
import { PotentialBidAddFields } from 'modules/domain/potentialBid/types'
import { useFormik } from 'formik'
import { User } from 'modules/domain/user/types'
import { Product } from 'modules/domain/collection/types'
import { useAFormHandler } from 'analytics/hooks'
import { generatePath, useNavigate } from 'react-router'
import { apiClient } from 'modules/utils/httpClient'
import { endpoints } from 'modules/endpoints'
import { isBidProductParamsEnabled } from 'env'
import BidRoutes from '../../routes'
import FormSelectShared from 'views/components/FormSelectShared'
import { SeasonSelect } from '../../components/SeasonSelect'
import { MobileModalFooter } from 'views/components/MobileComponents/Modal/MobileModalFooter'
import { useMediaQuery } from 'react-responsive'
import { defaultTheme } from '@agro-club/agroclub-shared'

type Props = {
  user?: User
  onClose: () => void
  children?: React.ReactNode
}

export const PotentialBidAddForm: FC<Props> = ({ onClose, user, children }) => {
  const { t } = useTranslation('bid')
  const { formProgress, formHandler } = useAFormHandler('create')
  const [product, setProduct] = useState<Product | undefined>()
  const navigate = useNavigate()

  const formik = useFormik<PotentialBidAddFields>({
    initialValues: {
      company: user?.profile?.company?.id || undefined,
      owner: user?.id ? String(user.id) : undefined,
      address: undefined,
      product: undefined,
      season: undefined,
      quantity: undefined,
      parameters: [],
    },
    onSubmit: formHandler(
      async () => {
        const dto = {
          company: formik.values.company,
          quantity: formik.values.quantity,
          product: formik.values.product,
          owner: formik.values.owner,
          address: formik.values.address,
          season: formik.values.season,
          parameters: isBidProductParamsEnabled ? formik.values.parameters : [],
        }

        return await apiClient.post<{ id: string }>(endpoints.potentialBid(), dto)
      },
      {
        onSuccess: (newBid) => navigate(generatePath(BidRoutes.PotentialBidDetail, { id: newBid.id })),
        onError: (error) => {
          const { errors } = error
          formik.setErrors(errors)
        },
      },
    ),
  })

  useEffect(() => {
    if (!product) {
      return
    }
    formik.setValues({
      ...formik.values,
      parameters: [],
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.product])

  const isMobile = useMediaQuery({
    query: `(max-width: ${defaultTheme.queries.mobile})`,
  })
  return (
    <>
      <SharedModalContent>
        <WarningContainer>
          <div>{t('form.createWarning')}</div>
        </WarningContainer>
        <SharedModalInputs>
          {children}
          <CommonBidModalControls formik={formik as any} />
          <FormSelectShared
            label={t('form.product')}
            placeholder={t('form.selectProductPlaceholder')}
            formik={formik}
            onClear={() => setProduct(undefined)}
            onSelectedOptionLoaded={(e) => setProduct(e.dto)}
            endpoint={endpoints.products()}
            simpleApiFormat
            onChange={(value) => {
              formik.setFieldValue('season', undefined)
              formik.setFieldValue('product', value)
            }}
            getLabel={(dto) => dto.title}
            fieldName="product"
            showSearch={false}
            required
          />
          <SeasonSelect formik={formik} disabled={!formik.values.product} />
          <ShortFormControl>
            <QuantityInput formik={formik} fieldName="quantity" label={t('common:volume')} />
          </ShortFormControl>
        </SharedModalInputs>
        <PotentialBidsParams product={product} formik={formik} />
      </SharedModalContent>
      {isMobile ? (
        <MobileModalFooter
          onConfirm={() => formik.handleSubmit()}
          confirmButtonText={t('common:add')}
          disabled={!formik.dirty}
          progress={formProgress}
          onClose={onClose}
        />
      ) : (
        <ADefaultModalFooter
          onConfirm={() => formik.handleSubmit()}
          confirmButtonText={t('common:add')}
          disabled={!formik.dirty}
          progress={formProgress}
          onClose={onClose}
        />
      )}
    </>
  )
}
