import { DealShipment } from 'modules/domain/deal/types'
import { Address } from '../types'
import { User } from '../user/types'

export enum WarehousesCondition {
  opened = 'opened',
  closed = 'closed',
}

export enum WarehouseStatuses {
  active = 'active',
  archived = 'archived',
}

export enum MovementType {
  income = 'income',
  outcome = 'outcome',
}

type ProductRemaining = {
  product_title: string
  total_quantity: string
}

export type Warehouse = {
  id: string
  title: string
  address: Address
  owner: User
  status: WarehouseStatuses
  total_quantity: string
  products_remaining: ProductRemaining[]
}

type WarehouseProduct = {
  id: number
  slug: string
  title: string
}

type WarehouseDealShort = {
  id: number
  quantity: number
  price_exw: number
  price_cpt: number
  sale_price: number
  purchase_price: number
  price_logistics: number
  price_carrier: number
  product: WarehouseProduct
}

type WarehouseShort = {
  id: number
  address: Address
  owner: User
  title: string
}

export type WarehouseMovement = {
  id: number
  warehouse: WarehouseShort
  deal: WarehouseDealShort
  movement_type: MovementType
  storage_price: number | null
  handling_price: number | null
  transshipment_price: number | null
  product_title: string
  seller: User
  seller_address_title: string
  customer: User
  customer_address_title: string
  documents_request_expected_complete_date: string | null
  quantity: number
  price_exw: number
  shipments: DealShipment[]
}
