import * as Yup from 'yup'

const UnloadingParametersSchema = (requiredFieldMessage) => {
  return Yup.object({
    unloading_warehouse_address: Yup.string().required(requiredFieldMessage).nullable(),
    unloading_warehouse_vehicle_type: Yup.array().min(1, requiredFieldMessage),
    unloading_warehouse_unloading_type: Yup.string().required(requiredFieldMessage).nullable(),
  })
}

export default UnloadingParametersSchema
