import { Routes, Route } from 'react-router-dom'
import { Calculator } from './Сalculator'
import { FC } from 'react'

export const CalculatorPage: FC = () => {
  return (
    <Routes>
      <Route index element={<Calculator />}></Route>
    </Routes>
  )
}
