import { RegionDistrictFilter } from './RegionDistrictFilter'
import { ListRequestParams } from 'modules/domain/types'
import { ABaseTableFilter } from '../ABaseTableFilter'
import { useTranslation } from 'react-i18next'
import { endpoints } from 'modules/endpoints'
import styled from 'styled-components'
import { FC, useMemo } from 'react'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 16px;
`

interface Props {
  updateFilterState: (filterObj: Record<string, any>) => void
  listRequestParams: ListRequestParams
}

export const CountryRegionDistrictFilter: FC<Props> = ({ listRequestParams, updateFilterState }) => {
  const { t } = useTranslation()

  const updateCountries = (country) => {
    updateFilterState({ country, region: [], district: [] })
  }

  const regionDistrictFilter = useMemo(() => {
    const parentId = Array.isArray(listRequestParams.filter.country)
      ? listRequestParams.filter.country.join(',')
      : listRequestParams.filter.country

    return { parent_id: parentId }
  }, [listRequestParams.filter.country])

  return (
    <Container>
      <ABaseTableFilter
        onClear={() => updateFilterState({ country: null, region: [], district: [] })}
        value={listRequestParams.filter.country}
        endpoint={endpoints.countries()}
        placeholder={t('common:country')}
        onChange={updateCountries}
        showSearch={false}
        simpleApiFormat
        multiple
        id="country"
      />
      {listRequestParams.filter.country?.length > 0 && (
        <RegionDistrictFilter
          filter={regionDistrictFilter}
          listRequestParams={listRequestParams}
          updateFilterState={updateFilterState}
        />
      )}
    </Container>
  )
}
