import { SharedModalGroupContent, SharedModalSubTitle } from '../SharedModal/styled'
import { TaskModalsControls } from '../TaskModalControls/TaskModalsControls'
import { FormGroup, RouterLink } from '@agro-club/agroclub-shared'
import { PotentialBid } from 'modules/domain/potentialBid/types'
import { getUserName } from 'modules/domain/user/utils'
import { FormikFieldsType } from 'modules/domain/types'
import CompanyRoutes from 'views/pages/Company/routes'
import { FormItemWrapper } from 'views/styled/common'
import FormSelectShared from '../FormSelectShared'
import { FormCompany } from '../form/FormCompany'
import { User } from 'modules/domain/user/types'
import { Deal } from 'modules/domain/deal/types'
import { UserLink } from '../UserLink/UserLink'
import { useTranslation } from 'react-i18next'
import { Bid } from 'modules/domain/bid/types'
import { endpoints } from 'modules/endpoints'
import { generatePath } from 'react-router'
import styled from 'styled-components'
import { FC, useState } from 'react'

type Props = {
  formik: FormikFieldsType
  isMyTasks?: boolean
  isLinkedFields: boolean
  unclickableUserLink?: boolean
  bid?: Bid | PotentialBid
  user?: User
  deal?: Deal
}

const LinkedFields = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px 0;
`

export const CommonFields: FC<Props> = ({
  unclickableUserLink,
  isLinkedFields,
  isMyTasks,
  formik,
  bid,
  user,
  deal,
}) => {
  const { t } = useTranslation('task')
  const [companyId, setCompanyId] = useState()
  const onChangeCompany = (company) => {
    setCompanyId(company)
    formik.setFieldValue('linked_user', null)
  }

  return (
    <>
      <TaskModalsControls formik={formik} isMyTasks={isMyTasks} />
      <SharedModalGroupContent>
        <SharedModalSubTitle>{t('form.linkedObject')}</SharedModalSubTitle>
        {isLinkedFields && (
          <LinkedFields>
            <FormCompany formik={formik} onChange={onChangeCompany} />
            <FormSelectShared
              endpoint={companyId && endpoints.activeEmployees(companyId)}
              getLabel={(dto) => getUserName(dto)}
              placeholder={t('common:user')}
              label={t('common:user')}
              fieldName="linked_user"
              disabled={!companyId}
              showSearch={false}
              formik={formik}
              simpleApiFormat
            />
          </LinkedFields>
        )}

        {!!user && (
          <FormGroup label={t('common:user')}>
            <FormItemWrapper>
              {unclickableUserLink ? user.profile?.full_name : <UserLink target="_blank" user={user} />}
            </FormItemWrapper>
          </FormGroup>
        )}

        {!!deal && (
          <FormGroup label={t('common:deal')}>
            <FormItemWrapper>
              <div>
                <RouterLink target="_blank" to={generatePath(CompanyRoutes.Details, { id: deal?.sale_bid.company.id })}>
                  {deal?.sale_bid.company.name}
                </RouterLink>
                &nbsp;→&nbsp;
                <RouterLink
                  target="_blank"
                  to={generatePath(CompanyRoutes.Details, { id: deal?.purchase_bid.company.id })}
                >
                  {deal?.purchase_bid.company.name}
                </RouterLink>
              </div>
            </FormItemWrapper>
          </FormGroup>
        )}

        {!!bid && (
          <FormGroup label={t('common:bid')}>
            <FormItemWrapper>
              <RouterLink target="_blank" to={generatePath(CompanyRoutes.Details, { id: bid.company.id })}>
                {bid.company.name}
              </RouterLink>
            </FormItemWrapper>
          </FormGroup>
        )}
      </SharedModalGroupContent>
    </>
  )
}
