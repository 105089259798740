import { useNotificationProgress } from 'hooks/useNotificationProgress'
import { UserContact } from 'modules/domain/userContact/types'
import { apiClient } from 'modules/utils/httpClient'
import { User } from 'modules/domain/user/types'
import { useTranslation } from 'react-i18next'
import { endpoints } from 'modules/endpoints'
import { CommonFields } from './CommonFields'
import { FC } from 'react'
import { useFormik } from 'formik'
import { useAFormHandler } from 'analytics/hooks'

type Props = {
  setShowModal: () => void
  refetchContacts: () => void
  contact: UserContact
  user: User
}

type Fields = {
  full_name: string | null
  position: string | null
  phone: string | null
  is_mobile_phone: boolean
}

export const EditContactModal: FC<Props> = ({ setShowModal, user, refetchContacts, contact }) => {
  const { t } = useTranslation()
  const { formProgress, formHandler } = useAFormHandler()

  useNotificationProgress(formProgress, t('userContact:edit_success'))

  const formik = useFormik<Fields>({
    initialValues: {
      full_name: contact.full_name,
      position: contact.position,
      phone: contact.phone,
      is_mobile_phone: contact.is_mobile_phone,
    },
    onSubmit: formHandler(
      async () => {
        await apiClient.put(endpoints.userContacts(user.id, contact.id), { ...formik.values })
      },
      {
        onSuccess: async () => {
          await refetchContacts()
          setShowModal()
        },
        onError: (error) => {
          const { errors } = error
          formik.setErrors(errors)
        },
      },
    ),
  })

  return (
    <CommonFields
      id="EditContactModal"
      formik={formik}
      setShowModal={setShowModal}
      title={t('userContact:edit_contact')}
      progress={formProgress}
    />
  )
}
