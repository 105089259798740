import { useTranslation } from 'react-i18next'
import { SharedModalSecondContent, SharedModalSubTitle } from 'views/components/SharedModal/styled'
import { Checkbox, defaultTheme } from '@agro-club/agroclub-shared'
import { useState } from 'react'
import FormSelectShared from 'views/components/FormSelectShared'
import { endpoints } from 'modules/endpoints'
import { FormikProps } from 'formik'
import { ExecutorsForm } from 'views/pages/Deal/DealDetail/types'
import { Executor } from 'modules/domain/deal/types'
import { isOneRegionalForAllJobs, workTypes } from '../../helpers'
import styled from 'styled-components'
import { isRussia } from 'env'
import PermissionCheck from 'views/components/PermissionCheck/PermissionCheck'

const CheckboxesWrapper = styled.div`
  display: flex;
  column-gap: 32px;
  @media (max-width: ${defaultTheme.queries.mobile}) {
    flex-direction: column;
    gap: 30px 0;
  }
`

type Props = {
  formik: FormikProps<ExecutorsForm>
  regionals: Executor[] | undefined
}

export const RegionalControls = ({ formik, regionals }: Props) => {
  const { t } = useTranslation('executors')
  const checkboxInitial = isOneRegionalForAllJobs(regionals)
  const [checkbox, setCheckbox] = useState(checkboxInitial)

  if (!isRussia) return null

  const checkboxOnChange = (_a, isChecked) => {
    if (isChecked) {
      workTypes.forEach((type) => formik.setFieldValue(type, ''))
    }

    setCheckbox(isChecked)
  }

  const unitedSelectsOnChange = (value) => {
    workTypes.forEach((type) => formik.setFieldValue(type, value))
  }

  const unitedSelectsLabel = (
    <div>
      <div>
        {t('sample')}, {t('documents')},
      </div>
      <div>{t('loading')}</div>
    </div>
  )

  return (
    <SharedModalSecondContent>
      <SharedModalSubTitle>{t('regional')}</SharedModalSubTitle>
      <CheckboxesWrapper>
        <Checkbox isChecked={checkbox} onChange={checkboxOnChange} label={t('one_regional_in_all_jobs')} />
        {isRussia && (
          <PermissionCheck scope={['deals.can_change_ignore_quality']}>
            <Checkbox
              isChecked={formik.values.ignore_quality}
              onChange={(_value, isChecked) => formik.setFieldValue('ignore_quality', isChecked)}
              label={t('ignore_quality')}
            />
          </PermissionCheck>
        )}
      </CheckboxesWrapper>
      {checkbox ? (
        <FormSelectShared
          onChange={unitedSelectsOnChange}
          endpoint={endpoints.executorsByType('regionals')}
          getLabel={(dto) => dto.full_name}
          searchField="search"
          fieldName="sample"
          label={unitedSelectsLabel}
          placeholder={t('fieldsLabels:select_assignee')}
          formik={formik}
        />
      ) : (
        workTypes.map((type) => (
          <FormSelectShared
            key={type}
            endpoint={endpoints.executorsByType('regionals')}
            getLabel={(dto) => dto.full_name}
            searchField="search"
            fieldName={type}
            label={t(type)}
            formik={formik}
            placeholder={t('fieldsLabels:select_assignee')}
          />
        ))
      )}
    </SharedModalSecondContent>
  )
}
