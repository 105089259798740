import { FC, useState } from 'react'

import { Bid } from 'modules/domain/bid/types'
import { KeyValue } from 'views/components/KeyValue/KeyValue'
import { Card } from 'views/components/Card/Card'
import { BidTaxesModal } from '../../BidModals/Bid/BidTaxesModal'
import { refetchFunc } from 'modules/domain/common/hooks'
import { getBrazilFieldRequirements, useBrazilBidDetails } from './utils'

type Props = {
  bid: Bid
  refreshBid: refetchFunc
}

export const OptionalDetails: FC<Props> = ({ bid, refreshBid }) => {
  const [isOpen, setIsOpen] = useState(false)
  const items = useBrazilBidDetails(bid)

  if (!items.length) return null

  const { bid_tag, aground_imputs } = getBrazilFieldRequirements({
    profileType: bid?.owner?.profile?.profile_type,
    product: bid.product,
  })

  return (
    <>
      <Card.Container bordered>
        <Card.Grid cols={2} maxColumnWidth="130px">
          {items.map((item) => (
            <KeyValue key={item.label} label={item.label}>
              {item.value}
            </KeyValue>
          ))}
        </Card.Grid>

        {(bid_tag || aground_imputs) && <Card.EditButton id="editTaxes" onClick={() => setIsOpen(true)} />}
      </Card.Container>

      {isOpen && <BidTaxesModal bid={bid} onClose={() => setIsOpen(false)} refetch={refreshBid} />}
    </>
  )
}
