import { SortOrder, SorterResult } from '@agro-club/agroclub-shared'

import { ColumnData } from 'modules/types'

export type UrlSortParam<T extends string = string> = T | `-${T}`
export type UrlSortParams = UrlSortParam | UrlSortParam[]

export const convertSortingToAntDFormat = (key?: string, sortParam?: UrlSortParam): SortOrder => {
  if (!key || key !== getSortKey(sortParam)) {
    return null
  }
  return getSortOrder(sortParam)
}

export const convertSortingToUrlFormat = (sorter: SorterResult<ColumnData>): UrlSortParam => {
  if (!sorter.order || !sorter.field) return ''

  return sorter.order === 'ascend' ? `${sorter.field}` : `-${sorter.field}`
}

export const getSortOrder = (sortParam?: UrlSortParam): SortOrder => {
  if (!sortParam) {
    return null
  }
  return String(sortParam).startsWith('-') ? 'descend' : 'ascend'
}

export const getSortKey = (sortParam?: UrlSortParam) => {
  if (!sortParam) {
    return null
  }
  return String(sortParam).replace(/^-/, '')
}

const sortParamsAsRecord = (sortParams: UrlSortParams) => {
  const params: UrlSortParam[] = Array.isArray(sortParams) ? sortParams : [sortParams]
  return params.reduce(
    (prev, sortParam) => ({ ...prev, [getSortKey(sortParam) || '']: getSortOrder(sortParam) }),
    {},
  ) as Record<string, SortOrder>
}

export const updateSortOrder = <T extends ColumnData[]>(columns: T, sortParams?: UrlSortParams): T => {
  if (!sortParams || !columns.length) {
    return columns
  }

  const sortRec = sortParamsAsRecord(sortParams)
  const predicate = (col) => col.sorter && col.dataIndex && sortRec[col.dataIndex]

  if (!columns.find(predicate)) {
    return columns
  }

  return columns.map((col) => (predicate(col) ? { ...col, sortOrder: sortRec[col.dataIndex] } : col)) as T
}
