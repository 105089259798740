import { Icons } from '@agro-club/agroclub-shared'
import { FC, useState } from 'react'
import { DispatchingModal } from './DispatchingModal/DispatchingModal'
import { AButton } from 'views/components/Analytics'

interface Props {
  id: string
  permissions: string[]
  onSuccess: () => void
  currentDates: string[]
}

export const EditInfoCell: FC<Props> = ({ id, permissions, onSuccess, currentDates }) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  return (
    <>
      <AButton
        id="edit"
        intent="secondary"
        Icon={Icons.IconEdit}
        size="small"
        onClick={() => setIsModalOpen(true)}
        disabled={!permissions.includes('deals.change_dealdispatching')}
      />

      {isModalOpen && (
        <DispatchingModal
          onClose={() => setIsModalOpen(false)}
          id={id}
          currentDates={currentDates}
          onSuccess={onSuccess}
        />
      )}
    </>
  )
}
