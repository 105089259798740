import { ResponsiveModal } from 'views/components/ResponsiveModal/ResponsiveModal'
import { SharedModalContent, SharedModalInputs } from 'views/components/SharedModal'
import { executorsFormFormatErrors } from 'views/pages/Deal/DealDetail/helpers'
import { ExecutorsForm, RegionalWorkType } from '../../types'
import { AnalyticsPlaces } from '../../../analyticsPlaces'
import { RegionalControls } from './RegionalControls'
import { apiClient } from 'modules/utils/httpClient'
import { useDealContext } from '../../DealContext'
import { useAFormHandler } from 'analytics/hooks'
import { Props } from './ExecutorsModalControl'
import { useTranslation } from 'react-i18next'
import { ExecutorItem } from './ExecutorItem'
import { RequestError } from 'modules/errors'
import { endpoints } from 'modules/endpoints'
import { useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import { isRussia } from 'env'
import { FC } from 'react'

export const ExecutorsModal: FC<Props> = ({ onClose }) => {
  const { deal, dealRefetch } = useDealContext()
  const { formProgress, formHandler } = useAFormHandler('editExecutor', { id: deal.id })
  const { t } = useTranslation('executors')
  const { id } = useParams()

  const formItems = [
    { type: 'user-coordinators', value: 'user_coordinator' },
    { type: 'deal-coordinators', value: 'deal_coordinator' },
    { type: 'shipment-coordinators', value: 'shipment_coordinator' },
    { type: 'logisticians', value: 'logisticians', multiple: true },
    { type: 'traders', value: 'trader' },
  ]

  const formik = useFormik<ExecutorsForm>({
    initialValues: {
      user_coordinator: deal.executors?.user_coordinator?.id || null,
      deal_coordinator: deal.executors?.deal_coordinator?.id || null,
      shipment_coordinator: deal.executors?.shipment_coordinator?.id || null,
      logisticians: deal.executors?.logisticians?.map((logist) => logist.id),
      trader: deal.executors?.trader?.id || null,

      sample: deal.executors?.regionals?.find((regional) => regional.work_type === RegionalWorkType.sample)?.id,
      documents: deal.executors?.regionals?.find((regional) => regional.work_type === RegionalWorkType.documents)?.id,
      loading: deal.executors?.regionals?.find((regional) => regional.work_type === RegionalWorkType.loading)?.id,

      ignore_quality: deal.executors?.ignore_quality,
    },

    onSubmit: formHandler(
      async () => {
        const regionalsWithType = [
          formik.values.sample ? { regional: formik.values.sample, work_type: RegionalWorkType.sample } : '',
          formik.values.documents ? { regional: formik.values.documents, work_type: RegionalWorkType.documents } : '',
          formik.values.loading ? { regional: formik.values.loading, work_type: RegionalWorkType.loading } : '',
        ].filter(Boolean)

        const dto = {
          ...formik.values,
          regionals_with_type: regionalsWithType,
        }

        delete dto.sample
        delete dto.documents
        delete dto.loading

        if (!isRussia) {
          delete dto.ignore_quality
        }

        await apiClient.put(endpoints.executorsEdit(id), dto)
      },
      {
        onSuccess: async () => {
          await dealRefetch()
          onClose()
        },
        onError: (err) => {
          const error = err as RequestError
          const { errors } = error
          formik.setErrors(executorsFormFormatErrors(errors))
        },
      },
    ),
  })

  const ModalContent = (
    <>
      <SharedModalInputs>
        {formItems.map((item) => (
          <ExecutorItem key={item.type} formik={formik} {...item} />
        ))}
      </SharedModalInputs>
      <RegionalControls formik={formik} regionals={deal.executors?.regionals} />
    </>
  )

  return (
    <ResponsiveModal
      id={AnalyticsPlaces.EXECUTORS_EDIT_MODAL}
      onConfirm={() => formik.submitForm()}
      confirmButtonText={t('common:save')}
      disabled={!formik.dirty}
      progress={formProgress}
      title={t('title')}
      onClose={onClose}
      size="medium"
    >
      <SharedModalContent>{ModalContent}</SharedModalContent>
    </ResponsiveModal>
  )
}
