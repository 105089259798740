import { FC } from 'react'
import { AntRate } from '@agro-club/agroclub-shared'
import { RatingByType } from 'modules/domain/user/types'
import { useTranslation } from 'react-i18next'
import { CommonRatingItemText, RatingItem, RatingItemText, RatingList } from '../styled'
import formatNumber from 'modules/utils/numbers/formatNumber'

interface RatingItemProps extends RatingByType {
  isCommonRating?: boolean
}

export const UserRatingByTypeItem: FC<RatingItemProps> = ({ label, rating_value, isCommonRating }) => {
  return (
    <RatingItem smallRating={!isCommonRating}>
      {isCommonRating ? (
        <>
          <CommonRatingItemText>{`${label} — ${formatNumber(rating_value, true, {
            fractionDigits: 1,
          })}`}</CommonRatingItemText>
          <AntRate value={rating_value} disabled={true} />
        </>
      ) : (
        <>
          <RatingItemText>{`${label} — ${formatNumber(rating_value, true, {
            fractionDigits: 1,
          })}`}</RatingItemText>
          <AntRate value={rating_value} disabled={true} />
        </>
      )}
    </RatingItem>
  )
}

interface RatingListProps {
  ratings: RatingByType[] | undefined
  commonRating: number
}

export const UserRatingByTypeList: FC<RatingListProps> = ({ ratings, commonRating }) => {
  const { t } = useTranslation('user')

  return (
    <RatingList>
      <UserRatingByTypeItem label={t('commonRating')} rating_value={commonRating} isCommonRating />

      {ratings?.map((rating) => (
        <UserRatingByTypeItem key={rating?.label} label={rating?.label} rating_value={rating?.rating_value} />
      ))}
    </RatingList>
  )
}
