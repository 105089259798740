import { FC, useState } from 'react'

import { useTranslation } from 'react-i18next'

import { useHelmet } from '@agro-club/agroclub-shared'

import * as Layout from 'views/layouts/NewLayout'
import * as Header from 'views/ui/Header/Header'
import { ListRequestParams } from 'modules/domain/types'
import { defaultListRequestParams } from 'views/components/TableFilters/data'
import { useFilters } from 'hooks/useFilter'
import { useColumns } from 'views/pages/Calls/CallsTable/useColumns'
import CallsFilters from 'views/pages/Calls/CallsTable/CallsFilters'
import { endpoints } from 'modules/endpoints'
import APageWrapper from 'views/components/PageWrapper/APageWrapper'
import { AnalyticsPages, AnalyticsPlaces } from './analyticsPlaces'
import ATableFullPage from 'views/components/Table/ATableFullPage'

const CallsList: FC = () => {
  const { t } = useTranslation(['calls'])
  const [listRequestParams, setListRequestParams] = useState<ListRequestParams>(defaultListRequestParams)
  const { isFiltersLoaded, updateFilterState } = useFilters({
    listRequestParams,
    setListRequestParams,
  })

  const columns = useColumns()

  useHelmet({ title: t('callsMetaTitle') })

  return (
    <APageWrapper page={AnalyticsPages.CALLS} place={AnalyticsPlaces.LIST}>
      <Layout.WrapperContent>
        <Layout.Header>
          <Layout.TopHeader>
            <Header.Breadcrumbs
              routes={[
                {
                  breadcrumbName: t('list.heading'),
                },
              ]}
            />
          </Layout.TopHeader>
          <Layout.PageName>{t('list.heading')}</Layout.PageName>
          <CallsFilters
            setListRequestParams={setListRequestParams}
            listRequestParams={listRequestParams}
            updateFilterState={updateFilterState}
          />
        </Layout.Header>
        <ATableFullPage
          columns={columns}
          listRequestParams={listRequestParams}
          dataEndpoint={endpoints.calls()}
          listParamsUpdated={setListRequestParams}
          isFiltersLoaded={isFiltersLoaded}
          rowHoverGray
        />
      </Layout.WrapperContent>
    </APageWrapper>
  )
}

export default CallsList
