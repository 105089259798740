import { EmptyContainer, FullContainer, RouterLinkStyled } from '../styled'
import { ContractsRoutes } from 'views/pages/Contracts/routes'
import { refetchFunc } from 'modules/domain/common/hooks'
import { AButton } from 'views/components/Analytics'
import { Icons } from '@agro-club/agroclub-shared'
import { RemoveContract } from './RemoveContract'
import { Text } from '@agro-club/agroclub-shared'
import { Deal } from 'modules/domain/deal/types'
import { generatePath } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { TagsStatus } from './TagsStatus'
import { DASH } from 'modules/constants'
import styled from 'styled-components'
import { FC } from 'react'

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const ControlsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px 0;
`
type Props = {
  openRequestDocsModal: () => void
  openEditDocsModal: () => void
  refetch: refetchFunc
  deal: Deal
}

export const MobileContractRequest: FC<Props> = ({ deal, refetch, openRequestDocsModal, openEditDocsModal }) => {
  const { t } = useTranslation('deal')

  const isContract = deal.sale_contract_request
  const title = <Text typography="accentLarge">{t('requestContractBlock.title')}</Text>

  return (
    <>
      {!isContract ? (
        <EmptyContainer>
          {title}
          <AButton id="request_docs" intent="primary" onClick={openRequestDocsModal} size="biggest">
            {t('requestContractBlock.request_docs')}
          </AButton>
        </EmptyContainer>
      ) : (
        <FullContainer>
          <RouterLinkStyled
            eventName="contract"
            to={generatePath(ContractsRoutes.Details, { id: deal.sale_contract_request.id })}
          />
          <ControlsWrapper>
            {title}
            <Text typography="bodyMedium" color="secondary">
              {t('requestContractBlock.assignee')}: {deal.sale_contract_request.assignee?.full_name ?? DASH}
            </Text>
            <TagsStatus contract={deal.sale_contract_request} />
          </ControlsWrapper>

          <ButtonsWrapper>
            <RemoveContract contractId={deal.sale_contract_request.id} onSuccess={refetch} />
            <AButton onClick={openEditDocsModal} Icon={Icons.IconEdit} id="edit_request_docs" intent="white" />
          </ButtonsWrapper>
        </FullContainer>
      )}
    </>
  )
}
