import { Progress } from '@agro-club/agroclub-shared'
import { useAFormHandler } from 'analytics/hooks'
import { useFormik } from 'formik'
import { useNotification } from 'hooks/useNotification'
import { endpoints } from 'modules/endpoints'
import { apiClient } from 'modules/utils/httpClient'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router'
import FormSelectShared from 'views/components/FormSelectShared'
import { QuantityInput } from 'views/components/QuantityInput/QuantityInput'
import { SharedModalInputs } from 'views/components/SharedModal'
import DealRoutes from 'views/pages/Deal/routes'
import { CreateWarehouseModal } from 'views/pages/Warehouses/List/CreateWarehouseModal'
import { ShortFormControl } from 'views/styled/common'
import { ResponsiveSelectAddButton } from '../FormSelectButtons/FormSelectButtons'
import { ResponsiveModal } from '../ResponsiveModal/ResponsiveModal'

const warehousesFilter = { status: 'active', sort: '-created' }

type Props = {
  bidId?: string
  dealId?: string
  onClose: () => void
  onSuccess: () => void
}

export const WarehouseMovementsModal: FC<Props> = ({ onClose, bidId, onSuccess, dealId }) => {
  const { t } = useTranslation('deal')
  const { formProgress, formHandler } = useAFormHandler()
  const [isOpen, setIsOpen] = useState(false)
  const navigate = useNavigate()
  const notify = useNotification()

  const redirectHandler = async (newDealId) => navigate(generatePath(DealRoutes.Details, { id: newDealId }))

  const formik = useFormik({
    initialValues: {
      warehouse: null,
      quantity: null,
    },

    onSubmit: formHandler(
      async (values) => {
        const bid = bidId ? { bid: bidId } : {}
        const deal = dealId ? { deal: dealId } : {}
        const dto = {
          ...values,
          ...bid,
          ...deal,
        }
        return await apiClient.post(endpoints.warehouseMovements(), dto)
      },
      {
        onSuccess: (res) => {
          if (dealId) {
            redirectHandler(res.deal_id)
          } else {
            onSuccess()
          }
          onClose()
        },
        onError: (error) => {
          const { errors } = error
          formik.setErrors(errors)

          //Для других ошибок
          const fields = Object.keys(formik.values)
          const errFields = Object.keys(errors)
          for (const item of errFields) {
            if (!fields.includes(item)) notify(Progress.ERROR, { title: errors[item] })
          }
        },
      },
    ),
  })

  const toggleModal = () => setIsOpen(true)

  return (
    <ResponsiveModal
      id="toTheWarehouse"
      onClose={onClose}
      title={t('toWarehouseModal.title')}
      disabled={!formik.values.warehouse}
      progress={formProgress}
      confirmButtonText={t('common:save')}
      onConfirm={() => formik.submitForm()}
    >
      <SharedModalInputs>
        <FormSelectShared
          formik={formik}
          fieldName="warehouse"
          label={t('toWarehouseModal.warehouse')}
          placeholder={t('toWarehouseModal.whPlaceholder')}
          endpoint={endpoints.warehousesShortList()}
          filter={warehousesFilter}
          getLabel={(dto) => dto.title}
          simpleApiFormat
          searchField="search"
          required
        >
          <ResponsiveSelectAddButton action={toggleModal} eventId="openAddWarehouseModal" />
        </FormSelectShared>

        <ShortFormControl>
          <QuantityInput formik={formik} fieldName="quantity" label={t('common:volume')} required />
        </ShortFormControl>
      </SharedModalInputs>
      {isOpen && (
        <CreateWarehouseModal
          onClose={() => setIsOpen(false)}
          onSuccess={(id) => formik.setFieldValue('warehouse', id)}
        />
      )}
    </ResponsiveModal>
  )
}
