import { FormGroup, Input, defaultTheme } from '@agro-club/agroclub-shared'
import { ProductParameter } from 'modules/domain/collection/types'
import { BidParameterDTO } from 'modules/domain/bid/types'
import { FormInputParameter } from '../FormInputParameter'
import { useMediaQuery } from 'react-responsive'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { FormikErrors } from 'formik'
import { FC, useMemo } from 'react'

type Props = {
  formikError: FormikErrors<BidParameterDTO>
  parameter: BidParameterDTO
  parameters: ProductParameter[]
  onChange: (values: BidParameterDTO) => void
}

const ProductInputsWrapper = styled.div`
  display: flex;
  column-gap: 4px;
  align-items: center;
  @media (max-width: ${defaultTheme.queries.mobile}) {
    column-gap: 16px;
    align-items: flex-start;
  }
`

export const MainParam: FC<Props> = ({ formikError, parameter, parameters, onChange }) => {
  const { t } = useTranslation('bid')
  const mainParameter = useMemo(
    () => parameters.find((p) => p.id === parameter.parameter),
    [parameter.parameter, parameters],
  )
  const commonProps = { formikError, parameter, onChange }

  const isMobile = useMediaQuery({
    query: `(max-width: ${defaultTheme.queries.mobile})`,
  })
  const parameterInputs = (
    <>
      <FormInputParameter {...commonProps} field={'parameter_value_from'} label={t('form.from')} />
      <FormInputParameter {...commonProps} field={'parameter_value_to'} label={t('form.to')} />
    </>
  )

  return (
    <>
      {isMobile ? (
        <ProductInputsWrapper>
          <FormGroup label={t('common:general')}>
            <Input value={mainParameter?.name} disabled />
          </FormGroup>
          {parameterInputs}
        </ProductInputsWrapper>
      ) : (
        <FormGroup label={mainParameter?.name} required>
          <ProductInputsWrapper>{parameterInputs}</ProductInputsWrapper>
        </FormGroup>
      )}
    </>
  )
}
