import { ResponsiveModal } from 'views/components/ResponsiveModal/ResponsiveModal'
import { SharedModalContent } from 'views/components/SharedModal'
import { refetchFunc } from 'modules/domain/common/hooks'
import { apiClient } from 'modules/utils/httpClient'
import { useAProgress } from 'hooks/useAProgress'
import { endpoints } from 'modules/endpoints'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  refetch: refetchFunc
  onClose: () => void
  dealId: string
}

export const CreatePositionsModal: FC<Props> = ({ onClose, dealId, refetch }) => {
  const { t } = useTranslation('deal')
  const [createPositionsProgress, createPositions] = useAProgress(
    () => apiClient.post(endpoints.dealCreatePositions(dealId)),
    {
      eventName: 'createBatch',
      onSuccess: async () => {
        await refetch()
        onClose()
      },
    },
  )
  return (
    <ResponsiveModal
      progress={createPositionsProgress}
      onConfirm={createPositions}
      title={t('createPositions')}
      id="DealStatusModal"
      onClose={onClose}
      size="small"
    >
      <SharedModalContent>{t('createPositionsModalText')}</SharedModalContent>
    </ResponsiveModal>
  )
}
