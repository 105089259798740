import { useCallback } from 'react'

import { REACT_APP_LOCALE, REACT_APP_CURRENCY_SYMBOL } from 'env'
import _ from 'lodash'
/**
 * @deprecated in favor formatNumber() utility function
 */
export default function useFormatNumber() {
  return useCallback(
    (
      value?: number | string | null,
      isFloat = false,
      options?: { fractionDigits?: number; showCurrency?: boolean },
    ) => {
      if (_.isNil(value)) {
        return ''
      }
      if (typeof value === 'string' && Number.isNaN(Number(value))) {
        return value
      }
      const fractionOptions = isFloat
        ? { minimumFractionDigits: options?.fractionDigits ?? 2, maximumFractionDigits: options?.fractionDigits ?? 2 }
        : undefined
      return `${Number(value).toLocaleString(REACT_APP_LOCALE, fractionOptions)}${
        options?.showCurrency ? ` ${REACT_APP_CURRENCY_SYMBOL}` : ''
      }`
    },
    [],
  )
}
