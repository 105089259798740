import { FormParamInput } from 'views/pages/Deal/DealDetail/DocumentRequestControl/ContractRequest/ContractRequestModal/ProductParams/FormParamInput'
import { FormGroup, Icons, Select, defaultTheme } from '@agro-club/agroclub-shared'
import { DealProductDTO } from 'views/pages/Deal/DealDetail/types'
import { ProductParameter } from 'modules/domain/collection/types'
import { ShortFormControl } from 'views/styled/common'
import { AButton } from 'views/components/Analytics'
import styled from 'styled-components'
import { FormikErrors } from 'formik'
import { useMemo } from 'react'

const Wrapper = styled.div`
  display: flex;
  column-gap: 4px;
`

const SelectWrapper = styled.div`
  width: 208px;
`

const RemoveBtn = styled(AButton)`
  @media (max-width: ${defaultTheme.queries.mobile}) {
    margin-top: 30px;
  }
`

type Props = {
  allParams: ProductParameter[]
  availableParams: ProductParameter[]
  parameter: DealProductDTO
  onRemove: () => void
  onChange: (values: DealProductDTO) => void
  formikError?: FormikErrors<DealProductDTO>
}

export const CropParam = ({ availableParams, parameter, onChange, onRemove, allParams, formikError }: Props) => {
  const options = useMemo(() => {
    const selectedParameter = allParams.find((param) => param.id === parameter.parameter)
    if (!selectedParameter) {
      return availableParams.map((param) => ({ value: param.id, label: param.name }))
    } else {
      return [selectedParameter, ...availableParams].map((param) => ({ value: param.id, label: param.name }))
    }
  }, [allParams, parameter.parameter, availableParams])

  return (
    <Wrapper>
      <SelectWrapper>
        <FormGroup error={formikError?.parameter} required>
          <Select
            status={!!formikError?.parameter ? 'error' : ''}
            options={options}
            onChange={(param) => onChange({ ...parameter, parameter: param })}
            value={parameter.parameter}
            showSearch={false}
            allowClear={false}
            fullWidth
          />
        </FormGroup>
      </SelectWrapper>
      <ShortFormControl width={114}>
        <FormParamInput onChange={onChange} parameter={parameter} formikError={formikError} required />
      </ShortFormControl>
      <RemoveBtn id="removeCrop" intent="white" Icon={Icons.IconRemove} onClick={onRemove} />
    </Wrapper>
  )
}
