import { FC } from 'react'
import { SharedBaseTableFilter, BaseTableFilterProps as Props, defaultTheme } from '@agro-club/agroclub-shared'
import { apiClient } from 'modules/utils/httpClient'
import { useMediaQuery } from 'react-responsive'
import { MobileFilterContainer } from '../MobileComponents/Filters/MobileFilterWrapper'

const InternalBaseTableFilter: FC<Props> = (props) => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${defaultTheme.queries.mobile})`,
  })

  const FilterContainer = isMobile ? MobileFilterContainer : 'div'

  return (
    <FilterContainer label={props.placeholder}>
      <SharedBaseTableFilter apiClient={apiClient} {...props} />
    </FilterContainer>
  )
}

export default InternalBaseTableFilter
