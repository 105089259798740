import { isBrazil } from 'env'
import { Deal } from 'modules/domain/deal/types'
import { TextWrapper } from '../styled'
import { useTranslation } from 'react-i18next'
import { AccentDescription, StatusDescription } from '../../styled'
import { formatPriceLogistics } from 'modules/utils/numbers/formatPriceLogistics'

interface Props {
  deal: Deal
  status: string
}

export const LogisticDescription = ({ deal, status }: Props) => {
  const { t } = useTranslation('deal')

  if (!isBrazil) {
    return (
      <>
        <TextWrapper>
          <StatusDescription key={0} status={status}>
            {t('contractSign.logisticPrice')}{' '}
            <AccentDescription key={1} status={status}>
              {formatPriceLogistics(deal.price_logistics)}
            </AccentDescription>
          </StatusDescription>
        </TextWrapper>

        <TextWrapper>
          <StatusDescription key={0} status={status}>
            {t('contractSign.carrierPrice')}{' '}
            <AccentDescription key={1} status={status}>
              {formatPriceLogistics(deal.price_carrier)}
            </AccentDescription>
          </StatusDescription>
        </TextWrapper>
      </>
    )
  }

  return null
}
