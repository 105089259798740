import { Text } from '@agro-club/agroclub-shared'
import { isUS } from 'env'
import { DASH } from 'modules/constants'
import { Bid, USBid } from 'modules/domain/bid/types'
import { PotentialBid } from 'modules/domain/potentialBid/types'
import { ProfileType } from 'modules/domain/user/types'
import { getUserCompanyName } from 'modules/domain/user/utils'
import { getPersonalNumberLabel } from 'modules/utils/numbers/formatPersonalNumber'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router-dom'
import AddressLegalType from 'views/components/AddressLegalType'
import { AddressExtraInfo } from 'views/components/AddressTitle/AddressTitle'
import { ARouterLink } from 'views/components/Analytics'
import { Card } from 'views/components/Card/Card'
import { KeyValue } from 'views/components/KeyValue/KeyValue'
import { CompanyFactoringIcon } from 'views/components/TooltipIcons/TooltipIcons'
import { TruncatedValue } from 'views/components/TruncatedValue/TruncatedValue'
import { UserLinkWithIcons } from 'views/components/UserLinkWithIcons/UserLinkWithIcons'
import CompanyRoutes from 'views/pages/Company/routes'
import UserRoutes from 'views/pages/User/routes'
import { EntityWithIconsWrapper } from 'views/styled/common'
import APhone from 'views/ui/Phone/APhone'
import { getUserLink } from './Bid/helpers'
import Vat from './Bid/Vat'

type Props = {
  bid: Bid | PotentialBid | USBid
}

const ContractType = ({ bid }: Props) => {
  const { t } = useTranslation('bid')
  const value = (bid as USBid).contract_type ? t(`usContractTypes.${(bid as USBid).contract_type}`) : DASH

  if (!isUS) return null

  return <KeyValue label={t('form.contractType')}>{value}</KeyValue>
}

export const GeneralInformation: FC<Props> = ({ bid }) => {
  const { t } = useTranslation(['bid', 'common', 'address'])
  const profile = bid.owner.profile
  const inn = profile?.company?.inn
  const ogrn = profile?.company?.state_reg_number
  const vat = profile?.company?.vat
  const isActive = bid.owner.is_active
  const isSellerOrCustomer =
    profile?.profile_type === ProfileType.seller || profile?.profile_type === ProfileType.customer
  const isVerified = profile?.is_verified && isActive && isSellerOrCustomer
  const isVerifiedByCoordinator = !isVerified && profile?.is_verified_by_coordinator && isActive && isSellerOrCustomer
  const isVerifiedBySecurity = !isVerified && profile?.is_verified_by_security && isActive && isSellerOrCustomer
  const isVerifiedByKyc = !isVerified && profile?.is_verified_by_kyc && isActive && isSellerOrCustomer
  const isHasShippedDeals = profile?.has_shipped_deals

  return (
    <Card.GapableContent>
      <Card.Title>{t('generalInfo')}</Card.Title>

      <Text>ID {bid.id}</Text>

      <ContractType bid={bid} />

      <KeyValue label={t('form.company')}>
        <EntityWithIconsWrapper>
          <TruncatedValue hint={getUserCompanyName(bid.owner)}>
            <ARouterLink
              eventName="company"
              itemId={String(bid.owner.profile?.company.id)}
              to={generatePath(CompanyRoutes.Details, { id: String(bid.owner.profile?.company.id) })}
            >
              {getUserCompanyName(bid.owner)}
            </ARouterLink>
          </TruncatedValue>
          <CompanyFactoringIcon company={bid.company} profileType={bid.owner.profile?.profile_type} />
        </EntityWithIconsWrapper>

        <div>
          {getPersonalNumberLabel()} {inn || t('common:notSpecified')}
        </div>
        {ogrn && (
          <div>
            {t('company:form.fields.ogrn')} {ogrn}
          </div>
        )}
        <Vat vat={vat} />
      </KeyValue>

      <KeyValue label={t('common:address')}>
        <div>
          <AddressExtraInfo address={bid.address} />
          {bid.address.address}
        </div>
        <AddressLegalType address={bid.address} />
      </KeyValue>

      {bid.owner_delivery_address && (
        <KeyValue label={t('bid:deliveryAddress')}>{bid.owner_delivery_address?.address}</KeyValue>
      )}

      <KeyValue label={t('common:user')}>
        <UserLinkWithIcons
          user={bid.owner}
          to={getUserLink(bid)}
          isVerified={isVerified}
          isSignificant={profile?.is_significant}
          hasShippedDeals={isHasShippedDeals}
          isVerifiedByCoordinator={isVerifiedByCoordinator}
          isVerifiedBySecurity={isVerifiedBySecurity}
          isVerifiedByKyc={isVerifiedByKyc}
        />
        <APhone phone={bid.owner.phone || ''} />
      </KeyValue>

      {bid.created_by && (
        <KeyValue label={t('created_bid')}>
          <ARouterLink
            eventName="bid_creator"
            itemId={String(bid.created_by.id)}
            to={generatePath(UserRoutes.Details, {
              id: String(bid.created_by.id),
            })}
          >
            {bid.created_by.full_name}
          </ARouterLink>
        </KeyValue>
      )}
    </Card.GapableContent>
  )
}
