import { AntTag } from '@agro-club/agroclub-shared'
import { IconPosition } from '../../Board/styled'
import { Deal } from 'modules/domain/deal/types'
import { useTranslation } from 'react-i18next'
import { FC } from 'react'

interface Props {
  deal: Deal
}

export const PositionTag: FC<Props> = ({ deal }) => {
  const { t } = useTranslation('deal')

  return (
    deal.is_position && (
      <AntTag icon={<IconPosition />} color="blue">
        {t('position')}
      </AntTag>
    )
  )
}
