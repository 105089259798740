import { Card } from 'views/components/Card/Card'
import { KeyValue } from 'views/components/KeyValue/KeyValue'
import { formatQuantity } from 'modules/utils/numbers/formatQuantity'
import { USBid } from 'modules/domain/bid/types'
import { getPriceTypeLabel } from 'modules/domain/bid/utils'
import { useTranslation } from 'react-i18next'
import formatPrice from 'modules/utils/numbers/formatPrice'
import { DateFormatted } from 'views/components/DateFormatted'
import { DatePeriodFormatted } from 'views/components/DatePeriodFormatted'
import { refetchFunc } from 'modules/domain/common/hooks'
import { useState } from 'react'
import { USBidEditModal } from 'views/pages/Bid/BidModals/Bid/USModals/USBidEditModal'
import { labelTarif } from 'modules/utils/numbers/formatTarif'
import { DASH } from 'modules/constants'
import { USDealBibEditModal } from 'views/pages/Deal/DealDetail/BidDetailBlock/USDealBibEditModal'
import { ProfileType } from 'modules/domain/user/types'

type Props = {
  bid: USBid
  refreshBid: refetchFunc
  usedOnPage: 'deal' | 'bid'
  readOnly: boolean
}

export const USEditableBidInfo = ({ bid, refreshBid, usedOnPage, readOnly }: Props) => {
  const { t } = useTranslation(['bid', 'common'])
  const priceLabel = getPriceTypeLabel(bid.price_type, t)
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const hasPricePeriods = !!bid.start_date && !!bid.end_date

  let editModal

  switch (usedOnPage) {
    case 'bid':
      editModal = <USBidEditModal bid={bid} refetch={refreshBid} onClose={() => setIsOpen(false)} />
      break
    case 'deal':
      editModal = <USDealBibEditModal bid={bid} refetch={refreshBid} onClose={() => setIsOpen(false)} />
      break
  }

  return (
    <Card.Container bordered>
      <Card.Grid cols={2}>
        {bid.owner.profile?.profile_type === ProfileType.seller && (
          <>
            <KeyValue label={t('form.firmOffer')}>{bid.is_firm ? t('common:yes') : t('common:no')}</KeyValue>
            <KeyValue label={t('form.hedgeTicket')}>{bid.hedge_ticket || DASH}</KeyValue>
          </>
        )}
        <KeyValue label={t('form.cropYear')}>{bid.season?.name ?? DASH}</KeyValue>
        <KeyValue label={t('form.symbol')}>{bid.symbol || DASH}</KeyValue>
        <KeyValue label={t('form.delivery')}>{priceLabel}</KeyValue>
        <KeyValue label={t('form.distance')}>{bid.owner_delivery_distance || DASH}</KeyValue>
        <KeyValue label={t('common:quantity')}>{formatQuantity(bid.quantity)}</KeyValue>
        <div></div>
        <KeyValue label={t('form.basis')}>{formatPrice(bid.basis || DASH)}</KeyValue>
        <KeyValue label={t('form.basisExpiration')}>
          {bid.basis_expiration ? <DateFormatted date={bid.basis_expiration} /> : DASH}
        </KeyValue>
        <KeyValue label={labelTarif(t('form.futuresPrice'))}>{bid.futures_price ?? DASH}</KeyValue>
        <KeyValue label={t('form.deliveryPeriod')}>
          {hasPricePeriods ? (
            <DatePeriodFormatted period={{ start_date: bid.start_date, end_date: bid.end_date }} />
          ) : (
            DASH
          )}
        </KeyValue>
        <KeyValue label={labelTarif(t('form.cashPrice'))}>{formatPrice(bid.price ?? DASH)}</KeyValue>
        <KeyValue label={t('form.expiredAt')}>
          {bid.expires_at ? <DateFormatted date={bid.expires_at} /> : DASH}
        </KeyValue>
      </Card.Grid>

      {!readOnly && <Card.EditButton id="editParameters" onClick={() => setIsOpen(true)} />}
      {isOpen && editModal}
    </Card.Container>
  )
}
