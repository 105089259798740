import { useMemo, FC } from 'react'
import { useTranslation } from 'react-i18next'

import { DealStatus } from 'modules/domain/deal/types'
import { ChangeDealStatus } from 'views/components/ChangeDealStatus/ChangeDealStatus'
import { useSingleEntity } from 'modules/domain/common/hooks'
import { endpoints } from 'modules/endpoints'
import { useNotificationProgress } from 'hooks/useNotificationProgress'
import { useAProgress } from 'hooks/useAProgress'
import { apiClient } from 'modules/utils/httpClient'
import { useDealContext } from '../DealContext'

export const StatusSelect: FC = () => {
  const { deal, dealRefetch } = useDealContext()
  const [, statuses] = useSingleEntity<Array<DealStatus>>(endpoints.dealStatuses())
  const { t } = useTranslation('deal')
  const { status, available_statuses } = deal
  const availableStatus = available_statuses && available_statuses.length && available_statuses[0]

  const currentStatus = useMemo(() => statuses?.find((s) => s.slug === status), [statuses, status])
  const nextStatus = useMemo(
    () => (availableStatus && statuses?.find((s) => s.slug === availableStatus)) || undefined,
    [availableStatus, statuses],
  )

  const [changeStatusProgress, changeDealStatus] = useAProgress(
    (dealId, nextStatus) => apiClient.post(endpoints.dealStatus(dealId), { new_status: String(nextStatus) }),
    {
      eventName: 'dealStatusChange',
      analyticsPayload: {
        id: deal.id,
        value: {
          from: currentStatus?.slug,
          to: nextStatus?.slug,
        },
      },
      onSuccess: () => dealRefetch(),
    },
  )

  useNotificationProgress(changeStatusProgress, t('notifySuccessStatusChange'))

  return currentStatus ? (
    <ChangeDealStatus
      changeStatusAction={changeDealStatus}
      changeStatusProgress={changeStatusProgress}
      currentStatus={currentStatus}
      nextStatus={nextStatus}
    />
  ) : null
}
