import styled from 'styled-components'

import { Team, TeamPlan } from 'modules/domain/teams/types'
import { TeamPlanCard } from 'views/components/TeamPlanCard/TeamPlanCard'
import { TeamMembersCard } from 'views/pages/Teams/TeamDetail/TeamMembersCard'
import { Progress } from '@agro-club/agroclub-shared'
import { FC } from 'react'

const TeamInfoContainer = styled.div`
  display: flex;
`

type Props = {
  teamPlans: TeamPlan[]
  teamMembers: Team
  setProgress?: Progress
  editProgress?: Progress
  setPlan: (volume: number, schedule_date: string) => void
  editPlan: (volume: number, id: number) => void
}

export const TeamInfo: FC<Props> = ({ teamPlans, teamMembers, setProgress, editProgress, setPlan, editPlan }) => (
  <TeamInfoContainer>
    <TeamPlanCard
      teamPlans={teamPlans}
      setPlan={setPlan}
      editPlan={editPlan}
      setProgress={setProgress}
      editProgress={editProgress}
    />
    <TeamMembersCard teamMembers={teamMembers} />
  </TeamInfoContainer>
)
