import { DealStatusModal, DealStatusModalProps } from './DealStatusModal/DealStatusModal'
import { Icons, Progress, Text, defaultTheme } from '@agro-club/agroclub-shared'
import { StatusesRightArrow } from '../StatusesRightArrow/StatusesRightArrow'
import { DealValidationErrors } from './DealStatusModal/DealValidationInfo'
import { useDealContext } from 'views/pages/Deal/DealDetail/DealContext'
import capitalizeFirstLetter from 'helpers/capitalizeFirstLetter'
import { StatusBlockWrapper } from '../../styled/common'
import { DealStatus } from 'modules/domain/deal/types'
import AMobileButton from '../Analytics/AMobileButton'
import { useMediaQuery } from 'react-responsive'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { AButton } from '../Analytics'
import { FC, useState } from 'react'

const ValidationWrapper = styled.div`
  display: flex;
`
export type DealStatusButtonProps = Omit<DealStatusModalProps, 'onClose'> & {
  canMoveNextStatus: boolean
}

export type ChangeDealStatusProps = {
  changeStatusAction: (id?: string | undefined, _status?: string | undefined) => Promise<unknown> | void
  nextStatus?: DealStatusButtonProps['nextStatus']
  changeStatusProgress: Progress
  currentStatus: DealStatus
}

export const ChangeDealStatus: FC<ChangeDealStatusProps> = (props) => {
  const { deal } = useDealContext()
  const { nextStatus, currentStatus } = props
  const { t } = useTranslation('logistics')

  const canMoveNextStatus = !Object.values(deal.next_status_validation).some((v) => v.missing)
  const [isConfirmVisible, setConfirmVisible] = useState<boolean>(false)

  const isMobile = useMediaQuery({
    query: `(max-width: ${defaultTheme.queries.mobile})`,
  })

  return (
    <>
      {!isMobile ? (
        <StatusBlockWrapper>
          <Text color="secondary" typography="buttonLarge">
            {t('common:status')}: {capitalizeFirstLetter(currentStatus?.label)}
          </Text>
          {nextStatus && !deal.is_position && (
            <>
              <StatusesRightArrow />
              <ValidationWrapper>
                {!canMoveNextStatus && <DealValidationErrors validationInfo={deal.next_status_validation} />}
                <>
                  <AButton
                    onClick={() => setConfirmVisible(true)}
                    disabled={!canMoveNextStatus}
                    id={nextStatus.slug}
                    intent="secondary"
                    size="medium"
                  >
                    {capitalizeFirstLetter(nextStatus.label)}
                  </AButton>
                </>
              </ValidationWrapper>
            </>
          )}
        </StatusBlockWrapper>
      ) : (
        nextStatus && (
          <AMobileButton
            onClick={() => setConfirmVisible(true)}
            disabled={!canMoveNextStatus}
            Icon={Icons.IconArrowRightM}
            id={nextStatus.slug}
            intent="secondary"
          >
            {capitalizeFirstLetter(nextStatus.label)}
          </AMobileButton>
        )
      )}
      {isConfirmVisible && nextStatus && (
        <DealStatusModal onClose={() => setConfirmVisible(false)} nextStatus={nextStatus} dealId={deal.id} {...props} />
      )}
    </>
  )
}
