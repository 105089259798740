import { Icons, Text, defaultTheme } from '@agro-club/agroclub-shared'
import { CurrencyCode, Export } from 'modules/domain/export/types'
import formatPrice from 'modules/utils/numbers/formatPrice'
import { Card } from 'views/components/Card/Card'
import useFormatDate from 'hooks/useFormatDate'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { FC } from 'react'

const RatesBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px 0;
`
const Currencies = styled.div`
  margin-top: 4px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
`
const CurrencyBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 0 8px;
`

type Props = {
  exportData: Export
}

const flags = {
  [CurrencyCode.USD]: Icons.IconFlagus,
  [CurrencyCode.EUR]: Icons.IconFlageu,
  [CurrencyCode.AED]: Icons.IconFlaguae,
  [CurrencyCode.CNY]: Icons.IconFlagcn,
  [CurrencyCode.RUB]: Icons.IconFlagRussia,
}
enum TypeRates {
  activation = 'activation',
  creation = 'creation',
}

const StyledText = styled(Text)<{ rateChange: 'increase' | 'decrease' | 'noChange' }>`
  color: ${({ rateChange }) => {
    if (rateChange === 'increase') return defaultTheme.color.accentApprove600
    if (rateChange === 'decrease') return defaultTheme.color.accentDestruct600
    return 'inherit'
  }};
`

export const Rates: FC<Props> = ({ exportData }) => {
  const { t } = useTranslation('export')
  const formatDate = useFormatDate()

  const renderCard = (rates, label, typeRate) => {
    const isActivation = typeRate === TypeRates.activation
    return (
      !!rates.length && (
        <Card.Container>
          <Text typography="bodyMedium">{label}</Text>
          <Currencies>
            {rates.map((item, i) => {
              const Icon = flags[item.currency_id]

              let rateChange: 'increase' | 'decrease' | 'noChange' = 'noChange'

              if (isActivation) {
                if (item.activation_date_rate > item.create_date_rate) {
                  rateChange = 'increase'
                } else if (item.activation_date_rate < item.create_date_rate) {
                  rateChange = 'decrease'
                }
              }

              return (
                <CurrencyBlock key={i}>
                  {Icon && <Icon />}
                  <StyledText typography="bodyMedium" rateChange={rateChange}>
                    {formatPrice(isActivation ? item.activation_date_rate : item.create_date_rate, true)}
                  </StyledText>
                </CurrencyBlock>
              )
            })}
          </Currencies>
        </Card.Container>
      )
    )
  }

  return (
    <RatesBlock>
      {renderCard(exportData.exchange_rates, t('creationRate'), TypeRates.creation)}
      {renderCard(
        exportData.exchange_rates,
        t('dateRate', { date: formatDate(exportData.exchange_rates[0].activation_date, true) }),
        TypeRates.activation,
      )}
    </RatesBlock>
  )
}
