import { DealCondition, DealStatusTypes } from 'modules/domain/deal/types'
import { useFetchMarginsAndRevenues } from './useFetchMarginsAndRevenues'
import { CardsBoard } from 'views/components/Board/CardsBoard'
import { ListRequestParams } from 'modules/domain/user/types'
import { refetchFunc } from 'modules/domain/common/hooks'
import { BatchesBlock } from './Batches/BatchesBlock'
import { useDealBoardColumns } from './hooks'
import { FC, useState } from 'react'
import { Column } from './Column'

type Props = {
  setListRequestParams?: (params: ListRequestParams) => void
  listRequestParams: ListRequestParams
  typeDeals: DealCondition
  isFiltersLoaded: boolean
  activeTab: string
}

export const Board: FC<Props> = ({
  setListRequestParams,
  listRequestParams,
  isFiltersLoaded,
  typeDeals,
  activeTab,
}) => {
  const columns = useDealBoardColumns()
  const { progress, margins, revenues, refetch } = useFetchMarginsAndRevenues(
    listRequestParams,
    isFiltersLoaded,
    typeDeals,
  )
  const [batchBlock, setBatchBlock] = useState(false)

  const handleRefetch = () => {
    setListRequestParams?.({
      ...listRequestParams,
    })
    refetch()
  }
  return (
    <CardsBoard>
      {columns.map((column) => {
        return (
          <>
            <Column
              onChangeBatch={() => setBatchBlock(!batchBlock)}
              listRequestParams={listRequestParams}
              isFiltersLoaded={isFiltersLoaded}
              isOpenBatchBlock={batchBlock}
              progressMargin={progress}
              key={column.statusDeal}
              typeDeals={typeDeals}
              activeTab={activeTab}
              revenues={revenues}
              margins={margins}
              column={column}
            />
            {column.statusDeal === DealStatusTypes.CONTRACT_SIGNED && batchBlock && (
              <BatchesBlock refetch={handleRefetch as refetchFunc} />
            )}
          </>
        )
      })}
    </CardsBoard>
  )
}
