import { Breadcrumbs, Header, PageName, TopHeader, WrapperContent } from 'views/layouts/NewLayout'
import { DispatchingDrawer } from '../Dispatching/components/DispatchingDrawer/DispatchingDrawer'
import { defaultListRequestParams } from 'views/components/TableFilters/data'
import APageWrapper from 'views/components/PageWrapper/APageWrapper'
import ATableFullPage from 'views/components/Table/ATableFullPage'
import { TaskModal } from 'views/components/TaskModal/TaskModal'
import { ListRequestParams } from 'modules/domain/types'
import { apiClient } from 'modules/utils/httpClient'
import { AButton } from 'views/components/Analytics'
import { useAProgress } from 'hooks/useAProgress'
import { FC, useEffect, useState } from 'react'
import { useColumns } from './Table/useColumns'
import { useTranslation } from 'react-i18next'
import { endpoints } from 'modules/endpoints'
import { useFilters } from 'hooks/useFilter'
import { Filters } from './Filters'

export type TypeTotals = {
  total_offloads_percent: string
  total_uploads_percent: string
  quantity: string
  uploads: string
  offloads: string
  transit: string
}

export const ShipmentsControlList: FC = () => {
  const { t } = useTranslation('shipmentsControl')
  const [taskModal, setTaskModal] = useState(false)
  const [totals, setTotals] = useState<TypeTotals>()

  const [drawerIsOpen, setDrawerIsOpen] = useState(false)
  const [selectedDealId, setSelectedDealId] = useState('')
  const [listRequestParams, setListRequestParams] = useState<ListRequestParams>({
    ...defaultListRequestParams,
  })

  const { isFiltersLoaded, updateFilterState } = useFilters({
    listRequestParams,
    setListRequestParams,
  })

  const [totalsProgress, fetchTotals] = useAProgress(
    async () => {
      const resp = await apiClient.get<TypeTotals>(endpoints.dispatchingTotals(), { ...listRequestParams.filter })
      setTotals(resp)
    },
    {
      eventName: 'getTotals',
    },
  )

  useEffect(() => {
    if (!isFiltersLoaded) return
    fetchTotals()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listRequestParams.filter, isFiltersLoaded])

  const columns = useColumns(setSelectedDealId, setDrawerIsOpen, totalsProgress, totals)

  return (
    <APageWrapper page="shipments" place="shipmentsList">
      <WrapperContent>
        <Header>
          <TopHeader>
            <Breadcrumbs routes={[{ breadcrumbName: t('title') }]} />
            <AButton id="add" intent="secondary" size="medium" onClick={() => setTaskModal(true)}>
              {t('common:setTask')}
            </AButton>
          </TopHeader>
          <PageName>{t('title')}</PageName>
          <Filters
            setListRequestParams={setListRequestParams}
            listRequestParams={listRequestParams}
            updateFilterState={updateFilterState}
          />
        </Header>
        <ATableFullPage
          listParamsUpdated={setListRequestParams}
          dataEndpoint={endpoints.dispatching()}
          listRequestParams={listRequestParams}
          isFiltersLoaded={isFiltersLoaded}
          columns={columns}
          rowHoverGray
        />
      </WrapperContent>
      <DispatchingDrawer
        refetch={() => setListRequestParams({ ...listRequestParams })}
        setDrawerIsOpen={setDrawerIsOpen}
        drawerIsOpen={drawerIsOpen}
        dealId={selectedDealId}
      />
      {taskModal && <TaskModal unclickableUserLink close={() => setTaskModal(false)} skipOnSuccessBeforeRedirect />}
    </APageWrapper>
  )
}
