import { defaultTheme } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

export const CommonWrapper = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  gap: 8px 0;
`

export const BorderedWrapper = styled(CommonWrapper)`
  border-radius: 8px;
  border: 1px solid;
  border-color: ${defaultTheme.color.secondary50};
  cursor: pointer;
  transition: border-color 0.3s ease;
`

export type SelectableWrapperProps = {
  selected?: boolean
}

export const SelectableWrapper = styled(BorderedWrapper).attrs<SelectableWrapperProps>((props) => ({
  className: props.selected ? 'selected' : '',
}))`
  &.selected,
  &.selected:hover {
    border-color: ${defaultTheme.color.secondaryHover};
  }
`
