import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { isNumber } from 'lodash'
import styled from 'styled-components'

import { BidChangesHistoryField, BidChangesHistoryRecord } from 'views/pages/Bid/BidDetail/Bid/types'
import { DatePeriodFormatted } from 'views/components/DatePeriodFormatted'
import { defaultTheme } from '@agro-club/agroclub-shared'

import formatPrice from 'modules/utils/numbers/formatPrice'
import { formatQuantity } from 'modules/utils/numbers/formatQuantity'

const Period = styled(DatePeriodFormatted)`
  color: ${defaultTheme.color.textSecondary60a};
`
export const HistoryChangesCell: FC<{ record: BidChangesHistoryRecord }> = ({ record }) => {
  const { t } = useTranslation('bid')

  const formatters: Record<BidChangesHistoryField, (v) => string> = {
    price: (v) => (isNumber(v) ? formatPrice(v, true) : ''),
    quantity: (v) => (isNumber(v) ? formatQuantity(v) : ''),
    status: (v) => (v ? t(`bidStatuses.${v}`) : ''),
  }

  const entries: string[] = Object.keys(formatters)
    .map((k) => formatters[k](record.changes[k]))
    .filter(Boolean)

  return (
    <>
      <div>{entries.join(', ')}</div>
      <Period period={record} />
    </>
  )
}
