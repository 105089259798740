import { ComponentProps, FC } from 'react'

import { AntDatePicker, defaultTheme } from '@agro-club/agroclub-shared'

import useLanguage from 'hooks/useLanguage'
import { useMediaQuery } from 'react-responsive'

interface Props extends Omit<ComponentProps<typeof AntDatePicker>, 'onChange' | 'locale'> {
  onChange: (customDate: object) => void
  size?: 'small' | 'middle' | 'large'
}

export const DateTimePicker: FC<Props> = ({ size, ...props }) => {
  const locale = useLanguage()

  const isMobile = useMediaQuery({
    query: `(max-width: ${defaultTheme.queries.mobile})`,
  })

  const manageSize = size || isMobile ? 'large' : 'middle'

  return <AntDatePicker {...props} showTime size={manageSize} locale={locale} fullWidth={isMobile} />
}
