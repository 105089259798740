import { defaultTheme } from '@agro-club/agroclub-shared'

import styled from 'styled-components'
import { ASimpleLink } from 'views/components/Analytics'

import { smallBox } from 'views/styled/common'

export const Item = styled.div`
  position: relative;
  overflow: hidden;
  ${smallBox};
  transition: background-color ease-in 0.2s;

  &:hover {
    background-color: ${defaultTheme.color.primary8a};
  }
`

export const ItemBody = styled.div`
  padding: 8px 12px 12px;
  display: grid;
  row-gap: 8px;
`

export const AItemLink = styled(ASimpleLink)`
  display: block;
  position: absolute;
  cursor: pointer;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0;
`
