const main = 'shipments-control'
const relativeRoutes = {
  relativePage: `${main}/*`,
  relativeList: `${main}`,
}

const routes = {
  List: `/${main}`,
}

const ShipmentsControlRoutes = { ...relativeRoutes, ...routes }

export default ShipmentsControlRoutes
