import useUpdateLocationQuery from 'hooks/useUpdateLocationQuery'
import { ListRequestParams } from 'modules/domain/types'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'

export interface FiltersProps {
  listRequestParams: ListRequestParams
  setListRequestParams: Dispatch<SetStateAction<ListRequestParams>>
  defaultParams?: object
}

export const useFilters = ({ listRequestParams, setListRequestParams, defaultParams }: FiltersProps) => {
  const isFiltersLoadedFromUrl = useUpdateLocationQuery({
    listRequestParams,
    setListRequestParams,
  })

  const [isFiltersLoaded, setIsFiltersLoaded] = useState(false)

  useEffect(() => {
    // This useEffect set default filters values
    // 1. Check if the filters are loaded from the URL
    if (!isFiltersLoadedFromUrl) return

    const isFilterEmpty = !Object.keys(listRequestParams.filter).length
    // 2. If we already have some filters or not default page is selected, we don't set default filters
    if (!isFilterEmpty || (isFilterEmpty && listRequestParams.page > 1)) {
      setIsFiltersLoaded(true)
      return
    }

    if (Object.keys(defaultParams || {}).length) {
      setListRequestParams({
        ...listRequestParams,
        filter: {
          ...listRequestParams.filter,
          ...defaultParams,
        },
      })
    } else {
      setIsFiltersLoaded(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFiltersLoadedFromUrl])

  useEffect(() => {
    if (isFiltersLoadedFromUrl && !isFiltersLoaded) {
      setIsFiltersLoaded(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listRequestParams])

  const updateFilterState = (filterObj: Record<string, any>) => {
    setListRequestParams((prev) => ({
      ...prev,
      page: 1,
      filter: {
        ...prev?.filter,
        ...filterObj,
      },
    }))
  }

  // deprecated in favor updateFilterState
  const handleChangeFilter = (value: any, filterName: string) => {
    setListRequestParams({
      ...listRequestParams,
      page: 1,
      filter: {
        ...listRequestParams?.filter,
        [filterName]: value,
      },
    })
  }

  return {
    /**
     * @deprecated in favor updateFilterState
     */
    handleChangeFilter,
    updateFilterState,
    isFiltersLoaded,
    /** @description needed for resetting, between tab switches, with independent filters */
    setIsFiltersLoaded,
  }
}
