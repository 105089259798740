import { FC } from 'react'
import { AntCollapse, Progress } from '@agro-club/agroclub-shared'
import { TripOfferPanel } from 'views/pages/LogisticsKR/components/TripOffers/TripOfferPanel'
import { useTranslation } from 'react-i18next'
import { refetchFunc } from 'modules/domain/common/hooks'
import { TripOffer, TripOfferStatuses } from 'views/pages/LogisticsKR/types'
import { LogisticRequest } from 'modules/domain/logisticsKR/types'

interface ResponsesProps {
  tripOffers: TripOffer[] | undefined
  activeRequest: LogisticRequest | undefined
  refetchDraftTrips: refetchFunc
  tripOffersRefetch: refetchFunc
  inProgressRequestRefetch: refetchFunc
  progress: Progress
}

export const TripOffers: FC<ResponsesProps> = ({
  tripOffers,
  tripOffersRefetch,
  refetchDraftTrips,
  inProgressRequestRefetch,
  activeRequest,
  progress,
}) => {
  const { t } = useTranslation('logisticBids')

  const newTripOffers = tripOffers?.filter((el) => el?.status === TripOfferStatuses.new)
  const rejectedTripOffers = tripOffers?.filter((el) => el?.status === TripOfferStatuses.closed)

  return (
    <>
      <AntCollapse bordered={false} noMarginTop accordion defaultActiveKey={[TripOfferStatuses.new]}>
        <TripOfferPanel
          activeRequest={activeRequest}
          title={t('board_columns.title.trip_offers')}
          key={TripOfferStatuses.new}
          progress={progress}
          tripOffers={newTripOffers}
          tripOffersRefetch={tripOffersRefetch}
          refetchDraftTrips={refetchDraftTrips}
          inProgressRequestRefetch={inProgressRequestRefetch}
        />
        <TripOfferPanel
          activeRequest={activeRequest}
          counterColor="grey"
          title={t('board_columns.title.rejected_trip_offers')}
          key={TripOfferStatuses.closed}
          progress={progress}
          tripOffers={rejectedTripOffers}
        />
      </AntCollapse>
    </>
  )
}
