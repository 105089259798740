import { ASharedModal, SharedModalContent, SharedModalInputs, SharedModalTitle } from '../../SharedModal'
import { DateISO, FormGroup, Radio, RadioItem } from '@agro-club/agroclub-shared'
import ADefaultModalFooter from '../../DefaultModalFooter/ADefaultModalFooter'
import { FormDatePicker } from '../../form/FormDatePicker'
import { refetchFunc } from 'modules/domain/common/hooks'
import DashboardRoutes from 'views/pages/Dashboard/routes'
import { useLocation, useNavigate } from 'react-router'
import { AButton } from 'views/components/Analytics'
import { apiClient } from 'modules/utils/httpClient'
import { useAFormHandler } from 'analytics/hooks'
import { Task } from 'modules/domain/task/types'
import { User } from 'modules/domain/user/types'
import { useTranslation } from 'react-i18next'
import { endpoints } from 'modules/endpoints'
import { InfoBoard } from './InfoBoard'
import styled from 'styled-components'
import { FormTags } from './FormTags'
import { useFormik } from 'formik'
import { FC, useState } from 'react'

type Props = {
  onClose: () => void
  refetch: () => void
  userRefetch?: refetchFunc
  task: Task
  user?: User
}

enum NextCallType {
  day = 'day',
  week = 'week',
  month = 'month',
  date = 'datetime',
}

type Fields = {
  call_status: boolean
  unsuccessful_reason: string
  next_call_type: NextCallType
  next_call_date: DateISO | undefined
  commChoice: boolean
}

const StyledFormGroup = styled(FormGroup)`
  align-items: center;
`

const DefaultUnsuccessfulReason = 'automatic_responder'

export const EndCallModal: FC<Props> = ({ onClose, refetch, task, user, userRefetch }) => {
  const { t } = useTranslation('my_tasks')
  const location = useLocation()
  const navigate = useNavigate()

  const [goToDashboard, setGoToDashboard] = useState(false)
  const isFromDashboard = !!location.state?.pathname && location.state?.pathname.startsWith(DashboardRoutes.Root)

  const { formProgress, formHandler } = useAFormHandler('balanceTasks')

  const formik = useFormik<Fields>({
    initialValues: {
      call_status: true,
      unsuccessful_reason: DefaultUnsuccessfulReason,
      next_call_type: NextCallType.day,
      next_call_date: undefined,
      commChoice: true,
    },

    onSubmit: formHandler(
      async () => {
        const id = task.linked_user?.id || user?.id
        if (!formik.values.commChoice && id) {
          await apiClient.post(endpoints.blackListUser(id), {
            do_not_call_reason: formik.values.unsuccessful_reason,
          })
          return
        }
        const payload = {
          call_status: formik.values.call_status,
          unsuccessful_reason: formik.values.call_status ? undefined : formik.values.unsuccessful_reason,
          next_call_type: formik.values.next_call_type,
          next_call_date: formik.values.next_call_type === NextCallType.date ? formik.values.next_call_date : undefined,
        }

        await apiClient.post(endpoints.completeTask(task.id), payload)
      },
      {
        onSuccess: () => {
          if (goToDashboard) {
            navigate({ pathname: location.state.pathname, search: location.state.search })
          } else {
            //we reload only when we block the user, so as not to constantly cause a request for the user
            !formik.values.commChoice && userRefetch?.()
            refetch()
            onClose()
          }
        },
        onError: (error) => {
          setGoToDashboard(false)
          const { errors } = error
          formik.setErrors(errors)
        },
      },
    ),
  })

  const badCall = formik.values.call_status === false

  const handleCallStatus = (v) => {
    formik.setFieldValue('call_status', v)
    formik.setFieldValue('commChoice', true)
    formik.setFieldValue('next_call_type', NextCallType.day)
    formik.setFieldValue('unsuccessful_reason', DefaultUnsuccessfulReason)
  }

  return (
    <ASharedModal id="endCall" size="medium" onClose={onClose}>
      <SharedModalTitle>{t('endCall')}</SharedModalTitle>
      <SharedModalContent>
        <SharedModalInputs>
          <StyledFormGroup label={t('callGo')} error={formik.errors.call_status}>
            <Radio onChange={handleCallStatus} value={formik.values.call_status}>
              <RadioItem value={true} label={t('successfully')} />
              <RadioItem value={false} label={t('notSuccessful')} />
            </Radio>
          </StyledFormGroup>

          {badCall && (
            <>
              <InfoBoard formik={formik} task={task} user={user} />
              <FormTags formik={formik} />
            </>
          )}

          {formik.values.commChoice && (
            <StyledFormGroup label={t('callBack')} error={formik.errors.next_call_type}>
              <Radio
                onChange={(value) => formik.setFieldValue('next_call_type', value)}
                value={formik.values.next_call_type}
              >
                <RadioItem value={NextCallType.day} label={t('day')} />
                <RadioItem value={NextCallType.week} label={t('week')} />
                <RadioItem value={NextCallType.month} label={t('month')} />
                <RadioItem value={NextCallType.date} label={t('date')} />
              </Radio>
            </StyledFormGroup>
          )}

          {formik.values.next_call_type === NextCallType.date && (
            <FormDatePicker label={t('enterDate')} fieldName="next_call_date" id="NextCallDate" formik={formik} />
          )}
        </SharedModalInputs>
      </SharedModalContent>
      <ADefaultModalFooter
        onConfirm={() => formik.submitForm()}
        confirmButtonText={t('common:save')}
        progress={formProgress}
        onClose={onClose}
      >
        {isFromDashboard && (
          <AButton
            id="SaveAndGoToDashboard"
            onClick={() => {
              setGoToDashboard(true)
              formik.submitForm()
            }}
            progress={formProgress}
            intent="white"
            size="big"
          >
            {t('task:form.saveAndGoToDashboard')}
          </AButton>
        )}
      </ADefaultModalFooter>
    </ASharedModal>
  )
}
