import { FC } from 'react'
import { FormGroup, Input, Radio, RadioItem } from '@agro-club/agroclub-shared'
import FormSelectShared from '../../../../components/FormSelectShared'
import { endpoints } from 'modules/endpoints'
import { FormItemWrapper, ShortFormControl } from 'views/styled/common'
import { useTranslation } from 'react-i18next'
import { CarrierType } from '../../types'
import { FormikFieldsType } from 'modules/domain/types'

type Props = {
  formik: FormikFieldsType
}

export const CarrierFormControls: FC<Props> = ({ formik }) => {
  const { t } = useTranslation('logisticsKR')
  return (
    <>
      <FormGroup error={formik.errors.contact_name} label={t('carriers.contact_name')}>
        <Input
          placeholder={t('common:fio')}
          {...formik.getFieldProps('contact_name')}
          invalid={!!formik.errors.contact_name}
        />
      </FormGroup>
      <FormSelectShared
        fieldName="working_regions"
        formik={formik}
        placeholder={t('carriers.placeholders.region')}
        endpoint={endpoints.provinces()}
        label={t('carriers.region')}
        multiple
        getLabel={(item) => item.name}
        simpleApiFormat
      />
      <FormSelectShared
        fieldName="favorite_regions"
        formik={formik}
        placeholder={t('carriers.placeholders.favorite_regions')}
        endpoint={endpoints.provinces()}
        label={t('carriers.favorite_regions')}
        multiple
        getLabel={(item) => item.name}
        simpleApiFormat
      />
      <FormGroup error={formik.errors.carrier_type} label={t('carriers.work_type')}>
        <FormItemWrapper>
          <Radio onChange={(value) => formik.setFieldValue('carrier_type', value)} value={formik.values.carrier_type}>
            <RadioItem value={CarrierType.local} label={t('carriers.local')} />
            <RadioItem value={CarrierType.global} label={t('carriers.global')} />
          </Radio>
        </FormItemWrapper>
      </FormGroup>
      <ShortFormControl>
        <FormGroup error={formik.errors.phone} label={t('common:phone')}>
          <Input
            type="tel"
            placeholder={t('common:phone_placeholder')}
            {...formik.getFieldProps('phone')}
            invalid={!!formik.errors.phone}
          />
        </FormGroup>
      </ShortFormControl>
      <FormGroup error={formik.errors.is_in_charter} label={t('carriers.is_in_charter')}>
        <FormItemWrapper>
          <Radio
            onChange={(value) => formik.setFieldValue('is_in_charter', !!value)}
            value={formik.values.is_in_charter ? 1 : 0}
          >
            <RadioItem value={1} label={t('common:yes')} />
            <RadioItem value={0} label={t('common:no')} />
          </Radio>
        </FormItemWrapper>
      </FormGroup>
      <FormGroup error={formik.errors.has_vat} label={t('carriers.has_vat')}>
        <FormItemWrapper>
          <Radio onChange={(value) => formik.setFieldValue('has_vat', !!value)} value={formik.values.has_vat ? 1 : 0}>
            <RadioItem value={1} label={t('common:yes')} />
            <RadioItem value={0} label={t('common:no')} />
          </Radio>
        </FormItemWrapper>
      </FormGroup>
    </>
  )
}
