import { useEffect, useState } from 'react'
import { format, startOfMonth } from 'date-fns'
import { SERVER_DATE_FORMAT } from 'modules/constants'
import * as managers from 'modules/domain/teams/managers'
import { getPlansByMembers } from 'views/pages/Teams/helpers'
import { Progress } from '@agro-club/agroclub-shared'
import { FilterWorksWith, TeamsPlansByMembers } from 'modules/domain/teams/types'

export const useTeamsTableData = (worksWith: FilterWorksWith): [Progress, TeamsPlansByMembers[]] => {
  const [progress, setProgress] = useState<Progress>(Progress.IDLE)
  const [teamsMembers, setTeamsMembers] = useState<TeamsPlansByMembers[]>([])

  useEffect(() => {
    const fetchListData = async () => {
      const startOfCurrentMonth = startOfMonth(new Date())
      const formatedCurrentMonth = format(startOfCurrentMonth, SERVER_DATE_FORMAT)
      const teamsMembersResponse = await managers.getMembers(worksWith)
      const plansResponse = await managers.getPlans(worksWith, formatedCurrentMonth)
      const { results: teamsMembers } = teamsMembersResponse
      const { results: plans } = plansResponse

      const membersList = getPlansByMembers(plans, teamsMembers)

      setTeamsMembers(membersList)
    }

    setProgress(Progress.WORK)
    fetchListData()
      .then(() => setProgress(Progress.SUCCESS))
      .catch(() => setProgress(Progress.ERROR))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return [progress, teamsMembers]
}
