import { Typography, defaultTheme } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

export const CardHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 12px 9px;
  border-bottom: 1px solid ${defaultTheme.color.secondary50};
  row-gap: 8px;
`

export const CardHeaderRow = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`

export const CardHeaderTags = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
`

export const CardHeaderInfo = styled.div`
  display: flex;
  flex-direction: column;
`

export const CardHeaderTitle = styled.div`
  ${Typography.accentLarge}
  color: ${defaultTheme.color.textPrimary900};
`

export const CardHeaderSubtitle = styled.div`
  ${Typography.bodyLarge}
  color: ${defaultTheme.color.textPrimary900};
`

export const Until = styled.span<{ $red?: boolean }>`
  color: ${({ $red }) => ($red ? defaultTheme.color.accentDestruct800 : defaultTheme.color.textPrimary900)};
`
