import { FC } from 'react'

import { CompanyRuAdd } from './CompanyDetail/CompanyGeneral/CompanyRuAdd'
import { AddWorldCompany } from './WorldModal/AddWorldCompany'
import { isRussia } from 'env'

type Props = {
  setAddCompanyModalOpen: (payload: boolean) => void
  refreshCompanies: () => void
}

export const AddCompanyControl: FC<Props> = ({ setAddCompanyModalOpen, refreshCompanies }) => {
  if (isRussia) {
    return <CompanyRuAdd onClose={() => setAddCompanyModalOpen(false)} refreshCompanies={refreshCompanies} />
  }

  return <AddWorldCompany onClose={() => setAddCompanyModalOpen(false)} refreshCompanies={refreshCompanies} />
}
