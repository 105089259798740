import { Icons } from '@agro-club/agroclub-shared'
import { BidTypes } from 'modules/domain/bid/types'
import { getOppositeBidType } from 'modules/domain/bid/utils'
import { refetchFunc } from 'modules/domain/common/hooks'
import { Deal } from 'modules/domain/deal/types'
import { getDealBidAndContract } from 'modules/domain/deal/utils'
import { FC } from 'react'
import { generatePath } from 'react-router'
import { AButton } from 'views/components/Analytics'
import BidRoutes from 'views/pages/Bid/routes'
import BidContractsRoutes from 'views/pages/BidContracts/routes'
import { DealPartyChangeData } from '../../../hooks/useDealPartyChange'

import { isRussia } from 'env'
import { useTranslation } from 'react-i18next'
import AMobileButton from 'views/components/Analytics/AMobileButton'
import { useDealContext } from '../../DealContext'
import { WarehouseMovements } from '../WarehouseMovements'
import { AddBidContractAction } from './AddBidContractAction'
import { ChangeDealPartyAction } from './ChangeDealPartyAction'
import { EditBidContractAction } from './EditBidContractAction'

type BidDetailActionsProps = {
  deal: Deal
  bidType: BidTypes
  dealRefetch: refetchFunc
  partyChanger: DealPartyChangeData
  isMobile: boolean
}

export const BidDetailActions: FC<BidDetailActionsProps> = ({ dealRefetch, deal, bidType, partyChanger, isMobile }) => {
  const { t } = useTranslation('deal')
  const { bid, contract } = getDealBidAndContract(deal, bidType)
  const { whMovements } = useDealContext()
  const bidContractLink = contract
    ? generatePath(BidContractsRoutes.Details, { id: contract.id })
    : generatePath(BidRoutes.Details, { id: bid?.id })

  const ourPartyChanging = partyChanger.bidType === bidType
  const otherPartyChanging = partyChanger.bidType === getOppositeBidType(bidType)

  const movementsOnSuccess = () => {
    dealRefetch()
    whMovements.refetch()
  }

  if (ourPartyChanging) {
    return <ChangeDealPartyAction partyChanger={partyChanger} type="stop" />
  }

  return (
    <>
      <AddBidContractAction deal={deal} bidType={bidType} refetch={dealRefetch} disabled={otherPartyChanging} />
      <ChangeDealPartyAction bidType={bidType} partyChanger={partyChanger} type="start" />
      <EditBidContractAction deal={deal} bidType={bidType} refetch={dealRefetch} disabled={otherPartyChanging} />
      {isRussia && bidType === BidTypes.purchase && (
        <WarehouseMovements isMobile={isMobile} refetch={movementsOnSuccess} dealId={deal.id} />
      )}
      {isMobile ? (
        <AMobileButton target="_blank" id="link" intent="white" Icon={Icons.IconOpen} to={bidContractLink}>
          {t('goToBid')}
        </AMobileButton>
      ) : (
        <AButton target="_blank" id="link" intent="white" Icon={Icons.IconOpen} to={bidContractLink} />
      )}
    </>
  )
}
