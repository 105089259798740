import { FC } from 'react'

import { Route, Routes } from 'react-router-dom'
import WarehousesRoutes from 'views/pages/Warehouses/routes'
import { WarehousesList } from './List/WarehousesList'
import { WarehouseDetail } from './WarehouseDetail/WarehouseDetail'

const WarehousesPage: FC = () => {
  return (
    <Routes>
      <Route index element={<WarehousesList />} />
      <Route path={WarehousesRoutes.relativeListWithTab} element={<WarehousesList />} />
      <Route path={WarehousesRoutes.relativeDetail} element={<WarehouseDetail />} />
    </Routes>
  )
}

export default WarehousesPage
