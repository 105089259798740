import { FC } from 'react'
import { AntTag } from '@agro-club/agroclub-shared'
import { useTranslation } from 'react-i18next'

interface Props {
  status: string
}

export const DealStatusLabel: FC<Props> = ({ status }) => {
  const { t } = useTranslation('deal')

  return <AntTag color="white">{t(`statuses.${status}`)}</AntTag>
}
