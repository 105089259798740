import { FC, useState } from 'react'
import { BorderedItem } from 'views/components/BorderedItem/BorderedItem'
import { ButtonsWrapper } from '../styled'
import { ExternalLink, Icons } from '@agro-club/agroclub-shared'
import { EditSellerSpecification } from './SellerSpecificationModal/EditSellerSpecification'
import { SellerSpec } from 'modules/domain/deal/types'
import formatPrice from '../../../../../../modules/utils/numbers/formatPrice'
import { useTranslation } from 'react-i18next'
import { AButton } from 'views/components/Analytics'
import { refetchFunc } from 'modules/domain/common/hooks'

type Props = {
  specification: SellerSpec[]
  refetch: refetchFunc
}

export const SpecificationList: FC<Props> = ({ specification, refetch }) => {
  const { t } = useTranslation()
  const [editModal, setEditModal] = useState(false)

  return (
    <div>
      <BorderedItem>
        <div>{t('common:price:price', { price: formatPrice(specification[0].price, true) })}</div>
        <ButtonsWrapper>
          <AButton id="editSpecification" intent="white" Icon={Icons.IconEdit} onClick={() => setEditModal(true)} />
        </ButtonsWrapper>
      </BorderedItem>
      {editModal && (
        <EditSellerSpecification onClose={setEditModal} onSuccess={refetch} specification={specification} />
      )}
      {specification &&
        specification[0]?.files?.map((file) => (
          <BorderedItem key={file.id}>
            <ExternalLink href={file.uploaded_file} target="_blank">
              {file.original_filename}
            </ExternalLink>
          </BorderedItem>
        ))}
    </div>
  )
}
