import { HistoryChangesCell } from './HistoryChangesCell'
import { HistoryType } from 'modules/domain/export/types'
import { ColumnsType } from '@agro-club/agroclub-shared'
import { date } from 'views/components/Table/renderers'
import { useTranslation } from 'react-i18next'
import { DASH } from 'modules/constants'
import { useMemo } from 'react'

export const useHistoryColumns = (): ColumnsType<HistoryType> => {
  const { t } = useTranslation('export')

  return useMemo(
    () => [
      {
        title: t('when'),
        width: 102,
        dataIndex: 'date',
        align: 'left',
        render: date,
      },
      {
        title: t('who'),
        width: 220,
        dataIndex: 'user',
        render: (user) => user.short_name || DASH,
      },
      {
        title: t('changes'),
        autoWidth: 210,
        dataIndex: 'changes',
        render: (_, record) => <HistoryChangesCell record={record} />,
      },
    ],
    [t],
  )
}
