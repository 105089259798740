import { CalculatorControls, CalculatorTitleColumn, CalculatorRowWrapper } from './styled'
import { BorderedItem } from 'views/components/BorderedItem/BorderedItem'
import { FC, ReactNode } from 'react'

type Props = {
  title: ReactNode
  children?: React.ReactNode
}

export const CalculatorRow: FC<Props> = ({ title, children }) => {
  return (
    <BorderedItem padding={false} hover={false}>
      <CalculatorRowWrapper>
        <CalculatorTitleColumn>{title}</CalculatorTitleColumn>
        <CalculatorControls>{children}</CalculatorControls>
      </CalculatorRowWrapper>
    </BorderedItem>
  )
}
