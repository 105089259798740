import styled from 'styled-components'
import { CustomScroll } from './common'

export const TwoColumns = styled.div`
  display: grid;
  grid-template-columns: 1038px 566px;
  grid-gap: 24px;
  overflow-y: auto;
  height: 100%;
  ${CustomScroll}
`

export const ButtonsWrapper = styled.div<{ marginTop?: number }>`
  display: grid;
  grid-gap: 16px;
  grid-auto-flow: column;
  justify-content: start;
`

export const ModalControls = styled(ButtonsWrapper)`
  margin-bottom: 24px;
`
