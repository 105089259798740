import { useTranslation } from 'react-i18next'
import APageWrapper from 'views/components/PageWrapper/APageWrapper'
import { AnalyticsPlaces, AnalyticsPages } from 'views/pages/Contracts/analyticsPlaces'
import { useHelmet } from '@agro-club/agroclub-shared'
import * as Layout from 'views/layouts/NewLayout'
import * as Header from 'views/ui/Header/Header'
import { useState } from 'react'
import { ListRequestParams } from 'modules/domain/types'
import { defaultListRequestParams } from 'views/components/TableFilters/data'
import { useFilters } from 'hooks/useFilter'
import { useColumns } from './useColumns'
import { ContractFilters } from './ContractFilters'
import { endpoints } from 'modules/endpoints'
import ATableFullPage from 'views/components/Table/ATableFullPage'

export const ContractsList = () => {
  const { t } = useTranslation('contracts')
  useHelmet({ title: t('metaTitle') })
  const [listRequestParams, setListRequestParams] = useState<ListRequestParams>(defaultListRequestParams)
  const { isFiltersLoaded, updateFilterState } = useFilters({
    listRequestParams,
    setListRequestParams,
  })

  const columns = useColumns(listRequestParams.sort)

  return (
    <APageWrapper page={AnalyticsPages.CONTRACTS} place={AnalyticsPlaces.LIST}>
      <Layout.WrapperContent>
        <Layout.Header>
          <Layout.TopHeader>
            <Header.Breadcrumbs
              routes={[{ breadcrumbName: t('menu:documents') }, { breadcrumbName: t('metaTitle') }]}
            />
          </Layout.TopHeader>
          <Layout.PageName>{t('metaTitle')}</Layout.PageName>
          <ContractFilters
            listRequestParams={listRequestParams}
            updateFilterState={updateFilterState}
            setListRequestParams={setListRequestParams}
          />
        </Layout.Header>
        <ATableFullPage
          columns={columns}
          dataEndpoint={endpoints.dealsContracts()}
          listParamsUpdated={setListRequestParams}
          listRequestParams={listRequestParams}
          isFiltersLoaded={isFiltersLoaded}
          rowHoverGray
        />
      </Layout.WrapperContent>
    </APageWrapper>
  )
}
