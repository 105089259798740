import { ListRequestParams } from 'modules/domain/types'
import { ColumnData } from 'modules/types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { convertSortingToAntDFormat } from 'modules/domain/common/tableUtils'
import useFormatDate from 'hooks/useFormatDate'
import { TableTextLink } from 'views/ui/TableTextLink/TableTextLink'
import { generatePath } from 'react-router'
import { ContractsRoutes } from 'views/pages/Contracts/routes'
import { AntTag } from '@agro-club/agroclub-shared'
import UserRoutes from 'views/pages/User/routes'

const statusToColor = {
  new: 'orange',
  in_progress: 'green',
  complete: 'grey',
}

export const useColumns = (sortParam: ListRequestParams['sort']): ColumnData[] => {
  const { t } = useTranslation('contracts')
  const formatDate = useFormatDate()

  return useMemo(
    () => [
      {
        title: t('table.contract'),
        width: 120,
        dataIndex: 'id',
        sorter: true,
        sortOrder: convertSortingToAntDFormat('id', sortParam),
        render: (id) => (
          <TableTextLink id="contract" tableItemId={id} to={generatePath(ContractsRoutes.Details, { id: id })}>
            ID {id}
          </TableTextLink>
        ),
      },
      {
        title: t('table.status'),
        width: 120,
        dataIndex: 'status',
        render: (status) => <AntTag color={statusToColor[status]}>{t(`table.statuses.${status}`)}</AntTag>,
      },
      {
        title: t('table.date'),
        width: 120,
        dataIndex: 'created',
        sorter: true,
        sortOrder: convertSortingToAntDFormat('created', sortParam),
        render: (created) => formatDate(created),
      },
      {
        title: t('table.deal_id'),
        width: 120,
        dataIndex: 'deal_id',
      },
      {
        title: t('table.product'),
        width: 200,
        dataIndex: 'product',
        render: (product) => product.title,
      },
      {
        title: t('table.seller_company'),
        autoWidth: 400,
        dataIndex: 'seller_company',
        render: (_, contract) => contract.seller_company?.name,
      },
      {
        title: t('table.createdBy'),
        width: 300,
        dataIndex: 'created_by',
        render: (created_by) => (
          <TableTextLink
            id="contract"
            tableItemId={created_by.id}
            to={generatePath(UserRoutes.Details, { id: created_by.id })}
          >
            {created_by.full_name}
          </TableTextLink>
        ),
      },
      {
        title: t('table.team'),
        width: 124,
        dataIndex: 'team',
        render: (team) => team?.name,
      },
      {
        title: t('table.assignee'),
        width: 224,
        dataIndex: 'assignee',
        render: (_, contract) => contract.assignee?.full_name,
      },
    ],
    [formatDate, sortParam, t],
  )
}
