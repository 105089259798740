export enum AnalyticsPages {
  DISPACHING = 'dispatchings',
}

export const AnalyticsPlaces = {
  LIST: 'DispatchingsList',
  DISPATCHING_MODAL: 'DispatchingModal',
  EDIT_LOGISTIAN_MODAL: 'EditLogistianModal',
  DRAWER_WITH_COMMENTS: 'drawer_with_comments',
}
