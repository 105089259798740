import _ from 'lodash'
import { FormGroup, Input, OptionType, Select } from '@agro-club/agroclub-shared'
import { FC, useMemo } from 'react'
import { InputsRow, FirstInRowControl, PaymenTab } from './styled'
import { Bank, DispatchingItemFormik, PaymentSources } from '../../types'
import { FormikProps } from 'formik'
import { EnumSelect } from 'views/components/form/EnumSelect/EnumSelect'
import { PaymentStatuses } from './PaymentStatuses'
import { HrWithHeader } from 'views/components/Hr/Hr'
import { useTranslation } from 'react-i18next'
import { LoanAgreementControls } from './LoanAgreementControls'

interface Props {
  formik: FormikProps<DispatchingItemFormik>
  dateString: string
  banks: Bank[]
}

export const PaymentData: FC<Props> = ({ formik, dateString, banks }) => {
  const { t } = useTranslation('dispatching')
  const banksOptions: OptionType[] = useMemo(
    () =>
      banks.map((bank) => ({
        value: String(bank.id),
        label: bank.name,
      })),
    [banks],
  )
  const bankId = formik.values.payments[dateString].bank

  const loanFieldName = `payments.${dateString}.loan_agreement`
  const firstRowError = [_.get(formik.errors, `payments.${dateString}.bank`), _.get(formik.errors, loanFieldName)]
    .filter(Boolean)
    .join(', ')

  const secondRowError = [
    _.get(formik.errors, `payments.${dateString}.source`),
    _.get(formik.errors, `payments.${dateString}.amount`),
  ]
    .filter(Boolean)
    .join(', ')
  return (
    <PaymenTab>
      <FormGroup error={firstRowError}>
        <InputsRow>
          <FirstInRowControl>
            <Select
              onChange={(bank) => {
                formik.setFieldValue(`payments.${dateString}.bank`, bank)
                formik.setFieldValue(`payments.${dateString}.loan_agreement`, null)
              }}
              placeholder={t('modal.inputs.bank_placeholder')}
              value={bankId}
              options={banksOptions}
              showSearch={false}
              fullWidth
              allowClear={false}
            />
          </FirstInRowControl>
          <LoanAgreementControls
            formik={formik}
            bankId={bankId}
            value={_.get(formik.values, loanFieldName)}
            fieldName={loanFieldName}
          />
        </InputsRow>
      </FormGroup>
      <FormGroup error={secondRowError}>
        <InputsRow>
          <FirstInRowControl>
            <EnumSelect
              formik={formik}
              fieldName={`payments.${dateString}.source`}
              enumType={PaymentSources}
              translationNameSpace="dispatching:paymentSources"
              placeholder={t('modal.inputs.source_placeholder')}
            />
          </FirstInRowControl>
          <Input
            type="number"
            {...formik.getFieldProps(`payments.${dateString}.amount`)}
            placeholder={t('modal.inputs.amount_placeholder')}
            invalid={!!_.get(formik.errors, `payments.${dateString}.amount`)}
          />
        </InputsRow>
      </FormGroup>
      <HrWithHeader text={t('modal.paymentStatus')} />
      <PaymentStatuses formik={formik} fieldName={`payments.${dateString}.status`} />
    </PaymenTab>
  )
}
