import { useCallback } from 'react'

import { isValid, parseISO } from 'date-fns'

import useLanguage from 'hooks/useLanguage'

export default function useFormatDate() {
  const locale = useLanguage()
  const clientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

  return useCallback(
    (value?: string | null, withTime?: boolean) => {
      if (!value) {
        return ''
      }
      const options: Intl.DateTimeFormatOptions | undefined = withTime
        ? { dateStyle: 'short', timeStyle: 'short', timeZone: clientTimeZone }
        : undefined
      const formatter = new Intl.DateTimeFormat(locale, options)
      const parsed = parseISO(value)
      return isValid(parsed) ? formatter.format(parsed) : ''
    },
    [locale, clientTimeZone],
  )
}
