import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { defaultTheme, useHelmet } from '@agro-club/agroclub-shared'
import useFormatDate from 'hooks/useFormatDate'
import * as Layout from 'views/layouts/NewLayout'
import { DealAccordion } from 'views/pages/Deal/DealDetail/DealAccordion/DealAccordion'
import * as Header from 'views/ui/Header/Header'
import { DealDetailContent } from 'views/pages/Deal/DealDetail/DealDetailContent'
import { useSingleEntity } from 'modules/domain/common/hooks'
import { endpoints } from 'modules/endpoints'
import DealRoutes from 'views/pages/Deal/routes'
import { Declaration } from 'modules/domain/types'
import APageWrapper from 'views/components/PageWrapper/APageWrapper'
import { AnalyticsPages, AnalyticsPlaces } from '../analyticsPlaces'
import { DealHeaderTags } from './DealHeaderTags'
import { useDealContext } from './DealContext'
import { DealManagement } from './DealManagement/DealManagement'
import { useMediaQuery } from 'react-responsive'
import { DetailMobile } from './Mobile/DetailMobile'

export const DealDetailWithProvider: FC = () => {
  const { t } = useTranslation('deal')
  const formatDate = useFormatDate()
  const { deal } = useDealContext()

  const [declarationProgress, declaration, declarationRefetch] = useSingleEntity<Declaration | undefined>(
    endpoints.dealDeclaration(deal.id),
  )

  useHelmet({ title: `${t('common:deal')} - ${deal?.product?.title}` })

  const isMobile = useMediaQuery({
    query: `(max-width: ${defaultTheme.queries.mobile})`,
  })

  return (
    <APageWrapper page={AnalyticsPages.DEAL_DETAIL} place={AnalyticsPlaces.DETAIL} id={deal.id}>
      {!isMobile ? (
        <Layout.WrapperContent>
          <Layout.Header>
            <Layout.TopHeader>
              <Header.Breadcrumbs
                routes={[
                  {
                    path: DealRoutes.List,
                    breadcrumbName: t('title'),
                  },
                  {
                    breadcrumbName: `${t('common:deal')} ID ${deal.id}`,
                  },
                ]}
              />
              <DealManagement />
            </Layout.TopHeader>

            <DealHeaderTags deal={deal} />

            <Layout.DetailPageSubTitle>
              {t('common:detailPageSubTitle', { id: deal.id, created: formatDate(deal.created, true) })}
            </Layout.DetailPageSubTitle>
          </Layout.Header>

          <Layout.ContentTwoColumns>
            <DealDetailContent declaration={declaration} />

            <DealAccordion
              declaration={declaration}
              declarationRefetch={declarationRefetch}
              declarationProgress={declarationProgress}
            />
          </Layout.ContentTwoColumns>
        </Layout.WrapperContent>
      ) : (
        <DetailMobile declaration={declaration} />
      )}
    </APageWrapper>
  )
}
