import { AntSkeleton } from '@agro-club/agroclub-shared'
import { FC } from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px 0;
`

const Item = styled.div`
  display: flex;
  align-items: center;
  column-gap: 29px;
`

export const FormSkeleton: FC = () => {
  const labelSkeleton = <AntSkeleton.Button active size="small" style={{ width: 183, borderRadius: 4 }} />
  const fieldSkeleton = <AntSkeleton.Button active size="small" style={{ width: 168, height: 40, borderRadius: 4 }} />

  return (
    <Wrapper>
      <Item>
        {labelSkeleton}
        {fieldSkeleton}
      </Item>

      <Item>
        {labelSkeleton}
        {fieldSkeleton}
      </Item>

      <Item>
        {labelSkeleton}
        {fieldSkeleton}
      </Item>

      <Item>
        {labelSkeleton}
        {fieldSkeleton}
      </Item>

      <Item>
        {labelSkeleton}
        {fieldSkeleton}
      </Item>
    </Wrapper>
  )
}
