import { antdGlobalStyle, defaultTheme } from '@agro-club/agroclub-shared'
import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  ${antdGlobalStyle}

  body {
    font-family: "TT Agro Club", 'Montserrat', -apple-system, BlinkMacSystemFont, sans-serif;
    background-color: ${defaultTheme.color.backgroundPrimary};

    //Remove blue light when touching on mobile
    //-webkit-tap-highlight-color - deprecated but no other solution was found
    -webkit-tap-highlight-color: transparent !important;
    
    #chatra.chatra--pos-right {
      bottom: 10px;
      right: 100px;
    }
    @media (max-width: ${defaultTheme.queries.mobile}) {
      #chatra{
        display: none;
      }
    }
  }


  .rc-tooltip {
    z-index: unset;
  }

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: ${defaultTheme.color.secondary16a};
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${defaultTheme.color.secondaryPlaceholder3};
    border-radius: 10px;
  }
`
export default GlobalStyle
