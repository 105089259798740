import { defaultTheme, Typography } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

export const WrapperIcons = styled.div`
  display: flex;
  margin-left: 8px;
  gap: 0 8px;
`

export const Rating = styled.div`
  ${Typography.bodyLarge}
  display: flex;
  align-items: center;
  column-gap: 4px;
  padding: 0 0 0 8px;
  color: ${defaultTheme.color.textSecondary60a};
  border-left: 1px solid ${defaultTheme.color.secondary24a};

  svg {
    width: 24px;
    height: 24px;
    fill: ${defaultTheme.color.accentNotify600};
    path {
      fill: ${defaultTheme.color.accentNotify600};
    }
  }
`
