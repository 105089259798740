import { FC } from 'react'
import { endpoints } from 'modules/endpoints'
import { RespFile } from 'modules/domain/types'
import AUploadFiles from 'views/components/UploadFiles/AUploadFiles'
import { apiClient } from 'modules/utils/httpClient'
import { uploadFile } from 'modules/domain/common/managers'
import { useSingleEntity } from 'modules/domain/common/hooks'
import { Card } from 'views/components/Card/Card'
import { useTranslation } from 'react-i18next'

type Props = {
  id: string
}

export const BidFiles: FC<Props> = ({ id }) => {
  const [progress, bidFiles, bidFilesRefetch] = useSingleEntity<RespFile[]>(endpoints.bidFiles(id))
  const { t } = useTranslation('bid')
  const removeHandler = async (bidId: string, fileId: string) => {
    await apiClient.delete(endpoints.deleteBidFiles(bidId, fileId))
    bidFilesRefetch(true)
  }

  const addHandler = async (bidId: string, file: File) => {
    await uploadFile(endpoints.bidFiles(bidId), file)
    bidFilesRefetch(true)
  }
  return (
    <Card.GapableContent>
      <Card.Title>{t('documents')}</Card.Title>
      <AUploadFiles
        progress={progress}
        removeFile={(fileId) => removeHandler(id, fileId)}
        addFile={({ file }) => addHandler(id, file)}
        fileList={bidFiles}
      />
    </Card.GapableContent>
  )
}
