export enum AnalyticsPages {
  TASKS = 'tasks',
  TASK_DETAIL = 'taskDetail',
}

export const AnalyticsPlaces = {
  LIST: 'TasksList',
  DETAIL: 'TaskDetail',
  ADD_MODAL: 'AddTaskModal',
}
