import { DebouncedInputFilter } from '@agro-club/agroclub-shared'
import { useAnalyticsChangeHandler } from 'analytics/hooks'
import { AnalyticsFilterVariant } from 'analytics/types'
import { REACT_APP_LOCALE } from 'env'
import { FC } from 'react'

type Props = {
  onChange: (value: any) => void
  id: AnalyticsFilterVariant
  type?: 'number' | 'text'
  value?: string | number
  placeholder: string
  disabled?: boolean
  suffix?: string
  prefix?: string
}

export const InputFilter: FC<Props> = ({ onChange, value, id, ...props }) => {
  const analyticsHandleChange = useAnalyticsChangeHandler(id)

  return (
    <DebouncedInputFilter
      onChange={analyticsHandleChange(onChange, { value: { from: value } })}
      locale={REACT_APP_LOCALE}
      value={value}
      {...props}
    />
  )
}
