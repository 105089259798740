import { ListRequestParams } from 'modules/domain/types'
import { ColumnData } from 'modules/types'
import ATableFullPage from 'views/components/Table/ATableFullPage'
import { FC, useMemo } from 'react'
import { Bid, UIBidTypes } from 'modules/domain/bid/types'
import { isBidWithPricePeriods } from 'modules/domain/bid/utils'
import NestedPricePeriods from './NestedPricePeriods'
import { isFuturesEnabled, isUS } from 'env'
import { updateSortOrder } from 'modules/domain/common/tableUtils'

interface Props {
  bidType: `${UIBidTypes}`
  listRequestParams: ListRequestParams
  setListRequestParams: (params: ListRequestParams) => void
  isFiltersLoaded: boolean
  dataEndpoint: string
  columnsFunc: (sort?: string) => ColumnData[]
}

const BidTable: FC<Props> = ({
  bidType,
  isFiltersLoaded,
  dataEndpoint,
  listRequestParams,
  setListRequestParams,
  columnsFunc,
}) => {
  const columns = columnsFunc()
  const sorted = useMemo(() => updateSortOrder(columns, listRequestParams.sort), [columns, listRequestParams.sort])
  const expandable = useMemo(() => {
    if (bidType === UIBidTypes.potential || !isFuturesEnabled || isUS) {
      return
    }
    return {
      showExpandColumn: true,
      rowExpandable: (row: Bid) => isBidWithPricePeriods(row),
      expandedRowRender: (row: Bid) => <NestedPricePeriods bid={row} />,
    }
  }, [bidType])
  return (
    <ATableFullPage
      columns={sorted}
      dataEndpoint={dataEndpoint}
      listParamsUpdated={setListRequestParams}
      listRequestParams={listRequestParams}
      isFiltersLoaded={isFiltersLoaded}
      expandable={expandable}
      rowHoverGray
    />
  )
}

export default BidTable
