import { FC, useState } from 'react'
import { Icons } from '@agro-club/agroclub-shared'
import { useTranslation } from 'react-i18next'
import { CarStatuses, LogisticsKRCar } from 'views/pages/LogisticsKR/types'
import { LogisticRequest } from 'modules/domain/logisticsKR/types'
import { AButton } from 'views/components/Analytics'
import { AddDraftTripModal } from 'views/pages/LogisticsKR/components/AddDraftTripModal/AddDraftTripModal'
import { EditCarBeforeTrip } from './EditCarBeforeTrip'
import { refetchFunc } from 'modules/domain/common/hooks'

type CardProps = {
  car: LogisticsKRCar
  request: LogisticRequest | undefined
  toTripShort?: boolean
  onTripCreated: refetchFunc
  refetchCars: refetchFunc
}

export const CarForRequestActions: FC<CardProps> = ({
  car,
  toTripShort = true,
  request,
  onTripCreated,
  refetchCars,
}) => {
  const { t } = useTranslation('logisticsKR')
  const [carToTripModal, setCarToTripModal] = useState(false)
  const [editCarModal, setEditCarModal] = useState(false)

  const handleToTripClick = () => {
    if (car.status === CarStatuses.draft) {
      setEditCarModal(true)
    } else {
      setCarToTripModal(true)
    }
  }
  let cardButtonsJsx
  if (toTripShort) {
    cardButtonsJsx = <AButton id="carToTrip" intent="white" Icon={Icons.IconShippingDone} onClick={handleToTripClick} />
  } else {
    cardButtonsJsx = (
      <AButton id="carToTrip" intent="secondary" Icon={Icons.IconShippingDone} size="small" onClick={handleToTripClick}>
        {t('cars.addToTrip')}
      </AButton>
    )
  }

  const handleCarIsEdited = async () => {
    setCarToTripModal(true)
    refetchCars(true)
  }
  return (
    <>
      {cardButtonsJsx}

      {editCarModal && (
        <EditCarBeforeTrip car={car} onClose={() => setEditCarModal(false)} onSuccess={handleCarIsEdited} />
      )}
      {carToTripModal && (
        <AddDraftTripModal
          carId={car.id}
          carrier={car.carrier}
          currTrailerId={car?.current_trailer?.id}
          onClose={() => setCarToTripModal(false)}
          onSuccess={onTripCreated}
          activeRequest={request}
        />
      )}
    </>
  )
}
