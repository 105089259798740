import { useSingleEntity } from 'modules/domain/common/hooks'
import { ShipLoad } from 'modules/domain/deal/types'
import { endpoints } from 'modules/endpoints'
import { useParams } from 'react-router-dom'
import { FC, useState } from 'react'
import { Loading } from './Loading'
import { AddShipLoadModal } from './Modals/AddShipLoadModal'
import { useTranslation } from 'react-i18next'
import { useShipLoadColumns } from './useColumns'

type Props = {
  setSummary: (summary: number) => void
}

export const ShipLoading: FC<Props> = ({ setSummary }) => {
  const { t } = useTranslation('deal')
  const { id } = useParams()
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [progress, items = [], refetch] = useSingleEntity<ShipLoad[]>(endpoints.dealShipLoading(id))
  const columns = useShipLoadColumns(refetch)

  return (
    <>
      <Loading
        setLoadSummary={setSummary}
        setModalIsOpen={setModalIsOpen}
        loadItems={items}
        columns={columns}
        progress={progress}
        noDataText={t('accordion.loading.loading_not_found')}
        addEventId="addShipLoading"
      />
      {modalIsOpen && <AddShipLoadModal dealId={id} onClose={() => setModalIsOpen(false)} refetch={refetch} />}
    </>
  )
}
