import { FormItemWrapper } from 'views/styled/common'
import { FormGroup, Radio, RadioItem, Select, defaultTheme } from '@agro-club/agroclub-shared'
import { useTranslation } from 'react-i18next'
import { FormikProps } from 'formik'
import { FC } from 'react'
import { CloningFormFields, CloningTypes } from '../../types'
import { useMediaQuery } from 'react-responsive'

interface Props {
  formik: FormikProps<CloningFormFields>
  setCurrentCloningType: (value: CloningTypes) => void
}

export const CloningTypeRadio: FC<Props> = ({ formik, setCurrentCloningType }) => {
  const { t } = useTranslation('deal')

  const changeHandler = (value) => {
    formik.setFieldValue('cloning_type', value)
    formik.setFieldValue('user', '')
    formik.setFieldValue('new_bid', '')
    formik.setFieldValue('distance', 0)
    formik.setFieldValue('logistics_rate', 0)
    formik.setFieldValue('price_logistics', 0)
    setCurrentCloningType(value)
  }

  const isMobile = useMediaQuery({
    query: `(max-width: ${defaultTheme.queries.mobile})`,
  })
  const options = [
    { value: CloningTypes.customer_change, label: t('cloningTypes.customer') },
    { value: CloningTypes.seller_change, label: t('cloningTypes.seller') },
    { value: CloningTypes.quantity_split, label: t('cloningTypes.quantity_split') },
  ]
  return (
    <FormGroup label={t('bidCloningModal.labels.cloning_type')}>
      {isMobile ? (
        <Select
          value={formik.values.cloning_type}
          onChange={changeHandler}
          options={options}
          showSearch={false}
          allowClear={false}
          fullWidth
        />
      ) : (
        <FormItemWrapper>
          <Radio onChange={changeHandler} value={formik.values.cloning_type}>
            <RadioItem value={CloningTypes.customer_change} label={t('cloningTypes.customer')} title="" />
            <RadioItem value={CloningTypes.seller_change} label={t('cloningTypes.seller')} title="" />
            <RadioItem value={CloningTypes.quantity_split} label={t('cloningTypes.quantity_split')} title="" />
          </Radio>
        </FormItemWrapper>
      )}
    </FormGroup>
  )
}
