import styled from 'styled-components'
import { Card } from 'views/components/Card/Card'
import { useTranslation } from 'react-i18next'
import { DocumentControl } from 'views/pages/Contracts/ContractDetails/GeneralInfo/DocumentControl'
import { DealContract } from 'modules/domain/deal/types'
import { refetchFunc } from 'modules/domain/common/hooks'

const GapWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
`

type Props = {
  contract: DealContract
  refetch: refetchFunc
}

export const StagesProcessing = ({ contract, refetch }: Props) => {
  const { t } = useTranslation('contracts')

  return (
    <Card.Container intent="primary" bordered>
      <Card.GapableContent>
        <Card.Title>{t('generalInfo.stages_processing')}</Card.Title>
        <GapWrapper>
          <DocumentControl
            eventId="farmer_documents_received"
            label={t('generalInfo.farmer_documents_received')}
            field="farmer_documents_received"
            onSuccess={refetch}
            contract={contract}
          />
          <DocumentControl
            eventId="approved_by_lawyers"
            label={t('generalInfo.approved_by_lawyers')}
            field="approved_by_lawyers"
            onSuccess={refetch}
            contract={contract}
          />
          <DocumentControl
            eventId="signed_by_agroclub"
            label={t('generalInfo.signed_by_agroclub')}
            field="signed_by_agroclub"
            onSuccess={refetch}
            contract={contract}
          />
        </GapWrapper>
      </Card.GapableContent>
    </Card.Container>
  )
}
