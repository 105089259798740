import { AvailableLanguages } from '@agro-club/agroclub-shared'
import { REACT_APP_LOCALE } from 'env'

const formatPhone = (phone: string) => {
  if (REACT_APP_LOCALE !== AvailableLanguages.ru) {
    return phone
  }
  const match = phone?.match(/^(\+7)(\d{3})(\d{3})(\d{2})(\d{2})$/)
  if (match) {
    return `${match[1]}\u00A0${match[2]}\u00A0${match[3]}-${match[4]}-${match[5]}`
  }
  return phone || ''
}

export default formatPhone
