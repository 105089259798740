const main = 'CarriersKR'

const relativeRoutes = {
  relativePage: `${main}/*`,
  relativeList: `${main}`,
  relativeDetails: `:id`,
  relativeAddCar: `:id/add-car`,
}

const routes = {
  List: `/${main}`,
  Details: `/${main}/${relativeRoutes.relativeDetails}`,
  AddCar: `/${main}/${relativeRoutes.relativeAddCar}`,
}

const CarriersKRRoutes = { ...relativeRoutes, ...routes }

export default CarriersKRRoutes
