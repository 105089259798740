import { FC } from 'react'

import { AntDatePicker, DateISO } from '@agro-club/agroclub-shared'
import { useAnalyticsChangeHandler } from 'analytics/hooks'
import useLanguage from 'hooks/useLanguage'

interface Props {
  id: string
  defaultValue?: DateISO | undefined
  value?: DateISO | undefined
  placeholder?: string
  allowClear?: boolean
  className?: string
  disabled?: boolean
  showTime?: boolean
  size?: 'small' | 'middle' | 'large'
  error?: boolean
  onChange: (customDate: object) => void
  fullWidth?: boolean
  place?: string
}

const AAntDatePicker: FC<Props> = ({ id, value, onChange, fullWidth, place, ...otherProps }) => {
  const handleChange = useAnalyticsChangeHandler(id)
  const locale = useLanguage()

  const analyticsPayload = {
    value: { from: value },
    place,
  }

  return (
    <AntDatePicker
      locale={locale}
      value={value}
      onChange={handleChange(onChange, analyticsPayload)}
      fullWidth={fullWidth}
      {...otherProps}
    />
  )
}

export default AAntDatePicker
