import { uploadFile } from 'modules/domain/common/managers'
import { apiClient } from 'modules/utils/httpClient'
import { RespFile } from 'modules/domain/types'
import { endpoints } from 'modules/endpoints'
import { useParams } from 'react-router-dom'
import { FC, useEffect } from 'react'
import AUploadFiles from 'views/components/UploadFiles/AUploadFiles'
import { Progress } from '@agro-club/agroclub-shared'
import { useSingleEntity } from 'modules/domain/common/hooks'

type Props = {
  setSummary: (arg: number) => void
}

export const Files: FC<Props> = ({ setSummary }) => {
  const { id } = useParams()

  const [progress, files = [], refetchFiles] = useSingleEntity<RespFile[]>(endpoints.userFiles(id))

  useEffect(() => {
    if (progress === Progress.SUCCESS) setSummary(files.length)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files.length])

  const removeFile = async (fileId: string) => {
    await apiClient.delete(endpoints.userFiles(id, fileId))
    refetchFiles(true)
  }

  const addFile = async (file: File) => {
    await uploadFile(endpoints.userFiles(id), file)
    refetchFiles(true)
  }

  return (
    <AUploadFiles
      progress={progress}
      removeFile={(fileId) => removeFile(fileId)}
      addFile={({ file }) => addFile(file)}
      fileList={files}
    />
  )
}
