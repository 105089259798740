import { isBrazil, isPriceTypeEnabled } from 'env'
import { AgroundImputs, Bid } from 'modules/domain/bid/types'
import { getPriceTypeLabel } from 'modules/domain/bid/utils'
import { Product } from 'modules/domain/types'
import { ProfileType } from 'modules/domain/user/types'
import { useTranslation } from 'react-i18next'
import { GridItem } from 'views/components/Card/Card'
import { DASH } from 'modules/constants'

export enum BrazilProductSlug {
  MILHO = 'milho',
}

type Props = {
  profileType?: ProfileType
  product?: Product
}

type Fields = Extract<keyof Bid, 'bid_tag' | 'aground_imputs'>

export const getBrazilFieldRequirements = ({ profileType, product }: Props): Record<Fields, boolean> => {
  if (!isBrazil) return { bid_tag: false, aground_imputs: false }
  return {
    bid_tag: profileType === ProfileType.customer,
    aground_imputs: profileType === ProfileType.customer && product?.slug === BrazilProductSlug.MILHO,
  }
}

export const useBrazilBidDetails = (bid: Bid): GridItem[] => {
  const { t } = useTranslation(['bid'])

  if (!isBrazil) return []

  const { bid_tag, aground_imputs } = getBrazilFieldRequirements({
    profileType: bid?.owner?.profile?.profile_type,
    product: bid.product,
  })

  return [
    isPriceTypeEnabled && { label: t('form.delivery'), value: getPriceTypeLabel(bid.price_type, t) },
    bid_tag && { label: t('bidTag.label'), value: bid.bid_tag ? t(`bidTag.${bid.bid_tag}`) : DASH },
    aground_imputs && {
      label: t('taxes.aground_imputs'),
      value: bid.aground_imputs ? t(`common:${bid.aground_imputs || AgroundImputs.NO}`) : DASH,
    },
  ].filter(Boolean) as GridItem[]
}
