import { FormikProps } from 'formik'
import FormSelectShared from 'views/components/FormSelectShared'
import { useTranslation } from 'react-i18next'
import { endpoints } from 'modules/endpoints'
import { ProfileType } from 'modules/domain/user/types'
import { ReactNode } from 'react'

interface Props {
  formik: FormikProps<any>
  onSelectedOptionLoad: (option: any) => void
  currentProfileType: ProfileType
  onChange?: () => void
  fieldName?: string
  filter?: Record<string, unknown>
  children?: ReactNode
}

export const FormSelectActiveUser = ({
  formik,
  onSelectedOptionLoad,
  currentProfileType,
  onChange,
  filter,
  fieldName = 'user',
  children,
}: Props) => {
  const { t } = useTranslation('deal')

  const getUserLabel = (dto) => {
    const name = dto.profile.full_name
    const isNotEmpty = /[^\s]/.test(name)

    if (isNotEmpty) return name

    return `${t('common:withoutName')} ID:${dto.id}`
  }

  return (
    <FormSelectShared
      formik={formik}
      fieldName={fieldName}
      label={t('common:user')}
      endpoint={`${endpoints.activeMarketUsers(currentProfileType)}&sort=last_name`}
      filter={filter}
      onChange={onChange}
      getLabel={getUserLabel}
      onSelectedOptionLoaded={onSelectedOptionLoad}
      searchField="search"
    >
      {children}
    </FormSelectShared>
  )
}
