import PermissionCheck from 'views/components/PermissionCheck/PermissionCheck'
import { Text, Verified, WrapperIcon } from '../../styled'
import { Icons } from '@agro-club/agroclub-shared'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { refetchFunc } from 'modules/domain/common/hooks'
import { User } from 'modules/domain/user/types'
import { CoordinatorConfirmModal } from './ConfirmModals/CoordinatorConfirmModal'

type Props = {
  userRefetch: refetchFunc
  user: User
}

export const VerifiedByCoordinator = ({ user, userRefetch }: Props) => {
  const { t } = useTranslation('user')

  const [verifyCoordinatorModal, setVerifyCoordinatorModal] = useState(false)

  if (!user.profile) return null

  return (
    <>
      <PermissionCheck scope={['users.can_verify_as_coordinator']}>
        <Verified
          isVerified={user.profile.is_verified_by_coordinator}
          isActive={user.is_active}
          onClick={() => setVerifyCoordinatorModal(true)}
        >
          <WrapperIcon>
            <Icons.IconCheckMark />
          </WrapperIcon>
          <Text>{t('verify_coordinator')}</Text>
        </Verified>
      </PermissionCheck>

      {verifyCoordinatorModal && (
        <CoordinatorConfirmModal
          onClose={() => setVerifyCoordinatorModal(false)}
          refetch={userRefetch}
          userId={user.id}
        />
      )}
    </>
  )
}
