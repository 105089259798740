import { useDisplayClearBtn } from 'hooks/useDisplayClearBtn'
import { TableFiltersProps } from 'modules/types'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { AClearFiltersButton, ASelectFilter } from 'views/components/Analytics'
import { FiltersAndSearchWrapper, FiltersWrapper } from 'views/components/CommonTableComponents/CommonTableComponents'
import { MonthFilter } from 'views/components/MonthFilter/MonthFilter'
import { SearchFilter } from 'views/components/SearchFilter/SearchFilter'
import { CropsFilter } from 'views/components/TableFilters'
import { defaultListRequestParams } from 'views/components/TableFilters/data'

export const WarehouseDetailFilters: FC<TableFiltersProps> = ({
  listRequestParams,
  updateFilterState,
  setListRequestParams,
  defaultFilterValues,
}) => {
  const { t } = useTranslation('common')

  const displayClearBtn = useDisplayClearBtn({
    listRequestParams,
    defaultFilters: defaultFilterValues,
  })

  const statusOptions = [
    {
      value: 'true',
      label: t('warehouses:opened'),
    },
    {
      value: 'false',
      label: t('warehouses:closed'),
    },
  ]

  return (
    <FiltersAndSearchWrapper>
      <FiltersWrapper>
        <MonthFilter
          placeholder={t('forEntirePeriod')}
          value={listRequestParams.filter.month}
          updateFilterState={updateFilterState}
        />
        <CropsFilter
          placeholder={t('filtersLabel.allProducts')}
          listRequestParams={listRequestParams}
          updateFilterState={updateFilterState}
        />
        <ASelectFilter
          onChange={(not_closed) => updateFilterState({ not_closed })}
          value={listRequestParams.filter.not_closed}
          placeholder={t('bid:filterByStatuses')}
          options={statusOptions}
          showSearch={false}
          allowClear={false}
          id="not_closed"
        />

        {displayClearBtn && (
          <AClearFiltersButton
            onClick={() => setListRequestParams({ ...defaultListRequestParams, filter: defaultFilterValues })}
          />
        )}
      </FiltersWrapper>
      <SearchFilter listRequestParams={listRequestParams} updateFilterState={updateFilterState} />
    </FiltersAndSearchWrapper>
  )
}
