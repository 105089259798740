import { Address } from 'modules/domain/types'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import AddressLegalType from 'views/components/AddressLegalType'
import { AddressTitle } from 'views/components/AddressTitle/AddressTitle'
import { KeyValue } from 'views/components/KeyValue/KeyValue'

type Props = {
  address: Address
}

export const WhMovementDetailAddress: FC<Props> = ({ address }) => {
  const { t } = useTranslation()

  return (
    <KeyValue label={t('deal:fields.unloading_address')}>
      {address.title && <AddressTitle accent>{address.title}</AddressTitle>}
      <div>
        {address.address}
        <AddressLegalType address={address} />
      </div>
    </KeyValue>
  )
}
