import { ASharedModal, SharedModalContent, SharedModalInputs, SharedModalTitle } from 'views/components/SharedModal'
import { USBid, USContractType } from 'modules/domain/bid/types'
import { refetchFunc } from 'modules/domain/common/hooks'
import { useTranslation } from 'react-i18next'
import { QuantityInput } from 'views/components/QuantityInput/QuantityInput'
import { labelTarif } from 'modules/utils/numbers/formatTarif'
import { FormGroup, Input } from '@agro-club/agroclub-shared'
import { useFormik } from 'formik'
import ADefaultModalFooter from 'views/components/DefaultModalFooter/ADefaultModalFooter'
import { useAFormHandler } from 'analytics/hooks'
import { apiClient } from 'modules/utils/httpClient'
import { endpoints } from 'modules/endpoints'
import { RequestError } from 'modules/errors'

type Props = {
  bid: USBid
  refetch: refetchFunc
  onClose: () => void
}

export const USDealBibEditModal = ({ onClose, bid, refetch }: Props) => {
  const { t } = useTranslation('deal')
  const { formProgress, formHandler } = useAFormHandler()
  const isBasis = bid.contract_type === USContractType.basis
  const isCashPriced = bid.contract_type === USContractType.cash_priced

  const formik = useFormik<{ quantity: number; basis?: number; price?: number }>({
    initialValues: {
      quantity: bid.quantity,
      basis: bid.basis,
      price: bid.price,
    },

    onSubmit: formHandler(
      async () => {
        /* TODO Finalize as needed, after finalizing the api */
        const dto = formik.values

        if (isBasis) delete dto.price
        if (isCashPriced) delete dto.basis

        await apiClient.put(endpoints.bid(bid.id), dto)
      },
      {
        onSuccess: async () => {
          await refetch()
          onClose()
        },
        onError: (error: RequestError) => {
          const { errors } = error
          formik.setErrors(errors)
        },
      },
    ),
  })

  return (
    <ASharedModal size="small" id="USDealBidEdit" onClose={onClose}>
      <SharedModalTitle>{t('bid:form.edit')}</SharedModalTitle>
      <SharedModalContent>
        <SharedModalInputs>
          <QuantityInput formik={formik} fieldName="quantity" label={t('common:volume')} />
          {isBasis && (
            <FormGroup label={labelTarif(t('bid:form.basis'))} error={formik.errors.basis}>
              <Input {...formik.getFieldProps('basis')} invalid={!!formik.errors.basis} type="number" />
            </FormGroup>
          )}
          {isCashPriced && (
            <FormGroup label={labelTarif(t('bid:form.cashPrice'))} error={formik.errors.price}>
              <Input {...formik.getFieldProps('price')} invalid={!!formik.errors.price} type="number" />
            </FormGroup>
          )}
        </SharedModalInputs>
      </SharedModalContent>

      <ADefaultModalFooter
        onClose={onClose}
        confirmButtonText={t('common:save')}
        onConfirm={() => formik.submitForm()}
        progress={formProgress}
        disabled={!formik.dirty}
      />
    </ASharedModal>
  )
}
