import { useTranslation } from 'react-i18next'
import env, { isRussia } from 'env'
import { endpoints } from 'modules/endpoints'
import FormSelectShared from 'views/components/FormSelectShared'
import { FC, useState } from 'react'
import { FormInput } from 'views/components/form/FormInput'
import { labelTarif } from 'modules/utils/numbers/formatTarif'
import { SharedModalInputs } from 'views/components/SharedModal'
import { ShortFormControl } from 'views/styled/common'
import { Deal, DealType } from 'modules/domain/deal/types'
import { getPriceTypeLabel } from 'modules/domain/bid/utils'
import { CheckboxGroup } from 'views/components/CheckboxGroup/CheckboxGroup'
import { Hr, HrWithHeader } from 'views/components/Hr/Hr'
import { BrokerType } from 'modules/domain/user/types'
import { DealEditableFields } from './types'
import { FormikProps } from 'formik'

type Props = {
  formik: FormikProps<DealEditableFields>
  deal: Deal
}

const currency = env.REACT_APP_CURRENCY_SYMBOL

export const PricesControl: FC<Props> = ({ formik, deal }) => {
  const [brokerSections, setBrokerSections] = useState<BrokerType[]>(
    [deal.seller_broker && BrokerType.seller, deal.customer_broker && BrokerType.customer].filter(
      Boolean,
    ) as BrokerType[],
  )
  const { t } = useTranslation(['deal'])
  const purchaseLabel = `${t('fields.purchasePrice')} ${getPriceTypeLabel(
    deal.purchase_bid.price_type,
    t,
  )}, ${currency}`
  const sellerLabel = `${t('fields.salePrice')} ${getPriceTypeLabel(deal.sale_bid.price_type, t)}, ${currency}`
  if (isRussia) {
    return (
      <SharedModalInputs>
        <FormSelectShared
          label={t('broker.seller_agent')}
          formik={formik}
          fieldName="seller_broker|broker"
          placeholder=""
          getLabel={(label) => label.full_name}
          endpoint={endpoints.brokers()}
          searchField="search"
        />
        <ShortFormControl>
          <FormInput
            formik={formik}
            field="seller_broker|commission"
            label={labelTarif(t('broker.broker_commission'))}
            type="number"
          />
          <FormInput
            formik={formik}
            field="sale_price"
            label={labelTarif(t('broker.priceWithoutCommission'))}
            type="number"
          />
          <FormInput label={purchaseLabel} field="purchase_price" formik={formik} type="number" />
        </ShortFormControl>
      </SharedModalInputs>
    )
  }
  const isBrokerDeal = formik.values.deal_type == DealType.broker
  let sellerBlock = (
    <ShortFormControl>
      <FormInput formik={formik} field="sale_price" label={sellerLabel} type="number" />
    </ShortFormControl>
  )
  if (isBrokerDeal && brokerSections.includes(BrokerType.seller)) {
    sellerBlock = (
      <SharedModalInputs>
        <HrWithHeader text={t('broker.seller_broker')} />
        <FormSelectShared
          label={t('broker.broker')}
          formik={formik}
          fieldName="seller_broker|broker"
          placeholder=""
          getLabel={(label) => label.full_name}
          endpoint={endpoints.brokers()}
          searchField="search"
        />
        <ShortFormControl>
          <FormInput
            formik={formik}
            field="seller_broker|commission"
            label={labelTarif(t('broker.broker_commission'))}
            type="number"
          />
        </ShortFormControl>
        {sellerBlock}
        {!brokerSections.includes(BrokerType.customer) && <Hr />}
      </SharedModalInputs>
    )
  }
  let customerBlock = (
    <ShortFormControl>
      <FormInput label={purchaseLabel} field="purchase_price" formik={formik} type="number" />
    </ShortFormControl>
  )
  if (isBrokerDeal && brokerSections.includes(BrokerType.customer)) {
    customerBlock = (
      <SharedModalInputs>
        <HrWithHeader text={t('broker.customer_broker')} />
        <FormSelectShared
          label={t('broker.broker')}
          formik={formik}
          fieldName="customer_broker|broker"
          placeholder=""
          getLabel={(label) => label.full_name}
          endpoint={endpoints.brokers()}
          searchField="search"
        />
        <ShortFormControl>
          <FormInput
            formik={formik}
            field="customer_broker|commission"
            label={labelTarif(t('broker.broker_commission'))}
            type="number"
          />
        </ShortFormControl>
        {customerBlock}
        <Hr />
      </SharedModalInputs>
    )
  }

  const onCheckBoxChange = (val: string[]) => {
    const brokerTypes = val as BrokerType[]
    setBrokerSections(brokerTypes)
    if (!brokerTypes.includes(BrokerType.seller)) {
      formik.setFieldValue('seller_broker|broker', null)
    }
    if (!brokerTypes.includes(BrokerType.customer)) {
      formik.setFieldValue('customer_broker|broker', null)
    }
  }

  return (
    <SharedModalInputs>
      {isBrokerDeal && (
        <CheckboxGroup
          keys={Object.keys(BrokerType)}
          value={brokerSections}
          onChange={onCheckBoxChange}
          label={t('broker.brokerType')}
          keysRootTranslate="deal:broker.brokerTypes"
        />
      )}
      {sellerBlock}
      {customerBlock}
    </SharedModalInputs>
  )
}
