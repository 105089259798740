import { AntDatePicker, defaultTheme } from '@agro-club/agroclub-shared'
import useLanguage from 'hooks/useLanguage'
import styled from 'styled-components'
import { ComponentProps, FC } from 'react'
import { useMediaQuery } from 'react-responsive'

interface Props extends Omit<ComponentProps<typeof AntDatePicker>, 'onChange' | 'locale'> {
  onChange: (customDate: object) => void
}

/** @deprecated used ShortFormControl */
const Wrapper = styled.div`
  width: 168px;
  @media (max-width: ${defaultTheme.queries.mobile}) {
    width: 100%;
  }
`

export const DatePicker: FC<Props> = (props) => {
  const locale = useLanguage()

  const isMobile = useMediaQuery({
    query: `(max-width: ${defaultTheme.queries.mobile})`,
  })

  return (
    <Wrapper>
      <AntDatePicker locale={locale} size={isMobile ? 'large' : 'middle'} fullWidth={isMobile} {...props} />
    </Wrapper>
  )
}
