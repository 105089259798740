import { FC } from 'react'
import { AntTagColor, AntTag, Progress } from '@agro-club/agroclub-shared'
import { PreloadCount } from './Preload'

type Props = {
  progress: Progress
  counter: number | string | undefined
  color?: AntTagColor
}
export const BoardCounter: FC<Props> = ({ counter, progress, color = 'orange' }) => (
  <>
    {progress === Progress.WORK ? (
      <PreloadCount />
    ) : (
      <AntTag counter={true} color={color}>
        {counter || '0'}
      </AntTag>
    )}
  </>
)
