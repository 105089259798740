import { Text, defaultTheme } from '@agro-club/agroclub-shared'
import env, { isBrazil, isRussia, isUS } from 'env'
import { Deal, DealStatusTypes } from 'modules/domain/deal/types'
import { formatTarifDistance } from 'modules/utils/numbers/formatDistance'
import formatNumber from 'modules/utils/numbers/formatNumber'
import formatPrice from 'modules/utils/numbers/formatPrice'
import { formatTarif } from 'modules/utils/numbers/formatTarif'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import styled from 'styled-components'
import { Card } from 'views/components/Card/Card'
import { KeyValue, Value } from 'views/components/KeyValue/KeyValue'
import { QuestionTooltip } from 'views/components/QuestionTooltip/QuestionTooltip'
import { FormulaTooltip } from '../../components/FormulaTooltip'
import { useDealContext } from '../DealContext'
import { QuestionTooltipContent } from '../QuestionTooltipContent'

const ValueAndTooltipWrapper = styled.div`
  display: flex;
  column-gap: 2px;
  align-items: center;
`
const PercentBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 0 4px;
`
const Percent = styled(Text)<{ isRed: boolean }>`
  color: ${({ isRed }) => (isRed ? defaultTheme.color.accentDestruct600 : defaultTheme.color.accentApprove600)};
  white-space: nowrap;
`
const NeedPercent = styled.div`
  background: ${defaultTheme.color.primary16a};
  border-radius: 4px;
  padding: 0 8px;
`
const Container = styled(Card.Container)`
  @media (max-width: ${defaultTheme.queries.mobile}) {
    margin: 0px 8px 8px 8px;
    width: auto;
  }
`
const NetMargin = (deal: Deal) => {
  const { t } = useTranslation('deal')
  if (!isBrazil) return null
  return <KeyValue label={t('fields.netMargin')}>{formatPrice(deal.net_margin, true)}</KeyValue>
}

export const NumbersInfo = () => {
  const { t } = useTranslation('deal')
  const { deal } = useDealContext()

  const currency = env.REACT_APP_CURRENCY_SYMBOL
  const actualMarginLabel = `${t('fields.factMargin')} ${deal.margin_confirmed ? t('fields.1c_tag') : ''}`
  const isVisibleMarginTooltip = deal.margin_confirmed && deal.status === DealStatusTypes.SHIPMENT_COMPLETE && isRussia

  const isVisibleCredit = isRussia && deal.purchase_bid.company.turnover_days
  const isMobile = useMediaQuery({
    query: `(max-width: ${defaultTheme.queries.mobile})`,
  })
  const maxColumnWidth = isMobile ? '' : '151px'

  const marginPercent = isVisibleCredit ? (
    <PercentBlock>
      <FormulaTooltip numberFormula={deal.margin_percent_formula} textFormula={deal.margin_percent_tooltip}>
        <Percent isRed={deal.margin_percent < deal.target_margin_percent}>
          {formatNumber(deal.margin_percent)} %
        </Percent>
      </FormulaTooltip>
      <NeedPercent>
        <Text typography="accentSmall">{`${t('need')} ${formatNumber(deal.target_margin_percent)}%`}</Text>
      </NeedPercent>
    </PercentBlock>
  ) : (
    <FormulaTooltip numberFormula={deal.margin_percent_formula} textFormula={deal.margin_percent_tooltip}>
      <div>{formatNumber(deal.margin_percent)} %</div>
    </FormulaTooltip>
  )

  const grossMargin = (
    <KeyValue label={t('fields.grossMargin')}>
      <FormulaTooltip numberFormula={deal.margin_formula} textFormula={deal.margin_tooltip}>
        <Value typography="accentLarge">{formatPrice(deal.margin, true)}</Value>
      </FormulaTooltip>
      {marginPercent}
      <FormulaTooltip numberFormula={deal.margin_per_kg_formula} textFormula={deal.margin_per_kg_tooltip}>
        <div>{formatTarif(deal.margin_per_kg)}</div>
      </FormulaTooltip>
    </KeyValue>
  )

  if (isUS) {
    return (
      <Container>
        <Card.Grid maxColumnWidth={maxColumnWidth} columnGap={8}>
          {grossMargin}
        </Card.Grid>
      </Container>
    )
  }

  return (
    <Container>
      <Card.Grid cols={2} maxColumnWidth={maxColumnWidth} columnGap={8}>
        <KeyValue label={t('fields.gmv')} valueTypo="accentLarge">
          <FormulaTooltip numberFormula={deal.gmv_formula} textFormula={deal.gmv_tooltip}>
            {formatPrice(deal.gmv, true)}
          </FormulaTooltip>
        </KeyValue>
        <KeyValue label={t('fields.freight_rate')} valueTypo="accentLarge">
          <FormulaTooltip
            numberFormula={deal.price_logistics_ton_per_km_formula}
            textFormula={deal.price_logistics_ton_per_km_tooltip}
          >
            {formatTarifDistance(deal.price_logistics_ton_per_km)}
          </FormulaTooltip>
        </KeyValue>
        {grossMargin}
        <KeyValue label={actualMarginLabel}>
          <Value typography="accentLarge">
            <ValueAndTooltipWrapper>
              {formatPrice(deal.actual_margin)} {t('common:rub', { currency })}
              {isVisibleMarginTooltip && (
                <QuestionTooltip size="compact" tooltipContent={<QuestionTooltipContent deal={deal} />} />
              )}
            </ValueAndTooltipWrapper>
          </Value>
          <div>{formatNumber(deal.actual_margin_percent)} %</div>
          <div>{formatTarifDistance(deal.actual_margin_per_kg)}</div>
        </KeyValue>
        {NetMargin(deal)}
        {isVisibleCredit ? (
          <KeyValue label={t('credit')}>
            <div>{formatPrice(deal.credit_costs, true)}</div>
            <div>{formatNumber(deal.credit_rate)} %</div>
            <div>{formatTarif(deal.cost_per_unit)}</div>
          </KeyValue>
        ) : null}
      </Card.Grid>
    </Container>
  )
}
