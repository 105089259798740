import { Text } from '@agro-club/agroclub-shared'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { CompanyDadata } from 'modules/domain/collection/types'
import { getPersonalNumberLabel } from 'modules/utils/numbers/formatPersonalNumber'
import { FC } from 'react'

const StyledCompany = styled.div`
  margin-top: 4px;
  margin-right: 26px;
`

type Props = {
  company?: CompanyDadata
}

export const CompanyShortInfo: FC<Props> = (props) => {
  const { company } = props
  const { t } = useTranslation('company')

  if (!company) {
    return null
  }

  return (
    <StyledCompany>
      <div>
        <Text as="span" typography="bodyLarge" color="secondary">
          {getPersonalNumberLabel()}:
        </Text>
        &nbsp;
        <Text as="span" typography="bodyLarge">
          {company.inn || t('common:notSpecified')}
        </Text>
      </div>
      <div>
        <Text as="span" typography="bodyLarge" color="secondary">
          {t('form.fields.legalAddress')}:
        </Text>
        &nbsp;
        <Text as="span" typography="bodyLarge">
          {company.address_short}
        </Text>
      </div>
    </StyledCompany>
  )
}
