import { FC, useState } from 'react'

import { PotentialBid } from 'modules/domain/potentialBid/types'
import { endpoints } from 'modules/endpoints'
import AComments from 'views/components/Comments/AComments'
import { CommentType } from 'modules/domain/comment/types'
import { useTranslation } from 'react-i18next'
import { AntCollapse, Panel } from '@agro-club/agroclub-shared'
import AntCollapseHeader from 'views/components/AntCollapseHeader/AntCollapseHeader'
import { SummaryComments } from 'views/components/AntCollapseHeader/SummaryComments'

interface Props {
  bid: PotentialBid
}

export const BidPotentialRightBlock: FC<Props> = ({ bid }) => {
  const { t } = useTranslation('bid')

  const [commentsSummary, setCommentsSummary] = useState<string>('')

  const options = [{ value: 'comments', title: t('form.userComment') }]
  const [activeKey, setActiveKey] = useState<string | string[]>(options[0].value)
  const components = {
    comments: (
      <AComments
        id={bid?.owner?.id}
        type={CommentType.user}
        url={endpoints.userComments(bid?.owner?.id)}
        maxHeight={400}
        popSummary={setCommentsSummary}
      />
    ),
  }
  const summary = {
    comments: <SummaryComments summary={commentsSummary} />,
  }
  return (
    <AntCollapse accordion bordered={false} defaultActiveKey={options[0].value} onChange={(key) => setActiveKey(key)}>
      {options.map((option) => (
        <Panel
          header={
            <AntCollapseHeader title={option.title} summary={activeKey !== option.value && summary[option.value]} />
          }
          key={option.value}
        >
          {components[option.value]}
        </Panel>
      ))}
    </AntCollapse>
  )
}
