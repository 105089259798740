import { ResponsiveModal } from 'views/components/ResponsiveModal/ResponsiveModal'
import { useNotificationProgress } from 'hooks/useNotificationProgress'
import { ProductPurpose } from 'modules/domain/documentRequest/types'
import { DealContractForm } from 'views/pages/Deal/DealDetail/types'
import { Deal, DealContractType } from 'modules/domain/deal/types'
import { SharedModalContent } from 'views/components/SharedModal'
import { refetchFunc } from 'modules/domain/common/hooks'
import { apiClient } from 'modules/utils/httpClient'
import { useAFormHandler } from 'analytics/hooks'
import { useTranslation } from 'react-i18next'
import { CommonFields } from './CommonFields'
import { endpoints } from 'modules/endpoints'
import { useFormik } from 'formik'

type Props = {
  deal: Deal
  onClose: () => void
  onSuccess: refetchFunc
}

export const ContractRequestModal = ({ onClose, deal, onSuccess }: Props) => {
  const { t } = useTranslation('deal')
  const { formProgress, formHandler } = useAFormHandler('sendContractRequest', { id: deal.id })

  useNotificationProgress(formProgress)

  const formik = useFormik<DealContractForm>({
    initialValues: {
      deal: deal.id,
      contract_type: DealContractType.agroclub,
      dispatching_date: '',
      payment_terms: '',
      transfer_address: '',
      email: '',
      phone: '',
      conformity_declaration_type: DealContractType.agroclub,
      season: deal.season?.startDate ?? '',
      quantity: deal.quantity,
      price: deal.price_exw,
      has_vat: false,
      product_purpose: ProductPurpose.alimentary,
      parameters: [],
      main_param: {},
      parameters_comment: '',
    },

    onSubmit: formHandler(
      async () => {
        const dto = {
          ...formik.values,
          parameters: [formik.values.main_param, ...formik.values.parameters],
        }
        delete dto.main_param
        await apiClient.post(endpoints.dealsContracts(), dto)
      },
      {
        onSuccess: async () => {
          await onSuccess()
          onClose()
        },
        onError: (error) => {
          const { errors } = error
          formik.setErrors(errors)
        },
      },
    ),
  })

  return (
    <ResponsiveModal
      title={t('requestDocsModal.title')}
      id="requestDocs"
      onClose={onClose}
      progress={formProgress}
      onConfirm={() => formik.submitForm()}
      confirmButtonText={t('common:send')}
    >
      <SharedModalContent>
        <CommonFields deal={deal} formik={formik} />
      </SharedModalContent>
    </ResponsiveModal>
  )
}
