import { FC } from 'react'

import { DocumentType } from 'modules/domain/types'
import InternalFilesByTypes, { InternalFilesByTypesProps } from './InternalFilesByTypes'
import { useAnalyticsContext } from 'analytics/hooks'

export const AFilesByTypes: FC<InternalFilesByTypesProps> = ({ files, onUpload, onDelete, ...otherProps }) => {
  const { track } = useAnalyticsContext()

  const handleUpload = async (documentType: DocumentType['slug'], file: File) => {
    onUpload?.(documentType, file)

    track('upload|document', {
      value: {
        to: file,
      },
    })
  }

  const handleDelete = async (documentId: string) => {
    onDelete?.(documentId)

    track('delete|document', {
      id: documentId,
      value: {
        to: files.find((f) => f.id === documentId),
      },
    })
  }

  return <InternalFilesByTypes onUpload={handleUpload} onDelete={handleDelete} files={files} {...otherProps} />
}
