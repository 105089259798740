import { FC } from 'react'
import { AntTagColor, AntTag } from '@agro-club/agroclub-shared'
import { isBrazil } from 'env'
import { Bid } from 'modules/domain/bid/types'

export const BidTeamTag: FC<{ bid?: Bid | null; color?: AntTagColor }> = ({ bid, color = 'white' }) => {
  if (!bid || !bid.owner?.profile?.team?.name || isBrazil) return null

  return <AntTag color={color}>{bid.owner.profile.team.name}</AntTag>
}
