import { Icons, Input, defaultTheme, Typography, Text } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

export const SubmitButtonWrapper = styled.div`
  display: flex;
  column-gap: 16px;
`

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
`

export const FieldWrapper = styled.div`
  display: flex;
  div[class^='InputsContainer'] {
    width: 292px;
  }
  margin-bottom: 24px;
`

export const InputStyled = styled(Input)`
  width: 120px;
`

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const InputLabel = styled.div`
  ${Typography.bodyLarge}
  color: ${defaultTheme.color.textSecondary60a};
  margin-bottom: 4px;
`

export const CodeWrapper = styled.div`
  margin-bottom: 24px;
`
export const RetryBtn = styled.button`
  border: none;
  background: none;
  box-shadow: none;
  ${Typography.bodyLarge}
  height: 20px;
  padding: 0;
  color: ${(props) => props.theme.color.primary600};
  text-decoration: none;
  cursor: pointer;
  white-space: nowrap;
  &:focus {
    outline: none;
  }

  &:hover {
    color: ${defaultTheme.color.primary800};
  }
`

export const RetryDelay = styled.div`
  ${Typography.bodyLarge}
  color: ${(props) => props.theme.color.textSecondary60a};
  white-space: nowrap;
`

export const BackIcon = styled(Icons.IconArrowLeftM)`
  path {
    fill: ${defaultTheme.color.icons600};
  }
`
export const ErrorText = styled(Text)`
  color: ${defaultTheme.color.accentDestruct600};
`
export const Controls = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 0 4px;
  margin-bottom: 4px;
  @media (max-width: ${defaultTheme.queries.mobile}) {
    gap: 0 10px;
  }
`
