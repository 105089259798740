import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useHelmet } from '@agro-club/agroclub-shared'
import * as Layout from 'views/layouts/NewLayout'
import * as Header from 'views/ui/Header/Header'

import useColumns from './useColumns/useColumns'

import { endpoints } from 'modules/endpoints'
import { CarsFilters } from 'views/pages/LogisticsKR/components/CarsTable/Filters/CarsFilters'
import { useFilters } from 'hooks/useFilter'
import { defaultListRequestParams } from 'views/components/TableFilters/data'
import { ListRequestParams } from 'modules/domain/types'
import APageWrapper from 'views/components/PageWrapper/APageWrapper'
import { AnalyticsPages, AnalyticsPlaces } from '../../analyticsPlaces'
import ATableFullPage from 'views/components/Table/ATableFullPage'

export const CarsList: FC = () => {
  const { t } = useTranslation('logisticsKR')
  useHelmet({ title: t('cars.heading') })

  const [listRequestParams, setListRequestParams] = useState<ListRequestParams>(defaultListRequestParams)

  const { updateFilterState, isFiltersLoaded } = useFilters({
    listRequestParams,
    setListRequestParams,
  })

  const handleClearFilters = () => {
    setListRequestParams(defaultListRequestParams)
  }
  const columns = useColumns({ listRequestParams, setListRequestParams })

  return (
    <APageWrapper page={AnalyticsPages.CARS} place={AnalyticsPlaces.CARS.LIST}>
      <Layout.WrapperContent>
        <Layout.Header>
          <Layout.TopHeader>
            <Header.Breadcrumbs
              routes={[
                {
                  breadcrumbName: t('cars.heading'),
                },
              ]}
            />
          </Layout.TopHeader>
          <Layout.PageName>{t('cars.heading')}</Layout.PageName>
          <CarsFilters
            updateFilterState={updateFilterState}
            listRequestParams={listRequestParams}
            handleClearFilters={handleClearFilters}
          />
        </Layout.Header>
        <ATableFullPage
          columns={columns}
          dataEndpoint={endpoints.LogisticsKR.car()}
          listParamsUpdated={setListRequestParams}
          listRequestParams={listRequestParams}
          isFiltersLoaded={isFiltersLoaded}
          rowHoverGray
        />
      </Layout.WrapperContent>
    </APageWrapper>
  )
}
