import { memo, useCallback, useContext, useMemo } from 'react'
import { useAudioPlayer } from 'react-use-audio-player'

import { IconPause, IconPlay } from '@agro-club/agroclub-shared'

import { PlayerContext } from 'views/components/Player/PlayerProvider'
import { PlayerSeekBar } from 'views/components/Player/PlayerSeekBar'
import { Button, PlayerWrapper } from 'views/components/Player/styled'
import { useAnalyticsClickHandler } from 'analytics/hooks'

export interface PlayerProps {
  itemId: string
  file: string
  duration?: number
}

function Player({ itemId, file, duration }: PlayerProps) {
  const { play } = useContext(PlayerContext)
  const { player, playing, togglePlayPause } = useAudioPlayer()

  const isActive = useMemo(() => file === player?._src, [file, player])
  const isPlaying = useMemo(() => file === player?._src && playing, [file, player, playing])
  const handleClick = useAnalyticsClickHandler(isPlaying ? 'pause' : 'play')

  const toggle = useCallback(() => {
    play(file)
    togglePlayPause()
  }, [file, play, togglePlayPause])

  return (
    <PlayerWrapper>
      <Button onClick={handleClick(toggle, { id: itemId })} isPlaying={isPlaying}>
        {isPlaying ? <IconPause /> : <IconPlay />}
      </Button>
      <PlayerSeekBar isActive={isActive} duration={duration} />
    </PlayerWrapper>
  )
}

export default memo(Player)
