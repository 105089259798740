import { Typography, defaultTheme } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

import { box } from 'views/styled/common'

export const PlanCardContainer = styled.div<{ isActive: boolean; isEditable?: boolean }>`
  ${box}
  display: ${({ isEditable }) => isEditable && 'flex'};
  flex-direction: ${({ isEditable }) => isEditable && 'column'};
  justify-content: ${({ isEditable }) => isEditable && 'space-between'};
  width: 314px;
  height: 132px;
  padding: ${({ isActive }) => (isActive ? '16px 25px' : '24px')};
  cursor: ${({ isEditable }) => isEditable && 'pointer'};
  box-shadow: none;

  border: 1px solid ${defaultTheme.color.textSecondary60a};

  ${({ isActive }) => isActive && `border: 1px solid ${defaultTheme.color.primary50a};`}

  ${({ isEditable }) =>
    isEditable &&
    `border: 1px solid ${defaultTheme.color.secondary50};

    &:hover {
      background-color: ${defaultTheme.color.primary8a};
    }`}
`

export const CardHeader = styled.div<{ isActive: boolean }>`
  ${({ isActive }) => (isActive ? Typography.accentLarge : Typography.bodyLarge)}
  color: ${({ isActive }) => (isActive ? defaultTheme.color.secondary900 : defaultTheme.color.textSecondary60a)};
  margin-bottom: 8px;

  :first-letter {
    text-transform: capitalize;
  }
`
