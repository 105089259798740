import { defaultTheme } from '@agro-club/agroclub-shared'

import env from 'env'
import {
  Goal,
  Progress,
  ProgressBarContainer,
  ProgressContainer,
  ProgressHeader,
  ProgressLine,
} from 'views/components/TeamsProgress/styled'
import { CSSProperties, FC } from 'react'
import { TeamsProgressSize } from 'views/components/TeamsProgress/types'
import formatNumber from 'modules/utils/numbers/formatNumber'

const currency = env.REACT_APP_CURRENCY_SYMBOL
const PROGRESS_LOW = 31
const PROGRESS_MIDDLE = 66

const getProgress = (progress: number) => (progress > 100 ? 100 : progress)

interface Props {
  size?: TeamsProgressSize
  volume: number
  completed: number
  direction?: CSSProperties['flexDirection']
  align?: CSSProperties['alignItems']
  isActive?: boolean
  isTableCell?: boolean
}

export const TeamsProgress: FC<Props> = ({
  size,
  volume,
  completed,
  direction = 'row',
  isActive = true,
  isTableCell,
  align,
}) => {
  const isColumn = direction === 'column'
  const isEmptyPlan = volume === 0 && completed === 0

  const progress = isEmptyPlan ? 0 : Math.round((completed / volume) * 100)
  const progressBarStyles = {
    width: progress < 0 ? '0%' : `${getProgress(progress)}%`,
    backgroundColor: defaultTheme.color.accentDestruct600,
  }

  const headerTextStyles = {
    flexDirection: direction,
    justifyContent: isColumn ? 'center' : 'space-between',
    alignItems: isColumn ? 'flex-start' : 'flex-end',
  }

  const goalStyles = {
    marginBottom: isColumn ? '4px' : 0,
  }

  if (progress > PROGRESS_LOW && progress < PROGRESS_MIDDLE) {
    progressBarStyles.backgroundColor = defaultTheme.color.accentNotify600
  } else if (progress >= PROGRESS_MIDDLE) {
    progressBarStyles.backgroundColor = defaultTheme.color.accentApprove600
  }

  if (isTableCell) {
    return (
      <ProgressContainer align={align}>
        <ProgressHeader style={headerTextStyles} isActive={isActive} isTableCell={isTableCell}>
          <Progress>
            {formatNumber(completed)} — {progress}%
          </Progress>
        </ProgressHeader>
        {isActive && (
          <ProgressBarContainer isTableCell={isTableCell}>
            <ProgressLine style={progressBarStyles} />
          </ProgressBarContainer>
        )}
      </ProgressContainer>
    )
  }

  return (
    <ProgressContainer>
      <ProgressHeader style={headerTextStyles} isActive={isActive}>
        <Goal size={size} style={goalStyles}>
          {formatNumber(volume)}
          {currency}
        </Goal>
        <Progress>
          {formatNumber(completed)}
          {currency} — {progress}%
        </Progress>
      </ProgressHeader>
      {isActive && (
        <ProgressBarContainer>
          <ProgressLine style={progressBarStyles} />
        </ProgressBarContainer>
      )}
    </ProgressContainer>
  )
}
