import { useTranslation } from 'react-i18next'
import { FC } from 'react'

type Props = {
  total: number | undefined
}

export const SummaryCount: FC<Props> = ({ total }) => {
  const { t } = useTranslation('common')

  return <div>{total ? total : t('no')}</div>
}
