import useFormatDate from 'hooks/useFormatDate'

import { ColumnData } from 'modules/types'
import { getPersonalNumberLabel } from 'modules/utils/numbers/formatPersonalNumber'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router-dom'
import APhone from 'views/ui/Phone/APhone'
import { TableTextLink } from 'views/ui/TableTextLink/TableTextLink'
import CustomersKRRoutes from '../routes'
import { PHONE_TABLE_CELL_WIDTH } from 'modules/constants'

const useColumns = (): ColumnData[] => {
  const { t } = useTranslation('logisticsKR')
  const formatDate = useFormatDate()
  return useMemo(
    () => [
      {
        title: t('customers.tableHeaders.register_date'),
        width: 140,
        dataIndex: 'created',
        render: (value) => formatDate(value),
      },
      {
        title: t('customers.tableHeaders.inn_company', { innName: getPersonalNumberLabel() }),
        width: 132,
        dataIndex: 'inn',
      },
      {
        title: t('common:company'),
        width: 600,
        dataIndex: 'name',
        render: (name, record) => (
          <TableTextLink id="customerLink" to={generatePath(CustomersKRRoutes.Details, { id: record.id })}>
            {name}
          </TableTextLink>
        ),
      },
      {
        title: t('common:phone'),
        width: PHONE_TABLE_CELL_WIDTH,
        dataIndex: 'phone',
        render: (value) => <APhone phone={value} shortBtnTitle className="phone-link-table" />,
      },
      {
        title: t('common:user'),
        width: 500,
        dataIndex: 'contact_name',
      },
    ],
    [t, formatDate],
  )
}

export default useColumns
