import { Description, WrapperFormControl } from './styled'
import FormSelectShared from '../FormSelectShared'
import { useTranslation } from 'react-i18next'
import { FC } from 'react'
import { isBrazil } from 'env'
import { FormikFieldsType } from 'modules/domain/types'

interface Props {
  formik: FormikFieldsType
  showHelpText?: boolean
  disabled?: boolean
  endpoint: string
  filter?: any
}

export const FormLegalType: FC<Props> = ({ formik, endpoint, disabled, showHelpText, filter }) => {
  const { t } = useTranslation()
  if (!isBrazil) {
    return null
  }

  return (
    <WrapperFormControl>
      <FormSelectShared
        placeholder={t('company:placeholder_type_company')}
        label={t('company:type_company')}
        getLabel={(dto) => dto.label}
        fieldName="legal_type"
        disabled={disabled}
        endpoint={endpoint}
        showSearch={false}
        simpleApiFormat
        formik={formik}
        filter={filter}
      />
      {showHelpText && <Description>{t('company:description_type_company')}</Description>}
    </WrapperFormControl>
  )
}
