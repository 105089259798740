import { DTO, OptionType, Progress } from '@agro-club/agroclub-shared'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { apiClient } from 'modules/utils/httpClient'
import { endpoints } from 'modules/endpoints'
import { t } from 'i18next'
import { isUS } from 'env'
import _ from 'lodash'

export interface DistrictDTO extends DTO {
  id: string
  name: string
  parent_id: string
  parent_name: string
}

export const convertDistrictDtoToOptionType = (items: DistrictDTO[]): OptionType[] => {
  return items.map(({ id, name, ...other }) => ({
    value: id.toString(),
    label: name,
    ...other,
  }))
}

export const useDistrict = (
  value: string[] | string | undefined,
  region: string[] | string,
  onChange: (value: any) => void,
) => {
  const [searchText, setSearchText] = useState('')
  const [districts, setDistricts] = useState<DistrictDTO[]>([])
  const [selectedDistricts, setSelectedDistricts] = useState<DistrictDTO[]>([])
  const [progress, setProgress] = useState<Progress>(Progress.IDLE)
  const isFiltersInitialized = useRef(false)

  // we need this due to url encoding - [xxx] encoded as xxx in url
  const valueArr = useMemo(() => {
    if (!value) {
      return []
    }

    return Array.isArray(value) ? value : [value]
  }, [value])

  const fetchDistricts = async () => {
    try {
      setProgress(Progress.WORK)

      const response = await apiClient.get<DistrictDTO[]>(endpoints.districts(), {
        parent_id: region,
        name: searchText,
      })
      const transformed = response.map((r) => ({
        ...r,
        id: r.id.toString(),
        parent_id: r.parent_id.toString(),
      }))

      setDistricts(transformed)
      setProgress(Progress.SUCCESS)

      return transformed
    } catch (error) {
      setProgress(Progress.ERROR)
    }
  }

  const handleSelectedDistricts = (fetchedDistricts) => {
    if (valueArr.length === 0) return

    let currentSelectedDistricts: DistrictDTO[] = selectedDistricts

    const regions = Array.isArray(region) ? region : [region]

    if (fetchedDistricts && valueArr.length > 0 && selectedDistricts.length === 0) {
      currentSelectedDistricts = fetchedDistricts.filter((d) => valueArr.includes(d.id))
    }

    const filtered = currentSelectedDistricts.filter((d) => regions.includes(d.parent_id))
    setSelectedDistricts(filtered)

    if (isFiltersInitialized.current && currentSelectedDistricts.length !== filtered.length) {
      onChange(_.map(filtered, 'id'))
    }
  }

  const handleRegionChange = async () => {
    const fetchedDistricts = await fetchDistricts()

    handleSelectedDistricts(fetchedDistricts)

    //It is necessary in order that after reloading the page,
    // when selected states and districts did not trigger onChange in onRegionChange
    if (!isFiltersInitialized.current) {
      isFiltersInitialized.current = true
    }
  }

  useEffect(() => {
    handleRegionChange()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [region])

  useEffect(() => {
    // search only after initial load
    if (progress !== Progress.SUCCESS) return

    fetchDistricts()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText])

  const handleDistrictChange = (val: string[]) => {
    if (!val.length) {
      onChange(val)
      setSelectedDistricts([])
      return
    }

    const newSelected: DistrictDTO[] = []

    val.forEach((id) => {
      let districtObj = selectedDistricts.find((d) => d.id === id)

      if (!districtObj) {
        districtObj = districts.find((d) => d.id === id)
      }

      if (districtObj) {
        newSelected.push(districtObj)
      }
    })

    setSelectedDistricts(newSelected)
    onChange(_.map(newSelected, 'id'))
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(_.debounce(setSearchText, 300), [])

  const placeholder = isUS ? t('regionFilter:county') : t('regionFilter:district')

  return { debouncedSearch, placeholder, handleDistrictChange, valueArr, districts, progress }
}
