import { FC, ReactNode, useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'

import { getContractSignStatuses } from 'views/pages/Deal/DealDetail/helpers'
import {
  StepsColumn,
  StepContainer,
  StatusContainer,
  StatusTitle,
  StatusDescription,
  StatusTitleContainer,
  StatusDescriptionContainer,
  SignActiveStatusDescription,
  IconCheckMark,
  IconAccount,
  IconSign,
  AccentDescription,
} from 'views/pages/Deal/DealDetail/styled'
import { Deal, DealDTO } from 'modules/domain/deal/types'
import { AButton } from 'views/components/Analytics'
import { apiClient } from 'modules/utils/httpClient'
import { endpoints } from 'modules/endpoints'
import { useAFormHandler } from 'analytics/hooks'
import { useFormik } from 'formik'
import { FormInput } from 'views/components/form/FormInput'
import { InputWrapper, TextWrapper } from '../styled'
import { LogisticInputs } from './LogisticInputs'
import { ApprovalStatus, SignStatus } from '../../types'
import { LogisticDescription } from './LogisticDescription'
import { refetchFunc } from 'modules/domain/common/hooks'

type StepProps = {
  title: string
  status: string
  icon: ReactNode
  body: ReactNode
  isLastItem: boolean
}

type LogisticBodyProps = {
  status: string
  deal: Deal
  dealRefetch: refetchFunc
}

type SignBodyProps = {
  status: string
  deal: Deal
  dealRefetch: refetchFunc
}

const Step: FC<StepProps> = ({ title, status, icon, body, isLastItem = false }) => {
  return (
    <StepContainer>
      <StatusContainer>
        <StatusTitleContainer>
          {icon}
          <StatusTitle status={status}>{title}</StatusTitle>
        </StatusTitleContainer>
        <StatusDescriptionContainer isLastItem={isLastItem}>{body}</StatusDescriptionContainer>
      </StatusContainer>
    </StepContainer>
  )
}

const UsersBody: FC<{ status: string }> = ({ status }) => {
  const { t } = useTranslation('deal')

  switch (status) {
    case SignStatus.INACTIVE:
      return <StatusDescription status={status}>{t('contractSign.waitingPrevStep')}</StatusDescription>
    case SignStatus.ACTIVE:
      return (
        <TextWrapper>
          <StatusDescription status={status}>
            {t('contractSign.status')} {t('contractSign.verifiedUsersNotReady')}
          </StatusDescription>
        </TextWrapper>
      )
    default:
      return (
        <TextWrapper>
          <StatusDescription status={status}>
            {t('contractSign.status')}{' '}
            <AccentDescription status={status}>{t('contractSign.verifiedUsersIsReady')}</AccentDescription>
          </StatusDescription>
        </TextWrapper>
      )
  }
}

const LogisticBody: FC<LogisticBodyProps> = ({ status, deal, dealRefetch }) => {
  const { t } = useTranslation('deal')
  const { formHandler, formProgress } = useAFormHandler('approveLogistics', { id: deal?.id })
  const {
    deal_type,
    quantity,
    distance,
    sale_price,
    purchase_price,
    price_logistics,
    price_carrier,
    paid_sum,
    fixed_margin,
  } = deal

  const formik = useFormik({
    initialValues: {
      deal_type,
      quantity,
      distance,
      sale_price,
      purchase_price,
      price_logistics,
      price_carrier,
      paid_sum,
      fixed_margin,
      days_for_export: null,
      price_exw: sale_price, // depricated
      price_cpt: purchase_price, // depricated
    },
    onSubmit: formHandler(async () => {
      await apiClient.put<DealDTO>(endpoints.deal(deal.id), formik.values)
      await apiClient.post(endpoints.dealChangeApprovalStatus(deal.id), {
        new_approval_status: ApprovalStatus.APPROVED_BY_LOGISTICIANS,
      })
      dealRefetch()
    }),
  })

  switch (status) {
    case SignStatus.INACTIVE:
      return <StatusDescription status={status}>{t('contractSign.waitingPrevStep')}</StatusDescription>
    case SignStatus.DONE:
      return (
        <>
          <TextWrapper>
            <StatusDescription status={status}>
              {t('contractSign.status')}{' '}
              <AccentDescription status={status}>{t('contractSign.statusApproved')}</AccentDescription>
            </StatusDescription>
          </TextWrapper>

          <TextWrapper>
            <StatusDescription status={status}>
              {t('contractSign.daysForExport')}{' '}
              <AccentDescription status={status}>
                {t('contractSign.daysForExportCount', { daysForExport: deal.days_for_export })}
              </AccentDescription>
            </StatusDescription>
          </TextWrapper>

          <LogisticDescription deal={deal} status={status} />
        </>
      )
    default:
      return (
        <>
          <InputWrapper>
            <FormInput
              label={t('contractSign.exportDays')}
              field="days_for_export"
              formik={formik}
              size={'small'}
              type="number"
            />
          </InputWrapper>

          <LogisticInputs formik={formik} />

          <AButton
            id="contactSignApprove"
            onClick={() => formik.submitForm()}
            intent="primary"
            size="medium"
            disabled={!formik.dirty}
            progress={formProgress}
          >
            {t('contractSign.approve')}
          </AButton>
        </>
      )
  }
}

const SignBody: FC<SignBodyProps> = ({ status, deal, dealRefetch }) => {
  const { t } = useTranslation('deal')

  const onHandleClick = async () => {
    await apiClient.post(endpoints.dealChangeApprovalStatus(deal.id), {
      new_approval_status: ApprovalStatus.APPROVED_BY_DIRECTOR,
    })
    dealRefetch()
  }

  switch (status) {
    case SignStatus.INACTIVE:
      return <StatusDescription status={status}>{t('contractSign.waitingPrevStep')}</StatusDescription>
    case SignStatus.DONE:
      return (
        <TextWrapper>
          <StatusDescription status={status}>
            {t('contractSign.status')}{' '}
            <AccentDescription status={status}>{t('contractSign.statusSigned')}</AccentDescription>
          </StatusDescription>
        </TextWrapper>
      )
    default:
      return (
        <>
          <SignActiveStatusDescription status={status}>{t('contractSign.waitingSign')}</SignActiveStatusDescription>
          <AButton id="contractSign" onClick={onHandleClick} intent="primary" size="medium">
            {t('contractSign.sign')}
          </AButton>
        </>
      )
  }
}

export const DealContractSign: FC<{
  deal: Deal
  dealRefetch: refetchFunc
}> = ({ deal, dealRefetch }) => {
  const { t } = useTranslation('deal')

  const approvalStatus = deal?.approval_status

  const [stepsStatus, setStepsStatus] = useState<{ users: SignStatus; logistic: SignStatus; sign: SignStatus }>({
    users: SignStatus.INACTIVE,
    logistic: SignStatus.INACTIVE,
    sign: SignStatus.INACTIVE,
  })

  useEffect(() => {
    const statuses = getContractSignStatuses(deal)

    setStepsStatus(statuses)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [approvalStatus])

  const steps = [
    {
      key: 'logistic',
      title: t('contractSign.approvedByLogistTitle'),
      icon: <IconAccount status={stepsStatus.logistic} />,
      body: <LogisticBody status={stepsStatus.logistic} deal={deal} dealRefetch={dealRefetch} />,
    },
    {
      key: 'users',
      title: t('contractSign.verifiedUsersTitle'),
      icon: <IconCheckMark status={stepsStatus.users} />,
      body: <UsersBody status={stepsStatus.users} />,
    },
    {
      key: 'sign',
      title: t('contractSign.signedTitle'),
      icon: <IconSign status={stepsStatus.sign} />,
      body: <SignBody status={stepsStatus.sign} deal={deal} dealRefetch={dealRefetch} />,
      isLastItem: true,
    },
  ]

  return (
    <StepsColumn>
      {steps.map((step) => (
        <Step
          key={step.key}
          title={step.title}
          status={stepsStatus[step.key]}
          icon={step.icon}
          body={step.body}
          isLastItem={!!step.isLastItem}
        />
      ))}
    </StepsColumn>
  )
}
