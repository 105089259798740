import { Description, WrapperFormControl, DescriptionWrapper, WrapperOption, DescriptionOption } from './styled'
import { getPersonalNumberLabel } from 'modules/utils/numbers/formatPersonalNumber'
import { TableTextLink } from 'views/ui/TableTextLink/TableTextLink'
import { OptionType } from '@agro-club/agroclub-shared'
import { Company } from 'modules/domain/company/types'
import CompanyRoutes from 'views/pages/Company/routes'
import FormSelectShared from '../FormSelectShared'
import { KeyValue } from '../KeyValue/KeyValue'
import { useTranslation } from 'react-i18next'
import { endpoints } from 'modules/endpoints'
import { generatePath } from 'react-router'
import { FC, useState } from 'react'
import { FormikFieldsType } from 'modules/domain/types'

interface Props {
  formik: FormikFieldsType
  showHelpText?: boolean
  showInn?: boolean
  allowClear?: boolean
  onChange?: (e: any) => void
  required?: boolean
}

export const FormCompany: FC<Props> = ({ formik, showHelpText, showInn, allowClear, onChange, required }) => {
  const { t } = useTranslation()
  const [value, setValue] = useState<Company | undefined>()

  const Option: FC<{ option: OptionType }> = ({ option }) => {
    const data = option.dto
    const description = `${getPersonalNumberLabel()}: ${data.inn || t('common:notSpecified')}`

    return (
      <WrapperOption>
        <div>{data.name}</div>
        <DescriptionOption>{description}</DescriptionOption>
      </WrapperOption>
    )
  }

  return (
    <WrapperFormControl>
      <FormSelectShared
        required={required}
        placeholder={t('common:company_placeholder')}
        endpoint={endpoints.company()}
        label={t('common:company')}
        OptionComponent={Option}
        getLabel={(dto) => dto.name}
        searchField="search"
        fieldName="company"
        formik={formik}
        allowClear={allowClear}
        onSelectedOptionLoaded={(e) => setValue(e.dto)}
        onClear={() => setValue(undefined)}
        onChange={onChange}
      />
      {showHelpText && (
        <Description>
          {t('user:form.helpText')}{' '}
          <TableTextLink id="companyLink" to={generatePath(CompanyRoutes.List)}>
            {t('company:companyMetaTitle')}
          </TableTextLink>
        </Description>
      )}
      {showInn && value && (
        <DescriptionWrapper>
          <KeyValue label={`${getPersonalNumberLabel()}:`}>{value?.inn || t('common:notSpecified')}</KeyValue>
        </DescriptionWrapper>
      )}
    </WrapperFormControl>
  )
}
