import { ASharedModal, SharedModalContent, SharedModalTitle } from 'views/components/SharedModal'
import { useTranslation } from 'react-i18next'
import ADefaultModalFooter from 'views/components/DefaultModalFooter/ADefaultModalFooter'
import { useAProgress } from 'hooks/useAProgress'
import { endpoints } from 'modules/endpoints'
import { useParams } from 'react-router-dom'
import { refetchFunc } from 'modules/domain/common/hooks'
import { apiClient } from 'modules/utils/httpClient'
import { FC } from 'react'

type Props = {
  onClose: () => void
  loadItemId: string
  refetch: refetchFunc
}

export const RemoveFarmerLoadModal: FC<Props> = ({ onClose, loadItemId, refetch }) => {
  const { id } = useParams()
  const { t } = useTranslation('deal')

  const [progress, removeHandler] = useAProgress(
    () => apiClient.delete(endpoints.dealSingleFarmerLoading(id, loadItemId)),
    {
      eventName: 'delete',
      analyticsPayload: {
        id: loadItemId,
      },
      onSuccess: async () => {
        await refetch(true)
        onClose()
      },
    },
  )

  return (
    <ASharedModal id="removeRegionalFromFarmerLoading" size="small" onClose={onClose}>
      <SharedModalTitle>{t('accordion.loading.farmerRemoveModalTitle')}</SharedModalTitle>
      <SharedModalContent>{t('accordion.loading.farmerRemoveModalBody')}</SharedModalContent>
      <ADefaultModalFooter onClose={onClose} progress={progress} onConfirm={removeHandler} />
    </ASharedModal>
  )
}
