import styled from 'styled-components'
import { Icons, defaultTheme, Typography } from '@agro-club/agroclub-shared'
import { useTranslation } from 'react-i18next'

const Completed = styled.div`
  color: ${defaultTheme.color.accentApprove600};
  ${Typography.bodyLarge}
  display: flex;
  align-items: center;

  svg path {
    fill: ${defaultTheme.color.accentApprove600};
  }
`

export const CompletedStatus = () => {
  const { t } = useTranslation('common')

  return (
    <Completed>
      {t('completed')}&nbsp;
      <Icons.IconCheck />
    </Completed>
  )
}
