import { DocumentWithTypeFile } from 'modules/domain/types'
import { apiClient } from 'modules/utils/httpClient'
import { endpoints } from 'modules/endpoints'

export const uploadDocuments = (contractId: string, document_type: string, file: File, dealId?: string) => {
  const form = new FormData()
  form.append('uploaded_file', file)
  form.append('document_type', document_type)

  if (dealId) {
    form.append('deal_id', dealId)
  }
  return apiClient.post<DocumentWithTypeFile>(endpoints.contractDocuments(contractId), form)
}

export const deleteDocumentFile = (id: string, document_id: string) =>
  apiClient.delete(endpoints.contractDeleteDocument(id, document_id))
