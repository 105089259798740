import { FunctionComponent, MouseEvent, useCallback, useEffect, useRef, useState } from 'react'
import { useAudioPosition } from 'react-use-audio-player'

import { SeekBarStyled, SeekBarStyledWrapper, SeekBarTick, Time } from 'views/components/Player/styled'

interface Props {
  isActive?: boolean
  duration?: number
}

const formatDuration = (total_seconds: number): string => {
  const minutes = Math.floor(total_seconds / 60)
  const seconds = Math.floor(total_seconds % 60)
    .toString()
    .padStart(2, '0')

  return `${minutes}:${seconds}`
}

export const PlayerSeekBar: FunctionComponent<Props> = ({ isActive, duration }) => {
  const { duration: fileDuration, seek, percentComplete, position } = useAudioPosition()

  const [barWidth, setBarWidth] = useState('0%')

  const seekBarElem = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setBarWidth(`${isActive ? percentComplete : 0}%`)
  }, [percentComplete, isActive])

  const goTo = useCallback(
    (event: MouseEvent) => {
      if (!isActive) {
        return
      }
      const { pageX: eventOffsetX } = event

      if (seekBarElem.current) {
        const elementOffsetX = window.pageXOffset + seekBarElem.current.getBoundingClientRect().left
        const elementWidth = seekBarElem.current.clientWidth
        const percent = (eventOffsetX - elementOffsetX) / elementWidth
        seek(percent * fileDuration)
      }
    },
    [fileDuration, seek, isActive],
  )

  return (
    <SeekBarStyledWrapper>
      <Time>{formatDuration(isActive ? position : 0)}</Time>
      <SeekBarStyled ref={seekBarElem} onClick={goTo}>
        <SeekBarTick style={{ width: barWidth }} isActive={isActive} />
      </SeekBarStyled>
      <Time>{formatDuration(duration || fileDuration)}</Time>
    </SeekBarStyledWrapper>
  )
}
