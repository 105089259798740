import { CustomScroll, HoverScrolling } from 'views/styled/common'
import { css } from 'styled-components'

export const CollapseWrapperStyles = css`
  position: relative;
  margin-right: 24px;
  max-height: calc(100dvh - 160px);

  .ant-collapse .ant-collapse-item-disabled > .ant-collapse-header {
    cursor: auto;
  }

  .ant-collapse-content-box {
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0 16px 0 16px;
    margin-right: 2px;
    box-shadow: inset 0px 20px 5px -15px rgba(39, 45, 51, 0.08);

    ${CustomScroll}
    ${HoverScrolling}
  }
`
