import { FiltersAndSearchWrapper, FiltersWrapper } from 'views/components/CommonTableComponents/CommonTableComponents'
import { ADateRangePicker } from 'views/components/TableFilters/DateRangePicker/ADateRangePicker'
import { AClearFiltersButton, ASelectFilter } from 'views/components/Analytics'
import { SearchFilter } from 'views/components/SearchFilter/SearchFilter'
import { StatusOrder } from 'modules/domain/logisticsKR/types'
import { useDisplayClearBtn } from 'hooks/useDisplayClearBtn'
import { ListRequestParams } from 'modules/domain/types'
import { useTranslation } from 'react-i18next'
import { FC } from 'react'

type Props = {
  updateFilterState: (filterObj: Record<string, any>) => void
  listRequestParams: ListRequestParams
  clearAllTabsState: () => void
}

export const Filters: FC<Props> = ({ updateFilterState, listRequestParams, clearAllTabsState }) => {
  const displayClearBtn = useDisplayClearBtn({
    listRequestParams,
  })
  const { t } = useTranslation('paymentOrders')

  const options = [
    {
      value: StatusOrder.paid,
      label: t('paid'),
    },
    {
      value: StatusOrder.unpaid,
      label: t('unpaid'),
    },
  ]

  return (
    <FiltersAndSearchWrapper>
      <FiltersWrapper>
        <ADateRangePicker
          onChange={(value) => updateFilterState({ start_date: value.startDate, end_date: value.endDate })}
          value={[listRequestParams.filter.start_date, listRequestParams.filter.end_date]}
          id="date"
        />
        <ASelectFilter
          onChange={(status) => updateFilterState({ status })}
          value={listRequestParams.filter.status}
          placeholder={t('status')}
          showSearch={false}
          options={options}
          id="status"
        />

        {displayClearBtn && <AClearFiltersButton onClick={clearAllTabsState} />}
      </FiltersWrapper>
      <SearchFilter listRequestParams={listRequestParams} updateFilterState={updateFilterState} />
    </FiltersAndSearchWrapper>
  )
}
