import { DispatchingDrawer } from 'views/pages/Dispatching/components/DispatchingDrawer/DispatchingDrawer'
import { SERVER_DATE_FORMAT, defaultTheme, useHelmet } from '@agro-club/agroclub-shared'
import { DispatchingFilters } from './components/Filters/DispatchingFilters'
import APageWrapper from 'views/components/PageWrapper/APageWrapper'
import { DispatchingContent } from './components/DispatchingContent'
import { AnalyticsPages, AnalyticsPlaces } from './analyticsPlaces'
import { useSingleEntity } from 'modules/domain/common/hooks'
import { Tab, useTabsDataV2 } from 'hooks/useTabsDataV2'
import { AAntdTabs } from 'views/components/Analytics'
import useClosedColumns from './useClosedColumns'
import { FC, useCallback, useState } from 'react'
import * as Layout from 'views/layouts/NewLayout'
import * as Header from 'views/ui/Header/Header'
import { useMediaQuery } from 'react-responsive'
import { useTranslation } from 'react-i18next'
import { endpoints } from 'modules/endpoints'
import { useTab } from 'hooks/useTabsDataV2'
import { DispatchingTypes } from './types'
import useActiveTab from './useActiveTab'
import useColumns from './useColumns'
import { format } from 'date-fns'

const openPageSize = 1000
const closedPageSize = 20

const DispatchingList: FC = () => {
  const { t } = useTranslation('dispatching')
  useHelmet({ title: t('heading') })
  const currentDate = format(new Date(), SERVER_DATE_FORMAT)
  const [drawerIsOpen, setDrawerIsOpen] = useState(false)
  const [selectedDealId, setSelectedDealId] = useState('')
  const [activeTab, setActiveTab] = useActiveTab()
  const tabs: Record<string, Tab> = {}
  tabs[DispatchingTypes.open] = useTab({
    label: t('tabs.open'),
    nonEditableParams: {
      pageSize: openPageSize,
    },
    endpoint: endpoints.dispatching(openPageSize),
    columnsFunc: useColumns,
  })
  tabs[DispatchingTypes.closed] = useTab({
    label: t('tabs.closed'),
    nonEditableParams: {
      pageSize: closedPageSize,
    },
    endpoint: endpoints.dispatchingClosed(),
    columnsFunc: useClosedColumns,
  })

  const { isFiltersLoaded, commonRequestParams, updateCommonFilterState, clearAllTabsState } = useTabsDataV2({
    activeTab,
    tabs,
    initialCommonRequestParams: { filter: { date: currentDate } },
    excludeFilters: { [DispatchingTypes.closed]: ['status'] },
  })

  const [, currentDates] = useSingleEntity<string[]>(
    endpoints.dispatchingDates(commonRequestParams.filter.date || currentDate),
  )

  const onSuccess = useCallback(() => {
    updateCommonFilterState(commonRequestParams.filter)
  }, [commonRequestParams, updateCommonFilterState])

  const dispatchingTabsItems = () => {
    const tabsJsx: any[] = []
    for (const key of Object.keys(tabs)) {
      tabsJsx.push({
        label: tabs[key].label,
        key,
        children: (
          <DispatchingContent
            listRequestParams={tabs[key].params}
            isFiltersLoaded={isFiltersLoaded}
            setListRequestParams={tabs[key].setter}
            // TODO fix it
            dataEndpoint={tabs[key].endpoint || ''}
            columnsFunc={tabs[key].columnsFunc}
            datesArray={currentDates}
            pageSize={tabs[key].params.pageSize ?? closedPageSize}
            onSuccess={onSuccess}
            setSelectedDealId={setSelectedDealId}
            setDrawerIsOpen={setDrawerIsOpen}
          />
        ),
      })
    }
    return tabsJsx
  }

  const isMobile = useMediaQuery({
    query: `(max-width: ${defaultTheme.queries.mobile})`,
  })

  return (
    <APageWrapper page={AnalyticsPages.DISPACHING} place={AnalyticsPlaces.LIST}>
      <Layout.WrapperContent>
        <Layout.Header>
          {!isMobile && (
            <Layout.TopHeader>
              <Header.Breadcrumbs routes={[{ breadcrumbName: t('heading') }]} />
            </Layout.TopHeader>
          )}
          <Layout.PageName>{t('heading')}</Layout.PageName>

          <DispatchingFilters
            listRequestParams={tabs[activeTab].params}
            updateFilterState={updateCommonFilterState}
            commonRequestParams={commonRequestParams}
            clearAllTabsState={clearAllTabsState}
            activeTab={activeTab}
          />
        </Layout.Header>

        <AAntdTabs
          id="tabs"
          items={dispatchingTabsItems()}
          onChange={(dispatchingType) => setActiveTab(dispatchingType)}
          activeKey={activeTab}
        />
      </Layout.WrapperContent>
      <DispatchingDrawer
        drawerIsOpen={drawerIsOpen}
        setDrawerIsOpen={setDrawerIsOpen}
        dealId={selectedDealId}
        refetch={onSuccess}
      />
    </APageWrapper>
  )
}

export default DispatchingList
