import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Typography } from '@agro-club/agroclub-shared'
import styled from 'styled-components'
import { Executor } from 'modules/domain/deal/types'
import { getOtherRegionalsCount, isOneRegionalForAllJobs } from 'views/pages/Deal/DealDetail/helpers'
import { QuestionTooltip } from 'views/components/QuestionTooltip/QuestionTooltip'

const InfoWrapper = styled.div`
  ${Typography.buttonLarge};
  display: inline-flex;
  align-items: center;
  column-gap: 12px;
`

export const RemainingCountAndTooltip: FC<{ executors: Executor[] }> = ({ executors }) => {
  const { t } = useTranslation('executors')
  const counter = getOtherRegionalsCount(executors)

  if (executors.length <= 1 || isOneRegionalForAllJobs(executors) || !counter) return null

  return (
    <InfoWrapper>
      <div>{t('and_also', { counter })}</div>
      <QuestionTooltip
        size="compact"
        tooltipContent={executors.map((el) => {
          return (
            <div key={el.work_type || el.id}>
              {el.work_type ? `${t(el.work_type)}: ` : ''}
              {el.full_name}
            </div>
          )
        })}
      />
    </InfoWrapper>
  )
}
