import { FC, useEffect, useState } from 'react'

import { useTableData } from 'modules/domain/common/hooks'
import { endpoints } from 'modules/endpoints'
import { defaultListRequestParams } from 'views/components/TableFilters/data'
import { useTranslation } from 'react-i18next'
import { LogisticStatuses, RequestType } from '../../../../types'
import { AntCollapse } from '@agro-club/agroclub-shared'
import { Requests } from './Requests'
import { LogisticRequest } from 'modules/domain/logisticsKR/types'
import { MultipleColumnWrapper } from '../CarrierBoard'

type RequestItems = {
  internal: LogisticRequest[]
  external: LogisticRequest[]
  archived: LogisticRequest[]
}

type Props = {
  carrier: string
  car: string
  onSelectedChanged: (request: LogisticRequest) => void
  activeRequestId: string | undefined
}

export const RequestsColumn: FC<Props> = ({ carrier, car, onSelectedChanged, activeRequestId }) => {
  const { t } = useTranslation('logisticsKR')
  const [listRequestParams, setListRequestParams] = useState({
    ...defaultListRequestParams,
    filter: { carrier, car },
    pageSize: 1000,
  })
  const { progress, data: requests } = useTableData(
    endpoints.LogisticsKR.requests(),
    listRequestParams,
    !!listRequestParams.filter.car,
  )
  const [items, setItems] = useState<RequestItems>({ internal: [], external: [], archived: [] })

  useEffect(() => {
    if (!requests) return
    const trasformed: RequestItems = {
      internal: requests.filter(
        (c) => c.source === RequestType.internal && c.logistics_status !== LogisticStatuses.shipment_complete,
      ),
      external: requests.filter(
        (c) => c.source === RequestType.external && c.logistics_status !== LogisticStatuses.shipment_complete,
      ),
      archived: requests.filter((c) => c.logistics_status === LogisticStatuses.shipment_complete),
    }
    if (trasformed.internal.length) {
      onSelectedChanged(trasformed.internal[0])
    }
    setItems(trasformed)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requests])

  useEffect(() => {
    setListRequestParams((prev) => ({ ...prev, filter: { carrier, car } }))
  }, [car, carrier])

  return (
    <MultipleColumnWrapper collapseAmount={3}>
      <AntCollapse defaultActiveKey={[RequestType.internal]} noMarginTop accordion bordered={false}>
        <Requests
          activeRequestId={activeRequestId}
          counter={items.internal.length}
          key={RequestType.internal}
          onSelectedChanged={onSelectedChanged}
          progress={progress}
          requests={items.internal}
          title={t('carriers.requests_column.internal')}
        />
        <Requests
          activeRequestId={activeRequestId}
          counter={items.external.length}
          key={RequestType.external}
          onSelectedChanged={onSelectedChanged}
          progress={progress}
          requests={items.external}
          title={t('carriers.requests_column.external')}
        />
        <Requests
          activeRequestId={activeRequestId}
          counter={items.archived.length}
          key={RequestType.archived}
          onSelectedChanged={onSelectedChanged}
          progress={progress}
          requests={items.archived}
          title={t('carriers.requests_column.archived')}
          tagColor="grey"
        />
      </AntCollapse>
    </MultipleColumnWrapper>
  )
}
