import { ColumnsType } from '@agro-club/agroclub-shared'
import { DASH } from 'modules/constants'
import { Warehouse } from 'modules/domain/warehouses/types'
import formatNumber from 'modules/utils/numbers/formatNumber'
import { formatQuantity, labelQuantity } from 'modules/utils/numbers/formatQuantity'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router'
import { ARouterLink } from 'views/components/Analytics'
import UserRoutes from 'views/pages/User/routes'
import APhone from 'views/ui/Phone/APhone'
import { TableTextLink } from 'views/ui/TableTextLink/TableTextLink'
import WarehousesRoutes from '../routes'

export const useColumns = (): ColumnsType<Warehouse> => {
  const { t } = useTranslation('warehouses')

  return useMemo(
    () => [
      {
        title: t('warehouse'),
        dataIndex: 'id',
        width: '394px',
        render: (id, data) => (
          <TableTextLink id="warehouse" to={generatePath(WarehousesRoutes.Details, { id })} key={id}>
            {data.title}
          </TableTextLink>
        ),
      },
      {
        title: t('address'),
        dataIndex: 'address',
        width: '394px',
        render: (address) => address?.address || DASH,
      },

      {
        title: t('user'),
        dataIndex: 'owner',
        width: '394px',
        render: (owner) => (
          <div>
            <ARouterLink
              eventName="owner"
              itemId={owner.id}
              to={generatePath(UserRoutes.Details, { id: owner.id })}
              bodysize="bodyMedium"
              key={owner.id}
            >
              {owner?.profile?.full_name}
            </ARouterLink>
            <div>
              <APhone phone={owner.phone} className="phone-link-table" />
            </div>
          </div>
        ),
      },
      {
        title: t('cropInWarehouse'),
        dataIndex: 'products_remaining',
        width: '394px',
        render: (products_remaining) =>
          products_remaining.length
            ? products_remaining
                .map((product) => `${product.product_title} (${formatQuantity(product.total_quantity)})`)
                .join(', ')
            : DASH,
      },
      {
        title: labelQuantity(t('commonQuantityInWarehouse')),
        dataIndex: 'total_quantity',
        align: 'right',
        width: '192px',
        render: (total_quantity) => formatNumber(total_quantity),
      },
    ],
    [t],
  )
}
