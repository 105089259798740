import { useTranslation } from 'react-i18next'
import { Card } from 'views/components/Card/Card'
import styled from 'styled-components'
import { Text } from '@agro-club/agroclub-shared'

const BodyWrapper = styled.div`
  margin-top: 4px;
`

type Props = {
  dontCallReason: string
}

export const DontDisturbWarning = ({ dontCallReason }: Props) => {
  const { t } = useTranslation('my_tasks')
  const description = `${t('user_mark')} ${t(dontCallReason)}`

  return (
    <Card.Container disableShadow intent="warning">
      <Text typography="accentLarge">{t('note_to_self')}</Text>
      <BodyWrapper>
        <Text typography="bodyMedium" color="secondary">
          {description}
        </Text>
      </BodyWrapper>
    </Card.Container>
  )
}
