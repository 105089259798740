import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Icons } from '@agro-club/agroclub-shared'
import { ACCORDION_ITEM_PAGE_SIZE } from 'modules/constants'
import { refetchFunc, usePagination } from 'modules/domain/common/hooks'
import { Carrier } from 'modules/domain/logisticsKR/types'
import { Driver } from 'modules/domain/types'
import styled from 'styled-components'
import { AButton } from 'views/components/Analytics'
import { Pagination } from 'views/components/Pagination/Pagination'
import { ListFooterWrapper } from 'views/styled/common'
import { DriverAddModal } from '../../../../components/DriverModals/DriverAddModal'
import { DriverItem } from './DriverItem'

const ItemList = styled.div`
  margin-bottom: 12px;
`

interface Props {
  carrier: Carrier
  drivers: Driver[]
  refetch: refetchFunc
}

export const Drivers: FC<Props> = ({ carrier, drivers, refetch }) => {
  const { t } = useTranslation('logisticsKR')
  const [driversCurrentPage, setDriversCurrentPage, driversCurrentItems] = usePagination(
    drivers,
    ACCORDION_ITEM_PAGE_SIZE,
  )

  const [isModalOpened, setIsModalOpened] = useState(false)

  return (
    <div>
      {!!driversCurrentItems?.length && (
        <ItemList>
          {driversCurrentItems.map((driver) => (
            <DriverItem key={driver.id} driver={driver} carrierId={carrier.id} refetch={refetch} />
          ))}
        </ItemList>
      )}
      <ListFooterWrapper>
        <AButton id="addDriver" intent="approve" onClick={() => setIsModalOpened(true)} Icon={Icons.IconAdd}>
          {t('carsDetails.buttons.addCar')}
        </AButton>
        <Pagination
          disableMargin
          total={drivers?.length || 0}
          currPage={driversCurrentPage}
          setCurrPage={setDriversCurrentPage}
        />
      </ListFooterWrapper>
      {isModalOpened && (
        <DriverAddModal setIsOpen={setIsModalOpened} carrierId={carrier.id} onSuccess={() => refetch()} />
      )}
    </div>
  )
}
