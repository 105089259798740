import { Route, Routes } from 'react-router-dom'
import { WhatsAppList } from './List/WhatsAppList'

function WhatsAppPage() {
  return (
    <Routes>
      <Route index element={<WhatsAppList />} />
    </Routes>
  )
}

export default WhatsAppPage
