import { FC } from 'react'

import { formatQuantity } from 'modules/utils/numbers/formatQuantity'
import { useTranslation } from 'react-i18next'
import useFormatDate from 'hooks/useFormatDate'

export type ChangesHistoryField = 'quantity' | 'delivery_basis' | 'number' | 'contract_date'

export type ChangesHistoryRecord = {
  changes: Record<ChangesHistoryField, number | string | null>
}

export const HistoryChangesCell: FC<{ record: ChangesHistoryRecord }> = ({ record }) => {
  const { t } = useTranslation('export')
  const formatDate = useFormatDate()

  const formatters: Record<ChangesHistoryField, (v) => string> = {
    quantity: (v) => (v ? formatQuantity(v) : ''),
    delivery_basis: (v) => (v ? t(`${v}`) : ''),
    number: (v) => (v ? `№ ${v}` : ''),
    contract_date: (v) => (v ? formatDate(v) : ''),
  }

  const entries: string[] = Object.keys(formatters)
    .map((k) => formatters[k](record.changes[k]))
    .filter(Boolean)

  return (
    <>
      <div>{entries.join(', ')}</div>
    </>
  )
}
