import AntCollapseHeader from 'views/components/AntCollapseHeader/AntCollapseHeader'
import { WrapperCollapse } from 'views/pages/User/UserDetail/UserRightPanel/styled'
import { AntCollapse, Panel } from '@agro-club/agroclub-shared'
import AComments from 'views/components/Comments/AComments'
import { useTranslation } from 'react-i18next'
import { endpoints } from 'modules/endpoints'
import { FC, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Files } from '../Files/Files'
import { SummaryComments } from 'views/components/AntCollapseHeader/SummaryComments'
import { SummaryCount } from 'views/components/AntCollapseHeader/SummaryCount'

export const RightBlock: FC = () => {
  const { t } = useTranslation('bidContract')
  const { id } = useParams()

  const [commentsSummary, setCommentsSummary] = useState<string>('')
  const [filesSummary, setFilesSummary] = useState<number>()

  const options = useMemo<{ title: string; value: string; isOpened?: boolean; isShow?: boolean }[]>(
    () => [
      { value: 'comments', title: t('common:comments'), isOpened: true, isShow: true },
      { value: 'files', title: t('common:files'), isOpened: false, isShow: true },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t],
  )

  const components = {
    comments: (
      <AComments url={endpoints.contractComments(id)} popSummary={setCommentsSummary} id={id} maxHeight={400} />
    ),
    files: <Files setSummary={setFilesSummary} />,
  }

  const summary = {
    comments: <SummaryComments summary={commentsSummary} />,
    files: <SummaryCount total={filesSummary} />,
  }
  const [activeKey, setActiveKey] = useState<string | string[]>(options[0].value)

  return (
    <WrapperCollapse>
      <AntCollapse
        onChange={(key) => setActiveKey(key)}
        defaultActiveKey={options[0].value}
        style={{ width: 566 }}
        bordered={false}
        accordion
      >
        {options.map((option) => {
          return (
            <Panel
              showArrow={true}
              forceRender
              key={option.value}
              header={
                <AntCollapseHeader title={option.title} summary={activeKey !== option.value && summary[option.value]} />
              }
            >
              {components[option.value]}
            </Panel>
          )
        })}
      </AntCollapse>
    </WrapperCollapse>
  )
}
