import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { endpoints } from 'modules/endpoints'
import { FormikProps } from 'formik'
import FormSelectShared from '../../FormSelectShared'
import { isUS } from 'env'

type Props = {
  formik: FormikProps<any>
}

export const FormMarketZone: FC<Props> = ({ formik }) => {
  const { t } = useTranslation('address')
  if (!isUS) return
  return (
    <FormSelectShared
      placeholder={t('market_zone')}
      endpoint={endpoints.marketZones()}
      label={t('market_zone')}
      getLabel={(dto) => dto.name}
      searchField="search"
      fieldName="market_zone"
      formik={formik}
      allowClear
      simpleApiFormat
    />
  )
}
