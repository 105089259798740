import { SharedModalTitle } from 'views/components/SharedModal'
import { BidTypes, BidContract } from 'modules/domain/bid/types'
import { defaultTheme } from '@agro-club/agroclub-shared'
import { ContractsColumn } from './Columns/ContractsColumn'
import { DealColumn } from './Columns/DealColumn'
import { RematchingModalContent, RematchingModal } from './styled'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useRematching, useRematchingSync } from '../../hooks/useRematching'

type Props = {
  onClose: () => void
}

export const RematchModal: FC<Props> = ({ onClose }) => {
  const sale = useRematching({ bidType: BidTypes.sale })
  const purchase = useRematching({ bidType: BidTypes.purchase })
  const { t } = useTranslation('deal')

  useRematchingSync(sale, purchase)
  useRematchingSync(purchase, sale)

  const resetParams = () => {
    sale.reset()
    purchase.reset()
  }

  const style = {
    background: defaultTheme.color.backgroundPrimary,
  }

  return (
    <RematchingModal id="rematch" onClose={onClose} size="extraLarge" contentStyle={style}>
      <SharedModalTitle>{t('rematch.newDeal')}</SharedModalTitle>
      <RematchingModalContent>
        <ContractsColumn main={sale} secondary={purchase} title={t('bid_type.sale')} />
        <ContractsColumn main={purchase} secondary={sale} title={t('bid_type.purchase')} />
        <DealColumn
          purchaseContract={purchase.bid as BidContract}
          saleContract={sale.bid as BidContract}
          reset={resetParams}
        />
      </RematchingModalContent>
    </RematchingModal>
  )
}
