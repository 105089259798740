import { useEffect } from 'react'

import { SharedModalContent, SharedModalInputs, SharedModalTitle } from 'views/components/SharedModal'
import { useTranslation } from 'react-i18next'
import { USBid, USContractType } from 'modules/domain/bid/types'
import { DateISO, FormGroup, Input, Progress, useHelmet } from '@agro-club/agroclub-shared'
import { apiClient } from 'modules/utils/httpClient'
import { endpoints } from 'modules/endpoints'
import { RequestError } from 'modules/errors'
import { ShortFormControl } from 'views/styled/common'
import { SeasonSelect } from 'views/pages/Bid/components/SeasonSelect'
import { QuantityInput } from 'views/components/QuantityInput/QuantityInput'
import { useAFormHandler } from 'analytics/hooks'
import ASharedModal from 'views/components/SharedModal/ASharedModal'
import ADefaultModalFooter from 'views/components/DefaultModalFooter/ADefaultModalFooter'
import { useFormik } from 'formik'
import { refetchFunc } from 'modules/domain/common/hooks'
import { labelTarif } from 'modules/utils/numbers/formatTarif'
import { DatePicker } from 'views/components/DatePicker/DatePicker'
import { DateRangePicker } from 'views/components/DateRangePicker/DateRangePicker'
import { AnalyticsPlaces } from 'views/pages/Bid/analyticsPlaces'
import { getAndSetCashPrice } from 'views/pages/Bid/BidModals/Bid/USModals/helpers'
import { useNotification } from 'hooks/useNotification'
import { isDeliveryAddressRequired } from 'views/pages/Bid/helpers'
import { USCommonFields } from './USCommonFields'

type Props = {
  onClose: () => void
  refetch: refetchFunc
  bid: USBid
}

export const USBidEditModal = ({ onClose, refetch, bid }: Props) => {
  const { formProgress, formHandler } = useAFormHandler()
  const { t } = useTranslation('bid')
  const notify = useNotification()

  useHelmet({ title: `${t('form.edit')} - ${bid.product?.title}` })

  const formik = useFormik({
    initialValues: {
      price: bid.price,
      quantity: bid.quantity,
      owner_delivery_distance: bid.owner_delivery_distance,
      season: bid.season?.id,
      symbol: bid.symbol,
      basis: bid.basis,
      basis_expiration: bid.basis_expiration,
      futures_price: bid.futures_price,
      start_date: bid.start_date,
      end_date: bid.end_date,
      expires_at: bid.expires_at,
      parameters: [],
      is_firm: bid.is_firm,
      hedge_ticket: bid.hedge_ticket,
    },

    enableReinitialize: true,
    onSubmit: formHandler(async () => await apiClient.put(endpoints.bid(bid.id), formik.values), {
      onSuccess: async () => {
        await refetch?.(true)
        notify(Progress.SUCCESS, { title: t('form.notifyEditSuccess') })
        onClose()
      },
      onError: (error: RequestError) => {
        const { errors } = error
        formik.setErrors(errors)
      },
    }),
  })

  useEffect(() => {
    if (bid.contract_type === USContractType.basis) {
      getAndSetCashPrice(formik)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.basis, formik.values.futures_price])

  return (
    <ASharedModal onClose={onClose} size="medium" id={AnalyticsPlaces.EDIT_MODAL}>
      <SharedModalTitle>{t('form.bidsParams')}</SharedModalTitle>
      <SharedModalContent>
        <SharedModalInputs>
          <ShortFormControl>
            <USCommonFields formik={formik} />
            {bid.owner.profile?.profile_type &&
              isDeliveryAddressRequired(bid.owner.profile.profile_type, bid.price_type) && (
                <FormGroup label={t('form.distance')} error={formik.errors.owner_delivery_distance}>
                  <Input
                    {...formik.getFieldProps('owner_delivery_distance')}
                    invalid={!!formik.errors.owner_delivery_distance}
                    type="number"
                  />
                </FormGroup>
              )}
            <SeasonSelect formik={formik} product_id={bid.product.id} />
            <FormGroup label={t('form.symbol')} error={formik.errors.symbol}>
              <Input {...formik.getFieldProps('symbol')} invalid={!!formik.errors.symbol} />
            </FormGroup>
            <QuantityInput formik={formik} fieldName="quantity" label={t('common:volume')} required />
            <FormGroup label={labelTarif(t('form.basis'))} error={formik.errors.basis}>
              <Input {...formik.getFieldProps('basis')} invalid={!!formik.errors.basis} type="number" />
            </FormGroup>

            <FormGroup label={t('form.basisExpiration')} error={formik.errors.basis_expiration}>
              <DatePicker
                onChange={(data) => formik.setFieldValue('basis_expiration', data['date'])}
                error={!!formik.errors.basis_expiration}
                placeholder={t('common:datePlaceholder')}
                value={formik.values.basis_expiration}
                allowClear
              />
            </FormGroup>

            <FormGroup label={labelTarif(t('form.futuresPrice'))} error={formik.errors.futures_price}>
              <Input {...formik.getFieldProps('futures_price')} invalid={!!formik.errors.futures_price} type="number" />
            </FormGroup>
            <FormGroup
              label={t('form.deliveryPeriod')}
              error={formik.errors.start_date || formik.errors.end_date}
              required
            >
              <DateRangePicker
                error={formik.errors.start_date || formik.errors.end_date}
                value={[formik.values.start_date, formik.values.end_date] as DateISO[]}
                onChange={({ startDate, endDate }) => {
                  formik.setFieldValue('start_date', startDate)
                  formik.setFieldValue('end_date', endDate)
                }}
              />
            </FormGroup>

            <FormGroup label={labelTarif(t('form.cashPrice'))} error={formik.errors.price}>
              <Input
                {...formik.getFieldProps('price')}
                invalid={!!formik.errors.price}
                type="number"
                disabled={bid.contract_type === USContractType.basis}
              />
            </FormGroup>
            <FormGroup label={t('form.expiredAt')} error={formik.errors.expires_at}>
              <DatePicker
                onChange={(data) => formik.setFieldValue('expires_at', data['date'])}
                error={!!formik.errors.expires_at}
                value={formik.values.expires_at}
                placeholder={t('common:datePlaceholder')}
                allowClear
              />
            </FormGroup>
          </ShortFormControl>
        </SharedModalInputs>
      </SharedModalContent>

      <ADefaultModalFooter
        onClose={onClose}
        confirmButtonText={t('common:save')}
        onConfirm={() => formik.handleSubmit()}
        progress={formProgress}
        disabled={!formik.dirty}
      />
    </ASharedModal>
  )
}
