import { AntdTable, Progress } from '@agro-club/agroclub-shared'
import { ListRequestParams } from 'modules/domain/types'
import { useHistoryColumns } from './useHistoryColumns'
import { FC } from 'react'

type Props = {
  setListRequestParams: (v: ListRequestParams) => void
  listRequestParams: ListRequestParams
  historyProgress: Progress
  historyTotal: number
  historyData: any[]
}

export const HistoryTable: FC<Props> = ({
  setListRequestParams,
  listRequestParams,
  historyProgress,
  historyTotal,
  historyData,
}) => {
  const historyColumns = useHistoryColumns()

  return (
    <AntdTable
      onPageChange={(page) => setListRequestParams({ ...listRequestParams, page })}
      pagination={{ showTotal: undefined, size: 'small' }}
      pageSize={listRequestParams.pageSize}
      page={listRequestParams.page}
      columns={historyColumns}
      progress={historyProgress}
      total={historyTotal}
      data={historyData}
      rowHoverGray
      bordered
    />
  )
}
