import { AntTag } from '@agro-club/agroclub-shared'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { IconUser } from '../../Board/styled'
import { Deal } from 'modules/domain/deal/types'

interface Props {
  deal: Deal
}

export const DirectorTag: FC<Props> = ({ deal }) => {
  const { t } = useTranslation('deal')

  return (
    <AntTag
      icon={<IconUser $approve={deal.is_approved_by_commercial_director} />}
      color={deal.is_approved_by_commercial_director ? 'blue' : 'grey'}
    >
      {t('manager')}
    </AntTag>
  )
}
