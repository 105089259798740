import { FC, useCallback, useEffect, useState } from 'react'
import { FormGroup, Input } from '@agro-club/agroclub-shared'
import { useTranslation } from 'react-i18next'
import { isBrazil, isUS } from 'env'
import { FormikFieldsType } from 'modules/domain/types'

const FRACTION_DIGITS = 1
const KG_IN_BAG = 60

type Value = string | number | undefined

export const formatQuantityNumber = (value: Value): number => parseFloat(Number(value).toFixed(FRACTION_DIGITS))
export const tonsToBags = (tons: Value): number => formatQuantityNumber((Number(tons) * 1000) / KG_IN_BAG)
export const bagsToTons = (bags: Value): number => formatQuantityNumber((Number(bags) * KG_IN_BAG) / 1000)

type Props = {
  formik: FormikFieldsType
  disabled?: boolean
  required?: boolean
  fieldName: string
  label: string
}

export const QuantityInput: FC<Props> = ({ formik, fieldName, label = '', required = false, disabled }) => {
  const [bagsValue, setBagsValue] = useState<number>()
  const { t } = useTranslation('common')

  const fieldValue = formik.values[fieldName]

  useEffect(() => {
    if (isBrazil) {
      setBagsValue(tonsToBags(fieldValue))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldValue])

  const handleQuantityChange = useCallback(
    (e) => {
      const bags = parseFloat(e.target.value)
      setBagsValue(bags)
      formik.setFieldValue(fieldName, bagsToTons(bags))
    },
    [fieldName, formik],
  )

  const inputProps = formik.getFieldProps(fieldName)

  return (
    <>
      <FormGroup label={`${label}, ${isUS ? t('bsh') : t('t')}`} error={formik.errors[fieldName]} required={required}>
        <Input {...inputProps} invalid={!!formik.errors[fieldName]} type="number" min={0} disabled={disabled} />
      </FormGroup>
      {isBrazil && (
        <FormGroup label={`${label}, ${t('bags')}`} error={formik.errors[fieldName]} required={required}>
          <Input
            invalid={!!formik.errors[fieldName]}
            onChange={handleQuantityChange}
            value={bagsValue}
            type="number"
            min={0}
            disabled
          />
        </FormGroup>
      )}
    </>
  )
}
