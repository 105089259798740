import { Topic } from './styled'
import * as Layout from 'views/layouts/NewLayout'

import { useSingleEntity } from 'modules/domain/common/hooks'
import { useTranslation } from 'react-i18next'
import { endpoints } from 'modules/endpoints'
import { RightBlock } from './components/RightBlock'
import { useParams } from 'react-router'
import { Task } from 'views/pages/MyTasks/TaskDetail/types'
import TasksRoutes from 'views/pages/MyTasks/routes'
import { useMarkAsRead } from 'modules/socket/hooks'
import { StatusSwitcher } from './components/StatusSwitcher'
import { AnalyticsPages, AnalyticsPlaces } from '../analyticsPlaces'
import APageWrapper from 'views/components/PageWrapper/APageWrapper'
import { FC } from 'react'
import { useHelmet } from '@agro-club/agroclub-shared'
import { LeftBlock } from './components/LeftBlock/LeftBlock'
import ItemLoadingLayout from 'views/layouts/ItemLoadingLayout/ItemLoadingLayout'
import { TwoColumns } from 'views/styled/detailedPage'

export const TaskDetail: FC = () => {
  const { id } = useParams()
  const { t } = useTranslation('my_tasks')

  const [, task, taskRefetch] = useSingleEntity<Task>(endpoints.getTask(id))

  const loading = () => <ItemLoadingLayout id={id} />

  useMarkAsRead(id, 'task')

  useHelmet({ title: task?.subject })

  if (!task) {
    return loading()
  }

  return (
    <APageWrapper page={AnalyticsPages.TASK_DETAIL} place={AnalyticsPlaces.DETAIL} id={id}>
      <Layout.WrapperContent>
        <Layout.Header>
          <Layout.TopHeader>
            <Layout.Breadcrumbs
              routes={[
                { breadcrumbName: t('my_tasks'), path: TasksRoutes.List },
                { breadcrumbName: task?.subject, key: id },
              ]}
            />
            <StatusSwitcher taskId={id} currentStatus={task?.status} refetch={taskRefetch} />
          </Layout.TopHeader>
        </Layout.Header>
        <Topic>{task?.subject}</Topic>
        <TwoColumns>
          <LeftBlock task={task} taskRefetch={taskRefetch} />
          <RightBlock task={task} />
        </TwoColumns>
      </Layout.WrapperContent>
    </APageWrapper>
  )
}
