import styled from 'styled-components'

export const PaymenTab = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;
  margin-top: 8px;
`

export const InputsRow = styled.div`
  display: flex;
  width: 100%;
  column-gap: 16px;
`

export const FirstInRowControl = styled.div`
  width: 196px;
  flex-shrink: 0;
`
