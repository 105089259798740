import { AntdTable, Progress } from '@agro-club/agroclub-shared'
import { DealShipment } from 'modules/domain/deal/types'
import { FC } from 'react'
import { useNestedShipmentsColumns } from './useNestedShipmentsColumns'

type Props = {
  shipments: DealShipment[]
}

export const NestedShipmentsTable: FC<Props> = ({ shipments }) => {
  const columns = useNestedShipmentsColumns()

  return (
    <AntdTable
      columns={columns}
      showHeader
      data={shipments}
      progress={Progress.SUCCESS}
      rowKey="id"
      disablePagination
    />
  )
}
