import { defaultListRequestParams } from 'views/components/TableFilters/data'
import ATableFullPage from 'views/components/Table/ATableFullPage'
import { ListRequestParams } from 'modules/domain/types'
import { Deal } from 'modules/domain/deal/types'
import { FC, useEffect, useState } from 'react'
import { endpoints } from 'modules/endpoints'
import { useColumns } from './useColumns'

type Props = { deal: Deal }

export const History: FC<Props> = ({ deal }) => {
  const [listRequestParams, setListRequestParams] = useState<ListRequestParams>({
    ...defaultListRequestParams,
    pageSize: 5,
  })
  useEffect(() => {
    setListRequestParams({ ...listRequestParams })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deal])

  const columns = useColumns()
  return (
    <ATableFullPage
      pagination={{ showTotal: undefined, size: 'small' }}
      dataEndpoint={endpoints.dealHistory(deal.id)}
      listParamsUpdated={setListRequestParams}
      listRequestParams={listRequestParams}
      columns={columns}
      isFiltersLoaded
      maxHeight={258}
      disableScrollY
      rowHoverGray
      bordered
    />
  )
}
