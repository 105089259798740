import styled from 'styled-components'
import { CustomScroll } from 'views/styled/common'
import { CollapseWrapperStyles } from '../styled'

export const WrapperCollapse = styled.div`
  min-width: 330px;
  height: calc(100dvh - 210px);

  .ant-collapse {
    max-height: calc(100dvh - 160px);
  }

  .ant-collapse-content-box {
    max-height: calc(100dvh - 270px);
  }

  ${CollapseWrapperStyles}
}
`

export const MultipleCollapseWrapper = styled.div`
  min-width: 330px;

  .ant-collapse-content-box {
    max-height: calc(100dvh - 330px);
  }

  ${CollapseWrapperStyles}
`

export const WrapperCards = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px 0;
  padding: 10px 0 16px 0;

  &:empty {
    display: none;
  }
`

export const ContentWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  height: 100%;
  overflow: auto;
  ${CustomScroll}
`
