import { FormGroup, Progress, Radio, RadioItem } from '@agro-club/agroclub-shared'
import { useFormik } from 'formik'
import { endpoints } from 'modules/endpoints'
import { apiClient } from 'modules/utils/httpClient'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { SharedModalContent, SharedModalTitle } from 'views/components/SharedModal'
import { SharedModalInputs } from 'views/components/SharedModal/styled'

import { CarrierFormControls } from '../../components/CarrierFormControls/CarrierFormControls'
import { CarrierStatuses, CarrierType } from '../../types'
import { FormItemWrapper } from 'views/styled/common'
import { Carrier } from 'modules/domain/logisticsKR/types'
import ASharedModal from 'views/components/SharedModal/ASharedModal'
import { AnalyticsPlaces } from '../../analyticsPlaces'
import ADefaultModalFooter from 'views/components/DefaultModalFooter/ADefaultModalFooter'
import { useAFormHandler } from 'analytics/hooks'
import { useNotification } from 'hooks/useNotification'
import { FormFields } from './types'
import { CompanyFormControls } from 'views/pages/LogisticsKR/CarriersKR/AddCarrierModal/CompanyFormControls/CompanyFormControls'

type Props = {
  onClose: () => void
  onSuccess?: () => void
  setValue?: (val) => void
  daData?: any
  title?: string
}

export const AddCarrierModal: FC<Props> = ({ daData, setValue, onClose, onSuccess, title }) => {
  const { t } = useTranslation('logisticsKR')
  const { formProgress, formHandler } = useAFormHandler('addCarrier')
  const notify = useNotification()

  const formik = useFormik<FormFields>({
    initialValues: {
      status: CarrierStatuses.active,
      inn: daData?.inn,
      name: daData?.name_short ?? daData?.name_full,
      phone: daData?.phone,
      contact_name: '',
      is_in_charter: false,
      has_vat: false,
      working_regions: [],
      favorite_regions: [],
      carrier_type: CarrierType.local,
    },

    onSubmit: formHandler(async () => await apiClient.post<Carrier>(endpoints.LogisticsKR.carriers(), formik.values), {
      onSuccess: async (response) => {
        await onSuccess?.()
        setValue?.(response)
        onClose()
      },
      onError: (error) => {
        const { errors } = error
        formik.setErrors(errors)
        notify(Progress.ERROR, { title: t('carriers.notifications.carrier_add_error') })
      },
    }),
  })

  return (
    <ASharedModal id={AnalyticsPlaces.CARRIERS.ADD_MODAL} size="medium" onClose={onClose}>
      <SharedModalTitle>{title || t('carriers.form.addTitle')}</SharedModalTitle>
      <SharedModalContent>
        <SharedModalInputs>
          <FormGroup error={formik.errors?.status} label={t('carriers.labels.carrier_type')}>
            <FormItemWrapper>
              <Radio onChange={(value) => formik.setFieldValue('status', value)} value={formik.values.status}>
                <RadioItem value={CarrierStatuses.active} label={t('carriers.radio_statuses.active')} />
                <RadioItem value={CarrierStatuses.draft} label={t('carriers.radio_statuses.draft')} />
              </Radio>
            </FormItemWrapper>
          </FormGroup>
          <CompanyFormControls formik={formik} />
          <CarrierFormControls formik={formik} />
        </SharedModalInputs>
      </SharedModalContent>
      <ADefaultModalFooter
        onClose={onClose}
        progress={formProgress}
        onConfirm={() => formik.submitForm()}
        confirmButtonText={t('common:save')}
      />
    </ASharedModal>
  )
}
