import { PopoverButton, PopoverButtonProps } from '../PopoverButton'
import { useAnalyticsClickHandler } from 'analytics/hooks'
import { FC } from 'react'
import { AnalyticsPayload } from 'analytics/types'

interface Props extends PopoverButtonProps {
  id: string
  analyticsPayload?: AnalyticsPayload
}

export const APopoverButton: FC<Props> = ({ id, analyticsPayload, text, Icon, onClick, ...otherProps }) => {
  const handleClick = useAnalyticsClickHandler(id)
  return <PopoverButton text={text} Icon={Icon} onClick={handleClick(onClick, analyticsPayload)} {...otherProps} />
}
