import { defaultTheme } from '@agro-club/agroclub-shared'
import { BidPrice } from 'modules/domain/bid/types'
import { getPriceTypeLabel } from 'modules/domain/bid/utils'
import { formatPriceLogistics, getPriceLogisticsLabel } from 'modules/utils/numbers/formatPriceLogistics'
import { formatQuantity } from 'modules/utils/numbers/formatQuantity'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import { Card } from 'views/components/Card/Card'
import { DatePeriodFormatted } from 'views/components/DatePeriodFormatted'
import { KeyValue } from 'views/components/KeyValue/KeyValue'
import { TextWithSubText } from 'views/components/TextWithSubText/TextWithSubText'
import { useDealContext } from '../DealContext'
import { MapButtons } from '../MappButtons/MapButtons'
import { PriceWithBroker } from './PriceWithBroker'

export const GeneralInfoContentUS: FC = () => {
  const { t } = useTranslation('deal')
  const { deal } = useDealContext()

  const isMobile = useMediaQuery({
    query: `(max-width: ${defaultTheme.queries.mobile})`,
  })

  //TODO позже будем включать. Если в итоге забьем, то удалить этот код
  // const basisTooltipContent = (
  //   <div>
  //     <div>{deal.estimated_seller_basis_formula}</div>
  //     <div>{deal.estimated_seller_basis_tooltip}</div>
  //   </div>
  // )
  // const deliveredTooltipContent = (
  //   <div>
  //     <div>{deal.estimated_seller_basis_delivered_formula}</div>
  //     <div>{deal.estimated_seller_basis_delivered_tooltip}</div>
  //   </div>
  // )

  return (
    <div style={{ width: '616px' }}>
      <Card.Grid cols={isMobile ? 1 : 3} maxColumnWidth="200px" columnGap={8}>
        <KeyValue label={t('fields.quantityShort')}>{formatQuantity(deal.quantity)}</KeyValue>
        {!isMobile && (
          <>
            <KeyValue label={t('fields.distance')}>
              <MapButtons deal={deal} />
            </KeyValue>
            <KeyValue label={t('fields.deliveryPeriod')}>
              <DatePeriodFormatted period={deal} showEmpty />
            </KeyValue>
          </>
        )}
        <KeyValue label={t('fields.basisToBid')}>
          <div style={{ display: 'flex', gap: '16px' }}>
            <TextWithSubText
              // tooltipContent={basisTooltipContent}
              text={
                <PriceWithBroker
                  price={deal.estimated_seller_basis}
                  commision={deal.seller_broker ? deal.seller_broker.commission : undefined}
                  sign="+"
                />
              }
              subText={getPriceTypeLabel(BidPrice.EXW, t)}
            />
            <TextWithSubText
              // tooltipContent={deliveredTooltipContent}
              text={
                <PriceWithBroker
                  price={deal.estimated_seller_basis_delivered}
                  commision={deal.customer_broker ? deal.customer_broker.commission : undefined}
                  sign="-"
                />
              }
              subText={getPriceTypeLabel(BidPrice.CPT, t)}
            />
          </div>
        </KeyValue>
        {isMobile && <MapButtons deal={deal} isMobile />}
        <KeyValue label={getPriceLogisticsLabel()}>
          {/* <DefaultTooltip tooltipContent={deal.price_logistics_formula}> */}
          {formatPriceLogistics(deal.price_logistics)}
          {/* </DefaultTooltip> */}
        </KeyValue>
      </Card.Grid>
    </div>
  )
}
