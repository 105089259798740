import { useTranslation } from 'react-i18next'
import { isUS } from 'env'
import { AntTag } from '@agro-club/agroclub-shared'
import { Bid, USBid } from 'modules/domain/bid/types'

type Props = {
  bid: Bid
}

export const USContractTypeTag = (props: Props) => {
  const { t } = useTranslation('bid')

  if (!isUS) return null
  const bid = props.bid as USBid

  return <>{bid.contract_type && <AntTag color="white">{t(`usContractTypes.${bid.contract_type}`)}</AntTag>}</>
}
