import { FC } from 'react'

import { ListRequestParams } from 'modules/domain/types'
import { SearchWrapper } from 'views/components/CommonTableComponents/CommonTableComponents'
import { ASearch } from '../Analytics'

interface Props {
  listRequestParams: ListRequestParams
  updateFilterState: ({ search }: { search: string }) => void
}

export const SearchFilter: FC<Props> = ({ listRequestParams, updateFilterState }) => (
  <SearchWrapper>
    <ASearch value={listRequestParams.filter.search} onChange={(search) => updateFilterState({ search })} />
  </SearchWrapper>
)
