import { AntTag } from '@agro-club/agroclub-shared'
import { DeliveryBasis, ExportStatus } from 'modules/domain/export/types'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

export const ContractStatusTag: FC<{ status: ExportStatus }> = ({ status }) => {
  const { t } = useTranslation('export')

  const isClosed = status === ExportStatus.closed
  const isActive = status === ExportStatus.active
  const text = (isClosed && t('closed')) || (isActive && t('active')) || t('fulfilled')
  const color = (isClosed && 'grey') || (isActive && 'green') || 'blue'

  return <AntTag color={color}>{text}</AntTag>
}

export const DeliveryBasisTag: FC<{ deliveryBasis: DeliveryBasis }> = ({ deliveryBasis }) => {
  const { t } = useTranslation('export')

  const text = deliveryBasis === DeliveryBasis.CIF ? t('cif') : t('fob')

  return <AntTag color="white">{text}</AntTag>
}
