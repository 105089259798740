import { Checkbox, FormGroup, Input } from '@agro-club/agroclub-shared'
import { useAnalyticsClickHandler } from 'analytics/hooks'
import { FormikProps } from 'formik'
import { endpoints } from 'modules/endpoints'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FormSelectButton } from 'views/components/FormSelectButtons/FormSelectButtons'
import { FormSelectDadata } from 'views/components/FormSelectDadata/FormSelectDadata'
import { HrWithHeader } from 'views/components/Hr/Hr'
import { ShortFormControl } from 'views/styled/common'
import FormSelectShared from '../../../../components/FormSelectShared'
import { AddCarrierModal } from '../../CarriersKR/AddCarrierModal/AddCarrierModal'
import { HintText } from '../../LogisticRequests/components/styled'
import { AddNewCarModal } from '../../LogisticRequests/RequestsBoard/OpenRequestsBoardColumns/DraftTrips/AddNewCarModal'
import { DraftTripsInitial } from '../../LogisticRequests/RequestsBoard/OpenRequestsBoardColumns/DraftTrips/types'
import { DriverAddModal } from '../DriverModals/DriverAddModal'
import { TrailerSelect } from '../TrailerSelect/TrailerSelect'
import { MarginWrapper } from './styled'

interface Props {
  formik: FormikProps<DraftTripsInitial>
  controlsDisable: Record<string, boolean> | undefined
  isExistingCarrierInDB: boolean
  setIsExistingCarrierInDB: (arg: boolean) => void
}

export const CarrierControls: FC<Props> = ({
  formik,
  controlsDisable,
  isExistingCarrierInDB,
  setIsExistingCarrierInDB,
}) => {
  const { t } = useTranslation('logisticBids')
  const [addCarrierModal, setAddCarrierModal] = useState(false)
  const [addCarModal, setAddCarModal] = useState(false)
  const [addDriverModal, setAddDriverModal] = useState(false)
  const handleClick = useAnalyticsClickHandler('add')

  const handleChangeCarrier = (data) => {
    setIsExistingCarrierInDB(true)
    if (formik.values?.carrier_id) {
      formik.setFieldValue('carrier_id', '')
    }
    formik.setFieldValue('carrier_inn', data?.inn)
    formik.setFieldValue('carrier_name', data?.name_short ?? data?.name_full)
    formik.setFieldValue('car_id', '')
    formik.setFieldValue('trailer', '')
    formik.setFieldValue('driver', '')
  }

  const carrierValue = () => {
    if (formik.values.carrier_inn) {
      return {
        value: formik.values?.carrier_inn,
        label: formik.values?.carrier_name,
      }
    }

    if (formik.values.carrier_id) {
      return {
        value: formik.values?.carrier_id,
        label: formik.values?.carrier_name,
      }
    }

    return undefined
  }

  return (
    <>
      <HrWithHeader text={t('form.block_titles.carrier_info')} />
      <FormSelectDadata
        error={formik.errors.carrier_id || formik.errors.carrier_inn || formik.errors.carrier_name}
        turnOffInfo
        value={carrierValue()}
        onChange={handleChangeCarrier}
        label={t('form.labels.carrier')}
        disabled={controlsDisable?.carrier}
        afterControlComponent={
          <FormSelectButton
            action={handleClick(() => setAddCarrierModal(true))}
            eventId="addCarrier"
            disabled={controlsDisable?.carrier}
          />
        }
      >
        {!formik.values.carrier_id && !isExistingCarrierInDB && <HintText>{t('form.hints.no_carrier_in_db')}</HintText>}
        <MarginWrapper>
          <Checkbox
            isChecked={formik.values.has_vat}
            onChange={(_value, isChecked) => formik.setFieldValue('has_vat', isChecked)}
            label={t('form.labels.vat')}
          />
          <Checkbox
            isChecked={formik.values.need_contract}
            onChange={(_value, isChecked) => formik.setFieldValue('need_contract', isChecked)}
            label={t('form.labels.need_contract')}
          />
        </MarginWrapper>
        {addCarrierModal && (
          <AddCarrierModal
            daData={{
              inn: formik.values?.carrier_inn,
              name_full: formik.values?.carrier_name,
              phone: formik.values.carrier_phone,
            }}
            onClose={() => setAddCarrierModal(false)}
            setValue={(val) => {
              formik.setFieldValue('carrier_id', val?.id)
              formik.setFieldValue('carrier_inn', val?.inn)
              formik.setFieldValue('carrier_phone', val?.phone)
              formik.setFieldValue('carrier_name', val?.name)
            }}
          />
        )}
      </FormSelectDadata>
      <ShortFormControl>
        <FormGroup label={t('form.labels.phone')} error={formik.errors.carrier_phone}>
          <Input
            placeholder={t('common:phone_placeholder')}
            {...formik.getFieldProps('carrier_phone')}
            invalid={!!formik.errors.carrier_phone}
          />
        </FormGroup>
      </ShortFormControl>
      <div>
        <FormSelectShared
          formik={formik}
          endpoint={endpoints.LogisticsKR.carrierCars(formik.values.carrier_id)}
          fieldName="car_id"
          searchField="search"
          label={t('form.labels.car')}
          placeholder=""
          getLabel={(dto) => `${dto.brand_model} / ${dto.licence_number}`}
          disabled={controlsDisable?.carrier || controlsDisable?.car}
        >
          <FormSelectButton
            action={handleClick(() => setAddCarModal(true))}
            eventId="addCar"
            disabled={controlsDisable?.carrier || controlsDisable?.car}
          />
        </FormSelectShared>

        {addCarModal && (
          <AddNewCarModal
            onClose={() => setAddCarModal(false)}
            carrierId={formik.values?.carrier_id}
            setValue={(val) => formik.setFieldValue('car_id', String(val))}
          />
        )}
      </div>
      <div>
        <TrailerSelect formik={formik} carrierId={formik.values.carrier_id} trailerInfoRequired />
      </div>
      <div>
        <FormSelectShared
          formik={formik}
          fieldName="driver"
          endpoint={endpoints.LogisticsKR.driversByCarrier(formik.values.carrier_id)}
          label={t('form.labels.driver')}
          placeholder=""
          getLabel={(dto) => dto.name}
          searchField="search"
        >
          <FormSelectButton action={handleClick(() => setAddDriverModal(true))} eventId="addDriver" />
        </FormSelectShared>
        {addDriverModal && (
          <DriverAddModal
            carrierId={formik.values.carrier_id}
            onSuccess={(id) => formik.setFieldValue('driver', String(id))}
            setIsOpen={setAddDriverModal}
          />
        )}
      </div>
    </>
  )
}
