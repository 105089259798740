import { convertDistrictDtoToOptionType, useDistrict } from './useDistrict'
import { FormGroup, Progress, Select } from '@agro-club/agroclub-shared'
import { FC } from 'react'

interface Props {
  onChange: (value: any) => void
  region: string[] | string
  disabled?: boolean
  value: string[]
  label: string
}

export const DistrictFormControl: FC<Props> = ({ value, region, onChange, label, disabled }) => {
  const { valueArr, districts, placeholder, progress, debouncedSearch, handleDistrictChange } = useDistrict(
    value,
    region,
    onChange,
  )
  return (
    <FormGroup label={label}>
      <Select
        options={convertDistrictDtoToOptionType(districts)}
        loading={progress === Progress.WORK}
        onChange={handleDistrictChange}
        onSearch={debouncedSearch}
        placeholder={placeholder}
        groupField="parent_name"
        disabled={disabled}
        value={valueArr}
        mode="multiple"
        fullWidth
      />
    </FormGroup>
  )
}
