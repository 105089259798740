const main = 'payment-orders'
const relativeRoutes = {
  relativePage: `${main}/*`,
  relativeList: `${main}`,
  relativeListWithTab: 'tab-:activeTab',
}

const routes = {
  List: `/${main}`,
  ListWithTab: `/${main}/${relativeRoutes.relativeListWithTab}`,
}

const PaymentOrdersRoutes = { ...relativeRoutes, ...routes }

export default PaymentOrdersRoutes
