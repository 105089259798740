import { BidStatus } from 'modules/domain/bid/types'
import { AntTag } from '@agro-club/agroclub-shared'
import { useTranslation } from 'react-i18next'
import { FC } from 'react'

type Props = {
  status: BidStatus
}

export const ContractStatusTag: FC<Props> = ({ status }) => {
  const { t } = useTranslation()

  const statusMapping = {
    [BidStatus.archived]: {
      text: <>{t('common:archived')}</>,
      color: 'grey',
    },
    [BidStatus.completed]: {
      text: <>{t('common:completed')}</>,
      color: 'white',
    },
    [BidStatus.published]: {
      text: <>{t('common:published')}</>,
      color: 'green',
    },
  }

  const { text, color } = statusMapping[status] || {}

  if (!text) return null

  return <AntTag color={color}>{text}</AntTag>
}
