import { AntCollapse, AntdTable, Panel, Progress, Spinner, defaultTheme } from '@agro-club/agroclub-shared'
import { TableWrapper } from 'views/pages/User/UserDetail/UserRightPanel/RightBlockTable/styled'
import { DealShipment } from 'modules/domain/deal/types'
import { LoadingBlock, Wrapper } from '../DealTaxCalculation'
import { useSingleEntity } from 'modules/domain/common/hooks'
import { useTranslation } from 'react-i18next'
import { endpoints } from 'modules/endpoints'
import { useColumns } from './useColumns'
import styled from 'styled-components'
import { FC } from 'react'
import { useDealContext } from '../DealContext'

const Table = styled(TableWrapper)`
  .ant-table {
    box-shadow: none;
    border: 1px solid ${defaultTheme.color.secondary50};
    margin-top: 5px;
  }
`

export const Shipments: FC = () => {
  const { deal } = useDealContext()
  const [progress, shipments = []] = useSingleEntity<DealShipment[]>(endpoints.dealShipment(deal.id))
  const columns = useColumns()
  const { t } = useTranslation('deal')

  if (progress === Progress.WORK || !shipments) {
    return (
      <LoadingBlock>
        <Spinner />
      </LoadingBlock>
    )
  }

  return (
    <Wrapper>
      <AntCollapse accordion>
        <Panel key={1} header={t('accordion.shipping')}>
          <Table>
            <AntdTable columns={columns} data={shipments} progress={Progress.SUCCESS} rowKey="id" />
          </Table>
        </Panel>
      </AntCollapse>
    </Wrapper>
  )
}
