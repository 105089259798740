import { Typography } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

export const ToggleColumnContent = styled.div<{ $open: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.color.backgroundSecondary};
  transition: background-color 0.1s;
  padding: 10px 12px;
  border-radius: 8px;
  margin-bottom: 16px;
  ${Typography.bodyMedium};
  cursor: pointer;
  color: ${({ theme }) => theme.color.primary600};
  &:hover {
    background-color: ${({ theme }) => theme.color.primary100};
  }
  svg {
    transition: transform 0.3s ease-in-out;
    transform: ${({ $open }) => `rotate(${$open ? '180deg' : 0})`};
    fill: ${({ theme }) => theme.color.primary600};
  }
`
