import { Progress } from '@agro-club/agroclub-shared'
import { WarehouseMovement } from 'modules/domain/warehouses/types'
import { endpoints } from 'modules/endpoints'
import { apiClient } from 'modules/utils/httpClient'
import { useEffect, useState } from 'react'
import { ListResponse } from 'types/api'

export const useWarehouseMovements = (dealId: string | undefined) => {
  const [list, setList] = useState<WarehouseMovement[]>([])
  const [last, setLast] = useState<WarehouseMovement>({} as WarehouseMovement)
  const [listWithoutLast, setListWithoutLast] = useState<WarehouseMovement[]>([])
  const [progress, setProgress] = useState<Progress>(Progress.IDLE)

  const fetchData = async (silent = false) => {
    if (!silent) {
      setProgress(Progress.WORK)
    }
    try {
      const response = await apiClient.get<ListResponse<WarehouseMovement>>(
        endpoints.warehouseMovementsForDeal(dealId as string),
      )
      setList(response.results)
      setLast(response.results[0])
      setListWithoutLast(response.results.slice(1))

      setProgress(Progress.SUCCESS)
    } catch (_) {
      setProgress(Progress.ERROR)
    }
  }

  useEffect(() => {
    if (dealId) fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dealId])

  return { list, last, listWithoutLast, progress, refetch: fetchData }
}
