import { useTranslation } from 'react-i18next'
import { FC } from 'react'

type Props = {
  hasData: number | boolean | undefined
}

export const SummaryThereIsNo: FC<Props> = ({ hasData }) => {
  const { t } = useTranslation('common')

  return <div>{hasData ? t('thereIs') : t('no')}</div>
}
