import { useDisplayClearBtn } from 'hooks/useDisplayClearBtn'
import { ListRequestParams } from 'modules/domain/types'
import { FiltersAndSearchWrapper, FiltersWrapper } from 'views/components/CommonTableComponents/CommonTableComponents'
import { CropsFilter } from 'views/components/TableFilters'

import { AddressFilter } from 'views/components/AddressFilter/AddressFilter'
import { AClearFiltersButton } from 'views/components/Analytics'
import { SearchFilter } from 'views/components/SearchFilter/SearchFilter'

import { CommonRegionFilter } from 'views/components/TableFilters/RegionsFilters/CommonRegionFilter'

interface Props {
  listRequestParams: ListRequestParams
  updateFilterState: (filterObj: Record<string, any>) => void
  clearAllTabsState: () => void
}

export const WarehousesFilters = ({ listRequestParams, updateFilterState, clearAllTabsState }: Props) => {
  const displayClearBtn = useDisplayClearBtn({
    listRequestParams,
  })

  return (
    <FiltersAndSearchWrapper>
      <FiltersWrapper>
        <CropsFilter listRequestParams={listRequestParams} updateFilterState={updateFilterState} />

        <CommonRegionFilter listRequestParams={listRequestParams} updateFilterState={updateFilterState} />

        <AddressFilter filter={listRequestParams.filter} onChange={(searchParams) => updateFilterState(searchParams)} />

        {displayClearBtn && <AClearFiltersButton onClick={() => clearAllTabsState()} />}
      </FiltersWrapper>
      <SearchFilter listRequestParams={listRequestParams} updateFilterState={updateFilterState} />
    </FiltersAndSearchWrapper>
  )
}
