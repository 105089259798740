import { useTranslation } from 'react-i18next'

import { UnloadingType } from '../../../types'
import { ListRequestParams } from 'modules/domain/types'
import { ASelectFilter } from 'views/components/Analytics'
import { AnalyticsFilterVariant } from 'analytics/types'
import { FC } from 'react'

type Props = {
  listRequestParams: ListRequestParams
  updateFilterState: (filterObj: Record<string, any>) => void
}

export const UnloadingTypeFilter: FC<Props> = ({ listRequestParams, updateFilterState }) => {
  const { t } = useTranslation('logisticsKR')

  const statusOptions = Object.keys(UnloadingType).map((unloading_type) => ({
    value: unloading_type,
    label: t(`directions.${unloading_type}`),
  }))

  return (
    <ASelectFilter
      placeholder={t('cars.filters.unloading_type')}
      options={statusOptions}
      value={listRequestParams.filter.unloading_type || []}
      mode="multiple"
      onChange={(unloading_type) => updateFilterState({ unloading_type })}
      showSearch={false}
      id={AnalyticsFilterVariant.UNLOADING_TYPE}
    />
  )
}
