import { Button, Progress } from '@agro-club/agroclub-shared'
import { FC, ReactNode } from 'react'
import styled from 'styled-components'
import { t } from 'i18next'

const ModalFooter = styled.div<{ withMargin?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: ${({ withMargin = true }) => (withMargin ? '24px' : 0)};
`
const RightFooterBlock = styled.div`
  display: flex;
  align-items: center;
  column-gap: 16px;
`

export type InternalDefaultModalFooterProps = {
  progress?: Progress
  onClose: () => void
  onConfirm?: (any?) => any
  confirmButtonText?: ReactNode
  cancelButtonText?: ReactNode
  disabled?: boolean
  withMargin?: boolean
  intent?: 'primary' | 'secondary' | 'approve' | 'destruct' | 'white'
  children?: ReactNode
}

const InternalDefaultModalFooter: FC<InternalDefaultModalFooterProps> = ({
  withMargin,
  progress,
  onClose,
  onConfirm,
  disabled,
  confirmButtonText = t('common:confirm'),
  cancelButtonText = t('common:canceling'),
  intent = 'primary',
  children,
}) => {
  return (
    <ModalFooter withMargin={withMargin}>
      <RightFooterBlock>
        <Button disabled={disabled} intent={intent} size="big" progress={progress} onClick={onConfirm}>
          {confirmButtonText}
        </Button>
        <Button intent="secondary" size="big" onClick={onClose}>
          {cancelButtonText}
        </Button>
      </RightFooterBlock>
      {children}
    </ModalFooter>
  )
}

export default InternalDefaultModalFooter
