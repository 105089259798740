import { useAnalyticsClickHandler, useModalPlace } from 'analytics/hooks'
import { MobileModal } from '@agro-club/agroclub-shared'
import { WrapperMobileModal } from './styled'
import { FC, ReactNode } from 'react'

type Props = {
  headerHeight?: number
  children: ReactNode
  onClose: () => void
  header: ReactNode
  id: string
}

export const AMobileModal: FC<Props> = ({ id, children, onClose, header, ...otherProps }) => {
  const handleClose = useAnalyticsClickHandler('close')
  useModalPlace(id)

  return (
    <MobileModal header={header} onClose={handleClose(onClose)} isOpen={true} {...otherProps}>
      <WrapperMobileModal>{children}</WrapperMobileModal>
    </MobileModal>
  )
}
