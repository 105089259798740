import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ColumnData } from 'modules/types'
import { ListRequestParams } from 'modules/domain/types'
import { convertSortingToAntDFormat } from 'modules/domain/common/tableUtils'
import { TableTextLink } from 'views/ui/TableTextLink/TableTextLink'
import { generatePath } from 'react-router-dom'
import SpecificationsRoutes from 'views/pages/Specifications/routes'
import useFormatDate from 'hooks/useFormatDate'
import env from 'env'
import { labelQuantity } from 'modules/utils/numbers/formatQuantity'

export const useColumns = (sortParam: ListRequestParams['sort']): ColumnData[] => {
  const { t } = useTranslation('specification')
  const formatDate = useFormatDate()
  const currency = env.REACT_APP_CURRENCY_SYMBOL
  return useMemo(
    () => [
      {
        title: t('table.name'),
        width: 140,
        dataIndex: 'number',
        sorter: true,
        sortOrder: convertSortingToAntDFormat('number', sortParam),
        render: (number, row) => (
          <TableTextLink
            id="specificationLink"
            tableItemId={row.id}
            to={generatePath(SpecificationsRoutes.Details, { id: row.id })}
          >
            {number}
          </TableTextLink>
        ),
      },
      {
        title: t('table.date_signed'),
        width: 150,
        dataIndex: 'signed_date',
        key: 'signed_date',
        sorter: true,
        sortOrder: convertSortingToAntDFormat('signed_date', sortParam),
        render: (signed_date) => formatDate(signed_date),
      },
      {
        title: t('table.term'),
        width: 100,
        dataIndex: 'deadline',
        sorter: true,
        sortOrder: convertSortingToAntDFormat('deadline', sortParam),
        render: (deadline) => formatDate(deadline),
      },
      {
        title: t('table.team'),
        width: 146,
        dataIndex: 'owner',
        render: (owner) => owner.profile?.team?.name,
      },
      {
        title: t('table.buyer'),
        width: 340,
        dataIndex: 'company',
        render: (company) => company.name,
      },
      {
        title: t('table.point_acceptance'),
        width: 340,
        dataIndex: 'address',
        render: (address) => address.address,
      },
      {
        title: t('table.product'),
        width: 160,
        dataIndex: 'product',
        render: (product) => product.title,
      },
      {
        title: t('common:priceWithCurrency', { currency }),
        width: 110,
        dataIndex: 'price',
        align: 'right',
        sorter: true,
        sortOrder: convertSortingToAntDFormat('price', sortParam),
      },
      {
        title: labelQuantity(),
        width: 110,
        dataIndex: 'quantity',
        align: 'right',
        sorter: true,
        sortOrder: convertSortingToAntDFormat('quantity', sortParam),
      },
      {
        title: labelQuantity(t('table.quantity_shipping')),
        width: 120,
        dataIndex: 'quantity_shipping',
        align: 'right',
      },
      {
        title: labelQuantity(t('table.brought')),
        width: 110,
        dataIndex: 'quantity_shipped',
        align: 'right',
      },
      {
        title: labelQuantity(t('table.remaining')),
        width: 116,
        dataIndex: 'quantity_left',
        align: 'right',
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sortParam, t, formatDate],
  )
}
