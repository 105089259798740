import { FC, useMemo } from 'react'
import { Bid } from 'modules/domain/bid/types'
import { KeyValue } from 'views/components/KeyValue/KeyValue'
import { useTranslation } from 'react-i18next'
import { GridItem } from 'views/components/Card/Card'
import formatNumber from 'modules/utils/numbers/formatNumber'
import styled from 'styled-components'
import { getMainParameter } from 'modules/utils/helpers'
import { QuestionTooltip } from 'views/components/QuestionTooltip/QuestionTooltip'

const ParamsWrapper = styled.div`
  display: flex;
  column-gap: 4px;
`

export const BidDetailGrid: FC<{ bid: Bid; uniqueItems?: GridItem[] }> = ({ bid, uniqueItems }) => {
  const { t } = useTranslation(['deal', 'bid', 'address'])
  const [, ...restParams] = bid.parameters || []

  const restProductParams = restParams.map((param) =>
    [param.parameter.name, param.condition_label, formatNumber(param.parameter_value)].filter(Boolean).join(' '),
  )

  const tooltipContent = useMemo(
    () => restProductParams.map((param) => <div key={String(param)}>{param}</div>),
    [restProductParams],
  )

  const paramsLabel = (
    <ParamsWrapper>
      {t('fields.product_params')}
      {bid.parameters?.length > 1 && (
        <QuestionTooltip size="compact" variant="secondary" tooltipContent={tooltipContent} />
      )}
    </ParamsWrapper>
  )

  const commonDetails = [
    bid.parameters?.length && { label: paramsLabel, value: getMainParameter(bid.parameters) },
    bid.season && { label: t('bid:season'), value: bid.season.name },
  ].filter(Boolean) as GridItem[]

  const items = [...commonDetails, ...(uniqueItems || [])].filter(Boolean)
  return (
    <>
      {items.map((item, index) => {
        return (
          <KeyValue key={index} label={item.label}>
            {item.value}
          </KeyValue>
        )
      })}
    </>
  )
}
