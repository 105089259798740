import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useHelmet } from '@agro-club/agroclub-shared'

import isLangSelect from 'helpers/isLangSelect'
import { I18nLangSelect } from 'views/components/I18nLangSelect/I18nLangSelect'

import PhoneForm, { requestCode } from './PhoneForm'
import OtpForm from './OtpForm'
import { AuthLayout } from './AuthLayout'
import styled from 'styled-components'
import APageWrapper from 'views/components/PageWrapper/APageWrapper'
import { useAnalyticsIdentify } from 'analytics/hooks'
import { AnalyticsPages, AnalyticsPlaces } from './analyticsPlaces'
import { useNavigate } from 'react-router'
import DashboardRoutes from '../Dashboard/routes'
import { AuthContext } from 'modules/context/AuthContext'

const FormMargin = styled.div`
  margin-top: 51px;
  width: 100%;
`
enum AuthSteps {
  PhoneInput = 'PhoneInput',
  OtpInput = 'OtpInput',
}

function AuthPage() {
  const { profile } = useContext(AuthContext)
  const [phone, setPhone] = useState('')

  const { t } = useTranslation(['auth'])
  const navigate = useNavigate()
  useHelmet({ title: t('metaTitle') })

  const [step, setStep] = useState(AuthSteps.PhoneInput)

  const { userIdentification } = useAnalyticsIdentify()

  useEffect(() => {
    if (profile) {
      userIdentification(profile.id)
      navigate(DashboardRoutes.Root)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile])

  const onPhoneSent = (phone) => {
    setPhone(phone)
    setStep(AuthSteps.OtpInput)
  }

  const renderStep = () => {
    switch (step) {
      case AuthSteps.PhoneInput:
        return <PhoneForm onSuccess={onPhoneSent} />
      case AuthSteps.OtpInput:
        return (
          <OtpForm resend={() => requestCode({ phone })} phone={phone} onBack={() => setStep(AuthSteps.PhoneInput)} />
        )
      default:
        return null
    }
  }

  return (
    <>
      <APageWrapper page={AnalyticsPages.LOGIN} place={AnalyticsPlaces.LOGIN.PAGE}>
        <AuthLayout>
          <FormMargin>{renderStep()}</FormMargin>
        </AuthLayout>
      </APageWrapper>

      {isLangSelect() && (
        <div style={{ position: 'absolute', bottom: 8, left: 8 }}>
          <I18nLangSelect />
        </div>
      )}
    </>
  )
}

export default AuthPage
