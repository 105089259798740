import { defaultTheme } from '@agro-club/agroclub-shared'
import { BidTypes } from 'modules/domain/bid/types'
import { isPurchaseBid } from 'modules/domain/bid/utils'
import { refetchFunc } from 'modules/domain/common/hooks'
import { Deal } from 'modules/domain/deal/types'
import { getDealBid } from 'modules/domain/deal/utils'
import { FC, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import { Card } from 'views/components/Card/Card'
import { DealPartyChangeData } from '../../../hooks/useDealPartyChange'
import { BidDetailActions } from '../BidDetailActions/BidDetailActions'
import { ActionsWrapper, ActionsWrapperMobile, Header, TitleWrapper } from './styled'

type Props = {
  refetch: refetchFunc
  deal: Deal
  bidType: BidTypes
  children?: ReactNode
  partyChanger: DealPartyChangeData
}

export const BidDetailTitle: FC<Props> = ({ refetch, deal, bidType, partyChanger, children = null }) => {
  const { t } = useTranslation('deal')

  const bid = getDealBid(deal, bidType)
  const title = isPurchaseBid(bid) ? t('buyer') : t('seller')
  const isMobile = useMediaQuery({
    query: `(max-width: ${defaultTheme.queries.mobile})`,
  })

  const bidDetailActions = (
    <BidDetailActions
      isMobile={isMobile}
      bidType={bidType}
      partyChanger={partyChanger}
      dealRefetch={refetch}
      deal={deal}
    />
  )

  return (
    <TitleWrapper>
      <Header>
        {!isMobile && <Card.Title>{title}</Card.Title>}

        {isMobile ? (
          <ActionsWrapperMobile>{bidDetailActions}</ActionsWrapperMobile>
        ) : (
          <ActionsWrapper>{bidDetailActions}</ActionsWrapper>
        )}
      </Header>
      {children}
    </TitleWrapper>
  )
}
