import { ColumnData } from 'modules/types'
import { useTranslation } from 'react-i18next'
import { Fragment, useMemo } from 'react'
import { ListRequestParams } from 'modules/domain/types'
import { convertSortingToAntDFormat } from 'modules/domain/common/tableUtils'
import { generatePath } from 'react-router'
import { AntTag } from '@agro-club/agroclub-shared'
import { DocumentRequestsRoutes } from '../routes'
import { DocumentRequestShort } from 'modules/domain/documentRequest/types'
import { TableTextLink } from 'views/ui/TableTextLink/TableTextLink'
import useFormatDate from 'hooks/useFormatDate'
import APhone from 'views/ui/Phone/APhone'

const Coordinator = (regionals: DocumentRequestShort['regionals']) => {
  return regionals.map((regional) => (
    <Fragment key={regional.id}>
      <div>{regional.full_name}</div>
      <APhone phone={regional.phone} />
    </Fragment>
  ))
}

const statusToColor = {
  new: 'orange',
  in_progress: 'green',
  complete: 'grey',
}

export const useColumns = (
  sortParam: ListRequestParams['sort'],
  statusDict: Record<string, string> | null,
): ColumnData[] => {
  const { t } = useTranslation('documentRequests')
  const formatDate = useFormatDate()
  const Date = (value) => formatDate(value)
  return useMemo(
    () => [
      {
        title: t('list.tableHeaders.id'),
        width: 112,
        dataIndex: 'id',
        sorter: true,
        key: 'id',
        sortOrder: convertSortingToAntDFormat('id', String(sortParam)),
        render: (id) => (
          <TableTextLink
            id="documentRequest"
            tableItemId={id}
            to={generatePath(DocumentRequestsRoutes.Details, { id: id })}
          >
            ID {id}
          </TableTextLink>
        ),
      },
      {
        title: t('list.tableHeaders.status'),
        width: 88,
        dataIndex: 'status',
        key: 'status',
        align: 'left',
        render: (status) => <AntTag color={statusToColor[status] || 'grey'}>{statusDict?.[status]}</AntTag>,
      },
      {
        title: t('list.tableHeaders.created'),
        width: 96,
        dataIndex: 'created',
        sorter: true,
        sortOrder: convertSortingToAntDFormat('created', sortParam),
        render: Date,
      },
      {
        title: t('list.tableHeaders.sellerCompany'),
        width: 260,
        dataIndex: 'seller_company',
        render: (sellerCompany) => sellerCompany.name,
      },
      {
        title: t('list.tableHeaders.product'),
        width: 160,
        dataIndex: 'product',
        render: (product) => product.title,
      },
      {
        title: t('list.tableHeaders.quantity'),
        width: 130,
        align: 'right',
        dataIndex: 'quantity',
        sortOrder: convertSortingToAntDFormat('quantity', sortParam),
        sorter: true,
      },
      {
        title: t('list.tableHeaders.expectedCompleteDate'),
        width: 150,
        dataIndex: 'expected_complete_date',
        sorter: true,
        sortOrder: convertSortingToAntDFormat('expected_complete_date', sortParam),
        render: Date,
      },
      {
        title: t('list.tableHeaders.receivedDsDate'),
        width: 150,
        dataIndex: 'received_ds_date',
        sorter: true,
        sortOrder: convertSortingToAntDFormat('received_ds_date', sortParam),
        render: Date,
      },
      {
        title: t('list.tableHeaders.samplingDate'),
        width: 120,
        sorter: true,
        dataIndex: 'sampling_date',
        sortOrder: convertSortingToAntDFormat('sampling_date', sortParam),
        render: Date,
      },
      {
        title: t('list.tableHeaders.receivedConclusionDate'),
        width: 120,
        sorter: true,
        dataIndex: 'received_conclusion_date',
        sortOrder: convertSortingToAntDFormat('received_conclusion_date', sortParam),

        render: Date,
      },
      {
        title: t('list.tableHeaders.receivedQuarantineDate'),
        width: 120,
        sorter: true,
        dataIndex: 'received_quarantine_date',
        sortOrder: convertSortingToAntDFormat('received_quarantine_date', sortParam),

        render: Date,
      },
      {
        title: t('list.tableHeaders.receivedVetDate'),
        width: 120,
        sorter: true,
        dataIndex: 'received_vet_date',
        sortOrder: convertSortingToAntDFormat('received_vet_date', sortParam),

        render: Date,
      },
      {
        title: t('list.tableHeaders.regional'),
        width: 300,
        dataIndex: 'regionals',
        render: Coordinator,
      },
      {
        title: t('list.tableHeaders.team'),
        width: 148,
        dataIndex: 'seller_team',
        render: (seller_team, data) => (
          <>
            {seller_team}, {data.customer_team}
          </>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sortParam, t, statusDict],
  )
}
