import { FC, useContext } from 'react'
import { Icons } from '@agro-club/agroclub-shared'
import { useTranslation } from 'react-i18next'
import { useNotificationProgress } from 'hooks/useNotificationProgress'
import { endpoints } from 'modules/endpoints'
import { apiClient } from 'modules/utils/httpClient'
import { useAProgress } from 'hooks/useAProgress'
import { AButton } from 'views/components/Analytics'
import { AuthContext } from 'modules/context/AuthContext'

interface ButtonProps {
  taskId: string
  onSuccess: () => void
  potential_executors?: string[]
}

export const SetTaskToWorkButton: FC<ButtonProps> = ({ taskId, onSuccess, potential_executors }) => {
  const { t } = useTranslation('my_tasks')
  const { profile: currentUser } = useContext(AuthContext)

  const [progress, setToWork, error] = useAProgress(() => apiClient.post(endpoints.setTaskToWork(taskId)), {
    eventName: 'setToWork',
    analyticsPayload: {
      id: taskId,
    },
    onSuccess,
  })

  useNotificationProgress(progress, { title: t('table.set_to_work_task') }, { title: error?.detail })

  return (
    <AButton
      id="setToWork"
      size="small"
      intent="secondary"
      Icon={Icons.IconDone}
      onClick={setToWork}
      progress={progress}
      disabled={currentUser && !potential_executors?.includes(currentUser.id)}
    >
      {t('table.to_work')}
    </AButton>
  )
}
