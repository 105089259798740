import { defaultTheme } from '@agro-club/agroclub-shared'
import { BidTypes } from 'modules/domain/bid/types'
import { refetchFunc } from 'modules/domain/common/hooks'
import { FC, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { AAntdTabs } from 'views/components/Analytics'
import { DealPartyChangeData } from '../../hooks/useDealPartyChange'
import { BidDetailBlock } from '../BidDetailBlock'
import { BidWarehouseBlock } from '../BidDetailBlock/BidWarehouseBlock/BidWarehouseBlock'
import { WhMovements } from '../DealContext'

const Wrapper = styled.div`
  background: ${defaultTheme.color.backgroundSecondary};
  border-radius: 16px;
  padding-top: 28px;
  .ant-tabs-nav {
    padding: 0 16px;
  }
`
type Props = {
  partyChanger: DealPartyChangeData
  whMovements: WhMovements
  dealRefetch: refetchFunc
}

export const MobileBids: FC<Props> = ({ partyChanger, whMovements, dealRefetch }) => {
  const { t } = useTranslation('deal')
  const [activeTab, setActiveTab] = useState<string>()
  const isVisibleWarehouse =
    partyChanger.deal.purchase_bid.is_for_warehouse && partyChanger.deal.has_warehouse_movements

  const customerTab = useMemo(
    () =>
      isVisibleWarehouse
        ? {
            warehouse: {
              label: t('warehouses:warehouse'),
            },
          }
        : {
            [BidTypes.purchase]: {
              label: t('buyer'),
            },
          },
    [isVisibleWarehouse, t],
  )

  const tabItems = useMemo(() => {
    const initialTabsData = {
      [BidTypes.sale]: {
        label: t('seller'),
      },
      ...customerTab,
    }

    const tabs: any = []
    for (const key of Object.keys(initialTabsData)) {
      tabs.push({
        label: initialTabsData[key].label,
        key,
        children:
          isVisibleWarehouse && key === 'warehouse' ? (
            <BidWarehouseBlock whMovements={whMovements} dealId={partyChanger.deal.id} dealRefetch={dealRefetch} />
          ) : (
            <BidDetailBlock key={key} bidType={BidTypes[key]} partyChanger={partyChanger} />
          ),
      })
    }
    return tabs
  }, [t, customerTab, isVisibleWarehouse, whMovements, partyChanger, dealRefetch])

  useEffect(() => {
    if (isVisibleWarehouse && activeTab === BidTypes.purchase) {
      setActiveTab('warehouse')
    }
    if (!isVisibleWarehouse && activeTab === 'warehouse') {
      setActiveTab(BidTypes.purchase)
    }
  }, [activeTab, isVisibleWarehouse])

  return (
    <Wrapper>
      <AAntdTabs
        id="bidTabs"
        defaultActiveKey={BidTypes.sale}
        items={tabItems}
        onChange={(tab) => setActiveTab(tab)}
        activeKey={activeTab}
      />
    </Wrapper>
  )
}
