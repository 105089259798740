const main = 'whatsApp'
const relativeRoutes = {
  relativePage: `${main}/*`,
  relativeList: `${main}`,
}

const routes = {
  List: `/${main}`,
}

const WhatsAppRoutes = { ...relativeRoutes, ...routes }

export default WhatsAppRoutes
