import { isBrazil } from 'env'
import { RatesBrContent } from './components/RatesListContent/RatesBrContent'
import { RatesRuContent } from './components/RatesListContent/RatesRuContent'

const RatesList = () => {
  if (isBrazil) return <RatesBrContent />

  return <RatesRuContent />
}

export default RatesList
