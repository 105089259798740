import { Route, Routes } from 'react-router-dom'

import TeamsRoutes from 'views/pages/Teams/routes'
import { TeamDetail } from 'views/pages/Teams/TeamDetail/TeamDetail'
import { TeamsView } from 'views/pages/Teams/TeamsView'
import { FC } from 'react'

const TeamsPage: FC = () => (
  <Routes>
    <Route index element={<TeamsView />} />
    <Route path={TeamsRoutes.relativeDetail} element={<TeamDetail />} />
  </Routes>
)

export default TeamsPage
