const main = 'teams'
const relativeRoutes = {
  relativePage: `${main}/*`,
  relativeList: `${main}`,
  relativeDetail: ':id',
}

const routes = {
  List: `/${main}`,
  Details: `/${main}/${relativeRoutes.relativeDetail}`,
}

const TeamRoutes = { ...relativeRoutes, ...routes }

export default TeamRoutes
