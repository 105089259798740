import { ATableFullPageWithoutData } from 'views/components/Table/ATableFullPageWithoutData'
import { useSingleEntity } from 'modules/domain/common/hooks'
import { getLogisticsRanges } from 'views/pages/LogisticsKR/helpers'
import { RateTableProps } from './types'
import { RateType } from 'views/pages/LogisticsKR/Rates/types'
import styled from 'styled-components'

const Wrapper = styled.div`
  .ant-table-cell-fix-right-first {
    right: 0px !important;
  }
`

export const AverageTable = ({
  columnsFunc,
  listRequestParams,
  setListRequestParams,
  dataEndpoint,
}: RateTableProps) => {
  const [progress, data = [], refetch] = useSingleEntity<Array<{ logistics_rates: RateType[] }>>(dataEndpoint)
  const columns = columnsFunc(getLogisticsRanges(), refetch)

  return (
    <Wrapper>
      <ATableFullPageWithoutData
        columns={columns}
        data={data}
        listParamsUpdated={setListRequestParams}
        listRequestParams={listRequestParams}
        pageSize={1}
        progress={progress}
        total={data?.length ?? 1}
        rowHoverGray
        rowKey={({ logistics_rates }) => logistics_rates[0].id}
      />
    </Wrapper>
  )
}
