import { defaultTheme, Typography } from '@agro-club/agroclub-shared'
import { DASH } from 'modules/constants'
import { Carrier } from 'modules/domain/logisticsKR/types'
import { getPersonalNumberLabel } from 'modules/utils/numbers/formatPersonalNumber'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import APhone from 'views/ui/Phone/APhone'

const Title = styled.div`
  ${Typography.bodyLarge}
  color: ${defaultTheme.color.textSecondary60a};
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  color: ${defaultTheme.color.textPrimary900};
`

const GroupInfo = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`

type Props = {
  carrier: Carrier
}

export const CarrierInfo: FC<Props> = ({ carrier }) => {
  const { t } = useTranslation('logisticsKR')
  const workingRegions = carrier?.working_regions?.map((region) => region.name).join(', ')
  const favoriteRegions = carrier.favorite_regions.map((region) => region.name).join(', ')

  return (
    <Wrapper>
      <GroupInfo>
        <Title>{t('carriers.accordion.subtitles.common_info')}</Title>
        {carrier.contact_name ? `${carrier.contact_name}, ` : DASH}
        <br />
        {carrier.name ? `${carrier.name}, ` : DASH}
        {carrier.inn ? `${getPersonalNumberLabel()} ${carrier.inn}, ` : DASH}
        {carrier.phone ? <APhone phone={carrier.phone} /> : DASH}
      </GroupInfo>
      {!!workingRegions && (
        <GroupInfo>
          <Title>{t('carriers.accordion.subtitles.work_region')}</Title>
          {workingRegions}
        </GroupInfo>
      )}
      {!!favoriteRegions && (
        <GroupInfo>
          <Title>{t('carriers.accordion.subtitles.favorite_region')}</Title>
          {favoriteRegions}
        </GroupInfo>
      )}
      <GroupInfo>
        <Title>{t('carriers.accordion.subtitles.work_type')}</Title>
        {t(`carriers.${carrier.carrier_type}`)}
      </GroupInfo>
    </Wrapper>
  )
}
