import { useMemo } from 'react'
import { ColumnData } from 'modules/types'
import { useTranslation } from 'react-i18next'
import { REACT_APP_CURRENCY_SYMBOL } from 'env'
import { DriverCell } from './DriverCell'
import formatNumber from 'modules/utils/numbers/formatNumber'
import { CarrierCell } from './CarrierCell'
import { AgentCell } from './AgentCell'
import useFormatDate from 'hooks/useFormatDate'
import { ListRequestParams } from 'modules/domain/types'
import { OnApprovalTripCell } from './OnApprovalTripCell'

export const useOnApprovalColumns = (
  listRequestParams: ListRequestParams,
  setListRequestParams: (params: ListRequestParams) => void,
): ColumnData[] => {
  const { t } = useTranslation('logisticsKR')
  const formatDate = useFormatDate()
  const currency = REACT_APP_CURRENCY_SYMBOL

  return useMemo(
    () => [
      {
        title: t('details.tableHeaders.load_date'),
        width: 94,
        dataIndex: 'load_date',
        render: (load_date) => formatDate(load_date),
      },
      {
        title: t('details.tableHeaders.added_by'),
        width: 132,
        dataIndex: 'logistician',
        render: (logistician) => (logistician ? <div>{logistician.short_name}</div> : null),
      },
      {
        title: t('details.tableHeaders.contract_type'),
        width: 112,
        dataIndex: 'deal_type',
        render: (deal_type) => t(`details.deal_types.${deal_type}`),
      },
      {
        title: t('details.tableHeaders.agent'),
        width: 142,
        dataIndex: 'agent',
        render: (agent, data) => (agent ? <AgentCell name={agent?.name} commission={data?.agent_commission} /> : null),
      },
      {
        title: t('details.tableHeaders.company'),
        width: 170,
        dataIndex: 'carrier',
        render: (_, trip) => <CarrierCell trip={trip} />,
      },
      {
        title: t('details.tableHeaders.driver'),
        width: 200,
        dataIndex: 'driver',
        render: (driver, data) => <DriverCell driver={driver} logistics_request={data} />,
      },
      {
        title: t('details.tableHeaders.price_logistics', { currency }),
        width: 95,
        align: 'right',
        dataIndex: 'price_logistics',
        render: (price_logistics) => formatNumber(price_logistics, true),
      },
      {
        title: '',
        width: 48,
        align: 'center',
        dataIndex: 'actions',
        render: (_, data) => (
          <OnApprovalTripCell
            listRequestParams={listRequestParams}
            setListRequestParams={setListRequestParams}
            tripId={data.id}
          />
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, currency, formatDate, listRequestParams],
  )
}
