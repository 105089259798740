import { Progress } from '@agro-club/agroclub-shared'
import { BidContractFilter } from 'modules/domain/bid/api'
import { Bid, BidContract, BidStatus, BidTypes } from 'modules/domain/bid/types'
import { useTableData } from 'modules/domain/common/hooks'
import { Deal } from 'modules/domain/deal/types'
import { ListRequestParams } from 'modules/domain/types'
import { endpoints } from 'modules/endpoints'
import { useEffect, useMemo, useState } from 'react'
import { defaultListRequestParams } from 'views/components/TableFilters/data'

type Params<T> = {
  bidType?: BidTypes | null
  filterBid?: T | null
  manualFetch?: boolean
}

export type RematchingItemsRequestParams = Omit<ListRequestParams, 'filter'> & {
  filter: BidContractFilter
}

export const useRematching = <T extends Bid | BidContract>(params: Params<T>) => {
  const [bid, setBid] = useState<T | null>(null)
  const [filterBid, setFilterBidState] = useState<T | null>(params.filterBid || null)
  const [search, setSearch] = useState('')
  const [productSlug, setProductSlug] = useState('')
  const [deal, setDeal] = useState<Deal | null>(null)

  const requestParams = useMemo(
    () =>
      ({
        ...defaultListRequestParams,
        filter: {
          bid_type: params.bidType,
          match_bid_id: filterBid?.id,
          matchable: true,
          deal_id: deal?.id,
          search,
          status: BidStatus.published,
          product_slug: productSlug,
        },
        pageSize: 1000,
      } as RematchingItemsRequestParams),
    [filterBid, search, productSlug, deal, params.bidType],
  )

  const { data: items, progress, refetch, total } = useTableData<T>(endpoints.contracts(), requestParams, false)
  const loading = progress === Progress.WORK

  const setFilterBid = (value: T | null) => {
    if (!value && filterBid) {
      setBid(null)
    }
    if (filterBid?.id != value?.id) {
      setFilterBidState(value)
    }
  }

  const reset = () => {
    setFilterBidState(null)
    setBid(null)
    setSearch('')
  }

  useEffect(() => {
    const requestParamsValid = requestParams.filter.bid_type || requestParams.filter.match_bid_id
    if (!requestParamsValid || params.manualFetch) {
      return
    }
    refetch()
  }, [requestParams, refetch, params.manualFetch])

  return {
    search,
    setSearch,

    deal,
    setDeal,

    productSlug,
    setProductSlug,

    filterBid,
    setFilterBid,
    bid,
    setBid,

    items,
    requestParams,
    refetch,
    loading,
    progress,
    total,

    reset,
  }
}

export type RematchingData = ReturnType<typeof useRematching>

export const useRematchingSync = (main: RematchingData, secondary: RematchingData) => {
  useEffect(() => {
    if (main.filterBid?.id) {
      return
    }

    if (secondary.filterBid?.id != main.bid?.id) {
      secondary.setBid(null)
      secondary.setProductSlug('')
    }
    secondary.setFilterBid(main.bid)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [main.bid, main.filterBid])
}
