import { AntTooltip, AntTooltipProps, Icons, defaultTheme } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

const IconQuestionWrapper = styled.div<{ size: 'normal' | 'compact' }>`
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: ${({ size }) => {
      if (size === 'normal') return '24px'
      if (size === 'compact') return '20px'
    }};
    height: ${({ size }) => {
      if (size === 'normal') return '24px'
      if (size === 'compact') return '20px'
    }};
  }

  svg > path {
    fill: ${defaultTheme.color.secondary300};
  }

  &:hover > svg > path {
    fill: ${defaultTheme.color.secondary900};
  }
`

export const QuestionTooltip = ({
  tooltipContent,
  placement = 'topLeft',
  variant = 'secondary',
  size = 'normal',
  ...otherProps
}: AntTooltipProps & { size?: 'normal' | 'compact' }) => {
  return (
    <AntTooltip variant={variant} tooltipContent={tooltipContent} placement={placement} {...otherProps}>
      <IconQuestionWrapper size={size}>
        <Icons.IconHow />
      </IconQuestionWrapper>
    </AntTooltip>
  )
}
