import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { DispatchingTypes } from './types'
import { SetStateAction, useEffect, useState } from 'react'
import DispatchingRoutes from './routes'

const useActiveTab = (): [string, React.Dispatch<SetStateAction<string>>] => {
  const navigate = useNavigate()
  const { activeTab: activeTabParams } = useParams()
  const [activeTab, setActiveTab] = useState<string>(activeTabParams || DispatchingTypes.open)
  useEffect(() => {
    navigate(
      {
        pathname: generatePath(DispatchingRoutes.ListWithTab, { activeTab: activeTab }),
        search: location.search,
      },
      { replace: true },
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab])

  return [activeTab, setActiveTab]
}

export default useActiveTab
