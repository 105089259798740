import { TaskManagementForm } from 'views/pages/MyTasks/TasksList/TaskDistribution/components/TaskManagementForm'
import { ResponseCoordinatorsTaskStats } from 'views/pages/MyTasks/TasksList/TaskDistribution/DistributeBlock'
import { SharedModalContent, SharedModalInputs, SharedModalTitle } from 'views/components/SharedModal'
import { CoordinatorsStatBoard } from 'views/pages/MyTasks/TasksList/TaskDistribution/RearrangeBlock'
import ADefaultModalFooter from 'views/components/DefaultModalFooter/ADefaultModalFooter'
import { DateISO, Progress, Spinner } from '@agro-club/agroclub-shared'
import ASharedModal from 'views/components/SharedModal/ASharedModal'
import { AnalyticsPlaces } from 'views/pages/User/analyticsPlaces'
import { useCombinedProgress } from 'hooks/useCombinedProgress'
import { useSingleEntity } from 'modules/domain/common/hooks'
import { HrWithHeader } from 'views/components/Hr/Hr'
import { AButton } from 'views/components/Analytics'
import { apiClient } from 'modules/utils/httpClient'
import { useAProgress } from 'hooks/useAProgress'
import { useAFormHandler } from 'analytics/hooks'
import { useTranslation } from 'react-i18next'
import { endpoints } from 'modules/endpoints'
import { ModalProps } from '../../../types'
import styled from 'styled-components'
import { useFormik } from 'formik'
import { FC } from 'react'

const Loading = styled.div`
  height: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
`

type Fields = {
  new_assignees: string[]
  new_team: string | null
  tasks_per_day: string
  other_task_per_day: string | null
  start_deadline_date: DateISO | undefined
}

export const BlockCoordinatorModal: FC<ModalProps> = ({ onClose, refetch, userId }) => {
  const { t } = useTranslation('user')

  const [statProgress, stat] = useSingleEntity<ResponseCoordinatorsTaskStats>(endpoints.getCoordinatorTaskStats(userId))

  const [blockCoordinatorProgress, blockCoordinator] = useAProgress(() => apiClient.post(endpoints.blockUser(userId)), {
    eventName: 'blockCoordinator',
    analyticsPayload: {
      id: userId,
    },
    onSuccess: () => {
      refetch()
      onClose()
    },
  })

  const { formProgress, formHandler } = useAFormHandler('blockUser', { id: userId })
  const progress = useCombinedProgress(blockCoordinatorProgress, formProgress)

  const formik = useFormik<Fields>({
    initialValues: {
      new_assignees: [],

      new_team: null,
      tasks_per_day: '50',
      start_deadline_date: undefined,
      other_task_per_day: null,
    },

    onSubmit: formHandler(
      async () => {
        const data = {
          old_assignees: [userId],
          new_assignees: formik.values.new_assignees,
          tasks_per_day:
            formik.values.tasks_per_day === 'other' ? formik.values.other_task_per_day : formik.values.tasks_per_day,
          start_deadline_date: formik.values.start_deadline_date,
        }
        await apiClient.post(endpoints.reassignTasks(), data)
      },
      {
        onSuccess: () => {
          blockCoordinator()
        },
        onError: (error) => {
          const { errors } = error
          formik.setErrors(errors)
        },
      },
    ),
  })

  return (
    <>
      <ASharedModal id={AnalyticsPlaces.BLOCK_MODAL} onClose={onClose} size="medium">
        <SharedModalTitle>{t('blockCoordinator')}</SharedModalTitle>
        {statProgress !== Progress.SUCCESS && (
          <Loading>
            <Spinner />
          </Loading>
        )}
        {statProgress === Progress.SUCCESS &&
          stat &&
          (stat.total_tasks ? (
            <>
              <SharedModalContent>
                <SharedModalInputs>
                  <div> {t('blockWithTasks')}</div>
                  <CoordinatorsStatBoard
                    title={t('my_tasks:task_distribution.total_tasks')}
                    progress={statProgress}
                    data={stat}
                  />
                  <HrWithHeader text={t('my_tasks:task_distribution.rearrange_tasks')} />
                  <TaskManagementForm formik={formik} teamField="new_team" assigneeField="new_assignees" />
                </SharedModalInputs>
              </SharedModalContent>

              <ADefaultModalFooter
                confirmButtonText={t('saveAndBlock')}
                onConfirm={() => formik.submitForm()}
                progress={progress}
                onClose={onClose}
              >
                <AButton progress={progress} onClick={blockCoordinator} intent="whiteDestruct" id="block" size="big">
                  {t('onlyBlock')}
                </AButton>
              </ADefaultModalFooter>
            </>
          ) : (
            <>
              <SharedModalContent>{t('blockWithoutTasks')}</SharedModalContent>
              <ADefaultModalFooter
                confirmButtonText={t('common:block')}
                onConfirm={blockCoordinator}
                progress={progress}
                onClose={onClose}
                intent="destruct"
              />
            </>
          ))}
      </ASharedModal>
    </>
  )
}
