import { AntTag } from '@agro-club/agroclub-shared'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { DispatchingStatuses } from 'views/pages/Dispatching/types'

interface Props {
  status: DispatchingStatuses
}

export const DispatchingStatusesTags: FC<Props> = ({ status }) => {
  const { t } = useTranslation('dispatching')
  const text = t(`statuses.${status}`)

  if (!status) return null

  switch (status) {
    case DispatchingStatuses.closed:
      return <AntTag color="grey">{text}</AntTag>
    case DispatchingStatuses.urgent:
      return <AntTag color="red">{text}</AntTag>
    case DispatchingStatuses.no_car_needed:
      return <AntTag color="cyan">{text}</AntTag>
    case DispatchingStatuses.car_restrictions:
      return <AntTag color="orange">{text}</AntTag>
    case DispatchingStatuses.pause:
      return <AntTag color="blue">{text}</AntTag>
    default:
      return <AntTag color="white">{text}</AntTag>
  }
}
