import SpecificationList from 'views/pages/Specifications/SpecificationList'
import SpecificationsRoutes from 'views/pages/Specifications/routes'
import SpecificationDetail from 'views/pages/Specifications/SpecificationDetail/SpecificationDetail'
import { Route, Routes } from 'react-router-dom'

const SpecificationPage = () => (
  <Routes>
    <Route index element={<SpecificationList />} />
    <Route path={SpecificationsRoutes.relativeDetail} element={<SpecificationDetail />} />
  </Routes>
)

export default SpecificationPage
