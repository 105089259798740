import { isBrazil } from 'env'
import { InputWrapper } from '../styled'
import { FormInput } from 'views/components/form/FormInput'
import { labelPriceLogistics } from 'modules/utils/numbers/formatPriceLogistics'
import { FormikProps } from 'formik'
import { useTranslation } from 'react-i18next'

interface Props {
  formik: FormikProps<any>
}

export const LogisticInputs = ({ formik }: Props) => {
  const { t } = useTranslation('deal')

  if (!isBrazil) {
    return (
      <>
        <InputWrapper>
          <FormInput
            label={labelPriceLogistics(t('contractSign.price_logistics'))}
            field="price_logistics"
            type="number"
            formik={formik}
            size={'small'}
          />
        </InputWrapper>

        <InputWrapper>
          <FormInput
            label={labelPriceLogistics(t('contractSign.price_carrier'))}
            field="price_carrier"
            type="number"
            formik={formik}
            size={'small'}
          />
        </InputWrapper>
      </>
    )
  }

  return null
}
