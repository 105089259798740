import { TableTextLink } from 'views/ui/TableTextLink/TableTextLink'
import { labelQuantity } from 'modules/utils/numbers/formatQuantity'
import * as renderers from 'views/components/Table/renderers'
import formatNumber from 'modules/utils/numbers/formatNumber'
import { labelTarif } from 'modules/utils/numbers/formatTarif'
import { CompanyCell } from 'views/pages/Bid/BidTable/styled'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router'
import BidContractsRoutes from '../routes'
import { useMemo } from 'react'
import { ContractStatusTag } from '../BidContractDetail/ContractStatusTag'

export const useColumns = () => {
  const { t } = useTranslation('bidContracts')

  return useMemo(
    () => [
      {
        title: t('list.id'),
        width: 100,
        dataIndex: 'number',
        render: (number, data) => (
          <TableTextLink
            to={generatePath(BidContractsRoutes.Details, { id: data.id })}
            tableItemId={data.id}
            id="contract"
          >
            {number}
          </TableTextLink>
        ),
      },
      {
        title: t('list.status'),
        width: 110,
        dataIndex: 'status',
        render: (status) => <ContractStatusTag status={status} />,
      },
      {
        title: t('list.signed'),
        width: 110,
        dataIndex: 'signed_at',
        render: renderers.date,
      },
      {
        title: t('list.crop'),
        width: 200,
        dataIndex: 'product',
        render: (product) => product.title,
      },
      {
        title: labelQuantity(),
        width: 120,
        dataIndex: 'quantity',
        align: 'right',
        render: renderers.number,
      },
      {
        title: labelQuantity(t('list.remainingQuantity')),
        width: 172,
        align: 'right',
        dataIndex: 'remaining_quantity',
        render: (remaining_quantity) => formatNumber(remaining_quantity),
      },
      {
        title: labelTarif(t('list.price')),
        width: 130,
        align: 'right',
        dataIndex: 'price',
        render: renderers.price,
      },
      {
        title: t('list.company'),
        width: 690,
        dataIndex: 'company',
        render: (company) => <CompanyCell id={company.id} name={company.name} />,
      },
    ],
    [t],
  )
}
