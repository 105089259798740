import { ResponsiveModal } from 'views/components/ResponsiveModal/ResponsiveModal'
import { KeyValue, Value } from 'views/components/KeyValue/KeyValue'
import { SharedModalContent } from 'views/components/SharedModal'
import formatNumber from 'modules/utils/numbers/formatNumber'
import { getPriceTypeLabel } from 'modules/domain/bid/utils'
import formatPrice from 'modules/utils/numbers/formatPrice'
import { defaultTheme } from '@agro-club/agroclub-shared'
import { AnalyticsPlaces } from '../analyticsPlaces'
import { Deal } from 'modules/domain/deal/types'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { FC } from 'react'

type Props = {
  deal: Deal
  close: () => void
}

const HistoryContentWrapper = styled.div`
  display: grid;
  grid-template-columns: 154px 154px;
  grid-gap: 12px 53px;
  @media (max-width: ${defaultTheme.queries.mobile}) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
  }
`

export const DealHistoryModal: FC<Props> = ({ deal, close }) => {
  const { t } = useTranslation(['deal', 'bid'])

  const saleBidLabel = getPriceTypeLabel(deal.sale_bid.price_type, t)
  const purchaseBidLabel = getPriceTypeLabel(deal.purchase_bid.price_type, t)

  return (
    <ResponsiveModal id={AnalyticsPlaces.HISTORY_MODAL} onClose={close} title={t('historyModal.title')}>
      <SharedModalContent>
        <HistoryContentWrapper>
          <Value typography="accentLarge">{t('historyModal.priceOnSign')}</Value>
          <Value typography="accentLarge">{t('historyModal.forNow')}</Value>
          <KeyValue label={saleBidLabel}>{formatPrice(deal.sale_price_on_contract_signed)}</KeyValue>
          <KeyValue label={saleBidLabel}>{formatPrice(deal.sale_price)}</KeyValue>
          <KeyValue label={purchaseBidLabel}>{formatPrice(deal.purchase_price_on_contract_signed)}</KeyValue>
          <KeyValue label={purchaseBidLabel}>{formatPrice(deal.purchase_price)}</KeyValue>
          <KeyValue label={t('historyModal.priceDelivery')}>
            {formatPrice(deal.price_logistics_on_contract_signed)}
          </KeyValue>
          <KeyValue label={t('historyModal.priceDelivery')}>{formatPrice(deal.price_logistics)}</KeyValue>
          <KeyValue label={t('historyModal.quantity')}>{formatNumber(deal.quantity_on_contract_signed)}</KeyValue>
          <KeyValue label={t('historyModal.quantity')}>{formatNumber(deal.quantity)}</KeyValue>
        </HistoryContentWrapper>
      </SharedModalContent>
    </ResponsiveModal>
  )
}
