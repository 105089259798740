import APageWrapper from 'views/components/PageWrapper/APageWrapper'
import * as Layout from 'views/layouts/NewLayout'
import * as Header from 'views/ui/Header/Header'
import { useTranslation } from 'react-i18next'
import { RoutesFilters } from 'views/pages/Routes/RoutesList/RoutesFilters'
import { useState } from 'react'
import { ListRequestParams } from 'modules/domain/types'
import { defaultListRequestParams } from 'views/components/TableFilters/data'
import { useFilters } from 'hooks/useFilter'
import ATableFullPage from 'views/components/Table/ATableFullPage'
import { useColumns } from 'views/pages/Routes/RoutesList/useColumns'
import { AnalyticsPages, AnalyticsPlaces } from 'views/pages/Routes/analyticsPlaces'
import { endpoints } from 'modules/endpoints'
import { AddFileButton } from 'views/pages/Routes/RoutesList/AddFileButton'
import { RightBlock } from 'views/pages/Routes/RoutesList/RightBlock'
import { RoutesFileResponse } from 'modules/domain/routes/types'

export const RoutesList = () => {
  const { t } = useTranslation('routes')
  const [fileResponse, setFileResponse] = useState<RoutesFileResponse | undefined>()

  const [listRequestParams, setListRequestParams] = useState<ListRequestParams>(defaultListRequestParams)
  const { isFiltersLoaded, updateFilterState } = useFilters({
    listRequestParams,
    setListRequestParams,
  })

  const columns = useColumns(listRequestParams.sort)

  return (
    <APageWrapper page={AnalyticsPages.ROUTES} place={AnalyticsPlaces.LIST}>
      <Layout.Header>
        <Layout.TopHeader>
          <Header.Breadcrumbs routes={[{ breadcrumbName: t('list.heading') }]} />
          <Header.AbsoluteButtonWrapper>
            <AddFileButton setFileResponse={setFileResponse} />
          </Header.AbsoluteButtonWrapper>
        </Layout.TopHeader>
        <Layout.PageName>{t('list.heading')}</Layout.PageName>
        <RoutesFilters
          listRequestParams={listRequestParams}
          setListRequestParams={setListRequestParams}
          updateFilterState={updateFilterState}
        />
      </Layout.Header>

      <Layout.ContentTwoColumns>
        <ATableFullPage
          listParamsUpdated={setListRequestParams}
          listRequestParams={listRequestParams}
          dataEndpoint={endpoints.brRoutesList()}
          isFiltersLoaded={isFiltersLoaded}
          columns={columns}
          rowHoverGray
          rowKey="created"
        />
        <RightBlock file={fileResponse} />
      </Layout.ContentTwoColumns>
    </APageWrapper>
  )
}
