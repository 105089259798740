import { FC, useState } from 'react'
import { Icons, Progress } from '@agro-club/agroclub-shared'
import { BoldText, CardWrapper } from '../../../components/styled'
import { useTranslation } from 'react-i18next'
import { LogisticRequest } from 'modules/domain/logisticsKR/types'
import { CardButtonWrapper } from 'views/styled/ItemCardStyles'
import useFormatDate from 'hooks/useFormatDate'
import APhone from 'views/ui/Phone/APhone'
import { AButton } from 'views/components/Analytics'
import { LogisticsKRTrip } from 'views/pages/LogisticsKR/types'
import { formatQuantity } from 'modules/utils/numbers/formatQuantity'
import { AddDraftTripModal } from 'views/pages/LogisticsKR/components/AddDraftTripModal/AddDraftTripModal'
import { WrapperCards } from 'views/pages/LogisticsKR/LogisticRequests/styled'
import { PreloadCard } from 'views/components/Board/Preload'
import { refetchFunc } from 'modules/domain/common/hooks'
import { DASH } from 'modules/constants'

type CardProps = {
  trip: LogisticsKRTrip
  refetchTripsReconciliation: refetchFunc
  request: LogisticRequest | undefined
}

const TripCard: FC<CardProps> = ({ trip, request, refetchTripsReconciliation }) => {
  const { t } = useTranslation('logisticsKR')
  const formatDate = useFormatDate()
  const [addCarModal, setAddCarModal] = useState(false)
  const { car, trailer, driver } = trip

  const carParameters = [
    !!car?.car_type && t(`vehicle_type.${car.car_type}`),
    !!car?.is_dump_truck ? t('cars.is_dump_truck') : t('cars.is_not_dump_truck'),
    !!car?.unloading_type && t(`directions.${car.unloading_type}`),
    !!car?.side_height && t('warehouses.details.values.scales_length', { scales_length: car.side_height }),
  ]
    .filter(Boolean)
    .join(', ')

  const quantityInfo = [
    trip.load_quantity && formatQuantity(trip.load_quantity),
    trip.unload_quantity && formatQuantity(trip.unload_quantity),
  ]
    .filter(Boolean)
    .join(' / ')

  return (
    <CardWrapper>
      <BoldText>{`${formatDate(trip.load_date)} / ${formatDate(trip.unload_date)}`}</BoldText>
      <BoldText>{quantityInfo}</BoldText>
      <div>{driver?.name}</div>
      <APhone phone={driver?.phone ?? DASH} />
      <div>{`${car?.brand_model ?? DASH} ${car?.licence_number ?? DASH} / ${trailer?.licence_number ?? DASH}`}</div>
      <div>{carParameters}</div>
      <div>
        {t('logisticBids:trip_count', { count: trip.car?.trips_count ?? 0 })}
        {trip.transit ? `, ${t('trips.transit')}` : ''}
      </div>
      <CardButtonWrapper>
        <AButton id="carToTrip" intent="white" Icon={Icons.IconAddPlusCircle} onClick={() => setAddCarModal(true)} />
      </CardButtonWrapper>
      {addCarModal && (
        <AddDraftTripModal
          carrierHasVat={trip.has_vat}
          agentHasVat={trip.has_agent_vat}
          agentCommission={trip.agent_commission}
          agent={trip.agent}
          carrier={trip.carrier}
          carId={trip.car?.id}
          trailerId={trip.trailer?.id}
          driverId={trip.driver?.id}
          onClose={() => setAddCarModal(false)}
          onSuccess={refetchTripsReconciliation}
          activeRequest={request}
        />
      )}
    </CardWrapper>
  )
}

type Props = {
  trips: LogisticsKRTrip[] | undefined
  refetchTripsReconciliation: () => Promise<void>
  request: LogisticRequest | undefined
  progress: Progress
}

export const Trips: FC<Props> = ({ trips, request, refetchTripsReconciliation, progress }) => {
  if (progress === Progress.WORK) {
    return (
      <WrapperCards>
        <PreloadCard />
      </WrapperCards>
    )
  }

  return (
    <WrapperCards>
      {trips?.map((trip) => (
        <TripCard
          key={trip?.id}
          trip={trip}
          request={request}
          refetchTripsReconciliation={refetchTripsReconciliation}
        />
      ))}
    </WrapperCards>
  )
}
