import { HistoryChangesCell } from './HistoryChangesCell'
import useFormatDate from 'hooks/useFormatDate'
import { useTranslation } from 'react-i18next'
import { ColumnData } from 'modules/types'
import { useMemo } from 'react'

export const useColumns = (): ColumnData[] => {
  const { t } = useTranslation('bid')
  const formatDate = useFormatDate()

  return useMemo(
    () => [
      {
        title: t('history.date'),
        width: 142,
        dataIndex: 'date',
        align: 'left',
        render: (date) => formatDate(date, true),
      },
      {
        title: t('history.user'),
        width: 148,
        dataIndex: 'user',
        render: (user) => user?.short_name || '-',
      },
      {
        title: t('history.changes'),
        autoWidth: 233,
        dataIndex: 'changes',
        render: (changes) => <HistoryChangesCell changes={changes} />,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t],
  )
}
