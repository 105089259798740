import { generatePath } from 'react-router'
import { useTranslation } from 'react-i18next'

import useLocationFrom from 'hooks/useLocationFrom'
import { Team } from 'modules/domain/teams/types'
import {
  CardHeader,
  MemberContainer,
  MemberIcon,
  MemberInfoContainer,
  MemberName,
  MemberPhone,
  MembersCard,
  MembersList,
  ProfileRole,
} from 'views/pages/Teams/TeamDetail/styled'
import UserRoutes from 'views/pages/User/routes'
import { FC } from 'react'

type Props = {
  teamMembers: Team
}

export const TeamMembersCard: FC<Props> = ({ teamMembers: { members } }) => {
  const from = useLocationFrom()
  const { t } = useTranslation('teams')

  return (
    <MembersCard>
      <CardHeader>{t('members')}</CardHeader>
      <MembersList>
        {members?.map((member) => (
          <MemberContainer key={member.user_id}>
            <MemberIcon />
            <MemberInfoContainer>
              <MemberName
                eventName="member"
                itemId={String(member.user_id)}
                to={generatePath(UserRoutes.Details, { id: String(member.user_id) })}
                state={from}
              >
                {member.full_name}
              </MemberName>
              <ProfileRole>{member.coordinator_type || t('user:role:coordinator')}</ProfileRole>
              <MemberPhone>{member.phone}</MemberPhone>
            </MemberInfoContainer>
          </MemberContainer>
        ))}
      </MembersList>
    </MembersCard>
  )
}
