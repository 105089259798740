import { FC, useState } from 'react'

import { Icons } from '@agro-club/agroclub-shared'

import { DetailsItemButtons, DetailsItemInfo, Text } from '../../../../components/styled'

import { endpoints } from 'modules/endpoints'
import { apiClient } from 'modules/utils/httpClient'

import { useTranslation } from 'react-i18next'
import { TrailerEditModal } from '../../../../components/TrailerModals/TrailerEditModal'
import { Trailer } from '../../../../types'
import { BorderedItem } from 'views/components/BorderedItem/BorderedItem'
import { SharedModalContent, SharedModalTitle } from 'views/components/SharedModal'
import { useNotificationProgress } from 'hooks/useNotificationProgress'
import { AButton } from 'views/components/Analytics'
import ASharedModal from 'views/components/SharedModal/ASharedModal'
import { AnalyticsPlaces } from 'views/pages/LogisticsKR/analyticsPlaces'
import ADefaultModalFooter from 'views/components/DefaultModalFooter/ADefaultModalFooter'
import { useAProgress } from 'hooks/useAProgress'

interface Props {
  trailer: Trailer
  carrierId: string
  refetch: () => void
}

export const TrailerItem: FC<Props> = ({ trailer, carrierId, refetch }) => {
  const { t } = useTranslation('logisticsKR')
  const [isModalOpened, setIsModalOpened] = useState(false)
  const [removeConfirmModal, setRemoveConfirmModal] = useState(false)

  const [removeProgress, remove] = useAProgress(
    (id) => apiClient.post(endpoints.LogisticsKR.trailerChangeStatus(id), { new_status: 'archived' }),
    {
      eventName: 'removeTrailer',
      onSuccess: refetch,
    },
  )

  const trailerParams = [
    !!trailer.brand_model && trailer.brand_model,
    !!trailer.unloading_type && t(`directions.${trailer.unloading_type}`),
    !!trailer.side_height && t('common:number_m', { number: trailer.side_height }),
  ]
    .filter(Boolean)
    .join(', ')

  useNotificationProgress(
    removeProgress,
    t('carriers.notifications.trailer_archived_success'),
    t('carriers.notifications.trailer_archived_error'),
  )

  return (
    <>
      <BorderedItem>
        <DetailsItemInfo>
          <Text>{trailer.licence_number}</Text>
          <Text>{trailerParams}</Text>
        </DetailsItemInfo>
        <DetailsItemButtons>
          <AButton id="editTrailer" intent="white" Icon={Icons.IconEdit} onClick={() => setIsModalOpened(true)} />
          <AButton
            id="removeTrailer"
            intent="white"
            Icon={Icons.IconDelete}
            onClick={() => setRemoveConfirmModal(true)}
          />
        </DetailsItemButtons>
      </BorderedItem>

      {isModalOpened && (
        <TrailerEditModal trailer={trailer} setIsOpen={setIsModalOpened} carrierId={carrierId} onSuccess={refetch} />
      )}
      {removeConfirmModal && (
        <ASharedModal
          id={AnalyticsPlaces.CARRIERS.DELETE_TRAILER_MODAL}
          size="small"
          onClose={() => setRemoveConfirmModal(false)}
        >
          <SharedModalTitle>{t('carriers.confirmModals.archivedTrailer')}</SharedModalTitle>
          <SharedModalContent>{t('carriers.confirmModals.youReallyWantArchivedTrailer')}</SharedModalContent>
          <ADefaultModalFooter
            onClose={() => setRemoveConfirmModal(false)}
            onConfirm={() => remove(trailer.id)}
            progress={removeProgress}
          />
        </ASharedModal>
      )}
    </>
  )
}
