import { defaultTheme, Typography } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

export const FilesHeader = styled.div`
  ${Typography.bodyLarge}
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${defaultTheme.color.textSecondary60a};
  padding: 9px 15px 10px 11px;
  border: 1px solid ${defaultTheme.color.secondary50};
  border-radius: 8px;

  &:not(:last-of-type) {
    border-bottom: none;
    border-radius: 8px 8px 0 0;
  }
`
export const FilesStyled = styled.div`
  & + & {
    margin-top: 8px;
  }
`

export const FilesContent = styled.div`
  border-radius: 8px;
  border: 1px solid ${defaultTheme.color.secondary50};

  a {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &:not(:first-child) {
    border-radius: 0 0 8px 8px;
  }

  &:not(:last-child) {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`

export const FileItemStyled = styled.div`
  ${Typography.bodyLarge};
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 42px;
  padding: 10px 11px 11px 11px;

  &:hover {
    background-color: ${defaultTheme.color.secondary25};
    &:last-child {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }

  & + & {
    border-top: 1px solid ${defaultTheme.color.secondary50};
  }
`

export const FileInput = styled.input`
  display: none;
`

export const FilesLayout = styled.div`
  position: relative;
`

export const Loading = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.9);
  z-index: 1;
`

export const SubTitle = styled.div`
  margin-bottom: 12px;
  color: ${defaultTheme.color.textPrimary900};

  &:not(:first-child) {
    margin-top: 24px;
  }
`
