import env from 'env'
import { useMemo } from 'react'
import { ColumnData } from 'modules/types'
import { useTranslation } from 'react-i18next'
import { DealLink } from 'views/components/DealLink/DealLink'
import { UserLink } from 'views/components/UserLink/UserLink'
import formatPrice from 'modules/utils/numbers/formatPrice'
import { CompanyLink } from 'views/components/CompanyLink/CompanyLink'
import { labelQuantity } from 'modules/utils/numbers/formatQuantity'
import formatNumber from 'modules/utils/numbers/formatNumber'

export const useColumns = (): ColumnData[] => {
  const { t } = useTranslation('specification')
  const currency = env.REACT_APP_CURRENCY_SYMBOL
  return useMemo(
    () => [
      {
        title: t('table.deal'),
        width: 100,
        dataIndex: 'id',
        render: (id) => <DealLink bodysize="bodyMedium" dealId={id} />,
      },
      {
        title: t('table.company_name'),
        width: 244,
        dataIndex: 'company',
        render: (company) => <CompanyLink bodysize="bodyMedium" company={company} />,
      },
      {
        title: t('table.owner'),
        width: 244,
        dataIndex: 'owner',
        render: (owner) => <UserLink bodysize="bodyMedium" user={owner} />,
      },
      {
        title: labelQuantity(),
        width: 82,
        dataIndex: 'quantity',
        render: (quantity) => formatNumber(quantity),
        align: 'right',
      },
      {
        title: labelQuantity(t('table.quantity_shipping')),
        width: 106,
        dataIndex: 'loaded_quantity',
        render: (loaded_quantity) => formatNumber(loaded_quantity),
        align: 'right',
      },
      {
        title: labelQuantity(t('table.brought')),
        width: 90,
        dataIndex: 'delivered_quantity',
        render: (delivered_quantity) => formatNumber(delivered_quantity),
        align: 'right',
      },
      {
        title: labelQuantity(t('table.remaining')),
        width: 100,
        dataIndex: 'remaining_quantity',
        render: (remaining_quantity) => formatNumber(remaining_quantity),
        align: 'right',
      },
      {
        title: t('table.price_exw', { currency }),
        width: 74,
        dataIndex: 'price_exw',
        render: (price_exw) => formatPrice(price_exw),
        align: 'right',
      },
    ],
    [t, currency],
  )
}
