import { defaultTheme, Icons, Input, Progress } from '@agro-club/agroclub-shared'
import { refetchFunc } from 'modules/domain/common/hooks'
import { apiClient } from 'modules/utils/httpClient'
import { AButton } from 'views/components/Analytics'
import { useAFormHandler } from 'analytics/hooks'
import { useMediaQuery } from 'react-responsive'
import { Deal } from 'modules/domain/deal/types'
import { endpoints } from 'modules/endpoints'
import styled from 'styled-components'
import { useFormik } from 'formik'
import { FC } from 'react'

const Content = styled.div`
  display: grid;
  grid-template-columns: 72px 40px 40px;
  column-gap: 8px;
  @media (max-width: ${defaultTheme.queries.mobile}) {
    grid-template-columns: 1fr 48px 48px;
  }
`

export const QuantityLoadPerDayForm: FC<{
  refetch: refetchFunc
  onClose: () => void
  countField: string
  deal: Deal
}> = ({ deal, countField, refetch, onClose }) => {
  const { formProgress, formHandler } = useAFormHandler('changeQuantityLoadPerDay', { id: deal.id })

  const isMobile = useMediaQuery({ query: `(max-width: ${defaultTheme.queries.mobile})` })

  const sizeBtn = isMobile ? 'biggest' : 'big'

  const formik = useFormik<{ count: number | null }>({
    initialValues: {
      count: deal.trip_count_norm?.[countField] || null,
    },

    onSubmit: formHandler(
      async () => {
        const data = { [countField]: formik.values.count }
        deal.trip_count_norm?.id &&
          (await apiClient.put(endpoints.dealChangeQuantityLoadPerDay(deal.id, deal.trip_count_norm.id), data))
      },
      {
        onSuccess: async () => {
          await refetch()
          onClose()
        },
        onError: (error) => {
          const { errors } = error
          formik.setErrors(errors)
        },
      },
    ),
  })
  return (
    <Content>
      <Input {...formik.getFieldProps('count')} invalid={!!formik.errors[countField]} type="number" />
      <AButton
        onClick={() => formik.submitForm()}
        progress={formProgress}
        Icon={Icons.IconDone}
        id="saveValuePerDay"
        intent="secondary"
        size={sizeBtn}
      />
      <AButton
        disabled={formProgress === Progress.WORK}
        onClick={onClose}
        Icon={Icons.IconClose}
        id="cancelValuePerDay"
        intent="secondary"
        size={sizeBtn}
      />
    </Content>
  )
}
