import { Logistician } from '../types'

export enum RatesProductTypes {
  cereals = 'cereals',
  oilseeds = 'oilseeds',
  average = 'average',
}

export interface RangeType {
  upper: number
  lower: number
}

export interface RateType {
  id?: string
  rate?: number
  distance_range: RangeType
}

export interface RateDTO {
  id: string
  logistics_rates: RateType[]
  name: string
  responsible_logisticians: Logistician[]
}

export interface EditableRatesDTO {
  sale_regions: string | string[]
  rates: RateType[]
  product_type?: RatesProductTypes | string
  responsible_logisticians: string[]
}

export type EditableAverageRatesDTO = Pick<EditableRatesDTO, 'rates'>
