import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import ASharedModal from 'views/components/SharedModal/ASharedModal'
import { SharedModalTitle, SharedModalContent } from 'views/components/SharedModal/styled'
import ADefaultModalFooter from 'views/components/DefaultModalFooter/ADefaultModalFooter'
import { AnalyticsPlaces } from 'views/pages/User/analyticsPlaces'

import { apiClient } from 'modules/utils/httpClient'
import { endpoints } from 'modules/endpoints'

import { useNotificationProgress } from 'hooks/useNotificationProgress'
import { useAProgress } from 'hooks/useAProgress'
import { ModalProps } from '../../types'

export const CoordinatorConfirmModal: FC<ModalProps> = ({ onClose, refetch, userId }) => {
  const { t } = useTranslation('user')
  const [verifyByCoordinatorProgress, verifyByCoordinator] = useAProgress(
    () => apiClient.post(endpoints.userVerifyCoordinator(userId)),
    {
      eventName: 'verifyByCoordinator',
      onSuccess: async () => {
        await refetch(true)
        onClose()
      },
    },
  )

  useNotificationProgress(verifyByCoordinatorProgress, t('form.notifyEditSuccess'))

  return (
    <ASharedModal id={AnalyticsPlaces.VERIFY_COORDINATOR_MODAL} onClose={onClose} size="small">
      <SharedModalTitle>{t('change_status')}</SharedModalTitle>
      <SharedModalContent>{t('doYouReallyWantMakeUserVerified')}</SharedModalContent>
      <ADefaultModalFooter onClose={onClose} onConfirm={verifyByCoordinator} progress={verifyByCoordinatorProgress} />
    </ASharedModal>
  )
}
