import { FC, useId, useMemo, useState } from 'react'

import { ExternalLink, Icons, Progress, Text } from '@agro-club/agroclub-shared'
import formatPhone from 'helpers/formatPhone'
import { useNotification } from 'hooks/useNotification'
import { endpoints } from 'modules/endpoints'
import { apiClient } from 'modules/utils/httpClient'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { AButton } from 'views/components/Analytics'
import { usePhoneVisibility } from 'views/ui/Phone/PhoneVisibilityContext'

const CommonComponent = styled.div<{ marginLeft?: string; marginRight?: string }>`
  display: inline-flex;
  align-items: center;
  margin-left: ${({ marginLeft }) => marginLeft};
  margin-right: ${({ marginRight }) => marginRight};
`

const WrapperVisible = styled(CommonComponent)`
  column-gap: 8px;
`

const WrapperHidden = styled(CommonComponent)`
  column-gap: 4px;
`

export type InternalPhoneProps = {
  phone: string
  isMobile?: boolean
  isMobileShort?: boolean
  className?: string
  onClick?: () => void
  shortBtnTitle?: boolean
  marginLeft?: string
  marginRight?: string
}

export const InternalPhone: FC<InternalPhoneProps> = ({
  phone,
  className,
  onClick,
  isMobile,
  isMobileShort,
  shortBtnTitle,
  marginRight,
  marginLeft,
  ...otherProps
}) => {
  const formatted = useMemo(() => formatPhone(phone), [phone])
  const { t } = useTranslation('common')

  const notify = useNotification()
  const [href, setHref] = useState<string>('#')
  const { visiblePhoneId, setVisiblePhoneId } = usePhoneVisibility()
  const id = useId()

  const copyPhoneHandler = () => {
    apiClient.post(endpoints.logs(), { phone })
    navigator.clipboard.writeText(phone)
    notify(Progress.SUCCESS, { title: t('phoneIsCopy') })
  }

  const showPhoneHandler = () => {
    apiClient.post(endpoints.logs(), { phone })
    setVisiblePhoneId(id)
  }

  const callHandler = () => {
    apiClient.post(endpoints.logs(), { phone })
    /* Чтобы до нажатия, нельзя была увидеть телефон в devtools в href */
    setHref(`tel:${phone}`)
  }

  const phoneIsVisible = id === visiblePhoneId

  return phoneIsVisible ? (
    <WrapperVisible marginLeft={marginLeft} marginRight={marginRight}>
      <div>
        <ExternalLink
          className={className}
          href={`tel:${phone}`}
          onClick={(e) => {
            e.stopPropagation()
            onClick?.()
          }}
          {...otherProps}
        >
          {formatted}
        </ExternalLink>

        {isMobile && (
          <Text as="span" color="secondary">
            &nbsp;({t('common:mobile').toLocaleLowerCase()})
          </Text>
        )}
        {isMobileShort && (
          <Text as="span" typography="bodySmall" color="secondary">
            &nbsp;({t('common:mobileShort').toLocaleLowerCase()})
          </Text>
        )}
      </div>
      <AButton id="copyVisiblePhone" onClick={copyPhoneHandler} intent="secondary" Icon={Icons.IconCopy} size="small" />
    </WrapperVisible>
  ) : (
    <WrapperHidden marginLeft={marginLeft} marginRight={marginRight}>
      <AButton id="showPhone" intent="secondary" size="small" onClick={showPhoneHandler}>
        {shortBtnTitle ? t('show') : t('showPhone')}
      </AButton>
      <AButton
        id="callToPhone"
        href={href}
        onClick={callHandler}
        intent="secondary"
        Icon={Icons.IconCallCenter}
        size="small"
      />
      <AButton id="copyVisiblePhone" onClick={copyPhoneHandler} intent="secondary" Icon={Icons.IconCopy} size="small" />
    </WrapperHidden>
  )
}
