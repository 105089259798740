import { FC, useEffect } from 'react'
import styled from 'styled-components'
import { Files } from './Files'
import { Declaration as DeclarationType, RespFile } from 'modules/domain/types'
import Declaration from './Declaration'
import { SellerSpecification } from './SellerSpecification'
import { BuyerSpecification } from './BuyerSpecification'
import { Deal } from 'modules/domain/deal/types'
import { isBidContractsEnabled } from 'env'
import { refetchFunc, useSingleEntity, useTableData } from 'modules/domain/common/hooks'
import { endpoints } from 'modules/endpoints'
import { defaultListRequestParams } from 'views/components/TableFilters/data'
import { Progress } from '@agro-club/agroclub-shared'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  margin-top: 2px;
`

type Props = {
  declaration: DeclarationType | undefined
  setSummary: (arg: boolean) => void
  declarationRefetch: refetchFunc
  declarationProgress: Progress
  deal: Deal
  dealRefetch: refetchFunc
}

export const DealFiles: FC<Props> = ({
  declarationRefetch,
  declaration,
  deal,
  dealRefetch,
  setSummary,
  declarationProgress,
}) => {
  const [dealFilesProgress, dealFiles, dealFilesRefetch] = useSingleEntity<RespFile[]>(endpoints.dealFileList(deal.id))
  const {
    progress: specProgress,
    data: specification = [],
    refetch: specificationRefetch,
  } = useTableData(endpoints.sellerSpecification(deal.id), defaultListRequestParams, !!deal.id)

  const groupDataIsLoaded =
    declarationProgress === Progress.SUCCESS &&
    dealFilesProgress === Progress.SUCCESS &&
    specProgress === Progress.SUCCESS

  useEffect(() => {
    if (groupDataIsLoaded) {
      setSummary(!!dealFiles?.length || !!declaration?.number || !!specification?.[0]?.price)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dealFiles?.length, declaration?.number, specification])

  const dealAndSpecRefectch = async () => {
    await Promise.all([specificationRefetch(), dealRefetch()])
  }

  return (
    <Wrapper>
      <SellerSpecification refetch={dealAndSpecRefectch} specification={specification} />
      {deal.purchase_specification && <BuyerSpecification deal={deal} />}
      <Declaration declaration={declaration} declarationRefetch={declarationRefetch} />

      <Files
        readonly={isBidContractsEnabled}
        progress={dealFilesProgress}
        dealFiles={dealFiles}
        refetch={dealFilesRefetch}
      />
    </Wrapper>
  )
}
