import { AntTagColor, Panel, Progress } from '@agro-club/agroclub-shared'
import { CollapsePanelProps } from 'antd'
import { TripOffer } from 'views/pages/LogisticsKR/types'
import { LogisticRequest } from 'modules/domain/logisticsKR/types'
import { FC, ReactNode } from 'react'
import { WrapperCards } from 'views/pages/LogisticsKR/LogisticRequests/styled'
import { TripOffersCard } from 'views/pages/LogisticsKR/components/TripOffers/TripOffersCard'
import { PreloadCard } from 'views/components/Board/Preload'
import { TripOffersHeader } from 'views/pages/LogisticsKR/components/TripOffers/TripOffersHeader'
import { refetchFunc } from 'modules/domain/common/hooks'
import { NoDataCard } from 'views/components/Board/NoDataCard'

interface Props extends Omit<CollapsePanelProps, 'header'> {
  tripOffers: TripOffer[] | undefined
  activeRequest: LogisticRequest | undefined
  refetchDraftTrips?: refetchFunc
  tripOffersRefetch?: refetchFunc
  inProgressRequestRefetch?: refetchFunc
  progress: Progress
  title: ReactNode
  counterColor?: AntTagColor
  noDataIsVisible?: boolean
}

export const TripOfferPanel: FC<Props> = ({
  tripOffers,
  tripOffersRefetch,
  refetchDraftTrips,
  activeRequest,
  progress,
  counterColor = 'orange',
  inProgressRequestRefetch,
  noDataIsVisible,
  title,
  ...otherProps
}) => {
  const counter = tripOffers?.length
  const loading = progress === Progress.WORK
  const preloadWrapper = progress === Progress.WORK && <PreloadCard />

  return (
    <>
      <Panel
        {...otherProps}
        header={<TripOffersHeader tagColor={counterColor} counter={counter} progress={progress} title={title} />}
      >
        <WrapperCards>
          {loading ? (
            <PreloadCard />
          ) : (
            tripOffers?.map((offer) => (
              <TripOffersCard
                key={offer.id}
                tripOffer={offer}
                activeRequest={activeRequest}
                tripOffersRefetch={tripOffersRefetch}
                refetchDraftTrips={refetchDraftTrips}
                inProgressRequestRefetch={inProgressRequestRefetch}
              />
            ))
          )}
          {!tripOffers?.length && !preloadWrapper && noDataIsVisible && <NoDataCard />}
        </WrapperCards>
      </Panel>
    </>
  )
}
