import { TaskModal } from 'views/components/TaskModal/TaskModal'
import { AButton } from 'views/components/Analytics'
import { Deal } from 'modules/domain/deal/types'
import { useTranslation } from 'react-i18next'
import { FC, useState } from 'react'
import AMobileButton from 'views/components/Analytics/AMobileButton'
import { Icons, defaultTheme } from '@agro-club/agroclub-shared'
import { useMediaQuery } from 'react-responsive'

export const SetTask: FC<{ deal: Deal }> = ({ deal }) => {
  const { t } = useTranslation('deal')
  const [taskModal, setTaskModal] = useState<boolean>(false)

  const isMobile = useMediaQuery({
    query: `(max-width: ${defaultTheme.queries.mobile})`,
  })

  return (
    <>
      {isMobile ? (
        <AMobileButton id="setTask" intent="secondary" Icon={Icons.IconMatch} onClick={() => setTaskModal(true)}>
          {t('common:setTask')}
        </AMobileButton>
      ) : (
        <AButton id="setTask" intent="secondary" size="medium" onClick={() => setTaskModal(true)}>
          {t('common:setTask')}
        </AButton>
      )}

      {taskModal && <TaskModal deal={deal} close={() => setTaskModal(false)} />}
    </>
  )
}
