import { Card } from 'views/components/Card/Card'
import AComments from 'views/components/Comments/AComments'
import { CommentType } from 'modules/domain/comment/types'
import { endpoints } from 'modules/endpoints'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export const DocumentRequestRightBlock = () => {
  const { id } = useParams()
  const { t } = useTranslation('documentRequests')

  return (
    <Card.Container>
      <Card.GapableContent>
        <Card.Title>{t('common:comments')}</Card.Title>
        <AComments
          id={id}
          type={CommentType.documentsRequest}
          url={endpoints.dealDocumentRequestComments(id)}
          maxHeight={400}
        />
      </Card.GapableContent>
    </Card.Container>
  )
}
