import { FC } from 'react'
import { BidTypes } from 'modules/domain/bid/types'
import { AButton } from 'views/components/Analytics'
import { Icons, Progress } from '@agro-club/agroclub-shared'
import { DealPartyChangeData } from 'views/pages/Deal/hooks/useDealPartyChange'
import { useTranslation } from 'react-i18next'
import { isDealPartyChangeEnabled } from 'env'

type Props = {
  bidType?: BidTypes
  partyChanger: DealPartyChangeData
  type?: 'start' | 'stop'
}

export const ChangeDealPartyAction: FC<Props> = ({ bidType, partyChanger, type = 'start' }) => {
  const { t } = useTranslation()

  if (!partyChanger.isApplicable || !isDealPartyChangeEnabled) {
    return null
  }

  if (type === 'start') {
    return (
      <AButton
        id="startDealPartyChange"
        intent="white"
        Icon={Icons.IconReplace}
        onClick={() => partyChanger.start(bidType)}
        disabled={!!partyChanger.bidType}
        tooltipProps={{
          tooltipContent: t('bid:contracts.changeContract'),
        }}
      />
    )
  }

  return (
    <AButton
      id="stopDealPartyChange"
      intent="secondary"
      onClick={() => partyChanger.stop()}
      disabled={partyChanger.changeProgress === Progress.WORK}
    >
      {t('common:canceling')}
    </AButton>
  )
}
