import { CommunicationDetails } from '../CommunicationDetails/CommunicationDetails'
import { defaultListRequestParams } from 'views/components/TableFilters/data'
import ATableFullPage from 'views/components/Table/ATableFullPage'
import { ListRequestParams } from 'modules/domain/types'
import { endpoints } from 'modules/endpoints'
import { useHelmet } from '@agro-club/agroclub-shared'
import * as Layout from 'views/layouts/NewLayout'
import * as Header from 'views/ui/Header/Header'
import { useTranslation } from 'react-i18next'
import { useFilters } from 'hooks/useFilter'
import { FC, useState } from 'react'
import { useColumns } from './useColumns'
import { Filters } from './Filters'

export type UserData = {
  client_company_id: string
  coordinator_name: string
  client_company: string
  coordinator_id: string
  client_phone: string
  client_name: string
  client_id: number
  date_sent: string
  id: number
}

// Not in use now, probably can be deleted
export const WhatsAppList: FC = () => {
  const { t } = useTranslation()
  const [listRequestParams, setListRequestParams] = useState<ListRequestParams>(defaultListRequestParams)
  const { isFiltersLoaded, updateFilterState } = useFilters({
    listRequestParams,
    setListRequestParams,
  })

  useHelmet({ title: t('whatsApp:metaTitle') })

  const [showModal, setShowModal] = useState(false)
  const [dataForQuery, setDataForQuery] = useState<UserData>()

  const openModal = (data: UserData) => {
    setDataForQuery(data)
    setShowModal(true)
  }

  const columns = useColumns(openModal)

  return (
    <Layout.WrapperContent>
      <Layout.Header>
        <Layout.TopHeader>
          <Header.Breadcrumbs
            routes={[
              {
                breadcrumbName: t('whatsApp:list.heading'),
              },
            ]}
          />
        </Layout.TopHeader>
        <Layout.PageName>{t('whatsApp:list.heading')}</Layout.PageName>
        <Filters
          setListRequestParams={setListRequestParams}
          listRequestParams={listRequestParams}
          updateFilterState={updateFilterState}
        />
      </Layout.Header>
      <ATableFullPage
        dataEndpoint={endpoints.whatsAppTwilio()}
        listParamsUpdated={setListRequestParams}
        listRequestParams={listRequestParams}
        isFiltersLoaded={isFiltersLoaded}
        columns={columns}
        rowHoverGray
      />

      {showModal && dataForQuery && <CommunicationDetails close={() => setShowModal(false)} data={dataForQuery} />}
    </Layout.WrapperContent>
  )
}
