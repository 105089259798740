import { FormGroup, Input, Radio, RadioItem } from '@agro-club/agroclub-shared'
import { FormikFieldsType } from 'modules/domain/types'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { FC } from 'react'

type Props = {
  formik: FormikFieldsType
}

const OtherBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 0 9px;
`

export const FormTasksPerDay: FC<Props> = ({ formik }) => {
  const { t } = useTranslation('my_tasks')
  return (
    <FormGroup label={t('task_distribution.tasks_per_day')} error={formik.errors.tasks_per_day}>
      <Radio onChange={(value) => formik.setFieldValue('tasks_per_day', value)} value={formik.values.tasks_per_day}>
        <RadioItem value={'50'} label={'50'} />
        <RadioItem value={'100'} label={'100'} />
        <RadioItem value={'200'} label={'200'} />

        <RadioItem
          value={'other'}
          label={
            <OtherBlock>
              <div>{t('task_distribution.other')}</div>
              <Input
                {...formik.getFieldProps('other_task_per_day')}
                disabled={formik.values.tasks_per_day !== 'other'}
                invalid={!!formik.errors.tasks_per_day}
              />
            </OtherBlock>
          }
        />
      </Radio>
    </FormGroup>
  )
}
