import { t } from 'i18next'
import { isBrazil, isRussia, isUS } from 'env'

export const getPhonePlaceholder = () => {
  if (isBrazil) {
    return '+55 ( ... ) ....  ....'
  }

  if (isUS) {
    return '+1 (...) ... ....'
  }

  if (isRussia) {
    return '+7 900 000 00 00'
  }
}

export const getPersonalNumberLabel = () => {
  return isBrazil ? t('common:cnpj') : t('common:inn')
}
