import { FC, ReactNode } from 'react'
import { Panel, Progress } from '@agro-club/agroclub-shared'
import { AntTagColor } from '@agro-club/agroclub-shared/dist/cjs/ui/misc/AntTag/types'
import { PreloadCard } from 'views/components/Board/Preload'
import { CollapseHeaderWithCount } from 'views/components/CollapseHeaderWithCount/CollapseHeaderWithCount'
import { NoDataCard } from 'views/components/Board/NoDataCard'
import { LogisticsKRTrip } from 'views/pages/LogisticsKR/types'
import { WrapperCards } from 'views/pages/LogisticsKR/LogisticRequests/styled'
import { DraftTripCard } from 'views/pages/LogisticsKR/components/DraftTripCard/DraftTripCard'

type Props = {
  trips: LogisticsKRTrip[]
  key: string
  title: ReactNode
  counter: number
  progress: Progress
  tagColor?: AntTagColor
  refetch: () => Promise<void>
}

export const TripsReconciliation: FC<Props> = ({ trips, counter, tagColor, title, progress, refetch, ...props }) => {
  const preloadWrapper = progress === Progress.WORK && <PreloadCard />

  return (
    <Panel
      {...props}
      header={<CollapseHeaderWithCount progress={progress} tagColor={tagColor} counter={counter} title={title} />}
    >
      <WrapperCards>
        {!preloadWrapper && trips.map((trip) => <DraftTripCard onSuccess={refetch} key={trip.id} trip={trip} />)}
        {preloadWrapper}
        {!trips.length && !preloadWrapper && <NoDataCard />}
      </WrapperCards>
    </Panel>
  )
}
