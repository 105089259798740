import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import { Icons, Progress, Text } from '@agro-club/agroclub-shared'
import { Deal } from 'modules/domain/deal/types'
import { LogisticRequest } from 'modules/domain/logisticsKR/types'
import { ListRequestParams } from 'modules/domain/types'
import { endpoints } from 'modules/endpoints'
import { apiClient } from 'modules/utils/httpClient'
import formatNumber from 'modules/utils/numbers/formatNumber'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router'
import { ContentBlock } from 'views/pages/LogisticsKR/components/styled'
import DealsKRRoutes from 'views/pages/LogisticsKR/LogisticRequests/routes'
import { LineSeparator } from 'views/ui/Header/Header'
import { AButton, ASearch } from 'views/components/Analytics'
import { TableSectionContainer, TableSectionHeader, TableSectionHeaderRightBlock, TableSectionTitle } from '../styled'
import { RegularTripsTable } from './RegularTripsTable'

interface Props {
  request: LogisticRequest
  refetchApprovingTable: () => void
  refetchTripsTable: () => void
  listRequestParams: ListRequestParams
  setListRequestParams: Dispatch<SetStateAction<ListRequestParams>>
}

export const RegularTripsTableSection: FC<Props> = ({
  request,
  refetchApprovingTable,
  setListRequestParams,
  listRequestParams,
  refetchTripsTable,
}) => {
  const { t } = useTranslation('logisticsKR')

  const [progress, setProgress] = useState<Progress>(Progress.IDLE)
  const [deal, setDeal] = useState<Deal>()

  useEffect(() => {
    const fetchDeal = async () => {
      if (!request?.deal_id) return

      setProgress(Progress.WORK)
      try {
        const data = await apiClient.get<Deal>(endpoints.deal(request?.deal_id))
        setDeal(data)
        setProgress(Progress.SUCCESS)
      } catch (err) {
        setProgress(Progress.ERROR)
      }
    }

    fetchDeal()
  }, [request?.deal_id])

  const updateFilterState = (filterObj: Record<string, any>) => {
    setListRequestParams((prev) => ({
      ...prev,
      page: 1,
      filter: {
        ...prev?.filter,
        ...filterObj,
      },
    }))
  }

  return (
    <ContentBlock>
      <TableSectionContainer>
        <TableSectionHeader>
          <div style={{ display: 'flex' }}>
            <TableSectionTitle>{t('details.tableSections.regularHeading')}</TableSectionTitle>
            {progress === Progress.SUCCESS && deal && (
              <div style={{ display: 'flex', marginLeft: '16px', alignItems: 'center' }}>
                <Text color="secondary" typography="bodyLarge">
                  {t('logisticsKR:details.tableSections.loaded')}: &nbsp;
                </Text>
                <Text color="secondary" typography="accentLarge">
                  {formatNumber(deal?.loaded_quantity, true)} {t('common:t')}
                </Text>
                &nbsp; / &nbsp;
                <Text color="secondary" typography="bodyLarge">
                  {t('logisticsKR:details.tableSections.unloaded')}: &nbsp;
                </Text>
                <Text color="secondary" typography="accentLarge">
                  {formatNumber(deal?.delivered_quantity, true)} {t('common:t')}
                </Text>
              </div>
            )}
          </div>
          <TableSectionHeaderRightBlock>
            <div style={{ width: '362px' }}>
              <ASearch
                value={listRequestParams.filter.search}
                onChange={(search) => updateFilterState({ search })}
                placeholder={t('logisticsKR:details.tableSections.searchPlaceHolder')}
              />
            </div>
            <LineSeparator />
            <AButton
              id="tripCars"
              intent="approve"
              Icon={Icons.IconPlus}
              to={generatePath(DealsKRRoutes.TripCars, { id: request.id })}
            />
          </TableSectionHeaderRightBlock>
        </TableSectionHeader>

        <RegularTripsTable
          request={request}
          listRequestParams={listRequestParams}
          setListRequestParams={setListRequestParams}
          refetchApprovingTable={refetchApprovingTable}
          refetchTripsTable={refetchTripsTable}
        />
      </TableSectionContainer>
    </ContentBlock>
  )
}
