import { AntPopover, Icons } from '@agro-club/agroclub-shared'
import { AButton } from 'views/components/Analytics'
import { FC, useState } from 'react'
import styled from 'styled-components'
import { FarmerLoad, ShipLoad } from 'modules/domain/deal/types'
import { refetchFunc } from 'modules/domain/common/hooks'
import { ShipLoadActions } from './ShipLoadActions'
import { FarmerLoadActions } from './FarmerLoadActions'

const AlignFlexWrapper = styled.div`
  display: flex;
  align-items: center;
`

type Props = {
  loadItem: ShipLoad | FarmerLoad
  refetch: refetchFunc
}

export const ShipLoadActionsCell: FC<Props> = ({ loadItem, refetch }) => {
  const [popoverVisible, setPopoverVisible] = useState(false)

  return (
    <AlignFlexWrapper>
      <AntPopover
        content={
          <ShipLoadActions refetch={refetch} loadItem={loadItem as ShipLoad} setPopoverVisible={setPopoverVisible} />
        }
        placement="bottomRight"
        trigger="click"
        open={popoverVisible}
        onOpenChange={() => setPopoverVisible(!popoverVisible)}
      >
        <AButton id="popover" intent="white" Icon={Icons.IconMoreHoriz} />
      </AntPopover>
    </AlignFlexWrapper>
  )
}

export const FarmerLoadActionsCell: FC<Props> = ({ loadItem, refetch }) => {
  const [popoverVisible, setPopoverVisible] = useState(false)

  return (
    <AlignFlexWrapper>
      <AntPopover
        content={
          <FarmerLoadActions
            refetch={refetch}
            loadItem={loadItem as FarmerLoad}
            setPopoverVisible={setPopoverVisible}
          />
        }
        placement="bottomRight"
        trigger="click"
        open={popoverVisible}
        onOpenChange={() => setPopoverVisible(!popoverVisible)}
      >
        <AButton id="popover" intent="white" Icon={Icons.IconMoreHoriz} />
      </AntPopover>
    </AlignFlexWrapper>
  )
}
