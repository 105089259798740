import { Controls, DateText, MultiCardContainerStyled, RouterLinkStyled, Title } from '../styled'
import { DealDocumentRequestStatusTags } from './DealDocumentRequestStatusTags'
import { DocumentRequestsRoutes } from 'views/pages/DocumentRequests/routes'
import { DealDocumentRequestControls } from './DealDocumentRequestControls'
import { MobileDocumentRequest } from './MobileDocumentRequest'
import { EditDocumentRequest } from './EditDocumentRequest'
import { AddModal } from './DocumentRequestModal/AddModal'
import { refetchFunc } from 'modules/domain/common/hooks'
import { defaultTheme } from '@agro-club/agroclub-shared'
import { WithoutDocsModal } from './WithoutDocsModal'
import { Declaration } from 'modules/domain/types'
import { Deal } from 'modules/domain/deal/types'
import { useMediaQuery } from 'react-responsive'
import useFormatDate from 'hooks/useFormatDate'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router'
import { FC, useState } from 'react'

type Props = {
  declaration: Declaration | undefined
  refetch: refetchFunc
  deal: Deal
}

export const DealDocumentRequest: FC<Props> = ({ deal, refetch, declaration }) => {
  const [withoutDocsModal, setWithoutDocsModal] = useState(false)
  const [addDocsModal, setAddDocsModal] = useState(false)
  const { t } = useTranslation('deal')
  const formatDate = useFormatDate()

  const documentRequest = deal.documents_request
  const withoutDocumentRequest = deal.without_documents_request

  const isMobile = useMediaQuery({
    query: `(max-width: ${defaultTheme.queries.mobile})`,
  })

  const handlerWithoutDocsModal = () => {
    setWithoutDocsModal(!withoutDocsModal)
  }
  const handlerAddDocsModal = () => {
    setAddDocsModal(!addDocsModal)
  }

  return (
    <>
      {isMobile ? (
        <MobileDocumentRequest
          handlerWithoutDocsModal={handlerWithoutDocsModal}
          handlerAddDocsModal={handlerAddDocsModal}
          declaration={declaration}
          refetch={refetch}
          deal={deal}
        />
      ) : (
        <MultiCardContainerStyled enableHover={!!documentRequest?.id} intent="primary" bordered>
          <div>
            <Title>{t('requestDocumentsBlock.title')}</Title>
            {documentRequest?.expected_complete_date && (
              <DateText>
                {t('requestDocumentsBlock.expectedDate', {
                  date: formatDate(documentRequest.expected_complete_date),
                })}
              </DateText>
            )}
          </div>

          {!withoutDocumentRequest && !documentRequest ? (
            <DealDocumentRequestControls
              handlerWithoutDocsModal={handlerWithoutDocsModal}
              handlerAddDocsModal={handlerAddDocsModal}
            />
          ) : (
            <Controls>
              <DealDocumentRequestStatusTags deal={deal} />
              {!withoutDocumentRequest && documentRequest?.id && (
                <RouterLinkStyled
                  eventName="detailedDocuments"
                  itemId={documentRequest.id}
                  to={generatePath(DocumentRequestsRoutes.Details, { id: documentRequest.id })}
                />
              )}
              <EditDocumentRequest declaration={declaration} deal={deal} refetch={refetch} />
            </Controls>
          )}
        </MultiCardContainerStyled>
      )}

      {withoutDocsModal && <WithoutDocsModal onClose={handlerWithoutDocsModal} deal={deal} onSuccess={refetch} />}
      {addDocsModal && deal && (
        <AddModal declaration={declaration} refetch={refetch} deal={deal} onClose={handlerAddDocsModal} />
      )}
    </>
  )
}
