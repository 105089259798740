import { FC } from 'react'

import { LinkProps } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { useAnalyticsClickHandler } from 'analytics/hooks'

interface Props extends LinkProps {
  eventName: string
  itemId?: string
}

const ASimpleLink: FC<Props> = ({ children, eventName, itemId, onClick, ...otherProps }) => {
  const handleClick = useAnalyticsClickHandler(eventName)

  return (
    <Link onClick={handleClick(onClick, { id: itemId })} {...otherProps}>
      {children}
    </Link>
  )
}

export default ASimpleLink
