import { FC } from 'react'

import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { defaultTheme, FormGroup, Input, Typography } from '@agro-club/agroclub-shared'
import { useFormik } from 'formik'

import styled from 'styled-components'
import { SharedModalInputs, SharedModalTitle } from 'views/components/SharedModal'
import { ShortFormControl } from 'views/styled/common'
import { FormGroupUpload } from 'views/components/FormGroupUpload/FormGroupUpload'
import { DatePicker } from 'views/components/DatePicker/DatePicker'
import { apiClient } from 'modules/utils/httpClient'
import { endpoints } from 'modules/endpoints'
import { useAFormHandler } from 'analytics/hooks'
import ASharedModal from 'views/components/SharedModal/ASharedModal'
import { AnalyticsPlaces } from 'views/pages/Deal/analyticsPlaces'
import ADefaultModalFooter from 'views/components/DefaultModalFooter/ADefaultModalFooter'
import { refetchFunc } from 'modules/domain/common/hooks'

interface Props {
  onClose: () => void
  onSuccess: refetchFunc
}

const InputDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const Description = styled.div`
  ${Typography.bodySmall};
  color: ${defaultTheme.color.secondaryPlaceholder};
  padding: 4px 0 0 211px;
`

export const DeclarationModal: FC<Props> = ({ onClose, onSuccess }) => {
  const { formProgress, formHandler } = useAFormHandler()
  const { t } = useTranslation('deal')
  const { id } = useParams()

  const formik = useFormik({
    initialValues: {
      number: '',
      start_date: '',
      files: [],
    },
    enableReinitialize: true,

    onSubmit: formHandler(
      async () =>
        await apiClient.post(endpoints.dealDeclaration(id), {
          number: formik.values.number,
          start_date: formik.values.start_date['date'],
          files: formik.values.files,
        }),
      {
        onSuccess: async () => {
          await onSuccess()
          onClose()
        },
        onError: (error) => {
          const { errors } = error
          formik.setErrors(errors)
        },
      },
    ),
  })

  return (
    <ASharedModal id={AnalyticsPlaces.ADD_DECLARATION_MODAL} onClose={onClose}>
      <SharedModalTitle>{t('declaration')}</SharedModalTitle>
      <SharedModalInputs>
        <InputDescriptionWrapper>
          <FormGroup error={formik.errors.number} label={t('number')} required>
            <Input
              {...formik.getFieldProps('number')}
              placeholder={t('fields.placeholder_declaration')}
              invalid={!!formik.errors.number}
            />
          </FormGroup>
          <Description>{t('fields.description_declaration')}</Description>
        </InputDescriptionWrapper>
        <ShortFormControl>
          <FormGroup error={formik.errors.start_date} label={t('dateFrom')} required>
            <DatePicker
              onChange={(v) => formik.setFieldValue('start_date', v)}
              placeholder={t('common:datePlaceholder')}
              error={!!formik.errors.start_date}
            />
          </FormGroup>
        </ShortFormControl>
        <FormGroupUpload maxWidthFileUpload={411} isSingleFile label={t('files')} formik={formik} fieldName="files" />
      </SharedModalInputs>
      <ADefaultModalFooter
        onClose={onClose}
        onConfirm={() => formik.submitForm()}
        confirmButtonText={t('common:save')}
        progress={formProgress}
      />
    </ASharedModal>
  )
}
