import { Icons, Input, Progress } from '@agro-club/agroclub-shared'
import { FC, ReactNode, useState } from 'react'
import { LoanAgreement } from '../../types'
import { FormikProps, useFormik } from 'formik'
import { AButton } from 'views/components/Analytics'
import { LoanAgreementSelect } from './LoanAgreementSelect'
import { useTranslation } from 'react-i18next'
import { endpoints } from 'modules/endpoints'
import { apiClient } from 'modules/utils/httpClient'
import { useSingleEntity } from 'modules/domain/common/hooks'
import { useAFormHandler } from 'analytics/hooks'
import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  column-gap: 8px;
  width: 100%;
`

interface Props {
  formik: FormikProps<any>
  bankId?: string
  fieldName: string
  value?: string
  isMobile?: boolean
}

export const LoanAgreementControls: FC<Props> = ({ formik, bankId, fieldName, value, isMobile }: Props) => {
  const { t } = useTranslation('dispatching')
  const [createMode, setCreateMode] = useState(false)
  const [, agreements, refetchLoans] = useSingleEntity<LoanAgreement[]>(
    bankId ? `${endpoints.dispatchingLoans()}?bank=${bankId}` : undefined,
  )
  const { formProgress, formHandler } = useAFormHandler()

  const createLoanFormik = useFormik<{ name: string }>({
    initialValues: { name: '' },
    onSubmit: formHandler(
      async () => {
        return await apiClient.post(endpoints.dispatchingLoans(), {
          name: createLoanFormik.values.name,
          bank: bankId,
        })
      },
      {
        onSuccess: async (created: LoanAgreement) => {
          formik.setFieldValue(fieldName, String(created.id))
          await refetchLoans()
          setCreateMode(false)
        },
        onError: (error) => {
          const { errors } = error
          createLoanFormik.setErrors(errors)
        },
      },
    ),
  })

  let mainControl: ReactNode, createControls: ReactNode
  const buttonsSize = isMobile ? 'biggest' : 'big'

  if (createMode) {
    mainControl = (
      <Input
        {...createLoanFormik.getFieldProps(`name`)}
        placeholder={t('modal.inputs.loan_agreement_placeholder')}
        invalid={!!formik.errors['name']}
      />
    )
    createControls = (
      <div style={{ display: 'flex', gap: '8px' }}>
        <AButton
          id="saveNewLoanAgreement"
          intent="secondary"
          Icon={Icons.IconCheck}
          size={buttonsSize}
          onClick={createLoanFormik.submitForm}
          progress={formProgress}
        />
        <AButton
          id="cancelAddLoanAgreement"
          intent="secondary"
          Icon={Icons.IconClose}
          size={buttonsSize}
          onClick={() => setCreateMode(false)}
          disabled={formProgress === Progress.WORK}
        />
      </div>
    )
  } else {
    mainControl = (
      <LoanAgreementSelect
        formik={formik}
        agreements={agreements}
        fieldName={fieldName}
        disabled={!bankId}
        placeholder={t('modal.inputs.loan_agreement_placeholder')}
        value={value}
      />
    )
    createControls = (
      <AButton
        id="addLoanAgreement"
        intent="approve"
        Icon={Icons.IconPlus}
        size={buttonsSize}
        onClick={() => setCreateMode(true)}
      />
    )
  }

  return (
    <Wrapper>
      {mainControl}
      {createControls}
    </Wrapper>
  )
}
