import { FC } from 'react'

import { DealList } from 'views/pages/Deal/DealList'
import DealRoutes from 'views/pages/Deal/routes'
import { Navigate, Route, Routes, generatePath, matchRoutes } from 'react-router-dom'
import { DealDetail } from 'views/pages/Deal/DealDetail/DealDetail'

const DealPage: FC = () => {
  // graceful replacement for old url format
  const location = window.location
  const matches = matchRoutes([{ path: DealRoutes.DetailOld }], location)
  let id: string | undefined = 'none'
  if (matches?.[0]) {
    id = matches[0].params.id
  }
  return (
    <Routes>
      <Route index element={<DealList />} />
      <Route path={DealRoutes.relativeListWithTab} element={<DealList />} />
      <Route path={DealRoutes.relativeListWithTabAndDeal} element={<DealList />} />
      <Route
        path={DealRoutes.relativeDetailOld}
        element={<Navigate to={generatePath(DealRoutes.Details, { id })} replace />}
      />
      <Route path={DealRoutes.relativeDetail} element={<DealDetail />} />
    </Routes>
  )
}

export default DealPage
