import { FC, useContext, useEffect, useRef } from 'react'

import { useTranslation } from 'react-i18next'

import { Progress, FormGroup, defaultTheme } from '@agro-club/agroclub-shared'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import formatTime from 'helpers/formatTime'
import useTimer from 'hooks/useTimer'
import i18n from 'i18n'

import {
  CodeWrapper,
  FormWrapper,
  InputStyled,
  RetryBtn,
  RetryDelay,
  SubmitButtonWrapper,
  BackIcon,
  InputWrapper,
  InputLabel,
  ErrorText,
  Controls,
} from './styled'
import { AButton } from 'views/components/Analytics'
import { useAFormHandler, useAnalyticsContext } from 'analytics/hooks'
import { apiClient } from 'modules/utils/httpClient'
import { TokenResponse } from 'modules/domain/auth/managers'
import { endpoints } from 'modules/endpoints'
import { saveAccessToken, saveRefreshToken } from 'modules/domain/auth/repository'
import { AuthContext } from 'modules/context/AuthContext'
import { useMediaQuery } from 'react-responsive'

type FormFields = {
  code: string
}

const validationSchema = Yup.object({
  code: Yup.string()
    .required(i18n.t('validation:code_required'))
    .test('len', i18n.t('validation:code_length'), (val) => !!val && val.length === 6),
})

const OtpForm: FC<{ phone: string; resend: () => void; onBack: () => void }> = ({ resend, phone, onBack }) => {
  const { track } = useAnalyticsContext()
  const { onLogin } = useContext(AuthContext)
  const { formProgress, formHandler } = useAFormHandler('login')

  const { t } = useTranslation(['auth', 'common'])

  const inputRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    inputRef.current?.focus()
  }, [])

  const formik = useFormik<FormFields>({
    initialValues: { code: '' },
    validationSchema,
    onSubmit: formHandler(
      async () => {
        const response = await apiClient.post<TokenResponse>(endpoints.login(), { code: formik.values.code, phone })
        saveRefreshToken(response.refresh)
        saveAccessToken(response.access)
        await onLogin()
      },
      {
        onError: (error) => {
          const { errors } = error
          if (errors.code) {
            formik.setErrors(errors)
          } else {
            formik.setErrors({ code: errors.non_field_errors?.[0] })
          }
        },
      },
    ),
  })

  const { seconds, startTimer } = useTimer({
    initialSeconds: 60,
  })
  const time = formatTime(seconds)

  const handleGetCode = () => {
    resend()
    startTimer()

    track('click|getCode')
  }

  const isMobile = useMediaQuery({
    query: `(max-width: ${defaultTheme.queries.mobile})`,
  })
  const size = isMobile ? 'biggest' : 'big'

  return (
    <FormWrapper>
      <CodeWrapper>
        <InputLabel>{t('auth:smsCode')}</InputLabel>
        <Controls>
          <InputWrapper>
            <FormGroup error={!isMobile && formProgress === Progress.ERROR ? formik.errors.code : undefined}>
              <InputStyled
                type="text"
                {...formik.getFieldProps('code')}
                onChange={({ target: { value } }) => {
                  formik.setFieldValue('code', value.replace(/\s*/g, ''))
                }}
                invalid={formProgress === Progress.ERROR}
                onKeyUp={(e) => {
                  if (e.key === 'Enter') formik.submitForm()
                }}
                autoFocus
              />
            </FormGroup>
          </InputWrapper>
          {seconds > 0 ? (
            <RetryDelay>{time}</RetryDelay>
          ) : (
            <RetryBtn disabled={formProgress === 'WORK'} onClick={handleGetCode}>
              {t('auth:sendAnotherCode')}
            </RetryBtn>
          )}
        </Controls>
        {isMobile && (
          <ErrorText typography="bodySmall">
            {formProgress === Progress.ERROR ? formik.errors.code : undefined}
          </ErrorText>
        )}
      </CodeWrapper>

      <SubmitButtonWrapper>
        <AButton id="back" Icon={BackIcon} intent="secondary" onClick={onBack} size={size} />

        <AButton
          id="send"
          style={{ width: '100%' }}
          size={size}
          type="submit"
          intent="primary"
          disabled={!formik.isValid || !formik.dirty}
          progress={formProgress}
          onClick={() => formik.submitForm()}
        >
          {t('auth:send')}
        </AButton>
      </SubmitButtonWrapper>
    </FormWrapper>
  )
}

export default OtpForm
