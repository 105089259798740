import { FC } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
  field: boolean
}

export const BooleanCell: FC<Props> = ({ field }) => {
  const { t } = useTranslation('common')

  if (field === null) {
    return <>—</>
  } else {
    return <>{field ? t('yes') : t('no')}</>
  }
}
