import { defaultTheme, Typography } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

export const WrapperFormControl = styled.div`
  display: flex;
  flex-direction: column;
`
export const Description = styled.span`
  ${Typography.bodySmall}
  color: ${defaultTheme.color.secondaryPlaceholder};
  padding-left: 212px;
  margin-top: 4px;
  a {
    ${Typography.bodySmall}
  }

  @media (max-width: ${defaultTheme.queries.mobile}) {
    padding-left: 0px;
  }
`

export const DescriptionWrapper = styled.div`
  padding: 4px 0 0 224px;
  @media (max-width: ${defaultTheme.queries.mobile}) {
    padding-left: 0px;
  }
`
export const WrapperOption = styled.div`
  white-space: normal;
`
export const DescriptionOption = styled.div`
  color: ${defaultTheme.color.secondaryPlaceholder};
`
