import { FormGroup, Input, Select } from '@agro-club/agroclub-shared'
import { labelTarif } from 'modules/utils/numbers/formatTarif'
import { FormInput } from 'views/components/form/FormInput'
import { FormikFieldsType } from 'modules/domain/types'
import { Deal } from 'modules/domain/deal/types'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import env, { isRussia } from 'env'
import { FC } from 'react'

type Props = {
  formik: FormikFieldsType
  disabled: boolean
  deal: Deal
}

const currency = env.REACT_APP_CURRENCY_SYMBOL

const WrapperSelect = styled.div`
  width: 180px;
`

export const MarginControl: FC<Props> = ({ formik, disabled, deal }) => {
  const { t } = useTranslation('deal')

  const onChangeSelect = (v) => {
    formik.setFieldValue('fixed_margin_by_export_contract', v === 'true')

    if (v === 'true') {
      formik.setFieldValue('fixed_margin', deal.export_contract_price)
    } else {
      formik.setFieldValue('fixed_margin', deal.fixed_margin)
    }
  }

  const options = [
    {
      value: 'true',
      label: labelTarif(t('export:contractPrice')),
    },
    {
      value: 'false',
      label: labelTarif(t('export:fixedPrice')),
    },
  ]

  const controlWithExport = isRussia && deal.export_contract_id

  return controlWithExport ? (
    <FormGroup
      label={
        <WrapperSelect>
          <Select
            value={formik.values.fixed_margin_by_export_contract ? 'true' : 'false'}
            onChange={onChangeSelect}
            allowClear={false}
            showSearch={false}
            options={options}
            fullWidth
          />
        </WrapperSelect>
      }
      error={formik.errors.fixed_margin}
    >
      <Input invalid={!!formik.errors.fixed_margin} {...formik.getFieldProps('fixed_margin')} disabled={disabled} />
    </FormGroup>
  ) : (
    <FormInput
      label={`${t('fields.fixed_margin')}, ${t('common:rub', { currency })}`}
      field="fixed_margin"
      disabled={disabled}
      formik={formik}
      type="number"
    />
  )
}
