import { AntPopover, Icons } from '@agro-club/agroclub-shared'
import { useAnalyticsClickHandler } from 'analytics/hooks'
import { isRussia, isUS } from 'env'
import { Bid, BidStatus, BidTypes, USBid } from 'modules/domain/bid/types'
import { FC, useState } from 'react'
import { generatePath } from 'react-router'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { AButton } from 'views/components/Analytics'
import { BidFuturesTag, BidTeamTag, USContractTypeTag } from 'views/components/Tags'
import { WarehouseMovementsModal } from 'views/components/WarehouseMovementsModal/WarehouseMovementsModal'
import { BarchartIcon } from 'views/pages/Bid/BidDetail/Bid/BarchartIcon'
import BidRoutes from 'views/pages/Bid/routes'
import { BidCardTags, CardButtonWrapper, ItemCard as CardWrapper } from 'views/styled/ItemCardStyles'
import { BidCardInfo } from './BidCardInfo'
import { BidPopoverContent } from './BidPopoverContent'
import { USBidCardInfo } from './USBidCardInfo'

type Props = {
  setActiveBid: (arg: Bid) => void
  activeCard: boolean
  bid: Bid | USBid
  refetch?: () => void
}

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  max-width: 242px;
`

const WarehouseButton = styled(AButton)`
  position: absolute;
  right: 12px;
  bottom: 8px;
`

export const BidCard: FC<Props> = ({ bid, setActiveBid, activeCard, refetch }) => {
  const [warehouseModal, setWarehouseModal] = useState(false)
  const warehouseBtnIsVisible = isRussia && bid.status === BidStatus.published && bid.bid_type === BidTypes.sale

  const handleClick = useAnalyticsClickHandler('bid')
  const [popoverVisible, setPopoverVisible] = useState(false)

  const { bidId } = useParams()

  const cardInfo = isUS ? <USBidCardInfo bid={bid as USBid} /> : <BidCardInfo bid={bid} />

  const tags = (
    <>
      <BarchartIcon source={bid.source} />
      <BidTeamTag bid={bid} />
      <USContractTypeTag bid={bid} />
      <BidFuturesTag bid={bid} />
    </>
  )

  const activeBid = bidId === String(bid.id)

  return (
    <CardWrapper
      onClick={handleClick(() => setActiveBid(bid))}
      id={activeBid ? bidId : ''}
      activeCard={activeCard}
      status={bid.status}
    >
      <BidCardTags>{tags}</BidCardTags>
      <CardButtonWrapper>
        {isUS && bid.status === BidStatus.published ? (
          <AntPopover
            content={<BidPopoverContent setPopoverVisible={setPopoverVisible} bid={bid as USBid} />}
            placement="bottomRight"
            trigger="click"
            open={popoverVisible}
            onOpenChange={() => setPopoverVisible(!popoverVisible)}
          >
            <AButton id="bitActions" intent="white" Icon={Icons.IconMoreHoriz} onClick={(e) => e.stopPropagation()} />
          </AntPopover>
        ) : (
          <AButton
            Icon={Icons.IconOpen}
            to={generatePath(bid.status === BidStatus.potential ? BidRoutes.PotentialBidDetail : BidRoutes.Details, {
              id: bid.id,
            })}
            id="goToBid"
            intent="white"
            analyticsPayload={{ id: bid.id }}
          />
        )}
      </CardButtonWrapper>
      <InfoWrapper>{cardInfo}</InfoWrapper>
      {warehouseBtnIsVisible && (
        <WarehouseButton
          id="warehouseMovements"
          intent="white"
          Icon={Icons.IconGarageMove}
          onClick={() => setWarehouseModal(true)}
        />
      )}
      {warehouseModal && (
        <WarehouseMovementsModal
          bidId={bid.id}
          onSuccess={refetch as () => void}
          onClose={() => setWarehouseModal(false)}
        />
      )}
    </CardWrapper>
  )
}
