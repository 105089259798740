import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Icons } from '@agro-club/agroclub-shared'
import { refetchFunc } from 'modules/domain/common/hooks'
import { User } from 'modules/domain/user/types'
import { useParams } from 'react-router'
import { BlockUserModal } from './ConfirmModals/BlockUser/BlockUserModal'
import { BlockUser, WrapperIcon, Text } from '../../styled'
import { BlockCoordinatorModal } from './ConfirmModals/BlockUser/BlockCoordinatorModal'
import { ROLES } from 'types/entities'

interface Props {
  userRefetch: refetchFunc
  user: User
  withSeparator?: boolean
}

export const BlockUserButton: FC<Props> = ({ user, userRefetch, withSeparator }) => {
  const { t } = useTranslation('user')
  const { id } = useParams()

  const [confirmBlockedModal, setConfirmBlockedModal] = useState(false)

  return (
    <>
      <BlockUser isActive={user.is_active} onClick={() => setConfirmBlockedModal(true)} withSeparator={withSeparator}>
        <WrapperIcon>
          <Icons.IconCancelled />
        </WrapperIcon>
        <Text>{user.is_active ? t('block') : t('blocked')}</Text>
      </BlockUser>
      {confirmBlockedModal && (
        <>
          {user.profile?.role === ROLES.coordinator ? (
            <BlockCoordinatorModal onClose={() => setConfirmBlockedModal(false)} refetch={userRefetch} userId={id} />
          ) : (
            <BlockUserModal onClose={() => setConfirmBlockedModal(false)} refetch={userRefetch} userId={id} />
          )}
        </>
      )}
    </>
  )
}
