import { Typography, defaultTheme } from '@agro-club/agroclub-shared'
import { Deal, WhoResponded } from 'modules/domain/deal/types'
import styled from 'styled-components'
import { ARouterLink } from 'views/components/Analytics'

export const CommonInfo = styled.div`
  display: flex;
  flex-direction: column;
`

export const CompanyLink = styled(ARouterLink)`
  ${Typography.bodyLarge}
  color: ${defaultTheme.color.textForLink};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  z-index: 0;
`

export const PriceRow = styled.div`
  display: flex;
  align-items: center;
`

export const ArrowWrapper = styled.div<{ $whoResponded: Deal['who_responded'] }>`
  transform: ${({ $whoResponded }) => ($whoResponded === WhoResponded.purchase ? 'scaleY(-1)' : 'none')};
  flex-grow: 0;
`
