import { ProfileType } from 'modules/domain/user/types'
import { EditWarehouseProps } from './types'
import { SellerWarehouseEditModal } from './SellerWarehouseEditModal'
import { BuyerWarehouseEditModal } from './BuyerWarehouseEditModal'
import { isBrazil } from 'env'

interface Props extends EditWarehouseProps {
  userType?: ProfileType
}

export const WarehousesEditModal = ({ onSuccess, bid, onClose, userAddress, userId, userType }: Props) => {
  if (!isBrazil) {
    if (userType === ProfileType.seller) {
      return (
        <SellerWarehouseEditModal
          onSuccess={onSuccess}
          bid={bid}
          onClose={onClose}
          userAddress={userAddress}
          userId={userId}
        />
      )
    }

    return (
      <BuyerWarehouseEditModal
        onSuccess={onSuccess}
        bid={bid}
        onClose={onClose}
        userAddress={userAddress}
        userId={userId}
      />
    )
  }

  return null
}
