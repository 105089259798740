import { FC } from 'react'

import { MobileButton, MobileButtonProps } from '@agro-club/agroclub-shared'
import { useAnalyticsClickHandler } from 'analytics/hooks'
import { AnalyticsPayload } from 'analytics/types'

export interface AButtonProps extends Omit<MobileButtonProps, 'id'> {
  id: string
  analyticsPayload?: AnalyticsPayload
}

const AMobileButton: FC<AButtonProps> = ({ id, children, onClick, analyticsPayload, ...otherProps }) => {
  const handleClick = useAnalyticsClickHandler(id)

  return (
    <MobileButton onClick={handleClick(onClick, analyticsPayload)} data-test-id={id} {...otherProps}>
      {children}
    </MobileButton>
  )
}

export default AMobileButton
