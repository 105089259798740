import { BidTypes } from 'modules/domain/bid/types'
import { Deal, EditableDealStatuses } from 'modules/domain/deal/types'
import { useRematching } from './useRematching'
import { isBidContractsEnabled } from 'env'
import { useEffect, useState } from 'react'
import { getDealBidAndContract } from 'modules/domain/deal/utils'
import { getOppositeBidType } from 'modules/domain/bid/utils'
import { apiClient } from 'modules/utils/httpClient'
import { endpoints } from 'modules/endpoints'
import { useProgress } from 'hooks/useProgress'

type Params = {
  deal?: Deal
}

export type PartyChangePayload = {
  quantity?: number
  price_logistics?: number
  distance?: number
}

export const useDealPartyChange = ({ deal = {} as Deal }: Params) => {
  const [bidType, setBidType] = useState<BidTypes | null>(null)
  const matching = useRematching({
    bidType,
    manualFetch: true,
  })

  const isApplicable = deal && isBidContractsEnabled && EditableDealStatuses.includes(deal.status)

  useEffect(
    () => {
      if (!matching.filterBid) {
        return
      }
      matching.refetch()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [matching.filterBid, matching.search, matching.deal],
  )

  const stop = () => {
    matching.setFilterBid(null)
    matching.setDeal(null)
    matching.setSearch('')
    setBidType(null)
  }

  const start = (type?: BidTypes) => {
    if (!isApplicable || !type) {
      return
    }
    const { bid, contract } = getDealBidAndContract(deal, getOppositeBidType(type))
    const filterBid = contract ?? bid
    if (type && filterBid) {
      matching.setDeal(deal)
      matching.setFilterBid(filterBid)
      setBidType(type)
      return
    }
    stop()
  }

  const performChange = async (payload: PartyChangePayload) => {
    if (!matching.bid) {
      return
    }
    const data = {
      ...payload,
      bid_id: matching.bid.id,
    }

    return apiClient.post<Deal>(endpoints.dealsPartyChange(deal.id), data)
  }

  const [changeProgress, change] = useProgress(performChange)

  return {
    deal,

    isApplicable,

    bidType,

    start,
    stop,
    change,
    changeProgress,

    matching,
  }
}

export type DealPartyChangeData = ReturnType<typeof useDealPartyChange>
