import { useAnalyticsContext } from 'analytics/hooks'
import { FC, ReactNode, useEffect } from 'react'
import { ErrorBoundary } from 'views/components/ErrorBoundary/ErrorBoundary'

interface Props {
  page: string
  place: string
  id?: string
  children?: ReactNode
}

const APageWrapper: FC<Props> = ({ children, page, place, id }) => {
  const { setOptions } = useAnalyticsContext()

  useEffect(() => {
    setOptions({
      page,
      place,
      id,
    })

    return () => {
      setOptions({
        page: undefined,
        id: undefined,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <ErrorBoundary>{children}</ErrorBoundary>
}

export default APageWrapper
