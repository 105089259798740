import styled from 'styled-components'
import { defaultTheme, Typography } from '@agro-club/agroclub-shared'

export const EntityTitle = styled.div`
  ${Typography.bodyLarge};
  color: ${defaultTheme.color.textSecondary60a};
  margin-bottom: 8px;
`

export const ItemContentInfo = styled.div`
  display: flex;
  flex-direction: column;
`

export const ItemList = styled.div`
  margin-bottom: 12px;
`

export const ButtonsWrapper = styled.div`
  position: absolute;
  display: flex;
  column-gap: 4px;
  right: 12px;
`

export const InputWrapper = styled.div`
  div {
    align-items: center;

    div:first-child {
      div {
        height: 32px;
        ${Typography.bodyMedium}
      }
    }

    div + div {
      width: 114px;
    }
  }
`

export const TextWrapper = styled.div`
  div {
    display: inline;
  }
`
