import { FC } from 'react'
import { Params } from 'views/pages/Bid/BidModals/Params'
import { FormikErrors, FormikProps } from 'formik'
import { BidParameterDTO } from 'modules/domain/bid/types'
import { isBidProductParamsEnabled } from 'env'
import { Product } from 'modules/domain/collection/types'
import { PotentialBidAddFields } from 'modules/domain/potentialBid/types'
import { ProductTitle } from '../styled'
import { SharedModalInputs } from 'views/components/SharedModal'

type Props = {
  product?: Product
  formik: FormikProps<PotentialBidAddFields>
}

export const PotentialBidsParams: FC<Props> = ({ formik, product }) => {
  const errors = formik?.errors?.parameters as FormikErrors<BidParameterDTO>[]
  if (!isBidProductParamsEnabled || !product || !product.parameters.length) return null

  return (
    <>
      {product?.title && <ProductTitle>{product?.title}</ProductTitle>}
      <SharedModalInputs>
        <Params formikErrors={errors} formik={formik} product={product} />
      </SharedModalInputs>
    </>
  )
}
