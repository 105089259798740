import { Typography, defaultTheme } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 8px;
`
export const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px 8px;
`
export const QuantityAndPrice = styled.div`
  display: flex;
  flex-direction: column;
  ${Typography.accentLarge}
`
export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`
export const AddressAndUser = styled.div``
export const Address = styled.div`
  color: ${defaultTheme.color.textSecondary60a};
`
export const Dates = styled.div`
  display: flex;
  gap: 0 16px;
`
