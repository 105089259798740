import PermissionCheck from 'views/components/PermissionCheck/PermissionCheck'
import { Text, Verified, WrapperIcon } from '../../styled'
import { Icons } from '@agro-club/agroclub-shared'
import { refetchFunc } from 'modules/domain/common/hooks'
import { User } from 'modules/domain/user/types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SecurityConfirmModal } from './ConfirmModals/SecurityConfirmModal'

type Props = {
  userRefetch: refetchFunc
  user: User
}

export const VerifiedBySecurity = ({ user, userRefetch }: Props) => {
  const { t } = useTranslation('user')
  const [verifySecurityModal, setVerifySecurityModal] = useState(false)

  if (!user.profile) return null

  return (
    <>
      <PermissionCheck scope={['users.can_verify_as_security']}>
        <Verified
          isActive={user.is_active}
          isVerified={user.profile.is_verified_by_security}
          onClick={() => setVerifySecurityModal(true)}
        >
          <WrapperIcon>
            <Icons.IconDefend />
          </WrapperIcon>
          <Text>
            {t('verified')}
            <br />
            {t('is_verified_by_security')}
          </Text>
        </Verified>
      </PermissionCheck>

      {verifySecurityModal && (
        <SecurityConfirmModal onClose={() => setVerifySecurityModal(false)} refetch={userRefetch} userId={user.id} />
      )}
    </>
  )
}
