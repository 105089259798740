import styled, { css } from 'styled-components'
import { box } from 'views/styled/common'

export const tableContainer = css`
  ${box};
  flex-shrink: 0;
  padding: 16px;

  .ant-table-thead > tr > th {
    padding: 11px 12px;
  }

  .ant-table-tbody > tr > td {
    vertical-align: top;
  }
`

export const PageContainer = styled.div`
  height: inherit;
  display: flex;
  overflow: auto;
  column-gap: 24px;
`
