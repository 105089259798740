import { GoogleLink, YandexLink } from '@agro-club/agroclub-shared'
import { useAnalyticsClickHandler } from 'analytics/hooks'
import { FC, ReactNode } from 'react'
import env from 'env'
import styled from 'styled-components'

const StyledWrapper = styled.div`
  a:hover {
    text-decoration: underline;
  }
`

type Props = {
  to: [number, number]
  from: [number, number]
  children: ReactNode
  id: string
}

export const ALinkMapWithRoute: FC<Props> = ({ children, to, from, id }) => {
  const handleClick = useAnalyticsClickHandler(id)

  return env.REACT_APP_USE_GOOGLE_MAPS ? (
    <StyledWrapper onClick={() => handleClick()}>
      <GoogleLink to={to} from={from}>
        {children}
      </GoogleLink>
    </StyledWrapper>
  ) : (
    <StyledWrapper onClick={() => handleClick()}>
      <YandexLink to={to} from={from}>
        {children}
      </YandexLink>
    </StyledWrapper>
  )
}
