import { TableWrapper } from 'views/pages/User/UserDetail/UserRightPanel/RightBlockTable/styled'
import { Export, ExportDealType } from 'modules/domain/export/types'
import { useSingleEntity } from 'modules/domain/common/hooks'
import { AntdTable, Text } from '@agro-club/agroclub-shared'
import formatPrice from 'modules/utils/numbers/formatPrice'
import { useTranslation } from 'react-i18next'
import { endpoints } from 'modules/endpoints'
import styled from 'styled-components'
import useColumns from './useColumns'
import { FC } from 'react'

type Props = {
  exportData: Export
}

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const DealsTable: FC<Props> = ({ exportData }) => {
  const { t } = useTranslation('export')
  const columns = useColumns()
  const [progress, deals] = useSingleEntity<ExportDealType[]>(endpoints.exportDeals(exportData.id))

  const footer = () => (
    <Footer>
      <Text typography="bodyMedium">{t('averagePrice')}</Text>
      &nbsp;
      <Text typography="accentMedium">{formatPrice(exportData.deal_weighted_avg_kg_price, true)}</Text>
    </Footer>
  )
  return (
    <TableWrapper>
      <AntdTable columns={columns} data={deals ?? []} progress={progress} rowKey="id" footer={footer} />
    </TableWrapper>
  )
}
