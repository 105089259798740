import { BodySizes } from '@agro-club/agroclub-shared'
import { User } from 'modules/domain/user/types'
import useLocationFrom from 'hooks/useLocationFrom'
import { generatePath } from 'react-router-dom'
import { getUserName } from 'modules/domain/user/utils'
import UserRoutes from '../../pages/User/routes'
import { FC, ReactNode } from 'react'
import { ARouterLink } from '../Analytics'
import { DASH } from 'modules/constants'

type Props = {
  user: {
    id: User['id']
    full_name?: string
    short_name?: string
    first_name?: string
    last_name?: string
    second_name?: string
    profile?: User['profile']
  }
  bodysize?: BodySizes
  to?: string
  children?: ReactNode
  target?: string
}

export const UserLink: FC<Props> = ({ user, bodysize, to, target, children }) => {
  const from = useLocationFrom()
  return (
    <>
      {user?.id ? (
        <ARouterLink
          eventName="user"
          itemId={user.id}
          to={to ?? generatePath(UserRoutes.Details, { id: user.id })}
          state={from}
          bodysize={bodysize}
          target={target}
        >
          {children || user.full_name || user.short_name || getUserName(user as User)}
        </ARouterLink>
      ) : (
        DASH
      )}
    </>
  )
}
