import { AntdTableProps, Progress, SorterResult, TablePaginationConfig } from '@agro-club/agroclub-shared'

import { convertSortingToUrlFormat } from 'modules/domain/common/tableUtils'
import { ListRequestParams } from 'modules/domain/types'
import { ColumnData } from 'modules/types'
import * as Styled from 'views/components/Table/styled'
import { useTableScroll } from 'views/components/Table/useTableScroll'
import { FC } from 'react'
import { EXPANDABLE_COLUMN_WIDTH } from 'modules/constants'

const singleDataEntityHeight = '60px'
const noDataHeight = '135px'

export interface InternalTableFullPageWithoutDataProps extends Partial<AntdTableProps> {
  bordered?: boolean
  disableScrollY?: boolean
  listRequestParams: ListRequestParams
  listParamsUpdated: (ListRequestParams) => void
  tableWidth?: number
  data: any[] | undefined
  progress: Progress
  total: number
  pageSize: number
  maxHeight?: number
}

export const InternalTableFullPageWithoutData: FC<InternalTableFullPageWithoutDataProps> = ({
  onRow,
  bordered = false,
  columns,
  disableScrollY = false,
  listParamsUpdated,
  total,
  pageSize,
  listRequestParams,
  data,
  progress,
  maxHeight,
  rowKey,
  ...otherProps
}) => {
  const height = useTableScroll('', total > pageSize)
  const preferableHeight = maxHeight ? maxHeight : height
  const expandableColumnWidth = otherProps.expandable?.showExpandColumn ? EXPANDABLE_COLUMN_WIDTH : 0
  const width =
    expandableColumnWidth +
    (columns as ColumnData[])?.reduce((acc, col) => acc + Number(col.width ?? col.autoWidth ?? 0), 0)

  const handleTableChange = (newPagination: TablePaginationConfig, filters: any, sorter: SorterResult<ColumnData>) => {
    const sortParams: SorterResult<ColumnData>['field'][] = Array.isArray(sorter)
      ? sorter.map(convertSortingToUrlFormat)
      : [convertSortingToUrlFormat(sorter)]

    listParamsUpdated({
      ...listRequestParams,
      sort: sortParams,
      page: newPagination.current,
    })
  }

  const defaultMinHeight = data?.length ? singleDataEntityHeight : noDataHeight

  return (
    <Styled.FullScreenWrapper minHeight={maxHeight ? defaultMinHeight : height}>
      <Styled.ExpandableAntdTable
        columns={columns ?? []}
        data={data ?? []}
        rowKey={rowKey}
        page={listRequestParams.page}
        pageSize={pageSize}
        total={total}
        progress={progress}
        scroll={{ x: width, y: disableScrollY ? undefined : preferableHeight }}
        onRow={onRow}
        onChange={handleTableChange as any}
        bordered={bordered}
        tableLayout="fixed"
        rowClassName={(record) => (record.has_unseen_events ? 'ant-table-row--bold' : '')}
        {...otherProps}
      />
    </Styled.FullScreenWrapper>
  )
}
