import { CompanySize } from 'modules/domain/user/types'
import { AntTag } from '@agro-club/agroclub-shared'
import { useTranslation } from 'react-i18next'
import { isRussia } from 'env'
import { FC } from 'react'

type Props = {
  companySize?: CompanySize | null
}

export const CompanySizeTag: FC<Props> = ({ companySize }) => {
  const { t } = useTranslation()
  if (!companySize || !isRussia) return

  const isBig = companySize === CompanySize.big_company

  return <AntTag color={isBig ? 'magenta' : 'blue'}>{isBig ? t('common:bigUser') : t('common:smallUser')}</AntTag>
}
