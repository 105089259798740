import Analytics from 'analytics'
import segmentPlugin from '@analytics/segment'
import { SEGMENT_WRITE_KEY } from 'env'

const plugins: any[] = []

if (SEGMENT_WRITE_KEY) {
  plugins.push(
    segmentPlugin({
      writeKey: SEGMENT_WRITE_KEY,
      disableAnonymousTraffic: false,
    }),
  )
}

const analyticsInstance = Analytics({
  app: 'Grain Market',
  version: '1',
  plugins,
})

export default analyticsInstance
