import { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import useLanguage from 'hooks/useLanguage'

import { LanguageSelect } from 'views/ui/LanguageSelect/LanguageSelect'
import { supportedLngs } from 'i18n'

export const I18nLangSelect: FC = () => {
  const locale = useLanguage()
  const { i18n } = useTranslation()

  const changeLanguage = useCallback(
    (selectedLng) => {
      i18n.changeLanguage(selectedLng).catch((e) => {
        console.error(e)
      })
    },
    [i18n],
  )
  return <LanguageSelect value={locale} codes={supportedLngs} onChange={changeLanguage} />
}
