import { Progress } from '@agro-club/agroclub-shared'
import { useAProgress } from 'hooks/useAProgress'
import { useSingleEntity } from 'modules/domain/common/hooks'
import { uploadFile } from 'modules/domain/common/managers'
import { RespFile } from 'modules/domain/types'
import { endpoints } from 'modules/endpoints'
import { apiClient } from 'modules/utils/httpClient'
import { FC, useEffect } from 'react'
import AUploadFiles from 'views/components/UploadFiles/AUploadFiles'

type Props = {
  eventId: string
  setSummary: (arg: number) => void
  ownerId: string
  dealId: string
}

export const DealUserFiles: FC<Props> = ({ dealId, ownerId, eventId, setSummary }) => {
  const [filesProgress, files = [], filesRefetch] = useSingleEntity<RespFile[]>(endpoints.userFiles(ownerId))
  const [, removeHandler] = useAProgress((ownerId, fileId) => apiClient.delete(endpoints.userFiles(ownerId, fileId)), {
    eventName: 'removeFile',
    analyticsPayload: {
      id: dealId,
    },
    onSuccess: filesRefetch,
  })

  useEffect(() => {
    if (filesProgress === Progress.SUCCESS) {
      setSummary(files.length)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files.length])

  const addHandler = async (ownerId: string, file: File) => {
    await uploadFile(endpoints.userFiles(ownerId), file)
    filesRefetch(true)
  }

  return (
    <AUploadFiles
      id={eventId}
      progress={filesProgress}
      removeFile={(fileId) => removeHandler(ownerId, fileId)}
      addFile={({ file }) => addHandler(ownerId, file)}
      fileList={files}
      readonly
    />
  )
}
