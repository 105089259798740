import { SharedModalContent, SharedModalInputs, SharedModalTitle } from 'views/components/SharedModal'
import ADefaultModalFooter from 'views/components/DefaultModalFooter/ADefaultModalFooter'
import { Props } from './ExecutorsModalControl'
import ASharedModal from 'views/components/SharedModal/ASharedModal'
import { AnalyticsPlaces } from '../../../analyticsPlaces'
import { useAFormHandler } from 'analytics/hooks'
import { apiClient } from 'modules/utils/httpClient'
import { useTranslation } from 'react-i18next'
import { RequestError } from 'modules/errors'
import { endpoints } from 'modules/endpoints'
import { useParams } from 'react-router-dom'
import { FC } from 'react'
import { useFormik } from 'formik'
import { ExecutorsForm } from '../../types'
import { ExecutorItem } from './ExecutorItem'
import { useDealContext } from '../../DealContext'

export const BrazilExecutorsModal: FC<Props> = ({ onClose }) => {
  const { deal, dealRefetch } = useDealContext()
  const { formProgress, formHandler } = useAFormHandler('editExecutor', { id: deal.id })
  const { t } = useTranslation('executors')
  const { id } = useParams()

  const formItems = [
    { type: 'user-coordinators', value: 'user_coordinator' },
    { type: 'deal-coordinators', value: 'deal_coordinator' },
    { type: 'logisticians', value: 'logisticians', multiple: true },
    { type: 'traders', value: 'trader' },
  ]

  const formik = useFormik<ExecutorsForm>({
    initialValues: {
      user_coordinator: deal.executors?.user_coordinator?.id || null,
      deal_coordinator: deal.executors?.deal_coordinator?.id || null,
      logisticians: deal.executors?.logisticians?.map((logist) => logist.id),
      regional: deal.executors?.regional?.id || null,
      trader: deal.executors?.trader?.id || null,
    },

    onSubmit: formHandler(async () => await apiClient.put(endpoints.executorsEdit(id), formik.values), {
      onSuccess: async () => {
        await dealRefetch()
        onClose()
      },
      onError: (err) => {
        const error = err as RequestError
        const { errors } = error
        formik.setErrors(errors)
      },
    }),
  })

  return (
    <ASharedModal id={AnalyticsPlaces.EXECUTORS_EDIT_MODAL} onClose={onClose} size="medium">
      <SharedModalTitle>{t('title')}</SharedModalTitle>
      <SharedModalContent>
        <SharedModalInputs>
          {formItems.map((item) => (
            <ExecutorItem key={item.type} formik={formik} {...item} />
          ))}
        </SharedModalInputs>
      </SharedModalContent>

      <ADefaultModalFooter
        onConfirm={() => formik.submitForm()}
        confirmButtonText={t('common:save')}
        disabled={!formik.dirty}
        progress={formProgress}
        onClose={onClose}
      />
    </ASharedModal>
  )
}
