import { SharedModalContent, SharedModalFooter, SharedModalTitle } from 'views/components/SharedModal'
import ASharedModal from 'views/components/SharedModal/ASharedModal'
import { refetchFunc } from 'modules/domain/common/hooks'
import { AButton } from 'views/components/Analytics'
import { apiClient } from 'modules/utils/httpClient'
import { useAProgress } from 'hooks/useAProgress'
import { useTranslation } from 'react-i18next'
import { endpoints } from 'modules/endpoints'
import { FC } from 'react'

type Props = {
  sampleId: string
  refetch: refetchFunc
  onClose: () => void
}

export const ConfirmArchive: FC<Props> = ({ sampleId, refetch, onClose }) => {
  const { t } = useTranslation('samples')

  const [removeProgress, removeSample] = useAProgress(() => apiClient.delete(endpoints.samples(sampleId)), {
    eventName: 'removeSample',
    analyticsPayload: {
      id: sampleId,
    },
    onSuccess: () => refetch(),
  })

  return (
    <ASharedModal onClose={onClose} size="small" id="archive_modal">
      <SharedModalTitle>{t('archiveSample')}</SharedModalTitle>
      <SharedModalContent>{t('areYouSureToArchiveSample')}</SharedModalContent>
      <SharedModalFooter>
        <AButton
          progress={removeProgress}
          onClick={async () => {
            await removeSample()
            onClose()
          }}
          intent="primary"
          size="big"
          id="save"
        >
          {t('common:archive')}
        </AButton>

        <AButton id="close" intent="secondary" size="big" onClick={onClose}>
          {t('common:canceling')}
        </AButton>
      </SharedModalFooter>
    </ASharedModal>
  )
}
