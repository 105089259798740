import styled from 'styled-components'
import { Text } from '@agro-club/agroclub-shared'

export const TableWrapper = styled.div`
  /* The scroll needs to be saved to compensate for padding 8px in the header. */
  .ant-table-body {
    overflow: scroll !important;
  }
`

export const HeaderCellSubtitle = styled(Text).attrs({
  typography: 'accentMedium',
  color: 'secondary',
})``
