import { BorderedItem } from 'views/components/BorderedItem/BorderedItem'
import { useSingleEntity } from 'modules/domain/common/hooks'
import { ExternalLink } from '@agro-club/agroclub-shared'
import { RespFile } from 'modules/domain/types'
import { Deal } from 'modules/domain/deal/types'
import { useTranslation } from 'react-i18next'
import { endpoints } from 'modules/endpoints'
import { EntityTitle } from '../styled'
import { FC } from 'react'

type Props = {
  deal: Deal
}

export const BuyerSpecification: FC<Props> = ({ deal }) => {
  const { t } = useTranslation('deal')
  const [, files] = useSingleEntity<RespFile[]>(endpoints.specificationFiles(deal.purchase_specification.id))

  return (
    <>
      {files?.length ? (
        <div>
          <EntityTitle>{t('accordion.dealFiles.buyer_specification')}</EntityTitle>
          <div>
            {files?.map((file) => (
              <BorderedItem key={file.id}>
                <ExternalLink href={file.uploaded_file} target="_blank">
                  {file.original_filename}
                </ExternalLink>
              </BorderedItem>
            ))}
          </div>
        </div>
      ) : null}
    </>
  )
}
