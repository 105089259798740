import { BorderedItem } from 'views/components/BorderedItem/BorderedItem'
import { ExternalLink, Icons, Text } from '@agro-club/agroclub-shared'
import { refetchFunc } from 'modules/domain/common/hooks'
import { SampleStatus } from 'views/pages/Samples/types'
import { AButton, ARouterLink } from 'views/components/Analytics'
import { ConfirmArchive } from './ConfirmArchive'
import useFormatDate from 'hooks/useFormatDate'
import { useTranslation } from 'react-i18next'
import { EditSample } from './EditSample'
import { SamplesForBid } from '../types'
import styled from 'styled-components'
import { FC, useState } from 'react'
import { generatePath } from 'react-router'
import SamplesRoutes from 'views/pages/Samples/routes'

const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`
const SampleInfo = styled.div`
  display: flex;
  flex-direction: column;
`
const Controls = styled.div`
  display: flex;
  gap: 0 4px;
`

const LinkWithTagWrapper = styled.div`
  display: flex;
`

type Props = {
  sample: SamplesForBid
  refetch: refetchFunc
}

export const SampleItem: FC<Props> = ({ sample, refetch }) => {
  const { t } = useTranslation()
  const formatDate = useFormatDate()
  const [showEditModal, setShowEditModal] = useState(false)
  const [isConfirmVisible, setConfirmVisible] = useState(false)
  const isArchived = sample.status === SampleStatus.archived
  const archiveTag = isArchived ? `, (${t('common:archived')})` : ''

  return (
    <BorderedItem>
      <Item>
        <SampleInfo>
          <LinkWithTagWrapper>
            <ARouterLink
              eventName="sample"
              to={generatePath(SamplesRoutes.Details, {
                id: sample.id,
              })}
            >
              {`ID ${sample.id}`}
            </ARouterLink>
            <Text color="secondary">{archiveTag}</Text>
          </LinkWithTagWrapper>

          <Text color={isArchived ? 'secondary' : 'primary'}>{`${sample.title}, ${t('common:from')} ${formatDate(
            sample.created,
          )}`}</Text>
          {sample.files?.length > 0 &&
            sample.files.map((file) => (
              <ExternalLink href={file.uploaded_file} target="_blank" key={file.id}>
                {file.original_filename}
              </ExternalLink>
            ))}
        </SampleInfo>
        <Controls>
          {!isArchived && (
            <>
              <AButton id="edit_sample" intent="white" Icon={Icons.IconEdit} onClick={() => setShowEditModal(true)} />
              {sample.status === SampleStatus.in_progress && (
                <AButton onClick={() => setConfirmVisible(true)} Icon={Icons.IconDelete} intent="white" id="delete" />
              )}
            </>
          )}
        </Controls>
      </Item>
      {showEditModal && <EditSample sample={sample} refetch={refetch} onClose={() => setShowEditModal(false)} />}
      {isConfirmVisible && (
        <ConfirmArchive onClose={() => setConfirmVisible(false)} sampleId={sample.id} refetch={refetch} />
      )}
    </BorderedItem>
  )
}
