import { Route, Routes } from 'react-router-dom'
import CarrierKRDetails from './CarrierKRDetails/CarrierKRDetails'
import { CarriersKRAddCar } from './CarriersKRAddCar/CarriersKRAddCar'
import CarriersKRList from './CarriersKRList/CarriersKRList'

import CarriersKRRoutes from './routes'
import { FC } from 'react'

const CarriersKRPage: FC = () => (
  <Routes>
    <Route index element={<CarriersKRList />} />
    <Route path={CarriersKRRoutes.relativeAddCar} element={<CarriersKRAddCar />} />
    <Route path={CarriersKRRoutes.relativeDetails} element={<CarrierKRDetails />} />
  </Routes>
)
export default CarriersKRPage
