import { useEffect, useState } from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { useSingleEntity } from 'modules/domain/common/hooks'
import { endpoints } from 'modules/endpoints'
import { Bid, BidStatus, USBid } from 'modules/domain/bid/types'
import { Icons, Progress, useHelmet } from '@agro-club/agroclub-shared'
import { useTranslation } from 'react-i18next'
import ItemLoadingLayout from 'views/layouts/ItemLoadingLayout/ItemLoadingLayout'
import * as Layout from 'views/layouts/NewLayout'
import * as Header from 'views/ui/Header/Header'
import BidRoutes from 'views/pages/Bid/routes'
import { BidPageTitle } from '../styled'
import { BidLeftBlock } from './BidLeftBlock'
import { BidRightBlock } from './BidRightBlock'
import { TaskModal } from 'views/components/TaskModal/TaskModal'
import { ArchiveBidButton } from './ArchiveBidButton'
import { AButton } from 'views/components/Analytics'
import APageWrapper from 'views/components/PageWrapper/APageWrapper'
import { AnalyticsPages, AnalyticsPlaces } from '../../analyticsPlaces'
import { BarchartIcon } from './BarchartIcon'
import { BidStatusTag, BidTypeTag, BidFuturesTag } from 'views/components/Tags'
import { BidCreatedUpdatedTag } from 'views/components/Tags/BidCreatedUpdatedTag'
import { CreateDealFromBidModal } from '../../components/US/CreateDealModal/CreateDealFromBidModal'
import { isUS } from 'env'

export const BidDetail = () => {
  const { id } = useParams()
  const { t } = useTranslation(['bid', 'common'])
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [createDealModal, setCreateDealModal] = useState<boolean>(false)
  const [fetchProgress, bid, refreshBid] = useSingleEntity<Bid>(endpoints.bid(id))
  useHelmet({ title: `${t('common:bid')} - ${bid?.product?.title || id}` })
  const navigate = useNavigate()

  useEffect(() => {
    // child bids can be opened directly
    if (bid?.parent_id) {
      navigate(generatePath(BidRoutes.Details, { id: bid.parent_id }))
    }
  }, [bid, navigate])

  if (fetchProgress === Progress.WORK || !bid) {
    return <ItemLoadingLayout id={id} />
  }

  return (
    <APageWrapper page={AnalyticsPages.BID_DETAIL} place={AnalyticsPlaces.DETAIL} id={id}>
      <Layout.WrapperContent>
        <Layout.Header>
          <Layout.TopHeader>
            <Header.Breadcrumbs
              routes={[
                { path: BidRoutes.List, breadcrumbName: t('list.heading') },
                { breadcrumbName: bid.product?.title },
              ]}
            />
            <Header.ButtonWrapper>
              {isUS && bid.status === BidStatus.published && (
                <>
                  <AButton
                    id="createDeal"
                    intent="approve"
                    onClick={() => setCreateDealModal(true)}
                    size="medium"
                    Icon={Icons.IconAdd}
                    analyticsPayload={{ id: bid.id }}
                  >
                    {t('bid:createDeal')}
                  </AButton>
                  <Header.LineSeparator />
                </>
              )}
              <AButton id="setTask" intent="secondary" onClick={() => setIsOpen(true)} size="medium">
                {t('common:setTask')}
              </AButton>

              <Header.LineSeparator />

              <ArchiveBidButton bid={bid} />
            </Header.ButtonWrapper>
          </Layout.TopHeader>
          <Layout.PageTitleWithTagWrapper>
            <BidPageTitle>{bid.product?.title}</BidPageTitle>
            <BarchartIcon source={bid.source} />
            <BidTypeTag bid={bid} />
            <BidStatusTag bid={bid} />
            <BidFuturesTag bid={bid} />
            <BidCreatedUpdatedTag bid={bid} />
          </Layout.PageTitleWithTagWrapper>
        </Layout.Header>
        <Layout.ContentTwoColumns>
          <div>
            <BidLeftBlock bid={bid} refreshBid={refreshBid} />
          </div>
          <div>
            <BidRightBlock bid={bid} />
          </div>
        </Layout.ContentTwoColumns>
      </Layout.WrapperContent>
      {isOpen && <TaskModal user={bid.owner} bid={bid} close={() => setIsOpen(false)} />}
      {createDealModal && <CreateDealFromBidModal bid={bid as USBid} onClose={() => setCreateDealModal(false)} />}
    </APageWrapper>
  )
}
