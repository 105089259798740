import { generatePath } from 'react-router-dom'
import styled from 'styled-components'
import { TableTextLink } from 'views/ui/TableTextLink/TableTextLink'
import BidRoutes from 'views/pages/Bid/routes'
import { Icons, Typography, defaultTheme } from '@agro-club/agroclub-shared'
import { BidSources, UIBidTypes } from 'modules/domain/bid/types'
import CompanyRoutes from 'views/pages/Company/routes'

const Title = styled.span`
  ${Typography.bodyMedium}
`

const SecondaryText = styled.p`
  margin: 0;
  color: ${defaultTheme.color.secondaryPlaceholder};
`

const LinkWrapper = styled.div`
  display: inline-block;
`

const Wrapper = styled.div`
  svg {
    width: 16px;
    height: 16px;
    margin-bottom: -4px;
    margin-left: 4px;

    path {
      fill: ${defaultTheme.color.primary600};
    }
  }
`

export const ProductCell = ({ id, title, status, parameters_description, source, season }) => {
  const generatePathToBid = () => {
    const path = status === UIBidTypes.potential ? BidRoutes.PotentialBidDetail : BidRoutes.Details

    return generatePath(path, { id })
  }

  return (
    <Wrapper>
      <LinkWrapper>
        <TableTextLink to={generatePathToBid()} tableItemId={id} id="bid">
          <Title>{title}</Title>
        </TableTextLink>
        {source === BidSources.BARCHART && <Icons.IconBarchart />}
      </LinkWrapper>

      {season && <SecondaryText>{season}</SecondaryText>}

      {parameters_description && <SecondaryText>{parameters_description}</SecondaryText>}
    </Wrapper>
  )
}

export const CompanyCell = ({ id, name }) => {
  return (
    <TableTextLink to={generatePath(CompanyRoutes.Details, { id })} tableItemId={id} id="company" target="_blank">
      <Title>{name}</Title>
    </TableTextLink>
  )
}
