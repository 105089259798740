import { FC } from 'react'
import { RequestDetailsAccordion } from '../RequestDetails/components/RequestsDetailsAccordion'
import { LogisticRequest } from 'modules/domain/logisticsKR/types'
import { AAntDrawer } from 'views/components/Analytics'
import { AnalyticsPlaces } from '../../analyticsPlaces'
import { refetchFunc } from 'modules/domain/common/hooks'

type Props = {
  drawerIsOpen: boolean
  setDrawerIsOpen: (arg: boolean) => void
  activeRequest: LogisticRequest | undefined
  requestsRefetch?: refetchFunc
  className?: string
}
export const RequestDrawer: FC<Props> = ({ activeRequest, requestsRefetch, setDrawerIsOpen, drawerIsOpen }) => {
  return (
    <AAntDrawer
      id={AnalyticsPlaces.REQUESTS_BOARD.INFO_DRAWER}
      width={576}
      closable={drawerIsOpen}
      open={drawerIsOpen}
      placement="right"
      onClose={() => setDrawerIsOpen(false)}
    >
      {activeRequest && (
        <RequestDetailsAccordion
          withoutShadow
          refetchRequest={requestsRefetch}
          request={activeRequest}
          defaultActiveKey="info"
        />
      )}
    </AAntDrawer>
  )
}
