import styled from 'styled-components'

export const MenuContainer = styled.div`
  height: 100%;
  overflow: hidden auto;
  padding-bottom: 10px;
  scrollbar-width: none; //Hide scroll for firefox

  &::-webkit-scrollbar {
    display: none;
  }
`
