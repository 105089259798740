import { defaultTheme } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 33px;
  width: 100%;
  max-width: 684px;
  margin-top: 16px;
  padding: 24px;
  background-color: ${defaultTheme.color.backgroundSecondary};
  box-shadow: ${defaultTheme.boxShadowColor};
  border-radius: 8px;
`

export const FormInputs = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`

export const InputWithCheckbox = styled.div`
  display: flex;
  align-items: center;
  column-gap: 24px;
`

export const FooterButtons = styled.div`
  display: flex;
  align-items: center;
  column-gap: 16px;
`
