import { Typography, defaultTheme } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;

  div {
    margin-right: 0;
  }
`

export const StatusItemWrapper = styled.div<{ iconColor?: string }>`
  display: flex;
  align-items: center;
  column-gap: 4px;
  ${Typography.bodySmall}
  color: ${defaultTheme.color.textSecondary60a};

  path {
    fill: ${({ iconColor: color }) => color};
  }
`

export const SectionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
`

export const CardRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  h4 {
    margin-bottom: 0;
  }
`

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
`

export const Wrapper = styled.div`
  width: 100%;
  max-width: 803px;
  min-width: 608px;
`

export const LinkWrapper = styled.div`
  width: 520px;
`
