import { FiltersAndSearchWrapper, FiltersWrapper } from '../CommonTableComponents/CommonTableComponents'
import { MobileFiltersModal } from '../MobileComponents/Filters/MobileFiltersModal'
import { MobileFilterBtn } from '../MobileComponents/Filters/MobileFilterBtn'
import { SearchFilter } from '../SearchFilter/SearchFilter'
import { defaultTheme } from '@agro-club/agroclub-shared'
import { ListRequestParams } from 'modules/domain/types'
import { AClearFiltersButton } from '../Analytics'
import { useMediaQuery } from 'react-responsive'
import { FC, ReactNode, useState } from 'react'

type Props = {
  listRequestParams: ListRequestParams
  displayClearBtn: boolean
  updateFilterState: any
  children: ReactNode
  onReset: () => void
}

export const ResponsiveFilters: FC<Props> = ({
  updateFilterState,
  listRequestParams,
  displayClearBtn,
  children,
  onReset,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const handlerModal = () => {
    setIsOpen(!isOpen)
  }
  const isMobile = useMediaQuery({
    query: `(max-width: ${defaultTheme.queries.mobile})`,
  })
  return (
    <>
      {isMobile ? (
        <FiltersAndSearchWrapper>
          <MobileFilterBtn handlerModal={handlerModal} displayClearBtn={displayClearBtn} />
          <SearchFilter listRequestParams={listRequestParams} updateFilterState={updateFilterState} />
          <MobileFiltersModal
            displayClearBtn={displayClearBtn}
            handlerModal={handlerModal}
            onReset={onReset}
            isOpen={isOpen}
          >
            {children}
          </MobileFiltersModal>
        </FiltersAndSearchWrapper>
      ) : (
        <FiltersAndSearchWrapper>
          <FiltersWrapper>
            {children}
            {displayClearBtn && <AClearFiltersButton onClick={onReset} />}
          </FiltersWrapper>
          <SearchFilter listRequestParams={listRequestParams} updateFilterState={updateFilterState} />
        </FiltersAndSearchWrapper>
      )}
    </>
  )
}
