import { FC, useMemo } from 'react'

import { AntDropdown, AvailableLanguages, Typography } from '@agro-club/agroclub-shared'

import { LanguageButton, OptionStyled } from 'views/ui/LanguageSelect/styled'
import styled from 'styled-components'

const CustomOverlayWrapper = styled.div`
  & .ant-dropdown-menu-item {
    ${Typography.bodyLarge};
    border-radius: 4px;
    padding: 5px 12px;
  }

  & * {
    height: auto;
  }
`

type Props = {
  codes: string[]
  value: AvailableLanguages
  onChange: (code: AvailableLanguages) => void
}

interface Lang {
  title: string
}

const languages: Record<string, Lang> = {
  [AvailableLanguages.ru]: {
    title: 'РУС',
  },
  [AvailableLanguages.pt_BR]: {
    title: 'BR',
  },
  [AvailableLanguages.en]: {
    title: 'EN',
  },
}

export const LanguageSelect: FC<Props> = ({ codes, value, onChange }) => {
  const items = useMemo(
    () =>
      codes.map((code) => {
        const language = languages[code]

        return {
          key: code,
          label: <OptionStyled onClick={() => onChange(code as AvailableLanguages)}>{language.title}</OptionStyled>,
        }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [codes, value],
  )

  return (
    <AntDropdown
      items={items}
      triggerElement={<LanguageButton>{languages[value].title}</LanguageButton>}
      CustomOverlayWrapper={CustomOverlayWrapper}
    />
  )
}
