import { defaultTheme, Typography } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

export const AddressesBlockWrapper = styled.div`
  ${Typography.bodyLarge}
  margin: 1px 0 12px 0;
`

export const BlockText = styled.div`
  width: 360px;
`
export const ItemText = styled.div``
export const TypeAddress = styled.div`
  color: ${defaultTheme.color.secondaryPlaceholder};
`

export const Btns = styled.div`
  display: flex;
  gap: 0 3px;
`

export const DropdownItemWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
  ${Typography.bodyLarge}
  color: ${defaultTheme.color.textPrimary900};

  svg {
    width: 20px;
    height: 20px;

    path {
      fill: ${defaultTheme.color.textPrimary900};
    }
  }

  a:hover {
    text-decoration: none;
  }
`
