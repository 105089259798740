import { DateISO } from '@agro-club/agroclub-shared'
import { Declaration, Product } from '../types'
import { CompanyShort } from '../company/types'

type Coordinator = {
  id: string
  phone: string
  full_name: string
  is_active: boolean
}

export enum DocumentRequestStatuses {
  'new' = 'new',
  'in_progress' = 'in_progress',
  'completed' = 'completed',
}

export type DocumentRequestShort = {
  id: string
  status: DocumentRequestStatuses
  quantity: number
  product: Product
  regionals: Coordinator[]
  seller_company: CompanyShort
  load_address: string
  consignee: string
  unload_address: string
  pay_to_customer_account: boolean

  expected_complete_date: DateISO
  sampling_date: DateISO
  request_ds: boolean
  received_ds: boolean
  received_ds_date: DateISO
  request_quarantine: boolean
  received_quarantine_date: DateISO
  received_quarantine: boolean
  request_vet: boolean
  received_vet_date: DateISO
  received_vet: boolean
  request_sdiz: boolean
  received_sdiz_date: DateISO
  received_sdiz: boolean
  received_conclusion_date: DateISO
  received_conclusion: boolean

  product_purpose: ProductPurpose
}

export type DocumentRequestFull = DocumentRequestShort & {
  deal_coordinator: Coordinator
  shipment_coordinator: Coordinator
  customer_company: CompanyShort
  declaration: Declaration | null
  deal: string
  available_statuses: DocumentRequestStatuses[]
}

export enum ProductPurpose {
  feed = 'feed',
  alimentary = 'alimentary',
}

export type DocumentRequestDTO = {
  deal: string
  request_ds: boolean
  request_quarantine: boolean
  request_vet: boolean
  request_sdiz: boolean
  regionals: string[] | null
  load_address: string
  unload_address: string
  consignee: string
  pay_to_customer_account: boolean
  product_purpose: ProductPurpose
  quantity: number
}

export type DocumentRequestDatesUpdateDTO = Partial<{
  expected_complete_date: DateISO
  sampling_date: DateISO
  received_ds_date: DateISO
  received_quarantine_date: DateISO
  received_vet_date: DateISO
  received_sdiz_date: DateISO
  received_sdiz: boolean
  received_vet: boolean
  received_quarantine: boolean
  received_ds: boolean
  received_conclusion: boolean
  received_conclusion_date: DateISO
  quantity: number
}>
