import { useBidColumns } from './useBidColumns'

export const usePotentialBidColumns = () => {
  const columns = useBidColumns()
  return [
    columns.product,
    columns.status,
    columns.quantity,
    columns.address,
    columns.distance,
    columns.company,
    columns.owner,
    columns.created,
    columns.created_by,
    columns.modified,
  ]
}
