import { useTranslation } from 'react-i18next'
import { ColumnsType } from 'antd/lib/table'
import { TeamsPlansByMembers } from 'modules/domain/teams/types'
import { ARouterLink } from 'views/components/Analytics'
import { generatePath } from 'react-router-dom'
import TeamsRoutes from 'views/pages/Teams/routes'
import formatNumber from 'modules/utils/numbers/formatNumber'
import { useMemo } from 'react'
import env from 'env'
import { TeamsProgress } from 'views/components/TeamsProgress/TeamsProgress'

const currency = env.REACT_APP_CURRENCY_SYMBOL

export const useTeamColumns = (): ColumnsType<TeamsPlansByMembers> => {
  const { t } = useTranslation('teams')

  return useMemo(
    () => [
      {
        title: t('team'),
        dataIndex: 'teamName',
        key: 'teamName',
        width: '88px',
        render: (teamName, data) => (
          <ARouterLink
            eventName="team"
            itemId={String(data.team)}
            to={generatePath(TeamsRoutes.Details, { id: data.team })}
            key={data.team}
          >
            {teamName}
          </ARouterLink>
        ),
      },
      {
        title: t('monthly_plan', { currency }),
        dataIndex: 'volume',
        key: 'volume',
        align: 'right',
        width: '142px',
        render: (volume) => formatNumber(volume),
      },
      {
        title: t('month_results', { currency }),
        dataIndex: 'completed',
        key: 'completed',
        align: 'right',
        width: '186px',
        render: (_, memberList) => (
          <TeamsProgress align="flex-end" isTableCell volume={memberList.volume} completed={memberList.completed} />
        ),
      },
    ],
    [t],
  )
}
