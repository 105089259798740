import { Icons } from '@agro-club/agroclub-shared'
import { AButton } from 'views/components/Analytics'
import { Header, TopHeader, PageName, Breadcrumbs } from 'views/layouts/NewLayout'
import * as HeaderLayout from 'views/ui/Header/Header'
import { RatesFilters } from '../RatesFilters'
import { ListRequestParams } from 'modules/domain/types'
import { useTranslation } from 'react-i18next'
import { isBrazil } from 'env'
import { useParams } from 'react-router-dom'
import { RatesProductTypes } from 'views/pages/LogisticsKR/Rates/types'

interface Props {
  listRequestParams: ListRequestParams
  updateFilterState: (filterObj: Record<string, any>) => void
  clearAllTabsState: () => void
  isEditingAvailable?: boolean
  setIsEditModalOpen?: (value: boolean) => void
  setIsAddModalOpen: (value: boolean) => void
}

export const RatesListHeader = ({
  listRequestParams,
  updateFilterState,
  clearAllTabsState,
  isEditingAvailable,
  setIsEditModalOpen,
  setIsAddModalOpen,
}: Props) => {
  const { t } = useTranslation('rates')
  const { activeTab: activeTabParams } = useParams()
  const isAverage = activeTabParams === RatesProductTypes.average

  return (
    <Header>
      <TopHeader>
        <Breadcrumbs routes={[{ breadcrumbName: t('heading') }]} />
        {!isAverage && !isBrazil && (
          <HeaderLayout.AbsoluteButtonWrapper>
            <AButton
              id="edit"
              intent="secondary"
              size="medium"
              disabled={!isEditingAvailable}
              onClick={() => setIsEditModalOpen?.(true)}
            >
              {t('editMultiple')}
            </AButton>

            <AButton
              id="add"
              Icon={Icons.IconAdd}
              intent="approve"
              size="medium"
              onClick={() => setIsAddModalOpen(true)}
            >
              {t('common:add')}
            </AButton>
          </HeaderLayout.AbsoluteButtonWrapper>
        )}
      </TopHeader>

      <PageName>{t('heading')}</PageName>

      <RatesFilters
        listRequestParams={listRequestParams}
        updateFilterState={updateFilterState}
        clearAllTabsState={clearAllTabsState}
        disabled={isAverage}
      />
    </Header>
  )
}
