import { DASH } from 'modules/constants'
import { refetchFunc } from 'modules/domain/common/hooks'
import { WarehouseMovement } from 'modules/domain/warehouses/types'
import { formatDailyPriceInTon } from 'modules/utils/numbers/formatDailyPriceInTon'
import { formatQuantity } from 'modules/utils/numbers/formatQuantity'
import { formatTarif } from 'modules/utils/numbers/formatTarif'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Card } from 'views/components/Card/Card'
import { KeyValue } from 'views/components/KeyValue/KeyValue'
import { WhMovementEditModal } from '../WhMovementEditModal'

type Props = {
  isMobile: boolean
  whMovement: WarehouseMovement
  whMovementRefetch: refetchFunc
}

export const EditableWhMovementInfo: FC<Props> = ({ isMobile, whMovement, whMovementRefetch }) => {
  const { t } = useTranslation('warehouses')
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <Card.Container bordered disableShadow>
        <Card.Grid cols={isMobile ? 2 : 3} maxColumnWidth={isMobile ? '1fr' : '128px'} columnGap={32}>
          <KeyValue label={t('detail.unloadDate')}>
            {whMovement.documents_request_expected_complete_date || DASH}
          </KeyValue>
          <KeyValue label={t('common:quantity')}>
            {whMovement.quantity ? formatQuantity(whMovement.quantity) : DASH}
          </KeyValue>
          <KeyValue label={t('common:exw')}>{whMovement.price_exw ? formatTarif(whMovement.price_exw) : DASH}</KeyValue>
          <KeyValue label={t('detail.storage')}>
            {whMovement.storage_price ? formatDailyPriceInTon(whMovement.storage_price) : DASH}
          </KeyValue>
          <KeyValue label={t('detail.handlingPrice')}>
            {whMovement.handling_price ? formatTarif(whMovement.handling_price) : DASH}
          </KeyValue>
          <KeyValue label={t('detail.transshipment')}>
            {whMovement.transshipment_price ? formatTarif(whMovement.transshipment_price) : DASH}
          </KeyValue>
          <Card.EditButton id="replaceWarehouse" onClick={() => setIsOpen(true)} />
        </Card.Grid>
      </Card.Container>
      {isOpen && (
        <WhMovementEditModal
          whMovementRefetch={whMovementRefetch}
          whMovement={whMovement}
          onClose={() => setIsOpen(false)}
        />
      )}
    </>
  )
}
