import { Company } from 'modules/domain/company/types'
import { StatusItemWrapper } from './styled'
import { Icons } from '@agro-club/agroclub-shared'
import { useTranslation } from 'react-i18next'
import { factoringStatusColors, getFactoringSummary } from '../helpers'

interface Props {
  company: Company
}

export const FactoringAllStatuses = ({ company }: Props) => {
  const { t } = useTranslation('company')
  const factoringSummary = getFactoringSummary(company)

  if (!factoringSummary) return null

  return (
    <StatusItemWrapper iconColor={factoringStatusColors[factoringSummary]}>
      <Icons.IconFactoring />
      {t(`factoringStatuses.${factoringSummary}`)}
    </StatusItemWrapper>
  )
}
