import { FC } from 'react'
import { SharedModalContent, SharedModalInputs, SharedModalTitle } from 'views/components/SharedModal'
import { CarModalContent } from '../../components/CarModalContent/CarModalContent'
import { useFormik } from 'formik'
import { FormGroup, Radio, RadioItem } from '@agro-club/agroclub-shared'
import { useTranslation } from 'react-i18next'
import { CarStatuses } from '../../types'
import { FormItemWrapper } from 'views/styled/common'
import { apiClient } from 'modules/utils/httpClient'
import { endpoints } from 'modules/endpoints'
import ASharedModal from 'views/components/SharedModal/ASharedModal'
import { AnalyticsPlaces } from '../../analyticsPlaces'
import ADefaultModalFooter from 'views/components/DefaultModalFooter/ADefaultModalFooter'
import { useAFormHandler } from 'analytics/hooks'

type Props = {
  carrierId: string
  onClose: () => void
  onSuccess: () => void
}

export type Fields = {
  status: string
  carrier: string
  brand_model: string
  licence_number: string
  car_type: string
  unloading_type: string
  side_height: string | null
  is_dump_truck: boolean
  has_trailer: boolean
  trailer: string
}

export const CarriersKRAddCarModal: FC<Props> = ({ onClose, carrierId, onSuccess }) => {
  const { formProgress, formHandler } = useAFormHandler()
  const { t } = useTranslation('logisticsKR')

  const addCarHandler = formHandler(async (dto) => await apiClient.post(endpoints.LogisticsKR.car(), dto), {
    onSuccess: async () => {
      await onSuccess()
      onClose()
    },
    onError: (error) => {
      const { errors } = error
      formik.setErrors(errors)
    },
  })

  const formik = useFormik<Fields>({
    initialValues: {
      status: CarStatuses.active,
      carrier: carrierId,
      brand_model: '',
      licence_number: '',
      car_type: '',
      unloading_type: '',
      side_height: null,
      is_dump_truck: false,
      has_trailer: false,
      trailer: '',
    },
    enableReinitialize: true,

    onSubmit: async () => await addCarHandler(formik.values),
  })

  return (
    <ASharedModal id={AnalyticsPlaces.CARRIERS.ADD_CAR_MODAL} onClose={onClose}>
      <SharedModalTitle>{t('carriers.carsAdd.heading')}</SharedModalTitle>
      <SharedModalContent>
        <SharedModalInputs>
          <FormGroup error={formik.errors.status} label={t('carsEdit.form.labels.car')}>
            <FormItemWrapper>
              <Radio onChange={(value) => formik.setFieldValue('status', value)} value={formik.values.status}>
                <RadioItem value={CarStatuses.active} label={t('statuses.active')} />
                <RadioItem value={CarStatuses.draft} label={t('statuses.draft')} />
              </Radio>
            </FormItemWrapper>
          </FormGroup>
          <CarModalContent formik={formik} showTrailer={formik.values.has_trailer} />
        </SharedModalInputs>
      </SharedModalContent>
      <ADefaultModalFooter
        progress={formProgress}
        onClose={onClose}
        disabled={!formik.dirty}
        onConfirm={() => formik.submitForm()}
        confirmButtonText={t('common:save')}
      />
    </ASharedModal>
  )
}
