import { FormGroup, Input } from '@agro-club/agroclub-shared'
import { FormikErrors } from 'formik'
import { DealProductDTO } from 'views/pages/Deal/DealDetail/types'
import { ReactNode } from 'react'

type Props = {
  label?: ReactNode
  formikError?: FormikErrors<any>
  parameter: DealProductDTO | undefined
  onChange: (values: DealProductDTO) => void
  required?: boolean
}

export const FormParamInput = ({ parameter, onChange, formikError, label = '', required }: Props) => {
  const onChangeParameter = (e) => {
    const newParameter = { ...parameter }
    newParameter['parameter_value'] = e.target.value
    onChange(newParameter)
  }

  const error = formikError && formikError['parameter_value']

  return (
    <FormGroup label={label} error={error} required={required}>
      <Input value={parameter?.['parameter_value']} onChange={onChangeParameter} type="number" invalid={!!error} />
    </FormGroup>
  )
}
