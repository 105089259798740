import { Progress } from '@agro-club/agroclub-shared'
import { useNotification } from './useNotification'
import { useTranslation } from 'react-i18next'

export const useNoPermissionNotification = () => {
  const { t } = useTranslation('common')
  const notify = useNotification()
  return (url?: string) => {
    notify(Progress.ERROR, {
      title: t('noPermissions.title'),
      text: url ? t('noPermissions.textWithUrl', { url }) : t('noPermissions.text'),
    })
  }
}
