import { Progress } from '@agro-club/agroclub-shared'
import { useAFormHandler } from 'analytics/hooks'
import { useFormik } from 'formik'
import { useNotification } from 'hooks/useNotification'
import { Bid, BidContract, MatchingBidContract } from 'modules/domain/bid/types'
import { FC, ReactNode } from 'react'
import { ASearch } from 'views/components/Analytics'
import { NoDataCard } from 'views/components/Board/NoDataCard'
import { Card } from 'views/components/Card/Card'
import { DealPartyChangeData, PartyChangePayload } from '../../hooks/useDealPartyChange'
import { ContractCard } from '../../Rematch/RematchModal/Columns/Cards/ContractCard'
import { ContractPreload } from '../../Rematch/RematchModal/Columns/ContractsColumn'
import { RematchForm } from '../../Rematch/RematchModal/RematchForm'
import { useDealContext } from '../DealContext'
import { BidDetailBlockProps } from './BidDetailBlockDefault'
import { BidDetailTitle } from './DetailTitle/BidDetailTitle'

const PartyChangerItem: FC<{ item: Bid | BidContract; changer: DealPartyChangeData; children?: ReactNode }> = ({
  item,
  changer,
  children,
}) => {
  const { matching } = changer

  const isSelected = matching?.bid?.id == item.id

  return (
    <ContractCard
      key={item.id}
      contract={item as BidContract}
      onSelect={() => {
        matching.setBid(item)
      }}
      isSelected={isSelected}
    >
      {isSelected ? children : null}
    </ContractCard>
  )
}

const PartyChangerItems: FC<{ changer: DealPartyChangeData; children?: ReactNode }> = ({ changer, children }) => {
  const { loading, items } = changer.matching

  if (loading) {
    return [1, 2, 3, 4].map((key) => <ContractPreload key={key} />)
  }

  if (!items?.length) {
    return <NoDataCard />
  }

  return items.map((item) => (
    <PartyChangerItem key={item.id} item={item} changer={changer}>
      {children}
    </PartyChangerItem>
  ))
}

const PartyChangerForm: FC<{ onSuccess: () => void; changer: DealPartyChangeData }> = ({ changer, onSuccess }) => {
  const {
    deal,
    matching: { loading, bid },
  } = changer

  const contract = bid as MatchingBidContract
  const dealPreview = contract.deal_preview ?? deal

  const { formProgress, formHandler } = useAFormHandler('dealPartyChange')

  const initialValues: PartyChangePayload = {
    quantity: Math.min(contract?.rematching_quantity ?? dealPreview.quantity, dealPreview.quantity),
    price_logistics: contract?.deal_preview?.price_logistics ?? 0,
    distance: contract?.deal_preview?.distance ?? deal.distance,
  }

  const formik = useFormik<PartyChangePayload>({
    initialValues,

    onSubmit: formHandler(
      () => {
        return changer.change(formik.values)
      },
      {
        onSuccess,
        onError: (error) => {
          const { errors } = error
          formik.setErrors(errors)
        },
      },
    ),
  })

  if (loading || !contract || !initialValues.quantity) {
    return null
  }

  return <RematchForm formik={formik} progress={formProgress} partyChanging />
}

export const BidDetailBlockPartyChanging: FC<BidDetailBlockProps> = ({ bidType, partyChanger }) => {
  const { deal, dealRefetch } = useDealContext()
  const notify = useNotification()
  const onSuccess = async () => {
    notify(Progress.SUCCESS)
    await dealRefetch()
    partyChanger.stop()
  }
  return (
    <Card.Container bordered>
      <Card.GapableContent>
        <BidDetailTitle bidType={bidType} refetch={dealRefetch} deal={deal} partyChanger={partyChanger}>
          <ASearch value={partyChanger.matching.search} onChange={partyChanger.matching.setSearch}></ASearch>
        </BidDetailTitle>
        <PartyChangerItems changer={partyChanger}>
          <PartyChangerForm changer={partyChanger} onSuccess={onSuccess} />
        </PartyChangerItems>
      </Card.GapableContent>
    </Card.Container>
  )
}
