import { defaultListRequestParams } from 'views/components/TableFilters/data'
import APageWrapper from 'views/components/PageWrapper/APageWrapper'
import ATableFullPage from 'views/components/Table/ATableFullPage'
import { ListRequestParams } from 'modules/domain/types'
import { SamplesFilters } from './SamplesFilters'
import * as Layout from 'views/layouts/NewLayout'
import * as Header from 'views/ui/Header/Header'
import { useTranslation } from 'react-i18next'
import { endpoints } from 'modules/endpoints'
import { useFilters } from 'hooks/useFilter'
import { useColumns } from './useColumns'
import { FC, useContext, useState } from 'react'
import { AuthContext } from 'modules/context/AuthContext'

export const SamplesList: FC = () => {
  const [listRequestParams, setListRequestParams] = useState<ListRequestParams>(defaultListRequestParams)
  const { profile: currentUser } = useContext(AuthContext)

  const { isFiltersLoaded, updateFilterState } = useFilters({
    listRequestParams,
    setListRequestParams,
    defaultParams: {
      team: currentUser?.profile?.team?.id && String(currentUser?.profile?.team?.id),
    },
  })

  const { t } = useTranslation('samples')
  const columns = useColumns(listRequestParams.sort)

  return (
    <APageWrapper page="Samples" place="SamplesList">
      <Layout.WrapperContent>
        <Layout.Header>
          <Layout.TopHeader>
            <Header.Breadcrumbs routes={[{ breadcrumbName: t('list.heading') }]} />
          </Layout.TopHeader>
          <Layout.PageName>{t('list.heading')}</Layout.PageName>
          <SamplesFilters
            setListRequestParams={setListRequestParams}
            listRequestParams={listRequestParams}
            updateFilterState={updateFilterState}
          />
        </Layout.Header>

        <ATableFullPage
          listParamsUpdated={setListRequestParams}
          listRequestParams={listRequestParams}
          dataEndpoint={endpoints.samples()}
          isFiltersLoaded={isFiltersLoaded}
          columns={columns}
          rowHoverGray
        />
      </Layout.WrapperContent>
    </APageWrapper>
  )
}
