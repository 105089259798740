import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { AntTag } from '@agro-club/agroclub-shared'

import { BidStatus, CarStatuses } from '../types'

type Props = {
  logistics_status: string
}

export const StatusCell: FC<Props> = ({ logistics_status }) => {
  const { t } = useTranslation('logisticsKR')

  switch (logistics_status) {
    case BidStatus.new:
      return <AntTag color="orange">{t('statuses.new')}</AntTag>
    case BidStatus.car_search:
      return <AntTag color="red">{t('statuses.car_search')}</AntTag>
    case BidStatus.shipping:
      return <AntTag color="blue">{t('statuses.shipping')}</AntTag>
    case BidStatus.shipment_complete:
      return <AntTag color="green">{t('statuses.shipment_complete')}</AntTag>
    case CarStatuses.active:
      return <AntTag color="green">{t('statuses.active')}</AntTag>
    case CarStatuses.archived:
      return <AntTag color="grey">{t('statuses.archived')}</AntTag>
    case CarStatuses.draft:
      return <AntTag color="red">{t('statuses.draft')}</AntTag>
    default:
      return <AntTag color="blue">{logistics_status}</AntTag>
  }
}
