import { ReactNode } from 'react'
import { AButton } from 'views/components/Analytics'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Text } from '@agro-club/agroclub-shared'
import { useAProgress } from 'hooks/useAProgress'
import { apiClient } from 'modules/utils/httpClient'
import { endpoints } from 'modules/endpoints'
import { useParams } from 'react-router-dom'
import { refetchFunc } from 'modules/domain/common/hooks'
import { DealContract } from 'modules/domain/deal/types'
import { Progress } from '@agro-club/agroclub-shared'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`

const ButtonsWrapper = styled.div`
  display: flex;
  column-gap: 16px;
`

type Props = {
  label: ReactNode
  eventId: string
  field: string
  onSuccess: refetchFunc
  contract: DealContract
}

export const DocumentControl = ({ label, eventId, field, onSuccess, contract }: Props) => {
  const { t } = useTranslation()
  const { id } = useParams()
  const [progress, updateDocumentAvailability] = useAProgress(
    (dto: Record<string, boolean>) => apiClient.patch(endpoints.dealsContracts(id), dto),
    {
      eventName: 'update_document_availability',
    },
  )

  const completeProgress = contract[field] ? Progress.IDLE : progress
  const cancelProgress = !contract[field] ? Progress.IDLE : progress

  const completeHandler = async () => {
    await updateDocumentAvailability({ [field]: true })
    await onSuccess()
  }

  const cancelHandler = async () => {
    await updateDocumentAvailability({ [field]: false })
    await onSuccess()
  }

  return (
    <Wrapper>
      <Text typography="bodyLarge" color="secondary">
        {label}
      </Text>
      <ButtonsWrapper>
        <AButton
          progress={completeProgress}
          id={eventId}
          intent="primary"
          disabled={contract[field]}
          onClick={completeHandler}
        >
          {t('common:complete')}
        </AButton>
        {contract[field] && (
          <AButton progress={cancelProgress} id={`${eventId}_cancel`} intent="secondary" onClick={cancelHandler}>
            {t('common:cancel')}
          </AButton>
        )}
      </ButtonsWrapper>
    </Wrapper>
  )
}
