import { AntSwitch, Text, defaultTheme } from '@agro-club/agroclub-shared'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { FC } from 'react'

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 0 30px;
`

const TextBlock = styled.div``
const WrapperControl = styled.div`
  display: flex;
  align-items: center;
  gap: 0 8px;
  color: ${defaultTheme.color.accentDestruct600};
`

const StyledText = styled(Text)<{ disabled?: boolean }>`
  color: ${({ disabled }) => disabled && defaultTheme.color.secondaryPlaceholder3};
`

type Props = {
  onChange: (value: boolean) => void
  disabledText?: boolean
  isStopText?: boolean
  loading?: boolean
  valueText: string
  keyText: string
  value: boolean
}

export const SwitchItem: FC<Props> = ({ keyText, valueText, disabledText, isStopText, value, onChange, loading }) => {
  const { t } = useTranslation('user')
  return (
    <Wrapper>
      <TextBlock>
        <StyledText disabled={disabledText} typography="bodyLarge">
          {keyText}
        </StyledText>
        <StyledText disabled={disabledText} typography="bodyMedium">
          {valueText}
        </StyledText>
      </TextBlock>
      <WrapperControl>
        {isStopText && <Text typography="bodySmall">{t('smsNotification.stopedText')}</Text>}
        <AntSwitch checked={value} onChange={onChange} loading={loading} />
      </WrapperControl>
    </Wrapper>
  )
}
