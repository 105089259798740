import { TaskStatuses } from 'views/pages/MyTasks/TasksList/components/TaskStatuses'
import { BorderedItem } from 'views/components/BorderedItem/BorderedItem'
import { AntTag, AntTooltip, defaultTheme, Text, Typography } from '@agro-club/agroclub-shared'
import { ARouterLink } from 'views/components/Analytics'
import { AdditionalInfo, TaskDetail } from './styled'
import TasksRoutes from 'views/pages/MyTasks/routes'
import useFormatDate from 'hooks/useFormatDate'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router'
import styled from 'styled-components'
import { FC } from 'react'
import { CommonTagsWrapper } from 'views/styled/common'
import { Task, TaskStatus } from 'modules/domain/task/types'
import { refetchFunc } from 'modules/domain/common/hooks'
import { CardButtonWrapper } from 'views/styled/ItemCardStyles'
import { User } from 'modules/domain/user/types'
import { isBrazil } from 'env'
import { CompleteTaskBtn } from 'views/components/TaskActions/CompleteTask/CompleteTaskBtn'

type Props = {
  tasksRefetch: refetchFunc
  userRefetch: refetchFunc
  task: Task
  user: User
}
const TaskWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 10px;
`

const Description = styled.div<{ isComplete?: boolean }>`
  ${Typography.bodyMedium};
  line-height: 22px;
  background: ${defaultTheme.color.backgroundPrimary};
  color: ${({ isComplete, theme }) => (isComplete ? theme.color.textSecondary60a : 'inherit')};
  border-radius: 8px;
  padding: 8px;
  margin-top: 8px;
  max-height: 57px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  cursor: pointer;
`
const OverlayContent = styled.div`
  width: 420px;
`
export const TaskItem: FC<Props> = ({ task, tasksRefetch, user, userRefetch }) => {
  const { t } = useTranslation('task')
  const additionalInfo = [task?.assignee_team?.name, task.assignee?.short_name].filter(Boolean).join(' / ')
  const formatDate = useFormatDate()
  const isComplete = task.status === TaskStatus.complete
  const assignee = `${task.assignee_team ? `${task.assignee_team} / ` : ''}${task.assignee_full_name ?? ''}`
  const subject = isBrazil ? (
    <ARouterLink
      eventName="taskLink"
      itemId={task.id}
      to={generatePath(TasksRoutes.Details, {
        id: task.id,
      })}
      target="_blank"
    >
      {task.subject}
    </ARouterLink>
  ) : (
    <Text>{task.subject}</Text>
  )

  return (
    <BorderedItem hover={false}>
      <TaskWrapper>
        <CommonTagsWrapper>
          <TaskStatuses status={task.status} />
          <AntTag color="white">{task.task_type_label}</AntTag>
        </CommonTagsWrapper>

        <TaskDetail>
          <Text color={isComplete ? 'secondary' : 'primary'}>{formatDate(task.deadline, true)}</Text>
          {isComplete ? <Text color="secondary">{task.subject}</Text> : <>{subject}</>}
          <Text color={isComplete ? 'tertiary' : 'secondary'}>{assignee}</Text>
          <AdditionalInfo>{additionalInfo}</AdditionalInfo>
          <AntTooltip
            tooltipContent={<OverlayContent>{task.comment}</OverlayContent>}
            variant="secondary"
            placement="top"
          >
            {task.comment && <Description isComplete={isComplete}>{task.comment}</Description>}
          </AntTooltip>
        </TaskDetail>
      </TaskWrapper>
      <CardButtonWrapper>
        {!isComplete && (
          <CompleteTaskBtn
            user={user}
            refetch={tasksRefetch}
            task={task}
            successText={t('notifyTaskCompleted')}
            userRefetch={userRefetch}
          />
        )}
      </CardButtonWrapper>
    </BorderedItem>
  )
}
