import { ListRequestParams } from 'modules/domain/types'
import { WarehouseMovement } from 'modules/domain/warehouses/types'
import { endpoints } from 'modules/endpoints'
import { Dispatch, FC, SetStateAction } from 'react'
import { useParams } from 'react-router-dom'
import ATableFullPage from 'views/components/Table/ATableFullPage'
import { NestedShipmentsTable } from './NestedShipmentsTable/NestedShipmentsTable'
import useColumns from './useColumns'

type Props = {
  listRequestParams: ListRequestParams
  setListRequestParams: Dispatch<SetStateAction<ListRequestParams>>
  isFiltersLoaded: boolean
}

export const WarehouseDetailTable: FC<Props> = ({ isFiltersLoaded, setListRequestParams, listRequestParams }) => {
  const { id } = useParams()
  const columns = useColumns()

  const expandable = {
    showExpandColumn: true,
    rowExpandable: (row: WarehouseMovement) => !!row.shipments.length,
    expandedRowRender: (row: WarehouseMovement) => <NestedShipmentsTable shipments={row.shipments} />,
  }

  return (
    <ATableFullPage
      listRequestParams={listRequestParams}
      listParamsUpdated={setListRequestParams}
      dataEndpoint={endpoints.warehouseMovementsById(id)}
      isFiltersLoaded={isFiltersLoaded}
      columns={columns}
      expandable={expandable}
    />
  )
}
