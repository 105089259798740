import { FC, useEffect, useState } from 'react'

import { useTableData } from 'modules/domain/common/hooks'
import { endpoints } from 'modules/endpoints'
import { defaultListRequestParams } from 'views/components/TableFilters/data'
import { useTranslation } from 'react-i18next'
import { AntCollapse } from '@agro-club/agroclub-shared'
import { Trips } from './Trips'
import { TripsReconciliation } from './TripsReconciliation'
import { TripOfferPanel } from 'views/pages/LogisticsKR/components/TripOffers/TripOfferPanel'
import { LogisticRequest } from 'modules/domain/logisticsKR/types'
import { LogisticsKRTrip, TripOfferStatuses, TripStatus } from 'views/pages/LogisticsKR/types'
import { MultipleColumnWrapper } from '../CarrierBoard'

type RequestItems = {
  approved: LogisticsKRTrip[]
  on_approval: LogisticsKRTrip[]
}

type Props = {
  carrier: string
  car: string
  request: LogisticRequest | undefined
}

export const TripsAndOffersColumn: FC<Props> = ({ carrier, car, request }) => {
  const { t } = useTranslation('logisticsKR')
  const [listRequestParams, setListRequestParams] = useState({
    ...defaultListRequestParams,
    filter: { carrier, car, request: request?.id },
    pageSize: 1000,
  })
  const carAndRequestDeps = !!listRequestParams.filter.car && !!listRequestParams.filter.request
  const {
    progress,
    data: trips,
    refetch: tripsRefetch,
  } = useTableData(endpoints.LogisticsKR.trips(), listRequestParams, carAndRequestDeps)

  const {
    progress: tripOffersProgress,
    data: tripOffers,
    refetch: refetchTripOffers,
  } = useTableData(endpoints.LogisticsKR.tripOffers(), listRequestParams, carAndRequestDeps)
  const newTripOffers = tripOffers?.filter((el) => el?.status === TripOfferStatuses.new)
  const rejectedTripOffers = tripOffers?.filter((el) => el?.status === TripOfferStatuses.closed)

  const [items, setItems] = useState<RequestItems>({ approved: [], on_approval: [] })

  useEffect(() => {
    if (!trips) return
    const trasformed: RequestItems = {
      approved: trips.filter((c) => c.status === TripStatus.approved),
      on_approval: trips.filter((c) => c.status === TripStatus.on_approval),
    }

    setItems(trasformed)
  }, [trips])

  useEffect(() => {
    setListRequestParams((prev) => ({ ...prev, filter: { carrier, car, request: request?.id } }))
  }, [car, carrier, request?.id])

  return (
    <MultipleColumnWrapper collapseAmount={4}>
      <AntCollapse defaultActiveKey={TripStatus.approved} noMarginTop accordion bordered={false}>
        <Trips
          trips={items.approved}
          key={TripStatus.approved}
          title={t('carriers.trips_column.approved')}
          counter={items.approved.length}
          progress={progress}
        />
        <TripsReconciliation
          refetch={tripsRefetch}
          trips={items.on_approval}
          key={TripStatus.on_approval}
          title={t('carriers.trips_column.on_approval')}
          counter={items.on_approval.length}
          progress={progress}
        />
        <TripOfferPanel
          activeRequest={request}
          title={t('logisticBids:board_columns.title.trip_offers')}
          key={TripOfferStatuses.new}
          progress={tripOffersProgress}
          tripOffers={newTripOffers}
          tripOffersRefetch={refetchTripOffers}
          refetchDraftTrips={tripsRefetch}
          noDataIsVisible
        />
        <TripOfferPanel
          activeRequest={request}
          counterColor="grey"
          title={t('logisticBids:board_columns.title.rejected_trip_offers')}
          key={TripOfferStatuses.closed}
          progress={tripOffersProgress}
          tripOffers={rejectedTripOffers}
          noDataIsVisible
        />
      </AntCollapse>
    </MultipleColumnWrapper>
  )
}
