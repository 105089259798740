import { OtherLinksModels, Task } from 'views/pages/MyTasks/TaskDetail/types'
import { useTranslation } from 'react-i18next'
import { ARouterLink } from 'views/components/Analytics'
import { generatePath } from 'react-router'
import { DocumentRequestsRoutes } from 'views/pages/DocumentRequests/routes'

interface Props {
  task: Task
}

export const OtherLinks = ({ task }: Props) => {
  const { t } = useTranslation('my_tasks')
  if (!task.other_links) return

  const linksArr = Object.values(task.other_links).map((link) => {
    if (link.model === OtherLinksModels.DOCUMENT_REQUEST) {
      return (
        <ARouterLink
          eventName="DocumentRequest"
          key={link.id}
          itemId={String(link.id)}
          to={generatePath(DocumentRequestsRoutes.Details, { id: link.id })}
        >
          {`${t('detail.document_request')} ${link.id}`}
        </ARouterLink>
      )
    }
  })

  return linksArr
}
