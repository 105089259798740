import { ColumnData } from 'modules/types'
import { useMemo } from 'react'
import { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'
import { EditRatesCell } from './components/EditRatesCell'
import { RegionCell } from './components/RegionCell'
import { RangeType, RatesProductTypes } from './types'

const getColumns = (
  range: RangeType[],
  t: TFunction,
  onSuccess: () => void,
  currentProductType?: RatesProductTypes,
) => {
  const rangeColumns = range.map((range, index) => ({
    key: range.upper.toString(),
    title: range.upper,
    width: 56,
    align: 'right',
    dataIndex: 'logistics_rates',
    render: (logistics_rates) => logistics_rates[index]?.rate,
  }))

  const results = [
    {
      title: t('region'),
      width: 144,
      align: 'left',
      dataIndex: 'name',
      render: (name, data) => <RegionCell name={name} logistician_name={data.responsible_logisticians[0]?.full_name} />,
    },
    ...rangeColumns,
    {
      title: '',
      width: 50,
      dataIndex: 'id',
      align: 'center',
      fixed: 'right',
      render: (id, data) => (
        <EditRatesCell
          regionIds={[id]}
          onSuccess={onSuccess}
          ratesData={data.logistics_rates}
          responsibleLogisticians={data.responsible_logisticians}
          currentProductType={currentProductType}
        />
      ),
    },
  ]

  return results
}

export const useColumns = (
  range: RangeType[],
  onSuccess: () => void,
  currentProductType?: RatesProductTypes,
): ColumnData[] => {
  const { t } = useTranslation('rates')

  return useMemo(() => getColumns(range, t, onSuccess, currentProductType), [range, t, currentProductType, onSuccess])
}
