import { FC } from 'react'
import formatPhone from 'helpers/formatPhone'
import { Driver, LogisticsKRTrip } from 'views/pages/LogisticsKR/types'
import { TableExternalLink } from 'views/ui/TableTextLink/TableTextLink'
import { FlexColumnWrapper, SecondaryText } from '../styled'
import { DASH } from 'modules/constants'

export interface Props {
  driver: Driver | null
  logistics_request: LogisticsKRTrip
}

export const DriverCell: FC<Props> = ({ driver, logistics_request }) => {
  const { brand_model, licence_number } = logistics_request.car || {}
  return (
    <FlexColumnWrapper>
      <div>{driver?.name ? driver.name : DASH}</div>
      {driver?.phone ? (
        <TableExternalLink href={`tel:${driver.phone}`}>{formatPhone(driver.phone)}</TableExternalLink>
      ) : (
        DASH
      )}
      <SecondaryText>{`${brand_model}/${licence_number}`}</SecondaryText>
    </FlexColumnWrapper>
  )
}
