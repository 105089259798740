import { useFormik } from 'formik'
import { WarehouseDTO } from 'modules/domain/types'
import * as managers from 'modules/domain/userAddress/managers'

import { BuyerWarehouseForm } from './BuyerWarehouseForm'
import { useAFormHandler } from 'analytics/hooks'
import { FC } from 'react'
import { RequestError } from 'modules/errors'
import { EditWarehouseProps } from './types'

export const BuyerWarehouseEditModal: FC<EditWarehouseProps> = ({
  onSuccess,
  bid,
  onClose,
  userAddress,
  userId,
  dealId,
}) => {
  const { formProgress, formHandler } = useAFormHandler('editBuyerWarehouse', { user_id: userId, id: dealId })
  const address = userAddress ? userAddress : (bid?.address as WarehouseDTO)
  const ownerId = userId ? userId : bid?.owner.id
  const addressId = userAddress && userId ? userAddress.id : bid?.address.id

  const formik = useFormik<WarehouseDTO>({
    initialValues: {
      is_dump_truck: address.is_dump_truck,
      schedule_from: address.schedule_from,
      schedule_to: address.schedule_to,
      unloading_type: address.unloading_type,
      vehicle_type: address.vehicle_type,
      overload: address.overload,
      volume_per_day: address.volume_per_day,
      works_on_weekend: address.works_on_weekend,
      car_delivery: address.car_delivery,
      railway_delivery: address.railway_delivery,
    },
    onSubmit: formHandler(
      async () => {
        await managers.editItem(String(ownerId), String(addressId), formik.values)
      },
      {
        onSuccess: async () => {
          await onSuccess()
          onClose()
        },
        onError: (err) => {
          const error = err as RequestError
          const { errors } = error
          formik.setErrors(errors)
        },
      },
    ),
  })

  return <BuyerWarehouseForm progress={formProgress} formik={formik} onClose={onClose} />
}
