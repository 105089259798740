import { Routes, Route } from 'react-router-dom'
import { FC } from 'react'
import { TasksList } from 'views/pages/MyTasks/TasksList/TasksList'
import { TaskDetail } from 'views/pages/MyTasks/TaskDetail/TaskDetail'
import TasksRoutes from 'views/pages/MyTasks/routes'

export const TasksPage: FC = () => {
  return (
    <Routes>
      <Route index element={<TasksList />}></Route>
      <Route path={TasksRoutes.relativeDetail} element={<TaskDetail />}></Route>
    </Routes>
  )
}
