import { initReactI18next } from 'react-i18next'

import i18n from 'i18next'
import HttpBackend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { AvailableLanguages } from '@agro-club/agroclub-shared'
import { InstanceIDs, REACT_APP_INSTANCE_ID, REACT_APP_LOCALE } from 'env'

const instance = REACT_APP_INSTANCE_ID

const getAvailableLanguages = (instance) => {
  switch (instance) {
    case InstanceIDs.US:
      return [AvailableLanguages.en]
    case InstanceIDs.BR:
      return [AvailableLanguages.pt_BR, AvailableLanguages.en]
    default:
      return [AvailableLanguages.ru, AvailableLanguages.en]
  }
}

export const supportedLngs = getAvailableLanguages(instance)

i18n
  .use(HttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // debug: true,
    supportedLngs,
    fallbackLng: REACT_APP_LOCALE,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    backend: {
      // before useSuspense please add all main ns to RootComponent
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
    detection: {
      order: ['cookie', 'htmlTag'],
      caches: ['cookie'],
    },
    react: {
      useSuspense: false,
    },
  })

export default i18n
