import { defaultTheme } from '@agro-club/agroclub-shared'
import { FC, ReactNode } from 'react'
import styled from 'styled-components'

const Wrapper = styled.div<{ hover: boolean }>`
  position: relative;
  border: 1px solid ${defaultTheme.color.secondary50};
  border-radius: 8px;
  overflow: hidden;
  &:hover {
    background-color: ${({ hover }) => (hover ? `${defaultTheme.color.secondary25}` : 'none')};
  }
  &:not(:first-child) {
    border-top: none;
    border-radius: 0 0 8px 8px;
  }

  &:not(:last-child) {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
`

const Content = styled.div<{ padding: boolean; justifyContent: FlexJustify; columnGap: string }>`
  display: flex;
  flex-direction: row;
  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: center;

  padding: ${({ padding }) => (padding ? '11px 12px' : `0`)};
  column-gap: ${({ columnGap }) => columnGap};
`

type FlexJustify = 'flex-start' | 'space-between' | 'flex-end' | 'center' | 'normal'

interface BorderedItemProps {
  children: ReactNode
  padding?: boolean
  justifyContent?: FlexJustify
  columnGap?: string
  hover?: boolean
}

export const BorderedItem: FC<BorderedItemProps> = ({
  children,
  padding = true,
  hover = true,
  justifyContent = 'space-between',
  columnGap = '10px',
}) => {
  return (
    <Wrapper hover={hover}>
      <Content padding={padding} columnGap={columnGap} justifyContent={justifyContent}>
        {children}
      </Content>
    </Wrapper>
  )
}
