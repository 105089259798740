import { useMemo, useState } from 'react'
import { ListRequestParams } from 'modules/domain/types'
import { defaultListRequestParams } from 'views/components/TableFilters/data'
import { useTableData } from 'modules/domain/common/hooks'
import { endpoints } from 'modules/endpoints'
import { Task, TaskStatus, TaskType } from 'modules/domain/task/types'
import { ACCORDION_ITEM_PAGE_SIZE } from 'modules/constants'
import { useFilters } from 'hooks/useFilter'

export type TaskManagerReturnType = ReturnType<typeof useUserTasksManager>

export const useUserTasksManager = (userId: string) => {
  const [listRequestParams, setListRequestParams] = useState<ListRequestParams>({
    ...defaultListRequestParams,
    pageSize: ACCORDION_ITEM_PAGE_SIZE,
  })
  const { isFiltersLoaded, updateFilterState } = useFilters({
    listRequestParams,
    setListRequestParams,
    defaultParams: { task_type: TaskType.CALL, status: TaskStatus.in_progress },
  })

  const {
    progress,
    total,
    data = [],
    refetch,
  } = useTableData<Task>(endpoints.newUserTasks(userId), listRequestParams, isFiltersLoaded)

  return useMemo(
    () => ({
      progress,
      total,
      data,
      refetch,
      listRequestParams,
      setListRequestParams,
      updateFilterState,
    }),
    [data, listRequestParams, progress, refetch, total, updateFilterState],
  )
}
