import AntCollapseHeader from 'views/components/AntCollapseHeader/AntCollapseHeader'
import { RightPanelComments } from 'views/components/Comments/styled'
import { AntCollapse, Panel, Progress } from '@agro-club/agroclub-shared'
import { refetchFunc } from 'modules/domain/common/hooks'
import AComments from 'views/components/Comments/AComments'
import { CommentType } from 'modules/domain/comment/types'
import { RespFile } from 'modules/domain/types'
import { useTranslation } from 'react-i18next'
import { FC, useMemo, useState } from 'react'
import { endpoints } from 'modules/endpoints'
import { Sample } from '../../types'
import { Files } from './Files'
import { SummaryComments } from 'views/components/AntCollapseHeader/SummaryComments'
import { SummaryCount } from 'views/components/AntCollapseHeader/SummaryCount'

type Props = {
  sample: Sample
  sampleProgress: Progress
  files: RespFile[] | undefined
  sampleRefetch: refetchFunc
}

export const RightBlock: FC<Props> = ({ sample, sampleProgress, files, sampleRefetch }) => {
  const { t } = useTranslation('sample')

  const [commentsSummary, setCommentsSummary] = useState<string>()

  const options = useMemo<{ title: string; value: string; isOpened?: boolean; isShow?: boolean }[]>(
    () =>
      [
        { value: 'comments', title: t('common:comments'), isOpened: true, isShow: true },
        { value: 'files', title: t('common:files'), isOpened: true, isShow: true },
      ].filter((option) => option.isShow),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t],
  )

  const components = {
    comments: (
      <RightPanelComments>
        <AComments
          url={endpoints.sampleComments(sample.id)}
          popSummary={setCommentsSummary}
          type={CommentType.user}
          maxHeight={400}
          id={sample.id}
        />
      </RightPanelComments>
    ),
    files: <Files progress={sampleProgress} files={files} refetchFiels={sampleRefetch} />,
  }

  const summary = {
    comments: <SummaryComments summary={commentsSummary} />,
    files: <SummaryCount total={files?.length} />,
  }

  const [activeKey, setActiveKey] = useState<string | string[]>(options[0].value)

  return (
    <div>
      <AntCollapse
        onChange={(key) => setActiveKey(key)}
        defaultActiveKey={options[1].value}
        style={{ width: 566 }}
        bordered={false}
        accordion
      >
        {options.map((option) => {
          return (
            <Panel
              forceRender={true}
              key={option.value}
              header={
                <AntCollapseHeader title={option.title} summary={activeKey !== option.value && summary[option.value]} />
              }
            >
              {components[option.value]}
            </Panel>
          )
        })}
      </AntCollapse>
    </div>
  )
}
