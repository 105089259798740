import { useCallback, useEffect, useState } from 'react'

import { Progress } from '@agro-club/agroclub-shared'

import { endpoints } from 'modules/endpoints'
import { apiClient } from 'modules/utils/httpClient'
import { Address } from 'modules/domain/types'

export const useAddress = (
  userId?: string,
  addressId?: string,
): [Progress, Address | undefined, (userId?: string, addressId?: string) => void] => {
  const [address, setAddress] = useState<Address | undefined>(undefined)
  const [progress, setProgress] = useState<Progress>(Progress.IDLE)

  const callback = useCallback(() => {
    if (userId) {
      setProgress(Progress.WORK)
      apiClient
        .get<Address[]>(endpoints.userAddress(userId))
        .then((data) => {
          setAddress(data.find((item) => item.id === addressId) as Address)
          setProgress(Progress.SUCCESS)
        })
        .catch(() => setProgress(Progress.ERROR))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressId, userId])

  useEffect(() => {
    callback()
  }, [callback])

  return [progress, address, callback]
}
