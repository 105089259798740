import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { FormGroup, Radio, RadioItem } from '@agro-club/agroclub-shared'
import { FormikFieldsType } from 'modules/domain/types'
import { FormItemWrapper } from 'views/styled/common'

interface Props {
  formik: FormikFieldsType
  label: string
  fieldName: string
  onChange?: (v: boolean) => void
}

export const BoolRadio: FC<Props> = ({ formik, label, fieldName, onChange }) => {
  const { t } = useTranslation('common')

  const getValue = (value) => {
    if (value === null) {
      return null
    } else {
      return value ? 1 : 0
    }
  }
  const handler = (v) => {
    if (onChange) {
      onChange(!!v)
      return
    }
    formik.setFieldValue(fieldName, !!v)
  }
  return (
    <FormGroup error={formik.errors[fieldName]} label={label}>
      <FormItemWrapper>
        <Radio onChange={handler} value={getValue(formik.values[fieldName])}>
          <RadioItem value={1} label={t('yes')} />
          <RadioItem value={0} label={t('no')} />
        </Radio>
      </FormItemWrapper>
    </FormGroup>
  )
}
