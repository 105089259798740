import { ASharedModal, SharedModalTitle } from 'views/components/SharedModal'
import { EditableFields, ShipLoadCommonContent } from './ModalCommonContent'
import { useFormik } from 'formik'
import { useAFormHandler } from 'analytics/hooks'
import { apiClient } from 'modules/utils/httpClient'
import { refetchFunc } from 'modules/domain/common/hooks'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { endpoints } from 'modules/endpoints'

type Props = {
  onClose: () => void
  refetch: refetchFunc
  dealId: string
}

export const AddShipLoadModal: FC<Props> = ({ onClose, refetch, dealId }) => {
  const { t } = useTranslation('deal')

  const { formProgress, formHandler } = useAFormHandler()

  const formik = useFormik<EditableFields>({
    initialValues: {
      regional: '',
      work_type: null,
      load_date: null,
      quantity: null,
      record_type: 'export',
    },

    onSubmit: formHandler(() => apiClient.post(endpoints.dealShipLoading(dealId), formik.values), {
      onSuccess: async () => {
        await refetch(true)
        onClose()
      },
      onError: (error) => {
        const { errors } = error
        formik.setErrors(errors)
      },
    }),
  })

  return (
    <ASharedModal id="shipLoading" onClose={onClose}>
      <SharedModalTitle>{t('accordion.loading.add_ship_load')}</SharedModalTitle>
      <ShipLoadCommonContent formik={formik} onClose={onClose} progress={formProgress} />
    </ASharedModal>
  )
}
