import { Deal, DealStatusTypes } from 'modules/domain/deal/types'
import { CreatePositionsModal } from './CreatePositionsModal'
import { refetchFunc } from 'modules/domain/common/hooks'
import { AButton } from 'views/components/Analytics'
import { useTranslation } from 'react-i18next'
import { FC, useState } from 'react'
import { isUS } from 'env'

type Props = {
  deal: Deal
  refetch: refetchFunc
}

export const MovePosition: FC<Props> = ({ deal, refetch }) => {
  const { t } = useTranslation('deal')
  const [isModalVisible, setIsModalVisible] = useState(false)

  const canMovePosition = isUS && deal.available_statuses?.[0] === DealStatusTypes.CONFIRMED_BATCH && !deal.is_position

  if (!canMovePosition) return null

  return (
    <>
      <AButton id="move-position" intent="secondary" onClick={() => setIsModalVisible(true)}>
        {t('movePosition')}
      </AButton>
      {isModalVisible && (
        <CreatePositionsModal onClose={() => setIsModalVisible(false)} refetch={refetch} dealId={deal.id} />
      )}
    </>
  )
}
