import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { ClearFiltersButton } from '@agro-club/agroclub-shared'
import { useAnalyticsClickHandler } from 'analytics/hooks'
import { AnalyticsFilterVariant } from 'analytics/types'

interface Props {
  onClick: () => void
  children?: React.ReactNode
}

const AClearFiltersButton: FC<Props> = ({ onClick }) => {
  const { t } = useTranslation('common')

  const handleClick = useAnalyticsClickHandler('clearFilters')

  return (
    <ClearFiltersButton
      onClick={
        handleClick(onClick, {
          filter: AnalyticsFilterVariant.CLEAR_FILTERS,
        }) as any
      }
      data-test-id={AnalyticsFilterVariant.CLEAR_FILTERS}
    >
      {t('resetAllFilters')}
    </ClearFiltersButton>
  )
}

export default AClearFiltersButton
