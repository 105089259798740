import { Card } from 'views/components/Card/Card'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { defaultTheme } from '@agro-club/agroclub-shared'

const SecondaryText = styled.div`
  color: ${defaultTheme.color.textSecondary60a};
`

export const WithoutSpecWarning = () => {
  const { t } = useTranslation('deal')

  return (
    <Card.Container disableShadow style={{ marginBottom: 8 }} intent="description">
      <div>{t('accordion.buyer_specifications.warn_primary_text')}</div>
      <SecondaryText>{t('accordion.buyer_specifications.warn_secondary_text')}</SecondaryText>
    </Card.Container>
  )
}
