import { BidPrice } from 'modules/domain/bid/types'
import { ProfileType } from 'modules/domain/user/types'

export const isDeliveryAddressRequired = (ownerProfileType: ProfileType, price_type?: BidPrice) => {
  if (ownerProfileType === ProfileType.seller && price_type === BidPrice.CPT) {
    return true
  } else if (ownerProfileType === ProfileType.customer && price_type === BidPrice.EXW) {
    return true
  }
  return false
}
