import formatNumber from './formatNumber'
import env, { isBrazil, isUS } from 'env'
import { t } from 'i18next'

const currency = env.REACT_APP_CURRENCY_SYMBOL

const unit = () => {
  const unitKey = isUS ? 'common:dollarBshMil' : isBrazil ? 'common:realTKm' : 'common:rubTKm'
  return t(unitKey, { currency })
}

export const labelTarifDistance = () => {
  return unit()
}

export const formatTarifDistance = (value) => {
  return `${formatNumber(value)} ${unit()}`
}

export const labelDistance = (label?: string, comma = true) => {
  return `${label || ''}${comma ? ', ' : ' '}${isUS ? t('common:mile') : t('common:km')}`
}

export const formatDistance = (value) => {
  return `${formatNumber(value)} ${isUS ? t('common:mile') : t('common:km')}`
}
