import { FC, useState } from 'react'
import { isBidContractsEnabled } from 'env'
import { BidTypes } from 'modules/domain/bid/types'
import { refetchFunc } from 'modules/domain/common/hooks'
import { Deal, EditableDealStatuses } from 'modules/domain/deal/types'
import { getDealContract } from 'modules/domain/deal/utils'
import { AButton } from 'views/components/Analytics'
import { EditContractModal } from 'views/pages/BidContracts/BidContractDetail/Modals/EditContractModal'
import { Icons } from '@agro-club/agroclub-shared'
import { useTranslation } from 'react-i18next'

type Props = {
  deal: Deal
  bidType: BidTypes
  disabled?: boolean
  refetch: refetchFunc
}

export const EditBidContractAction: FC<Props> = ({ disabled = false, refetch, deal, bidType }) => {
  const { t } = useTranslation()
  const [modalVisible, setModalVisible] = useState(false)

  const contract = getDealContract(deal, bidType)
  const isContractEditable = EditableDealStatuses.includes(deal.status)

  if (!isBidContractsEnabled || !contract || !isContractEditable) {
    return null
  }

  return (
    <>
      <AButton
        id="editContract"
        intent="white"
        Icon={Icons.IconEdit}
        onClick={() => setModalVisible(true)}
        disabled={disabled}
        tooltipProps={{
          tooltipContent: t('bid:contracts.editContract'),
        }}
      />

      {modalVisible && (
        <EditContractModal refetch={refetch} onClose={() => setModalVisible(false)} contract={contract} />
      )}
    </>
  )
}
