import { defaultTheme, Typography } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

export const OptionStyled = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
  height: 40px;
`

export const LanguageButton = styled.button`
  ${Typography.bodyLarge};
  border: 1px solid ${defaultTheme.color.secondary100};
  border-radius: 8px;
  transition: 0.3s ease all;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 12px;
  background-color: transparent;

  &:hover {
    background-color: ${defaultTheme.color.secondary8a};
    cursor: pointer;
  }

  &.ant-dropdown-open {
    background-color: ${defaultTheme.color.primary8a};
  }
`
