import { FormGroup } from '@agro-club/agroclub-shared'
import { FC } from 'react'
import _ from 'lodash'
import { EnumSelect, EnumSelectProps } from './EnumSelect'

type Props = EnumSelectProps & {
  required?: boolean
  label?: string
}

export const FormEnumSelect: FC<Props> = ({ required, formik, label, fieldName, ...rest }) => {
  return (
    <FormGroup error={_.get(formik.errors, fieldName)} label={label} required={required}>
      <EnumSelect formik={formik} fieldName={fieldName} {...rest} />
    </FormGroup>
  )
}
