import { useHelmet } from '@agro-club/agroclub-shared'
import { endpoints } from 'modules/endpoints'
import { useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import APageWrapper from 'views/components/PageWrapper/APageWrapper'
import { WrapperContent } from 'views/layouts/NewLayout'
import { AnalyticsPages, AnalyticsPlaces } from 'views/pages/LogisticsKR/analyticsPlaces'
import { AddRatesModal } from '../AddRatesModal'
import { RatesListHeader } from './RatesListHeader'
import { useFilters } from 'hooks/useFilter'
import { defaultListRequestParams } from 'views/components/TableFilters/data'
import { ListRequestParams } from 'modules/domain/types'
import { useColumns } from '../../useColumns'
import { CommonRatesTable } from '../RatesTables/CommonRatesTable'

export const RatesBrContent = () => {
  const { t } = useTranslation('rates')
  useHelmet({ title: t('metaTitle') })

  const [listRequestParams, setListRequestParams] = useState<ListRequestParams>(defaultListRequestParams)
  const [isAddModalOpen, setIsAddModalOpen] = useState<boolean>(false)

  const { isFiltersLoaded, updateFilterState } = useFilters({
    listRequestParams,
    setListRequestParams,
  })

  const onSuccess = useCallback(() => {
    updateFilterState(listRequestParams.filter)
  }, [listRequestParams, updateFilterState])

  return (
    <APageWrapper page={AnalyticsPages.RATES} place={AnalyticsPlaces.RATES.LIST}>
      <WrapperContent>
        <RatesListHeader
          listRequestParams={listRequestParams}
          updateFilterState={updateFilterState}
          clearAllTabsState={() => setListRequestParams({ ...defaultListRequestParams })}
          setIsAddModalOpen={setIsAddModalOpen}
        />

        <CommonRatesTable
          listRequestParams={listRequestParams}
          isFiltersLoaded={isFiltersLoaded}
          setListRequestParams={setListRequestParams}
          dataEndpoint={endpoints.LogisticsKR.ratesWithRegion()}
          columnsFunc={useColumns}
          onSuccess={onSuccess}
        />

        {isAddModalOpen && <AddRatesModal refetch={onSuccess} onClose={() => setIsAddModalOpen(false)} />}
      </WrapperContent>
    </APageWrapper>
  )
}
