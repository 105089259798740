export enum AnalyticsPages {
  SPECIFICATIONS = 'specifications',
  SPECIFICATION_DETAIL = 'specificationDetail',
}

export const AnalyticsPlaces = {
  LIST: 'SpecificationsList',
  DETAIL: 'SpecificationDetail',
  CLOSE_MODAL: 'CloseModal',
  ARCHIVE_MODAL: 'ArchiveModal',
  UPDATE_MODAL: 'UpdateModal',
}
