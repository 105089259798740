import { ASharedModal, SharedModalContent, SharedModalTitle } from 'views/components/SharedModal'
import ADefaultModalFooter from 'views/components/DefaultModalFooter/ADefaultModalFooter'
import { Export, ExportStatus } from 'modules/domain/export/types'
import { refetchFunc } from 'modules/domain/common/hooks'
import { EditExport } from '../components/EditExport'
import { apiClient } from 'modules/utils/httpClient'
import { AButton } from 'views/components/Analytics'
import { useAProgress } from 'hooks/useAProgress'
import * as Header from 'views/ui/Header/Header'
import { useTranslation } from 'react-i18next'
import { endpoints } from 'modules/endpoints'
import { FC, useState } from 'react'

type Props = {
  refetch: refetchFunc
  exportData: Export
}

export const Controls: FC<Props> = ({ refetch, exportData }) => {
  const { t } = useTranslation('export')
  const [isConfirmVisible, setIsConfirmVisible] = useState(false)
  const [isOpenEditModal, setIsOpenEditModal] = useState(false)

  const handlerArchiveModal = () => {
    setIsConfirmVisible((prev) => !prev)
  }
  const handlerEditModal = () => {
    setIsOpenEditModal((prev) => !prev)
  }

  const [progress, complete] = useAProgress(() => apiClient.post(endpoints.exportFulfill(exportData.id)), {
    eventName: 'completeExport',
    analyticsPayload: {
      id: exportData.id,
    },
    onSuccess: () => refetch(),
  })

  const [archiveProgress, archive] = useAProgress(() => apiClient.post(endpoints.exportClose(exportData.id)), {
    eventName: 'archiveExport',
    analyticsPayload: {
      id: exportData.id,
    },
    onSuccess: () => {
      refetch()
      handlerArchiveModal()
    },
  })
  return (
    <Header.ButtonWrapper>
      {exportData.status === ExportStatus.active && (
        <>
          <AButton id="edit" intent="secondary" onClick={handlerEditModal}>
            {t('edit')}
          </AButton>
          <AButton id="complete" intent="secondary" onClick={complete} progress={progress}>
            {t('execute')}
          </AButton>
          <Header.LineSeparator />
        </>
      )}

      {exportData.status !== ExportStatus.closed && (
        <AButton id="archive" intent="destruct" onClick={handlerArchiveModal}>
          {t('archive')}
        </AButton>
      )}

      {isConfirmVisible && (
        <ASharedModal onClose={handlerArchiveModal} size="small" id="archiveModal">
          <SharedModalTitle>{t('archiveContract')}</SharedModalTitle>
          <SharedModalContent>{t('areYouSureToArchiveContract')}</SharedModalContent>
          <ADefaultModalFooter
            confirmButtonText={t('common:archive')}
            onClose={handlerArchiveModal}
            progress={archiveProgress}
            onConfirm={archive}
          />
        </ASharedModal>
      )}
      {isOpenEditModal && <EditExport onClose={handlerEditModal} exportData={exportData} refetch={refetch} />}
    </Header.ButtonWrapper>
  )
}
