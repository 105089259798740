import { defaultTheme, Typography } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

export const SeekBarStyledWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`

export const SeekBarStyled = styled.div`
  cursor: pointer;
  width: 100%;
  background: #8ac5ff;
  box-sizing: unset;
  border-radius: 0.5px;
  display: block;
  height: 2px;
  margin: 0 4px;
`

export const SeekBarTick = styled.div<{ isActive?: boolean }>`
  background-color: ${defaultTheme.color.primary600};
  height: 100%;
  position: relative;

  &:active {
    background-color: ${defaultTheme.color.primary800};
  }
  :after {
    content: ${({ isActive }) => (isActive ? '""' : 'unset')};
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background: ${defaultTheme.color.primary600};
    position: absolute;
    top: -3px;
    right: -6px;
  }

  &:active:after {
    background: ${defaultTheme.color.primary800};
  }
`

export const PlayerWrapper = styled.div`
  display: grid;
  column-gap: 6px;
  grid-template-columns: 20px auto;
  align-items: center;
  border-radius: 8px;
  padding: 0 12px 0 0;
`

export const Time = styled.div`
  ${Typography.bodySmall}
  color: ${defaultTheme.color.textPrimary900};
  font-variant-numeric: tabular-nums;
`

export const Button = styled.div<{ isPlaying?: boolean }>`
  background: ${defaultTheme.color.primary100};
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  & > svg {
    fill: ${defaultTheme.color.primary600};
    ${({ isPlaying }) => (isPlaying ? '' : 'transform: translateX(1px);')}
  }

  &:hover {
    background: ${defaultTheme.color.primary600};
    & > svg {
      fill: ${defaultTheme.color.backgroundSecondary};
    }
  }

  &:active {
    background: ${defaultTheme.color.primary800};
    & > svg {
      fill: ${defaultTheme.color.backgroundSecondary};
    }
  }
`
