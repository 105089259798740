import { defaultListRequestParams } from 'views/components/TableFilters/data'
import { Icons, useHelmet } from '@agro-club/agroclub-shared'
import { ListRequestParams } from 'modules/domain/types'
import * as Layout from 'views/layouts/NewLayout'
import * as Header from 'views/ui/Header/Header'
import { useTranslation } from 'react-i18next'
import { endpoints } from 'modules/endpoints'
import { useFilters } from 'hooks/useFilter'
import useColumns from './useColumns/useColumns'
import { FC, useState } from 'react'

import { CarriersKRFilters } from './CarriersKRFilters'
import { AddCarrierModal } from '../AddCarrierModal/AddCarrierModal'
import APageWrapper from 'views/components/PageWrapper/APageWrapper'
import { AnalyticsPages, AnalyticsPlaces } from '../../analyticsPlaces'
import { AButton } from 'views/components/Analytics'
import ATableFullPage from 'views/components/Table/ATableFullPage'

const CarriersKRList: FC = () => {
  const { t } = useTranslation(['logisticsKR'])
  useHelmet({ title: t('carriers.heading') })
  const [listRequestParams, setListRequestParams] = useState<ListRequestParams>({
    ...defaultListRequestParams,
  })
  const { isFiltersLoaded, updateFilterState } = useFilters({
    listRequestParams,
    setListRequestParams,
  })
  const columns = useColumns({ listRequestParams, setListRequestParams })
  const [addCarrierModal, setAddCarrierModal] = useState<boolean>(false)

  const handleClearFilters = () => {
    setListRequestParams(defaultListRequestParams)
  }

  return (
    <APageWrapper page={AnalyticsPages.CARRIERS.LIST} place={AnalyticsPlaces.CARRIERS.LIST}>
      <Layout.WrapperContent>
        <Layout.Header>
          <Layout.TopHeader>
            <Header.Breadcrumbs routes={[{ breadcrumbName: t('carriers.heading') }]} />
            <AButton
              id="add"
              Icon={Icons.IconAdd}
              intent="approve"
              size="medium"
              onClick={() => setAddCarrierModal(true)}
            >
              {t('common:add')}
            </AButton>
          </Layout.TopHeader>
          <Layout.PageName>{t('carriers.heading')}</Layout.PageName>
          {addCarrierModal && (
            <AddCarrierModal
              onSuccess={() => setListRequestParams({ ...listRequestParams })}
              onClose={() => setAddCarrierModal(false)}
            />
          )}
          <CarriersKRFilters
            setListRequestParams={setListRequestParams}
            updateFilterState={updateFilterState}
            listRequestParams={listRequestParams}
            onClearFilters={handleClearFilters}
          />
        </Layout.Header>
        <ATableFullPage
          listParamsUpdated={setListRequestParams}
          listRequestParams={listRequestParams}
          isFiltersLoaded={isFiltersLoaded}
          dataEndpoint={endpoints.LogisticsKR.carriers()}
          columns={columns}
          rowHoverGray
        />
      </Layout.WrapperContent>
    </APageWrapper>
  )
}

export default CarriersKRList
