import { isBrazil } from 'env'
import ATableFullPage from 'views/components/Table/ATableFullPage'
import { getLogisticsRanges } from 'views/pages/LogisticsKR/helpers'
import { RateTableProps } from './types'

export const CommonRatesTable = ({
  setSelectedRows,
  listRequestParams,
  setListRequestParams,
  isFiltersLoaded,
  onSuccess,
  dataEndpoint,
  columnsFunc,
}: RateTableProps) => {
  const columns = columnsFunc(getLogisticsRanges(), onSuccess as () => void, listRequestParams.filter.product_type)
  const rowSelection = {
    onChange: (selectedKeys, selectedRows) => {
      setSelectedRows?.(selectedRows)
    },
  }

  return (
    <ATableFullPage
      columns={columns}
      dataEndpoint={dataEndpoint}
      listParamsUpdated={setListRequestParams}
      listRequestParams={listRequestParams}
      isFiltersLoaded={isFiltersLoaded}
      rowSelection={
        !isBrazil
          ? {
              type: 'checkbox',
              ...rowSelection,
            }
          : undefined
      }
      rowHoverGray
    />
  )
}
