import { isUS } from 'env'
import { useBidColumns } from './useBidColumns'

export const useBidPurchaseColumns = () => {
  const columns = useBidColumns()

  if (isUS) {
    return [
      columns.symbol,
      columns.product,
      columns.status,
      columns.quantity,
      columns.fobBasis,
      columns.deliveryBasis,
      columns.distance,
      columns.price_exw,
      columns.salePriceDelivered,
      columns.basis,
      columns.futuresPrice,
      columns.cashPrice,
      columns.address,
      columns.company,
      columns.created,
      columns.created_by,
      columns.modified,
    ].filter(Boolean)
  }

  return [
    columns.product,
    columns.status,
    columns.quantity,
    columns.price_exw,
    columns.price_cpt,
    columns.address,
    columns.distance,
    columns.company,
    columns.owner,
    columns.created,
    columns.created_by,
    columns.modified,
  ].filter(Boolean)
}
