import { FC, useEffect } from 'react'
import { RespFile } from 'modules/domain/types'
import AUploadFiles from 'views/components/UploadFiles/AUploadFiles'
import { apiClient } from 'modules/utils/httpClient'
import { endpoints } from 'modules/endpoints'
import { uploadFile } from 'modules/domain/common/managers'
import { useSingleEntity } from 'modules/domain/common/hooks'
import { Progress } from '@agro-club/agroclub-shared'

interface Props {
  companyId: string
  contractId: string
  setSummary: (arg: number) => void
}

export const ContractDocuments: FC<Props> = ({ contractId, companyId, setSummary }) => {
  const [progress, contractFiles = [], contractFilesRefetch] = useSingleEntity<RespFile[]>(
    endpoints.contractFiles(companyId, contractId),
  )

  useEffect(() => {
    if (progress === Progress.SUCCESS) setSummary(contractFiles.length)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractFiles.length])

  const addHandler = async (companyId: string, contractId: string, file: File) => {
    await uploadFile(endpoints.contractFiles(companyId, contractId), file)
    contractFilesRefetch(true)
  }

  const removeHandler = async (companyId: string, contractId: string, fileId: string) => {
    await apiClient.delete(endpoints.deleteContractFile(companyId, contractId, fileId))
    contractFilesRefetch(true)
  }
  return (
    <AUploadFiles
      removeFile={(fileId) => removeHandler(companyId, contractId, fileId)}
      addFile={({ file }) => addHandler(companyId, contractId, file)}
      fileList={contractFiles}
    />
  )
}
