import { TableWrapper } from 'views/pages/User/UserDetail/UserRightPanel/RightBlockTable/styled'
import { VerticalFormatQuantity } from 'modules/utils/numbers/formatQuantity'
import { CompanyLink } from 'views/components/CompanyLink/CompanyLink'
import { ContractDeliveryPeriod } from './ContractDeliveryPeriod'
import { AntdTable, Progress } from '@agro-club/agroclub-shared'
import { BidContract, BidTypes } from 'modules/domain/bid/types'
import { formatTarif } from 'modules/utils/numbers/formatTarif'
import { DateFormatted } from 'views/components/DateFormatted'
import { KeyValue } from 'views/components/KeyValue/KeyValue'
import { Card, GridItem } from 'views/components/Card/Card'
import { ARouterLink } from 'views/components/Analytics'
import UserRoutes from 'views/pages/User/routes'
import { Deal } from 'modules/domain/deal/types'
import useFormatDate from 'hooks/useFormatDate'
import { useTranslation } from 'react-i18next'
import BidRoutes from 'views/pages/Bid/routes'
import { generatePath } from 'react-router'
import { useColumns } from './useColumns'
import styled from 'styled-components'
import { FC } from 'react'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px 0;
`

type Props = {
  contract: BidContract
  contractDeals?: Deal[]
  progress: Progress
}

export const LeftBlock: FC<Props> = ({ contract, contractDeals, progress }) => {
  const { t } = useTranslation('bidContracts')
  const columns = useColumns(contract)

  const isPurchase = contract.bid_type === BidTypes.purchase

  const formatDate = useFormatDate()
  const items = [
    contract.payment_due_date && {
      label: t(`bid:contracts.${isPurchase ? 'purchasePaymentDate' : 'sellerPaymentDate'}`),
      value: formatDate(contract.payment_due_date),
    },

    contract.payment_type_description && {
      label: t(`bid:contracts.${isPurchase ? 'purchaseFormPayment' : 'sellerFormPayment'}`),
      value: contract.payment_type_description,
    },
  ].filter(Boolean) as GridItem[]

  return (
    <Wrapper>
      <Card.Container>
        <Card.Grid cols={2} maxColumnWidth={'548px'}>
          <Card.GapableContent>
            <Card.Title>{t('commonInformation')}</Card.Title>
            <Card.Grid cols={3} maxColumnWidth={'172px'}>
              <KeyValue label={t('bid')}>
                <ARouterLink
                  target="_blank"
                  eventName="bid"
                  itemId={contract.bid_id}
                  to={generatePath(BidRoutes.Details, { id: contract.bid_id })}
                >
                  {contract.bid_id}
                </ARouterLink>
              </KeyValue>
              <KeyValue label={t('crop')}>{contract.product.title}</KeyValue>
              <KeyValue label={t('price')}>{formatTarif(contract.price)}</KeyValue>
            </Card.Grid>
            <Card.Grid cols={2} maxColumnWidth={'172px'}>
              <KeyValue label={t('volume')}>
                <VerticalFormatQuantity valueTons={contract.quantity} />
              </KeyValue>
              <KeyValue label={t('remainingQuantity')}>
                <VerticalFormatQuantity valueTons={contract.remaining_quantity} />
              </KeyValue>
              <KeyValue label={t('created')}>
                <DateFormatted date={contract.created} />
              </KeyValue>
              {contract.signed_at && (
                <KeyValue label={t('signed')}>
                  <DateFormatted date={contract.signed_at} />
                </KeyValue>
              )}
            </Card.Grid>
            <KeyValue label={t('company')}>
              <CompanyLink company={contract.company}>{contract.company.name}</CompanyLink>
              <div>{contract.address.address}</div>
            </KeyValue>
            <KeyValue label={t('shipping')}>
              <ContractDeliveryPeriod
                shipment_from_date={contract.shipment_from_date}
                shipment_due_date={contract.shipment_due_date}
              />
            </KeyValue>
            {contract.created_by?.id && (
              <KeyValue label={t('createdBy')}>
                <ARouterLink
                  to={generatePath(UserRoutes.Details, { id: contract.created_by.id })}
                  itemId={String(contract.created_by.id)}
                  eventName="user"
                  target="_blank"
                >
                  {contract.created_by?.full_name}
                </ARouterLink>
              </KeyValue>
            )}
          </Card.GapableContent>

          <div>
            <Card.GapableContent>
              <Card.Title>{t('paymentInformation')}</Card.Title>
              <Card.Grid cols={2} maxColumnWidth="200px">
                {items.map((item, index) => {
                  return (
                    <KeyValue key={index} label={item.label}>
                      {item.value}
                    </KeyValue>
                  )
                })}
              </Card.Grid>
            </Card.GapableContent>
          </div>
        </Card.Grid>
      </Card.Container>

      <TableWrapper>
        <AntdTable columns={columns} data={contractDeals ?? []} progress={progress} rowKey="id" />
      </TableWrapper>
    </Wrapper>
  )
}
