import { FormGroup, Input } from '@agro-club/agroclub-shared'
import { useTranslation } from 'react-i18next'
import { FC } from 'react'
import { FormikFieldsType } from 'modules/domain/types'

interface Props {
  formik: FormikFieldsType
}

export const FormLastName: FC<Props> = ({ formik }) => {
  const { t } = useTranslation()
  return (
    <FormGroup error={formik.errors.last_name} label={t('common:lastName')} required>
      <Input
        invalid={!!formik.errors.last_name}
        {...formik.getFieldProps('last_name')}
        placeholder={t('common:last_name_placeholder')}
      />
    </FormGroup>
  )
}
