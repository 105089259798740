import { AnalyticsFilterVariant } from 'analytics/types'
import { isFactoringAvailable } from 'env'
import { FactoringStatus } from 'modules/domain/company/types'
import { ListRequestParams } from 'modules/domain/types'
import { useTranslation } from 'react-i18next'
import ASelectFilter from 'views/components/Analytics/ASelectFilter'

interface Props {
  listRequestParams: ListRequestParams
  updateFilterState: any
}

export const FactoringFilter = ({ listRequestParams, updateFilterState }: Props) => {
  const { t } = useTranslation('company')

  if (!isFactoringAvailable) return null

  const options = [
    {
      value: FactoringStatus.accepted,
      label: t('list.filters.withFactoring'),
    },
    {
      value: FactoringStatus.cancelled,
      label: t('list.filters.withoutFactoring'),
    },
    {
      value: FactoringStatus.in_progress,
      label: t('list.filters.inProgress'),
    },
    {
      value: FactoringStatus.not_verified,
      label: t('list.filters.notVerified'),
    },
  ]

  return (
    <ASelectFilter
      onChange={(factoring_status) => updateFilterState({ factoring_status })}
      value={listRequestParams.filter.factoring_status}
      placeholder={t('list.filters.factoring')}
      options={options}
      showSearch={false}
      id={AnalyticsFilterVariant.FACTORING}
    />
  )
}
