import { AntDropdown, Icons } from '@agro-club/agroclub-shared'
import { AddressMenuData } from './types'
import { AButton, ARouterLink } from 'views/components/Analytics'
import { DropdownItemWrapper } from './styled'
import styled from 'styled-components'

interface Props {
  data: AddressMenuData[]
}

const Wrapper = styled.div`
  .ant-dropdown-menu {
    a {
      &:hover {
        text-decoration: none;
      }
    }
  }
`

export const AddressItemMenu = ({ data }: Props) => {
  const items = data.map((menuItem, index) => ({
    key: `menu-item-${index}`,
    label: menuItem.link ? (
      <ARouterLink
        onClick={menuItem?.onClick}
        eventName={menuItem.link.eventName}
        to={menuItem.link.to}
        target="_blank"
        title={menuItem.label}
      >
        <DropdownItemWrapper>
          {menuItem.icon}
          {menuItem.label}
        </DropdownItemWrapper>
      </ARouterLink>
    ) : (
      <DropdownItemWrapper as="a" onClick={menuItem?.onClick}>
        {/* TODO as="a" is temporary hack to make onClick work on the whole block area. Need fix in shared. Need fix */}
        {menuItem.icon}
        {menuItem.label}
      </DropdownItemWrapper>
    ),
  }))

  return (
    <AntDropdown
      items={items}
      CustomOverlayWrapper={Wrapper}
      triggerElement={<AButton id="openDropdown" Icon={Icons.IconMoreHoriz} intent="white" />}
    />
  )
}
