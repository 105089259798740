import { FC } from 'react'

import { SelectFilter, SelectProps, defaultTheme } from '@agro-club/agroclub-shared'
import { useAnalyticsContext } from 'analytics/hooks'
import { AnalyticsFilterVariant } from 'analytics/types'
import { useMediaQuery } from 'react-responsive'
import { MobileFilterContainer } from '../MobileComponents/Filters/MobileFilterWrapper'

const ASelectFilter: FC<SelectProps> = ({ id, value, onChange, placeholder, ...otherProps }) => {
  const { track } = useAnalyticsContext()

  const handleChange = (newValue: any, option: any) => {
    onChange?.(newValue, option)

    const eventName = `change|${id}`

    track(eventName, {
      filter: id as AnalyticsFilterVariant,
      value: {
        from: value,
        to: newValue,
      },
    })
  }

  const isMobile = useMediaQuery({
    query: `(max-width: ${defaultTheme.queries.mobile})`,
  })

  const FilterContainer = isMobile ? MobileFilterContainer : 'div'

  return (
    <FilterContainer label={placeholder}>
      <SelectFilter value={value} onChange={handleChange} data-testid={id} placeholder={placeholder} {...otherProps} />
    </FilterContainer>
  )
}

export default ASelectFilter
