import { parseISO } from 'date-fns'

import { TeamPlan } from 'modules/domain/teams/types'

export const usePlansData = (year: number, data: TeamPlan[]) => {
  const initialData = Array(12)
    .fill(null)
    .map((item, i) => ({
      schedule_date: `${year}-${(i + 1).toString().padStart(2, '0')}-01`,
      monthIndex: i + 1,
      volume: 0,
      completed: 0,
      id: 0,
    }))

  const tableData = initialData.map((item) => {
    const today = new Date()
    const currentMonth = today.getMonth()

    const date = item.schedule_date
    const planItem = data.find((plan) => plan.schedule_date === date)
    const parsedDate = parseISO(date)

    if (!planItem) {
      return {
        ...item,
        isActiveMonth: false,
      }
    }

    return {
      id: planItem.id,
      schedule_date: planItem.schedule_date,
      monthIndex: parsedDate.getMonth(),
      volume: planItem.volume,
      completed: planItem.completed,
      isActiveMonth: parseISO(planItem.schedule_date).getMonth() === currentMonth,
    }
  })

  return tableData
}
