import { useTranslation } from 'react-i18next'

import { ListRequestParams } from 'modules/domain/types'
import { CarrierStatuses } from '../../types'
import { ASelectFilter } from 'views/components/Analytics'
import { AnalyticsFilterVariant } from 'analytics/types'
import { FC } from 'react'

type Props = {
  listRequestParams: ListRequestParams
  updateFilterState: (filterObj: Record<string, string>) => void
}

export const CarrierStatusFilter: FC<Props> = ({ listRequestParams, updateFilterState }) => {
  const { t } = useTranslation('logisticsKR')

  const statusOptions = Object.keys(CarrierStatuses).map((status) => ({
    value: status,
    label: t(`carriers.statuses.${status}`),
  }))

  return (
    <ASelectFilter
      id={AnalyticsFilterVariant.STATUS}
      placeholder={t('cars.filters.status')}
      options={statusOptions}
      value={listRequestParams.filter.status}
      onChange={(status) => updateFilterState({ status })}
      showSearch={false}
    />
  )
}
