import { Checkbox, FormGroup, Progress, Radio, RadioItem } from '@agro-club/agroclub-shared'
import { FormikContextType } from 'formik'
import { CompanyDTO, Prepayment } from 'modules/domain/company/types'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { FormSelectDadata } from 'views/components/FormSelectDadata/FormSelectDadata'
import { SharedModalContent, SharedModalInputs, SharedModalTitle } from 'views/components/SharedModal/styled'
import { NumberInput } from 'views/ui/NumberInput/NumberInput'
import { FormItemWrapper } from 'views/styled/common'
import ASharedModal from 'views/components/SharedModal/ASharedModal'
import { AnalyticsPlaces } from '../../analyticsPlaces'
import ADefaultModalFooter from 'views/components/DefaultModalFooter/ADefaultModalFooter'
import { FC } from 'react'
import { CompanySize } from 'modules/domain/user/types'

type Props = {
  formik: FormikContextType<CompanyDTO>
  progress: Progress
  onClose: () => void
  showCompany?: boolean
}

export const ShortNumberWrapper = styled.div`
  max-width: 94px;
`

export const CompanyRuDetailsModal: FC<Props> = ({ formik, progress, onClose, showCompany }) => {
  const { t } = useTranslation('company')

  return (
    <ASharedModal id={AnalyticsPlaces.DETAILS_MODAL} size="medium" onClose={onClose}>
      <SharedModalTitle>{t('commonInfo')}</SharedModalTitle>
      <SharedModalContent>
        <SharedModalInputs>
          {showCompany && (
            <FormSelectDadata
              error={formik.errors.name || formik.errors.inn}
              onChange={(c) => {
                formik.setValues({ ...formik.values, name: c?.name_short ?? c?.name_full, inn: c?.inn ?? null })
              }}
              required
            />
          )}
          <FormGroup label={t('form.fields.vat')} error={formik.errors.vat}>
            <FormItemWrapper>
              <Radio
                value={formik.values.vat}
                onChange={(val) => {
                  formik.setFieldValue('vat', val)
                }}
              >
                <RadioItem value={true} label={t('common:thereIs')} />
                <RadioItem value={false} label={t('common:no')} />
              </Radio>
            </FormItemWrapper>
          </FormGroup>
          <FormGroup label={t('form.fields.prepayment')} error={formik.errors.prepayment}>
            <FormItemWrapper>
              <Radio
                value={formik.values.prepayment}
                onChange={(val) => {
                  formik.setFieldValue('prepayment', val)
                }}
              >
                <RadioItem value={Prepayment.yes} label={t('form.prepaymentValues.yes')} />
                <RadioItem value={Prepayment.no} label={t('form.prepaymentValues.no')} />
                <RadioItem value={Prepayment.unknown} label={t('form.prepaymentValues.unknown')} />
              </Radio>
            </FormItemWrapper>
          </FormGroup>
          <FormGroup label={t('form.fields.payment_delay_days')} error={formik.errors.payment_delay_days}>
            <ShortNumberWrapper>
              <NumberInput
                isInteger
                onChange={(value) => formik.setFieldValue('payment_delay_days', value || 0)}
                value={formik.values.payment_delay_days || 0}
              />
            </ShortNumberWrapper>
          </FormGroup>
          <FormGroup
            label={t('form.fields.agreementType')}
            error={formik.errors.cooperation_type_agent || formik.errors.cooperation_type_trader}
          >
            <FormItemWrapper>
              <Checkbox
                isChecked={formik.values.cooperation_type_trader}
                onChange={(_value, isChecked) => formik.setFieldValue('cooperation_type_trader', isChecked)}
                label={t('form.fields.cooperation_type_trader')}
              />
              <Checkbox
                isChecked={formik.values.cooperation_type_agent}
                onChange={(_value, isChecked) => formik.setFieldValue('cooperation_type_agent', isChecked)}
                label={t('form.fields.cooperation_type_agent')}
              />
            </FormItemWrapper>
          </FormGroup>
          <FormGroup label={t('typeCompany')} error={formik.errors.company_size}>
            <FormItemWrapper>
              <Radio
                value={formik.values.company_size}
                onChange={(val) => {
                  formik.setFieldValue('company_size', val)
                }}
              >
                <RadioItem value={CompanySize.big_company} label={t('large')} />
                <RadioItem value={CompanySize.small_company} label={t('small')} />
                <RadioItem value={null} label={t('notSpecified')} />
              </Radio>
            </FormItemWrapper>
          </FormGroup>
        </SharedModalInputs>
      </SharedModalContent>

      <ADefaultModalFooter
        onClose={onClose}
        onConfirm={formik.handleSubmit}
        progress={progress}
        confirmButtonText={t('common:save')}
      />
    </ASharedModal>
  )
}
