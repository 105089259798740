import { t } from 'i18next'
import { ListRequestParams } from 'modules/domain/types'
import { endpoints } from 'modules/endpoints'
import { AnalyticsFilterVariant } from 'analytics/types'
import { ABaseTableFilter } from './ABaseTableFilter'
import { FC } from 'react'

interface Props {
  listRequestParams: ListRequestParams
  updateFilterState: any
  endpoint?: string
}

export const TeamsFilter: FC<Props> = ({ listRequestParams, updateFilterState, endpoint }) => {
  const url = endpoint || endpoints.teams()

  const commonProps = {
    onChange: (team) => updateFilterState({ team }),
    value: listRequestParams.filter.team,
    placeholder: t('bid:filterByTeams'),
    getLabel: (label) => label.name,
    endpoint: url,
    showSearch: false,
    multiple: true,
    listHeight: 300, // Removed scrolling for one item
    id: AnalyticsFilterVariant.TEAMS,
  }

  return <ABaseTableFilter {...commonProps} />
}
