import { parseISO } from 'date-fns'

export const getEditableStatus = (schedule_date: string) => {
  const today = new Date()
  const currentYear = today.getFullYear()
  const parsedDate = parseISO(schedule_date)
  const activeYear = parsedDate.getFullYear()
  const isFutureYear = currentYear < activeYear
  const isPastYear = currentYear > activeYear
  const monthIndex = parsedDate.getMonth()
  const currentMonthIndex = today.getMonth()
  let isEditable = false

  if (isPastYear) {
    isEditable = false
  } else if (isFutureYear) {
    isEditable = true
  } else if (monthIndex > currentMonthIndex && currentYear === activeYear) {
    isEditable = true
  }

  return isEditable
}
