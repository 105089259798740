import { Icons, MobileModal, Text, defaultTheme } from '@agro-club/agroclub-shared'
import { Divider } from 'views/layouts/MainLayout/MainLayout'
import { AButton } from 'views/components/Analytics'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { FC, ReactNode } from 'react'

const MobileControl = styled(Icons.IconAdjustments)`
  position: absolute;
  top: 18px;
  right: 18px;
  path {
    fill: ${defaultTheme.color.textPrimary900};
  }
`
const WrapperFilters = styled.div`
  background: ${defaultTheme.color.backgroundPrimary};
  display: flex;
  flex-direction: column;
  gap: 4px 0;
`
const Controls = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px 0;
`

type Props = {
  displayClearBtn: boolean
  handlerModal: () => void
  onReset: () => void
  children: ReactNode
  isOpen: boolean
}

export const MobileFiltersModal: FC<Props> = ({ handlerModal, isOpen, children, displayClearBtn, onReset }) => {
  const { t } = useTranslation()
  return (
    <>
      <MobileControl onClick={handlerModal} />
      <MobileModal
        header={<Text typography="titleH4">{t('common:filters')}</Text>}
        onClose={handlerModal}
        isOpen={isOpen}
        hideClose
      >
        <WrapperFilters>{children}</WrapperFilters>
        <Controls>
          <Divider />
          <AButton id="apply" intent="primary" size="biggest" onClick={handlerModal}>
            {t('common:close')}
          </AButton>
          {displayClearBtn && (
            <AButton id="reset" intent="white" size="biggest" onClick={onReset}>
              {t('common:reset')}
            </AButton>
          )}
        </Controls>
      </MobileModal>
    </>
  )
}
