import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'

import { apiClient } from 'modules/utils/httpClient'
import { endpoints } from 'modules/endpoints'
import { SharedModalContent, SharedModalTitle } from 'views/components/SharedModal'
import { SharedModalInputs } from 'views/components/SharedModal/styled'
import { DriverModalContent } from './DriverModalContent'
import { useAFormHandler } from 'analytics/hooks'
import ASharedModal from 'views/components/SharedModal/ASharedModal'
import ADefaultModalFooter from 'views/components/DefaultModalFooter/ADefaultModalFooter'
import { AnalyticsPlaces } from '../../analyticsPlaces'

interface Props {
  setIsOpen: (val: boolean) => void
  carrierId: string
  onSuccess: (id: number) => void
}

export const DriverAddModal: FC<Props> = ({ setIsOpen, carrierId, onSuccess }) => {
  const { formProgress, formHandler } = useAFormHandler()
  const { t } = useTranslation('logisticsKR')

  const formik = useFormik({
    initialValues: {
      carrier: carrierId,
      name: null,
      phone: null,
    },
    enableReinitialize: true,
    onSubmit: formHandler(async () => await apiClient.post(endpoints.LogisticsKR.drivers(), formik.values), {
      onSuccess: async (requestResult) => {
        await onSuccess(requestResult.id)
        setIsOpen(false)
      },
      onError: (error) => {
        const { errors } = error
        formik.setErrors(errors)
      },
    }),
  })

  return (
    <ASharedModal id={AnalyticsPlaces.CARRIERS.ADD_DRIVER_MODAL} size="medium" onClose={() => setIsOpen(false)}>
      <SharedModalTitle>{t('carsDetails.addForm.title')}</SharedModalTitle>
      <SharedModalContent>
        <SharedModalInputs>
          <DriverModalContent formik={formik} />
        </SharedModalInputs>
      </SharedModalContent>
      <ADefaultModalFooter
        onClose={() => setIsOpen(false)}
        onConfirm={() => formik.submitForm()}
        confirmButtonText={t('common:save')}
        progress={formProgress}
      />
    </ASharedModal>
  )
}
