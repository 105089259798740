// import { Icons } from '@agro-club/agroclub-shared'
import { FC, useState } from 'react'

import { FormikProps } from 'formik'
import { CreateDealFields } from './types'
import { ProfileType, User } from 'modules/domain/user/types'
import { FormSelectActiveUser } from 'views/components/FormSelectUser'
// import { AButton } from 'views/components/Analytics'
import { AddUser } from 'views/pages/User/ModalUser/AddUser'
import { USBid } from 'modules/domain/bid/types'

interface Props {
  formik: FormikProps<CreateDealFields>
  bid: USBid
  setUser: (user) => void
}

export const UserControl: FC<Props> = ({ formik, setUser, bid }) => {
  const [isCreateOpen, setIsCreateOpen] = useState(false)
  // memo filter
  const [filter] = useState({ with_company: true })

  const counterPartProfile =
    bid.owner.profile?.profile_type === ProfileType.seller ? ProfileType.customer : ProfileType.seller
  const onUserCreateSuccess = (user: User) => {
    formik.setFieldValue('user', user.id)
  }
  // temp hide add btn
  return (
    <>
      <FormSelectActiveUser
        onSelectedOptionLoad={(option) => setUser(option.dto)}
        currentProfileType={counterPartProfile}
        formik={formik}
        filter={filter}
      >
        {/* <AButton id="addUser" intent="approve" size="big" Icon={Icons.IconPlus} onClick={() => setIsCreateOpen(true)} /> */}
      </FormSelectActiveUser>
      {isCreateOpen && <AddUser onSuccess={onUserCreateSuccess} onClose={() => setIsCreateOpen(false)} />}
    </>
  )
}
