import { Typography, defaultTheme } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

type TableInputProps = {
  error?: boolean
  visible?: boolean
}

export const TableInput = styled.input<TableInputProps>`
  outline: none;
  width: 100%;
  height: 32px;
  padding: 5px 12px 5px 12px;
  border: 1px solid;
  border-color: ${({ error }) =>
    error ? `${defaultTheme.color.accentDestruct600}` : `${defaultTheme.color.secondary100}`};
  border-radius: 4px;
  ${Typography.bodyLarge}
  &:focus {
    border-color: ${({ error }) =>
      error ? `${defaultTheme.color.accentDestruct600}` : `${defaultTheme.color.primary500}`};
  }
  transition: border-color 0.3s ease;
  visibility: ${({ visible = true }) => (visible ? 'visible' : 'hidden')};
`
