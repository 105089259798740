import { AntdBreadcrumbs, defaultTheme, Typography } from '@agro-club/agroclub-shared'
import styled from 'styled-components'
import { mobileMenuHeight } from 'views/styled/common'

export const WrapperContent = styled.div`
  display: flex;
  flex-direction: column;
  background: ${defaultTheme.color.backgroundPrimary};
  width: 100%;
  height: 100dvh;
  padding: 24px 0;
  @media (max-width: ${defaultTheme.queries.mobile}) {
    height: calc(100dvh - ${mobileMenuHeight});
    padding: 16px 0 0 0;
  }

  .phone-link-table {
    ${Typography.bodyRegular}
    &:hover {
      padding-top: -2px;
      text-decoration: underline;
      color: ${({ theme }) => theme.color.primary600};
    }
  }
`

/* So far, the width of the first column is 1014.
When we redesign the menu for the new design, we will need to make the column width 1038
 */
export const ContentTwoColumns = styled.div`
  display: grid;
  height: calc(100dvh - 130px);
  grid-template-columns: 1038px 566px;
  gap: 0 24px;
  overflow: auto;
`
export const ContentOneColumn = styled.div`
  height: calc(100dvh - 130px);
  overflow: auto;
`

export const WrapperContentFlex = styled(WrapperContent)`
  display: flex;
  flex-direction: column;
`

export const Header = styled.div`
  width: 100%;
`
export const Breadcrumbs = styled(AntdBreadcrumbs)`
  margin-bottom: 8px;

  &.ant-breadcrumb {
    ${Typography.bodyMedium}
    cursor: pointer;
  }
`
export const TopHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: -4px;

  &:first-child {
    align-items: flex-start;
  }
`
export const HeaderWrapBtns = styled.div`
  display: flex;
  gap: 0 16px;
`

export const PageTitleWrapper = styled.div`
  margin-bottom: 16px;
`

export const PageTitleWithTagWrapper = styled(PageTitleWrapper)`
  display: flex;
  align-items: center;
  gap: 8px;
`

export const PageName = styled(PageTitleWrapper)`
  ${Typography.titleH2}
  @media (max-width: ${defaultTheme.queries.mobile}) {
    ${Typography.titleH4}
    text-align: center;
  }
  color: ${defaultTheme.color.secondary900};
`

export const DetailPageSubTitle = styled.div`
  ${Typography.bodyLarge};
  color: ${defaultTheme.color.textPrimary900};
  margin-bottom: 16px;
`

export const DetailPageName = styled(PageTitleWrapper)`
  ${Typography.titleH3}
  color: ${defaultTheme.color.secondary900};
`
