import ADefaultModalFooter from 'views/components/DefaultModalFooter/ADefaultModalFooter'
import { ASharedModal, SharedModalTitle } from 'views/components/SharedModal'
import { CommonFields, ContractFormFields } from './CommonFields'
import { refetchFunc } from 'modules/domain/common/hooks'
import { BidContract } from 'modules/domain/bid/types'
import { apiClient } from 'modules/utils/httpClient'
import { useAFormHandler } from 'analytics/hooks'
import { useTranslation } from 'react-i18next'
import { endpoints } from 'modules/endpoints'
import { useFormik } from 'formik'
import { FC } from 'react'

type Props = {
  refetch: refetchFunc
  onClose: () => void
  contract: BidContract
}

export const EditContractModal: FC<Props> = ({ onClose, contract, refetch }) => {
  const { t } = useTranslation('bid')
  const { formProgress, formHandler } = useAFormHandler('editContract')

  const onSuccess = async () => {
    await refetch()
    onClose()
  }

  const formik = useFormik<ContractFormFields>({
    initialValues: {
      shipment_from_date: contract.shipment_from_date,
      shipment_due_date: contract.shipment_due_date,
      payment_due_date: contract.payment_due_date,
      signed_at: contract.signed_at,
      quantity: contract.quantity,
      bid_id: contract.bid_id,
      number: contract.number,
      price: contract.price,
      payment_type: contract.payment_type,
      payment_hour_period: contract.payment_hour_period,
      payment_volume_period: contract.payment_volume_period,
    },
    onSubmit: formHandler(
      async () => {
        await apiClient.put(endpoints.contracts(contract.id), formik.values)
      },
      {
        onSuccess,
        onError: (error) => {
          formik.setErrors(error.errors)
        },
      },
    ),
  })
  return (
    <ASharedModal size="small" onClose={onClose} id="editContract">
      <SharedModalTitle>{t('contracts.editContract')}</SharedModalTitle>
      <CommonFields formik={formik} bid={contract} />
      <ADefaultModalFooter
        onConfirm={() => formik.submitForm()}
        confirmButtonText={t('common:save')}
        progress={formProgress}
        onClose={onClose}
      />
    </ASharedModal>
  )
}
