import { AntTag } from '@agro-club/agroclub-shared'
import { IconContracts } from 'views/pages/Deal/Board/styled'
import { Deal } from 'modules/domain/deal/types'
import { useTranslation } from 'react-i18next'

type Props = {
  deal: Deal
}

export const ContractTag = ({ deal }: Props) => {
  const { t } = useTranslation('contracts')

  return (
    <AntTag
      color={deal.has_sale_contract_request ? 'blue' : 'grey'}
      icon={<IconContracts $hasSaleContractRequest={deal.has_sale_contract_request} />}
    >
      {t('contract')}
    </AntTag>
  )
}
