import styled from 'styled-components'
import { defaultTheme, Typography } from '@agro-club/agroclub-shared'
import { TableTextLink } from 'views/ui/TableTextLink/TableTextLink'

export const RatingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 13px;
`

export const RatingList = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
`

export const RatingItem = styled.div<{ smallRating?: boolean }>`
  display: flex;
  column-gap: ${({ smallRating }) => (smallRating ? '4px' : '8px')};
  align-items: center;

  .ant-rate {
    display: flex;
    align-items: center;
    font-size: ${({ smallRating }) => (smallRating ? '14px' : '22px')};
  }
  .ant-rate-star {
    &:not(:last-child) {
      margin-right: ${({ smallRating }) => (smallRating ? '3px' : '6px')};
    }
  }
`

export const CommonRatingItemText = styled.div`
  ${Typography.accentLarge}
  color: ${defaultTheme.color.textPrimary900};
`

export const TableRatingText = styled.div`
  ${Typography.bodyLarge}
  color: ${defaultTheme.color.textPrimary900};
`

export const RatingItemText = styled.div`
  ${Typography.bodyMedium}
  color: ${defaultTheme.color.textPrimary900};
`

export const TableLink = styled(TableTextLink)`
  ${Typography.bodyLarge}
`
