import styled, { css } from 'styled-components'
import { defaultTheme, Typography } from '@agro-club/agroclub-shared'
import { FC, MouseEventHandler, ReactNode } from 'react'
import { ButtonProps } from 'antd'
import { Link, LinkProps } from 'react-router-dom'

const buttonStyles = css`
  ${Typography.buttonLarge}
  display: flex;
  column-gap: 8px;
  align-items: center;
  height: 40px;
  color: ${defaultTheme.color.textPrimary900};
  cursor: pointer;
  padding-left: 4px;

  &:hover {
    background-color: ${defaultTheme.color.secondary8a};
    border-radius: 4px;
  }

  svg {
    width: 20px;
    height: 20px;

    path {
      fill: ${defaultTheme.color.textPrimary900};
    }
  }
`

const Button = styled.div`
  ${buttonStyles}
`

export const ButtonLink = styled(Link)`
  ${buttonStyles}
`

export interface PopoverButtonProps extends ButtonProps {
  Icon?: ReactNode
  text: ReactNode
  onClick?: MouseEventHandler<HTMLElement>
  to?: LinkProps['to']
}

export const PopoverButton: FC<PopoverButtonProps> = ({ text, Icon, onClick, to, ...otherProps }) => {
  if (to) {
    return (
      <ButtonLink to={to} onClick={onClick} {...otherProps}>
        {Icon}
        <div>{text}</div>
      </ButtonLink>
    )
  }
  return (
    <Button onClick={onClick} {...otherProps}>
      {Icon}
      <div>{text}</div>
    </Button>
  )
}
