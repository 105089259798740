import { Route, Routes } from 'react-router-dom'

import { CarsList } from './CarsList/CarsList'
import { FC } from 'react'

const CarsKRPage: FC = () => (
  <Routes>
    <Route index element={<CarsList />} />
  </Routes>
)
export default CarsKRPage
