const main = 'calculator'

const relativeRoutes = {
  relativePage: `${main}/*`,
  relativeList: `${main}`,
}

const routes = {
  List: `/${main}`,
}

const CalculatorRoutes = { ...relativeRoutes, ...routes }

export default CalculatorRoutes
