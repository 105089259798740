import formatPrice, { labelPrice } from 'modules/utils/numbers/formatPrice'
import { CompanyLink } from 'views/components/CompanyLink/CompanyLink'
import { TableTextLink } from 'views/ui/TableTextLink/TableTextLink'
import { labelQuantity } from 'modules/utils/numbers/formatQuantity'
import { CellRenders } from 'views/components/Table/renderers'
import { ExportDealType } from 'modules/domain/export/types'
import { ColumnsType } from '@agro-club/agroclub-shared'
import DealRoutes from 'views/pages/Deal/routes'
import UserRoutes from 'views/pages/User/routes'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router'
import styled from 'styled-components'
import { useMemo } from 'react'

const Links = styled.div`
  display: flex;
  flex-direction: column;
`

const useColumns = (): ColumnsType<ExportDealType> => {
  const { t } = useTranslation('export')

  return useMemo(
    () => [
      {
        title: t('dealSellerCompany'),
        dataIndex: 'id',
        width: 358,
        render: (_, row) => (
          <Links>
            <TableTextLink id="deal" tableItemId={row.id} to={generatePath(DealRoutes.Details, { id: row.id })}>
              {`ID ${row.id}`}
            </TableTextLink>
            <TableTextLink
              id="deal"
              tableItemId={row.owner.id}
              to={generatePath(UserRoutes.Details, { id: row.owner.id })}
            >
              {row.owner.full_name}
            </TableTextLink>
            <CompanyLink bodysize="bodyMedium" company={row.company} />
          </Links>
        ),
      },

      {
        title: labelQuantity(t('volume')),
        dataIndex: 'quantity',
        align: 'right',
        width: 82,
        render: CellRenders.number,
      },
      {
        title: labelQuantity(t('uploaded')),
        dataIndex: 'loaded_quantity',
        align: 'right',
        width: 104,
        render: CellRenders.number,
      },
      {
        title: labelQuantity(t('delivered')),
        dataIndex: 'delivered_quantity',
        align: 'right',
        width: 90,
        render: CellRenders.number,
      },
      {
        title: labelQuantity(t('remaining')),
        dataIndex: 'remaining_quantity',
        align: 'right',
        width: 98,
        render: CellRenders.number,
      },
      {
        title: labelPrice(t('price')),
        dataIndex: 'price_exw',
        align: 'right',
        width: 74,
        render: (price_exw) => formatPrice(price_exw),
      },
      {
        title: labelPrice(t('priceWithLogistic')),
        dataIndex: 'price_with_logistics',
        align: 'right',
        width: 134,
        render: CellRenders.price,
      },
    ],
    [t],
  )
}

export default useColumns
