import ADefaultModalFooter from 'views/components/DefaultModalFooter/ADefaultModalFooter'
import { SharedModalContent, SharedModalTitle } from 'views/components/SharedModal'
import { formatQuantity } from 'modules/utils/numbers/formatQuantity'
import ASharedModal from 'views/components/SharedModal/ASharedModal'
import { BidContract, BidStatus } from 'modules/domain/bid/types'
import { refetchFunc } from 'modules/domain/common/hooks'
import { apiClient } from 'modules/utils/httpClient'
import { AButton } from 'views/components/Analytics'
import { useAProgress } from 'hooks/useAProgress'
import { Text } from '@agro-club/agroclub-shared'
import { useTranslation } from 'react-i18next'
import { endpoints } from 'modules/endpoints'
import { useState, FC } from 'react'

type Props = {
  contract: BidContract
  refetch: refetchFunc
}

export const CompleteContractBtn: FC<Props> = ({ contract, refetch }) => {
  const { t } = useTranslation('bidContracts')
  const { id } = contract

  const [isConfirmVisible, setConfirmVisible] = useState<boolean>(false)

  const onSuccess = async () => {
    await refetch()
    setConfirmVisible(false)
  }

  const [progress, completeContract] = useAProgress(
    () => apiClient.post(endpoints.changeStatusContract(id), { new_status: BidStatus.completed }),
    {
      eventName: 'completeContract',
      onSuccess,
    },
  )
  const isRemainingQuantity = contract.remaining_quantity > 0
  return (
    <>
      <AButton
        intent="secondary"
        size="medium"
        onClick={() => {
          setConfirmVisible(true)
        }}
        id="complete"
        analyticsPayload={{ id }}
      >
        {t('complete')}
      </AButton>

      {isConfirmVisible && (
        <ASharedModal id="completeModal" onClose={() => setConfirmVisible(false)} size="small">
          <SharedModalTitle>{t('changeStatusHeader')}</SharedModalTitle>
          <SharedModalContent>
            {isRemainingQuantity && (
              <Text typography="accentLarge">
                {`${t('remainingQuantityText')} ${formatQuantity(contract.remaining_quantity)}`}
              </Text>
            )}
            <Text>{t('confirmationText')}</Text>
          </SharedModalContent>
          <ADefaultModalFooter
            onClose={() => setConfirmVisible(false)}
            confirmButtonText={t('complete')}
            onConfirm={completeContract}
            progress={progress}
          />
        </ASharedModal>
      )}
    </>
  )
}
