import { FC, useEffect, useState } from 'react'

import { useTableData } from 'modules/domain/common/hooks'
import { endpoints } from 'modules/endpoints'
import { defaultListRequestParams } from 'views/components/TableFilters/data'
import { useTranslation } from 'react-i18next'
import { CarStatuses, LogisticsKRCar } from 'views/pages/LogisticsKR/types'
import { Carrier } from 'modules/domain/logisticsKR/types'
import { AntCollapse } from '@agro-club/agroclub-shared'
import { Cars } from './Cars'
import { MultipleColumnWrapper } from '../CarrierBoard'

type CarItems = {
  active: LogisticsKRCar[]
  draft: LogisticsKRCar[]
  archived: LogisticsKRCar[]
}

type Props = {
  carrier: Carrier
  onSelectedChanged: (carId: string) => void
  activeCarId: string
}

export const CarsColumn: FC<Props> = ({ carrier, onSelectedChanged, activeCarId }) => {
  const { t } = useTranslation('logisticsKR')
  const [listRequestParams] = useState({
    ...defaultListRequestParams,
    filter: { carrier: carrier.id },
    pageSize: 1000, // TODO fix this
  })
  const { progress, data: cars, refetch } = useTableData(endpoints.LogisticsKR.car(), listRequestParams, !!carrier)
  const [items, setItems] = useState<CarItems>({ active: [], draft: [], archived: [] })

  useEffect(() => {
    if (!cars) return
    const trasformed: CarItems = {
      active: cars.filter((c) => c.status === CarStatuses.active),
      draft: cars.filter((c) => c.status === CarStatuses.draft),
      archived: cars.filter((c) => c.status === CarStatuses.archived),
    }
    if (trasformed.active.length) {
      onSelectedChanged(trasformed.active[0].id)
    }
    setItems(trasformed)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cars])

  return (
    <MultipleColumnWrapper collapseAmount={3}>
      <AntCollapse accordion noMarginTop bordered={false} defaultActiveKey={[CarStatuses.active]}>
        <Cars
          cars={items.active}
          refetch={refetch}
          onSelectedChanged={onSelectedChanged}
          activeCarId={activeCarId}
          key={CarStatuses.active}
          title={t('carriers.cars_column.cars')}
          counter={items.active.length}
          progress={progress}
        />
        <Cars
          cars={items.draft}
          refetch={refetch}
          onSelectedChanged={onSelectedChanged}
          activeCarId={activeCarId}
          key={CarStatuses.draft}
          title={t('carriers.cars_column.draft')}
          counter={items.draft.length}
          progress={progress}
          tagColor="grey"
        />
        <Cars
          cars={items.archived}
          refetch={refetch}
          onSelectedChanged={onSelectedChanged}
          activeCarId={activeCarId}
          key={CarStatuses.archived}
          title={t('carriers.cars_column.archived_cars')}
          counter={items.archived.length}
          progress={progress}
          tagColor="grey"
        />
      </AntCollapse>
    </MultipleColumnWrapper>
  )
}
