import { AntTagColor, AntTag } from '@agro-club/agroclub-shared'
import { useTranslation } from 'react-i18next'

export const MarginTag = ({ value }) => {
  const { t } = useTranslation('dashboard')
  if (value === undefined) {
    return null
  }
  const color: AntTagColor = value > 0 ? 'green' : 'red'
  return <AntTag color={color}>{t('margin', { value })}</AntTag>
}
