import _ from 'lodash'
import { RateDTO } from './Rates/types'
import { isBrazil } from 'env'
import { DASH } from 'modules/constants'

export const removeSeconds = (time?: string | null) => {
  if (time) {
    const timeArr = time.split(':')
    if (timeArr?.length === 3) {
      timeArr.pop()
      return timeArr.join(':')
    }
    return time
  }
  return DASH
}

export const generateRanges = (start: number, end: number, step: number, skipFirst?: boolean) => {
  const resultArray = Array(Math.ceil((end - start) / step))
    .fill(start)
    .map((item, index) => {
      const result = item + index * step
      const nextResult = item + (index + 1) * step

      return {
        lower: index > 0 || skipFirst ? result + 1 : result,
        upper: nextResult,
      }
    })

  return resultArray
}

export const getLogisticsRanges = () => {
  if (isBrazil) {
    return [...generateRanges(0, 1500, 100), ...generateRanges(1500, 3500, 250, true)]
  }

  return [...generateRanges(0, 1000, 50), ...generateRanges(1000, 1500, 100, true)]
}

export const prepareMultipleEditing = (selectedRows: RateDTO[]) => {
  const longestRatesArray = selectedRows
    .map((row) => row.logistics_rates.filter((el) => el?.rate ?? 0 > 0))
    .sort((a, b) => b.length - a.length)[0]

  const preparedLogisticians = _.flatten(selectedRows?.map((row) => row?.responsible_logisticians))
  const selectedRowIds = selectedRows.map((row) => row?.id)
  const responsible_logisticians = _.unionBy(preparedLogisticians, 'id')

  return {
    id: selectedRowIds,
    logistic_rates: longestRatesArray,
    responsible_logisticians,
  }
}
