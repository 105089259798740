import { SVGIcon } from '@agro-club/agroclub-shared/dist/cjs/ui/buttons/Buttons/types'
import { Text, defaultTheme } from '@agro-club/agroclub-shared'
import styled from 'styled-components'
import { FC } from 'react'

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const WrapperIcon = styled.div`
  width: 44px;
  height: 44px;
  svg {
    width: inherit;
    height: inherit;
    fill: ${defaultTheme.color.secondaryPlaceholder3};
    path {
      fill: ${defaultTheme.color.secondaryPlaceholder3};
    }
  }
`
const StyledText = styled(Text)`
  color: ${defaultTheme.color.secondaryPlaceholder3};
`
export const NoDataScreen: FC<{ Icon?: SVGIcon; title: string }> = ({ Icon, title }) => {
  return (
    <Wrapper>
      {Icon && (
        <WrapperIcon>
          <Icon />
        </WrapperIcon>
      )}
      <StyledText typography="titleH4" color="secondary">
        {title}
      </StyledText>
    </Wrapper>
  )
}
