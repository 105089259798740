import { FormikContextType } from 'formik'
import { ExecutorsForm } from 'views/pages/Deal/DealDetail/types'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import FormSelectShared from 'views/components/FormSelectShared'
import { endpoints } from 'modules/endpoints'

interface ExecutorItemProps {
  formik: FormikContextType<ExecutorsForm>
  multiple?: boolean
  value: string
  type: string
}

export const ExecutorItem: FC<ExecutorItemProps> = ({ type, value, formik, multiple }) => {
  const { t } = useTranslation('executors')
  return (
    <FormSelectShared
      onClear={() => formik.setFieldValue(value, null)}
      endpoint={endpoints.executorsByType(type)}
      getLabel={(dto) => dto.full_name}
      searchField="search"
      multiple={multiple}
      fieldName={value}
      label={t(value)}
      formik={formik}
      placeholder={t('fieldsLabels:select_assignee')}
    />
  )
}
