import { FC } from 'react'
import { SharedModalContent, SharedModalInputs } from 'views/components/SharedModal'
import { ShortFormControl } from 'views/styled/common'
import { FormGroup, Input, defaultTheme, Progress } from '@agro-club/agroclub-shared'
import { FormGroupUpload } from 'views/components/FormGroupUpload/FormGroupUpload'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import env from '../../../../../../../env'
import { FormikFieldsType, RespFile } from 'modules/domain/types'
import ADefaultModalFooter from 'views/components/DefaultModalFooter/ADefaultModalFooter'

const Description = styled.div`
  width: calc(100% - 20px);
  padding: 16px;
  border-radius: 8px;
  background-color: ${defaultTheme.color.accentNotify16a};
`

type Props = {
  formik: FormikFieldsType
  progress: Progress
  onClose: (arg: boolean) => void
  files?: RespFile[]
}

export const ModalContent: FC<Props> = ({ formik, progress, onClose, files }) => {
  const { t } = useTranslation('deal')
  const currency = env.REACT_APP_CURRENCY_SYMBOL

  return (
    <SharedModalContent>
      <SharedModalInputs>
        <Description>{t('accordion.dealFiles.spec_modal_description')}</Description>
        <ShortFormControl>
          <FormGroup error={formik.errors.price} label={t('common:price:price_exw', { currency })} required>
            <Input {...formik.getFieldProps('price')} invalid={!!formik.errors.price} type="number" />
          </FormGroup>
        </ShortFormControl>
        <FormGroupUpload
          files={files}
          label={t('common:files')}
          formik={formik}
          fieldName="files"
          maxWidthFileUpload={411}
        />
        <ADefaultModalFooter
          progress={progress}
          onClose={() => onClose(false)}
          confirmButtonText={t('common:save')}
          onConfirm={() => formik.submitForm()}
        />
      </SharedModalInputs>
    </SharedModalContent>
  )
}
