import { Card } from 'views/components/Card/Card'
import { KeyValue } from 'views/components/KeyValue/KeyValue'
import { PricePeriodsCardItems } from 'views/pages/Bid/components/Futures'
import { formatTarif } from 'modules/utils/numbers/formatTarif'
import { formatQuantity } from 'modules/utils/numbers/formatQuantity'
import { isBidContractsEnabled } from 'env'
import { Bid, BidSources } from 'modules/domain/bid/types'
import { getPriceTypeLabel, isBidWithPricePeriods } from 'modules/domain/bid/utils'
import { useTranslation } from 'react-i18next'
import { BidEditModal } from '../../BidModals/Bid/BidEditModal'
import { useState } from 'react'
import { refetchFunc } from 'modules/domain/common/hooks'

type Props = {
  bid: Bid
  refreshBid: refetchFunc
}

export const EditableDetails = ({ bid, refreshBid }: Props) => {
  const { t } = useTranslation(['bid', 'common'])
  const priceLabel = getPriceTypeLabel(bid.price_type, t)
  const isPricePeriodsBid = isBidWithPricePeriods(bid)
  const [isOpen, setIsOpen] = useState<boolean>(false)

  return (
    <Card.Container bordered>
      <Card.GapableContent>
        {bid.season && (
          <Card.Grid>
            <KeyValue label={t('season')}>{bid.season.name}</KeyValue>
          </Card.Grid>
        )}
        {isPricePeriodsBid ? (
          <PricePeriodsCardItems bid={bid} />
        ) : (
          <Card.Grid>
            <KeyValue label={priceLabel}>{formatTarif(bid.price)}</KeyValue>
          </Card.Grid>
        )}
        <Card.Grid>
          <KeyValue label={t('common:quantity')}>{formatQuantity(bid.quantity)}</KeyValue>
        </Card.Grid>
        {isBidContractsEnabled && (
          <Card.Grid>
            <KeyValue label={t('common:residualVolume')}>{formatQuantity(bid.remaining_quantity)}</KeyValue>
          </Card.Grid>
        )}
        {!!bid.parameters.length && (
          <Card.Grid>
            <KeyValue label={t('form.cropsParams')}>
              {bid.parameters.map((p) => {
                return <div key={p.id}>{p.description}</div>
              })}
            </KeyValue>
          </Card.Grid>
        )}
      </Card.GapableContent>

      {bid.source !== BidSources.BARCHART && <Card.EditButton id="editParameters" onClick={() => setIsOpen(true)} />}
      {isOpen && <BidEditModal bid={bid} onSuccess={refreshBid} onClose={() => setIsOpen(false)} />}
    </Card.Container>
  )
}
