import { CompanyControlsProps } from 'views/pages/LogisticsKR/CarriersKR/AddCarrierModal/types'
import { OptionType } from '@agro-club/agroclub-shared'
import { FormSelectDadata } from 'views/components/FormSelectDadata/FormSelectDadata'

export const RuCompanyControl = ({ formik }: CompanyControlsProps) => {
  const daDataInitialValues = formik.values.inn
    ? ({
        value: formik.values.inn,
        label: formik.values.name,
      } as OptionType)
    : undefined
  return (
    <>
      <FormSelectDadata
        initialValue={daDataInitialValues}
        error={formik.errors?.name || formik.errors?.inn}
        onChange={(c) =>
          formik.setValues({ ...formik.values, name: c?.name_full ?? c?.name_short ?? null, inn: c?.inn ?? null })
        }
        required
      />
    </>
  )
}
