import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { AntTag } from '@agro-club/agroclub-shared'
import { Bid } from 'modules/domain/bid/types'
import { containsFilledDatePeriod } from 'views/components/DatePeriodFormatted'
import { isBidWithPricePeriods } from 'modules/domain/bid/utils'
import { isBrazil } from 'env'

export const BidFuturesTag: FC<{ bid?: Bid }> = ({ bid }) => {
  const { t } = useTranslation('bid')
  const label = t('kind.futures')

  if (isBrazil && bid && (isBidWithPricePeriods(bid) || containsFilledDatePeriod(bid))) {
    return <AntTag color="purple"> {label} </AntTag>
  }

  return null
}
