import { Typography } from '@agro-club/agroclub-shared'
import styled from 'styled-components'
import { smallBox } from 'views/styled/common'

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
`

type HeaderProps = { size: 'big' | 'small' }
export const Header = styled.div<HeaderProps>`
  display: flex;
  align-items: center;
  height: ${({ size }) => (size === 'small' ? '48px' : '68px')};
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 1;
  margin-bottom: 22px;
  padding: 12px 12px 14px 12px;
  ${smallBox};
  ${Typography.accentLarge};
`

export const Item = styled.div`
  position: relative;
  transition: background-color 0.1s;
  width: 100%;
  margin-bottom: 16px;
  padding: 10px 28px 10px 12px;
  ${smallBox}
`

export const Counter = styled.span`
  padding: 0 6px;
  margin-left: 4px;
  color: ${({ theme }) => theme.color.backgroundSecondary};
  background-color: ${({ theme }) => theme.color.accentDestruct600};
  border-radius: 10px;
  ${Typography.accentSmall}
`
