import { CardRow, LinkWrapper } from '../styled'
import AExternalLink from 'views/components/Analytics/AExternalLink'
import { ChangeFactoringStatus } from './ChangeFactoringStatus'

import { refetchFunc } from 'modules/domain/common/hooks'
import { FactoringRequest, FactoringStatus, FactoringTypes } from 'modules/domain/company/types'
import { TruncatedValue } from 'views/components/TruncatedValue/TruncatedValue'
import { AntTag } from '@agro-club/agroclub-shared'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

interface Props {
  factoringRequest: FactoringRequest
  refetchCompany: refetchFunc
}

const TagsWrapper = styled.div`
  display: flex;
  gap: 8px;
`

export const DetailedFactoringInfo = ({ factoringRequest, refetchCompany }: Props) => {
  const { t } = useTranslation('company')

  const doc = factoringRequest.document
  const pdf = factoringRequest.document_pdf
  const tagColor = factoringRequest.status === FactoringStatus.accepted ? 'green' : 'white'
  if (factoringRequest.factoring_type === FactoringTypes.customer) {
    t('factoringCustomerDocInfo')
  }

  return (
    <>
      <TagsWrapper>
        <AntTag color="white">{t(`factoringTypes.${factoringRequest.factoring_type}`)}</AntTag>
        <AntTag color={tagColor}>{t(`factoringDetailedStatuses.${factoringRequest.status}`)}</AntTag>
      </TagsWrapper>
      <CardRow>
        {factoringRequest.factoring_type === FactoringTypes.customer ? (
          t('factoringCustomerDocInfo')
        ) : (
          <div style={{ display: 'flex', flexDirection: 'column', gap: '6px' }}>
            <LinkWrapper>
              <AExternalLink href={doc?.uploaded_file} target="_blank" eventName="viewFactoringFile">
                <TruncatedValue hint={doc?.original_filename}>{doc?.original_filename}</TruncatedValue>
              </AExternalLink>
            </LinkWrapper>
            <LinkWrapper>
              <AExternalLink href={pdf?.uploaded_file} target="_blank" eventName="viewFactoringFilePdf">
                <TruncatedValue hint={pdf?.original_filename}>{pdf?.original_filename}</TruncatedValue>
              </AExternalLink>
            </LinkWrapper>
          </div>
        )}
        <ChangeFactoringStatus factoringRequest={factoringRequest} refetchCompany={refetchCompany} />
      </CardRow>
    </>
  )
}
