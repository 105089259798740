import { Route, Routes } from 'react-router-dom'
import CallStatisticsList from 'views/pages/CallStatistics/CallStatisticsList'

const CallStatisticsPage = () => (
  <Routes>
    <Route index element={<CallStatisticsList />} />
  </Routes>
)

export default CallStatisticsPage
