import { FC } from 'react'
import { Route, Routes } from 'react-router'
import DispatchingList from './DispatchingList'
import DispatchingRoutes from './routes'

const DispatchingPage: FC = () => {
  return (
    <Routes>
      <Route index element={<DispatchingList />} />
      <Route path={DispatchingRoutes.relativeListWithTab} element={<DispatchingList />} />
    </Routes>
  )
}

export default DispatchingPage
