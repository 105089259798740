import styled from 'styled-components'
import { defaultTheme, Typography } from '@agro-club/agroclub-shared'

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
`

export const InfoSection = styled.div<{ rowGap?: number }>`
  display: flex;
  flex-direction: column;
  row-gap: ${({ rowGap }) => (rowGap ? `${rowGap}px` : '4px')};
`

export const InfoRow = styled.div`
  display: flex;
  align-items: center;
  column-gap: 32px;
`

export const InfoSectionTitle = styled.div`
  ${Typography.bodyLarge}
  color: ${defaultTheme.color.textSecondary60a};
`

export const InfoSectionText = styled.div`
  ${Typography.bodyLarge}
  color: ${defaultTheme.color.textPrimary900};
`

export const Title = styled.div`
  ${Typography.accentLarge}
  color: ${defaultTheme.color.textPrimary900};
`

export const Text = styled.div`
  margin: 0;
  ${Typography.bodyLarge}
  color: ${defaultTheme.color.textPrimary900};
`

export const AddressInfoWrapper = styled.div`
  max-width: 462px;
`

export const EditButtonWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`
