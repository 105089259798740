import { defaultTheme } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

export const InfoBoard = styled.div`
  border-radius: 8px;
  background: ${defaultTheme.color.backgroundPrimary};
  padding: 7px 16px;
  display: flex;
  flex-direction: column;
  gap: 4px 0;
  max-width: 616px;
`
export const StatBoard = styled.div`
  display: flex;
  justify-content: space-between;
`
