import { FC, useMemo } from 'react'
import { Bid } from 'modules/domain/bid/types'
import { isSaleBid } from 'modules/domain/bid/utils'
import { useBidColumns } from './useBidColumns'
import { AntdTable, Progress } from '@agro-club/agroclub-shared'
import { ColumnData } from 'modules/types'

interface NestedPricePeriodsProps {
  bid: Bid
}

const NestedPricePeriods: FC<NestedPricePeriodsProps> = ({ bid }) => {
  const columnDef = useBidColumns()
  const isSale = isSaleBid(bid)

  const columns = useMemo(
    () =>
      [
        columnDef.price_period,
        columnDef.quantity,
        columnDef.price_exw,
        isSale && columnDef.tax_from_exw,
        columnDef.price_cpt,
        columnDef.empty,
      ]
        .filter(Boolean)
        .map((def) => ({ ...def, sorter: false })) as ColumnData[],
    [columnDef, isSale],
  )

  return (
    <AntdTable
      columns={columns}
      showHeader
      data={bid.price_periods ?? []}
      progress={Progress.SUCCESS}
      rowKey={'id'}
      disablePagination
    />
  )
}

export default NestedPricePeriods
