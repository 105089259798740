import { RouterLink, BodySizes } from '@agro-club/agroclub-shared'
import { useAnalyticsClickHandler } from 'analytics/hooks'
import { LinkProps } from 'react-router-dom'
import React from 'react'

interface Props extends Omit<LinkProps, 'id'> {
  eventName: string
  itemId?: string
  bodysize?: BodySizes
}

const ARouterLink = React.forwardRef<LinkProps['itemRef'], Props>(
  ({ eventName, itemId, children, onClick, ...otherProps }, ref) => {
    const handleClick = useAnalyticsClickHandler(eventName)

    return (
      <RouterLink onClick={handleClick(onClick, { id: itemId })} {...otherProps} itemRef={ref as any}>
        {children}
      </RouterLink>
    )
  },
)

export default ARouterLink
