import { ResponsiveModal } from 'views/components/ResponsiveModal/ResponsiveModal'
import { SharedModalContent } from 'views/components/SharedModal'
import { refetchFunc } from 'modules/domain/common/hooks'
import { apiClient } from 'modules/utils/httpClient'
import { AButton } from 'views/components/Analytics'
import { Icons } from '@agro-club/agroclub-shared'
import { useAProgress } from 'hooks/useAProgress'
import { useTranslation } from 'react-i18next'
import { endpoints } from 'modules/endpoints'
import { useState } from 'react'

type Props = {
  contractId: string
  onSuccess: refetchFunc
}

export const RemoveContract = ({ contractId, onSuccess }: Props) => {
  const { t } = useTranslation('deal')
  const [removeModal, setRemoveModal] = useState(false)
  const [progress, removeHandler] = useAProgress(
    (contractId) => apiClient.delete(endpoints.dealsContracts(contractId)),
    {
      eventName: 'deleteDealContractRequest',
      analyticsPayload: { id: contractId },
      onSuccess,
    },
  )

  return (
    <>
      <AButton
        onClick={() => setRemoveModal(true)}
        id="removeDealContractRequest"
        Icon={Icons.IconCancelled}
        intent="white"
      />
      {removeModal && (
        <ResponsiveModal
          title={t('requestContractBlock.remove_modal_title')}
          onConfirm={() => removeHandler(contractId)}
          confirmButtonText={t('common:confirm')}
          onClose={() => setRemoveModal(false)}
          id="removeDealContractRequest"
          progress={progress}
          size="small"
        >
          <SharedModalContent>{t('requestContractBlock.remove_modal_text')}</SharedModalContent>
        </ResponsiveModal>
      )}
    </>
  )
}
