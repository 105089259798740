import { Company } from 'modules/domain/company/types'
import { TaskStatus, TaskType } from 'modules/domain/task/types'
import { Carrier } from 'modules/domain/logisticsKR/types'

export type User = {
  is_verified_by_coordinator: boolean
  is_verified_by_security: boolean
  is_verified: boolean
  short_name: string
  full_name: string
  phone: string
  id: number
}

export enum OtherLinksModels {
  DOCUMENT_REQUEST = 'DocumentsRequest',
}

export type DocumentRequestLink = {
  id: number
  model: OtherLinksModels
}

export type Task = {
  linked_deal?: { id: any; product: any; purchase_bid: any; sale_bid: any }
  assignee_team: { id: number; name: string }
  linked_bid?: { id: any; company: any }
  comment?: string | null
  is_completed: boolean
  is_accepted: boolean
  completed_at: string
  status: TaskStatus
  linked_user?: User
  linked_carrier?: Carrier
  linked_company?: Company
  other_links?: Record<string, DocumentRequestLink>
  created_by: User
  deadline: string
  priority: number
  created: string
  subject: string
  assignee: User
  id: number
  task_type: TaskType
}
