import { Icons } from '@agro-club/agroclub-shared'
import { useAProgress } from 'hooks/useAProgress'
import * as managers from 'modules/domain/common/managers'
import { RoutesFileResponse } from 'modules/domain/routes/types'
import { endpoints } from 'modules/endpoints'
import { AUploadFileButton } from 'views/components/UploadFileButton/AUploadFileButton'

type AddFileButtonProps = {
  setFileResponse: (file: RoutesFileResponse) => void
}

export const AddFileButton = ({ setFileResponse }: AddFileButtonProps) => {
  const [progress, addHandler] = useAProgress(
    async (file: File) => await managers.uploadFile<RoutesFileResponse>(endpoints.brRoutesUploadFile(), file),
    {
      eventName: 'addRoutesFile',
      onSuccess: (response) => setFileResponse(response),
    },
  )

  return (
    <AUploadFileButton
      eventName="addRoutesFile"
      icon={Icons.IconUpload}
      progress={progress}
      accept=".xlsx"
      onUpload={addHandler}
    />
  )
}
