import 'analytics/initAnalytics'
import { createRoot } from 'react-dom/client'

import App from './App'
import { Sentry } from './sentry'

const main = async () => {
  await Sentry.init()
  const container = document.getElementById('root')
  const root = createRoot(container!)
  root.render(<App />)
}

main()
