export type DatePeriod = {
  start_date: string | null
  end_date: string | null
}

export const containsDatePeriod = <T>(obj?: T): obj is T & DatePeriod =>
  Boolean(typeof obj === 'object' && obj && 'start_date' in obj && 'end_date' in obj)

export const containsFilledDatePeriod = <T>(obj?: T): obj is T & NonNullable<DatePeriod> =>
  Boolean(containsDatePeriod(obj) && obj.start_date !== null && obj.end_date !== null)
