import { createContext, FC, ReactNode, useContext, useState } from 'react'

const PhoneVisibilityContext = createContext<{
  visiblePhoneId: string | null
  setVisiblePhoneId: (id: string | null) => void
} | null>(null)

export const PhoneVisibilityProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [visiblePhoneId, setVisiblePhoneId] = useState<string | null>(null)

  return (
    <PhoneVisibilityContext.Provider value={{ visiblePhoneId, setVisiblePhoneId }}>
      {children}
    </PhoneVisibilityContext.Provider>
  )
}

export const usePhoneVisibility = () => {
  const context = useContext(PhoneVisibilityContext)
  if (!context) {
    throw new Error('useVisiblePhone must be used within a VisiblePhoneProvider')
  }
  return context
}
