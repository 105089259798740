const main = 'documents-requests'

const relativeRoutes = {
  RelativePage: `${main}/*`,
  RelativeList: `${main}`,
  RelativeDetails: `:id`,
}

export const DocumentRequestsRoutes = {
  ...relativeRoutes,
  List: `/${main}`,
  Details: `/${main}/${relativeRoutes.RelativeDetails}`,
}
