import { Route, Routes } from 'react-router-dom'
import DashboardRoutes from 'views/pages/Dashboard/routes'
import { Dashboard } from './Dashboard'

function DashboardPage() {
  return (
    <Routes>
      <Route index element={<Dashboard />} />
      <Route path={DashboardRoutes.relativeListWithTab} element={<Dashboard />} />
    </Routes>
  )
}

export default DashboardPage
