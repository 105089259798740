import { useSingleEntity } from 'modules/domain/common/hooks'
import { FarmerLoad } from 'modules/domain/deal/types'
import { endpoints } from 'modules/endpoints'
import { useParams } from 'react-router-dom'
import { FC, useState } from 'react'
import { Loading } from './Loading'
import { useTranslation } from 'react-i18next'
import { AddFarmerLoadModal } from './Modals/AddFarmerLoadModal'
import { useFarmerLoadColumns } from './useColumns'

type Props = {
  setLoadSummary: (summary: number) => void
}

export const FarmerLoading: FC<Props> = ({ setLoadSummary }) => {
  const { t } = useTranslation('deal')
  const { id } = useParams()
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [progress, items = [], refetch] = useSingleEntity<FarmerLoad[]>(endpoints.dealFarmerLoading(id))
  const columns = useFarmerLoadColumns(refetch)

  return (
    <>
      <Loading
        setLoadSummary={setLoadSummary}
        setModalIsOpen={setModalIsOpen}
        loadItems={items}
        columns={columns}
        progress={progress}
        noDataText={t('accordion.loading.farmer_loading_not_found')}
        addEventId="addFarmerLoading"
      />
      {modalIsOpen && <AddFarmerLoadModal dealId={id} onClose={() => setModalIsOpen(false)} refetch={refetch} />}
    </>
  )
}
