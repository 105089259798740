import { TaskDistributionModal } from './TaskDistribution/TaskDistributionModal'
import { defaultListRequestParams } from 'views/components/TableFilters/data'
import { ReactComponent as IconAdd } from 'assets/icons/addIcon.svg'
import APageWrapper from 'views/components/PageWrapper/APageWrapper'
import { AnalyticsPages, AnalyticsPlaces } from '../analyticsPlaces'
import ATableFullPage from 'views/components/Table/ATableFullPage'
import { TaskModal } from 'views/components/TaskModal/TaskModal'
import { FC, useCallback, useContext, useState } from 'react'
import { AuthContext } from 'modules/context/AuthContext'
import { ListRequestParams } from 'modules/domain/types'
import { useHelmet } from '@agro-club/agroclub-shared'
import { AButton } from 'views/components/Analytics'
import TasksRoutes from 'views/pages/MyTasks/routes'
import * as Layout from 'views/layouts/NewLayout'
import { useTranslation } from 'react-i18next'
import { endpoints } from 'modules/endpoints'
import { useFilters } from 'hooks/useFilter'
import { TasksFilters } from './TaskFilters'
import useColumns from './useColumns'
import PermissionCheck from 'views/components/PermissionCheck/PermissionCheck'

export const TasksList: FC = () => {
  const { t } = useTranslation('my_tasks')
  useHelmet({ title: t('my_tasks') })
  const { profile: currentUser } = useContext(AuthContext)
  const [listRequestParams, setListRequestParams] = useState<ListRequestParams>({
    ...defaultListRequestParams,
  })
  const [isTaskModalOpen, setIsTaskModalOpen] = useState(false)
  const handlerModal = () => setIsTaskModalOpen((isOpen) => !isOpen)
  const [isDistributionModal, setIsDistributionModal] = useState(false)
  const handlerDistributionModal = () => setIsDistributionModal((isOpen) => !isOpen)
  const { isFiltersLoaded, updateFilterState } = useFilters({
    listRequestParams,
    setListRequestParams,
    defaultParams: { assignee: String(currentUser?.id) },
  })

  const onTaskActionSuccess = useCallback(() => {
    setListRequestParams({ ...listRequestParams })
  }, [listRequestParams])

  const columns = useColumns(onTaskActionSuccess)

  return (
    <APageWrapper page={AnalyticsPages.TASKS} place={AnalyticsPlaces.LIST}>
      <Layout.WrapperContent>
        <Layout.Header>
          <Layout.TopHeader>
            <Layout.Breadcrumbs routes={[{ breadcrumbName: t('my_tasks'), path: TasksRoutes.List }]} />
            <Layout.HeaderWrapBtns>
              <PermissionCheck scope={['tasks.can_assign_tasks']}>
                <AButton id="distributeTasks" intent="secondary" size="medium" onClick={handlerDistributionModal}>
                  {t('distribute_tasks')}
                </AButton>
              </PermissionCheck>
              <AButton id="add" intent="approve" Icon={IconAdd} size="medium" onClick={handlerModal}>
                {t('add')}
              </AButton>
            </Layout.HeaderWrapBtns>
          </Layout.TopHeader>

          <Layout.PageName>{t('my_tasks')}</Layout.PageName>

          <TasksFilters
            setListRequestParams={setListRequestParams}
            updateFilterState={updateFilterState}
            listRequestParams={listRequestParams}
            isFiltersLoaded={isFiltersLoaded}
          />
        </Layout.Header>

        <ATableFullPage
          dataEndpoint={endpoints.tasksTableData()}
          listParamsUpdated={setListRequestParams}
          listRequestParams={listRequestParams}
          isFiltersLoaded={isFiltersLoaded}
          columns={columns}
          rowHoverGray
        />
        {isDistributionModal && (
          <TaskDistributionModal
            refetch={() => setListRequestParams({ ...listRequestParams })}
            onClose={handlerDistributionModal}
          />
        )}

        {isTaskModalOpen && (
          <TaskModal
            onSuccess={() => setListRequestParams({ ...listRequestParams })}
            skipOnSuccessBeforeRedirect
            unclickableUserLink
            close={handlerModal}
            isMyTasks
          />
        )}
      </Layout.WrapperContent>
    </APageWrapper>
  )
}
