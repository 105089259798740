import { FC } from 'react'
import { Driver } from '../../../../types'
import { DriverDocuments } from './DriverDocuments'

type Props = {
  drivers: Driver[] | undefined
  setDocumentsCounter: (arg) => void
}

export const DriversDocuments: FC<Props> = ({ drivers, setDocumentsCounter }) => {
  return (
    <>
      {!!drivers?.length &&
        drivers.map((driver) => (
          <DriverDocuments key={driver.id} driver={driver} setDocumentsCounter={setDocumentsCounter} />
        ))}
    </>
  )
}
