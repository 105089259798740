import { FC } from 'react'
import { PotentialBid } from 'modules/domain/potentialBid/types'
import { KeyValue } from 'views/components/KeyValue/KeyValue'
import { Card } from 'views/components/Card/Card'
import { useTranslation } from 'react-i18next'
import { formatQuantity } from 'modules/utils/numbers/formatQuantity'

type Props = {
  bid: PotentialBid
  setIsOpen: (v: boolean) => void
}

export const PotentialParamenters: FC<Props> = ({ bid, setIsOpen }) => {
  const { t } = useTranslation(['bid', 'common'])

  return (
    <Card.GapableContent>
      <Card.Title>{t('form.bidsParams')}</Card.Title>
      <Card.Container bordered>
        <Card.GapableContent>
          <KeyValue label={t('common:quantity')}>{formatQuantity(bid.quantity)}</KeyValue>
          {bid.season && (
            <Card.Grid>
              <KeyValue label={t('season')}>{bid.season.name}</KeyValue>
            </Card.Grid>
          )}

          {bid.parameters.length ? (
            <KeyValue label={t('form.cropsParams')}>
              {bid.parameters.map((p) => {
                return <div key={p.id}>{p.description}</div>
              })}
            </KeyValue>
          ) : null}
        </Card.GapableContent>

        <Card.EditButton onClick={() => setIsOpen(true)} />
      </Card.Container>
    </Card.GapableContent>
  )
}
