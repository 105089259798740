import ADefaultModalFooter from 'views/components/DefaultModalFooter/ADefaultModalFooter'
import { ASharedModal, SharedModalTitle } from 'views/components/SharedModal'
import { refetchFunc } from 'modules/domain/common/hooks'
import { Bid, BidTypes } from 'modules/domain/bid/types'
import { apiClient } from 'modules/utils/httpClient'
import { useAFormHandler } from 'analytics/hooks'
import { Deal } from 'modules/domain/deal/types'
import { useTranslation } from 'react-i18next'
import { CommonFields, ContractFormFields } from './CommonFields'
import { endpoints } from 'modules/endpoints'
import { useFormik } from 'formik'
import { FC } from 'react'
import { getDealBid, getDealPrice } from 'modules/domain/deal/utils'

type Props = {
  refetch: refetchFunc
  onClose: () => void
} & (
  | {
      bid?: undefined
      deal: Deal
      bidType: BidTypes
    }
  | {
      bid: Bid
      deal?: undefined
      bidType?: undefined
    }
)

export const AddContractModal: FC<Props> = ({ onClose, bidType, deal, refetch, bid: bidFromProps }) => {
  const { t } = useTranslation('bid')
  const { formProgress, formHandler } = useAFormHandler('addContract')
  const bid = deal ? getDealBid(deal, bidType) : bidFromProps

  const initialPrice = deal ? getDealPrice(deal, bidType) : bid.price

  const onSuccess = async () => {
    await refetch()
    onClose()
  }

  const formik = useFormik<ContractFormFields>({
    initialValues: {
      deal_id: deal?.id,
      bid_id: bid.id,
      shipment_due_date: undefined,
      shipment_from_date: undefined,
      payment_due_date: undefined,
      quantity: deal?.quantity || bid.quantity,
      signed_at: undefined,
      price: initialPrice,
      number: undefined,
      payment_type: undefined,
      payment_hour_period: undefined,
      payment_volume_period: undefined,
    },

    onSubmit: formHandler(
      async () => {
        await apiClient.post(endpoints.contracts(), formik.values)
      },
      {
        onSuccess,
        onError: (error) => {
          formik.setErrors(error.errors)
        },
      },
    ),
  })
  return (
    <ASharedModal size="small" onClose={onClose} id="addContract">
      <SharedModalTitle>{t('contracts.addContract')}</SharedModalTitle>
      <CommonFields formik={formik} bid={bid} />
      <ADefaultModalFooter
        onConfirm={() => formik.submitForm()}
        confirmButtonText={t('common:save')}
        progress={formProgress}
        onClose={onClose}
      />
    </ASharedModal>
  )
}
