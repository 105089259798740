import { TableFiltersProps } from 'modules/types'
import { useDisplayClearBtn } from 'hooks/useDisplayClearBtn'
import { FiltersWrapper } from 'views/components/CommonTableComponents/CommonTableComponents'
import { defaultListRequestParams } from 'views/components/TableFilters/data'
import { CoordinatorTypeFilter } from 'views/filters/CoordinatorTypeFilter'
import { isBrazil } from 'env'
import { ADateRangePicker } from 'views/components/TableFilters/DateRangePicker/ADateRangePicker'
import { AnalyticsFilterVariant } from 'analytics/types'
import { AClearFiltersButton } from 'views/components/Analytics'
import { FC } from 'react'
import { NewTeamFilter } from 'views/components/TeamFilter/NewTeamFilter'

const CallStatisticsFilters: FC<TableFiltersProps> = ({
  setListRequestParams,
  listRequestParams,
  updateFilterState,
}) => {
  const displayClearBtn = useDisplayClearBtn({
    listRequestParams,
  })

  return (
    <FiltersWrapper>
      <ADateRangePicker
        id={AnalyticsFilterVariant.DATE}
        value={[listRequestParams.filter.call_start_after, listRequestParams.filter.call_start_before]}
        onChange={(value) => {
          updateFilterState({ call_start_after: value.startDate, call_start_before: value.endDate })
        }}
      />
      {!isBrazil && (
        <>
          <CoordinatorTypeFilter updateFilterState={updateFilterState} listRequestParams={listRequestParams} />
          <NewTeamFilter
            eventId="coordinatorTeamFilter"
            value={listRequestParams.filter.team}
            updateFilterState={updateFilterState}
          />
        </>
      )}

      {displayClearBtn && (
        <AClearFiltersButton
          onClick={() => setListRequestParams && setListRequestParams({ ...defaultListRequestParams })}
        />
      )}
    </FiltersWrapper>
  )
}

export default CallStatisticsFilters
