import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useHelmet } from '@agro-club/agroclub-shared'
import * as Layout from 'views/layouts/NewLayout'
import * as Header from 'views/ui/Header/Header'
import { SpecificationFilters } from 'views/pages/Specifications/SpecificationFilters/SpecificationFilters'
import { ListRequestParams } from 'modules/domain/types'
import { defaultListRequestParams } from 'views/components/TableFilters/data'
import { useFilters } from 'hooks/useFilter'
import { endpoints } from 'modules/endpoints'
import { useColumns } from 'views/pages/Specifications/useColumns'
import { SpecificationStatus } from 'modules/domain/specification/types'
import { format } from 'date-fns'
import { getMonthList } from 'modules/utils/helpers'
import { YEAR_MONTH } from 'modules/constants'
import APageWrapper from 'views/components/PageWrapper/APageWrapper'
import { AnalyticsPages, AnalyticsPlaces } from './analyticsPlaces'
import ATableFullPage from 'views/components/Table/ATableFullPage'

const SpecificationList = () => {
  const { t } = useTranslation(['specification'])
  useHelmet({ title: t('specifications') })
  const [listRequestParams, setListRequestParams] = useState<ListRequestParams>(defaultListRequestParams)
  const { isFiltersLoaded, updateFilterState } = useFilters({
    listRequestParams,
    setListRequestParams,
    defaultParams: {
      month: format(getMonthList()[0], YEAR_MONTH),
      status: SpecificationStatus.open,
    },
  })

  const columns = useColumns(listRequestParams.sort)

  return (
    <APageWrapper page={AnalyticsPages.SPECIFICATIONS} place={AnalyticsPlaces.LIST}>
      <Layout.WrapperContent>
        <Layout.Header>
          <Layout.TopHeader>
            <Header.Breadcrumbs routes={[{ breadcrumbName: t('specifications') }]} />
          </Layout.TopHeader>
          <Layout.PageName>{t('specifications')}</Layout.PageName>
          <SpecificationFilters
            listRequestParams={listRequestParams}
            setListRequestParams={setListRequestParams}
            updateFilterState={updateFilterState}
          />
        </Layout.Header>
        <ATableFullPage
          columns={columns}
          listRequestParams={listRequestParams}
          dataEndpoint={endpoints.specifications()}
          listParamsUpdated={setListRequestParams}
          isFiltersLoaded={isFiltersLoaded}
          rowHoverGray
        />
      </Layout.WrapperContent>
    </APageWrapper>
  )
}

export default SpecificationList
