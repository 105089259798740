import { useState, FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Progress } from '@agro-club/agroclub-shared'
import { apiClient } from 'modules/utils/httpClient'
import { endpoints } from 'modules/endpoints'
import { useNotificationProgress } from 'hooks/useNotificationProgress'
import { useNavigate } from 'react-router-dom'
import { Bid, BidStatus } from 'modules/domain/bid/types'
import { SharedModalContent, SharedModalFooter, SharedModalTitle } from 'views/components/SharedModal'
import { AButton } from 'views/components/Analytics'
import ASharedModal from 'views/components/SharedModal/ASharedModal'
import { AnalyticsPlaces } from '../../analyticsPlaces'
import { useAProgress } from 'hooks/useAProgress'

type Props = {
  bid: Bid
}

export const ArchiveBidButton: FC<Props> = ({ bid }) => {
  const { t } = useTranslation('bid')
  const { id, status } = bid
  const navigate = useNavigate()
  const [isConfirmVisible, setConfirmVisible] = useState<boolean>(false)
  const [changeStatusProgress, changeStatus] = useAProgress(
    () => apiClient.post(endpoints.bidChangeStatus(id), { new_status: BidStatus.archived }),
    { eventName: 'archive' },
  )

  useNotificationProgress(changeStatusProgress)

  return (
    <>
      <AButton
        intent="destruct"
        size="medium"
        onClick={() => {
          setConfirmVisible(true)
        }}
        disabled={status == BidStatus.archived}
        id="archive"
        analyticsPayload={{ id }}
      >
        {t('common:archive')}
      </AButton>

      {isConfirmVisible && (
        <ASharedModal onClose={() => setConfirmVisible(false)} size="small" id={AnalyticsPlaces.ARCHIVE_MODAL}>
          <SharedModalTitle>{t('archiveBid')}</SharedModalTitle>
          <SharedModalContent>{t('areYouSureToArchiveBid')}</SharedModalContent>
          <SharedModalFooter>
            <AButton
              id="save"
              intent="primary"
              size="big"
              disabled={changeStatusProgress === Progress.WORK}
              onClick={async () => {
                try {
                  await changeStatus()
                  setConfirmVisible(false)
                  navigate(-1)
                } catch (e) {}
              }}
              progress={changeStatusProgress}
            >
              {t('common:archive')}
            </AButton>

            <AButton id="close" intent="secondary" size="big" onClick={() => setConfirmVisible(false)}>
              {t('common:canceling')}
            </AButton>
          </SharedModalFooter>
        </ASharedModal>
      )}
    </>
  )
}
