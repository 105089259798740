import { FC } from 'react'
import { ListRequestParams } from 'modules/domain/types'
import styled from 'styled-components'

import { DistrictFilter } from '../DistrictFilter'
import { RegionsFilter } from '../RegionsFilter'
// import _ from 'lodash'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 16px;
`
interface Props {
  listRequestParams: ListRequestParams
  updateFilterState: (filterObj: Record<string, any>) => void
  disabled?: boolean
  regionPlaceholder?: string
  districtPlaceholder?: string
  filter?: any
}

export const RegionDistrictFilter: FC<Props> = ({
  listRequestParams,
  updateFilterState,
  regionPlaceholder,
  districtPlaceholder,
  filter,
}) => {
  return (
    <Container>
      <RegionsFilter
        listRequestParams={listRequestParams}
        updateFilterState={updateFilterState}
        multiple
        onClear={() => updateFilterState({ region: [], district: [] })}
        placeholder={regionPlaceholder}
        filter={filter}
      />

      {listRequestParams.filter.region?.length > 0 && (
        <DistrictFilter
          value={listRequestParams.filter.district}
          onChange={(district) => updateFilterState({ district })}
          region={listRequestParams.filter.region}
          placeholder={districtPlaceholder}
        />
      )}
    </Container>
  )
}
