const main = 'CustomersKR'

const relativeRoutes = {
  relativePage: `${main}/*`,
  relativeList: `${main}`,
  relativeDetails: `:id`,
}

const routes = {
  List: `/${main}`,
  Details: `/${main}/${relativeRoutes.relativeDetails}`,
}

const CustomersKRRoutes = { ...relativeRoutes, ...routes }

export default CustomersKRRoutes
