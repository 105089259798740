import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { EntityTitle } from '../styled'
import { Icons } from '@agro-club/agroclub-shared'
import { AddSellerSpecification } from './SellerSpecificationModal/AddSellerSpecification'
import { SpecificationList } from './SpecificationList'
import { SellerSpec } from 'modules/domain/deal/types'
import { AButton } from 'views/components/Analytics'
import { isBidContractsEnabled } from 'env'
import { refetchFunc } from 'modules/domain/common/hooks'

type Props = {
  specification: SellerSpec[]
  refetch: refetchFunc
}

export const SellerSpecification: FC<Props> = ({ specification, refetch }) => {
  const { t } = useTranslation('deal')
  const [newSpecificationModal, setNewSpecificationModal] = useState(false)

  const addButton = !isBidContractsEnabled ? (
    <AButton id="addFile" intent="approve" Icon={Icons.IconAdd} onClick={() => setNewSpecificationModal(true)}>
      {t('common:add')}
    </AButton>
  ) : (
    t('common:no')
  )

  return (
    <div>
      <EntityTitle>{t('accordion.dealFiles.seller_specification')}</EntityTitle>
      {!!specification.length ? <SpecificationList specification={specification} refetch={refetch} /> : addButton}
      {newSpecificationModal && <AddSellerSpecification onSuccess={refetch} onClose={setNewSpecificationModal} />}
    </div>
  )
}
