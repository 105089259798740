import { Task } from 'views/pages/MyTasks/TaskDetail/types'
import { useTranslation } from 'react-i18next'
import { Text } from '@agro-club/agroclub-shared'
import { ARouterLink } from 'views/components/Analytics'
import { generatePath } from 'react-router'
import APhone from 'views/ui/Phone/APhone'
import { refetchFunc } from 'modules/domain/common/hooks'
import { UserForVerification } from './UserForVerification'
import TaskFiles from '../TaskFiles'
import CarriersKRRoutes from 'views/pages/LogisticsKR/CarriersKR/routes'
import { Card } from 'views/components/Card/Card'
import { InfoBlock, RestrictionContainer } from '../../styled'
import { KeyValue } from 'views/components/KeyValue/KeyValue'
import { DASH } from 'modules/constants'

interface Props {
  task: Task
  taskRefetch: refetchFunc
}

export const CarrierVerification = ({ task, taskRefetch }: Props) => {
  const { t } = useTranslation('my_tasks')

  return (
    <Card.Container>
      <RestrictionContainer>
        <Card.GapableContent>
          <Card.Title>{t('detail.description')}</Card.Title>
          <InfoBlock>
            <Text>{t('detail.carrier_call')}</Text>
            <Text>{t('detail.bind_user')}</Text>
            <Text>{t('detail.auth_user_after_bind')}</Text>
          </InfoBlock>
        </Card.GapableContent>

        <Card.GapableContent>
          <Card.Title>{t('detail.related_object')}</Card.Title>
          <KeyValue label={t('common:company')}>
            <ARouterLink
              eventName="companyLink"
              to={generatePath(CarriersKRRoutes.Details, { id: task?.linked_carrier?.id })}
            >
              {task?.linked_carrier?.name}
            </ARouterLink>
            <Text>
              {t('common:inn')}: {task?.linked_carrier?.inn}
            </Text>
            <APhone phone={task.linked_carrier?.phone || DASH} />
          </KeyValue>
        </Card.GapableContent>

        <Card.GapableContent>
          <Card.Title>{t('detail.user_for_verification')}</Card.Title>
          <UserForVerification task={task} onSuccess={taskRefetch} />
        </Card.GapableContent>

        <TaskFiles id={task?.id} />
      </RestrictionContainer>
    </Card.Container>
  )
}
