import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { AntTag } from '@agro-club/agroclub-shared'
import { Bid, BidStatus } from 'modules/domain/bid/types'
import { PotentialBid } from 'modules/domain/potentialBid/types'

type Props = {
  bid: Bid | PotentialBid
}

export const BidStatusTag: FC<Props> = ({ bid }) => {
  const { t } = useTranslation('bid')
  const label = bid.status === BidStatus.potential ? t('bidTypes.potential') : t(`bidStatuses.${bid.status}`)
  const color = bid.status === BidStatus.published ? 'green' : 'grey'

  return <AntTag color={color}>{label}</AntTag>
}
