import { FC, ReactNode } from 'react'
import { WrapperCards } from 'views/components/Board/styled'
import { CarCard } from './CarCard'
import { NoDataCard } from 'views/components/Board/NoDataCard'
import { CarStatuses, LogisticsKRCar } from '../../../../types'
import { Panel, Progress } from '@agro-club/agroclub-shared'
import { CollapseHeaderWithCount } from 'views/components/CollapseHeaderWithCount/CollapseHeaderWithCount'
import { AntTagColor } from '@agro-club/agroclub-shared/dist/cjs/ui/misc/AntTag/types'
import { PreloadCard } from 'views/components/Board/Preload'

type Props = {
  cars: LogisticsKRCar[]
  refetch: () => Promise<void>
  onSelectedChanged: (carId: string) => void
  activeCarId: string
  key: CarStatuses
  title: ReactNode
  counter: number
  progress: Progress
  tagColor?: AntTagColor
}

export const Cars: FC<Props> = ({
  cars,
  refetch,
  onSelectedChanged,
  activeCarId,
  counter,
  title,
  progress,
  tagColor,
  ...props
}) => {
  const preloadWrapper = progress === Progress.WORK && <PreloadCard />

  return (
    <Panel
      {...props}
      header={<CollapseHeaderWithCount progress={progress} tagColor={tagColor} counter={counter} title={title} />}
    >
      <WrapperCards>
        {cars.map((car) => (
          <CarCard
            onSelectedChanged={onSelectedChanged}
            refetch={refetch}
            key={car.id}
            car={car}
            activeCarId={activeCarId}
          />
        ))}
        {preloadWrapper}
        {!cars.length && !preloadWrapper && <NoDataCard />}
      </WrapperCards>
    </Panel>
  )
}
