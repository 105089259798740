import { FC, useMemo } from 'react'

import { Progress } from '@agro-club/agroclub-shared'

import { FiltersAndSearchWrapper, FiltersWrapper } from 'views/components/CommonTableComponents/CommonTableComponents'
import { defaultListRequestParams } from 'views/components/TableFilters/data'
import { useDisplayClearBtn } from 'hooks/useDisplayClearBtn'
import { TableFiltersProps } from 'modules/types'
import { SearchFilter } from 'views/components/SearchFilter/SearchFilter'
import { useTranslation } from 'react-i18next'
import { endpoints } from 'modules/endpoints'
import { format } from 'date-fns'
import useDateFnsLocale from 'hooks/useDateFnsLocale'
import { MONTH_NAME_YEAR, YEAR_MONTH } from 'modules/constants'
import { getMonthList } from 'modules/utils/helpers'
import capitalizeFirstLetter from 'helpers/capitalizeFirstLetter'
import { StatusFilter, TeamsFilter } from 'views/components/TableFilters'
import { AClearFiltersButton, ASelectFilter } from 'views/components/Analytics'
import { AnalyticsFilterVariant } from 'analytics/types'
import { ABaseTableFilter } from 'views/components/TableFilters/ABaseTableFilter'

export const DocumentRequestsFilters: FC<
  TableFiltersProps & {
    statuses: any[]
    statusProgress: Progress
  }
> = ({ setListRequestParams, listRequestParams, updateFilterState, statuses, statusProgress }) => {
  const { t } = useTranslation('documentRequests')
  const displayClearBtn = useDisplayClearBtn({
    listRequestParams,
  })

  const locale = useDateFnsLocale()
  const dateOptions = useMemo(
    () =>
      getMonthList().map((date) => ({
        value: format(date, YEAR_MONTH),
        label: capitalizeFirstLetter(format(date, MONTH_NAME_YEAR, { locale })),
      })),
    [locale],
  )

  return (
    <FiltersAndSearchWrapper>
      <FiltersWrapper>
        <ASelectFilter
          placeholder={t('common:forEntirePeriod')}
          options={dateOptions}
          value={listRequestParams.filter.month}
          onChange={(month) => updateFilterState({ month })}
          showSearch={false}
          id={AnalyticsFilterVariant.MONTH}
        />
        <TeamsFilter
          listRequestParams={listRequestParams}
          updateFilterState={(data) => updateFilterState({ team: data.team })}
        />
        <StatusFilter
          listRequestParams={listRequestParams}
          isLoading={statusProgress === Progress.WORK}
          options={statuses}
          updateFilterState={updateFilterState}
        />
        <ABaseTableFilter
          onChange={(product) => updateFilterState({ product_id: product })}
          value={listRequestParams.filter.product_id}
          placeholder={t('common:product')}
          getLabel={(label) => label.title}
          endpoint={endpoints.products()}
          simpleApiFormat
          multiple
          id={AnalyticsFilterVariant.PRODUCT}
        />
        {displayClearBtn && (
          <AClearFiltersButton onClick={() => setListRequestParams({ ...defaultListRequestParams })} />
        )}
      </FiltersWrapper>

      <SearchFilter listRequestParams={listRequestParams} updateFilterState={updateFilterState} />
    </FiltersAndSearchWrapper>
  )
}
