import { AntTagColor, Progress } from '@agro-club/agroclub-shared'
import { FC, ReactNode } from 'react'
import { CollapseHeaderWrapper, TitleAndTagWrapper } from 'views/pages/LogisticsKR/LogisticRequests/components/styled'
import { BoardCounter } from 'views/components/Board/BoardCounter'

type Props = {
  counter: number | undefined
  progress: Progress
  title: ReactNode
  color?: AntTagColor
}

export const RequestColumnHeader: FC<Props> = ({ counter, progress, title, color }) => {
  return (
    <CollapseHeaderWrapper>
      <TitleAndTagWrapper>
        <div>{title}</div>
        <BoardCounter color={color} counter={counter} progress={progress} />
      </TitleAndTagWrapper>
    </CollapseHeaderWrapper>
  )
}
