import { FC } from 'react'
import { Task, TaskStatus } from 'modules/domain/task/types'
import { SetTaskToWorkButton } from './ActionButtons'

import { useTranslation } from 'react-i18next'
import { CompleteTaskBtn } from 'views/components/TaskActions/CompleteTask/CompleteTaskBtn'

interface Props {
  task: Task
  currentStatus: TaskStatus
  onSuccess: () => void
  potential_executors: string[]
}

export const TableActions: FC<Props> = ({ task, currentStatus, onSuccess, potential_executors }) => {
  const { t } = useTranslation('task')
  switch (currentStatus) {
    case TaskStatus.new:
      return <SetTaskToWorkButton taskId={task.id} onSuccess={onSuccess} potential_executors={potential_executors} />
    case TaskStatus.in_progress:
      return <CompleteTaskBtn refetch={onSuccess} task={task} successText={t('notifyTaskCompleted')} forDashboard />
    default:
      return null
  }
}
