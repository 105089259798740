import { FC } from 'react'

import { isBrazil } from 'env'
import { Address } from 'modules/domain/types'
import { useTranslation } from 'react-i18next'

interface Props {
  address: Address
}

const AddressLegalType: FC<Props> = ({ address }) => {
  const { t } = useTranslation('address')

  if (!isBrazil) return null

  return <>, {t(`legal_types.${address.legal_type}`)}</>
}

export default AddressLegalType
