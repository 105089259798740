import { FC, useState } from 'react'
import { ListRequestParams } from 'modules/domain/types'
import { endpoints } from 'modules/endpoints'
import { RightBlockTable } from '../../RightBlockTable/RightBlockTable'
import useColumns from './useColumns'

interface Props {
  userId: string
}

export const UserRatingByDeal: FC<Props> = ({ userId }) => {
  const [listRequestParams, setListRequestParams] = useState<ListRequestParams>({ page: 1, pageSize: 5 })
  const columns = useColumns()

  return (
    <RightBlockTable
      endpoint={endpoints.userRatingByDeal(userId)}
      columns={columns}
      requestParams={listRequestParams}
      setRequestParams={setListRequestParams}
    />
  )
}
