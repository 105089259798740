import { defaultListRequestParams } from 'views/components/TableFilters/data'
import { useTableData } from 'modules/domain/common/hooks'
import { Bid, BidContract } from 'modules/domain/bid/types'
import { endpoints } from 'modules/endpoints'
import { ContractItem } from './ContractItem'
import styled from 'styled-components'
import { FC, useEffect, useMemo } from 'react'

const Wrapper = styled.div``

type Props = {
  setContracts: (count: number) => void
  bid: Bid
}

export const Contracts: FC<Props> = ({ bid, setContracts }) => {
  const contractsListRequestParams = useMemo(
    () => ({
      ...defaultListRequestParams,
      filter: { bid_id: bid.id },
      pageSize: 100,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const { data: contracts } = useTableData<BidContract>(endpoints.contracts(), contractsListRequestParams, true)

  useEffect(() => {
    if (!contracts?.length) return
    setContracts(contracts?.length)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contracts])
  return (
    <div>
      <Wrapper>
        {contracts?.map((contract) => (
          <ContractItem contract={contract} key={contract.id} />
        ))}
      </Wrapper>
    </div>
  )
}
