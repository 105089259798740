import { FormikErrors } from 'formik'
import { FormGroup, Input } from '@agro-club/agroclub-shared'
import { FC, ReactNode } from 'react'
import { FormikFieldsType } from 'modules/domain/types'

type Props = {
  error?: string | string[] | FormikErrors<any> | FormikErrors<any>[]
  formik: FormikFieldsType
  placeholder?: string
  disabled?: boolean
  required?: boolean
  label?: ReactNode
  field: string
  type?: string
  size?: 'thin' | 'small' | 'medium' | 'big'
  disableRightPadding?: boolean
  labelWidth?: number
}

export const FormInput: FC<Props> = ({
  required = false,
  placeholder,
  disabled,
  formik,
  label,
  field,
  error,
  type,
  size,
  disableRightPadding,
  labelWidth,
}) => {
  return (
    <FormGroup
      label={label}
      error={error || formik.errors[field]}
      required={required}
      disableRightPadding={disableRightPadding}
      labelWidth={labelWidth}
    >
      <Input
        invalid={!!error || !!formik.errors[field]}
        {...formik.getFieldProps(field)}
        placeholder={placeholder}
        disabled={disabled}
        type={type}
        inputSize={size}
      />
    </FormGroup>
  )
}
