import styled from 'styled-components'
import { Card } from 'views/components/Card/Card'

export const WarningContainer = styled(Card.Container).attrs({
  intent: 'description',
  disableShadow: true,
})`
  width: 734px;
`

export const SelectWithButton = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
`

export const ButtonWrapper = styled.div`
  align-self: flex-start;
  margin-left: 212px;
`
