import { NoData } from 'views/components/NoData/NoData'
import { Progress } from '@agro-club/agroclub-shared'
import { ColumnData } from 'modules/types'
import { ATableFullPageWithoutData } from 'views/components/Table/ATableFullPageWithoutData'
import { FC } from 'react'
import { DashboardTabItemProps } from '../types'
import { refetchFunc } from 'modules/domain/common/hooks'

export type UseDashboardColumnsProps = {
  refetch: refetchFunc
  successText: string
}

type Props = DashboardTabItemProps & {
  successText: string
  useColumns: (props: UseDashboardColumnsProps) => ColumnData[]
  rowClassName?: () => string
}

export const DashboardTasksList: FC<Props> = (props) => {
  const { fetchFunc: refetch, progress, data, total, successText, useColumns, rowClassName } = props

  const columns = useColumns({ refetch, successText })

  return !data?.length && !total && (progress === Progress.SUCCESS || progress === Progress.ERROR) ? (
    <NoData />
  ) : (
    <ATableFullPageWithoutData columns={columns} {...props} rowHoverGray rowClassName={rowClassName} />
  )
}
