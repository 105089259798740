import { FC } from 'react'

import { SharedModalInputs } from 'views/components/SharedModal'
import { BidAddFields, BidEditableFields, BidParameterDTO } from 'modules/domain/bid/types'
import { MainParam } from 'views/pages/Bid/BidModals/Bid/MainParam'
import { Params } from 'views/pages/Bid/BidModals/Params'
import { FormikErrors, FormikProps } from 'formik'
import { Product } from 'modules/domain/collection/types'
import { isBidProductParamsEnabled } from 'env'
import { ProductTitle } from '../styled'

export type Props = {
  formik: FormikProps<BidAddFields> | FormikProps<BidEditableFields>
  product?: Product
}

export const BidParamsInputs: FC<Props> = ({ formik, product }) => {
  if (!isBidProductParamsEnabled) return null

  let errorMainParam
  let errorsParams

  if (formik?.errors?.parameters?.length) {
    errorMainParam = formik.errors.parameters[0] as FormikErrors<BidParameterDTO>
    errorsParams = formik.errors.parameters.slice(1) as FormikErrors<BidParameterDTO>[]
  }
  return (
    <>
      {product?.title && <ProductTitle>{product?.title}</ProductTitle>}
      <SharedModalInputs>
        {product && product.main_parameter && (
          <MainParam
            formikError={errorMainParam}
            parameters={[product.main_parameter]}
            parameter={formik.values.mainParameter}
            onChange={(values) => formik.setFieldValue('mainParameter', values)}
          />
        )}
        {product && <Params separateMainParam formikErrors={errorsParams} formik={formik} product={product} />}
      </SharedModalInputs>
    </>
  )
}
