import { SERVER_DATE_FORMAT } from '@agro-club/agroclub-shared'
import { useDisplayClearBtn } from 'hooks/useDisplayClearBtn'
import { ListRequestParams } from 'modules/domain/types'

import { format } from 'date-fns'
import { FC } from 'react'
import { ResponsiveFilters } from 'views/components/ResponsiveFilters/ResponsiveFilters'
import { DateFilter } from '../DateFilter'
import { StatusFilter } from '../StatusFilter'
import { CropsFilter, TeamsFilter } from 'views/components/TableFilters'
import { LogisticiansFilter } from '../LogisticiansFilter'
import { DispatchingTypes } from '../../types'

export type DispatchingFiltersProps = {
  updateFilterState: (filterObj: Record<string, any>, commaShieldingParam?: string) => void
  clearAllTabsState: (defaultParams?: Partial<ListRequestParams>) => void
  commonRequestParams: ListRequestParams
  listRequestParams: ListRequestParams
  activeTab: string
}

export const currentDateForDispatchingFilter = format(new Date(), SERVER_DATE_FORMAT)

export const DispatchingFilters: FC<DispatchingFiltersProps> = ({
  listRequestParams,
  updateFilterState,
  clearAllTabsState,
  activeTab,
}) => {
  const displayClearBtn = useDisplayClearBtn({
    listRequestParams,
    defaultFilters: { date: currentDateForDispatchingFilter },
  })

  return (
    <ResponsiveFilters
      listRequestParams={listRequestParams}
      displayClearBtn={displayClearBtn}
      onReset={() => {
        clearAllTabsState({ filter: { date: currentDateForDispatchingFilter } })
      }}
      updateFilterState={updateFilterState}
    >
      <DateFilter listRequestParams={listRequestParams} updateFilterState={updateFilterState} />

      <StatusFilter
        listRequestParams={listRequestParams}
        updateFilterState={updateFilterState}
        disabled={activeTab === DispatchingTypes.closed}
      />

      <TeamsFilter listRequestParams={listRequestParams} updateFilterState={updateFilterState} />

      <CropsFilter listRequestParams={listRequestParams} updateFilterState={updateFilterState} />

      <LogisticiansFilter listRequestParams={listRequestParams} updateFilterState={updateFilterState} />
    </ResponsiveFilters>
  )
}
