import { FC, ReactNode, useRef } from 'react'

import { AntTooltip, defaultTheme, Icons, Progress } from '@agro-club/agroclub-shared'

import { FileInput, FilesContent, FilesHeader, FilesStyled } from 'views/components/FilesByTypes/styled'
import styled from 'styled-components'
import { AButton } from '../Analytics'
import { TruncatedValue } from 'views/components/TruncatedValue/TruncatedValue'

const ButtonWrapper = styled.div`
  display: flex;
  column-gap: 8px;
`

const IconQuestionWrapper = styled.div`
  width: 24px;
  height: 24px;
  margin-left: 4px;

  svg > path {
    fill: ${defaultTheme.color.secondary300};
  }

  &:hover > svg > path {
    fill: ${defaultTheme.color.secondary900};
  }
`

interface Props {
  header: string
  isAdd?: boolean
  progress?: Progress
  onUpload(file: File): void
  description?: ReactNode
  children?: React.ReactNode
}

const FilesGroup: FC<Props> = ({ header, onUpload, children, isAdd, progress, description }) => {
  const ref = useRef<HTMLInputElement>(null)

  const addFile = () => {
    ref.current?.click()
  }
  const onChange = (event): void => {
    const [file] = [...event.target.files]
    if (onUpload) {
      onUpload(file)
    }
  }

  return (
    <FilesStyled>
      {!isAdd && (
        <FilesHeader>
          <AntTooltip overlayStyle={{ maxWidth: '300px' }} placement="top" variant="secondary" tooltipContent={header}>
            <TruncatedValue hint={header}>{header}</TruncatedValue>
          </AntTooltip>

          <ButtonWrapper>
            {description && (
              <AntTooltip
                variant="secondary"
                tooltipContent={description}
                overlayInnerStyle={{ width: 300 }}
                arrowPointAtCenter
                placement="topLeft"
              >
                <IconQuestionWrapper>
                  <Icons.IconHow />
                </IconQuestionWrapper>
              </AntTooltip>
            )}

            <AButton
              id="addFile"
              progress={progress}
              intent="approve"
              Icon={Icons.IconAdd}
              size="small"
              onClick={addFile}
            />
          </ButtonWrapper>
        </FilesHeader>
      )}
      {children && <FilesContent>{children}</FilesContent>}
      <FileInput type="file" ref={ref} onChange={onChange} />
    </FilesStyled>
  )
}

export default FilesGroup
