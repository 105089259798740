import _ from 'lodash'
import { DispatchingStatuses } from './types'
import { DASH } from 'modules/constants'

export const calculateColumnSum = (data, property, calculateAllStatuses = true) => {
  let preparedData

  if (calculateAllStatuses) {
    preparedData = data
  } else {
    preparedData = data.filter(
      (item) => item.status !== DispatchingStatuses.reconciling && item.status !== DispatchingStatuses.rate_reconciled,
    )
  }

  const sum = preparedData
    .map((item) => _.get(item, property))
    .filter((item) => item)
    .reduce((acc, item) => acc + item, 0)

  if (sum) {
    return sum
  }

  return DASH
}
