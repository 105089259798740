import useFormatDate from 'hooks/useFormatDate'
import { FC } from 'react'
import { generatePath } from 'react-router-dom'
import SpecificationsRoutes from 'views/pages/Specifications/routes'
import { TableTextLink } from 'views/ui/TableTextLink/TableTextLink'
import { useTranslation } from 'react-i18next'

interface Props {
  deadline: string | null
  id: string | null
  without_purchase_specification: boolean
}

export const SpecificationLink: FC<Props> = ({ deadline, id, without_purchase_specification }) => {
  const { t } = useTranslation('common')
  const formatDate = useFormatDate()

  if (without_purchase_specification) return <>{t('without_spec')}</>

  if (!deadline) return <>—</>

  return (
    <TableTextLink id="specificationLink" to={generatePath(SpecificationsRoutes.Details, { id })} target="_blank">
      {formatDate(deadline)}
    </TableTextLink>
  )
}
