import { FC } from 'react'
import { ListRequestParams } from 'modules/domain/types'
import { useTranslation } from 'react-i18next'
import { endpoints } from 'modules/endpoints'
import { ABaseTableFilter } from 'views/components/TableFilters/ABaseTableFilter'
import { AnalyticsFilterVariant } from 'analytics/types'

interface Props {
  listRequestParams: ListRequestParams
  updateFilterState: (filterObj: Record<string, any>, commaShieldingParam?: string) => void
}

export const LogisticiansFilter: FC<Props> = ({ listRequestParams, updateFilterState }) => {
  const { t } = useTranslation('dispatching')

  return (
    <ABaseTableFilter
      id={AnalyticsFilterVariant.LOGISTICIAN}
      onChange={(logistician) => updateFilterState({ logistician })}
      endpoint={endpoints.dispatchingLogisticians()}
      value={listRequestParams.filter.logistician}
      placeholder={t('table.logistician')}
      getLabel={(dto) => dto.full_name}
      searchField="search"
      showSearch={true}
      multiple
    />
  )
}
