import { LogisticRequest } from 'modules/domain/logisticsKR/types'
import { Progress } from '@agro-club/agroclub-shared'
import { FC, useEffect } from 'react'
import { WrapperCards } from 'views/pages/LogisticsKR/LogisticRequests/styled'
import { PreloadCard } from 'views/components/Board/Preload'
import { LogisticRequestsCard } from 'views/pages/LogisticsKR/LogisticRequests/components/LogisticRequestsCard/LogisticRequestsCard'
import { useParams } from 'react-router-dom'

type Props = {
  setDrawerIsOpen?: (arg: boolean) => void
  requests: LogisticRequest[] | undefined
  progress: Progress
  setActiveRequest: (arg: LogisticRequest) => void
}

export const RequestColumn: FC<Props> = ({ requests, setActiveRequest, progress, setDrawerIsOpen }) => {
  const { selectedRequest } = useParams()

  useEffect(() => {
    if (selectedRequest && progress === Progress.SUCCESS) {
      const el = document.getElementById(`${selectedRequest + 'active'}`)
      el?.scrollIntoView({ block: 'center', inline: 'center' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress])

  if (progress === Progress.WORK) {
    return (
      <WrapperCards>
        <PreloadCard />
      </WrapperCards>
    )
  }

  return (
    <WrapperCards>
      {requests?.map((request) => (
        <LogisticRequestsCard
          setDrawerIsOpen={setDrawerIsOpen}
          key={request.id}
          request={request}
          setActiveRequest={setActiveRequest}
        />
      ))}
    </WrapperCards>
  )
}
