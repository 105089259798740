import { Route, Routes } from 'react-router-dom'
import DealsKRRoutes from 'views/pages/LogisticsKR/LogisticRequests/routes'
import { RequestsBoard } from './RequestsBoard/RequestsBoard'
import { RequestCars } from './RequestCars/RequestCars'
import RequestDetails from './RequestDetails/RequestDetails'
import { FC } from 'react'

const LogisticRequestsPage: FC = () => (
  <Routes>
    <Route index element={<RequestsBoard />} />
    <Route path={DealsKRRoutes.relativeListWithTab} element={<RequestsBoard />} />
    <Route path={DealsKRRoutes.relativeListWithTabAndRequest} element={<RequestsBoard />} />
    <Route path={DealsKRRoutes.relativeDetails} element={<RequestDetails />} />
    <Route path={DealsKRRoutes.relativeTripCars} element={<RequestCars />} />
  </Routes>
)
export default LogisticRequestsPage
