import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AntTag, Icons } from '@agro-club/agroclub-shared'
import { generatePath } from 'react-router-dom'
import { LogisticsKRTrip } from '../../types'
import { AButton, ARouterLink } from 'views/components/Analytics'
import APhone from 'views/ui/Phone/APhone'

import { refetchFunc } from 'modules/domain/common/hooks'
import env from 'env'
import useFormatDate from 'hooks/useFormatDate'
import UserRoutes from 'views/pages/User/routes'
import formatPrice from 'modules/utils/numbers/formatPrice'
import CarriersKRRoutes from '../../CarriersKR/routes'
import { RemoveTripModal } from '../../CarriersKR/RemoveTripModal/RemoveTripModal'
import {
  ItemCard,
  CardButtonWrapper,
  CardContentWrapper,
  GroupInfoWrapper,
  TextCard,
} from 'views/styled/ItemCardStyles'
import { CommonTagsWrapper } from 'views/styled/common'
import { DASH } from 'modules/constants'

type CardProps = {
  trip: LogisticsKRTrip
  onSuccess: refetchFunc
}

export const DraftTripCard: FC<CardProps> = ({ trip, onSuccess }) => {
  const { t } = useTranslation('logisticBids')
  const { car, trailer, driver } = trip
  const [confirmRemoveModal, setConfirmRemoveModal] = useState(false)

  const currency = env.REACT_APP_CURRENCY_SYMBOL
  const formatDate = useFormatDate()
  return (
    <ItemCard withoutHighlight>
      <CardButtonWrapper>
        <AButton id="cancelTrip" intent="white" Icon={Icons.IconRemove} onClick={() => setConfirmRemoveModal(true)} />
      </CardButtonWrapper>
      <CardContentWrapper>
        <CommonTagsWrapper>
          <AntTag color="white">{t(`tags.${trip.deal_type}`)}</AntTag>
        </CommonTagsWrapper>
        {!!trip.logistician && (
          <ARouterLink
            eventName="logistician"
            itemId={trip.logistician?.id}
            to={generatePath(UserRoutes.Details, { id: trip.logistician?.id })}
          >
            {trip.logistician?.full_name ?? trip.logistician?.short_name}
          </ARouterLink>
        )}
        {trip?.agent && (
          <GroupInfoWrapper>
            <TextCard>{trip?.agent?.name}</TextCard>
            <TextCard>
              {`${trip.has_agent_vat ? t('vat.with_vat') : t('vat.not_vat')}, ${formatPrice(
                trip?.agent_commission,
              )} ${t('common:rubPerKg', { currency })}`}
            </TextCard>
          </GroupInfoWrapper>
        )}
        <GroupInfoWrapper>
          <ARouterLink
            eventName="carrier"
            itemId={trip.carrier?.id}
            to={generatePath(CarriersKRRoutes.Details, { id: trip.carrier?.id })}
          >
            {trip?.carrier?.name || DASH}
          </ARouterLink>
          <APhone phone={trip.carrier?.phone ?? DASH} />
          <TextCard>{trip?.has_vat ? t('vat.with_vat') : t('vat.not_vat')}</TextCard>
          <TextCard>{`${car?.brand_model} ${car?.licence_number} / ${trailer?.licence_number || DASH}`}</TextCard>
          <TextCard>{driver?.name}</TextCard>
        </GroupInfoWrapper>
        <TextCard thumbnail>{`${formatDate(trip.load_date) || DASH}, ${formatPrice(trip.price_logistics) || DASH} ${t(
          'common:rubPerKg',
          { currency },
        )}`}</TextCard>
      </CardContentWrapper>
      {confirmRemoveModal && <RemoveTripModal tripId={trip.id} onSuccess={onSuccess} onClose={setConfirmRemoveModal} />}
    </ItemCard>
  )
}
