import { FC, useEffect, useState } from 'react'

import { generatePath } from 'react-router-dom'

import { BoardContainer } from 'views/pages/LogisticsKR/components/Board/BoardContainer'
import { Column, ColumnContent } from 'views/pages/LogisticsKR/components/Board/Column'
import { useTableData } from 'modules/domain/common/hooks'
import { endpoints } from 'modules/endpoints'
import { Item } from 'views/pages/LogisticsKR/components/Board/ColumnItem'
import DealsKRRoutes from 'views/pages/LogisticsKR/LogisticRequests/routes'
import { LogisticRequest } from 'modules/domain/logisticsKR/types'
import { useTranslation } from 'react-i18next'
import { ListRequestParams } from 'modules/domain/types'

type extendedRequest = Item & LogisticRequest

const ItemComponent: FC<{ item: Item }> = ({ item }) => {
  const { t } = useTranslation('logisticsKR')
  const request = item as extendedRequest
  return (
    <div>
      <span>{request.product.name},</span>
      <div>
        {request.quantity} {t('common:t')}
      </div>
      <div>{request.loading_warehouse.address}</div>
      <div>{request.unloading_warehouse.address}</div>
    </div>
  )
}

type Props = {
  listRequestParams: ListRequestParams
}

export const CustomerKRBoard: FC<Props> = ({ listRequestParams }) => {
  const { t } = useTranslation('logisticsKR')
  const { progress, data: requests, total } = useTableData(endpoints.LogisticsKR.requests(), listRequestParams, true)
  const [items, setItems] = useState<Array<Item>>([])
  useEffect(() => {
    if (!requests) return
    const trasformedRequests: Array<Item> = requests.map((r, index) => ({
      ...r,
      btnLink: generatePath(DealsKRRoutes.Details, { id: r.id }),
      isSelected: index === 0 ? true : false,
      onClick: () => {
        setItems((prevItems) => prevItems.map((i) => ({ ...i, isSelected: i.id === r.id ? true : false })))
      },
    }))
    setItems(trasformedRequests)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requests])

  return (
    <BoardContainer>
      <Column>
        <ColumnContent
          items={items}
          count={total}
          progress={progress}
          ItemComponent={ItemComponent}
          title={t('customers.details.requests')}
          initialOpened
        />
      </Column>
    </BoardContainer>
  )
}
