import { FC, useState } from 'react'

import { useTranslation } from 'react-i18next'

import { CountryPhoneInput, FormGroup, Progress, defaultTheme } from '@agro-club/agroclub-shared'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import i18n from 'i18n'
import { getCountryCodes, getDefaultCountryCode } from 'views/pages/Auth/countryCodes'

import { FieldWrapper, FormWrapper, InputLabel, InputWrapper } from './styled'
import { useAFormHandler } from 'analytics/hooks'
import { AButton } from 'views/components/Analytics'
import { apiClient } from 'modules/utils/httpClient'
import { endpoints } from 'modules/endpoints'
import { TokenResponse } from 'modules/domain/auth/managers'
import { useMediaQuery } from 'react-responsive'

export const requestCode = (payload) => apiClient.post<TokenResponse>(endpoints.getCode(), payload)

type FormFields = {
  phone: string
}

const validationSchema = Yup.object({
  phone: Yup.string().required(i18n.t('validation:phone_required')),
})

const getDefaultPrefix = () => {
  const countryOptions = getCountryCodes()
  const defaultCountryCode = getDefaultCountryCode()
  const prefix = countryOptions.find((country) => country.id === defaultCountryCode)?.phone_prefix
  return `+${prefix ?? ''}`
}
const countryOptions = getCountryCodes()
const defaultCountryCode = getDefaultCountryCode()

const PhoneForm: FC<{ onSuccess: (phone: string) => void }> = ({ onSuccess }) => {
  const { t } = useTranslation(['auth', 'common'])
  const { formProgress, formHandler } = useAFormHandler('sendPhone')

  const [phonePrefix, setPhonePrefix] = useState(getDefaultPrefix)

  const formik = useFormik<FormFields>({
    initialValues: { phone: '' },
    validationSchema,
    onSubmit: formHandler(
      async () => {
        await requestCode(formik.values)
      },
      {
        onSuccess: () => onSuccess(formik.values.phone),
        onError: (error) => {
          const { errors } = error
          if (errors.phone) {
            formik.setErrors(errors)
          } else {
            formik.setErrors({ phone: errors[0] })
          }
        },
      },
    ),
  })

  const isPhoneEntered = formik.values.phone.length > 0 && formik.values.phone !== phonePrefix
  const isMobile = useMediaQuery({
    query: `(max-width: ${defaultTheme.queries.mobile})`,
  })

  return (
    <FormWrapper>
      <FieldWrapper>
        <InputWrapper style={{ width: '100%' }}>
          <InputLabel>{t('common:phoneNumber')}</InputLabel>
          <FormGroup error={formik.errors.phone} disableRightPadding>
            <CountryPhoneInput
              defaultCountryCode={defaultCountryCode}
              countryOptions={countryOptions}
              name="phone"
              phoneNumber={formik.values.phone}
              onChange={(phone: string, callingCode) => {
                setPhonePrefix(callingCode)
                formik.setFieldValue('phone', phone)
              }}
              onKeyUp={(e) => {
                if (e.key === 'Enter') formik.submitForm()
              }}
              invalid={formProgress === Progress.ERROR}
              disableRightPadding
              autoFocus
            />
          </FormGroup>
        </InputWrapper>
      </FieldWrapper>

      <AButton
        size={isMobile ? 'biggest' : 'big'}
        type="submit"
        intent="primary"
        disabled={!formik.isValid || !isPhoneEntered}
        progress={formProgress}
        id="submit"
        onClick={() => formik.submitForm()}
      >
        {t('auth:next')}
      </AButton>
    </FormWrapper>
  )
}

export default PhoneForm
