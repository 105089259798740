import { useCallback, useEffect, useState } from 'react'
import { ListRequestParams, ParamLoadSteps } from 'modules/domain/types'
import { defaultListRequestParams, initialListRequestParams } from 'views/components/TableFilters/data'
import useUpdateLocationQuery from './useUpdateLocationQuery'
import _ from 'lodash'
import { ListRequestedParams } from 'modules/domain/specification/types'

export type TabInitialData = object & {
  extraParams?: Partial<ListRequestParams>
}

export type TabsData = {
  isFiltersLoaded: boolean
  commonRequestParams: ListRequestParams
  updateCommonFilterState: (filterObj: Record<string, any>) => void
  // looks like we dont need to export setCommonRequestParams. updateCommonFilterState - should be enough
  /** @deprecated */
  setCommonRequestParams: (params: ListRequestParams) => void
  tablesParams: Record<string, ListRequestParams>
  tablesSetters: Record<string, (ListRequestParams) => void>
  clearAllTabsState: () => void
}

export const useTabsData = (
  activeTab: string,
  initialTabsData: Record<string, TabInitialData>,
  defaultParams?: object,
): TabsData => {
  const [commonRequestParams, setCommonRequestParams] = useState<ListRequestParams>({
    ...initialListRequestParams,
  })
  const [paramsForUrl, setParamsForUrl] = useState<ListRequestParams>({
    ...initialListRequestParams,
  })
  const initialTabsSettings: Record<string, ListRequestParams> = {}
  for (const key of Object.keys(initialTabsData)) {
    initialTabsSettings[key] = { ...initialListRequestParams }
  }
  const [tablesParams, setTablesParams] = useState<Record<string, ListRequestParams>>(initialTabsSettings)
  const tablesSetters: Record<string, (ListRequestParams) => void> = {}

  Object.keys(initialTabsData).forEach((key) => {
    // keys list is the same each render - so we can trust useCallback call order
    // eslint-disable-next-line react-hooks/rules-of-hooks
    tablesSetters[key] = useCallback((singleTableListParams: ListRequestParams) => {
      setTablesParams((prev) => ({ ...prev, [key]: singleTableListParams }))
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
  })

  const [isFiltersLoaded, setIsFiltersLoaded] = useState(false)

  const isFiltersLoadedFromUrl = useUpdateLocationQuery({
    listRequestParams: paramsForUrl,
    setListRequestParams: setParamsForUrl,
  })

  useEffect(() => {
    if (!isFiltersLoadedFromUrl) return

    let newCommon = { ...commonRequestParams, filter: paramsForUrl.filter, loadStep: ParamLoadSteps.Loaded }
    const initTables = () => {
      const modifiedTableData = {}
      for (const [key] of Object.entries(initialTabsData)) {
        const isActiveTab = key === activeTab
        const extraParams = initialTabsData[key].extraParams ? initialTabsData[key].extraParams : {}
        const extraFilters = initialTabsData[key].extraParams?.filter ? initialTabsData[key].extraParams?.filter : {}
        modifiedTableData[key] = {
          ...extraParams,
          filter: { ...newCommon.filter, ...extraFilters },
          page: isActiveTab ? paramsForUrl.page : 1,
          sort: isActiveTab ? paramsForUrl.sort : undefined,
          loadStep: ParamLoadSteps.Loaded,
        }
      }
      setTablesParams(modifiedTableData)
    }
    const isFilterEmpty = !Object.keys(paramsForUrl.filter).length
    if (!isFilterEmpty || (isFilterEmpty && paramsForUrl.page > 1)) {
      setCommonRequestParams(newCommon)
      initTables()
      return
    }

    if (Object.keys(defaultParams || {}).length) {
      newCommon = {
        ...newCommon,
        filter: {
          ...commonRequestParams.filter,
          ...defaultParams,
        },
      }
    }
    setCommonRequestParams(newCommon)
    initTables()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFiltersLoadedFromUrl])

  useEffect(() => {
    if (
      commonRequestParams.loadStep === ParamLoadSteps.Loaded &&
      Object.values(tablesParams)[0].loadStep === ParamLoadSteps.Loaded
    ) {
      setIsFiltersLoaded(true)
    }
  }, [commonRequestParams, tablesParams])

  useEffect(() => {
    if (!isFiltersLoaded) return
    const params = tablesParams[activeTab]
    const extraParams = initialTabsData[activeTab].extraParams
    const paramsToOmit = extraParams ? Object.keys(extraParams) : []
    const filtersToOmit = extraParams?.filter ? Object.keys(extraParams.filter) : []
    const urlParams = {
      ..._.omit(params, paramsToOmit),
      filter: { ..._.omit(params.filter, filtersToOmit) },
    } as ListRequestedParams
    setParamsForUrl(urlParams)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, tablesParams, isFiltersLoaded])

  const clearAllTabsState = () => {
    const modfied: Record<string, ListRequestParams> = {}
    setCommonRequestParams({ ...defaultListRequestParams })
    setParamsForUrl({ ...defaultListRequestParams })
    // we need to reset each table to default to drop sorting
    for (const key of Object.keys(initialTabsData)) {
      const extraParams = initialTabsData[key].extraParams ? initialTabsData[key].extraParams : {}
      modfied[key] = { ...defaultListRequestParams, ...extraParams }
    }
    setTablesParams(modfied)
  }

  const updateCommonFilterState = useCallback((filterObj: Record<string, any>) => {
    setCommonRequestParams((prev) => ({
      ...prev,
      page: 1,
      filter: {
        ...prev.filter,
        ...filterObj,
      },
    }))
    setTablesParams((prev) => {
      const modifiedTableData = {}
      for (const [key] of Object.entries(initialTabsData)) {
        modifiedTableData[key] = {
          ...prev[key],
          filter: {
            ...prev[key].filter,
            ...filterObj,
          },
          page: 1,
        }
      }
      return modifiedTableData
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    isFiltersLoaded,
    commonRequestParams,
    updateCommonFilterState,
    setCommonRequestParams,
    tablesParams,
    tablesSetters,
    clearAllTabsState,
  }
}
