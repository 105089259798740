import { CheckboxV2 } from '@agro-club/agroclub-shared'
import { useAnalyticsContext } from 'analytics/hooks'
import { FC } from 'react'

interface Props {
  disabled?: boolean
  checked?: boolean
  label?: string
  id: string
  onChange?: (any) => void
}

const ACheckboxV2: FC<Props> = ({ id, checked, onChange, ...otherProps }) => {
  const { track } = useAnalyticsContext()

  const handleChange = (e: any) => {
    onChange?.(e)

    track(`change|${id}`, {
      value: {
        from: checked,
        to: e.target.checked,
      },
    })
  }

  return <CheckboxV2 id={id} checked={checked} onChange={handleChange} {...otherProps} />
}

export default ACheckboxV2
