import { AvailableLanguages, defaultTheme, Typography } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

import logoText from 'assets/images/logo-text.svg'
import logo from 'assets/images/logo-img.svg'
import logoTextRu from 'assets/images/logo-text-ru.svg'
import { box } from 'views/styled/common'

import { REACT_APP_LOCALE } from 'env'
import { FC } from 'react'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding-top: 190px;
  padding-bottom: 20px;
  background: ${defaultTheme.color.backgroundPrimary};
`

const Copyright = styled.div`
  ${Typography.bodyMedium}
  color: ${defaultTheme.color.textSecondary60a};
`

const Logo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 22px;
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 380px;
  padding: 62px 44px 56px;
  margin-bottom: 40px;
  ${box}
`

export const AuthLayout: FC<{ children?: React.ReactNode }> = ({ children }) => {
  return (
    <Wrapper>
      <ContentWrapper>
        <Logo>
          <img src={logo} alt="logo" width="104" />
          <img src={REACT_APP_LOCALE === AvailableLanguages.ru ? logoTextRu : logoText} alt={logoText} />
        </Logo>
        {children}
      </ContentWrapper>
      <Copyright>© Agro.Club, Inc</Copyright>
    </Wrapper>
  )
}
