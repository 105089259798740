import { RespFile } from 'modules/domain/types'
import { CompanySize, ProfileType } from '../user/types'

export type FactoringRequest = {
  id: string
  company_id: string
  status: FactoringStatus
  document?: RespFile
  document_pdf?: RespFile
  factoring_type: FactoringTypes
}
export enum CompanyCommercial {
  yes = 'yes',
  no = 'no',
  unknown = 'unknown',
}

// factoring type mostly the same as ProfileType, but less options
export enum FactoringTypes {
  seller = 'seller',
  customer = 'customer',
}

export type Company = {
  id: string
  inn: string
  name: string
  created: string
  payment_delay_days: number
  prepayment?: keyof typeof Prepayment
  vat?: boolean
  cooperation_type_agent: boolean
  cooperation_type_trader: boolean
  state_reg_number?: string
  has_shipped_deals?: boolean
  factoring_requests?: FactoringRequest[]
  factoring_seller_status: FactoringStatus
  factoring_customer_status: FactoringStatus
  turnover_days: number
  soy_restriction?: boolean
  company_size?: CompanySize | null
  company_member_types?: ProfileType[]
  commercial: CompanyCommercial
}

export type CompanyShort = {
  id: string
  inn: string
  name: string
  factoring_seller_status?: FactoringStatus
  factoring_customer_status?: FactoringStatus
}

export enum Prepayment {
  yes = 'yes',
  no = 'no',
  unknown = 'unknown',
}

export type CompanyDTO = Partial<Nullable<Company, 'inn' | 'name'>>
export enum ActivityType {
  processing = 'processing ',
  export = 'export',
}

export type CompanyExtraData = {
  id: string
  company_id: string
  activity_type: ActivityType | ''
  employees_count: number
  established_at: string // "1995-02-21"
  has_agro_arbitration_cases: boolean | null
  arbitration_claims_sum: number | null
  has_enforcement_proceeding: boolean | null
  enforcement_proceeding_sum: number
  has_blocked_bank_accounts: boolean | null
  directors_companies_have_problems: boolean | null
  connected_companies_have_problems: boolean | null
  last_accounting_statements_date: string // '2017-01-01'
  revenue: number
  profit: number
  equity: number
  current_assets: number
  fixed_assets: number
  current_liabilities: number
  has_all_documents: boolean
  paid_us_without_delay_amount: number | null
  okved_code: string
  okved_text: string
  kontur_brief_report: RespFile | null
}

export enum FactoringStatus {
  not_verified = 'not_verified',
  in_progress = 'in_progress',
  cancelled = 'cancelled',
  accepted = 'accepted',
}
