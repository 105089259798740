import { FC, ReactNode } from 'react'
import { AntTag, Icons } from '@agro-club/agroclub-shared'

export const DocumentStatus: FC<{ ready: boolean; children?: ReactNode }> = ({ ready, children }) => {
  return (
    <AntTag color={ready ? 'blue' : 'grey'} icon={ready ? <Icons.IconArrowCheck /> : <Icons.IconTime />}>
      {children}
    </AntTag>
  )
}
