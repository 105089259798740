import { FC } from 'react'
import { Text } from '@agro-club/agroclub-shared'

interface Prop {
  name: string
  logistician_name?: string
}

export const RegionCell: FC<Prop> = ({ name, logistician_name }) => {
  return (
    <>
      <Text typography="bodyMedium" color="primary">
        {name}
      </Text>
      {logistician_name && (
        <Text typography="bodySmall" color="secondary">
          {logistician_name}
        </Text>
      )}
    </>
  )
}
