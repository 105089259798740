import { defaultTheme } from '@agro-club/agroclub-shared'
import { BidTypes } from 'modules/domain/bid/types'
import { FC } from 'react'
import { useMediaQuery } from 'react-responsive'
import styled from 'styled-components'
import { useDealPartyChange } from '../../hooks/useDealPartyChange'
import { BidDetailBlock } from '../BidDetailBlock'
import { BidWarehouseBlock } from '../BidDetailBlock/BidWarehouseBlock/BidWarehouseBlock'
import { useDealContext } from '../DealContext'
import { MobileBids } from './MobileBids'

const BidDetailsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 495px);
  column-gap: 16px;
`
export const Bids: FC = () => {
  const { deal, dealRefetch, whMovements } = useDealContext()
  const partyChanger = useDealPartyChange({ deal })

  const isVisibleWarehouse = deal.purchase_bid.is_for_warehouse && deal.has_warehouse_movements
  const isMobile = useMediaQuery({
    query: `(max-width: ${defaultTheme.queries.mobile})`,
  })

  return (
    <>
      {isMobile ? (
        <MobileBids whMovements={whMovements} dealRefetch={dealRefetch} partyChanger={partyChanger} />
      ) : (
        <BidDetailsWrapper>
          <BidDetailBlock bidType={BidTypes.sale} partyChanger={partyChanger} />
          {isVisibleWarehouse ? (
            <BidWarehouseBlock whMovements={whMovements} dealId={deal.id} dealRefetch={dealRefetch} />
          ) : (
            <BidDetailBlock bidType={BidTypes.purchase} partyChanger={partyChanger} />
          )}
        </BidDetailsWrapper>
      )}
    </>
  )
}
