import { AntTag, AntTooltip, defaultTheme, Text, Typography } from '@agro-club/agroclub-shared'
import { isUS, isUserProductTagsEnabled } from 'env'
import useFormatDate from 'hooks/useFormatDate'
import { t } from 'i18next'
import { Profile, ProfileType, User } from 'modules/domain/user/types'
import { getUserName } from 'modules/domain/user/utils'
import { ColumnData } from 'modules/types'
import { getPersonalNumberLabel } from 'modules/utils/numbers/formatPersonalNumber'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router-dom'
import styled from 'styled-components'
import { MoratoriumTag } from 'views/components/Tags/CompanyTag'
import {
  CoordinatorVerifiedUserIcon,
  KeyUserIcon,
  KycVerifiedUserIcon,
  SecurityVerifiedUserIcon,
  UserHasDealsIcon,
  VerifiedUserIcon,
} from 'views/components/TooltipIcons/TooltipIcons'
import { TruncatedValue, TruncatedValueWrapper } from 'views/components/TruncatedValue/TruncatedValue'
import CompanyRoutes from 'views/pages/Company/routes'
import { EntityWithIconsWrapper } from 'views/styled/common'
import APhone from 'views/ui/Phone/APhone'
import { TableTextLink } from 'views/ui/TableTextLink/TableTextLink'
import { CompanyCommercialTag } from '../Company/CompanyCommercialTag'
import UserRoutes from './routes'
import { CompanySizeTag } from './UserDetail/CompanySizeTag'
import { PHONE_TABLE_CELL_WIDTH } from 'modules/constants'

const NameWrapper = styled.div`
  svg {
    width: 16px;
    height: 16px;
    margin-bottom: -4px;
    margin-right: 5px;
  }
`

const CoordinatorType = styled.div`
  ${Typography.bodySmall};
  color: ${defaultTheme.color.secondaryPlaceholder};
`

export const getUserColumns = () => {
  const optionalColumns: ColumnData[] = []

  if (!isUS && isUserProductTagsEnabled) {
    optionalColumns.push({
      title: t('user:list.tableHeaders.product_tags'),
      width: 260,
      dataIndex: 'profile',
      key: 'product_tags',
      render: (profile?: Profile) => profile?.product_tags?.map((t) => t.title).join(', '),
    })
  }

  return optionalColumns
}

interface CellProps {
  value: User
}

const NameCell: FC<CellProps> = ({ value }) => {
  const profile = value?.profile
  const isSellerOrCustomer =
    profile?.profile_type === ProfileType.seller || profile?.profile_type === ProfileType.customer
  const name = getUserName(value as User)
  const isActive = value?.is_active
  const isVerified = profile?.is_verified && isActive && isSellerOrCustomer
  const isVerifiedbyCoordinator = !isVerified && profile?.is_verified_by_coordinator && isActive && isSellerOrCustomer
  const isVerifiedbySecurity = !isVerified && profile?.is_verified_by_security && isActive && isSellerOrCustomer
  const isVerifiedbyKyc = !isVerified && profile?.is_verified_by_kyc && isActive && isSellerOrCustomer
  const isHasShippedDeals = profile?.has_shipped_deals

  return (
    <NameWrapper>
      <TableTextLink
        target="_blank"
        to={generatePath(UserRoutes.Details, { id: value.id })}
        id="user"
        tableItemId={value.id}
      >
        {name}
      </TableTextLink>
      &nbsp;
      <KeyUserIcon isVisible={profile?.is_significant} />
      <VerifiedUserIcon isVisible={isVerified} />
      <CoordinatorVerifiedUserIcon isVisible={isVerifiedbyCoordinator} />
      <SecurityVerifiedUserIcon isVisible={isVerifiedbySecurity} />
      <KycVerifiedUserIcon isVisible={isVerifiedbyKyc} />
      <UserHasDealsIcon isVisible={isHasShippedDeals} />
    </NameWrapper>
  )
}

const CompanyCell: FC<CellProps> = ({ value }) => {
  const company = value?.profile?.company

  return company ? (
    <EntityWithIconsWrapper>
      <TruncatedValue hint={company?.name}>
        <TableTextLink
          id="company"
          tableItemId={company?.id || ''}
          to={generatePath(CompanyRoutes.Details, { id: company?.id || '' })}
        >
          {company?.name}
        </TableTextLink>
      </TruncatedValue>
      <MoratoriumTag isVisible={company.soy_restriction} />
    </EntityWithIconsWrapper>
  ) : (
    '—'
  )
}

const RoleCell: FC<CellProps> = ({ value }) => {
  const { t } = useTranslation('user')
  return (
    <>
      {value?.profile?.role ? t(`role.${value?.profile?.role}`) : '—'}
      {value?.profile?.coordinator_type && (
        <CoordinatorType>{t(`coordinator_type.${value?.profile?.coordinator_type}`)}</CoordinatorType>
      )}
    </>
  )
}

const CommentWrapper = styled.div`
  max-width: 400px;
`
const DateText = styled(Text)`
  color: ${defaultTheme.color.secondary200};
`
const CommentText = styled(Text)`
  white-space: pre-line;
  word-break: break-word;
`
const StyledTooltip = styled(AntTooltip)`
  cursor: pointer;
`
const Tags = styled.div`
  display: flex;
  gap: 0 4px;
`

const CommentsCell: FC<CellProps> = ({ value }) => {
  const formatDate = useFormatDate()

  if (!value.comments.length) return <>—</>

  const tooltipContent = (
    <CommentWrapper>
      <DateText typography="bodySmall">{formatDate(value.comments[0].modified, true)}</DateText>
      <CommentText typography="bodyMedium">{value.comments[0].comment}</CommentText>
    </CommentWrapper>
  )

  return (
    <StyledTooltip tooltipContent={tooltipContent} variant="secondary" placement="bottom">
      <Text color="secondary" typography="bodySmall">
        {formatDate(value.comments[0].modified, true)}
      </Text>
      <TruncatedValueWrapper>{value.comments[0].comment}</TruncatedValueWrapper>
    </StyledTooltip>
  )
}

const StatusCell: FC<CellProps> = ({ value }) => {
  const { t } = useTranslation()
  return (
    <Tags>
      <AntTag color={value.is_active ? 'green' : 'grey'}>
        {value.is_active ? t('user:list.isActive') : t('user:list.inActive')}
      </AntTag>
      <CompanySizeTag companySize={value?.profile?.company_size} />
      <CompanyCommercialTag commercial={value?.profile?.company?.commercial} />
    </Tags>
  )
}

export const useColumns = (): ColumnData[] => {
  const { t } = useTranslation('user')
  const formatDate = useFormatDate()

  return useMemo(
    () =>
      [
        !isUS && {
          title: t('list.tableHeaders.dateJoined'),
          width: 140,
          dataIndex: 'date_joined',
          render: (value) => formatDate(value),
        },
        {
          title: t('user:list.tableHeaders.status'),
          width: 180,
          dataIndex: 'is_active',
          render: (_, row) => <StatusCell value={row} />,
        },
        ...getUserColumns(),
        {
          title: t('user'),
          width: 302,
          dataIndex: 'full_name',
          render: (_value, row) => <NameCell value={row} />,
        },
        {
          title: t('list.tableHeaders.phone'),
          width: PHONE_TABLE_CELL_WIDTH,
          dataIndex: 'phone',
          render: (value, row: User) => (
            <APhone phone={value} shortBtnTitle className="phone-link-table" isMobileShort={row.is_mobile_phone} />
          ),
        },
        {
          title: t('list.tableHeaders.company'),
          width: 302,
          dataIndex: 'company_name',
          render: (_value, row) => <CompanyCell value={row} />,
        },
        !isUS && {
          title: t('list.tableHeaders.inn_company', { innName: getPersonalNumberLabel() }),
          width: 120,
          dataIndex: 'company_inn',
          render: (_value, row) => row?.profile?.company?.inn,
        },

        {
          title: t('list.tableHeaders.profile_type'),
          width: 120,
          dataIndex: 'profile_type',
          render: (_value, row) => row?.profile?.profile_type && t(`profile_type.${row?.profile?.profile_type}`),
        },
        isUS && {
          title: t('list.tableHeaders.comments'),
          width: 262,
          dataIndex: 'comments',
          render: (_value, row) => <CommentsCell value={row} />,
        },
        isUS && {
          title: t('list.tableHeaders.days'),
          width: 80,
          dataIndex: 'days_since_last_comment',
          sorter: true,
          render: (_value) => (_value !== null ? String(_value) : '—'),
        },
        {
          title: t('list.tableHeaders.role'),
          width: 260,
          dataIndex: 'role',
          render: (_value, row) => <RoleCell value={row} />,
        },
        {
          title: t('list.tableHeaders.team'),
          width: 100,
          dataIndex: 'team',
          render: (_value, row) => row?.profile?.team?.name,
        },
      ].filter(Boolean) as ColumnData[],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, formatDate],
  )
}
