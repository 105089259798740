import { FC, Fragment } from 'react'
import { Deal } from 'modules/domain/deal/types'
import { ProductPurpose } from 'modules/domain/documentRequest/types'
import { useTranslation } from 'react-i18next'
import { Divider, TagsWrapper } from '../styled'
import { DocumentStatus } from '../DocumentStatusTag'
import { useMediaQuery } from 'react-responsive'
import { defaultTheme } from '@agro-club/agroclub-shared'

interface Props {
  deal: Deal
}

export const DealDocumentRequestStatusTags: FC<Props> = ({ deal }) => {
  const { t } = useTranslation('deal')

  const dsTag = deal.documents_request?.request_ds && (
    <DocumentStatus ready={deal.documents_request.received_ds}>{t('requestDocumentsBlock.ds')}</DocumentStatus>
  )
  const quarantineTag = deal.documents_request?.request_quarantine && (
    <DocumentStatus ready={deal.documents_request.received_conclusion}>
      {t('requestDocumentsBlock.quarantine')}
    </DocumentStatus>
  )
  const vetTag = deal.documents_request?.product_purpose === ProductPurpose.feed &&
    deal.documents_request.request_vet && (
      <DocumentStatus ready={deal.documents_request.received_vet}>{t('requestDocumentsBlock.vet')}</DocumentStatus>
    )
  const sdizTag = deal.documents_request?.request_sdiz && (
    <DocumentStatus ready={deal.documents_request.received_sdiz}>{t('requestDocumentsBlock.sdiz')}</DocumentStatus>
  )

  const withoutDocsTag = deal.without_documents_request && (
    <DocumentStatus ready={deal.without_documents_request}>{t('requestDocumentsBlock.withoutDocs')}</DocumentStatus>
  )

  const statusTags = [dsTag, quarantineTag, vetTag, sdizTag, withoutDocsTag].filter((item) => item)
  const isMobile = useMediaQuery({
    query: `(max-width: ${defaultTheme.queries.mobile})`,
  })

  return (
    <TagsWrapper>
      {statusTags.map((item, index) => (
        <Fragment key={index}>
          {!!index && !isMobile && <Divider>—</Divider>}
          {item}
        </Fragment>
      ))}
    </TagsWrapper>
  )
}
