import { Routes, Route } from 'react-router-dom'

import { CompanyDetail } from 'views/pages/Company/CompanyDetail/CompanyDetail'

import CompanyList from './CompanyList'
import CompanyRoutes from './routes'

function CompanyPage() {
  return (
    <Routes>
      <Route index element={<CompanyList />} />
      <Route path={CompanyRoutes.relativeDetail} element={<CompanyDetail />} />
    </Routes>
  )
}

export default CompanyPage
