import { Route, Routes } from 'react-router-dom'
import CustomerKRDetails from './CustomerKRDetails/CustomerKRDetails'
import CustomersKRList from './CustomersKRList/CustomersKRList'
import CustomersKRRoutes from './routes'
import { FC } from 'react'

const CustomersKRPage: FC = () => (
  <Routes>
    <Route index element={<CustomersKRList />} />
    <Route path={CustomersKRRoutes.relativeDetails} element={<CustomerKRDetails />} />
  </Routes>
)
export default CustomersKRPage
