import { t } from 'i18next'

export const formatBoolArr = (values: (boolean | undefined | null)[], trueTranslations: string[]) => {
  const result = values.reduce<string[]>((arr, val, index) => {
    if (val) arr.push(trueTranslations[index])
    return arr
  }, [])

  if (result.length === 0) {
    return t('common:unknown')
  }
  return result.join(', ')
}
