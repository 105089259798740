import React from 'react'
import useLanguage from 'hooks/useLanguage'
import { AntRangePicker, RangeDateProps } from '@agro-club/agroclub-shared'

type Props = Omit<RangeDateProps, 'locale'>

export const DateRangePicker: React.VFC<Props> = (props) => {
  const locale = useLanguage()
  return <AntRangePicker locale={locale} {...props} />
}
