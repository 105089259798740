import styled from 'styled-components'
import { Deal } from 'modules/domain/deal/types'
import { useTranslation } from 'react-i18next'
import formatPrice from 'modules/utils/numbers/formatPrice'

const TooltipContentWrapper = styled.div`
  display: grid;
  column-gap: 8px;
  grid-template-columns: 1fr auto;
  max-width: 320px;
`

const AlignLeftWrapper = styled.div`
  text-align: left;
`

const AlignRightWrapper = styled.div`
  text-align: right;
`

type TooltipContentProps = {
  deal: Deal
}

export const QuestionTooltipContent = ({ deal }: TooltipContentProps) => {
  const { t } = useTranslation('deal')

  return (
    <TooltipContentWrapper>
      <AlignLeftWrapper>
        <div>{t('margin_tooltip.revenue')}</div>
        <div>{t('margin_tooltip.logistics_costs')}</div>
        <div>{t('margin_tooltip.purchase_costs')}</div>
        <div>{t('margin_tooltip.non_operating_costs')}</div>
      </AlignLeftWrapper>
      <AlignRightWrapper>
        <div>{formatPrice(deal.revenue, true)}</div>
        <div>{formatPrice(deal.logistics_costs, true)}</div>
        <div>{formatPrice(deal.purchase_costs, true)}</div>
        <div>{formatPrice(deal.non_operating_costs, true)}</div>
      </AlignRightWrapper>
    </TooltipContentWrapper>
  )
}
