import { ALinkMapWithRoute } from 'views/components/Maps/ALinkMapWithRoute'
import { formatDistance } from 'modules/utils/numbers/formatDistance'
import { Icons, defaultTheme } from '@agro-club/agroclub-shared'
import { KeyValue } from 'views/components/KeyValue/KeyValue'
import { Deal } from 'modules/domain/deal/types'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { FC } from 'react'

type Props = {
  deal: Deal
  isMobile?: boolean
}

const Wrapper = styled.div`
  display: flex;
  gap: 0 8px;
`

const LinkWithIconWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.color.primary600};
`

const IconWrapper = styled.div`
  display: flex;
  align-items: center;

  svg {
    height: 14px;

    path {
      fill: ${({ theme }) => theme.color.secondary900};
    }
  }
`
const MobileWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0 8px;
  padding: 8px;
  background: ${defaultTheme.color.backgroundSecondary};
  border-radius: 8px;
  svg {
    path {
      fill: ${defaultTheme.color.primary600};
    }
  }
`
const TextDistance = styled.div`
  color: ${defaultTheme.color.primary600};
`

export const MapButtons: FC<Props> = ({ deal, isMobile }) => {
  const { t } = useTranslation('deal')
  const mapsButtonData = [
    {
      Icon: Icons.IconCar,
      d: deal.distance,
      label: t('remotenessCar'),
    },
  ]

  if (deal.parameterized_distance) {
    mapsButtonData.push({
      Icon: Icons.IconShipping,
      d: deal.parameterized_distance,
      label: t('remotenessFreightCar'),
    })
  }
  const fromCoordinates: [number, number] = [deal.sale_bid.address.latitude, deal.sale_bid.address.longitude]
  const toCoordinates: [number, number] = [deal.purchase_bid.address.latitude, deal.purchase_bid.address.longitude]

  return (
    <>
      {isMobile ? (
        mapsButtonData.map(({ Icon, d, label }, index) => (
          <ALinkMapWithRoute key={index} id="distance" from={fromCoordinates} to={toCoordinates}>
            <MobileWrapper>
              <KeyValue label={label}>
                <TextDistance>{formatDistance(d)}</TextDistance>
              </KeyValue>
              <Icon />
            </MobileWrapper>
          </ALinkMapWithRoute>
        ))
      ) : (
        <Wrapper>
          {mapsButtonData.map(({ Icon, d }, index) => (
            <LinkWithIconWrapper key={index}>
              <IconWrapper>
                <Icon />
              </IconWrapper>
              <ALinkMapWithRoute id="distance" key={index} from={fromCoordinates} to={toCoordinates}>
                {formatDistance(d)}
              </ALinkMapWithRoute>
            </LinkWithIconWrapper>
          ))}
        </Wrapper>
      )}
    </>
  )
}
