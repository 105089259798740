import { CheckboxV2, FormGroup, Input } from '@agro-club/agroclub-shared'
import { useTranslation } from 'react-i18next'
import { FC } from 'react'
import { getPhonePlaceholder } from 'modules/utils/numbers/formatPersonalNumber'
import { ShortFormControl } from 'views/styled/common'
import { FormikFieldsType } from 'modules/domain/types'
import styled from 'styled-components'
import { isUS } from 'env'

export const InputWithCheckbox = styled.div`
  display: flex;
  align-items: center;
  column-gap: 12px;
`
interface Props {
  formik: FormikFieldsType
  isEdit?: boolean
}

export const FormPhoneInput: FC<Props> = ({ formik, isEdit }) => {
  const { t } = useTranslation()
  const inputJSX = (
    <Input
      placeholder={getPhonePlaceholder()}
      {...formik.getFieldProps('phone')}
      invalid={!!formik.errors.phone}
      disabled={isEdit}
    />
  )
  if (isUS) {
    return (
      <ShortFormControl width={488}>
        <FormGroup label={t('common:phoneNumber')} error={formik.errors.phone} required>
          <InputWithCheckbox>
            <div>{inputJSX}</div>

            <CheckboxV2
              checked={formik.values.is_mobile_phone}
              onChange={() => {
                formik.setFieldValue('is_mobile_phone', !formik.values.is_mobile_phone)
              }}
              id="is_mobile_phone"
              label={t('common:mobile')}
            />
          </InputWithCheckbox>
        </FormGroup>
      </ShortFormControl>
    )
  }

  if (isEdit) return

  return (
    <ShortFormControl>
      <FormGroup label={t('common:phoneNumber')} error={formik.errors.phone} required>
        {inputJSX}
      </FormGroup>
    </ShortFormControl>
  )
}
