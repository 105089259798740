import { Typography, defaultTheme } from '@agro-club/agroclub-shared'
import DealRoutes from 'views/pages/Deal/routes'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { FC } from 'react'
import { BidContract } from 'modules/domain/bid/types'
import { CommonWrapper } from 'views/components/Card/Wrapper'

const Wrapper = styled(CommonWrapper)`
  background: ${defaultTheme.color.accentNotify100};
  border-radius: 8px;
  padding: 16px;
`
const Header = styled.div`
  ${Typography.accentLarge}
  margin-bottom: 4px;
`

const InfoBlock = styled.div`
  color: ${defaultTheme.color.secondaryPlaceholder};
`
const DealLink = styled(Link)`
  color: ${defaultTheme.color.primary600};
`
type Props = {
  dealIDs?: BidContract['deal_ids']
}
export const NoDealCard: FC<Props> = ({ dealIDs }) => {
  const { t } = useTranslation('deal')
  return (
    <Wrapper>
      <Header>{t('rematch.referenceInformation')}</Header>
      {dealIDs?.length ? (
        <InfoBlock>
          {t('rematch.dealLink')}{' '}
          <DealLink to={generatePath(DealRoutes.Details, { id: dealIDs[0] })} target="_blank">
            {t('deal')}
          </DealLink>
          .
        </InfoBlock>
      ) : (
        <InfoBlock>{t('rematch.noDealText')}</InfoBlock>
      )}
    </Wrapper>
  )
}
