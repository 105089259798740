import { FC, useMemo } from 'react'

import { FormGroup, Select, Typography, defaultTheme } from '@agro-club/agroclub-shared'
import styled from 'styled-components'
import { BidParameterDTO } from 'modules/domain/bid/types'
import { ProductParameter } from 'modules/domain/collection/types'
import { useTranslation } from 'react-i18next'
import { BinButton } from 'views/ui/BinButton/BinButton'
import { FormikErrors } from 'formik'
import { FormInputParameter } from './FormInputParameter'
import { useMediaQuery } from 'react-responsive'

const SelectWrapper = styled.div`
  width: 208px;
`

const Wrapper = styled.div`
  display: flex;
  column-gap: 4px;
  align-items: center;
  margin-bottom: 16px;
  ${Typography.bodyLarge}
  @media (max-width: ${defaultTheme.queries.mobile}) {
    column-gap: 16px;
    align-items: flex-start;
  }
`
const DelBtn = styled(BinButton)`
  @media (max-width: ${defaultTheme.queries.mobile}) {
    margin-top: 26px;
  }
`

type Props = {
  allParams: ProductParameter[]
  availableParams: ProductParameter[]
  parameter: BidParameterDTO
  onRemove: () => void
  onChange: (values: BidParameterDTO) => void
  formikError?: FormikErrors<BidParameterDTO>
}

export const CropsParam: FC<Props> = (props) => {
  const { t } = useTranslation('bid')
  const { formikError, parameter, availableParams, onRemove, onChange, allParams } = props

  const options = useMemo(() => {
    const selectedParameter = allParams.find((param) => param.id === parameter.parameter)
    if (!selectedParameter) {
      return availableParams.map((p) => ({ value: p.id, label: p.name }))
    } else {
      return [selectedParameter, ...availableParams].map((p) => ({ value: p.id, label: p.name }))
    }
  }, [allParams, availableParams, parameter.parameter])

  const commonProps = { formikError, parameter, onChange }

  const isMobile = useMediaQuery({
    query: `(max-width: ${defaultTheme.queries.mobile})`,
  })

  return (
    <Wrapper>
      <SelectWrapper>
        <FormGroup error={formikError?.parameter} required label={isMobile ? t('common:additional') : ''}>
          <Select
            status={!!formikError?.parameter ? 'error' : ''}
            options={options}
            onChange={(parameter) => onChange({ ...parameter, parameter })}
            value={parameter.parameter}
            showSearch={false}
            allowClear={false}
            fullWidth
          />
        </FormGroup>
      </SelectWrapper>
      <FormInputParameter {...commonProps} field={'parameter_value_from'} label={t('form.from')} />
      <FormInputParameter {...commonProps} field={'parameter_value_to'} label={t('form.to')} />
      <DelBtn onClick={onRemove} />
    </Wrapper>
  )
}
