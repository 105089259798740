import { Coordinator } from 'modules/domain/types'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { KeyValue } from 'views/components/KeyValue/KeyValue'
import { UserLink } from 'views/components/UserLink/UserLink'

interface Props {
  regionals: Coordinator[]
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const DocumentRequestRegionals = ({ regionals }: Props) => {
  const { t } = useTranslation('documentRequests')

  return (
    <KeyValue label={t('detail.dealInfo.regional')}>
      {regionals.map((regional) => (
        <Wrapper key={regional.id}>
          <UserLink user={regional} />
        </Wrapper>
      ))}
    </KeyValue>
  )
}
