import { FormikProps } from 'formik'
import { FC } from 'react'
import { SharedModalInputs } from 'views/components/SharedModal'
import FormSelectShared from 'views/components/FormSelectShared'
import { endpoints } from 'modules/endpoints'
import { useTranslation } from 'react-i18next'
import { SharedModalContent, SharedModalSecondContent, SharedModalSubTitle } from 'views/components/SharedModal/styled'
import styled from 'styled-components'
import { FormInput } from 'views/components/form/FormInput'
import { isBrazil } from 'env'

interface Props {
  formik: FormikProps<any>
  isAverageRates?: boolean
  isEditModal?: boolean
}

const InputsWrapper = styled.div<{ itemsPerColumn: number }>`
  display: grid;
  row-gap: 16px;
  column-gap: 112px;
  grid-template: ${({ itemsPerColumn }) => `repeat(${itemsPerColumn}, 1fr) / repeat(2, 1fr)`};
  grid-auto-flow: column;
`

export const ModalContent: FC<Props> = ({ formik, isEditModal, isAverageRates }) => {
  const { t } = useTranslation('rates')
  const itemsPerColumn = Math.ceil(formik.values.rates.length / 2)

  return (
    <SharedModalContent>
      <SharedModalInputs>
        <FormSelectShared
          label={t('modal.regions')}
          formik={formik}
          fieldName="sale_regions"
          getLabel={(dto) => dto.name}
          endpoint={endpoints.provinces()}
          simpleApiFormat
          disabled={(isEditModal && isBrazil) || isAverageRates}
          multiple
        />

        <FormSelectShared
          label={t('modal.logistician')}
          formik={formik}
          fieldName="responsible_logisticians"
          getLabel={(dto) => dto.full_name}
          endpoint={endpoints.dispatchingLogisticians()}
          multiple
          disabled={isAverageRates}
        />
      </SharedModalInputs>

      <SharedModalSecondContent>
        <SharedModalSubTitle>{t('modal.subTitle')}</SharedModalSubTitle>
        <InputsWrapper itemsPerColumn={itemsPerColumn}>
          {formik.values.rates.map((rate, index) => (
            <FormInput
              key={rate.distance_range.upper}
              error={formik?.errors?.rates?.[index]?.rate}
              label={rate.distance_range.upper}
              formik={formik}
              field={`rates[${index}].rate`}
              type="number"
              labelWidth={144}
            />
          ))}
        </InputsWrapper>
      </SharedModalSecondContent>
    </SharedModalContent>
  )
}
