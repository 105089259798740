const main = 'new-logistics-request'

const relativeRoutes = {
  relativePage: `${main}/*`,
  relativeList: `${main}`,
  relativeDetails: `:id`,
  relativeListWithTab: 'tab-:activeTab',
  relativeListWithTabAndRequest: 'tab-:activeTab/selectedRequest-:selectedRequest',
  relativeTripDetails: `:id/trips/:tripId`,
  relativeTripEdit: `:id/trips/:tripId/edit`,
  relativeTripCars: `:id/trips/cars`,
}

const routes = {
  List: `/${main}`,
  ListRequestsWithTab: `/${main}/${relativeRoutes.relativeListWithTab}`,
  ListRequestsWithTabAndRequest: `/${main}/${relativeRoutes.relativeListWithTabAndRequest}`,
  Details: `/${main}/${relativeRoutes.relativeDetails}`,
  /**
   * @deprecated Not in use now
   */
  TripDetails: `/${main}/${relativeRoutes.relativeTripDetails}`,
  /**
   * @deprecated Not in use now
   */
  TripEdit: `/${main}/${relativeRoutes.relativeTripEdit}`,
  TripCars: `/${main}/${relativeRoutes.relativeTripCars}`,
}

const DealsKRRoutes = { ...relativeRoutes, ...routes }

export default DealsKRRoutes
