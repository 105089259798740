import { FC, useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'

import { Icons, Progress, SectionTableBody, SectionTableHead } from '@agro-club/agroclub-shared'

import env from 'env'
import { Deal, DealShipment } from 'modules/domain/deal/types'
import {
  DrawerShippingWrapper,
  HeadCell,
  SectionTableHeadRowWithPadding,
  SectionTableWithoutBorder,
  TableWrapper,
} from 'views/pages/Deal/DealDetail/styled'

import { ACCORDION_ITEM_PAGE_SIZE } from 'modules/constants'
import { usePagination, useSingleEntity } from 'modules/domain/common/hooks'
import { endpoints } from 'modules/endpoints'
import { labelPriceLogistics } from 'modules/utils/numbers/formatPriceLogistics'
import { labelQuantity } from 'modules/utils/numbers/formatQuantity'
import { AButton } from 'views/components/Analytics'
import { Pagination } from 'views/components/Pagination/Pagination'
import { AddShipmentModal } from 'views/pages/Deal/DealDetail/DealAccordion/DealShipping/AddShipmentModal'
import { ListFooterWrapper } from 'views/styled/common'
import { ShippingItem } from './DealShippingItem'

type Props = {
  deal: Deal
  setSummary: (arg: boolean) => void
}

export const DealAccordionShipping: FC<Props> = ({ deal, setSummary }) => {
  const { t } = useTranslation('deal')
  const [progress, shipments = [], shipmentsRefetch] = useSingleEntity<DealShipment[]>(endpoints.dealShipment(deal.id))

  const [addShipmentModal, setAddShipmentModal] = useState<boolean>(false)
  const [shipmentsCurrentPage, setShipmentsCurrentPage, shipmentsCurrentItems] = usePagination(
    shipments,
    ACCORDION_ITEM_PAGE_SIZE,
  )
  const currency = env.REACT_APP_CURRENCY_SYMBOL

  useEffect(() => {
    if (progress === Progress.SUCCESS) {
      setSummary(!!shipments.length)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipments.length])

  return (
    <DrawerShippingWrapper>
      {shipments.length > 0 && (
        <TableWrapper>
          <SectionTableWithoutBorder>
            <SectionTableHead>
              <SectionTableHeadRowWithPadding>
                <HeadCell width={102}>{t('fields.date')}</HeadCell>
                <HeadCell width={88} textAlign="end">
                  {labelQuantity()}
                </HeadCell>
                <HeadCell width={108} textAlign="end">
                  {labelPriceLogistics(t('fields.price_logistics_per_kg_short'))}
                </HeadCell>
                <HeadCell width={78} textAlign="end">
                  {t('fields.price_exw_short', { currency })}
                </HeadCell>
                <HeadCell width={78} textAlign="end">
                  {t('fields.price_cpt_short', { currency })}
                </HeadCell>
                <HeadCell width={78} textAlign="end" />
              </SectionTableHeadRowWithPadding>
            </SectionTableHead>
            <SectionTableBody>
              {shipmentsCurrentItems.map((shipment) => (
                <ShippingItem
                  key={shipment.id}
                  shipment={shipment}
                  shipmentsRefetch={shipmentsRefetch}
                  dealId={deal.id}
                />
              ))}
            </SectionTableBody>
          </SectionTableWithoutBorder>
        </TableWrapper>
      )}
      <ListFooterWrapper>
        <AButton id="addShipping" intent="approve" Icon={Icons.IconAdd} onClick={() => setAddShipmentModal(true)}>
          {t('common:add')}
        </AButton>
        <Pagination
          disableMargin
          total={shipments.length}
          currPage={shipmentsCurrentPage}
          setCurrPage={setShipmentsCurrentPage}
        />
      </ListFooterWrapper>
      {addShipmentModal && (
        <AddShipmentModal
          dealId={deal.id}
          onClose={() => setAddShipmentModal(false)}
          shipmentRefetch={shipmentsRefetch}
          deal={deal}
        />
      )}
    </DrawerShippingWrapper>
  )
}
