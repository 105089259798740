import { FC } from 'react'
import { LogisticsKRCar } from '../../types'
import { SharedModalInputs, SharedModalTitle } from 'views/components/SharedModal'
import { useTranslation } from 'react-i18next'
import { CarModalContent } from 'views/pages/LogisticsKR/components/CarModalContent/CarModalContent'
import { useFormik } from 'formik'
import { apiClient } from 'modules/utils/httpClient'
import { endpoints } from 'modules/endpoints'
import { ListRequestParams } from 'modules/domain/types'
import ASharedModal from 'views/components/SharedModal/ASharedModal'
import { AnalyticsPlaces } from '../../analyticsPlaces'
import ADefaultModalFooter from 'views/components/DefaultModalFooter/ADefaultModalFooter'
import { useAFormHandler } from 'analytics/hooks'

type Props = {
  car: LogisticsKRCar
  onClose: () => void
  listRequestParams?: ListRequestParams
  setListRequestParams?: (ListRequestParams) => void
  onSuccess?: () => Promise<void>
}

export const CarEditModal: FC<Props> = ({ car, onClose, setListRequestParams, listRequestParams, onSuccess }) => {
  const { formProgress, formHandler } = useAFormHandler()
  const { t } = useTranslation('logisticsKR')

  const formik = useFormik({
    initialValues: {
      brand_model: car.brand_model,
      licence_number: car.licence_number,
      car_type: car.car_type,
      unloading_type: car.unloading_type,
      side_height: car.side_height,
      is_dump_truck: car.is_dump_truck,
      has_trailer: car.has_trailer || false, //TODO temporary substitution, will need to change later
      status: car.status,
    },
    onSubmit: formHandler(
      async () => await apiClient.put(endpoints.LogisticsKR.car(car.id), formik.values),

      {
        onSuccess: () => {
          setListRequestParams?.({ ...listRequestParams })
          onSuccess?.()
          onClose()
        },
        onError: (error) => {
          const { errors } = error
          formik.setErrors(errors)
        },
      },
    ),
  })
  return (
    <ASharedModal id={AnalyticsPlaces.CARS.EDIT_MODAL} onClose={onClose}>
      <SharedModalTitle>{t('carsEdit.heading')}</SharedModalTitle>
      <SharedModalInputs>
        <CarModalContent formik={formik} isEditForm showTrailer={false} />
      </SharedModalInputs>
      <ADefaultModalFooter
        onClose={onClose}
        onConfirm={() => formik.submitForm()}
        confirmButtonText={t('common:save')}
        progress={formProgress}
      />
    </ASharedModal>
  )
}
