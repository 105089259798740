import { Typography, defaultTheme, Icons } from '@agro-club/agroclub-shared'
import styled, { css } from 'styled-components'

import APhone from 'views/ui/Phone/APhone'

export const PhoneLink = styled(APhone)`
  ${Typography.bodyMedium};
  color: ${defaultTheme.color.primary600};
  display: block;
`

const iconParams = css`
  display: block;
  margin: 0 auto;
  width: 20px;
  height: auto;
`

export const StyledIconPhoneIn = styled(Icons.IconPhoneIn)`
  ${iconParams}

  & > path {
    fill: ${defaultTheme.color.accentApprove600};
  }
`
export const StyledIconPhoneOut = styled(Icons.IconPhoneOut)`
  ${iconParams}

  & > path {
    fill: ${defaultTheme.color.primary600};
  }
`
export const StyledIconPhoneMissed = styled(Icons.IconPhoneMissed)`
  ${iconParams}

  & > path {
    fill: ${defaultTheme.color.accentDestruct600};
  }
`
