import { Pagination, Progress, Spinner, defaultTheme } from '@agro-club/agroclub-shared'
import { ListRequestParams } from 'modules/domain/types'
import { useTranslation } from 'react-i18next'
import { NoDataScreen } from './NoDataScreen'
import { FC, ReactNode } from 'react'
import styled from 'styled-components'
import { AButton } from '../Analytics'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100dvh - 260px);
  overflow-y: auto;
  scrollbar-width: none;
  position: relative;
`

const Cards = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1px 0;
`

const WrapperPagination = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 8px;
  gap: 8px 0;
`

type Props = {
  setListRequestParams: (params: ListRequestParams) => void
  listRequestParams: ListRequestParams
  dataLength?: number
  children: ReactNode
  progress: Progress
  pageSize: number
  total: number
}

const Loading = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: ${defaultTheme.color.backgroundSecondary};
  opacity: 0.5;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 16px;
`

const calculatePagination = (currentPage, total, pageSize) => {
  const maxPage = Math.ceil(total / pageSize)
  const next = currentPage < maxPage ? currentPage + 1 : null
  const previous = currentPage > 1 ? currentPage - 1 : null

  return { next, previous }
}

export const MobileDataList: FC<Props> = ({
  setListRequestParams,
  listRequestParams,
  dataLength,
  children,
  progress,
  pageSize,
  total,
}) => {
  const { t } = useTranslation('dispatching')

  const { next, previous } = calculatePagination(listRequestParams.page, total, pageSize)

  const isVisiblePagination = next || previous
  return (
    <Wrapper>
      <Cards>{children}</Cards>
      {isVisiblePagination && (
        <WrapperPagination>
          {next && (
            <AButton
              style={{ width: '100%' }}
              onClick={() => setListRequestParams({ ...listRequestParams, page: next })}
              disabled={progress == Progress.WORK}
              intent="secondary"
              size="biggest"
              id="next"
            >
              {t('common:next')}
            </AButton>
          )}

          <Pagination
            total={total}
            pageSize={pageSize}
            current={listRequestParams.page}
            onChange={(page) => setListRequestParams({ ...listRequestParams, page })}
          />
        </WrapperPagination>
      )}
      {progress == Progress.WORK ? (
        <Loading>
          <Spinner />
        </Loading>
      ) : null}
      {!dataLength && progress !== Progress.WORK && <NoDataScreen title={t('common:not_found')} />}
    </Wrapper>
  )
}
