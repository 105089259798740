import { FC } from 'react'
import { FormGroup, Input, Text, defaultTheme } from '@agro-club/agroclub-shared'
import { FormikErrors } from 'formik'
import { BidParameterDTO } from 'modules/domain/bid/types'
import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'

const ShortInput = styled.div`
  width: 94px;
`

type Props = {
  formikError?: FormikErrors<BidParameterDTO>
  parameter: BidParameterDTO
  onChange: (values: BidParameterDTO) => void
  field: string
  label: string
}

export const FormInputParameter: FC<Props> = (props) => {
  const { formikError, parameter, field, onChange, label } = props

  const onChangeParameter = (e) => {
    const newParameter = { ...parameter }
    newParameter[field] = e.target.value
    onChange(newParameter)
  }

  const error = formikError && formikError[field]

  const isMobile = useMediaQuery({
    query: `(max-width: ${defaultTheme.queries.mobile})`,
  })

  return (
    <FormGroup
      label={<Text color={isMobile ? undefined : 'primary'}>{label}</Text>}
      labelContainerStyle={{ minWidth: 0 }}
      error={error}
      labelWidth={30}
    >
      <ShortInput>
        <Input value={parameter[field]} onChange={onChangeParameter} invalid={!!error} type="number" min={0} />
      </ShortInput>
    </FormGroup>
  )
}
