import { Icons, defaultTheme } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

export const WrapperData = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px 0;
`
export const InfoBlock = styled.div`
  display: flex;
  flex-direction: column;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`

export const Box = styled.div`
  display: flex;
  align-items: center;
`

export const IconDeals = styled(Icons.IconDeals)`
  margin-left: 5px;
  width: 16px;
  height: 16px;
  path {
    fill: ${defaultTheme.color.primary600};
  }
`

export const IconVerifiedUser = styled(Icons.IconCheckMark)`
  margin-left: 5px;
  width: 16px;
  height: 16px;
  path {
    fill: ${defaultTheme.color.primary600};
  }
`

export const KeyUser = styled(Icons.IconDollar)`
  margin-left: 5px;
  width: 16px;
  height: 16px;
  path {
    fill: ${defaultTheme.color.accentApprove600};
  }
`
