import { SmsKeys, TemplateType } from 'modules/domain/user/types'
import { Text } from '@agro-club/agroclub-shared'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { FC } from 'react'

type Props = {
  tableData: {
    key: SmsKeys
    type: string
    value: string
  }[]
  templateType: TemplateType
  product: string
}
const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 32px;
`

const TableBlock = styled.div`
  display: grid;
  grid-template-columns: repeat(3, auto);
  white-space: nowrap;
  margin-top: 12px;
`
const TwoColumn = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 60px);
  white-space: nowrap;
  margin-top: 12px;
`

export const SmsInfo: FC<Props> = ({ tableData, product, templateType }) => {
  const { t } = useTranslation('user')

  const data: any = tableData.reduce((acc, item) => {
    acc[item.key] = item.value
    return acc
  }, {})

  return (
    <>
      {templateType === TemplateType.cash ? (
        <>
          <Grid>
            <Text color="secondary" typography="bodyLarge">
              {t('bestProductText', { product })}
            </Text>
            <Text color="secondary" typography="bodyLarge">
              {t('weCanText')}
            </Text>
          </Grid>
          <Grid>
            <TableBlock>
              <div></div>
              <div>{t('fob')}</div>
              <div>Delivered</div>
              <div>{data.month_1_name}</div>
              <div>{data.local_1_fob_cash}</div>
              <div>{`${data.local_1_delivered_cash} => ${data.local_1_distance} mi`}</div>

              <div>{data.month_2_name}</div>
              <div>{data.local_2_fob_cash}</div>
              <div>{`${data.local_2_delivered_cash} => ${data.local_2_distance} mi`}</div>

              <div>{data.month_3_name}</div>
              <div>{data.local_3_fob_cash}</div>
              <div>{`${data.local_3_delivered_cash} => ${data.local_3_distance} mi`}</div>
            </TableBlock>

            <TableBlock>
              <div></div>
              <div>{t('fob')}</div>
              <div>{t('ourPremium')}</div>

              <div>{data.month_1_name}</div>
              <div>{data.best_1_fob_cash}</div>
              <div>{data.best_1_premium}</div>

              <div>{data.month_2_name}</div>
              <div>{data.best_2_fob_cash}</div>
              <div>{data.best_2_premium}</div>

              <div>{data.month_3_name}</div>
              <div>{data.best_3_fob_cash}</div>
              <div>{data.best_3_premium}</div>
            </TableBlock>
          </Grid>
        </>
      ) : (
        <>
          <Text color="secondary" typography="bodyLarge">
            {t('weCanText')}
          </Text>

          <TwoColumn>
            <div></div>
            <div>{t('fob')}</div>

            <div>{data.month_1_name}</div>
            <div>{data.best_1_fob_cash}</div>

            <div>{data.month_2_name}</div>
            <div>{data.best_2_fob_cash}</div>

            <div>{data.month_3_name}</div>
            <div>{data.best_3_fob_cash}</div>
          </TwoColumn>
        </>
      )}
    </>
  )
}
