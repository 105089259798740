const main = 'users'
const relativeRoutes = {
  relativePage: `${main}/*`,
  relativeList: `${main}`,
  relativeDetail: ':id',
  relativeBidPrice: ':id/bid-price',
  // This route is required for navigation from the overdue bid card located in the dashboard
  relativeBidPriceForNavigation: ':id/bid-price/:topBid',
  relativeDetailWithBid: ':id/:bidId/:statusBid',
}

const routes = {
  List: `/${main}`,
  Details: `/${main}/${relativeRoutes.relativeDetail}`,
  DetailsWithBid: `/${main}/${relativeRoutes.relativeDetailWithBid}`,
  BidPrice: `/${main}/${relativeRoutes.relativeBidPrice}`,
  BidPriceWithTopBid: `/${main}/${relativeRoutes.relativeBidPriceForNavigation}`,
}

const UserRoutes = { ...relativeRoutes, ...routes }

export default UserRoutes
