import { FC } from 'react'
import { Team } from 'modules/domain/teams/types'
import { useTranslation } from 'react-i18next'
import { DASH } from 'modules/constants'

type Props = {
  assignedTeam: Team | undefined
  assignee: string | undefined
}

export const SummaryAssigneeTeam: FC<Props> = ({ assignedTeam, assignee }) => {
  const { t } = useTranslation('common')

  return <div>{`${assignedTeam ? assignedTeam.name : t('common:withoutTeam')}, ${assignee || DASH}`}</div>
}
