import { FiltersWrapper, FiltersAndSearchWrapper } from 'views/components/CommonTableComponents/CommonTableComponents'
import { defaultListRequestParams } from 'views/components/TableFilters/data'
import { useDisplayClearBtn } from 'hooks/useDisplayClearBtn'
import { TableFiltersProps } from 'modules/types'
import { SearchFilter } from 'views/components/SearchFilter/SearchFilter'
import { ADateRangePicker } from 'views/components/TableFilters/DateRangePicker/ADateRangePicker'
import { AnalyticsFilterVariant } from 'analytics/types'
import { AClearFiltersButton } from 'views/components/Analytics'
import { FC } from 'react'
import { FactoringFilter } from './FactoringFilter'
import { CommercialFilter } from './CommercialFilter'

const CompanyFilters: FC<TableFiltersProps> = ({ setListRequestParams, listRequestParams, updateFilterState }) => {
  const displayClearBtn = useDisplayClearBtn({
    listRequestParams,
  })

  return (
    <FiltersAndSearchWrapper>
      <FiltersWrapper>
        <ADateRangePicker
          value={[listRequestParams.filter.created_after, listRequestParams.filter.created_before]}
          onChange={(value) => updateFilterState({ created_after: value.startDate, created_before: value.endDate })}
          id={AnalyticsFilterVariant.DATE}
        />
        <CommercialFilter listRequestParams={listRequestParams} updateFilterState={updateFilterState} />
        <FactoringFilter listRequestParams={listRequestParams} updateFilterState={updateFilterState} />

        {displayClearBtn && (
          <AClearFiltersButton onClick={() => setListRequestParams({ ...defaultListRequestParams })} />
        )}
      </FiltersWrapper>

      <SearchFilter listRequestParams={listRequestParams} updateFilterState={updateFilterState} />
    </FiltersAndSearchWrapper>
  )
}

export default CompanyFilters
