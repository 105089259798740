import { Typography, defaultTheme } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

import { box } from 'views/styled/common'
import { AButton } from '../Analytics'

export const TeamPlansCard = styled.div`
  ${box};
  width: 1038px;
  max-height: 666px;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  box-shadow: none;
  padding: 16px 32px 16px 16px;
  margin-right: 24px;
`

export const TeamPlansContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px 24px;
`

export const TeamPlanYearsSwitcher = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 18px;
  margin-left: 8px;
`

export const SwitcherButton = styled(AButton)`
  &:disabled {
    background-color: #fff;

    svg path {
      fill: ${defaultTheme.color.secondary600};
    }

    &:hover {
      background-color: #fff;
    }
  }
`

export const TeamPlanSwitcherLabel = styled.div`
  ${Typography.titleH4}
  margin: 0 24px;
`
