import { FC, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { Carrier } from 'modules/domain/logisticsKR/types'
import { RightPanelComments } from 'views/components/Comments/styled'
import { useParams } from 'react-router-dom'
import { endpoints } from 'modules/endpoints'
import { AntCollapse, Panel } from '@agro-club/agroclub-shared'
import { Trailers } from './Trailers/Trailers'
import { useTableData } from 'modules/domain/common/hooks'
import { defaultListRequestParams } from 'views/components/TableFilters/data'
import { Drivers } from './Drivers/Drivers'
import { CarrierDocuments } from './CarrierDocuments/CarrierDocuments'
import { DriversDocuments } from './DriversDocuments/DriversDocuments'
import { CarrierFiles } from './CarrierFiles/CarrierFiles'
import { CarrierInfo } from './CarrierInfo/CarrierInfo'
import AComments from 'views/components/Comments/AComments'
import { Employees } from './Employees/Employees'
import AntCollapseHeader from 'views/components/AntCollapseHeader/AntCollapseHeader'
import { SummaryComments } from 'views/components/AntCollapseHeader/SummaryComments'
import { SummaryCount } from 'views/components/AntCollapseHeader/SummaryCount'
import { SummaryThereIsNo } from 'views/components/AntCollapseHeader/SummaryThereIsNo'

const CarrierAccordion: FC<{ carrier: Carrier }> = ({ carrier }) => {
  const { t } = useTranslation('logisticsKR')
  const { id } = useParams()
  const [listRequestParams] = useState({
    ...defaultListRequestParams,
    filter: { carrier: carrier.id },
    pageSize: 10000,
  })
  const [commentsSummary, setCommentsSummary] = useState<string>()
  const [hasEmployees, setHasEmployees] = useState<number>()
  const [trailersSummary, setTrailersSummary] = useState<number>()
  const [carrierDocsSummary, setCarrierDocsSummary] = useState<number>()
  const [driverDocumentsCounter, setDriverDocumentsCounter] = useState<Record<string, number>>({})
  const thereIsDriverDocs = !!Object.values(driverDocumentsCounter).find((el) => el > 0)
  const [filesSummary, setFilesSummary] = useState<number>()
  const { data: drivers = [], refetch: refetchDrivers } = useTableData(
    endpoints.LogisticsKR.drivers(),
    listRequestParams,
    !!listRequestParams.filter.carrier,
  )

  const options = [
    { value: 'comments', title: t('carriers.accordion.titles.comments') },
    { value: 'user', title: t('carriers.accordion.titles.company_info') },
    { value: 'employees', title: t('carriers.accordion.titles.employees') },
    { value: 'trailers', title: t('carriers.accordion.titles.trailers') },
    { value: 'drivers', title: t('carriers.accordion.titles.drivers') },
    { value: 'carrier_documents', title: t('carriers.accordion.titles.carrier_documents') },
    { value: 'drivers_documents', title: t('carriers.accordion.titles.drivers_documents') },
    { value: 'files', title: t('carriers.accordion.titles.files') },
  ]

  const components = {
    comments: (
      <RightPanelComments>
        <AComments
          url={endpoints.LogisticsKR.carrierComments(id)}
          popSummary={setCommentsSummary}
          maxHeight={400}
          id={id}
        />
      </RightPanelComments>
    ),
    user: <CarrierInfo carrier={carrier} />,
    employees: <Employees listRequestParams={listRequestParams} carrierId={carrier.id} setSummary={setHasEmployees} />,
    trailers: <Trailers listRequestParams={listRequestParams} carrierId={carrier.id} setSummary={setTrailersSummary} />,
    drivers: <Drivers carrier={carrier} refetch={refetchDrivers} drivers={drivers} />,
    carrier_documents: <CarrierDocuments carrierId={carrier.id} setSummary={setCarrierDocsSummary} />,
    drivers_documents: <DriversDocuments drivers={drivers} setDocumentsCounter={setDriverDocumentsCounter} />,
    files: <CarrierFiles setSummary={setFilesSummary} carrierId={carrier.id} />,
  }

  const summary = {
    comments: <SummaryComments summary={commentsSummary} />,
    trailers: <SummaryCount total={trailersSummary} />,
    drivers: <SummaryCount total={drivers?.length} />,
    employees: <SummaryThereIsNo hasData={hasEmployees} />,
    carrier_documents: <SummaryThereIsNo hasData={carrierDocsSummary} />,
    drivers_documents: <SummaryThereIsNo hasData={thereIsDriverDocs} />,
    files: <SummaryCount total={filesSummary} />,
  }

  const [activeKey, setActiveKey] = useState<string | string[]>(options[0].value)

  return (
    <div>
      <AntCollapse onChange={(key) => setActiveKey(key)} defaultActiveKey={options[0].value} bordered={false} accordion>
        {options.map((option) => (
          <Panel
            forceRender
            key={option.value}
            header={
              <AntCollapseHeader title={option.title} summary={activeKey !== option.value && summary[option.value]} />
            }
          >
            {components[option.value]}
          </Panel>
        ))}
      </AntCollapse>
    </div>
  )
}

export default CarrierAccordion
