import { FC } from 'react'
import { TableExternalLink, TableTextLink } from 'views/ui/TableTextLink/TableTextLink'
import { generatePath } from 'react-router-dom'
import CarriersKRRoutes from 'views/pages/LogisticsKR/CarriersKR/routes'
import { useTranslation } from 'react-i18next'
import formatPhone from 'helpers/formatPhone'
import { LogisticsKRTrip } from 'views/pages/LogisticsKR/types'
import { FlexColumnWrapper } from '../styled'
import { DASH } from 'modules/constants'

interface Props {
  trip: LogisticsKRTrip
}

export const CarrierCell: FC<Props> = ({ trip }) => {
  const { t } = useTranslation('logisticBids')

  return (
    <FlexColumnWrapper>
      <TableTextLink id="carrierLink" to={generatePath(CarriersKRRoutes.Details, { id: trip.carrier?.id })}>
        {trip?.carrier?.name}
      </TableTextLink>
      <TableExternalLink href={`tel:${trip.carrier?.phone}`}>
        {formatPhone(trip.carrier?.phone ?? DASH)}
      </TableExternalLink>
      <div>{trip.has_vat ? t('vat.with_vat') : t('vat.not_vat')}</div>
    </FlexColumnWrapper>
  )
}
