import { ListRequestParams, ParamLoadSteps } from 'modules/domain/types'

export const defaultListRequestParams: ListRequestParams = {
  page: 1,
  filter: {},
  // sort: undefined,
}

export const initialListRequestParams: ListRequestParams = {
  page: 1,
  filter: {},
  sort: undefined,
  loadStep: ParamLoadSteps.Initial,
}
