import { parseISO, isValid, format } from 'date-fns'
import { t } from 'i18next'
import { DASH, RU_DATE_FORMAT } from 'modules/constants'
import { Declaration } from 'modules/domain/types'

export const getDeclarationText = (declaration?: Declaration | null) => {
  const parsedDate = parseISO(declaration?.start_date as string)

  const date = isValid(parsedDate) && format(parsedDate, RU_DATE_FORMAT)

  return declaration ? `${declaration.number} ${t('common:from')} ${date}` : DASH
}
