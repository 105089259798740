import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { FormGroup, Select } from '@agro-club/agroclub-shared'

import { SelectsProps } from './types'
import { ShortFormControl } from 'views/styled/common'

export const SimpleOptionsSelect: FC<SelectsProps> = ({
  formik,
  fieldName,
  EnumType,
  label,
  optionsTranslations,
  required,
  controlContainerStyle,
  allowClear = true,
}) => {
  const { t } = useTranslation()
  const unloadOptions = Object.keys(EnumType).map((value) => ({
    label: t(`${optionsTranslations}.${value}`),
    value: value,
  }))

  return (
    <ShortFormControl>
      <FormGroup
        error={formik.errors[fieldName]}
        label={label}
        required={required}
        controlContainerStyle={controlContainerStyle}
      >
        <Select
          options={unloadOptions}
          value={formik.values[fieldName]}
          onChange={(value) => formik.setFieldValue(fieldName, value)}
          showSearch={false}
          fullWidth
          allowClear={allowClear}
        />
      </FormGroup>
    </ShortFormControl>
  )
}
