import { convertDateTimeToServerUTC, defaultTheme, Progress, Text } from '@agro-club/agroclub-shared'
import { SharedModalContent, SharedModalFooter, SharedModalInputs } from 'views/components/SharedModal/styled'
import { MobileModalFooter } from '../MobileComponents/Modal/MobileModalFooter'
import { Task, TaskPriority, TaskType } from 'modules/domain/task/types'
import { AMobileModal } from '../MobileComponents/Modal/AMobileModal'
import { FC, useContext, useEffect, useMemo, useState } from 'react'
import { AnalyticsPlaces } from 'views/pages/Bid/analyticsPlaces'
import { PotentialBid } from 'modules/domain/potentialBid/types'
import { SharedModalTitle } from 'views/components/SharedModal'
import { useLocation, useNavigate } from 'react-router-dom'
import DashboardRoutes from 'views/pages/Dashboard/routes'
import { AuthContext } from 'modules/context/AuthContext'
import { useNotification } from 'hooks/useNotification'
import { getUserName } from 'modules/domain/user/utils'
import ASharedModal from '../SharedModal/ASharedModal'
import { apiClient } from 'modules/utils/httpClient'
import { useAFormHandler } from 'analytics/hooks'
import { useMediaQuery } from 'react-responsive'
import { Deal } from 'modules/domain/deal/types'
import { User } from 'modules/domain/user/types'
import { Bid } from 'modules/domain/bid/types'
import { useTranslation } from 'react-i18next'
import { CommonFields } from './CommonFields'
import { endpoints } from 'modules/endpoints'
import { addBusinessDays } from 'date-fns'
import { AButton } from '../Analytics'
import { useFormik } from 'formik'

type EditableFields = {
  assigned_team?: string | undefined | null
  potential_executors?: string[]
  task_type: Task['task_type']
  linked_deal?: string
  linked_user?: string
  linked_bid?: string
  priority?: string
  subject?: string
  deadline: string
  comment?: string
  files?: []
}

type Props = {
  skipOnSuccessBeforeRedirect?: boolean
  unclickableUserLink?: boolean
  bid?: Bid | PotentialBid
  onSuccess?: () => void
  isMyTasks?: boolean
  close: () => void
  user?: User
  deal?: Deal
}

export const TaskModal: FC<Props> = ({
  skipOnSuccessBeforeRedirect,
  unclickableUserLink,
  onSuccess,
  isMyTasks,
  close,
  user,
  deal,
  bid,
}) => {
  const { formProgress, formHandler } = useAFormHandler('addTask')
  const { t } = useTranslation('task')
  const location = useLocation()
  const [goToDashboard, setGoToDashboard] = useState(false)
  const isFromDashboard = !!location.state?.pathname && location.state?.pathname.startsWith(DashboardRoutes.Root)
  const navigate = useNavigate()
  const { profile: currentUser } = useContext(AuthContext)
  const teamId = currentUser?.profile?.team?.id
  const linkedUserName = getUserName(user)
  const nextBusinessDay = useMemo(() => addBusinessDays(new Date(), 1).toISOString(), [])

  const notify = useNotification()

  const formik = useFormik<EditableFields>({
    initialValues: {
      subject: '',
      task_type: isMyTasks ? TaskType.MEETING : TaskType.CALL,
      deadline: convertDateTimeToServerUTC(nextBusinessDay),
      assigned_team: teamId ? String(teamId) : 'null',
      potential_executors: [String(currentUser?.id)],
      priority: TaskPriority.low,
      comment: undefined,
      linked_deal: deal?.id,
      linked_user: user?.id,
      linked_bid: bid?.id,
      files: [],
    },

    onSubmit: formHandler(
      async () => {
        await apiClient.post(endpoints.task(), formik.values)
      },
      {
        onSuccess: () => {
          if (goToDashboard) {
            if (!skipOnSuccessBeforeRedirect) onSuccess?.()
            navigate({ pathname: location.state.pathname, search: location.state.search })
          } else {
            onSuccess?.()
            close()
          }
          notify(Progress.SUCCESS, { title: t('notifySuccess') })
        },
        onError: (error) => {
          const { errors } = error
          formik.setErrors(errors)
          setGoToDashboard(false)
        },
      },
    ),
  })

  useEffect(() => {
    switch (formik.values.task_type) {
      case 'call':
        formik.setFieldValue('subject', `${t('form.call')} ${linkedUserName}`)
        formik.setFieldTouched('subject')
        return
      case 'meeting':
        const subjectText = linkedUserName.length ? t('form.meetWith') : t('form.meet')
        formik.setFieldValue('subject', `${subjectText} ${linkedUserName}`)
        formik.setFieldTouched('subject')
        return
      default:
        formik.setFieldValue('subject', '')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.task_type, t])

  const isLinkedFields = !user && !deal && !bid

  const isMobile = useMediaQuery({
    query: `(max-width: ${defaultTheme.queries.mobile})`,
  })

  const fromDashboardBtn = isFromDashboard && (
    <AButton
      id="saveAndGoToDashboard"
      progress={formProgress}
      intent="primary"
      size="big"
      onClick={() => {
        setGoToDashboard(true)
        formik.submitForm()
      }}
    >
      {t('form.saveAndGoToDashboard')}
    </AButton>
  )

  return (
    <>
      {isMobile ? (
        <AMobileModal
          header={<Text typography="titleH4">{t('common:setTask')}</Text>}
          id={AnalyticsPlaces.TASK_MODAL}
          onClose={close}
        >
          <SharedModalInputs>
            <CommonFields
              unclickableUserLink={unclickableUserLink}
              isLinkedFields={isLinkedFields}
              isMyTasks={isMyTasks}
              formik={formik}
              user={user}
              deal={deal}
              bid={bid}
            />
          </SharedModalInputs>

          <MobileModalFooter confirmButtonText={t('common:save')} progress={formProgress} onConfirm={formik.submitForm}>
            {fromDashboardBtn}
          </MobileModalFooter>
        </AMobileModal>
      ) : (
        <ASharedModal onClose={close} size="medium" id={AnalyticsPlaces.TASK_MODAL}>
          <SharedModalTitle>{t('common:setTask')}</SharedModalTitle>
          <SharedModalContent>
            <CommonFields
              unclickableUserLink={unclickableUserLink}
              isLinkedFields={isLinkedFields}
              isMyTasks={isMyTasks}
              formik={formik}
              user={user}
              deal={deal}
              bid={bid}
            />
          </SharedModalContent>

          <SharedModalFooter>
            {fromDashboardBtn}

            <AButton id="save" size="big" progress={formProgress} intent="primary" onClick={formik.submitForm}>
              {t('common:save')}
            </AButton>

            <AButton size="big" intent="secondary" onClick={close} id="cancel">
              {t('common:cancel')}
            </AButton>
          </SharedModalFooter>
        </ASharedModal>
      )}
    </>
  )
}
