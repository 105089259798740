import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import { AnalyticsPlaces } from 'views/pages/User/analyticsPlaces'
import ASharedModal from 'views/components/SharedModal/ASharedModal'
import ADefaultModalFooter from 'views/components/DefaultModalFooter/ADefaultModalFooter'
import { CloseDealModalContent } from 'views/components/CloseDealModal/CloseDealModalContent'

import { useAFormHandler } from 'analytics/hooks'

import { SharedModalTitle } from 'views/components/SharedModal'
import { apiClient } from 'modules/utils/httpClient'
import { endpoints } from 'modules/endpoints'

interface Props {
  onStatusChange: () => void
  onClose: () => void
  matchId: string
}

export const RemoveMatchModal: FC<Props> = ({ onClose, matchId, onStatusChange }) => {
  const { formProgress, formHandler } = useAFormHandler('removeMatch')
  const { t } = useTranslation('deal')

  const formik = useFormik<{ closed_reason: string }>({
    initialValues: {
      closed_reason: '',
    },
    onSubmit: formHandler(async () => await apiClient.post(endpoints.dealClose(matchId), formik.values), {
      onSuccess: async () => {
        await onStatusChange?.()
        onClose()
      },
      onError: (error) => {
        const { errors } = error
        formik.setErrors(errors)
      },
    }),
  })

  return (
    <ASharedModal id={AnalyticsPlaces.REMOVE_MATCH_MODAL} size="small" onClose={onClose}>
      <SharedModalTitle>{t('closeDealModal.title')}</SharedModalTitle>

      <CloseDealModalContent formik={formik} value="closed_reason" />

      <ADefaultModalFooter
        disabled={!formik.dirty}
        onConfirm={() => formik.handleSubmit()}
        confirmButtonText={t('closeDeal')}
        onClose={onClose}
        progress={formProgress}
      />
    </ASharedModal>
  )
}
