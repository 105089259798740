import { Progress } from '@agro-club/agroclub-shared'
import { useMemo } from 'react'

export const useCombinedProgress = (...list: Progress[]): Progress => {
  return useMemo(() => {
    let result: Progress = Progress.SUCCESS

    loop: for (const progress of list) {
      switch (progress) {
        case Progress.WORK:
        case Progress.IDLE:
        case Progress.ERROR:
          result = progress
          break loop
      }
    }

    return result
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, list)
}
