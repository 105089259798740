import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHelmet } from '@agro-club/agroclub-shared'
import * as Layout from 'views/layouts/NewLayout'
import { ContentTwoColumns } from 'views/layouts/NewLayout'

import * as Header from 'views/ui/Header/Header'
import CallStatisticsFilters from './CallStatisticsFilters'
import { ListRequestParams } from 'modules/domain/types'
import { defaultListRequestParams } from 'views/components/TableFilters/data'
import { useFilters } from 'hooks/useFilter'
import { endpoints } from 'modules/endpoints'
import { useColumns } from './useColumns'
import { format } from 'date-fns'
import { SERVER_DATE_FORMAT } from 'modules/constants'
import APageWrapper from 'views/components/PageWrapper/APageWrapper'
import { AnalyticsPages, AnalyticsPlaces } from './analyticsPlaces'
import ATableFullPage from 'views/components/Table/ATableFullPage'
import { EmployeeStatistics } from './EmployeeStatistics'

const CallStatisticsList: FC = () => {
  const { t } = useTranslation(['callStatistics'])
  useHelmet({ title: t('callStatisticsMetaTitle') })
  const [listRequestParams, setListRequestParams] = useState<ListRequestParams>(defaultListRequestParams)
  const currentDay = format(new Date(), SERVER_DATE_FORMAT)

  const { isFiltersLoaded, updateFilterState } = useFilters({
    listRequestParams,
    setListRequestParams,
    defaultParams: {
      call_start_after: listRequestParams.filter.call_start_before ? undefined : currentDay,
      call_start_before: listRequestParams.filter.call_start_after ? undefined : currentDay,
    },
  })
  const columns = useColumns(listRequestParams.sort)

  return (
    <APageWrapper page={AnalyticsPages.CALLS_STATS} place={AnalyticsPlaces.LIST}>
      <Layout.WrapperContent>
        <Layout.Header>
          <Layout.TopHeader>
            <Header.Breadcrumbs
              routes={[
                {
                  breadcrumbName: t('list.heading'),
                },
              ]}
            />
          </Layout.TopHeader>
          <Layout.PageName>{t('list.heading')}</Layout.PageName>
          <CallStatisticsFilters
            setListRequestParams={setListRequestParams}
            listRequestParams={listRequestParams}
            updateFilterState={updateFilterState}
          />
        </Layout.Header>
        <ContentTwoColumns>
          <ATableFullPage
            columns={columns}
            dataEndpoint={endpoints.callStatistics()}
            listParamsUpdated={setListRequestParams}
            listRequestParams={listRequestParams}
            isFiltersLoaded={isFiltersLoaded}
          />
          <EmployeeStatistics />
        </ContentTwoColumns>
      </Layout.WrapperContent>
    </APageWrapper>
  )
}

export default CallStatisticsList
