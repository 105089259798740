import { FC, useMemo, useState } from 'react'

import { Bid, BidTypes } from 'modules/domain/bid/types'
import { endpoints } from 'modules/endpoints'
import AComments from 'views/components/Comments/AComments'
import { CommentType } from 'modules/domain/comment/types'
import { useTranslation } from 'react-i18next'
import { AntCollapse, Panel } from '@agro-club/agroclub-shared'
import AntCollapseHeader from 'views/components/AntCollapseHeader/AntCollapseHeader'
import { SampleBlock } from './Samples/SampleBlock'
import { Contracts } from './Contracts/Contracts'
import { isBidContractsEnabled } from 'env'
import { SummaryComments } from 'views/components/AntCollapseHeader/SummaryComments'
import { SummaryThereIsNo } from 'views/components/AntCollapseHeader/SummaryThereIsNo'
import { HistoryTable } from './History/HistoryTable'

type Props = {
  bid: Bid
}

export const BidRightBlock: FC<Props> = ({ bid }) => {
  const { t } = useTranslation('bid')

  const [commentsSummary, setCommentsSummary] = useState<string>('')
  const [samplesSummary, setSamplesSummary] = useState<number>()
  const [contracts, setContracts] = useState<number>()

  const options = useMemo<{ title: string; value: string; isOpened?: boolean; isShow?: boolean }[]>(
    () =>
      [
        { value: 'comments', title: t('form.userComment'), isOpened: true, isShow: true },
        { value: 'samples', title: t('form.samples'), isOpened: true, isShow: bid.bid_type !== BidTypes.purchase },
        { value: 'history', title: t('form.history'), isOpened: true, isShow: true },
        {
          value: 'contracts',
          title: t('contracts.contracts'),
          isOpened: true,
          isShow: isBidContractsEnabled,
        },
      ].filter((option) => option.isShow),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t],
  )

  const [activeKey, setActiveKey] = useState<string | string[]>(options[0].value)
  const components = {
    comments: (
      <AComments
        id={bid?.owner?.id}
        type={CommentType.user}
        url={endpoints.userComments(bid?.owner?.id)}
        maxHeight={400}
        popSummary={setCommentsSummary}
      />
    ),
    samples: <SampleBlock setSummary={setSamplesSummary} bidId={bid.id} />,
    history: <HistoryTable />,
    contracts: <Contracts bid={bid} setContracts={setContracts} />,
  }
  const summary = {
    comments: <SummaryComments summary={commentsSummary} />,
    samples: <SummaryThereIsNo hasData={samplesSummary} />,
    contracts: <SummaryThereIsNo hasData={contracts} />,
  }
  return (
    <AntCollapse accordion bordered={false} defaultActiveKey={options[0].value} onChange={(key) => setActiveKey(key)}>
      {options.map((option) => (
        <Panel
          forceRender
          header={
            <AntCollapseHeader title={option.title} summary={activeKey !== option.value && summary[option.value]} />
          }
          key={option.value}
        >
          {components[option.value]}
        </Panel>
      ))}
    </AntCollapse>
  )
}
