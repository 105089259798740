import { Icons, Progress } from '@agro-club/agroclub-shared'
import { Task, TaskType } from 'modules/domain/task/types'
import { refetchFunc } from 'modules/domain/common/hooks'
import { useNotification } from 'hooks/useNotification'
import { apiClient } from 'modules/utils/httpClient'
import { AButton } from 'views/components/Analytics'
import { useAProgress } from 'hooks/useAProgress'
import { useTranslation } from 'react-i18next'
import { endpoints } from 'modules/endpoints'
import { EndCallModal } from './EndCallModal'
import { FC, useState } from 'react'
import { User } from 'modules/domain/user/types'

type Props = {
  refetch: refetchFunc | (() => void)
  successText: string
  task: Task
  forDashboard?: boolean
  user?: User
  userRefetch?: refetchFunc
}

export const CompleteTaskBtn: FC<Props> = ({ refetch, task, successText, forDashboard, user, userRefetch }) => {
  const { t } = useTranslation('task')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const notify = useNotification()

  const [progress, completeTask] = useAProgress(() => apiClient.post(endpoints.completeTask(task.id)), {
    eventName: 'completeTask',
    analyticsPayload: {
      id: task.id,
    },
    onSuccess: () => {
      notify(Progress.SUCCESS, { title: successText })
      refetch(true)
    },
  })

  const actionBtn = () => {
    if (task.task_type === TaskType.CALL || task.task_type === TaskType.NEW_USER) {
      setIsModalOpen(true)
      return
    }
    completeTask()
  }

  return (
    <>
      {forDashboard ? (
        <AButton
          Icon={Icons.IconDone}
          progress={progress}
          onClick={actionBtn}
          intent="secondary"
          id="completeTask"
          size="small"
        >
          {t('task:makeComplete')}
        </AButton>
      ) : (
        <AButton id="completeTask" Icon={Icons.IconApprove} progress={progress} onClick={actionBtn} intent="white" />
      )}
      {isModalOpen && (
        <EndCallModal
          user={user}
          refetch={refetch}
          onClose={() => setIsModalOpen(false)}
          task={task}
          userRefetch={userRefetch}
        />
      )}
    </>
  )
}
