export enum AnalyticsPages {
  COMPANIES = 'companies',
  COMPANY_DETAIL = 'companyDetail',
}

export const AnalyticsPlaces = {
  LIST: 'CompaniesList',
  DETAIL: 'CompanyDetail',
  DETAILS_MODAL: 'DetailsModal',
}
