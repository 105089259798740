import { Icons, Text } from '@agro-club/agroclub-shared'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { FC } from 'react'
import { Card } from 'views/components/Card/Card'

const Wrapper = styled(Card.Container)`
  position: relative;
`
const IconClose = styled(Icons.IconClose)`
  width: 18px;
  height: 18px;
  position: absolute;
  right: 16px;
  top: 12px;
  cursor: pointer;
`
type Props = {
  text: string
  onClose: () => void
}

export const InfoBlock: FC<Props> = ({ text, onClose }) => {
  const { t } = useTranslation('deal')
  return (
    <Wrapper intent="warning">
      <IconClose onClick={onClose} />
      <Text typography="accentLarge">{t('helpInformation')}</Text>
      <Text>{text}</Text>
    </Wrapper>
  )
}
