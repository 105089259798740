import { FC } from 'react'

import { Bid } from 'modules/domain/bid/types'
import { Card } from 'views/components/Card/Card'
import { BidFiles } from './BidFiles'
import { refetchFunc } from 'modules/domain/common/hooks'
import { GeneralInformation } from '../GeneralInformation'
import { ExtraInfo } from './ExtraInfo'
import { BidLeftBlockTwoColumns } from '../styled'

interface Props {
  refreshBid: refetchFunc
  bid: Bid
}

export const BidLeftBlock: FC<Props> = ({ bid, refreshBid }) => {
  return (
    <Card.Container>
      <BidLeftBlockTwoColumns>
        <div>
          <GeneralInformation bid={bid} />
          <BidFiles id={bid.id} />
        </div>

        <ExtraInfo bid={bid} refreshBid={refreshBid} />
      </BidLeftBlockTwoColumns>
    </Card.Container>
  )
}
