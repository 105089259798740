import styled from 'styled-components'
import { CustomScroll } from 'views/styled/common'

export const BoardContainer = styled.div`
  display: inline-grid;
  grid-column-gap: 24px;
  grid-auto-flow: column;
  grid-template-columns: 330px 330px 330px;

  ${CustomScroll}
  .ant-collapse {
    max-height: calc(100dvh - 160px);
  }

  .ant-collapse-content-box {
    max-height: calc(100dvh - 350px);
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0 16px 0 16px;
    margin-right: 2px;
    box-shadow: inset 0px 20px 5px -15px rgba(39, 45, 51, 0.08);

    ${CustomScroll}
    &::-webkit-scrollbar {
      display: none;
    }

    &:hover::-webkit-scrollbar {
      display: block;
    }
  }

  .ant-collapse-content {
    border-top: none;
  }
`
