import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Deal } from 'modules/domain/deal/types'
import { getName } from './helpers'
import { isBrazil } from 'env'
import { getRegionalListSortedByWorkStage } from 'views/pages/Deal/DealDetail/helpers'
import { RemainingCountAndTooltip } from 'views/pages/Deal/DealDetail/DealExecutorsBlock/RemainingCountAndTooltip'
import { useMediaQuery } from 'react-responsive'
import { defaultTheme } from '@agro-club/agroclub-shared'
import { KeyValue } from 'views/components/KeyValue/KeyValue'

interface Props {
  deal: Deal
}

export const Regionals: FC<Props> = ({ deal }) => {
  const { t } = useTranslation('executors')

  const isMobile = useMediaQuery({
    query: `(max-width: ${defaultTheme.queries.mobile})`,
  })

  if (!isBrazil) {
    const regionals = getRegionalListSortedByWorkStage(deal?.executors?.regionals)

    return (
      <KeyValue
        horizontal={!isMobile}
        label={t(`regionals`)}
        columnGap={!isMobile ? 'medium' : undefined}
        labelTypo="bodyMedium"
        valueTypo="bodyLarge"
      >
        {!!regionals?.length ? (
          <div>
            {getName(regionals[0])}
            &nbsp;
            <RemainingCountAndTooltip executors={regionals} />
          </div>
        ) : (
          t('not_selected')
        )}
      </KeyValue>
    )
  }

  return null
}
