import { useTranslation } from 'react-i18next'
import { BorderedItem } from 'views/components/BorderedItem/BorderedItem'

export const NoTasks = () => {
  const { t } = useTranslation('my_tasks')

  return (
    <BorderedItem hover={false} justifyContent="center">
      {t('filter_task_not_found')}
    </BorderedItem>
  )
}
