import { ASharedModal, SharedModalContent, SharedModalTitle } from 'views/components/SharedModal'
import ADefaultModalFooter from 'views/components/DefaultModalFooter/ADefaultModalFooter'
import { AddressControl } from 'views/components/Maps/AddressControl'
import { GeoAddress } from '@agro-club/agroclub-shared'
import { useTranslation } from 'react-i18next'
import { FC, useState } from 'react'

type Props = {
  onClose: () => void
  address?: GeoAddress
  setAddress: (address: GeoAddress) => void
}

export const MapModal: FC<Props> = ({ onClose, address, setAddress }) => {
  const { t } = useTranslation('calculator')
  const [internalAddress, setInternalAddress] = useState<GeoAddress>()

  const onSuccess = () => {
    if (!internalAddress) return
    setAddress(internalAddress)
    onClose()
  }

  return (
    <ASharedModal id="addAddress" size="large" onClose={onClose}>
      <SharedModalTitle>{t('addAddress')}</SharedModalTitle>
      <SharedModalContent>
        <AddressControl handlerMap={setInternalAddress} mapHeight={400} address={address} />
      </SharedModalContent>
      <ADefaultModalFooter
        confirmButtonText={t('common:confirm')}
        disabled={!internalAddress}
        onConfirm={onSuccess}
        onClose={onClose}
      />
    </ASharedModal>
  )
}
