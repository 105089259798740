import AUploadFiles from 'views/components/UploadFiles/AUploadFiles'
import { uploadFile } from 'modules/domain/common/managers'
import { Progress } from '@agro-club/agroclub-shared'
import { apiClient } from 'modules/utils/httpClient'
import { RespFile } from 'modules/domain/types'
import { endpoints } from 'modules/endpoints'
import { useParams } from 'react-router-dom'
import { FC } from 'react'

type Props = {
  refetchFiels: (silent: boolean) => void
  files: RespFile[] | undefined
  progress: Progress
}

export const Files: FC<Props> = ({ progress, refetchFiels, files }) => {
  const { id } = useParams()

  const removeFile = async (fileId: string) => {
    await apiClient.delete(endpoints.sampleFiles(id, fileId))
    refetchFiels(true)
  }

  const addFile = async (file: File) => {
    await uploadFile(endpoints.sampleFiles(id), file)
    refetchFiels(true)
  }

  return (
    <AUploadFiles
      removeFile={(fileId) => removeFile(fileId)}
      addFile={({ file }) => addFile(file)}
      progress={progress}
      fileList={files}
    />
  )
}
