const main = 'deals'
const relativeRoutes = {
  relativePage: `${main}/*`,
  relativeList: `${main}`,
  relativeDetail: ':id',
  relativeDetailOld: ':id/edit',
  relativeListWithTab: 'tab-:activeTab',
  relativeListWithTabAndDeal: 'tab-:activeTab/selectedDeal-:selectedDeal',
}

const routes = {
  List: `/${main}`,
  ListWithTab: `/${main}/${relativeRoutes.relativeListWithTab}`,
  ListWithTabAndDeal: `/${main}/${relativeRoutes.relativeListWithTabAndDeal}`,
  Details: `/${main}/${relativeRoutes.relativeDetail}`,
  DetailOld: `/${main}/${relativeRoutes.relativeDetailOld}`,
}

const DealRoutes = { ...relativeRoutes, ...routes }

export default DealRoutes
