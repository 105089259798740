import { Icons } from '@agro-club/agroclub-shared'
import { FC, useState } from 'react'
import styled from 'styled-components'
import { EditLogisticianModal } from './EditLogisticianModal/EditLogisticianModal'
import { isBrazil } from 'env'
import { AButton } from 'views/components/Analytics'
import { DASH } from 'modules/constants'

interface Props {
  data: any
  permissions: string[]
  deal_id: string
  onSuccess: () => void
}

const LogisticianWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 4px;
`

const LogisticianList = styled.div`
  display: flex;
  flex-direction: column;
`

export const LogisticianCell: FC<Props> = ({ data, permissions, deal_id, onSuccess }) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const logisticians = isBrazil ? [data.logistician] : data.logisticians
  const logisticianIds = logisticians?.filter(Boolean).map((el) => el.id)

  return (
    <>
      <LogisticianWrapper>
        <LogisticianList>
          {!!logisticians?.length
            ? logisticians?.map((el, index) => <div key={el?.id || index}>{el?.full_name || DASH}</div>)
            : DASH}
        </LogisticianList>

        <AButton
          id="edit"
          intent="secondary"
          Icon={Icons.IconEdit}
          size="small"
          onClick={() => setIsModalOpen(true)}
          disabled={!permissions.includes('deals.change_dealexecutors')}
        />
      </LogisticianWrapper>

      {isModalOpen && (
        <EditLogisticianModal
          id={logisticianIds}
          deal_id={deal_id}
          onClose={() => setIsModalOpen(false)}
          onSuccess={onSuccess}
        />
      )}
    </>
  )
}
