import { defaultTheme } from '@agro-club/agroclub-shared'
import { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'

export const calculateScrollHeight = (id: string, hasPaganation: boolean, hasMobileMenu: boolean) => {
  // Default bottom pagination 64 + pagination top margin
  const paginationSize = (hasPaganation ? 64 : 0) + 24
  const mobileMenuSize = hasMobileMenu ? 93 : 0
  let tHeaderBottom = 0
  if (id) {
    // it is rather dirty solution, but i haven't better yet
    const element = document.getElementById(id)
    if (element) {
      tHeaderBottom = element.getElementsByClassName('ant-table-thead')[0].getBoundingClientRect().bottom
    }
  } else {
    const tableHeaders = document.getElementsByClassName('ant-table-thead')
    for (const item of tableHeaders) {
      const rect = item.getBoundingClientRect()
      if (rect.height) {
        tHeaderBottom = rect.bottom
        break
      }
    }
  }
  const pageHeader = 0

  const height = `calc(100dvh - ${tHeaderBottom + paginationSize + pageHeader + mobileMenuSize}px)`

  return height
}
export const useTableScroll = (id: string, hasPaganation: boolean) => {
  const [height, setHeight] = useState<string>('200px')
  const isMobile = useMediaQuery({
    query: `(max-width: ${defaultTheme.queries.mobile})`,
  })

  // useEffect to make sure that search by css isn't slow down react render
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    setHeight(calculateScrollHeight(id, hasPaganation, isMobile))
  })
  return height
}
