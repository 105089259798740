import { useCallback, useState } from 'react'

export const useLocalStorage = (key: string, initialValue: any) => {
  const [storedValue, setStoredValue] = useState(() => {
    const item = localStorage.getItem(key)
    return item ? JSON.parse(item) : initialValue
  })

  const setValue = useCallback(
    (value: any) => {
      setStoredValue(value)
      localStorage.setItem(key, JSON.stringify(value))
    },
    [key],
  )

  return [storedValue, setValue]
}
