import { DealContract } from 'modules/domain/deal/types'
import { formatQuantity } from 'modules/utils/numbers/formatQuantity'
import { useTranslation } from 'react-i18next'
import { Card } from 'views/components/Card/Card'
import { DealLink } from 'views/components/DealLink/DealLink'
import { KeyValue } from 'views/components/KeyValue/KeyValue'

import useFormatDate from 'hooks/useFormatDate'
import { DASH } from 'modules/constants'
import { refetchFunc } from 'modules/domain/common/hooks'
import { formatTarif } from 'modules/utils/numbers/formatTarif'
import styled from 'styled-components'
import { CompanyLink } from 'views/components/CompanyLink/CompanyLink'
import { StagesProcessing } from 'views/pages/Contracts/ContractDetails/GeneralInfo/StagesProcessing'
import { MultilineText } from 'views/styled/common'
import APhone from 'views/ui/Phone/APhone'

const TwoColumns = styled.div`
  display: grid;
  grid-template-columns: 664px 242px;
  column-gap: 100px;
`

type Props = {
  contract: DealContract
  refetch: refetchFunc
}

export const GeneralInfo = ({ contract, refetch }: Props) => {
  const { t } = useTranslation('contracts')
  const formatDate = useFormatDate()

  const dispatchingDate = formatDate(contract.dispatching_date)
  return (
    <Card.Container>
      <TwoColumns>
        <div>
          <Card.GapableContent>
            <Card.Title>{t('generalInfo.contract_format')}</Card.Title>
            <div>{t(`contractType.${contract.contract_type}`)}</div>
          </Card.GapableContent>

          <Card.GapableContent>
            <Card.Title>{t('generalInfo.general_info')}</Card.Title>
            <Card.Grid cols={4}>
              <KeyValue label={t('common:deal')}>
                <DealLink dealId={contract.deal} />
              </KeyValue>
              <KeyValue label={t('common:product')}>{contract.product.title}</KeyValue>
              <KeyValue label={t('common:quantity')}>{formatQuantity(+contract.quantity)}</KeyValue>
              <KeyValue label={t('common:price:exw')}>{formatTarif(contract.price, contract.has_vat)}</KeyValue>
              <KeyValue label={t('generalInfo.season')}>{contract.season}</KeyValue>
              <KeyValue label={t('generalInfo.product_purpose')}>
                {t(`productPurpose.${contract.product_purpose}`)}
              </KeyValue>
              <KeyValue label={t('generalInfo.ds')}>
                {t(`contractType.${contract.conformity_declaration_type}`)}
              </KeyValue>
              <KeyValue label={t('generalInfo.dispatching_date')}>{dispatchingDate}</KeyValue>
              <KeyValue style={{ gridColumnEnd: 'span 4' }} label={t('generalInfo.payment_terms')}>
                {contract.payment_terms || DASH}
              </KeyValue>
            </Card.Grid>
          </Card.GapableContent>

          <Card.GapableContent>
            <Card.Title>{t('generalInfo.product_params')}</Card.Title>
            <Card.Grid cols={4}>
              {contract.parameters.map((param) => (
                <KeyValue key={param.id} label={param.parameter.name}>
                  {param.parameter_value}
                  {param.parameter.units}
                </KeyValue>
              ))}
            </Card.Grid>
            <KeyValue label={t('common:comment')}>
              <MultilineText>{contract.parameters_comment}</MultilineText>
            </KeyValue>
          </Card.GapableContent>

          <Card.GapableContent>
            <Card.Title>{t('generalInfo.seller')}</Card.Title>
            <KeyValue label={t('common:company')}>
              <CompanyLink company={contract.seller_company} />
              <div>
                {t('common:inn')} {contract.seller_company.inn}
              </div>
            </KeyValue>
            <KeyValue label={t('generalInfo.email')}>{contract.email}</KeyValue>
            <KeyValue label={t('common:phone')}>
              <APhone phone={contract.phone} />
            </KeyValue>
            <KeyValue label={t('generalInfo.transfer_address')}>{contract.transfer_address}</KeyValue>
          </Card.GapableContent>
        </div>

        <StagesProcessing contract={contract} refetch={refetch} />
      </TwoColumns>
    </Card.Container>
  )
}
