import { Card } from 'views/components/Card/Card'
import { KeyValue } from 'views/components/KeyValue/KeyValue'
import { DealLink } from 'views/components/DealLink/DealLink'
import { formatQuantity } from 'modules/utils/numbers/formatQuantity'
import { UserLink } from 'views/components/UserLink/UserLink'
import { DocumentRequestRegionals } from 'views/pages/DocumentRequests/DocumentRequestsDetail/DocumentRequestRegionals'
import { getDeclarationText } from 'views/pages/Deal/helpers/getDeclarationText'
import { DocumentRequestCompanyInfo } from 'views/pages/DocumentRequests/DocumentRequestsDetail/DocumentRequestCompanyInfo'
import { DocumentRequestsDates } from 'views/pages/DocumentRequests/DocumentRequestsDetail/DocumentRequestsDates'
import styled from 'styled-components'
import { DocumentRequestFull } from 'modules/domain/documentRequest/types'
import { useTranslation } from 'react-i18next'
import { refetchFunc } from 'modules/domain/common/hooks'
import { DASH } from 'modules/constants'

const TwoColumns = styled.div`
  display: grid;
  grid-template-columns: 664px 242px;
  column-gap: 100px;
`

type Props = {
  documentRequest: DocumentRequestFull
  documentRefetch: refetchFunc
}

export const DocumentRequestLeftBlock = ({ documentRequest, documentRefetch }: Props) => {
  const { t } = useTranslation('documentRequests')

  const requestedDocuments =
    [
      documentRequest?.request_ds ? t('detail.main.ds') : null,
      documentRequest?.request_quarantine ? t('detail.main.quarantine') : null,
      documentRequest?.request_vet ? t('detail.main.vet') : null,
      documentRequest?.request_sdiz ? t('detail.main.sdiz') : null,
    ]
      .filter(Boolean)
      .join(', ') || DASH

  return (
    <Card.Container>
      <TwoColumns>
        <div>
          <Card.GapableContent>
            <Card.Title>{t('detail.main.title')}</Card.Title>
            <div>{requestedDocuments}</div>
          </Card.GapableContent>

          <Card.GapableContent>
            <Card.Title>{t('detail.dealInfo.title')}</Card.Title>
            <Card.Grid cols={4}>
              <KeyValue label={t('common:deal')}>
                <DealLink dealId={documentRequest.deal} />
              </KeyValue>
              <KeyValue label={t('common:product')}>{documentRequest.product.title}</KeyValue>
              <KeyValue label={t('detail.dealInfo.productPurpose')}>
                {t(`detail.dealInfo.${documentRequest.product_purpose}`)}
              </KeyValue>
              <KeyValue label={t('common:quantity')}>{formatQuantity(documentRequest.quantity)}</KeyValue>
            </Card.Grid>
            <Card.Grid cols={2}>
              <KeyValue label={`${t('executors:current_deal_coordinator')} (${t('executors:deal_coordinator')})`}>
                <UserLink user={documentRequest.deal_coordinator} />
              </KeyValue>
              <DocumentRequestRegionals regionals={documentRequest.regionals} />
              <KeyValue
                label={`${t('executors:current_shipment_coordinator')} (${t('executors:shipment_coordinator')})`}
              >
                <UserLink user={documentRequest.shipment_coordinator} />
              </KeyValue>
            </Card.Grid>
            <KeyValue label={t('detail.dealInfo.declaration')}>
              {getDeclarationText(documentRequest.declaration)}
            </KeyValue>
          </Card.GapableContent>

          <Card.GapableContent>
            <Card.Title>{t('detail.sellerInfo.title')}</Card.Title>
            <DocumentRequestCompanyInfo company={documentRequest.seller_company} label={t('common:company')} />
            <KeyValue label={t('detail.sellerInfo.loadAddress')}>{documentRequest.load_address || DASH}</KeyValue>
          </Card.GapableContent>

          <Card.GapableContent>
            <Card.Title>{t('detail.customerInfo.title')}</Card.Title>
            <KeyValue label={t('detail.customerInfo.consignee')}>{documentRequest.consignee || DASH}</KeyValue>
            <DocumentRequestCompanyInfo company={documentRequest.customer_company} label={t('common:company')} />
            <KeyValue label={t('detail.customerInfo.unloadAddress')}>{documentRequest.unload_address || DASH}</KeyValue>
            {documentRequest.pay_to_customer_account && (
              <KeyValue label={t('detail.customerInfo.extra')}>
                {t('detail.main.payToCustomerAccount', { company: documentRequest.customer_company.name })}
              </KeyValue>
            )}
          </Card.GapableContent>
        </div>

        <div>
          <DocumentRequestsDates documentRequest={documentRequest} refetch={documentRefetch} />
        </div>
      </TwoColumns>
    </Card.Container>
  )
}
