import { CommentsItem } from 'views/components/Comments/CommentsItem'
import { Comment } from 'views/components/Comments/hooks'
import * as S from 'views/components/Comments/styled'

import { Spinner } from '../CommonTableComponents/CommonTableComponents'
import { FC, useEffect, useMemo, useRef } from 'react'
import { UI_TIMEOUT_200 } from 'modules/constants'
import { PinnedCommentItem } from 'views/components/Comments/PinnedCommentItem'
import { ACommentsTrack } from 'views/components/Comments/AComments'

type Props = {
  isLoading?: boolean
  comments: Comment[]
  maxHeight?: number
  isEdit: boolean
  setComment: (text: string) => void
  setIsEdit: (arg: boolean) => void
  setCommentId: (id: string) => void
  onATrack: ACommentsTrack
}

export const CommentsList: FC<Props> = ({
  isLoading,
  comments,
  maxHeight,
  setComment,
  setIsEdit,
  isEdit,
  setCommentId,
  onATrack,
}) => {
  const listRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (isLoading) return

    setTimeout(() => {
      listRef?.current?.scrollTo({ top: listRef?.current?.scrollHeight })
    }, UI_TIMEOUT_200)
  }, [comments.length, isLoading])

  const sortedComments = useMemo(
    () =>
      comments.map((comment) =>
        comment.is_pinned ? (
          <PinnedCommentItem
            onATrack={onATrack}
            setCommentId={setCommentId}
            key={comment.id}
            setComment={setComment}
            setIsEdit={setIsEdit}
            isEdit={isEdit}
            comment={comment}
          />
        ) : (
          <CommentsItem key={comment.id} comment={comment} />
        ),
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [comments, isEdit, setComment, setCommentId, setIsEdit],
  )

  return sortedComments.length > 0 ? (
    <S.CommentsList maxHeight={maxHeight} ref={listRef}>
      {isLoading ? <Spinner /> : sortedComments}
    </S.CommentsList>
  ) : null
}
