import { ColumnData } from 'modules/types'
import { useMemo } from 'react'
import { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'
import { RangeType } from './types'
import { EditRatesCell } from './components/EditRatesCell'

const getColumns = (range: RangeType[], t: TFunction, onSuccess: () => void) => {
  const rangeColumns = range.map((range, index) => ({
    key: range.upper.toString(),
    title: range.upper,
    width: 56,
    align: 'right',
    dataIndex: 'logistics_rates',
    render: (logistics_rates) => logistics_rates?.[index]?.rate,
  }))

  const results = [
    ...rangeColumns,
    {
      title: '',
      width: 50,
      dataIndex: 'id',
      align: 'center',
      fixed: 'right',
      render: (id, data) => <EditRatesCell onSuccess={onSuccess} ratesData={data.logistics_rates} />,
    },
  ]

  return results
}

export const useAverageColumns = (range: RangeType[], onSuccess: () => void): ColumnData[] => {
  const { t } = useTranslation('rates')

  return useMemo(() => getColumns(range, t, onSuccess), [range, t, onSuccess])
}
