import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'

import { apiClient } from 'modules/utils/httpClient'
import { endpoints } from 'modules/endpoints'
import { SharedModalContent, SharedModalInputs, SharedModalTitle } from 'views/components/SharedModal'
import { Trailer } from 'views/pages/LogisticsKR/types'
import { TrailerModalContent } from './TrailerModalContent'
import ASharedModal from 'views/components/SharedModal/ASharedModal'
import ADefaultModalFooter from 'views/components/DefaultModalFooter/ADefaultModalFooter'
import { AnalyticsPlaces } from '../../analyticsPlaces'
import { useAFormHandler } from 'analytics/hooks'

interface Props {
  setIsOpen: (val: boolean) => void
  carrierId: string
  onSuccess: () => void
  trailer: Trailer
}

export const TrailerEditModal: FC<Props> = ({ setIsOpen, carrierId, onSuccess, trailer }) => {
  const { formProgress, formHandler } = useAFormHandler()
  const { t } = useTranslation('logisticsKR')

  const handleEdit = formHandler(
    async () => await apiClient.put(endpoints.LogisticsKR.trailers(trailer?.id), formik.values),

    {
      onSuccess: async () => {
        await onSuccess()
        setIsOpen(false)
      },
      onError: (error) => {
        const { errors } = error
        formik.setErrors(errors)
      },
    },
  )

  const formik = useFormik({
    initialValues: {
      carrier: carrierId,
      licence_number: trailer.licence_number,
      brand_model: trailer.brand_model,
      unloading_type: trailer.unloading_type,
      side_height: trailer.side_height,
    },
    enableReinitialize: true,
    onSubmit: handleEdit,
  })

  return (
    <ASharedModal id={AnalyticsPlaces.CARRIERS.EDIT_TRAILER_MODAL} size="small" onClose={() => setIsOpen(false)}>
      <SharedModalTitle>{t('common.editTrailer')}</SharedModalTitle>
      <SharedModalContent>
        <SharedModalInputs>
          <TrailerModalContent formik={formik} isEditModal />
        </SharedModalInputs>
      </SharedModalContent>
      <ADefaultModalFooter
        progress={formProgress}
        onClose={() => setIsOpen(false)}
        onConfirm={() => formik.submitForm()}
        confirmButtonText={t('common:save')}
      />
    </ASharedModal>
  )
}
