import { FC, useCallback } from 'react'
import { WrapperCards } from '../../../styled'
import { AntTag, Progress } from '@agro-club/agroclub-shared'
import { generatePath } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { CarStatuses, LogisticsKRCar } from 'views/pages/LogisticsKR/types'
import { CardGroupInfo, CardWrapper, TagsWrapper } from '../../../components/styled'
import { LogisticRequest } from 'modules/domain/logisticsKR/types'
import CarriersKRRoutes from 'views/pages/LogisticsKR/CarriersKR/routes'
import APhone from 'views/ui/Phone/APhone'
import { CardButtonWrapper } from 'views/styled/ItemCardStyles'
import { PreloadCard } from 'views/components/Board/Preload'
import { CarForRequestActions } from 'views/pages/LogisticsKR/components/CarActions/CarForRequestActions'
import { ARouterLink } from 'views/components/Analytics'
import { refetchFunc } from 'modules/domain/common/hooks'

type TagsProps = {
  car: LogisticsKRCar
}

const Tags: FC<TagsProps> = ({ car }) => {
  const { t } = useTranslation('logisticsKR')
  const labels: string[] = []

  if (car.travelled_from_loading_to_unloading_warehouse_matched) {
    labels.push(t('matches_tags.travelled_from_loading_to_unloading_warehouse_matched'))
  } else {
    if (car.visited_loading_warehouse_matched) {
      labels.push(t('matches_tags.visited_loading_warehouse_matched'))
    }
    if (car.travelled_from_loading_to_unloading_region_matched) {
      labels.push(t('matches_tags.travelled_from_loading_to_unloading_region_matched'))
    }
  }

  if (car.favorite_region_matched) labels.push(t('matches_tags.favorite_region_matched'))

  if (car.carrier_type_matched) {
    labels.push(t('matches_tags.carrier_type.global'))
  } else {
    labels.push(t('matches_tags.carrier_type.local'))
  }

  if (car.is_available_matched) labels.push(t('matches_tags.is_available_matched'))

  return (
    <TagsWrapper>
      {car.status === CarStatuses.draft && <AntTag color="red">{t('matches_tags.draft')}</AntTag>}
      {labels.map((label) => (
        <AntTag key={label} color="white">
          {label}
        </AntTag>
      ))}
    </TagsWrapper>
  )
}

type CardProps = {
  car: LogisticsKRCar
  activeRequest: LogisticRequest | undefined
  refetchDraftTrips: refetchFunc
  refetchMatches: refetchFunc
  refetchInWorkRequestList: refetchFunc
}

const MatchesCard: FC<CardProps> = ({
  car,
  activeRequest,
  refetchDraftTrips,
  refetchMatches,
  refetchInWorkRequestList,
}) => {
  const onSuccess = useCallback(
    async (arg?: boolean) => {
      await refetchInWorkRequestList(arg)
      await refetchDraftTrips(arg)
    },
    [refetchInWorkRequestList, refetchDraftTrips],
  )

  return (
    <CardWrapper>
      <Tags car={car} />
      <CardGroupInfo>
        <ARouterLink
          eventName="match"
          itemId={car.carrier.id}
          to={generatePath(CarriersKRRoutes.Details, { id: car.carrier.id })}
        >
          {car.carrier.name}
        </ARouterLink>
        <APhone phone={car.carrier?.phone} />
        <div>{`${car.brand_model} ${car.licence_number}`}</div>
        {/*<div>СЮДА НДС</div>*/}
        <CardButtonWrapper>
          <CarForRequestActions
            car={car}
            request={activeRequest}
            onTripCreated={onSuccess}
            refetchCars={refetchMatches}
          />
        </CardButtonWrapper>
      </CardGroupInfo>
    </CardWrapper>
  )
}

type Props = {
  relevantCars: LogisticsKRCar[] | undefined
  activeRequest: LogisticRequest | undefined
  refetchMatches: refetchFunc
  refetchDraftTrips: refetchFunc
  refetchInWorkRequestList: refetchFunc
  progress: Progress
}

export const Matches: FC<Props> = ({
  relevantCars,
  activeRequest,
  refetchDraftTrips,
  progress,
  refetchMatches,
  refetchInWorkRequestList,
}) => {
  if (progress === Progress.WORK) {
    return (
      <WrapperCards>
        <PreloadCard />
      </WrapperCards>
    )
  }

  return (
    <WrapperCards>
      {relevantCars?.map((car) => (
        <MatchesCard
          key={car?.id}
          car={car}
          activeRequest={activeRequest}
          refetchMatches={refetchMatches}
          refetchDraftTrips={refetchDraftTrips}
          refetchInWorkRequestList={refetchInWorkRequestList}
        />
      ))}
    </WrapperCards>
  )
}
