import { defaultTheme, Typography } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

export const CurrentMonth = styled.div`
  ${Typography.accentLarge}
  margin-bottom: 4px;
  color: ${defaultTheme.color.textPrimary900};

  :first-letter {
    text-transform: capitalize;
  }
`

export const Title = styled.div`
  margin-bottom: 16px;
  ${Typography.titleH4}
  color: ${defaultTheme.color.textPrimary900};
`

export const TableContainer = styled.div`
  width: 100%;
  margin-top: 16px;

  tbody > .current-month-row {
    td {
      ${Typography.accentLarge}
      line-height: 19px;
    }
  }

  tbody > .month-row {
    td {
      ${Typography.bodyLarge}
      line-height: 19px;
      padding: 12px !important;
    }
  }

  .ant-table-thead > tr > th {
    padding: 12px;
    ${Typography.bodyLarge};
    line-height: 1.2 !important;
  }
`
