import { CompanyControlsProps } from '../types'
import { isRussia } from 'env'
import { WorldCompanyControls } from './WorldCompanyControls'
import { RuCompanyControl } from './RuCompanyControl'

export const CompanyFormControls = ({ formik }: CompanyControlsProps) => {
  if (isRussia) {
    return <RuCompanyControl formik={formik} />
  }

  return <WorldCompanyControls formik={formik} />
}
