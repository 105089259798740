import { AntSkeleton, Icons, Progress, Text } from '@agro-club/agroclub-shared'
import { isUS } from 'env'
import { useTableData } from 'modules/domain/common/hooks'
import { DealCondition, DealStatusTypes, ListDeal, MarginsforDeals } from 'modules/domain/deal/types'
import { ListRequestParams } from 'modules/domain/types'
import { endpoints } from 'modules/endpoints'
import { FC, useEffect } from 'react'
import { useParams } from 'react-router'
import styled from 'styled-components'
import { AButton } from 'views/components/Analytics'
import { BoardColumn, BoardContent, BoardHeader, BoardScrollWrapper } from 'views/components/Board/CardsBoard'
import { NoDataCard } from 'views/components/Board/NoDataCard'
import { PreloadCard, PreloadCount } from 'views/components/Board/Preload'
import { Count } from 'views/components/Board/styled'
import PermissionCheck from 'views/components/PermissionCheck/PermissionCheck'
import { DataMargin } from '../helpers/DataMargin'
import { DealCard } from './DealCard'
import { useColumnContentMargin } from './hooks'

type Props = {
  column: { statusDeal: DealStatusTypes; label: string }
  listRequestParams: ListRequestParams
  margins: MarginsforDeals | undefined
  dealsLoadingStatus?: Progress
  onChangeBatch: () => void
  revenues?: MarginsforDeals
  progressMargin: Progress
  typeDeals: DealCondition
  isOpenBatchBlock: boolean
  isFiltersLoaded: boolean
  activeTab: string
}

export const PreloadMargin = () => (
  <AntSkeleton.Button active size="small" style={{ height: 20, width: 200, marginTop: 4 }} />
)

const TopHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 16px;
`
export const Column: FC<Props> = ({
  listRequestParams,
  isFiltersLoaded,
  progressMargin,
  activeTab,
  typeDeals,
  revenues,
  margins,
  column,
  onChangeBatch,
  isOpenBatchBlock,
}) => {
  const { statusDeal, label } = column

  const getEndpoint = (status: DealStatusTypes) => {
    if (typeDeals === DealCondition.open) {
      return endpoints.dealActualWithStatus(status)
    }

    return endpoints.dealClosedWithStatus(status)
  }

  const { progress: dealsLoadingStatus, data: deals = [] } = useTableData<ListDeal>(
    getEndpoint(statusDeal),
    listRequestParams,
    isFiltersLoaded,
  )
  const loadDeals = dealsLoadingStatus === Progress.WORK

  const moneyBlock =
    !margins || progressMargin === Progress.WORK ? (
      PreloadMargin()
    ) : (
      <DataMargin status={statusDeal} margins={margins} revenues={revenues} />
    )

  const { selectedDeal: selectedDealParams } = useParams()

  useEffect(() => {
    if (selectedDealParams && dealsLoadingStatus === Progress.SUCCESS) {
      const el = document.getElementById(`${selectedDealParams + 'active'}`)
      el?.scrollIntoView({ block: 'center', inline: 'center' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dealsLoadingStatus])

  const topMargin = useColumnContentMargin(statusDeal, progressMargin)

  const isShowBatchesBtn = statusDeal === DealStatusTypes.CONTRACT_SIGNED && isUS && activeTab === DealCondition.open

  return (
    <BoardColumn borderRadius={isOpenBatchBlock && isShowBatchesBtn ? '8px 0 8px 8px' : undefined}>
      <BoardHeader>
        <TopHeader>
          <Text as="span" typography="titleH4">
            {label}
            {loadDeals ? <PreloadCount /> : <Count color={deals.length ? 'orange' : 'grey'}>{deals.length}</Count>}
          </Text>
          {isShowBatchesBtn && (
            <PermissionCheck scope={['bids.view_position']}>
              <AButton
                Icon={isOpenBatchBlock ? Icons.IconArrowLeftS : Icons.IconArrowRightS}
                onClick={onChangeBatch}
                intent="primary"
                id="openBatches"
              />
            </PermissionCheck>
          )}
        </TopHeader>

        {typeDeals == DealCondition.open ? moneyBlock : null}
      </BoardHeader>
      <BoardScrollWrapper topMargin={topMargin} id={statusDeal}>
        <BoardContent>
          {loadDeals ? (
            <PreloadCard />
          ) : (
            deals.map((deal) => <DealCard key={deal.id} deal={deal} activeTab={activeTab} />)
          )}
          {!loadDeals && !deals.length && <NoDataCard />}
        </BoardContent>
      </BoardScrollWrapper>
    </BoardColumn>
  )
}
