import { AMobileModal } from 'views/components/MobileComponents/Modal/AMobileModal'
import { DealAccordion } from '../DealAccordion/DealAccordion'
import { useSingleEntity } from 'modules/domain/common/hooks'
import { Declaration } from 'modules/domain/types'
import { Deal } from 'modules/domain/deal/types'
import { endpoints } from 'modules/endpoints'
import styled from 'styled-components'
import { FC } from 'react'

/* TODO: временный компонент */
type Props = {
  onClose: () => void
  deal: Deal
}
const EmptyBlock = styled.div`
  height: 30px;
`
const WrapperMobileModal = styled.div`
  margin: -16px;
`
export const AccordionModal: FC<Props> = ({ onClose, deal }) => {
  const [declarationProgress, declaration, declarationRefetch] = useSingleEntity<Declaration | undefined>(
    endpoints.dealDeclaration(deal.id),
  )
  return (
    <AMobileModal id="" onClose={onClose} header={<EmptyBlock />}>
      <WrapperMobileModal>
        <DealAccordion
          declaration={declaration}
          declarationRefetch={declarationRefetch}
          declarationProgress={declarationProgress}
        />
      </WrapperMobileModal>
    </AMobileModal>
  )
}
