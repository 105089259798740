import useFormatDate from 'hooks/useFormatDate'
import { PHONE_TABLE_CELL_WIDTH } from 'modules/constants'
import { ListRequestParams } from 'modules/domain/types'
import { ColumnData } from 'modules/types'
import { getPersonalNumberLabel } from 'modules/utils/numbers/formatPersonalNumber'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router-dom'
import APhone from 'views/ui/Phone/APhone'
import { TableTextLink } from 'views/ui/TableTextLink/TableTextLink'
import CarriersKRPage from '../../routes'
import { ActionsCell } from './ActionsCell'
import { CarrierStatusCell } from './CarrierStatusCell'

type Props = {
  listRequestParams: ListRequestParams
  setListRequestParams: (ListRequestParams) => void
}

const useColumns = ({ setListRequestParams, listRequestParams }: Props): ColumnData[] => {
  const { t } = useTranslation('logisticsKR')
  const formatDate = useFormatDate()
  return useMemo(
    () => [
      {
        title: t('carriers.tableHeaders.register_date'),
        width: 140,
        dataIndex: 'created',
        render: (value) => formatDate(value),
      },
      {
        title: t('carriers.tableHeaders.status'),
        width: 98,
        dataIndex: 'status',
        render: (status) => <CarrierStatusCell status={status} />,
      },
      {
        title: t('common:company'),
        width: 380,
        dataIndex: 'name',
        render: (name, record) => (
          <TableTextLink
            id="carrier"
            tableItemId={record.id}
            to={generatePath(CarriersKRPage.Details, { id: record.id })}
          >
            {name}
          </TableTextLink>
        ),
      },
      {
        title: getPersonalNumberLabel(),
        width: 120,
        dataIndex: 'inn',
      },
      {
        title: t('common:phone'),
        width: PHONE_TABLE_CELL_WIDTH,
        dataIndex: 'phone',
        render: (value) => value && <APhone phone={value} className="phone-link-table" shortBtnTitle />,
      },
      {
        title: t('customers.contact_name'),
        width: 358,
        dataIndex: 'contact_name',
      },
      {
        title: t('carriers.tableHeaders.contract'),
        width: 82,
        dataIndex: 'contract',
      },
      {
        title: t('carriers.tableHeaders.is_in_charter'),
        width: 72,
        dataIndex: 'is_in_charter',
        render: (value) => (value ? t('common:yes') : t('common:no')),
      },
      {
        title: t('carriers.tableHeaders.cars_count'),
        width: 116,
        dataIndex: 'cars_count',
        align: 'center',
      },
      {
        title: t('carriers.tableHeaders.actions'),
        width: 110,
        dataIndex: 'actions',
        render: (_, data) => (
          <ActionsCell data={data} listRequestParams={listRequestParams} setListRequestParams={setListRequestParams} />
        ),
      },
    ],
    [listRequestParams, setListRequestParams, t, formatDate],
  )
}

export default useColumns
