import { FC } from 'react'

import { DealShipment, DealShipmentForm } from 'modules/domain/deal/types'
import { useFormik } from 'formik'
import { SharedModalContent, SharedModalTitle } from 'views/components/SharedModal'
import { useTranslation } from 'react-i18next'
import { ShortFormControl } from 'views/styled/common'
import { DatePicker } from 'views/components/DatePicker/DatePicker'
import { DateISO, FormGroup, Input } from '@agro-club/agroclub-shared'
import env from 'env'
import * as managers from 'modules/domain/deal/managers'
import { QuantityInput } from 'views/components/QuantityInput/QuantityInput'
import { useAFormHandler } from 'analytics/hooks'
import ASharedModal from 'views/components/SharedModal/ASharedModal'
import { AnalyticsPlaces } from '../../../analyticsPlaces'
import ADefaultModalFooter from 'views/components/DefaultModalFooter/ADefaultModalFooter'
import { labelPriceLogistics } from 'modules/utils/numbers/formatPriceLogistics'

interface Props {
  dealId: string
  shipment: DealShipment
  onSuccess: () => Promise<void>
  onClose: () => void
}

export const EditShipmentModal: FC<Props> = ({ dealId, onClose, shipment, onSuccess }) => {
  const { t } = useTranslation('deal')
  const { formProgress, formHandler } = useAFormHandler()

  const currency = env.REACT_APP_CURRENCY_SYMBOL
  const formik = useFormik<DealShipmentForm>({
    initialValues: {
      delivered_at: shipment.delivered_at,
      price_logistics: shipment.price_logistics,
      price_cpt: shipment.price_cpt,
      price_exw: shipment.price_exw,
      quantity: shipment.quantity,
    },
    onSubmit: formHandler(async () => await managers.editShipmentItem(dealId, shipment.id, formik.values), {
      onSuccess: async () => {
        await onSuccess()
        onClose()
      },
      onError: (error) => {
        const { errors } = error
        formik.setErrors(errors)
      },
    }),
  })

  return (
    <ASharedModal id={AnalyticsPlaces.EDIT_SHIPMENT_MODAL} size="small" onClose={onClose}>
      <SharedModalTitle>{t('common:editing')}</SharedModalTitle>
      <SharedModalContent>
        <ShortFormControl>
          <FormGroup label={t('fields.dateOfShipment')} error={formik.errors.delivered_at}>
            <DatePicker
              onChange={(data) => formik.setFieldValue('delivered_at', data['date'])}
              error={Boolean(formik.errors.delivered_at)}
              placeholder={t('common:datePlaceholder')}
              value={formik.values.delivered_at as DateISO}
            />
          </FormGroup>
          <QuantityInput formik={formik} fieldName="quantity" label={t('common:volume')} />
          <FormGroup label={labelPriceLogistics(t('common:logistics'))} error={formik.errors.price_logistics}>
            <Input
              {...formik.getFieldProps('price_logistics')}
              type="number"
              min={0}
              invalid={!!formik.errors.price_logistics}
            />
          </FormGroup>
          <FormGroup label={t('fields.price_exw', { currency })} error={formik.errors.price_exw}>
            <Input {...formik.getFieldProps('price_exw')} type="number" invalid={!!formik.errors.price_exw} min={0} />
          </FormGroup>
          <FormGroup label={t('fields.price_cpt', { currency })} error={formik.errors.price_cpt}>
            <Input {...formik.getFieldProps('price_cpt')} type="number" invalid={!!formik.errors.price_cpt} min={0} />
          </FormGroup>
        </ShortFormControl>
      </SharedModalContent>
      <ADefaultModalFooter
        progress={formProgress}
        onClose={onClose}
        confirmButtonText={t('common:save')}
        onConfirm={() => formik.submitForm()}
        disabled={!formik.dirty}
      />
    </ASharedModal>
  )
}
