import { defaultTheme } from '@agro-club/agroclub-shared'
import { Company, FactoringStatus } from 'modules/domain/company/types'

export const factoringStatusColors = {
  [FactoringStatus.in_progress]: defaultTheme.color.secondary300,
  [FactoringStatus.accepted]: defaultTheme.color.primary600,
  [FactoringStatus.cancelled]: defaultTheme.color.accentDestruct600,
}

export const getFactoringSummary = (company: Company) => {
  const fields = [company.factoring_seller_status, company.factoring_customer_status]
  if (fields.includes(FactoringStatus.accepted)) return FactoringStatus.accepted
  if (fields.includes(FactoringStatus.in_progress)) return FactoringStatus.in_progress
  if (fields.includes(FactoringStatus.cancelled)) return FactoringStatus.cancelled
  return null
}
