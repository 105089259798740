import { CountryRegionDistrictFilter } from './CountryRegionDistrictFilter'
import { RegionDistrictFilter } from './RegionDistrictFilter'
import { ListRequestParams } from 'modules/domain/types'
import { isRussia } from 'env'
import { FC } from 'react'

interface Props {
  updateFilterState: (filterObj: Record<string, any>) => void
  listRequestParams: ListRequestParams
}

export const CommonRegionFilter: FC<Props> = ({ listRequestParams, updateFilterState }) => {
  return isRussia ? (
    <CountryRegionDistrictFilter listRequestParams={listRequestParams} updateFilterState={updateFilterState} />
  ) : (
    <RegionDistrictFilter listRequestParams={listRequestParams} updateFilterState={updateFilterState} />
  )
}
