import { Icons, defaultTheme } from '@agro-club/agroclub-shared'
import { BidSources } from 'modules/domain/bid/types'
import { FC } from 'react'
import styled from 'styled-components'

interface Props {
  source?: string
}

const IconWrapper = styled.div`
  display: flex;
  width: 22px;
  height: 22px;

  svg {
    width: inherit;
    height: inherit;

    path {
      fill: ${defaultTheme.color.primary600};
    }
  }
`

export const BarchartIcon: FC<Props> = ({ source }) => {
  if (source !== BidSources.BARCHART) return null

  return (
    <IconWrapper>
      <Icons.IconBarchart />
    </IconWrapper>
  )
}
