import { FC } from 'react'

import { FormattedEventsCount } from 'modules/socket/types'
import { useTranslation } from 'react-i18next'
import { OptionItem } from '../types'

import MenuCounter from './MenuCounter'
import { Link } from 'react-router-dom'
import { getOptionCount } from '../utils'

interface Props {
  option: OptionItem
  count: FormattedEventsCount
  isChildrenOpt?: boolean
}

const MenuLabel: FC<Props> = ({ option, count, isChildrenOpt }) => {
  const { t } = useTranslation('menu')

  return (
    <>
      {option.children?.length ? t(option.translateKey) : <Link to={option.key}>{t(option.translateKey)}</Link>}
      {option.eventModel && isChildrenOpt ? (
        <MenuCounter count={getOptionCount(count, option.eventModel || [])} />
      ) : null}
    </>
  )
}

export default MenuLabel
