import { FC, useState } from 'react'

import { Card } from 'views/components/Card/Card'
import { PotentialBid } from 'modules/domain/potentialBid/types'
import { refetchFunc, useSingleEntity } from 'modules/domain/common/hooks'
import { Product } from 'modules/domain/collection/types'
import { endpoints } from 'modules/endpoints'
import { GeneralInformation } from '../GeneralInformation'
import { PotentialParamenters } from './PotentialParamenters'
import { BidLeftBlockTwoColumns } from '../styled'
import { PotentialBidEditModal } from '../../BidModals/PotentialBid/PotentialBidEditModal'

interface Props {
  refreshBid: refetchFunc
  bid: PotentialBid
}

export const BidPotentialLeftBlock: FC<Props> = ({ bid, refreshBid }) => {
  const [, product] = useSingleEntity<Product>(endpoints.productById(bid.product.id))
  const [isOpen, setIsOpen] = useState<boolean>(false)

  return (
    <Card.Container>
      <BidLeftBlockTwoColumns>
        <div>
          <GeneralInformation bid={bid} />
        </div>
        <PotentialParamenters bid={bid} setIsOpen={setIsOpen} />
      </BidLeftBlockTwoColumns>

      {isOpen && (
        <PotentialBidEditModal bid={bid} product={product} onSuccess={refreshBid} onClose={() => setIsOpen(false)} />
      )}
    </Card.Container>
  )
}
