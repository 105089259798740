import { FiltersWrapper } from 'views/components/CommonTableComponents/CommonTableComponents'
import { AClearFiltersButton } from 'views/components/Analytics'
import { useDisplayClearBtn } from 'hooks/useDisplayClearBtn'
import { FC, useMemo } from 'react'
import { ListRequestParams } from 'modules/domain/types'
import { LogisticianFilter } from '../../components/LogisticianFilter'
import { RegionsFilter } from './RegionsFilterById'
import { isBrazil, isRussia } from 'env'
import { ABaseTableFilter } from 'views/components/TableFilters/ABaseTableFilter'
import { endpoints } from 'modules/endpoints'
import { useTranslation } from 'react-i18next'

interface Props {
  listRequestParams: ListRequestParams
  updateFilterState: (filterObj: Record<string, any>) => void
  clearAllTabsState: () => void
  disabled: boolean
}

export const RatesFilters: FC<Props> = ({ listRequestParams, updateFilterState, clearAllTabsState, disabled }) => {
  const displayClearBtn = useDisplayClearBtn({
    listRequestParams,
  })
  const { t } = useTranslation()

  const regionDistrictFilter = useMemo(() => {
    const parentId = Array.isArray(listRequestParams.filter.country)
      ? listRequestParams.filter.country.join(',')
      : listRequestParams.filter.country

    return { parent_id: parentId }
  }, [listRequestParams.filter.country])

  if (!isBrazil) {
    return (
      <FiltersWrapper>
        {
          <>
            {isRussia ? (
              <>
                <ABaseTableFilter
                  onChange={(country) => updateFilterState({ country, id: [] })}
                  onClear={() => updateFilterState({ country: null, id: [] })}
                  value={listRequestParams.filter.country}
                  endpoint={endpoints.countries()}
                  placeholder={t('common:country')}
                  disabled={disabled}
                  showSearch={false}
                  simpleApiFormat
                  id="country"
                  multiple
                />
                {listRequestParams.filter.country?.length > 0 && (
                  <RegionsFilter
                    filter={regionDistrictFilter}
                    listRequestParams={listRequestParams}
                    updateFilterState={updateFilterState}
                    disabled={disabled}
                  />
                )}
              </>
            ) : (
              <RegionsFilter
                listRequestParams={listRequestParams}
                updateFilterState={updateFilterState}
                disabled={disabled}
              />
            )}
          </>
        }

        <LogisticianFilter
          listRequestParams={listRequestParams}
          updateFilterState={updateFilterState}
          disabled={disabled}
          fieldKey="logisticians"
          multiple
        />

        {displayClearBtn && <AClearFiltersButton onClick={() => clearAllTabsState()} />}
      </FiltersWrapper>
    )
  }

  return null
}
