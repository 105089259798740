import PlayerProvider from 'views/components/Player/PlayerProvider'
import { DetailMessage, Direction } from './CommunicationDetails'
import { Name, Text, WrapperItem, WrapperPlayer } from './styled'
import Player from 'views/components/Player/Player'
import useFormatDate from 'hooks/useFormatDate'
import { FC } from 'react'

type Props = {
  message: DetailMessage
}

export const MessageItem: FC<Props> = ({ message }) => {
  const formatDate = useFormatDate()

  const renderInbound = () => (
    <WrapperItem inbound>
      <Name>
        {formatDate(message.date_sent, true)}, {message.client_name}
      </Name>

      {message.record_link ? (
        <WrapperPlayer>
          <PlayerProvider>
            <Player file={message.record_link} itemId={String(message.id)} />
          </PlayerProvider>
        </WrapperPlayer>
      ) : (
        <Text inbound>{message.body}</Text>
      )}
    </WrapperItem>
  )

  const renderOutbound = () => (
    <WrapperItem>
      <Name>
        {formatDate(message.date_sent, true)}, {message.coordinator_name}
      </Name>
      <Text>{message.body}</Text>
    </WrapperItem>
  )

  return message.direction === Direction.inbound ? renderInbound() : renderOutbound()
}
