import { formatTarif } from 'modules/utils/numbers/formatTarif'
import { Bid, BidContract, BidPrice, BidTypes } from './types'
import { isPriceTypeEnabled, isUS } from 'env'
import { DashboardTaskBid } from '../dashboard/types'

export const isBidWithPricePeriods = (bid?: Bid): bid is WithRequired<Bid, 'price_periods'> =>
  Boolean(bid?.price_periods?.length)

export const isPurchaseBid = (bid?: Bid | null) => bid?.bid_type === BidTypes.purchase

export const isSaleBid = (bid?: Bid | null) => bid?.bid_type === BidTypes.sale

export const getPriceTypeLabel = (price_type: BidPrice, t: any) => {
  if (isUS) {
    return price_type === BidPrice.CPT ? t('common:delivered') : t('common:fob')
  }
  return price_type === BidPrice.CPT ? t('common:cpt') : t('common:exw')
}

export const getPriceWithType = (bid: Bid | DashboardTaskBid, t, priceOverride?: number | null) => {
  const price = priceOverride ?? bid.price
  if (isPriceTypeEnabled) {
    return `${getPriceTypeLabel(bid.price_type, t)} ${formatTarif(price)}`
  }
  return formatTarif(price)
}

export const isBidContract = (value: unknown): value is BidContract => {
  if (!value) {
    return false
  }
  const contract = value as BidContract
  return 'number' in contract && 'bid_id' in contract && 'signed_at' in contract
}

export const getOppositeBidType = (bidType?: BidTypes) => {
  return bidType === BidTypes.sale ? BidTypes.purchase : BidTypes.sale
}
