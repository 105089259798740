import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ColumnData } from 'modules/types'
import { TableTextLink } from 'views/ui/TableTextLink/TableTextLink'
import { generatePath } from 'react-router'
import TasksRoutes from '../routes'
import { TaskStatuses } from './components/TaskStatuses'
import { TableActions } from './components/TableActions'
import * as renderers from 'views/components/Table/renderers'
import UserRoutes from 'views/pages/User/routes'
import CompanyRoutes from 'views/pages/Company/routes'
import { DASH } from 'modules/constants'

const useColumns = (onTaskActionSuccess: () => void): ColumnData[] => {
  const { t } = useTranslation('my_tasks')

  return useMemo(
    () => [
      {
        title: t('table.headers.type'),
        dataIndex: 'task_type',
        width: 120,
      },
      {
        title: t('table.headers.status'),
        dataIndex: 'status',
        width: 120,
        render: (status) => <TaskStatuses status={status} />,
      },
      {
        title: t('table.headers.priority'),
        dataIndex: 'priority',
        width: 96,
        align: 'center',
        render: renderers.priority,
      },
      {
        title: t('table.headers.dueDate'),
        dataIndex: 'deadline',
        sorter: true,
        width: 160,
        render: renderers.date,
      },
      {
        title: t('detail.date_completion'),
        dataIndex: 'completed_at',
        sorter: true,
        width: 160,
        render: renderers.date,
      },
      {
        title: t('table.headers.subject'),
        dataIndex: 'subject',
        width: 302,
        render: (subject, data) => (
          <TableTextLink id="task" tableItemId={data.id} to={generatePath(TasksRoutes.Details, { id: data.id })}>
            {subject}
          </TableTextLink>
        ),
      },
      {
        title: t('table.headers.executor'),
        dataIndex: 'assignee_full_name',
        width: 240,
      },
      {
        title: t('table.headers.user'),
        dataIndex: 'linked_user',
        width: 240,
        render: (linked_user) => (
          <>
            {linked_user?.id ? (
              <TableTextLink
                id="client"
                tableItemId={linked_user.id}
                to={generatePath(UserRoutes.Details, { id: linked_user.id })}
                target="_blank"
              >
                {linked_user.full_name}
              </TableTextLink>
            ) : (
              DASH
            )}
          </>
        ),
      },
      {
        title: t('table.headers.company'),
        dataIndex: 'linked_user_company',
        width: 240,
        render: (linked_user_company) => (
          <>
            {linked_user_company?.id ? (
              <TableTextLink
                id="client"
                tableItemId={linked_user_company.id}
                to={generatePath(CompanyRoutes.Details, { id: linked_user_company.id })}
                target="_blank"
              >
                {linked_user_company.name}
              </TableTextLink>
            ) : (
              DASH
            )}
          </>
        ),
      },
      {
        title: t('table.headers.author'),
        dataIndex: 'created_by_full_name',
        width: 240,
      },
      {
        title: t('table.headers.team'),
        dataIndex: 'assignee_team',
        width: 112,
      },
      {
        title: t('table.headers.createDate'),
        dataIndex: 'created',
        width: 140,
        render: renderers.date,
      },
      {
        title: t('table.headers.action'),
        dataIndex: 'id',
        width: 134,
        render: (id, data) => (
          <TableActions
            task={data}
            currentStatus={data.status}
            onSuccess={onTaskActionSuccess}
            potential_executors={data.potential_executors}
          />
        ),
      },
    ],
    [t, onTaskActionSuccess],
  )
}

export default useColumns
