import axios, { AxiosError, AxiosRequestConfig, Method } from 'axios'
import { stringify } from 'query-string'

import { REACT_APP_API_BASE_URL } from 'env'
import { downloadBlob } from 'helpers/download'
import i18n from 'i18n'
import { getAccessToken } from 'modules/domain/auth/repository'
import { Sentry } from 'sentry'

import { handleApiErrors } from './handleApiErrors'
import { refreshTokenAndRetry } from './refreshAndRetry'
import { endpoints } from 'modules/endpoints'

axios.interceptors.request.use(
  (config) => {
    Sentry.handleAxiosRequest(config)
    return config
  },
  (error) => {
    Sentry.handleAxiosError(error)
    return Promise.reject(error)
  },
)

const excludeRefreshForEndpoints = [endpoints.refreshToken(), endpoints.login(), endpoints.getCode()]

axios.interceptors.response.use(
  (response) => {
    Sentry.handleAxiosResponse(response)
    return response
  },
  (error: AxiosError<any>) => {
    const status = error.response?.status
    const url = error.config?.url
    const page = error.config?.params?.page

    // If the request was cancelled, simply return an error without logging to Sentry
    if (axios.isCancel(error)) {
      return Promise.reject(error)
    }

    if (status === 401 && url && !excludeRefreshForEndpoints.includes(url)) {
      return refreshTokenAndRetry(error)
    }

    // https://agroclub.atlassian.net/browse/GM-1449 network errors should be ignored (that ones w/o response status)
    if (!status || status === 401 || status === 400) {
      return Promise.reject(error)
    }

    // ignore errors due to non existing page
    if (status === 404 && page && page > 1) {
      return Promise.reject(error)
    }

    Sentry.handleAxiosError(error)
    return Promise.reject(error)
  },
)

export type EmitterToken<T> =
  | {
      type: 'progress'
      total: number
      loaded: number
      percent: number
    }
  | { type: 'success'; result: T }
  | { type: 'error'; error: AxiosError }

type ParamType = string | number | boolean | undefined | null
type UrlParams = { [index: string]: ParamType | ParamType[] }
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type RequestBody = Record<string, any> | FormData
type RequestExtraOptions = {
  multipart?: boolean
}
type RequestOptions = AxiosRequestConfig & RequestExtraOptions
export const defaultHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
}

export const formHeaders = {
  Accept: 'application/json',
  'Content-Type': 'multipart/form-data',
}

const bodyToForm = (params: RequestBody): FormData =>
  !(params instanceof FormData)
    ? Object.keys(params)
        .filter((key) => Boolean(params[key]))
        .reduce((form, key) => {
          form.append(key, params[key])
          return form
        }, new FormData())
    : params

export function performRequest<T>(
  method: Method,
  lang: string,
  baseURL: string,
  url: string,
  params: UrlParams | null,
  _body: RequestBody | null,
  options: RequestOptions = {},
): Promise<T> {
  const cancelToken = axios.CancelToken.source()
  const body = _body ? (options.multipart ? bodyToForm(_body) : _body) : undefined
  let headers
  if (body instanceof FormData) {
    headers = formHeaders
  } else {
    headers = defaultHeaders
  }
  const token = getAccessToken()
  const request = axios({
    headers: {
      ...options.headers,
      ...headers,
      Authorization: token ? `Bearer ${token}` : '',
      'Accept-Language': lang,
      'Agro-Club-Source': 'panel',
    },
    method,
    url,
    params,
    baseURL,
    data: body,
    cancelToken: cancelToken.token,
    responseType: options.responseType || 'json',
    paramsSerializer: (params) => stringify(params),
    signal: options.signal,
  })
    .then((res) => {
      if (res.config.responseType === 'blob') {
        downloadBlob(res.data, res.headers)
      }
      return res.data
    })
    .catch((err) => {
      return handleApiErrors(err)
    })

  return request
}

export function makeHttpClient(baseURL: string) {
  if (!baseURL) {
    // eslint-disable-next-line no-console
    console.error('REACT_APP_API_BASE_URL is missing')
  }

  let lang = i18n.language
  document.documentElement.lang = lang
  i18n.on('languageChanged', (lng) => {
    document.documentElement.lang = lng
    lang = lng
  })

  return {
    get<T>(path: string, params?: UrlParams, options?: RequestOptions) {
      return performRequest<T>('get', lang, baseURL, path, params || null, null, options)
    },
    post<T>(path: string, body?: RequestBody, options?: RequestOptions) {
      return performRequest<T>('post', lang, baseURL, path, null, body || null, options)
    },
    put<T>(path: string, body?: RequestBody, options?: RequestOptions) {
      return performRequest<T>('put', lang, baseURL, path, null, body || null, options)
    },
    delete<T>(path: string, body?: RequestBody, options?: RequestOptions) {
      return performRequest<T>('delete', lang, baseURL, path, null, body || null, options)
    },
    patch<T>(path: string, body?: RequestBody, options?: RequestOptions) {
      return performRequest<T>('patch', lang, baseURL, path, null, body || null, options)
    },
  }
}

export const axiosClient = (config: AxiosRequestConfig) => axios(config)
export const apiClient = makeHttpClient(REACT_APP_API_BASE_URL)
export const authClient = makeHttpClient(REACT_APP_API_BASE_URL)

export default makeHttpClient
