import { ASharedModal, SharedModalTitle } from 'views/components/SharedModal'
import { EditableFields, ShipLoadCommonContent } from './ModalCommonContent'
import { useFormik } from 'formik'
import { useAFormHandler } from 'analytics/hooks'
import { useTranslation } from 'react-i18next'
import { apiClient } from 'modules/utils/httpClient'
import { FarmerLoad, ShipLoad } from 'modules/domain/deal/types'
import { endpoints } from 'modules/endpoints'
import { useParams } from 'react-router-dom'
import { refetchFunc } from 'modules/domain/common/hooks'
import { FC } from 'react'

type Props = {
  onClose: () => void
  loadItem: ShipLoad | FarmerLoad
  refetch: refetchFunc
}

export const EditShipLoadModal: FC<Props> = ({ onClose, loadItem, refetch }) => {
  const { id: dealId } = useParams()
  const { t } = useTranslation('deal')
  const { formProgress, formHandler } = useAFormHandler()

  const formik = useFormik<EditableFields>({
    initialValues: {
      regional: loadItem.regional.id,
      work_type: loadItem.work_type,
      load_date: loadItem.load_date,
      quantity: loadItem.quantity,
    },

    onSubmit: formHandler(() => apiClient.put(endpoints.dealSingleShipLoading(dealId, loadItem.id), formik.values), {
      onSuccess: async () => {
        await refetch(true)
        onClose()
      },
      onError: (error) => {
        const { errors } = error
        formik.setErrors(errors)
      },
    }),
  })

  return (
    <ASharedModal id="shipLoad" onClose={onClose}>
      <SharedModalTitle>{t('accordion.loading.edit_ship_load')}</SharedModalTitle>
      <ShipLoadCommonContent formik={formik} onClose={onClose} progress={formProgress} />
    </ASharedModal>
  )
}
