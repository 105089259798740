import { Card } from 'views/components/Card/Card'
import styled, { css } from 'styled-components'
import { Typography, defaultTheme } from '@agro-club/agroclub-shared'
import { ARouterLink } from 'views/components/Analytics'

const cardContainerCommon = css<{ enableHover?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    background-color: ${({ enableHover }) => (enableHover ? `${defaultTheme.color.primary16a}` : '')};
  }
`

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 16px;
`

export const DateText = styled.div`
  ${Typography.bodyMedium};
  color: ${defaultTheme.color.textSecondary60a};
`

export const Title = styled.h4`
  ${Typography.titleH4};
  margin: 0;
`

export const CardContainerStyled = styled(Card.Container)<{ enableHover?: boolean }>`
  ${cardContainerCommon}
`

export const MultiCardContainerStyled = styled(Card.MultiContainers)<{ enableHover?: boolean }>`
  ${cardContainerCommon}
`

export const TagsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  @media (max-width: ${defaultTheme.queries.mobile}) {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 12px;
  }
`

export const Divider = styled.span`
  color: ${defaultTheme.color.textSecondary60a};
`

export const RouterLinkStyled = styled(ARouterLink)`
  position: absolute;
  top: 0;
  left: 0;
  right: 200px;
  bottom: 0;
  cursor: pointer;
  @media (max-width: ${defaultTheme.queries.mobile}) {
    right: 50px;
  }
`
export const Controls = styled.div`
  display: flex;
`

export const Container = styled.div`
  width: 300px;
  min-height: 198px;
  flex-shrink: 0;
  scroll-snap-align: start;
  background: ${defaultTheme.color.primary8a};
  border: 1px solid ${defaultTheme.color.primary16a};
  border-radius: 16px;
`
export const EmptyContainer = styled(Container)`
  padding: 16px 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  gap: 16px 0;
`
export const FullContainer = styled(Container)`
  padding: 16px;
  position: relative;
  display: flex;
`
