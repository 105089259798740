import { useTranslation } from 'react-i18next'

import { TableFilterProps } from '../../filters/types'
import { FC } from 'react'
import { StatusFilter } from 'views/components/TableFilters'
import { BidStatus } from 'modules/domain/bid/types'

type Props = TableFilterProps & {
  disabled?: boolean
}

export const BidStatusFilter: FC<Props> = ({ updateFilterState, listRequestParams, disabled }) => {
  const { t } = useTranslation('bid')

  const optionsStatus = [
    {
      value: BidStatus.published,
      label: t('bidStatuses.published'),
    },
    {
      value: BidStatus.archived,
      label: t('bidStatuses.archived'),
    },
  ]

  return (
    <StatusFilter
      listRequestParams={listRequestParams}
      updateFilterState={updateFilterState}
      options={optionsStatus}
      disabled={disabled}
    />
  )
}
