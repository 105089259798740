import { Typography, AntdBreadcrumbs, defaultTheme } from '@agro-club/agroclub-shared'
import styled from 'styled-components'
import { FC } from 'react'

type BreadcrumbsProps = {
  routes: object[]
  otherProps?: any
}

const BreadcrumbsWrapper = styled.div`
  .ant-breadcrumb {
    ${Typography.bodyMedium}
    margin-bottom: 8px;
  }

  .ant-breadcrumb-link {
    a {
      color: ${defaultTheme.color.textPrimary900} !important;
    }

    span {
      color: ${defaultTheme.color.textSecondary60a};
    }
  }
`

export const Breadcrumbs: FC<BreadcrumbsProps> = (props) => {
  const { routes, ...otherProps } = props
  return (
    <BreadcrumbsWrapper>
      <AntdBreadcrumbs routes={routes} {...otherProps} />
    </BreadcrumbsWrapper>
  )
}

export const FiltersWrpapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`
