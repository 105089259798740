import { Progress } from '@agro-club/agroclub-shared'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { FooterStepBackButton } from './styled'
import { SharedModalFooter, SharedModalFooterWrapper } from 'views/components/SharedModal'
import { AButton } from 'views/components/Analytics'
import { FormikFieldsType } from 'modules/domain/types'
interface Props {
  onSubmit?: () => void
  onCancel: () => void
  currentStep: number
  setCurrentStep: (val: number) => void
  progress?: Progress
  formik: FormikFieldsType
}

export const LogisticsKRPopUpFooter: FC<Props> = ({
  onSubmit,
  onCancel,
  currentStep,
  setCurrentStep,
  progress,
  formik,
}) => {
  const { t } = useTranslation('logisticsKR')
  const stepNumber = currentStep + 1

  const handleNextStep = () => {
    const validateForm = async () => {
      return await formik.validateForm()
    }

    validateForm().then((errs) => {
      if (Object.keys(errs).length === 0) setCurrentStep(currentStep + 1)
    })
  }

  const handleStepBack = () => {
    setCurrentStep(currentStep - 1)
  }
  return (
    <SharedModalFooterWrapper withMargin>
      <SharedModalFooter withMargin={false}>
        {currentStep < 2 ? (
          <AButton id="nextStep" intent="primary" size="big" onClick={handleNextStep}>
            {t('list.createForm.buttons.nextStep')}
          </AButton>
        ) : (
          <AButton id="createBid" intent="primary" size="big" onClick={onSubmit} progress={progress}>
            {t('list.createForm.buttons.createBid')}
          </AButton>
        )}

        <AButton id="cancel" intent="secondary" size="big" onClick={onCancel}>
          {t('list.createForm.buttons.cancelForm')}
        </AButton>
      </SharedModalFooter>

      {currentStep > 0 && (
        <FooterStepBackButton onClick={handleStepBack}>
          {t('list.createForm.buttons.backToStep', { stepNumber: stepNumber - 1 })}
        </FooterStepBackButton>
      )}
    </SharedModalFooterWrapper>
  )
}
