import { FC, useEffect } from 'react'
import { Driver } from '../../../../types'
import { useSingleEntity } from 'modules/domain/common/hooks'
import { endpoints } from 'modules/endpoints'
import { DocumentFile, DocumentType } from 'modules/domain/types'
import styled from 'styled-components'
import { defaultTheme, Typography } from '@agro-club/agroclub-shared'
import { apiClient } from 'modules/utils/httpClient'
import { uploadDocuments } from 'modules/domain/common/managers'
import { AFilesByTypes } from 'views/components/FilesByTypes/AFilesByTypes'

const DriverName = styled.div`
  ${Typography.accentLarge}
  color: ${defaultTheme.color.textPrimary900};
  margin-bottom: 12px;

  &:not(:first-child) {
    margin-top: 16px;
  }
`

type Props = {
  driver: Driver
  setDocumentsCounter: (arg) => void
}

export const DriverDocuments: FC<Props> = ({ driver, setDocumentsCounter }) => {
  const [, types = []] = useSingleEntity<DocumentType[]>(endpoints.LogisticsKR.driverDocumentTypes(driver.id))
  const [, documents, documentsRefetch] = useSingleEntity<DocumentFile[]>(
    endpoints.LogisticsKR.driverDocuments(driver.id),
  )

  useEffect(() => {
    if (!documents) return
    setDocumentsCounter((prev) => ({
      ...prev,
      [driver.id]: documents.length,
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documents])

  const onUploadHandler = async (document_type, file) => {
    try {
      await uploadDocuments(endpoints.LogisticsKR.driverDocuments(driver.id), document_type, file)
      documentsRefetch()
    } catch (err) {
      console.error(err)
    }
  }

  const onDeleteHandler = async (document_id) => {
    try {
      await apiClient.delete(endpoints.LogisticsKR.driverDocument(driver.id, document_id))
      documentsRefetch()
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <>
      <DriverName>{driver.name}</DriverName>
      <AFilesByTypes types={types} files={documents || []} onUpload={onUploadHandler} onDelete={onDeleteHandler} />
    </>
  )
}
