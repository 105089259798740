import { SharedModalContent, SharedModalInputs } from 'views/components/SharedModal'
import { useTranslation } from 'react-i18next'
import { FormGroup, Input } from '@agro-club/agroclub-shared'
import { ShortFormControl } from 'views/styled/common'
import { useFormik } from 'formik'
import ADefaultModalFooter from 'views/components/DefaultModalFooter/ADefaultModalFooter'
import { CommonBidModalControls } from 'views/pages/Bid/BidModals/CommonBidModalControls'
import FormSelectShared from 'views/components/FormSelectShared'
import { endpoints } from 'modules/endpoints'
import { SeasonSelect } from 'views/pages/Bid/components/SeasonSelect'
import { QuantityInput } from 'views/components/QuantityInput/QuantityInput'
import { labelTarif } from 'modules/utils/numbers/formatTarif'
import { DatePicker } from 'views/components/DatePicker/DatePicker'
import { DateRangePicker } from 'views/components/DateRangePicker/DateRangePicker'
import { BidPrice, USContractType } from 'modules/domain/bid/types'
import { ProfileType, User } from 'modules/domain/user/types'
import { useAFormHandler } from 'analytics/hooks'
import { generatePath, useNavigate } from 'react-router-dom'
import BidRoutes from 'views/pages/Bid/routes'
import { apiClient } from 'modules/utils/httpClient'
import { useEffect, useState } from 'react'
import { getAndSetCashPrice } from './helpers'
import { USAddBidFields } from 'views/pages/Bid/BidModals/Bid/USModals/types'
import { WarningContainer } from '../../styled'
import { USATypePriceControl } from 'views/pages/Bid/components/US/TypePriceControl'
import { USCommonFields } from './USCommonFields'

type Props = {
  user?: User
  productId?: string
  symbol?: string
  onClose: () => void
  onSuccess?: (value) => void
  children?: React.ReactNode
  contractType: USContractType
}

export const USAddBidModal = ({ user, productId, symbol, onClose, onSuccess, children, contractType }: Props) => {
  const { t } = useTranslation('bid')
  const { formProgress, formHandler } = useAFormHandler('create')
  const navigate = useNavigate()

  const [ownerProfileType, setOwnerProfileType] = useState<ProfileType | undefined>(user?.profile?.profile_type)

  const navigateToNewBid = (id: string) => {
    onClose()
    navigate(generatePath(BidRoutes.Details, { id }))
  }

  const formik = useFormik<USAddBidFields>({
    initialValues: {
      contract_type: USContractType.basis,
      company: user?.profile?.company?.id || undefined,
      owner: user?.id ? String(user.id) : undefined,
      address: undefined,
      product: productId,
      price: 0,
      price_type: undefined,
      quantity: undefined,
      season: undefined,
      owner_delivery_distance: undefined,
      symbol: symbol,
      basis: undefined,
      basis_expiration: undefined,
      futures_price: undefined,
      start_date: undefined,
      end_date: undefined,
      expires_at: undefined,
      parameters: [],
      is_firm: false,
      hedge_ticket: undefined,
    },
    enableReinitialize: true,

    onSubmit: formHandler(async () => await apiClient.post<{ id: string }>(endpoints.bid(), formik.values), {
      onSuccess: async (createdBid) => {
        onSuccess ? await onSuccess(createdBid) : navigateToNewBid(createdBid.id)
        onClose()
      },
      onError: (error) => {
        const { errors } = error
        formik.setErrors(errors)
      },
    }),
  })

  const isDisableCashPrice = formik.values.contract_type !== USContractType.cash_priced

  useEffect(() => {
    if (!ownerProfileType) return

    const priceType = ownerProfileType === ProfileType.customer ? BidPrice.CPT : BidPrice.EXW
    formik.setFieldValue('price_type', priceType)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ownerProfileType])

  useEffect(() => {
    if (isDisableCashPrice) {
      getAndSetCashPrice(formik)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.basis, formik.values.futures_price])

  useEffect(() => {
    formik.setFieldValue('contract_type', contractType)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractType])

  return (
    <>
      <SharedModalContent>
        <WarningContainer>
          <div>{t('form.createWarning')}</div>
        </WarningContainer>
        <SharedModalInputs>
          {children}
          <CommonBidModalControls formik={formik} setOwnerProfileType={setOwnerProfileType} />
          {formik.values.owner && ownerProfileType && (
            <USATypePriceControl formik={formik} ownerProfileType={ownerProfileType} />
          )}
          {ownerProfileType === ProfileType.seller && <USCommonFields formik={formik} />}

          <FormSelectShared
            label={t('form.product')}
            placeholder={t('form.selectProductPlaceholder')}
            formik={formik}
            endpoint={endpoints.products()}
            simpleApiFormat
            getLabel={(dto) => dto.title}
            onChange={(value) => {
              formik.setFieldValue('season', undefined)
              formik.setFieldValue('product', value)
            }}
            fieldName="product"
            showSearch={false}
            required
          />
          <ShortFormControl>
            <SeasonSelect formik={formik} disabled={!formik.values.product} />

            <FormGroup label={t('form.symbol')} error={formik.errors.symbol}>
              <Input {...formik.getFieldProps('symbol')} invalid={!!formik.errors.symbol} />
            </FormGroup>

            <QuantityInput formik={formik} fieldName="quantity" required label={t('common:volume')} />

            <FormGroup label={labelTarif(t('form.basis'))} error={formik.errors.basis}>
              <Input {...formik.getFieldProps('basis')} invalid={!!formik.errors.basis} type="number" />
            </FormGroup>

            <FormGroup label={t('form.basisExpiration')} error={formik.errors.basis_expiration}>
              <DatePicker
                onChange={(data) => formik.setFieldValue('basis_expiration', data['date'])}
                error={!!formik.errors.basis_expiration}
                placeholder={t('common:datePlaceholder')}
              />
            </FormGroup>

            <FormGroup label={labelTarif(t('form.futuresPrice'))} error={formik.errors.futures_price}>
              <Input {...formik.getFieldProps('futures_price')} invalid={!!formik.errors.futures_price} type="number" />
            </FormGroup>
            <FormGroup label={t('form.deliveryPeriod')} error={formik.errors.start_date || formik.errors.end_date}>
              <DateRangePicker
                size="large"
                error={formik.errors.start_date || formik.errors.end_date}
                onChange={({ startDate, endDate }) => {
                  formik.setFieldValue('start_date', startDate)
                  formik.setFieldValue('end_date', endDate)
                }}
              />
            </FormGroup>

            <FormGroup label={labelTarif(t('form.cashPrice'))} error={formik.errors.price}>
              <Input
                {...formik.getFieldProps('price')}
                invalid={!!formik.errors.price}
                type="number"
                disabled={isDisableCashPrice}
              />
            </FormGroup>
            <FormGroup label={t('form.expiredAt')} error={formik.errors.expires_at}>
              <DatePicker
                onChange={(data) => formik.setFieldValue('expires_at', data['date'])}
                error={!!formik.errors.expires_at}
                placeholder={t('common:datePlaceholder')}
              />
            </FormGroup>
          </ShortFormControl>
        </SharedModalInputs>
      </SharedModalContent>
      <ADefaultModalFooter
        onClose={onClose}
        confirmButtonText={t('common:add')}
        onConfirm={() => formik.handleSubmit()}
        progress={formProgress}
        disabled={!formik.dirty}
      />
    </>
  )
}
