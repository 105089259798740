import useFormatDate from 'hooks/useFormatDate'
import { Text } from '@agro-club/agroclub-shared'
import { CommentContainer, CommentDate, CommentHeader, CommentText } from './styled'
import { Comment } from './hooks'

export type CommentItemProps = {
  comment: Comment
  setIsEdit?: (arg: boolean) => void
  setComment?: (text: string) => void
  setCommentId?: (id: string) => void
  isEdit?: boolean
}

export const CommentsItem = ({ comment }: CommentItemProps) => {
  const formatDate = useFormatDate()
  return (
    <CommentContainer>
      <CommentHeader>
        <Text as="span" typography="accentMedium">
          {comment.author}
        </Text>
        <CommentDate>{formatDate(comment.created, true)}</CommentDate>
      </CommentHeader>
      <CommentText>{comment.comment}</CommentText>
    </CommentContainer>
  )
}
