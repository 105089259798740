import { FC } from 'react'

import { useAnalyticsContext } from 'analytics/hooks'
import { InternalComments, InternalCommentsProps } from './InternalComments'
import { AnalyticsPayload } from 'analytics/types'

export type ACommentsTrack = (eventName: string, comment?: string) => void

interface ACommentProps extends Omit<InternalCommentsProps, 'onATrack'> {
  analyticsPayload?: AnalyticsPayload
  eventPostFix?: string
}

const AComments: FC<ACommentProps> = ({ eventPostFix, ...props }) => {
  const { track } = useAnalyticsContext()

  const onATrack = (eventName: string, comment?: string) => {
    track(eventName, {
      ...props.analyticsPayload,
      place: eventPostFix ? `comments|${eventPostFix}` : 'comments',
      value: {
        to: comment,
      },
    })
  }

  return <InternalComments onATrack={onATrack} {...props} />
}

export default AComments
