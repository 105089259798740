import { FC, useEffect } from 'react'
import { apiClient } from 'modules/utils/httpClient'
import { endpoints } from 'modules/endpoints'
import { uploadFile } from 'modules/domain/common/managers'
import { RespFile } from 'modules/domain/types'
import AUploadFiles from 'views/components/UploadFiles/AUploadFiles'
import { Progress } from '@agro-club/agroclub-shared'
import { useSingleEntity } from 'modules/domain/common/hooks'

type Props = {
  setSummary: (arg: number) => void
  carrierId: string
}

export const CarrierFiles: FC<Props> = ({ carrierId, setSummary }) => {
  const [progress, files = [], refetch] = useSingleEntity<RespFile[]>(endpoints.LogisticsKR.carrierFiles(carrierId))

  useEffect(() => {
    if (progress === Progress.SUCCESS) setSummary(files.length)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files.length])

  const removeHandler = async (carrierId: string, fileId: string) => {
    try {
      await apiClient.delete(endpoints.LogisticsKR.carrierFile(carrierId, fileId))
      refetch(true)
    } catch (err) {
      console.error(err)
    }
  }

  const addHandler = async (carrierId: string, file: File) => {
    try {
      await uploadFile(endpoints.LogisticsKR.carrierFiles(carrierId), file)
      refetch(true)
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <AUploadFiles
      progress={progress}
      removeFile={(fileId) => removeHandler(carrierId, fileId)}
      addFile={({ file }) => addHandler(carrierId, file)}
      fileList={files}
    />
  )
}
