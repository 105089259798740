import { CompanyShort } from 'modules/domain/company/types'
import { useTranslation } from 'react-i18next'

import { CompanyLink } from 'views/components/CompanyLink/CompanyLink'
import { KeyValue } from 'views/components/KeyValue/KeyValue'
import styled from 'styled-components'
import { FC, ReactNode } from 'react'

type Props = {
  company: {
    id: CompanyShort['id']
    name: CompanyShort['name']
    inn?: CompanyShort['inn']
  }
  label: ReactNode
}

const Inn = styled.div`
  margin-top: 4px;
`

export const DocumentRequestCompanyInfo: FC<Props> = ({ company, label }) => {
  const { t } = useTranslation('common')

  return (
    <KeyValue label={label}>
      <CompanyLink company={company} />
      {!!company.inn && (
        <Inn>
          {t('inn')} {company.inn}
        </Inn>
      )}
    </KeyValue>
  )
}
