import { defaultTheme } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

export const DescriptionCase = styled.div`
  margin-bottom: 12px;
`
export const WrapperDatePicker = styled.div`
  margin-bottom: 12px;
`
export const HeaderButtons = styled.div`
  display: flex;
  justify-content: space-between;
`

export const TableWraper = styled.div`
  margin-top: 16px;
  td.ant-table-cell {
    padding: 6px 12px !important;
  }
`
export const Error = styled.div`
  color: ${defaultTheme.color.accentDestruct600};
  margin-top: 12px;
`
export const Loading = styled.div`
  color: ${defaultTheme.color.secondaryPlaceholder};
  margin-top: 12px;
`
