import { DocumentRequestDTO, ProductPurpose } from 'modules/domain/documentRequest/types'
import { Checkbox, FormGroup, Text, defaultTheme } from '@agro-club/agroclub-shared'
import { ResponsiveModal } from 'views/components/ResponsiveModal/ResponsiveModal'
import { AnalyticsPlaces } from 'views/pages/Deal/analyticsPlaces'
import { SharedModalContent } from 'views/components/SharedModal'
import { DescriptionContainer, SmallText } from './styled'
import { refetchFunc } from 'modules/domain/common/hooks'
import { ShortFormControl } from 'views/styled/common'
import { CommonFormFields } from './CommonFormFields'
import { apiClient } from 'modules/utils/httpClient'
import { Declaration } from 'modules/domain/types'
import { useAFormHandler } from 'analytics/hooks'
import { useMediaQuery } from 'react-responsive'
import { Deal } from 'modules/domain/deal/types'
import { useTranslation } from 'react-i18next'
import { endpoints } from 'modules/endpoints'
import styled from 'styled-components'
import { FC, useState } from 'react'
import { useFormik } from 'formik'

type Props = {
  onClose: () => void
  deal: Deal
  refetch?: refetchFunc
  declaration: Declaration | undefined
}
const StyledHeader = styled(Text)`
  text-align: center;
`

export const AddModal: FC<Props> = ({ onClose, deal, refetch, declaration }) => {
  const { t } = useTranslation('documentRequests')
  const { formProgress, formHandler } = useAFormHandler()
  const [filesCollected, setFilesCollected] = useState(false)

  const formik = useFormik<DocumentRequestDTO>({
    initialValues: {
      deal: deal.id,
      request_ds: false,
      request_quarantine: false,
      request_vet: false,
      request_sdiz: false,
      regionals: null,
      load_address: '',
      unload_address: '',
      consignee: '',
      pay_to_customer_account: false,
      product_purpose: ProductPurpose.feed,
      quantity: deal.quantity,
    },
    onSubmit: formHandler(
      async (values) => {
        if (!filesCollected) return

        if (values.product_purpose === ProductPurpose.alimentary) {
          values.request_vet = false
        }
        return await apiClient.post(endpoints.dealDocumentRequest(), values)
      },
      {
        onSuccess: () => {
          refetch?.()
          onClose()
        },
        onError: (error) => {
          const { errors } = error
          formik.setErrors(errors || {})
        },
      },
    ),
  })

  const isMobile = useMediaQuery({
    query: `(max-width: ${defaultTheme.queries.mobile})`,
  })
  const TitleContainer = isMobile ? StyledHeader : 'div'
  return (
    <ResponsiveModal
      title={<TitleContainer typography="titleH4">{t('modal.title')}</TitleContainer>}
      id={AnalyticsPlaces.ADD_DOCUMENT_REQUEST_MODAL}
      onConfirm={() => formik.handleSubmit()}
      confirmButtonText={t('common:send')}
      disabled={!formik.dirty}
      progress={formProgress}
      onClose={onClose}
      headerHeight={89}
      size="large"
    >
      <SharedModalContent>
        <DescriptionContainer>
          <SmallText>{t('modal.warningText')}</SmallText>
          <ShortFormControl width={170}>
            <FormGroup error={formik.submitCount && !filesCollected ? t('modal.filesNotCollectedError') : undefined}>
              <Checkbox
                isChecked={filesCollected}
                onChange={(_, value) => setFilesCollected(value)}
                label={t('modal.filesCollected')}
              />
            </FormGroup>
          </ShortFormControl>
        </DescriptionContainer>
        <CommonFormFields formik={formik} deal={deal} declaration={declaration} />
      </SharedModalContent>
    </ResponsiveModal>
  )
}
