import { FC, useEffect, useRef } from 'react'

import { AntDrawer, AntDrawerProps } from '@agro-club/agroclub-shared'
import { useAnalyticsClickHandler, useAnalyticsContext } from 'analytics/hooks'
import { useIsMounted } from 'hooks/useIsMounted'

interface Props extends Omit<AntDrawerProps, 'id'> {
  id: string
}

const AAntDrawer: FC<Props> = ({ id, open, children, onClose, ...otherProps }) => {
  const handleClose = useAnalyticsClickHandler('close')
  const { setOptions, options } = useAnalyticsContext()
  const isMounted = useIsMounted()

  const prevPlace = useRef('')

  useEffect(() => {
    prevPlace.current = options.place || ''

    return () => {
      setOptions({ place: prevPlace.current })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!isMounted) return

    if (open) {
      prevPlace.current = options.place || ''
      setOptions({ place: id })
    } else {
      setOptions({ place: prevPlace.current })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  return (
    <AntDrawer open={open} onClose={handleClose(onClose)} {...otherProps}>
      {children}
    </AntDrawer>
  )
}

export default AAntDrawer
