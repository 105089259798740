import { Item } from 'views/components/ColumnBoard/styled'
import styled, { css } from 'styled-components'
import { FC } from 'react'

const selectedItem = css`
  background-color: ${(props) => props.theme.color.primary300};
  color: ${(props) => props.theme.color.backgroundSecondary};
`

const archivedItem = css`
  color: ${(props) => props.theme.color.textSecondary60a};
`

export const BoardItem = styled(Item)<{ isSelected?: boolean; isArchived?: boolean }>`
  padding: 12px;
  ${({ isSelected }) => isSelected && selectedItem};
  ${({ isArchived, isSelected }) => !isSelected && isArchived && archivedItem};
  cursor: pointer;
`

export type Item = {
  id: string
  isSelected?: boolean
  isArchived?: boolean
  btnLink?: string
  onClick: () => void
}

type Props = {
  item: Item
  ItemComponent: FC<{ item: Item }>
}

export const ColumnItem: FC<Props> = ({ item, ItemComponent }) => {
  return (
    <BoardItem isArchived={item.isArchived} isSelected={item.isSelected} onClick={() => item.onClick()}>
      <ItemComponent item={item} />
    </BoardItem>
  )
}
