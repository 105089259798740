import { useTranslation } from 'react-i18next'

import useFormatDate from 'hooks/useFormatDate'
import { Company } from 'modules/domain/company/types'
import * as Header from 'views/ui/Header/Header'
import { getPersonalNumberLabel } from 'modules/utils/numbers/formatPersonalNumber'
import { FC } from 'react'
import { MoratoriumTag } from 'views/components/Tags/CompanyTag'
import { CompanySizeTag } from 'views/pages/User/UserDetail/CompanySizeTag'
import { isRussia } from 'env'

type Props = {
  company: Company
}

export const CompanySubtitle: FC<Props> = (props) => {
  const { company } = props
  const { t } = useTranslation('company')
  const formatDate = useFormatDate()

  const inn = `${getPersonalNumberLabel()}: ${company.inn || t('common:notSpecified')}`

  const date = `${t('form.fields.created')}: ${formatDate(company.created, true)}`

  return (
    <Header.SubTitle>
      <Header.InlineContainer>
        {isRussia && <CompanySizeTag companySize={company.company_size} />}
        <MoratoriumTag isVisible={company.soy_restriction} />
        {[inn, date].filter(Boolean).join(' / ')}
      </Header.InlineContainer>
    </Header.SubTitle>
  )
}
