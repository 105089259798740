import { FC, useState } from 'react'
import { LoadingActionButtons } from './LoadingActionButtons'
import { EditShipLoadModal } from '../Modals/EditShipLoadModal'
import { ShipLoad } from 'modules/domain/deal/types'
import { refetchFunc } from 'modules/domain/common/hooks'
import { RemoveShipLoadModal } from '../Modals/RemoveShipLoadModal'

type Props = {
  setPopoverVisible: (arg: boolean) => void
  loadItem: ShipLoad
  refetch: refetchFunc
}
export const ShipLoadActions: FC<Props> = ({ setPopoverVisible, loadItem, refetch }) => {
  const [editModal, setEditModal] = useState(false)
  const [removeModal, setRemoveModal] = useState(false)
  return (
    <>
      <LoadingActionButtons
        setPopoverVisible={setPopoverVisible}
        setEditModal={setEditModal}
        setRemoveModal={setRemoveModal}
      />
      {editModal && <EditShipLoadModal onClose={() => setEditModal(false)} loadItem={loadItem} refetch={refetch} />}
      {removeModal && (
        <RemoveShipLoadModal onClose={() => setRemoveModal(false)} loadItemId={loadItem.id} refetch={refetch} />
      )}
    </>
  )
}
