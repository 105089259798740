import { FC, useCallback } from 'react'
import { AAntDrawer } from 'views/components/Analytics'
import { AnalyticsPlaces } from 'views/pages/Dispatching/analyticsPlaces'
import { DrawerComments } from 'views/pages/Dispatching/components/DispatchingDrawer/DrawerComments'

type Props = {
  drawerIsOpen: boolean
  setDrawerIsOpen: (arg: boolean) => void
  dealId: string
  refetch: () => void
}

export const DispatchingDrawer: FC<Props> = ({ setDrawerIsOpen, drawerIsOpen, dealId, refetch }) => {
  const onSuccess = useCallback(() => {
    refetch()
    setDrawerIsOpen(false)
  }, [refetch, setDrawerIsOpen])

  return (
    <AAntDrawer
      id={AnalyticsPlaces.DRAWER_WITH_COMMENTS}
      width={448}
      closable={drawerIsOpen}
      open={drawerIsOpen}
      placement="right"
      onClose={() => setDrawerIsOpen(false)}
    >
      <DrawerComments dealId={dealId} onSuccess={onSuccess} />
    </AAntDrawer>
  )
}
