import useFormatDate from 'hooks/useFormatDate'
import { CompanyExtraData as CompanyExtraDataType } from 'modules/domain/company/types'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import useFormatNumber from 'hooks/useFormatNumber'
import { KeyValue } from 'views/components/KeyValue/KeyValue'
import { Card } from 'views/components/Card/Card'
import { formatBool } from 'helpers/formatBool'
import { FC } from 'react'

type Props = {
  extraData: CompanyExtraDataType
}

const OkvedDetail = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
`

export const CompanyExtraData: FC<Props> = ({ extraData }) => {
  const { t } = useTranslation('company')
  const formatNumber = useFormatNumber()
  const formatDate = useFormatDate()

  const unknown = t('common:unknown')
  const thereIs = t('common:thereIs')

  return (
    <Card.Container>
      <Card.GapableContent>
        <Card.Title>{t('okved')}</Card.Title>
        <OkvedDetail>
          <div>{extraData.okved_code}</div>
          <div>{extraData.okved_text}</div>
        </OkvedDetail>
      </Card.GapableContent>

      <Card.HorizontalDivider />

      <Card.GapableContent>
        <Card.Title>{t('common')}</Card.Title>
        <Card.Grid cols={2}>
          <KeyValue label={t('extra.established_at')}>{formatDate(extraData.established_at)}</KeyValue>
          <KeyValue label={t('extra.employees_count')}>{formatNumber(extraData.employees_count) || unknown}</KeyValue>
          <KeyValue label={t('extra.activity_type')}>
            {extraData.activity_type ? t(`activityTypes.${extraData.activity_type}`) : unknown}
          </KeyValue>
          <KeyValue label={t('extra.has_all_documents')}>{formatBool(extraData.has_all_documents)}</KeyValue>
          <KeyValue label={t('extra.directors_companies_have_problems')}>
            {formatBool(extraData.directors_companies_have_problems, thereIs)}
          </KeyValue>
          <KeyValue label={t('extra.connected_companies_have_problems')}>
            {formatBool(extraData.connected_companies_have_problems, thereIs)}
          </KeyValue>
        </Card.Grid>
      </Card.GapableContent>

      <Card.HorizontalDivider />

      <Card.GapableContent>
        <Card.Title>{t('arbitrage')}</Card.Title>
        <Card.Grid cols={2}>
          <KeyValue label={t('extra.has_enforcement_proceeding')}>
            {formatBool(extraData.has_enforcement_proceeding)}
          </KeyValue>
          <KeyValue label={t('extra.enforcement_proceeding_sum')}>
            {formatNumber(extraData.enforcement_proceeding_sum) || unknown}
          </KeyValue>
          <KeyValue label={t('extra.has_agro_arbitration_cases')}>
            {formatBool(extraData.has_agro_arbitration_cases, thereIs)}
          </KeyValue>
          <KeyValue label={t('extra.arbitration_claims_sum')}>
            {formatNumber(extraData.arbitration_claims_sum) || unknown}
          </KeyValue>
        </Card.Grid>
      </Card.GapableContent>

      <Card.HorizontalDivider />

      <Card.GapableContent>
        <Card.Title>{t('finance')}</Card.Title>
        <Card.Grid cols={2}>
          <KeyValue label={t('extra.last_accounting_statements_date')}>
            {formatDate(extraData.last_accounting_statements_date) || unknown}
          </KeyValue>
          <div></div>
          <KeyValue label={t('extra.has_blocked_bank_accounts')}>
            {formatBool(extraData.has_blocked_bank_accounts)}
          </KeyValue>
          <KeyValue label={t('extra.paid_us_without_delay_amount')}>
            {formatNumber(extraData.paid_us_without_delay_amount)}
          </KeyValue>
        </Card.Grid>
        <Card.Grid cols={3}>
          <KeyValue isNumber label={t('extra.revenue')}>
            {formatNumber(extraData.revenue, false, {
              showCurrency: true,
            }) || unknown}
          </KeyValue>
          <KeyValue isNumber label={t('extra.profit')}>
            {formatNumber(extraData.profit, false, {
              showCurrency: true,
            }) || unknown}
          </KeyValue>
          <KeyValue isNumber label={t('extra.current_assets')}>
            {formatNumber(extraData.current_assets, false, {
              showCurrency: true,
            }) || unknown}
          </KeyValue>
          <KeyValue isNumber label={t('extra.equity')}>
            {formatNumber(extraData.equity, false, {
              showCurrency: true,
            }) || unknown}
          </KeyValue>
          <KeyValue isNumber label={t('extra.fixed_assets')}>
            {formatNumber(extraData.fixed_assets, false, {
              showCurrency: true,
            }) || unknown}
          </KeyValue>
          <KeyValue isNumber label={t('extra.current_liabilities')}>
            {formatNumber(extraData.current_liabilities, false, {
              showCurrency: true,
            }) || unknown}
          </KeyValue>
        </Card.Grid>
      </Card.GapableContent>
    </Card.Container>
  )
}
