import { FC } from 'react'
import { FormikState, FormikHelpers } from 'formik'
import { CommonInfo } from './Steps/CommonInfo/CommonInfo'
import { LoadingParameters } from './Steps/LoadingParameters/LoadingParameters'
import { UnloadingParameters } from './Steps/UnloadingParameters/UnloadingParameters'

interface Props {
  currentStep: number
  formik: FormikState<any> & FormikHelpers<any>
  isLoadingNewAddress: boolean
  setIsLoadingNewAddress: (value: boolean) => void
  isUnloadingNewAddress: boolean
  setIsUnloadingNewAddress: (value: boolean) => void
}

export const Stepper: FC<Props> = ({
  currentStep,
  formik,
  isLoadingNewAddress,
  setIsLoadingNewAddress,
  isUnloadingNewAddress,
  setIsUnloadingNewAddress,
}) => {
  let stepContent

  switch (currentStep) {
    case 0:
      stepContent = <CommonInfo formik={formik} />
      break
    case 1:
      stepContent = (
        <LoadingParameters
          formik={formik}
          isNewAddress={isLoadingNewAddress}
          setIsNewAddress={setIsLoadingNewAddress}
        />
      )
      break
    case 2:
      stepContent = (
        <UnloadingParameters
          formik={formik}
          isNewAddress={isUnloadingNewAddress}
          setIsNewAddress={setIsUnloadingNewAddress}
        />
      )
      break
  }

  return stepContent
}
