import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { FormItemWrapper } from 'views/styled/common'
import { FormGroup, Radio, RadioItem } from '@agro-club/agroclub-shared'
import { DealType } from 'modules/domain/logisticsKR/types'
import { FormikFieldsType } from 'modules/domain/types'

interface Props {
  formik: FormikFieldsType
}

export const RadioDealType: FC<Props> = ({ formik }) => {
  const { t } = useTranslation('logisticBids')

  return (
    <FormGroup label={t('form.labels.deal_type')}>
      <FormItemWrapper>
        <Radio onChange={(value) => formik.setFieldValue('deal_type', value)} value={formik.values.deal_type}>
          <RadioItem value={DealType.direct} label={t('form.labels.direct')} />
          <RadioItem value={DealType.agent} label={t('form.labels.agent')} />
          <RadioItem value={DealType.forwarding_company} label={t('form.labels.forwarding_company')} />
        </Radio>
      </FormItemWrapper>
    </FormGroup>
  )
}
