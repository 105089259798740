import { format, parseISO } from 'date-fns'
import capitalizeFirstLetter from 'helpers/capitalizeFirstLetter'

import { DASH, MONTH_NAME_YEAR } from 'modules/constants'
import { TeamsOverall } from 'modules/domain/teams/types'
import formatNumber from 'modules/utils/numbers/formatNumber'

export const usePlansTableData = (year: number, data: TeamsOverall, locale: Locale) => {
  const initialData = Array(12)
    .fill(null)
    .map((item, i) => ({
      month: `${year}-${(i + 1).toString().padStart(2, '0')}-01`,
      monthIndex: i + 1,
      volume: DASH,
      completed: DASH,
      progress: '0%',
    }))

  const tableData = initialData.map((item) => {
    const date = item.month
    const overallItem = data[date]
    const parsedDate = parseISO(date)
    const month = capitalizeFirstLetter(format(parsedDate, MONTH_NAME_YEAR, { locale }))
    let progress = 0

    if (!overallItem) {
      return {
        ...item,
        month,
      }
    }

    if (overallItem.volume > 0) {
      progress = Math.round((overallItem.completed / overallItem.volume) * 100)
    }

    return {
      month,
      monthIndex: item.monthIndex,
      volume: formatNumber(overallItem.volume),
      completed: formatNumber(overallItem.completed),
      progress: `${progress}%`,
    }
  })

  return tableData
}
