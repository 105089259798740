import { createContext, useMemo, useState } from 'react'
import { AudioPlayerProvider, useAudioPlayer } from 'react-use-audio-player'

export const PlayerContext = createContext({
  play: (_file: string) => {
    console.log('Player is don"t ready')
  },
})

export function PlayerContent({ children }) {
  const [src, setSrc] = useState('')
  useAudioPlayer({
    src,
    autoplay: true,
    format: 'mp3',
  })

  const context = useMemo(
    () => ({
      play: (file) => setSrc(file),
    }),
    [],
  )

  return <PlayerContext.Provider value={context}>{children}</PlayerContext.Provider>
}

export function PlayerProvider({ children }) {
  return (
    <AudioPlayerProvider>
      <PlayerContent>{children}</PlayerContent>
    </AudioPlayerProvider>
  )
}

export default PlayerProvider
