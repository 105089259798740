import { FC, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useSingleEntity } from 'modules/domain/common/hooks'
import { endpoints } from 'modules/endpoints'
import { useTranslation } from 'react-i18next'
import { Progress, useHelmet } from '@agro-club/agroclub-shared'
import ItemLoadingLayout from 'views/layouts/ItemLoadingLayout/ItemLoadingLayout'
import * as Layout from 'views/layouts/NewLayout'
import * as Header from 'views/ui/Header/Header'
import BidRoutes from '../../routes'
import { BidPageTitle } from '../styled'
import { TaskModal } from 'views/components/TaskModal/TaskModal'
import { DeletePotentialButton } from './DeletePotentialButton'
import { BidPotentialLeftBlock } from './BidPotentialLeftBlock'
import { BidPotentialRightBlock } from './BidPotentialRightBlock'
import { PotentialBid } from 'modules/domain/potentialBid/types'
import APageWrapper from 'views/components/PageWrapper/APageWrapper'
import { AnalyticsPages, AnalyticsPlaces } from '../../analyticsPlaces'
import { BidCreatedUpdatedTag, BidStatusTag } from 'views/components/Tags'

export const BidDetailPotential: FC = () => {
  const { id } = useParams()
  const { t } = useTranslation(['bid', 'common'])
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [fetchProgress, bid, refreshBid] = useSingleEntity<PotentialBid>(endpoints.potentialBid(id))
  useHelmet({ title: `${t('common:bid')} - ${bid?.product?.title || id}` })

  if (fetchProgress === Progress.WORK || !bid) {
    return <ItemLoadingLayout id={id} />
  }

  return (
    <APageWrapper page={AnalyticsPages.POTENTIAL_BID_DETAIL} place={AnalyticsPlaces.POTENTIAL_DETAIL} id={id}>
      <Layout.WrapperContent>
        <Layout.Header>
          <Layout.TopHeader>
            <Header.Breadcrumbs
              routes={[
                { path: BidRoutes.List, breadcrumbName: t('list.heading') },
                { breadcrumbName: bid.product?.title },
              ]}
            />

            <Header.ButtonWrapper>
              <DeletePotentialButton id={bid.id} />
            </Header.ButtonWrapper>
          </Layout.TopHeader>
          <Layout.PageTitleWithTagWrapper>
            <BidPageTitle>{bid.product?.title}</BidPageTitle>
            <BidStatusTag bid={bid} />
            <BidCreatedUpdatedTag bid={bid} />
          </Layout.PageTitleWithTagWrapper>
        </Layout.Header>
        <Layout.ContentTwoColumns>
          <div>
            <BidPotentialLeftBlock bid={bid} refreshBid={refreshBid} />
          </div>
          <div>
            <BidPotentialRightBlock bid={bid} />
          </div>
        </Layout.ContentTwoColumns>
      </Layout.WrapperContent>
      {isOpen && <TaskModal user={bid.owner} bid={bid} close={() => setIsOpen(false)} />}
    </APageWrapper>
  )
}
