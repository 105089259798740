import { FormGroup, TextArea } from '@agro-club/agroclub-shared'
import { useAFormHandler } from 'analytics/hooks'
import { useFormik } from 'formik'
import { refetchFunc } from 'modules/domain/common/hooks'
import { endpoints } from 'modules/endpoints'
import { apiClient } from 'modules/utils/httpClient'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { FormGroupUpload } from 'views/components/FormGroupUpload/FormGroupUpload'
import { ResponsiveModal } from 'views/components/ResponsiveModal/ResponsiveModal'
import { SharedModalContent, SharedModalInputs } from 'views/components/SharedModal'

type Props = {
  onClose: () => void
  tripId: string
  docType: string
  onSuccess: (() => void) | refetchFunc
}

export const UploadTtnModal: FC<Props> = ({ onClose, docType, tripId, onSuccess }) => {
  const { t } = useTranslation('logisticsKR')
  const { formProgress, formHandler } = useAFormHandler()

  const filesUpload = (values) => {
    const uploadPromises = values.files_ids.map((file_id) => {
      const dto = {
        comment: values.comment,
        document_type: values.document_type,
        file_id,
      }

      return apiClient.post(endpoints.LogisticsKR.tripDocuments(tripId), dto)
    })

    return Promise.all(uploadPromises)
  }

  const formik = useFormik({
    initialValues: {
      comment: '',
      document_type: docType,
      files_ids: [],
    },

    onSubmit: formHandler((values) => filesUpload(values), {
      onSuccess: () => {
        onSuccess()
        onClose()
      },
    }),
  })

  return (
    <ResponsiveModal
      id="ttn_files"
      title={t('details.addTtn')}
      progress={formProgress}
      size="medium"
      confirmButtonText={t('common:save')}
      onConfirm={() => formik.submitForm()}
      onClose={onClose}
      disabled={!formik.values.files_ids.length}
    >
      <SharedModalContent>
        <SharedModalInputs>
          <FormGroup label={t('common:comment')}>
            <TextArea
              className="comment-area"
              {...formik.getFieldProps('comment')}
              height={82}
              placeholder={t('common:commentPlaceholder')}
            />
          </FormGroup>
          <FormGroupUpload label={t('common:files')} fieldName="files_ids" formik={formik} maxWidthFileUpload={405} />
        </SharedModalInputs>
      </SharedModalContent>
    </ResponsiveModal>
  )
}
