import { ColumnsType, Text } from '@agro-club/agroclub-shared'
import { DASH } from 'modules/constants'
import { User } from 'modules/domain/user/types'
import { WarehouseMovement } from 'modules/domain/warehouses/types'
import { labelQuantity, labelQuantityTarif } from 'modules/utils/numbers/formatQuantity'
import { labelTarif } from 'modules/utils/numbers/formatTarif'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router'
import { CellRenders } from 'views/components/Table/renderers'
import { UserLink } from 'views/components/UserLink/UserLink'
import DealRoutes from 'views/pages/Deal/routes'
import APhone from 'views/ui/Phone/APhone'
import { TableTextLink } from 'views/ui/TableTextLink/TableTextLink'

const UserCompanyInfo: FC<{ owner: User }> = ({ owner }) => {
  return (
    <div>
      <UserLink bodysize="bodyMedium" user={owner} />
      <div>
        <APhone phone={owner.phone} />
      </div>
      <Text typography="bodyMedium" color="secondary">
        {owner.profile?.company.name || DASH}
      </Text>
    </div>
  )
}

const useColumns = (): ColumnsType<WarehouseMovement> => {
  const { t } = useTranslation('warehouses')

  return useMemo(
    () => [
      {
        title: `${t('common:crop')}/${t('common:deal')}`,
        dataIndex: 'product',
        width: 160,
        render: (_, row) => (
          <div>
            <TableTextLink
              id="deal"
              tableItemId={String(row.deal.id)}
              to={generatePath(DealRoutes.Details, { id: row.deal.id })}
            >
              {row.deal.product.title}
            </TableTextLink>
            <Text typography="bodyMedium" color="secondary">{`ID ${row.deal.id}`}</Text>
          </div>
        ),
      },
      {
        title: `${t('detail.seller')}/${t('detail.company')}`,
        dataIndex: 'seller',
        width: 450,
        render: (_, row) => <UserCompanyInfo owner={row.seller} />,
      },
      {
        title: `${t('detail.customer')}/${t('detail.company')}`,
        dataIndex: 'customer',
        width: 450,
        render: (_, row) => <UserCompanyInfo owner={row.customer} />,
      },
      {
        title: t('detail.unloadDate'),
        dataIndex: 'documents_request_expected_complete_date',
        width: 116,
        render: CellRenders.date,
      },
      {
        title: labelQuantity(t('detail.unloaded')),
        dataIndex: 'quantity',
        align: 'right',
        width: 105,
      },
      {
        title: labelTarif(t('common:exw')),
        dataIndex: 'price_exw',
        width: 87,
        align: 'right',
        render: CellRenders.price,
      },
      {
        title: labelQuantityTarif(t('detail.storage')),
        dataIndex: 'storage_price',
        width: 114,
        align: 'right',
        render: CellRenders.price,
      },
      {
        title: labelQuantityTarif(t('detail.handlingPrice')),
        dataIndex: 'handling_price',
        width: 127,
        align: 'right',
        render: CellRenders.price,
      },
      {
        title: labelQuantityTarif(t('detail.transshipment')),
        dataIndex: 'transshipment_price',
        width: 121,
        align: 'right',
        render: CellRenders.price,
      },
    ],
    [t],
  )
}

export default useColumns
