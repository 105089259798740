import { FC } from 'react'
import { FormGroup, Input } from '@agro-club/agroclub-shared'
import { SimpleOptionsSelect } from '../CarsForm/SimpleOptionsSelect'
import { UnloadingType } from '../../types'
import { NumberInput } from 'views/ui/NumberInput/NumberInput'
import { useTranslation } from 'react-i18next'
import { FormikFieldsType } from 'modules/domain/types'

type Props = {
  formik: FormikFieldsType
  isEditModal?: boolean
}

export const TrailerModalContent: FC<Props> = ({ formik, isEditModal }) => {
  const { t } = useTranslation('logisticsKR')

  return (
    <>
      <FormGroup error={formik.errors.licence_number} label={t('carsEdit.form.labels.licence_number')} required>
        <Input
          placeholder={t('carsEdit.form.placeholders.default_trailer.licence_number')}
          {...formik.getFieldProps('licence_number')}
          invalid={!!formik.errors.licence_number}
          disabled={isEditModal}
        />
      </FormGroup>
      <FormGroup
        error={formik.errors.brand_model}
        label={t('carsEdit.form.labels.default_trailer.brand_model')}
        required={!isEditModal}
      >
        <Input
          placeholder={t('carsEdit.form.placeholders.default_trailer.brand_model')}
          {...formik.getFieldProps('brand_model')}
          invalid={!!formik.errors.brand_model}
        />
      </FormGroup>
      <SimpleOptionsSelect
        formik={formik}
        fieldName="unloading_type"
        EnumType={UnloadingType}
        label={t('carsEdit.form.labels.unloading_type')}
        optionsTranslations="logisticsKR:directions"
        required
        allowClear={false}
      />
      <FormGroup
        error={formik.errors.side_height}
        label={t('carsEdit.form.labels.side_height')}
        required={!isEditModal}
      >
        <NumberInput
          placeholder={t('carsEdit.form.placeholders.side_height')}
          {...formik.getFieldProps('side_height')}
          invalid={!!formik.errors.side_height}
          onChange={(value) => formik.setFieldValue('side_height', value)}
        />
      </FormGroup>
    </>
  )
}
