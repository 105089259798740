import styled from 'styled-components'
import { FullScreenWrapper } from 'views/components/Table/styled'

// TODO need to move in defaultTheme.size (for example)
export const maxColumnWidth = '172px'

export const DealsWrapper = styled(FullScreenWrapper)`
  margin-top: 16px;
  margin-bottom: 100px;
`
