import { defaultListRequestParams } from 'views/components/TableFilters/data'
import { useTableData } from 'modules/domain/common/hooks'
import { ListRequestParams } from 'modules/domain/types'
import { ProductSms } from 'modules/domain/user/types'
import { endpoints } from 'modules/endpoints'
import { useMemo, useState } from 'react'

const PAGE_SIZE = 3

export const useSmsHistoryManager = (userId: string) => {
  const [listRequestParams, setListRequestParams] = useState<ListRequestParams>({
    ...defaultListRequestParams,
    pageSize: PAGE_SIZE,
  })

  const {
    progress,
    total,
    data = [],
    refetch,
  } = useTableData<ProductSms>(endpoints.marketingProductSms(userId), listRequestParams, true)

  const updateFilterState = (filterObj: Record<string, any>) => {
    setListRequestParams((prev) => ({
      ...prev,
      page: 1,
      filter: {
        ...prev?.filter,
        ...filterObj,
      },
    }))
  }

  return useMemo(
    () => ({
      setListRequestParams,
      listRequestParams,
      progress,
      refetch,
      total,
      data,
      updateFilterState,
    }),
    [data, listRequestParams, progress, refetch, total],
  )
}
