import { FC } from 'react'

import { AnalyticsFilterVariant } from 'analytics/types'
import { ListRequestParams } from 'modules/domain/types'
import { endpoints } from 'modules/endpoints'
import { useTranslation } from 'react-i18next'
import { ABaseTableFilter } from 'views/components/TableFilters/ABaseTableFilter'

interface Props {
  listRequestParams: ListRequestParams
  updateFilterState: (filterObj: Record<string, any>, commaShieldingParam?: string) => void
  disabled: boolean
}

export const StatusFilter: FC<Props> = ({ listRequestParams, updateFilterState, disabled }) => {
  const { t } = useTranslation('dispatching')

  return (
    <ABaseTableFilter
      id={AnalyticsFilterVariant.STATUS}
      onChange={(status) => updateFilterState({ status })}
      endpoint={endpoints.dispatchingStatuses()}
      value={listRequestParams.filter.status}
      placeholder={t('table.status')}
      getLabel={(dto) => dto.label}
      showSearch={false}
      simpleApiFormat
      disabled={disabled}
      multiple
    />
  )
}
