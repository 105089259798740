import { FC } from 'react'
import { DealsWrapper } from './styled'
import { useColumns } from './useColumns'
import { endpoints } from 'modules/endpoints'
import { AntdTable } from '@agro-club/agroclub-shared'
import { Deals } from 'modules/domain/specification/types'
import { useSingleEntity } from 'modules/domain/common/hooks'

interface Props {
  id: string
}

export const DealsTable: FC<Props> = ({ id }) => {
  const columns = useColumns()
  const [progress, deals = []] = useSingleEntity<Deals[]>(endpoints.specificationDeals(id))

  return (
    <DealsWrapper minHeight="auto">
      <AntdTable rowKey={({ id }) => id} columns={columns} data={deals} progress={progress} pageSize={deals?.length} />
    </DealsWrapper>
  )
}

export default DealsTable
