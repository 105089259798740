import { Route, Routes } from 'react-router-dom'
import CallsList from 'views/pages/Calls/CallsList'

function CallsPage() {
  return (
    <Routes>
      <Route index element={<CallsList />} />
    </Routes>
  )
}

export default CallsPage
