import styled from 'styled-components'
import { defaultTheme, Typography } from '@agro-club/agroclub-shared'
import { CSSProperties } from 'react'

export const SideHeightFilterWrapper = styled.div`
  max-width: 142px;

  ${Typography.bodyLarge}
  input {
    height: 32px;
    border-radius: 8px;
    box-shadow: none;
    background-color: transparent;
  }
`

export const FiltersWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 16px 0;
  z-index: 2;
  gap: 10px;
`

export const HeaderButtons = styled.div`
  display: flex;
  align-items: center;
  column-gap: 16px;
`

export const HeaderTitleWrapper = styled.div`
  margin-top: 12px;
`

export const SeparateDetailsItem = styled.div<{ $alignCenter?: boolean }>`
  display: flex;
  align-items: ${({ $alignCenter }) => ($alignCenter ? 'center' : 'flex-start')};
  justify-content: space-between;
  padding: 8px 12px;
  margin-top: 4px;
  border: 1px solid ${defaultTheme.color.secondary50};
  border-radius: 8px;
`

export const DetailsItemInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 4px;
`

export const DetailsItemButtons = styled.div`
  display: flex;
  align-items: center;
  column-gap: 6px;
`

export const DetailsStyledIcon = styled.div`
  button {
    width: 32px;
    height: 32px;
    min-width: auto;
    padding: 0;

    &:hover,
    &:focus {
      background-color: ${defaultTheme.color.primary24a};
      box-shadow: none;
    }
  }

  svg {
    path {
      fill: inherit;
      stroke: inherit;
    }
  }
`

export const SpinnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const TimeInputContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 6px;
`

export const TimeInputWrapper = styled.div`
  max-width: 94px;
`

export const ShortField = styled.div`
  max-width: 212px;

  .ant-picker {
    width: 100%;
  }
`

export const ContentBlock = styled.div`
  width: 100%;
  padding: 16px;
  background-color: ${defaultTheme.color.backgroundSecondary};
  border-radius: 8px;
  box-shadow: ${defaultTheme.boxShadowColor};
`

export const Title = styled.h2`
  margin: 0 0 12px 0;
  ${Typography.titleH4}
  color: ${defaultTheme.color.textPrimary900};
`

export const SubTitle = styled.h3`
  margin: 0;
  ${Typography.bodyLarge}
  color: ${defaultTheme.color.textSecondary60a};
`

export const Text = styled.p`
  margin: 0;
  ${Typography.bodyLarge}
  color: ${defaultTheme.color.textPrimary900};
`

export const InfoTable = styled.div<{ withBottomMargin?: boolean }>`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  margin-bottom: ${({ withBottomMargin = false }) => (withBottomMargin ? '24px' : 0)};
`

export const InfoRow = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, 172px);
`

export const LongInfoRow = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const InfoItem = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
`

export const controlContainerStyles = (): CSSProperties => ({
  maxWidth: 238,
  flex: '0 0 100%',
})
