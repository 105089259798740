import { Progress } from '@agro-club/agroclub-shared'
import { useAProgress } from 'hooks/useAProgress'
import { useNotification } from 'hooks/useNotification'
import { BidContractFilter } from 'modules/domain/bid/api'
import { Bid, BidContract } from 'modules/domain/bid/types'
import { Deal } from 'modules/domain/deal/types'
import { ListRequestParams } from 'modules/domain/types'
import { endpoints } from 'modules/endpoints'
import { apiClient } from 'modules/utils/httpClient'
import { useCallback, useState } from 'react'

type UseRematchingPreviewParams = {
  deal?: Deal
  saleContract: Bid | BidContract | null
  purchaseContract: Bid | BidContract | null
}

export type RematchingItemsRequestParams = Omit<ListRequestParams, 'filter'> & {
  filter: BidContractFilter
}

export const useRematchingPreview = ({
  deal: initialDeal,
  saleContract,
  purchaseContract,
}: UseRematchingPreviewParams) => {
  const [deal, setDeal] = useState<Deal | null>(initialDeal ?? null)
  const notify = useNotification()

  const onError = (error) => {
    notify(Progress.ERROR, { title: error?.error[0] })
    setDeal(null)
  }

  const [progress, getPreview] = useAProgress(
    () =>
      apiClient.get<Deal>(
        endpoints.dealsManualPreview({
          sale_bid_id: saleContract?.id,
          purchase_bid_id: purchaseContract?.id ?? '',
        }),
      ),
    {
      eventName: 'DealPreview',
      onSuccess: (response) => setDeal(response),
      onError,
    },
  )

  const refetch = useCallback(() => {
    if (!saleContract?.id || !purchaseContract?.id) {
      setDeal(null)
      return
    }
    return getPreview()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saleContract, purchaseContract])

  return {
    deal: progress !== Progress.WORK && saleContract?.id && purchaseContract?.id ? deal : null,
    setDeal,
    saleContract,
    purchaseContract,
    progress,
    refetch,
  }
}

export type RematchingPreviewData = ReturnType<typeof useRematchingPreview>
