import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { BooleanCell } from '../../../../components/BooleanCell'
import { ColumnData } from 'modules/types'
import { removeSeconds } from 'views/pages/LogisticsKR/helpers'
import { generatePath } from 'react-router'
import WarehousesKRRoutes from '../../../routes'
import { TableTextLink } from 'views/ui/TableTextLink/TableTextLink'
import { AddressWrapper } from '../styled'
import useFormatNumber from 'hooks/useFormatNumber'

const useColumns = (): ColumnData[] => {
  const { t } = useTranslation('logisticsKR')
  const formatNumber = useFormatNumber()

  return useMemo(
    () => [
      {
        title: t('warehouses.list.tableHeaders.address'),
        dataIndex: 'address',
        width: 325,
        render: (address, data) => (
          <AddressWrapper>
            <TableTextLink
              id="warehouse"
              tableItemId={data.id}
              to={generatePath(WarehousesKRRoutes.Details, { id: data.id, type: 'loading' })}
            >
              {address}
            </TableTextLink>
          </AddressWrapper>
        ),
      },
      {
        title: t('warehouses.list.tableHeaders.gates_height'),
        dataIndex: 'gates_height',
        width: 144,
        align: 'right',
        render: (gates_height) => formatNumber(gates_height),
      },
      {
        title: t('warehouses.list.tableHeaders.loader_type'),
        dataIndex: 'loader_type',
        width: 180,
        render: (loader_type) => loader_type.map((type) => t(`vehicle_type.${type}`)).join(', '),
      },
      {
        title: t('warehouses.list.tableHeaders.scales_capacity'),
        dataIndex: 'scales_capacity',
        width: 85,
        align: 'right',
      },
      {
        title: t('warehouses.list.tableHeaders.scales_remoteness'),
        dataIndex: 'scales_remoteness',
        width: 120,
        align: 'right',
      },
      {
        title: t('warehouses.list.tableHeaders.scales_length'),
        dataIndex: 'scales_length',
        width: 144,
        align: 'right',
      },
      {
        title: t('warehouses.list.tableHeaders.volume_per_day'),
        dataIndex: 'volume_per_day',
        width: 144,
        align: 'right',
        render: (volume_per_day) => formatNumber(volume_per_day),
      },
      {
        title: t('warehouses.list.tableHeaders.vehicle_type'),
        dataIndex: 'vehicle_type',
        width: 188,
        render: (vehicle_type) => vehicle_type.map((type) => t(`vehicle_type.${type}`)).join(', '),
      },
      {
        title: t('warehouses.list.tableHeaders.schedule'),
        dataIndex: 'schedule_from',
        width: 120,
        render: (schedule_from, data) => `${removeSeconds(schedule_from)}-${removeSeconds(data.schedule_to)}`,
      },
      {
        title: t('warehouses.list.tableHeaders.works_on_weekend'),
        dataIndex: 'works_on_weekend',
        width: 90,
        render: (works_on_weekend) => <BooleanCell field={works_on_weekend} />,
      },
      {
        title: t('warehouses.list.tableHeaders.car_delivery'),
        dataIndex: 'car_delivery',
        width: 56,
        render: (car_delivery) => <BooleanCell field={car_delivery} />,
      },
      {
        title: t('warehouses.list.tableHeaders.railway_delivery'),
        dataIndex: 'railway_delivery',
        width: 56,
        render: (railway_delivery) => <BooleanCell field={railway_delivery} />,
      },
    ],
    [t, formatNumber],
  )
}

export default useColumns
