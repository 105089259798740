const main = 'warehousesKR'

const relativeRoutes = {
  relativePage: `${main}/*`,
  relativeList: `${main}`,
  relativeDetails: `:id/:type`,
  relativeListWithTab: `tab-:activeTab`,
}

const routes = {
  List: `/${main}`,
  Details: `/${main}/${relativeRoutes.relativeDetails}`,
  ListWithTabs: `/${main}/${relativeRoutes.relativeListWithTab}`,
}

const WarehousesKRRoutes = { ...relativeRoutes, ...routes }

export default WarehousesKRRoutes
