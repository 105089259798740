import { FC } from 'react'
import { useParams } from 'react-router-dom'

import { endpoints } from 'modules/endpoints'
import { RespFile } from 'modules/domain/types'
import { apiClient } from 'modules/utils/httpClient'
import { uploadFile } from 'modules/domain/common/managers'
import { EntityTitle } from '../styled'
import { useTranslation } from 'react-i18next'
import AUploadFiles from 'views/components/UploadFiles/AUploadFiles'
import { Progress } from '@agro-club/agroclub-shared'

type Props = {
  progress: Progress
  dealFiles: RespFile[] | undefined
  refetch: (silent: boolean) => void
  readonly?: boolean
}

export const Files: FC<Props> = ({ progress, dealFiles, refetch, readonly }) => {
  const { t } = useTranslation('deal')
  const { id } = useParams()

  const removeHandler = async (dealId: string, fileId: string) => {
    await apiClient.delete(endpoints.dealFiles(dealId, fileId))
    refetch(true)
  }

  const addHandler = async (dealId: string, file: File) => {
    await uploadFile(endpoints.dealFiles(dealId), file)
    refetch(true)
  }

  return (
    <div>
      <EntityTitle>{t('accordion.dealFiles.files')}</EntityTitle>
      <AUploadFiles
        id="dealFile"
        progress={progress}
        removeFile={(fileId) => removeHandler(id, fileId)}
        addFile={({ file }) => addHandler(id, file)}
        fileList={dealFiles}
        readonly={readonly}
      />
    </div>
  )
}
