import { useAProgress } from 'hooks/useAProgress'
import { WarehouseStatuses } from 'modules/domain/warehouses/types'
import { endpoints } from 'modules/endpoints'
import { apiClient } from 'modules/utils/httpClient'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { AButton } from 'views/components/Analytics'
import ADefaultModalFooter from 'views/components/DefaultModalFooter/ADefaultModalFooter'
import { ASharedModal, SharedModalContent, SharedModalTitle } from 'views/components/SharedModal'

type Props = {
  refetch: () => void
  status: WarehouseStatuses
}

export const ArchiveWarehouse: FC<Props> = ({ refetch, status }) => {
  const { id } = useParams()
  const { t } = useTranslation('warehouses')
  const [confirmModal, setConfirmModal] = useState(false)

  const [progress, archive] = useAProgress(
    async () => apiClient.post(endpoints.warehouseChangeStatus(id), { new_status: WarehouseStatuses.archived }),
    {
      eventName: 'archiveWarehouse',
      onSuccess: async () => {
        await refetch()
        setConfirmModal(false)
      },
    },
  )

  return (
    <>
      <AButton
        id="archiveWarehouse"
        intent="secondary"
        disabled={status === WarehouseStatuses.archived}
        onClick={() => setConfirmModal(true)}
      >
        {t('detail.archiveWarehouse')}
      </AButton>
      {confirmModal && (
        <ASharedModal size="small" id="archiveModal" onClose={() => setConfirmModal(false)}>
          <SharedModalTitle>{t('detail.archiveWarehouse')}</SharedModalTitle>
          <SharedModalContent>{t('detail.archiveWarehouseConfirm')}</SharedModalContent>
          <ADefaultModalFooter
            onClose={() => setConfirmModal(false)}
            progress={progress}
            onConfirm={() => archive()}
            confirmButtonText={t('common:archive')}
          />
        </ASharedModal>
      )}
    </>
  )
}
