import AUploadFiles from 'views/components/UploadFiles/AUploadFiles'
import { useSingleEntity } from 'modules/domain/common/hooks'
import { uploadFile } from 'modules/domain/common/managers'
import { apiClient } from 'modules/utils/httpClient'
import { Text } from '@agro-club/agroclub-shared'
import { RespFile } from 'modules/domain/types'
import { useTranslation } from 'react-i18next'
import { endpoints } from 'modules/endpoints'
import styled from 'styled-components'
import { FC } from 'react'

type Props = {
  id: string
}
const StyledText = styled(Text)`
  margin-bottom: 12px;
`

export const ExportFiles: FC<Props> = ({ id }) => {
  const [progress, files, bidFilesRefetch] = useSingleEntity<RespFile[]>(endpoints.exportFiles(id))
  const { t } = useTranslation('bid')

  const removeHandler = async (exportId: string, fileId: string) => {
    await apiClient.delete(endpoints.exportFiles(exportId, fileId))
    bidFilesRefetch(true)
  }

  const addHandler = async (exportId: string, file: File) => {
    await uploadFile(endpoints.exportFiles(exportId), file)
    bidFilesRefetch(true)
  }
  return (
    <div>
      <StyledText typography="titleH4">{t('documents')}</StyledText>
      <AUploadFiles
        removeFile={(fileId) => removeHandler(id, fileId)}
        addFile={({ file }) => addHandler(id, file)}
        progress={progress}
        fileList={files}
      />
    </div>
  )
}
