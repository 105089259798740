import { Route, Routes } from 'react-router-dom'
import BidList from 'views/pages/Bid/BidList'
import BidRoutes from 'views/pages/Bid/routes'
import { BidDetail } from 'views/pages/Bid/BidDetail/Bid/BidDetail'
import { BidDetailPotential } from './BidDetail/PotentialBid/BidDetailPotential'

function BidPage() {
  return (
    <Routes>
      <Route index element={<BidList />} />
      <Route path={BidRoutes.relativeListWithTab} element={<BidList />} />
      <Route path={BidRoutes.relativeDetail} element={<BidDetail />} />
      <Route path={BidRoutes.relativePotentialBidsDetail} element={<BidDetailPotential />} />
    </Routes>
  )
}

export default BidPage
