import { ResponsiveModal } from 'views/components/ResponsiveModal/ResponsiveModal'
import { SharedModalContent } from 'views/components/SharedModal'
import { refetchFunc } from 'modules/domain/common/hooks'
import { apiClient } from 'modules/utils/httpClient'
import { Deal } from 'modules/domain/deal/types'
import { useProgress } from 'hooks/useProgress'
import { useTranslation } from 'react-i18next'
import { endpoints } from 'modules/endpoints'
import { FC } from 'react'

type Props = {
  refetch: refetchFunc
  onClose: () => void
  deal: Deal
}

export const DeleteModal: FC<Props> = ({ onClose, deal, refetch }) => {
  const { t } = useTranslation('deal')

  const noDocuments = deal.without_documents_request

  const [progress, onSubmit] = useProgress(async () => {
    if (noDocuments) {
      await apiClient.post(endpoints.deleteWithoutDocumentsRequest(deal.id))
    }
    if (deal.documents_request) {
      await apiClient.delete(endpoints.documentsRequest(deal.documents_request.id))
    }
    await refetch()
  })

  const title = noDocuments ? t('without_docs_modal.titleDelete') : t('with_docs_modal.title')
  const content = noDocuments ? t('without_docs_modal.descriptionDelete') : t('with_docs_modal.description')

  return (
    <ResponsiveModal
      confirmButtonText={t('common:yes_remove')}
      onConfirm={onSubmit}
      progress={progress}
      id="DeleteDocument"
      onClose={onClose}
      title={title}
    >
      <SharedModalContent>{content}</SharedModalContent>
    </ResponsiveModal>
  )
}
