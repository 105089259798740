import { useAFormHandler } from 'analytics/hooks'
import { useFormik } from 'formik'
import { refetchFunc } from 'modules/domain/common/hooks'
import { WarehouseMovement } from 'modules/domain/warehouses/types'
import { endpoints } from 'modules/endpoints'
import { apiClient } from 'modules/utils/httpClient'
import { labelDailyPriceInTon } from 'modules/utils/numbers/formatDailyPriceInTon'
import { labelTarif } from 'modules/utils/numbers/formatTarif'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { FormInput } from 'views/components/form/FormInput'
import { ResponsiveModal } from 'views/components/ResponsiveModal/ResponsiveModal'
import { SharedModalInputs } from 'views/components/SharedModal'

type Props = {
  onClose: () => void
  whMovement: WarehouseMovement
  whMovementRefetch: refetchFunc
}

export const WhMovementEditModal: FC<Props> = ({ onClose, whMovement, whMovementRefetch }) => {
  const { t } = useTranslation('warehouses')
  const { formProgress, formHandler } = useAFormHandler()

  const formik = useFormik({
    initialValues: {
      storage_price: whMovement?.storage_price,
      handling_price: whMovement?.handling_price,
      transshipment_price: whMovement?.transshipment_price,
    },

    onSubmit: formHandler(() => apiClient.put(endpoints.warehouseMovements(whMovement.id), formik.values), {
      onSuccess: async () => {
        await whMovementRefetch(true)
        onClose()
      },
      onError: (error) => {
        const { errors } = error
        formik.setErrors(errors)
      },
    }),
  })

  return (
    <ResponsiveModal
      id="warehouseEdit"
      size="small"
      onClose={onClose}
      title={t('editWarehouse')}
      progress={formProgress}
      disabled={!formik.dirty}
      confirmButtonText={t('common:save')}
      onConfirm={() => formik.submitForm()}
    >
      <SharedModalInputs>
        <FormInput
          label={labelDailyPriceInTon(t('detail.storage'))}
          formik={formik}
          field="storage_price"
          type="number"
        />
        <FormInput label={labelTarif(t('detail.handlingPrice'))} formik={formik} field="handling_price" type="number" />
        <FormInput
          label={labelTarif(t('detail.transshipment'))}
          formik={formik}
          field="transshipment_price"
          type="number"
        />
      </SharedModalInputs>
    </ResponsiveModal>
  )
}
