import { Progress } from '@agro-club/agroclub-shared'
import { useSingleEntity } from 'modules/domain/common/hooks'
import { RatingByType, User } from 'modules/domain/user/types'
import { endpoints } from 'modules/endpoints'
import { FC } from 'react'
import { SpinnerCenter } from 'views/pages/Specifications/styled'
import { RatingWrapper } from './styled'
import { UserRatingByDeal } from './UserRatingByDeal/UserRatingByDeal'
import { UserRatingByTypeList } from './UserRatingByType/UserRatingByType'

interface Props {
  user: User
}

export const UserRating: FC<Props> = ({ user }) => {
  const { id, rating } = user
  const [ratingsByTypeProgress, ratingsByType] = useSingleEntity<RatingByType[]>(endpoints.userRatingByType(id))

  if (ratingsByTypeProgress === Progress.WORK && !ratingsByType) return <SpinnerCenter />

  return (
    <RatingWrapper>
      <UserRatingByTypeList ratings={ratingsByType} commonRating={rating} />
      <UserRatingByDeal userId={id} />
    </RatingWrapper>
  )
}
