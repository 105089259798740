import { SharedModalContent, SharedModalInputs, SharedModalTitle } from 'views/components/SharedModal'
import ADefaultModalFooter from 'views/components/DefaultModalFooter/ADefaultModalFooter'
import ASharedModal from 'views/components/SharedModal/ASharedModal'
import { Deal, DealStatusTypes } from 'modules/domain/deal/types'
import FormSelectShared from 'views/components/FormSelectShared'
import { refetchFunc } from 'modules/domain/common/hooks'
import { BidContract } from 'modules/domain/bid/types'
import { apiClient } from 'modules/utils/httpClient'
import { AButton } from 'views/components/Analytics'
import { useAFormHandler } from 'analytics/hooks'
import { Text } from '@agro-club/agroclub-shared'
import * as Header from 'views/ui/Header/Header'
import { useTranslation } from 'react-i18next'
import { endpoints } from 'modules/endpoints'
import { useState, FC } from 'react'
import { useFormik } from 'formik'

type Props = {
  contractDeals: Deal[]
  contract: BidContract
  refetch: refetchFunc
}

const statusDeals = [DealStatusTypes.NEW, DealStatusTypes.IN_PROGRESS, DealStatusTypes.AGREED]

export const CloseContractBtn: FC<Props> = ({ contract, refetch, contractDeals }) => {
  const { t } = useTranslation('bidContracts')
  const { id } = contract

  const [isConfirmVisible, setConfirmVisible] = useState<boolean>(false)
  const { formProgress, formHandler } = useAFormHandler('closeContract', { id })

  const formik = useFormik<{ closed_reason: string }>({
    initialValues: {
      closed_reason: '',
    },

    onSubmit: formHandler(
      async () => {
        await apiClient.post(endpoints.closeContract(id), formik.values)
      },
      {
        onSuccess: async () => {
          await refetch(true)
          setConfirmVisible(false)
        },
        onError: (error) => {
          const { errors } = error
          formik.setErrors(errors)
        },
      },
    ),
  })

  const unlockedBtn =
    !contractDeals.length || contractDeals.some((deal) => statusDeals.includes(deal.status as DealStatusTypes))

  return (
    <>
      <Header.LineSeparator />
      <AButton
        intent="destruct"
        size="medium"
        onClick={() => {
          setConfirmVisible(true)
        }}
        disabled={!unlockedBtn}
        id="close"
        analyticsPayload={{ id }}
      >
        {t('close')}
      </AButton>

      {isConfirmVisible && (
        <ASharedModal id="closeModal" onClose={() => setConfirmVisible(false)} size="small">
          <SharedModalTitle>{t('closeContract')}</SharedModalTitle>
          <SharedModalContent>
            <SharedModalInputs>
              <Text>{t('reasonСlosing')}</Text>
              <FormSelectShared
                endpoint={endpoints.contractClosedReasons()}
                getLabel={(dto) => dto.label}
                fieldName="closed_reason"
                disableRightPadding
                allowClear={false}
                showSearch={false}
                formik={formik}
                simpleApiFormat
                hideLabel
              />
            </SharedModalInputs>
          </SharedModalContent>
          <ADefaultModalFooter
            onClose={() => setConfirmVisible(false)}
            onConfirm={() => formik.submitForm()}
            confirmButtonText={t('close')}
            disabled={!formik.dirty}
            progress={formProgress}
            intent="destruct"
          />
        </ASharedModal>
      )}
    </>
  )
}
