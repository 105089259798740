import { Priority } from '../styled'
import { priority as renderPriority } from 'views/components/Table/renderers'
import { useTranslation } from 'react-i18next'
import { FC } from 'react'
import { Task } from '../types'
import { KeyValue } from 'views/components/KeyValue/KeyValue'
import useFormatDate from 'hooks/useFormatDate'
import { Card } from 'views/components/Card/Card'
import { ARouterLink } from 'views/components/Analytics'

interface Props {
  task?: Task | undefined
}

export const RightBlock: FC<Props> = ({ task }) => {
  const { t } = useTranslation('my_tasks')
  const formatDate = useFormatDate()
  const renderPriorityText = (priority: number) => {
    switch (priority) {
      case 1:
        return t('detail.high')
      case 2:
        return t('detail.medium')
      case 3:
        return t('detail.low')
      default:
        return priority
    }
  }

  return (
    <Card.Container>
      <Card.GapableContent>
        <Card.Title>{t('detail.information')}</Card.Title>
        {task?.assignee && <KeyValue label={t('detail.assignee')}>{task.assignee.full_name}</KeyValue>}
        {task?.priority && (
          <KeyValue label={t('detail.priority')}>
            <Priority>
              {renderPriority(task.priority)}
              <>{renderPriorityText(task.priority)}</>
            </Priority>
          </KeyValue>
        )}
        {task?.assignee_team && <KeyValue label={t('detail.team')}>{task.assignee_team.name}</KeyValue>}
        {task?.created && <KeyValue label={t('detail.date_creation')}>{formatDate(task.created)}</KeyValue>}
        {task?.deadline && <KeyValue label={t('detail.deadline')}>{formatDate(task.deadline)}</KeyValue>}
        {task?.completed_at && <KeyValue label={t('detail.date_completion')}>{formatDate(task.completed_at)}</KeyValue>}
        {task?.created_by && (
          <KeyValue label={t('detail.author')}>
            <ARouterLink eventName="author" itemId={String(task.created_by.id)} to={`/users/${task.created_by.id}`}>
              {task.created_by.full_name}
            </ARouterLink>
          </KeyValue>
        )}
      </Card.GapableContent>
    </Card.Container>
  )
}
