import { useTranslation } from 'react-i18next'

import { FormGroup, Select } from '@agro-club/agroclub-shared'
import { FormikProps } from 'formik'

import { AgroundImputs, BidAddFields } from 'modules/domain/bid/types'
import { ShortFormControl } from 'views/styled/common'
import { FC } from 'react'
import { TaxesFormFields } from '../../BidModals/Bid/BidTaxesModal'

type Props = {
  formik: FormikProps<BidAddFields> | FormikProps<TaxesFormFields>
}

export const AgroundImputsSelect: FC<Props> = ({ formik }) => {
  const { t } = useTranslation('bid')
  const error = formik.errors.aground_imputs

  return (
    <ShortFormControl>
      <FormGroup label={t('taxes.aground_imputs')} error={error}>
        <Select
          onChange={(val) => formik.setFieldValue('aground_imputs', val)}
          options={[
            { value: AgroundImputs.YES, label: t('common:yes') },
            { value: AgroundImputs.NO, label: t('common:no') },
          ]}
          value={formik.values.aground_imputs}
          showSearch={false}
          allowClear={false}
          status={error ? 'error' : ''}
          fullWidth
        />
      </FormGroup>
    </ShortFormControl>
  )
}
