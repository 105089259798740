import { getPersonalNumberLabel } from 'modules/utils/numbers/formatPersonalNumber'
import { CompanyLink } from 'views/components/CompanyLink/CompanyLink'
import { Company } from 'modules/domain/company/types'
import { useTranslation } from 'react-i18next'
import { InfoBlock } from './styled'
import { FC } from 'react'

type Props = {
  company: Company
}

export const CompanyInfo: FC<Props> = ({ company }) => {
  const { t } = useTranslation()
  const userInn = company?.inn ? `${getPersonalNumberLabel()}: ${company.inn}` : t('common:notSpecified')

  return (
    <InfoBlock>
      <CompanyLink company={company} />

      {userInn}
    </InfoBlock>
  )
}
