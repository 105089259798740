import { FC, useState } from 'react'

import { useTranslation } from 'react-i18next'

import env from 'env'
import useFormatNumber from 'hooks/useFormatNumber'
import { TeamsFullPlansList } from 'modules/domain/teams/types'
import {
  EmptyPlanCardContainer,
  EmptyPlanDetail,
  EmptyPlanTitle,
  PopupBodyContainer,
  PopupBodyLabel,
  PopupInput,
} from 'views/pages/Teams/TeamDetail/styled'
import { SharedModalContent, SharedModalTitle } from 'views/components/SharedModal'
import ASharedModal from 'views/components/SharedModal/ASharedModal'
import ADefaultModalFooter from 'views/components/DefaultModalFooter/ADefaultModalFooter'
import { useAnalyticsClickHandler } from 'analytics/hooks'
import { Progress } from '@agro-club/agroclub-shared'

const currency = env.REACT_APP_CURRENCY_SYMBOL

type PopupProps = {
  inputValue: number
  onChange: (number) => void
}

export const PopupBody: FC<PopupProps> = ({ onChange, inputValue }) => {
  const { t } = useTranslation('teams')

  return (
    <PopupBodyContainer>
      <PopupBodyLabel>{t('volumeAmount', { currency })}</PopupBodyLabel>
      <PopupInput inputSize="small" value={inputValue} onChange={(e) => onChange(parseInt(e.target.value, 10))} />
    </PopupBodyContainer>
  )
}

type Props = {
  monthName: string
  year: number
  isInactiveMonth: boolean
  plan: TeamsFullPlansList
  progress?: Progress
  setPlan: (volume: number, schedule_date: string) => void
}

export const TeamPlanEmptyCard: FC<Props> = ({ monthName, year, isInactiveMonth, plan, progress, setPlan }) => {
  const handleClick = useAnalyticsClickHandler('plan')

  const [value, setValue] = useState(0)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const formatNumber = useFormatNumber()
  const withVolume = plan.volume !== 0

  const { t } = useTranslation('teams')

  if (isInactiveMonth) {
    return (
      <EmptyPlanCardContainer isInactiveMonth={isInactiveMonth}>
        <EmptyPlanTitle isInactiveMonth={isInactiveMonth}>{monthName}</EmptyPlanTitle>
        {withVolume ? (
          <EmptyPlanDetail isInactiveMonth={isInactiveMonth} withVolume={withVolume}>
            {formatNumber(plan.volume)}
            {currency}
          </EmptyPlanDetail>
        ) : (
          <EmptyPlanDetail isInactiveMonth={isInactiveMonth}>{t('notPlaned')}</EmptyPlanDetail>
        )}
      </EmptyPlanCardContainer>
    )
  }

  return (
    <>
      <EmptyPlanCardContainer onClick={handleClick(() => setModalIsOpen(true))}>
        <EmptyPlanTitle>{monthName}</EmptyPlanTitle>
        <EmptyPlanDetail>{t('clickToPlan')}</EmptyPlanDetail>
      </EmptyPlanCardContainer>
      {modalIsOpen && (
        <ASharedModal id="PlanModal" onClose={() => setModalIsOpen(false)} size="small">
          <SharedModalTitle>{t('plansPopupHeader', { planMonth: monthName, planYear: year })}</SharedModalTitle>
          <SharedModalContent>
            <PopupBody onChange={setValue} inputValue={value} />
          </SharedModalContent>

          <ADefaultModalFooter
            onClose={() => setModalIsOpen(false)}
            confirmButtonText={t('common:save')}
            onConfirm={async () => {
              await setPlan(value, plan.schedule_date)
              setModalIsOpen(false)
            }}
            progress={progress}
          />
        </ASharedModal>
      )}
    </>
  )
}
