/* import { Accordion, AccordionContent, AccordionHeader, AccordionItem } from '@agro-club/agroclub-shared' */
import { Customer } from 'modules/domain/logisticsKR/types'
import { FC } from 'react'

const RightBlock: FC<{ customer: Customer }> = () => {
  return <div></div>
  // const { t } = useTranslation('logisticsKR')
  /*   return (
    <Accordion single>
      <AccordionItem id="cars">
        <AccordionHeader>{t('details.carHeading')}</AccordionHeader>
        <AccordionContent>{customer.id}</AccordionContent>
      </AccordionItem>
      <AccordionItem id="adresses">
        <AccordionHeader>{t('details.addressHeading')} </AccordionHeader>
        <AccordionContent>TODO</AccordionContent>
      </AccordionItem>
      <AccordionItem id="comments">
        <AccordionHeader>{t('details.commentsHeading')} </AccordionHeader>
        <AccordionContent>TODO</AccordionContent>
      </AccordionItem>
    </Accordion>
  ) */
}

export default RightBlock
