const main = 'call-statistics'
const relativeRoutes = {
  relativePage: `${main}/*`,
  relativeList: `${main}`,
}

const routes = {
  List: `/${main}`,
}

const CallStatisticsRoutes = { ...relativeRoutes, ...routes }

export default CallStatisticsRoutes
