interface PayloadValue {
  from?: any
  to?: any
}

export interface AnalyticsPayload {
  user_id?: string
  page?: string
  place?: string
  filter?: AnalyticsFilterVariant
  field?: string
  id?: string
  value?: PayloadValue
  key?: string
}

export enum AnalyticsFilterVariant {
  CROPS = 'cropsFilter',
  COMPANY = 'companyFilter',
  DASHBOARD_SORT = 'dashboardSortFilter',
  TEAMS = 'teamsFilter',
  STATUS = 'statusFilter',
  SOURCE = 'sourceFilter',
  REGION = 'regionFilter',
  DISTRICT = 'districtFilter',
  ADDRESS = 'addressFilter',
  CLEAR_FILTERS = 'clear_filters',
  RADIUS = 'radiusFilter',
  FILE = 'fileFilter',
  TYPE = 'typeFilter',
  ASSIGNEE = 'assigneeFilter',
  AUTHOR = 'authorFilter',
  PRIORITY = 'priorityFilter',
  DATE = 'dateFilter',
  ROLE = 'roleFilter',
  COORDINATOR_TYPE = 'coordinatorTypeFilter',
  COORDINATOR_NAME = 'coordinatorNameFilter',
  FEATURE = 'featureFilter',
  MONTH = 'monthFilter',
  PRODUCT = 'productFilter',
  SEARCH = 'search',
  REQUEST_TYPE = 'requestTypeFilter',
  REQUEST_WITH_TRIP_OFFER = 'requestWithTripOffer',
  BOOLEAN = 'booleanFilter',
  CAR = 'carFilter',
  UNLOADING_TYPE = 'unloadingTypeFilter',
  SIDE_HEIGHT_FILTER = 'sideHeightFilter',
  DELIVERY = 'deliveryFilter',
  CALL_TYPE = 'callTypeFilter',
  LOGISTICIAN = 'logisticianFilter',
  FACTORING = 'factoringFilter',
  SEASONS = 'seasonsFilter',
  DEAL_TYPE = 'dealType',
  CITY = 'city',
  USER_STATUS = 'userStatus',
  DAYS = 'daysFilter',
}
