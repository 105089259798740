import { PaymentOrdersList } from './PaymentOrdersList'
import { Route, Routes } from 'react-router-dom'
import RatesRoutes from './routes'
import { FC } from 'react'

const PaymentOrdersPage: FC = () => (
  <Routes>
    <Route index element={<PaymentOrdersList />} />
    <Route path={RatesRoutes.relativeListWithTab} element={<PaymentOrdersList />} />
  </Routes>
)
export default PaymentOrdersPage
