import { AntTag } from '@agro-club/agroclub-shared'
import { Deal } from 'modules/domain/deal/types'
import { IconSample } from '../../Board/styled'
import { useTranslation } from 'react-i18next'
import { FC } from 'react'

interface Props {
  deal: Deal
}

export const SampleTag: FC<Props> = ({ deal }) => {
  const { t } = useTranslation()

  return (
    <AntTag icon={<IconSample $approve={deal.has_samples} />} color={deal.has_samples ? 'blue' : 'grey'}>
      {t('samples:samples')}
    </AntTag>
  )
}
