import { VehicleTypeCheckboxGroup } from '../CheckboxGroup/VehicleTypeCheckboxGroup'
import { OverloadRadio } from 'views/components/OverloadRadio/OverloadRadio'
import { FormGroup, Progress, Select } from '@agro-club/agroclub-shared'
import { ScheduleControls } from '../ScheduleControls/ScheduleControls'
import { ResponsiveModal } from '../ResponsiveModal/ResponsiveModal'
import { AnalyticsPlaces } from '../../pages/Deal/analyticsPlaces'
import { UnloadingType, WarehouseDTO } from 'modules/domain/types'
import { QuantityInput } from '../QuantityInput/QuantityInput'
import { YesNoUnknownRadio } from './YesNoUnknownRadio'
import { ShortFormControl } from '../../styled/common'
import { SharedModalInputs } from '../SharedModal'
import { BoolRadio } from '../BoolRadio/BoolRadio'
import { useTranslation } from 'react-i18next'
import { FormikProps } from 'formik'
import { FC } from 'react'

type Props = {
  onClose: () => void
  formik: FormikProps<WarehouseDTO>
  progress: Progress
  boolDeliveryRadio?: boolean //Probably a temporary solution, since the new logistics have switched to BoolRadio
}
export const BuyerWarehouseForm: FC<Props> = ({ onClose, formik, progress, boolDeliveryRadio }) => {
  const { t } = useTranslation('address')

  return (
    <ResponsiveModal
      title={t('logisticsKR:details:unloadingModal:heading')}
      id={AnalyticsPlaces.BUYER_WAREHOUSE_EDIT_MODAL}
      onConfirm={() => formik.submitForm()}
      disabled={!formik.dirty}
      progress={progress}
      onClose={onClose}
      size="large"
    >
      <SharedModalInputs>
        <VehicleTypeCheckboxGroup formik={formik} />
        <BoolRadio fieldName="is_dump_truck" formik={formik} label={t('fields.is_dump_truck')} />
        <ShortFormControl>
          <FormGroup label={t('fields.unloading_type')} error={formik.errors.unloading_type}>
            <Select
              onChange={(val) => formik.setFieldValue('unloading_type', val)}
              options={Object.keys(UnloadingType).map((val) => ({
                value: val,
                label: t(`vehicles:unloadingType.${val}`),
              }))}
              value={formik.values.unloading_type}
              showSearch={false}
              fullWidth
              allowClear={false}
            />
          </FormGroup>
          <QuantityInput formik={formik} fieldName="volume_per_day" label={t('fields.volume_per_day.customer')} />
        </ShortFormControl>
        <OverloadRadio formik={formik} />
        <FormGroup label={t('fields.schedule.customer')}>
          <ScheduleControls formik={formik} />
        </FormGroup>
        {['works_on_weekend', 'car_delivery', 'railway_delivery'].map((fieldName) =>
          boolDeliveryRadio ? (
            //Probably a temporary solution, since the new logistics have switched to BoolRadio
            <BoolRadio
              key={fieldName}
              label={t(`logisticsKR:list:createForm:labels:${fieldName}`)}
              fieldName={fieldName}
              formik={formik}
            />
          ) : (
            <FormGroup key={fieldName} label={t(`logisticsKR:list:createForm:labels:${fieldName}`)}>
              <YesNoUnknownRadio
                onChange={(value) => formik.setFieldValue(fieldName, value)}
                value={formik.values[fieldName]}
              />
            </FormGroup>
          ),
        )}
      </SharedModalInputs>
    </ResponsiveModal>
  )
}
