import { AntTooltip, Icons, defaultTheme, Text } from '@agro-club/agroclub-shared'
import { VaidationRecord } from 'modules/domain/deal/types'
import { FC } from 'react'
import styled from 'styled-components'

const HoverWrapper = styled.div`
  display: flex;
  align-items: center;
  &:hover {
    path {
      fill: ${defaultTheme.color.accentOrange800};
    }
  }
`

const IconWrapper = styled.div<{ $iconColor?: string; $hoverColor?: string; size?: 'large' | 'small' }>`
  display: inline;
  margin-right: 4px;
  width: ${({ size }) => (size === 'large' ? '24px' : '16px')};
  height: ${({ size }) => (size === 'large' ? '24px' : '16px')};

  svg {
    width: inherit;
    height: inherit;

    path {
      fill: ${({ $iconColor }) => $iconColor};
    }
  }
`
const ValidationLine = styled.div`
  display: flex;
  align-items: center;
`
const HintContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`
const PERMS_KEY = 'perms'

type Props = {
  validationInfo: Record<string, VaidationRecord>
}

export const DealValidationErrors: FC<Props> = ({ validationInfo }) => {
  const messages: JSX.Element[] = []
  Object.entries(validationInfo).forEach(([key, val]) => {
    if (key == PERMS_KEY && !val.missing) {
      return
    }
    messages.push(
      <ValidationLine key={key}>
        {val.missing ? (
          <IconWrapper size="small" $iconColor={defaultTheme.color.secondary200}>
            <Icons.IconClose />
          </IconWrapper>
        ) : (
          <IconWrapper size="small" $iconColor={defaultTheme.color.accentApprove600}>
            <Icons.IconArrowCheck />
          </IconWrapper>
        )}
        <Text as="span" typography="bodyMedium">
          {val.text}
        </Text>
      </ValidationLine>,
    )
  })
  const hintContent = <HintContent>{messages}</HintContent>
  return (
    <HoverWrapper>
      <AntTooltip tooltipContent={hintContent} variant="secondary">
        <IconWrapper size="large" $iconColor={defaultTheme.color.accentOrange600}>
          <Icons.IconExclamationCircle />
        </IconWrapper>
      </AntTooltip>
    </HoverWrapper>
  )
}
