import styled from 'styled-components'
import { AntdTable, Typography } from '@agro-club/agroclub-shared'
import { EXPANDABLE_COLUMN_WIDTH } from 'modules/constants'

export const FullScreenWrapper = styled.div<{ minHeight: string }>`
  .ant-table-body {
    min-height: ${({ minHeight }) => minHeight};
  }

  .ant-table-pagination,
  .ant-pagination {
    font-size: 18px;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .ant-pagination-total-text {
    ${Typography.bodyLarge}
    padding-top: 4px;
  }
  .ant-table-body tr.ant-table-row--bold td {
    // we want make sure that we have bold font here, and any internal fonts for common table don't override this
    font-weight: 600 !important;
  }
`

export const ExpandableAntdTable = styled(AntdTable)`
  > div.ant-spin-nested-loading > div.ant-spin-container > div.ant-table > .ant-table-container {
    > .ant-table-body > table > tbody > tr.ant-table-expanded-row {
      > td {
        background-color: ${({ theme }) => theme.color.backgroundQuarternary};
        div.ant-table {
          box-shadow: none;

          background-color: transparent;
          .ant-table-thead th {
            background-color: transparent;
          }
          .ant-table-row:last-child td {
            border-bottom-color: transparent;
          }
        }
      }
    }
  }
  col.ant-table-expand-icon-col {
    width: ${EXPANDABLE_COLUMN_WIDTH}px;
  }
  button.ant-table-row-expand-icon {
    border-radius: 4px;
  }
`
