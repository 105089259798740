import { BidContract, BidTypes } from 'modules/domain/bid/types'
import { KeyValue } from 'views/components/KeyValue/KeyValue'
import { Card, GridItem } from 'views/components/Card/Card'
import useFormatDate from 'hooks/useFormatDate'
import { useTranslation } from 'react-i18next'
import { FC } from 'react'

export const ContractPaymentDetail: FC<{ contract: BidContract }> = ({ contract }) => {
  const { t } = useTranslation('bid')
  const formatDate = useFormatDate()

  const isPurchase = contract.bid_type === BidTypes.purchase
  const items = [
    contract.payment_due_date && {
      label: t(`contracts.${isPurchase ? 'purchasePaymentDate' : 'sellerPaymentDate'}`),
      value: formatDate(contract.payment_due_date),
    },

    contract.payment_type_description && {
      label: t(`contracts.${isPurchase ? 'purchaseFormPayment' : 'sellerFormPayment'}`),
      value: contract.payment_type_description,
    },
  ].filter(Boolean) as GridItem[]

  return (
    <Card.Container bordered disableShadow>
      <Card.Grid cols={2}>
        {items.map((item, index) => {
          return (
            <KeyValue key={index} label={item.label}>
              {item.value}
            </KeyValue>
          )
        })}
      </Card.Grid>
    </Card.Container>
  )
}
