import { IconUser, Input, Typography, defaultTheme } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

import { box } from 'views/styled/common'
import { ARouterLink } from 'views/components/Analytics'

export const MembersCard = styled.div`
  ${box};
  width: 566px;
  height: min-content;
  flex-shrink: 0;
  padding: 16px 18px;
  margin-right: 32px;
`

export const CardHeader = styled.div`
  ${Typography.titleH4}
  margin-bottom: 18px;
`

export const MembersList = styled.div`
  border: 1px solid ${({ theme }) => theme.color.secondary50};
  border-radius: 8px;
`

export const MemberContainer = styled.div`
  display: flex;
  padding: 12px;

  & + & {
    border-top: 1px solid ${({ theme }) => theme.color.secondary50};
  }
`

export const MemberIcon = styled(IconUser)`
  width: 44px;
  height: 44px;
  fill: ${({ theme }) => theme.color.secondary100};
  margin-right: 12px;
`

export const MemberInfoContainer = styled.div`
  display: flex;
  flex-direction: column;

  & > *:not(:last-child) {
    margin-bottom: 4px;
  }
`

export const MemberName = styled(ARouterLink)`
  ${Typography.bodyLarge}
  color: ${({ theme }) => theme.color.primary600};
  cursor: pointer;

  :hover {
    color: ${defaultTheme.color.primary800};
    text-decoration: none;
  }
`

export const MemberPhone = styled.div`
  ${Typography.bodyLarge}
  color: ${defaultTheme.color.textPrimary900};
`

export const ProfileRole = MemberPhone

export const EmptyPlanCardContainer = styled.div<{ isInactiveMonth?: boolean }>`
  ${box}
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 314px;
  height: 132px;
  border: 1px solid ${defaultTheme.color.secondary50};
  box-shadow: none;
  padding: 24px;
  cursor: ${({ isInactiveMonth }) => (isInactiveMonth ? 'default' : 'pointer')};

  ${({ isInactiveMonth }) =>
    !isInactiveMonth
      ? `&:hover {
      background-color: ${defaultTheme.color.primary8a};
    }`
      : ''}
`

export const EmptyPlanTitle = styled.div<{ isInactiveMonth?: boolean }>`
  ${Typography.bodyLarge}
  color: ${({ isInactiveMonth }) =>
    isInactiveMonth ? defaultTheme.color.textSecondary60a : defaultTheme.color.textPrimary900};
  :first-letter {
    text-transform: capitalize;
  }
`

export const EmptyPlanDetail = styled.div<{ withVolume?: boolean; isInactiveMonth?: boolean }>`
  ${({ withVolume }) => (withVolume ? Typography.bodyLarge : Typography.bodyMedium)}
  width: ${({ isInactiveMonth }) => (isInactiveMonth ? '100%' : '162px')};
  color: ${defaultTheme.color.textSecondary60a};
`
export const PopupBodyContainer = styled.div`
  display: flex;
  align-items: center;
`

export const PopupBodyLabel = styled.div`
  ${Typography.bodyLarge}
  color: ${defaultTheme.color.textSecondary60a};
  margin-right: 26px;
`

export const PopupInput = styled(Input)`
  width: 188px;
  input {
    height: 32px;
  }
`
