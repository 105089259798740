import { BodySizes } from '@agro-club/agroclub-shared'
import { generatePath } from 'react-router-dom'
import DealRoutes from '../../pages/Deal/routes'
import { FC, ReactNode } from 'react'

import { ARouterLink } from '../Analytics'

type Props = {
  dealId: string
  target?: string
  bodysize?: BodySizes
  children?: ReactNode
}

export const DealLink: FC<Props> = ({ dealId, bodysize, target, children }) => {
  return (
    <ARouterLink
      eventName="deal"
      itemId={dealId}
      bodysize={bodysize}
      to={generatePath(DealRoutes.Details, { id: dealId })}
      target={target}
    >
      {children || `ID ${dealId}`}
    </ARouterLink>
  )
}
