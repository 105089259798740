export enum AnalyticsPages {
  BIDS = 'bids',
  BID_DETAIL = 'bidDetail',
  BID_EDIT = 'bidEdit',
  POTENTIAL_BID_DETAIL = 'potentialBidDetail',
}

export const AnalyticsPlaces = {
  LIST: 'BidList',
  POTENTIAL_DETAIL: 'PotentialBidDetail',
  DETAIL: 'BidDetail',
  EDIT: 'BidEdit',
  TASK_MODAL: 'TaskModal',
  ARCHIVE_MODAL: 'ArchiveModal',
  EDIT_MODAL: 'EditModal',
  TAXES_MODAL: 'TaxesModal',
  CREATE_MODAL: 'CreateModal',
  DELETE_MODAL: 'DeleteModal',
  POTENTIAL_EDIT_MODAL: 'PotentialEditModal',
}
