import { defaultTheme, Icons } from '@agro-club/agroclub-shared'
import { DASH } from 'modules/constants'
import { refetchFunc, usePagination } from 'modules/domain/common/hooks'
import { CompanyShort } from 'modules/domain/company/types'
import { WarehouseMovement } from 'modules/domain/warehouses/types'
import { formatDailyPriceInTon } from 'modules/utils/numbers/formatDailyPriceInTon'
import { formatQuantity } from 'modules/utils/numbers/formatQuantity'
import { formatTarif } from 'modules/utils/numbers/formatTarif'
import { FC, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import { generatePath } from 'react-router'
import styled from 'styled-components'
import { AButton } from 'views/components/Analytics'
import { Card } from 'views/components/Card/Card'
import { CompanyLink } from 'views/components/CompanyLink/CompanyLink'
import { KeyValue } from 'views/components/KeyValue/KeyValue'
import { Pagination } from 'views/components/Pagination/Pagination'
import WarehousesRoutes from 'views/pages/Warehouses/routes'
import APhone from 'views/ui/Phone/APhone'
import { useDealContext } from '../../DealContext'
import { WhMovementEditModal } from '../../WhMovementEditModal'

const Button = styled(AButton)`
  position: absolute;
  top: 16px;
  right: 16px;
`

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

const PaginationWrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  justify-content: ${({ isMobile }) => (isMobile ? 'center' : 'normal')};
`

type WhMovementProps = {
  whMovement: WarehouseMovement
  warehouseRefetch: refetchFunc
  isMobile: boolean
}

const WhMovement: FC<WhMovementProps> = ({ whMovement, warehouseRefetch, isMobile }) => {
  const { t } = useTranslation('warehouses')
  const [isOpen, setIsOpen] = useState(false)
  const companyPersonalNumber = `${t('common:inn')}: ${
    whMovement.warehouse.owner.profile?.company.inn || t('common:notSpecified')
  }`

  const warehouseInfo = isMobile ? (
    <>
      <CompanyLink company={whMovement.warehouse.owner.profile?.company as CompanyShort} />
      <div>{companyPersonalNumber}</div>
      <APhone phone={whMovement.warehouse.owner.phone} />
      <div>{whMovement.warehouse.address.address}</div>
    </>
  ) : (
    <>
      <div>
        <CompanyLink company={whMovement.warehouse.owner.profile?.company as CompanyShort} />
        {' / '}
        {companyPersonalNumber}
        {' / '}
        <APhone phone={whMovement.warehouse.owner.phone} />
      </div>
      <div>{whMovement.warehouse.address.address}</div>
    </>
  )

  return (
    <Card.GapableContent>
      <HeaderWrapper>
        <Card.Title>{whMovement.warehouse.title}</Card.Title>
        {warehouseInfo}
      </HeaderWrapper>
      <Card.Container bordered>
        <Card.Grid cols={isMobile ? 2 : 5} rowGap={isMobile ? 8 : 16} maxColumnWidth="1fr">
          <KeyValue label={t('detail.unloadDate')}>
            {whMovement.documents_request_expected_complete_date || DASH}
          </KeyValue>
          <KeyValue label={t('common:quantity')}>
            {whMovement.quantity ? formatQuantity(whMovement.quantity) : DASH}
          </KeyValue>
          <KeyValue label={t('detail.storage')}>
            {whMovement.storage_price ? formatDailyPriceInTon(whMovement.storage_price) : DASH}
          </KeyValue>
          <KeyValue label={t('detail.handlingPrice')}>
            {whMovement.handling_price ? formatTarif(whMovement.handling_price) : DASH}
          </KeyValue>
          <KeyValue label={t('detail.transshipment')}>
            {whMovement.transshipment_price ? formatTarif(whMovement.transshipment_price) : DASH}
          </KeyValue>
        </Card.Grid>
        <Card.EditButton onClick={() => setIsOpen(true)} />
        {isOpen && (
          <WhMovementEditModal
            whMovement={whMovement}
            whMovementRefetch={warehouseRefetch}
            onClose={() => setIsOpen(false)}
          />
        )}
      </Card.Container>
    </Card.GapableContent>
  )
}

export const WhMovementsBlock = () => {
  const { deal, whMovements } = useDealContext()

  const whMovementsList = useMemo(() => {
    if (!deal.has_warehouse_movements) {
      return []
    }

    return deal.purchase_bid.is_for_warehouse ? whMovements.listWithoutLast : whMovements.list
  }, [deal.has_warehouse_movements, deal.purchase_bid.is_for_warehouse, whMovements.list, whMovements.listWithoutLast])

  const [whMovementsCurrentPage, setWhMovementsCurrentPage, whMovementsCurrentItems] = usePagination(whMovementsList, 1)

  const isMobile = useMediaQuery({
    query: `(max-width: ${defaultTheme.queries.mobile})`,
  })

  if (!whMovementsList.length) {
    return null
  }

  return (
    <Card.Container bordered borderRadius={isMobile ? 'large' : 'normal'}>
      <Card.GapableContent>
        <div>
          {whMovementsCurrentItems.map((item) => (
            <WhMovement key={item.id} isMobile={isMobile} whMovement={item} warehouseRefetch={whMovements.refetch} />
          ))}
          <PaginationWrapper isMobile={isMobile}>
            <Pagination
              size={isMobile ? 'default' : 'small'}
              total={whMovementsList.length}
              currPage={whMovementsCurrentPage}
              setCurrPage={setWhMovementsCurrentPage}
              pageSize={1}
              showLessItems={isMobile}
            />
          </PaginationWrapper>
        </div>
      </Card.GapableContent>
      <Button
        id="warehouse"
        intent="white"
        Icon={Icons.IconOpen}
        target="_blank"
        to={generatePath(WarehousesRoutes.Details, { id: whMovementsList[0].warehouse.id })}
      />
    </Card.Container>
  )
}
