import { QuantityAndPrice, AddressAndUser, Wrapper, Address, Header, Dates, Tags } from './styled'
import { RemainingQuantity } from 'views/pages/Deal/Rematch/RematchModal/Columns/Cards/styled'
import { ContractDeliveryPeriod } from 'views/pages/BidContracts/BidContractDetail/ContractDeliveryPeriod'
import { ContractStatusTag } from 'views/pages/BidContracts/BidContractDetail/ContractStatusTag'
import { BorderedItem } from 'views/components/BorderedItem/BorderedItem'
import { formatQuantity } from 'modules/utils/numbers/formatQuantity'
import { AButton, ARouterLink } from 'views/components/Analytics'
import BidContractsRoutes from 'views/pages/BidContracts/routes'
import { formatTarif } from 'modules/utils/numbers/formatTarif'
import { BidContract } from 'modules/domain/bid/types'
import { AntTag, Icons } from '@agro-club/agroclub-shared'
import CompanyRoutes from 'views/pages/Company/routes'
import useFormatDate from 'hooks/useFormatDate'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router'
import { FC } from 'react'
import { CommonWrapper } from 'views/components/Card/Wrapper'

type Props = {
  contract: BidContract
}

export const ContractItem: FC<Props> = ({ contract }) => {
  const formatDate = useFormatDate()
  const { t } = useTranslation('bid')

  return (
    <BorderedItem>
      <Wrapper>
        <Header>
          <Tags>
            <AntTag color="blue">№ {contract.number}</AntTag>
            {contract.signed_at && (
              <AntTag color="white">
                {t('contracts.signed')} {formatDate(contract.signed_at)}
              </AntTag>
            )}
            <ContractStatusTag status={contract.status} />
          </Tags>
          <AButton
            target="_blank"
            id="link"
            intent="white"
            Icon={Icons.IconOpen}
            to={generatePath(BidContractsRoutes.Details, { id: contract.id })}
          />
        </Header>
        <CommonWrapper>
          <QuantityAndPrice>
            <div>
              {`${formatQuantity(contract.remaining_quantity)}, ${contract.price_type} ${formatTarif(contract.price)}`}
            </div>
            <RemainingQuantity>
              {`${t('contracts.initialVolume')}: ${formatQuantity(contract.quantity)}`}
            </RemainingQuantity>
          </QuantityAndPrice>
          <AddressAndUser>
            <ARouterLink
              eventName="company"
              itemId={String(contract.company.id)}
              to={generatePath(CompanyRoutes.Details, { id: contract.company.id })}
            >
              {contract.company.name}
            </ARouterLink>
            <Address>{contract.address.short_address}</Address>
          </AddressAndUser>
          <Dates>
            <div>
              {t('contracts.payment')} {formatDate(contract.payment_due_date)}
            </div>
            <ContractDeliveryPeriod
              shipment_due_date={contract.shipment_due_date}
              shipment_from_date={contract.shipment_from_date}
              label={t('contracts.shipment')}
            />
          </Dates>
        </CommonWrapper>
      </Wrapper>
    </BorderedItem>
  )
}
