import { FC, ReactNode } from 'react'
import { RequestCard } from './RequestCard'
import { NoDataCard } from 'views/components/Board/NoDataCard'
import { WrapperCards } from 'views/components/Board/styled'
import { LogisticRequest } from 'modules/domain/logisticsKR/types'
import { RequestType } from '../../../../types'
import { Panel, Progress } from '@agro-club/agroclub-shared'
import { AntTagColor } from '@agro-club/agroclub-shared/dist/cjs/ui/misc/AntTag/types'
import { CollapseHeaderWithCount } from 'views/components/CollapseHeaderWithCount/CollapseHeaderWithCount'
import { PreloadCard } from 'views/components/Board/Preload'

type Props = {
  requests: LogisticRequest[]
  activeRequestId: string | undefined
  onSelectedChanged: (request: LogisticRequest) => void
  key: RequestType
  title: ReactNode
  counter: number
  progress: Progress
  tagColor?: AntTagColor
}

export const Requests: FC<Props> = ({
  requests,
  activeRequestId,
  onSelectedChanged,
  counter,
  tagColor,
  progress,
  title,
  ...props
}) => {
  const preloadWrapper = progress === Progress.WORK && <PreloadCard />

  return (
    <Panel
      {...props}
      header={<CollapseHeaderWithCount progress={progress} tagColor={tagColor} counter={counter} title={title} />}
    >
      <WrapperCards>
        {!preloadWrapper &&
          requests.map((request) => (
            <RequestCard
              onSelectedChanged={onSelectedChanged}
              activeRequestId={activeRequestId}
              key={request.id}
              request={request}
            />
          ))}
        {preloadWrapper}
        {!requests.length && !preloadWrapper && <NoDataCard />}
      </WrapperCards>
    </Panel>
  )
}
