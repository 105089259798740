import { defaultListRequestParams } from 'views/components/TableFilters/data'
import { useHelmet } from '@agro-club/agroclub-shared'
import { ListRequestParams } from 'modules/domain/types'
import * as Layout from 'views/layouts/NewLayout'
import * as Header from 'views/ui/Header/Header'
import { useTranslation } from 'react-i18next'
import { endpoints } from 'modules/endpoints'
import { useFilters } from 'hooks/useFilter'
import useColumns from './useColumns'
import { FC, useState } from 'react'

import { CustomersKRFilters } from './CustomersKRFilters'
import APageWrapper from 'views/components/PageWrapper/APageWrapper'
import { AnalyticsPages, AnalyticsPlaces } from '../../analyticsPlaces'
import ATableFullPage from 'views/components/Table/ATableFullPage'

const CustomersKRList: FC = () => {
  const { t } = useTranslation(['logisticsKR'])
  useHelmet({ title: t('customers.heading') })
  const [listRequestParams, setListRequestParams] = useState<ListRequestParams>({
    ...defaultListRequestParams,
  })
  const { isFiltersLoaded, updateFilterState } = useFilters({
    listRequestParams,
    setListRequestParams,
  })
  const columns = useColumns()
  // const [isModalOpen, setIsPopUpOpen] = useState<boolean>(false)

  return (
    <APageWrapper page={AnalyticsPages.CUSTOMERS.LIST} place={AnalyticsPlaces.CUSTOMERS.LIST}>
      <Layout.WrapperContent>
        <Layout.Header>
          <Layout.TopHeader>
            <Header.Breadcrumbs routes={[{ breadcrumbName: t('customers.heading') }]} />
            {/*             <AButton id="add" Icon={Icons.IconAdd} intent="approve" size="medium" onClick={() => setIsPopUpOpen(true)}>
              {t('common:add')}
            </AButton> */}
          </Layout.TopHeader>
          <Layout.PageName>{t('customers.heading')}</Layout.PageName>
          {/*           {isModalOpen && (
            <CustomerAdd setIsOpen={setIsPopUpOpen} onSucess={() => setListRequestParams({ ...listRequestParams })} />
          )} */}
          <CustomersKRFilters
            setListRequestParams={setListRequestParams}
            updateFilterState={updateFilterState}
            listRequestParams={listRequestParams}
            isFiltersLoaded={isFiltersLoaded}
          />
        </Layout.Header>
        <ATableFullPage
          listParamsUpdated={setListRequestParams}
          listRequestParams={listRequestParams}
          isFiltersLoaded={isFiltersLoaded}
          dataEndpoint={endpoints.LogisticsKR.customers()}
          columns={columns}
          rowHoverGray
        />
      </Layout.WrapperContent>
    </APageWrapper>
  )
}

export default CustomersKRList
