import { convertSortingToAntDFormat } from 'modules/domain/common/tableUtils'
import { TableTextLink } from 'views/ui/TableTextLink/TableTextLink'
import { ListRequestParams } from 'modules/domain/types'
import { AntTag } from '@agro-club/agroclub-shared'
import useFormatDate from 'hooks/useFormatDate'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router'
import { ColumnData } from 'modules/types'
import { SampleStatus } from '../types'
import SamplesRoutes from '../routes'
import { useMemo } from 'react'
import UserRoutes from 'views/pages/User/routes'

export const useColumns = (sortParam: ListRequestParams['sort']): ColumnData[] => {
  const { t } = useTranslation('samples')
  const formatDate = useFormatDate()
  return useMemo(
    () => [
      {
        title: t('list.id'),
        width: 120,
        dataIndex: 'id',
        sorter: true,
        key: 'id',
        sortOrder: convertSortingToAntDFormat('id', String(sortParam)),
      },
      {
        title: t('list.status'),
        width: 136,
        dataIndex: 'status',
        render: (status) => (
          <>
            {status === SampleStatus.in_progress ? (
              <AntTag color="green">{t('inProgress')}</AntTag>
            ) : (
              <AntTag color="grey">{t('archived')}</AntTag>
            )}
          </>
        ),
      },
      {
        title: t('list.name'),
        width: 476,
        dataIndex: 'title',
        key: 'title',
        align: 'left',
        render: (title, row) => (
          <TableTextLink id="title" to={generatePath(SamplesRoutes.Details, { id: row.id })}>
            {title}
          </TableTextLink>
        ),
      },
      {
        title: t('list.forWhom'),
        width: 360,
        dataIndex: 'owner',
        render: (owner) => (
          <TableTextLink id="title" to={generatePath(UserRoutes.Details, { id: owner.id })}>
            {owner.full_name}
          </TableTextLink>
        ),
      },
      {
        title: t('list.crop'),
        width: 220,
        dataIndex: 'product',
        render: (product) => product.title,
      },
      {
        title: t('list.dateCreation'),
        width: 150,
        dataIndex: 'created',
        sorter: true,
        key: 'created',
        sortOrder: convertSortingToAntDFormat('created', String(sortParam)),
        render: (created) => formatDate(created),
      },
      {
        title: t('list.validity'),
        width: 150,

        dataIndex: 'expire_at',
        sorter: true,
        key: 'expire_at',
        sortOrder: convertSortingToAntDFormat('expire_at', String(sortParam)),
        render: (expire_at) => formatDate(expire_at),
      },
      {
        title: t('list.team'),
        width: 152,
        dataIndex: 'team',
      },
    ],
    [t, formatDate, sortParam],
  )
}
