import { FC } from 'react'

import { useAnalyticsChangeHandler } from 'analytics/hooks'
import InternalTableFullPage, { InternalTableFullPageProps } from './InternalTableFullPage'

const ATableFullPage: FC<InternalTableFullPageProps> = (props) => {
  const handlePageChange = useAnalyticsChangeHandler('tablePage')

  return <InternalTableFullPage {...props} onPageChange={handlePageChange()} />
}

export default ATableFullPage
