import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@agro-club/agroclub-shared'

import { DatePeriodFormatted, DatePeriod, containsFilledDatePeriod } from '../DatePeriodFormatted'

type Props = {
  data: DatePeriod
}

export const DeliveryPeriodData: FC<Props> = ({ data }) => {
  const { t } = useTranslation('bid')
  if (!containsFilledDatePeriod(data)) {
    return null
  }

  return (
    <Text style={{ display: 'flex' }}>
      {t('bid:deliveryPeriodShort')}
      : &nbsp;
      <DatePeriodFormatted period={data} wrap={false} />
    </Text>
  )
}
