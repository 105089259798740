export const saveAccessToken = (accessToken: string): void => localStorage.setItem('access_token', accessToken)
export const getAccessToken = (): string | null => localStorage.getItem('access_token')

export const saveRefreshToken = (refreshToken: string): void => localStorage.setItem('refresh_token', refreshToken)
export const getRefreshToken = (): string | null => localStorage.getItem('refresh_token')

export const clearToken = (): void => {
  localStorage.removeItem('access_token')
  localStorage.removeItem('refresh_token')
}
