import { FC } from 'react'
import { ModalProps } from 'views/pages/User/UserDetail/UserRightPanel/UserInfo/types'
import { useTranslation } from 'react-i18next'
import { useAProgress } from 'hooks/useAProgress'
import { apiClient } from 'modules/utils/httpClient'
import ASharedModal from 'views/components/SharedModal/ASharedModal'
import { AnalyticsPlaces } from 'views/pages/User/analyticsPlaces'
import { SharedModalContent, SharedModalTitle } from 'views/components/SharedModal'
import ADefaultModalFooter from 'views/components/DefaultModalFooter/ADefaultModalFooter'
import { endpoints } from 'modules/endpoints'
import { useNotification } from 'hooks/useNotification'
import { Progress } from '@agro-club/agroclub-shared'

export const KycConfirmModal: FC<ModalProps> = ({ onClose, refetch, userId }) => {
  const { t } = useTranslation('user')
  const notify = useNotification()
  const [verifyKycProgress, verifyKyc] = useAProgress(() => apiClient.post(endpoints.userVerifyKyc(userId)), {
    eventName: 'kycVerify',
    onSuccess: async () => {
      await refetch(true)
      onClose()
      notify(Progress.SUCCESS, { title: t('form.notifyEditSuccess') })
    },
  })

  return (
    <ASharedModal id={AnalyticsPlaces.KYC_MODAL} onClose={onClose} size="small">
      <SharedModalTitle>{t('change_status')}</SharedModalTitle>
      <SharedModalContent>{t('doYouReallyWantKyc')}</SharedModalContent>
      <ADefaultModalFooter onClose={onClose} onConfirm={verifyKyc} progress={verifyKycProgress} />
    </ASharedModal>
  )
}
