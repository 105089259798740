import { IconBin } from '@agro-club/agroclub-shared'
import styled from 'styled-components'
import { AButton } from 'views/components/Analytics'
import { FC } from 'react'

const DeleteButton = styled(AButton)`
  width: 40px;
  height: 40px;
  padding: 6px 8px;
`

export const BinButton: FC<Partial<{ title: string; onClick: () => void }>> = (props) => {
  return <DeleteButton id="delete" size="small" intent="white" Icon={IconBin} {...props} />
}
