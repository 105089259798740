import { SharedModalContent, SharedModalTitle } from 'views/components/SharedModal/styled'
import ASharedModal from 'views/components/SharedModal/ASharedModal'
import { Progress, Spinner } from '@agro-club/agroclub-shared'
import { useSingleEntity } from 'modules/domain/common/hooks'
import { SpinnerWrapper } from 'views/styled/common'
import useFormatDate from 'hooks/useFormatDate'
import { UserData } from '../List/WhatsAppList'
import { useTranslation } from 'react-i18next'
import { endpoints } from 'modules/endpoints'
import { MessageItem } from './MessageItem'
import { Wrapper } from './styled'
import { FC } from 'react'

type Props = {
  close: () => void
  data: UserData
}

export enum Status {
  undelivered = 'undelivered',
  inProgress = 'in-progress',
  received = 'received',
  failed = 'failed',
  read = 'read',
}

export enum Direction {
  outboundApi = 'outbound-api',
  inbound = 'inbound',
}

export type DetailMessage = {
  record_link: string | null
  client_company_id: string
  coordinator_name: string
  coordinator_id: number
  client_company: string
  direction: Direction
  client_phone: string
  client_name: string
  client_id: number
  date_sent: string
  status: Status
  body: string
  sid: string
  id: number
}

export const CommunicationDetails: FC<Props> = ({ close, data }) => {
  const { t } = useTranslation('whatsApp')
  const formatDate = useFormatDate()
  const [progress, messages] = useSingleEntity<DetailMessage[]>(
    endpoints.whatsAppDetailTwilio(data.coordinator_id, encodeURIComponent(data.client_phone), data.date_sent),
  )

  return (
    <ASharedModal onClose={close} id="CommunicationsDetailsModal">
      <SharedModalTitle>{data.client_company || t('modal.noName')}</SharedModalTitle>
      <SharedModalContent>
        <div>
          {formatDate(data.date_sent)}, {data.coordinator_name}
        </div>
        <Wrapper>
          {progress === Progress.WORK ? (
            <SpinnerWrapper>
              <Spinner />
            </SpinnerWrapper>
          ) : (
            <>
              {messages?.map((item) => {
                return <MessageItem key={item.id} message={item} />
              })}
            </>
          )}
        </Wrapper>
      </SharedModalContent>
    </ASharedModal>
  )
}
