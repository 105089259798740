import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import { endpoints } from 'modules/endpoints'
import { apiClient } from 'modules/utils/httpClient'
import { FormSelectDadata } from 'views/components/FormSelectDadata/FormSelectDadata'
import { Carrier } from 'modules/domain/logisticsKR/types'
import _ from 'lodash'
import { SharedModalContent, SharedModalInputs, SharedModalTitle } from 'views/components/SharedModal/styled'
import { CarrierFormControls } from '../../components/CarrierFormControls/CarrierFormControls'
import { CarrierType } from '../../types'
import ASharedModal from 'views/components/SharedModal/ASharedModal'
import { AnalyticsPlaces } from '../../analyticsPlaces'
import ADefaultModalFooter from 'views/components/DefaultModalFooter/ADefaultModalFooter'
import { useAFormHandler } from 'analytics/hooks'

type FormFields = {
  inn: string
  name: string
  phone: string
  contact_name?: string
  is_in_charter: boolean
  has_vat: boolean
  working_regions: string[]
  favorite_regions: string[]
  carrier_type: CarrierType
}

type Props = {
  carrier: Carrier
  onClose: () => void
  onSuccess: () => void
}

export const CarrierEditModal: FC<Props> = ({ onClose, onSuccess, carrier }) => {
  const { t } = useTranslation('logisticsKR')
  const { formProgress, formHandler } = useAFormHandler('editCarrier', { id: carrier.id })

  const formik = useFormik<FormFields>({
    initialValues: {
      inn: carrier.inn,
      name: carrier.name,
      phone: carrier.phone,
      contact_name: carrier.contact_name,
      is_in_charter: carrier.is_in_charter,
      has_vat: carrier.has_vat,
      working_regions: carrier.working_regions?.map((region) => String(region.id)) ?? [],
      favorite_regions: carrier.favorite_regions?.map((region) => String(region.id)) ?? [],
      carrier_type: carrier.carrier_type,
    },
    onSubmit: formHandler(async () => await apiClient.put(endpoints.LogisticsKR.carriers(carrier.id), formik.values), {
      onSuccess: async () => {
        await onSuccess()
        onClose()
      },
      onError: (error) => {
        const { errors } = error
        formik.setErrors(errors)
      },
    }),
  })

  return (
    <ASharedModal id={AnalyticsPlaces.CARRIERS.EDIT_MODAL} size="medium" onClose={onClose}>
      <SharedModalTitle>{t('carriers.form.editTitle')}</SharedModalTitle>
      <SharedModalContent>
        <SharedModalInputs>
          <FormSelectDadata
            initialValue={{ value: carrier.id, label: _.truncate(carrier.name, { length: 30 }), dto: carrier }}
            disabled
            required
          />
          <CarrierFormControls formik={formik} />
        </SharedModalInputs>
      </SharedModalContent>
      <ADefaultModalFooter
        onClose={onClose}
        progress={formProgress}
        onConfirm={() => formik.submitForm()}
        confirmButtonText={t('common:save')}
      />
    </ASharedModal>
  )
}
