import { StatusBlockWrapper } from 'views/styled/common'
import { useTranslation } from 'react-i18next'
import { StatusesRightArrow } from 'views/components/StatusesRightArrow/StatusesRightArrow'
import { AButton } from 'views/components/Analytics'
import { DealContract } from 'modules/domain/deal/types'
import { refetchFunc } from 'modules/domain/common/hooks'
import { CompletedStatus } from 'views/components/CompletedStatus/CompletedStatus'
import { useAProgress } from 'hooks/useAProgress'
import { apiClient } from 'modules/utils/httpClient'
import { endpoints } from 'modules/endpoints'
import { useParams } from 'react-router-dom'

type Props = {
  contract: DealContract
  onSuccess: refetchFunc
}

export const ChangeStatusControl = ({ contract, onSuccess }: Props) => {
  const { t } = useTranslation('contracts')
  const { id } = useParams()

  const [updateStatusProgress, updateStatus] = useAProgress(
    (status: string) =>
      apiClient.post(endpoints.dealContractChangeStatus(id), {
        new_status: status,
      }),
    {
      eventName: 'dealContractChangeStatuses',
      onSuccess: () => onSuccess(true),
    },
  )
  const nextStatus = contract.available_statuses[0]

  return (
    <>
      {nextStatus ? (
        <StatusBlockWrapper>
          <div>{t(`table.statuses.${contract.status}`)}</div>
          <StatusesRightArrow />
          <AButton
            id={nextStatus}
            intent="secondary"
            onClick={() => updateStatus(nextStatus)}
            progress={updateStatusProgress}
          >
            {t(`table.statuses.${nextStatus}`)}
          </AButton>
        </StatusBlockWrapper>
      ) : (
        <CompletedStatus />
      )}
    </>
  )
}
