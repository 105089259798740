import { FC } from 'react'
import { FormGroup } from '@agro-club/agroclub-shared'
import { endpoints } from 'modules/endpoints'
import FormSelectShared from 'views/components/FormSelectShared'
import { NumberInput } from 'views/ui/NumberInput/NumberInput'
import { useTranslation } from 'react-i18next'
import env from 'env'
import { SharedModalInputs } from 'views/components/SharedModal'
import { ShortField } from 'views/pages/LogisticsKR/components/styled'
import { FormikFieldsType } from 'modules/domain/types'

interface Props {
  formik: FormikFieldsType
}

export const CommonInfo: FC<Props> = ({ formik }) => {
  const { t } = useTranslation('logisticsKR')
  const currency = env.REACT_APP_CURRENCY_SYMBOL

  return (
    <>
      <SharedModalInputs>
        <FormSelectShared
          endpoint={endpoints.LogisticsKR.customers()}
          fieldName="customer"
          placeholder={t('list.createForm.placeholders.buyer')}
          label={t('list.createForm.labels.buyer')}
          formik={formik}
        />

        <FormSelectShared
          endpoint={endpoints.LogisticsKR.products()}
          fieldName="product"
          placeholder={t('list.createForm.placeholders.product')}
          label={t('list.createForm.labels.product')}
          getLabel={(dto) => dto.name}
          formik={formik}
        />

        <FormGroup error={formik.errors.quantity} label={t('list.createForm.labels.quantity')}>
          <ShortField>
            <NumberInput
              placeholder={t('list.createForm.placeholders.quantity')}
              {...formik.getFieldProps('quantity')}
              onChange={(value) => formik.setFieldValue('quantity', value)}
              invalid={!!formik.errors.quantity}
            />
          </ShortField>
        </FormGroup>

        <FormGroup
          error={formik.errors.price_logistics}
          label={t('list.createForm.labels.price_logistics', { currency })}
        >
          <ShortField>
            <NumberInput
              placeholder={t('list.createForm.placeholders.price_logistics')}
              {...formik.getFieldProps('price_logistics')}
              invalid={!!formik.errors.price_logistics}
              onChange={(value) => formik.setFieldValue('price_logistics', value)}
            />
          </ShortField>
        </FormGroup>

        <FormGroup error={formik.errors.distance} label={t('list.createForm.labels.distance')}>
          <ShortField>
            <NumberInput
              placeholder={t('list.createForm.placeholders.distance')}
              {...formik.getFieldProps('distance')}
              invalid={!!formik.errors.distance}
              onChange={(value) => formik.setFieldValue('distance', value)}
            />
          </ShortField>
        </FormGroup>
      </SharedModalInputs>
    </>
  )
}
