import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { ColumnData } from 'modules/types'
import { generatePath } from 'react-router-dom'
import Player from 'views/components/Player/Player'
import PlayerProvider from 'views/components/Player/PlayerProvider'
import { StyledIconPhoneIn, StyledIconPhoneMissed, StyledIconPhoneOut } from 'views/pages/Calls/CallsTable/styled'
import UserRoutes from 'views/pages/User/routes'
import { TableTextLink } from 'views/ui/TableTextLink/TableTextLink'

import useFormatDate from 'hooks/useFormatDate'
import { PHONE_TABLE_CELL_WIDTH } from 'modules/constants'
import CompanyRoutes from 'views/pages/Company/routes'
import APhone from 'views/ui/Phone/APhone'

const phoneIcon = {
  in: <StyledIconPhoneIn />,
  out: <StyledIconPhoneOut />,
  missed: <StyledIconPhoneMissed />,
}

export const useColumns = (): ColumnData[] => {
  const { t } = useTranslation('calls')
  const formatDate = useFormatDate()
  return useMemo(
    () => [
      {
        title: t('call_type'),
        width: 94,
        dataIndex: 'call_type',
        key: 'call_type',
        align: 'center',
        render: (call_type) => phoneIcon[call_type],
      },
      {
        title: t('call_start'),
        width: 140,
        dataIndex: 'call_start',
        key: 'call_start',
        render: (call_start) => formatDate(call_start, true),
      },
      {
        title: t('coordinator'),
        width: 212,
        dataIndex: 'coordinator_name',
        key: 'coordinator_name',
      },
      {
        title: t('user'),
        width: 376,
        dataIndex: 'client_name',
        key: 'client_name',
        render: (client_name, data) => (
          <>
            {data?.client_id && (
              <TableTextLink
                id="client"
                tableItemId={data.client_id}
                to={generatePath(UserRoutes.Details, { id: data.client_id })}
              >
                {client_name}
              </TableTextLink>
            )}
          </>
        ),
      },
      {
        title: t('phone'),
        width: PHONE_TABLE_CELL_WIDTH,
        dataIndex: 'client_phone',
        key: 'client_phone',
        render: (client_phone) => <APhone shortBtnTitle phone={client_phone} />,
      },
      {
        title: t('company'),
        width: 400,
        dataIndex: 'client_company',
        key: 'client_company',
        render: (client_company, row) => (
          <>
            {row.client_company_id && (
              <TableTextLink
                id="clientCompany"
                tableItemId={row.client_company_id}
                to={generatePath(CompanyRoutes.Details, { id: row.client_company_id })}
              >
                {client_company}
              </TableTextLink>
            )}
          </>
        ),
      },
      {
        title: t('record'),
        width: 230,
        dataIndex: 'record_link',
        key: 'record_link',
        render: (record_link, data) =>
          !!data?.conversation_duration && (
            <PlayerProvider>
              <Player file={record_link} duration={data.conversation_duration} itemId={data.id} />
            </PlayerProvider>
          ),
      },
    ],
    [t, formatDate],
  )
}
