import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { AnalyticsPlaces } from 'views/pages/User/analyticsPlaces'
import ADefaultModalFooter from 'views/components/DefaultModalFooter/ADefaultModalFooter'
import ASharedModal from 'views/components/SharedModal/ASharedModal'
import { SharedModalTitle, SharedModalContent } from 'views/components/SharedModal/styled'

import { endpoints } from 'modules/endpoints'
import { apiClient } from 'modules/utils/httpClient'

import { useAProgress } from 'hooks/useAProgress'
import { useNotificationProgress } from 'hooks/useNotificationProgress'
import { ModalProps } from '../../types'

export const SecurityConfirmModal: FC<ModalProps> = ({ onClose, refetch, userId }) => {
  const { t } = useTranslation('user')
  const [verifyBySecurityProgress, verifyBySecurity] = useAProgress(
    () => apiClient.post(endpoints.userVerifySecurity(userId)),
    {
      eventName: 'verifyBySecurity',
      onSuccess: async () => {
        await refetch(true)
        onClose()
      },
    },
  )

  useNotificationProgress(verifyBySecurityProgress, t('form.notifyEditSuccess'))

  return (
    <ASharedModal id={AnalyticsPlaces.VERIFY_SECURITY_MODAL} onClose={onClose} size="small">
      <SharedModalTitle>{t('change_status')}</SharedModalTitle>
      <SharedModalContent>{t('doYouReallyWantMakeUserVerified')}</SharedModalContent>
      <ADefaultModalFooter onClose={onClose} onConfirm={() => verifyBySecurity()} progress={verifyBySecurityProgress} />
    </ASharedModal>
  )
}
