import { FC } from 'react'

import { useAnalyticsChangeHandler } from 'analytics/hooks'
import { InternalDateRangePicker, InternalDateRangePickerProps } from './InternalDateRangePicker'

type Props = {
  id: string
  place?: string
} & InternalDateRangePickerProps

export const ADateRangePicker: FC<Props> = ({ value, id, onChange, place, ...otherProps }) => {
  const handleChange = useAnalyticsChangeHandler(id)

  const analyticsPayload = {
    value: { from: { startDate: value?.[0], endDate: value?.[1] } },
    place,
  }

  return <InternalDateRangePicker onChange={handleChange(onChange, analyticsPayload)} value={value} {...otherProps} />
}
