import { MobileDataList } from 'views/components/MobileComponents/MobileDataList'
import { ListRequestParams } from 'modules/domain/types'
import { Progress } from '@agro-club/agroclub-shared'
import { DispatchingListType } from '../../types'
import { CardDispatch } from './CardDispatch'
import { FC } from 'react'

type Props = {
  setListRequestParams: (params: ListRequestParams) => void
  listRequestParams: ListRequestParams
  data: DispatchingListType[]
  onSuccess: () => void
  datesArray?: string[]
  progress: Progress
  pageSize: number
  total: number
}

export const DispatchingMobileList: FC<Props> = ({
  setListRequestParams,
  listRequestParams,
  datesArray,
  onSuccess,
  progress,
  pageSize,
  total,
  data,
}) => {
  return (
    <MobileDataList
      setListRequestParams={setListRequestParams}
      listRequestParams={listRequestParams}
      dataLength={data.length}
      progress={progress}
      pageSize={pageSize}
      total={total}
    >
      {datesArray &&
        data.map((item) => {
          return <CardDispatch key={item.id} data={item} datesArray={datesArray} refetch={onSuccess} />
        })}
    </MobileDataList>
  )
}
