import { defaultTheme } from '@agro-club/agroclub-shared'
import styled from 'styled-components'
import { FormInput } from 'views/components/form/FormInput'

export const CalculatorInput = styled(FormInput).attrs({
  size: 'small',
})`
  input:disabled {
    color: ${defaultTheme.color.secondary500};
    cursor: pointer;
  }
`

export const CalculatorActions = styled.div`
  margin-top: 12px;
  display: flex;
  gap: 0 8px;
`

export const CalculatorRowWrapper = styled.div`
  display: grid;
  grid-template-columns: 252px auto;
  height: 100%;
  flex: 1;
`

export const CalculatorControls = styled.div`
  padding: 6px 14px;
  display: flex;
  gap: 0 12px;
  align-items: center;
  background: ${defaultTheme.color.primary8a};
  .ant-select:not(.ant-select-customize-input):not(.ant-select-disabled) .ant-select-selector {
    background: ${defaultTheme.color.backgroundSecondary};
  }
  align-content: space-between;
  div {
    display: flex;
    flex: 1;
  }
`
export const SelectWrapper = styled.div`
  max-width: 349px;
`
export const CalculatorTitleColumn = styled.div`
  display: flex;
  align-items: center;
  padding: 14px 12px;
`
