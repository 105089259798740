import { Team, TeamPlan, TeamsPlansByMembers } from 'modules/domain/teams/types'

export const getPlansByMembers = (plans: TeamPlan[], teamsMembers: Team[]) => {
  let plansByMembers: TeamsPlansByMembers[] = []

  for (let i = 0; i < teamsMembers.length; i++) {
    const plansByTeam = plans.find(({ team }) => team === teamsMembers[i].id)
    let plansWithMembersData: TeamsPlansByMembers | null = null

    if (plansByTeam) {
      plansWithMembersData = {
        teamName: teamsMembers[i].name,
        usersAmount: teamsMembers[i].members?.length,
        members: teamsMembers[i].members,
        ...plansByTeam,
      }
    } else {
      plansWithMembersData = {
        teamName: teamsMembers[i].name,
        usersAmount: teamsMembers[i].members?.length,
        members: teamsMembers[i].members,
        team: teamsMembers[i].id,
        volume: 0,
        completed: 0,
      }
    }

    if (plansWithMembersData !== null) {
      plansByMembers = [...plansByMembers, plansWithMembersData]
    }
  }

  return plansByMembers
}
