import { FC, useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'

import { format, parseISO } from 'date-fns'

import env from 'env'
import useDateFnsLocale from 'hooks/useDateFnsLocale'
import useFormatNumber from 'hooks/useFormatNumber'
import { TeamsFullPlansList } from 'modules/domain/teams/types'
import { getEditableStatus } from 'views/components/PlanMonthCard/helpers'
import { CardHeader, PlanCardContainer } from 'views/components/PlanMonthCard/styled'
import { TeamsProgress } from 'views/components/TeamsProgress/TeamsProgress'
import { EmptyPlanDetail, EmptyPlanTitle } from 'views/pages/Teams/TeamDetail/styled'
import { PopupBody, TeamPlanEmptyCard } from 'views/pages/Teams/TeamDetail/TeamPlanEmptyCard'
import { SharedModalContent, SharedModalTitle } from '../SharedModal'
import ASharedModal from '../SharedModal/ASharedModal'
import ADefaultModalFooter from '../DefaultModalFooter/ADefaultModalFooter'
import { Progress } from '@agro-club/agroclub-shared'
import { useAnalyticsClickHandler } from 'analytics/hooks'

const currency = env.REACT_APP_CURRENCY_SYMBOL

type Props = {
  plan: TeamsFullPlansList
  setProgress?: Progress
  editProgress?: Progress
  setPlan: (volume: number, schedule_date: string) => void
  editPlan: (volume: number, id: number) => void
}

export const PlanMonthCard: FC<Props> = ({ plan, setProgress, editProgress, setPlan, editPlan }) => {
  const handleClick = useAnalyticsClickHandler('month')
  const [value, setValue] = useState(0)
  const [modalIsOpen, setModalIsOpen] = useState(false)

  useEffect(() => {
    setValue(plan.volume)
  }, [plan.volume])

  const { t } = useTranslation('teams')
  const formatNumber = useFormatNumber()

  const locale = useDateFnsLocale()
  const today = new Date()
  const currentYear = today.getFullYear()
  const parsedDate = parseISO(plan.schedule_date)
  const monthName = format(parsedDate, 'LLLL', { locale })
  const activeYear = parsedDate.getFullYear()
  const monthIndex = parsedDate.getMonth()
  const currentMonthIndex = today.getMonth()

  const isFutureYear = currentYear < activeYear
  const isPastYear = currentYear > activeYear
  const isEmptyPlan = plan.volume === 0 && plan.completed === 0 && plan.id === 0
  const isInactiveMonth = monthIndex < currentMonthIndex
  const isEditable = getEditableStatus(plan.schedule_date)
  const isActive = plan.isActiveMonth

  if (isEmptyPlan || (isPastYear && plan.volume !== 0)) {
    return (
      <TeamPlanEmptyCard
        monthName={monthName}
        year={activeYear}
        isInactiveMonth={(isInactiveMonth && !isFutureYear) || isPastYear}
        plan={plan}
        setPlan={setPlan}
        progress={setProgress}
      />
    )
  }

  if (isEditable) {
    return (
      <>
        <PlanCardContainer
          isEditable={isEditable}
          isActive={isActive}
          onClick={handleClick(() => setModalIsOpen(true), { value: { to: plan } })}
        >
          <EmptyPlanTitle>{monthName}</EmptyPlanTitle>
          {plan.volume === 0 ? (
            <EmptyPlanDetail>{t('clickToPlan')}</EmptyPlanDetail>
          ) : (
            <EmptyPlanDetail withVolume={isEditable}>
              {formatNumber(plan.volume)}
              {currency}
            </EmptyPlanDetail>
          )}
        </PlanCardContainer>

        {modalIsOpen && (
          <ASharedModal id="PlanModal" onClose={() => setModalIsOpen(false)} size="small">
            <SharedModalTitle>{t('plansPopupHeader', { planMonth: monthName, planYear: activeYear })}</SharedModalTitle>
            <SharedModalContent>
              <PopupBody onChange={setValue} inputValue={value} />
            </SharedModalContent>

            <ADefaultModalFooter
              onClose={() => setModalIsOpen(false)}
              confirmButtonText={t('common:save')}
              progress={editProgress}
              onConfirm={async () => {
                await editPlan(value, plan.id)
                setModalIsOpen(false)
              }}
            />
          </ASharedModal>
        )}
      </>
    )
  }

  return (
    <PlanCardContainer isActive={isActive}>
      <CardHeader isActive={isActive}>{monthName}</CardHeader>
      <TeamsProgress
        direction="column"
        size="small"
        volume={plan.volume}
        completed={plan.completed}
        isActive={isActive}
      />
    </PlanCardContainer>
  )
}
