import { FC, useState } from 'react'

import { useTranslation } from 'react-i18next'

import { Icons, useHelmet } from '@agro-club/agroclub-shared'

import * as Layout from 'views/layouts/NewLayout'
import * as Header from 'views/ui/Header/Header'
import CompanyFilters from 'views/pages/Company/CompanyFilters/CompanyFilters'
import { ListRequestParams } from 'modules/domain/types'
import { defaultListRequestParams } from 'views/components/TableFilters/data'
import { useFilters } from 'hooks/useFilter'
import { endpoints } from 'modules/endpoints'
import { useColumns } from 'views/pages/Company/useColumns'
import { AddCompanyControl } from './AddCompanyControl'
import APageWrapper from 'views/components/PageWrapper/APageWrapper'
import { AnalyticsPages, AnalyticsPlaces } from './analyticsPlaces'
import { AButton } from 'views/components/Analytics'
import ATableFullPage from 'views/components/Table/ATableFullPage'

const CompanyList: FC = () => {
  const { t } = useTranslation(['company'])
  const [listRequestParams, setListRequestParams] = useState<ListRequestParams>(defaultListRequestParams)
  const [addCompanyModalOpen, setAddCompanyModalOpen] = useState(false)
  const { isFiltersLoaded, updateFilterState } = useFilters({
    listRequestParams,
    setListRequestParams,
  })
  const columns = useColumns(listRequestParams.sort)
  const refreshCompanies = () => {
    setListRequestParams({ ...listRequestParams })
  }
  useHelmet({ title: t('companyMetaTitle') })

  return (
    <APageWrapper page={AnalyticsPages.COMPANIES} place={AnalyticsPlaces.LIST}>
      <Layout.WrapperContent>
        <Layout.Header>
          <Layout.TopHeader>
            <Header.Breadcrumbs routes={[{ breadcrumbName: t('list.heading') }]} />
            <AButton
              id="add"
              intent="approve"
              Icon={Icons.IconAdd}
              onClick={() => {
                setAddCompanyModalOpen(true)
              }}
              size="medium"
            >
              {t('common:add')}
            </AButton>
          </Layout.TopHeader>
          <Layout.PageName>{t('list.heading')}</Layout.PageName>
          <CompanyFilters
            setListRequestParams={setListRequestParams}
            listRequestParams={listRequestParams}
            isFiltersLoaded={isFiltersLoaded}
            updateFilterState={updateFilterState}
          />
        </Layout.Header>
        <ATableFullPage
          columns={columns}
          dataEndpoint={endpoints.company()}
          listParamsUpdated={setListRequestParams}
          listRequestParams={listRequestParams}
          isFiltersLoaded={isFiltersLoaded}
          rowHoverGray
        />
        {addCompanyModalOpen && (
          <AddCompanyControl setAddCompanyModalOpen={setAddCompanyModalOpen} refreshCompanies={refreshCompanies} />
        )}
      </Layout.WrapperContent>
    </APageWrapper>
  )
}

export default CompanyList
