import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router'

import formatNumber from 'modules/utils/numbers/formatNumber'
import CarriersKRRoutes from 'views/pages/LogisticsKR/CarriersKR/routes'
import { CarForRequestActions } from 'views/pages/LogisticsKR/components/CarActions/CarForRequestActions'
import { LogisticsKRCar } from 'views/pages/LogisticsKR/types'
import APhone from 'views/ui/Phone/APhone'
import { TableTextLink } from 'views/ui/TableTextLink/TableTextLink'
import { BooleanCell } from '../../../components/BooleanCell'
import { StatusCell } from '../../../components/StatusCell'

const useColumns = (request, refetch) => {
  const { t } = useTranslation('logisticsKR')

  return useMemo(
    () => [
      {
        title: t('cars.tableHeaders.licence_number'),
        dataIndex: 'licence_number',
        width: 150,
        render: (licence_number, data) => (
          <>
            {data.brand_model} <br />
            {licence_number}
          </>
        ),
      },
      {
        title: t('cars.tableHeaders.status'),
        dataIndex: 'status',
        width: 96,
        align: 'left',
        render: (status) => <StatusCell logistics_status={status} />,
      },
      {
        title: t('cars.tableHeaders.owner'),
        dataIndex: 'carrier',
        width: 480,
        render: (carrier) =>
          carrier ? (
            <>
              <TableTextLink id="carrierLink" to={generatePath(CarriersKRRoutes.Details, { id: carrier.id })}>
                {carrier.name}
              </TableTextLink>
              <br />
              <APhone phone={carrier.phone} />
            </>
          ) : null,
      },
      {
        title: t('cars.tableHeaders.trailer'),
        dataIndex: 'has_trailer',
        width: 110,
        render: (has_trailer) => <BooleanCell field={has_trailer} />,
      },
      {
        title: t('cars.tableHeaders.car_type'),
        dataIndex: 'car_type',
        width: 126,
        render: (car_type) => (car_type ? t(`vehicle_type.${car_type}`) : null),
      },
      {
        title: t('cars.tableHeaders.unloading_type'),
        dataIndex: 'unloading_type',
        width: 120,
        render: (unloading_type) => (unloading_type ? t(`directions.${unloading_type}`) : null),
      },
      {
        title: t('cars.tableHeaders.side_height'),
        dataIndex: 'side_height',
        width: 127,
        render: (side_height) => (side_height ? formatNumber(side_height, true, { fractionDigits: 2 }) : null),
        align: 'right',
      },
      {
        title: t('cars.tableHeaders.is_dump_truck'),
        dataIndex: 'is_dump_truck',
        width: 90,
        render: (is_dump_truck) => <BooleanCell field={is_dump_truck} />,
      },
      {
        title: t('cars.tableHeaders.is_in_charter'),
        dataIndex: 'carrier',
        width: 72,
        render: (carrier) => <BooleanCell field={carrier?.is_in_charter} />,
      },
      {
        title: t('cars.tableHeaders.trips_count'),
        dataIndex: 'trips_count',
        width: 66,
        align: 'center',
      },
      {
        title: t('cars.tableHeaders.action'),
        dataIndex: 'id',
        key: 'actions',
        width: 120,
        render: (id, car: LogisticsKRCar) => {
          return (
            <CarForRequestActions
              car={car}
              request={request}
              onTripCreated={refetch}
              refetchCars={refetch}
              toTripShort={false}
            />
          )
        },
      },
    ],
    [t, request, refetch],
  )
}

export default useColumns
