import { FiltersAndSearchWrapper, FiltersWrapper } from 'views/components/CommonTableComponents/CommonTableComponents'
import { ABaseTableFilter } from 'views/components/TableFilters/ABaseTableFilter'
import { SearchFilter } from 'views/components/SearchFilter/SearchFilter'
import { AClearFiltersButton } from 'views/components/Analytics'
import { useDisplayClearBtn } from 'hooks/useDisplayClearBtn'
import { CropsFilter } from 'views/components/TableFilters'
import { ListRequestParams } from 'modules/domain/types'
import { endpoints } from 'modules/endpoints'
import { useTranslation } from 'react-i18next'

interface Props {
  updateFilterState: (filterObj: Record<string, any>) => void
  listRequestParams: ListRequestParams
  clearAllTabsState: () => void
}

export const ContractsFilters = ({ listRequestParams, updateFilterState, clearAllTabsState }: Props) => {
  const displayClearBtn = useDisplayClearBtn({
    listRequestParams,
  })
  const { t } = useTranslation()

  return (
    <FiltersAndSearchWrapper>
      <FiltersWrapper>
        <CropsFilter
          listRequestParams={listRequestParams}
          updateFilterState={updateFilterState}
          fieldName="product"
          showSearch={false}
          multiple={false}
        />
        <ABaseTableFilter
          onChange={(created_by) => updateFilterState({ created_by })}
          value={listRequestParams.filter.created_by}
          endpoint={endpoints.coordinatorsShort()}
          getLabel={(label) => label.full_name}
          placeholder={t('common:author')}
          searchField="search"
          id="author"
        />
        <ABaseTableFilter
          onChange={(status) => updateFilterState({ status })}
          value={listRequestParams.filter.status}
          endpoint={endpoints.contractStatuses()}
          getLabel={(label) => label.label}
          placeholder={t('common:status')}
          simpleApiFormat
          id="status"
        />

        {displayClearBtn && <AClearFiltersButton onClick={clearAllTabsState} />}
      </FiltersWrapper>
      <SearchFilter listRequestParams={listRequestParams} updateFilterState={updateFilterState} />
    </FiltersAndSearchWrapper>
  )
}
