import { FC } from 'react'

import { useAnalyticsClickHandler } from 'analytics/hooks'
import { InternalPhone, InternalPhoneProps } from './InternalPhone'

const APhone: FC<InternalPhoneProps> = ({ phone, onClick, ...otherProps }) => {
  const handleClick = useAnalyticsClickHandler('phone')

  return <InternalPhone onClick={handleClick(onClick, { value: { to: phone } })} phone={phone} {...otherProps} />
}

export default APhone
