import { useContext } from 'react'

import { Spinner } from '@agro-club/agroclub-shared'
import { useTranslation } from 'react-i18next'
import { useAnalyticsContext } from 'analytics/hooks'
import { AuthContext } from 'modules/context/AuthContext'
import { AButton } from 'views/components/Analytics'

export const CurrentUser = () => {
  const { track } = useAnalyticsContext()
  const { t } = useTranslation('auth')
  const { profile, logOut } = useContext(AuthContext)

  const handleLogout = () => {
    track('logout', {
      place: 'MainMenu',
    })
    logOut()
  }

  if (!profile) {
    return (
      <div style={{ margin: 'auto', width: 'fit-content', padding: '10px' }}>
        <Spinner />
      </div>
    )
  }

  return (
    <AButton onClick={handleLogout} id="logout" intent="white">
      {t('logout')}
    </AButton>
  )
}
