import { BidContractDetail } from './BidContractDetail/BidContractDetail'
import { Route, Routes } from 'react-router-dom'
import { BidContractList } from './BidContractList'
import BidContractsRoutes from './routes'

function BidContractsPage() {
  return (
    <Routes>
      <Route index element={<BidContractList />} />
      <Route path={BidContractsRoutes.relativeListWithTab} element={<BidContractList />} />
      <Route path={BidContractsRoutes.relativeDetail} element={<BidContractDetail />} />
    </Routes>
  )
}

export default BidContractsPage
