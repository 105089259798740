import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNotificationProgress } from 'hooks/useNotificationProgress'
import { ReactComponent as IconArrow } from 'assets/icons/arrow.svg'
import { endpoints } from 'modules/endpoints'
import { apiClient } from 'modules/utils/httpClient'
import { ExecuteBlock } from '../styled'
import { useAProgress } from 'hooks/useAProgress'
import { AButton } from 'views/components/Analytics'

interface ButtonProps {
  taskId: string
  refetch: () => void
}

export const SetTaskToWorkButton: FC<ButtonProps> = ({ taskId, refetch }) => {
  const { t } = useTranslation('my_tasks')

  const [progress, setToWork, error] = useAProgress(() => apiClient.post(endpoints.setTaskToWork(taskId)), {
    eventName: 'setToWork',
    analyticsPayload: {
      id: taskId,
    },
    onSuccess: refetch,
  })
  useNotificationProgress(progress, { title: t('table.set_to_work_task') }, { title: error?.detail })

  return (
    <ExecuteBlock>
      <span>{t('table.new')}</span>
      <IconArrow />
      <AButton id="setToWork" intent="secondary" onClick={setToWork} progress={progress}>
        {t('detail.to_work')}
      </AButton>
    </ExecuteBlock>
  )
}

export const CompleteTaskButton: FC<ButtonProps> = ({ taskId, refetch }) => {
  const { t } = useTranslation('my_tasks')

  const [progress, completeTask] = useAProgress(() => apiClient.post(endpoints.completeTask(taskId)), {
    eventName: 'complete',
    analyticsPayload: {
      id: taskId,
    },
    onSuccess: refetch,
  })
  useNotificationProgress(progress, t('table.completed_task'))

  return (
    <ExecuteBlock>
      <span>{t('table.in_progress')}</span>
      <IconArrow />
      <AButton id="completeTask" intent="secondary" onClick={completeTask} progress={progress}>
        {t('detail.perform')}
      </AButton>
    </ExecuteBlock>
  )
}
