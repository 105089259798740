import { FC } from 'react'
import { AnalyticsPlaces } from '../../analyticsPlaces'
import { ASharedModal, SharedModalContent, SharedModalTitle } from 'views/components/SharedModal'
import { useTranslation } from 'react-i18next'
import { LogisticsKRCar } from '../../types'
import { Progress } from '@agro-club/agroclub-shared'
import ADefaultModalFooter from 'views/components/DefaultModalFooter/ADefaultModalFooter'

interface Props {
  car: LogisticsKRCar
  onClose: (arg: boolean) => void
  onChange: (carId: string) => Promise<any>
  progress: Progress
}

export const ArchivedConfirmModal: FC<Props> = ({ onClose, car, onChange, progress }) => {
  const { t } = useTranslation('logisticsKR')

  return (
    <ASharedModal id={AnalyticsPlaces.CARRIERS.ARCHIVE_CAR_MODAL} size="small" onClose={() => onClose(false)}>
      <SharedModalTitle>{t('carsDetails.archiveForm.title')}</SharedModalTitle>
      <SharedModalContent>{t('carsDetails.archiveForm.text')}</SharedModalContent>
      <ADefaultModalFooter
        progress={progress}
        onClose={() => onClose(false)}
        onConfirm={() => onChange(car.id)}
        confirmButtonText={t('common:archive')}
      />
    </ASharedModal>
  )
}
