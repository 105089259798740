import { FC } from 'react'

import { SharedModalContent, SharedModalInputs, SharedModalTitle } from 'views/components/SharedModal'
import { useTranslation } from 'react-i18next'
import { FormGroup, Input, useHelmet } from '@agro-club/agroclub-shared'
import { useFormik } from 'formik'
import { PotentialBid } from 'modules/domain/potentialBid/types'
import { PotentialBidEditableFields } from 'modules/domain/bid/types'
import { Product } from 'modules/domain/collection/types'
import { apiClient } from 'modules/utils/httpClient'
import { endpoints } from 'modules/endpoints'
import { InitialMainParameter } from '../BidCreateModal'
import { ShortFormControl } from 'views/styled/common'
import ASharedModal from 'views/components/SharedModal/ASharedModal'
import { useAFormHandler } from 'analytics/hooks'
import { AnalyticsPlaces } from '../../analyticsPlaces'
import ADefaultModalFooter from 'views/components/DefaultModalFooter/ADefaultModalFooter'
import { PotentialBidsParams } from './PotentialBidsParams'
import { SeasonSelect } from '../../components/SeasonSelect'
import { refetchFunc } from 'modules/domain/common/hooks'

type Props = {
  onClose: () => void
  onSuccess: refetchFunc
  bid: PotentialBid
  product?: Product
}

export const PotentialBidEditModal: FC<Props> = ({ onClose, onSuccess, bid, product }) => {
  const { t } = useTranslation('bid')
  useHelmet({ title: `${t('form.potentialEdit')} - ${product?.title}` })
  const { formProgress, formHandler } = useAFormHandler('editPotentialBid', { id: bid.id })

  const initialValues = {
    quantity: bid.quantity,
    season: bid.season?.id,
    parameters:
      bid.parameters.map((p) => ({
        parameter: p.parameter.id,
        parameter_value_from: p.parameter_value_from,
        parameter_value_to: p.parameter_value_to,
      })) || [],
    mainParameter: InitialMainParameter,
  }

  const formik = useFormik<PotentialBidEditableFields>({
    initialValues,
    enableReinitialize: true,

    onSubmit: formHandler(async () => await apiClient.put(endpoints.potentialBid(bid.id), formik.values), {
      onSuccess: async () => {
        await onSuccess(true)
        onClose()
      },
      onError: (error) => {
        const { errors } = error
        formik.setErrors(errors)
      },
    }),
  })

  return (
    <ASharedModal onClose={onClose} size="medium" id={AnalyticsPlaces.POTENTIAL_EDIT_MODAL}>
      <SharedModalTitle>{t('form.bidsParams')}</SharedModalTitle>
      <SharedModalContent>
        <SharedModalInputs>
          <ShortFormControl>
            <FormGroup label={t('form.generalQuantity')} error={formik.errors.quantity} required>
              <Input {...formik.getFieldProps('quantity')} invalid={!!formik.errors.quantity} type={'number'} />
            </FormGroup>
          </ShortFormControl>
          <SeasonSelect formik={formik} product_id={bid.product.id} />
        </SharedModalInputs>
        <PotentialBidsParams product={product} formik={formik} />
      </SharedModalContent>

      <ADefaultModalFooter
        onClose={onClose}
        confirmButtonText={t('common:save')}
        onConfirm={() => formik.handleSubmit()}
        progress={formProgress}
        disabled={!formik.dirty}
      />
    </ASharedModal>
  )
}
