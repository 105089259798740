import { RematchingColumnTitle, QuantityBlock, SelectableQuantity } from './styled'
import { quantityInTons } from 'modules/utils/numbers/formatQuantity'
import { AntSkeleton, Progress, Text } from '@agro-club/agroclub-shared'
import { BidContractTotalsItem } from 'modules/domain/bid/types'
import { PreloadCount } from 'views/components/Board/Preload'
import { Count } from 'views/components/Board/styled'
import { ASearch } from 'views/components/Analytics'
import { FC } from 'react'
import { RematchingData } from 'views/pages/Deal/hooks/useRematching'
import usePrevious from 'hooks/usePrevious'

type Props = {
  matching: RematchingData
  totals: BidContractTotalsItem[]
  totalsProgress: Progress
  countLoading: boolean
  title: string
  count: number
}

export const HeaderControls: FC<Props> = ({ matching, totalsProgress, countLoading, totals, title, count }) => {
  const onSlugClick = (slug: string) => {
    if (matching.filterBid) {
      return
    }
    if (matching.productSlug === slug) {
      slug = ''
    }
    matching.setBid(null)
    matching.setProductSlug(slug)
  }

  const skeletons = usePrevious(totals.length || 1)
  const totalsLoading = totalsProgress === Progress.WORK

  return (
    <>
      <RematchingColumnTitle>
        <span>{title}</span>
        {countLoading ? <PreloadCount /> : <Count color="orange">{count}</Count>}
      </RematchingColumnTitle>
      <QuantityBlock>
        {totalsLoading
          ? Array.from({ length: skeletons }).map((_, key) => (
              <AntSkeleton.Button active key={key}></AntSkeleton.Button>
            ))
          : totals.map((item) => {
              return (
                <SelectableQuantity
                  key={item.slug}
                  selected={item.slug === matching.productSlug}
                  onClick={() => {
                    onSlugClick(item.slug)
                  }}
                  active={!!matching.filterBid?.id}
                >
                  <Text typography="bodyMedium" color="secondary">
                    {item.title}
                  </Text>

                  <Text typography="bodyMedium" style={{ whiteSpace: 'nowrap' }}>
                    {quantityInTons(item.rematching_quantity)}
                  </Text>
                </SelectableQuantity>
              )
            })}
      </QuantityBlock>
      {(!!totals.length || totalsLoading) && <ASearch value={matching.search} onChange={matching.setSearch} />}
    </>
  )
}
