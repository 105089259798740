import { LocationStateFrom } from 'types/location'

const useLocationFrom = (): LocationStateFrom['from'] => {
  // it will be removed - temp fix for optiomization
  const { pathname, search } = window.location

  return {
    pathname,
    search,
  }
}

export default useLocationFrom
