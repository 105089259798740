import * as Layout from 'views/layouts/NewLayout'
import * as Header from 'views/ui/Header/Header'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSingleEntity } from 'modules/domain/common/hooks'
import { DealContract } from 'modules/domain/deal/types'
import { endpoints } from 'modules/endpoints'
import APageWrapper from 'views/components/PageWrapper/APageWrapper'
import { AnalyticsPages, AnalyticsPlaces } from '../analyticsPlaces'
import { ContractsRoutes } from 'views/pages/Contracts/routes'
import { GeneralInfo } from './GeneralInfo/GeneralInfo'
import { ChangeStatusControl } from './ChangeStatusControl'
import { useMarkAsRead } from 'modules/socket/hooks'
import { RightBlock } from './RightBlock/RightBlock'

export const ContractDetails = () => {
  const { id } = useParams()
  const { t } = useTranslation('contracts')
  const [, contract, refetch] = useSingleEntity<DealContract>(endpoints.dealsContracts(id))
  useMarkAsRead(id, 'salecontractrequest')

  if (!contract) return null

  return (
    <APageWrapper id={id} page={AnalyticsPages.CONTRACTS_DETAIL} place={AnalyticsPlaces.DETAIL}>
      <Layout.WrapperContent>
        <Layout.Header>
          <Layout.TopHeader>
            <Header.Breadcrumbs
              routes={[
                { breadcrumbName: t('menu:documents') },
                { breadcrumbName: t('menu:contracts'), path: ContractsRoutes.List },
                { breadcrumbName: t('detailTitle', { id }) },
              ]}
            />
            <ChangeStatusControl contract={contract} onSuccess={refetch} />
          </Layout.TopHeader>
          <Layout.PageTitleWrapper>
            <Header.Title title={t('detailTitle', { id })} />
          </Layout.PageTitleWrapper>
        </Layout.Header>
        <Layout.ContentTwoColumns>
          <GeneralInfo contract={contract} refetch={refetch} />
          <RightBlock />
        </Layout.ContentTwoColumns>
      </Layout.WrapperContent>
    </APageWrapper>
  )
}
