import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router'

import CarriersKRRoutes from '../../../CarriersKR/routes'

import { StatusCell } from '../../../components/StatusCell'
import { BooleanCell } from '../../../components/BooleanCell'
import { TableTextLink } from 'views/ui/TableTextLink/TableTextLink'
import { ColumnData } from 'modules/types'
import formatNumber from 'modules/utils/numbers/formatNumber'
import { ListRequestParams } from 'modules/domain/types'
import { ActionsCell } from './ActionsCell'

type Props = {
  listRequestParams: ListRequestParams
  setListRequestParams: (ListRequestParams) => void
}
const useColumns = ({ setListRequestParams, listRequestParams }: Props): ColumnData[] => {
  const { t } = useTranslation('logisticsKR')

  return useMemo(
    () => [
      {
        title: t('cars.tableHeaders.licence_number'),
        dataIndex: 'licence_number',
        width: 230,
        render: (licence_number, data) => (
          <>
            {data.brand_model}&nbsp;
            {licence_number}
          </>
        ),
      },
      {
        title: t('cars.tableHeaders.trailer'),
        dataIndex: 'has_trailer',
        width: 150,
        render: (has_trailer) => (has_trailer ? t('common:thereIs') : t('common:no')),
      },
      {
        title: t('cars.tableHeaders.status'),
        dataIndex: 'status',
        width: 96,
        render: (status) => <StatusCell logistics_status={status} />,
      },
      {
        title: t('cars.tableHeaders.owner'),
        dataIndex: 'carrier',
        width: 426,
        render: (carrier) => (
          <TableTextLink
            id="carrier"
            tableItemId={carrier.id}
            to={generatePath(CarriersKRRoutes.Details, { id: carrier.id })}
          >
            {carrier.name}
          </TableTextLink>
        ),
      },
      {
        title: t('cars.tableHeaders.car_type'),
        dataIndex: 'car_type',
        width: 126,
        render: (car_type) => car_type && t(`vehicle_type.${car_type}`),
      },
      {
        title: t('cars.tableHeaders.unloading_type'),
        dataIndex: 'unloading_type',
        width: 125,
        render: (unloading_type) => unloading_type && t(`directions.${unloading_type}`),
      },
      {
        title: t('cars.tableHeaders.side_height'),
        dataIndex: 'side_height',
        width: 128,
        render: (side_height) => side_height && formatNumber(side_height, true),
        align: 'right',
      },
      {
        title: t('cars.tableHeaders.is_dump_truck'),
        dataIndex: 'is_dump_truck',
        width: 90,
        render: (is_dump_truck) => <BooleanCell field={is_dump_truck} />,
      },
      {
        title: t('cars.tableHeaders.is_in_charter'),
        dataIndex: 'carrier',
        width: 72,
        render: (carrier) => <BooleanCell field={carrier?.is_in_charter} />,
      },
      {
        title: t('cars.tableHeaders.trips_count'),
        dataIndex: 'trips_count',
        width: 66,
        align: 'center',
      },
      {
        title: t('cars.tableHeaders.actions'),
        dataIndex: 'actions',
        width: 110,
        render: (_, data) => (
          <ActionsCell data={data} listRequestParams={listRequestParams} setListRequestParams={setListRequestParams} />
        ),
      },
    ],
    [listRequestParams, setListRequestParams, t],
  )
}

export default useColumns
