import { FormikProps } from 'formik'
import { isRussia } from 'env'
import { FormInput } from 'views/components/form/FormInput'
import { useTranslation } from 'react-i18next'
import { labelPriceLogistics } from 'modules/utils/numbers/formatPriceLogistics'
import styled from 'styled-components'
import { defaultTheme } from '@agro-club/agroclub-shared'
import { DealEditableFields } from './types'

interface Props {
  formik: FormikProps<DealEditableFields>
}

const ShortLabelWrapper = styled.div`
  max-width: 180px;
  @media (max-width: ${defaultTheme.queries.mobile}) {
    max-width: none;
  }
`

export const PriceCarrierInput = ({ formik }: Props) => {
  const { t } = useTranslation('deal')

  if (isRussia) {
    return (
      <FormInput
        formik={formik}
        field="price_carrier"
        label={<ShortLabelWrapper>{labelPriceLogistics(t('fields.carrier_price'))}</ShortLabelWrapper>}
        type="number"
      />
    )
  }

  return null
}
