import { useMediaQuery } from 'react-responsive'
import { DocumentStatus } from '../DocumentStatusTag'
import { Divider, TagsWrapper } from '../styled'
import { DealContract } from 'modules/domain/deal/types'
import { useTranslation } from 'react-i18next'
import { defaultTheme } from '@agro-club/agroclub-shared'

type Props = {
  contract: DealContract
}

export const TagsStatus = ({ contract }: Props) => {
  const { t } = useTranslation('deal')

  const isMobile = useMediaQuery({
    query: `(max-width: ${defaultTheme.queries.mobile})`,
  })
  const dash = !isMobile && <Divider>—</Divider>
  return (
    <TagsWrapper>
      <DocumentStatus ready={contract?.farmer_documents_received ?? false}>
        {t('requestContractBlock.farmer_documents_received')}
      </DocumentStatus>
      {dash}
      <DocumentStatus ready={contract?.approved_by_lawyers ?? true}>
        {t('requestContractBlock.approved_by_lawyers')}
      </DocumentStatus>
      {dash}
      <DocumentStatus ready={contract?.signed_by_agroclub ?? false}>
        {t('requestContractBlock.signed_by_agroclub')}
      </DocumentStatus>
    </TagsWrapper>
  )
}
