import { useTranslation } from 'react-i18next'

import { TeamPlan } from 'modules/domain/teams/types'
import { PlanMonthCard } from 'views/components/PlanMonthCard/PlanMonthCard'
import { usePlansData } from 'views/components/TeamPlanCard/hooks'
import {
  SwitcherButton,
  TeamPlanSwitcherLabel,
  TeamPlanYearsSwitcher,
  TeamPlansCard,
  TeamPlansContainer,
} from 'views/components/TeamPlanCard/styled'
import { Icons, Progress } from '@agro-club/agroclub-shared'
import { FC, useState } from 'react'

type Props = {
  teamPlans: TeamPlan[]
  setProgress?: Progress
  editProgress?: Progress
  setPlan: (volume: number, schedule_date: string) => void
  editPlan: (volume: number, id: number) => void
}

export const TeamPlanCard: FC<Props> = ({ teamPlans, setProgress, editProgress, setPlan, editPlan }) => {
  const [year, setYear] = useState(new Date().getFullYear())

  const { t } = useTranslation('teams')

  const currentYear = new Date().getFullYear()
  const teamPlansData = usePlansData(year, teamPlans)
  const hasPrevYearData =
    usePlansData(year - 1, teamPlans).some((item) => item.volume !== 0) || year - 1 === currentYear
  const hasNextYearData = year <= currentYear

  const setNextYear = () => {
    if (hasNextYearData) {
      setYear(year + 1)
    }
  }

  const setPrevYear = () => {
    if (hasPrevYearData) {
      setYear(year - 1)
    }
  }

  return (
    <TeamPlansCard>
      <TeamPlanYearsSwitcher>
        <SwitcherButton
          id="prevYear"
          intent="white"
          Icon={Icons.IconArrowLeftM}
          onClick={setPrevYear}
          disabled={hasNextYearData}
        />

        <TeamPlanSwitcherLabel>{t('plans', { year })}</TeamPlanSwitcherLabel>

        <SwitcherButton
          id="nextYear"
          intent="white"
          Icon={Icons.IconArrowRightM}
          onClick={setNextYear}
          disabled={hasPrevYearData}
        />
      </TeamPlanYearsSwitcher>

      <TeamPlansContainer>
        {teamPlansData.map((plan) => (
          <PlanMonthCard
            key={plan.schedule_date}
            plan={plan}
            setPlan={setPlan}
            editPlan={editPlan}
            setProgress={setProgress}
            editProgress={editProgress}
          />
        ))}
      </TeamPlansContainer>
    </TeamPlansCard>
  )
}
