import { FC } from 'react'

import { SharedModalContent, SharedModalInputs, SharedModalTitle } from 'views/components/SharedModal'
import { useTranslation } from 'react-i18next'
import { AgroundImputs, Bid, BidTags } from 'modules/domain/bid/types'
import { useFormik } from 'formik'
import { apiClient } from 'modules/utils/httpClient'
import { endpoints } from 'modules/endpoints'
import ASharedModal from 'views/components/SharedModal/ASharedModal'
import { useAFormHandler } from 'analytics/hooks'
import { AnalyticsPlaces } from '../../analyticsPlaces'
import ADefaultModalFooter from 'views/components/DefaultModalFooter/ADefaultModalFooter'
import { AgroundImputsSelect } from '../../components/Brazil/AgroundImputsSelect'
import { BidTagSelect } from '../../components/Brazil/BidTagSelect'
import { refetchFunc } from 'modules/domain/common/hooks'
import { getBrazilFieldRequirements } from '../../components/Brazil/utils'

export type TaxesFormFields = {
  aground_imputs?: AgroundImputs
  bid_tag?: BidTags
}

type Props = {
  onClose: () => void
  refetch: refetchFunc
  bid: Bid
}

export const BidTaxesModal: FC<Props> = ({ onClose, refetch, bid }) => {
  const { t } = useTranslation('bid')
  const { formProgress, formHandler } = useAFormHandler()

  const initialValues = {
    aground_imputs: bid.aground_imputs,
    bid_tag: bid.bid_tag,
  }

  const formik = useFormik<TaxesFormFields>({
    initialValues,
    onSubmit: formHandler(async () => await apiClient.patch(endpoints.bid(bid.id), formik.values), {
      onSuccess: async () => {
        await refetch(true)
        onClose()
      },
      onError: (error) => {
        const { errors } = error
        formik.setErrors(errors)
      },
    }),
  })

  const required = getBrazilFieldRequirements({ profileType: bid?.owner?.profile?.profile_type, product: bid.product })

  if (!required.bid_tag && !required.aground_imputs) {
    return null
  }

  return (
    <ASharedModal onClose={onClose} size="small" id={AnalyticsPlaces.TAXES_MODAL}>
      <SharedModalTitle>{t('form.detailsEdit')}</SharedModalTitle>
      <SharedModalContent>
        <SharedModalInputs>
          {required.aground_imputs && <AgroundImputsSelect formik={formik} />}
          {required.bid_tag && <BidTagSelect formik={formik} />}
        </SharedModalInputs>
      </SharedModalContent>

      <ADefaultModalFooter
        onClose={onClose}
        confirmButtonText={t('common:save')}
        onConfirm={() => formik.handleSubmit()}
        progress={formProgress}
        disabled={!formik.dirty}
      />
    </ASharedModal>
  )
}
