import { ExternalLink, Icons, Text } from '@agro-club/agroclub-shared'
import { useAProgress } from 'hooks/useAProgress'
import useFormatDate from 'hooks/useFormatDate'
import { refetchFunc } from 'modules/domain/common/hooks'
import { endpoints } from 'modules/endpoints'
import { apiClient } from 'modules/utils/httpClient'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { AButton } from 'views/components/Analytics'
import { BorderedItem } from 'views/components/BorderedItem/BorderedItem'
import { Card } from 'views/components/Card/Card'
import { TtnDocument } from 'views/pages/LogisticsKR/types'

const GapWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`

const ItemContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

type Props = {
  file: TtnDocument
  refetch: refetchFunc
  refetchTripsTable: () => void
  setDeletedTtnIdForSync?: (docId: string) => void
}

export const FileTtnItem: FC<Props> = ({ file, refetch, refetchTripsTable, setDeletedTtnIdForSync }) => {
  const { t } = useTranslation('logisticsKR')
  const formatDate = useFormatDate()

  const [removeProgress, removeHandler] = useAProgress(
    async (tripId: number, docId: number) =>
      await apiClient.delete(endpoints.LogisticsKR.tripsDocument(String(tripId), String(docId))),
    {
      eventName: 'removeTripTtnFile',
      onSuccess: () => {
        setDeletedTtnIdForSync?.(String(file.uploadedfile))
        refetch(true)
        refetchTripsTable()
      },
    },
  )

  return (
    <BorderedItem hover={false}>
      <GapWrapper>
        <ItemContentWrapper>
          <div>
            <ExternalLink href={file.uploaded_file} target="_blank">
              {file.original_filename}
            </ExternalLink>
            <Text color="primary">
              {t('details.accordion.ttn')}: {file.ttn_number}, {t('details.accordion.fileLoadDate')}:{' '}
              {formatDate(file.uploaded_at)}
            </Text>
          </div>
          <AButton
            id="removeTtnFile"
            onClick={() => removeHandler(file.trip, file.uploadedfile)}
            progress={removeProgress}
            intent="white"
            Icon={Icons.IconRemove}
          />
        </ItemContentWrapper>
        {file.comment && (
          <Card.Container intent="backgroundPrimary" padding="8px">
            <Text style={{ whiteSpace: 'pre-line' }} typography="bodySmall">
              {file.comment}
            </Text>
          </Card.Container>
        )}
      </GapWrapper>
    </BorderedItem>
  )
}
