import { defaultTheme, OptionType, Typography } from '@agro-club/agroclub-shared'
import { isBrazil } from 'env'
import { FormikProps } from 'formik'
import { Bid } from 'modules/domain/bid/types'
import { Deal } from 'modules/domain/deal/types'
import { endpoints } from 'modules/endpoints'
import { formatQuantity } from 'modules/utils/numbers/formatQuantity'
import { formatTarif } from 'modules/utils/numbers/formatTarif'
import { FC, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import FormSelectShared from 'views/components/FormSelectShared'
import { CloningFormFields } from '../../types'

const StyledOption = styled.div`
  white-space: normal;
`

const Label = styled.div`
  ${Typography.bodyLarge}
`

const Address = styled.div`
  ${Typography.bodyLarge}
  color: ${defaultTheme.color.textSecondary60a};
`

const Option: FC<{ option: OptionType }> = ({ option }) => {
  return (
    <StyledOption>
      <Label>{option.label}</Label>
      <Address>{option.dto.address.address}</Address>
    </StyledOption>
  )
}

interface Props {
  formik: FormikProps<CloningFormFields>
  onChange?: (bid: Bid) => void
  required?: boolean
  onSelectedOptionLoad?: (selected: any) => void
  deal: Deal
  children: ReactNode
}

export const FormSelectBid: FC<Props> = ({ required, onChange, formik, onSelectedOptionLoad, deal, children }) => {
  const { t } = useTranslation('deal')

  const getSelectedOptLabel = (bid: Bid): string => {
    const result = [
      bid.product.title,
      formatQuantity(bid.quantity),
      bid.parameters.length ? bid.parameters[0].description : '',
      formatTarif(bid.price),
    ]
      .filter(Boolean)
      .join(', ')

    return result
  }

  return (
    <FormSelectShared
      required={required}
      placeholder={t('bidCloningModal.bid_placeholder')}
      endpoint={formik.values.user ? endpoints.userBidsForDeal(formik.values.user) : ''}
      label={t('bidCloningModal.bid')}
      OptionComponent={Option}
      filter={{ product: deal.product.slug, with_contract: isBrazil ? true : undefined }}
      getLabel={(dto) => getSelectedOptLabel(dto)}
      searchField="search"
      fieldName="new_bid"
      formik={formik}
      onChange={onChange}
      onSelectedOptionLoaded={onSelectedOptionLoad}
      simpleApiFormat
      disabled={!formik.values.user}
    >
      {children}
    </FormSelectShared>
  )
}
