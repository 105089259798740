import { AntCollapse, Panel } from '@agro-club/agroclub-shared'
import { WrapperCollapse } from 'views/pages/LogisticsKR/LogisticRequests/styled'
import { useTableData } from 'modules/domain/common/hooks'
import { endpoints } from 'modules/endpoints'
import { ListRequestParams } from 'modules/domain/types'
import { LogisticStatuses, RequestCondition } from 'views/pages/LogisticsKR/types'
import { useTranslation } from 'react-i18next'
import { RequestColumnHeader } from 'views/pages/LogisticsKR/LogisticRequests/components/RequestColumnHeader/RequestColumnHeader'
import { RequestColumn } from 'views/pages/LogisticsKR/LogisticRequests/components/RequestColumn/RequestColumn'
import { RequestDrawer } from 'views/pages/LogisticsKR/LogisticRequests/RequestsBoard/RequestDrawer'
import { useCallback, useEffect, useMemo } from 'react'
import { LogisticRequest } from 'modules/domain/logisticsKR/types'
import { generatePath, useNavigate, useParams } from 'react-router'
import DealsKRRoutes from 'views/pages/LogisticsKR/LogisticRequests/routes'

type Props = {
  listRequestParams: ListRequestParams
  setDrawerIsOpen: (boolean) => void
  drawerIsOpen: boolean
  isFiltersLoaded: boolean
  activeRequest: LogisticRequest | undefined
  setActiveRequest: (arg: LogisticRequest | undefined) => void
}

export const ClosedRequestBoardColumns = ({
  setActiveRequest,
  activeRequest,
  listRequestParams,
  isFiltersLoaded,
  setDrawerIsOpen,
  drawerIsOpen,
}: Props) => {
  const { t } = useTranslation('logisticBids')
  const { activeTab, selectedRequest } = useParams()
  const navigate = useNavigate()

  const {
    progress: waitForDocsProgress,
    data: waitForDocsList,
    refetch: waitForDocsRefetch,
  } = useTableData(
    endpoints.LogisticsKR.requestsWithStatus(LogisticStatuses.waiting_for_documents),
    listRequestParams,
    isFiltersLoaded,
  )
  const {
    progress: shipmentCompleteProgress,
    data: shipmentCompleteList,
    refetch: shipmentCompleteRefetch,
  } = useTableData(
    endpoints.LogisticsKR.requestsWithStatus(LogisticStatuses.shipment_complete),
    listRequestParams,
    isFiltersLoaded,
  )
  const {
    progress: cancelledProgress,
    data: cancelledList,
    refetch: cancelledRefetch,
  } = useTableData(
    endpoints.LogisticsKR.requestsWithStatus(LogisticStatuses.cancelled),
    listRequestParams,
    isFiltersLoaded,
  )

  useEffect(() => {
    // wait for server response when changing filters
    if (!waitForDocsList || !shipmentCompleteList || !cancelledList) return

    const concatRequests = waitForDocsList?.concat(shipmentCompleteList, cancelledList)
    const activeRequestInSearchResult = !!concatRequests?.find((el) => String(el?.id) === String(selectedRequest))

    // If there is no active request in the filtered data, then remove this request from the ulr
    if (!activeRequestInSearchResult && selectedRequest) {
      navigate(
        {
          pathname: generatePath(DealsKRRoutes.ListRequestsWithTab, { activeTab: activeTab }),
          search: location.search,
        },
        { replace: true },
      )
      setActiveRequest(undefined)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [waitForDocsList, shipmentCompleteList, cancelledList])

  const drawerIsOpenMemo = useMemo(
    () => drawerIsOpen && activeTab === RequestCondition.closed,
    [activeTab, drawerIsOpen],
  )

  const refetchForDrawer = useCallback(
    (status?: LogisticStatuses) => {
      switch (status) {
        case LogisticStatuses.waiting_for_documents:
          return waitForDocsRefetch
        case LogisticStatuses.shipment_complete:
          return shipmentCompleteRefetch
        case LogisticStatuses.cancelled:
          return cancelledRefetch
        default:
          return undefined
      }
    },
    [cancelledRefetch, shipmentCompleteRefetch, waitForDocsRefetch],
  )
  const options = [
    {
      value: 'waiting_for_documents',
      header: (
        <RequestColumnHeader
          counter={waitForDocsList?.length}
          progress={waitForDocsProgress}
          title={t('board_columns.title.waiting_for_documents')}
          color="grey"
        />
      ),
    },
    {
      value: 'shipment_complete',
      header: (
        <RequestColumnHeader
          counter={shipmentCompleteList?.length}
          progress={shipmentCompleteProgress}
          title={t('board_columns.title.shipment_complete')}
          color="grey"
        />
      ),
    },
    {
      value: 'cancelled',
      header: (
        <RequestColumnHeader
          counter={cancelledList?.length}
          progress={cancelledProgress}
          title={t('board_columns.title.cancelled')}
          color="grey"
        />
      ),
    },
  ]

  const components = {
    waiting_for_documents: (
      <RequestColumn requests={waitForDocsList} progress={waitForDocsProgress} setActiveRequest={setActiveRequest} />
    ),
    shipment_complete: (
      <RequestColumn
        requests={shipmentCompleteList}
        progress={shipmentCompleteProgress}
        setActiveRequest={setActiveRequest}
      />
    ),
    cancelled: (
      <RequestColumn requests={cancelledList} progress={cancelledProgress} setActiveRequest={setActiveRequest} />
    ),
  }

  return (
    <>
      {options.map((option) => (
        <WrapperCollapse key={option.value}>
          <AntCollapse bordered={false} key={option.value} noMarginTop defaultActiveKey={option.value}>
            <Panel collapsible="disabled" header={option.header} key={option.value} showArrow={false}>
              {components[option.value]}
            </Panel>
          </AntCollapse>
        </WrapperCollapse>
      ))}
      <RequestDrawer
        activeRequest={activeRequest}
        drawerIsOpen={drawerIsOpenMemo}
        requestsRefetch={refetchForDrawer(activeRequest?.logistics_status)}
        setDrawerIsOpen={setDrawerIsOpen}
      />
    </>
  )
}
