import { endpoints } from 'modules/endpoints'
import { apiClient } from 'modules/utils/httpClient'

import { ClosedReason, DealsBidDto, DealShipment, DealShipmentForm } from './types'

export const addShipmentItem = (dealId: string, dto: DealShipmentForm) =>
  apiClient.post<DealShipment>(endpoints.dealShipment(dealId), dto)

export const editShipmentItem = (dealId: string, shipmentId: string, dto: DealShipmentForm) =>
  apiClient.put<DealShipment>(endpoints.dealShipment(dealId, shipmentId), dto)

export const updatePurchaseBid = (dealId: string, dto: DealsBidDto) =>
  apiClient.put(endpoints.dealUpdatePurchaseBid(dealId), dto)

export const updateSaleBid = (dealId: string, dto: DealsBidDto) =>
  apiClient.put(endpoints.dealUpdateSaleBid(dealId), dto)

export const closeDeal = (id: string, closed_reason: ClosedReason) =>
  apiClient.post<unknown>(endpoints.dealClose(id), { closed_reason })

export const bindSpecificationForDeal = (dealId: string, specificationId: string) =>
  apiClient.put(endpoints.specificationDealBinding(dealId), { purchase_specification: specificationId })
