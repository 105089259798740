import { defaultTheme } from '@agro-club/agroclub-shared'
import styled from 'styled-components'
import { Card } from 'views/components/Card/Card'

export const Column = styled.div``

export const WarningContainer = styled(Card.Container).attrs({
  intent: 'description',
  disableShadow: true,
})`
  margin-bottom: 12px;
`
export const ProductTitle = styled(Card.Title)`
  margin-bottom: 16px;
  margin-top: 24px;
  @media (max-width: ${defaultTheme.queries.mobile}) {
    text-align: center;
  }
`
