import { FarmerLoad } from 'modules/domain/deal/types'
import { refetchFunc } from 'modules/domain/common/hooks'
import { FC, useState } from 'react'
import { LoadingActionButtons } from './LoadingActionButtons'
import { EditFarmerLoadModal } from '../Modals/EditFarmerLoadModal'
import { RemoveFarmerLoadModal } from '../Modals/RemoveFarmerLoadModal'

type Props = {
  setPopoverVisible: (arg: boolean) => void
  loadItem: FarmerLoad
  refetch: refetchFunc
}
export const FarmerLoadActions: FC<Props> = ({ setPopoverVisible, loadItem, refetch }) => {
  const [editModal, setEditModal] = useState(false)
  const [removeModal, setRemoveModal] = useState(false)
  return (
    <>
      <LoadingActionButtons
        setPopoverVisible={setPopoverVisible}
        setEditModal={setEditModal}
        setRemoveModal={setRemoveModal}
      />
      {editModal && <EditFarmerLoadModal onClose={() => setEditModal(false)} loadItem={loadItem} refetch={refetch} />}
      {removeModal && (
        <RemoveFarmerLoadModal onClose={() => setRemoveModal(false)} loadItemId={loadItem.id} refetch={refetch} />
      )}
    </>
  )
}
