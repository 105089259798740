type NonUndefined<T extends Record<string, unknown>> = {
  [P in keyof T]: undefined extends T[P] ? never : T[P]
}

export function nonEmptyFields<T extends Record<string, unknown>>(obj: T): NonUndefined<T> {
  const result: Record<string, unknown> = {}
  for (const [key, val] of Object.entries(obj)) {
    if (typeof val !== undefined && val !== '') {
      result[key] = val
    }
  }
  return result as NonUndefined<T>
}
