import { HeaderSummary, HeaderTitle, HeaderWrapper } from 'views/components/AntCollapseHeader/styled'
import { FC, ReactNode } from 'react'
import { AntSkeleton, Progress } from '@agro-club/agroclub-shared'

type Props = {
  title: ReactNode
  summary?: ReactNode
  progress?: Progress
}

const AntCollapseHeader: FC<Props> = ({ title, summary, progress }) => {
  const isLoading = progress && progress === Progress.WORK

  return (
    <HeaderWrapper>
      <HeaderTitle>{title}</HeaderTitle>
      <HeaderSummary>
        {isLoading && summary ? (
          <AntSkeleton.Button active size="small" style={{ width: 45, height: 16, marginTop: 2, borderRadius: 4 }} />
        ) : (
          summary
        )}
      </HeaderSummary>
    </HeaderWrapper>
  )
}

export default AntCollapseHeader
