import styled from 'styled-components'

export const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
`

export const Header = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`

export const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0 4px;
`
export const ActionsWrapperMobile = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
`
