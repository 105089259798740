import { AgroundImputs } from 'modules/domain/bid/types'
import { DealContractType } from 'modules/domain/deal/types'
import { ProductPurpose } from 'modules/domain/documentRequest/types'

export enum RatingTypes {
  contract_signing_speed = 'contract_signing_speed',
  documents_collecting = 'documents_collecting',
  overpayment_refund = 'overpayment_refund',
  seller_quality = 'seller_quality',
  shipment_speed = 'shipment_speed',
}

export enum Direction {
  within_state = 'within_state',
  within_state_ret = 'within_state_ret',
  out_state = 'out_state',
}

enum SaleOperations {
  buy_farmer_pf = 'buy_farmer_pf',
  buy_farmer_pj = 'buy_farmer_pj',
  buy_company_pj = 'buy_company_pj',
}

enum PurchaseOperations {
  sale_merchants = 'sale_merchants',
  sale_feed = 'sale_feed',
  export = 'export',
}

export interface DealTaxItem {
  quantity: number
  gross_sales: number
  gross_sales_after_taxes: number
  gross_margin: number
  gross_margin_percent: number
  product_cost: number
  product_net_cost: number
  freight_cost: number
  freight_net_cost: number
  freight_icms_credit: number
  freight_piscofins_credit: number
  icms_debit: number
  icms_credit: number
  piscofins_debit: number
  piscofins_credit: number
  taxes_source_data: {
    direction: Direction
    freight_icms_rate: number
    freight_piscofins_rate: number
    purchase_aground_imputs?: AgroundImputs
    purchase_icms_rate: number
    purchase_operation: PurchaseOperations
    purchase_piscofins_rate: number
    purchase_state: string
    sale_aground_imputs?: AgroundImputs
    sale_icms_rate: number
    sale_operation: SaleOperations
    sale_piscofins_rate: number
    sale_state: string
  }
}

export enum CloningTypes {
  seller_change = 'seller_change',
  customer_change = 'customer_change',
  quantity_split = 'quantity_split',
}

export interface CloningFormFields {
  new_bid: string
  quantity: number | string
  price_exw: number | string
  price_cpt: number | string
  distance: number | string
  logistics_rate: number | string
  price_logistics: number | string
  cloning_type: CloningTypes
  user: string
}

export type DealProductDTO = {
  id?: string
  parameter?: string
  parameter_value?: string
}

export interface DealContractForm {
  deal: string
  contract_type: DealContractType
  dispatching_date: string
  payment_terms: string
  transfer_address: string
  email: string
  phone: string
  conformity_declaration_type: DealContractType
  season: string
  quantity: string | number
  price: number
  has_vat: boolean
  product_purpose: ProductPurpose
  parameters: DealProductDTO[]
  main_param?: DealProductDTO
  parameters_comment: string
}

export enum SignStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  DONE = 'done',
}

export enum ApprovalStatus {
  NOT_APPROVED = 'not_approved',
  APPROVED_BY_LOGISTICIANS = 'approved_by_logistician',
  APPROVED_BY_DIRECTOR = 'approved_by_commercial_director',
}

export enum RegionalWorkType {
  sample = 'sample',
  documents = 'documents',
  loading = 'loading',
}

export type Regionals = {
  regional: string
  work_type: RegionalWorkType
}

export type ExecutorsForm = {
  user_coordinator?: string | number | null
  deal_coordinator?: string | number | null
  shipment_coordinator?: string | number | null
  logisticians?: (string | number)[]
  regional?: string | number | null
  trader?: string | number | null
  logistician?: string | null
  regionals?: string[] | number[] | null
  regionals_with_type?: Regionals[]
  sample?: string
  documents?: string
  loading?: string
  ignore_quality?: boolean
}
