import { FC, useEffect, useState } from 'react'

import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import * as managers from 'modules/domain/teams/managers'
import { Team, TeamPlan } from 'modules/domain/teams/types'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import { TeamInfo } from 'views/pages/Teams/TeamDetail/TeamInfo'
import * as Header from 'views/ui/Header/Header'
import TeamsRoutes from 'views/pages/Teams/routes'
import { useHelmet } from '@agro-club/agroclub-shared'
import { AnalyticsPages, AnalyticsPlaces } from '../analyticsPlaces'
import APageWrapper from 'views/components/PageWrapper/APageWrapper'
import { useAProgress } from 'hooks/useAProgress'

export const TeamDetail: FC = () => {
  const { t } = useTranslation('teams')
  const { id } = useParams()

  const [teamPlans, setTeamPlans] = useState<TeamPlan[]>([])
  const [teamMembers, setTeamMembers] = useState<Team>({ id: 0, name: '' })

  const [setProgress, setPlan] = useAProgress(
    (volume, schedule_date) => managers.setPlans(volume, parseInt(id, 10), schedule_date),
    {
      eventName: 'setPlan',
      onSuccess: async () => {
        const plans = await managers.getPlansById(id)
        setTeamPlans(plans)
      },
    },
  )

  const [editProgress, editPlan] = useAProgress((volume, planId) => managers.editPlan(volume, planId), {
    eventName: 'editPlan',
    onSuccess: async () => {
      const plans = await managers.getPlansById(id)
      setTeamPlans(plans)
    },
  })

  useEffect(() => {
    const fetchData = async () => {
      const plans = await managers.getPlansById(id)
      const members = await managers.getMembersById(id)

      setTeamPlans(plans)
      setTeamMembers(members)
    }

    fetchData()
  }, [id])

  useHelmet({ title: teamMembers.name })

  return (
    <APageWrapper page={AnalyticsPages.TEAM_DETAIL} place={AnalyticsPlaces.DETAIL} id={id}>
      <Layout.Header>
        <Header.Root>
          <Header.Breadcrumbs
            routes={[
              {
                path: TeamsRoutes.List,
                breadcrumbName: t('teams'),
              },
              {
                breadcrumbName: teamMembers.name,
              },
            ]}
          />
          <Header.Title title={teamMembers.name} compact />
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <TeamInfo
          setProgress={setProgress}
          editProgress={editProgress}
          teamPlans={teamPlans}
          teamMembers={teamMembers}
          setPlan={setPlan}
          editPlan={editPlan}
        />
      </Layout.Content>
    </APageWrapper>
  )
}
