import { useFormik } from 'formik'

import { updateItem } from 'modules/domain/company/managers'
import { Company, CompanyDTO } from 'modules/domain/company/types'
import { RequestError } from 'modules/errors'
import { CompanyRuDetailsModal } from './CompanyRuDetailsModal'
import { useAProgress } from 'hooks/useAProgress'
import { FC } from 'react'

type Props = {
  onClose: () => void
  company: Company
  refreshCompany: (arg: boolean) => void
}

export const CompanyRuEdit: FC<Props> = ({ onClose, company, refreshCompany }) => {
  const [editProgress, editCompany] = useAProgress(updateItem, {
    eventName: 'edit',
    onSuccess: async () => {
      await refreshCompany(true)
      onClose()
    },
    onError: (err) => {
      const error = err as RequestError
      const { errors } = error
      formik.setErrors(errors)
    },
  })

  const formik = useFormik<CompanyDTO>({
    initialValues: company,
    enableReinitialize: true,
    onSubmit: (values) => editCompany(company.id, values),
  })

  return <CompanyRuDetailsModal progress={editProgress} onClose={onClose} formik={formik} />
}
