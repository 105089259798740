const main = 'specifications'
const relativeRoutes = {
  relativePage: `${main}/*`,
  relativeList: `${main}`,
  relativeDetail: ':id',
}

const routes = {
  List: `/${main}`,
  Details: `/${main}/${relativeRoutes.relativeDetail}`,
}

const SpecificationsRoutes = { ...relativeRoutes, ...routes }

export default SpecificationsRoutes
