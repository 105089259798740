import { useTranslation } from 'react-i18next'
import { DealContractForm } from 'views/pages/Deal/DealDetail/types'
import { FormikProps } from 'formik'
import {
  Checkbox,
  DateISO,
  FormGroup,
  Input,
  defaultTheme,
  Typography,
  Radio,
  RadioItem,
} from '@agro-club/agroclub-shared'
import { ProductPurpose } from 'modules/domain/documentRequest/types'
import { FormItemWrapper, ShortFormControl } from 'views/styled/common'
import { DealContractType } from 'modules/domain/deal/types'
import { DatePicker } from 'views/components/DatePicker/DatePicker'
import { QuantityInput } from 'views/components/QuantityInput/QuantityInput'
import { REACT_APP_CURRENCY_SYMBOL } from 'env'
import styled from 'styled-components'

const ControlWithCheckbox = styled.div`
  display: flex;
  column-gap: 4px;
  @media (max-width: ${defaultTheme.queries.mobile}) {
    flex-direction: column;
    gap: 18px 0;
  }
`

const ControlDescription = styled.div`
  ${Typography.bodySmall}
  color: ${defaultTheme.color.textSecondary60a};
  margin-top: 4px;
  margin-left: 212px;
  @media (max-width: ${defaultTheme.queries.mobile}) {
    margin-left: 0px;
  }
`
const SeasonQuantity = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px 0;
  @media (max-width: ${defaultTheme.queries.mobile}) {
    flex-direction: row;
    gap: 0 16px;
  }
`

type Props = {
  formik: FormikProps<DealContractForm>
}

const currency = REACT_APP_CURRENCY_SYMBOL

export const ControlsBlock = ({ formik }: Props) => {
  const { t } = useTranslation('deal')

  return (
    <>
      <FormGroup label={t('requestDocsModal.contract_form')} error={formik.errors.contract_type}>
        <FormItemWrapper>
          <Radio value={formik.values.contract_type} onChange={(value) => formik.setFieldValue('contract_type', value)}>
            <RadioItem value={DealContractType.agroclub} label={t('requestDocsModal.agroclub')} />
            <RadioItem value={DealContractType.farmer} label={t('requestDocsModal.seller')} />
          </Radio>
        </FormItemWrapper>
      </FormGroup>

      <ShortFormControl>
        <FormGroup label={t('requestDocsModal.dispatching_date')} error={formik.errors.dispatching_date} required>
          <DatePicker
            onChange={(data) => formik.setFieldValue('dispatching_date', data['date'])}
            value={formik.values.dispatching_date as DateISO}
            error={!!formik.errors.dispatching_date}
            placeholder={t('common:datePlaceholder')}
            allowClear={true}
          />
        </FormGroup>
      </ShortFormControl>

      <FormGroup label={t('requestDocsModal.payment_terms')} error={formik.errors.payment_terms}>
        <Input {...formik.getFieldProps('payment_terms')} />
      </FormGroup>

      <div>
        <FormGroup label={t('requestDocsModal.transfer_address')} error={formik.errors.transfer_address} required>
          <Input
            {...formik.getFieldProps('transfer_address')}
            placeholder={t('requestDocsModal.placeholders.enter_address')}
            invalid={!!formik.errors.transfer_address}
          />
        </FormGroup>
        <ControlDescription>{t('requestDocsModal.transfer_address_description')}</ControlDescription>
      </div>

      <FormGroup label={t('requestDocsModal.email')} error={formik.errors.email} required>
        <Input
          {...formik.getFieldProps('email')}
          placeholder={t('requestDocsModal.placeholders.enter_email')}
          invalid={!!formik.errors.email}
        />
      </FormGroup>

      <ShortFormControl>
        <FormGroup label={t('requestDocsModal.phone')} error={formik.errors.phone} required>
          <Input
            {...formik.getFieldProps('phone')}
            placeholder={t('common:phone_placeholder')}
            invalid={!!formik.errors.phone}
          />
        </FormGroup>
      </ShortFormControl>

      <FormGroup
        label={t('requestDocsModal.conformity_declaration_type')}
        error={formik.errors.conformity_declaration_type}
      >
        <FormItemWrapper>
          <Radio
            value={formik.values.conformity_declaration_type}
            onChange={(value) => formik.setFieldValue('conformity_declaration_type', value)}
          >
            <RadioItem value={DealContractType.agroclub} label={t('requestDocsModal.agroclub')} />
            <RadioItem value={DealContractType.farmer} label={t('requestDocsModal.seller')} />
          </Radio>
        </FormItemWrapper>
      </FormGroup>
      <SeasonQuantity>
        <ShortFormControl>
          <FormGroup label={t('requestDocsModal.season')} error={formik.errors.season} required>
            <Input {...formik.getFieldProps('season')} invalid={!!formik.errors.season} />
          </FormGroup>
        </ShortFormControl>

        <ShortFormControl>
          <QuantityInput formik={formik} fieldName="quantity" label={t('common:volume')} required />
        </ShortFormControl>
      </SeasonQuantity>

      <ControlWithCheckbox>
        <ShortFormControl>
          <FormGroup label={t('requestDocsModal.price', { currency })} error={formik.errors.price} required>
            <Input {...formik.getFieldProps('price')} invalid={!!formik.errors.price} />
          </FormGroup>
        </ShortFormControl>
        <Checkbox
          onChange={(_value, isChecked) => formik.setFieldValue('has_vat', isChecked)}
          label={t('requestDocsModal.has_vat')}
          isChecked={formik.values.has_vat}
        />
      </ControlWithCheckbox>

      <FormGroup label={t('requestDocsModal.product_purpose')} error={formik.errors.product_purpose}>
        <FormItemWrapper>
          <Radio
            value={formik.values.product_purpose}
            onChange={(value) => formik.setFieldValue('product_purpose', value)}
          >
            <RadioItem value={ProductPurpose.alimentary} label={t('requestDocsModal.alimentary')} />
            <RadioItem value={ProductPurpose.feed} label={t('requestDocsModal.feed')} />
          </Radio>
        </FormItemWrapper>
      </FormGroup>
    </>
  )
}
