import { makeRefreshTokenAndRetry, TokensPair } from '@agro-club/agroclub-shared'
import axios from 'axios'
import { clearToken, getRefreshToken, saveAccessToken, saveRefreshToken } from 'modules/domain/auth/repository'
import { endpoints } from 'modules/endpoints'
import { authClient } from './httpClient'

const refreshToken = async (token: string): Promise<TokensPair> => {
  const response = await authClient.post<any>(endpoints.refreshToken(), { refresh: token })
  return {
    accessToken: response.access,
    refreshToken: response.refresh,
  }
}

export const refreshTokenAndRetry = makeRefreshTokenAndRetry({
  getRefreshToken,
  refreshTokenPair: refreshToken,
  saveRefreshToken,
  saveAccessToken,
  clearTokens: clearToken,
  client: axios,
  onFailedRetry: () => {
    window.location.reload()
  },
})
