import { ASharedModal, SharedModalInputs, SharedModalTitle } from 'views/components/SharedModal'
import ADefaultModalFooter from 'views/components/DefaultModalFooter/ADefaultModalFooter'
import { DateISO, FormGroup, Input } from '@agro-club/agroclub-shared'
import { DatePicker } from 'views/components/DatePicker/DatePicker'
import { refetchFunc } from 'modules/domain/common/hooks'
import { apiClient } from 'modules/utils/httpClient'
import { Sample } from 'views/pages/Samples/types'
import { useAFormHandler } from 'analytics/hooks'
import { useTranslation } from 'react-i18next'
import { endpoints } from 'modules/endpoints'
import { SamplesForBid } from '../types'
import { useFormik } from 'formik'
import { FC } from 'react'
import { FormGroupUpload } from 'views/components/FormGroupUpload/FormGroupUpload'
import { RespFile } from 'modules/domain/types'

type Props = {
  sample: SamplesForBid | Sample
  refetch: refetchFunc
  onClose: () => void
}

type Fields = {
  expire_at: DateISO | undefined
  title: string
  files: RespFile[]
}

export const EditSample: FC<Props> = ({ onClose, refetch, sample }) => {
  const { t } = useTranslation('samples')
  const { formProgress, formHandler } = useAFormHandler('editSample', { id: sample.id })

  const formik = useFormik<Fields>({
    initialValues: {
      expire_at: sample.expire_at,
      title: sample.title,
      files: sample.files,
    },

    onSubmit: formHandler(
      async () => {
        await apiClient.put(endpoints.samples(sample.id), formik.values)
      },
      {
        onSuccess: async () => {
          await refetch()
          onClose()
        },
        onError: (error) => {
          formik.setErrors(error.errors)
        },
      },
    ),
  })

  return (
    <ASharedModal size="medium" onClose={onClose} id={sample.id}>
      <SharedModalTitle>{t('edit')}</SharedModalTitle>
      <SharedModalInputs>
        <FormGroup label={t('sampleName')} error={formik.errors.title} required>
          <Input {...formik.getFieldProps('title')} invalid={!!formik.errors.title} />
        </FormGroup>
        <FormGroup label={t('validity')} error={formik.errors.expire_at} required>
          <DatePicker
            onChange={(data) => formik.setFieldValue('expire_at', data['date'])}
            value={formik.values.expire_at}
            placeholder={t('validity')}
          />
        </FormGroup>
        <FormGroupUpload
          files={formik.values.files}
          maxWidthFileUpload={405}
          progress={formProgress}
          label={t('file')}
          fieldName="files"
          formik={formik}
        />
      </SharedModalInputs>
      <ADefaultModalFooter
        onConfirm={() => formik.handleSubmit()}
        confirmButtonText={t('common:save')}
        progress={formProgress}
        onClose={onClose}
      />
    </ASharedModal>
  )
}
