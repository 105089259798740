import { ASharedModal, SharedModalTitle } from 'views/components/SharedModal'
import { AnalyticsPlaces } from 'views/pages/Bid/analyticsPlaces'
import { Radio, RadioItem } from '@agro-club/agroclub-shared'
import { DistributeBlock } from './DistributeBlock'
import { RearrangeBlock } from './RearrangeBlock'
import { useTranslation } from 'react-i18next'
import { BalanceBlock } from './BalanceBlock'
import { TakeOffBlock } from './TakeOffBlock'
import styled from 'styled-components'
import { FC, useState } from 'react'

type Props = {
  refetch: () => void
  onClose: () => void
}

enum TypeDistribution {
  distribute = 'distribute',
  rearrange = 'rearrange',
  balance = 'balance',
  takeOff = 'take_off',
}

const StyledRadio = styled(Radio)`
  justify-content: space-between;
  padding-right: 20px;
`

export const Controls: FC<{ onChange: (value: TypeDistribution) => void; value: string | number }> = ({
  onChange,
  value,
}) => {
  const { t } = useTranslation('my_tasks')

  return (
    <StyledRadio onChange={onChange} value={value}>
      <RadioItem value={TypeDistribution.distribute} label={t('task_distribution.distribute')} />
      <RadioItem value={TypeDistribution.rearrange} label={t('task_distribution.rearrange')} />
      <RadioItem value={TypeDistribution.balance} label={t('task_distribution.balance')} />
      <RadioItem value={TypeDistribution.takeOff} label={t('task_distribution.take_off')} />
    </StyledRadio>
  )
}

export const TaskDistributionModal: FC<Props> = ({ onClose, refetch }) => {
  const { t } = useTranslation('my_tasks')
  const [currentBlock, setCurrentBlock] = useState<TypeDistribution>(TypeDistribution.distribute)

  const renderBlock = (type: TypeDistribution) => {
    const BlockComponent = {
      [TypeDistribution.distribute]: DistributeBlock,
      [TypeDistribution.rearrange]: RearrangeBlock,
      [TypeDistribution.balance]: BalanceBlock,
      [TypeDistribution.takeOff]: TakeOffBlock,
    }[type]

    return (
      <BlockComponent onClose={onClose} refetch={refetch}>
        <Controls value={currentBlock} onChange={setCurrentBlock} />
      </BlockComponent>
    )
  }

  return (
    <ASharedModal onClose={onClose} size="medium" id={AnalyticsPlaces.TASK_MODAL}>
      <SharedModalTitle>{t('task_distribution.task_distribution')}</SharedModalTitle>
      {renderBlock(currentBlock)}
    </ASharedModal>
  )
}
