import { FC, useState } from 'react'

import { useTranslation } from 'react-i18next'

import { AntPopover, Icons } from '@agro-club/agroclub-shared'

import { isBrazil } from 'env'
import { DealShipment } from 'modules/domain/deal/types'
import {
  BodyCell,
  ButtonsBodyCell,
  ButtonsWrapper,
  SecondaryBodyCell,
  SecondRow,
  TableRow,
} from 'views/pages/Deal/DealDetail/styled'

import formatNumber from 'modules/utils/numbers/formatNumber'
import useFormatDate from 'hooks/useFormatDate'
import { AButton } from 'views/components/Analytics'
import formatPrice from '../../../../../../modules/utils/numbers/formatPrice'
import { PopoverContent } from './PopoverContent'

type ItemProps = {
  shipment: DealShipment
  shipmentsRefetch: () => Promise<void>
  dealId: string
}

export const ShippingItem: FC<ItemProps> = ({ shipment, shipmentsRefetch, dealId }) => {
  const { t } = useTranslation('deal')
  const [popoverVisible, setPopoverVisible] = useState(false)

  const formatDate = useFormatDate()
  return (
    <>
      <TableRow>
        <BodyCell>{formatDate(shipment.delivered_at)}</BodyCell>
        <BodyCell textAlign="end">{formatNumber(shipment.quantity, true)}</BodyCell>
        <BodyCell textAlign="end">{formatNumber(shipment.price_logistics, true)}</BodyCell>
        <BodyCell textAlign="end">{formatNumber(shipment.price_exw, true)}</BodyCell>
        <BodyCell textAlign="end">{formatNumber(shipment.price_cpt, true)}</BodyCell>
        <ButtonsBodyCell rowSpan={2}>
          <ButtonsWrapper>
            <AntPopover
              content={
                <PopoverContent
                  dealId={dealId}
                  shipment={shipment}
                  shipmentRefetch={shipmentsRefetch}
                  setPopoverVisible={setPopoverVisible}
                />
              }
              placement="bottomRight"
              trigger="click"
              open={popoverVisible}
              onOpenChange={() => setPopoverVisible(!popoverVisible)}
            >
              <AButton id="popover" intent="white" Icon={Icons.IconMoreHoriz} />
            </AntPopover>
          </ButtonsWrapper>
        </ButtonsBodyCell>
      </TableRow>
      <SecondRow key={`${shipment.id}-postfix`}>
        <SecondaryBodyCell colSpan={2} textAlign="start">
          {t('fields.gmv_full', { number: formatPrice(shipment.gmv, true) })}
        </SecondaryBodyCell>
        <SecondaryBodyCell colSpan={3} textAlign="start">
          {t('fields.margin_full', { number: formatPrice(shipment.margin, true) })}
        </SecondaryBodyCell>
      </SecondRow>
      {getExtraShipmentData(t, shipment)}
    </>
  )
}

const getExtraShipmentData = (t, shipment: DealShipment) => {
  if (isBrazil) {
    return null
  }
  return (
    <SecondRow key={`${shipment.id}-postfix-${shipment.id}`}>
      <SecondaryBodyCell colSpan={2} textAlign="start">
        {t('fields.lost_amount', { number: formatPrice(shipment.lost_amount, true) })}
      </SecondaryBodyCell>
      <SecondaryBodyCell colSpan={3} textAlign="start">
        {t('fields.timeout_amount', { number: formatPrice(shipment.timeout_amount, true) })}
      </SecondaryBodyCell>
    </SecondRow>
  )
}
