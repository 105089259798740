import { Deal } from 'modules/domain/deal/types'
import { FC } from 'react'
import { AButton } from 'views/components/Analytics'
import { useTranslation } from 'react-i18next'
import { refetchFunc } from 'modules/domain/common/hooks'
import { useAProgress } from 'hooks/useAProgress'
import { apiClient } from 'modules/utils/httpClient'
import { endpoints } from 'modules/endpoints'

interface Props {
  deal: Deal
  dealRefetch: refetchFunc
  specRefetch: refetchFunc
}

export const WithoutSpecification: FC<Props> = ({ deal, dealRefetch, specRefetch }) => {
  const { t } = useTranslation('deal')
  const [progress, changeToWithoutSpec] = useAProgress(() => apiClient.post(endpoints.dealChangeWithoutSpec(deal.id)), {
    eventName: 'dealChangeToWithoutSpec',
    analyticsPayload: { id: deal.id },
    onSuccess: async () => {
      await dealRefetch(true)
      await specRefetch(true)
    },
  })

  return (
    <AButton
      onClick={changeToWithoutSpec}
      progress={progress}
      intent="secondary"
      id="dealChangeToWithoutSpec"
      disabled={deal.without_purchase_specification}
    >
      {t('accordion.buyer_specifications.without_spec')}
    </AButton>
  )
}
