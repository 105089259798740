import { ContractFilesWithTypes } from './ContractFilesWithTypes'
import { ContractFileManager } from './ContractFileManager'
import { Deal } from 'modules/domain/deal/types'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { endpoints } from 'modules/endpoints'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px 0;
`

type Props = {
  setSummary: (hasFiles: boolean) => void
  contractId?: string
  bidId: string
  deal: Deal
}

export const ContractDocs: FC<Props> = ({ deal, setSummary, bidId, contractId }) => {
  const { t } = useTranslation('deal')
  const [hasAdditionalFiles, setHasAdditionalFiles] = useState(false)
  const [hasContractFiles, setHasContractFiles] = useState(false)

  useEffect(() => {
    if (!hasAdditionalFiles && !hasContractFiles) {
      setSummary(false)
      return
    }
    setSummary(true)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasAdditionalFiles, hasContractFiles])

  return (
    <Wrapper>
      {contractId && (
        <ContractFilesWithTypes contractId={contractId} dealId={deal.id} setHasFiles={setHasContractFiles} />
      )}
      <ContractFileManager
        fileListEndpoint={endpoints.bidFiles(String(bidId))}
        deleteFileEndpoint={endpoints.deleteBidFiles}
        title={t('accordion.additionalDocuments')}
        uploadFileEndpoint={endpoints.bidFiles}
        setHasFiles={setHasAdditionalFiles}
        id={bidId}
      />
    </Wrapper>
  )
}
