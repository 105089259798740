import { FormikContextType } from 'formik'
import { WarehouseDTO } from 'modules/domain/types'
import { TimeWrapper } from 'views/pages/Deal/DealDetail/DealEditModals/styled'
import { AntTimePicker } from '@agro-club/agroclub-shared'
import { FC } from 'react'

type Props = {
  formik: FormikContextType<WarehouseDTO>
}

export const ScheduleControls: FC<Props> = ({ formik }) => (
  <TimeWrapper>
    <AntTimePicker
      value={formik.values.schedule_from || ''}
      onChange={(value) => formik.setFieldValue('schedule_from', value)}
      size="large"
    />
    <div>—</div>
    <AntTimePicker
      value={formik.values.schedule_to || ''}
      onChange={(value) => formik.setFieldValue('schedule_to', value)}
      size="large"
    />
  </TimeWrapper>
)
