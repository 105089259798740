import { AntTag } from '@agro-club/agroclub-shared'
import { Deal } from 'modules/domain/deal/types'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { IconDocuments } from '../../Board/styled'

interface Props {
  deal: Deal
}

export const DocumentsTag: FC<Props> = ({ deal }) => {
  const { t } = useTranslation('deal')

  return (
    <AntTag
      icon={<IconDocuments $markDocumentsRequest={deal.mark_documents_request} />}
      color={deal.mark_documents_request ? 'blue' : 'grey'}
    >
      {t('documents_request')}
    </AntTag>
  )
}
