import { useTranslation } from 'react-i18next'

import { AnalyticsFilterVariant } from 'analytics/types'
import { ListRequestParams } from 'modules/domain/types'
import { FC } from 'react'
import { ACheckboxV2 } from 'views/components/Analytics'
import { DashboardTab } from '../types'

type Props = {
  activeTab: DashboardTab
  listRequestParams: ListRequestParams
  updateFilterState: (ListRequestParams) => void
  filterKey: string
}

export const UserStatusFilter: FC<Props> = ({
  activeTab,
  listRequestParams: activeRequestParams,
  updateFilterState,
  filterKey,
}) => {
  const { t } = useTranslation('dashboard')

  if (activeTab !== DashboardTab.matches) return

  const updateChecked = (val: boolean) => {
    updateFilterState({ [filterKey]: val ? true : undefined })
  }

  return (
    <ACheckboxV2
      onChange={(e) => updateChecked(e.target.checked)}
      checked={activeRequestParams.filter[filterKey] ? JSON.parse(activeRequestParams.filter[filterKey]) : undefined}
      label={t(filterKey)}
      id={`${AnalyticsFilterVariant.USER_STATUS}-${filterKey}`}
    />
  )
}
