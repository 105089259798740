import { useTranslation } from 'react-i18next'
import { getPriceTypeLabel, isPurchaseBid } from 'modules/domain/bid/utils'
import { FC, useState } from 'react'
import { Card } from 'views/components/Card/Card'
import { KeyValue } from 'views/components/KeyValue/KeyValue'
import { formatTarif } from 'modules/utils/numbers/formatTarif'
import { formatQuantity } from 'modules/utils/numbers/formatQuantity'
import { BidDetailGrid } from 'views/pages/Deal/DealDetail/BidDetailBlock/BidDetailGrid'
import { DealPriceQuantityEditModal } from '../../DealEditModals/DealPriceQuantityEditModal'
import { Bid, BidContract } from 'modules/domain/bid/types'
import { refetchFunc } from 'modules/domain/common/hooks'
import { useMediaQuery } from 'react-responsive'
import { defaultTheme } from '@agro-club/agroclub-shared'

type Props = {
  bid: Bid | BidContract
  readOnly: boolean
  refetch: refetchFunc
}

export const RUBidDetailProduct: FC<Props> = ({ bid, refetch, readOnly = false }) => {
  const { t } = useTranslation(['deal', 'bid', 'address'])
  const isPurchase = isPurchaseBid(bid)
  const [priceQuantityEditIsOpen, setPriceQuantityEditIsOpen] = useState(false)

  const isMobile = useMediaQuery({
    query: `(max-width: ${defaultTheme.queries.mobile})`,
  })

  return (
    <>
      <Card.Container bordered disableShadow>
        <Card.Grid cols={2} maxColumnWidth={isMobile ? '1fr' : '186px'} columnGap={32}>
          <KeyValue label={getPriceTypeLabel(bid.price_type, t)}>{formatTarif(bid.price)}</KeyValue>
          <KeyValue label={t('fields.quantity')}>{formatQuantity(bid.quantity)}</KeyValue>

          <BidDetailGrid bid={bid} />
        </Card.Grid>
        {!readOnly && (
          <Card.EditButton
            id={isPurchase ? 'editBuyerDetails' : 'editSellerDetails'}
            onClick={() => setPriceQuantityEditIsOpen(true)}
          />
        )}
      </Card.Container>
      {priceQuantityEditIsOpen && (
        <DealPriceQuantityEditModal
          onClose={() => setPriceQuantityEditIsOpen(false)}
          bid={bid}
          isPurchase={isPurchase}
          onSuccess={refetch}
        />
      )}
    </>
  )
}
