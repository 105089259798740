import { endpoints } from 'modules/endpoints'
import { apiClient } from 'modules/utils/httpClient'

import { Company, CompanyDTO } from './types'

export const updateItem = (id: string, dto: Partial<CompanyDTO>) => apiClient.put<Company>(endpoints.company(id), dto)

export const changeRating = (
  dealId: string,
  values: {
    value: number
    rating_type: string
  },
) => apiClient.post(endpoints.dealRating(dealId), values)
