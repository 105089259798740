import { useTranslation } from 'react-i18next'
import { FC } from 'react'

type Props = {
  summary: string | undefined
}

export const SummaryComments: FC<Props> = ({ summary }) => {
  const { t } = useTranslation('common')

  return <div>{summary ? `${t('last')}: ${summary}` : t('no')}</div>
}
