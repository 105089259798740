import { ResponsiveModal } from 'views/components/ResponsiveModal/ResponsiveModal'
import { Deal, DealContract, DealContractType } from 'modules/domain/deal/types'
import { refetchFunc, useSingleEntity } from 'modules/domain/common/hooks'
import { useNotificationProgress } from 'hooks/useNotificationProgress'
import { ProductPurpose } from 'modules/domain/documentRequest/types'
import { DealContractForm } from 'views/pages/Deal/DealDetail/types'
import { SharedModalContent } from 'views/components/SharedModal'
import { apiClient } from 'modules/utils/httpClient'
import { useAFormHandler } from 'analytics/hooks'
import { useTranslation } from 'react-i18next'
import { endpoints } from 'modules/endpoints'
import { CommonFields } from './CommonFields'
import { useFormik } from 'formik'

type Props = {
  deal: Deal
  onClose: () => void
  onSuccess: refetchFunc
}

export const EditContractRequestModal = ({ onClose, deal, onSuccess }: Props) => {
  const { t } = useTranslation('deal')
  const [, contract] = useSingleEntity<DealContract>(endpoints.dealsContracts(deal.sale_contract_request.id))
  const { formProgress, formHandler } = useAFormHandler('editContractRequest', { id: deal.id })

  useNotificationProgress(formProgress)

  const params = contract?.parameters
    .slice(1)
    .map((param) => ({ parameter: param.parameter.id, parameter_value: param.parameter_value }))
  const mainParam = {
    parameter: contract?.parameters[0].parameter.id,
    parameter_value: contract?.parameters[0].parameter_value,
  }

  const formik = useFormik<DealContractForm>({
    initialValues: {
      deal: deal.id,
      contract_type: contract?.contract_type ?? DealContractType.agroclub,
      dispatching_date: contract?.dispatching_date ?? '',
      payment_terms: contract?.payment_terms ?? '',
      transfer_address: contract?.transfer_address ?? '',
      email: contract?.email ?? '',
      phone: contract?.phone ?? '',
      conformity_declaration_type: contract?.conformity_declaration_type ?? DealContractType.agroclub,
      season: contract?.season ?? '',
      quantity: deal.quantity,
      price: deal.price_exw,
      has_vat: false,
      product_purpose: ProductPurpose.alimentary,
      parameters: params ?? [],
      main_param: mainParam,
      parameters_comment: contract?.parameters_comment || '',
    },
    enableReinitialize: true,
    onSubmit: formHandler(
      async () => {
        const dto = {
          ...formik.values,
          parameters: [formik.values.main_param, ...formik.values.parameters],
        }
        delete dto.main_param
        await apiClient.put(endpoints.dealsContracts(contract?.id), dto)
      },
      {
        onSuccess: async () => {
          await onSuccess()
          onClose()
        },
        onError: (error) => {
          const { errors } = error
          formik.setErrors(errors)
        },
      },
    ),
  })

  return (
    <ResponsiveModal
      onConfirm={() => formik.submitForm()}
      confirmButtonText={t('common:send')}
      title={t('requestDocsModal.title')}
      progress={formProgress}
      onClose={onClose}
      id="requestDocs"
    >
      <SharedModalContent>
        <CommonFields formik={formik} deal={deal} />
      </SharedModalContent>
    </ResponsiveModal>
  )
}
