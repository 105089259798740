import { AvailableLanguages } from '@agro-club/agroclub-shared'
import { enUS, ptBR, ru } from 'date-fns/locale'

import useLanguage from 'hooks/useLanguage'

const LOCALES = {
  [AvailableLanguages.ru]: ru,
  [AvailableLanguages.en]: enUS,
  [AvailableLanguages.pt_BR]: ptBR,
}

export default function useDateFnsLocale() {
  const language = useLanguage()
  return LOCALES[language] || LOCALES[AvailableLanguages.ru]
}
