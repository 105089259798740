import { FC, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Icons, Progress } from '@agro-club/agroclub-shared'
import { LogisticRequest } from 'modules/domain/logisticsKR/types'
import { AddDraftTripModal } from 'views/pages/LogisticsKR/components/AddDraftTripModal/AddDraftTripModal'
import { CollapseHeaderWrapper, TitleAndTagWrapper } from '../../../components/styled'
import { BoardCounter } from 'views/components/Board/BoardCounter'
import { AButton } from 'views/components/Analytics'
import styled from 'styled-components'
import { refetchFunc } from 'modules/domain/common/hooks'
import { LogisticStatuses } from 'views/pages/LogisticsKR/types'

const ButtonWrapper = styled.div`
  position: absolute;
  top: 14px;
  right: 16px;
`

type Props = {
  counter: number | undefined
  activeRequest: LogisticRequest | undefined
  refetchDraftTrips: refetchFunc
  refetchInWorkRequestList: refetchFunc
  progress: Progress
}

export const DraftTripsHeader: FC<Props> = ({
  counter,
  activeRequest,
  refetchDraftTrips,
  progress,
  refetchInWorkRequestList,
}) => {
  const { t } = useTranslation('logisticBids')
  const [modalIsOpen, setModalIsOpen] = useState(false)

  const onSuccess = useCallback(
    async (arg?: boolean) => {
      await refetchInWorkRequestList(arg)
      await refetchDraftTrips(arg)
    },
    [refetchInWorkRequestList, refetchDraftTrips],
  )

  return (
    <CollapseHeaderWrapper>
      <TitleAndTagWrapper>
        <div>{t('board_columns.title.trips_reconciliation')}</div>
        <BoardCounter counter={counter} progress={progress} />
      </TitleAndTagWrapper>
      {activeRequest?.logistics_status === LogisticStatuses.in_progress && (
        <ButtonWrapper>
          <AButton
            id="addTripReconciliation"
            intent="approve"
            Icon={Icons.IconAdd}
            onClick={() => setModalIsOpen(true)}
          />
        </ButtonWrapper>
      )}
      {modalIsOpen && (
        <AddDraftTripModal activeRequest={activeRequest} onClose={setModalIsOpen} onSuccess={onSuccess} />
      )}
    </CollapseHeaderWrapper>
  )
}
