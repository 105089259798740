import { useTranslation } from 'react-i18next'

import { Button, Typography, defaultTheme, useHelmet } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

import { getUserName } from 'modules/domain/user/utils'
import { AuthLayout } from './AuthLayout'
import { FC, useContext } from 'react'
import { AuthContext } from 'modules/context/AuthContext'

const Text = styled.div`
  text-align: center;
  margin-bottom: 24px;
  margin-top: 61px;
  ${Typography.bodyLarge}
  color: ${defaultTheme.color.textPrimary900}
`

const StyledButton = styled(Button)`
  width: 100%;
`

export const NotPermitted: FC = () => {
  const { t } = useTranslation('auth')
  useHelmet({ title: t('metaTitle') })
  const { profile, logOut } = useContext(AuthContext)
  const name = getUserName(profile)
  return (
    <AuthLayout>
      <Text>{t('notPermitted', { name })}</Text>
      <StyledButton size="big" intent="primary" onClick={logOut}>
        {t('logout')}
      </StyledButton>
    </AuthLayout>
  )
}
