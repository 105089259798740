import { useTranslation } from 'react-i18next'
import { ContentBlock } from 'views/pages/LogisticsKR/components/styled'

import { TableSectionContainer, TableSectionHeader, TableSectionTitle } from '../styled'
import { ListRequestParams } from 'modules/domain/types'
import { useState } from 'react'
import { defaultListRequestParams } from 'views/components/TableFilters/data'
import { TripOfferStatuses } from 'views/pages/LogisticsKR/types'
import { LogisticRequest } from 'modules/domain/logisticsKR/types'
import { TripOffersTable } from './TripOffersTable'

type Props = {
  draftTripsRefetch: () => void
  request: LogisticRequest
}

export const TripOffersSection = ({ request, draftTripsRefetch }: Props) => {
  const { t } = useTranslation('logisticsKR')
  const [listRequestParams, setListRequestParams] = useState<ListRequestParams>({
    ...defaultListRequestParams,
    filter: { status: TripOfferStatuses.new },
  })

  return (
    <ContentBlock>
      <TableSectionContainer>
        <TableSectionHeader>
          <TableSectionTitle>{t('details.tableSections.tripOffersHeading')}</TableSectionTitle>
        </TableSectionHeader>
        <TripOffersTable
          draftTripsRefetch={draftTripsRefetch}
          listRequestParams={listRequestParams}
          setListRequestParams={setListRequestParams}
          request={request}
        />
      </TableSectionContainer>
    </ContentBlock>
  )
}
