import { Icons, Progress, Spinner, Text, Typography } from '@agro-club/agroclub-shared'
import { useProgress } from 'hooks/useProgress'
import { refetchFunc } from 'modules/domain/common/hooks'
import { endpoints } from 'modules/endpoints'
import { markEventAsRead } from 'modules/socket/managers'
import { EventModel, MarkAsReadPayload } from 'modules/socket/types'
import { apiClient } from 'modules/utils/httpClient'
import { FC, useEffect } from 'react'
import styled from 'styled-components'
import { AButton, AExternalLink } from 'views/components/Analytics'
import { TtnDocumentShort } from 'views/pages/LogisticsKR/types'

const ItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap: 4px;
  padding: 5px 4px;
  align-items: center;

  &:hover {
    background-color: ${({ theme }) => theme.color.primary8a};
    border-radius: 4px;
  }
`

const ItemInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 237px;
  min-height: 38px;
  word-break: break-all;
`

const PopoverContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  width: 300px;
`

const SpinnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 38px;
  width: 100%;
`

const Link = styled(AExternalLink)`
  ${Typography.bodyLarge}
`

type ItemProps = {
  tripId: string
  onSuccess: () => void
  doc: TtnDocumentShort
}

const Item: FC<ItemProps> = ({ tripId, onSuccess, doc }) => {
  const [progress, removeDoc] = useProgress(() => apiClient.delete(endpoints.LogisticsKR.tripsDocument(tripId, doc.id)))

  const handleRemove = async (e) => {
    e.stopPropagation()
    await removeDoc()
    await onSuccess()
  }

  return (
    <ItemWrapper>
      <ItemInfo>
        <Link eventName="ttnFile" target="_blank" href={doc.uploaded_file}>
          {doc.original_filename}
        </Link>
        {doc.rejection_reason && (
          <Text typography="bodySmall" color="accent">
            {doc.rejection_reason}
          </Text>
        )}
      </ItemInfo>
      <AButton
        id="removeTtnFromTrip"
        intent="white"
        Icon={Icons.IconRemove}
        progress={progress}
        onClick={handleRemove}
      />
    </ItemWrapper>
  )
}

type PopoverContentProps = {
  progress: Progress
  documents: TtnDocumentShort[]
  onSuccess: refetchFunc | (() => void)
  tripId: string
  hasErrorTtn: boolean
}

export const PopoverContent: FC<PopoverContentProps> = ({ documents, progress, onSuccess, tripId, hasErrorTtn }) => {
  useEffect(() => {
    if (!hasErrorTtn) return

    if (progress === Progress.SUCCESS) {
      const eventData = documents
        .map(
          (el) =>
            !!el.rejection_reason && {
              object_id: el.id,
              model: 'tripdocument' as EventModel,
              app_label: 'new_logistics',
            },
        )
        .filter(Boolean) as MarkAsReadPayload[]

      markEventAsRead(eventData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasErrorTtn, progress])

  return (
    <PopoverContentWrapper>
      {progress === Progress.WORK ? (
        <SpinnerWrapper>
          <Spinner />
        </SpinnerWrapper>
      ) : (
        documents.map((doc) => <Item key={doc.id} onSuccess={onSuccess} tripId={tripId} doc={doc} />)
      )}
    </PopoverContentWrapper>
  )
}
