import { FC, useState } from 'react'
import { Icons } from '@agro-club/agroclub-shared'
import { LogisticRequest } from 'modules/domain/logisticsKR/types'
import { useTranslation } from 'react-i18next'
import { DetailsItemInfo, SeparateDetailsItem } from 'views/pages/LogisticsKR/components/styled'
import {
  AddressInfoWrapper,
  EditButtonWrapper,
  InfoSection,
  InfoSectionText,
  InfoSectionTitle,
  InfoWrapper,
} from './CommonInfo/styled'
import { EditAssigneeModal } from './EditAssigneeModal'
import { AButton } from '../../../../../components/Analytics'
import { refetchFunc } from 'modules/domain/common/hooks'

interface Props {
  request: LogisticRequest
  refetchRequest?: refetchFunc
}

export const AssigneesAccordion: FC<Props> = ({ request, refetchRequest }) => {
  const { t } = useTranslation('logisticsKR')
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  const onSuccess = async () => {
    await refetchRequest?.(true)
  }

  return (
    <InfoWrapper>
      <InfoSection>
        <InfoSectionTitle>{t('details.accordion.team')}</InfoSectionTitle>
        <InfoSectionText>
          {request.assigned_team ? request.assigned_team?.name : t('common:withoutTeam')}
        </InfoSectionText>
      </InfoSection>
      {/* <InfoSection>
        <InfoSectionTitle>{t('details.accordion.senior_logistician')}</InfoSectionTitle>
        <InfoSectionText></InfoSectionText>
      </InfoSection> */}
      {!!request.executors?.length && (
        <InfoSection>
          <InfoSectionTitle>{t('details.accordion.logistician')}</InfoSectionTitle>
          <SeparateDetailsItem $alignCenter={request?.executors?.length <= 1}>
            <AddressInfoWrapper>
              <DetailsItemInfo>
                {request?.executors?.map((executor) => (
                  <InfoSectionText key={executor?.id}>{executor?.full_name}</InfoSectionText>
                ))}
              </DetailsItemInfo>
            </AddressInfoWrapper>
            <EditButtonWrapper>
              <AButton id="editAssignees" Icon={Icons.IconEdit} intent="white" onClick={() => setIsModalOpen(true)} />
            </EditButtonWrapper>
          </SeparateDetailsItem>
          {isModalOpen && (
            <EditAssigneeModal request={request} onClose={() => setIsModalOpen(false)} onSuccess={onSuccess} />
          )}
        </InfoSection>
      )}
    </InfoWrapper>
  )
}
