import { createContext, FC, useContext, useEffect, useState } from 'react'

import AnalyticsEvents from 'analytics/events'
import { AnalyticsPayload } from 'analytics/types'
import { useAnalytics } from 'use-analytics'
import { AuthContext } from '../modules/context/AuthContext'

interface Props {
  options: Options
  setOptions: (options: Options) => void
  track: (eventName: string, payload?: AnalyticsPayload) => void
}

export const AnalyticsContext = createContext<Props>({
  options: {},
  setOptions: () => null,
  track: () => null,
})

interface Options {
  page?: string
  place?: string
  id?: string
  ignoreEvents?: string[]
  disabledTrackOnPageLoad?: boolean
}

export const AnalyticsContextProvider: FC<{ children?: React.ReactNode }> = ({ children }) => {
  const { track } = useAnalytics()
  const [options, setOptions] = useState<Options>({})
  const { profile } = useContext(AuthContext)

  // Send Page event on page load
  useEffect(() => {
    if (!options.page || options.disabledTrackOnPageLoad) return

    trackWithPayload(AnalyticsEvents.Page)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options.page, options.disabledTrackOnPageLoad])

  // TODO check rerenders due trackWithPayload
  const trackWithPayload = (eventName: string, payload?: AnalyticsPayload) => {
    if (options.ignoreEvents?.includes(eventName)) return
    track(eventName, {
      backup_user_id: profile?.id,
      page: options.page,
      place: options.place,
      id: options.id,
      ...payload,
    })
  }

  const handleSetOptions = (newOptions: Options) => {
    setOptions((prevOptions) => {
      return {
        ...prevOptions,
        ...newOptions,
      }
    })
  }

  return (
    <AnalyticsContext.Provider
      value={{
        options,
        setOptions: handleSetOptions,
        track: trackWithPayload,
      }}
    >
      {children}
    </AnalyticsContext.Provider>
  )
}
