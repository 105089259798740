import { ResponsiveModal } from 'views/components/ResponsiveModal/ResponsiveModal'
import { DeliveryBasis, DutyType } from 'modules/domain/export/types'
import { FormGroup, TextArea } from '@agro-club/agroclub-shared'
import { HrWithHeader } from 'views/components/Hr/Hr'
import { apiClient } from 'modules/utils/httpClient'
import { useAFormHandler } from 'analytics/hooks'
import { useTranslation } from 'react-i18next'
import { endpoints } from 'modules/endpoints'
import { CommonFields } from './CommonFields'
import { cloneDeep } from 'lodash'
import { useFormik } from 'formik'
import { FC } from 'react'

type Props = {
  onClose: () => void
  refetch: () => void
}

export type FormFields = {
  product: null | string
  quantity: null | string
  delivery_basis: DeliveryBasis
  number: null | string
  unique_contract_number: null | string
  contract_date: null | string
  through_dubai: Boolean
  through_dubai_price: null | string
  through_dubai_price_currency: null | string
  purchaser: null | string
  ship: null | string
  ship_owner: null | string
  price: null | string
  price_currency: null | string
  freight_cost: null | string
  freight_currency: null | string
  duty_type: null | DutyType
  duty_value: null | string
  storage_cost: null | string
  storage_currency: null | string
  documents_cost: null | string
  documents_currency: null | string
  warehouse: null | string
  warehouse_cost: null | string
  warehouse_currency: null | string
  freight_broker: null | string
  freight_broker_cost: null | string
  freight_broker_currency: null | string
  export_broker: null | string
  export_broker_cost: null | string
  export_broker_currency: null | string
  insurance: null | string
  insurance_cost: null | string
  insurance_currency: null | string
  surveyor: null | string
  surveyor_cost: null | string
  surveyor_currency: null | string
  comment?: null | string
}

export const AddExport: FC<Props> = ({ onClose, refetch }) => {
  const { t } = useTranslation('export')

  const { formProgress, formHandler } = useAFormHandler('addExportContract')

  const formik = useFormik<FormFields>({
    initialValues: {
      product: null,
      quantity: null,
      delivery_basis: DeliveryBasis.CIF,
      number: null,
      unique_contract_number: null,
      contract_date: null,
      through_dubai: false,
      through_dubai_price: null,
      through_dubai_price_currency: null,
      purchaser: null,
      ship: null,
      ship_owner: null,
      price: null,
      price_currency: null,
      freight_cost: null,
      freight_currency: null,
      duty_type: null,
      duty_value: null,
      storage_cost: null,
      storage_currency: null,
      documents_cost: null,
      documents_currency: null,
      warehouse: null,
      warehouse_cost: null,
      warehouse_currency: null,
      freight_broker: null,
      freight_broker_cost: null,
      freight_broker_currency: null,
      export_broker: null,
      export_broker_cost: null,
      export_broker_currency: null,
      insurance: null,
      insurance_cost: null,
      insurance_currency: null,
      surveyor: null,
      surveyor_cost: null,
      surveyor_currency: null,
      comment: null,
    },

    onSubmit: formHandler(
      async () => {
        const valuesToSend = cloneDeep(formik.values)
        delete valuesToSend.duty_value

        if (formik.values.duty_type === DutyType.FIXED) {
          valuesToSend.fix_duty_value = formik.values.duty_value
        }
        if (formik.values.duty_type === DutyType.PERCENT) {
          valuesToSend.percent_duty_value = formik.values.duty_value
        }

        await apiClient.post(endpoints.export(), valuesToSend)
      },
      {
        onSuccess: () => {
          refetch()
          onClose()
        },
        onError: (error) => {
          const { errors } = error
          formik.setErrors(errors)
        },
      },
    ),
  })

  return (
    <ResponsiveModal
      onConfirm={() => formik.submitForm()}
      confirmButtonText={t('common:save')}
      title={t('newExportContract')}
      progress={formProgress}
      onClose={onClose}
      id="addExport"
    >
      <CommonFields formik={formik}>
        <HrWithHeader text={t('extra')} />
        <FormGroup error={formik.errors.comment} label={t('comment')}>
          <TextArea {...formik.getFieldProps('comment')} invalid={!!formik.errors.comment} height={80} />
        </FormGroup>
      </CommonFields>
    </ResponsiveModal>
  )
}
