import { FormikProps } from 'formik'
import { FC } from 'react'
import { CloningFormFields, CloningTypes } from '../../types'
import { ProfileType } from 'modules/domain/user/types'
import { isBrazil } from 'env'
import { BRCloningRadio } from './BRCloningRadio/BRCloningRadio'
import { CloningTypeRadio } from './CloningTypeRadio'

interface Props {
  formik: FormikProps<CloningFormFields>
  setCurrentCloningType: (value: CloningTypes) => void
  currentProfileType: ProfileType
  changeProfileTypeHandler: (value: ProfileType) => void
}

export const RadioButtons: FC<Props> = ({
  formik,
  setCurrentCloningType,
  currentProfileType,
  changeProfileTypeHandler,
}) => {
  if (isBrazil) {
    return (
      <BRCloningRadio
        formik={formik}
        setCurrentCloningType={setCurrentCloningType}
        currentProfileType={currentProfileType}
        changeProfileTypeHandler={changeProfileTypeHandler}
      />
    )
  }

  return <CloningTypeRadio formik={formik} setCurrentCloningType={setCurrentCloningType} />
}
