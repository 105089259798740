import { FormikFieldsType } from 'modules/domain/types'
import { CheckboxV2, FormGroup } from '@agro-club/agroclub-shared'

import { useTranslation } from 'react-i18next'
import { FC } from 'react'
import { FormItemWrapper } from 'views/styled/common'

type FormikProps = {
  formik: FormikFieldsType
  keys: string[]
  fieldName: string
  formGroupLabel: string
  checkboxLabel?: string
  required?: boolean
}

export const FormCheckboxGroup: FC<FormikProps> = ({
  formik,
  fieldName,
  formGroupLabel,
  required,
  keys,
  checkboxLabel,
}) => {
  const { t } = useTranslation()

  const handleChangeGroupCheckbox = (field, id) => {
    if (formik.values[field].includes(id)) {
      return formik.setFieldValue(
        field,
        formik.values[field]?.filter((el) => el !== id),
      )
    }
    return formik.setFieldValue(field, [...formik.values[field], id])
  }
  return (
    <FormGroup required={required} label={formGroupLabel} error={formik.errors[fieldName]}>
      <FormItemWrapper>
        {keys.map((type) => (
          <CheckboxV2
            key={type}
            id={type}
            checked={formik.values[fieldName]?.includes(type)}
            onChange={(e) => handleChangeGroupCheckbox(fieldName, e.target.id)}
            label={t(`${checkboxLabel}.${type}`)}
          />
        ))}
      </FormItemWrapper>
    </FormGroup>
  )
}

type Props = {
  value: string[]
  keys: string[]
  onChange: (v: string[]) => void
  keysRootTranslate: string
  label?: string
  required?: boolean
}

export const CheckboxGroup: FC<Props> = ({ value, onChange, keys, label, keysRootTranslate, required }) => {
  const { t } = useTranslation()

  const handleChangeGroupCheckbox = (e) => {
    const id = e.target.id
    if (value.includes(id)) {
      onChange(value?.filter((el) => el !== id))
    } else {
      onChange([...value, id])
    }
  }
  return (
    <FormGroup required={required} label={label}>
      <FormItemWrapper>
        {keys.map((key) => (
          <CheckboxV2
            key={key}
            id={key}
            checked={value?.includes(key)}
            onChange={handleChangeGroupCheckbox}
            label={t(`${keysRootTranslate}.${key}`)}
          />
        ))}
      </FormItemWrapper>
    </FormGroup>
  )
}
