import { KeyUser, Text, WrapperIcon } from '../../styled'
import { Icons } from '@agro-club/agroclub-shared'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { refetchFunc } from 'modules/domain/common/hooks'
import { User } from 'modules/domain/user/types'
import { SignificantConfirmModal } from './ConfirmModals/SignificantConfirmModal'

type Props = {
  userRefetch: refetchFunc
  user: User
}

export const KeyUserVerification = ({ userRefetch, user }: Props) => {
  const { t } = useTranslation('user')
  const [confirmSignificantModal, setConfirmSignificantModal] = useState(false)

  if (!user.profile || user.profile.is_significant === undefined) return null

  return (
    <>
      <KeyUser
        isActive={user.is_active}
        isVerified={user.profile.is_significant}
        onClick={() => setConfirmSignificantModal(true)}
      >
        <WrapperIcon>
          <Icons.IconDollar />
        </WrapperIcon>
        <Text>{t('key_user')}</Text>
      </KeyUser>

      {confirmSignificantModal && (
        <SignificantConfirmModal
          onClose={() => setConfirmSignificantModal(false)}
          refetch={userRefetch}
          userId={user.id}
        />
      )}
    </>
  )
}
