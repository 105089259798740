import { GeoAddress, GoogleAddressForm } from '@agro-club/agroclub-shared'
import { getGoogleOptions } from '../form/FormMap'
import { FC } from 'react'
import env from 'env'

interface Props {
  size?: 'small' | 'middle' | 'large'
  handlerMap: (data: any) => void
  address?: GeoAddress
  mapHeight?: number
  showMap?: boolean
}

export const AddressControl: FC<Props> = ({ handlerMap, showMap, size, mapHeight, address }) => {
  const googleKey = env.REACT_APP_GOOGLE_MAPS_API_KEY

  return (
    <GoogleAddressForm
      {...getGoogleOptions()}
      mapHeight={mapHeight}
      onChange={handlerMap}
      apikey={googleKey}
      showMap={showMap}
      address={address}
      size={size}
    />
  )
}
