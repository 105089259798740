import { defaultTheme } from '@agro-club/agroclub-shared'
import { AButton } from 'views/components/Analytics'
import { useMediaQuery } from 'react-responsive'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { FC } from 'react'

interface Props {
  handlerWithoutDocsModal: () => void
  handlerAddDocsModal: () => void
}

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 16px;
  @media (max-width: ${defaultTheme.queries.mobile}) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px 0;
  }
`
const StyledBtn = styled(AButton)`
  width: 100%;
`

export const DealDocumentRequestControls: FC<Props> = ({ handlerWithoutDocsModal, handlerAddDocsModal }) => {
  const { t } = useTranslation('deal')
  const isMobile = useMediaQuery({
    query: `(max-width: ${defaultTheme.queries.mobile})`,
  })

  return (
    <ButtonsWrapper>
      <StyledBtn
        size={isMobile ? 'biggest' : 'medium'}
        onClick={handlerWithoutDocsModal}
        id="withoutDocs"
        intent="primary"
      >
        {t('requestDocumentsBlock.withoutDocs')}
      </StyledBtn>

      <StyledBtn
        size={isMobile ? 'biggest' : 'medium'}
        onClick={handlerAddDocsModal}
        id="requestDocuments"
        intent="primary"
      >
        {t('requestDocumentsBlock.button')}
      </StyledBtn>
    </ButtonsWrapper>
  )
}
